import { formatText } from "bernie-l10n";

/**
 * Returns a text corresponding to the rating given.
 * 0-3.4: Withhold superlative
 * @param {number} rating - Number between 0-5 representing average rating of hotel
 * @return {string}
 */
export const getRatingText = (rating: number): string => {
  switch (true) {
    case rating > 9.2:
      return formatText("hotels.review.ratingCategory5");
    case rating >= 9.0:
      return formatText("hotels.review.ratingCategory4");
    case rating >= 8.6:
      return formatText("hotels.review.ratingCategory3");
    case rating >= 8.0:
      return formatText("hotels.review.ratingCategory2");
    case rating >= 7.0:
      return formatText("hotels.review.ratingCategory1");
    default:
      return "";
  }
};

export const getNormalRatingText = (rating: number): string => {
  switch (true) {
    case rating > 9.2:
      return formatText("hotels.review.ratingCategoryNormal5");
    case rating >= 9.0:
      return formatText("hotels.review.ratingCategoryNormal4");
    case rating >= 8.6:
      return formatText("hotels.review.ratingCategoryNormal3");
    case rating >= 8.0:
      return formatText("hotels.review.ratingCategoryNormal2");
    case rating >= 7.0:
      return formatText("hotels.review.ratingCategoryNormal1");
    default:
      return "";
  }
};
