/**
 * Exports possible values for Location types
 * Comes from the FlexDefinition declaration file
 */
export enum LocationType {
  AIRPORT = "airport",
  POINT_OF_INTEREST = "point_of_interest",
  METRO_STATION = "metro_station",
  TRAIN_STATION = "train_station",
  MULTI_CITY = "multi_city_vicinity",
  NEIGHBORHOOD = "neighborhood",
  AIRPORT_METRO_CODE = "airport_metro_code",
  CITY = "city",
  CONTINENT = "continent",
  COUNTRY = "country",
  MULTI_REGION = "multi_region_within_a_country",
  PROVINCE_STATE = "province_state",
  HOTEL = "hotel",
  HIGH_LEVEL_REGION = "high_level_region",
  CUSTOM_LARGE = "custom_large",
  USER_LOCATION = "user_location",
}

/**
 * Returns true if the location is a polygon, that is it can contain another location
 * (it's represented by a polygon on a map as opposed to a single lat, long coordinate)
 * @param locationType: Type of the location
 */
export function isPolygon(locationType: string) {
  return (
    locationType === LocationType.MULTI_CITY ||
    locationType === LocationType.NEIGHBORHOOD ||
    locationType === LocationType.CITY ||
    locationType === LocationType.CONTINENT ||
    locationType === LocationType.COUNTRY ||
    locationType === LocationType.MULTI_REGION ||
    locationType === LocationType.PROVINCE_STATE ||
    locationType === LocationType.HIGH_LEVEL_REGION ||
    locationType === LocationType.CUSTOM_LARGE
  );
}
