import * as React from "react";
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { UitkIconSize, UitkIcon } from "uitk-react-icons";
import { gtConfig } from "src/stores/wizard/config/staticConfigs/car/gt/gtConfig";
import { useLocalization } from "@shared-ui/localization-context";
import { liveAnnounce, Level } from "uitk-react-utils";
import { UitkSpacing } from "uitk-react-spacing";

export const SwapLocations = ({ swapLocations }: { swapLocations: () => void }) => {
  const { formatText } = useLocalization();
  const switchA11yText = formatText(gtConfig.switchLocKey);
  const actionSwapA11yText = formatText(gtConfig.switchActionLocKey);

  const [switchState, setSwitchState] = React.useState(false);

  const onSwapLocations = () => {
    const liveAnnounceText = switchState ? actionSwapA11yText : `${actionSwapA11yText}.`;
    liveAnnounce(liveAnnounceText, Level.ASSERTIVE);
    swapLocations();
    setSwitchState((prevState) => !prevState);
  };

  return (
    <>
      <Viewport>
        <ViewMedium>
          <button className="SwapLocationsDesktop" onClick={onSwapLocations} type="button" aria-label={switchA11yText}>
            <UitkIcon name="swap_vert" id="swapLocations" size={UitkIconSize.MEDIUM} />
          </button>
        </ViewMedium>
        <ViewSmall>
          <button className="SwapLocations" onClick={onSwapLocations} type="button" aria-label={switchA11yText}>
            <UitkSpacing padding={{ inlinestart: "half" }}>
              <UitkIcon name="swap_vert" id="swapLocations" size={UitkIconSize.MEDIUM} />
            </UitkSpacing>
          </button>
        </ViewSmall>
      </Viewport>
    </>
  );
};
