import * as React from "react";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

/**
 * This module is used for troubleshooting purposes.
 * It adds the tracing Ids as commented out html so that we can trace this specific request using splunk
 */
export const TracingInfo = ({ extendedContext }: { extendedContext: ExtendedContextStore }) => (
  <div className="TracingInfo is-hidden">
    {extendedContext && extendedContext.traceInfo && (
      <>
        <span id="DeviceId">{extendedContext.deviceId}</span>
        <span id="TraceId">{extendedContext.traceInfo["Trace-ID" as keyof string[][]]}</span>
        <span id="MessageId">{extendedContext.traceInfo["Message-ID" as keyof string[][]]}</span>
      </>
    )}
  </div>
);
