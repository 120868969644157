export const locKeys = {
  submitButton: "wizard.submit.label",
  destinationLabel: "wizard.cruise.destination.label",
  dateStartLabel: "wizard.cruise.date.start.label",
  dateEndLabel: "wizard.cruise.date.end.label",

  destinationPlaceholder: "wizard.cruise.destination.placeholder.label",
  mostPopular: "wizard.cruise.destination.mostPopular.label",
  caribbean: "wizard.cruise.destination.caribbean.label",
  bahamas: "wizard.cruise.destination.bahamas.label",
  mexico: "wizard.cruise.destination.mexico.label",
  alaska: "wizard.cruise.destination.alaska.label",
  europe: "wizard.cruise.destination.europe.label",
  bermuda: "wizard.cruise.destination.bermuda.label",
  hawaii: "wizard.cruise.destination.hawaii.label",
  canadaNewEngland: "wizard.cruise.destination.canadaNewEngland.label",
  otherDestinations: "wizard.cruise.destination.others.label",
  arcticAntarctic: "wizard.cruise.destination.arcticAntarctic.label",
  middleEast: "wizard.cruise.destination.middleEast.label",
  africa: "wizard.cruise.destination.africa.label",
  panamaCanal: "wizard.cruise.destination.panamaCanal.label",
  asia: "wizard.cruise.destination.asia.label",
  pacificCoastal: "wizard.cruise.destination.pacificCoastal.label",
  australiaNewZealand: "wizard.cruise.destination.australiaNewZealand.label",
  centralAmerica: "wizard.cruise.destination.centralAmerica.label",
  galapagos: "wizard.cruise.destination.galapagos.label",
  getaway: "wizard.cruise.destination.getaway.label",
  transatlantic: "wizard.cruise.destination.transatlantic.label",
  world: "wizard.cruise.destination.world.label",
  southAmerica: "wizard.cruise.destination.southAmerica.label",
  southPacific: "wizard.cruise.destination.southPacific.label",
  transpacific: "wizard.cruise.destination.transpacific.label",
  dialogDoneButton: "wizard.roomPicker.doneButton.label",
  dismissDialog: "typeahead.dismiss",
};
