export class ItemKeyHelper {
  private itemIndex: number;

  private baseKey: string;

  constructor(baseKey: string) {
    this.itemIndex = 0;
    this.baseKey = baseKey;
  }

  public next() {
    return `${this.baseKey}_${this.itemIndex++}`;
  }
}
