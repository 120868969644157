import type { DayOfWeek, OffsetDatesOptions } from "src/stores/wizard/config";

const dayInMillisecond = 60 * 60 * 24 * 1000;

// input pattern: YYYY-MM-DD
const parseDate = (input: string): number => {
  const parts = input.split("-");
  const theDate = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10));
  return theDate.getTime();
};

const isUsFormat = (input: string): boolean => {
  const usFormat = new RegExp(
    "^((((0[13578])|([13578])|(1[02]))[\\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\\/](([1-9])|([0-2][0-9]))))[\\/]\\d{4}$|^\\d{4}$"
  );
  return usFormat.test(input);
};

const convertUSFormatToISO = (input: string): string => {
  const parts = input.split("/");
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
};

const getFirstOfTodayDateInMillis = (): number => {
  const nowDate = new Date();
  nowDate.setHours(0, 0, 0, 0);

  return nowDate.getTime();
};

const getDiffDaysBetweenEpochDates = (date: number, anotherDate: number): number => {
  const rawDays = (date - anotherDate) / dayInMillisecond;
  return Math.floor(rawDays);
};

/**
 * @param date the date to compare with the given dayOfWeek.
 * @param dayOfWeek ie.: "Monday"
 * @returns the difference in days between the given date and the next dayOfWeek after that date. Returns 0, if the given dayOfWeek is not a valid day or the given date falls on the same day as the given dayOfWeek. The result is always within the range of 0 to 6 (inclusive).
 */
const getDiffDaysBetweenDateAndDayOfWeek = (date: Date, dayOfWeek: DayOfWeek): number => {
  const daysOfWeek: DayOfWeek[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const targetDayIndex = daysOfWeek.indexOf(dayOfWeek);
  const currentDayIndex = date.getDay();

  return targetDayIndex === -1 ? 0 : (targetDayIndex - currentDayIndex + 7) % 7;
};

export const getOffsetDatesByDatesOption = (offsetOptions: OffsetDatesOptions): OffsetDatesOptions => {
  const offsetOptionsResult = {} as OffsetDatesOptions;
  const todayDateMillis = getFirstOfTodayDateInMillis();
  let startDateDays = -1;
  let endDateDays = -1;
  if (offsetOptions.startDate) {
    const startDate = isUsFormat(offsetOptions.startDate)
      ? convertUSFormatToISO(offsetOptions.startDate)
      : offsetOptions.startDate;
    startDateDays = getDiffDaysBetweenEpochDates(parseDate(startDate), todayDateMillis);
  }
  if (offsetOptions.endDate) {
    const endDate = isUsFormat(offsetOptions.endDate)
      ? convertUSFormatToISO(offsetOptions.endDate)
      : offsetOptions.endDate;
    endDateDays = getDiffDaysBetweenEpochDates(parseDate(endDate), todayDateMillis);
  }
  if (startDateDays >= 0 && endDateDays > 0 && startDateDays < endDateDays) {
    offsetOptionsResult.startDateOffset = startDateDays;
    offsetOptionsResult.endDateOffset = endDateDays;
    return offsetOptionsResult;
  }

  if (
    offsetOptions.startDateOffset &&
    offsetOptions.endDateOffset &&
    offsetOptions.startDateOffset > 0 &&
    offsetOptions.startDateOffset < offsetOptions.endDateOffset
  ) {
    if (offsetOptions.adjustOffsetsToDayOfWeek) {
      const newDateWithOffset = new Date();
      newDateWithOffset.setDate(newDateWithOffset.getDate() + offsetOptions.startDateOffset);
      const diff = getDiffDaysBetweenDateAndDayOfWeek(newDateWithOffset, offsetOptions.adjustOffsetsToDayOfWeek);
      offsetOptions.startDateOffset += diff;
      offsetOptions.endDateOffset += diff;
    }
    offsetOptionsResult.startDateOffset = offsetOptions.startDateOffset;
    offsetOptionsResult.endDateOffset = offsetOptions.endDateOffset;
  }

  return offsetOptionsResult;
};
