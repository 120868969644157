import { timeValues } from "../shared/TimeDropdown/config/time/timeValues";
import { timeValues as timeValuesHotwire } from "../flexComponents/WizardCarPWA/utils/timeValues";

function locKeysFn(timeVals: string[]) {
  return {
    time24hrs: timeVals.map((timeValue) => `wizard.time${timeValue}.text`),
    time12hrs: timeVals.map((timeValue) => `wizard.time${timeValue}12hr.text`),
  };
}

export const locKeys = locKeysFn(timeValues);
export const locKeysHotwire = locKeysFn(timeValuesHotwire);
