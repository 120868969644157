import { LineOfBusinessDomain, PageLocation } from "__generated__/typedefs";
import { PageStore } from "bernie-plugin-mobx";

export const lineOfBusinessMapper: Record<string, LineOfBusinessDomain> = {
  H: "LODGING",
  P: "PACKAGES",
  F: "FLIGHT",
  C: "CAR",
  CR: "CRUISES",
  A: "ACTIVITIES",
  U: "UNCLASSIFIED",
  R: "RAILS",
  GT: "GROUNDTRANSFERS",
};

export const funnelLocationMapper: Record<string, PageLocation> = {
  0: "SYSTEM",
  10: "ENTRY",
  20: "SEARCH_RESULTS",
  30: "DETAILS",
  40: "CHECKOUT",
  50: "CHECKOUT_ERROR",
  60: "PAYMENT_ERROR",
  70: "CONFIRMATION",
  80: "POST_BOOKING",
  90: "USER",
  500: "FEATURES",
};

export interface PageIdentity {
  pageId: string;
  lineOfBusiness: string;
  funnelLocation: string;
}

export const getPageIdentity = (page: PageStore): PageIdentity => {
  if (!page || !page.pageId) {
    return { pageId: "", lineOfBusiness: "", funnelLocation: "" };
  }

  const [pageId, lineOfBusiness, funnelLocation] = page.pageId.split(",").map((identifier) => identifier.trim());
  return { pageId, lineOfBusiness, funnelLocation };
};

export const getFunnelLocation = (funnelLocation: string): PageLocation =>
  funnelLocationMapper[funnelLocation] || "ENTRY";

export const getLineOfBusiness = (lineOfBusiness: string): LineOfBusinessDomain =>
  lineOfBusinessMapper[lineOfBusiness] || "UNCLASSIFIED";
