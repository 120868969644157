import * as React from "react";
import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";

export interface Resource {
  readonly path: string;
  readonly type: string;
}

export enum PrefetchProductType {
  LODGING_SRP = "LODGING_SRP",
  FLIGHTS_SRP = "FLIGHTS_SRP",
}

const prefetchProductConfig = {
  [PrefetchProductType.LODGING_SRP]: {
    appName: "shopping-pwa",
    url: "/get-shopping-pwa-manifest-assets",
  },
  [PrefetchProductType.FLIGHTS_SRP]: {
    appName: "flights-shopping-pwa",
    url: "/flights-search-api/resources",
  },
};
const EXPIRATION_MINUTES = 10;

const logger = ClientLogger.getLoggerWithIdentifier("blossom.usePrefetchAssets");
const sessionStorageKey = (productType: PrefetchProductType) => `prefetch-resources-${productType}`;

const generatePrefetchLinks = (productType: PrefetchProductType, resource: Resource): void => {
  const { path } = resource;
  const linkElement = document.createElement("link");
  linkElement.rel = "prefetch";
  linkElement.href = `https://c.travel-assets.com/${prefetchProductConfig[productType].appName}/${path}`;
  //For future usage, `resource.type` could be used for adding type specific attributes eg: link.as = "style" later
  document.head.appendChild(linkElement);
};

const setResourcesInSessionStorage = (productType: PrefetchProductType, resources: Resource[]): void => {
  sessionStorage.setItem(
    sessionStorageKey(productType),
    JSON.stringify({
      timestamp: new Date(),
      resources,
    })
  );
};

const isSessionStorageOutdated = (timestamp: Date): boolean => {
  const expiration = new Date(timestamp);
  const now = new Date();
  expiration.setMinutes(expiration.getMinutes() + EXPIRATION_MINUTES);

  return now.getTime() > expiration.getTime();
};

const getValidResourcesFromSessionStorage = (productType: PrefetchProductType): Resource[] | undefined => {
  const key = sessionStorageKey(productType);
  const prefetchCacheData = sessionStorage.getItem(key);

  if (prefetchCacheData) {
    const parsedPrefetchCacheData = JSON.parse(prefetchCacheData);
    const { timestamp, resources } = parsedPrefetchCacheData;

    if (isSessionStorageOutdated(timestamp)) {
      sessionStorage.removeItem(key);

      return undefined;
    }

    return resources;
  }

  return undefined;
};

const getResources = async (
  productType: PrefetchProductType,
  responseMapper: (response: any) => Resource[]
): Promise<Resource[] | undefined> => {
  const cacheResources = getValidResourcesFromSessionStorage(productType);
  if (cacheResources) {
    return cacheResources;
  }

  try {
    const url = prefetchProductConfig[productType].url;
    const response = await fetch(url);
    if (response.ok) {
      const resources = responseMapper(await response.json());
      if (Array.isArray(resources)) {
        setResourcesInSessionStorage(productType, resources);

        return resources;
      }
    }
  } catch (error) {
    logger.logEvent(
      new SystemEvent(SystemEventLevel.ERROR, `Failed to fetch list of resource assets for product: ${productType}`),
      error
    );
  }

  return undefined;
};

export const usePrefetchAssets = (productType: PrefetchProductType, responseMapper: (response: any) => Resource[]) => {
  const resourcesProcessed = React.useRef(false);
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const prefetch = React.useCallback(async () => {
    // prefetch links once, if mounted
    if (mounted.current && !resourcesProcessed.current) {
      const resources = await getResources(productType, responseMapper);
      if (resources?.length) {
        resources.forEach((resource) => generatePrefetchLinks(productType, resource));
        resourcesProcessed.current = true;
      }
    }
  }, [productType, responseMapper]);

  return { prefetch };
};
