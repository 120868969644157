import { DateFieldData, LodgingFormEvent } from "@shared-ui/lodging-form";
import { useLocalization } from "@shared-ui/localization-context";

export const useDateFieldData = (isVrbo = false): DateFieldData => {
  const { formatText } = useLocalization();
  return {
    startId: "hotels-check-in",
    startLabel: formatText("datePicker.fromDate.label"),
    startName: "startDate",
    startPlaceholder: formatText("datePicker.dateInput.placeholder"),
    startInvalid: "",
    endId: "hotels-check-out",
    endLabel: formatText("datePicker.toDate.label"),
    endName: "endDate",
    endPlaceholder: formatText("datePicker.dateInput.placeholder"),
    endInvalid: "",
    buttonText: formatText("datePicker.doneHeader"),
    daysBookableInAdvance: 500,
    midnightBookingOffset: 4,
    __typename: "LodgingFormDateField",
    validations: getValidations(isVrbo, formatText),
  };
};

export const getValidations = (isVrbo: boolean, formatText: (key: string, ...args: any[]) => string) => {
  const validationList: DateFieldData["validations"] = [
    {
      __typename: "StartDateRequiredValidation",
      error: formatText("wizard.datePicker.warning.invalidFromDate.text"),
      events: [LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    },
    {
      __typename: "EndDateRequiredValidation",
      error: formatText("wizard.datePicker.warning.invalidToDate.text"),
      events: [LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    },
    {
      __typename: "DateOrderValidation",
      error: formatText("wizard.datePicker.warning.invalidToDate.text"),
      events: [LodgingFormEvent.ON_LOAD, LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    },
  ];

  if (!isVrbo) {
    const maxDateRangeValidation: any = {
      __typename: "MaxDateRangeValidation",
      error: formatText("wizard.datePicker.warning.dateRangeOverLimit"),
      maxDateRange: 28,
      events: [LodgingFormEvent.ON_LOAD, LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    };
    const newValidationsList: DateFieldData["validations"] = [...validationList, maxDateRangeValidation];
    return newValidationsList;
  }

  return validationList;
};
