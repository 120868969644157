import { useLocation } from "react-router-dom";

export const usePreviewAndRequestDateInputs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const previewParam = queryParams.get("preview") === "true";
  const requestDateParam = queryParams.get("requestDate");

  return {
    previewParam,
    requestDateParam,
  };
};
