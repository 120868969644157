import * as React from "react";
import {
  CustomerNotification as InlineNotification,
  CustomerNotificationLocation as InlineNotificationLocation,
} from "@shared-ui/customer-notifications";
import { PageStore } from "bernie-plugin-mobx";
import { inject } from "mobx-react";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { isVariantEnabled } from "src/components/utility/experiment";
import { UitkSpacing } from "uitk-react-spacing";

interface TravelAdvisoryProps {
  context: ExtendedContextStore;
  page?: PageStore;
}

export const TravelAdvisory: React.FC<TravelAdvisoryProps> = inject("page")((props: TravelAdvisoryProps) => {
  const { context, page } = props;
  const isTravelAdvisoryExperimentEnabled = isVariantEnabled(context, "LP_SC_Covid19_TravelAdvisory_for_LandingPages");

  if (!page || !page.pageId || !context || !isTravelAdvisoryExperimentEnabled) {
    return null;
  }

  let regionId = context.searchContext?.location?.id?.toString() || "";

  if (context.searchContext?.location?.type === "hotel") {
    regionId = context.searchContext?.location?.parent.id?.toString() || "";
  }

  return (
    <div className="travelAdvisoryBanner">
      <UitkSpacing padding={{ block: "three" }}>
        <InlineNotification
          inputs={{
            xPageId: page.pageId,
            notificationLocation: InlineNotificationLocation.AFTER_HEADER,
            optionalContext: {
              journeyCriterias: [
                {
                  destination: {
                    regionId: regionId,
                  },
                },
              ],
            },
          }}
        />
      </UitkSpacing>
    </div>
  );
});

export default TravelAdvisory;
