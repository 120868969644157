import * as React from "react";
import { Breadcrumbs as SharedUIBreadcrumbs } from "@shared-ui/retail-global-navigation-breadcrumbs";
import { observer, inject } from "mobx-react";
import { BreadcrumbsProps } from "./typings";

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { uriContext: uriContextStore } = props;

  const uriContext = uriContextStore?.get();
  if (!uriContext) return null;

  return <SharedUIBreadcrumbs inputs={{ uriContext }} />;
};

export const BreadcrumbsModule = inject("uriContext")(observer(Breadcrumbs));

export default BreadcrumbsModule;
