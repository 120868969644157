import { createContext } from "react";

export enum BackgroundColorTokens {
  BACKGROUND_WHITE = "pageWhiteBackground",
  BACKGROUND_NEUTRAL_1_50 = "pageNeutral150Background",
  BACKGROUND_DEFAULT = "",
}

export enum PageTypesForBackground {
  HOMEPAGE = "Homepage,U,10",
  FLIGHT_LP = "Flights,F,10",
  HOTEL_LP = "Hotels,H,10",
  PACKAGE_LP = "Packages,P,10",
  CARS_LP = "Cars,C,10",
  CRUISES_LP = "Cruises,CR,10",
  ACTIVITIES_LP = "Activities,A,10",
  APP_LP = "App,U,10",
}

export const PageBackgroundContext = createContext(BackgroundColorTokens.BACKGROUND_DEFAULT);
