import * as React from "react";
import { useExperiment } from "@shared-ui/experiment-context";
import { Field } from "uitk-react-form";
import { PrefetchProductType, usePrefetchAssets } from "components/utility/usePrefetchAssets";

export const useLodgingSrpPrefetchAssetsExperiment = () => {
  const { exposure, logExperiment } = useExperiment("Prefetching_Lodging_SRP_javascript_bundles_from_homepage");
  const { prefetch } = usePrefetchAssets(PrefetchProductType.LODGING_SRP, (response) => response.srp);

  const prefetchLodgingSrpAssets = React.useCallback(
    (field: Field) => {
      const isDestinationFieldChanged = field?.name === "destination_form_field";
      if (isDestinationFieldChanged) {
        try {
          logExperiment(exposure);
        } catch (e) {
          // noop
        }
        if (exposure.bucket === 1) {
          prefetch();
        }
      }
    },
    [logExperiment, prefetch, exposure]
  );

  return { prefetchLodgingSrpAssets };
};
