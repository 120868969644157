import { Logger, NOOP_LOGGER } from "bernie-logger";

/**
 * Utility that checks if we are in the client-side or server-side and only logs if we are server-side
 * @param {Logger} logger
 * @returns {Logger}
 */
export const ServerSideLogger = (logger: Logger) => {
  return typeof window !== "undefined" && window ? NOOP_LOGGER : logger;
};
