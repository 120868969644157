import * as React from "react";
import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";

/**
 * How many digits to keep for the lat/lon numbers. 5 digits is more than enough for our use case:
 * https://en.wikipedia.org/wiki/Decimal_degrees#Precision
 */
const PRECISION = 5;
const LAT = "lat";
const LON = "lon";
const GEOLOCATION_OPTIONS = {
  timeout: 30000,
  maximumAge: 0,
};

/**
 * Hook which calls the browser's geolocation API and reloads the page with new lat/lon param.
 * Only happens if the lat/lon is not already present in the URL, askForGeoLocation is set to true
 */
export const useGeoLocationReload = (logger: ClientLogger) => {
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(LAT) && !searchParams.has(LON)) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          searchParams.set(LAT, position.coords.latitude.toFixed(PRECISION));
          searchParams.set(LON, position.coords.longitude.toFixed(PRECISION));
          window.location.search = searchParams.toString();
        },
        (error) =>
          logger.logEvent(
            new SystemEvent(SystemEventLevel.ERROR, "NAVIGATOR_GEOLOCATION_GET_CURRENT_POSITION_ERROR"),
            error
          ),
        GEOLOCATION_OPTIONS
      );
    }
  }, []);
};
