import * as React from "react";

export interface AdaptiveMenuDialogTriggerProps {
  trigger?: any;
  onClick?: (e?: any, eventData?: any) => void;
}

export const AdaptiveMenuDialogTrigger: React.FC<AdaptiveMenuDialogTriggerProps> = (props) => {
  return React.cloneElement(props.trigger, { onClick: props.onClick });
};
