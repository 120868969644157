import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "uitk-react-layout-grid";
import { UitkBanner } from "uitk-react-banner";
import { UitkText } from "uitk-react-text";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkLink } from "uitk-react-link";

export interface LobErrorSummaryProps {
  /**
   * Localization token for the heading
   */
  locHeadingToken: string;
  /**
   * Localization arguments for the heading
   */
  locHeadingArgs?: any;
  /**
   * Spaces in the grid for the component
   */
  colSpan?: UitkLayoutConditionalGridSpan;
  /**
   * Localization token for description
   */
  locDescriptionToken?: string;
  /**
   * css classes for the component
   */
  className?: string;
  /**
   * Localization token for link
   */
  locLinkToken?: string;
  /**
   * Link's Id for focus
   */
  linkClickFocusId?: string;
  /**
   * Reference for the link
   */
  inputErrorRef?: React.RefObject<HTMLHeadingElement> | null;
  setInputsRefs?: (ref: React.RefObject<HTMLHeadingElement>) => void;

  /**
   * Sends the focus on the heading element in case of an error (default is true).
   */
  focusOnError?: boolean;
}

/**
 * Component for showing error summary when needed.
 *
 * @component
 * @example
 * const headingToken = "example.heading.token"
 * const descriptionToken = 'example.description.token'
 * const UitkLayoutConditionalGridSpan = { small: 1 as UitkLayoutGridColumnRow, medium: 6 as UitkLayoutGridColumnRow };
 * return (
 *   <LobErrorSummary
 *    locHeadingToken={headingToken}
 *    locDescriptionToken={descriptionToken}
 *    colSpan={UitkLayoutConditionalGridSpan}
 *   />
 * )
 */

export const LobErrorSummary = (props: LobErrorSummaryProps) => {
  const { formatText } = useLocalization();
  const [focusElement] = React.useState(React.createRef<HTMLDivElement>());

  const {
    locHeadingToken,
    locDescriptionToken,
    colSpan,
    className,
    locHeadingArgs,
    locLinkToken,
    inputErrorRef,
    linkClickFocusId,
    setInputsRefs,
    focusOnError,
  } = props;

  const setFocusOnError = focusOnError ?? true;

  React.useEffect(() => {
    if (setInputsRefs) {
      setInputsRefs(focusElement);
    }

    if (setFocusOnError) {
      focusElement?.current?.focus();
    }
  }, []);

  function onLinkClick(e: React.MouseEvent) {
    e.preventDefault();

    if (inputErrorRef?.current) {
      inputErrorRef.current.focus();
    }
  }

  const heading = locHeadingArgs ? formatText(locHeadingToken, ...locHeadingArgs) : formatText(locHeadingToken, 1);

  return (
    <UitkLayoutGridItem colSpan={colSpan} className={className}>
      <div data-testid="lob-error-summary" role="alert" ref={focusElement} tabIndex={-1}>
        <UitkBanner theme="error">
          <UitkText>{heading}</UitkText>
          {locDescriptionToken && (
            <UitkSpacing margin={{ blockstart: "half" }}>
              <UitkText>{formatText(locDescriptionToken)}</UitkText>
            </UitkSpacing>
          )}
          {locLinkToken && (
            <UitkSpacing margin={{ blockstart: "two" }}>
              <UitkLink>
                <a href={`#${linkClickFocusId}`} onClick={onLinkClick}>
                  {formatText(locLinkToken)}
                </a>
              </UitkLink>
            </UitkSpacing>
          )}
        </UitkBanner>
      </div>
    </UitkLayoutGridItem>
  );
};
