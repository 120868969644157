import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkExpandoPeek } from "uitk-react-expando";
import { AnalyticsConfig } from "uitk-react-utils";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkHeading } from "uitk-react-text";

import { withStores } from "stores";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

import { DestinationDescriptionProps } from "./typings";
import { DestinationDescriptionFlexModuleResult } from "typings/microserviceModels/destination-description-flex-module";
import { observer } from "mobx-react";

// These values are a starting point for mobile and desktop,
// and are pulled from the new Figma design.
export const SMALL_COLLAPSE_HEIGHT = 7;
export const MEDIUM_COLLAPSE_HEIGHT = 15;

const DestinationDescription = withStores(
  "analytics",
  "context",
  "flexModuleModelStore"
)(
  observer((props: DestinationDescriptionProps) => {
    const { templateComponent, flexModuleModelStore, getDescriptionBody } = props;
    const { metadata, config } = templateComponent;
    const model = flexModuleModelStore.getModel(metadata.id) as DestinationDescriptionFlexModuleResult | null;
    const [isExpandoPeekVisible, setIsExpandoPeekVisible] = React.useState(false);

    if (!model) {
      return null;
    }

    const { content } = model;
    const { formatText } = useLocalization();

    // This is covered in e2e test
    /* istanbul ignore next */
    const analytics: AnalyticsConfig = {
      id: "TG.LP.Dest.Description",
      callback: (moduleId) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: moduleId,
          action: Action.CLICK,
        };
        sendDelayedTrackEvent(flexTrackingInfo, props.analytics);
      },
    };

    const { teaser, body } = content;

    const teaserText = Boolean(teaser?.length) && (
      <UitkSpacing padding={{ blockend: "three" }}>
        <UitkHeading tag="h2" size={6}>
          {teaser.join("")}
        </UitkHeading>
      </UitkSpacing>
    );

    const descriptionText = (thresholdHeight: number) =>
      Boolean(body?.length) && (
        <UitkExpandoPeek
          lines={thresholdHeight}
          items={thresholdHeight}
          collapseLabel={formatText("read.less.label")}
          expandLabel={formatText("read.more.label")}
          analytics={analytics}
          isVisible={isExpandoPeekVisible}
          handleClick={
            /* remove ignore when react-testing-library and jsdom are updated */
            /* istanbul ignore next */ (): void => setIsExpandoPeekVisible((prevIsVisible) => !prevIsVisible)
          }
        >
          <>{getDescriptionBody(body)}</>
        </UitkExpandoPeek>
      );

    const descriptionView = (thresholdHeight: number) => (
      <article>
        {teaserText}
        {descriptionText(thresholdHeight)}
      </article>
    );

    return (
      <div id={metadata.id} data-fm={config.fmId} className="DestinationDescription">
        <Viewport>
          <ViewSmall>{descriptionView(SMALL_COLLAPSE_HEIGHT)}</ViewSmall>
          <ViewMedium>{descriptionView(MEDIUM_COLLAPSE_HEIGHT)}</ViewMedium>
        </Viewport>
      </div>
    );
  })
);

export default DestinationDescription;
