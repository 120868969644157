import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export enum AffinityConfig {
  HSR_FILTER_CODE = "hsrFilterCode",
  HSR_FILTERS = "hsrFilters",
  BEX_API_FILTERS = "bexApiFilters",
}

export const getStarRatingNumbersFallback = (starRatingCode: string) => {
  const possibleScores = ["1", "2", "3", "4", "5"];
  const score = !!starRatingCode && typeof starRatingCode === "string" ? starRatingCode.replace(/[^0-9]/g, "") : "";
  if (possibleScores.includes(score)) {
    return +score;
  }
  return undefined;
};

const starRatingMap: any = {
  "1 Star": 1,
  "2 Star": 2,
  "3 Star": 3,
  "4 Star": 4,
  "5 Star": 5,
  "Property class 1": 1,
  "Property class 2": 2,
  "Property class 3": 3,
  "Property class 4": 4,
  "Property class 5": 5,
};

export function getStarRating(flexContext: ExtendedContextStore): number | undefined {
  const starRatingCode: string | undefined =
    flexContext &&
    flexContext.searchContext &&
    flexContext.searchContext.starRating &&
    flexContext.searchContext.starRating.code;
  return starRatingMap[starRatingCode] || getStarRatingNumbersFallback(starRatingCode);
}

export function getHSRStarRatingCode(flexContext: ExtendedContextStore): number | undefined {
  const starRating = getStarRating(flexContext);

  return starRating && starRating * 10; // HSR accepts 10, 20, 30, 40, 50.
}

export function getAffinityAttribute(flexContext: ExtendedContextStore, key: string) {
  const affinity: any = (flexContext && flexContext.searchContext && flexContext.searchContext.affinity) || {};

  return affinity[key];
}

export function getHSRFilterCode(flexContext: ExtendedContextStore): string | undefined {
  return getAffinityAttribute(flexContext, AffinityConfig.HSR_FILTER_CODE);
}

export function getHSRFilters(flexContext: ExtendedContextStore): { [key: string]: string } | undefined {
  return getAffinityAttribute(flexContext, AffinityConfig.HSR_FILTERS);
}

export function getBEXApiFilters(flexContext: ExtendedContextStore): { [key: string]: string } | undefined {
  return getAffinityAttribute(flexContext, AffinityConfig.BEX_API_FILTERS);
}
export function getPropertyTypeCode(context: ExtendedContextStore): string | undefined {
  const srpPropertyTypeCode = context?.searchContext?.alternativeAccommodation?.lodgingUrlCode;

  if (!srpPropertyTypeCode) {
    return undefined;
  }

  const bundlePropertyTypesFilters = ["APARTMENT", "APART_HOTEL", "CONDO", "VACATION_HOME", "CONDO_RESORT"];
  const bundlePropertyTypesTargets = ["APARTMENT", "APART_HOTEL", "VILLA", "CONDO", "VACATION_HOME", "CONDO_RESORT"];
  if (bundlePropertyTypesFilters.includes(srpPropertyTypeCode)) {
    return bundlePropertyTypesTargets.join(",");
  }

  return srpPropertyTypeCode;
}
