// Utils
import {
  Action,
  FlexTrackingInfo,
  sendDelayedTrackEvent,
  sendImmediateTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

// Types
import { ModuleMapProps } from "../typings";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { isVariantEnabled } from "components/utility/experiment";

type DialogActions = {
  openDialog: () => void;
  closeDialog: () => void;
  toggleDialog: () => void;
};

/**
 * Custom hook to control the fullscreen map dialog behavior
 * @param props
 */
export const useFullscreenOpen = (props: ModuleMapProps, isDialogOpen?: boolean, dialogActions?: DialogActions) => {
  const { templateComponent, analytics, context } = props;

  const sendUserToSRP = () => {
    const flexTrackingInfo: FlexTrackingInfo = {
      moduleName: templateComponent.type || "",
      rfrr: "toSRP",
      action: Action.CLICK,
    };

    sendImmediateTrackEvent(flexTrackingInfo, analytics);
    const regionId = context?.searchContext?.location.id;
    const langId = context?.langId;

    if (regionId && langId) {
      const linkBuilder = new HotelSearchLinkBuilder()
        .withRegionId(regionId)
        .withLangId(langId.toString(10))
        .withStayLength()
        .withDaysInFuture()
        .withMapOpen();

      if (isVariantEnabled(context, "Blossom_SRP_Lite_V2")) {
        linkBuilder.withExpandForm();
      }

      window.location.assign(linkBuilder.build());
    }
  };

  const toggleShowFullscreenMap = () => {
    const flexTrackingInfo: FlexTrackingInfo = {
      moduleName: templateComponent.type || "",
      rfrr: isDialogOpen ? "close" : undefined,
      action: Action.CLICK,
    };

    sendDelayedTrackEvent(flexTrackingInfo, analytics);

    dialogActions?.toggleDialog();
  };

  return {
    toggleShowFullscreenMap,
    sendUserToSRP,
  };
};

export default useFullscreenOpen;
