import { createContext } from "react";

export enum StorefrontWizardRegionTypes {
  STOREFRONT_WIZARD_REGION_DEFAULT,
  STOREFRONT_WIZARD_REGION_BEX,
  STOREFRONT_WIZARD_REGION_COMET,
  STOREFRONT_WIZARD_REGION_TAAP,
}

export const StorefrontWizardRegionTypesContext = createContext(
  StorefrontWizardRegionTypes.STOREFRONT_WIZARD_REGION_DEFAULT
);
