import * as React from "react";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { getRatingText } from "src/components/utility/Reviews";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkText } from "uitk-react-text";

/**
 * A component for displaying the average rating and total rating count
 */

const AverageRating = ({ rating, language }: { rating: number; language?: string[] }) => (
  <UitkSpacing padding={{ inlineend: "one" }}>
    <UitkText size={300} inline>
      {[rating.toLocaleString(language, { maximumFractionDigits: 1 })]}
      /5
    </UitkText>
  </UitkSpacing>
);

const RatingTextAndRatingCount = ({
  language,
  localizationKey,
  reviewCount,
}: {
  reviewCount: number;
  language?: string[];
  localizationKey?: string;
}) => {
  const getL10nMessage = (key?: string) => {
    const reviewsAmount = reviewCount.toLocaleString(language, { maximumFractionDigits: 1 });
    const locKey = `${key}.reviewCount`;
    const defaultLocKey = "hotels.review.reviewCount";
    const { formatText } = useLocalization();

    return formatText(locKey, reviewCount, reviewsAmount) || formatText(defaultLocKey, reviewCount, reviewsAmount);
  };

  const ratingText = getL10nMessage(localizationKey);

  return (
    <UitkText size={300} inline>
      ({ratingText})
    </UitkText>
  );
};

export const AggregateRating = ({
  context,
  rating,
  reviewCount,
  localizationKey,
  withSuperlative,
}: {
  context?: ExtendedContextStore;
  rating?: number;
  reviewCount?: number;
  localizationKey?: string;
  withSuperlative?: boolean;
}) => {
  if (!rating || !reviewCount || !context) {
    return null;
  }

  const language = context.locale ? context.locale.split(/[_-]/) : undefined;

  return (
    <UitkSpacing margin={{ blockstart: "unset" }}>
      <div className="AggregateRating">
        <AverageRating rating={rating} language={language} />
        {withSuperlative && (
          <UitkSpacing padding={{ inlineend: "one" }}>
            <UitkText size={300} inline>
              {getRatingText(rating)}
            </UitkText>
          </UitkSpacing>
        )}
        {language && (
          <RatingTextAndRatingCount language={language} reviewCount={reviewCount} localizationKey={localizationKey} />
        )}
      </div>
    </UitkSpacing>
  );
};
