import { cropResizeSrc } from "components/shared/BlossomImage/BlossomImage";

export const optimizeHeroImageUrl = (
  imageUrl: string,
  isMobile?: boolean,
  isVrbo?: boolean,
  shouldImproveImage?: boolean
): string => {
  return isMobile
    ? cropResizeSrc(
        imageUrl,
        shouldImproveImage && !isVrbo ? { width: 450, height: 280 } : { width: 360, height: 224 },
        "medium",
        false
      )
    : isVrbo
    ? cropResizeSrc(imageUrl, { width: 1920, height: 580 }, "medium", false)
    : cropResizeSrc(
        imageUrl,
        shouldImproveImage ? { width: 1920, height: 480 } : { width: 1040, height: 580 },
        shouldImproveImage ? "medium" : "high",
        false
      );
};

export const optimizeHotwireHeroImageUrl = (imageUrl: string, isMobile?: boolean): string => {
  return isMobile
    ? cropResizeSrc(imageUrl, { width: 575, height: 431 }, "medium")
    : cropResizeSrc(imageUrl, { width: 1176, height: 504 }, "medium");
};

export const imgDimension = (imgHeight: string): any => {
  switch (imgHeight) {
    case "usf-mobile-image-height": {
      return "13.125rem";
    }
    default: {
      return undefined;
    }
  }
};
