import * as React from "react";
import { Media, License } from "typings/utility/media";
import { AttributionText, getAttributionAltText } from "components/shared/AttributionText/AttributionText";

export interface RenderAttributionTextAttr {
  mainText: string;
  altText: string;
  alternateView?: boolean;
  attribUrl?: string;
}
export type RenderAttributionTextHTML = (attr: RenderAttributionTextAttr) => JSX.Element;

export function MediaAttribution(media: Media, name: string, overrideRender?: RenderAttributionTextHTML) {
  const imageAttribution: License = media.license;
  const authorName: string = media.author;

  const authorUrl: string = media.authorUrl;
  const source: string = media.source;
  const origin: string = media.origin;

  const licenseUrl: string = imageAttribution?.url || (authorUrl && authorUrl);
  const imageAlt: string = media.mediaAlt?.length > 0 ? media.mediaAlt : "";
  const attributionAltText: string = getAttributionAltText(
    source,
    origin,
    name,
    imageAlt,
    authorName,
    imageAttribution
  );
  const attributionText = (
    <AttributionText
      source={source}
      origin={origin}
      attribUrl={media.mediaUrlOriginal}
      authorName={authorName}
      authorUrl={authorUrl}
      licenseSuite={imageAttribution?.suite}
      licenseName={imageAttribution?.name}
      licenseUrl={licenseUrl}
      overrideRender={overrideRender}
    />
  );

  return {
    attributionAltText,
    attributionText,
  };
}
