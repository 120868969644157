import * as React from "react";

interface PropertyFiltersInteractiveContextType {
  apply: (() => void) | null;
}

export const PropertyFiltersInteractiveContext = React.createContext<PropertyFiltersInteractiveContextType | null>(
  null
);

export const useInteractiveFilters = () => {
  const context = React.useContext(PropertyFiltersInteractiveContext);

  if (!context) {
    return null;
  }

  return context.apply;
};

export default PropertyFiltersInteractiveContext;
