import * as React from "react";
import { TabContentsProps } from "./typings";
import { withStores } from "stores";
import { RegionChildren } from "src/utils/RegionUtils";

export const TabContents = withStores("context")(({ templateComponent, blossomComponent }: TabContentsProps) => {
  /* istanbul ignore next */
  if (templateComponent?.children && blossomComponent) {
    return <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />;
  }

  /* istanbul ignore next */
  return null;
});

export default TabContents;
