import { getAttributionAltText } from "components/shared/AttributionText/AttributionText";
import { Image } from "typings/microserviceModels/destination-where-to-stay-flex-module";
import { cropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";

export const dxOptimizedImage = (image: Image, name: string, width: number, height: number) => {
  const coppedToSizeImageUrl = cropResizeSrc(image.mediaUrl, { width, height }, "medium");

  return {
    alt: getAttributionAltText(
      image.source,
      image.origin,
      name,
      image.mediaAlt,
      image.attribution && image.attribution.author && image.attribution.author.name,
      image.attribution && image.attribution.license && image.attribution.license,
      image.mediaUrl
    ),
    mediaUrl: coppedToSizeImageUrl,
    mediaUrlTrimmed: image.mediaUrl,
    originalMediaUrl: image.mediaUrl,
    src: coppedToSizeImageUrl,
    source: image.source,
    origin: image.origin,
    caption: image.mediaAlt,
    thumbnailSrc: coppedToSizeImageUrl,
    attribUrl: image.attribution && image.attribution.license.url,
    authorName: image.attribution && image.attribution.author.name,
    authorUrl: image.attribution && image.attribution.author.url,
    licenseSuite: image.attribution && image.attribution.license.suite,
    licenseName: image.attribution && image.attribution.license.name,
    licenseUrl: image.attribution && image.attribution.license.url,
  };
};
