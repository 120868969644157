import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { ExtendedTemplateComponent } from "stores/ExperienceTemplateStore/typings";

export const mapAnalyticsData = (
  context: ExtendedContextStore,
  templateComponent?: ExtendedTemplateComponent
): string => {
  const analyticsData = {
    point_of_sale: {
      eg_pos_id: context.site.pointOfSaleId,
      locale: context.locale,
    },
    user: {
      authentication_state: context.user.authState,
    },
    additional_context: {
      user_interface: {
        component_name: templateComponent?.type,
        component_element_text: "",
        component_position: templateComponent?.metadata.id.slice(-1),
        component_id: templateComponent?.config.fmId,
      },
    },
    uis_prime_site: {
      uis_prime_tpid: context.site.tpid.toString(),
      uis_prime_eapid: context.site.eapid.toString(),
    },
  };

  return JSON.stringify(analyticsData);
};
