//
// * Utility functions that handle cookies, refactored from:
// * https://plainjs.com/javascript/utilities/set cookie get cookie and delete cookie
//

/**
 * Sets/Updates a given key with a value in a cookie
 * @param key {string}   Name of the key to set
 * @param value {string}   Value of the key in the cookie to set
 * @param days {number}   Number of days that the key should stay
 */

export const setCookie = ({ key, value, days = 1 }: { key: string; value: string; days?: number }) => {
  const date = new Date();
  // 86400000 = number of seconds in a day
  date.setTime(date.getTime() + 86400000 * days);
  document.cookie = `${key}=${encodeURIComponent(value)};path=/;expires=${date.toUTCString()}`;
};

/**
 * Gets a given name in a cookie
 * @param cname {string}   cookie name
 */
export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const decodedParts = decodedCookie.split(";");
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < decodedParts.length; i++) {
    let decodedPart = decodedParts[i];
    while (decodedPart.charAt(0) === " ") {
      decodedPart = decodedPart.substring(1);
    }
    if (decodedPart.indexOf(name) === 0) {
      return decodedPart.substring(name.length, decodedPart.length);
    }
  }

  return undefined;
};
