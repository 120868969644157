import * as React from "react";

interface SchemaOrgScriptProps {
  structuredData: string | undefined;
}

export const SchemaOrgScript = (props: SchemaOrgScriptProps) => {
  if (!props.structuredData) {
    return null;
  }

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: props.structuredData }} />;
};
