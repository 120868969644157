import * as React from "react";

/**
 * Custom hook to initialize ONCE specific data of a component. Replaces constructor of class based components.
 */
export const useInitializer = (callBack: () => void) => {
  const hasBeenCalled = React.useRef(false);

  if (hasBeenCalled.current) return;

  callBack();

  hasBeenCalled.current = true;
};
