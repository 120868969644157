import * as React from "react";
import { useExperiment } from "@shared-ui/experiment-context";

export const useLodgingSearchExperiment = (hideFlexibleDates: boolean = false) => {
  const { exposure: extendBookingWindowVrbo, logExperiment: logExtendBookingWindowVrbo } = useExperiment(
    "Vrbo_SearchDates_LeadTimeExtension24Mo"
  );

  if (hideFlexibleDates) {
    const onOpenDatesField = React.useCallback(() => {
      try {
        logExtendBookingWindowVrbo(extendBookingWindowVrbo);
      } catch {
        // noop
      }
    }, [extendBookingWindowVrbo, logExtendBookingWindowVrbo]);

    return {
      onOpenDatesField,
    };
  }

  const { exposure: flexDateContentSearchExposure, logExperiment: logFlexDateContentSearchExposure } = useExperiment(
    "Flex_Date_M2_duration_month_search_HcomBex_web"
  );

  const {
    exposure: flexDateContentSearchExposureVrbo,
    logExperiment: logFlexDateContentSearchExposureVrbo,
  } = useExperiment("Flex_Date_M2_duration_month_v2_Vrbo_web");

  const includeFlexibleCalendarContent = true;

  const includeFlexibleDatesContent =
    flexDateContentSearchExposure.bucket > 0 || flexDateContentSearchExposureVrbo.bucket > 0;

  const onOpenDatesField = React.useCallback(() => {
    try {
      logFlexDateContentSearchExposure(flexDateContentSearchExposure);
    } catch {
      // noop
    }

    try {
      logExtendBookingWindowVrbo(extendBookingWindowVrbo);
    } catch {
      // noop
    }

    try {
      logFlexDateContentSearchExposureVrbo(flexDateContentSearchExposureVrbo);
    } catch {
      // noop
    }
  }, [
    logFlexDateContentSearchExposure,
    flexDateContentSearchExposure,
    extendBookingWindowVrbo,
    logExtendBookingWindowVrbo,
  ]);

  return {
    includeFlexibleCalendarContent,
    includeFlexibleDatesContent,
    onOpenDatesField,
  };
};
