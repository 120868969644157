import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { PropertyFilteredReviews } from "@shared-ui/lodging-reviews";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

import { UitkSheet, UitkSheetContent } from "uitk-react-sheet";
import { UitkToolbar, UitkToolbarProps } from "uitk-react-toolbar";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

const WrappedPropertyFilteredReviews = PropertyBexApiWrapper(PropertyFilteredReviews);

export const PropertyFilteredReviewsDialog: React.FC<
  ComponentProps & { onClose: () => void; triggerRef?: React.RefObject<HTMLElement> }
> = ({ context, onClose, triggerRef = null }) => {
  const { formatText } = useLocalization();

  const toolbarProps: UitkToolbarProps = {
    toolbarTitle: formatText("reviews.dialog.title"),
    navigationContent: {
      navIconLabel: formatText("reviews.dialog.close.a11yLabel"),
      onClick: onClose,
    },
  };

  const sheet = (sheetType: "full" | "centered", dialogHeight?: number) => (
    <UitkSheet type={sheetType} height={dialogHeight} isVisible triggerRef={triggerRef} returnFocusOnClose>
      <UitkToolbar {...toolbarProps} />
      <UitkSheetContent fullSheetContentMaxWidth="extra-large">
        <WrappedPropertyFilteredReviews context={context} />
      </UitkSheetContent>
    </UitkSheet>
  );

  return (
    <Viewport>
      <ViewSmall>{sheet("full")}</ViewSmall>
      <ViewMedium>{sheet("centered", 1200)}</ViewMedium>
    </Viewport>
  );
};
