// @ts-nocheck
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AnalyticsPayload: any;
  BigDecimal: any;
  Locale: any;
  URL: any;
  UUID: any;
};

export type AbandonedCheckout = {
  __typename?: "AbandonedCheckout";
  card: HomeCard;
  heading: EgdsHeading;
};

export type AbandonedCheckoutDiscardStatus = {
  __typename?: "AbandonedCheckoutDiscardStatus";
  isSuccessful: Scalars["Boolean"];
};

export interface AccessibilityData {
  hint?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
}

export interface AccessibilityField {
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
}

export type ActionCheckBox = {
  __typename?: "ActionCheckBox";
  analytics: ClientSideAnalytics;
  checkBox: EgdsBasicCheckBox;
};

export type ActionDetails = AccessibilityField & {
  __typename?: "ActionDetails";
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.action.accessibility instead. */
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. */
  action: Scalars["String"];
  /** @deprecated Do not use. */
  details?: Maybe<Scalars["String"]>;
  /** @deprecated Use accessibilityLabel instead, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  /** @deprecated Use action field instead, enums are discouraged. */
  name: ActionDetailsType;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.text instead. */
  title?: Maybe<Scalars["String"]>;
};

export type ActionDetailsType =
  | "AUTHWINDOW"
  | "CLOSE"
  | "COPY"
  | "DATEPICKER"
  | "EXTERNAL_LINK"
  | "FREE_LX"
  | "OPEN"
  | "TERMS_AND_CONDITIONS";

export type ActionHistory = {
  __typename?: "ActionHistory";
  campaignId: Scalars["String"];
  entryPoints?: Maybe<EntryData>;
  expUserId: Scalars["String"];
  sharePoints?: Maybe<EntryData>;
  total: Scalars["Int"];
  triviaPoints?: Maybe<Array<EntryData>>;
};

export type ActionLink = {
  __typename?: "ActionLink";
  action: ActionType;
  analytics: ClientSideAnalytics;
  text: Scalars["String"];
};

export type ActionType = "DownloadDPReport" | "JUMPLINK" | "MODAL" | "Open" | "OpenPriceChangeAlert" | "REDIRECT";

export type ActiveState = "ACTIVE" | "INACTIVE" | "UNKNOWN" | "USED";

export type ActivitiesByCategoriesModule = {
  __typename?: "ActivitiesByCategoriesModule";
  action?: Maybe<ActivityGroupAction>;
  groups: Array<ActivityGroup>;
};

export interface ActivitiySearchCriteriaInput {
  primary: PrimaryActivitySearchCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type Activity = {
  __typename?: "Activity";
  activityDuration?: Maybe<EgdsText>;
  /** @deprecated use clientSideAnalytics */
  analytics?: Maybe<ActivityAnalytics>;
  /** @deprecated Use cardBadges instead */
  badges?: Maybe<ActivityTileBadges>;
  cardBadges?: Maybe<ActivityCardBadges>;
  categoryDetails?: Maybe<Array<Maybe<ActivityCategoryDetails>>>;
  clickAction?: Maybe<ActivityAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  description?: Maybe<Scalars["String"]>;
  detailsLink?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated Use activityDuration instead */
  duration: ActivityDuration;
  enabled: Scalars["Boolean"];
  features: ActivitySearchFeature;
  /** @deprecated should use features instead */
  freeCancel: Scalars["Boolean"];
  /** @deprecated Use imageCarousel instead */
  gallery?: Maybe<ActivityGallery>;
  id: Scalars["String"];
  /** @deprecated Use imageCarousel instead */
  image?: Maybe<Image>;
  imageCarousel?: Maybe<EgdsCarousel>;
  /** @deprecated Use leadTicketPrice instead */
  leadTicket: ActivitySearchTicket;
  leadTicketPrice: ActivityPriceDisplay;
  link?: Maybe<Scalars["String"]>;
  location?: Maybe<Coordinates>;
  /** @deprecated Use primary instead */
  name: Scalars["String"];
  /** @deprecated We send badges instead directly now */
  priceMetadata?: Maybe<ActivityPriceMetadata>;
  primary: EgdsText;
  redemption?: Maybe<ActivityRedemption>;
  reviewSummary?: Maybe<ActivityReviewsSummary>;
  saveItem?: Maybe<TripsSaveItem>;
  secondaryMessage?: Maybe<Scalars["String"]>;
  sectionRef?: Maybe<Scalars["String"]>;
  /** @deprecated use TripsSaveItem */
  shortlistingMessages?: Maybe<ActivityShortlistingMessages>;
  supplierName?: Maybe<Scalars["String"]>;
  thirdPartyPartnerName?: Maybe<Scalars["String"]>;
  tripUpdateButtons?: Maybe<ActivityTripUpdateButtons>;
  /** @deprecated should use clickAction instead */
  url?: Maybe<Scalars["URL"]>;
};

export type ActivityAboveTheFold = {
  __typename?: "ActivityAboveTheFold";
  banners?: Maybe<Array<ActivityBanner>>;
  breadcrumbs: Array<ActivityBreadcrumb>;
  pills?: Maybe<Array<ActivityPill>>;
};

export interface ActivityAccessibility {
  label?: Maybe<Scalars["String"]>;
}

export type ActivityAction = {
  __typename?: "ActivityAction";
  accessibilityText: Scalars["String"];
  /** @deprecated use clickanalytics */
  analytics?: Maybe<ActivityAnalytics>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  openInNewTab: Scalars["Boolean"];
  uri?: Maybe<HttpUri>;
  url: Scalars["String"];
};

export type ActivityActionCallbackMessages = {
  __typename?: "ActivityActionCallbackMessages";
  failure: Scalars["String"];
  success: Scalars["String"];
};

export type ActivityActionTile = {
  __typename?: "ActivityActionTile";
  /** @deprecated use clientSideAnalytics */
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  enabled: Scalars["Boolean"];
  image?: Maybe<Image>;
  link?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  primary: EgdsText;
  primaryAction: ActivityAction;
  secondaryMessage?: Maybe<Scalars["String"]>;
};

export type ActivityAddOfferToTripAction = UiAction & {
  __typename?: "ActivityAddOfferToTripAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  offerNaturalKey: ActivityOfferNaturalKey;
};

export type ActivityAnalytics = {
  __typename?: "ActivityAnalytics";
  linkName: Scalars["String"];
  microMessagePayload?: Maybe<Scalars["String"]>;
  referrerId: Scalars["String"];
};

export type ActivityAnchoredMessage = {
  __typename?: "ActivityAnchoredMessage";
  accessibilityText: Scalars["String"];
  clickAction?: Maybe<ActivityClickAction>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
};

export type ActivityAssortedComponentsTile = {
  __typename?: "ActivityAssortedComponentsTile";
  pills?: Maybe<ActivityPills>;
  shopWithPoints?: Maybe<ActivityToggle>;
  /** @deprecated Use sortSection for the consistency across all platforms */
  sort?: Maybe<ShoppingDropdownField>;
  sortDisclaimer?: Maybe<ActivityAnchoredMessage>;
  sortSection?: Maybe<ShoppingSortAndFilterSection>;
};

export type ActivityAvailability = {
  __typename?: "ActivityAvailability";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  date: Date;
  formattedDate: EgdsPlainText;
  formattedPrice?: Maybe<Scalars["String"]>;
  highlighted: Scalars["Boolean"];
  isAvailable: Scalars["Boolean"];
  /** @deprecated Use formattedPrice instead */
  price?: Maybe<Money>;
  priceTextTheme?: Maybe<ActivityAvailabilityPriceTextTheme>;
};

export type ActivityAvailabilityPriceTextTheme = "POSITIVE";

export type ActivityBadge = {
  __typename?: "ActivityBadge";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text?: Maybe<Scalars["String"]>;
  type: ActivityBadgeType;
};

export type ActivityBadgeType = "CAMPAIGN_DEAL" | "DEAL_ADD_ON" | "DEAL_GENERIC" | "DEAL_MEMBER" | "GLOBAL_LOW_TIER";

export type ActivityBanner = {
  __typename?: "ActivityBanner";
  action?: Maybe<ActivityMessageActionLink>;
  analytics?: Maybe<ActivityAnalytics>;
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  /** @deprecated use title instead */
  primaryText: Icon;
  secondaryAction?: Maybe<ActivityMessageActionLink>;
  /** @deprecated use subtitle instead */
  secondaryText: Icon;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type: ActivityBannerType;
  /** @deprecated use icon/mark instead as per the use case */
  view: Icon;
};

export type ActivityBannerType = "ALERT" | "INFO" | "SHOP_WITH_POINTS";

export type ActivityBasicTile = {
  __typename?: "ActivityBasicTile";
  /** @deprecated use clientSideAnalytics */
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  enabled: Scalars["Boolean"];
  image?: Maybe<Image>;
  link?: Maybe<Scalars["String"]>;
  /** @deprecated Use primary instead */
  name: Scalars["String"];
  primary: EgdsText;
  secondaryMessage?: Maybe<Scalars["String"]>;
};

export type ActivityBreadcrumb = {
  __typename?: "ActivityBreadcrumb";
  analytics?: Maybe<ActivityAnalytics>;
  label: Scalars["String"];
  selectionsToRemove: Array<ActivitySelectedValue>;
};

export type ActivityButtonDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityButtonDialogTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  button: EgdsButton;
};

export type ActivityCancellationType = "FREE_CANCELLATION" | "NO_CANCELLATION";

export interface ActivityCard {
  analytics?: Maybe<ClientSideAnalytics>;
}

export type ActivityCardBadges = {
  __typename?: "ActivityCardBadges";
  primary?: Maybe<EgdsBadge>;
  secondary?: Maybe<EgdsBadge>;
};

export type ActivityCardContent =
  | ActivityCardHeadingContent
  | ActivityCardHtmlContent
  | ActivityCardSectionContent
  | ActivityCardTextContent
  | ActivityCardTextListContent
  | ActivityPriceDisplay;

export type ActivityCardHeadingContent = {
  __typename?: "ActivityCardHeadingContent";
  heading?: Maybe<EgdsText>;
};

export type ActivityCardHtmlContent = {
  __typename?: "ActivityCardHtmlContent";
  expando?: Maybe<EgdsExpando>;
  heading?: Maybe<EgdsText>;
  text: Scalars["String"];
};

export type ActivityCardLinkListContent = {
  __typename?: "ActivityCardLinkListContent";
  heading?: Maybe<EgdsHeading>;
  list: EgdsLinkList;
};

export type ActivityCardReviewsRatingContent = {
  __typename?: "ActivityCardReviewsRatingContent";
  disclaimer?: Maybe<ActivityDisclaimerDialog>;
  primary?: Maybe<EgdsText>;
  secondaries?: Maybe<Array<EgdsText>>;
};

export type ActivityCardSectionContent = {
  __typename?: "ActivityCardSectionContent";
  columns: Array<ActivityCardSectionContentColumn>;
  /**
   * @deprecated
   *         Please start using the rows within columns in ActivityCardSectionContent instead of using this field directly.
   *         The new pattern allows us query both rows and columns in a more SDUI way.
   *
   */
  rows: Array<ActivityCardSectionContentElement>;
};

export type ActivityCardSectionContentColumn = {
  __typename?: "ActivityCardSectionContentColumn";
  rows: Array<ActivityCardSectionContentElement>;
};

export type ActivityCardSectionContentElement =
  | ActivityCardHeadingContent
  | ActivityCardHtmlContent
  | ActivityCardLinkListContent
  | ActivityCardReviewsRatingContent
  | ActivityCardTextContent
  | ActivityCardTextListContent
  | ActivityPriceDisplay;

export type ActivityCardTextContent = {
  __typename?: "ActivityCardTextContent";
  expando?: Maybe<EgdsExpando>;
  heading?: Maybe<EgdsText>;
  items: Array<EgdsText>;
};

export type ActivityCardTextListContent = {
  __typename?: "ActivityCardTextListContent";
  expando?: Maybe<EgdsExpando>;
  heading?: Maybe<EgdsText>;
  icon?: Maybe<Icon>;
  list: EgdsTextList;
};

export type ActivityCarouselAccessibilityData = ActivityAccessibility & {
  __typename?: "ActivityCarouselAccessibilityData";
  label?: Maybe<Scalars["String"]>;
  nextButton?: Maybe<Scalars["String"]>;
  prevButton?: Maybe<Scalars["String"]>;
};

export type ActivityCarouselContainer = {
  __typename?: "ActivityCarouselContainer";
  accessibility?: Maybe<ActivityAccessibility>;
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  cards: Array<ActivityCard>;
  heading?: Maybe<EgdsHeading>;
};

export type ActivityCategory =
  | "ADULTS_ONLY_SHOWS"
  | "ADVENTURES"
  | "ADVENTURE_OUTDOOR"
  | "AIRBOAT_TOURS"
  | "AIRPLANE_RIDES"
  | "AIRPLANE_TOURS"
  | "AIRPORT_GROUND_TRANSPORTATION"
  | "AIRPORT_LOUNGES"
  | "AIRPORT_TO_AIRPORT_TRANSFERS"
  | "AIR_BALLOON_HELICOPTER_TOURS"
  | "AIR_HELICOPTER_BALLOON_TOURS"
  | "AMUSEMENT_THEME_PARKS"
  | "ARCHITECTURE_TOURS"
  | "ART_CLASSES"
  | "ART_CULTURE_HISTORY_TOURS"
  | "ART_MUSIC_LITERARYTOURS"
  | "ATTRACTIONS"
  | "ATTRACTION_TICKETS"
  | "BALLOON_RIDES"
  | "BALLOON_TOURS"
  | "BAR_PUB_TOURS"
  | "BEER_BREWERY_TOURS"
  | "BEYOND_THE_CITY"
  | "BIKE_MOUNTAIN_BIKE_TOURS"
  | "BIKE_RENTALS"
  | "BOAT_RENTAL"
  | "BOAT_TOURS"
  | "BOAT_TOURS_CRUISES"
  | "BRUNCHC_RUISES"
  | "BUNGEE_JUMPING_SKYDIVING"
  | "BUS_MINIVAN_TOURS"
  | "CABARET"
  | "CAMPAIGN_DEALS"
  | "CASTLE_PALACE_TOURS"
  | "CHAUFFER_SERVICES"
  | "CHRISTMAS"
  | "CIRQUE"
  | "CIRQUE_DU_SOLEIL"
  | "CIRQUE_DU_SOLEILSHOWS"
  | "CITY_AND_ATTRACTION_TRANSFERS"
  | "CITY_ATTRACTION_TRANSFERS"
  | "CITY_TOURS"
  | "CITY_TOURS_PACKAGES"
  | "CLASSES_WORKSHOPS"
  | "CLUB_AND_PARTY_BUS_TOURS"
  | "CLUB_PARTY_BUS_TOURS"
  | "COFFEE_TEA_TOURS"
  | "COMEDY_SHOWS"
  | "CONCERTS_SPECIAL_EVENTS"
  | "COOKING_CLASSES"
  | "CRUISES_BOAT_TOURS"
  | "CRUISES_WATER_TOURS"
  | "CULTURAL_HERITAGE_EXPERIENCES"
  | "CUSTOM_PRIVATE_TOURS"
  | "DANCE_CLASSES"
  | "DAY_OF_THE_DEAD"
  | "DAY_SPAS"
  | "DAY_TRIPS"
  | "DAY_TRIPS_EXCURSIONS"
  | "DINING_EXPERIENCES"
  | "DINNER_CRUISES"
  | "DINNER_THEATER"
  | "DISNEY"
  | "DISNEY_PARKS"
  | "DOLPHIN_WHALE_WATCHING"
  | "DOSE_OF_CULTURE"
  | "DUCK_TOURS"
  | "EASTER"
  | "FAMILY_FRIENDLY"
  | "FASHION_SHOWS_TOURS"
  | "FISHING_CHARTERS_TOURS"
  | "FISHING_TOURS_AND_CHARTERS"
  | "FOOD_DRINK"
  | "FOOD_DRINK_NIGHTLIFE"
  | "FOOD_TOURS"
  | "FUN_FOR_FOODIES"
  | "FUN_ON_THE_WATER"
  | "GALLERIES_MUSEUMS"
  | "GAMES_SHOWS_SPECIAL_EVENTS"
  | "GETTING_AROUND"
  | "GOLF_TEE_TIMES"
  | "GOLF_TOURS_TEE_TIMES"
  | "HALLOWEEN"
  | "HAMMAMS_TURKISH_BATHS"
  | "HELICOPTER_RIDES"
  | "HELICOPTER_TOURS"
  | "HIGH_SPEED_DRIVING"
  | "HIGH_SPEED_RIDES"
  | "HIKING_CAMPING"
  | "HISTORICAL_TOURS"
  | "HISTORY_CULTURE"
  | "HOLIDAY_SEASONAL_TOURS"
  | "HONEYMOON_PACKAGE"
  | "HONEYMOON_PACKAGES"
  | "HOP_ON_HOP_OFF"
  | "HOP_ON_HOP_OFF_TOURS"
  | "HORSE_CARRIAGE_RIDES"
  | "HOT_SPRINGS_THERMAL_SPAS"
  | "INTERESTS"
  | "KAYAKING_CANOEING"
  | "LATEST_GREATEST"
  | "LIFT_TICKETS"
  | "LIKELY_TO_SELL_OUT"
  | "LITERARY_ART_MUSIC_TOURS"
  | "LOCAL_EXPERT_PICKS"
  | "LUNCH_CRUISES"
  | "MOTORCYCLE_SCOOTER_TOURS"
  | "MOVIE_TV_TOURS"
  | "MULTIDAY_CRUISES"
  | "MULTI_DAY_EXTENDED_TOURS"
  | "MUSEUM_TICKETS"
  | "MUSEUM_TICKETS_PASSES"
  | "MUST_SEE_SIGHTS"
  | "NA"
  | "NATIONAL_HOLIDAYS"
  | "NEW"
  | "NEWY_EARS"
  | "NIGHTLIFE"
  | "NIGHTLIFE_PASSES"
  | "NIGHT_CRUISES"
  | "NIGHT_TOURS"
  | "ONSENS"
  | "OTHER_ANIMALS_ACTIVITIES"
  | "OTHER_ANIMAL_ACTIVITIES"
  | "OTHER_CLASSES"
  | "OTHER_OUTDOOR_ACTIVITIES"
  | "OTHER_SEASONAL_EVENTS"
  | "OTHER_TOURS"
  | "OTHER_TRANSFERS"
  | "OTHER_TRANSPORTATION"
  | "OTHER_WATER_ACTIVITIES"
  | "PHOTOGRAPHY_TOURS"
  | "PORT_TRANSFERS"
  | "PRIVATE_CUSTOM_TOURS"
  | "PRIVATE_DAY_TRIPS"
  | "PRIVATE_TOURS"
  | "PRIVATE_TRANSFERS"
  | "PURE_ROMANCE"
  | "RELIGIOUS_SPIRITUAL_TOURS"
  | "RIVER_HARBOUR_CRUISES"
  | "ROMAN_STAYCATION"
  | "SAFARIS"
  | "SAILING_TRIPS"
  | "SCOOTER_RENTALS"
  | "SEGWAY_TOURS"
  | "SHARED_TRANSFERS"
  | "SHARK_DIVING"
  | "SHOPPING_FASHION"
  | "SHOPPING_PASSES_OFFERS"
  | "SHOPPING_TOURS"
  | "SHORE_EXCURIONS"
  | "SHOWS_CONCERTS"
  | "SHOW_SPORT_TICKETS"
  | "SIGHTSEEING_ATTRACTION_PASSES"
  | "SIGHTSEEING_CITY_PASSES"
  | "SIGHTSEEING_PASSES"
  | "SKIING_RENTALS"
  | "SKIP_THE_LINE_TOURS"
  | "SNORKELING_SCUBA_DIVING"
  | "SNOWBOARDING_RENTALS"
  | "SNOWMOBILE_TOURS"
  | "SNOWSHOEING"
  | "SPA"
  | "SPA_WELLNESS"
  | "SPEED_BOATS_JET_SKIS"
  | "SPORTING_EVENTS_PACKAGES"
  | "SPORTS_PACKAGES"
  | "STAY_ACTIVE"
  | "SUBMARINE_TOURS"
  | "SUNSET_CRUISES"
  | "SUPERNATURAL_TOURS"
  | "SUSTAINABLE_TOURS"
  | "SWIM_WITH_DOLPHINS"
  | "THEATER_SHOWS_MUSICALS"
  | "THEME_PARKS"
  | "THEME_PARK_TICKETS_TOURS"
  | "THE_GREAT_OUTDOORS"
  | "THRILL_SEEKER"
  | "TICKETS"
  | "TOURS_ACTIVITIES"
  | "TOURS_SIGHTSEEING"
  | "TRANSPORTATION_SERVICES"
  | "TREAT_YOURSELF"
  | "TUBING"
  | "UNIVERSAL_THEME_PARKS"
  | "VALENTINES_DAY"
  | "WALKING_BIKE_TOURS"
  | "WALKING_TOURS"
  | "WATERSKIING_WAKEBOARDING"
  | "WATER_ACTIVITIES"
  | "WATER_PARKS"
  | "WEDDING_CEREMONIES"
  | "WEDDING_HONEYMOON"
  | "WEDDING_PACKAGES"
  | "WEDDING_SHONEYMOONS"
  | "WHAT_TO_DO_WITH_GROUPS"
  | "WHAT_TO_DO_WITH_KIDS"
  | "WHITE_WATER_RAFTING"
  | "WILDLIFE_NATURE"
  | "WINE_TASTING_WINERY_TOURS"
  | "WINTER_ACTIVITIES"
  | "YOGA_CLASS"
  | "YOGA_CLASSES"
  | "ZOO_TICKETS"
  | "ZOO_TICKETS_PASSES";

export type ActivityCategoryDetails = {
  __typename?: "ActivityCategoryDetails";
  category: ActivityCategory;
  enabled: Scalars["Boolean"];
  isSelected: Scalars["Boolean"];
  name: Scalars["String"];
  raw: Scalars["String"];
  size: FormattedNumber;
};

export type ActivityCategoryType =
  | "ACTIVITIES"
  | "CATEGORIES"
  | "DEALS_PROMOTIONS"
  | "NA"
  | "RECOMMENDATIONS"
  | "TOURS"
  | "TRANSPORTATION";

export interface ActivityClickAction {
  accessibilityText: Scalars["String"];
  /** @deprecated Use ClientSideAnalytics instead. */
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
}

export type ActivityCloseDialogAction = UiAction & {
  __typename?: "ActivityCloseDialogAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ActivityCloseDialogClickAction = ActivityClickAction & {
  __typename?: "ActivityCloseDialogClickAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
};

export type ActivityCopyLinkAction = ActivityNavigationBarClickAction & {
  __typename?: "ActivityCopyLinkAction";
  accessibilityText: Scalars["String"];
  analytics: ClientSideAnalytics;
  toast?: Maybe<EgdsToast>;
  value: Scalars["String"];
};

export type ActivityCrossSellSearchResults = {
  __typename?: "ActivityCrossSellSearchResults";
  group: ActivityGroup;
};

export type ActivityDateRange = {
  __typename?: "ActivityDateRange";
  endDate: Date;
  startDate: Date;
};

export interface ActivityDateRangeInput {
  endDate: DateInput;
  startDate: DateInput;
}

export type ActivityDateRangePickerDialog = ActivityDialog & {
  __typename?: "ActivityDateRangePickerDialog";
  dateRangePicker: EgdsDateRangePicker;
  trigger: ActivityDialogTrigger;
};

export type ActivityDestination = {
  __typename?: "ActivityDestination";
  coordinates?: Maybe<Coordinates>;
  pinnedActivityId?: Maybe<Scalars["String"]>;
  referencedActivityId?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["String"]>;
  regionName?: Maybe<Scalars["String"]>;
  regionType?: Maybe<Scalars["String"]>;
};

export interface ActivityDestinationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  pinnedActivityId?: InputMaybe<Scalars["String"]>;
  referencedActivityId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  regionName?: InputMaybe<Scalars["String"]>;
  regionType?: InputMaybe<Scalars["String"]>;
}

export type ActivityDetailViewOptions = "NON_CONSOLIDATED_OFFERS" | "SHOP_WITH_POINTS" | "THIRD_PARTY_REVIEWS";

export type ActivityDetailsMessagingAction = {
  __typename?: "ActivityDetailsMessagingAction";
  text?: Maybe<Scalars["String"]>;
  url: Scalars["URL"];
};

export type ActivityDetailsUisPayLoad = {
  __typename?: "ActivityDetailsUisPayLoad";
  detailsPayload: Scalars["String"];
};

export interface ActivityDialog {
  trigger: ActivityDialogTrigger;
}

export interface ActivityDialogTrigger {
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
}

export type ActivityDisclaimerDialog = ActivityDialog & {
  __typename?: "ActivityDisclaimerDialog";
  contents: Array<EgdsText>;
  dialog: EgdsActionDialog;
  dialogActions?: Maybe<Array<ActivityClickAction>>;
  trigger: ActivityDialogTrigger;
};

export type ActivityDiscountType =
  | "AIR_ATTACH_MIP"
  | "AIR_HOTEL_ATTACH_MIP"
  | "CAMPAIGN_DEAL"
  | "FREE_ACTIVITY"
  | "GUEST"
  | "HOTEL_ATTACH_MIP"
  | "MIP"
  | "MOD"
  | "STRIKE_OUT";

export type ActivityDuration = {
  __typename?: "ActivityDuration";
  formatted?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  raw: Duration;
};

export type ActivityEmailAction = ActivityNavigationBarClickAction & {
  __typename?: "ActivityEmailAction";
  accessibilityText: Scalars["String"];
  analytics: ClientSideAnalytics;
  mailBody: Scalars["String"];
  mailSubject: Scalars["String"];
  mailTo?: Maybe<MailToUri>;
};

export type ActivityEmptyStateTile = {
  __typename?: "ActivityEmptyStateTile";
  analytics?: Maybe<ClientSideAnalytics>;
  body: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
};

export type ActivityErrorMessagingClickAction = ActivityClickAction & {
  __typename?: "ActivityErrorMessagingClickAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
};

export type ActivityEventLocationDetails = {
  __typename?: "ActivityEventLocationDetails";
  address: Address;
  coordinates: Coordinates;
  description: Scalars["String"];
  formattedAddress?: Maybe<Scalars["String"]>;
  region: ActivityRegion;
};

export type ActivityExpando = {
  __typename?: "ActivityExpando";
  collapseAnalytics?: Maybe<ActivityAnalytics>;
  collapseLabel: Scalars["String"];
  expandAnalytics?: Maybe<ActivityAnalytics>;
  expandLabel: Scalars["String"];
  minimalHeight?: Maybe<Scalars["Int"]>;
  threshold?: Maybe<Scalars["Int"]>;
};

export type ActivityFakeInputDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityFakeInputDialogTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  /** @deprecated Use Icon instead */
  graphic?: Maybe<UiGraphic>;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type ActivityFilter = ActivityThemeFilter;

export type ActivityFreeCancellation = {
  __typename?: "ActivityFreeCancellation";
  formatted: Scalars["String"];
  type: ActivityCancellationType;
};

export type ActivityGallery = {
  __typename?: "ActivityGallery";
  /** @deprecated Use featuredMedia instead */
  featured: Image;
  featuredMedia: Media;
  /** @deprecated Use medias instead */
  images: Array<Image>;
  medias: Array<Media>;
};

export type ActivityGalleryComponent = {
  __typename?: "ActivityGalleryComponent";
  carousel?: Maybe<EgdsCarousel>;
  featuredMedia?: Maybe<Array<ActivityMediaItem>>;
  thumbnailDialog?: Maybe<ActivityThumbnailMediaGalleryDialog>;
};

export type ActivityGraphicDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityGraphicDialogTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  graphic: UiGraphic;
};

export type ActivityGroup = {
  __typename?: "ActivityGroup";
  action?: Maybe<ActivityGroupAction>;
  activityTiles: Array<ActivityTile>;
  /** @deprecated Use analyticsList instead */
  analytics?: Maybe<ActivityAnalytics>;
  analyticsList: Array<ClientSideAnalytics>;
  heading?: Maybe<EgdsText>;
  /** @deprecated The analytics object provides the complete referrerId now */
  id?: Maybe<Scalars["String"]>;
  /** @deprecated Use heading instead */
  name?: Maybe<Scalars["String"]>;
  navigationActions?: Maybe<ActivityNavigationActions>;
  /** @deprecated Use viewType instead */
  type: ActivityGroupType;
  viewType: ActivityGroupViewType;
};

export type ActivityGroupAction = {
  __typename?: "ActivityGroupAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  label: Scalars["String"];
  pagination?: Maybe<Pagination>;
  url: Scalars["String"];
};

export type ActivityGroupType =
  | "ACTIVITY_LIST"
  | "ACTIVITY_MESSAGE"
  | "AVAILABLE_TODAY"
  | "CATEGORY"
  | "FILTERED_ACTIVITIES"
  | "INTERESTS"
  | "KEYWORD_SEARCH"
  | "MORE_ACTIVITIES"
  | "NEIGHBOURHOOD"
  | "PINNED_ACTIVITY"
  | "POI"
  | "RECOMMENDATIONS"
  | "SLIM_CATEGORY"
  | "SUB_CATEGORIES"
  | "SUB_CATEGORY"
  | "SWP_SORT_SECTION"
  | "TOP_ACTIVITIES"
  | "TRIPS_ACTIVITY_LIST"
  | "XSELL_RECOMMENDATIONS";

export type ActivityGroupViewType =
  | "ACTIVITY_LIST"
  | "ACTIVITY_SPANNED_TILE_LIST"
  | "ACTIVITY_TILES_CAROUSEL"
  | "CATEGORY_TILES_CAROUSEL"
  | "KEYWORD_SEARCH"
  | "MESSAGE"
  | "SLIM_CATEGORY_TILES_CAROUSEL"
  | "TEXT";

export type ActivityIcon = {
  __typename?: "ActivityIcon";
  anchoredSubtext?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated Use nullabe field icon_temp instead */
  icon: Icon;
  icon_temp?: Maybe<Icon>;
  label: Scalars["String"];
  mark?: Maybe<Mark>;
  subtext?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type ActivityImageHeaderCard = ActivityCard & {
  __typename?: "ActivityImageHeaderCard";
  analytics?: Maybe<ClientSideAnalytics>;
  badges?: Maybe<ActivityCardBadges>;
  clickAction?: Maybe<ActivityClickAction>;
  contents?: Maybe<Array<ActivityCardContent>>;
  /** @deprecated use mediaTile instead */
  image?: Maybe<Image>;
  mediaTile?: Maybe<MediaTile>;
  saveItem?: Maybe<TripsSaveItem>;
};

export type ActivityInfo = {
  __typename?: "ActivityInfo";
  activity: Activity;
  availabilities: Array<ActivityAvailability>;
  availabilityCalendar: Array<ActivityAvailability>;
  directFeedbackLink?: Maybe<DirectFeedbackLink>;
  gallery: ActivityGallery;
  imageGallery?: Maybe<ActivityGalleryComponent>;
  /** @deprecated Use staticDetails query which will contain location card. */
  location: ActivityLocation;
  loyaltyPoints?: Maybe<ActivityLoyaltyPoints>;
  navigationBar?: Maybe<ActivityNavigationBar>;
  notFound: ActivityNotFoundComponent;
  /** @deprecated will remove filter, replace with offer(offerId) */
  offer: ActivityOffer;
  offerComponent: ActivityOfferDetailsCard;
  /** @deprecated will remove filter, replace with offers(selectedDate) */
  offers: Array<ActivityOffer>;
  offersComponent?: Maybe<ActivityOffersComponent>;
  offersMessage?: Maybe<ActivityOfferMessage>;
  offersSummary: ActivityOffersSummary;
  overview?: Maybe<ActivityOverviewComponent>;
  presentation: ActivityPresentation;
  recommendations?: Maybe<ActivityRecommendationsComponent>;
  reviews: Array<ActivityReview>;
  staticDetails?: Maybe<ActivityStaticDetailsComponent>;
  tabbedNavigationBar?: Maybe<ActivityTabbedNavigationBar>;
  telesales?: Maybe<ActivityTelesales>;
  uisPayload: ActivityDetailsUisPayLoad;
};

export type ActivityInfoNavigationBarArgs = {
  searchFilters?: InputMaybe<ActivitySearchFiltersInput>;
};

export type ActivityInfoOfferArgs = {
  filter: ActivityOfferFilterInput;
  offerId: Scalars["String"];
};

export type ActivityInfoOfferComponentArgs = {
  offerId: Scalars["String"];
  selectedDate: DateInput;
};

export type ActivityInfoOffersArgs = {
  filter?: InputMaybe<ActivityOfferFilterInput>;
  selectedDate?: InputMaybe<DateInput>;
};

export type ActivityInfoOffersComponentArgs = {
  selectedDate?: InputMaybe<DateInput>;
};

export type ActivityInfoReviewsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sortBy?: InputMaybe<ActivityReviewSort>;
  source?: InputMaybe<Scalars["String"]>;
};

export type ActivityItemsByPoiModule = {
  __typename?: "ActivityItemsByPoiModule";
  groups: Array<ActivityPoiGroup>;
};

export type ActivityItineraryCard = {
  __typename?: "ActivityItineraryCard";
  contents: Array<ActivityItineraryCardContent>;
  heading: EgdsHeading;
  sectionRef?: Maybe<Scalars["String"]>;
};

export interface ActivityItineraryCardContent {
  expando?: Maybe<EgdsExpando>;
  stops: Array<ActivityItineraryStopLocationContent>;
}

export type ActivityItineraryCardHopOnHopOffContent = ActivityItineraryCardContent & {
  __typename?: "ActivityItineraryCardHopOnHopOffContent";
  expando?: Maybe<EgdsExpando>;
  features?: Maybe<EgdsTextIconList>;
  pointsOfInterest?: Maybe<ActivityCardTextListContent>;
  schedule?: Maybe<ActivityCardTextContent>;
  stops: Array<ActivityItineraryStopLocationContent>;
};

export type ActivityItineraryCardMultiDayContent = ActivityItineraryCardContent & {
  __typename?: "ActivityItineraryCardMultiDayContent";
  expando?: Maybe<EgdsExpando>;
  features?: Maybe<EgdsTextIconList>;
  stops: Array<ActivityItineraryStopLocationContent>;
};

export type ActivityItineraryCardStandardContent = ActivityItineraryCardContent & {
  __typename?: "ActivityItineraryCardStandardContent";
  expando?: Maybe<EgdsExpando>;
  menu?: Maybe<Array<ActivityCardTextListContent>>;
  stops: Array<ActivityItineraryStopLocationContent>;
};

export type ActivityItineraryStopLocationContent = {
  __typename?: "ActivityItineraryStopLocationContent";
  heading?: Maybe<EgdsHeading>;
  icon?: Maybe<Icon>;
  list?: Maybe<EgdsTextList>;
  /** @deprecated Will be removed - use textV2 instead */
  text?: Maybe<EgdsParagraph>;
  textV2?: Maybe<EgdsText>;
};

export type ActivityLeadTicket = {
  __typename?: "ActivityLeadTicket";
  label?: Maybe<Scalars["String"]>;
  legalDisclaimer?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated To be removed. */
  likelyToSellOut?: Maybe<ActivityLikelyToSellOut>;
  loyaltyPointsOption?: Maybe<ActivityLoyaltyPointsOption>;
  price?: Maybe<ActivityPrice>;
  priceAccessibilityText?: Maybe<Scalars["String"]>;
};

export type ActivityLeadTicketView = {
  __typename?: "ActivityLeadTicketView";
  action: ActivityMessageActionLink;
  message?: Maybe<Scalars["String"]>;
  ticket: ActivityLeadTicket;
};

export type ActivityLikelyToSellOut = {
  __typename?: "ActivityLikelyToSellOut";
  formatted?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
};

export type ActivityLinkClickAction = ActivityClickAction & {
  __typename?: "ActivityLinkClickAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  anchorTagUrl?: Maybe<Scalars["String"]>;
  clientSideAnalytics: ClientSideAnalytics;
  linkTagUrl?: Maybe<Scalars["String"]>;
  opensInNewTab: Scalars["Boolean"];
};

export type ActivityLinkDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityLinkDialogTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  graphic?: Maybe<UiGraphic>;
  label: Scalars["String"];
};

export type ActivityLocation = {
  __typename?: "ActivityLocation";
  event: ActivityEventLocationDetails;
  redemption: Array<ActivityLocationDetails>;
  sectionRef?: Maybe<Scalars["String"]>;
};

export type ActivityLocationCard = ActivityCard & {
  __typename?: "ActivityLocationCard";
  analytics?: Maybe<ClientSideAnalytics>;
  contents: Array<ActivityCardSectionContent>;
  heading: EgdsHeading;
  map: ActivityLocationMapDialog;
  sectionRef: Scalars["String"];
};

export type ActivityLocationDetails = {
  __typename?: "ActivityLocationDetails";
  address: Address;
  coordinates: Coordinates;
  description: Scalars["String"];
  distanceFromHotel?: Maybe<ActivityText>;
  formattedAddress?: Maybe<Scalars["String"]>;
};

export type ActivityLocationMapDialog = ActivityDialog & {
  __typename?: "ActivityLocationMapDialog";
  dialog: EgdsFullScreenDialog;
  dynamicMap: ActivityMap;
  trigger: ActivityDialogTrigger;
};

export type ActivityLoyaltyPoints = {
  __typename?: "ActivityLoyaltyPoints";
  message: Scalars["String"];
};

export type ActivityLoyaltyPointsOption = {
  __typename?: "ActivityLoyaltyPointsOption";
  formattedPoints?: Maybe<Scalars["String"]>;
  leadingCaption?: Maybe<Scalars["String"]>;
  pointsAmount?: Maybe<FormattedNumber>;
  pointsFirst: Scalars["Boolean"];
  pointsUnit?: Maybe<Scalars["String"]>;
  strikeThroughFirst: Scalars["Boolean"];
};

export type ActivityMap = {
  __typename?: "ActivityMap";
  egMap: EgdsBasicMap;
  markers: Array<ActivityMapMarker>;
};

export type ActivityMapDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityMapDialogTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars["String"]>;
  staticMap: ActivityMap;
  staticMapImage?: Maybe<StaticMapImage>;
  /** @deprecated use staticMap image instead because staticMapUrl does not have map accessibility text */
  staticMapUrl?: Maybe<Scalars["String"]>;
};

export type ActivityMapGroupType = "POI";

export type ActivityMapMarker = EgdsMapMarker & {
  __typename?: "ActivityMapMarker";
  id?: Maybe<Scalars["String"]>;
  markerIconType: Array<Scalars["String"]>;
  markerPosition: Coordinates;
  markerStatus: EgdsMapMarkerStatus;
  markerType: Scalars["String"];
  placeCard?: Maybe<ActivityMapPlaceCard>;
  text: Scalars["String"];
};

export type ActivityMapPlaceCard = {
  __typename?: "ActivityMapPlaceCard";
  image?: Maybe<Image>;
  primary: EgdsText;
  secondaries?: Maybe<Array<EgdsText>>;
  tag: EgdsIconText;
};

export interface ActivityMarketingInfoInput {
  mctc?: InputMaybe<Scalars["Int"]>;
  sem?: InputMaybe<Scalars["String"]>;
  semdtl?: InputMaybe<Scalars["String"]>;
}

export type ActivityMediaItem = MediaItem & {
  __typename?: "ActivityMediaItem";
  media: Media;
};

export type ActivityMessage = {
  __typename?: "ActivityMessage";
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ActivityMessageAction = {
  __typename?: "ActivityMessageAction";
  first?: Maybe<ActivityMessageClickAction>;
  /** @deprecated Use first instead */
  primary?: Maybe<ActivityMessageActionLink>;
  second?: Maybe<ActivityMessageClickAction>;
  /** @deprecated Use second instead */
  secondary?: Maybe<ActivityMessageActionLink>;
};

export type ActivityMessageActionLink = {
  __typename?: "ActivityMessageActionLink";
  accessibilityText: Scalars["String"];
  filterSelection?: Maybe<ActivitySelectedValue>;
  href: Scalars["String"];
  rfrr?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type ActivityMessageClickAction = {
  __typename?: "ActivityMessageClickAction";
  clickAction: ActivityClickAction;
  text?: Maybe<Scalars["String"]>;
};

export type ActivityMessageResult = {
  __typename?: "ActivityMessageResult";
  action?: Maybe<ActivityMessagingResultAction>;
  analytics?: Maybe<ActivityAnalytics>;
  filterKey?: Maybe<Scalars["String"]>;
  index?: Maybe<Scalars["Int"]>;
  subtitle?: Maybe<ActivityMessagingResultTitle>;
  title?: Maybe<ActivityMessagingResultTitle>;
  type?: Maybe<ActivityMessageType>;
};

export type ActivityMessageTile = {
  __typename?: "ActivityMessageTile";
  action?: Maybe<ActivityMessageAction>;
  analytics?: Maybe<ActivityAnalytics>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use graphic instead */
  icon?: Maybe<Icon>;
  /** @deprecated Use graphic instead */
  mark?: Maybe<Mark>;
  subtitle?: Maybe<ActivityMessageTitle>;
  title?: Maybe<ActivityMessageTitle>;
  type?: Maybe<ActivityMessageType>;
};

export type ActivityMessageTitle = {
  __typename?: "ActivityMessageTitle";
  styleTokens?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type ActivityMessageType =
  | "ADDON"
  | "EARN_2X_REWARD_POINTS"
  | "GIFTING_LX_BANNER"
  | "LOGIN"
  | "SOURCED_CAMPAIGN"
  | "SUMMER_CAMPAIGN_PRIMARY"
  | "SUMMER_CAMPAIGN_SECONDARY";

export type ActivityMessagingAction = {
  __typename?: "ActivityMessagingAction";
  href: Scalars["String"];
  rfrr?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type ActivityMessagingResultAction = {
  __typename?: "ActivityMessagingResultAction";
  primary?: Maybe<ActivityMessagingAction>;
  secondary?: Maybe<ActivityMessagingAction>;
};

export type ActivityMessagingResultTitle = {
  __typename?: "ActivityMessagingResultTitle";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text?: Maybe<Scalars["String"]>;
};

export type ActivityModulesResponse = {
  __typename?: "ActivityModulesResponse";
  activitiesByCategories: ActivitiesByCategoriesModule;
  activityItemsByPoi?: Maybe<ActivityItemsByPoiModule>;
  categories?: Maybe<ActivityGroup>;
};

export type ActivityMultiItemTripUpdateAction = UiAction & {
  __typename?: "ActivityMultiItemTripUpdateAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  offerNaturalKey: ActivityOfferNaturalKey;
  /** @deprecated use offerNaturalKey */
  productId: Scalars["String"];
};

export type ActivityNaturalKey = {
  __typename?: "ActivityNaturalKey";
  offerToken: Scalars["String"];
  productToken: Scalars["String"];
};

export interface ActivityNaturalKeyInput {
  offerToken: Scalars["String"];
  productToken: Scalars["String"];
}

export type ActivityNavigationAction = {
  __typename?: "ActivityNavigationAction";
  accessibility: Scalars["String"];
  analytics?: Maybe<ClientSideAnalytics>;
};

export type ActivityNavigationActions = {
  __typename?: "ActivityNavigationActions";
  next: ActivityNavigationAction;
  previous: ActivityNavigationAction;
};

export type ActivityNavigationBar = {
  __typename?: "ActivityNavigationBar";
  backLink: EgdsStandardLink;
  primary?: Maybe<Scalars["String"]>;
  saveItem?: Maybe<TripsSaveItem>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  shareMenu?: Maybe<EgdsMenu>;
};

export interface ActivityNavigationBarClickAction {
  accessibilityText: Scalars["String"];
  analytics: ClientSideAnalytics;
}

export type ActivityNotFoundComponent = {
  __typename?: "ActivityNotFoundComponent";
  backButton: EgdsButton;
  backButtonAction: ActivityLinkClickAction;
  graphic?: Maybe<UiGraphic>;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
};

export type ActivityOffer = {
  __typename?: "ActivityOffer";
  activityOfferMessages?: Maybe<ActivityOfferMessages>;
  activityOfferTicketsSummary?: Maybe<ActivityOfferTicketsSummary>;
  /** @deprecated use formattedAvailableTime or availableTimeEpoch instead */
  availableTime: DateTime;
  availableTimeEpoch: Scalars["String"];
  /** @deprecated Use badge from ActivityOfferPriceDetails instead */
  badges?: Maybe<ActivityOfferBadges>;
  count: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  features: Array<ActivityIcon>;
  formattedAvailableTime: Scalars["String"];
  highlighted: Scalars["Boolean"];
  hotelPickupAvailable: Scalars["Boolean"];
  id: Scalars["String"];
  maxTicketSelectionAllowed?: Maybe<Scalars["Int"]>;
  minTicketSelectionAllowed?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  priceDetails: ActivityOfferPriceDetails;
  /** @deprecated We send badges instead directly now */
  priceMetaData?: Maybe<ActivityPriceMetadata>;
  /** @deprecated Use priceDetails instead */
  priceSummary: ActivityOfferPriceSummary;
  priceType: ActivityPriceType;
  promoId?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<ActivityOfferOption>>;
  /** @deprecated  Use travelerSelectorDialog instead */
  ticketTypes: Array<ActivityOfferTicketType>;
  travelerSelectorDialog: ActivityTravelerSelectorDialog;
  tripUpdateButtons?: Maybe<ActivityTripUpdateButtons>;
};

export type ActivityOfferBadges = {
  __typename?: "ActivityOfferBadges";
  primary?: Maybe<ActivityBadge>;
  secondary?: Maybe<ActivityBadge>;
};

export type ActivityOfferBookingErrorBanner = {
  __typename?: "ActivityOfferBookingErrorBanner";
  banner: UiBanner;
  clientSideAnalytics: ClientSideAnalytics;
  statusCode: Scalars["Int"];
};

export type ActivityOfferCreateTripAction = ActivityClickAction & {
  __typename?: "ActivityOfferCreateTripAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  callbackMessages: ActivityActionCallbackMessages;
  clientSideAnalytics: ClientSideAnalytics;
  createTripRequestBody: Scalars["String"];
};

export type ActivityOfferDetailsCard = {
  __typename?: "ActivityOfferDetailsCard";
  bookButton: EgdsButton;
  createTripAction: ActivityOfferCreateTripAction;
  description?: Maybe<EgdsParagraph>;
  elementId: Scalars["String"];
  errorMessages?: Maybe<Array<ActivityOfferBookingErrorBanner>>;
  features?: Maybe<ActivityCardTextListContent>;
  heading: Scalars["String"];
  priceDetails: ActivityOfferPriceDetails;
  selections?: Maybe<Array<ActivityOfferSelectionElement>>;
};

export interface ActivityOfferFilterInput {
  selections?: InputMaybe<Array<ActivitySelectedValueInput>>;
  tickets?: InputMaybe<Array<ActivityTicketInput>>;
}

export type ActivityOfferItem = OfferItem & {
  __typename?: "ActivityOfferItem";
  activityOffer?: Maybe<OfferData>;
  badge?: Maybe<CampaignOfferBadge>;
  city: Scalars["String"];
  dates?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  durationLabel?: Maybe<Scalars["String"]>;
  freeCancellationMessage?: Maybe<Scalars["String"]>;
  image: Image;
  name: Scalars["String"];
  province?: Maybe<Scalars["String"]>;
  secondaryContent?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ActivityOfferLoyaltyEarnAmount = {
  __typename?: "ActivityOfferLoyaltyEarnAmount";
  /** @deprecated The Money type is now deprecated. */
  loyaltyEarnAmount: Money;
  selectedCount: Scalars["Int"];
};

export type ActivityOfferMessage = {
  __typename?: "ActivityOfferMessage";
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type ActivityOfferMessages = {
  __typename?: "ActivityOfferMessages";
  /** @deprecated To be removed. */
  likelyToSellOut?: Maybe<ActivityLikelyToSellOut>;
  /** @deprecated To be removed. */
  nonRefundable?: Maybe<ActivityMessage>;
  oneKeyEarnPointsMessage?: Maybe<EgdsText>;
  operatingHours?: Maybe<EgdsText>;
  removeSelectedOffer?: Maybe<EgdsText>;
  vbpMessage?: Maybe<Scalars["String"]>;
};

export type ActivityOfferMultiSelectionOption = ActivityOfferOption & {
  __typename?: "ActivityOfferMultiSelectionOption";
  dialog: EgdsFullScreenDialog;
  doneButton: UiPrimaryButton;
  fields?: Maybe<Array<ActivityOfferSelectionField>>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<EgdsHeading>;
  id: Scalars["String"];
  selections?: Maybe<Array<ActivityOfferSelectionField>>;
  title: Scalars["String"];
  trigger: ActivityDialogTrigger;
};

export type ActivityOfferNaturalKey = {
  __typename?: "ActivityOfferNaturalKey";
  offerProductNaturalKey: Scalars["String"];
};

export interface ActivityOfferNaturalKeyInput {
  offerProductNaturalKey: Scalars["String"];
}

export interface ActivityOfferOption {
  /** @deprecated Use ActivityOfferMultiSelectionOption.selections now */
  fields?: Maybe<Array<ActivityOfferSelectionField>>;
  /** @deprecated Use ActivityOfferMultiSelectionOption.trigger now */
  graphic?: Maybe<UiGraphic>;
  id: Scalars["String"];
  /** @deprecated Use ActivityOfferMultiSelectionOption.trigger now */
  title: Scalars["String"];
}

export type ActivityOfferPrice = {
  __typename?: "ActivityOfferPrice";
  perTravelerPrice: ActivityPrice;
  selectedCount: Scalars["Int"];
  totalLoyaltyPointsOption?: Maybe<ActivityLoyaltyPointsOption>;
  totalPrice: ActivityPrice;
};

export type ActivityOfferPriceDetails = {
  __typename?: "ActivityOfferPriceDetails";
  badge?: Maybe<EgdsStandardBadge>;
  detailsTitle: Scalars["String"];
  footer?: Maybe<Array<ActivityOfferPriceDetailsFooterElement>>;
  lineItems: Array<ActivityOfferPriceLineItems>;
  priceDisplay: ActivityPriceDisplay;
  totalTitle: Scalars["String"];
};

export type ActivityOfferPriceDetailsFooterElement = ActivityDisclaimerDialog | EgdsPlainText;

export type ActivityOfferPriceLineItems = {
  __typename?: "ActivityOfferPriceLineItems";
  accessibility: Scalars["String"];
  lockupPrice: Scalars["String"];
  priceBreakdown: Scalars["String"];
  strikeThroughPrice?: Maybe<Scalars["String"]>;
};

export type ActivityOfferPriceSummary = {
  __typename?: "ActivityOfferPriceSummary";
  ticketBreakdownSummary: ActivityOfferTicketBreakdownPriceSummary;
  totalPriceSummary: ActivityOfferTotalPriceSummary;
};

export type ActivityOfferSelectionElement =
  | ActivityOfferSelectionGraphicText
  | ActivityRadioGroupDialog
  | ActivityTravelerSelectorDialog;

export type ActivityOfferSelectionField = {
  __typename?: "ActivityOfferSelectionField";
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"];
  theme: ActivityOfferUdsToken;
  value: Scalars["String"];
};

export type ActivityOfferSelectionGraphicText = {
  __typename?: "ActivityOfferSelectionGraphicText";
  graphicText: EgdsGraphicText;
  key: Scalars["String"];
  value: Scalars["String"];
};

export type ActivityOfferSingleSelectionOption = ActivityOfferOption & {
  __typename?: "ActivityOfferSingleSelectionOption";
  fields?: Maybe<Array<ActivityOfferSelectionField>>;
  graphic?: Maybe<UiGraphic>;
  id: Scalars["String"];
  title: Scalars["String"];
};

export type ActivityOfferTicketBreakdownPriceSummary = {
  __typename?: "ActivityOfferTicketBreakdownPriceSummary";
  ticketTypePrices: Array<ActivityOfferTicketTypePriceSummary>;
  title: Scalars["String"];
};

export type ActivityOfferTicketSummaryExtendedMessage = {
  __typename?: "ActivityOfferTicketSummaryExtendedMessage";
  action?: Maybe<ActivityMessagingAction>;
  text: Scalars["String"];
};

export type ActivityOfferTicketType = {
  __typename?: "ActivityOfferTicketType";
  defaultSelectedCount: Scalars["Int"];
  label: Scalars["String"];
  /** @deprecated The loyalty earn is being calculated with offers call */
  loyaltyEarnAwards?: Maybe<Array<ActivityOfferLoyaltyEarnAmount>>;
  maxSelectableCount: Scalars["Int"];
  minSelectableCount: Scalars["Int"];
  prices: Array<ActivityOfferPrice>;
  promoId?: Maybe<Scalars["String"]>;
  restrictionText?: Maybe<Scalars["String"]>;
  step: Scalars["Int"];
  ticketKey: Scalars["String"];
  ticketPlural: Scalars["String"];
  ticketSingular: Scalars["String"];
  type: Scalars["String"];
};

export type ActivityOfferTicketTypePriceSummary = {
  __typename?: "ActivityOfferTicketTypePriceSummary";
  accessibilityText: Scalars["String"];
  breakdownLeadPriceLabel: Scalars["String"];
  breakdownStrikeOutPriceLabel?: Maybe<Scalars["String"]>;
  totalLeadPriceLabel: Scalars["String"];
};

export type ActivityOfferTicketsSummary = {
  __typename?: "ActivityOfferTicketsSummary";
  activityOfferTicketsSummaryMessages?: Maybe<Array<ActivityOfferTicketsSummaryMessage>>;
  icon: Scalars["String"];
  title: Scalars["String"];
};

export type ActivityOfferTicketsSummaryMessage = {
  __typename?: "ActivityOfferTicketsSummaryMessage";
  extendedMessage?: Maybe<ActivityOfferTicketSummaryExtendedMessage>;
  href?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  rfrr?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type ActivityOfferTotalPriceSummary = {
  __typename?: "ActivityOfferTotalPriceSummary";
  legalDisclaimer?: Maybe<ActivityAnchoredMessage>;
  loyaltyPointsMessage?: Maybe<Scalars["String"]>;
  pointsFirst: Scalars["Boolean"];
  price: ActivityPrice;
  priceAccessibilityText: Scalars["String"];
  title: Scalars["String"];
};

export type ActivityOfferUdsToken = "SELECTED" | "UNSELECTED";

export type ActivityOffersComponent = {
  __typename?: "ActivityOffersComponent";
  offersListComponent?: Maybe<ActivityOffersListComponent>;
  offersSummaryComponent?: Maybe<ActivityOffersSummaryComponent>;
  sectionRef: Scalars["String"];
};

export type ActivityOffersListComponent = {
  __typename?: "ActivityOffersListComponent";
  analytics?: Maybe<ClientSideAnalytics>;
  datePickerLink: ActivityDateRangePickerDialog;
  dateRange: EgdsDateRange;
  filters?: Maybe<ActivityOffersListFilterPills>;
  heading?: Maybe<Scalars["String"]>;
  offers?: Maybe<Array<ActivityOfferDetailsCard>>;
  offersMessage?: Maybe<ActivityOfferMessage>;
  pointsToggle?: Maybe<ActivityToggle>;
};

export type ActivityOffersListDialog = ActivityDialog & {
  __typename?: "ActivityOffersListDialog";
  dialog: EgdsFullScreenDialog;
  offersList: ActivityOffersListComponent;
  trigger: ActivityDialogTrigger;
};

export type ActivityOffersListFilterPills = {
  __typename?: "ActivityOffersListFilterPills";
  heading?: Maybe<Scalars["String"]>;
  pills: Array<EgdsBasicPill>;
};

export type ActivityOffersSummary = {
  __typename?: "ActivityOffersSummary";
  filters?: Maybe<Array<ActivityOfferOption>>;
  leadTicketView: ActivityLeadTicketView;
};

export type ActivityOffersSummaryComponent = {
  __typename?: "ActivityOffersSummaryComponent";
  datePickerLink: ActivityDateRangePickerDialog;
  dateRange: EgdsDateRange;
  heading?: Maybe<Scalars["String"]>;
  offersButton: ActivityOffersListDialog;
  priceDisplay: ActivityPriceDisplay;
};

export type ActivityOpenDetailsModalClickAction = ActivityClickAction & {
  __typename?: "ActivityOpenDetailsModalClickAction";
  accessibilityText: Scalars["String"];
  activityId: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  dateRange: ActivityDateRange;
  dialog: EgdsFullScreenDialog;
};

export type ActivityOpenDialogAction = UiAction & {
  __typename?: "ActivityOpenDialogAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  dialog: ActivityDialog;
};

export type ActivityOpenOffersModalAction = UiAction & {
  __typename?: "ActivityOpenOffersModalAction";
  accessibility?: Maybe<Scalars["String"]>;
  activityId: Scalars["String"];
  analytics: ClientSideAnalytics;
  dateRange: ActivityDateRange;
  dismissButtonAccessibility: Scalars["String"];
  dismissButtonAnalytics: ClientSideAnalytics;
  heading?: Maybe<EgdsHeading>;
  selectedOffers?: Maybe<Array<ActivityOfferNaturalKey>>;
  subHeading?: Maybe<EgdsPlainText>;
};

export type ActivityOverviewComponent = {
  __typename?: "ActivityOverviewComponent";
  contents: ActivityCardSectionContent;
  findTicketsAction?: Maybe<ActivityRefClickAction>;
  findTicketsButton?: Maybe<UiPrimaryButton>;
  heading: EgdsHeading;
  location?: Maybe<ActivityLocationCard>;
  priceDisplay?: Maybe<ActivityPriceDisplay>;
  reviewSummary?: Maybe<ActivityReviewsSummary>;
  sectionRef: Scalars["String"];
  /** @deprecated Use `findTicketsAction`. */
  seeTicketsAction: ActivityRefClickAction;
  /** @deprecated Use `findTicketsButton`. */
  seeTicketsButton: UiPrimaryButton;
  subHeading?: Maybe<Array<EgdsText>>;
};

export type ActivityPage = {
  __typename?: "ActivityPage";
  pageId: Scalars["String"];
  pageName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ActivityPageHrefLangLink = {
  __typename?: "ActivityPageHrefLangLink";
  href: Scalars["String"];
  hrefLang: Scalars["String"];
};

export type ActivityPageMetaTagType = "HTTP_EQUIV" | "NAME" | "PROPERTY";

export type ActivityPageSeoMetaTag = {
  __typename?: "ActivityPageSeoMetaTag";
  content: Scalars["String"];
  metaTagType: ActivityPageMetaTagType;
  metaTagValue: Scalars["String"];
};

export type ActivityPageType = "ACTIVITY_INFO";

export type ActivityPaginationAction = UiAction & {
  __typename?: "ActivityPaginationAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  pagination: Pagination;
};

export type ActivityPaginationInfo = {
  __typename?: "ActivityPaginationInfo";
  currentPageIndex: Scalars["Int"];
  nextPageIndex: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type ActivityPaginationInfoAction = ActivityClickAction & {
  __typename?: "ActivityPaginationInfoAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  pagination: Pagination;
};

export type ActivityPartnerProvidedDetails = {
  __typename?: "ActivityPartnerProvidedDetails";
  body: Scalars["String"];
  heading: Scalars["String"];
};

export type ActivityPill = UiPill & {
  __typename?: "ActivityPill";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiAction>;
  /** @deprecated DO NOT USE. Will be removed. */
  analytics?: Maybe<ActivityAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  /** @deprecated DO NOT USE. Will be removed. */
  label?: Maybe<Scalars["String"]>;
  primary: Scalars["String"];
  selected: Scalars["Boolean"];
  /** @deprecated DO NOT USE. Will be removed. */
  selections?: Maybe<Array<ActivitySelectedValue>>;
};

export type ActivityPills = {
  __typename?: "ActivityPills";
  appliedFilters: Array<ShoppingSortAndFilterAppliedFilter>;
  clearAll?: Maybe<UiTertiaryButton>;
};

export type ActivityPoiGroup = {
  __typename?: "ActivityPoiGroup";
  activitiesGroup?: Maybe<ActivityGroup>;
  poiTile: ActivityPoiTile;
};

export type ActivityPoiTile = {
  __typename?: "ActivityPoiTile";
  description: Scalars["String"];
  detailsLink?: Maybe<ActivityAnchoredMessage>;
  medias: Array<Media>;
  title: Scalars["String"];
};

export type ActivityPointsToRemember = {
  __typename?: "ActivityPointsToRemember";
  expando?: Maybe<ActivityExpando>;
  heading: Scalars["String"];
  headingGraphic?: Maybe<UiGraphic>;
  items: Array<Scalars["String"]>;
  key: Scalars["String"];
  sectionRef?: Maybe<Scalars["String"]>;
  sectionRefLink?: Maybe<ActivityRefClickAction>;
  structuredItems: Array<ActivityIcon>;
};

export type ActivityPopupClickAction = ActivityClickAction & {
  __typename?: "ActivityPopupClickAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  dismissAction?: Maybe<ActivityMessagingAction>;
  messages: Array<Scalars["String"]>;
  /** @deprecated use messages instead */
  text: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type ActivityPresentation = {
  __typename?: "ActivityPresentation";
  banners: Array<ActivityBanner>;
  detailsSectionGroups: Array<ActivitySectionGroup>;
  features?: Maybe<Array<ActivityIcon>>;
  legalDisclaimer?: Maybe<ActivityBanner>;
  localisationDisclaimer?: Maybe<ActivityBanner>;
  loyaltyPointsValue?: Maybe<Money>;
  partnerProvidedDetails?: Maybe<Array<ActivityPartnerProvidedDetails>>;
  /** @deprecated Use detailsSectionGroup instead */
  pointsToRemember: Array<ActivityPointsToRemember>;
  promotionMessage?: Maybe<ActivityPromotion>;
  staticMap?: Maybe<Scalars["URL"]>;
  title: Scalars["String"];
};

export type ActivityPrice = {
  __typename?: "ActivityPrice";
  /** @deprecated Use leadFormatted or leadAmount instead. */
  lead: Money;
  leadAmount: Scalars["Float"];
  leadFormatted: Scalars["String"];
  /** @deprecated Use strikeOutFormatted or strikeOutAmount instead. */
  strikeOut?: Maybe<Money>;
  strikeOutAmount?: Maybe<Scalars["Float"]>;
  strikeOutFormatted?: Maybe<Scalars["String"]>;
};

export type ActivityPriceDisplay = {
  __typename?: "ActivityPriceDisplay";
  disclaimer?: Maybe<ActivityDisclaimerDialog>;
  footer?: Maybe<Array<EgdsText>>;
  header?: Maybe<Array<EgdsText>>;
  priceLockup?: Maybe<EgdsPriceLockup>;
};

export type ActivityPriceMetadata = {
  __typename?: "ActivityPriceMetadata";
  discountPercent: Scalars["String"];
  discountType: ActivityDiscountType;
};

export type ActivityPriceType = "GROUP" | "NORMAL" | "VOLUME_BASED";

export interface ActivityProductInfoInput {
  offerToken: Scalars["String"];
}

export type ActivityPromotion = {
  __typename?: "ActivityPromotion";
  /** @deprecated Use clickAction instead */
  action?: Maybe<ActivityPromotionDetailsAction>;
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityMessageAction>;
  icon: Icon;
  mark?: Maybe<Mark>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: ActivityMessageType;
};

export type ActivityPromotionDetailsAction = {
  __typename?: "ActivityPromotionDetailsAction";
  primary: ActivityDetailsMessagingAction;
  secondary?: Maybe<ActivityDetailsMessagingAction>;
};

export type ActivityRadioGroupDialog = ActivityDialog & {
  __typename?: "ActivityRadioGroupDialog";
  dialog: EgdsFullScreenDialog;
  doneButton: UiPrimaryButton;
  heading?: Maybe<EgdsHeading>;
  radioGroup: EgdsBasicRadioGroup;
  trigger: ActivityDialogTrigger;
};

export type ActivityRecommendationDetails = {
  __typename?: "ActivityRecommendationDetails";
  enabled: Scalars["Boolean"];
  formatted: Icon;
  isSelected: Scalars["Boolean"];
  raw: Scalars["String"];
  size: FormattedNumber;
};

export type ActivityRecommendationsComponent = {
  __typename?: "ActivityRecommendationsComponent";
  carousel: ActivityCarouselContainer;
  heading?: Maybe<EgdsHeading>;
};

export type ActivityRedemption = {
  __typename?: "ActivityRedemption";
  locations?: Maybe<Array<Coordinates>>;
  type?: Maybe<ActivityRedemptionType>;
};

export type ActivityRedemptionType = "PRINT" | "VOUCHER_LESS";

export type ActivityRefClickAction = ActivityClickAction & {
  __typename?: "ActivityRefClickAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  label: Scalars["String"];
  sectionRef: Scalars["String"];
};

export type ActivityRegion = {
  __typename?: "ActivityRegion";
  fullRegionName?: Maybe<Scalars["String"]>;
  regionId: Scalars["String"];
  regionName?: Maybe<Scalars["String"]>;
};

export type ActivityRemoveOffersFromTripAction = UiAction & {
  __typename?: "ActivityRemoveOffersFromTripAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  offerNaturalKeys: Array<ActivityOfferNaturalKey>;
};

export type ActivityReview = {
  __typename?: "ActivityReview";
  /** @deprecated use formattedActivityDate instead */
  activityDate?: Maybe<DateTime>;
  author?: Maybe<Scalars["String"]>;
  expando?: Maybe<EgdsExpando>;
  formattedActivityDate?: Maybe<Scalars["String"]>;
  formattedSubmissionDate?: Maybe<Scalars["String"]>;
  helpfulVotes: ActivityReviewHelpfulVote;
  id: Scalars["String"];
  /** @deprecated Not being queried or used */
  locale: Scalars["Locale"];
  /** @deprecated Use overallRatingMessage instead */
  overallRating: FormattedNumber;
  /** @deprecated Use overallScoreMessage instead */
  overallRatingMessage?: Maybe<Scalars["String"]>;
  overallScoreMessage?: Maybe<EgdsText>;
  reviewedOn?: Maybe<Scalars["String"]>;
  /** @deprecated Use formattedSubmissionDate instead */
  submissionDate?: Maybe<DateTime>;
  text: Scalars["String"];
  userLocation?: Maybe<Scalars["String"]>;
};

export type ActivityReviewCommentsDialog = ActivityDialog & {
  __typename?: "ActivityReviewCommentsDialog";
  activityId: Scalars["String"];
  dialog: EgdsFullScreenDialog;
  trigger: ActivityDialogTrigger;
};

export type ActivityReviewDetailsComponent = ActivityReviewsComponent & {
  __typename?: "ActivityReviewDetailsComponent";
  comments: Array<ActivityReview>;
  heading: Scalars["String"];
  seeAllButton?: Maybe<ActivityReviewCommentsDialog>;
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  summary?: Maybe<ActivityReviewsSummary>;
};

export type ActivityReviewHelpfulVote = {
  __typename?: "ActivityReviewHelpfulVote";
  count: Scalars["Int"];
  icon: Icon;
  message: Scalars["String"];
};

export type ActivityReviewListComponent = ActivityReviewsComponent & {
  __typename?: "ActivityReviewListComponent";
  comments: Array<ActivityReview>;
  moreButton?: Maybe<ActivityReviewsPaginationButton>;
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  summary?: Maybe<ActivityReviewsSummary>;
};

export type ActivityReviewSort = "HIGHEST_TO_LOWEST_RATED" | "LOWEST_TO_HIGHEST_RATED" | "NEWEST_TO_OLDEST";

export interface ActivityReviewsComponent {
  comments: Array<ActivityReview>;
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  summary?: Maybe<ActivityReviewsSummary>;
}

export type ActivityReviewsInfo = {
  __typename?: "ActivityReviewsInfo";
  /** @deprecated Use reviewsComponent instead */
  comments: Array<ActivityReview>;
  reviewsComponent?: Maybe<ActivityReviewDetailsComponent>;
  reviewsDialog?: Maybe<ActivityReviewListComponent>;
  scores?: Maybe<Array<EgdsProgressBar>>;
  /** @deprecated Use reviewsComponent instead */
  showMoreButton?: Maybe<EgdsButton>;
  /** @deprecated Use reviewsComponent instead */
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  /** @deprecated Use reviewsComponent instead */
  summary?: Maybe<ActivityReviewsSummary>;
};

export type ActivityReviewsPaginationButton = {
  __typename?: "ActivityReviewsPaginationButton";
  action: ActivityPaginationInfoAction;
  button: EgdsButton;
};

export type ActivityReviewsSummary = {
  __typename?: "ActivityReviewsSummary";
  averageScore: EgdsText;
  averageScorePhraseParts?: Maybe<Array<EgdsStylizedText>>;
  blockWebCrawlersEnabled: Scalars["Boolean"];
  disclaimer?: Maybe<ActivityDisclaimerDialog>;
  /** @deprecated use `blockWebCrawlersEnabled` */
  isExternalSource: Scalars["Boolean"];
  reviewCommentsDialog?: Maybe<ActivityReviewCommentsDialog>;
  /** @deprecated use disclaimer instead */
  reviewCountMessage?: Maybe<Scalars["String"]>;
  /** @deprecated use accessibility from averageScore EGDSHeading instead */
  reviewRatingAccessibilityText?: Maybe<Scalars["String"]>;
  /** @deprecated use averageScore instead */
  reviewRatingMessage?: Maybe<Scalars["String"]>;
  reviewScoreBase?: Maybe<EgdsText>;
  /** @deprecated use averageScore instead */
  score: FormattedNumber;
  sectionRef?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  /** @deprecated use disclaimer instead */
  total: Scalars["String"];
  /** @deprecated use disclaimer instead */
  verifiedReviewsMessage?: Maybe<ActivityAnchoredMessage>;
};

export type ActivitySmsAction = ActivityNavigationBarClickAction & {
  __typename?: "ActivitySMSAction";
  accessibilityText: Scalars["String"];
  analytics: ClientSideAnalytics;
  smsBody: Scalars["String"];
};

export type ActivitySearchByKeywordTile = {
  __typename?: "ActivitySearchByKeywordTile";
  analytics?: Maybe<ActivityAnalytics>;
  clearAnalytics?: Maybe<ActivityAnalytics>;
  dismiss?: Maybe<Icon>;
  icon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export interface ActivitySearchCriteriaDateRangeInput {
  endDate: DateInput;
  startDate: DateInput;
}

export interface ActivitySearchCriteriaLocationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  pinnedActivityId?: InputMaybe<Scalars["String"]>;
  referencedActivityId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  regionName?: InputMaybe<Scalars["String"]>;
  regionType?: InputMaybe<Scalars["String"]>;
}

export type ActivitySearchFeature = {
  __typename?: "ActivitySearchFeature";
  /** @deprecated Use enhancedCleaningFeature instead */
  enhancedCleaning?: Maybe<ActivityIcon>;
  enhancedCleaningFeature?: Maybe<EgdsText>;
  /** @deprecated Use freeCancellationFeature instead */
  freeCancellation?: Maybe<ActivityFreeCancellation>;
  freeCancellationFeature?: Maybe<EgdsText>;
  hotelPickup?: Maybe<ActivityIcon>;
  likelyToSellOut?: Maybe<ActivityLikelyToSellOut>;
  loyaltyEarnAwards?: Maybe<ActivityIcon>;
  pinnedTopBottom?: Maybe<Pinned>;
  promotion?: Maybe<Icon>;
  selectedOfferDetails?: Maybe<Array<EgdsText>>;
  travelDistance?: Maybe<ActivityTravelDistance>;
  /** @deprecated Use travelDistance */
  travelDuration?: Maybe<ActivityTravelDuration>;
  volumePricing?: Maybe<Icon>;
};

export interface ActivitySearchFiltersInput {
  activityId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  selections?: InputMaybe<Array<ActivitySelectedValueInput>>;
}

export type ActivitySearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "ActivitySearchForm";
    actionURL: Scalars["String"];
    activitySearchFormInputIds: ActivitySearchFormInputIds;
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    subTitle?: Maybe<EgdsSpannableText>;
  };

export type ActivitySearchFormInputIds = {
  __typename?: "ActivitySearchFormInputIds";
  dateRangeInputId: Scalars["String"];
  destinationInputId: Scalars["String"];
};

export type ActivitySearchMap = {
  __typename?: "ActivitySearchMap";
  center: Coordinates;
  name: Scalars["String"];
  staticMap?: Maybe<Scalars["URL"]>;
  zoom: Scalars["Int"];
};

export type ActivitySearchOptions =
  | "CATEGORY"
  | "FILTER_NESTED_CATEGORIES"
  | "GAIA_RETURN_ADDITIONAL_DISTANCE_PARAMETER"
  | "GIFTING_ACTIVITIES"
  | "GUEST_PROMO"
  | "NON_CONSOLIDATED_OFFERS"
  | "SHOP_WITH_POINTS"
  | "SHOW_GT_BANNER";

export type ActivitySearchResults = {
  __typename?: "ActivitySearchResults";
  activities: Array<Activity>;
  activityGroups: Array<ActivityGroup>;
  categorySelection?: Maybe<CategorySelectionComponent>;
  map?: Maybe<ActivitySearchMap>;
  noMatchesFound?: Maybe<NoMatchesFoundComponent>;
  pageTitle?: Maybe<Scalars["String"]>;
  productGroups: Array<ProductGroupsContainer>;
  searchSummary: ActivitySearchSummary;
  /** @deprecated Use universalSortAndFilter which is EGDS compliant */
  sortAndFilter: ActivitySortAndFilter;
  sortDisclaimer?: Maybe<ActivityAnchoredMessage>;
  uisPayload: Scalars["String"];
  universalSortAndFilter?: Maybe<ShoppingSortAndFilters>;
};

export type ActivitySearchResultsActivityGroupsArgs = {
  types?: InputMaybe<Array<ActivityGroupType>>;
};

export type ActivitySearchResultsMapArgs = {
  type?: InputMaybe<ActivityMapGroupType>;
};

export type ActivitySearchSummary = {
  __typename?: "ActivitySearchSummary";
  aboveTheFold?: Maybe<ActivityAboveTheFold>;
  activitySize: Scalars["Int"];
  dateValidationResult?: Maybe<DateValidationResult>;
  marketSize: Scalars["Int"];
  messages?: Maybe<Array<ActivityMessageResult>>;
  pageHeading?: Maybe<Scalars["String"]>;
  pageInfo: ActivityPage;
  /** @deprecated Use pagination from activity group action */
  paginationInfo?: Maybe<ActivityPaginationInfo>;
  resolvedRegion?: Maybe<ActivityRegion>;
};

export type ActivitySearchTicket = {
  __typename?: "ActivitySearchTicket";
  code?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  legalDisclaimer?: Maybe<ActivityAnchoredMessage>;
  loyaltyPointsOption?: Maybe<ActivityLoyaltyPointsOption>;
  price?: Maybe<ActivityPrice>;
  priceAccessibilityText?: Maybe<Scalars["String"]>;
  taxesAndFeesMessage?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type ActivitySearchViewOptions =
  | "DISTANCE_FROM_HOTEL_TO_ACTIVITY"
  | "FILTER_NESTED_CATEGORIES"
  | "NEARBY_ACTIVITIES"
  | "SHOP_WITH_POINTS"
  | "SHOW_GT_BANNER"
  | "THIRD_PARTY_REVIEWS"
  | "TRAVEL_DISTANCE_FROM_HOTEL";

export type ActivitySection = {
  __typename?: "ActivitySection";
  heading: Scalars["String"];
  headingGraphic?: Maybe<UiGraphic>;
  sectionRefLink?: Maybe<ActivityRefClickAction>;
  structuredItems: Array<ActivityIcon>;
};

export type ActivitySectionGroup = {
  __typename?: "ActivitySectionGroup";
  footer?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  sectionRef?: Maybe<Scalars["String"]>;
  sections: Array<ActivitySection>;
};

export type ActivitySelectableFilterOption = ActivitySortAndFilterOption & {
  __typename?: "ActivitySelectableFilterOption";
  analytics?: Maybe<ActivitySortAndFilterAnalytics>;
  enabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  nestedOptions?: Maybe<Array<ActivitySelectableFilterOption>>;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
  value: Scalars["String"];
};

export type ActivitySelectedValue = {
  __typename?: "ActivitySelectedValue";
  id: Scalars["String"];
  value: Scalars["String"];
};

export interface ActivitySelectedValueInput {
  id: Scalars["String"];
  value: Scalars["String"];
}

export type ActivitySelectionClickAction = ActivityClickAction & {
  __typename?: "ActivitySelectionClickAction";
  accessibilityText: Scalars["String"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  selectionList?: Maybe<Array<ActivitySelectedValue>>;
};

export type ActivitySelectionField = ActivitySortAndFilterField & {
  __typename?: "ActivitySelectionField";
  id: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  options: Array<ActivitySelectableFilterOption>;
  udsToken: ActivityUdsToken;
};

export type ActivitySeoContent = {
  __typename?: "ActivitySeoContent";
  canonicalUrl?: Maybe<HttpUri>;
  hreflangLinks: Array<ActivityPageHrefLangLink>;
  metaTags: Array<ActivityPageSeoMetaTag>;
  title?: Maybe<Scalars["String"]>;
};

export type ActivityShortlistingMessages = {
  __typename?: "ActivityShortlistingMessages";
  removeFocusText?: Maybe<Scalars["String"]>;
  removeText?: Maybe<Scalars["String"]>;
  saveFocusText?: Maybe<Scalars["String"]>;
  saveText?: Maybe<Scalars["String"]>;
};

export type ActivitySort =
  | "CAMPAIGN_DEALS"
  | "DISCOUNT"
  | "DISTANCE_FROM_HOTEL"
  | "PRICE_HIGH_TO_LOW"
  | "PRICE_LOW_TO_HIGH"
  | "RECOMMENDED";

export type ActivitySortAndFilter = {
  __typename?: "ActivitySortAndFilter";
  appliedFilterCount?: Maybe<Scalars["Int"]>;
  /** @deprecated use sortAndFilterSections now as it's a more generic and powerful schema */
  categoryFilterOptions?: Maybe<Array<CategoryFilterViewModel>>;
  clearButtonTitle?: Maybe<Scalars["String"]>;
  closeIcon?: Maybe<Icon>;
  defaultSort?: Maybe<ActivitySort>;
  /** @deprecated use sortAndFilterSections now as it's a more generic and powerful schema */
  recommendationFilterOptions?: Maybe<Array<ActivityRecommendationDetails>>;
  /** @deprecated use sortAndFilterSections now as it's a more generic and powerful schema */
  sort: Array<ActivitySortViewModel>;
  sortAndFilterSections: Array<ActivitySortAndFilterSection>;
  title: Scalars["String"];
};

export type ActivitySortAndFilterAnalytics = {
  __typename?: "ActivitySortAndFilterAnalytics";
  linkName: Scalars["String"];
  referrerId: Scalars["String"];
};

export interface ActivitySortAndFilterField {
  id: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  udsToken: ActivityUdsToken;
}

export interface ActivitySortAndFilterOption {
  analytics?: Maybe<ActivitySortAndFilterAnalytics>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
}

export type ActivitySortAndFilterSection = {
  __typename?: "ActivitySortAndFilterSection";
  dismiss?: Maybe<Icon>;
  fields?: Maybe<Array<ActivitySortAndFilterField>>;
  title?: Maybe<Scalars["String"]>;
};

export type ActivitySortViewModel = {
  __typename?: "ActivitySortViewModel";
  formatted: Scalars["String"];
  raw: ActivitySort;
  selected: Scalars["Boolean"];
};

export type ActivityStaticDetailsCard = {
  __typename?: "ActivityStaticDetailsCard";
  content: Array<ActivityCardContent>;
  heading: EgdsHeading;
  sectionRef?: Maybe<Scalars["String"]>;
};

export type ActivityStaticDetailsComponent = {
  __typename?: "ActivityStaticDetailsComponent";
  sections: Array<ActivityStaticDetailsSection>;
};

export type ActivityStaticDetailsSection = ActivityItineraryCard | ActivityLocationCard | ActivityStaticDetailsCard;

export type ActivitySubMenu = EgdsSubMenu & {
  __typename?: "ActivitySubMenu";
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]>;
};

export type ActivitySubMenuItem = EgdsMenuListItem & {
  __typename?: "ActivitySubMenuItem";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<ActivityNavigationBarClickAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title: Scalars["String"];
};

export type ActivityTabbedNavigationBar = {
  __typename?: "ActivityTabbedNavigationBar";
  findTicketsAction?: Maybe<ActivityRefClickAction>;
  findTicketsButton?: Maybe<UiPrimaryButton>;
  heading?: Maybe<Scalars["String"]>;
  tabs: Array<NavigationTab>;
};

export type ActivityTelesales = {
  __typename?: "ActivityTelesales";
  analytics?: Maybe<ActivityAnalytics>;
  label: Scalars["String"];
  phoneNumberLink: TelUri;
};

export type ActivityText = {
  __typename?: "ActivityText";
  accessibilityText?: Maybe<Scalars["String"]>;
  size: ActivityTextSize;
  text: Scalars["String"];
};

export type ActivityTextAlignment = "CENTRE" | "LEFT" | "RIGHT";

export type ActivityTextInputSortField = ActivitySortAndFilterField & {
  __typename?: "ActivityTextInputSortField";
  id: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  placeholder: Scalars["String"];
  udsToken: ActivityUdsToken;
  value: Scalars["String"];
};

export type ActivityTextSize = "LARGE" | "MEDIUM" | "SMALL";

export type ActivityTextTile = {
  __typename?: "ActivityTextTile";
  /** @deprecated Will rename it 'Text' after cleanup */
  activityText?: Maybe<ActivityText>;
  alignment: ActivityTextAlignment;
  anchoredText?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated We use ActivityText now */
  size: ActivityTextSize;
  /** @deprecated We use ActivityText now */
  text: Scalars["String"];
};

export type ActivityThemeFilter = {
  __typename?: "ActivityThemeFilter";
  themes: Array<Scalars["String"]>;
};

export type ActivityThumbnailMediaGalleryDialog = ActivityDialog & {
  __typename?: "ActivityThumbnailMediaGalleryDialog";
  dialog: EgdsBasicThumbnailMediaGallery;
  trigger: ActivityDialogTrigger;
};

export interface ActivityTicketInput {
  code: Scalars["String"];
  count: Scalars["Int"];
}

export type ActivityTile =
  | Activity
  | ActivityActionTile
  | ActivityAssortedComponentsTile
  | ActivityBasicTile
  | ActivityEmptyStateTile
  | ActivityMessageTile
  | ActivitySearchByKeywordTile
  | ActivityTextTile;

export type ActivityTileBadges = {
  __typename?: "ActivityTileBadges";
  primary?: Maybe<ActivityBadge>;
  secondary?: Maybe<ActivityBadge>;
};

export type ActivityToggle = UiToggle & {
  __typename?: "ActivityToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel: Scalars["String"];
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription: Scalars["String"];
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  label: Scalars["String"];
  uncheckedAccessibilityLabel: Scalars["String"];
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription: Scalars["String"];
  uncheckedLabel: Scalars["String"];
};

export type ActivityTravelDistance = {
  __typename?: "ActivityTravelDistance";
  label?: Maybe<Scalars["String"]>;
};

export type ActivityTravelDuration = {
  __typename?: "ActivityTravelDuration";
  formatted?: Maybe<Scalars["String"]>;
};

export interface ActivityTravelerDetailsInput {
  age: Scalars["Int"];
  count: Scalars["Int"];
}

export interface ActivityTravelerInfoInput {
  numberOfAdults: Scalars["Int"];
  numberOfChildren: Scalars["Int"];
  numberOfInfants: Scalars["Int"];
}

export type ActivityTravelerSelectorDialog = ActivityDialog & {
  __typename?: "ActivityTravelerSelectorDialog";
  dialog: EgdsFullScreenDialog;
  doneButton: UiPrimaryButton;
  errorMessageForSelectedTickets?: Maybe<EgdsGraphicText>;
  heading?: Maybe<EgdsHeading>;
  ticketsStepInput: Array<EgdsTravelerStepInput>;
  trigger: ActivityDialogTrigger;
};

export type ActivityTripUpdateButtons = {
  __typename?: "ActivityTripUpdateButtons";
  addToTrip?: Maybe<EgdsButton>;
  removeFromTrip?: Maybe<EgdsButton>;
  updateTrip?: Maybe<EgdsButton>;
};

export type ActivityUdsToken = "MULTI_SELECT_LIST" | "MULTI_SELECT_RANGE" | "SINGLE_SELECT" | "TEXT";

export interface ActivityUpdateInput {
  newActivity: ActivityNaturalKeyInput;
  oldActivity: ActivityNaturalKeyInput;
}

export type ActivityUpdateOfferInTripAction = UiAction & {
  __typename?: "ActivityUpdateOfferInTripAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  newOfferNaturalKey: ActivityOfferNaturalKey;
  oldOfferNaturalKey: ActivityOfferNaturalKey;
};

export interface AdButton {
  accessibility?: Maybe<Scalars["String"]>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
}

export type AdButtonTypes = AdTransparencyButton;

export type AdTransparencyButton = AdButton & {
  __typename?: "AdTransparencyButton";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type AdTransparencyTrigger = {
  __typename?: "AdTransparencyTrigger";
  adTransparencyButton?: Maybe<AdButtonTypes>;
  adTransparencyId: Scalars["String"];
  adTransparencyOverlaySkeleton?: Maybe<TransparencyOverlaySkeleton>;
};

export type AdaptExEventProperty = {
  __typename?: "AdaptExEventProperty";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type AdaptexCampaignTrackingDetail = {
  __typename?: "AdaptexCampaignTrackingDetail";
  campaignId: Scalars["String"];
  eventTarget?: Maybe<Scalars["String"]>;
};

export interface AddInsuranceRequestInput {
  countryCode?: InputMaybe<Scalars["String"]>;
  insuranceProductIds: Array<Scalars["String"]>;
  regionCode?: InputMaybe<Scalars["String"]>;
  removeInsuranceProductIds?: InputMaybe<Array<Scalars["String"]>>;
  sourceId: Scalars["String"];
  updateRequestId: Scalars["String"];
}

export interface AddInsuranceRequestOldInput {
  insuranceSearchId: Scalars["String"];
  offerTypeId: Scalars["Int"];
}

export type AddInsuranceResponse = {
  __typename?: "AddInsuranceResponse";
  status: InsuranceMutationStatus;
};

export type AddInsuranceResponseOld = {
  __typename?: "AddInsuranceResponseOld";
  message: InsurancePhrase;
  status: InsuranceMutationStatus;
};

export type AddOnRequested = "CB1" | "COB" | "SC";

export interface AdditionalChangeQueryParamsInput {
  itineraryNumber?: InputMaybe<Scalars["String"]>;
  opaqueToken?: InputMaybe<Scalars["String"]>;
}

export type AdditionalInformation = {
  __typename?: "AdditionalInformation";
  durationAndStop: Scalars["String"];
  /** @deprecated This will be replaced by nextFlightOriginAirportInfo */
  nextFlightOriginAirport?: Maybe<Scalars["String"]>;
  nextFlightOriginAirportInfo?: Maybe<FlightsIconAndLabel>;
};

export type AdditionalInformationPopover = {
  __typename?: "AdditionalInformationPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  closeLabel?: Maybe<Scalars["String"]>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  /** @deprecated Use `enrichedSecondaries` instead. */
  secondaries: Array<Scalars["String"]>;
};

export type AdditionalInformationPopoverDialogSection = EgdsDialog & {
  __typename?: "AdditionalInformationPopoverDialogSection";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer?: Maybe<EgdsDialogFooter>;
  subSections: Array<PricePresentationSubSection>;
  title?: Maybe<Scalars["String"]>;
};

export type AdditionalInformationPopoverGridSection = {
  __typename?: "AdditionalInformationPopoverGridSection";
  subSections: Array<PricePresentationSubSection>;
};

export type AdditionalInformationPopoverListSection = {
  __typename?: "AdditionalInformationPopoverListSection";
  content: EgdsList;
};

export type AdditionalInformationPopoverSection =
  | AdditionalInformationPopoverDialogSection
  | AdditionalInformationPopoverGridSection
  | AdditionalInformationPopoverListSection
  | AdditionalInformationPopoverTextSection;

export type AdditionalInformationPopoverTextSection = {
  __typename?: "AdditionalInformationPopoverTextSection";
  text: EgdsText;
};

export interface AdditionalOnboardingContextInput {
  cmsToken?: InputMaybe<Scalars["String"]>;
  isFirstAppLaunch?: InputMaybe<Scalars["Boolean"]>;
  madCustomerPlacementId?: InputMaybe<Scalars["String"]>;
  originUrl?: InputMaybe<Scalars["String"]>;
  scenario?: InputMaybe<Scalars["String"]>;
}

export interface AdditionalOnboardingOperationContextInput {
  cmsToken?: InputMaybe<Scalars["String"]>;
  originUrl?: InputMaybe<Scalars["String"]>;
}

export interface AdditionalProductsInput {
  activityProductInfos?: InputMaybe<Array<ActivityProductInfoInput>>;
  additionalProductsOperationType: AdditionalProductsOperationType;
}

export type AdditionalProductsOperationType = "ADD" | "REMOVE";

export type AdditionalShoppedAncillaries = "FIRST_CHECKED_BAG";

export type AdditionalShopping = "FIRST_CHECKED_BAG";

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  firstLine: Scalars["String"];
  province?: Maybe<Scalars["String"]>;
  secondLine?: Maybe<Scalars["String"]>;
  thirdLine?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
};

export type AdsTargetingData = {
  __typename?: "AdsTargetingData";
  adProvider: Scalars["String"];
  dateEnd?: Maybe<Scalars["String"]>;
  dateStart?: Maybe<Scalars["String"]>;
  dest?: Maybe<Scalars["String"]>;
  locResolver?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  pageName: Scalars["String"];
  siteId: Scalars["Int"];
  uuid: Scalars["String"];
};

export type AdvertiserReportingContext = "FLIGHTS";

export type AdvertiserReportingForm = {
  __typename?: "AdvertiserReportingForm";
  downloadButton: DownloadButtonWithDialog;
  filterOptions: ReportingOptionFilters;
  filtersAppliedToggle: EgdsExpandoPeek;
  header: AdvertiserReportingFormHeader;
  refreshButton: UiPrimaryButton;
};

export type AdvertiserReportingFormHeader = {
  __typename?: "AdvertiserReportingFormHeader";
  primaryText: Scalars["String"];
  secondaryText?: Maybe<Scalars["String"]>;
};

export interface AffiliatesAnalyticEvent {
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
}

export type AffiliatesAnalyticsInfo = {
  __typename?: "AffiliatesAnalyticsInfo";
  alternateProperties?: Maybe<AffiliatesCarousel>;
  avgStay?: Maybe<AffiliatesContent>;
  guestReviews?: Maybe<AffiliatesReviews>;
  popularPlacesToVisit?: Maybe<AffiliatesCarousel>;
  title: Scalars["String"];
};

export type AffiliatesBackAction = {
  __typename?: "AffiliatesBackAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesBadge = {
  __typename?: "AffiliatesBadge";
  badge: EgdsStandardBadge;
};

export type AffiliatesButton = {
  __typename?: "AffiliatesButton";
  action: AffiliatesButtonAction;
  button: EgdsButton;
  showActionProgress?: Maybe<Scalars["Boolean"]>;
};

export type AffiliatesButtonAction =
  | AffiliatesBackAction
  | AffiliatesCancelAction
  | AffiliatesCloseAction
  | AffiliatesCloseToolbarAction
  | AffiliatesCopyLinkAction
  | AffiliatesCreateLinkAction
  | AffiliatesCreateTagSaveAction
  | AffiliatesDownloadImageAction
  | AffiliatesLinkAccountAction
  | AffiliatesNavigateAction
  | AffiliatesOpenCloseToolbarDialogAction
  | AffiliatesOpenPartnerDetailsAction
  | AffiliatesOpenToolboxAction
  | AffiliatesOutwardLinkAction
  | AffiliatesShareLinkAction
  | AffiliatesShowDownloadImagesGalleryFormAction
  | AffiliatesShowHighlightedReviewsInfoAction
  | AffiliatesSignInFirstAction
  | AffiliatesVanityLinkSaveAction
  | AffiliatesViewStatsAction;

export type AffiliatesCancelAction = {
  __typename?: "AffiliatesCancelAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCarousel = {
  __typename?: "AffiliatesCarousel";
  items: Array<AffiliatesCarouselItem>;
  nextButton: AffiliatesPagingButton;
  previousButton: AffiliatesPagingButton;
  title: Scalars["String"];
};

export type AffiliatesCarouselItem = AffiliatesImageCarouselItem | AffiliatesLodgingCardCarouselItem;

export type AffiliatesCarouselNextAction = {
  __typename?: "AffiliatesCarouselNextAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCarouselPrevAction = {
  __typename?: "AffiliatesCarouselPrevAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesChannelType = "ANDROID" | "IOS" | "WEB";

export type AffiliatesCheckFailure = {
  __typename?: "AffiliatesCheckFailure";
  failureType: AffiliatesCheckFailureType;
};

export type AffiliatesCheckFailureType = "PARTNER_NOT_LINKED" | "STATUS_NOT_APPROVED" | "TOOL_DISABLED";

export interface AffiliatesClientContextInput {
  deviceContext?: InputMaybe<AffiliatesDeviceContextInput>;
  lineOfBusiness: LineOfBusinessDomain;
  pageId?: InputMaybe<Scalars["String"]>;
  pageLocation: PageLocation;
  partnerContext?: InputMaybe<AffiliatesPartnerContextInput>;
}

export type AffiliatesCloseAction = {
  __typename?: "AffiliatesCloseAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCloseToolbarAction = {
  __typename?: "AffiliatesCloseToolbarAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesCloseToolbarDialog = {
  __typename?: "AffiliatesCloseToolbarDialog";
  cancelButton: AffiliatesButton;
  closeToolbarButton: AffiliatesButton;
  content: Array<AffiliatesSpannableText>;
  title: Scalars["String"];
};

export type AffiliatesCloseToolbarFailureResponse = {
  __typename?: "AffiliatesCloseToolbarFailureResponse";
  banner: UiBanner;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesCloseToolbarResponse =
  | AffiliatesCloseToolbarFailureResponse
  | AffiliatesCloseToolbarSuccessResponse;

export type AffiliatesCloseToolbarSuccessResponse = {
  __typename?: "AffiliatesCloseToolbarSuccessResponse";
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesCoachMark = {
  __typename?: "AffiliatesCoachMark";
  autoDismissAfterSecs: Scalars["Int"];
  dismissButton?: Maybe<AffiliatesButton>;
  heading: Scalars["String"];
  instructions: Array<AffiliatesSpannableText>;
};

export interface AffiliatesCollectionContextInput {
  collectionId: Scalars["String"];
  shopId: Scalars["String"];
}

export type AffiliatesCommissionInfo = {
  __typename?: "AffiliatesCommissionInfo";
  avgBookingValue?: Maybe<AffiliatesCommissionInfoItem>;
  brandDomain: Scalars["String"];
  brandLogo: Mark;
  commissionRate: AffiliatesCommissionInfoItem;
  estimatedCommission?: Maybe<AffiliatesCommissionInfoItem>;
  title: Scalars["String"];
  validityPeriod: AffiliatesCommissionInfoItem;
};

export type AffiliatesCommissionInfoItem = {
  __typename?: "AffiliatesCommissionInfoItem";
  title: Scalars["String"];
  value: Scalars["String"];
};

export type AffiliatesConfirmAccountLinkFailureResponse = {
  __typename?: "AffiliatesConfirmAccountLinkFailureResponse";
  banner: UiBanner;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesConfirmAccountLinkResponse =
  | AffiliatesConfirmAccountLinkFailureResponse
  | AffiliatesConfirmAccountLinkSuccessResponse;

export type AffiliatesConfirmAccountLinkSuccessResponse = {
  __typename?: "AffiliatesConfirmAccountLinkSuccessResponse";
  affiliatesState: AffiliatesState;
  closeAction: AffiliatesCloseAction;
  goToAccountButton: AffiliatesButton;
  heading: Scalars["String"];
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  message: Scalars["String"];
  toolboxInstruction: Scalars["String"];
  toolboxPreviewImage: Image;
};

export type AffiliatesConnectionInfo = {
  __typename?: "AffiliatesConnectionInfo";
  companyName: Scalars["String"];
  companyNameLabel: Scalars["String"];
  emailAddress: Scalars["String"];
  emailAddressLabel: Scalars["String"];
  title: Scalars["String"];
};

export type AffiliatesContent = {
  __typename?: "AffiliatesContent";
  description?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type AffiliatesCopyLinkAction = {
  __typename?: "AffiliatesCopyLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  toast: EgdsToast;
  url: Scalars["String"];
};

export type AffiliatesCreateAffiliateTagFailureResponse = {
  __typename?: "AffiliatesCreateAffiliateTagFailureResponse";
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesCreateAffiliateTagRequestInput {
  userText: Scalars["String"];
}

export type AffiliatesCreateAffiliateTagResponse =
  | AffiliatesCreateAffiliateTagFailureResponse
  | AffiliatesCreateAffiliateTagSuccessResponse;

export type AffiliatesCreateAffiliateTagSuccessResponse = {
  __typename?: "AffiliatesCreateAffiliateTagSuccessResponse";
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  switch: AffiliatesSwitch;
  vanityLinkForm: AffiliatesVanityLinkForm;
};

export type AffiliatesCreateLinkAction = {
  __typename?: "AffiliatesCreateLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesCreateLinkDefaultSuccessResponse = {
  __typename?: "AffiliatesCreateLinkDefaultSuccessResponse";
  closeAction: AffiliatesCloseAction;
  createTagForm?: Maybe<AffiliatesCreateTagForm>;
  descriptiveLinkForm: AffiliatesDescriptiveLinkForm;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  onloadAction: AffiliatesOnloadAction;
  pageInfo: AffiliatesPageInfo;
  shareToBlog: AffiliatesShareToBlog;
  statsLink?: Maybe<AffiliatesStandardLink>;
  statusMessage: AffiliatesIconText;
  switch?: Maybe<AffiliatesSwitch>;
  vanityLinkForm?: Maybe<AffiliatesVanityLinkForm>;
};

export type AffiliatesCreateLinkFailureResponse = {
  __typename?: "AffiliatesCreateLinkFailureResponse";
  banner: UiBanner;
  closeAction: AffiliatesCloseAction;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  statsLink?: Maybe<AffiliatesStandardLink>;
};

export type AffiliatesCreateLinkLodgingSuccessResponse = {
  __typename?: "AffiliatesCreateLinkLodgingSuccessResponse";
  closeAction: AffiliatesCloseAction;
  createTagForm?: Maybe<AffiliatesCreateTagForm>;
  descriptiveLinkForm: AffiliatesDescriptiveLinkForm;
  downloadImages?: Maybe<AffiliatesDownloadImages>;
  downloadImagesGalleryForm?: Maybe<AffiliatesDownloadImagesGalleryForm>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  lodgingInfo: AffiliatesLodgingInfo;
  onloadAction: AffiliatesOnloadAction;
  shareToBlog: AffiliatesShareToBlog;
  statsLink?: Maybe<AffiliatesStandardLink>;
  statusMessage: AffiliatesIconText;
  switch?: Maybe<AffiliatesSwitch>;
  vanityLinkForm?: Maybe<AffiliatesVanityLinkForm>;
};

export interface AffiliatesCreateLinkRequestInput {
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
  targetUrl: Scalars["String"];
}

export type AffiliatesCreateLinkResponse =
  | AffiliatesCreateLinkDefaultSuccessResponse
  | AffiliatesCreateLinkFailureResponse
  | AffiliatesCreateLinkLodgingSuccessResponse;

export type AffiliatesCreateTagExampleCustomLink = {
  __typename?: "AffiliatesCreateTagExampleCustomLink";
  defaultTag: Scalars["String"];
  description: AffiliatesSpannableText;
  label: Scalars["String"];
  tagPlaceholder: Scalars["String"];
  url: Scalars["String"];
};

export type AffiliatesCreateTagForm = {
  __typename?: "AffiliatesCreateTagForm";
  backButton: AffiliatesButton;
  createTagButton: AffiliatesButton;
  description: Array<AffiliatesSpannableText>;
  exampleCustomLink: AffiliatesCreateTagExampleCustomLink;
  serviceFailureError?: Maybe<Scalars["String"]>;
  tagHandle: AffiliatesTextInputField;
  title: Scalars["String"];
};

export type AffiliatesCreateTagSaveAction = {
  __typename?: "AffiliatesCreateTagSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCreateVanityLinkFailureResponse = {
  __typename?: "AffiliatesCreateVanityLinkFailureResponse";
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  statusMessage: AffiliatesIconText;
};

export interface AffiliatesCreateVanityLinkRequestInput {
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
  targetUrl: Scalars["String"];
  userText: Scalars["String"];
}

export type AffiliatesCreateVanityLinkResponse =
  | AffiliatesCreateVanityLinkFailureResponse
  | AffiliatesCreateVanityLinkSuccessResponse;

export type AffiliatesCreateVanityLinkSuccessResponse = {
  __typename?: "AffiliatesCreateVanityLinkSuccessResponse";
  copyButton: AffiliatesTransitionButton;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  label: Scalars["String"];
  onloadAction: AffiliatesOnloadAction;
  shareButton: AffiliatesButton;
  statusMessage: AffiliatesIconText;
  url: Scalars["String"];
};

export type AffiliatesDescriptiveLinkForm = {
  __typename?: "AffiliatesDescriptiveLinkForm";
  copyButton: AffiliatesTransitionButton;
  label: Scalars["String"];
  shareButton: AffiliatesButton;
  url: Scalars["String"];
};

export interface AffiliatesDeviceContextInput {
  appVersion?: InputMaybe<Scalars["String"]>;
  channelType?: InputMaybe<AffiliatesChannelType>;
}

export type AffiliatesDownloadImageAction = {
  __typename?: "AffiliatesDownloadImageAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesDownloadImages = {
  __typename?: "AffiliatesDownloadImages";
  description: AffiliatesSpannableText;
  openDownloadImagesGalleryFormButton: AffiliatesButton;
  title: Scalars["String"];
};

export type AffiliatesDownloadImagesGalleryForm = {
  __typename?: "AffiliatesDownloadImagesGalleryForm";
  backButton: AffiliatesButton;
  description: AffiliatesSpannableText;
  images: Array<AffiliatesDownloadableImage>;
  pagination?: Maybe<AffiliatesPagination>;
  title: Scalars["String"];
};

export type AffiliatesDownloadableImage = {
  __typename?: "AffiliatesDownloadableImage";
  defaultButton: AffiliatesButton;
  downloadUrl: Scalars["String"];
  image: Image;
  successButton: AffiliatesButton;
};

export type AffiliatesFieldError = {
  __typename?: "AffiliatesFieldError";
  errorMessage: AffiliatesSpannableText;
  identifier: Scalars["String"];
};

export type AffiliatesHighlightedReview = {
  __typename?: "AffiliatesHighlightedReview";
  date: Scalars["String"];
  rating: Scalars["String"];
  reviewerName: Scalars["String"];
  text: Scalars["String"];
  textControl: EgdsExpandoPeek;
  title: Scalars["String"];
};

export type AffiliatesHighlightedReviews = {
  __typename?: "AffiliatesHighlightedReviews";
  criticalReview?: Maybe<AffiliatesHighlightedReview>;
  infoButton: AffiliatesButton;
  infoDialog: AffiliatesInfoDialog;
  positiveReview?: Maybe<AffiliatesHighlightedReview>;
  title: Scalars["String"];
};

export type AffiliatesIconText = {
  __typename?: "AffiliatesIconText";
  graphic: UiGraphic;
  text: EgdsStylizedText;
};

export type AffiliatesImageCarouselItem = {
  __typename?: "AffiliatesImageCarouselItem";
  card: EgdsImageCard;
  cardLinkAction: AffiliatesLinkAction;
};

export type AffiliatesImpressionAnalyticEvent = AffiliatesAnalyticEvent & {
  __typename?: "AffiliatesImpressionAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type AffiliatesInfoDialog = {
  __typename?: "AffiliatesInfoDialog";
  closeButton: AffiliatesButton;
  message: AffiliatesSpannableText;
};

export type AffiliatesInitiateAccountLinkConnectionExistsResponse = {
  __typename?: "AffiliatesInitiateAccountLinkConnectionExistsResponse";
  affiliatesState: AffiliatesState;
  closeAction: AffiliatesCloseAction;
  continueButton: AffiliatesButton;
  heading: Scalars["String"];
  icon: Icon;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  message: Scalars["String"];
};

export type AffiliatesInitiateAccountLinkFailureResponse = {
  __typename?: "AffiliatesInitiateAccountLinkFailureResponse";
  closeAction: AffiliatesCloseAction;
  heading: Scalars["String"];
  icon: Icon;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  message: Scalars["String"];
};

export type AffiliatesInitiateAccountLinkResponse =
  | AffiliatesInitiateAccountLinkConnectionExistsResponse
  | AffiliatesInitiateAccountLinkFailureResponse
  | AffiliatesInitiateAccountLinkSignInResponse
  | AffiliatesInitiateAccountLinkSuccessResponse;

export type AffiliatesInitiateAccountLinkSignInResponse = {
  __typename?: "AffiliatesInitiateAccountLinkSignInResponse";
  affiliatesLogo: Mark;
  brandLogo: Mark;
  cancelButton: AffiliatesButton;
  closeAction: AffiliatesCloseAction;
  heading: Scalars["String"];
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  linkingIcon: Icon;
  message: Scalars["String"];
  signInButton: AffiliatesButton;
};

export type AffiliatesInitiateAccountLinkSuccessResponse = {
  __typename?: "AffiliatesInitiateAccountLinkSuccessResponse";
  affiliatesConnectionInfo: AffiliatesConnectionInfo;
  affiliatesLogo: Mark;
  brandLogo: Mark;
  cancelButton: AffiliatesButton;
  closeAction: AffiliatesCloseAction;
  connectButton: AffiliatesButton;
  heading: Scalars["String"];
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  linkingIcon: Icon;
  message: Scalars["String"];
};

export type AffiliatesInlineLink = AffiliatesSpannableTextItem & {
  __typename?: "AffiliatesInlineLink";
  action: AffiliatesLinkAction;
  disabled?: Maybe<Scalars["Boolean"]>;
  text: Scalars["String"];
};

export type AffiliatesInteractionAnalyticEvent = AffiliatesAnalyticEvent & {
  __typename?: "AffiliatesInteractionAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type AffiliatesLinkAccountAction = {
  __typename?: "AffiliatesLinkAccountAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesLinkAction = AffiliatesCreateLinkAction | AffiliatesOutwardLinkAction | AffiliatesViewStatsAction;

export type AffiliatesLodgingCardCarouselItem = {
  __typename?: "AffiliatesLodgingCardCarouselItem";
  amenities?: Maybe<Array<AffiliatesIconText>>;
  cardLinkAction: AffiliatesLinkAction;
  id?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  overAllDescription?: Maybe<Scalars["String"]>;
  overAllRating?: Maybe<Scalars["String"]>;
  reviewsStat?: Maybe<Scalars["String"]>;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type AffiliatesLodgingInfo = {
  __typename?: "AffiliatesLodgingInfo";
  propertyImage?: Maybe<Image>;
  propertyName: Scalars["String"];
};

export type AffiliatesLodgingStatsSuccessResponse = {
  __typename?: "AffiliatesLodgingStatsSuccessResponse";
  analyticsInfo?: Maybe<AffiliatesAnalyticsInfo>;
  closeAction: AffiliatesCloseAction;
  commissionInfo?: Maybe<AffiliatesCommissionInfo>;
  getLink: AffiliatesStandardLink;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  lodgingInfo: AffiliatesLodgingInfo;
};

export type AffiliatesLogo = {
  __typename?: "AffiliatesLogo";
  action: AffiliatesOutwardLinkAction;
  mark: Mark;
};

export type AffiliatesNavigateAction = {
  __typename?: "AffiliatesNavigateAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  destination: Scalars["String"];
};

export type AffiliatesOnloadAction = AffiliatesCopyLinkAction;

export type AffiliatesOpenCloseToolbarDialogAction = {
  __typename?: "AffiliatesOpenCloseToolbarDialogAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenPartnerDetailsAction = {
  __typename?: "AffiliatesOpenPartnerDetailsAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenToolboxAction = {
  __typename?: "AffiliatesOpenToolboxAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOutwardLinkAction = {
  __typename?: "AffiliatesOutwardLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  url: Scalars["String"];
};

export type AffiliatesPageInfo = {
  __typename?: "AffiliatesPageInfo";
  pageImage?: Maybe<Image>;
  pageName: Scalars["String"];
};

export type AffiliatesPagination = {
  __typename?: "AffiliatesPagination";
  label?: Maybe<Scalars["String"]>;
  labelEndPlaceHolder?: Maybe<Scalars["String"]>;
  labelStartPlaceHolder?: Maybe<Scalars["String"]>;
  nextButton: AffiliatesPagingButton;
  pageSize: Scalars["Int"];
  prevButton: AffiliatesPagingButton;
};

export type AffiliatesPagingAction =
  | AffiliatesCarouselNextAction
  | AffiliatesCarouselPrevAction
  | AffiliatesPagingNextAction
  | AffiliatesPagingPrevAction;

export type AffiliatesPagingButton = {
  __typename?: "AffiliatesPagingButton";
  accessibility?: Maybe<Scalars["String"]>;
  action: AffiliatesPagingAction;
  disabled?: Maybe<Scalars["Boolean"]>;
  iconId: Scalars["String"];
  iconTitle: Scalars["String"];
};

export type AffiliatesPagingNextAction = {
  __typename?: "AffiliatesPagingNextAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesPagingPrevAction = {
  __typename?: "AffiliatesPagingPrevAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesPartnerContextInput {
  token?: InputMaybe<Scalars["String"]>;
}

export type AffiliatesPartnerDetails = {
  __typename?: "AffiliatesPartnerDetails";
  account: AffiliatesStandardLink;
  closeToolbarDialog: AffiliatesCloseToolbarDialog;
  email: Scalars["String"];
  help: AffiliatesStandardLink;
  linkHistory: AffiliatesStandardLink;
  name: Scalars["String"];
  openCloseToolbarDialogButton: AffiliatesButton;
};

export interface AffiliatesPropertyContextInput {
  egPropertyId?: InputMaybe<Scalars["String"]>;
  hotelsPropertyId?: InputMaybe<Scalars["String"]>;
}

export type AffiliatesRatings = {
  __typename?: "AffiliatesRatings";
  categories: Array<AffiliatesContent>;
  grades: Array<EgdsProgressBar>;
  overAllDescription: Scalars["String"];
  overAllRating: Scalars["String"];
  title: Scalars["String"];
};

export type AffiliatesRemoveTripItemAction = {
  __typename?: "AffiliatesRemoveTripItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesReviews = {
  __typename?: "AffiliatesReviews";
  highlightedReviews?: Maybe<AffiliatesHighlightedReviews>;
  ratings?: Maybe<AffiliatesRatings>;
};

export type AffiliatesSaveTripItemAction = {
  __typename?: "AffiliatesSaveTripItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShareLinkAction = {
  __typename?: "AffiliatesShareLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  url: Scalars["String"];
};

export type AffiliatesShareToBlog = {
  __typename?: "AffiliatesShareToBlog";
  buttons: Array<AffiliatesShareToBlogButton>;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type AffiliatesShareToBlogButton = {
  __typename?: "AffiliatesShareToBlogButton";
  action: AffiliatesOutwardLinkAction;
  mark: Mark;
};

export type AffiliatesShowCreateTagFormAction = {
  __typename?: "AffiliatesShowCreateTagFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowDescriptiveLinkFormAction = {
  __typename?: "AffiliatesShowDescriptiveLinkFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowDownloadImagesGalleryFormAction = {
  __typename?: "AffiliatesShowDownloadImagesGalleryFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowHighlightedReviewsInfoAction = {
  __typename?: "AffiliatesShowHighlightedReviewsInfoAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowVanityLinkFormAction = {
  __typename?: "AffiliatesShowVanityLinkFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSignInFirstAction = {
  __typename?: "AffiliatesSignInFirstAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSpannableText = {
  __typename?: "AffiliatesSpannableText";
  inlineContent: Array<AffiliatesSpannableTextItem>;
};

export interface AffiliatesSpannableTextItem {
  text: Scalars["String"];
}

export type AffiliatesStandardLink = {
  __typename?: "AffiliatesStandardLink";
  action: AffiliatesLinkAction;
  disabled?: Maybe<Scalars["Boolean"]>;
  subtext?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type AffiliatesState = {
  __typename?: "AffiliatesState";
  token: Scalars["String"];
};

export type AffiliatesStateCheckFailureResponse = {
  __typename?: "AffiliatesStateCheckFailureResponse";
  affiliatesCheckFailure?: Maybe<AffiliatesCheckFailure>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesStateCheckResponse = AffiliatesStateCheckFailureResponse | AffiliatesStateCheckSuccessResponse;

export type AffiliatesStateCheckSuccessResponse = {
  __typename?: "AffiliatesStateCheckSuccessResponse";
  affiliatesState: AffiliatesState;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesStatsFailureResponse = {
  __typename?: "AffiliatesStatsFailureResponse";
  banner: UiBanner;
  closeAction: AffiliatesCloseAction;
  getLink: AffiliatesStandardLink;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesStatsRequestInput {
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
}

export type AffiliatesStatsResponse = AffiliatesLodgingStatsSuccessResponse | AffiliatesStatsFailureResponse;

export type AffiliatesStripeFailureResponse = {
  __typename?: "AffiliatesStripeFailureResponse";
  affiliatesCheckFailure?: Maybe<AffiliatesCheckFailure>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesStripeResponse = AffiliatesStripeFailureResponse | AffiliatesStripeSuccessResponse;

export type AffiliatesStripeSuccessResponse = {
  __typename?: "AffiliatesStripeSuccessResponse";
  getLinkButton: AffiliatesButton;
  getLinkCoachMark: AffiliatesCoachMark;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  logo: AffiliatesLogo;
  openPartnerDetailsButton: AffiliatesButton;
  partnerDetails: AffiliatesPartnerDetails;
  statsButton: AffiliatesButton;
  statsCoachMark: AffiliatesCoachMark;
  toolboxButton: AffiliatesButton;
  toolboxCoachMark: AffiliatesCoachMark;
};

export type AffiliatesSwitch = {
  __typename?: "AffiliatesSwitch";
  offAction: AffiliatesSwitchAction;
  onAction: AffiliatesSwitchAction;
  switch: EgdsStandardSwitch;
};

export type AffiliatesSwitchAction =
  | AffiliatesShowCreateTagFormAction
  | AffiliatesShowDescriptiveLinkFormAction
  | AffiliatesShowVanityLinkFormAction;

export type AffiliatesText = AffiliatesSpannableTextItem & {
  __typename?: "AffiliatesText";
  text: Scalars["String"];
};

export type AffiliatesTextFieldSelectionAction = {
  __typename?: "AffiliatesTextFieldSelectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesTextInputField = {
  __typename?: "AffiliatesTextInputField";
  handle: EgdsTextInputField;
  selectionAction: AffiliatesTextFieldSelectionAction;
};

export type AffiliatesTransitionButton = {
  __typename?: "AffiliatesTransitionButton";
  defaultButton: AffiliatesButton;
  transitionButton: AffiliatesButton;
  transitionInSecs: Scalars["Int"];
  transitionOnRender?: Maybe<Scalars["Boolean"]>;
};

export type AffiliatesTravelerCollection = {
  __typename?: "AffiliatesTravelerCollection";
  collectionId: Scalars["String"];
  description: Scalars["String"];
  items: Array<AffiliatesTravelerCollectionItem>;
  name: Scalars["String"];
  shareButton: AffiliatesButton;
};

export type AffiliatesTravelerCollectionDetailsFailureResponse = {
  __typename?: "AffiliatesTravelerCollectionDetailsFailureResponse";
  banner: UiBanner;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesTravelerCollectionDetailsRequestInput {
  collectionContext: AffiliatesCollectionContextInput;
}

export type AffiliatesTravelerCollectionDetailsResponse =
  | AffiliatesTravelerCollectionDetailsFailureResponse
  | AffiliatesTravelerCollectionDetailsSuccessResponse;

export type AffiliatesTravelerCollectionDetailsSuccessResponse = {
  __typename?: "AffiliatesTravelerCollectionDetailsSuccessResponse";
  closeAction: AffiliatesCloseAction;
  collectionDetails: AffiliatesTravelerCollection;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  shopDetails: AffiliatesTravelerShop;
};

export type AffiliatesTravelerCollectionItem = {
  __typename?: "AffiliatesTravelerCollectionItem";
  bookNowButton: AffiliatesButton;
  city: Scalars["String"];
  description: Scalars["String"];
  image: Image;
  itemId: Scalars["String"];
  name: Scalars["String"];
  rating: AffiliatesBadge;
  ratingDescription: Scalars["String"];
  removeTripItemAction: AffiliatesTripsSaveItemAction;
  saveTripItemAction: AffiliatesTripsSaveItemAction;
  shareButton: AffiliatesButton;
  tripsSaveItem: TripsSaveItem;
};

export type AffiliatesTravelerShop = {
  __typename?: "AffiliatesTravelerShop";
  creatorImage: Image;
  creatorName: Scalars["String"];
  shopId: Scalars["String"];
};

export type AffiliatesTripsSaveItemAction = AffiliatesRemoveTripItemAction | AffiliatesSaveTripItemAction;

export type AffiliatesVanityLinkForm = {
  __typename?: "AffiliatesVanityLinkForm";
  cancelButton: AffiliatesButton;
  pageHandle: AffiliatesTextInputField;
  saveButton: AffiliatesButton;
  serviceFailureError?: Maybe<Scalars["String"]>;
};

export type AffiliatesVanityLinkSaveAction = {
  __typename?: "AffiliatesVanityLinkSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  statusMessage: AffiliatesIconText;
};

export type AffiliatesViewStatsAction = {
  __typename?: "AffiliatesViewStatsAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffirmContentDetails = {
  __typename?: "AffirmContentDetails";
  elementAttributes: Array<InstallmentPlanAttribute>;
  link: InstallmentPlanLink;
  logo: UiGraphic;
  text: EgdsStylizedText;
};

export type AffirmDetails = {
  __typename?: "AffirmDetails";
  analytics: ClientSideAnalytics;
  elementAttributes: Array<InstallmentPlanAttribute>;
};

export type AffirmInstallmentPlanDetails = {
  __typename?: "AffirmInstallmentPlanDetails";
  apiKey: Scalars["String"];
  elementAttributes: Array<InstallmentPlanAttribute>;
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  scriptUrl: Scalars["String"];
};

export type AgencyBusinessModel = "EXPEDIA_COLLECT" | "HOTEL_COLLECT";

export type AgencyDetail = {
  __typename?: "AgencyDetail";
  agencyEntryFields: Array<EgdsInputField>;
  logo: AgencyImage;
};

export type AgencyImage = {
  __typename?: "AgencyImage";
  altText?: Maybe<Scalars["String"]>;
  logoToggle: UiToggle;
  url?: Maybe<Uri>;
};

export type AgencyLogo = {
  __typename?: "AgencyLogo";
  altText?: Maybe<Scalars["String"]>;
  logoToggle: UiToggle;
  url?: Maybe<Uri>;
};

export type AgencyPropertyUnitKey = {
  __typename?: "AgencyPropertyUnitKey";
  lodgingType: Scalars["String"];
  packageRates?: Maybe<Scalars["Boolean"]>;
  propertyDestination: Scalars["String"];
  propertyNaturalKey: PropertyNaturalKey;
};

export type AgencyQuoteAction = UiAction & {
  __typename?: "AgencyQuoteAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionIdentifier: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type AgencyQuoteBuilderClear = {
  __typename?: "AgencyQuoteBuilderClear";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  clearButton: UiSecondaryButton;
};

export type AgencyQuoteBuilderCollapsedButton = {
  __typename?: "AgencyQuoteBuilderCollapsedButton";
  /** @deprecated Floating button for a collapsed side QuoteBuilder */
  collapsedButton?: Maybe<UiPrimaryFloatingActionButton>;
  expandAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  floatingButton: UiSecondaryFloatingActionButton;
};

export type AgencyQuoteBuilderExpandLink = {
  __typename?: "AgencyQuoteBuilderExpandLink";
  collapseAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  collapsedLabel?: Maybe<Scalars["String"]>;
  expandAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  expandedLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Simplified to just label and analytics without EGDSExpandoLink class */
  expandoLink: EgdsExpandoLink;
  /** @deprecated Replacing with toolbarNavType to be string */
  navType: ToolbarNavType;
  toolbarNavType: Scalars["String"];
};

export type AgencyQuoteBuilderGenerateQuote = {
  __typename?: "AgencyQuoteBuilderGenerateQuote";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  generateQuoteButton: UiPrimaryButton;
};

export type AgencyQuoteBuilderHeader = {
  __typename?: "AgencyQuoteBuilderHeader";
  /** @deprecated Replaced by clearClickstream */
  clear: UiSecondaryButton;
  clearClickstream: AgencyQuoteBuilderClear;
  /** @deprecated Replaced by expandLinkClickstream */
  expandLink: EgdsExpandoLink;
  expandLinkClickstream: AgencyQuoteBuilderExpandLink;
  /** @deprecated Replaced by generateQuoteClickstream */
  generateQuote: UiPrimaryButton;
  generateQuoteClickstream: AgencyQuoteBuilderGenerateQuote;
  quoteTitle: Scalars["String"];
};

export type AgencyQuoteBuilderHeaderContent = {
  __typename?: "AgencyQuoteBuilderHeaderContent";
  clear?: Maybe<AgencyQuoteBuilderClear>;
  generateQuote?: Maybe<AgencyQuoteBuilderGenerateQuote>;
  quoteTitle: Scalars["String"];
  toolbarAction?: Maybe<AgencyQuoteBuilderExpandLink>;
};

export interface AgencyQuoteBuilderPropertyRateCardInput {
  businessModelType?: InputMaybe<BusinessModelType>;
  checkIn: DateInput;
  checkOut: DateInput;
  inventoryType: InventoryType;
  packageRate: Scalars["Boolean"];
  pricePerNight: Scalars["String"];
  priceTotal: Scalars["String"];
  propertyDestination: Scalars["String"];
  propertyId: Scalars["String"];
  propertyName: Scalars["String"];
  propertyType: Scalars["String"];
  ratePlanId: Scalars["String"];
  refundabilityType?: InputMaybe<RefundableType>;
  roomTypeDescription: Scalars["String"];
  roomTypeId: Scalars["String"];
  rooms: Array<PropertyRoomInput>;
  starRating?: InputMaybe<Scalars["Float"]>;
}

export type AgencyQuoteBuilderRateCard = {
  __typename?: "AgencyQuoteBuilderRateCard";
  dates: Scalars["String"];
  header: Scalars["String"];
  packageRate: Scalars["Boolean"];
  packageRateLabel?: Maybe<Scalars["String"]>;
  price: Array<Scalars["String"]>;
  rating?: Maybe<EgdsRating>;
  /** @deprecated Replaced by removeClickstream to use EG Clickstream events instead of UIS Prime */
  remove: UiTertiaryButton;
  removeClickstream: AgencyQuoteBuilderRemove;
  summary: Array<Scalars["String"]>;
};

export interface AgencyQuoteBuilderRateCardInput {
  propertyInput: AgencyQuoteBuilderPropertyRateCardInput;
}

export type AgencyQuoteBuilderRemove = {
  __typename?: "AgencyQuoteBuilderRemove";
  analytics: Scalars["AnalyticsPayload"];
  removeButton: UiTertiaryButton;
};

export interface AgencyQuoteBuilderRemoveInput {
  all: Scalars["Boolean"];
  index?: InputMaybe<Scalars["Int"]>;
}

export type AgencyQuoteBuilderResponse = {
  __typename?: "AgencyQuoteBuilderResponse";
  announceMessage: Scalars["String"];
  collapsedButton?: Maybe<AgencyQuoteBuilderCollapsedButton>;
  disclaimer?: Maybe<Scalars["String"]>;
  /** @deprecated Replacing with sheetDisplayType to be string */
  displayType: QuoteBuilderType;
  /** @deprecated Replaced by headerContent */
  header: AgencyQuoteBuilderHeader;
  headerContent?: Maybe<AgencyQuoteBuilderHeaderContent>;
  notification?: Maybe<Scalars["String"]>;
  /** @deprecated Needed to remove the timer */
  quoteBuilderAutoCollapseTimeout?: Maybe<Scalars["Int"]>;
  /** @deprecated Moved into sheetContent */
  rateCards: Array<AgencyQuoteBuilderRateCard>;
  sheetContent?: Maybe<AgencyQuoteBuilderSheetContent>;
  /** @deprecated Sheet value is not needed anymore */
  sheetDisplayType: Scalars["String"];
};

export type AgencyQuoteBuilderSheetContent = {
  __typename?: "AgencyQuoteBuilderSheetContent";
  clearAll?: Maybe<AgencyQuoteBuilderClear>;
  generateQuote?: Maybe<AgencyQuoteBuilderGenerateQuote>;
  rateCards: Array<AgencyQuoteBuilderRateCard>;
};

export interface AgencyQuoteDeleteInput {
  quoteIds: Array<Scalars["String"]>;
}

export type AgencyQuoteDeleteStatus = {
  __typename?: "AgencyQuoteDeleteStatus";
  deletedCount: Scalars["Int"];
  isSuccessful: Scalars["Boolean"];
};

export interface AgencyQuoteDeleteStatusInput {
  deletedCount: Scalars["Int"];
  isSuccessful: Scalars["Boolean"];
}

export interface AgencyQuoteDetails {
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
}

export type AgencyQuoteDetailsFailure = AgencyQuoteDetails & {
  __typename?: "AgencyQuoteDetailsFailure";
  /** @deprecated Use notification placard in place of banner.  Banner will be removed in a future release. */
  banner: UiBanner;
  notificationPlacard: AgencyQuoteNotificationPlacard;
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
};

export type AgencyQuoteDetailsSuccess = AgencyQuoteDetails & {
  __typename?: "AgencyQuoteDetailsSuccess";
  actionButtons: Array<UiPrimaryButton>;
  agencyQuoteIdentityInfo: AgencyQuoteIdentityInfo;
  agencySiteInfo: AgencyDetail;
  agentMessage: AgentComment;
  /** @deprecated Use notification placard in place of banner.  Banner will be removed in a future release. */
  banner?: Maybe<UiBanner>;
  messages?: Maybe<Array<EgdsText>>;
  notificationPlacard?: Maybe<AgencyQuoteNotificationPlacard>;
  pageTitle: EgdsHeading;
  /** @deprecated This link is moved to PropertyUnitCard and should be consumed from there. */
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
  /** @deprecated Use propertyUnitCards in place of propertyRoomCards. */
  propertyRoomCards: Array<PropertyUnitCard>;
  propertyUnitCards: Array<UnitCard>;
  quoteRegulatoryMessages?: Maybe<Array<EgdsText>>;
  /** @deprecated use Trip Summary in PropertyUnitCard */
  tripSummary: EgdsTextSection;
};

export type AgencyQuoteIdentityInfo = {
  __typename?: "AgencyQuoteIdentityInfo";
  identityEntryFields: Array<EgdsInputField>;
  messages?: Maybe<Array<EgdsText>>;
};

export type AgencyQuoteInputField = EgdsInputField & {
  __typename?: "AgencyQuoteInputField";
  egdsElementId?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export type AgencyQuoteListContent = {
  __typename?: "AgencyQuoteListContent";
  header: AgencyQuoteListHeader;
  quotes?: Maybe<Array<AgencyQuoteListQuote>>;
};

export type AgencyQuoteListElement = {
  __typename?: "AgencyQuoteListElement";
  element: Element;
};

export type AgencyQuoteListHeader = {
  __typename?: "AgencyQuoteListHeader";
  contents?: Maybe<Array<AgencyQuoteListHeaderContent>>;
};

export type AgencyQuoteListHeaderContent = {
  __typename?: "AgencyQuoteListHeaderContent";
  contentId?: Maybe<Scalars["String"]>;
  element?: Maybe<AgencyQuoteListElement>;
  sortType?: Maybe<AgencyQuoteListSortType>;
};

export interface AgencyQuoteListInput {
  deleteStatus?: InputMaybe<AgencyQuoteDeleteStatusInput>;
  paginationIndex?: InputMaybe<Scalars["Int"]>;
  searchCriteria?: InputMaybe<Scalars["String"]>;
  selectedTab?: InputMaybe<Scalars["String"]>;
  sortContentId?: InputMaybe<Scalars["String"]>;
  sortType?: InputMaybe<AgencyQuoteListSortType>;
}

export type AgencyQuoteListPagination = {
  __typename?: "AgencyQuoteListPagination";
  nextButton?: Maybe<EgdsButton>;
  nextIndex?: Maybe<Scalars["Int"]>;
  paginationText?: Maybe<Scalars["String"]>;
  previousButton?: Maybe<EgdsButton>;
  previousIndex?: Maybe<Scalars["Int"]>;
};

export type AgencyQuoteListQuote = {
  __typename?: "AgencyQuoteListQuote";
  contents?: Maybe<Array<AgencyQuoteListQuoteContent>>;
  quoteId: Scalars["String"];
};

export type AgencyQuoteListQuoteContent = {
  __typename?: "AgencyQuoteListQuoteContent";
  element?: Maybe<AgencyQuoteListElement>;
};

export type AgencyQuoteListResponse = {
  __typename?: "AgencyQuoteListResponse";
  confirmationDialog?: Maybe<ConfirmationDialog>;
  deleteButton?: Maybe<EgdsButton>;
  deleteToast?: Maybe<EgdsToast>;
  message?: Maybe<Scalars["String"]>;
  notificationPlacard?: Maybe<AgencyQuoteNotificationPlacard>;
  pageTitle: Scalars["String"];
  pagination?: Maybe<AgencyQuoteListPagination>;
  quoteContent?: Maybe<AgencyQuoteListContent>;
  searchSection?: Maybe<AgencyQuoteListSearchSection>;
  tabs?: Maybe<EgdsTabs>;
};

export type AgencyQuoteListSearchSection = {
  __typename?: "AgencyQuoteListSearchSection";
  searchBox: EgdsInputField;
  searchButton: EgdsButton;
  searchPills?: Maybe<Array<EgdsPill>>;
};

export type AgencyQuoteListSortType = "ASCENDING" | "DESCENDING" | "NOT_SORTED";

export type AgencyQuoteListTooltip = {
  __typename?: "AgencyQuoteListTooltip";
  text: Scalars["String"];
  tooltip: PopoverSheet;
};

export interface AgencyQuoteNaturalKeyInput {
  lodgingType: Scalars["String"];
  packageRates?: InputMaybe<Scalars["Boolean"]>;
  propertyDestination: Scalars["String"];
  propertyNaturalKeys: Array<PropertyNaturalKeyInput>;
}

export type AgencyQuoteNotificationPlacard = {
  __typename?: "AgencyQuoteNotificationPlacard";
  details: UiBanner;
  displayType: BannerDisplayType;
};

export interface AgencyQuoteSaveAgencyDetailInput {
  address1?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
}

export interface AgencyQuoteSaveCardInput {
  destination: Scalars["String"];
  hideCancellationState: Scalars["Boolean"];
  hidePriceState: Scalars["Boolean"];
  lob: ExpLineOfBusiness;
  naturalKey: AgencyQuoteNaturalKeyInput;
  totalPrice?: InputMaybe<Scalars["String"]>;
}

export type AgencyQuoteSaveFailure = {
  __typename?: "AgencyQuoteSaveFailure";
  banner: UiBanner;
};

export interface AgencyQuoteSaveInput {
  agencyInfo: AgencyQuoteSaveAgencyDetailInput;
  agentMessage?: InputMaybe<Scalars["String"]>;
  quoteCards: Array<AgencyQuoteSaveCardInput>;
  quoteId?: InputMaybe<Scalars["String"]>;
  quoteName: Scalars["String"];
  travelerName: Scalars["String"];
}

export type AgencyQuoteSaveResponse = AgencyQuoteSaveFailure | AgencyQuoteSaveSuccess;

export type AgencyQuoteSaveSuccess = {
  __typename?: "AgencyQuoteSaveSuccess";
  quoteId?: Maybe<Scalars["String"]>;
  toast: EgdsToast;
};

export type AgencyQuoteSwitchableMessage = {
  __typename?: "AgencyQuoteSwitchableMessage";
  displayToggle: UiToggle;
  message: EgdsTextSection;
};

export type AgencyQuoteSwitchablePriceSummary = {
  __typename?: "AgencyQuoteSwitchablePriceSummary";
  displayPriceToggle?: Maybe<UiToggle>;
  /** @deprecated Please do not use displayToggle as it has been deprecated. */
  displayToggle: UiToggle;
  priceBreakdownSummary?: Maybe<PricePresentation>;
  priceChangeAlert?: Maybe<PopoverSheet>;
};

export type AgencyQuoteToggle = UiToggle & {
  __typename?: "AgencyQuoteToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  /** @deprecated This declaration overrides deprecated member but not marked as deprecated itself. */
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type AgencySiteInfo = {
  __typename?: "AgencySiteInfo";
  agencyEntryFields: Array<EgdsInputField>;
  logo: AgencyLogo;
};

export type AgentComment = {
  __typename?: "AgentComment";
  egdsElementId?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  label?: Maybe<EgdsText>;
  placeHolder?: Maybe<Scalars["String"]>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export type AgentMessage = {
  __typename?: "AgentMessage";
  label?: Maybe<EgdsText>;
  placeHolder?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AgentPresence = {
  __typename?: "AgentPresence";
  agentResourceUri: Scalars["String"];
  agentStateNames: Array<Scalars["String"]>;
  agentStates: Array<AgentState>;
  avatar: Scalars["String"];
  currentState: AgentState;
  emailId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  logonName?: Maybe<Scalars["String"]>;
};

export interface AgentQuoteDetails {
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
}

export type AgentQuoteDetailsFailure = AgentQuoteDetails & {
  __typename?: "AgentQuoteDetailsFailure";
  banner: UiBanner;
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
};

export type AgentQuoteDetailsSuccess = AgentQuoteDetails & {
  __typename?: "AgentQuoteDetailsSuccess";
  actionButtons: Array<UiPrimaryButton>;
  agencySiteInfo: AgencySiteInfo;
  agentMessage: AgentMessage;
  banner?: Maybe<UiBanner>;
  messages?: Maybe<Array<EgdsText>>;
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
  propertyRoomCards: Array<PropertyRoomCard>;
  tripSummary: EgdsTextSection;
};

export type AgentQuoteInputField = EgdsInputField & {
  __typename?: "AgentQuoteInputField";
  egdsElementId?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export interface AgentQuoteNaturalKeyInput {
  packageRates?: InputMaybe<Scalars["Boolean"]>;
  propertyDestination: Scalars["String"];
  propertyNaturalKeys: Array<PropertyNaturalKeyInput>;
}

export type AgentQuoteSwitchableMessage = {
  __typename?: "AgentQuoteSwitchableMessage";
  displayToggle: UiToggle;
  message: EgdsTextSection;
};

export type AgentQuoteSwitchablePriceSummary = {
  __typename?: "AgentQuoteSwitchablePriceSummary";
  displayToggle: UiToggle;
  priceBreakdownSummary?: Maybe<PricePresentation>;
};

export type AgentQuoteToggle = UiToggle & {
  __typename?: "AgentQuoteToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type AgentState = {
  __typename?: "AgentState";
  agentPresenceUri: Scalars["String"];
  localizedText?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  type: AgentStateType;
};

export interface AgentStateInput {
  agentPresenceUri: Scalars["String"];
  localizedText?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  type: AgentStateType;
}

export type AgentStateType = "NotReady" | "Offline" | "Ready";

export type AgentToolsBookingLineItem = {
  __typename?: "AgentToolsBookingLineItem";
  date: Date;
  /** @deprecated no longer needed */
  label: Scalars["String"];
  values: Array<Maybe<Scalars["Float"]>>;
};

export type AgentToolsBookingPrice = {
  __typename?: "AgentToolsBookingPrice";
  base?: Maybe<LabeledValue>;
  bookingPrice: LabeledValue;
  coupons?: Maybe<LabeledValue>;
  taxesAndFees?: Maybe<LabeledValue>;
};

export type AgentToolsBookingSummaryItem = {
  __typename?: "AgentToolsBookingSummaryItem";
  label: Scalars["String"];
  values: Array<Maybe<Scalars["Float"]>>;
};

export type AgentToolsChangeOrCancelReason = {
  __typename?: "AgentToolsChangeOrCancelReason";
  approver?: Maybe<AgentToolsReasonApprover>;
  calculateRefund?: Maybe<EgdsBasicRadioGroup>;
  calculateRefundExpedia?: Maybe<EgdsBasicRadioGroup>;
  calculator?: Maybe<LodgingChangeReasonCalculator>;
  fullAmount: Scalars["Float"];
  overRefundTitle?: Maybe<Scalars["String"]>;
  refundSource?: Maybe<EgdsBasicRadioGroup>;
  refundSourcePortion?: Maybe<AgentToolsReasonRefundSourcePortion>;
  reset?: Maybe<UiSecondaryButton>;
  title: Scalars["String"];
};

export interface AgentToolsExtraBedOptionsInput {
  extraAdultBedCount: Scalars["Int"];
  extraChildBedCount: Scalars["Int"];
  extraCribCount: Scalars["Int"];
}

export type AgentToolsFutureTravelCredit = {
  __typename?: "AgentToolsFutureTravelCredit";
  terms?: Maybe<LabeledList>;
  totalAmount: LabeledValue;
};

export interface AgentToolsFutureTravelCreditDatesInput {
  bookByDate?: InputMaybe<Scalars["String"]>;
  travelCompleteByDate?: InputMaybe<Scalars["String"]>;
  travelStartByDate?: InputMaybe<Scalars["String"]>;
}

export interface AgentToolsFutureTravelCreditInput {
  amount?: InputMaybe<Scalars["Float"]>;
  dates?: InputMaybe<AgentToolsFutureTravelCreditDatesInput>;
}

export interface AgentToolsLodgingActionInput {
  payload?: InputMaybe<AgentToolsLodgingChangeActionPayloadInput>;
  type: AgentToolsLodgingActionType;
}

export type AgentToolsLodgingActionType = "Cancel" | "ComboChange" | "OccupancyChange" | "RoomTypeChange";

export interface AgentToolsLodgingChangeActionPayloadInput {
  checkInDate?: InputMaybe<DateTimeInput>;
  checkOutDate?: InputMaybe<DateTimeInput>;
  extraBedOptions?: InputMaybe<AgentToolsExtraBedOptionsInput>;
  inventoryProviderId: Scalars["Int"];
  ratePlanId?: InputMaybe<Scalars["String"]>;
  roomOccupancy?: InputMaybe<AgentToolsRoomOccupancyInput>;
  roomOptions?: InputMaybe<AgentToolsRoomOptionsInput>;
  roomTypeID?: InputMaybe<Scalars["String"]>;
}

export type AgentToolsLodgingCostBreakdown = {
  __typename?: "AgentToolsLodgingCostBreakdown";
  amountPaid: LabeledValue;
  beginningBookingBalance?: Maybe<LabeledValue>;
  endingBookingBalance?: Maybe<LabeledValue>;
  finalPenalties: AgentToolsLodgingFinalPenalties;
  newBookingPrice?: Maybe<AgentToolsBookingPrice>;
  originalBookingPrice: AgentToolsBookingPrice;
  previousRefunds?: Maybe<LabeledValue>;
  title: Scalars["String"];
  totalChargesOrRefunds: LabeledValue;
};

export type AgentToolsLodgingFinalPenalties = {
  __typename?: "AgentToolsLodgingFinalPenalties";
  finalPenalties: LabeledValue;
  penalties: LabeledValue;
  penaltyWaivers: LabeledValue;
};

export type AgentToolsNumberInput = {
  __typename?: "AgentToolsNumberInput";
  errorMessage?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type AgentToolsRadioGroup = EgdsRadioGroup & {
  __typename?: "AgentToolsRadioGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]>;
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type AgentToolsReasonApprover = {
  __typename?: "AgentToolsReasonApprover";
  contact: Scalars["String"];
  errorMessage: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  title: Scalars["String"];
};

export type AgentToolsReasonCodes = {
  __typename?: "AgentToolsReasonCodes";
  primaryElements: Array<ReasonCodePrimaryElements>;
  secondaryElements?: Maybe<Array<ReasonCodeSecondaryElements>>;
};

export type AgentToolsReasonRefundSourcePortion = {
  __typename?: "AgentToolsReasonRefundSourcePortion";
  /** @deprecated use errorMessages instead */
  errorMessage: Scalars["String"];
  errorMessages: RefundSourcePortionErrorMessages;
  expediaPortion: EgdsBasicRadioGroup;
  supplierPortionTitle: Scalars["String"];
};

export type AgentToolsRefundCalculation = {
  __typename?: "AgentToolsRefundCalculation";
  expediaBalanceAmountInput: EgdsNumberInputField;
  expediaOtherAmountInput: EgdsNumberInputField;
  expediaPortionRadioGroup: AgentToolsRadioGroup;
  fullOrPartialRadioGroup: AgentToolsRadioGroup;
  fullRefundInput: EgdsNumberInputField;
  partialRefundTable: AgentToolsRefundCalculator;
  supplierPortionInput: EgdsNumberInputField;
  supplierPortionTitle: EgdsPlainText;
};

export type AgentToolsRefundCalculator = {
  __typename?: "AgentToolsRefundCalculator";
  footer: AgentToolsRefundTableFooter;
  footnote?: Maybe<EgdsPlainText>;
  header: AgentToolsRefundTableHeader;
  lineItems: Array<AgentToolsRefundTableLineItem>;
  reset: UiSecondaryButton;
};

export type AgentToolsRefundTableFooter = {
  __typename?: "AgentToolsRefundTableFooter";
  bookingSummaryItems: Array<AgentToolsBookingSummaryItem>;
  totalRefund: AgentToolsRefundTableTotalRefund;
};

export type AgentToolsRefundTableHeader = {
  __typename?: "AgentToolsRefundTableHeader";
  bookingTableHeader: Array<AgentToolsRefundTableHeaderItem>;
  refund: AgentToolsRefundTableHeaderItem;
  totalRefund: AgentToolsRefundTableHeaderItem;
};

export type AgentToolsRefundTableHeaderItem = {
  __typename?: "AgentToolsRefundTableHeaderItem";
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type AgentToolsRefundTableInput = {
  __typename?: "AgentToolsRefundTableInput";
  baseAmount: Scalars["Float"];
  refundType: AgentToolsRadioGroup;
  refundValueInput: AgentToolsRefundTableValueInput;
};

export type AgentToolsRefundTableLineItem = {
  __typename?: "AgentToolsRefundTableLineItem";
  bookingDetails: AgentToolsBookingLineItem;
  refundInput: AgentToolsRefundTableInput;
};

export type AgentToolsRefundTableTotalRefund = {
  __typename?: "AgentToolsRefundTableTotalRefund";
  validations: Array<EgdsInputValidation>;
};

export type AgentToolsRefundTableValueInput = {
  __typename?: "AgentToolsRefundTableValueInput";
  balanceAmount: EgdsNumberInputField;
  partialAmount: EgdsNumberInputField;
  percentage: EgdsNumberInputField;
};

export interface AgentToolsRoomOccupancyInput {
  adultCount: Scalars["Int"];
  childAgeList: Array<Scalars["Int"]>;
}

export interface AgentToolsRoomOptionsInput {
  bedSelected: Scalars["Int"];
  bedSelectedInfo: BedSelectedInfoInput;
  smokingTypeSelected: SmokingTypeSelectedInput;
  specialRequest: Scalars["String"];
}

export type AgentToolsStep = {
  __typename?: "AgentToolsStep";
  label: Scalars["String"];
};

export type AgentToolsStepIndicator = {
  __typename?: "AgentToolsStepIndicator";
  activeStep: Scalars["Int"];
  steps: Array<AgentToolsStep>;
};

export type AgentToolsSummaryCard = {
  __typename?: "AgentToolsSummaryCard";
  chargeAmount?: Maybe<AgentToolsTransaction>;
  confirmationEmail?: Maybe<LabeledValue>;
  futureTravelCreditAmount?: Maybe<AgentToolsFutureTravelCredit>;
  newBookingBalance?: Maybe<LabeledValue>;
  originalBookingPrice?: Maybe<LabeledValue>;
  penalty?: Maybe<LabeledValue>;
  refundAmount?: Maybe<AgentToolsTransaction>;
  /** @deprecated not needed */
  refundedOrChargedBy?: Maybe<LabeledValue>;
  title: Scalars["String"];
};

export type AgentToolsSummaryCardComponentType = "FLIGHT_CANCEL" | "LODGING_CANCEL" | "LODGING_CHANGE";

export type AgentToolsTransaction = {
  __typename?: "AgentToolsTransaction";
  paymentBreakdown?: Maybe<Array<LabeledValue>>;
  timeline?: Maybe<LabeledValue>;
  totalAmount: LabeledValue;
};

export type AirCreditWebAction = UiAction & {
  __typename?: "AirCreditWebAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
};

export type AirlineCreditsContentPresentation = AirlineCreditsLoadedPresentation | AirlineCreditsLoadingPresentation;

export type AirlineCreditsLoadedPresentation = {
  __typename?: "AirlineCreditsLoadedPresentation";
  dialogId: FlightsDialogId;
  dialogs?: Maybe<Array<FlightsDialog>>;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  sections: Array<FlightsCreditSelectionPresentationSection>;
};

export type AirlineCreditsLoadingPresentation = {
  __typename?: "AirlineCreditsLoadingPresentation";
  heading: Scalars["String"];
  subheading: Scalars["String"];
};

export type AirlineCreditsPresentation = {
  __typename?: "AirlineCreditsPresentation";
  dialogId: FlightsDialogId;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  sections: Array<FlightsCreditSelectionPresentationSection>;
};

export type Airport = {
  __typename?: "Airport";
  code: Scalars["String"];
  subText?: Maybe<Scalars["String"]>;
};

export type AirportLocationType = "AIRPORT" | "METROCODE" | "UNSPECIFIED";

export type AirportOption = {
  __typename?: "AirportOption";
  accessibilityHeading: Scalars["String"];
  accessibilityMessage: Scalars["String"];
  destinationAirportDistance: Scalars["String"];
  destinationCityName: Scalars["String"];
  icon: Icon;
  originAirportDistance: Scalars["String"];
  originCityName: Scalars["String"];
  selectAction: FlightsAction;
};

export type Alignment = "CENTER" | "LEFT" | "RIGHT";

export type AllArticlesErrorView = {
  __typename?: "AllArticlesErrorView";
  errorMessage: UiBanner;
  /** @deprecated Help Articles title won't be shown */
  title: Scalars["String"];
};

export type AllHelpArticles = {
  __typename?: "AllHelpArticles";
  dialogTitleOption: HelpArticleHeadingSection;
  helpArticleContactUsWidget?: Maybe<HelpArticlesContactUsWidgetSection>;
  /** @deprecated Help Articles title won't be shown */
  helpArticleTitle: Scalars["String"];
  helpArticlesOnHomepage: Array<HelpArticleCard>;
  /** @deprecated This component is replaced as separate query helparticlesresultsview.helpArticleSearchBar */
  helpArticlesSearchBar?: Maybe<EgdsTypeaheadInputField>;
};

export type AllHelpArticlesView = AllArticlesErrorView | AllHelpArticles;

export type AllHotelProducts = {
  __typename?: "AllHotelProducts";
  hotelProducts: Array<HotelProduct>;
};

export type AllHotelProductsData = {
  __typename?: "AllHotelProductsData";
  messageContent: AllHotelProducts;
  schemaName: Scalars["String"];
};

export type AlterMode = "DEBUG" | "NONE" | "PREVIEW" | "RELEASED";

export type AlternateAirportOptions = {
  __typename?: "AlternateAirportOptions";
  accessibilityTitle: Scalars["String"];
  airportOptionAnalytics: FlightsAnalytics;
  airportOptions: Array<AirportOption>;
  alternateOptionTitle: Scalars["String"];
};

export type AlternateDateOption = {
  __typename?: "AlternateDateOption";
  dates: LodgingLinkMessage;
  price?: Maybe<PropertyPriceOption>;
};

export type AlternateDateOptions = {
  __typename?: "AlternateDateOptions";
  accessibilityTitle: Scalars["String"];
  alternateOptionTitle: Scalars["String"];
  dateOptionAnalytics: FlightsAnalytics;
  dateOptions: Array<DateOption>;
};

export type AlternateDatesCard = {
  __typename?: "AlternateDatesCard";
  header?: Maybe<LodgingHeader>;
  options: Array<AlternateDateOption>;
};

export type AlternateRecommendationCard = CarRecommendationCard & {
  __typename?: "AlternateRecommendationCard";
  action?: Maybe<CarAction>;
  analytics?: Maybe<CarAnalytics>;
  button?: Maybe<CarActionableItem>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  subText?: Maybe<CarPhrase>;
  title?: Maybe<CarPhrase>;
};

export type AlternativeLinkType = "ALTERNATIVE_ACCOMMODATION" | "ALTERNATIVE_LOBS";

export type AmenitiesDialog = {
  __typename?: "AmenitiesDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingDialogTriggerMessage;
};

export type AmenityCategory =
  | "ACCESSIBLE_ROLL_IN_SHOWER"
  | "AIR_CONDITIONING"
  | "ALL_INCLUSIVE"
  | "ARCADE"
  | "BABYSITTING"
  | "BAR"
  | "BARBECUE"
  | "BOWLING"
  | "BREAKFAST_AVAILABLE"
  | "BREAKFAST_INCLUDED"
  | "BREAKFAST_NOT_AVAILABLE"
  | "BUSINESS_SERVICES"
  | "CHILDREN_CLUB"
  | "DAILY_HOUSEKEEPING"
  | "EXTRA_BED"
  | "FIRE_PLACE"
  | "FITNESS_EQUIPMENT"
  | "FREE_AIRPORT_TRANSPORTATION"
  | "FREE_BREAKFAST"
  | "FREE_CANCEL"
  | "FREE_PARKING"
  | "FREE_WELCOME_DRINK"
  | "FREE_WIRED_INTERNET"
  | "FRONT_DESK_24_HOURS"
  | "GROCERY"
  | "HIGH_SPEED_INTERNET"
  | "HOT_TUB"
  | "INTERNET_NOT_AVAILABLE"
  | "IN_ROOM_INTERNET"
  | "KIDS_POOL"
  | "KITCHEN"
  | "LAUNDRY"
  | "LIVING_AREA"
  | "MICROWAVE"
  | "MINI_GOLF"
  | "NON_REFUNDABLE"
  | "ON_PRIVATE_BEACH"
  | "ON_THE_BEACH"
  | "OUTDOOR_SPACE"
  | "PARKING"
  | "PARTIAL_REFUND"
  | "PETS_ALLOWED"
  | "PLAYGROUND"
  | "POOL"
  | "RESERVE_NOW_PAY_DEPOSIT"
  | "RESERVE_NOW_PAY_LATER"
  | "RESTAURANT_IN_HOTEL"
  | "ROOM_SERVICE"
  | "SMOKE_FREE"
  | "SOUNDPROOF_ROOM"
  | "SPA_SERVICES_ON_SITE"
  | "SPECIAL_DEAL"
  | "TENNIS_COURT"
  | "TOYS"
  | "WASHER"
  | "WATERSLIDE"
  | "WIFI_SURCHARGE"
  | "WIRED_INTERNET_SURCHARGE";

export type AmenityFilter = "CARRY_ON_BAG" | "INVALID" | "NO_CANCEL_FEE" | "NO_CHANGE_FEE" | "SEAT_CHOICE";

export type AnalyticsElement =
  | VirtualAgentControlImpressionAnalyticEvent
  | VirtualAgentControlInteractionAnalyticEvent
  | VirtualAgentControlPageViewAnalyticEvent;

export type AnalyticsKey = "NUMBER_OF_BAGS" | "PIECE_OR_WEIGHT_CONCEPT" | "QUANTITY_OF_BAGS";

export interface AncillaryContentInput {
  ancillaryToken: Scalars["String"];
}

export interface AncillaryJourneySelectionsInput {
  journeyIndex: Scalars["Int"];
  journeyStatus?: InputMaybe<FlightJourneyStatus>;
  travellerAncillarySelections: Array<TravellerAncillarySelectionInput>;
}

export type AncillaryShoppingPath = "PACKAGE_INFOSITE" | "PACKAGE_TRIPS" | "STANDALONE_INFOSITE" | "STANDALONE_TRIPS";

export type AnnualSummaryCarouselItem = {
  __typename?: "AnnualSummaryCarouselItem";
  /** @deprecated Please use carouselItemAccessibility for accessibility strings */
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<UiPrimaryButton>;
  carouselItemAccessibility?: Maybe<CarouselItemAccessibility>;
  carouselItemId: Scalars["String"];
  closeIcon?: Maybe<UiTertiaryButton>;
  description?: Maybe<Array<Scalars["String"]>>;
  heading?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  logo?: Maybe<Mark>;
  subHeading?: Maybe<Scalars["String"]>;
  travelExplored?: Maybe<Array<AnnualSummaryTravelExplored>>;
};

export type AnnualSummaryHomeResult = {
  __typename?: "AnnualSummaryHomeResult";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  annualSummaryCarouselItem: AnnualSummaryCarouselItem;
};

export type AnnualSummaryResult = {
  __typename?: "AnnualSummaryResult";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  annualSummaryCarouselItem?: Maybe<Array<AnnualSummaryCarouselItem>>;
};

export type AnnualSummaryTravelExplored = {
  __typename?: "AnnualSummaryTravelExplored";
  exploredItems?: Maybe<Array<Scalars["String"]>>;
  icon?: Maybe<Icon>;
};

export type AppDownloadButton = {
  __typename?: "AppDownloadButton";
  button?: Maybe<UiSecondaryButton>;
  revealAction?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type AppGrowthActionNotification = {
  __typename?: "AppGrowthActionNotification";
  exitText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type AppGrowthBorder = {
  __typename?: "AppGrowthBorder";
  shape: AppGrowthBorderShape;
  style: AppGrowthBorderStyle;
};

export type AppGrowthBorderShape = "ROUNDED" | "SQUARE";

export type AppGrowthBorderStyle = "BOLD" | "DEFAULT" | "NONE";

export type AppGrowthButton = {
  __typename?: "AppGrowthButton";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  egdsElementId: Scalars["String"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  style: EgdsButtonStyle;
  uiAction: AppGrowthUiAction;
};

export type AppGrowthDialog = {
  __typename?: "AppGrowthDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  details?: Maybe<EgdsTextList>;
  footer?: Maybe<EgdsDialogFooter>;
  header: EgdsSectionHeading;
};

export type AppGrowthMediaItem = MediaItem & {
  __typename?: "AppGrowthMediaItem";
  alt?: Maybe<Scalars["String"]>;
  media: Media;
};

export type AppGrowthNavigation = {
  __typename?: "AppGrowthNavigation";
  accessibility?: Maybe<Scalars["String"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]>;
  toolbarIcon?: Maybe<AppGrowthToolbarIcon>;
  toolbarStyle?: Maybe<AppGrowthToolbarStyle>;
};

export interface AppGrowthQueryImpressionSupportResponse {
  clientSideAnalytics: ClientSideAnalytics;
  clientSideAnalyticsImpression: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
}

export interface AppGrowthQueryResponse {
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
}

export type AppGrowthSummary = {
  __typename?: "AppGrowthSummary";
  accessibility?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
};

export type AppGrowthTextIconLinkListItem = {
  __typename?: "AppGrowthTextIconLinkListItem";
  accessibility: Scalars["String"];
  action?: Maybe<UiLinkAction>;
  border: AppGrowthBorder;
  egdsElementId: Scalars["String"];
  icon?: Maybe<Icon>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  uiAction?: Maybe<AppGrowthUiAction>;
};

export type AppGrowthToolbarIcon = "BACK" | "CLOSE";

export type AppGrowthToolbarStyle = "TRANSPARENT_ACTION_OVERLAY";

export type AppGrowthUiAction = {
  __typename?: "AppGrowthUIAction";
  actionId?: Maybe<Scalars["String"]>;
  campaignId?: Maybe<Scalars["String"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  deeplink?: Maybe<UiLinkAction>;
  shareText?: Maybe<Scalars["String"]>;
  triviaId?: Maybe<Scalars["String"]>;
  uiActionType: AppGrowthUiActionName;
};

export type AppGrowthUiActionName =
  | "ACCEPT"
  | "DEEPLINK"
  | "DENY"
  | "EXIT_TO_APP_HOME"
  | "GOTO_DASHBOARD"
  | "LOGIN_AND_SIGNUP"
  | "NONE"
  | "OK"
  | "SHARE"
  | "SIGNUP"
  | "TRIVIA_ANSWER"
  | "TRIVIA_END"
  | "TRIVIA_HOW_TO"
  | "TRIVIA_NEXT"
  | "TRIVIA_START";

export type AppliedCoupon = {
  __typename?: "AppliedCoupon";
  confirmationMessage?: Maybe<Scalars["String"]>;
  couponDetail?: Maybe<AppliedCouponDetail>;
  couponErrorBanner?: Maybe<CouponErrorBanner>;
  signals: Array<CouponSignal>;
};

export type AppliedCouponDetail = {
  __typename?: "AppliedCouponDetail";
  code?: Maybe<Scalars["String"]>;
  displayInfo: EgdsStandardMessagingCard;
  instanceId?: Maybe<Scalars["String"]>;
};

export interface ApplyPaymentMethodRequestInput {
  paymentMethod: PaymentMethodInput;
  sessionId: Scalars["String"];
  sessionToken: Scalars["String"];
}

export type AriaLabelledText = {
  __typename?: "AriaLabelledText";
  accessibilityText: Scalars["String"];
  message: Scalars["String"];
};

export interface ArithmeticOperationsCriteriaInput {
  inputNumbers: Array<Scalars["Float"]>;
  operation: Operation;
}

export type ArkoseChallenge = {
  __typename?: "ArkoseChallenge";
  apiKey: Scalars["String"];
  src?: Maybe<Scalars["String"]>;
};

export type ArticlesSummarizationClickStreamAnalytics = {
  __typename?: "ArticlesSummarizationClickStreamAnalytics";
  article: HelpArticleEventPayload;
  duaid: Scalars["UUID"];
  event: EgClickstreamEvent;
  experience: Experience;
};

export type ArticlesSummarizationView =
  | HelpArticleSearchErrorView
  | HelpArticleSummarizationPoweredByLlm
  | LlmSearchErrorView;

export type AspectRatio =
  | "FOUR_ONE"
  | "FOUR_THREE"
  | "ONE_ONE"
  | "SIXTEEN_NINE"
  | "THREE_FOUR"
  | "THREE_TWO"
  | "TWENTY_ONE_NINE";

export type AtoChallenge = {
  __typename?: "AtoChallenge";
  action: AtoChallengeAction;
  placement: AtoChallengePlacement;
};

export type AtoChallengeAction = ArkoseChallenge;

export type AtoChallengePlacement = "DirectFeedbackSubmitFormAction";

export interface AtoChallengeValidationInput {
  captchaToken?: InputMaybe<Scalars["String"]>;
}

export interface AuthenticationConfigInput {
  authToken?: InputMaybe<Scalars["String"]>;
  channelOriginId?: InputMaybe<Scalars["String"]>;
}

export type AuthenticationState = "ANONYMOUS" | "AUTHENTICATED" | "IDENTIFIED";

export type AutoSuggestInfo = {
  __typename?: "AutoSuggestInfo";
  autoSuggestKeyValuePair?: Maybe<Array<AutoSuggestKeyValuePair>>;
  header?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["String"]>;
  uri: HttpUri;
};

export type AutoSuggestKeyValuePair = {
  __typename?: "AutoSuggestKeyValuePair";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type AvailabilityCalendar = {
  __typename?: "AvailabilityCalendar";
  configuration: AvailabilityCalendarConfiguration;
  days?: Maybe<Array<AvailabilityCalendarDay>>;
  id: Scalars["ID"];
};

export type AvailabilityCalendarConfiguration = {
  __typename?: "AvailabilityCalendarConfiguration";
  availableByDefault: Scalars["Boolean"];
  beginDate: Date;
  currentDate: Date;
  defaultCheckinValidityAfterEndDate: CheckinValidityWithReason;
  defaultCheckoutValidityWhenWithinConstraints: CheckoutValidityWithReason;
  defaultStayConstraints: StayConstraints;
  endDate: Date;
};

export type AvailabilityCalendarDay = {
  __typename?: "AvailabilityCalendarDay";
  available: Scalars["Boolean"];
  checkinValidity: CheckinValidityWithReason;
  checkoutValidity: CheckoutValidityWithReason;
  date: Date;
  stayConstraints: StayConstraints;
};

export type AvailabilityCallToAction = LodgingButton | LodgingPlainMessage;

export type AvailableRoutes = {
  __typename?: "AvailableRoutes";
  destinationsString?: Maybe<Scalars["String"]>;
  originCountries: Array<OriginCountry>;
  originString?: Maybe<Scalars["String"]>;
};

export type Avatar = {
  __typename?: "Avatar";
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  text?: Maybe<Scalars["String"]>;
};

export type AvatarGroup = {
  __typename?: "AvatarGroup";
  items: Array<Avatar>;
};

export type BackgroundType = "PRIMARY" | "SECONDARY";

export type Badge = {
  __typename?: "Badge";
  /** @deprecated Only used for uitk-react-badges v2, use text for v3 */
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Only used for uitk-react-badges v2, use icon_temp temporarily */
  icon?: Maybe<BadgeIcon>;
  icon_temp?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text?: Maybe<Scalars["String"]>;
  /** @deprecated Only used for uitk-react-badges v2, use theme_temp temporarily */
  theme?: Maybe<Theme>;
  theme_temp?: Maybe<Theme>;
};

export type BadgeIcon =
  | "ACCESS_TIME"
  | "ADD"
  | "BUNDLED_SAVINGS_PLUS"
  | "EXP29639_ORB"
  | "FAMILY_FRIENDLY"
  | "INFO_OUTLINE"
  | "LOB_DEALS"
  | "LOCAL_OFFER"
  | "MOD"
  | "SMARTPHONE";

export type BadgeWrapper = {
  __typename?: "BadgeWrapper";
  badge: EgdsBadge;
};

export type BagsSelectionType = "ALL" | "NONE" | "SOME";

export type BannerDisplayType = "ERROR" | "INFO" | "NO_BANNER" | "WARN";

export type BannerPlacementServiceEgdsStandardLink = {
  __typename?: "BannerPlacementServiceEGDSStandardLink";
  action: BannerPlacementServiceUiLinkAction;
  disabled: Scalars["Boolean"];
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export type BannerPlacementServiceUiLinkAction = UiAction & {
  __typename?: "BannerPlacementServiceUILinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  resource: HttpUri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"];
};

export type BannerTheme = "BRAND_PROMO_PRIMARY" | "PRIMARY" | "SECONDARY";

export type BasicShoppingAppliedFilter = ShoppingAppliedFilter & {
  __typename?: "BasicShoppingAppliedFilter";
  filter?: Maybe<EgdsPill>;
  linkUrl?: Maybe<UiLinkAction>;
  nextSearchCriteria?: Maybe<ShoppingSearchCriteria>;
};

export type BedInformation = {
  __typename?: "BedInformation";
  englishType: Scalars["String"];
  type: Scalars["String"];
};

export type BedOptions = {
  __typename?: "BedOptions";
  bedInfo: Array<BedInformation>;
  icon: Scalars["String"];
  name: Scalars["String"];
};

export interface BedSelectedInfoInput {
  bedSelectedId: Scalars["String"];
  name: Scalars["String"];
}

export type BestTimeToGo = {
  __typename?: "BestTimeToGo";
  impression: ClientSideAnalytics;
  monthlyHistoricalForecast: DestinationMonthlyHistoricalForecast;
  subtitle: EgdsSpannableText;
  title: Scalars["String"];
};

export type BinSpec = {
  __typename?: "BinSpec";
  bin?: Maybe<Scalars["Int"]>;
  brand?: Maybe<Scalars["String"]>;
  cardConfig?: Maybe<PaymentCardConfiguration>;
  cardLogo?: Maybe<Mark>;
  issuerCountry?: Maybe<Scalars["String"]>;
};

export type BodyElements = NotificationMessageCard | NotificationMultiBody | NotificationSimpleBody;

export type BookResponse = {
  __typename?: "BookResponse";
  data?: Maybe<BookResponseType>;
};

export type BookResponseType = {
  __typename?: "BookResponseType";
  checkoutSessionToken: Scalars["String"];
  feedbackMessage?: Maybe<EgdsToast>;
  orderId?: Maybe<Scalars["String"]>;
  signals: Array<CheckoutUiSignal>;
};

export type BookSubmittingDialog = {
  __typename?: "BookSubmittingDialog";
  primaryContent: EgdsPlainText;
};

export type BookWithAirlinesDialog = FlightsDialogPresentation & {
  __typename?: "BookWithAirlinesDialog";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Booking = {
  __typename?: "Booking";
  actions: Array<HelpCenterIntentButtons>;
  helpCenterIntentButton: HelpCenterIntentButtons;
  icon: Icon;
  isDisable: Scalars["Boolean"];
  itineraryNumber?: Maybe<Scalars["String"]>;
  product: Scalars["String"];
  timePeriod: Scalars["String"];
  title: Scalars["String"];
};

export interface BookingChangeFlightInput {
  airRecordLocator?: InputMaybe<Scalars["String"]>;
  client?: InputMaybe<BookingServicingClient>;
  conversationId?: InputMaybe<Scalars["String"]>;
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
  opaqueId?: InputMaybe<Scalars["String"]>;
  selectedChanges?: InputMaybe<Array<BookingServicingChangeSelectionInput>>;
  tripId: Scalars["String"];
  tripItemId?: InputMaybe<Scalars["String"]>;
  tripViewId?: InputMaybe<Scalars["String"]>;
}

export type BookingChangeFlightInputType = {
  __typename?: "BookingChangeFlightInputType";
  airRecordLocator?: Maybe<Scalars["String"]>;
  client?: Maybe<BookingServicingClient>;
  conversationId?: Maybe<Scalars["String"]>;
  flightSearchCriteria?: Maybe<FlightSearchCriteriaInputType>;
  opaqueId?: Maybe<Scalars["String"]>;
  selectedChanges?: Maybe<Array<BookingServicingChangeSelectionInputType>>;
  tripId: Scalars["String"];
  tripItemId?: Maybe<Scalars["String"]>;
  tripViewId?: Maybe<Scalars["String"]>;
};

export type BookingConfirmation = {
  __typename?: "BookingConfirmation";
  bookingConfirmationBody: Scalars["String"];
  /** @deprecated No longer needed because of the design updates. */
  bookingConfirmationHeader: Scalars["String"];
  bookingConfirmationLinkLabel: Scalars["String"];
};

export type BookingLineItem = {
  __typename?: "BookingLineItem";
  date: Date;
  originalAmountPaid: Scalars["Float"];
  supplierNetRate: Scalars["Float"];
};

export type BookingLineItemHeader = {
  __typename?: "BookingLineItemHeader";
  nights: Scalars["String"];
  originalAmountPaid: Scalars["String"];
  refundInput: Scalars["String"];
  supplierNetRate: Scalars["String"];
  totalRefund: Scalars["String"];
};

export type BookingRefundEntry = {
  __typename?: "BookingRefundEntry";
  additionalRefundInput?: Maybe<EgdsNumberInputField>;
  amountPaidBalance?: Maybe<Scalars["Float"]>;
  approverGroup?: Maybe<Array<EgdsTextInputField>>;
  reasonCodes?: Maybe<AgentToolsReasonCodes>;
  refundCalculationGroup?: Maybe<AgentToolsRefundCalculation>;
  reset?: Maybe<UiSecondaryButton>;
  title: Scalars["String"];
  waivingSource?: Maybe<AgentToolsRadioGroup>;
};

export type BookingServiceCancel = {
  __typename?: "BookingServiceCancel";
  confirmCancellation: BookingServicingConfirmCancellation;
  headsUpPresentation: BookingServicingCancelHeadsUp;
  reviewCancellation: BookingServicingReviewCancellation;
};

export type BookingServiceChange = {
  __typename?: "BookingServiceChange";
  messagingElements: Array<BookingServicingMessagingElement>;
  originalBookingPriceDetails?: Maybe<BookingServicingPriceDetails>;
  presentation: BookingServicingChangePresentation;
  redirectAction?: Maybe<BookingServicingChangeRedirectPresentation>;
};

export type BookingServiceChangeMessagingElementsArgs = {
  originalBookingId: Scalars["String"];
};

export type BookingServiceChangeOriginalBookingPriceDetailsArgs = {
  ignoreSelectTravelers?: InputMaybe<Scalars["Boolean"]>;
  originalBookingId: Scalars["String"];
};

export type BookingServiceChangePresentationArgs = {
  criteriaInput: BookingServicingChangeCriteriaInput;
};

export type BookingServiceChangeRedirectActionArgs = {
  input: BookingServicingChangeCriteriaInput;
};

export type BookingServicingAccept = {
  __typename?: "BookingServicingAccept";
  acceptConfirmationLoading: BookingServicingAcceptConfirmation;
  headsUp: BookingServicingHeadsUp;
  reviewAccept: BookingServicingAcceptReview;
};

export type BookingServicingAcceptConfirmation = {
  __typename?: "BookingServicingAcceptConfirmation";
  response: BookingServicingAcceptConfirmationResponse;
};

export type BookingServicingAcceptConfirmationElements =
  | BookingServicingAcceptInformationCards
  | BookingServicingFlightsTripDetailedCard
  | BookingServicingInformationBanner;

export type BookingServicingAcceptConfirmationFooter = {
  __typename?: "BookingServicingAcceptConfirmationFooter";
  buttons: Array<BookingServicingButton>;
};

export type BookingServicingAcceptConfirmationPresentation = {
  __typename?: "BookingServicingAcceptConfirmationPresentation";
  content: Array<BookingServicingAcceptConfirmationElements>;
};

export type BookingServicingAcceptConfirmationResponse =
  | BookingServicingAcceptConfirmationSuccess
  | BookingServicingConfirmLoading
  | BookingServicingError;

export type BookingServicingAcceptConfirmationSuccess = {
  __typename?: "BookingServicingAcceptConfirmationSuccess";
  content: BookingServicingAcceptConfirmationPresentation;
  footer: BookingServicingAcceptConfirmationFooter;
  heading: BookingServicingHeading;
};

export interface BookingServicingAcceptFlightInput {
  airRecordLocator: Scalars["String"];
  obid?: InputMaybe<Scalars["String"]>;
  tripId: Scalars["String"];
  tripItemId?: InputMaybe<Scalars["String"]>;
  tripViewId?: InputMaybe<Scalars["String"]>;
}

export type BookingServicingAcceptInformationCards = {
  __typename?: "BookingServicingAcceptInformationCards";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  heading: Scalars["String"];
  policyCards: Array<BookingServicingInformationCard>;
};

export type BookingServicingAcceptReview = {
  __typename?: "BookingServicingAcceptReview";
  content: BookingServicingReviewAcceptContent;
  toolbar: BookingServicingToolbar;
};

export type BookingServicingAppNavigation = BookingServicingClientAction & {
  __typename?: "BookingServicingAppNavigation";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  destination: BookingServicingViews;
};

export type BookingServicingBasicCheckBox = BookingServicingCheckBox & {
  __typename?: "BookingServicingBasicCheckBox";
  checkbox: EgdsBasicCheckBox;
};

export type BookingServicingBreakdownItem = {
  __typename?: "BookingServicingBreakdownItem";
  accessibilityMessage: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type BookingServicingButton = {
  __typename?: "BookingServicingButton";
  button: EgdsButton;
  clientAction: BookingServicingClientAction;
  iconPosition?: Maybe<EgdsIconPosition>;
};

export type BookingServicingCancelConfirmationContentElement =
  | BookingServicingCard
  | BookingServicingFlightRefundSummaryCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingItemsBreakdownCard
  | BookingServicingMessages
  | BookingServicingMessagingElement;

export type BookingServicingCancelConfirmationLoaded = {
  __typename?: "BookingServicingCancelConfirmationLoaded";
  response: BookingServicingCancelConfirmationResponse;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingCancelConfirmationResponse =
  | BookingServicingCancelConfirmationSuccess
  | BookingServicingError
  | CancelConfirmationError;

export type BookingServicingCancelConfirmationSuccess = {
  __typename?: "BookingServicingCancelConfirmationSuccess";
  /** @deprecated Deprecated in favor of using presentation, replace with presentation */
  content: CancelConfirmationPresentation;
  footer: CancelConfirmationFooter;
  presentation?: Maybe<BookingServicingSummaryPresentation>;
};

export interface BookingServicingCancelCriteriaInput {
  flightsCriteriaInput: BookingServicingCancelFlightInput;
}

export type BookingServicingCancelExitDialog = {
  __typename?: "BookingServicingCancelExitDialog";
  content: Array<BookingServicingElement>;
  displayAnalytics: ClientSideAnalytics;
  graphic?: Maybe<UiGraphic>;
  sheet: EgdsSheet;
};

export interface BookingServicingCancelFlightInput {
  airRecordLocator: Scalars["String"];
  cancelType: BookingServicingCancelType;
  obid?: InputMaybe<Scalars["String"]>;
  tripId: Scalars["String"];
  tripItemId?: InputMaybe<Scalars["String"]>;
  tripViewId?: InputMaybe<Scalars["String"]>;
}

export type BookingServicingCancelFlightInputType = {
  __typename?: "BookingServicingCancelFlightInputType";
  airRecordLocator: Scalars["String"];
  cancelType: BookingServicingCancelType;
  obid?: Maybe<Scalars["String"]>;
  tripId: Scalars["String"];
  tripItemId?: Maybe<Scalars["String"]>;
  tripViewId?: Maybe<Scalars["String"]>;
};

export type BookingServicingCancelHeadsUp = {
  __typename?: "BookingServicingCancelHeadsUp";
  headsUpLoaded: BookingServicingCancelHeadsUpLoaded;
  headsUpLoading: BookingServicingCancelHeadsUpLoading;
};

export type BookingServicingCancelHeadsUpHeadsUpLoadedArgs = {
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type BookingServicingCancelHeadsUpHeadsUpLoadingArgs = {
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type BookingServicingCancelHeadsUpLoaded = {
  __typename?: "BookingServicingCancelHeadsUpLoaded";
  /** @deprecated Use the response field, replace with HeadsUpResponse */
  content: BookingServicingRulesAndRestrictionsPresentation;
  /** @deprecated Use the response field, replace with HeadsUpResponse */
  footer: BookingServicingCardCallToAction;
  /** @deprecated Deprecated in favor of BookingServicingToolbar, replace with HeadsUpResponse */
  goBackAction?: Maybe<BookingServicingResourceLinkAction>;
  /** @deprecated Use the response field, replace with HeadsUpResponse */
  graphic?: Maybe<UiGraphic>;
  presentation?: Maybe<BookingServicingHeadsUp>;
  /** @deprecated Deprecated in favor of using presentation, replace with presentation */
  response: HeadsUpResponse;
  /** @deprecated Deprecated in favor of using toolbar inside presentation, replace with presentation toolbar */
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingCancelHeadsUpLoading = {
  __typename?: "BookingServicingCancelHeadsUpLoading";
  goBackAction?: Maybe<BookingServicingResourceLinkAction>;
  numberOfCards: Scalars["Int"];
  rulesAndRestrictionsLink: EgdsStandardLink;
  title: Scalars["String"];
};

export type BookingServicingCancelType = "INVOLUNTARY" | "VOLUNTARY";

export type BookingServicingCancelView = {
  __typename?: "BookingServicingCancelView";
  cancelInputs: BookingServicingCancelFlightInputType;
  cancelView: BookingServicingCancelViews;
};

export type BookingServicingCancelViews = "CONFIRMATION" | "HEADS_UP" | "REVIEW";

export type BookingServicingCancellationAction = BookingServicingClientAction & {
  __typename?: "BookingServicingCancellationAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingCancellationContentElement =
  | BookingServicingCard
  | BookingServicingFlightRefundSummaryCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingItemsBreakdownCard
  | BookingServicingMessages
  | BookingServicingMessagingElement;

export type BookingServicingCard = {
  __typename?: "BookingServicingCard";
  area?: Maybe<Scalars["String"]>;
  callToAction?: Maybe<BookingServicingCardCallToAction>;
  content: Array<BookingServicingCardElement>;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  theme?: Maybe<BookingServicingCardTheme>;
  title: Scalars["String"];
};

export type BookingServicingCardCallToAction = {
  __typename?: "BookingServicingCardCallToAction";
  buttons?: Maybe<Array<BookingServicingButton>>;
  /** @deprecated Deprecated in favor of a list of elements, replace with elements */
  element: EgdsButton;
  /** @deprecated Deprecated in favor of a list of buttons, replace with buttons */
  elements?: Maybe<Array<EgdsButton>>;
  footerText?: Maybe<Scalars["String"]>;
};

export type BookingServicingCardElement =
  | BookingServicingCardCallToAction
  | BookingServicingCheckboxesArea
  | BookingServicingCustomTriggerDatePicker
  | BookingServicingCustomTriggerTravelerSelector
  | BookingServicingLink
  | BookingServicingSearchForm
  | BookingServicingText
  | BookingServicingTexts;

export type BookingServicingCardTheme = "NEGATIVE";

export interface BookingServicingChangeCriteriaInput {
  flightsInput?: InputMaybe<BookingChangeFlightInput>;
}

export interface BookingServicingChangeErrorInput {
  adults?: InputMaybe<Scalars["String"]>;
  backNavigation?: InputMaybe<Scalars["String"]>;
  changeType?: InputMaybe<Scalars["String"]>;
  checkInDate?: InputMaybe<Scalars["String"]>;
  checkOutDate?: InputMaybe<Scalars["String"]>;
  children?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  itineraryNumber?: InputMaybe<Scalars["String"]>;
}

export type BookingServicingChangeLodging = {
  __typename?: "BookingServicingChangeLodging";
  detailsPresentation: BookingServicingChangePresentation;
  directWordPresentation: BookingServicingDirectWordPresentation;
  errorPresentation: BookingServicingChangePresentation;
  messaging?: Maybe<Array<BookingServicingMessagingElement>>;
  presentation: BookingServicingChangePresentation;
};

export type BookingServicingChangeLodgingDetailsPresentationArgs = {
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingDirectWordPresentationArgs = {
  changeType?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingErrorPresentationArgs = {
  changeLodgingInput: BookingServicingChangeLodgingInput;
  errorInput: BookingServicingChangeErrorInput;
};

export type BookingServicingChangeLodgingMessagingArgs = {
  confirmedChangeTypes?: InputMaybe<Array<Scalars["String"]>>;
  input?: InputMaybe<BookingServicingChangeLodgingInput>;
};

export type BookingServicingChangeLodgingPresentationArgs = {
  confirmedChangeTypes?: InputMaybe<Array<Scalars["String"]>>;
  input: BookingServicingChangeLodgingInput;
};

export interface BookingServicingChangeLodgingInput {
  orderLineGuid: Scalars["String"];
  orderNumber: Scalars["String"];
  tripId: Scalars["String"];
}

export type BookingServicingChangeLodgingMutationResponse = {
  __typename?: "BookingServicingChangeLodgingMutationResponse";
  clientAction?: Maybe<BookingServicingClientAction>;
  errorSummary?: Maybe<BookingServicingChangePresentation>;
};

export type BookingServicingChangePresentation = {
  __typename?: "BookingServicingChangePresentation";
  criteria?: Maybe<BookingServicingSearchCriteria>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  errorAction?: Maybe<BookingServicingClientAction>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]>;
  immediateSheetAction?: Maybe<BookingServicingSheetAction>;
  messaging?: Maybe<Array<BookingServicingMessagingElement>>;
  navigationAction?: Maybe<UiLinkAction>;
  primaryButton?: Maybe<BookingServicingButton>;
  secondaryButton?: Maybe<BookingServicingButton>;
  sections?: Maybe<Array<BookingServicingElement>>;
  subText?: Maybe<Array<EgdsText>>;
  toolbar?: Maybe<BookingServicingToolbar>;
  virtualAgent?: Maybe<BookingServicingVirtualAgentControl>;
};

export type BookingServicingChangeRedirectAction = BookingServicingClientAction & {
  __typename?: "BookingServicingChangeRedirectAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  redirectURI: Uri;
};

export type BookingServicingChangeRedirectPresentation =
  | BookingServicingChangeRedirectAction
  | BookingServicingErrorSummary;

export interface BookingServicingChangeSelectionInput {
  name: Scalars["String"];
  value: Scalars["String"];
}

export type BookingServicingChangeSelectionInputType = {
  __typename?: "BookingServicingChangeSelectionInputType";
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type BookingServicingChangeView = {
  __typename?: "BookingServicingChangeView";
  changeInputs: BookingChangeFlightInputType;
  changeView: BookingServicingChangeViews;
};

export type BookingServicingChangeViews = "CHANGE_FORM";

export interface BookingServicingCheckBox {
  checkbox: EgdsBasicCheckBox;
}

export type BookingServicingCheckboxesArea = {
  __typename?: "BookingServicingCheckboxesArea";
  /** @deprecated Deprecated in favor of a nullable field, replace with selectAll */
  applyAll: EgdsBasicCheckBox;
  /** @deprecated Deprecated in favor of using BookingServicingCheckbox, replace with selectAll */
  checkAll?: Maybe<EgdsBasicCheckBox>;
  checkboxes?: Maybe<Array<BookingServicingCheckBox>>;
  /** @deprecated Deprecated in favor of using BookingServicingCheckbox, replace with checkboxes */
  individuals: Array<EgdsBasicCheckBox>;
  selectAll?: Maybe<BookingServicingCheckBox>;
  validations?: Maybe<Array<BookingServicingTravelerInputValidation>>;
};

export type BookingServicingClient = "VIRTUAL_AGENT" | "VNEXT" | "WEB";

export interface BookingServicingClientAction {
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
}

export type BookingServicingCloseAction = BookingServicingClientAction & {
  __typename?: "BookingServicingCloseAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingComposableTexts =
  | BookingServicingLink
  | EgdsGraphicText
  | EgdsHeading
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText;

export type BookingServicingConfirmCancellation = {
  __typename?: "BookingServicingConfirmCancellation";
  confirmCancellationLoading: BookingServicingConfirmLoading;
};

export type BookingServicingConfirmLoading = {
  __typename?: "BookingServicingConfirmLoading";
  heading?: Maybe<Scalars["String"]>;
  subHeading?: Maybe<Scalars["String"]>;
};

export type BookingServicingCreditsFailure = {
  __typename?: "BookingServicingCreditsFailure";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  buttons: Array<BookingServicingButton>;
  /** @deprecated Moving to EGClickstream event tracking, replace with analyticsPayload */
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  emptyState: UiEmptyState;
};

export type BookingServicingCreditsLoading = {
  __typename?: "BookingServicingCreditsLoading";
  heading: Scalars["String"];
};

export type BookingServicingCreditsResult =
  | BookingServicingCreditsFailure
  | BookingServicingCreditsLoading
  | BookingServicingCreditsSuccess;

export type BookingServicingCreditsSection = {
  __typename?: "BookingServicingCreditsSection";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  banner?: Maybe<UiBanner>;
  cards?: Maybe<Array<BookingServicingOfferCard>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<Scalars["String"]>;
  helpMessages?: Maybe<Array<BookingServicingComposableTexts>>;
  messages?: Maybe<Array<BookingServicingComposableTexts>>;
  unavailableCards?: Maybe<Array<BookingServicingOfferCard>>;
  unavailableCardsExpando?: Maybe<EgdsExpandoLink>;
};

export type BookingServicingCreditsSuccess = {
  __typename?: "BookingServicingCreditsSuccess";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  banner?: Maybe<UiBanner>;
  content: Array<BookingServicingComposableTexts>;
  couponsSection?: Maybe<BookingServicingCreditsSection>;
  credits?: Maybe<Array<BookingServicingOfferCard>>;
  /** @deprecated Moving to EGClickstream event tracking, replace with analyticsPayload */
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  title?: Maybe<Scalars["String"]>;
};

export type BookingServicingCustomTrigger = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type BookingServicingCustomTriggerDatePicker = {
  __typename?: "BookingServicingCustomTriggerDatePicker";
  datePicker: EgdsSingleDatePicker;
  trigger: BookingServicingCustomTrigger;
  validations?: Maybe<Array<BookingServicingDatePickerValidation>>;
};

export type BookingServicingCustomTriggerTravelerSelector = {
  __typename?: "BookingServicingCustomTriggerTravelerSelector";
  selector: Array<GraphQlPair>;
  trigger: BookingServicingCustomTrigger;
};

export type BookingServicingDatePickerValidation = {
  __typename?: "BookingServicingDatePickerValidation";
  message: Scalars["String"];
  ruleName: Scalars["String"];
};

export type BookingServicingDeeplinkAction = BookingServicingClientAction & {
  __typename?: "BookingServicingDeeplinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  deeplink: Uri;
};

export type BookingServicingDirectWordPresentation = {
  __typename?: "BookingServicingDirectWordPresentation";
  button?: Maybe<BookingServicingButton>;
};

export type BookingServicingElement =
  | BookingServicingButton
  | BookingServicingCard
  | BookingServicingFormSection
  | BookingServicingLink
  | BookingServicingText
  | BookingServicingTextList;

export type BookingServicingError = {
  __typename?: "BookingServicingError";
  buttons?: Maybe<Array<BookingServicingButton>>;
  description: Array<Scalars["String"]>;
  displayAnalytics: Array<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"];
};

export type BookingServicingErrorSummary = {
  __typename?: "BookingServicingErrorSummary";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  errorMessages: Array<UiBanner>;
};

export type BookingServicingExitDialog = {
  __typename?: "BookingServicingExitDialog";
  accessibility?: Maybe<Scalars["String"]>;
  content: Array<BookingServicingElement>;
  dialogId: Scalars["String"];
  /** @deprecated Deprecated in favor of a list of analytics, replace with displayAnalyticsList */
  displayAnalytics: ClientSideAnalytics;
  displayAnalyticsList: Array<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  sheet: EgdsSheet;
};

export type BookingServicingExpando = {
  __typename?: "BookingServicingExpando";
  collapsedLabel: BookingServicingText;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  expandedLabel: BookingServicingText;
  expando: EgdsExpando;
};

export type BookingServicingFlightLegDetails = {
  __typename?: "BookingServicingFlightLegDetails";
  badge?: Maybe<EgdsStandardBadge>;
  departureDate: Scalars["String"];
  departureTime: Scalars["String"];
  departureTimeAccessibilityMessage: Scalars["String"];
  flightInfo: EgdsGraphicText;
  flightsDetailedJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  originAndDestination: Scalars["String"];
  stopOverDetails?: Maybe<Scalars["String"]>;
};

export type BookingServicingFlightRefundSummaryCard = {
  __typename?: "BookingServicingFlightRefundSummaryCard";
  /** @deprecated Deprecated in favor of a list of description messages, replace with descriptionMessages */
  description: Scalars["String"];
  descriptionMessages?: Maybe<Array<EgdsSpannableText>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"];
  refundDetails?: Maybe<BookingServicingPriceDetails>;
  totalRefundAmount?: Maybe<BookingServicingBreakdownItem>;
};

export type BookingServicingFlightsDetailedCardDescription = {
  __typename?: "BookingServicingFlightsDetailedCardDescription";
  description?: Maybe<Array<EgdsSpannableText>>;
  heading: Scalars["String"];
  travelReferenceNumbers?: Maybe<Array<EgdsSpannableText>>;
};

export type BookingServicingFlightsDetailedCardFlightDetails = {
  __typename?: "BookingServicingFlightsDetailedCardFlightDetails";
  legs: Array<BookingServicingFlightLegDetails>;
  travelerTripSummary?: Maybe<Scalars["String"]>;
};

export type BookingServicingFlightsDetailedCardSection = {
  __typename?: "BookingServicingFlightsDetailedCardSection";
  contents: Array<BookingServicingFlightsDetailedCardSectionContent>;
};

export type BookingServicingFlightsDetailedCardSectionContent =
  | BookingServicingFlightsDetailedCardDescription
  | BookingServicingFlightsDetailedCardFlightDetails
  | BookingServicingFlightsDetailedCardTravelers;

export type BookingServicingFlightsDetailedCardTravelers = {
  __typename?: "BookingServicingFlightsDetailedCardTravelers";
  heading: Scalars["String"];
  travelers: Array<BookingServicingGraphicText>;
};

export type BookingServicingFlightsTripDetailedCard = {
  __typename?: "BookingServicingFlightsTripDetailedCard";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  sections: Array<BookingServicingFlightsDetailedCardSection>;
};

export type BookingServicingFlightsTripSummaryCard = {
  __typename?: "BookingServicingFlightsTripSummaryCard";
  badge?: Maybe<EgdsStandardBadge>;
  description: Array<EgdsSpannableText>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  expando?: Maybe<EgdsExpandoListItem>;
  heading?: Maybe<Scalars["String"]>;
  legs: Array<BookingServicingFlightLegDetails>;
  subheading?: Maybe<Scalars["String"]>;
  travelers?: Maybe<Array<Maybe<EgdsGraphicText>>>;
  travelersTripType?: Maybe<Scalars["String"]>;
};

export type BookingServicingFooter = {
  __typename?: "BookingServicingFooter";
  buttons: Array<BookingServicingButton>;
  text?: Maybe<Scalars["String"]>;
};

export type BookingServicingFormAnalytics = {
  __typename?: "BookingServicingFormAnalytics";
  analytics: ClientSideAnalytics;
  id: Scalars["String"];
};

export type BookingServicingFormInputContainer = {
  __typename?: "BookingServicingFormInputContainer";
  actionButton?: Maybe<BookingServicingButton>;
  actionDescription?: Maybe<Scalars["String"]>;
  analyticsList?: Maybe<Array<BookingServicingFormAnalytics>>;
  inputFields?: Maybe<Array<BookingServicingFormInputField>>;
  label?: Maybe<Scalars["String"]>;
  subText?: Maybe<Array<Scalars["String"]>>;
  validationAnalyticsList?: Maybe<Array<BookingServicingFormAnalytics>>;
};

export type BookingServicingFormInputField = BookingServicingFormOptions | EgdsTextAreaInputField | EgdsTextInputField;

export type BookingServicingFormOptions = {
  __typename?: "BookingServicingFormOptions";
  expando: EgdsExpando;
  optionGroup: BookingServicingOptionGroup;
};

export type BookingServicingFormSection = {
  __typename?: "BookingServicingFormSection";
  heading?: Maybe<Scalars["String"]>;
  subSections?: Maybe<Array<BookingServicingFormSubSection>>;
};

export type BookingServicingFormSubSection = {
  __typename?: "BookingServicingFormSubSection";
  heading?: Maybe<Scalars["String"]>;
  inputContainers?: Maybe<Array<BookingServicingFormInputContainer>>;
  subText?: Maybe<Array<Scalars["String"]>>;
};

export type BookingServicingGraphicText = {
  __typename?: "BookingServicingGraphicText";
  graphic: UiGraphic;
  text: Scalars["String"];
};

export type BookingServicingHeading = {
  __typename?: "BookingServicingHeading";
  heading: Scalars["String"];
  link?: Maybe<EgdsStandardLink>;
};

export type BookingServicingHeadsUp = {
  __typename?: "BookingServicingHeadsUp";
  content: BookingServicingHeadsUpContent;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingHeadsUpContent = BookingServicingError | BookingServicingHeadsUpSuccess;

export type BookingServicingHeadsUpSuccess = {
  __typename?: "BookingServicingHeadsUpSuccess";
  contents: Array<BookingServicingInformationCard>;
  displayAnalytics: Array<ClientSideAnalytics>;
  footer: BookingServicingFooter;
  heading: BookingServicingHeading;
};

export type BookingServicingInformationBanner = {
  __typename?: "BookingServicingInformationBanner";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  graphic: UiGraphic;
  text: Scalars["String"];
};

export type BookingServicingInformationCard = {
  __typename?: "BookingServicingInformationCard";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"];
  links?: Maybe<Array<EgdsStandardLink>>;
  texts: Array<Scalars["String"]>;
};

export type BookingServicingItemsBreakdownCard = {
  __typename?: "BookingServicingItemsBreakdownCard";
  description: Scalars["String"];
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"];
  items: Array<BookingServicingBreakdownItem>;
  link?: Maybe<EgdsStandardLink>;
};

export type BookingServicingLink = {
  __typename?: "BookingServicingLink";
  action?: Maybe<BookingServicingClientAction>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  link: EgdsStandardLink;
};

export type BookingServicingLinkTarget = "EXTERNAL" | "INTERNAL";

export type BookingServicingMessages = {
  __typename?: "BookingServicingMessages";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"];
  messages: Array<BookingServicingCard>;
};

export type BookingServicingMessagingElement = {
  __typename?: "BookingServicingMessagingElement";
  component: BookingServicingMessagingElementComponent;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type BookingServicingMessagingElementComponent = EgdsStandardMessagingCard | UiBanner;

export type BookingServicingOfferCard = {
  __typename?: "BookingServicingOfferCard";
  /** @deprecated Use additionalLinkSections instead. Will also support EGClickstream AnalyticsPayload */
  additionalContentSections?: Maybe<Array<EgdsStandardLink>>;
  additionalLinkSections?: Maybe<Array<BookingServicingLink>>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  content: BookingServicingOfferContent;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  featuredHeader?: Maybe<BookingServicingOfferFeaturedHeader>;
  /** @deprecated Does not allow tracking of multiple analytics. Use displayAnalytics instead */
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
};

export type BookingServicingOfferContent = {
  __typename?: "BookingServicingOfferContent";
  priceSection?: Maybe<BookingServicingOfferPriceSection>;
  primarySection: EgdsTextSection;
};

export type BookingServicingOfferFeaturedHeader = {
  __typename?: "BookingServicingOfferFeaturedHeader";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  heading: Scalars["String"];
  icon: Icon;
};

export type BookingServicingOfferPriceSection = {
  __typename?: "BookingServicingOfferPriceSection";
  priceLockup: EgdsPriceLockup;
};

export type BookingServicingOptionGroup = EgdsBasicRadioGroup | EgdsCheckBoxGroup;

export type BookingServicingPreviousView = {
  __typename?: "BookingServicingPreviousView";
  accessibility?: Maybe<Scalars["String"]>;
};

export type BookingServicingPriceDetails = {
  __typename?: "BookingServicingPriceDetails";
  content: PricePresentation;
  expando: BookingServicingExpando;
};

export type BookingServicingRefetch = BookingServicingClientAction & {
  __typename?: "BookingServicingRefetch";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingResourceLinkAction = BookingServicingClientAction & {
  __typename?: "BookingServicingResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  resource: Uri;
  target: BookingServicingLinkTarget;
  useRelativePath?: Maybe<Scalars["Boolean"]>;
};

export type BookingServicingReviewAcceptContent = BookingServicingError | BookingServicingReviewAcceptSuccess;

export type BookingServicingReviewAcceptElements =
  | BookingServicingAcceptInformationCards
  | BookingServicingFlightsTripDetailedCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingInformationBanner;

export type BookingServicingReviewAcceptPresentation = {
  __typename?: "BookingServicingReviewAcceptPresentation";
  content: Array<BookingServicingReviewAcceptElements>;
};

export type BookingServicingReviewAcceptSuccess = {
  __typename?: "BookingServicingReviewAcceptSuccess";
  content: BookingServicingReviewAcceptPresentation;
  footer: BookingServicingFooter;
  heading: BookingServicingHeading;
};

export type BookingServicingReviewCancellation = {
  __typename?: "BookingServicingReviewCancellation";
  reviewCancellationLoaded: BookingServicingReviewCancellationLoaded;
};

export type BookingServicingReviewCancellationReviewCancellationLoadedArgs = {
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type BookingServicingReviewCancellationError = {
  __typename?: "BookingServicingReviewCancellationError";
  buttons: Array<BookingServicingButton>;
  content: Array<BookingServicingText>;
  /** @deprecated Deprecated in favor of a list of content text, replace with content */
  description: Scalars["String"];
  displayAnalytics: Array<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"];
};

export type BookingServicingReviewCancellationLoaded = {
  __typename?: "BookingServicingReviewCancellationLoaded";
  response: BookingServicingReviewCancellationResponse;
  /** @deprecated Deprecated in favor of using toolbarSection, replace with toolbarSection */
  toolbar: BookingServicingToolbar;
  toolbarSection?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingReviewCancellationResponse =
  | BookingServicingError
  | BookingServicingReviewCancellationError
  | BookingServicingReviewCancellationSuccess;

export type BookingServicingReviewCancellationSuccess = {
  __typename?: "BookingServicingReviewCancellationSuccess";
  /** @deprecated Deprecated in favor of using presentation, replace with presentation */
  content: ReviewCancellationPresentation;
  /** @deprecated Deprecated in favor of using footerSection, replace with footerSection */
  footer: ReviewCancellationFooter;
  footerSection?: Maybe<ReviewCancellationFooter>;
  presentation?: Maybe<BookingServicingSummaryPresentation>;
};

export type BookingServicingRulesAndRestrictionsPresentation = {
  __typename?: "BookingServicingRulesAndRestrictionsPresentation";
  content: Array<BookingServicingElement>;
  displayAnalytics: ClientSideAnalytics;
  footerText?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  link?: Maybe<EgdsStandardLink>;
  primaryButton?: Maybe<BookingServicingButton>;
};

export type BookingServicingSearchCriteria = PropertySearchCriteria;

export type BookingServicingSearchForm = {
  __typename?: "BookingServicingSearchForm";
  searchForm: FlightSearchInputForm;
};

export type BookingServicingSheetAction = {
  __typename?: "BookingServicingSheetAction";
  dismissAction: BookingServicingResourceLinkAction;
  sheet: EgdsSheet;
  sheetContent: BookingServicingRulesAndRestrictionsPresentation;
};

export type BookingServicingSubmitAction = BookingServicingClientAction & {
  __typename?: "BookingServicingSubmitAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingSummary =
  | BookingServicingCard
  | BookingServicingFlightRefundSummaryCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingItemsBreakdownCard
  | BookingServicingMessages
  | BookingServicingMessagingElement;

export type BookingServicingSummaryPresentation = {
  __typename?: "BookingServicingSummaryPresentation";
  content: Array<BookingServicingSummary>;
  displayAnalytics: Array<ClientSideAnalytics>;
  heading: Scalars["String"];
};

export type BookingServicingText = {
  __typename?: "BookingServicingText";
  primary: EgdsText;
  secondary?: Maybe<EgdsText>;
};

export type BookingServicingTextList = {
  __typename?: "BookingServicingTextList";
  heading?: Maybe<EgdsHeading>;
  list: EgdsSpannableList;
};

export type BookingServicingTexts = {
  __typename?: "BookingServicingTexts";
  texts: Array<BookingServicingText>;
};

export type BookingServicingToolbar = {
  __typename?: "BookingServicingToolbar";
  dialog?: Maybe<BookingServicingToolbarDialog>;
  navigation: BookingServicingToolbarNavigation;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<BookingServicingToolbarType>;
};

export type BookingServicingToolbarDialog = BookingServicingExitDialog;

export type BookingServicingToolbarNavigation = {
  __typename?: "BookingServicingToolbarNavigation";
  action: BookingServicingClientAction;
  buttonId: Scalars["String"];
  buttonLabel?: Maybe<Scalars["String"]>;
  iconLabel: Scalars["String"];
  navigationType?: Maybe<BookingServicingToolbarNavigationType>;
};

export type BookingServicingToolbarNavigationType = "BACK" | "BACK_DIALOG" | "CLOSE" | "EXPAND_LESS" | "EXPAND_UP";

export type BookingServicingToolbarType = "STANDARD" | "TRANSPARENT";

export type BookingServicingTravelerCheckBox = BookingServicingCheckBox & {
  __typename?: "BookingServicingTravelerCheckBox";
  checkbox: EgdsBasicCheckBox;
  travelerType?: Maybe<BookingServicingTravelerType>;
};

export interface BookingServicingTravelerDetailsInput {
  accessibility?: InputMaybe<Array<Scalars["String"]>>;
  bedTypeId?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
  smoking?: InputMaybe<Scalars["String"]>;
}

export type BookingServicingTravelerInputValidation = {
  __typename?: "BookingServicingTravelerInputValidation";
  errorSummary?: Maybe<EgdsErrorSummary>;
  type: BookingServicingTravelerValidationType;
};

export type BookingServicingTravelerType = "ADULT" | "CHILD" | "INFANT";

export type BookingServicingTravelerValidationType = "CHILD_TRAVELING_ALONE";

export type BookingServicingViews =
  | BookingServicingCancelView
  | BookingServicingChangeView
  | BookingServicingPreviousView;

export type BookingServicingVirtualAgentAnalytic = {
  __typename?: "BookingServicingVirtualAgentAnalytic";
  analytic: ClientSideAnalytics;
  subscribedEvent: Scalars["String"];
};

export type BookingServicingVirtualAgentControl = {
  __typename?: "BookingServicingVirtualAgentControl";
  clientOverrides?: Maybe<BookingServicingVirtualAgentConversationContext>;
  pageName: Scalars["String"];
};

export type BookingServicingVirtualAgentConversationContext = {
  __typename?: "BookingServicingVirtualAgentConversationContext";
  analytics?: Maybe<Array<BookingServicingVirtualAgentAnalytic>>;
  conversationProperties?: Maybe<BookingServicingVirtualAgentConversationContextProperties>;
  intent?: Maybe<Scalars["String"]>;
  intentArguments?: Maybe<Array<BookingServicingVirtualAgentIntentArgument>>;
};

export type BookingServicingVirtualAgentConversationContextProperties = {
  __typename?: "BookingServicingVirtualAgentConversationContextProperties";
  launchPoint?: Maybe<Scalars["String"]>;
  pageName?: Maybe<Scalars["String"]>;
  skipWelcome?: Maybe<Scalars["String"]>;
};

export type BookingServicingVirtualAgentIntentAction = BookingServicingClientAction & {
  __typename?: "BookingServicingVirtualAgentIntentAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  context?: Maybe<BookingServicingVirtualAgentConversationContext>;
};

export type BookingServicingVirtualAgentIntentArgument = {
  __typename?: "BookingServicingVirtualAgentIntentArgument";
  id: Scalars["String"];
  value: Scalars["String"];
};

export type BookingSummaryItem = {
  __typename?: "BookingSummaryItem";
  originalAmountPaid: Scalars["Float"];
  supplierNetRate: Scalars["Float"];
  title: Scalars["String"];
};

export type BookingUpdate = {
  __typename?: "BookingUpdate";
  attribute?: Maybe<EgdsStylizedText>;
  existingAttributeValue: EgdsStylizedText;
  updatedAttributeValue: EgdsStylizedText;
};

export type BooleanEnum = "FALSE" | "TRUE";

export type BooleanValue = {
  __typename?: "BooleanValue";
  id: Scalars["String"];
  value: Scalars["Boolean"];
};

export interface BooleanValueInput {
  id: Scalars["String"];
  value: Scalars["Boolean"];
}

export type Bounds = {
  __typename?: "Bounds";
  northeast: Coordinates;
  southwest: Coordinates;
};

export type BrandedDealCrossSellFallback = MultiItemFlightCrossSellPrimer;

export type BrandedDealCrossSellUiContent = {
  __typename?: "BrandedDealCrossSellUIContent";
  primaryContent: BrandedDealCrossSellUiContentSection;
  secondaryContent: BrandedDealCrossSellUiContentSection;
};

export type BrandedDealCrossSellUiContentItem = BrandedDealCrossSellUiContent | EgdsStylizedText;

export type BrandedDealCrossSellUiContentSection = {
  __typename?: "BrandedDealCrossSellUIContentSection";
  details: Array<BrandedDealCrossSellUiElement>;
};

export type BrandedDealCrossSellUiElement =
  | BrandedDealCrossSellUiImageWithText
  | MultiItemCrossSellUiBadge
  | MultiItemCrossSellUiHeaderText
  | MultiItemCrossSellUiLinkAction
  | MultiItemCrossSellUiText;

export type BrandedDealCrossSellUiImageWithText = {
  __typename?: "BrandedDealCrossSellUIImageWithText";
  description: MultiItemCrossSellUiText;
  image: Image;
};

export type BrandedDealCrossSellUiResponse = {
  __typename?: "BrandedDealCrossSellUIResponse";
  action: MultiItemCrossSellUiLinkAction;
  actionBar: Array<MultiItemCrossSellUiLinkAction>;
  /** @deprecated Use crossSellBadges instead */
  badges?: Maybe<Array<MultiItemCrossSellUiBadge>>;
  brandedDealContent: Array<BrandedDealCrossSellUiContentItem>;
  /** @deprecated Use brandedDealContent instead */
  contentSections: BrandedDealCrossSellUiContent;
  crossSellBadges: Array<EgdsProgramBadge>;
  impressionAnalytics: ClientSideAnalytics;
};

export type BrandedDealPrice = {
  __typename?: "BrandedDealPrice";
  base: Money;
  taxesAndFees: Money;
};

export interface BrandedDealProductPriceInput {
  base: MoneyInput;
  taxesAndFees: MoneyInput;
}

export type BrandedDealSearchPrice = {
  __typename?: "BrandedDealSearchPrice";
  base: SearchAmountModel;
  taxesAndFees: SearchAmountModel;
};

export type Breadcrumb = BreadcrumbItem &
  TextBreadcrumb & {
    __typename?: "Breadcrumb";
    text: Scalars["String"];
    uiLinkAction: UiLinkAction;
  };

export interface BreadcrumbItem {
  text?: Maybe<Scalars["String"]>;
  uiLinkAction?: Maybe<UiLinkAction>;
}

export type Breadcrumbs = {
  __typename?: "Breadcrumbs";
  breadcrumbItems: Array<BreadcrumbItem>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  menuAnalytics?: Maybe<ClientSideAnalytics>;
  seoStructuredData: Scalars["String"];
};

export type BreakpointConfig = {
  __typename?: "BreakpointConfig";
  lg: Scalars["Int"];
  md: Scalars["Int"];
  sm: Scalars["Int"];
  xl: Scalars["Int"];
};

export interface BulkAddInventoryInput {
  dates: Array<DateInput>;
  increment: Scalars["Int"];
  roomTypeIDs: Array<Scalars["String"]>;
}

export type BulkAddInventoryResponse = {
  __typename?: "BulkAddInventoryResponse";
  applyFailedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  applySucceededEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  responseType: BulkEditResponseType;
};

export type BulkEditResponseType = "ERROR" | "SUCCESS";

export type BulletStyle = "DEFAULT" | "ICON_ALTERNATE" | "ICON_STANDARD" | "NO_BULLET" | "UNRECOGNIZED";

export type BusinessModelType = "EXPEDIA_COLLECT" | "HOTEL_COLLECT";

export type Campaign = {
  __typename?: "Campaign";
  campaignId: Scalars["UUID"];
  goal: Scalars["String"];
  isPaused: Scalars["Boolean"];
  name: Scalars["String"];
};

export type CampaignContentPaginationInfo = {
  __typename?: "CampaignContentPaginationInfo";
  currentPage: Scalars["Int"];
  hasMoreContentPages: Scalars["Boolean"];
};

export type CampaignDescriptions = {
  __typename?: "CampaignDescriptions";
  long?: Maybe<Scalars["String"]>;
  short?: Maybe<Scalars["String"]>;
};

export type CampaignDetail = {
  __typename?: "CampaignDetail";
  banner: Image;
  campaignContentPaginationInfo?: Maybe<CampaignContentPaginationInfo>;
  deeplinkType?: Maybe<DeeplinkType>;
  description: CampaignDescriptions;
  /** @deprecated No longer relevant, replace with OffersUrl.url */
  destinationUrl?: Maybe<Scalars["String"]>;
  fromOriginHeaderText?: Maybe<Scalars["String"]>;
  headline: Scalars["String"];
  id: Scalars["String"];
  landingPageUrl?: Maybe<Scalars["URL"]>;
  offers?: Maybe<Array<OfferItem>>;
  offersUrl: OffersUrl;
  productTypes: Array<Scalars["String"]>;
  recommendedType: RecommendedType;
  referrer: Scalars["String"];
  termsAndConditions: Scalars["String"];
};

export type CampaignDetailOffersArgs = {
  numberOfDestinations?: InputMaybe<Scalars["Int"]>;
};

export type CampaignOfferBadge = {
  __typename?: "CampaignOfferBadge";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
  type: CampaignOfferBadgeType;
};

export type CampaignOfferBadgeType = "DEFAULT" | "DISCOUNT";

export type CampaignPagination = {
  __typename?: "CampaignPagination";
  hasNextPage: Scalars["Boolean"];
  referrer: Scalars["String"];
};

export interface CampaignParametersInput {
  campaignId: Scalars["String"];
  contentPagination?: InputMaybe<ContentPaginationInput>;
  propertyParameters?: InputMaybe<CampaignPropertyParametersInput>;
}

export type CampaignProductType = "ACTIVITY" | "FLIGHT" | "HOTEL" | "PACKAGE";

export interface CampaignPropertyParametersInput {
  guestRating?: InputMaybe<Scalars["Float"]>;
  propertyId: Scalars["String"];
  starRating?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<PropertyDetailsPropertyType>;
}

export type CancelConfirmationError = {
  __typename?: "CancelConfirmationError";
  buttons: Array<BookingServicingButton>;
  content?: Maybe<Array<BookingServicingText>>;
  displayAnalytics: Array<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"];
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type CancelConfirmationFooter = {
  __typename?: "CancelConfirmationFooter";
  buttons?: Maybe<Array<Maybe<BookingServicingButton>>>;
};

export type CancelConfirmationPresentation = {
  __typename?: "CancelConfirmationPresentation";
  content?: Maybe<Array<BookingServicingCancelConfirmationContentElement>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"];
};

export type CancellationPolicyDialogContentEntry = DialogContentEntry & {
  __typename?: "CancellationPolicyDialogContentEntry";
  entryContent: Array<Scalars["String"]>;
  entryTitle: LodgingHeader;
};

export interface CancellationWaiverDeclineRequestContext {
  bookingItemId: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
  reason: Scalars["String"];
  serviceRequestId: Scalars["String"];
}

export type CarAccessory = {
  __typename?: "CarAccessory";
  accessibility: Scalars["String"];
  analytics: CarAnalytics;
  id: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<Scalars["String"]>;
  pricePeriod?: Maybe<Scalars["String"]>;
  selectionState: CarAccessorySelectionState;
  text?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type CarAccessorySelectionState = "NOT_SELECTED" | "SELECTED";

export type CarAction = {
  __typename?: "CarAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionType: CarActionType;
  analytics?: Maybe<CarAnalytics>;
};

export type CarActionType =
  | "APPLY_LOYALTY_POINTS"
  | "CAR_MAP_MARKER"
  | "CHANGE_SEARCH"
  | "DISMISS"
  | "FILTER_SALE_OFFER"
  | "FORM_SUBMISSION"
  | "GO_BACK_TO_SEARCH"
  | "GO_TO_CANCELLATION_POLICY"
  | "GO_TO_CHECKOUT"
  | "GO_TO_CLEANLINESS_DIALOG"
  | "GO_TO_DELTA_CAMPAIGN_LINK"
  | "GO_TO_DETAILS"
  | "GO_TO_PACKAGES"
  | "GO_TO_RECOMMENDED_SORT_FAQ"
  | "GO_TO_SIGNIN"
  | "GO_TO_SIGNUP"
  | "GO_TO_SIGN_IN_AND_UNLOCK_CAR_SALES"
  | "GO_TO_WPLUS_MEMBER_TRIAL_LINK"
  | "LOAD_MORE"
  | "OFF_AIRPORT_CHANGE_SEARCH"
  | "OPEN_DIALOG"
  | "OPEN_DOC"
  | "OPEN_GROUND_TRANSFERS"
  | "OPEN_INLINE_TIME_WIZARD"
  | "OPEN_INLINE_WIZARD"
  | "OPEN_NEW_TAB"
  | "OPEN_ON_CURRENT_TAB"
  | "OPEN_PARTNER_MESSAGING_LINK"
  | "OPEN_RULE"
  | "OPEN_TEL"
  | "REFRESH"
  | "REMOVE_LOYALTY_POINTS"
  | "SEE_CARS_AT_MAP_MARKER"
  | "SHARE_FEEDBACK"
  | "SHOW_AARP_EXCLUSIVE_OFFERS"
  | "SHOW_FREE_CANCELLATION_OFFERS"
  | "UNLOCK_CAR_SALES";

export type CarActionableItem = {
  __typename?: "CarActionableItem";
  action: CarAction;
  icon?: Maybe<Icon>;
  tel?: Maybe<TelUri>;
  text: Scalars["String"];
  url?: Maybe<HttpUri>;
};

export type CarAdditionalBenefits = {
  __typename?: "CarAdditionalBenefits";
  benefits: Array<CarPhraseIconText>;
  headingText: Scalars["String"];
};

export type CarAnalytics = {
  __typename?: "CarAnalytics";
  linkName: Scalars["String"];
  referrerId: Scalars["String"];
};

export type CarAppliedSortAndFilter = {
  __typename?: "CarAppliedSortAndFilter";
  analytics: ClientSideAnalytics;
  crossIcon: Icon;
  id: Scalars["String"];
  text: Scalars["String"];
  value: Scalars["String"];
};

export type CarAppliedSortAndFilters = {
  __typename?: "CarAppliedSortAndFilters";
  appliedSortAndFilters: Array<CarAppliedSortAndFilter>;
};

export type CarAvailableAccessories = {
  __typename?: "CarAvailableAccessories";
  accessories: Array<CarAccessory>;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type CarBreakupComponent = {
  __typename?: "CarBreakupComponent";
  id: Scalars["String"];
  lineItems: Array<CarBreakupLineItem>;
  title: Scalars["String"];
};

export type CarBreakupLineItem = {
  __typename?: "CarBreakupLineItem";
  accessibility?: Maybe<Scalars["String"]>;
  dialogContent?: Maybe<CarPriceBreakupDialog>;
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  openDialogAction?: Maybe<CarAction>;
  richValue?: Maybe<CarsRichText>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  value: Scalars["String"];
};

export type CarBreakupSummarySection = {
  __typename?: "CarBreakupSummarySection";
  lineItems: Array<CarBreakupLineItem>;
  notIncludedInTotal?: Maybe<CarBreakupComponent>;
  title: Scalars["String"];
  total: Scalars["String"];
};

export type CarButton = {
  __typename?: "CarButton";
  analytics?: Maybe<CarAnalytics>;
  text: Scalars["String"];
};

export type CarDetailContext = {
  __typename?: "CarDetailContext";
  carOfferToken: Scalars["String"];
  continuationContextualId?: Maybe<Scalars["String"]>;
  optionAction?: Maybe<CarAccessorySelectionState>;
  optionToken?: Maybe<Scalars["String"]>;
  originalDeeplink?: Maybe<Scalars["String"]>;
  principalToken?: Maybe<Scalars["String"]>;
  rewardPointsSelection?: Maybe<RewardsPointSelectionEnum>;
  selectedAccessories?: Maybe<Array<Scalars["String"]>>;
};

export interface CarDetailContextInput {
  carOfferToken: Scalars["String"];
  continuationContextualId?: InputMaybe<Scalars["String"]>;
  optionAction?: InputMaybe<CarAccessorySelectionState>;
  optionToken?: InputMaybe<Scalars["String"]>;
  originalDeeplink?: InputMaybe<Scalars["String"]>;
  principalToken?: InputMaybe<Scalars["String"]>;
  rewardPointsSelection?: InputMaybe<RewardsPointSelectionEnum>;
  selectedAccessories?: InputMaybe<Array<Scalars["String"]>>;
}

export type CarDetailsResult = {
  __typename?: "CarDetailsResult";
  actionableConfidenceMessages: Array<CarPhrase>;
  additionalBenefits?: Maybe<CarAdditionalBenefits>;
  adsTargetingData?: Maybe<AdsTargetingData>;
  availableAccessories?: Maybe<CarAvailableAccessories>;
  carOfferToken?: Maybe<Scalars["String"]>;
  carSearchLink?: Maybe<CarActionableItem>;
  deal?: Maybe<CarMessagingCard>;
  detailSummary: CarDetailsSummary;
  detailsNavTabs?: Maybe<DetailsNavTabs>;
  directFeedbackListing?: Maybe<DirectFeedbackListing>;
  dropOffCharge?: Maybe<CarMessagingCard>;
  easeCancel?: Maybe<CarMessagingCard>;
  enhancedCleanlinessInfo?: Maybe<EnhancedCleanlinessInfo>;
  importantInfo: CarImportantInfo;
  inclusionsDetail?: Maybe<CarInclusionsDetail>;
  insurancePriming?: Maybe<CarInsurancePriming>;
  isTravelApiPath: Scalars["Boolean"];
  loyaltyInfo?: Maybe<Array<CarPhrase>>;
  offerBadges?: Maybe<Array<CarOfferBadge>>;
  oneKeyDetails?: Maybe<OneKeyDetails>;
  oneKeyInfo?: Maybe<CarOfferBadge>;
  onlineCheckInInfo?: Maybe<CarOnlineCheckIn>;
  pairConfidenceMessages?: Maybe<Array<CarPhrase>>;
  priceAlert?: Maybe<CarMessagingCard>;
  priceDetails?: Maybe<CarPriceDetails>;
  priceSummary: CarPriceSummary;
  promotions: Array<CarPromotion>;
  rentalLocations: CarRentalLocations;
  rentalProtectionInfo?: Maybe<RentalProtectionInfo>;
  reserveAction: CarActionableItem;
  reviewsSummary?: Maybe<CarReviewsSummary>;
  saleUnlocked?: Maybe<CarMessagingCard>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  skipTheCounterInfo?: Maybe<CarSkipTheCounterInfo>;
  telesales?: Maybe<CarTelesales>;
  tripsSaveItemWrapper?: Maybe<TripsSaveItemWrapper>;
  variantsSummary?: Maybe<CarVariantsSummary>;
  vehicle?: Maybe<VehicleDetails>;
  vendor: CarVendor;
};

export type CarDetailsSummary = {
  __typename?: "CarDetailsSummary";
  pageTitle: Scalars["String"];
};

export type CarDialogConfidenceMessage = {
  __typename?: "CarDialogConfidenceMessage";
  dialogContent?: Maybe<CarsDialog>;
  icon?: Maybe<Icon>;
  iconMobileRender: Scalars["Boolean"];
  openDialogAction?: Maybe<CarAction>;
  text: Scalars["String"];
};

export type CarDialogContent = {
  __typename?: "CarDialogContent";
  action: CarAction;
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type CarDisclaimerDialog = {
  __typename?: "CarDisclaimerDialog";
  closeAnalytics: CarAnalytics;
  /** @deprecated use contents instead */
  content: Array<CarPhrase>;
  contentNative?: Maybe<Array<CarPhraseKeyValueTuple>>;
  contents: Array<Array<CarPhrase>>;
  feedback?: Maybe<UserFeedback>;
};

export type CarDropOffOfferCard = {
  __typename?: "CarDropOffOfferCard";
  carPriceDetail: CarPriceDetail;
  detailsContext: CarDetailContext;
  selectButton: CarActionableItem;
  tripLocations: CarOfferVendorLocations;
  vehicle: VehicleDetails;
  vendor: CarVendor;
  viewMap: CarActionableItem;
};

export type CarDropOffTitle = {
  __typename?: "CarDropOffTitle";
  action: CarAction;
  icon: Icon;
  text: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
};

export type CarFormatStyle = "IFRAME" | "LIST";

export type CarImportantInfo = {
  __typename?: "CarImportantInfo";
  importantInfoItems: Array<CarImportantInfoItems>;
  infos: Array<Scalars["String"]>;
  rulesAndRestrictions: CarActionableItem;
  title: Scalars["String"];
};

export type CarImportantInfoItems = {
  __typename?: "CarImportantInfoItems";
  analytics: CarAnalytics;
  description: Array<Scalars["String"]>;
  icon?: Maybe<Icon>;
  name: Scalars["String"];
  subtext: Scalars["String"];
};

export type CarInclusion = {
  __typename?: "CarInclusion";
  analytics: CarAnalytics;
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  infos?: Maybe<Array<CarPhrase>>;
  summary?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CarInclusionsDetail = {
  __typename?: "CarInclusionsDetail";
  inclusions: Array<CarInclusion>;
  text: Scalars["String"];
  title: Scalars["String"];
};

export type CarInsurancePriming = {
  __typename?: "CarInsurancePriming";
  icon: Icon;
  text: Scalars["String"];
};

export type CarItemCardMapMarker = CarMapMarker & {
  __typename?: "CarItemCardMapMarker";
  action: CarAction;
  coordinates: Coordinates;
  hoverAction?: Maybe<Array<CarAnalytics>>;
  icon?: Maybe<Icon>;
  itemCard: CarMapItemCard;
  type: CarMapMarkerType;
};

export type CarListingCard = CarMessagingCard | CarOfferCard | CarSaleCard;

export type CarLoadMoreAction = {
  __typename?: "CarLoadMoreAction";
  action: CarAction;
  icon: Icon;
  searchPagination: CarSearchPagination;
  text: Scalars["String"];
};

export type CarMap = {
  __typename?: "CarMap";
  bounds: CarMapBounds;
  center: Coordinates;
  closeAction: CarAction;
  closeIcon: Icon;
  dialogAccessibility: Scalars["String"];
  filterTitle?: Maybe<Scalars["String"]>;
  mapButton: CarActionableItem;
  mapSearchThisAreaButton?: Maybe<CarActionableItem>;
  markers: Array<CarMapMarker>;
  text: Scalars["String"];
  title: Scalars["String"];
  zoomLevel: Scalars["Int"];
};

export type CarMapBounds = {
  __typename?: "CarMapBounds";
  northeast: Coordinates;
  southwest: Coordinates;
};

export type CarMapDropOffLocationCard = {
  __typename?: "CarMapDropOffLocationCard";
  address: Scalars["String"];
  carPriceDetail: CarPriceDetail;
  detailsContext: CarDetailContext;
  distance: Scalars["String"];
  placeId?: Maybe<Scalars["String"]>;
  selectButton: CarActionableItem;
  superlative: Scalars["String"];
  vendorImage: Image;
  vendorLocationId: Scalars["String"];
};

export type CarMapItemCard = CarMapDropOffLocationCard | CarMapPickupLocationCard | CarMapSearchLocationCard;

export interface CarMapMarker {
  action: CarAction;
  coordinates: Coordinates;
  hoverAction?: Maybe<Array<CarAnalytics>>;
  icon?: Maybe<Icon>;
  type: CarMapMarkerType;
}

export type CarMapMarkerType = "CarMarker" | "PlaceMarker";

export type CarMapPickupLocationCard = {
  __typename?: "CarMapPickupLocationCard";
  action: CarAction;
  address: Scalars["String"];
  distance: Scalars["String"];
  placeId?: Maybe<Scalars["String"]>;
  priceSummary: CarPriceSummary;
  seeCarsButtonText: Scalars["String"];
  shortAddress: Scalars["String"];
  superlative: Scalars["String"];
  totalResultsByLocation?: Maybe<Scalars["Int"]>;
  totalResultsByLocationFormatted?: Maybe<Scalars["String"]>;
  vendorImage: Image;
  vendorLocationId: Scalars["String"];
  vendorReview?: Maybe<CarOfferReview>;
};

export type CarMapSearchLocationCard = {
  __typename?: "CarMapSearchLocationCard";
  text: Scalars["String"];
  title: Scalars["String"];
};

export type CarMessagingCard = {
  __typename?: "CarMessagingCard";
  analytics?: Maybe<CarAnalytics>;
  carRecommendations?: Maybe<Array<Maybe<CarRecommendations>>>;
  cardDescriptions: Array<CarPhraseText>;
  cardTitle?: Maybe<CarsRichText>;
  /** @deprecated use cardDescriptions instead */
  description: Scalars["String"];
  /** @deprecated use cardDescriptions instead */
  descriptions: Array<Scalars["String"]>;
  dialog?: Maybe<CarsDialog>;
  icon?: Maybe<Icon>;
  illustrationURL?: Maybe<HttpUri>;
  image?: Maybe<Image>;
  links: Array<CarActionableItem>;
  mark?: Maybe<Scalars["String"]>;
  /** @deprecated use cardTitle instead */
  title?: Maybe<Scalars["String"]>;
};

export type CarNaturalKey = {
  __typename?: "CarNaturalKey";
  offerToken: Scalars["String"];
  specialEquipments?: Maybe<Array<SpecialEquipment>>;
};

export interface CarNaturalKeyInput {
  offerToken: Scalars["String"];
  specialEquipments?: InputMaybe<Array<SpecialEquipment>>;
}

export type CarOfferBadge = {
  __typename?: "CarOfferBadge";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text: Scalars["String"];
  theme: Scalars["String"];
};

export type CarOfferCard = {
  __typename?: "CarOfferCard";
  accessibilityString?: Maybe<Scalars["String"]>;
  action: CarAction;
  actionableConfidenceMessages: Array<CarPhrase>;
  additionalBenefits?: Maybe<Array<CarPhraseIconText>>;
  cancellationAndPaymentMessages?: Maybe<Array<CarPhrase>>;
  clubbedPriceOptions?: Maybe<ClubbedPriceOptions>;
  comparableDealsClickAnalytics?: Maybe<CarAnalytics>;
  detailsContext: CarDetailContext;
  enhancedCleanlinessDialog?: Maybe<EnhancedCleanlinessDialog>;
  guaranteedAnalytics?: Maybe<CarAnalytics>;
  infositeURL: HttpUri;
  /** @deprecated to delete */
  isFareComparisonTestEnabled?: Maybe<Scalars["Boolean"]>;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  offerBadges: Array<CarOfferBadge>;
  offerDiscountBadges: Array<CarOfferBadge>;
  offerHeading?: Maybe<Scalars["String"]>;
  offerPositionClickAnalytics?: Maybe<CarAnalytics>;
  personalisedDeal?: Maybe<CarPhraseText>;
  priceBadges: Array<CarOfferBadge>;
  priceSummary: CarPriceSummary;
  priceSummaryText?: Maybe<Scalars["String"]>;
  reserveButtonText: Scalars["String"];
  review?: Maybe<CarOfferReview>;
  shortList: CarShortList;
  tripLocations: CarOfferVendorLocations;
  tripsSaveItemWrapper?: Maybe<TripsSaveItemWrapper>;
  unlockOffer?: Maybe<CarActionableItem>;
  vehicle: VehicleDetails;
  vendor: CarVendor;
};

export type CarOfferLocationInfo = {
  __typename?: "CarOfferLocationInfo";
  locationSubInfo?: Maybe<Array<Scalars["String"]>>;
  text: Scalars["String"];
};

export type CarOfferLocations = {
  __typename?: "CarOfferLocations";
  dropOffLocation?: Maybe<CarOfferLocationInfo>;
  pickUpLocation: CarOfferLocationInfo;
};

export type CarOfferReview = {
  __typename?: "CarOfferReview";
  rating: Scalars["String"];
  richRatingText?: Maybe<CarsRichText>;
  superlative: Scalars["String"];
  totalCount?: Maybe<Scalars["String"]>;
};

export type CarOfferVendorLocationInfo = {
  __typename?: "CarOfferVendorLocationInfo";
  icon: Icon;
  /** @deprecated same value as locationId, will use placeId in the future */
  id: Scalars["String"];
  locationId: Scalars["String"];
  locationSubInfo?: Maybe<Array<Scalars["String"]>>;
  placeId: Scalars["String"];
  text: Scalars["String"];
};

export type CarOfferVendorLocations = {
  __typename?: "CarOfferVendorLocations";
  dropOffLocation?: Maybe<CarOfferVendorLocationInfo>;
  pickUpLocation: CarOfferVendorLocationInfo;
};

export type CarOnlineCheckIn = {
  __typename?: "CarOnlineCheckIn";
  icon?: Maybe<Icon>;
  infos: Array<CarPhrase>;
  title: Scalars["String"];
};

export type CarPaymentInfo = {
  __typename?: "CarPaymentInfo";
  additionalPaymentInfo?: Maybe<Array<Scalars["String"]>>;
  icon: Icon;
  text: Scalars["String"];
};

export type CarPhrase =
  | CarActionableItem
  | CarDialogConfidenceMessage
  | CarDialogContent
  | CarOfferBadge
  | CarPhraseHtmlText
  | CarPhraseIconText
  | CarPhraseMark
  | CarPhrasePairText
  | CarPhraseText
  | CarsRichText;

export type CarPhraseHtmlText = {
  __typename?: "CarPhraseHTMLText";
  placeholderTextUrl?: Maybe<Array<CarActionableItem>>;
  value: CarPhraseText;
};

export type CarPhraseIconText = {
  __typename?: "CarPhraseIconText";
  icon?: Maybe<Icon>;
  text: Scalars["String"];
  theme?: Maybe<Scalars["String"]>;
};

export type CarPhraseKeyValueTuple = {
  __typename?: "CarPhraseKeyValueTuple";
  key: Scalars["String"];
  value: Array<CarPhrase>;
};

export type CarPhraseMark = {
  __typename?: "CarPhraseMark";
  description: Scalars["String"];
  name: Scalars["String"];
  text: Scalars["String"];
  url?: Maybe<HttpUri>;
};

export type CarPhrasePairText = {
  __typename?: "CarPhrasePairText";
  richSubText?: Maybe<CarsRichText>;
  richText?: Maybe<CarsRichText>;
};

export type CarPhraseText = {
  __typename?: "CarPhraseText";
  text: Scalars["String"];
};

export type CarPriceBreakupDialog = {
  __typename?: "CarPriceBreakupDialog";
  breakupContent?: Maybe<Array<CarBreakupLineItem>>;
  commonDialog: CarsDialog;
};

export type CarPriceDetail = {
  __typename?: "CarPriceDetail";
  priceSubInfo?: Maybe<Array<Scalars["String"]>>;
  priceTitle: Scalars["String"];
};

export type CarPriceDetails = {
  __typename?: "CarPriceDetails";
  breakupSection: Array<CarBreakupComponent>;
  closeAction: CarAction;
  closeIcon: Icon;
  priceDetailsButton: CarActionableItem;
  summary: CarBreakupSummarySection;
  title: Scalars["String"];
};

export type CarPriceInfo = {
  __typename?: "CarPriceInfo";
  accessibility?: Maybe<Scalars["String"]>;
  formattedValue?: Maybe<Scalars["String"]>;
  price: Money;
  qualifier: Scalars["String"];
  rawPrice?: Maybe<Scalars["Float"]>;
};

export type CarPriceSummary = {
  __typename?: "CarPriceSummary";
  accessibility?: Maybe<Scalars["String"]>;
  discountBadge?: Maybe<CarOfferBadge>;
  floatingCTAPriceDetailsAnalytics?: Maybe<CarAnalytics>;
  floatingCTAReserveButtonAnalytics?: Maybe<CarAnalytics>;
  lead?: Maybe<CarPriceInfo>;
  loyaltyPointsOption?: Maybe<LoyaltyPointsOption>;
  paymentInfo?: Maybe<CarPaymentInfo>;
  priceAdditionalInfo?: Maybe<Array<Scalars["String"]>>;
  reference?: Maybe<CarReferencePriceInfo>;
  strikeThroughFirst?: Maybe<Scalars["Boolean"]>;
  total: CarPriceInfo;
};

export type CarPromotion = {
  __typename?: "CarPromotion";
  additionalInfo?: Maybe<Scalars["String"]>;
  icon: Icon;
  text: Scalars["String"];
};

export interface CarRecommendationCard {
  action?: Maybe<CarAction>;
  icon?: Maybe<Icon>;
}

export type CarRecommendations = {
  __typename?: "CarRecommendations";
  analytics?: Maybe<Array<CarAnalytics>>;
  carRecommendationsCards?: Maybe<Array<CarRecommendationCard>>;
  heading?: Maybe<Scalars["String"]>;
};

export type CarReferencePriceInfo = {
  __typename?: "CarReferencePriceInfo";
  /** @deprecated Use price summary accessibility string */
  accessibility?: Maybe<Scalars["String"]>;
  additionalInfo?: Maybe<Scalars["String"]>;
  price: Money;
  qualifier?: Maybe<Scalars["String"]>;
};

export type CarRentalLocation = {
  __typename?: "CarRentalLocation";
  airportCode?: Maybe<Scalars["String"]>;
  coordinates?: Maybe<Coordinates>;
  isExactLocationSearch?: Maybe<Scalars["Boolean"]>;
  regionId?: Maybe<Scalars["String"]>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type CarRentalLocationDetail = {
  __typename?: "CarRentalLocationDetail";
  address: CarVendorLocationInfo;
  analytics?: Maybe<CarAnalytics>;
  analyticsOnClose?: Maybe<CarAnalytics>;
  dateTime: CarVendorLocationInfo;
  hoursOfOperation?: Maybe<CarVendorLocationInfo>;
  instruction?: Maybe<CarVendorLocationInfo>;
  locationTimeLine?: Maybe<Scalars["String"]>;
  showLocationDetail?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export interface CarRentalLocationInput {
  airportCode?: InputMaybe<Scalars["String"]>;
  coordinates?: InputMaybe<CoordinatesInput>;
  isExactLocationSearch?: InputMaybe<Scalars["Boolean"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
}

export type CarRentalLocations = {
  __typename?: "CarRentalLocations";
  dropOffLocation: CarRentalLocationDetail;
  isLocationDataMerged: Scalars["Boolean"];
  pickUpLocation: CarRentalLocationDetail;
  title: Scalars["String"];
};

export type CarReview = {
  __typename?: "CarReview";
  author?: Maybe<Scalars["String"]>;
  classificationName: Scalars["String"];
  expandButton: ExpandButton;
  rentalDuration?: Maybe<Scalars["String"]>;
  submissionTime: Scalars["String"];
  text: Scalars["String"];
  themes?: Maybe<Array<CarReviewThemes>>;
};

export type CarReviewDialogContent = {
  __typename?: "CarReviewDialogContent";
  description: Scalars["String"];
  /** @deprecated use disclaimer text list instead */
  disclaimerFullDescription: Scalars["String"];
  disclaimerLink?: Maybe<CarActionableItem>;
  disclaimerTextList?: Maybe<Array<Scalars["String"]>>;
  disclaimerTitle: Scalars["String"];
  disclaimerTitleAction: CarActionableItem;
  heading: Scalars["String"];
  /** @deprecated Use feedbackForm instead */
  opinionLabFeedback: OpinionLabFeedback;
  reviewBreakdown: Array<ReviewBreakdown>;
  reviews?: Maybe<Array<CarReview>>;
  reviewsSeeMoreAction?: Maybe<CarButton>;
  totalRatings: Scalars["String"];
  userFeedback: UserFeedback;
};

export type CarReviewThemes = {
  __typename?: "CarReviewThemes";
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]>;
};

export type CarReviewsSummary = {
  __typename?: "CarReviewsSummary";
  actionableVerifiedRatingsCountMsg?: Maybe<CarDialogConfidenceMessage>;
  dialogCloseIconLabel: Scalars["String"];
  dialogContent?: Maybe<CarReviewDialogContent>;
  dialogTrigger?: Maybe<CarActionableItem>;
  location?: Maybe<Scalars["String"]>;
  recommendedRate: Scalars["String"];
  recommendedText: Scalars["String"];
  superlative?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  topReviews: Array<Scalars["String"]>;
};

export type CarRouteType = "ONEWAY" | "ROUNDTRIP";

export type CarRulesAndRestrictionsContainer = {
  __typename?: "CarRulesAndRestrictionsContainer";
  analytics: CarAnalytics;
  footer?: Maybe<CarPhraseText>;
  formatStyle?: Maybe<CarFormatStyle>;
  id: Scalars["String"];
  rulesAndRestrictionsItems: Array<CarPhrase>;
  title: CarPhraseText;
};

export interface CarRulesAndRestrictionsCriteriaInput {
  carOfferToken?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["String"]>;
  useEgtpPath?: InputMaybe<Scalars["Boolean"]>;
}

export type CarRulesAndRestrictionsResult = {
  __typename?: "CarRulesAndRestrictionsResult";
  carSummary: CarSummary;
  header: CarPhraseText;
  /** @deprecated To be deleted and we need to create separate interface and its implementations(List, Text, Iframe etc) for R&R */
  rulesAndRestrictionsContainer: Array<CarRulesAndRestrictionsContainer>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  toolbar: CarToolbar;
};

export type CarSaleCard = {
  __typename?: "CarSaleCard";
  analytics?: Maybe<CarAnalytics>;
  cardAction?: Maybe<CarAction>;
  cardDescription?: Maybe<CarPhraseText>;
  cardTitle?: Maybe<CarsRichText>;
  image?: Maybe<Image>;
  linkText?: Maybe<CarsRichText>;
};

export type CarSearchCriteria = {
  __typename?: "CarSearchCriteria";
  primary: PrimaryCarCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface CarSearchCriteriaInput {
  primary: PrimaryCarCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type CarSearchFilterNoMatchMessage = {
  __typename?: "CarSearchFilterNoMatchMessage";
  allOffersTextSeparator: Scalars["String"];
  messagingCard: CarMessagingCard;
};

export type CarSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "CarSearchForm";
    actionURL: Scalars["String"];
    alternativeDropOffLocationLabel?: Maybe<Scalars["String"]>;
    dates?: Maybe<EgdsSearchFormDatePickerField>;
    driverAgeCheck?: Maybe<CarSearchFormDriverAgeCheck>;
    dropOffLocation?: Maybe<EgdsSearchFormLocationField>;
    dropOffTime?: Maybe<EgdsSelect>;
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    localDateFormat?: Maybe<Scalars["String"]>;
    pickUpLocation?: Maybe<EgdsSearchFormLocationField>;
    pickUpTime?: Maybe<EgdsSelect>;
    playback?: Maybe<EgdsSearchPlayback>;
    rentalTypes?: Maybe<EgdsBasicTabs>;
    search?: Maybe<EgdsButton>;
    specialRates?: Maybe<CarSearchFormSpecialRates>;
  };

export type CarSearchFormCorporateOrContractedOption = {
  __typename?: "CarSearchFormCorporateOrContractedOption";
  discountCode: EgdsTextInputField;
  optionLabel: Scalars["String"];
};

export type CarSearchFormDiscountCode = {
  __typename?: "CarSearchFormDiscountCode";
  action: CarSearchFormDiscountCodeAction;
  discountCode: EgdsPill;
};

export type CarSearchFormDiscountCodeAction = {
  __typename?: "CarSearchFormDiscountCodeAction";
  discountType: EgdsSelect;
  discountTypeOptions: Array<CarSearchFormDiscountTypeOption>;
  done: EgdsButton;
  errorSummary: EgdsErrorSummary;
  preferredBrand: EgdsSelect;
  toolbar: UiToolbar;
};

export type CarSearchFormDiscountTypeOption = {
  __typename?: "CarSearchFormDiscountTypeOption";
  corporateOrContractedOption?: Maybe<CarSearchFormCorporateOrContractedOption>;
  preferredBrand: CarSearchFormPreferredBrand;
  specialOrAdvertisedOption: CarSearchFormSpecialOrAdvertisedOption;
};

export type CarSearchFormDriverAgeCheck = {
  __typename?: "CarSearchFormDriverAgeCheck";
  driverAge: EgdsNumberInputField;
  driverAgeRequired: EgdsBasicCheckBox;
  label: Scalars["String"];
};

export type CarSearchFormPreferredBrand =
  | "AC"
  | "AD"
  | "AL"
  | "EP"
  | "ET"
  | "EY"
  | "FX"
  | "GI"
  | "MW"
  | "NU"
  | "RO"
  | "SC"
  | "SV"
  | "SX"
  | "ZA"
  | "ZD"
  | "ZE"
  | "ZI"
  | "ZL"
  | "ZR"
  | "ZT";

export type CarSearchFormSpecialOrAdvertisedDiscountCodeOption = {
  __typename?: "CarSearchFormSpecialOrAdvertisedDiscountCodeOption";
  discountCodeInput: EgdsTextInputField;
  discountCodeTypeOption: Scalars["String"];
};

export type CarSearchFormSpecialOrAdvertisedOption = {
  __typename?: "CarSearchFormSpecialOrAdvertisedOption";
  addAnotherDiscountCode: EgdsButton;
  discountCodeOptions: Array<CarSearchFormSpecialOrAdvertisedDiscountCodeOption>;
  discountCodeType: EgdsSelect;
  maxDiscountCode: Scalars["Int"];
  optionLabel: Scalars["String"];
};

export type CarSearchFormSpecialRates = {
  __typename?: "CarSearchFormSpecialRates";
  discountCode: CarSearchFormDiscountCode;
  label: Scalars["String"];
  showAarpRate?: Maybe<EgdsPill>;
};

export type CarSearchListing = CarMessagingCard | CarOfferCard | CarSaleCard | SponsoredContentPlacement;

export type CarSearchLocation = {
  __typename?: "CarSearchLocation";
  fullName: Scalars["String"];
  regionId?: Maybe<Scalars["String"]>;
  shortName: Scalars["String"];
};

export type CarSearchLocations = {
  __typename?: "CarSearchLocations";
  dropOffLocation?: Maybe<CarSearchLocation>;
  pickUpLocation: CarSearchLocation;
  routeType: CarRouteType;
  searchAccessibility: Scalars["String"];
};

export type CarSearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "CarSearchMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type CarSearchPagination = {
  __typename?: "CarSearchPagination";
  size: Scalars["Int"];
  startingIndex: Scalars["Int"];
};

export type CarSearchResults = {
  __typename?: "CarSearchResults";
  adsTargetingData?: Maybe<AdsTargetingData>;
  carAppliedSortAndFilters: CarAppliedSortAndFilters;
  /** @deprecated use carRentalLocations node instead */
  carRentalLocation: CarOfferLocations;
  carRentalLocations: CarSearchLocations;
  carSearchListings: Array<CarSearchListing>;
  carsShoppingContext: CarsShoppingContext;
  changeRentalLocation?: Maybe<ChangeRentalLocation>;
  filterNoMatchMessage?: Maybe<CarSearchFilterNoMatchMessage>;
  /** @deprecated Use 'carSearchListings going forward */
  listings: Array<CarListingCard>;
  loadMoreAction?: Maybe<CarLoadMoreAction>;
  loyaltyInfo?: Maybe<Array<CarPhrase>>;
  makeModelDisclaimer: CarPhrase;
  map?: Maybe<CarMap>;
  multiItemAnchorPriceToken?: Maybe<Scalars["String"]>;
  multiItemPickUpDropOffMessage?: Maybe<MultiItemPickUpDropOffMessage>;
  multiItemPlayBackTitleBar?: Maybe<MultiItemPlayBackTitleBar>;
  oneKeyInfo?: Maybe<OneKeyInfo>;
  recommendedSortDisclaimer?: Maybe<CarPhrase>;
  recommendedSortExplanation: CarDisclaimerDialog;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  shoppingContext?: Maybe<ShoppingContext>;
  sortAndFilter: ShoppingSortAndFilters;
  summary: CarSearchSummary;
};

export type CarSearchSummary = {
  __typename?: "CarSearchSummary";
  carRecommendations?: Maybe<Array<Maybe<CarRecommendations>>>;
  dynamicTitle: Array<Array<CarPhrase>>;
  includedFeeFooter?: Maybe<IncludedFeeFooter>;
  pageTitle: Scalars["String"];
  /** @deprecated to be deleted to use dynamicTitle */
  title: Scalars["String"];
};

export type CarShareFeedbackAction = {
  __typename?: "CarShareFeedbackAction";
  button: CarActionableItem;
  text: Scalars["String"];
};

export type CarShortList = {
  __typename?: "CarShortList";
  favorited: Scalars["Boolean"];
  removeUrl: Scalars["String"];
  saveUrl: Scalars["String"];
};

export type CarSkipTheCounterInfo = {
  __typename?: "CarSkipTheCounterInfo";
  icon?: Maybe<Icon>;
  infos: Array<CarPhrase>;
  title: Scalars["String"];
};

export type CarSummary = {
  __typename?: "CarSummary";
  pickupDateTime: CarPhraseText;
  pickupLocation: CarPhraseText;
  vehicleCategory: CarPhraseText;
  vendor: CarVendor;
};

export type CarTelesales = {
  __typename?: "CarTelesales";
  label: Scalars["String"];
  phoneNumberLink: CarActionableItem;
};

export type CarToolbar = {
  __typename?: "CarToolbar";
  action: CarAction;
  icon: Icon;
};

export type CarVariant = {
  __typename?: "CarVariant";
  additionalInfo: CarVariantAdditionalInfo;
  badges: Array<Scalars["String"]>;
  carOfferToken: Scalars["String"];
  features: Array<CarVariantFeature>;
  price: CarVariantPriceSummary;
  selection: CarVariantSelection;
  title: Scalars["String"];
};

export type CarVariantAdditionalInfo = {
  __typename?: "CarVariantAdditionalInfo";
  icon: Icon;
  infoPanel: CarVariantInfoPanel;
};

export type CarVariantFeature = {
  __typename?: "CarVariantFeature";
  icon: Icon;
  text: Scalars["String"];
};

export type CarVariantInfoPanel = {
  __typename?: "CarVariantInfoPanel";
  details: Array<CarVariantInfoPanelDetail>;
  summary: Scalars["String"];
  title: Scalars["String"];
};

export type CarVariantInfoPanelDetail = {
  __typename?: "CarVariantInfoPanelDetail";
  features: Array<CarVariantInfoPanelFeature>;
  text: Scalars["String"];
};

export type CarVariantInfoPanelFeature = {
  __typename?: "CarVariantInfoPanelFeature";
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type CarVariantPriceSummary = {
  __typename?: "CarVariantPriceSummary";
  lead: CarPriceInfo;
  messages: Array<Scalars["String"]>;
  total?: Maybe<CarPriceInfo>;
};

export type CarVariantSelection = {
  __typename?: "CarVariantSelection";
  icon?: Maybe<Icon>;
  status: Scalars["Boolean"];
  text: Scalars["String"];
};

export type CarVariantsSummary = {
  __typename?: "CarVariantsSummary";
  title: Scalars["String"];
  variants: Array<CarVariant>;
};

export type CarVendor = {
  __typename?: "CarVendor";
  image?: Maybe<Image>;
};

export type CarVendorLocationInfo = {
  __typename?: "CarVendorLocationInfo";
  icon: Icon;
  locationSubInfo?: Maybe<Array<Scalars["String"]>>;
  text: Scalars["String"];
};

export type CardActions = "DISMISS";

export type CardConfiguration = PaymentCardConfiguration;

export type CardSize = "LARGE" | "MEDIUM" | "SMALL";

export type CardStatus = "NOT_UPGRADED" | "UPGRADED";

export type CardTheme =
  | "GLOBAL_LOYALTY_EXTRAHIGH"
  | "GLOBAL_LOYALTY_HIGH"
  | "GLOBAL_LOYALTY_LOW"
  | "GLOBAL_LOYALTY_MIDDLE"
  | "GLOBAL_LOYALTY_STANDARD"
  | "PRIMARY"
  | "SECONDARY";

export type CardinalTemplate = {
  __typename?: "CardinalTemplate";
  category: PluralCategory;
  template: Scalars["String"];
};

export type CarouselAction = {
  __typename?: "CarouselAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type CarouselActions = {
  __typename?: "CarouselActions";
  next: CarouselAction;
  previous: CarouselAction;
};

export type CarouselButton = EgdsCarouselItem & {
  __typename?: "CarouselButton";
  action: UiLinkAction;
  button: UiButton;
  id: Scalars["String"];
};

export type CarouselIcon = EgdsCarouselItem & {
  __typename?: "CarouselIcon";
  action: UiLinkAction;
  button: UiPrimaryButton;
  id: Scalars["String"];
};

export type CarouselItemAccessibility = {
  __typename?: "CarouselItemAccessibility";
  accessibilityNext?: Maybe<Scalars["String"]>;
  accessibilityPrevious?: Maybe<Scalars["String"]>;
};

export type CarryOnBagType = "CARRY_ON_BAG";

export type CarsDialog = {
  __typename?: "CarsDialog";
  buttonText: Scalars["String"];
  closeButtonLabel?: Maybe<Scalars["String"]>;
  content?: Maybe<CarsDialogContent>;
  text: Array<Scalars["String"]>;
  title: Scalars["String"];
  type?: Maybe<CarsDialogType>;
};

export type CarsDialogBody = {
  __typename?: "CarsDialogBody";
  body: Array<CarPhrase>;
  title: CarPhrase;
};

export type CarsDialogContent = {
  __typename?: "CarsDialogContent";
  body: Array<CarsDialogBody>;
  footer?: Maybe<Array<CarPhrase>>;
  header: CarPhrase;
};

export type CarsDialogType = "LIMITED_RESULTS" | "LIMITED_RESULTS_WITHOUT_TURO" | "LIMITED_RESULTS_WITH_TURO";

export interface CarsDomainRequestContextInput {
  partnerContext: PartnerContextInput;
  principalJWT: Scalars["String"];
}

export type CarsErrorContent = {
  __typename?: "CarsErrorContent";
  analytics: CarAnalytics;
  errorEventName?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  locationAnalytics?: Maybe<CarAnalytics>;
  resetButtonAction?: Maybe<UiPrimaryButton>;
  subText: Scalars["String"];
};

export type CarsRichText = {
  __typename?: "CarsRichText";
  style?: Maybe<CarsRichTextStyle>;
  theme?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type CarsRichTextStyle = "BOLD" | "BULLET" | "ITALICIZE";

export type CarsSearchResultsWrapper = {
  __typename?: "CarsSearchResultsWrapper";
  carRecommendationContent?: Maybe<Array<CarRecommendations>>;
  carSearchResults?: Maybe<CarSearchResults>;
  carsErrorContent?: Maybe<CarsErrorContent>;
};

export type CarsShoppingContext = {
  __typename?: "CarsShoppingContext";
  searchId?: Maybe<Scalars["String"]>;
};

export type Cart = {
  __typename?: "Cart";
  id: Scalars["UUID"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  itemCount: Scalars["Int"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  items: Array<CartItem>;
};

export interface CartItem {
  id: Scalars["UUID"];
}

export type CartMutations = {
  __typename?: "CartMutations";
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  addProperty: CartSummary;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  emptyCart: CartSummary;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  remove: CartSummary;
};

export type CartMutationsAddPropertyArgs = {
  key: PropertyOfferInput;
  searchContext: CartPropertySearchContextInput;
};

export type CartMutationsRemoveArgs = {
  id: Scalars["UUID"];
};

export interface CartOptionsInput {
  ttl: CartTtl;
}

export type CartPropertySearchContext = {
  __typename?: "CartPropertySearchContext";
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  checkIn: Date;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  checkOut: Date;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  regionId: Scalars["String"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  rooms: Array<PropertyRoom>;
};

export interface CartPropertySearchContextInput {
  checkIn: DateInput;
  checkOut: DateInput;
  regionId: Scalars["String"];
  rooms: Array<PropertyRoomInput>;
}

export type CartSummary = {
  __typename?: "CartSummary";
  id: Scalars["UUID"];
  itemCount: Scalars["Int"];
};

export type CartTtl = "FIVE_MINUTES" | "SEVEN_DAYS" | "TWELVE_HOURS";

export type CategoryFilterSummary = {
  __typename?: "CategoryFilterSummary";
  details: ActivityCategoryDetails;
  size: Scalars["Int"];
  subCategories?: Maybe<Array<ActivityCategoryDetails>>;
};

export type CategoryFilterViewModel = {
  __typename?: "CategoryFilterViewModel";
  displayOrder: Scalars["Int"];
  name: Scalars["String"];
  summary?: Maybe<Array<CategoryFilterSummary>>;
  /** @deprecated should use name, i.e. localized value */
  type: ActivityCategoryType;
};

export type CategorySelectionComponent = {
  __typename?: "CategorySelectionComponent";
  carousel: EgdsCarousel;
  seeAllLink?: Maybe<SeeAllCategoriesLink>;
};

export type ChangeFlightLabelPresentation = {
  __typename?: "ChangeFlightLabelPresentation";
  changeFlightLabel: FlightsActionableLabel;
  departureDate: FlightsPhrase;
};

export type ChangeOfferDetails = {
  __typename?: "ChangeOfferDetails";
  cancellationChange?: Maybe<RatePlanMessage>;
  categorizedListings?: Maybe<Array<LodgingCategorizedUnit>>;
  changeYourOptionsLink: EgdsStandardLink;
  errorRedirectUrlPath?: Maybe<Scalars["String"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  microRoomCardSection?: Maybe<LodgingMicroCardSection>;
  playback: LodgingChangePlayback;
  primaryCallToAction?: Maybe<LodgingForm>;
  propertyId?: Maybe<Scalars["String"]>;
  subtitle: Scalars["String"];
  title: Scalars["String"];
  totalDifference?: Maybe<PricePresentationSection>;
};

export type ChangePackageConfirmationDialog = {
  __typename?: "ChangePackageConfirmationDialog";
  content: MishopUiChangePackageConfirmationDialogContent;
  dialog: EgdsActionDialog;
  trigger: EgdsButton;
};

export type ChangePackageSearchCriteria = {
  __typename?: "ChangePackageSearchCriteria";
  dateRange: DateRange;
  multiItemSessionId: Scalars["String"];
  origin: PackageLocation;
  travelerGroups: Array<TravelerOccupancyGroup>;
};

export type ChangeRentalLocation = {
  __typename?: "ChangeRentalLocation";
  link: CarPhrase;
  placeHolder: CarPhrase;
  primary: CarPhrase;
  secondary: CarPhrase;
};

export type ChannelType = "VIRTUAL_AGENT";

export type ChatBotIntentType = "CANCEL" | "CHANGE" | "VIEW_BOOKING";

export type ChatBotProductType = "FLIGHT";

export type ChatBotWaiverRedemptionState = "CREDIT_REDEMPTION";

export type ChatCapabilities = {
  __typename?: "ChatCapabilities";
  enableHistory?: Maybe<Scalars["Boolean"]>;
};

export type ChatInput = {
  __typename?: "ChatInput";
  chatInputStatus?: Maybe<ChatInputStatus>;
  interactionMode?: Maybe<InteractionMode>;
};

export type ChatInputStatus = "DISABLE" | "ENABLE";

export type ChatbotAuthentication = {
  __typename?: "ChatbotAuthentication";
  code?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type ChatbotCapabilities = "CLICKSTREAM" | "GLASSBOX";

export type ChatbotConfig = {
  __typename?: "ChatbotConfig";
  auth: ChatbotAuthentication;
  /** @deprecated authToken has been replaced by auth.token */
  authToken: Scalars["String"];
  channelOriginId: Scalars["String"];
  chatbotCapabilities?: Maybe<Array<ChatbotCapabilities>>;
  conversationProperties: ConversationProperties;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]>;
  userProperties?: Maybe<UserProperties>;
  version: ChatbotVersion;
};

export interface ChatbotConversationIntentMessage {
  intent: ChatBotIntentType;
}

export type ChatbotConversationRedeemIntentMessage = ChatbotConversationIntentMessage & {
  __typename?: "ChatbotConversationRedeemIntentMessage";
  emailAddress: Scalars["String"];
  intent: ChatBotIntentType;
  itineraryNumber: Scalars["String"];
  product: ChatBotProductType;
  waiverRedemptionState?: Maybe<ChatBotWaiverRedemptionState>;
};

export interface ChatbotDeviceIdentifiersInput {
  deviceToken?: InputMaybe<Scalars["String"]>;
  sailthruDeviceID?: InputMaybe<Scalars["String"]>;
}

export interface ChatbotIntentValueInput {
  id: Scalars["String"];
  value: Scalars["String"];
}

export type ChatbotVersion = {
  __typename?: "ChatbotVersion";
  javascriptUrl: HttpUri;
  versionId: ChatbotVersionId;
  wrapperUrl?: Maybe<HttpUri>;
};

export type ChatbotVersionId = "V1" | "V2";

export type CheckinValidityWithReason =
  | "INVALID"
  | "INVALID_DUE_TO_BEING_IN_PAST"
  | "INVALID_DUE_TO_CHANGEOVER_EXCLUSION"
  | "INVALID_DUE_TO_MAX_FUTURE_BOOKING"
  | "INVALID_DUE_TO_MIN_PRIOR_NOTIFICATION"
  | "INVALID_DUE_TO_MIN_STAY_NOT_ACHIEVABLE"
  | "INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK"
  | "INVALID_DUE_TO_NOT_AVAILABLE"
  | "INVALID_DUE_TO_NO_VALID_CHECKOUT_WITHIN_CONSTRAINTS"
  | "VALID";

export type CheckoutAccountCreationResponse = {
  __typename?: "CheckoutAccountCreationResponse";
  analytics: Array<EgClickstreamEvent>;
  createAccount: SmartFormSignalAction;
  heading: Scalars["String"];
  passwordWithStrengthMeter: PasswordWithStrengthMeter;
  subHeading: Scalars["String"];
};

export type CheckoutDetails = {
  __typename?: "CheckoutDetails";
  bookButton: UiPrimaryButton;
  bookSubmittingDialog: BookSubmittingDialog;
  checkoutIdleCallToActionDialog: CheckoutIdleCallToActionDialog;
  checkoutInactivityDialog: CheckoutInactivityDialog;
  fraudComponentDetails: FraudComponentDetails;
  getSession: CheckoutSessionDetailsResponse;
  pageTitle: Scalars["String"];
};

export type CheckoutDetailsGetSessionArgs = {
  orderId?: InputMaybe<Scalars["String"]>;
  sessionID: Scalars["UUID"];
};

export type CheckoutDomainInfo = {
  __typename?: "CheckoutDomainInfo";
  name: Scalars["String"];
  refId: Scalars["String"];
};

export interface CheckoutDomainInfoInput {
  name: Scalars["String"];
  refId: Scalars["String"];
}

export type CheckoutFailureReason = FailureReason & {
  __typename?: "CheckoutFailureReason";
  description: Scalars["String"];
  failureDialog: MishopUiNonFullscreenDialog;
  message: Scalars["String"];
};

export type CheckoutIdleCallToActionDialog = {
  __typename?: "CheckoutIdleCallToActionDialog";
  heading: Scalars["String"];
  idleDelay: IdleDelay;
  primaryButton: UiPrimaryButton;
  primaryContent: EgdsPlainText;
};

export type CheckoutInactivityDialog = {
  __typename?: "CheckoutInactivityDialog";
  heading: Scalars["String"];
  primaryButton: UiPrimaryButton;
  primaryContent: EgdsPlainText;
};

export type CheckoutMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "CheckoutMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type CheckoutOption = {
  __typename?: "CheckoutOption";
  type: CheckoutOptionType;
  value: Scalars["String"];
};

export interface CheckoutOptionInput {
  type: CheckoutOptionType;
  value: Scalars["String"];
}

export type CheckoutOptionType =
  | "DESTINATION_ID"
  | "FENCING_ATTRIBUTES"
  | "HOTEL_BUSINESS_MODEL"
  | "HOTEL_MARKETING_CHANNEL"
  | "META_DEEPLINK"
  | "NATIVE"
  | "PRICE_TOKEN"
  | "PROPERTY_UPSELL_AMOUNT"
  | "RATE_UPGRADE_ELIGIBLE"
  | "RATE_UPSELL"
  | "RATE_UPSELL_AMOUNT"
  | "ROOM_UPGRADE_ELIGIBLE"
  | "ROOM_UPSELL"
  | "SEARCH_ID"
  | "SHOPPING_DEAL_BADGE_PERCENTAGE"
  | "SHOP_WITH_POINTS"
  | "TAAP_PACKAGE_RATES";

export type CheckoutOptions = {
  __typename?: "CheckoutOptions";
  type: Scalars["String"];
  value: Scalars["String"];
};

export interface CheckoutRedirectInput {
  cabinNumber: Scalars["String"];
  deck: Scalars["String"];
  offerToken?: InputMaybe<Scalars["String"]>;
}

export type CheckoutResource = {
  __typename?: "CheckoutResource";
  participant: Scalars["String"];
  resourceIdentifier: ResourceIdentifier;
};

export type CheckoutSessionDetailsResponse = {
  __typename?: "CheckoutSessionDetailsResponse";
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  resources?: Maybe<Array<CheckoutResource>>;
  signals: Array<CheckoutUiSignal>;
  token?: Maybe<Scalars["UUID"]>;
};

export interface CheckoutSessionInfoInput {
  sessionId: Scalars["UUID"];
  sessionToken: Scalars["UUID"];
}

export type CheckoutUiSignal = {
  __typename?: "CheckoutUISignal";
  domainInfoList?: Maybe<Array<CheckoutDomainInfo>>;
  signal: UiSignal;
};

export type CheckoutValidityWithReason =
  | "INVALID"
  | "INVALID_DUE_TO_CHANGEOVER_EXCLUSION"
  | "INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK"
  | "VALID";

export type Child = {
  __typename?: "Child";
  age: Scalars["Int"];
};

export interface ChildInput {
  age: Scalars["Int"];
}

export type ChronoUnit =
  | "CENTURIES"
  | "DAYS"
  | "DECADES"
  | "ERAS"
  | "FOREVER"
  | "HALFDAYS"
  | "HOURS"
  | "MICROS"
  | "MILLENNIA"
  | "MILLIS"
  | "MINUTES"
  | "MONTHS"
  | "NANOS"
  | "SECONDS"
  | "WEEKS"
  | "YEARS";

export type CleaningAndSafetyPractice = "ENHANCED_CLEANING";

export type CleanlinessDialog = {
  __typename?: "CleanlinessDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingDialogTriggerMessage;
};

export type ClickToClaimToast = {
  __typename?: "ClickToClaimToast";
  toast: EgdsToast;
};

export type ClickstreamAnalytics = {
  __typename?: "ClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: EgClickstreamPayload;
};

export type ClickstreamAnalyticsData = {
  __typename?: "ClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]>;
  eventVersion?: Maybe<Scalars["String"]>;
  payload: Scalars["AnalyticsPayload"];
};

export type ClickstreamError = {
  __typename?: "ClickstreamError";
  code?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type ClientAccessibility = {
  __typename?: "ClientAccessibility";
  text: Scalars["String"];
};

export type ClientInfo = {
  __typename?: "ClientInfo";
  name: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
};

export interface ClientInfoInput {
  name: Scalars["String"];
  version?: InputMaybe<Scalars["String"]>;
}

export type ClientRequestContext = {
  __typename?: "ClientRequestContext";
  context: Context;
  deviceContext: DeviceContext;
  localeInfo: LocaleInfo;
  simpleContext: Context;
  siteContext: SiteContext;
};

export interface ClientRequestInput {
  headers: Array<HttpHeaderInput>;
  remoteAddress: Scalars["String"];
  url: Scalars["String"];
}

export type ClientSideAnalytics = {
  __typename?: "ClientSideAnalytics";
  eventType?: Maybe<EgReferrerEventType>;
  linkName: Scalars["String"];
  referrerId: Scalars["String"];
  uisPrimeMessages: Array<EgUisMicroMessage>;
  urls?: Maybe<Array<Scalars["URL"]>>;
};

export interface ClientSideErrorRepresentation {
  /** @deprecated This field is deprecated and replaced with clientSideErrorRepresentationDialog */
  clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
  clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
}

export type ClientSideErrorRepresentationDialog = EgdsDialog & {
  __typename?: "ClientSideErrorRepresentationDialog";
  accessibility?: Maybe<Scalars["String"]>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  content: Scalars["String"];
  dialogFooter: ClientSideErrorRepresentationDialogFooter;
  displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  heading: Scalars["String"];
  paragraph: Scalars["String"];
};

export type ClientSideErrorRepresentationDialogFooter = {
  __typename?: "ClientSideErrorRepresentationDialogFooter";
  buttons: Array<ClientSideErrorRepresentationDialogFooterButton>;
};

export type ClientSideErrorRepresentationDialogFooterButton = {
  __typename?: "ClientSideErrorRepresentationDialogFooterButton";
  accessibility?: Maybe<Scalars["String"]>;
  action: ClientSideErrorRepresentationDialogFooterButtonAction;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
};

export type ClientSideErrorRepresentationDialogFooterButtonAction = {
  __typename?: "ClientSideErrorRepresentationDialogFooterButtonAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionType: DialogButtonActionType;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type ClientSideIllustrationAsset = "FlightHotelIncludedSet";

export type ClientSideImpressionAnalytics = {
  __typename?: "ClientSideImpressionAnalytics";
  clickstreamAnalytics?: Maybe<ClickstreamAnalytics>;
  /** @deprecated error has been deprecated */
  error?: Maybe<BooleanEnum>;
  /** @deprecated name has been deprecated, replace with uisPrimeAnalytics.eventType */
  name?: Maybe<EventType>;
  uisPrimeAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated value has been deprecated, replace with uisPrimeAnalytics.referrerId */
  value?: Maybe<Scalars["Float"]>;
};

export type ClientSideImpressionEventAnalytics = {
  __typename?: "ClientSideImpressionEventAnalytics";
  event: Scalars["String"];
  linkName?: Maybe<Scalars["String"]>;
  referrerId: Scalars["String"];
  uisPrimeMessages: Array<EgUisMicroMessage>;
  /** @deprecated Please use the URLs list field */
  url?: Maybe<Scalars["URL"]>;
  urls?: Maybe<Array<Scalars["URL"]>>;
};

export type CloseType = EgdsSheet;

export type ClosedPeriodBounding = "LOWER" | "UPPER";

export type ClubbedPrice = {
  __typename?: "ClubbedPrice";
  additionalBenefits?: Maybe<Array<CarPhraseIconText>>;
  additionalPaymentMessages?: Maybe<Array<CarPhrase>>;
  cancellationMessages?: Maybe<Array<CarPhrase>>;
  heading?: Maybe<CarPhrase>;
  infositeURL: HttpUri;
  loyaltyEarnMessages?: Maybe<Array<CarPhrase>>;
  offerBadges: Array<CarOfferBadge>;
  paymentMessages: Array<CarPhrase>;
  priceBadges: Array<CarOfferBadge>;
  priceSummary: CarPriceSummary;
  reserveButtonAction: CarAction;
  reserveButtonText: Scalars["String"];
  title: CarPhrase;
};

export type ClubbedPriceOptions = {
  __typename?: "ClubbedPriceOptions";
  dialogContent: ClubbedPriceOptionsDialog;
  dialogTrigger: CarActionableItem;
};

export type ClubbedPriceOptionsDialog = {
  __typename?: "ClubbedPriceOptionsDialog";
  clubbedPrices: Array<ClubbedPrice>;
  commonAdditionalBenefits?: Maybe<Array<CarPhraseIconText>>;
  modalFooter: Scalars["String"];
  optionsSummary: Array<CarPhrase>;
  title: Scalars["String"];
  vehicleCategory: Scalars["String"];
  vehicleDescription?: Maybe<Scalars["String"]>;
  vendor: CarVendor;
};

export type CoBrandBannerData = {
  __typename?: "CoBrandBannerData";
  endDate?: Maybe<Scalars["String"]>;
  logoData?: Maybe<CoBrandBannerLogoData>;
  startDate?: Maybe<Scalars["String"]>;
  /** @deprecated This property will not be supported in future. Please use styles. */
  style: CoBrandBannerStyle;
  styles: CoBrandBannerStyles;
  textData?: Maybe<CoBrandBannerTextData>;
  timezone?: Maybe<Timezone>;
};

export type CoBrandBannerLogoData = {
  __typename?: "CoBrandBannerLogoData";
  altText: Scalars["String"];
  desktop: CoBrandBannerLogoDetail;
  href?: Maybe<Scalars["URL"]>;
  logoContainerStyle: CoBrandBannerStyle;
  mobile: CoBrandBannerLogoDetail;
  tablet: CoBrandBannerLogoDetail;
};

export type CoBrandBannerLogoDetail = {
  __typename?: "CoBrandBannerLogoDetail";
  imgSrc: Scalars["URL"];
  style: Array<CoBrandBannerProperty>;
};

export type CoBrandBannerProperty = {
  __typename?: "CoBrandBannerProperty";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type CoBrandBannerStyle = {
  __typename?: "CoBrandBannerStyle";
  commonStyle?: Maybe<Array<CoBrandBannerProperty>>;
  desktop?: Maybe<Array<CoBrandBannerProperty>>;
  mobile?: Maybe<Array<CoBrandBannerProperty>>;
  tablet?: Maybe<Array<CoBrandBannerProperty>>;
};

export type CoBrandBannerStyles = {
  __typename?: "CoBrandBannerStyles";
  mainContainer: CoBrandBannerStyle;
  subContainer: CoBrandBannerStyle;
};

export type CoBrandBannerTextData = {
  __typename?: "CoBrandBannerTextData";
  textContainerStyle: CoBrandBannerStyle;
  textSections: Array<CoBrandBannerTextSection>;
};

export type CoBrandBannerTextSection = {
  __typename?: "CoBrandBannerTextSection";
  style?: Maybe<Array<CoBrandBannerProperty>>;
  textTokens: Array<CoBrandBannerTextToken>;
};

export type CoBrandBannerTextToken = {
  __typename?: "CoBrandBannerTextToken";
  href?: Maybe<Scalars["URL"]>;
  style?: Maybe<Array<CoBrandBannerProperty>>;
  text: Scalars["String"];
};

export type CoachingTipsView =
  | "IN_CONTEXT_COACHING_CAROUSEL"
  | "OPPY_HUB"
  | "STANDALONE_MINIMAL"
  | "STANDALONE_SUGGESTED";

export type CollectionCarouselHeroTile = {
  __typename?: "CollectionCarouselHeroTile";
  carouselCTAButton: IncentivesActionLink;
  content: IncentivesTileContent;
  nextSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  offers: Array<IncentivesOfferTile>;
  previousSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionCarouselHeroTilesBody = {
  __typename?: "CollectionCarouselHeroTilesBody";
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<CollectionCarouselHeroTile>;
};

export type CollectionCarouselMediaTilesBody = {
  __typename?: "CollectionCarouselMediaTilesBody";
  carouselCTAButton?: Maybe<IncentivesActionLink>;
  nextSelectedAnalytics: CollectionClickstreamAnalytics;
  previousSelectedAnalytics: CollectionClickstreamAnalytics;
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<IncentivesMediaTile>;
};

export type CollectionClickstreamAnalytics = {
  __typename?: "CollectionClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: CollectionClickstreamPayload;
};

export type CollectionClickstreamPayload = {
  __typename?: "CollectionClickstreamPayload";
  collectionId: Scalars["String"];
  collectionType: CollectionType;
  componentPosition?: Maybe<Scalars["Int"]>;
  destinationGeoId?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["String"]>;
  trackingId?: Maybe<Scalars["String"]>;
};

export type CollectionHeroImage = {
  __typename?: "CollectionHeroImage";
  image: Image;
};

export type CollectionItemClickthrough = "PDP" | "PINNED_SRP";

export type CollectionPaginationAction = {
  __typename?: "CollectionPaginationAction";
  button: EgdsButton;
  paginationQuery: CollectionPaginationQuery;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionPaginationContext = {
  __typename?: "CollectionPaginationContext";
  maxPageSize?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export interface CollectionPaginationContextInput {
  maxPageSize?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
}

export type CollectionPaginationQuery = {
  __typename?: "CollectionPaginationQuery";
  paginationContext: CollectionPaginationContext;
};

export type CollectionType = "FLIGHT" | "GEOGRAPHY" | "LODGING";

export type CommissionTier = "BASIC" | "BASIC_PLUS" | "PREMIUM" | "PREMIUM_PLUS";

export type CommunicationCenter = HeaderSharedUi & {
  __typename?: "CommunicationCenter";
  skipSsr: Scalars["Boolean"];
};

export type CommunicationCenterActionCard = {
  __typename?: "CommunicationCenterActionCard";
  body?: Maybe<Array<EgdsStylizedText>>;
  heading?: Maybe<EgdsStylizedText>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
};

export type CommunicationCenterBanner = {
  __typename?: "CommunicationCenterBanner";
  action?: Maybe<CommunicationCenterButton>;
  body?: Maybe<Scalars["String"]>;
  icon: Icon;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  theme: Scalars["String"];
  title: Scalars["String"];
};

export type CommunicationCenterButton = {
  __typename?: "CommunicationCenterButton";
  action?: Maybe<UiLinkAction>;
  button?: Maybe<EgdsButton>;
};

export interface CommunicationCenterCard {
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"];
  title?: Maybe<EgdsStylizedText>;
}

export type CommunicationCenterConversationCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterConversationCard";
  author?: Maybe<Scalars["String"]>;
  authorChannel?: Maybe<Scalars["String"]>;
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"];
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  defaultPreviewMessage?: Maybe<EgdsStylizedText>;
  entityDetail?: Maybe<CommunicationCenterConversationEntity>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  location?: Maybe<EgdsStylizedText>;
  participantId?: Maybe<Scalars["String"]>;
  sentDateTime?: Maybe<Scalars["String"]>;
  state?: Maybe<CommunicationCenterConversationState>;
  timeStamp?: Maybe<EgdsStylizedText>;
  title?: Maybe<EgdsStylizedText>;
};

export type CommunicationCenterConversationEntity = {
  __typename?: "CommunicationCenterConversationEntity";
  banner?: Maybe<CommunicationCenterBanner>;
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  entityName?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  navigationIcon?: Maybe<Icon>;
  status?: Maybe<EgdsStandardBadge>;
  tripDate?: Maybe<EgdsStylizedText>;
  viewDetailButton?: Maybe<CommunicationCenterButton>;
};

export type CommunicationCenterConversationMutationResponse = {
  __typename?: "CommunicationCenterConversationMutationResponse";
  conversationId: Scalars["String"];
  operationStatus: CommunicationCenterOperationStatus;
  state: CommunicationCenterConversationState;
};

export type CommunicationCenterConversationState = "READ" | "UNKNOWN" | "UNREAD";

export type CommunicationCenterConversationView = {
  __typename?: "CommunicationCenterConversationView";
  banner?: Maybe<CommunicationCenterBanner>;
  conversation: Array<Message>;
  conversationMetadata: ConversationMetadata;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  messageInput: MessageInput;
};

export type CommunicationCenterConversations = {
  __typename?: "CommunicationCenterConversations";
  banner?: Maybe<CommunicationCenterBanner>;
  clickToActionButton?: Maybe<CommunicationCenterButton>;
  conversations?: Maybe<Array<CommunicationCenterConversationCard>>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  unread?: Maybe<Scalars["Int"]>;
};

export type CommunicationCenterEmptyState = {
  __typename?: "CommunicationCenterEmptyState";
  /** @deprecated replace with actions */
  actionButton?: Maybe<Array<EgdsButton>>;
  actions?: Maybe<Array<CommunicationCenterButton>>;
  body: Array<EgdsText>;
  emptyStateType?: Maybe<EmptyStateType>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  title: EgdsStylizedText;
};

export type CommunicationCenterEntryPoint = {
  __typename?: "CommunicationCenterEntryPoint";
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  defaultTab?: Maybe<Scalars["String"]>;
  icon: Icon;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  label?: Maybe<Scalars["String"]>;
  messageUnseenCount?: Maybe<Scalars["Int"]>;
  notificationUnseenCount?: Maybe<Scalars["Int"]>;
};

export type CommunicationCenterFormMessage = {
  __typename?: "CommunicationCenterFormMessage";
  displayType: CommunicationCenterNotificationMessageDisplayType;
  level: CommunicationCenterNotificationMessageLevel;
  name: Scalars["String"];
  title: EgdsStylizedText;
};

export type CommunicationCenterFormSection = {
  __typename?: "CommunicationCenterFormSection";
  fields: Array<CommunicationCenterButton>;
  title: EgdsStylizedText;
};

export type CommunicationCenterMessageAlignment = "FULL" | "LEFT" | "RIGHT";

export type CommunicationCenterMessageMutationResponse = {
  __typename?: "CommunicationCenterMessageMutationResponse";
  clickAnalytics: ClientSideAnalytics;
  messageId: Scalars["String"];
  operationStatus: CommunicationCenterOperationStatus;
};

export type CommunicationCenterMessagePreviewCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterMessagePreviewCard";
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"];
  image: Image;
  label?: Maybe<EgdsStylizedText>;
  state: CommunicationCenterMessagePreviewState;
  timeStamp?: Maybe<EgdsStylizedText>;
  title?: Maybe<EgdsStylizedText>;
};

export type CommunicationCenterMessagePreviewState = "READ" | "UNREAD";

export type CommunicationCenterMessagePreviews = {
  __typename?: "CommunicationCenterMessagePreviews";
  banner?: Maybe<CommunicationCenterBanner>;
  cards?: Maybe<Array<CommunicationCenterMessagePreviewCard>>;
  defaultMessageId?: Maybe<Scalars["String"]>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  pagination?: Maybe<PaginationDetails>;
};

export type CommunicationCenterMessageProductDetail = {
  __typename?: "CommunicationCenterMessageProductDetail";
  banner?: Maybe<CommunicationCenterBanner>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  entityName?: Maybe<EgdsStylizedText>;
  entityOwner?: Maybe<EgdsSpannableText>;
  heading?: Maybe<EgdsStylizedText>;
  messageId: Scalars["String"];
  navigationIcon?: Maybe<Icon>;
  status?: Maybe<EgdsStandardBadge>;
  viewDetailButton?: Maybe<CommunicationCenterButton>;
};

export type CommunicationCenterMutationResponse = {
  __typename?: "CommunicationCenterMutationResponse";
  messageId: Scalars["String"];
  updatedState: CommunicationCenterMessagePreviewState;
};

export type CommunicationCenterNotificationCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterNotificationCard";
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"];
  icon: Icon;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  links?: Maybe<Array<EgdsStandardLink>>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  state: CommunicationCenterNotificationState;
  timeStamp?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  type: CommunicationCenterNotificationType;
  userActions: Array<CommunicationCenterNotificationUserAction>;
};

export type CommunicationCenterNotificationForm = {
  __typename?: "CommunicationCenterNotificationForm";
  feedbackMessages?: Maybe<Array<CommunicationCenterFormMessage>>;
  sections: Array<CommunicationCenterFormSection>;
  title: EgdsStylizedText;
};

export type CommunicationCenterNotificationMessageDisplayType = "FORM" | "PAGE";

export type CommunicationCenterNotificationMessageLevel = "ERROR" | "INFO" | "SUCCESS" | "WARNING";

export type CommunicationCenterNotificationMutationResponse = {
  __typename?: "CommunicationCenterNotificationMutationResponse";
  notificationId: Scalars["String"];
  operationStatus: CommunicationCenterOperationStatus;
};

export type CommunicationCenterNotificationState = "READ" | "SEEN" | "UNKNOWN" | "UNSEEN";

export type CommunicationCenterNotificationType = "COUPON_CAMPAIGN" | "DEFAULT";

export type CommunicationCenterNotificationUserAction = {
  __typename?: "CommunicationCenterNotificationUserAction";
  icon?: Maybe<Icon>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  secondaryText?: Maybe<EgdsStylizedText>;
  text: EgdsStylizedText;
  type: CommunicationCenterNotificationUserActionType;
};

export type CommunicationCenterNotificationUserActionType = "DELETE" | "MARK_AS_READ" | "MARK_AS_UNREAD";

export type CommunicationCenterNotifications = {
  __typename?: "CommunicationCenterNotifications";
  actionCards?: Maybe<Array<CommunicationCenterActionCard>>;
  banner?: Maybe<CommunicationCenterBanner>;
  clickToActionButton?: Maybe<CommunicationCenterButton>;
  deleteActionForm?: Maybe<CommunicationCenterNotificationForm>;
  emptyState: CommunicationCenterEmptyState;
  heading: EgdsStylizedText;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  notifications?: Maybe<Array<CommunicationCenterNotificationCard>>;
  /** @deprecated replace with empty state actions */
  redirectPath?: Maybe<Scalars["String"]>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  /** @deprecated replace with clickToActionButton */
  showAllButton?: Maybe<EgdsButton>;
  unseen: Scalars["Int"];
};

export type CommunicationCenterOperationStatus = "GENERIC_FAILURE" | "INVALID_INPUT" | "SERVICE_ERROR" | "SUCCESS";

export type CommunicationCenterPreview = {
  __typename?: "CommunicationCenterPreview";
  conversations?: Maybe<CommunicationCenterConversations>;
  label?: Maybe<EgdsStylizedText>;
  messages?: Maybe<CommunicationCenterMessagePreviews>;
  notifications?: Maybe<CommunicationCenterNotifications>;
  /** @deprecated replace with empty state CTA */
  redirectPath?: Maybe<Scalars["String"]>;
  tabLabels?: Maybe<Array<CommunicationCenterTab>>;
};

export type CommunicationCenterQuery = {
  __typename?: "CommunicationCenterQuery";
  communicationCenterConversationView: CommunicationCenterConversationView;
  communicationCenterConversations: CommunicationCenterConversations;
  communicationCenterMessagePreviews: CommunicationCenterMessagePreviews;
  communicationCenterMessageProductDetail: CommunicationCenterMessageProductDetail;
  communicationCenterNotifications: CommunicationCenterNotifications;
};

export type CommunicationCenterQueryCommunicationCenterConversationViewArgs = {
  conversationId: Scalars["String"];
};

export type CommunicationCenterQueryCommunicationCenterConversationsArgs = {
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type CommunicationCenterQueryCommunicationCenterMessagePreviewsArgs = {
  pagination: PaginationDetailsInput;
};

export type CommunicationCenterQueryCommunicationCenterMessageProductDetailArgs = {
  messageId: Scalars["String"];
};

export type CommunicationCenterQueryCommunicationCenterNotificationsArgs = {
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type CommunicationCenterTab = {
  __typename?: "CommunicationCenterTab";
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  label: EgdsStylizedText;
  status: TabStatus;
  unseen: Scalars["Int"];
};

export type CommunicationChannel = "EMAIL" | "PUSH" | "SMS";

export type CommunicationPreferencesAction = UniversalProfileAction & {
  __typename?: "CommunicationPreferencesAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"];
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type CommunicationPreferencesBrandComponent = {
  __typename?: "CommunicationPreferencesBrandComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  bookings?: Maybe<CommunicationPreferencesOptionsComponent>;
  commPrefsPrivacyStatement?: Maybe<UniversalProfileStandardLink>;
  manageEmailPreferences?: Maybe<UniversalProfileStandardLink>;
  pushNotification?: Maybe<CommunicationPreferencesPushNotificationComponent>;
  shoppings?: Maybe<CommunicationPreferencesOptionsComponent>;
  title: Scalars["String"];
};

export type CommunicationPreferencesChannelSelectionForm = {
  __typename?: "CommunicationPreferencesChannelSelectionForm";
  channelOptions: Array<UniversalProfileCommunicationPreferencesCheckBoxComponent>;
  contentHeading?: Maybe<UniversalProfileContentHeading>;
  heading: UniversalProfileHeading;
};

export type CommunicationPreferencesForm = {
  __typename?: "CommunicationPreferencesForm";
  categoryChannels: Array<CommunicationPreferencesChannelSelectionForm>;
  formSubmitConfirmationDialog?: Maybe<UniversalProfileFormSubmitConfirmationDialog>;
  subExperienceType?: Maybe<UniversalProfileSubExperienceType>;
  title: Scalars["String"];
  updateButton: UniversalProfileButton;
};

export type CommunicationPreferencesMainComponent = {
  __typename?: "CommunicationPreferencesMainComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  communicationBrandPreferences?: Maybe<CommunicationPreferencesOptionsComponent>;
  sharedGlobalPreferences?: Maybe<CommunicationPreferencesOptionsComponent>;
  title: Scalars["String"];
};

export type CommunicationPreferencesMessagingCard = {
  __typename?: "CommunicationPreferencesMessagingCard";
  action?: Maybe<CommunicationPreferencesAction>;
  card: EgdsStandardMessagingCard;
  commPrefsBrandExperienceType?: Maybe<UniversalProfileSubExperienceType>;
  graphicIllustration?: Maybe<Illustration>;
  type?: Maybe<CommunicationPreferencesOptionsType>;
};

export type CommunicationPreferencesOptionsComponent = {
  __typename?: "CommunicationPreferencesOptionsComponent";
  heading?: Maybe<UniversalProfileHeading>;
  options: Array<CommunicationPreferencesMessagingCard>;
};

export type CommunicationPreferencesOptionsType =
  | "ACCOUNT_SUPPORT"
  | "COMMUNICATION_PREFERENCES_EXPEDIA"
  | "COMMUNICATION_PREFERENCES_HOTELS"
  | "COMMUNICATION_PREFERENCES_VRBO"
  | "COMMUNICATION_PREFERENCES_WOTIF"
  | "CONFIRMATION_AND_CRITICAL_UPDATES"
  | "DEFAULT"
  | "MANAGE_EMAIL_PREFERENCE"
  | "OFFERS_AND_INSPIRATION"
  | "ONE_KEY"
  | "ONE_KEY_MONTHLY_STATEMENTS"
  | "PARTNERS_OFFERS_WITH_US"
  | "PERSONALIZED_TRIP_RECOMMENDATIONS"
  | "PRIVACY_STATEMENT"
  | "REVIEWS"
  | "SURVEYS"
  | "THE_TRAVEL_EDIT"
  | "TRIP_BOARD_ACTIVITY"
  | "TRIP_PLANNING_ASSISTANCE"
  | "UNSUB_ALL"
  | "UNSUB_OTHER_EMAILS";

export type CommunicationPreferencesPushNotificationComponent = {
  __typename?: "CommunicationPreferencesPushNotificationComponent";
  heading: UniversalProfileHeading;
};

export type ComparableDealsErrorContent = {
  __typename?: "ComparableDealsErrorContent";
  analytics: CarAnalytics;
  text: Scalars["String"];
};

export type ComparableDealsResults = {
  __typename?: "ComparableDealsResults";
  carAppliedSortAndFilters: CarAppliedSortAndFilters;
  comparableDealsDisplayAnalytics?: Maybe<CarAnalytics>;
  comparableDealsHeading: Scalars["String"];
  criteriaLevel: Scalars["Int"];
  listings: Array<CarListingCard>;
};

export type ComparableDealsResultsWrapper = {
  __typename?: "ComparableDealsResultsWrapper";
  comparableDealsErrorContent?: Maybe<ComparableDealsErrorContent>;
  comparableDealsResults?: Maybe<ComparableDealsResults>;
};

export type ComponentConfig = {
  __typename?: "ComponentConfig";
  exposures?: Maybe<Array<ExperimentType>>;
  required?: Maybe<Scalars["Boolean"]>;
  segments?: Maybe<Array<Scalars["String"]>>;
};

export type ComponentMetaData = {
  __typename?: "ComponentMetaData";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Scalars["String"]>;
};

export type CompositeLocalizedTextModel = {
  __typename?: "CompositeLocalizedTextModel";
  key: Scalars["String"];
  /** @deprecated Use models instead */
  localizedFragment: EgdsLocalizedText;
  models: EgdsTemplateModels;
};

export type CompressionLevel = "CAP" | "HIGH" | "LOW" | "MEDIUM" | "NA";

export type ConfigTag = "H1" | "H2" | "H3" | "H4" | "H5" | "H6";

export type ConfirmationDialog = {
  __typename?: "ConfirmationDialog";
  ariaLabelledText?: Maybe<AriaLabelledText>;
  footer?: Maybe<EgdsDialogFooter>;
  /** @deprecated Use ariaLabelledText instead. */
  message?: Maybe<EgdsText>;
};

export interface ConfirmationDialogInput {
  elementName?: InputMaybe<Scalars["String"]>;
  elementType: DialogElementType;
  numberOfElements?: InputMaybe<Scalars["Int"]>;
}

export type ConfirmationSummary = {
  __typename?: "ConfirmationSummary";
  analyticsEvent?: Maybe<EgClickstreamEvent>;
  assuranceHeading: Scalars["String"];
  confirmationSummarySections: Array<ConfirmationSummarySection>;
  continueInBrowserLink?: Maybe<EgdsStandardLink>;
  image: Image;
  redirectToTripButton: EgdsButton;
  secondaryCTALink?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type ConfirmationSummarySection = {
  __typename?: "ConfirmationSummarySection";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  confirmationSummarySectionDescription: Scalars["String"];
  confirmationSummarySectionValue: Scalars["String"];
};

export type ConnectivitySettingsButton = {
  __typename?: "ConnectivitySettingsButton";
  button?: Maybe<EgdsButton>;
  buttonType?: Maybe<ConnectivitySettingsButtonType>;
};

export type ConnectivitySettingsButtonType =
  | "BACK"
  | "CANCEL"
  | "CHANGE"
  | "CONFIRM"
  | "CONNECT"
  | "DISCONNECT"
  | "NEXT";

export type ConnectivitySettingsGettingStarted = {
  __typename?: "ConnectivitySettingsGettingStarted";
  addButton?: Maybe<EgdsButton>;
  roomAndRatesPlanMapping?: Maybe<EgdsStandardLink>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ConnectivitySettingsHeader = {
  __typename?: "ConnectivitySettingsHeader";
  feedback?: Maybe<EgdsStandardLink>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ConnectivitySettingsMain = ConnectivitySettingsGettingStarted;

export type ConnectivitySettingsStep = {
  __typename?: "ConnectivitySettingsStep";
  label: Scalars["String"];
};

export type ConnectivitySettingsStepIndicator = {
  __typename?: "ConnectivitySettingsStepIndicator";
  activeStep: Scalars["Int"];
  steps: Array<ConnectivitySettingsStep>;
};

export type ConnectivitySettingsTypeahead = {
  __typename?: "ConnectivitySettingsTypeahead";
  adapterConfig: SettingProviderTypeaheadEssAdapterConfig;
  label: Scalars["String"];
  placeholder?: Maybe<Scalars["String"]>;
};

export type ConsentMgmt = {
  __typename?: "ConsentMgmt";
  card: ConsentMgmtCard;
};

export type ConsentMgmtCard = {
  __typename?: "ConsentMgmtCard";
  button: UiPrimaryButton;
  editorial?: Maybe<EgdsTextSection>;
};

export type ContactHostAction = InquiryFormDialogAction | UiLinkAction;

export type ContactHostButton = {
  __typename?: "ContactHostButton";
  action?: Maybe<ContactHostAction>;
  button?: Maybe<ContactHostButtonType>;
};

export type ContactHostButtonType = UiSecondaryButton | UiTertiaryButton;

export type ContactHostSection = {
  __typename?: "ContactHostSection";
  cta?: Maybe<ContactHostButton>;
  header?: Maybe<Scalars["String"]>;
};

export type ContactOptionsSheet = {
  __typename?: "ContactOptionsSheet";
  heading: Scalars["String"];
  options: Array<ContactUsOption>;
  subheading: Scalars["String"];
  toolBar: ContactUsToolBar;
};

export type ContactUsEmailFormView = ContactUsEmailSheet | ContactUsErrorView;

export type ContactUsEmailSheet = {
  __typename?: "ContactUsEmailSheet";
  back: Scalars["String"];
  customerEmail: EgdsEmailInputField;
  customerName: EgdsTextInputField;
  details: EgdsTextAreaInputField;
  heading: Scalars["String"];
  send: UiPrimaryButton;
  subheading: Scalars["String"];
  toolBar: ContactUsToolBar;
};

export type ContactUsErrorView = {
  __typename?: "ContactUsErrorView";
  errorMessage?: Maybe<Scalars["String"]>;
  errorTitle?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  icon: Icon;
  subheading?: Maybe<Scalars["String"]>;
  toolBar: ContactUsToolBar;
};

export interface ContactUsFormContentInput {
  category: Scalars["String"];
  intent: Scalars["String"];
  itineraryNumber: Scalars["String"];
  phoneNumber: Scalars["String"];
}

export type ContactUsOption = {
  __typename?: "ContactUsOption";
  leftIcon: Icon;
  option: ContactUsOptionType;
  optionLabel: Scalars["String"];
  rightIcon: Icon;
  waitTime?: Maybe<Scalars["String"]>;
};

export type ContactUsOptionType =
  | "CALL_BACK_WITH_AGENT"
  | "CALL_TO_AGENT"
  | "CHAT_BACK_WITH_AGENT"
  | "CHAT_WITH_AGENT"
  | "SEND_EMAIL_AGENT";

export type ContactUsOptionsView = ContactOptionsSheet | ContactUsErrorView;

export type ContactUsPreFill = {
  __typename?: "ContactUsPreFill";
  category?: Maybe<Scalars["String"]>;
  product?: Maybe<Scalars["String"]>;
};

export type ContactUsSendEmailFailed = {
  __typename?: "ContactUsSendEmailFailed";
  button: UiPrimaryButton;
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export interface ContactUsSendEmailFormRequestInput {
  customerEmail: Scalars["String"];
  customerName: Scalars["String"];
  details: Scalars["String"];
  intent: Scalars["String"];
  product: Scalars["String"];
}

export type ContactUsSendEmailFormView = ContactUsSendEmailFailed | ContactUsSendEmailSuccess;

export interface ContactUsSendEmailRequestInput {
  customerEmail: Scalars["String"];
  customerName: Scalars["String"];
  message: Scalars["String"];
  subject: Scalars["String"];
}

export type ContactUsSendEmailSuccess = {
  __typename?: "ContactUsSendEmailSuccess";
  button: UiPrimaryButton;
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export type ContactUsSendEmailView = ContactUsSendEmailFailed | ContactUsSendEmailSuccess;

export type ContactUsSheet = {
  __typename?: "ContactUsSheet";
  customerFormContent: ContactUsSheetContent;
  heading: Scalars["String"];
  seeContactUsOptions: UiPrimaryButton;
  subheading: Scalars["String"];
  toolBar: ContactUsToolBar;
};

export type ContactUsSheetContent = {
  __typename?: "ContactUsSheetContent";
  categorySelect: EgdsBasicSelect;
  intentSelect: EgdsBasicSelect;
  itineraryNumber: EgdsTextInputField;
  phoneNumber: EgdsTextInputField;
};

export type ContactUsSheetView = ContactUsErrorView | ContactUsSheet;

export type ContactUsToolBar = {
  __typename?: "ContactUsToolBar";
  icon: Icon;
  navIconLabel?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ContactUsView = {
  __typename?: "ContactUsView";
  contactUsEmailFormView: ContactUsEmailFormView;
  contactUsOptionsView: ContactUsOptionsView;
  contactUsSheetView: ContactUsSheetView;
  contactUsSheetViewWithPayload: ContactUsSheetView;
  emailUsFormView: EmailUsFormView;
  speakNextAvailableAgentSheetView: SpeakNextAvailableAgentSheetView;
  speakNextAvailableAgentView: SpeakNextAvailableAgentView;
};

export type ContactUsViewContactUsEmailFormViewArgs = {
  optionType: ContactUsOptionType;
};

export type ContactUsViewContactUsOptionsViewArgs = {
  searchFormInput: ContactUsFormContentInput;
};

export type ContactUsViewContactUsSheetViewWithPayloadArgs = {
  articleIntent?: InputMaybe<Scalars["String"]>;
  articleProduct?: InputMaybe<Scalars["String"]>;
};

export type ContactUsViewEmailUsFormViewArgs = {
  optionType: ContactUsOptionType;
};

export type ContactUsViewSpeakNextAvailableAgentSheetViewArgs = {
  speakNextAvailableSheetRequest: SpeakNextAvailableAgentSheetRequestInput;
};

export type ContactUsViewSpeakNextAvailableAgentViewArgs = {
  optionType: ContactUsOptionType;
};

export type ContactUsViewMutation = {
  __typename?: "ContactUsViewMutation";
  sendContactUsEmail: ContactUsSendEmailFormView;
  sendEmail: ContactUsSendEmailView;
};

export type ContactUsViewMutationSendContactUsEmailArgs = {
  sendEmailRequest: ContactUsSendEmailFormRequestInput;
};

export type ContactUsViewMutationSendEmailArgs = {
  sendEmailRequest: ContactUsSendEmailRequestInput;
};

export type ContainerTheme = "DEFAULT" | "IMPORTANT";

export interface ContentActionPanelInput {
  coachingParameters?: InputMaybe<SupplyCoachingParameters>;
  type: Scalars["String"];
}

export interface ContentPaginationInput {
  pageNumber: Scalars["Int"];
  pageSize?: InputMaybe<Scalars["Int"]>;
}

export interface ContentScoreInput {
  cardType?: InputMaybe<Scalars["String"]>;
  previousScore?: InputMaybe<Scalars["String"]>;
  skipForNow?: InputMaybe<Scalars["String"]>;
  source: Scalars["String"];
}

export type ContentSectionDetails = {
  __typename?: "ContentSectionDetails";
  accessibilitySection: PropertyContentSectionGroup;
};

export type ContentSource = "DESTINATION_API" | "EM";

export type Context = {
  __typename?: "Context";
  /** @deprecated Rely on HTTP headers for client identification, replace with Format: `Client-Info=<name>,<version>,<environment>` */
  clientInfo?: Maybe<ClientInfo>;
  currency?: Maybe<Scalars["String"]>;
  debugContext?: Maybe<DebugContext>;
  device: Device;
  eapid?: Maybe<Scalars["Int"]>;
  /**
   * @deprecated We no longer rely on identity for user determination.
   *         User determination is now based upon passed headers. Soon this will be made nullable and later removed.
   *         Will be replaced by JWTs when available., replace with DUAID Header
   */
  identity: Identity;
  locale: Scalars["Locale"];
  privacyTrackingState?: Maybe<PrivacyTrackingState>;
  siteId: Scalars["Int"];
  tpid?: Maybe<Scalars["Int"]>;
};

export interface ContextInput {
  clientInfo?: InputMaybe<ClientInfoInput>;
  currency?: InputMaybe<Scalars["String"]>;
  debugContext?: InputMaybe<DebugContextInput>;
  device: DeviceInput;
  eapid?: InputMaybe<Scalars["Int"]>;
  identity?: InputMaybe<IdentityInput>;
  locale: Scalars["Locale"];
  privacyTrackingState?: InputMaybe<PrivacyTrackingState>;
  siteId: Scalars["Int"];
  tpid?: InputMaybe<Scalars["Int"]>;
}

export type ContextualInfo = {
  __typename?: "ContextualInfo";
  binSpec?: Maybe<BinSpec>;
  checkoutId?: Maybe<Scalars["String"]>;
  etzToken?: Maybe<Scalars["String"]>;
  providerConfirmationStatus?: Maybe<Scalars["String"]>;
  publicApiKey?: Maybe<Scalars["String"]>;
  script?: Maybe<Scalars["String"]>;
};

export interface ContextualNotification {
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  revealActions?: Maybe<Array<NotificationAction>>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
}

export interface ConversationAttributesInput {
  currentPageUrl?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<ConversationType>;
}

export interface ConversationContextInput {
  attributes?: InputMaybe<ConversationAttributesInput>;
  conversationId?: InputMaybe<Scalars["String"]>;
  participantId?: InputMaybe<Scalars["String"]>;
}

export type ConversationIdentifiers = {
  __typename?: "ConversationIdentifiers";
  conversationId: Scalars["String"];
  participantId: Scalars["String"];
};

export interface ConversationInitializationConfigsInput {
  conversation?: InputMaybe<ConversationInput>;
  conversationAttributes?: InputMaybe<Array<VirtualAgentControlAttributeInput>>;
  forceNewConversation?: InputMaybe<Scalars["Boolean"]>;
  mode: ConversationMode;
  participant?: InputMaybe<ParticipantInput>;
}

export interface ConversationInput {
  conversationId: Scalars["String"];
}

export type ConversationMetadata = {
  __typename?: "ConversationMetadata";
  defaultMessageDeliveryText: Scalars["String"];
  failedMessageText: EgdsGraphicText;
  loadingText: Scalars["String"];
  retry: CommunicationCenterButton;
};

export type ConversationMode = "CREATE_CONVERSATION" | "JOIN_CONVERSATION";

export type ConversationParticipant = {
  __typename?: "ConversationParticipant";
  cpUserId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  participantType: ConversationParticipantType;
  user?: Maybe<ConversationUser>;
};

export type ConversationParticipantType =
  | "HumanAgent"
  | "HumanAgentVA"
  | "Supplier"
  | "SupplierVA"
  | "Traveler"
  | "TravelerVA";

export type ConversationProperties = {
  __typename?: "ConversationProperties";
  launchPoint: Scalars["String"];
  pageName?: Maybe<Scalars["String"]>;
  skipWelcome?: Maybe<Scalars["String"]>;
  testVaId?: Maybe<Scalars["String"]>;
  traceId?: Maybe<Scalars["UUID"]>;
};

export type ConversationType = "MAIN" | "SUPPLIER";

export type ConversationUser = {
  __typename?: "ConversationUser";
  preferredName?: Maybe<Scalars["String"]>;
};

export type CoordinateInformation = {
  __typename?: "CoordinateInformation";
  flightDetailsWarningInfo?: Maybe<FlightsIconAndLabel>;
  icon: Icon;
  /** @deprecated This will be replaced by flightDetailsWarningInfo */
  overnightMessage?: Maybe<Scalars["String"]>;
  /** @deprecated This will be replaced by flightDetailsWarningInfo */
  overnightMessageInfo?: Maybe<FlightsIconAndLabel>;
  subtitle: Scalars["String"];
  /** @deprecated Replaced by titleAndAccessibilityMessage */
  title: Scalars["String"];
  titleAndAccessibilityMessage: FlightsMessageAndAccessibility;
};

export type Coordinates = {
  __typename?: "Coordinates";
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export interface CoordinatesInput {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
}

export type CopyToClipboardAction = TripsAction & {
  __typename?: "CopyToClipboardAction";
  analytics: ClientSideAnalytics;
  toast?: Maybe<TripsInfoToast>;
  value: Scalars["String"];
};

export type CostPercentiles = {
  __typename?: "CostPercentiles";
  eightieth: Money;
  fiftieth: Money;
  ninetieth: Money;
  tenth: Money;
  twentieth: Money;
};

export type CostPercentilesByMonth = {
  __typename?: "CostPercentilesByMonth";
  month: Month;
  percentiles: CostPercentiles;
};

export type CostPercentilesByWeek = {
  __typename?: "CostPercentilesByWeek";
  percentiles: CostPercentiles;
  week: Scalars["Int"];
};

export type Coupon = {
  __typename?: "Coupon";
  code?: Maybe<Scalars["String"]>;
  instanceId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type CouponAndCreditAction = UiAction & {
  __typename?: "CouponAndCreditAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  useInstructions?: Maybe<CouponAndCreditUseAction>;
};

export type CouponAndCreditCard = {
  __typename?: "CouponAndCreditCard";
  actions: Array<Maybe<UiAction>>;
  airlineIcon?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  banner?: Maybe<UiBanner>;
  cardContents: Array<Maybe<CouponsAndCreditsCardContent>>;
  heading: Scalars["String"];
  subheading?: Maybe<Scalars["String"]>;
};

export type CouponAndCreditChatbotAction = UiAction & {
  __typename?: "CouponAndCreditChatbotAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  intentMessage: ChatbotConversationIntentMessage;
  resource: Uri;
};

export type CouponAndCreditUseAction = {
  __typename?: "CouponAndCreditUseAction";
  actions: Array<UiAction>;
  heading?: Maybe<Scalars["String"]>;
  messages: Array<CouponAndCreditUseInstructionsMessage>;
};

export type CouponAndCreditUseInstructionsMessage = {
  __typename?: "CouponAndCreditUseInstructionsMessage";
  elements: Array<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type CouponDetail = {
  __typename?: "CouponDetail";
  id: Scalars["String"];
  name: Scalars["String"];
  statusMessage: Scalars["String"];
  termsAndConditions: CouponTermsAndConditions;
};

export type CouponDetailsContainer = {
  __typename?: "CouponDetailsContainer";
  content: Array<CouponsText>;
  title?: Maybe<Scalars["String"]>;
  type: SectionType;
};

export type CouponDomainInfo = {
  __typename?: "CouponDomainInfo";
  name: Scalars["String"];
  refId: Scalars["String"];
};

export type CouponEntryTab = EgdsTab & {
  __typename?: "CouponEntryTab";
  accessibility?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  tabId: Scalars["String"];
};

export type CouponErrorBanner = {
  __typename?: "CouponErrorBanner";
  message: Scalars["String"];
};

export interface CouponIdentifierInput {
  type: CouponIdentifierType;
  value: Scalars["String"];
}

export type CouponIdentifierType = "CODE" | "INSTANCE_ID";

export type CouponRedemption = {
  __typename?: "CouponRedemption";
  appliedCoupon?: Maybe<AppliedCouponDetail>;
  callToAction?: Maybe<EgdsStandardMessagingCard>;
  defaultErrorMessage: Scalars["String"];
  dialog?: Maybe<CouponRedemptionView>;
  errorMessage?: Maybe<Scalars["String"]>;
  fallBack: Array<FallBack>;
  isModuleApplicable: Scalars["Boolean"];
  signals: Array<CouponSignal>;
  toastMessage?: Maybe<Scalars["String"]>;
};

export type CouponRedemptionFromAccount = {
  __typename?: "CouponRedemptionFromAccount";
  apply: EgdsButton;
  coupons: Array<Coupon>;
  noCouponSelectedMessage: Scalars["String"];
  text: Scalars["String"];
};

export type CouponRedemptionView = {
  __typename?: "CouponRedemptionView";
  accountCoupons?: Maybe<CouponRedemptionFromAccount>;
  codeEntry: CouponRedemptionWithCode;
  couponEntryTypeSelection: EgdsBasicTabs;
  toolbar: EgdsDialogToolbar;
};

export type CouponRedemptionWithCode = {
  __typename?: "CouponRedemptionWithCode";
  apply: EgdsButton;
  couponCodeInput: EgdsTextInputField;
  noCouponCodeEnteredMessage: Scalars["String"];
  signIn?: Maybe<EgdsStandardLink>;
};

export type CouponSignal = {
  __typename?: "CouponSignal";
  domainInfoList: Array<CouponDomainInfo>;
  id?: Maybe<Scalars["String"]>;
  signal?: Maybe<Scalars["String"]>;
  signalDetails: Array<CouponSignalDetails>;
  urn: Scalars["String"];
};

export type CouponSignalDetails = {
  __typename?: "CouponSignalDetails";
  detailedDescription: Scalars["String"];
  reasonUrn: Scalars["String"];
  summary: Scalars["String"];
};

export type CouponTermsAndConditions = {
  __typename?: "CouponTermsAndConditions";
  informationUrl: HttpUri;
  text: Scalars["String"];
};

export type CouponsAndCredits = {
  __typename?: "CouponsAndCredits";
  activeStatus?: Maybe<ActiveState>;
  additionalMessaging?: Maybe<Scalars["String"]>;
  banner?: Maybe<UiBanner>;
  elements: Array<CouponsAndCreditsSectionContainer>;
  navLink?: Maybe<UiLinkAction>;
  sourceAnalyticsTagging?: Maybe<ClientSideAnalytics>;
};

export type CouponsAndCreditsCardContent = {
  __typename?: "CouponsAndCreditsCardContent";
  link?: Maybe<UiLinkAction>;
  value: Scalars["String"];
};

export type CouponsAndCreditsEmptyState = {
  __typename?: "CouponsAndCreditsEmptyState";
  elements: Array<Scalars["String"]>;
};

export type CouponsAndCreditsExpando = {
  __typename?: "CouponsAndCreditsExpando";
  action: UiAction;
  elements: Array<CouponAndCreditCard>;
};

export type CouponsAndCreditsExplainedLink = {
  __typename?: "CouponsAndCreditsExplainedLink";
  footer?: Maybe<Scalars["String"]>;
  link: UiLinkAction;
};

export type CouponsAndCreditsGroup = {
  __typename?: "CouponsAndCreditsGroup";
  cards: Array<CouponAndCreditCard>;
  expando?: Maybe<CouponsAndCreditsExpando>;
  heading?: Maybe<Scalars["String"]>;
  subheading?: Maybe<Scalars["String"]>;
};

export type CouponsAndCreditsListing = CouponsAndCreditsEmptyState | CouponsAndCreditsGroup;

export type CouponsAndCreditsSectionContainer = {
  __typename?: "CouponsAndCreditsSectionContainer";
  elements: Array<CouponsAndCreditsListing>;
  heading: Scalars["String"];
  links: Array<Maybe<CouponsAndCreditsExplainedLink>>;
};

export type CouponsAndCreditsSimpleAction = UiAction & {
  __typename?: "CouponsAndCreditsSimpleAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type CouponsText = {
  __typename?: "CouponsText";
  combinedTexts: Array<UnionText>;
};

export type CreateConversationError = {
  __typename?: "CreateConversationError";
  banner?: Maybe<VirtualAgentControlErrorBanner>;
  fullPage?: Maybe<VirtualAgentControlErrorFullPageView>;
};

export type CreateConversationResult = CreateConversationError | CreateConversationSuccess;

export type CreateConversationSuccess = {
  __typename?: "CreateConversationSuccess";
  identifiers: ConversationIdentifiers;
};

export type CreditCardApplicationApprovedState = {
  __typename?: "CreditCardApplicationApprovedState";
  disclosures: CreditCardLinkGroup;
  disclosuresInfo: Scalars["String"];
  image: Image;
  info: Array<Scalars["String"]>;
  offerDetails: Array<CreditCardApplicationOfferDetail>;
  offerInfo?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type CreditCardApplicationBanner = {
  __typename?: "CreditCardApplicationBanner";
  backgroundImage: Image;
  heading: EgdsSpannableText;
  image: Image;
  text: Scalars["String"];
};

export type CreditCardApplicationBenefit = {
  __typename?: "CreditCardApplicationBenefit";
  cardContainer: CreditCardApplicationBenefitCardContainer;
  details: Array<CreditCardApplicationBenefitDetail>;
};

export type CreditCardApplicationBenefitCardContainer = {
  __typename?: "CreditCardApplicationBenefitCardContainer";
  cardInfo: Array<CreditCardApplicationBenefitCardInfo>;
  disclaimer?: Maybe<Array<EgdsSpannableText>>;
};

export type CreditCardApplicationBenefitCardInfo = {
  __typename?: "CreditCardApplicationBenefitCardInfo";
  benefitDetails?: Maybe<CreditCardApplicationBenefitDetail>;
  buttons?: Maybe<Array<CreditCardApplyButton>>;
  cardType: CreditCardType;
  description?: Maybe<EgdsStylizedText>;
  heading: EgdsSpannableText;
  image: Image;
};

export type CreditCardApplicationBenefitDetail = {
  __typename?: "CreditCardApplicationBenefitDetail";
  description: EgdsSpannableText;
  heading: Array<CreditCardApplicationBenefitDetailHeading>;
  subHeading?: Maybe<EgdsSpannableText>;
};

export type CreditCardApplicationBenefitDetailHeading = CreditCardApplicationSizedHeading;

export type CreditCardApplicationBenefitHeader = {
  __typename?: "CreditCardApplicationBenefitHeader";
  backgroundImage: Image;
  bonusDetails: Array<CreditCardBenefitHeaderDetails>;
  cardDescription: Scalars["String"];
  earningDetails: Array<CreditCardBenefitHeaderDetails>;
  footerDetails: CreditCardBenefitHeaderDetails;
  heading: EgdsSpannableText;
  image: Image;
};

export type CreditCardApplicationCompoundLoadingDialog = {
  __typename?: "CreditCardApplicationCompoundLoadingDialog";
  initialContent: CreditCardApplicationCompoundLoadingDialogContent;
  timeoutContent?: Maybe<CreditCardApplicationCompoundLoadingDialogContent>;
  timeoutSeconds?: Maybe<Scalars["Int"]>;
};

export type CreditCardApplicationCompoundLoadingDialogContent = {
  __typename?: "CreditCardApplicationCompoundLoadingDialogContent";
  heading: Scalars["String"];
  subheading?: Maybe<Scalars["String"]>;
};

export type CreditCardApplicationContext = {
  __typename?: "CreditCardApplicationContext";
  applicationToken: Scalars["String"];
};

export type CreditCardApplicationDeclineAction = {
  __typename?: "CreditCardApplicationDeclineAction";
  actionType: CreditCardApplicationDeclineActionType;
};

export type CreditCardApplicationDeclineActionType = CreditCardApplicationDismissAction | UiLinkAction;

export type CreditCardApplicationDismissAction = {
  __typename?: "CreditCardApplicationDismissAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars["String"]>;
};

export type CreditCardApplicationEarlyExitDialog = {
  __typename?: "CreditCardApplicationEarlyExitDialog";
  buttons: Array<CreditCardCtaButton>;
  text?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type CreditCardApplicationForm = {
  __typename?: "CreditCardApplicationForm";
  consentAndCta: CreditCardConsentAndCta;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  header?: Maybe<CreditCardApplicationFormHeader>;
  identifiers: CreditCardApplicationFormIdentifiers;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  loadingDialog: CreditCardApplicationCompoundLoadingDialog;
  sections: Array<CreditCardApplicationFormSection>;
  subtitles?: Maybe<Array<Scalars["String"]>>;
  title?: Maybe<Scalars["String"]>;
};

export type CreditCardApplicationFormCobrandConfiguration = {
  __typename?: "CreditCardApplicationFormCobrandConfiguration";
  brandIdentifier: Scalars["String"];
  cardType: CreditCardType;
  landingUrl: Scalars["String"];
  merchantNumber: Scalars["String"];
  partnerMemberId?: Maybe<Scalars["String"]>;
  referrer: Scalars["String"];
  spendAmount?: Maybe<Scalars["Float"]>;
};

export type CreditCardApplicationFormConfiguration = {
  __typename?: "CreditCardApplicationFormConfiguration";
  coBrand: CreditCardApplicationFormCobrandConfiguration;
  merchantBackend: CreditCardApplicationFormMerchantBackendConfiguration;
  prefill?: Maybe<CreditCardApplicationFormPrefillData>;
  provider: CreditCardApplicationFormProviderConfiguration;
  ui: CreditCardApplicationFormUiConfiguration;
};

export type CreditCardApplicationFormDateInputField = {
  __typename?: "CreditCardApplicationFormDateInputField";
  dateFormat: Scalars["String"];
  egdsElementId: Scalars["String"];
  errorMessage: Scalars["String"];
  input: CreditCardApplicationFormMaskedNumberInputField;
};

export type CreditCardApplicationFormDisclaimer = {
  __typename?: "CreditCardApplicationFormDisclaimer";
  label: Scalars["String"];
  text: Array<CreditCardApplicationFormText>;
};

export type CreditCardApplicationFormField =
  | CreditCardApplicationFormDisclaimer
  | CreditCardApplicationFormFieldRow
  | CreditCardApplicationFormFieldSet
  | CreditCardApplicationFormInput
  | CreditCardApplicationFormText
  | CreditCardExternalContent;

export type CreditCardApplicationFormFieldRow = {
  __typename?: "CreditCardApplicationFormFieldRow";
  fields: Array<CreditCardApplicationFormInput>;
};

export type CreditCardApplicationFormFieldSet = {
  __typename?: "CreditCardApplicationFormFieldSet";
  fields: Array<CreditCardApplicationFormFieldSetEntry>;
  label?: Maybe<Scalars["String"]>;
};

export type CreditCardApplicationFormFieldSetEntry =
  | CreditCardApplicationFormFieldRow
  | CreditCardApplicationFormInput
  | CreditCardApplicationFormText;

export type CreditCardApplicationFormHeader = CreditCardApplicationBanner | CreditCardApplicationBenefitHeader;

export type CreditCardApplicationFormIdentifiers = {
  __typename?: "CreditCardApplicationFormIdentifiers";
  applicationId: Scalars["String"];
  applicationToken: Scalars["String"];
  instantCredit: Scalars["Boolean"];
  landingUrl: Scalars["String"];
  referrer: Scalars["String"];
  subProductCode: Scalars["String"];
};

export type CreditCardApplicationFormInput = {
  __typename?: "CreditCardApplicationFormInput";
  inputField: CreditCardApplicationFormInputField;
  subLabel?: Maybe<Scalars["String"]>;
  tooltipAction?: Maybe<CreditCardApplicationFormTooltipAction>;
};

export type CreditCardApplicationFormInputField =
  | CreditCardApplicationFormDateInputField
  | CreditCardApplicationFormMaskedNumberInputField
  | CreditCardHiddenDescriptionCheckbox
  | EgdsBasicCheckBox
  | EgdsBasicRadioGroup
  | EgdsBasicSelect
  | EgdsEmailInputField
  | EgdsNumberInputField
  | EgdsTextInputField;

export type CreditCardApplicationFormMaskedNumberInputField = {
  __typename?: "CreditCardApplicationFormMaskedNumberInputField";
  input: EgdsNumberInputField;
  masking: Scalars["String"];
};

export type CreditCardApplicationFormMerchantBackendConfiguration = {
  __typename?: "CreditCardApplicationFormMerchantBackendConfiguration";
  applicationsUrl: Scalars["String"];
  jwtToken: Scalars["String"];
  paymentInitializationUrl: Scalars["String"];
  sdkEventUrl: Scalars["String"];
  secureConfigurationUrl: Scalars["String"];
};

export type CreditCardApplicationFormOperation = "EQ" | "GE" | "GT" | "LE" | "LT" | "NE" | "NREX" | "REX";

export type CreditCardApplicationFormPrefillData = {
  __typename?: "CreditCardApplicationFormPrefillData";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type CreditCardApplicationFormProviderConfiguration = {
  __typename?: "CreditCardApplicationFormProviderConfiguration";
  sdkScriptUrl: Scalars["String"];
  sdkUrl: Scalars["String"];
};

export interface CreditCardApplicationFormQueryInput {
  landingUrl?: InputMaybe<Scalars["String"]>;
  sourcePage: CreditCardSourcePage;
}

export type CreditCardApplicationFormSdk = {
  __typename?: "CreditCardApplicationFormSdk";
  configuration: CreditCardApplicationFormConfiguration;
  headers: Array<CreditCardApplicationSdkHeader>;
};

export type CreditCardApplicationFormSection = {
  __typename?: "CreditCardApplicationFormSection";
  egdsElementId: Scalars["String"];
  fields: Array<CreditCardApplicationFormField>;
  hasDivider: Scalars["Boolean"];
  parentId?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  visibilityConditions?: Maybe<Array<CreditCardApplicationFormVisibilityCondition>>;
  visible: Scalars["Boolean"];
};

export type CreditCardApplicationFormText = {
  __typename?: "CreditCardApplicationFormText";
  textField: CreditCardApplicationTextField;
};

export type CreditCardApplicationFormTooltipAction = {
  __typename?: "CreditCardApplicationFormTooltipAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  sheet: EgdsSheet;
  text: Scalars["String"];
};

export type CreditCardApplicationFormUiConfiguration = {
  __typename?: "CreditCardApplicationFormUiConfiguration";
  loadCustomCSS?: Maybe<Scalars["Boolean"]>;
  loadCustomResourceBundle?: Maybe<Scalars["Boolean"]>;
  noAnimation?: Maybe<Scalars["Boolean"]>;
  silentInit?: Maybe<Scalars["Boolean"]>;
};

export interface CreditCardApplicationFormUpdateInput {
  applicationId: Scalars["String"];
  applicationToken: Scalars["String"];
  fields: CreditCardApplicationFormValuesInput;
}

export interface CreditCardApplicationFormValuesInput {
  fieldValues: Array<GraphQlPairInput>;
}

export type CreditCardApplicationFormVisibilityCondition = {
  __typename?: "CreditCardApplicationFormVisibilityCondition";
  left: Scalars["String"];
  operation: CreditCardApplicationFormOperation;
  right: Scalars["String"];
};

export type CreditCardApplicationHeadingSize = "LARGE" | "SMALL";

export type CreditCardApplicationInactivityAndTimeout = {
  __typename?: "CreditCardApplicationInactivityAndTimeout";
  expiringSessionTimerSeconds: Scalars["Int"];
  inactivityWarningScreen: CreditCardApplicationInactivityWarningScreen;
  inactivityWarningSeconds: Scalars["Int"];
  sessionTimeoutScreen: CreditCardApplicationSessionTimeoutScreen;
};

export type CreditCardApplicationInactivityWarningScreen = {
  __typename?: "CreditCardApplicationInactivityWarningScreen";
  buttons: Array<CreditCardCtaButton>;
  content: Scalars["String"];
  counter: EgdsGraphicText;
  title: Scalars["String"];
};

export type CreditCardApplicationOfferDetail = {
  __typename?: "CreditCardApplicationOfferDetail";
  description: Scalars["String"];
  title: Scalars["String"];
};

export interface CreditCardApplicationOfferInput {
  applicationId: Scalars["String"];
  applicationToken: Scalars["String"];
  secureConsent: CreditCardApplicationSecureConsentInput;
}

export type CreditCardApplicationOfferScreen = {
  __typename?: "CreditCardApplicationOfferScreen";
  consentAndCta: CreditCardConsentAndCta;
  details?: Maybe<Array<CreditCardApplicationOfferDetail>>;
  earlyExitDialog?: Maybe<CreditCardApplicationEarlyExitDialog>;
  image?: Maybe<Image>;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  info?: Maybe<Scalars["String"]>;
  loadingDialog?: Maybe<CreditCardApplicationCompoundLoadingDialog>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CreditCardApplicationOpenDialogAction = {
  __typename?: "CreditCardApplicationOpenDialogAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  dialogType: CreditCardDialogType;
};

export type CreditCardApplicationPendingState = {
  __typename?: "CreditCardApplicationPendingState";
  info: Array<Scalars["String"]>;
  nextStepsText: Array<CreditCardApplicationTextField>;
  nextStepsTitle: Scalars["String"];
  offerDetails: Array<CreditCardApplicationOfferDetail>;
  title: Scalars["String"];
};

export type CreditCardApplicationPlacement = {
  __typename?: "CreditCardApplicationPlacement";
  accessibilityHeading?: Maybe<Scalars["String"]>;
  cardFeeLink: EgdsInlineLink;
  cardImage: Image;
  cta?: Maybe<CreditCardApplyButton>;
  ctaIcon?: Maybe<Icon>;
  ctaMessage: Scalars["String"];
  featuredHeading?: Maybe<Scalars["String"]>;
  headingClaim: EgdsPlainText;
  subHeadingClaim: CreditCardIconText;
  termsClaim: Scalars["String"];
};

export type CreditCardApplicationSdkEarlyExitDialog = {
  __typename?: "CreditCardApplicationSdkEarlyExitDialog";
  dialog?: Maybe<CreditCardApplicationEarlyExitDialog>;
  sdkPageIds: Array<Scalars["String"]>;
};

export type CreditCardApplicationSdkHeader = {
  __typename?: "CreditCardApplicationSdkHeader";
  header?: Maybe<CreditCardApplicationFormHeader>;
  sdkPageIds: Array<Scalars["String"]>;
};

export interface CreditCardApplicationSecureConsentInput {
  creditConsent?: InputMaybe<Scalars["Boolean"]>;
  disclosureCreatedDatetime?: InputMaybe<Scalars["String"]>;
  eSignConsent?: InputMaybe<Scalars["Boolean"]>;
  mobileInformationConsent?: InputMaybe<Scalars["Boolean"]>;
  phoneConsent?: InputMaybe<Scalars["Boolean"]>;
  ssaConsent?: InputMaybe<Scalars["Boolean"]>;
  termsAgreementConsent?: InputMaybe<Scalars["Boolean"]>;
  wellsFargoPrivacyPolicyConsent?: InputMaybe<Scalars["Boolean"]>;
}

export type CreditCardApplicationSessionTimeoutScreen = {
  __typename?: "CreditCardApplicationSessionTimeoutScreen";
  buttons: Array<CreditCardCtaButton>;
  content: Scalars["String"];
  title: Scalars["String"];
};

export type CreditCardApplicationSizedHeading = {
  __typename?: "CreditCardApplicationSizedHeading";
  size: CreditCardApplicationHeadingSize;
  text: Scalars["String"];
};

export type CreditCardApplicationStepResponse = {
  __typename?: "CreditCardApplicationStepResponse";
  applicationContext: CreditCardApplicationContext;
  nextScreen: CreditCardScreen;
};

export type CreditCardApplicationTextField =
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableList
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsUnorderedList;

export interface CreditCardApplicationThankYouInput {
  applicantFirstName: Scalars["String"];
  applicationID: Scalars["String"];
  applicationToken: Scalars["String"];
  cardType: CreditCardType;
  screenType: CreditCardThankYouScreenType;
}

export type CreditCardApplicationThankYouScreen = {
  __typename?: "CreditCardApplicationThankYouScreen";
  applicationState: CreditCardApplicationThankYouState;
  banner?: Maybe<CreditCardApplicationBanner>;
  ctaButton: CreditCardCtaButton;
};

export type CreditCardApplicationThankYouState = CreditCardApplicationApprovedState | CreditCardApplicationPendingState;

export type CreditCardApplyButton = {
  __typename?: "CreditCardApplyButton";
  action: CreditCardApplyButtonAction;
  button: CreditCardButton;
};

export type CreditCardApplyButtonAction = CreditCardEmbeddedApplyAction | UiLinkAction;

export type CreditCardApplyButtonActionType = "EMBEDDED" | "EXTERNAL";

export type CreditCardAwarenessPlacement = {
  __typename?: "CreditCardAwarenessPlacement";
  backgroundImage: Image;
  badge?: Maybe<EgdsBadge>;
  cta: CreditCardApplyButton;
  heading: EgdsPlainText;
  headingNote?: Maybe<EgdsPlainText>;
  image: Image;
  subHeading?: Maybe<EgdsPlainText>;
};

export type CreditCardBenefitHeaderDetails = {
  __typename?: "CreditCardBenefitHeaderDetails";
  description?: Maybe<CreditCardApplicationTextField>;
  title: CreditCardBenefitHeaderTitle;
};

export type CreditCardBenefitHeaderTitle = {
  __typename?: "CreditCardBenefitHeaderTitle";
  icon?: Maybe<UiGraphic>;
  title: Scalars["String"];
};

export interface CreditCardBenefitsInput {
  landingUrl?: InputMaybe<Scalars["String"]>;
}

export type CreditCardBenefitsSection = {
  __typename?: "CreditCardBenefitsSection";
  benefits: Array<CreditCardApplicationBenefit>;
  title: Scalars["String"];
};

export type CreditCardButton = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export interface CreditCardCheckoutSessionValidateRequestInput {
  sessionId: Scalars["String"];
}

export type CreditCardCheckoutSessionValidateResponse = {
  __typename?: "CreditCardCheckoutSessionValidateResponse";
  signals: Array<CreditCardCheckoutSignalType>;
};

export type CreditCardCheckoutSignalType = "PRICE_ADJUSTED" | "PRICE_CHANGED" | "USER_TIER_UPGRADED";

export type CreditCardComparisonHeader = {
  __typename?: "CreditCardComparisonHeader";
  backgroundImage: Image;
  cardContainer: CreditCardApplicationBenefitCardContainer;
  jumpLink: CreditCardIconText;
  oneKeyLogo: Image;
  subtitle: EgdsSpannableText;
};

export interface CreditCardComparisonHeaderInput {
  landingUrl?: InputMaybe<Scalars["String"]>;
}

export interface CreditCardComparisonTableInput {
  landingUrl?: InputMaybe<Scalars["String"]>;
}

export type CreditCardComparisonTableItem = CreditCardApplicationBenefitCardContainer | CreditCardDetailedComparison;

export type CreditCardComparisonTableSection = {
  __typename?: "CreditCardComparisonTableSection";
  content: Array<CreditCardComparisonTableItem>;
  title: Scalars["String"];
};

export type CreditCardConsentAndCta = {
  __typename?: "CreditCardConsentAndCta";
  buttons: Array<CreditCardCtaButton>;
  consent?: Maybe<EgdsSpannableText>;
};

export type CreditCardContentFormat = "HTML" | "PDF";

export type CreditCardContentSource = "TERMS_AND_CONDITIONS";

export type CreditCardCtaAction =
  | CreditCardApplicationDeclineAction
  | CreditCardApplicationDismissAction
  | CreditCardApplicationOpenDialogAction
  | CreditCardDownloadExternalContentAction
  | EgdsDismissAction
  | UiLinkAction;

export type CreditCardCtaButton = {
  __typename?: "CreditCardCtaButton";
  action?: Maybe<CreditCardCtaAction>;
  button: CreditCardButton;
};

export type CreditCardDetailedComparison = {
  __typename?: "CreditCardDetailedComparison";
  details: Array<CreditCardDetailedComparisonRow>;
};

export type CreditCardDetailedComparisonDetails = CreditCardApplicationBenefitDetail | Icon;

export type CreditCardDetailedComparisonRow = {
  __typename?: "CreditCardDetailedComparisonRow";
  comparison: Array<CreditCardDetailedComparisonDetails>;
  title?: Maybe<Scalars["String"]>;
};

export type CreditCardDialogType = "EARLY_EXIT";

export type CreditCardDownloadExternalContentAction = {
  __typename?: "CreditCardDownloadExternalContentAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type CreditCardEmbeddedApplyAction = {
  __typename?: "CreditCardEmbeddedApplyAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  container: CreditCardEmbeddedApplyActionSheet;
};

export type CreditCardEmbeddedApplyActionSheet = {
  __typename?: "CreditCardEmbeddedApplyActionSheet";
  sheet: EgdsSheet;
};

export type CreditCardEncodedContent = {
  __typename?: "CreditCardEncodedContent";
  content: Scalars["String"];
  encodingType: CreditCardEncodingType;
  fileName: Scalars["String"];
};

export interface CreditCardEncodedContentInput {
  applicationId: Scalars["String"];
  applicationToken: Scalars["String"];
  cardType: CreditCardType;
  contentFormat: CreditCardContentFormat;
  contentSource: CreditCardContentSource;
}

export type CreditCardEncodingType = "Base64";

export type CreditCardErrorScreen = {
  __typename?: "CreditCardErrorScreen";
  actionButton?: Maybe<CreditCardCtaButton>;
  actionDescription?: Maybe<Scalars["String"]>;
  banner?: Maybe<CreditCardApplicationBanner>;
  ctaButton: CreditCardCtaButton;
  heading: Scalars["String"];
  paragraphs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  subheading?: Maybe<Scalars["String"]>;
};

export interface CreditCardErrorScreenInput {
  cardType?: InputMaybe<CreditCardType>;
  sourcePage: CreditCardSourcePage;
}

export type CreditCardExpandableText = {
  __typename?: "CreditCardExpandableText";
  content: Array<CreditCardApplicationTextField>;
  title: EgdsExpandoListItem;
};

export type CreditCardExternalContent = {
  __typename?: "CreditCardExternalContent";
  button: CreditCardCtaButton;
  encodedContent: CreditCardEncodedContent;
  label: Scalars["String"];
};

export type CreditCardFaqSection = {
  __typename?: "CreditCardFAQSection";
  content: Array<CreditCardExpandableText>;
  title: Scalars["String"];
};

export interface CreditCardFraudOtpInput {
  applicationToken: Scalars["String"];
  passcode?: InputMaybe<Scalars["String"]>;
  passcodeInstructionId: Scalars["String"];
  phoneNumberLastFour?: InputMaybe<Scalars["String"]>;
  screenType: CreditCardFraudOtpScreenType;
}

export type CreditCardFraudOtpScreen = {
  __typename?: "CreditCardFraudOTPScreen";
  consentAndCta: CreditCardConsentAndCta;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  inputField?: Maybe<EgdsNumberInputField>;
  moreInfoTrigger: CreditCardMoreInfoTrigger;
  paragraphs: Array<EgdsSpannableText>;
  title: Scalars["String"];
  toastMessage?: Maybe<Scalars["String"]>;
};

export type CreditCardFraudOtpScreenType = "GENERATE_CODE" | "VERIFY_CODE";

export type CreditCardHiddenDescriptionCheckbox = {
  __typename?: "CreditCardHiddenDescriptionCheckbox";
  egdsCheckbox: EgdsCheckBox;
};

export type CreditCardIconText = {
  __typename?: "CreditCardIconText";
  icon: Icon;
  position: EgdsIconPosition;
  text: Scalars["String"];
};

export type CreditCardLinkGroup = {
  __typename?: "CreditCardLinkGroup";
  links: EgdsLinkList;
  title?: Maybe<Scalars["String"]>;
};

export type CreditCardMoreInfoTrigger = {
  __typename?: "CreditCardMoreInfoTrigger";
  text: Scalars["String"];
  tooltipAction: CreditCardApplicationFormTooltipAction;
};

export type CreditCardOneKeyCashSection = {
  __typename?: "CreditCardOneKeyCashSection";
  brandLogos: Array<UiGraphic>;
  description: Array<EgdsSpannableText>;
  subtitle: EgdsSpannableText;
  title: Scalars["String"];
};

export type CreditCardPromotionResult = {
  __typename?: "CreditCardPromotionResult";
  applyButton: CreditCardApplyButton;
};

export type CreditCardScreen = "ERROR" | "OFFER" | "ORIGIN";

export type CreditCardSourcePage = "APPLICATION" | "CHECKOUT";

export type CreditCardTermsAndConditions = {
  __typename?: "CreditCardTermsAndConditions";
  banner?: Maybe<CreditCardApplicationBanner>;
  consentAndCta: CreditCardConsentAndCta;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  loadingDialog: CreditCardApplicationCompoundLoadingDialog;
  sections: Array<CreditCardApplicationFormSection>;
};

export type CreditCardThankYouScreenType = "APPROVED" | "PENDING";

export type CreditCardType = "FEE" | "NO_FEE";

export type CreditEligibleFilterType = "CREDIT_ELIGIBLE";

export type CreditRedemptionInsurtechOffersResponse = {
  __typename?: "CreditRedemptionInsurtechOffersResponse";
  creditRedemptionInsurtechModule: InsurtechModule;
};

export interface CriteriaRange {
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
}

export type CrossLinkImageLinkCard = {
  __typename?: "CrossLinkImageLinkCard";
  gaiaId?: Maybe<Scalars["String"]>;
  imagelink: EgdsImageLinkCard;
};

export type CrossLinkItem = CrossLinkImageLinkCard | CrossLinkStandardLink;

export type CrossLinkList = {
  __typename?: "CrossLinkList";
  carousel?: Maybe<EgdsCarousel>;
  items: Array<CrossLinkItem>;
  outline?: Maybe<CrossLinkOutline>;
};

export type CrossLinkLocations = {
  __typename?: "CrossLinkLocations";
  carousel?: Maybe<EgdsCarousel>;
  items: Array<CrossLinkItem>;
  map?: Maybe<EgdsBasicMap>;
  outline?: Maybe<CrossLinkOutline>;
};

export type CrossLinkOutline = {
  __typename?: "CrossLinkOutline";
  description?: Maybe<Scalars["String"]>;
  header?: Maybe<Scalars["String"]>;
  subheader?: Maybe<Scalars["String"]>;
};

export type CrossLinkStandardLink = {
  __typename?: "CrossLinkStandardLink";
  description?: Maybe<Scalars["String"]>;
  link: EgdsStandardLink;
};

export type CrossLinksGroupItems = {
  __typename?: "CrossLinksGroupItems";
  items: Array<CrossLinkItem>;
  name?: Maybe<Scalars["String"]>;
};

export type CrossLinksGroups = {
  __typename?: "CrossLinksGroups";
  items: Array<CrossLinksGroupItems>;
  outline?: Maybe<CrossLinkOutline>;
};

export interface CrossLinksParameterInput {
  name: Scalars["String"];
  value: Scalars["String"];
}

export type CrossSellAction = CrossSellButtonAction | CrossSellLinkAction;

export type CrossSellButtonAction = UiAction & {
  __typename?: "CrossSellButtonAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  resource: Uri;
};

export type CrossSellLegalDisclaimerDialog = {
  __typename?: "CrossSellLegalDisclaimerDialog";
  analytics: ClientSideAnalytics;
  dialog: EgdsActionDialog;
  graphic: UiGraphic;
  text: Scalars["String"];
};

export type CrossSellLinkAction = UiAction & {
  __typename?: "CrossSellLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
  resource: Uri;
};

export type CrossSellMessageItem = {
  __typename?: "CrossSellMessageItem";
  crossSellMessage: Scalars["String"];
  disclaimerDialog?: Maybe<CrossSellLegalDisclaimerDialog>;
};

export type CrossSellOption = {
  __typename?: "CrossSellOption";
  action: CrossSellLinkAction;
  actionBar?: Maybe<Array<CrossSellAction>>;
  graphic?: Maybe<UiGraphic>;
  header: CrossSellPrimary;
  image?: Maybe<Image>;
  messageItems: Array<CrossSellSecondary>;
  /** @deprecated use header instead */
  primary: Scalars["String"];
  /** @deprecated use messageItems instead */
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type CrossSellPrimary = CrossSellMessageItem;

export type CrossSellProductType = "ACTIVITY" | "CAR" | "FLIGHT" | "HOTEL";

export type CrossSellProductTypeQueries = {
  __typename?: "CrossSellProductTypeQueries";
  productTypes: Array<CrossSellProductType>;
};

export type CrossSellQueries = {
  __typename?: "CrossSellQueries";
  crossSellProductTypes: CrossSellProductTypeQueries;
  dynamic: DynamicCrossSellQueries;
  static: StaticCrossSellQueries;
};

export type CrossSellQueriesCrossSellProductTypesArgs = {
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type CrossSellSecondary = CrossSellLinkAction | CrossSellMessageItem | PackageUiButton;

export interface CrossSellUserLocationInput {
  city: Scalars["String"];
  continent: Scalars["String"];
  country: Scalars["String"];
  region: Scalars["String"];
}

export type CruiseAction = UiAction & {
  __typename?: "CruiseAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type CruiseAmenities = {
  __typename?: "CruiseAmenities";
  rows?: Maybe<Array<CruiseHighlightRow>>;
};

export type CruiseAmenitiesList = {
  __typename?: "CruiseAmenitiesList";
  amenities: Array<CruiseAmenityInfo>;
  collapseA11yLabel?: Maybe<Scalars["String"]>;
  collapseLabel?: Maybe<Scalars["String"]>;
  expandA11yLabel?: Maybe<Scalars["String"]>;
  expandLabel?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  visibleItems?: Maybe<Scalars["Int"]>;
};

export interface CruiseAmenitiesListInput {
  amenities: Array<CruiseAmenityInfoInput>;
  heading?: InputMaybe<Scalars["String"]>;
}

export type CruiseAmenitiesListOutput = {
  __typename?: "CruiseAmenitiesListOutput";
  amenities: Array<CruiseAmenityInfoOutput>;
  heading?: Maybe<Scalars["String"]>;
};

export type CruiseAmenitiesSection = {
  __typename?: "CruiseAmenitiesSection";
  amenities?: Maybe<Array<CruiseAmenityDetails>>;
  heading: Scalars["String"];
};

export type CruiseAmenityDetails = {
  __typename?: "CruiseAmenityDetails";
  amenitySummaries?: Maybe<CruiseAmenitiesList>;
  amenitySummariesLabel?: Maybe<Scalars["String"]>;
  benefitTexts: Array<Scalars["String"]>;
  promotionText: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type CruiseAmenityInfo = {
  __typename?: "CruiseAmenityInfo";
  a11yCategory?: Maybe<Scalars["String"]>;
  a11yDescription: Scalars["String"];
  category?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  icon: Icon;
};

export interface CruiseAmenityInfoInput {
  a11yCategory?: InputMaybe<Scalars["String"]>;
  a11yDescription: Scalars["String"];
  category?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  icon: CruiseOrderSummaryRatesPromotionIconInfoInput;
}

export type CruiseAmenityInfoOutput = {
  __typename?: "CruiseAmenityInfoOutput";
  a11yCategory?: Maybe<Scalars["String"]>;
  a11yDescription: Scalars["String"];
  category?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  icon: CruiseOrderSummaryRatesPromotionIconInfoOutput;
};

export type CruiseBadgeTheme =
  | "DEAL_GENERIC"
  | "DEAL_MEMBER"
  | "GLOBAL_LOYALTY_EXTRAHIGH_TIER"
  | "GLOBAL_LOYALTY_HIGH_TIER"
  | "GLOBAL_LOYALTY_LOW_TIER"
  | "GLOBAL_LOYALTY_MID_TIER"
  | "VIP";

export type CruiseBundleDetailsResults = {
  __typename?: "CruiseBundleDetailsResults";
  bundleDetails: CruiseOrderSummaryPromotionSideSheet;
};

export type CruiseCabinCategory = {
  __typename?: "CruiseCabinCategory";
  amenitiesList?: Maybe<CruiseAmenitiesList>;
  deckRange?: Maybe<CruiseInfo>;
  disclaimer?: Maybe<CruiseInfo>;
  galleryCarousel?: Maybe<CruiseGallery>;
  occupancyRange?: Maybe<CruiseInfo>;
  offlineRateDetails?: Maybe<OfflineRateDetails>;
  price: CruisePrice;
  selectButton?: Maybe<UiPrimaryButton>;
  squareFootageRange?: Maybe<CruiseInfo>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CruiseCabinCategorySection = {
  __typename?: "CruiseCabinCategorySection";
  analytics: CruiseCarouselAnalytics;
  cabinCategories?: Maybe<Array<CruiseCabinCategory>>;
  nextButtonText: Scalars["String"];
  prevButtonText: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type CruiseCabinClass = {
  __typename?: "CruiseCabinClass";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  continueButton: UiPrimaryButton;
  descriptionText: Scalars["String"];
  features: Array<CruiseCabinClassFeature>;
  galleryCarousel: CruiseGallery;
  occupancyRange?: Maybe<CruiseCabinClassOccupancy>;
  offlineRateDetails?: Maybe<OfflineRateDetails>;
  price: CruisePrice;
  roomRateSelection: CruiseRoomRateSelection;
  squareFootageRange?: Maybe<CruiseCabinClassSquareFootage>;
  title: Scalars["String"];
  userInterest?: Maybe<CruiseUserInterest>;
};

export type CruiseCabinClassFeature = {
  __typename?: "CruiseCabinClassFeature";
  accessibility: Scalars["String"];
  icon: Icon;
  text: Scalars["String"];
};

export type CruiseCabinClassOccupancy = {
  __typename?: "CruiseCabinClassOccupancy";
  accessibility: Scalars["String"];
  icon: Icon;
  text: Scalars["String"];
};

export type CruiseCabinClassSquareFootage = {
  __typename?: "CruiseCabinClassSquareFootage";
  accessibility: Scalars["String"];
  icon: Icon;
  text: Scalars["String"];
};

export type CruiseCabinExperience = {
  __typename?: "CruiseCabinExperience";
  analytics: CruiseCabinExperienceAnalytics;
  cabinClasses?: Maybe<Array<CruiseCabinClass>>;
  crumbs?: Maybe<Array<CruiseProgressIndicatorStep>>;
  descriptionText?: Maybe<Scalars["String"]>;
  expressedPreference?: Maybe<CruiseExpressedPreference>;
  nextButtonText: Scalars["String"];
  prevButtonText: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type CruiseCabinExperienceAnalytics = {
  __typename?: "CruiseCabinExperienceAnalytics";
  carouselName: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["String"];
};

export type CruiseCarouselAnalytics = {
  __typename?: "CruiseCarouselAnalytics";
  carouselName: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["String"];
};

export type CruiseCarouselButtonText = {
  __typename?: "CruiseCarouselButtonText";
  galleryButton: Scalars["String"];
  nextButton: Scalars["String"];
  overlayButton?: Maybe<EgdsOverlayButton>;
  prevButton: Scalars["String"];
};

export interface CruiseChildInput {
  age: Scalars["Int"];
}

export interface CruiseCommonItinerary {
  callToAction?: Maybe<UiPrimaryButton>;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
}

export type CruiseCommonItineraryImpl = CruiseCommonItinerary & {
  __typename?: "CruiseCommonItineraryImpl";
  callToAction?: Maybe<UiPrimaryButton>;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
};

export type CruiseConsultationError = {
  __typename?: "CruiseConsultationError";
  error: Scalars["String"];
  fieldId: Scalars["String"];
};

export type CruiseConsultationForm = {
  __typename?: "CruiseConsultationForm";
  additionalQuestion: EgdsTextAreaInputField;
  passengerBasicInfo: Array<EgdsElement>;
  roomPreferences: Array<EgdsSelect>;
  submit: UiPrimaryButton;
  travelExperiencePreferences: Array<EgdsRadioGroup>;
};

export interface CruiseConsultationFormInput {
  adultCount: Scalars["Int"];
  childCount: Scalars["Int"];
  country: Scalars["String"];
  datesFlexible?: InputMaybe<Scalars["String"]>;
  emailAddress: Scalars["String"];
  everCruised?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  passengersOver55?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  questions?: InputMaybe<Scalars["String"]>;
  roomTypePreference: Scalars["String"];
  zipCode: Scalars["String"];
}

export type CruiseConsultationFormSuccess = {
  __typename?: "CruiseConsultationFormSuccess";
  link: UiSecondaryButton;
  redirectUrl: Scalars["String"];
  text: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseConsultationResults = {
  __typename?: "CruiseConsultationResults";
  formFields: CruiseConsultationForm;
  introPassage: EgdsStandardMessagingCard;
  submit: CruiseConsultationSubmissionResponse;
  tripDetails?: Maybe<CruiseConsultationTripDetails>;
  userErrorMessage: EgdsErrorSummary;
};

export type CruiseConsultationSubmissionResponse = {
  __typename?: "CruiseConsultationSubmissionResponse";
  errorMessage?: Maybe<EgdsErrorSummary>;
  errors?: Maybe<Array<CruiseConsultationError>>;
  success?: Maybe<CruiseConsultationFormSuccess>;
};

export type CruiseConsultationTripDetails = {
  __typename?: "CruiseConsultationTripDetails";
  departingDate: Scalars["String"];
  departingLocation: Scalars["String"];
  iconMedia?: Maybe<CruiseMediaItem>;
  numOfTravelers: Scalars["String"];
  returningDate: Scalars["String"];
  returningLocation: Scalars["String"];
  title: Scalars["String"];
  tripDurationAndDestination: Scalars["String"];
};

export type CruiseDeckSelect = {
  __typename?: "CruiseDeckSelect";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  availableDeckLabel?: Maybe<Scalars["String"]>;
  availableOptions: Array<CruiseSelectOptions>;
  deckAnalytics: ClientSideAnalytics;
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  unavailableDeckLabel?: Maybe<Scalars["String"]>;
  unavailableOptions?: Maybe<Array<CruiseSelectOptions>>;
};

export type CruiseDialog = {
  __typename?: "CruiseDialog";
  closeAction?: Maybe<UiAction>;
  content: Scalars["String"];
  openAction?: Maybe<UiAction>;
  priceText?: Maybe<Scalars["String"]>;
  primaryButton: UiPrimaryButton;
};

export type CruiseDialogStaleSession = {
  __typename?: "CruiseDialogStaleSession";
  dialogButton: UiTertiaryButton;
  message: Scalars["String"];
  timeoutInSeconds: Scalars["Int"];
  title: Scalars["String"];
};

export interface CruiseDurationRangeInput {
  max: Scalars["Int"];
  min: Scalars["Int"];
}

export type CruiseExploreMapButton = {
  __typename?: "CruiseExploreMapButton";
  accessibility: Scalars["String"];
  clientSideAnalytics: ClientSideAnalytics;
  text: Scalars["String"];
};

export type CruiseExpressedPreference = {
  __typename?: "CruiseExpressedPreference";
  cabinExperienceIndex: Scalars["Int"];
};

export type CruiseFeature = "LIVE_SHOPPING_USING_MSC_ADAPTER" | "MEMBER_DEALS" | "MEMBER_DEALS_FOR_NON_MEMBERS";

export type CruiseFilterType =
  | "CABIN"
  | "DEPARTURE"
  | "DESTINATION"
  | "LENGTH"
  | "NAME"
  | "NOT_SET"
  | "SHIP"
  | "THEME_OCEAN"
  | "THEME_RIVER";

export type CruiseGallery = {
  __typename?: "CruiseGallery";
  a11yGalleryHeading?: Maybe<Scalars["String"]>;
  buttonText: CruiseCarouselButtonText;
  detailDialogClose?: Maybe<UiAction>;
  detailDialogLeftRight?: Maybe<UiAction>;
  dialogCloseText?: Maybe<Scalars["String"]>;
  dialogOpen?: Maybe<UiAction>;
  imageClick?: Maybe<UiAction>;
  leftRightClientSideAnalytics?: Maybe<UiAction>;
  /** @deprecated Use CruiseMediaItem list instead. */
  media?: Maybe<Array<CruiseMedia>>;
  mediaItems?: Maybe<Array<CruiseMediaItem>>;
  text?: Maybe<Scalars["String"]>;
  thumbnailDialogClose?: Maybe<UiAction>;
  thumbnailDialogImageClick?: Maybe<UiAction>;
  videoButtonText?: Maybe<CruiseVideoButtonText>;
  videoPlayPauseButtonClick?: Maybe<UiAction>;
};

export type CruiseHighlight = {
  __typename?: "CruiseHighlight";
  icon?: Maybe<Icon>;
  secondaryText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type CruiseHighlightRow = {
  __typename?: "CruiseHighlightRow";
  highlights?: Maybe<Array<CruiseHighlight>>;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type CruiseIcon = {
  __typename?: "CruiseIcon";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
  theme?: Maybe<CruiseBadgeTheme>;
};

export type CruiseInfo = {
  __typename?: "CruiseInfo";
  accessibility?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  moreInfo?: Maybe<CruisePopover>;
  text: Scalars["String"];
};

export type CruiseItinerary = CruiseCommonItinerary & {
  __typename?: "CruiseItinerary";
  callToAction?: Maybe<UiPrimaryButton>;
  galleryCarousel: CruiseGallery;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  title: Scalars["String"];
};

export type CruiseItineraryItem = {
  __typename?: "CruiseItineraryItem";
  accessibilityTimeDescription?: Maybe<Scalars["String"]>;
  button?: Maybe<EgdsOverlayButton>;
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  primarySubtitle?: Maybe<Scalars["String"]>;
  secondarySubtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type CruiseLinePolicies = {
  __typename?: "CruiseLinePolicies";
  policies: Array<CruiseLinePolicy>;
  title: Scalars["String"];
};

export type CruiseLinePolicy = {
  __typename?: "CruiseLinePolicy";
  clientSideAnalytics: ClientSideAnalytics;
  description: Scalars["String"];
  icon?: Maybe<Icon>;
  link?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export interface CruiseLinePolicyInput {
  link?: InputMaybe<Scalars["String"]>;
}

export type CruiseLinePolicyOutput = {
  __typename?: "CruiseLinePolicyOutput";
  link?: Maybe<Scalars["String"]>;
};

export type CruiseMedia = {
  __typename?: "CruiseMedia";
  alt?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  src?: Maybe<Scalars["String"]>;
  type?: Maybe<MediaType>;
};

export type CruiseMediaItem = MediaItem & {
  __typename?: "CruiseMediaItem";
  alt?: Maybe<Scalars["String"]>;
  media: Media;
};

export type CruiseMessagingCard = {
  __typename?: "CruiseMessagingCard";
  icon: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<CruisePhoneNumber>;
  requestConsultation: CruiseRequestConsultation;
  title: Scalars["String"];
};

export type CruiseNavigationBar = {
  __typename?: "CruiseNavigationBar";
  primaryButton: UiPrimaryButton;
  tabs?: Maybe<Array<SailingNavigationBarTab>>;
};

export type CruiseOffersResults = {
  __typename?: "CruiseOffersResults";
  amenities: CruiseAmenities;
  cabinExperience?: Maybe<CruiseCabinExperience>;
  cruiseLinePolicies?: Maybe<CruiseLinePolicies>;
  details: CruiseSailingDetails;
  /** @deprecated Use galleryMediaItem instead. */
  gallery: CruiseGallery;
  galleryMediaItem: CruiseGallery;
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  itinerary: CruiseItinerary;
  memberSignInBanner?: Maybe<EgdsStandardMessagingCard>;
  messagingCard: CruiseMessagingCard;
  messagingCardStandard: EgdsStandardMessagingCard;
  navigationBar?: Maybe<CruiseNavigationBar>;
  sailingSearchLink: CruiseSailingSearchLink;
  termsAndConditions: CruiseSubsection;
};

export type CruiseOrderSummaryDeckplan = {
  __typename?: "CruiseOrderSummaryDeckplan";
  a11yLabel?: Maybe<Scalars["String"]>;
  checkoutUrl: Scalars["String"];
  guaranteedCabin?: Maybe<CruiseOrderSummaryDeckplanGuaranteedCabin>;
  isCategoryGuaranteed: Scalars["Boolean"];
  messaging: CruiseOrderSummaryDeckplanMessaging;
  roomLocationSidesheet?: Maybe<CruiseOrderSummaryDeckplanSidesheet>;
};

export type CruiseOrderSummaryDeckplanDialog = {
  __typename?: "CruiseOrderSummaryDeckplanDialog";
  buttons: Array<EgdsButton>;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseOrderSummaryDeckplanGuaranteedCabin = {
  __typename?: "CruiseOrderSummaryDeckplanGuaranteedCabin";
  dialog?: Maybe<CruiseOrderSummaryDeckplanDialog>;
  messagingCard: EgdsStandardMessagingCard;
  value: Scalars["String"];
};

export type CruiseOrderSummaryDeckplanImage = {
  __typename?: "CruiseOrderSummaryDeckplanImage";
  id: Scalars["String"];
  image: CruiseMediaItem;
};

export type CruiseOrderSummaryDeckplanMessaging = {
  __typename?: "CruiseOrderSummaryDeckplanMessaging";
  accessibility?: Maybe<Scalars["String"]>;
  accessibilityTemplate?: Maybe<EgdsLocalizedText>;
  headingTemplate?: Maybe<EgdsLocalizedText>;
  messagingCard: EgdsStandardMessagingCard;
};

export type CruiseOrderSummaryDeckplanRoomsExpando = {
  __typename?: "CruiseOrderSummaryDeckplanRoomsExpando";
  collapseLabel: Scalars["String"];
  expandLabel: Scalars["String"];
  isVisible: Scalars["Boolean"];
  items: Scalars["Int"];
};

export type CruiseOrderSummaryDeckplanSidesheet = {
  __typename?: "CruiseOrderSummaryDeckplanSidesheet";
  backText: Scalars["String"];
  closeIconLabel: Scalars["String"];
  deckLayout: Array<CruiseOrderSummaryDeckplanImage>;
  decks: CruiseDeckSelect;
  frontText: Scalars["String"];
  roomAnalytics: ClientSideAnalytics;
  rooms: Array<CruiseSelect>;
  roomsExpando: CruiseOrderSummaryDeckplanRoomsExpando;
  saveButton: UiPrimaryButton;
  title: Scalars["String"];
};

export type CruiseOrderSummaryOfflineRate = {
  __typename?: "CruiseOrderSummaryOfflineRate";
  descriptionForDesktopView: Scalars["String"];
  descriptionForMobileView: Scalars["String"];
  phoneNumber?: Maybe<CruisePhoneNumber>;
  promotions?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type CruiseOrderSummaryPriceDetails = {
  __typename?: "CruiseOrderSummaryPriceDetails";
  closeIcon: Icon;
  depositMessage?: Maybe<Scalars["String"]>;
  membersSaveMessage?: Maybe<CruiseInfo>;
  passengerFareDetails: Array<PassengerFareDetails>;
  reducedDepositDetails?: Maybe<ReducedDepositDetails>;
  reserveButton: UiPrimaryButton;
  title: Scalars["String"];
  totalPrice: Scalars["String"];
  totalPriceLabel: Scalars["String"];
};

export type CruiseOrderSummaryPriceSummary = {
  __typename?: "CruiseOrderSummaryPriceSummary";
  a11yLabel?: Maybe<Scalars["String"]>;
  priceDetails: CruiseOrderSummaryPriceDetails;
  stickyBottom: StickyBottom;
};

export type CruiseOrderSummaryPromotionDetails = {
  __typename?: "CruiseOrderSummaryPromotionDetails";
  amenityInfo: CruiseAmenityInfo;
  cancellationPolicy?: Maybe<CruiseLinePolicy>;
  /** @deprecated This field is deprecated. Use promotionInstruction instead */
  cruiseOrderSummaryPromotionInstruction?: Maybe<CruiseOrderSummaryPromotionInstruction>;
  /** @deprecated This field is deprecated. Use promotionPolicy instead */
  cruiseOrderSummaryPromotionPolicy?: Maybe<CruiseOrderSummaryPromotionPolicy>;
  promotionInstruction?: Maybe<CruiseOrderSummaryPromotionInstruction>;
  promotionPolicy?: Maybe<CruiseOrderSummaryPromotionPolicy>;
};

export interface CruiseOrderSummaryPromotionDetailsInput {
  amenityInfo: CruiseAmenityInfoInput;
  cancellationPolicy?: InputMaybe<CruiseLinePolicyInput>;
  cruiseOrderSummaryPromotionInstruction?: InputMaybe<CruiseOrderSummaryPromotionInstructionInput>;
  cruiseOrderSummaryPromotionPolicy?: InputMaybe<CruiseOrderSummaryPromotionPolicyInput>;
}

export type CruiseOrderSummaryPromotionDetailsOutput = {
  __typename?: "CruiseOrderSummaryPromotionDetailsOutput";
  amenityInfo: CruiseAmenityInfoOutput;
  cancellationPolicy?: Maybe<CruiseLinePolicyOutput>;
  cruiseOrderSummaryPromotionInstruction?: Maybe<CruiseOrderSummaryPromotionInstructionOutput>;
  cruiseOrderSummaryPromotionPolicy?: Maybe<CruiseOrderSummaryPromotionPolicyOutput>;
};

export type CruiseOrderSummaryPromotionExpando = {
  __typename?: "CruiseOrderSummaryPromotionExpando";
  analytics: ClientSideAnalytics;
  combinedAmenities?: Maybe<Array<CruiseAmenitiesList>>;
  title: Scalars["String"];
};

export interface CruiseOrderSummaryPromotionExpandoInput {
  combinedAmenities?: InputMaybe<Array<CruiseAmenitiesListInput>>;
}

export type CruiseOrderSummaryPromotionExpandoOutput = {
  __typename?: "CruiseOrderSummaryPromotionExpandoOutput";
  combinedAmenities?: Maybe<Array<CruiseAmenitiesListOutput>>;
};

export type CruiseOrderSummaryPromotionInstruction = {
  __typename?: "CruiseOrderSummaryPromotionInstruction";
  promotionCode: Scalars["String"];
  promotionName?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  title: Scalars["String"];
};

export interface CruiseOrderSummaryPromotionInstructionInput {
  promotionCode: Scalars["String"];
  promotionName?: InputMaybe<Scalars["String"]>;
  text: Scalars["String"];
  title: Scalars["String"];
}

export type CruiseOrderSummaryPromotionInstructionOutput = {
  __typename?: "CruiseOrderSummaryPromotionInstructionOutput";
  promotionCode: Scalars["String"];
  promotionName?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseOrderSummaryPromotionPolicy = {
  __typename?: "CruiseOrderSummaryPromotionPolicy";
  text: Scalars["String"];
  title: Scalars["String"];
};

export interface CruiseOrderSummaryPromotionPolicyInput {
  text: Scalars["String"];
  title: Scalars["String"];
}

export type CruiseOrderSummaryPromotionPolicyOutput = {
  __typename?: "CruiseOrderSummaryPromotionPolicyOutput";
  text: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseOrderSummaryPromotionSideSheet = {
  __typename?: "CruiseOrderSummaryPromotionSideSheet";
  closeIcon: CruiseOrderSummaryPromotionSideSheetCloseIcon;
  /** @deprecated This field is deprecated. Use promotionDetails instead */
  cruiseOrderSummaryPromotionDetails: Array<CruiseOrderSummaryPromotionDetails>;
  expando: CruiseOrderSummaryPromotionExpando;
  offlineSailingMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  price?: Maybe<CruisePrice>;
  promotionDetails: Array<CruiseOrderSummaryPromotionDetails>;
  termsAndConditions?: Maybe<CruiseOrderSummaryPromotionTermsAndConditions>;
  text?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type CruiseOrderSummaryPromotionSideSheetCloseIcon = {
  __typename?: "CruiseOrderSummaryPromotionSideSheetCloseIcon";
  analytics: ClientSideAnalytics;
  icon: Icon;
  iconLabel: Scalars["String"];
};

export interface CruiseOrderSummaryPromotionSideSheetInput {
  cruiseOrderSummaryPromotionDetails: Array<CruiseOrderSummaryPromotionDetailsInput>;
  expando: CruiseOrderSummaryPromotionExpandoInput;
  termsAndConditions?: InputMaybe<CruiseOrderSummaryPromotionTermsAndConditionsInput>;
  text?: InputMaybe<Array<Scalars["String"]>>;
}

export type CruiseOrderSummaryPromotionSideSheetOutput = {
  __typename?: "CruiseOrderSummaryPromotionSideSheetOutput";
  cruiseOrderSummaryPromotionDetails: Array<CruiseOrderSummaryPromotionDetailsOutput>;
  expando: CruiseOrderSummaryPromotionExpandoOutput;
  termsAndConditions?: Maybe<CruiseOrderSummaryPromotionTermsAndConditionsOutput>;
  text?: Maybe<Array<Scalars["String"]>>;
};

export type CruiseOrderSummaryPromotionTermsAndConditions = {
  __typename?: "CruiseOrderSummaryPromotionTermsAndConditions";
  accessibleText?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<UiLinkAction>;
  text?: Maybe<Scalars["String"]>;
};

export interface CruiseOrderSummaryPromotionTermsAndConditionsInput {
  link?: InputMaybe<CruiseOrderSummaryRatesUiLinkActionInfoInput>;
}

export type CruiseOrderSummaryPromotionTermsAndConditionsOutput = {
  __typename?: "CruiseOrderSummaryPromotionTermsAndConditionsOutput";
  link?: Maybe<CruiseOrderSummaryRatesUiLinkActionInfoOutput>;
};

export type CruiseOrderSummaryRatesInfo = {
  __typename?: "CruiseOrderSummaryRatesInfo";
  a11ySelectButtonText: Scalars["String"];
  bookableOnline: Scalars["Boolean"];
  offlineRateDetails?: Maybe<OfflineRateDetails>;
  price?: Maybe<CruisePrice>;
  promotionDetails?: Maybe<CruiseOrderSummaryPromotionSideSheet>;
  promotionDetailsAnalytics?: Maybe<ClientSideAnalytics>;
  promotionDetailsIcon: Icon;
  promotionDetailsText: Scalars["String"];
  promotions: CruiseAmenitiesList;
  rateInfo: CruiseOrderSummaryRatesInfoOutput;
  selectedIcon: Icon;
  updateInfo?: Maybe<CruiseOrderSummaryRatesUpdateInfo>;
};

export interface CruiseOrderSummaryRatesInfoInput {
  offerToken: Scalars["String"];
  price: CruiseOrderSummaryRatesPriceInput;
  promotionDetails?: InputMaybe<CruiseOrderSummaryPromotionSideSheetInput>;
  promotions: Array<CruiseOrderSummaryRatesPromotionInfoInput>;
  updateInfo?: InputMaybe<CruiseOrderSummaryRatesUpdateInfoInput>;
}

export type CruiseOrderSummaryRatesInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesInfoOutput";
  offerToken: Scalars["String"];
  price: CruiseOrderSummaryRatesPriceOutput;
  promotionDetails?: Maybe<CruiseOrderSummaryPromotionSideSheetOutput>;
  promotions: Array<CruiseOrderSummaryRatesPromotionInfoOutput>;
  updateInfo?: Maybe<CruiseOrderSummaryRatesUpdateInfoOutput>;
};

export interface CruiseOrderSummaryRatesInput {
  adultCount: Scalars["Int"];
  cabinExperience: Scalars["String"];
  cabinType: Scalars["String"];
  checkoutRedirectInput?: InputMaybe<CheckoutRedirectInput>;
  childAges?: InputMaybe<Array<CruiseChildInput>>;
  childCount: Scalars["Int"];
  leadInPrice?: InputMaybe<Scalars["Float"]>;
  sailingToken: Scalars["String"];
}

export type CruiseOrderSummaryRatesOverviewAmenitiesSidesheet = {
  __typename?: "CruiseOrderSummaryRatesOverviewAmenitiesSidesheet";
  closeButtonAnalytics: CruiseSidesheetAnalytics;
  combinedAmenities?: Maybe<Array<CruiseAmenitiesList>>;
  navIconLabel: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseOrderSummaryRatesOverviewSection = {
  __typename?: "CruiseOrderSummaryRatesOverviewSection";
  a11yLabel?: Maybe<Scalars["String"]>;
  amenitiesSidesheet: CruiseOrderSummaryRatesOverviewAmenitiesSidesheet;
  deckRange?: Maybe<CruiseInfo>;
  disclaimer?: Maybe<CruiseInfo>;
  galleryCarousel?: Maybe<CruiseGallery>;
  occupancyRange?: Maybe<CruiseInfo>;
  price?: Maybe<CruisePrice>;
  squareFootageRange?: Maybe<CruiseInfo>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  viewAmenitiesButtonAnalytics: CruiseSidesheetAnalytics;
  viewAmenitiesIcon: Icon;
  viewAmenitiesString: Scalars["String"];
};

export interface CruiseOrderSummaryRatesPageDataInput {
  ratesInfo: Array<CruiseOrderSummaryRatesInfoInput>;
  selectedRateIndex: Scalars["Int"];
}

export type CruiseOrderSummaryRatesPriceChangeMessage = {
  __typename?: "CruiseOrderSummaryRatesPriceChangeMessage";
  icon: Icon;
  message: Scalars["String"];
};

export interface CruiseOrderSummaryRatesPriceChangeMessageInput {
  price: Scalars["String"];
  type: Scalars["String"];
}

export type CruiseOrderSummaryRatesPriceChangeMessageOutput = {
  __typename?: "CruiseOrderSummaryRatesPriceChangeMessageOutput";
  price: Scalars["String"];
  type: Scalars["String"];
};

export interface CruiseOrderSummaryRatesPriceInput {
  baseFare?: InputMaybe<CurrencyTypeInput>;
  cabinCategoryTitle: Scalars["String"];
  cruiseLineCode?: InputMaybe<Scalars["String"]>;
  initialPrice?: InputMaybe<CurrencyAmountTypeInput>;
  isSoldOut: Scalars["Boolean"];
  memberPricing: MemberPricingInput;
  price?: InputMaybe<CurrencyAmountTypeInput>;
  rateCategory: RateCategoryInput;
  strikeoutPrice?: InputMaybe<CurrencyAmountTypeInput>;
}

export type CruiseOrderSummaryRatesPriceOutput = {
  __typename?: "CruiseOrderSummaryRatesPriceOutput";
  baseFare?: Maybe<CurrencyTypeOutput>;
  cabinCategoryTitle: Scalars["String"];
  cruiseLineCode?: Maybe<Scalars["String"]>;
  initialPrice?: Maybe<CurrencyAmountTypeOutput>;
  isSoldOut: Scalars["Boolean"];
  memberPricing: MemberPricing;
  price?: Maybe<CurrencyAmountTypeOutput>;
  rateCategory: RateCategory;
  strikeoutPrice?: Maybe<CurrencyAmountTypeOutput>;
};

export interface CruiseOrderSummaryRatesPromotionIconInfoInput {
  description: Scalars["String"];
  id: Scalars["String"];
  token: Scalars["String"];
}

export type CruiseOrderSummaryRatesPromotionIconInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesPromotionIconInfoOutput";
  description: Scalars["String"];
  id: Scalars["String"];
  token: Scalars["String"];
};

export interface CruiseOrderSummaryRatesPromotionInfoInput {
  fullName: Scalars["String"];
  icon: CruiseOrderSummaryRatesPromotionIconInfoInput;
}

export type CruiseOrderSummaryRatesPromotionInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesPromotionInfoOutput";
  fullName: Scalars["String"];
  icon: Icon;
};

export type CruiseOrderSummaryRatesResults = {
  __typename?: "CruiseOrderSummaryRatesResults";
  crumbs?: Maybe<Array<CruiseProgressIndicatorStep>>;
  deckPlan?: Maybe<CruiseOrderSummaryDeckplan>;
  details?: Maybe<CruiseSailingDetails>;
  dialogStaleSession?: Maybe<CruiseDialogStaleSession>;
  errorMessaging?: Maybe<CruiseRatesQueryErrorMessaging>;
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  offlineRates?: Maybe<CruiseOrderSummaryOfflineRate>;
  offlineSailingMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  overview?: Maybe<CruiseOrderSummaryRatesOverviewSection>;
  priceChangeMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  priceSummary?: Maybe<CruiseOrderSummaryPriceSummary>;
  rates?: Maybe<CruiseOrderSummaryRatesSection>;
  roomSoldOutMessaging?: Maybe<RoomSoldOutMessaging>;
};

export type CruiseOrderSummaryRatesSection = {
  __typename?: "CruiseOrderSummaryRatesSection";
  a11yTitle: Scalars["String"];
  description: Scalars["String"];
  rateAnalytics: ClientSideAnalytics;
  rates: Array<CruiseOrderSummaryRatesInfo>;
  title: Scalars["String"];
};

export interface CruiseOrderSummaryRatesUiLinkActionInfoInput {
  url: Scalars["String"];
}

export type CruiseOrderSummaryRatesUiLinkActionInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesUILinkActionInfoOutput";
  url: Scalars["String"];
};

export type CruiseOrderSummaryRatesUpdateDialog = {
  __typename?: "CruiseOrderSummaryRatesUpdateDialog";
  closeDialogText: Scalars["String"];
  description: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseOrderSummaryRatesUpdateInfo = {
  __typename?: "CruiseOrderSummaryRatesUpdateInfo";
  dialog?: Maybe<CruiseOrderSummaryRatesUpdateDialog>;
  priceChangeMessage?: Maybe<CruiseOrderSummaryRatesPriceChangeMessage>;
};

export interface CruiseOrderSummaryRatesUpdateInfoInput {
  priceChangeMessage?: InputMaybe<CruiseOrderSummaryRatesPriceChangeMessageInput>;
}

export type CruiseOrderSummaryRatesUpdateInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesUpdateInfoOutput";
  priceChangeMessage?: Maybe<CruiseOrderSummaryRatesPriceChangeMessageOutput>;
};

export type CruiseOrderSummaryRoomUnavailableErrorMessage = {
  __typename?: "CruiseOrderSummaryRoomUnavailableErrorMessage";
  icon: Icon;
  message: Scalars["String"];
};

export type CruiseOrderSummaryRoomUnavailableViewResults = {
  __typename?: "CruiseOrderSummaryRoomUnavailableViewResults";
  cabinCategorySection: CruiseCabinCategorySection;
  cabinExperience: CruiseCabinExperience;
  errorMessage: CruiseOrderSummaryRoomUnavailableErrorMessage;
  messagingCard: CruiseMessagingCard;
  sailingUnavailable?: Maybe<CruiseSearchAgainSection>;
  searchAgain: CruiseSearchAgainSection;
};

export type CruisePhoneNumber = {
  __typename?: "CruisePhoneNumber";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  formatted?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
};

export type CruisePopover = {
  __typename?: "CruisePopover";
  accessibility: Scalars["String"];
  close: Icon;
  icon: Icon;
  text: Scalars["String"];
  title: Scalars["String"];
  titleIcon: Icon;
};

export type CruisePrice = {
  __typename?: "CruisePrice";
  allInPriceText?: Maybe<Scalars["String"]>;
  badge?: Maybe<CruiseIcon>;
  badges?: Maybe<Array<CruiseIcon>>;
  dialog?: Maybe<CruiseDialog>;
  icon?: Maybe<CruiseIcon>;
  priceFormatted?: Maybe<Scalars["String"]>;
  priceText?: Maybe<Scalars["String"]>;
  secondaryText?: Maybe<Scalars["String"]>;
  strikeoutPriceFormatted?: Maybe<Scalars["String"]>;
  strikeoutPriceText?: Maybe<Scalars["String"]>;
  supportingText?: Maybe<SupportingText>;
  /** @deprecated This field is deprecated. Use supportingText.text instead */
  text?: Maybe<Scalars["String"]>;
};

export type CruiseProductGallery = {
  __typename?: "CruiseProductGallery";
  heading?: Maybe<Scalars["String"]>;
  nextButton?: Maybe<Scalars["String"]>;
  prevButton?: Maybe<Scalars["String"]>;
  thumbnailLeftRightClickAnalytics?: Maybe<UiAction>;
};

export type CruiseProgressChangeLink = {
  __typename?: "CruiseProgressChangeLink";
  a11yLabel: Scalars["String"];
  action: UiLinkAction;
  label: Scalars["String"];
};

export type CruiseProgressIndicatorStep = {
  __typename?: "CruiseProgressIndicatorStep";
  a11yLabel: Scalars["String"];
  changeSelection?: Maybe<CruiseProgressChangeLink>;
  currentStep: Scalars["Boolean"];
  label: Scalars["String"];
};

export type CruisePromotionsTermsAndConditionsResult = {
  __typename?: "CruisePromotionsTermsAndConditionsResult";
  termsAndConditions: CruiseAmenitiesSection;
};

export type CruiseRatesQueryErrorDialog = {
  __typename?: "CruiseRatesQueryErrorDialog";
  buttons: Array<EgdsButton>;
  description: Scalars["String"];
  searchAgainUrl: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseRatesQueryErrorMessaging = {
  __typename?: "CruiseRatesQueryErrorMessaging";
  dialog: CruiseRatesQueryErrorDialog;
};

export type CruiseRequestConsultation = {
  __typename?: "CruiseRequestConsultation";
  clientSideAnalytics: ClientSideAnalytics;
  link: Scalars["String"];
  openInNewTab: Scalars["Boolean"];
  text: Scalars["String"];
};

export interface CruiseRequestContextInput {
  cruiseFeatures?: InputMaybe<Array<CruiseFeature>>;
  defaultCurrency?: InputMaybe<Scalars["String"]>;
  franchiseModelKey?: InputMaybe<Scalars["String"]>;
  isAgent?: InputMaybe<Scalars["Boolean"]>;
}

export type CruiseRoomRateSelection = {
  __typename?: "CruiseRoomRateSelection";
  cabinExperienceDeepLinkValue: Scalars["String"];
  changeButtonAnalytics: ClientSideAnalytics;
  changeButtonText: Scalars["String"];
  crumbs: Array<CruiseProgressIndicatorStep>;
  /** @deprecated Use offlineMessagingCardStandard */
  offlineMessagingCard?: Maybe<CruiseMessagingCard>;
  offlineMessagingCardStandard?: Maybe<EgdsStandardMessagingCard>;
  termsAndConditions: CruiseSubsection;
  toolbar: CruiseRoomRateSelectionToolbar;
};

export type CruiseRoomRateSelectionToolbar = {
  __typename?: "CruiseRoomRateSelectionToolbar";
  closeButtonAnalytics: CruiseRoomRateSelectionToolbarAnalytics;
  navIconLabel: Scalars["String"];
  title: Scalars["String"];
};

export type CruiseRoomRateSelectionToolbarAnalytics = {
  __typename?: "CruiseRoomRateSelectionToolbarAnalytics";
  description: Scalars["String"];
  id: Scalars["String"];
};

export interface CruiseSailingCabinCategoriesInput {
  adultCount: Scalars["Int"];
  cabinExperience: Scalars["String"];
  childAges?: InputMaybe<Array<CruiseChildInput>>;
  childCount: Scalars["Int"];
}

export type CruiseSailingDetails = {
  __typename?: "CruiseSailingDetails";
  a11yDescription?: Maybe<Scalars["String"]>;
  a11yLabel?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  iconMedia?: Maybe<CruiseMediaItem>;
  infoSitePath?: Maybe<Scalars["String"]>;
  pageTitle?: Maybe<Scalars["String"]>;
  priceSummary: CruisePrice;
  primarySubsection?: Maybe<CruiseSubsection>;
  primarySubtitle?: Maybe<Scalars["String"]>;
  secondarySubsection?: Maybe<CruiseSubsection>;
  secondarySubtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type CruiseSailingSearchLink = {
  __typename?: "CruiseSailingSearchLink";
  defaultLink: UiLinkAction;
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type CruiseSearchAgainSection = {
  __typename?: "CruiseSearchAgainSection";
  description: Scalars["String"];
  icon?: Maybe<Icon>;
  searchAgainLink: UiLinkAction;
  searchAgainText: Scalars["String"];
  title: Scalars["String"];
};

export interface CruiseSearchCriteriaInput {
  primary: PrimaryCruiseCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type CruiseSearchFilters = {
  __typename?: "CruiseSearchFilters";
  filtersDialog: CruiseSearchFiltersDialog;
  portTypeaheadAdapterDetails: CruiseTypeaheadAdapterDetails;
  /** @deprecated Use universalSortAndFilter.clearAll.primary instead */
  removeAllFiltersText: Scalars["String"];
  shipTypeaheadAdapterDetails: CruiseTypeaheadAdapterDetails;
  universalSortAndFilter: ShoppingSortAndFilters;
};

export type CruiseSearchFiltersDialog = {
  __typename?: "CruiseSearchFiltersDialog";
  appliedFilterCount?: Maybe<Scalars["String"]>;
  clearButtonText: Scalars["String"];
  closeIconLabel: Scalars["String"];
  doneButtonText: Scalars["String"];
  filterButtonIcon: Icon;
  filterCountTheme: CruiseBadgeTheme;
  primary: Scalars["String"];
};

export interface CruiseSearchFiltersInput {
  cruiseLines?: InputMaybe<Scalars["String"]>;
  departureLocations?: InputMaybe<Scalars["String"]>;
  destinationNames?: InputMaybe<Scalars["String"]>;
  earliestDeptDate?: InputMaybe<Scalars["String"]>;
  filterType: CruiseFilterType;
  latestDeptDate?: InputMaybe<Scalars["String"]>;
  maxLength?: InputMaybe<Scalars["String"]>;
  memberOnlyOffer?: InputMaybe<Scalars["Boolean"]>;
  minLength?: InputMaybe<Scalars["String"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  sailingVarieties?: InputMaybe<Scalars["String"]>;
  ships?: InputMaybe<Scalars["String"]>;
  superCategoryCode?: InputMaybe<Scalars["String"]>;
}

export type CruiseSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "CruiseSearchForm";
    actionURL: Scalars["String"];
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    expertAdviceInfo?: Maybe<Scalars["String"]>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    typeaheadAdapterDetails?: Maybe<TypeaheadAdapterDetails>;
  };

export type CruiseSearchNavigation = {
  __typename?: "CruiseSearchNavigation";
  button: UiSecondaryButton;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export interface CruiseSearchOptionsInput {
  adultCount: Scalars["Int"];
  childCount: Scalars["Int"];
  limit: Scalars["Int"];
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
}

export type CruiseSearchResults = {
  __typename?: "CruiseSearchResults";
  filters: CruiseSearchFilters;
  sailingSearch: SailingSearchResults;
};

export type CruiseSearchResultsSailingSearchArgs = {
  cruiseContext: CruiseRequestContextInput;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sailingToken?: InputMaybe<Scalars["String"]>;
  signInLinkAppended?: InputMaybe<Scalars["Boolean"]>;
};

export type CruiseSelect = {
  __typename?: "CruiseSelect";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  options: Array<CruiseSelectOptions>;
};

export type CruiseSelectOptions = {
  __typename?: "CruiseSelectOptions";
  accessibility?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  primary: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  value: Scalars["String"];
};

export type CruiseSidesheetAnalytics = {
  __typename?: "CruiseSidesheetAnalytics";
  description: Scalars["String"];
  id: Scalars["String"];
};

export type CruiseSubsection = {
  __typename?: "CruiseSubsection";
  headingLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use headingLabel instead */
  label: Scalars["String"];
  message: Scalars["String"];
  secondaryMessage?: Maybe<Scalars["String"]>;
};

export type CruiseTypeaheadAdapterDetails = {
  __typename?: "CruiseTypeaheadAdapterDetails";
  adapterRequest?: Maybe<CruiseTypeaheadAdapterRequest>;
  domainId: Scalars["String"];
  essCategories?: Maybe<Array<EssCategory>>;
};

export type CruiseTypeaheadAdapterRequest = {
  __typename?: "CruiseTypeaheadAdapterRequest";
  filters?: Maybe<CruiseTypeaheadFilterContext>;
  includeMetadata?: Maybe<Array<Scalars["String"]>>;
};

export type CruiseTypeaheadFilterContext = {
  __typename?: "CruiseTypeaheadFilterContext";
  include?: Maybe<Array<FilterItem>>;
};

export type CruiseUserInterest = {
  __typename?: "CruiseUserInterest";
  text: Scalars["String"];
};

export type CruiseVideoButtonText = {
  __typename?: "CruiseVideoButtonText";
  pauseButton: Scalars["String"];
  playButton: Scalars["String"];
};

export type Currency = {
  __typename?: "Currency";
  code: Scalars["String"];
  name: Scalars["String"];
  symbol: Scalars["String"];
};

export interface CurrencyAmountTypeInput {
  amount?: InputMaybe<Scalars["Float"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}

export type CurrencyAmountTypeOutput = {
  __typename?: "CurrencyAmountTypeOutput";
  amount?: Maybe<Scalars["Float"]>;
  currencyCode?: Maybe<Scalars["String"]>;
};

export type CurrencySelector = LocalePreferenceSelector & {
  __typename?: "CurrencySelector";
  disabled: Scalars["Boolean"];
  heading: Scalars["String"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<CurrencySelectorOption>;
  queryParameterName: Scalars["String"];
  trackingEvent: Scalars["String"];
};

export type CurrencySelectorOption = LocaleSelectorOption & {
  __typename?: "CurrencySelectorOption";
  name: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
};

export interface CurrencyTypeInput {
  defaultCurrencyAmount?: InputMaybe<CurrencyAmountTypeInput>;
  supplyCurrencyAmount?: InputMaybe<CurrencyAmountTypeInput>;
}

export type CurrencyTypeOutput = {
  __typename?: "CurrencyTypeOutput";
  defaultCurrencyAmount?: Maybe<CurrencyAmountTypeOutput>;
  supplyCurrencyAmount?: Maybe<CurrencyAmountTypeOutput>;
};

export type CurrentBreadcrumb = BreadcrumbItem &
  TextBreadcrumb & {
    __typename?: "CurrentBreadcrumb";
    text: Scalars["String"];
    uiLinkAction?: Maybe<UiLinkAction>;
  };

export type CurrentTripIntent = {
  __typename?: "CurrentTripIntent";
  timeframe?: Maybe<TripIntentWhen>;
  where?: Maybe<TripIntentWhere>;
  who?: Maybe<TripIntentWho>;
  why: TripIntentWhy;
};

export type CustomUiComposableElement = UiSimpleText | UiTextList;

export type Customer = {
  __typename?: "Customer";
  currentTripIntent: CurrentTripIntent;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  findInboxNotificationsByUser: Array<CustomerNotification>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  findInlineNotificationByUser: CustomerNotification;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  findNotificationById: CustomerNotification;
  lodgingRebound?: Maybe<LodgingRebound>;
  mutateHistory?: Maybe<HistoryMutationResponse>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  notification: CustomerNotification;
  searchHistory?: Maybe<Array<History>>;
  searchHistoryGroups?: Maybe<Array<HistoryGroup>>;
};

export type CustomerFindInboxNotificationsByUserArgs = {
  expUserId: Scalars["String"];
};

export type CustomerFindInlineNotificationByUserArgs = {
  dismissed: Scalars["Boolean"];
  expUserId: Scalars["Int"];
  read: Scalars["Boolean"];
  seen: Scalars["Boolean"];
};

export type CustomerFindNotificationByIdArgs = {
  notificationId: Scalars["String"];
};

export type CustomerLodgingReboundArgs = {
  regionId?: InputMaybe<Scalars["String"]>;
};

export type CustomerMutateHistoryArgs = {
  hide?: InputMaybe<Scalars["Boolean"]>;
  historyTypes?: InputMaybe<Array<HistoryType>>;
  interactionId?: InputMaybe<Scalars["String"]>;
};

export type CustomerNotificationArgs = {
  funnelLocation: FunnelLocation;
  lineOfBusiness: ExpLineOfBusiness;
  notificationLocation: NotificationLocation;
  optionalContext?: InputMaybe<CustomerNotificationOptionalContextInput>;
};

export type CustomerSearchHistoryArgs = {
  filterCriteria?: InputMaybe<SearchHistoryFilterCriteriaInput>;
  includeBookedSearches?: InputMaybe<Scalars["Boolean"]>;
  numberOfResults?: InputMaybe<Scalars["Int"]>;
  types?: InputMaybe<Array<HistoryType>>;
};

export type CustomerSearchHistoryGroupsArgs = {
  groupingCriteria?: InputMaybe<SearchHistoryGroupingCriteriaInput>;
  includeBookedSearches?: InputMaybe<Scalars["Boolean"]>;
  types?: InputMaybe<Array<HistoryType>>;
};

export type CustomerAddress = {
  __typename?: "CustomerAddress";
  city?: Maybe<Scalars["String"]>;
  countryAlpha3Code?: Maybe<Scalars["String"]>;
  firstLine?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  secondLine?: Maybe<Scalars["String"]>;
};

export type CustomerContactInfo = {
  __typename?: "CustomerContactInfo";
  customerAddress?: Maybe<CustomerAddress>;
  emergencyContacts?: Maybe<CustomerEmergencyContacts>;
  phoneNumbers?: Maybe<CustomerPhoneNumbers>;
};

export type CustomerEmergencyContact = {
  __typename?: "CustomerEmergencyContact";
  emergencyContactName?: Maybe<CustomerName>;
  phoneNumber?: Maybe<CustomerPhoneNumber>;
};

export type CustomerEmergencyContacts = {
  __typename?: "CustomerEmergencyContacts";
  emergencyContact?: Maybe<Array<CustomerEmergencyContact>>;
};

export type CustomerGender = "FEMALE" | "MALE" | "UNKNOWN";

export type CustomerName = {
  __typename?: "CustomerName";
  first: Scalars["String"];
  last: Scalars["String"];
  middle?: Maybe<Scalars["String"]>;
};

export type CustomerNotification = {
  __typename?: "CustomerNotification";
  attributes?: Maybe<Array<CustomerNotificationAttribute>>;
  backgroundId?: Maybe<Scalars["String"]>;
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<CustomerNotificationPhrase>>;
  containerLink?: Maybe<CustomerNotificationContainerLink>;
  dismiss?: Maybe<CustomerNotificationDismiss>;
  displayEndDateTime?: Maybe<DateTime>;
  displayStartDateTime?: Maybe<DateTime>;
  form?: Maybe<CustomerNotificationForm>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<CustomerNotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  qrCode?: Maybe<Image>;
  revealActions?: Maybe<Array<CustomerNotificationAction>>;
  timeStamp?: Maybe<Scalars["String"]>;
  title?: Maybe<CustomerNotificationPhrase>;
  type: CustomerNotificationType;
};

export interface CustomerNotificationAccessibilityField {
  accessibilityLabel?: Maybe<Scalars["String"]>;
}

export type CustomerNotificationAction =
  | CustomerNotificationAnalytics
  | CustomerNotificationCookie
  | CustomerNotificationMarketing
  | CustomerNotificationModifiableAttributes
  | CustomerNotificationSpecifications;

export type CustomerNotificationAnalytics = {
  __typename?: "CustomerNotificationAnalytics";
  description?: Maybe<Scalars["String"]>;
  referrerId: Scalars["String"];
};

export type CustomerNotificationAttribute = {
  __typename?: "CustomerNotificationAttribute";
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type CustomerNotificationContainerLink = CustomerNotificationLink & {
  __typename?: "CustomerNotificationContainerLink";
  actions?: Maybe<Array<CustomerNotificationAction>>;
  id?: Maybe<Scalars["String"]>;
  target?: Maybe<CustomerNotificationLinkTarget>;
  url?: Maybe<Scalars["URL"]>;
};

export type CustomerNotificationCookie = {
  __typename?: "CustomerNotificationCookie";
  expires: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type CustomerNotificationDismiss = {
  __typename?: "CustomerNotificationDismiss";
  actions?: Maybe<Array<CustomerNotificationAction>>;
  icon: Icon;
};

export type CustomerNotificationForm = {
  __typename?: "CustomerNotificationForm";
  feedbackMessages?: Maybe<Array<CustomerNotificationMessage>>;
  sections: Array<CustomerNotificationFormSection>;
  title?: Maybe<Scalars["String"]>;
};

export type CustomerNotificationFormButtonType =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export interface CustomerNotificationFormField {
  label: Scalars["String"];
}

export type CustomerNotificationFormSection = {
  __typename?: "CustomerNotificationFormSection";
  fields: Array<CustomerNotificationFormField>;
  title?: Maybe<Scalars["String"]>;
};

export interface CustomerNotificationLink {
  actions?: Maybe<Array<CustomerNotificationAction>>;
  id?: Maybe<Scalars["String"]>;
  target?: Maybe<CustomerNotificationLinkTarget>;
  url?: Maybe<Scalars["URL"]>;
}

export type CustomerNotificationLinkTarget = "NEW_TAB" | "SAME_TAB";

export type CustomerNotificationLinkType =
  | "ANCHOR"
  | "APP_DOWNLOAD_LINK"
  | "BUTTON"
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export type CustomerNotificationMarketing = {
  __typename?: "CustomerNotificationMarketing";
  marketingCode: Scalars["String"];
  marketingCodeDetail: Scalars["String"];
};

export type CustomerNotificationMessage = {
  __typename?: "CustomerNotificationMessage";
  displayType: CustomerNotificationMessageDisplayType;
  level: CustomerNotificationMessageLevel;
  name: Scalars["String"];
  text: Scalars["String"];
  title: Scalars["String"];
};

export type CustomerNotificationMessageDisplayType = "FORM" | "PAGE";

export type CustomerNotificationMessageLevel = "ERROR" | "SUCCESS" | "WARNING";

export type CustomerNotificationMessageType = "INVALID_INPUT" | "SERVICE_ERROR" | "SUCCESS";

export type CustomerNotificationModifiableAttributes = {
  __typename?: "CustomerNotificationModifiableAttributes";
  dismiss?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  read?: Maybe<Scalars["Boolean"]>;
  seen?: Maybe<Scalars["Boolean"]>;
  /** @deprecated This field is now deprecated. */
  star?: Maybe<Scalars["Boolean"]>;
};

export interface CustomerNotificationModifiableAttributesInput {
  dismiss?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  read?: InputMaybe<Scalars["Boolean"]>;
  seen?: InputMaybe<Scalars["Boolean"]>;
  star?: InputMaybe<Scalars["Boolean"]>;
}

export interface CustomerNotificationOptionalContextInput {
  itineraryNumber?: InputMaybe<Scalars["String"]>;
  journeyCriterias?: InputMaybe<Array<CustomerNotificationOptionalContextJourneyCriteriaInput>>;
  referer?: InputMaybe<Scalars["URL"]>;
  regionId?: InputMaybe<Scalars["String"]>;
  regionIds?: InputMaybe<Array<Scalars["String"]>>;
  tripFolderId?: InputMaybe<Scalars["String"]>;
}

export interface CustomerNotificationOptionalContextJourneyCriteriaInput {
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<CustomerNotificationOptionalContextJourneyCriteriaLocationInput>;
  origin?: InputMaybe<CustomerNotificationOptionalContextJourneyCriteriaLocationInput>;
}

export interface CustomerNotificationOptionalContextJourneyCriteriaLocationInput {
  airportTLA?: InputMaybe<Scalars["String"]>;
  propertyId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
}

export type CustomerNotificationPhrase = CustomerNotificationAccessibilityField & {
  __typename?: "CustomerNotificationPhrase";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  completeText: Scalars["String"];
  items: Array<CustomerNotificationPhrasePart>;
};

export type CustomerNotificationPhraseLinkNode = CustomerNotificationLink &
  CustomerNotificationPhrasePart & {
    __typename?: "CustomerNotificationPhraseLinkNode";
    actions?: Maybe<Array<CustomerNotificationAction>>;
    icon?: Maybe<Icon>;
    id?: Maybe<Scalars["String"]>;
    linkStyle?: Maybe<CustomerNotificationPhraseLinkStyle>;
    styles: Array<CustomerNotificationPhrasePartStyle>;
    target?: Maybe<CustomerNotificationLinkTarget>;
    text: Scalars["String"];
    type: CustomerNotificationLinkType;
    url?: Maybe<Scalars["URL"]>;
  };

export type CustomerNotificationPhraseLinkStyle =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export interface CustomerNotificationPhrasePart {
  styles: Array<CustomerNotificationPhrasePartStyle>;
  text: Scalars["String"];
}

export type CustomerNotificationPhrasePartStyle = "EMPHASIS" | "IMPORTANT" | "STANDARD";

export type CustomerNotificationPhraseTextNode = CustomerNotificationPhrasePart & {
  __typename?: "CustomerNotificationPhraseTextNode";
  styles: Array<CustomerNotificationPhrasePartStyle>;
  text: Scalars["String"];
};

export type CustomerNotificationSelectionField = CustomerNotificationFormField & {
  __typename?: "CustomerNotificationSelectionField";
  id: Scalars["String"];
  label: Scalars["String"];
  options?: Maybe<Array<CustomerNotificationSelectionOption>>;
};

export type CustomerNotificationSelectionOption = {
  __typename?: "CustomerNotificationSelectionOption";
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  selected: Scalars["Boolean"];
  shortLabel?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  value: Scalars["String"];
};

export type CustomerNotificationSpecifications = {
  __typename?: "CustomerNotificationSpecifications";
  dismiss?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  read?: Maybe<Scalars["Boolean"]>;
  seen?: Maybe<Scalars["Boolean"]>;
};

export type CustomerNotificationSubmitButton = CustomerNotificationFormField & {
  __typename?: "CustomerNotificationSubmitButton";
  actions?: Maybe<Array<CustomerNotificationAction>>;
  buttonType?: Maybe<CustomerNotificationFormButtonType>;
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  label: Scalars["String"];
};

export type CustomerNotificationTextInputField = CustomerNotificationFormField & {
  __typename?: "CustomerNotificationTextInputField";
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  label: Scalars["String"];
  placeholder: Scalars["String"];
};

export type CustomerNotificationTextToDownloadAppResponse = {
  __typename?: "CustomerNotificationTextToDownloadAppResponse";
  invalidFields?: Maybe<Array<Scalars["String"]>>;
  messageBody?: Maybe<Scalars["String"]>;
  messageType: CustomerNotificationMessageType;
};

export type CustomerNotificationType =
  | "APP_DOWNLOAD"
  | "APP_HERO"
  | "CITI_COBRAND"
  | "CITI_COBRAND_VERTICAL"
  | "CLEANLINESS"
  | "GDPR"
  | "GENERIC"
  | "HEADER"
  | "INBOX"
  | "MAD_MARQUEE"
  | "NONE"
  | "OSANO"
  | "TAKEOVER"
  | "TRAVEL_ADVISORY";

export interface CustomerNotificationUserInput {
  inputs: Array<CustomerNotificationUserInputElementInput>;
}

export interface CustomerNotificationUserInputElementInput {
  type: Scalars["String"];
  value: Scalars["String"];
}

export type CustomerPersonalInfo = {
  __typename?: "CustomerPersonalInfo";
  dateOfBirth?: Maybe<Date>;
  gender?: Maybe<CustomerGender>;
  name?: Maybe<CustomerName>;
};

export type CustomerPhoneNumber = {
  __typename?: "CustomerPhoneNumber";
  countryCode?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type CustomerPhoneNumbers = {
  __typename?: "CustomerPhoneNumbers";
  alternates?: Maybe<Array<CustomerPhoneNumber>>;
  primary?: Maybe<CustomerPhoneNumber>;
};

export type CustomerProfile = {
  __typename?: "CustomerProfile";
  contactInfo?: Maybe<CustomerContactInfo>;
  personalInfo?: Maybe<CustomerPersonalInfo>;
};

export type DamageLiability = "COF" | "RDD";

export type DashboardType = "DEFERRED_PAYMENT" | "PERFORMANCE";

export type DataSourceType = "CART" | "CHECKOUT" | "ENTITY" | "OFFER" | "ORDER";

export type Date = {
  __typename?: "Date";
  day: Scalars["Int"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  formatted: Scalars["String"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  formattedDate: Scalars["String"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  fullDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Migrate to using platform formatters and return a localized string */
  isoFormat: Scalars["String"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  longDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Migrate to using platform formatters and return a localized string */
  mediumDateFormat?: Maybe<Scalars["String"]>;
  month: Scalars["Int"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  shortDateFormat?: Maybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type DateFormattedArgs = {
  format: Scalars["String"];
};

export type DateFormattedDateArgs = {
  format: Scalars["String"];
};

export interface DateInput {
  day: Scalars["Int"];
  month: Scalars["Int"];
  year: Scalars["Int"];
}

export type DateInputType = {
  __typename?: "DateInputType";
  day: Scalars["Int"];
  month: Scalars["Int"];
  year: Scalars["Int"];
};

export type DateOption = {
  __typename?: "DateOption";
  accessibilityHeading: Scalars["String"];
  accessibilityMessage: Scalars["String"];
  numDaysDifference: Scalars["String"];
  selectAction: FlightsAction;
  tripDates: Scalars["String"];
  tripOD: Scalars["String"];
};

export type DatePeriod = {
  __typename?: "DatePeriod";
  bound?: Maybe<ClosedPeriodBounding>;
  length: Scalars["Int"];
  unit: DatePeriodUnit;
};

export interface DatePeriodInput {
  bound?: InputMaybe<ClosedPeriodBounding>;
  length: Scalars["Int"];
  unit: DatePeriodUnit;
}

export type DatePeriodUnit = "DAY" | "MONTH" | "WEEK" | "WEEKEND";

export type DateRange = {
  __typename?: "DateRange";
  /** @deprecated Use platform formatters and return localized strings, or use java time class for internal logic */
  duration: Duration;
  end: Date;
  start: Date;
};

export type DateRangeDurationArgs = {
  chronoUnit: ChronoUnit;
};

export interface DateRangeInput {
  end: DateInput;
  start: DateInput;
}

export type DateTime = {
  __typename?: "DateTime";
  day: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  epochSeconds: Scalars["String"];
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"];
  /** @deprecated Use platform formatters and return localized strings instead */
  fullDateFormat?: Maybe<Scalars["String"]>;
  hour: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  longDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumTimeFormat?: Maybe<Scalars["String"]>;
  minute: Scalars["Int"];
  month: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["String"];
  second: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  shortDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  shortTimeFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use ZonedDateTime if you want time zone information, replace with ZonedDateTime */
  timeZoneOffsetSeconds: Scalars["Int"];
  year: Scalars["Int"];
};

export type DateTimeFormattedArgs = {
  format: Scalars["String"];
};

export interface DateTimeInput {
  day: Scalars["Int"];
  hour: Scalars["Int"];
  minute: Scalars["Int"];
  month: Scalars["Int"];
  second: Scalars["Int"];
  year: Scalars["Int"];
}

export type DateTimeRecommendationCard = CarRecommendationCard & {
  __typename?: "DateTimeRecommendationCard";
  action?: Maybe<CarAction>;
  icon?: Maybe<Icon>;
  subText?: Maybe<CarPhraseText>;
  text?: Maybe<CarsRichText>;
};

export type DateValidationResult = {
  __typename?: "DateValidationResult";
  dateAdjusted?: Maybe<Scalars["Boolean"]>;
  formattedEndDate?: Maybe<Scalars["String"]>;
  formattedStartDate?: Maybe<Scalars["String"]>;
  /** @deprecated Use formattedEndDate instead */
  searchedEndDate?: Maybe<DateTime>;
  /** @deprecated Use formattedStartDate instead */
  searchedStartDate?: Maybe<DateTime>;
};

export type DateValue = {
  __typename?: "DateValue";
  id: Scalars["String"];
  value: Date;
};

export interface DateValueInput {
  id: Scalars["String"];
  value: DateInput;
}

export type DatelessCheckAvailability = {
  __typename?: "DatelessCheckAvailability";
  checkAvailabilityButton: UiPrimaryButton;
};

export type DealType = "AARP" | "POINTS_PROMOTION";

export type DebugContext = {
  __typename?: "DebugContext";
  abacusOverrides: Array<Exposure>;
  alterMode?: Maybe<AlterMode>;
};

export interface DebugContextInput {
  abacusOverrides: Array<ExposureInput>;
  alterMode?: InputMaybe<AlterMode>;
}

export type DeeplinkType = "HSR" | "PDP";

export type DeferredPaymentReport = {
  __typename?: "DeferredPaymentReport";
  uri?: Maybe<HttpUri>;
};

export type DeprecatedCustomerContext = {
  __typename?: "DeprecatedCustomerContext";
  device: DeprecatedCustomerContextDevice;
  identity: DeprecatedCustomerContextIdentity;
  locale: Scalars["Locale"];
  siteId: Scalars["Int"];
};

export type DeprecatedCustomerContextDevice = {
  __typename?: "DeprecatedCustomerContextDevice";
  type: DeviceType;
};

export type DeprecatedCustomerContextIdentity = {
  __typename?: "DeprecatedCustomerContextIdentity";
  duaid: Scalars["UUID"];
};

export type DeprecatedMultiItemContext = {
  __typename?: "DeprecatedMultiItemContext";
  device: DeprecatedMultiItemContextDevice;
  identity: DeprecatedMultiItemContextIdentity;
  locale: Scalars["Locale"];
  siteId: Scalars["Int"];
};

export type DeprecatedMultiItemContextDevice = {
  __typename?: "DeprecatedMultiItemContextDevice";
  type: DeviceType;
};

export type DeprecatedMultiItemContextIdentity = {
  __typename?: "DeprecatedMultiItemContextIdentity";
  duaid: Scalars["UUID"];
};

export type DeprecatedPropertyContext = {
  __typename?: "DeprecatedPropertyContext";
  currency?: Maybe<Scalars["String"]>;
  debugContext?: Maybe<DeprecatedPropertyDebugContext>;
  device: DeprecatedPropertyMultiItemContextDevice;
  eapid?: Maybe<Scalars["Int"]>;
  identity: DeprecatedPropertyMultiItemContextIdentity;
  locale: Scalars["Locale"];
  siteId: Scalars["Int"];
  tpid?: Maybe<Scalars["Int"]>;
};

export type DeprecatedPropertyDebugContext = {
  __typename?: "DeprecatedPropertyDebugContext";
  abacusOverrides?: Maybe<Array<DeprecatedPropertyExposure>>;
  alterMode?: Maybe<AlterMode>;
};

export type DeprecatedPropertyExposure = {
  __typename?: "DeprecatedPropertyExposure";
  bucket: Scalars["Int"];
  id: Scalars["String"];
};

export type DeprecatedPropertyMultiItemContext = {
  __typename?: "DeprecatedPropertyMultiItemContext";
  id: Scalars["String"];
  packageType: PackageType;
};

export type DeprecatedPropertyMultiItemContextDevice = {
  __typename?: "DeprecatedPropertyMultiItemContextDevice";
  type: DeviceType;
};

export type DeprecatedPropertyMultiItemContextIdentity = {
  __typename?: "DeprecatedPropertyMultiItemContextIdentity";
  duaid: Scalars["UUID"];
};

export type DeprecatedShoppingContext = {
  __typename?: "DeprecatedShoppingContext";
  multiItem: DeprecatedPropertyMultiItemContext;
};

export type Destination = {
  __typename?: "Destination";
  coordinates?: Maybe<Coordinates>;
  excludedPropertyIds?: Maybe<Array<Scalars["String"]>>;
  mapBounds?: Maybe<Array<Coordinates>>;
  pinnedPropertyId?: Maybe<Scalars["String"]>;
  propertyIds?: Maybe<Array<Scalars["String"]>>;
  regionId?: Maybe<Scalars["String"]>;
  regionName?: Maybe<Scalars["String"]>;
};

export type DestinationAirport = LocAirport & {
  __typename?: "DestinationAirport";
  airportCode: Scalars["String"];
  airportName?: Maybe<Scalars["String"]>;
};

export type DestinationBadges = {
  __typename?: "DestinationBadges";
  accessibility: Scalars["String"];
  badges: Array<EgdsStandardBadge>;
};

export type DestinationBestTimeToGoInformation = {
  __typename?: "DestinationBestTimeToGoInformation";
  expando: DestinationBestTimeToGoInformationExpando;
  tabs: Array<DestinationBestTimeToGoInformationTab>;
};

export type DestinationBestTimeToGoInformationExpando = {
  __typename?: "DestinationBestTimeToGoInformationExpando";
  content: EgdsExpandoCard;
  graphic?: Maybe<UiGraphic>;
};

export type DestinationBestTimeToGoInformationTab = {
  __typename?: "DestinationBestTimeToGoInformationTab";
  impression?: Maybe<ClientSideAnalytics>;
  information: DestinationInformation;
  title: EgdsText;
};

export type DestinationButtonMapTrigger = {
  __typename?: "DestinationButtonMapTrigger";
  action: Scalars["String"];
  button: EgdsButton;
  mapTitle?: Maybe<Scalars["String"]>;
};

export type DestinationChatCarousel = {
  __typename?: "DestinationChatCarousel";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  carousel: EgdsCarousel;
  heading: EgdsHeading;
  subHeading: EgdsHeading;
};

export type DestinationCountry = {
  __typename?: "DestinationCountry";
  countryName?: Maybe<Scalars["String"]>;
  destinations: Array<DestinationAirport>;
};

export type DestinationExpertTips = {
  __typename?: "DestinationExpertTips";
  impression: ClientSideAnalytics;
  tips: Array<DestinationTips>;
  title: Scalars["String"];
};

export type DestinationGallery = {
  __typename?: "DestinationGallery";
  carousel: EgdsCarousel;
  items: Array<DestinationGalleryItem>;
};

export type DestinationGalleryItem = DestinationImageGalleryItem;

export type DestinationHeading = {
  __typename?: "DestinationHeading";
  description?: Maybe<Scalars["String"]>;
  destinationBadges: DestinationBadges;
  /** @deprecated Please use expandoPeek */
  expando?: Maybe<EgdsExpandoLink>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  gallery: DestinationGallery;
  impression: ClientSideAnalytics;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export interface DestinationHeadingCriteriaInput {
  imageLimit?: InputMaybe<Scalars["Int"]>;
  placeId: Scalars["String"];
  source?: InputMaybe<ContentSource>;
}

export type DestinationHeadingTip = {
  __typename?: "DestinationHeadingTip";
  darkImage: Image;
  impression: ClientSideAnalytics;
  lightImage: Image;
  text: EgdsSpannableText;
};

export type DestinationHeroMedia = {
  __typename?: "DestinationHeroMedia";
  media: DestinationMedia;
  title: EgdsText;
};

export interface DestinationHeroMediaCriteriaInput {
  affinityURNs?: InputMaybe<Array<Scalars["String"]>>;
  destinationId: Scalars["String"];
}

export interface DestinationImageDimensionsInput {
  height: Scalars["Int"];
  width: Scalars["Int"];
}

export type DestinationImageGalleryItem = {
  __typename?: "DestinationImageGalleryItem";
  attribution?: Maybe<EgdsSpannableText>;
  image: Image;
  impression: ClientSideAnalytics;
};

export type DestinationInformation = DestinationInsightsMonthlyDetails | DestinationMonthlyHistoricalForecast;

export interface DestinationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  excludedPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
  mapBounds?: InputMaybe<Array<CoordinatesInput>>;
  pinnedPropertyId?: InputMaybe<Scalars["String"]>;
  propertyIds?: InputMaybe<Array<Scalars["String"]>>;
  regionId?: InputMaybe<Scalars["String"]>;
  regionName?: InputMaybe<Scalars["String"]>;
}

export type DestinationInsights = {
  __typename?: "DestinationInsights";
  localGetaways: Array<InsightsDestination>;
  lodgingCost: Array<CostPercentilesByMonth>;
  popularMonthsToVisit: Array<Month>;
  travelGuide?: Maybe<TravelGuide>;
};

export type DestinationInsightsLocalGetawaysArgs = {
  maxResults?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<MonthId>;
};

export type DestinationInsightsLodgingCostArgs = {
  lengthOfStay?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<MonthId>;
  starRating?: InputMaybe<Scalars["Int"]>;
};

export type DestinationInsightsMonthlyData = {
  __typename?: "DestinationInsightsMonthlyData";
  accessibility?: Maybe<Scalars["String"]>;
  averagePrice: DestinationInsightsPrice;
  click: ClientSideAnalytics;
  label: Scalars["String"];
  monthName: Scalars["String"];
  selected: Scalars["Boolean"];
};

export type DestinationInsightsMonthlyDetails = {
  __typename?: "DestinationInsightsMonthlyDetails";
  accessibility?: Maybe<Scalars["String"]>;
  monthlyData: Array<DestinationInsightsMonthlyData>;
  subtitle: Scalars["String"];
  title: Scalars["String"];
  tooltip: DestinationInsightsTooltip;
};

export type DestinationInsightsPrice = {
  __typename?: "DestinationInsightsPrice";
  currency: Scalars["String"];
  priceAsNumber: Scalars["Int"];
  priceAsText: Scalars["String"];
};

export type DestinationInsightsPriceRange = {
  __typename?: "DestinationInsightsPriceRange";
  higherBound: DestinationInsightsPrice;
  lowerBound: DestinationInsightsPrice;
};

export type DestinationInsightsTooltip = {
  __typename?: "DestinationInsightsTooltip";
  button: EgdsButton;
  closeIcon: Icon;
  content: EgdsSpannableText;
  tooltipSheet: EgdsSheet;
};

export type DestinationListContentEventAttributes = {
  __typename?: "DestinationListContentEventAttributes";
  destinationGeoId: Scalars["String"];
};

export type DestinationMap = {
  __typename?: "DestinationMap";
  backButton: EgdsButton;
  egdsSheet: EgdsSheet;
  map: EgdsBasicMap;
};

export type DestinationMedia = {
  __typename?: "DestinationMedia";
  attribution?: Maybe<EgdsSpannableText>;
  media: Media;
};

export type DestinationMonthlyHistoricalForecast = {
  __typename?: "DestinationMonthlyHistoricalForecast";
  actions: Array<EgdsStandardMessagingCard>;
  description: Scalars["String"];
  monthsForecast: MonthsForecastsTabs;
};

export interface DestinationNeighborhoodsContextInput {
  userLocation?: InputMaybe<CoordinatesInput>;
}

export type DestinationOfferItem = OfferItem & {
  __typename?: "DestinationOfferItem";
  affinities?: Maybe<Array<Scalars["String"]>>;
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  flightOffer?: Maybe<OfferData>;
  image: Image;
  lodgingOffer?: Maybe<OfferData>;
  name: Scalars["String"];
  packageOffer?: Maybe<OfferData>;
  propertyCount?: Maybe<Scalars["String"]>;
  secondaryContent?: Maybe<Scalars["String"]>;
};

export type DestinationPriceCard = {
  __typename?: "DestinationPriceCard";
  actionAccessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  badge?: Maybe<EgdsStandardBadge>;
  body: EgdsText;
  description: Scalars["String"];
  header: EgdsText;
  interaction: ClientSideAnalytics;
};

export type DestinationPriceInsightModule = {
  __typename?: "DestinationPriceInsightModule";
  actionSection?: Maybe<EgdsStandardMessagingCard>;
  legalSheet?: Maybe<EgdsSheet>;
  priceSection?: Maybe<DestinationPriceCard>;
};

export type DestinationPriceInsights = {
  __typename?: "DestinationPriceInsights";
  currentMonthAverage: DestinationInsightsPrice;
  expando: EgdsExpandoPeek;
  expandoIcon?: Maybe<Icon>;
  /** @deprecated Please use expando */
  expandoPeek: EgdsExpandoCard;
  impression: ClientSideAnalytics;
  monthlyDetails: DestinationInsightsMonthlyDetails;
  subtitle: EgdsSpannableText;
  superTitle?: Maybe<EgdsSpannableText>;
  title: EgdsSpannableText;
  typicalRange: DestinationInsightsPriceRange;
};

export type DestinationRecommendationCard = {
  __typename?: "DestinationRecommendationCard";
  action: UiLinkAction;
  actionCard?: Maybe<EgdsStandardMessagingCard>;
  affinities?: Maybe<DestinationBadges>;
  analytics: ClientSideAnalytics;
  button?: Maybe<DestinationButtonMapTrigger>;
  details: Array<DestinationRecommendationCardContent>;
  heading?: Maybe<DestinationRecommendationHeading>;
  media: DestinationRecommendationCardMedia;
};

export type DestinationRecommendationCardContent = {
  __typename?: "DestinationRecommendationCardContent";
  button?: Maybe<EgdsButton>;
  content?: Maybe<DestinationRecommendationDetailContent>;
  heading?: Maybe<DestinationRecommendationHeading>;
  link?: Maybe<EgdsStandardLink>;
};

export type DestinationRecommendationCardImage = {
  __typename?: "DestinationRecommendationCardImage";
  attribution?: Maybe<EgdsSpannableText>;
  image?: Maybe<Image>;
  impression: ClientSideAnalytics;
};

export type DestinationRecommendationCardImages = {
  __typename?: "DestinationRecommendationCardImages";
  images: Array<DestinationRecommendationCardImage>;
};

export type DestinationRecommendationCardMedia =
  | DestinationRecommendationCardImage
  | DestinationRecommendationCardImages;

export interface DestinationRecommendationCriteriaInput {
  maxCards?: InputMaybe<Scalars["Int"]>;
  minCards?: InputMaybe<Scalars["Int"]>;
  referencePlaceId: Scalars["String"];
  strategy: DestinationRecommendationStrategy;
  type: DestinationRecommendationPlaceType;
}

export type DestinationRecommendationDetailContent = EgdsParagraph | EgdsTextIconList;

export type DestinationRecommendationGroup = {
  __typename?: "DestinationRecommendationGroup";
  backgroundImage?: Maybe<Image>;
  badge?: Maybe<EgdsStandardBadge>;
  bottomLink?: Maybe<EgdsStandardLink>;
  button?: Maybe<UiPrimaryButton>;
  cards: Array<DestinationRecommendationCard>;
  carousel: EgdsCarousel;
  heading: DestinationRecommendationHeading;
  impression: ClientSideAnalytics;
  interaction: ClientSideAnalytics;
  map?: Maybe<DestinationMap>;
};

export type DestinationRecommendationGroups = {
  __typename?: "DestinationRecommendationGroups";
  groups: Array<DestinationRecommendationGroup>;
  heading?: Maybe<DestinationRecommendationHeading>;
  impression: ClientSideAnalytics;
  interaction: ClientSideAnalytics;
};

export type DestinationRecommendationHeading = {
  __typename?: "DestinationRecommendationHeading";
  subTitle?: Maybe<Scalars["String"]>;
  superTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type DestinationRecommendationPlaceType = "CITY" | "NEIGHBORHOOD" | "POI";

export type DestinationRecommendationStrategy = "NEARBY" | "SIMILAR" | "TOP";

export type DestinationTemperature = {
  __typename?: "DestinationTemperature";
  accessibility?: Maybe<Scalars["String"]>;
  interaction: ClientSideAnalytics;
  max: Scalars["Int"];
  maxTempText: Scalars["String"];
  min: Scalars["Int"];
  minTempText: Scalars["String"];
  weatherIcon: Icon;
};

export type DestinationTip = {
  __typename?: "DestinationTip";
  description: EgdsSpannableText;
  icon: Icon;
};

export type DestinationTipItem = {
  __typename?: "DestinationTipItem";
  destinationTips: Array<DestinationTip>;
  name: Scalars["String"];
};

export type DestinationTips = {
  __typename?: "DestinationTips";
  category: Scalars["String"];
  interaction: ClientSideAnalytics;
  items: Array<DestinationTipItem>;
};

export type DestinationTravelGuidePolicy = "PERSONALIZED" | "USER_LOCATION";

export type DetailsMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "DetailsMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type DetailsNavTabs = {
  __typename?: "DetailsNavTabs";
  navTabs: Array<NavTab>;
};

export type Device = {
  __typename?: "Device";
  type: DeviceType;
};

export type DeviceContext = {
  __typename?: "DeviceContext";
  brandName?: Maybe<Scalars["String"]>;
  deviceOS?: Maybe<Scalars["String"]>;
  deviceOSVersion?: Maybe<Scalars["String"]>;
  isMobile?: Maybe<Scalars["Boolean"]>;
  isTablet?: Maybe<Scalars["Boolean"]>;
  isWirelessDevice?: Maybe<Scalars["Boolean"]>;
  mobileBrowser?: Maybe<Scalars["String"]>;
  modelName?: Maybe<Scalars["String"]>;
  physicalScreenHeight?: Maybe<Scalars["Int"]>;
  physicalScreenWidth?: Maybe<Scalars["Int"]>;
  resolutionHeight?: Maybe<Scalars["Int"]>;
  resolutionWidth?: Maybe<Scalars["Int"]>;
};

export interface DeviceInput {
  type: DeviceType;
}

export type DeviceType =
  | "APP_PHONE"
  | "APP_TABLET"
  | "CHAT_APP"
  | "DESKTOP"
  | "MOBILE_PHONE"
  | "MOBILE_TABLET"
  | "VOICE_APP";

export type DialogAction = {
  __typename?: "DialogAction";
  icon?: Maybe<Icon>;
  linkUrl: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  referrerId?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type DialogBlock = {
  __typename?: "DialogBlock";
  image: Image;
  text?: Maybe<Scalars["String"]>;
};

export type DialogButtonActionType =
  | "CLOSE_DIALOG"
  | "CREATE_TRACKING_MUTATION"
  | "DISABLE_TRACKING_MUTATION"
  | "EMAIL_OPT_OUT"
  | "UPDATE_TRACKING_MUTATION";

export type DialogContent = {
  __typename?: "DialogContent";
  content?: Maybe<Array<Scalars["String"]>>;
  title?: Maybe<LodgingHeader>;
};

export interface DialogContentEntry {
  entryContent: Array<Scalars["String"]>;
  entryTitle: LodgingHeader;
}

export type DialogElementType = "CLEAR_RATES" | "DELETE_QUOTES";

export type Dimensions = {
  __typename?: "Dimensions";
  density: Scalars["Float"];
  height: Scalars["Float"];
  width: Scalars["Float"];
};

export interface DimensionsInput {
  density: Scalars["Float"];
  height: Scalars["Float"];
  width: Scalars["Float"];
}

export type DirectFeedback = {
  __typename?: "DirectFeedback";
  callToAction: DirectFeedbackCallToAction;
  /** @deprecated Include promptId as part of the callToAction field instead */
  promptId: Scalars["ID"];
};

export type DirectFeedbackCallToAction = {
  __typename?: "DirectFeedbackCallToAction";
  callToAction: DirectFeedbackCallToActionElement;
  contextValues: Array<GraphQlPair>;
  eager: Scalars["Boolean"];
  postSubmitDisplay?: Maybe<DirectFeedbackPostSubmitElement>;
  promptId: Scalars["String"];
};

export type DirectFeedbackCallToActionElement =
  | EgdsImageLink
  | EgdsInlineLink
  | EgdsStandardLink
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type DirectFeedbackCheckbox = EgdsCheckBox & {
  __typename?: "DirectFeedbackCheckbox";
  analytics: ClientSideAnalytics;
  description?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
  label: EgdsText;
  name?: Maybe<Scalars["String"]>;
  required: Scalars["Boolean"];
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackFieldError = {
  __typename?: "DirectFeedbackFieldError";
  error: Scalars["String"];
  fieldId?: Maybe<Scalars["ID"]>;
};

export type DirectFeedbackFieldSet = {
  __typename?: "DirectFeedbackFieldSet";
  elements: Array<DirectFeedbackFieldSetElement>;
  label?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackFieldSetElement =
  | DirectFeedbackInputField
  | DirectFeedbackRadioGroup
  | DirectFeedbackTextArea
  | EgdsHeading
  | EgdsPlainText
  | EgdsSpannableText;

export type DirectFeedbackFormDisplayElement =
  | DirectFeedbackTextList
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsPlainText
  | EgdsSpannableText;

export type DirectFeedbackFormPage = {
  __typename?: "DirectFeedbackFormPage";
  commands?: Maybe<Array<EgdsButton>>;
  /** @deprecated Please use displayConditions instead. */
  displayCondition?: Maybe<DirectFeedbackPageDisplayCondition>;
  displayConditions: Array<DirectFeedbackPageDisplayCondition>;
  elements: Array<DirectFeedbackFormPageElement>;
  footer?: Maybe<Array<DirectFeedbackFormDisplayElement>>;
  id?: Maybe<Scalars["String"]>;
  /** @deprecated Please use commands instead. */
  submitButton: UiPrimaryButton;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackFormPageElement =
  | DirectFeedbackFieldSet
  | DirectFeedbackInputField
  | DirectFeedbackMultiSelectGroup
  | DirectFeedbackRadioGroup
  | DirectFeedbackTextArea
  | DirectFeedbackTextList
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsPlainText
  | EgdsSpannableText;

export type DirectFeedbackFormValidation = {
  __typename?: "DirectFeedbackFormValidation";
  errors: Array<DirectFeedbackFieldError>;
  serviceError?: Maybe<EgdsErrorSummary>;
};

export type DirectFeedbackInputField = EgdsInputField & {
  __typename?: "DirectFeedbackInputField";
  analytics: ClientSideAnalytics;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  name?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackLink = {
  __typename?: "DirectFeedbackLink";
  feedbackFormAction: DirectFeedbackCallToAction;
};

export type DirectFeedbackListing = {
  __typename?: "DirectFeedbackListing";
  callToAction: DirectFeedbackCallToAction;
};

export type DirectFeedbackMultiSelectGroup = {
  __typename?: "DirectFeedbackMultiSelectGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  legend: Scalars["String"];
  options: Array<EgdsCheckBox>;
  required?: Maybe<Scalars["Boolean"]>;
  selected?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackOpenFormAction = UiAction & {
  __typename?: "DirectFeedbackOpenFormAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  promptId?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackOpenNextPageAction = UiAction & {
  __typename?: "DirectFeedbackOpenNextPageAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  destinationId?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackOpenPreviousPageAction = UiAction & {
  __typename?: "DirectFeedbackOpenPreviousPageAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type DirectFeedbackPageDisplayCondition = {
  __typename?: "DirectFeedbackPageDisplayCondition";
  conditionType: DirectFeedbackPageDisplayConditionType;
  expectedValue: Scalars["String"];
  fieldId: Scalars["ID"];
};

export type DirectFeedbackPageDisplayConditionType = "BOOL" | "NONE" | "PATTERN" | "TEXT";

export type DirectFeedbackPostSubmitElement =
  | EgdsImageLink
  | EgdsInlineLink
  | EgdsSpannableText
  | EgdsStandardLink
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type DirectFeedbackPrompt = {
  __typename?: "DirectFeedbackPrompt";
  /** @deprecated Client spinners should implement this field, using the DirectFeedbackCallToAction found in bex-api-shared-types */
  callToAction?: Maybe<UiTertiaryButton>;
  challenges: Array<AtoChallenge>;
  /** @deprecated Client spinners should implement this field, using the DirectFeedbackCallToAction found in bex-api-shared-types */
  decoratedCallToAction?: Maybe<DirectFeedbackCallToAction>;
  id: Scalars["ID"];
  pages: Array<DirectFeedbackFormPage>;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackRadioButton = EgdsRadioButton & {
  __typename?: "DirectFeedbackRadioButton";
  analytics: ClientSideAnalytics;
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  label: Scalars["String"];
  labelSuffix?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type DirectFeedbackRadioGroup = EgdsRadioGroup & {
  __typename?: "DirectFeedbackRadioGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  legend: Scalars["String"];
  options: Array<EgdsRadioButton>;
  required?: Maybe<Scalars["Boolean"]>;
  selected?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackSubmitFormAction = UiAction & {
  __typename?: "DirectFeedbackSubmitFormAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type DirectFeedbackTextArea = EgdsInputField & {
  __typename?: "DirectFeedbackTextArea";
  analytics: ClientSideAnalytics;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  isEditable: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  maxRows?: Maybe<Scalars["Int"]>;
  minRows?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export type DirectFeedbackTextList = {
  __typename?: "DirectFeedbackTextList";
  bullet: BulletStyle;
  listItems: Array<DirectFeedbackTextListItem>;
};

export type DirectFeedbackTextListItem = {
  __typename?: "DirectFeedbackTextListItem";
  egdsElementId: Scalars["String"];
  icon?: Maybe<Icon>;
  style?: Maybe<EgdsTextListItemStyle>;
  text: EgdsText;
};

export interface DirectFeedbackValuesInput {
  atoShieldValidation?: InputMaybe<AtoChallengeValidationInput>;
  context: Array<GraphQlPairInput>;
  formValues: Array<GraphQlPairInput>;
}

export type DirectionsPath = {
  __typename?: "DirectionsPath";
  path: Scalars["String"];
  pathType: EgdsPathType;
};

export interface DirectionsReferenceInput {
  id: Scalars["String"];
  type: EgdsMapFeatureType;
}

export type DisclaimerContentMarkup = {
  __typename?: "DisclaimerContentMarkup";
  content: Array<MarkupText>;
};

export type DisclaimerMessage = {
  __typename?: "DisclaimerMessage";
  heading?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type DiscoverMoreItem = EgdsCarouselItem & {
  __typename?: "DiscoverMoreItem";
  card: EgdsImageLinkCard;
  icon: Icon;
  id?: Maybe<Scalars["String"]>;
};

export type DiscoveryAlternativeLinkCard = {
  __typename?: "DiscoveryAlternativeLinkCard";
  cardAction: UiLinkAction;
  impression: ClientSideAnalytics;
  media: Media;
  subtitle?: Maybe<EgdsText>;
  title: EgdsText;
};

export interface DiscoveryAlternativeLinkCardCriteriaInput {
  alternativeTripType: AlternativeLinkType;
  destinationId: Scalars["String"];
}

export type DiscoveryAlternativeLinkCardGroup = {
  __typename?: "DiscoveryAlternativeLinkCardGroup";
  carousel: EgdsCarousel;
  discoveryAlternativeLinkCard: Array<DiscoveryAlternativeLinkCard>;
  title: EgdsText;
};

export type DiscoveryAttribution = {
  __typename?: "DiscoveryAttribution";
  closeContent?: Maybe<EgdsButton>;
  content: EgdsSpannableText;
  showContent?: Maybe<EgdsButton>;
};

export type DiscoveryBadge = {
  __typename?: "DiscoveryBadge";
  action?: Maybe<UiLinkAction>;
  badge: EgdsBadge;
};

export type DiscoveryCard = GenericDiscoveryCard;

export type DiscoveryCardBadges = {
  __typename?: "DiscoveryCardBadges";
  heading: Array<EgdsBadge>;
  /** @deprecated Use priceBadge */
  price?: Maybe<EgdsBadge>;
  priceBadge?: Maybe<DiscoveryBadge>;
  priceButton?: Maybe<DiscoveryPriceButton>;
};

export type DiscoveryCardContentSize =
  | "ABBREVIATED"
  | "FOUNDATION"
  | "FOUNDATION_DETAILS_LIST"
  | "LANDING"
  | "LONG_FORM"
  | "LONG_FORM_FEATURED"
  | "SHORT_FORM"
  | "SMART_LANDING"
  | "UBER_ABBREVIATED"
  | "UBER_ABBREVIATED_PRICE"
  | "UBER_ABBREVIATED_RATING";

export type DiscoveryCardLocation = {
  __typename?: "DiscoveryCardLocation";
  leadingIcon: Icon;
  text: EgdsText;
  trailingIcon?: Maybe<Icon>;
};

export type DiscoveryCardMedia = DiscoveryMediaGallery | DiscoveryMediaItem;

export type DiscoveryCloseCardAction = {
  __typename?: "DiscoveryCloseCardAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
};

export type DiscoveryContainer = {
  __typename?: "DiscoveryContainer";
  itemsLayout: DiscoveryGroupLayout;
  wrapper: DiscoveryWrapper;
};

export type DiscoveryContainerType = "CAROUSEL" | "EXPANDO" | "LIST";

export interface DiscoveryDestinationContextInput {
  placeCoordinates?: InputMaybe<CoordinatesInput>;
  placeId?: InputMaybe<DiscoveryPlaceIdInput>;
}

export type DiscoveryDetailsList =
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsTextIconList
  | EgdsUnorderedList
  | EnrichedDetailsList;

export type DiscoveryDisclaimer = DiscoveryDisclaimerOverlay;

export type DiscoveryDisclaimerOverlay = {
  __typename?: "DiscoveryDisclaimerOverlay";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  overlay?: Maybe<DiscoveryOverlay>;
  text: Scalars["String"];
};

export type DiscoveryExpandoText = {
  __typename?: "DiscoveryExpandoText";
  text: Scalars["String"];
  textControl: EgdsExpandoPeek;
};

export interface DiscoveryFlexFiltersInput {
  accommodationTypeCode?: InputMaybe<Scalars["String"]>;
  affinityTypeCode?: InputMaybe<Scalars["String"]>;
  hotelBrandCode?: InputMaybe<Scalars["String"]>;
  starRatingCode?: InputMaybe<Scalars["String"]>;
}

export type DiscoveryFooter = DiscoveryFooterSection | DiscoverySlimCard;

export type DiscoveryFooterAction = EgdsStandardLink | UiLinkAction | UiSecondaryButton;

export type DiscoveryFooterSection = {
  __typename?: "DiscoveryFooterSection";
  action?: Maybe<DiscoveryFooterAction>;
  disclaimerText?: Maybe<EgdsText>;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<EgdsText>;
};

export type DiscoveryGroupContainer = DiscoveryContainer | EgdsCarousel;

export type DiscoveryGroupErrorSection = {
  __typename?: "DiscoveryGroupErrorSection";
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<UiPrimaryButton>;
  primary?: Maybe<EgdsHeading>;
  secondary?: Maybe<EgdsPlainText>;
};

export type DiscoveryGroupLayout = DiscoveryList | EgdsCarousel;

export type DiscoveryHeading = {
  __typename?: "DiscoveryHeading";
  disclaimer?: Maybe<DiscoveryDisclaimer>;
  featuredHeader?: Maybe<Scalars["String"]>;
  featuredHeaderAccessibilityLabel?: Maybe<Scalars["String"]>;
  highlight?: Maybe<Scalars["String"]>;
  highlightAccessibilityLabel?: Maybe<Scalars["String"]>;
  link?: Maybe<EgdsStandardLink>;
  subTitle?: Maybe<Scalars["String"]>;
  subTitleAccessibilityLabel?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  titleAccessibilityLabel: Scalars["String"];
};

export type DiscoveryItemsGroup = {
  __typename?: "DiscoveryItemsGroup";
  cards: Array<DiscoveryCard>;
  container?: Maybe<DiscoveryGroupContainer>;
  footer?: Maybe<DiscoveryFooter>;
  /** @deprecated Replace with field action inside footer field */
  footerLink?: Maybe<EgdsStandardLink>;
  heading?: Maybe<DiscoveryHeading>;
  impression?: Maybe<ClientSideAnalytics>;
  seoStructuredData?: Maybe<Scalars["String"]>;
};

export type DiscoveryList = {
  __typename?: "DiscoveryList";
  id: Scalars["String"];
  scroll?: Maybe<ClientSideAnalytics>;
  scrollEnd?: Maybe<ClientSideAnalytics>;
};

export interface DiscoveryLodgingContextInput {
  checkin?: InputMaybe<DateInput>;
  checkout?: InputMaybe<DateInput>;
  discoveryFlexFilters?: InputMaybe<DiscoveryFlexFiltersInput>;
  propertyId?: InputMaybe<Scalars["String"]>;
  rooms?: InputMaybe<Array<DiscoveryRoomInput>>;
  searchCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type DiscoveryMap = {
  __typename?: "DiscoveryMap";
  dynamicMapTrigger?: Maybe<EgdsButton>;
  map: EgdsBasicMap;
  mapControls?: Maybe<DiscoveryMapControls>;
  openMap?: Maybe<ClientSideAnalytics>;
  seeAllProducts: UiPrimaryButton;
  tabs: Array<DiscoveryMapTab>;
  userLocationMarker?: Maybe<DiscoveryUserLocationMarker>;
};

export type DiscoveryMapTabsArgs = {
  strategies?: InputMaybe<Array<Scalars["String"]>>;
};

export type DiscoveryMapControls = {
  __typename?: "DiscoveryMapControls";
  buttonGroup: EgdsToggleButtonGroup;
  openControlsButton: EgdsToggleButton;
};

export type DiscoveryMapTab = {
  __typename?: "DiscoveryMapTab";
  cardContainer?: Maybe<DiscoveryItemsGroup>;
  errorSection?: Maybe<DiscoveryMapTabErrorSection>;
  markers?: Maybe<Array<DiscoveryMarker>>;
  pill: EgdsBasicPill;
  srpParams?: Maybe<Scalars["String"]>;
  staticMapImageUrl?: Maybe<Scalars["String"]>;
  tabId: Scalars["String"];
  toolbar: DiscoveryMapToolbar;
};

export type DiscoveryMapTabErrorSection = {
  __typename?: "DiscoveryMapTabErrorSection";
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<UiPrimaryButton>;
  primary?: Maybe<EgdsHeading>;
  secondary?: Maybe<EgdsPlainText>;
};

export type DiscoveryMapToolbar = {
  __typename?: "DiscoveryMapToolbar";
  action?: Maybe<DiscoveryMapToolbarCloseAction>;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
};

export type DiscoveryMapToolbarCloseAction = {
  __typename?: "DiscoveryMapToolbarCloseAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
};

export type DiscoveryMarker = {
  __typename?: "DiscoveryMarker";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  interaction?: Maybe<ClientSideAnalytics>;
  mapCard?: Maybe<DiscoveryCard>;
  markerIconType?: Maybe<Array<Scalars["String"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type DiscoveryMediaGallery = {
  __typename?: "DiscoveryMediaGallery";
  carousel?: Maybe<EgdsCarousel>;
  items: Array<DiscoveryMediaItem>;
};

export type DiscoveryMediaItem = {
  __typename?: "DiscoveryMediaItem";
  attribution?: Maybe<DiscoveryAttribution>;
  media?: Maybe<Media>;
};

export type DiscoveryOfferingType = "DESTINATION" | "PRODUCT";

export type DiscoveryOverlay = {
  __typename?: "DiscoveryOverlay";
  accessibilityLabel: Scalars["String"];
  analytics: ClientSideAnalytics;
  closeButton?: Maybe<EgdsOverlayButton>;
  footerLink?: Maybe<EgdsStandardLink>;
  heading: DiscoveryOverlayHeading;
};

export type DiscoveryOverlayHeading = {
  __typename?: "DiscoveryOverlayHeading";
  backgroundImage?: Maybe<Media>;
  subTitle?: Maybe<EgdsText>;
  title: EgdsHeading;
};

export type DiscoveryPersonalizedModuleList = {
  __typename?: "DiscoveryPersonalizedModuleList";
  heading?: Maybe<DiscoveryText>;
  modules: Array<DiscoveryItemsGroup>;
  /** @deprecated title is deprecated. Please use heading instead. */
  title?: Maybe<Scalars["String"]>;
};

export interface DiscoveryPlaceIdInput {
  id: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
}

export type DiscoveryPriceButton = DiscoveryPrimaryAltButton;

export type DiscoveryPriceStrategy = "DATELESS" | "LIVE" | "NONE";

export type DiscoveryPrimaryAltButton = {
  __typename?: "DiscoveryPrimaryAltButton";
  button: UiPrimaryButton;
};

export type DiscoveryProductGroupMap = {
  __typename?: "DiscoveryProductGroupMap";
  discoveryMap: DiscoveryMap;
  errorSection?: Maybe<DiscoveryGroupErrorSection>;
  staticMapImageUrl?: Maybe<Scalars["String"]>;
};

export type DiscoveryProductUniqueness = {
  __typename?: "DiscoveryProductUniqueness";
  background?: Maybe<Scalars["String"]>;
  details?: Maybe<EgdsSpannableList>;
  title?: Maybe<EgdsGraphicText>;
};

export interface DiscoveryRecentActivityContextInput {
  lodging?: InputMaybe<DiscoveryLodgingContextInput>;
  marketingChannelTypeCode?: InputMaybe<Scalars["Int"]>;
  outputLineOfBusiness: LineOfBusinessDomain;
  pageId?: InputMaybe<Scalars["String"]>;
}

export interface DiscoveryRecommendationContextInput {
  destination?: InputMaybe<DiscoveryDestinationContextInput>;
  lodging?: InputMaybe<DiscoveryLodgingContextInput>;
  marketingChannelCode?: InputMaybe<Scalars["Int"]>;
  orderId?: InputMaybe<Scalars["String"]>;
  outputLineOfBusiness?: InputMaybe<LineOfBusinessDomain>;
  pageId?: InputMaybe<Scalars["String"]>;
}

export type DiscoveryRecommendationInputContext =
  | "DESTINATION"
  | "ORDER"
  | "PRODUCT"
  | "RECENTLY_VIEWED_PRODUCT"
  | "RECENT_SEARCHES"
  | "USER_LOCATION";

export type DiscoveryRecommendationStrategy =
  | "ALTERNATIVE"
  | "EG_PLACES"
  | "LOOKUP_AND_ASSEMBLE"
  | "NEARBY"
  | "POPULAR"
  | "SEM_RESULT"
  | "SIMILAR"
  | "TRENDING";

export type DiscoveryReviewSnippet = DiscoveryExpandoText;

export interface DiscoveryRoomInput {
  adults: Scalars["Int"];
  children?: InputMaybe<Array<Scalars["Int"]>>;
}

export type DiscoverySlimCard = {
  __typename?: "DiscoverySlimCard";
  action?: Maybe<DiscoverySlimCardAction>;
  graphic?: Maybe<UiGraphic>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  rightIcon?: Maybe<Icon>;
  secondaries: Array<DiscoveryText>;
};

export type DiscoverySlimCardAction = UiLinkAction;

export type DiscoveryStarRating = EgdsIconRating;

export type DiscoveryText = EgdsGraphicText | EgdsPlainText;

export type DiscoveryUserLocationMarker = {
  __typename?: "DiscoveryUserLocationMarker";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  markerIconType?: Maybe<Array<Scalars["String"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type DiscoveryWrapper = EgdsExpandoCard;

export type DisplayPrice = {
  __typename?: "DisplayPrice";
  disclaimer?: Maybe<LodgingPlainDialog>;
  price: FormattedMoney;
  role: Scalars["String"];
};

export type DisplayPriceSummaryItem = {
  __typename?: "DisplayPriceSummaryItem";
  leadPrice?: Maybe<LodgingEnrichedMessage>;
  priceLabel?: Maybe<LodgingEnrichedMessage>;
  priceMessages?: Maybe<Array<LodgingEnrichedMessage>>;
};

export type Distance = {
  __typename?: "Distance";
  unit: DistanceUnit;
  value: Scalars["Float"];
};

export type DistanceUnit = "KILOMETER" | "MILE";

export type DownloadButtonWithDialog = {
  __typename?: "DownloadButtonWithDialog";
  dialog: EgdsFullScreenDialog;
  dialogButtons: Array<DialogBlock>;
  triggerButton: UiPrimaryButton;
};

export type DropOffSearchResults = {
  __typename?: "DropOffSearchResults";
  carDropOffOfferCard: Array<CarDropOffOfferCard>;
  carDropOffOfferCardTitle: Scalars["String"];
  carDropOffTitle: CarDropOffTitle;
  map?: Maybe<CarMap>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
};

export type Duration = {
  __typename?: "Duration";
  /** @deprecated Use platform formatters and return localized strings */
  unit: ChronoUnit;
  /** @deprecated Use platform formatters and return localized strings */
  value: Scalars["String"];
};

export interface DurationSpan {
  closeButton: EgdsButton;
  doneButton: EgdsButton;
  title: Scalars["String"];
}

export type DurationSpanInputValidation = EgdsInputValidation & {
  __typename?: "DurationSpanInputValidation";
  errorMessage: Scalars["String"];
  errorSummary?: Maybe<EgdsErrorSummary>;
  type: DurationSpanValidationType;
};

export type DurationSpanSelector = DurationSpan &
  EgdsElement & {
    __typename?: "DurationSpanSelector";
    closeButton: EgdsButton;
    doneButton: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]>;
    maxDurationSpanLimit: EgdsNumberInputField;
    minDurationSpanLimit: EgdsNumberInputField;
    title: Scalars["String"];
    validations?: Maybe<Array<EgdsInputValidation>>;
  };

export type DurationSpanValidationType =
  | "MAXIMUM_GREATER_THAN_MINIMUM"
  | "MAXIMUM_VALUE"
  | "MINIMUM_LESS_THAN_MAXIMUM"
  | "MINIMUM_VALUE"
  | "REQUIRED_VALUE";

export type DurationTimelineSideSection = {
  __typename?: "DurationTimelineSideSection";
  differentDayArrival?: Maybe<Scalars["String"]>;
  primary: Scalars["String"];
  /** @deprecated Use 'secondaryMessage' field to support different screen sizes */
  secondary: Scalars["String"];
  secondaryMessage: FlightsResponsiveMessage;
};

export type DynamicCardElement =
  | EgdsSpannableText
  | EgdsStylizedText
  | VirtualAgentControlActionable
  | VirtualAgentControlHorizontalBreak;

export type DynamicCrossSellQueries = {
  __typename?: "DynamicCrossSellQueries";
  productCrossSell: MultiItemCrossSellUiResponse;
};

export type DynamicCrossSellQueriesProductCrossSellArgs = {
  crossSellProductType: CrossSellProductType;
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type DynamicPackageDetailsPrimers = DynamicPackageDetailsPrimersSuccess | PackageDetailsPrimersError;

export type DynamicPackageDetailsPrimersSuccess = {
  __typename?: "DynamicPackageDetailsPrimersSuccess";
  heading: EgdsHeading;
  primers: DynamicPackageDetailsSuccessPrimers;
  state: MishopDetailsUiState;
};

export type DynamicPackageDetailsSuccessPrimers = {
  __typename?: "DynamicPackageDetailsSuccessPrimers";
  bookByPhonePrimer?: Maybe<MishopUiBookByPhonePrimer>;
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  /** @deprecated legalContentPrimer has been moved to be a member of the PackageUIProductContentPrimer union */
  legalContentPrimer: MishopUiLegalContentPrimer;
  notificationPrimer: MishopUiNotificationPrimer;
  oneKeyBannerPrimer?: Maybe<MishopUiOneKeyBannerPrimer>;
  pricingDetailsPrimer: MishopUiPricingDetailsPrimer;
  productContentPrimers: Array<PackageDetailsProductContentPrimer>;
  searchWizardPrimer?: Maybe<PackageUiChangePackageFormPrimer>;
  stepIndicatorPrimer: MishopUiStepIndicatorPrimer;
  tripSaveItemPrimer?: Maybe<PackageUiTripSaveItemPrimer>;
};

export type DynamicRateRule = {
  __typename?: "DynamicRateRule";
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use description instead of descriptionId, replace with description */
  descriptionId?: Maybe<Scalars["Int"]>;
  discountPercent?: Maybe<Scalars["Int"]>;
  discountType?: Maybe<RateDiscountType>;
  expiration?: Maybe<Duration>;
  sameDay: Scalars["Boolean"];
};

export type EgClickstreamAnalytics = {
  __typename?: "EGClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: Scalars["AnalyticsPayload"];
};

export type EgClickstreamEvent = {
  __typename?: "EGClickstreamEvent";
  actionLocation?: Maybe<Scalars["String"]>;
  clickstreamTraceId?: Maybe<Scalars["String"]>;
  eventCategory: Scalars["String"];
  eventName: Scalars["String"];
  eventType: Scalars["String"];
  eventVersion: Scalars["String"];
};

export type EgClickstreamPayload = TripRecommendationModule;

export type EgdsActionDialog = EgdsDialog & {
  __typename?: "EGDSActionDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer: EgdsDialogFooter;
};

export interface EgdsBadge {
  accessibility?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  size?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
}

export type EgdsBadgeNotification = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSBadgeNotification";
    accessibility?: Maybe<Scalars["String"]>;
    count?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
    theme?: Maybe<Scalars["String"]>;
    visible?: Maybe<Scalars["Boolean"]>;
  };

export interface EgdsBaseMap {
  center?: Maybe<Coordinates>;
  /** @deprecated Redundant field: locale is already provided at the shared-ui level */
  locale?: Maybe<Scalars["Locale"]>;
  zoom?: Maybe<Scalars["Int"]>;
}

export interface EgdsBaseMapConfig {
  accessToken: Scalars["String"];
  /** @deprecated prefer using data from EGDSDynamicMapConfig */
  config?: Maybe<MapConfiguration>;
  egdsMapProvider: EgdsMapProvider;
}

export type EgdsBasicCheckBox = EgdsCheckBox &
  EgdsElement & {
    __typename?: "EGDSBasicCheckBox";
    checkedAnalytics?: Maybe<ClientSideAnalytics>;
    description?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    enabled: Scalars["Boolean"];
    errorMessage?: Maybe<Scalars["String"]>;
    label: EgdsText;
    name?: Maybe<Scalars["String"]>;
    required: Scalars["Boolean"];
    state: EgdsCheckBoxState;
    uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsBasicDateRange = EgdsDateRange & {
  __typename?: "EGDSBasicDateRange";
  loadingMessage?: Maybe<Scalars["String"]>;
  results: Array<EgdsDateRangeResult>;
};

export type EgdsBasicDateRangeResult = EgdsDateRangeResult & {
  __typename?: "EGDSBasicDateRangeResult";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  date: Date;
  label: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  theme?: Maybe<EgdsDateRangeResultTheme>;
};

export type EgdsBasicLocalizedText = EgdsElement &
  EgdsTemplateModels &
  EgdsText & {
    __typename?: "EGDSBasicLocalizedText";
    accessibilityTemplate?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    models: Array<TemplateModel>;
    template: Scalars["String"];
    text: Scalars["String"];
  };

export type EgdsBasicMap = EgdsBaseMap & {
  __typename?: "EGDSBasicMap";
  bounds?: Maybe<EgdsMapBounds>;
  center?: Maybe<Coordinates>;
  centeredBoundsOptions?: Maybe<EgdsMapCenteredBoundsOptions>;
  computedBoundsOptions?: Maybe<EgdsMapComputedBoundsOptions>;
  config: EgdsBaseMapConfig;
  initialViewport?: Maybe<EgdsMapInitialViewport>;
  label?: Maybe<Scalars["String"]>;
  /** @deprecated Redundant field: locale is already provided at the shared-ui level */
  locale?: Maybe<Scalars["Locale"]>;
  markers: Array<EgdsMapMarker>;
  zoom?: Maybe<Scalars["Int"]>;
};

export type EgdsBasicOption = EgdsElement &
  EgdsOption & {
    __typename?: "EGDSBasicOption";
    changeAnalytics?: Maybe<ClientSideAnalytics>;
    egdsElementId?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    selected?: Maybe<Scalars["Boolean"]>;
    value: Scalars["String"];
  };

export type EgdsBasicPill = EgdsPill & {
  __typename?: "EGDSBasicPill";
  accessibility?: Maybe<Scalars["String"]>;
  deselectAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"];
  selectedText?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type EgdsBasicRadioButton = EgdsRadioButton & {
  __typename?: "EGDSBasicRadioButton";
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  label: Scalars["String"];
  labelSuffix?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type EgdsBasicRadioGroup = EgdsRadioGroup & {
  __typename?: "EGDSBasicRadioGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]>;
};

export type EgdsBasicRemovablePill = EgdsPill & {
  __typename?: "EGDSBasicRemovablePill";
  accessibility?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  remove?: Maybe<UiGraphic>;
  removeAccessibility?: Maybe<Scalars["String"]>;
  removeAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"];
  value?: Maybe<Scalars["String"]>;
};

export type EgdsBasicSectionHeading = EgdsSectionHeading & {
  __typename?: "EGDSBasicSectionHeading";
  graphic?: Maybe<UiGraphic>;
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
};

export type EgdsBasicSelect = EgdsElement &
  EgdsSelect & {
    __typename?: "EGDSBasicSelect";
    clickAnalytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    label: Scalars["String"];
    options: Array<EgdsOption>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsBasicStepInput = EgdsElement &
  EgdsStepInput & {
    __typename?: "EGDSBasicStepInput";
    decreaseAnalytics?: Maybe<ClientSideAnalytics>;
    decreaseText?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    increaseAnalytics?: Maybe<ClientSideAnalytics>;
    increaseText?: Maybe<Scalars["String"]>;
    key?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    max?: Maybe<Scalars["Int"]>;
    min?: Maybe<Scalars["Int"]>;
    step: Scalars["Int"];
    subLabel?: Maybe<Scalars["String"]>;
    value: Scalars["Int"];
  };

export type EgdsBasicTab = EgdsElement &
  EgdsTab & {
    __typename?: "EGDSBasicTab";
    accessibility?: Maybe<Scalars["String"]>;
    clickAnalytics?: Maybe<ClientSideAnalytics>;
    egdsElementId?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    tabId: Scalars["String"];
  };

export type EgdsBasicTabs = EgdsElement &
  EgdsTabs & {
    __typename?: "EGDSBasicTabs";
    egdsElementId?: Maybe<Scalars["String"]>;
    selectedTabId?: Maybe<Scalars["String"]>;
    tabs: Array<EgdsTab>;
  };

export type EgdsBasicThumbnailMediaGallery = EgdsThumbnailMediaGallery & {
  __typename?: "EGDSBasicThumbnailMediaGallery";
  tabs?: Maybe<EgdsTabs>;
  thumbnails: Array<MediaItem>;
  toolbar: EgdsDialogToolbar;
};

export type EgdsBasicTraveler = EgdsTraveler & {
  __typename?: "EGDSBasicTraveler";
  ages?: Maybe<Array<EgdsSelect>>;
  count: EgdsStepInput;
  type?: Maybe<EgdsTravelerType>;
};

export type EgdsBasicTravelerSelector = EgdsElement &
  EgdsTravelerSelector & {
    __typename?: "EGDSBasicTravelerSelector";
    closeButton: EgdsButton;
    doneButton: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]>;
    subTitles?: Maybe<Array<Scalars["String"]>>;
    title: Scalars["String"];
    travelers?: Maybe<EgdsTravelers>;
    validations?: Maybe<Array<EgdsInputValidation>>;
  };

export type EgdsBasicTriggerPill = EgdsPill & {
  __typename?: "EGDSBasicTriggerPill";
  accessibility?: Maybe<Scalars["String"]>;
  deselectAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"];
  trigger?: Maybe<UiGraphic>;
};

export type EgdsBulletedList = EgdsElement &
  EgdsTextList & {
    __typename?: "EGDSBulletedList";
    egdsElementId?: Maybe<Scalars["String"]>;
    listItems: Array<EgdsTextListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export interface EgdsButton {
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
}

export type EgdsButtonStyle = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type EgdsCard = EgdsImageCard | EgdsImageLinkCard | EgdsMarkCard | EgdsStandardMessagingCard;

export type EgdsCardCarouselItem = EgdsCarouselItem & {
  __typename?: "EGDSCardCarouselItem";
  card: EgdsCard;
  id?: Maybe<Scalars["String"]>;
};

export type EgdsCardThemes = "BORDERED" | "PADDED";

export type EgdsCardinalLocalizedText = EgdsElement &
  EgdsTemplateModels &
  EgdsText & {
    __typename?: "EGDSCardinalLocalizedText";
    accessibilityTemplates?: Maybe<Array<CardinalTemplate>>;
    accessibilityText?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    models: Array<TemplateModel>;
    secondaryTemplates?: Maybe<Array<CardinalTemplate>>;
    templates: Array<CardinalTemplate>;
    text: Scalars["String"];
  };

export type EgdsCarousel = {
  __typename?: "EGDSCarousel";
  carouselId?: Maybe<Scalars["String"]>;
  items: Array<EgdsCarouselItem>;
  nextButton?: Maybe<EgdsButton>;
  previousButton?: Maybe<EgdsButton>;
};

export type EgdsCarouselContainer = EgdsGalleryCarousel & {
  __typename?: "EGDSCarouselContainer";
  accessibilityHeadingText: Scalars["String"];
  media: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]>;
  previousButtonText?: Maybe<Scalars["String"]>;
};

export interface EgdsCarouselItem {
  id?: Maybe<Scalars["String"]>;
}

export interface EgdsCheckBox {
  description?: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
  label: EgdsText;
  name?: Maybe<Scalars["String"]>;
  required: Scalars["Boolean"];
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]>;
}

export type EgdsCheckBoxGroup = {
  __typename?: "EGDSCheckBoxGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  options: Array<EgdsCheckBox>;
};

export type EgdsCheckBoxState = "CHECKED" | "INDETERMINATE" | "UNCHECKED";

export type EgdsComposableTextElement =
  | EgdsBulletedList
  | EgdsComposableTextElementSectionContainer
  | EgdsGraphicText
  | EgdsHeading
  | EgdsIconText
  | EgdsInlineLink
  | EgdsLinkList
  | EgdsOrderedList
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText
  | EgdsTextIconList
  | EgdsTextListSection
  | EgdsTextSection
  | EgdsUnorderedList;

export interface EgdsComposableTextElementContainer {
  elements: Array<EgdsComposableTextElement>;
}

export type EgdsComposableTextElementSectionContainer = EgdsComposableTextElementContainer &
  EgdsElement & {
    __typename?: "EGDSComposableTextElementSectionContainer";
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsComposableTextElement>;
    heading?: Maybe<EgdsSectionHeading>;
  };

export type EgdsCompositeDatePicker = {
  __typename?: "EGDSCompositeDatePicker";
  dateFormat: Scalars["String"];
  datePicker: EgdsDatePicker;
  endDateInputField?: Maybe<EgdsDatePickerInputField>;
  showDatePicker: Scalars["Boolean"];
  singleDateSelect: Scalars["Boolean"];
  singleMode: Scalars["Boolean"];
  startDateInputField: EgdsDatePickerInputField;
};

export type EgdsCompositeLocalizedText = EgdsElement &
  EgdsText & {
    __typename?: "EGDSCompositeLocalizedText";
    egdsElementId?: Maybe<Scalars["String"]>;
    models: Array<CompositeLocalizedTextModel>;
    template: Scalars["String"];
    text: Scalars["String"];
  };

export interface EgdsDatePicker {
  buttonText: Scalars["String"];
  clearButtonText?: Maybe<Scalars["String"]>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars["String"];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars["String"]>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars["Boolean"]>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars["String"];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
}

export type EgdsDatePickerContent = {
  __typename?: "EGDSDatePickerContent";
  accessibility?: Maybe<Scalars["String"]>;
  dateKey: Scalars["String"];
  text?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
};

export type EgdsDatePickerFlexibilityCalendarContent = {
  __typename?: "EGDSDatePickerFlexibilityCalendarContent";
  flexibleDateOptions: Array<EgdsBasicPill>;
  formElementId: Scalars["String"];
  title?: Maybe<EgdsPlainText>;
};

export type EgdsDatePickerFlexibilityContent = {
  __typename?: "EGDSDatePickerFlexibilityContent";
  calendarContent?: Maybe<EgdsDatePickerFlexibilityCalendarContent>;
  contentSelector?: Maybe<EgdsTabs>;
  flexibilityLabel?: Maybe<EgdsCardinalLocalizedText>;
  /** @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type */
  flexibleDateOptions: Array<EgdsBasicPill>;
  flexibleDatesContent?: Maybe<EgdsDatePickerFlexibilityDatesContent>;
  /** @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type */
  formElementId: Scalars["String"];
  /** @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type */
  title?: Maybe<EgdsPlainText>;
};

export type EgdsDatePickerFlexibilityDatesContent = {
  __typename?: "EGDSDatePickerFlexibilityDatesContent";
  monthsSelection?: Maybe<EgdsDatePickerFlexibleMonths>;
  nightsSelection?: Maybe<EgdsDatePickerFlexibleNights>;
};

export type EgdsDatePickerFlexibleMonths = {
  __typename?: "EGDSDatePickerFlexibleMonths";
  heading?: Maybe<Scalars["String"]>;
  options: EgdsToggleButtonGroup;
  subHeading: Scalars["String"];
};

export type EgdsDatePickerFlexibleNights = {
  __typename?: "EGDSDatePickerFlexibleNights";
  heading?: Maybe<Scalars["String"]>;
  includeWeekend: EgdsBasicCheckBox;
  options: Array<EgdsBasicPill>;
};

export type EgdsDatePickerInputField = EgdsInputField & {
  __typename?: "EGDSDatePickerInputField";
  dateFormat?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export interface EgdsDateRange {
  loadingMessage?: Maybe<Scalars["String"]>;
  results: Array<EgdsDateRangeResult>;
}

export type EgdsDateRangePicker = EgdsDatePicker & {
  __typename?: "EGDSDateRangePicker";
  buttonText: Scalars["String"];
  buttonTextTemplate?: Maybe<EgdsLocalizedText>;
  clearButtonText?: Maybe<Scalars["String"]>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars["String"];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  endDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  endDatePlaceholderText: Scalars["String"];
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars["String"]>;
  maxNumberOfDaysSelected?: Maybe<Scalars["Int"]>;
  nextButtonAnalytics?: Maybe<ClientSideAnalytics>;
  previousButtonAnalytics?: Maybe<ClientSideAnalytics>;
  sameDaySelectionAllowed: Scalars["Boolean"];
  selectedEndDate?: Maybe<Date>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars["Boolean"]>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars["String"];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
};

export interface EgdsDateRangeResult {
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  date: Date;
  label: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  theme?: Maybe<EgdsDateRangeResultTheme>;
}

export type EgdsDateRangeResultTheme = "LINK" | "POSITIVE";

export type EgdsDayName = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED";

export type EgdsDecorativeText = "HIGHLIGHT" | "LINE_THROUGH" | "SUBSCRIPT" | "SUPERSCRIPT" | "UNDERLINE";

export interface EgdsDetailMediaGallery {
  closeText: Scalars["String"];
  mediaItems: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]>;
  previousButtonText?: Maybe<Scalars["String"]>;
}

export interface EgdsDialog {
  closeAnalytics?: Maybe<ClientSideAnalytics>;
}

export interface EgdsDialogFooter {
  buttons: Array<EgdsButton>;
}

export type EgdsDialogSpannableToolbar = {
  __typename?: "EGDSDialogSpannableToolbar";
  actions?: Maybe<Array<EgdsToolbarActionItem>>;
  closeText?: Maybe<Scalars["String"]>;
  navigationType?: Maybe<NavigationType>;
  title: Scalars["String"];
};

export type EgdsDialogToolbar = {
  __typename?: "EGDSDialogToolbar";
  closeText?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type EgdsDismissAction = {
  __typename?: "EGDSDismissAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars["String"]>;
};

export type EgdsDynamicMapConfig = EgdsBaseMapConfig & {
  __typename?: "EGDSDynamicMapConfig";
  accessToken: Scalars["String"];
  /** @deprecated Prefer using data provided by EGDSDynamicMapConfig and not the one from this object */
  config?: Maybe<MapConfiguration>;
  egdsMapProvider: EgdsMapProvider;
  externalConfigEndpoint?: Maybe<HttpUri>;
  mapId?: Maybe<Scalars["String"]>;
};

export interface EgdsElement {
  egdsElementId?: Maybe<Scalars["String"]>;
}

export type EgdsElementWrapper = {
  __typename?: "EGDSElementWrapper";
  element: EgdsElement;
};

export interface EgdsElements {
  elements: Array<EgdsElement>;
}

export type EgdsEmailInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSEmailInputField";
    autofill?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsErrorSummary = EgdsElement & {
  __typename?: "EGDSErrorSummary";
  analytics?: Maybe<ClientSideAnalytics>;
  body?: Maybe<EgdsText>;
  egdsElementId?: Maybe<Scalars["String"]>;
  goToErrorLinkText?: Maybe<Scalars["String"]>;
  goToFirstErrorLinkText?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  headingTemplate?: Maybe<EgdsLocalizedText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export interface EgdsExpando {
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedLabel: Scalars["String"];
}

export type EgdsExpandoCard = EgdsExpando & {
  __typename?: "EGDSExpandoCard";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedLabel: Scalars["String"];
  subtitle?: Maybe<Scalars["String"]>;
  triggerPosition?: Maybe<ExpandoTriggerPosition>;
};

export type EgdsExpandoLink = EgdsExpando & {
  __typename?: "EGDSExpandoLink";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedAccessibilityText?: Maybe<Scalars["String"]>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedAccessibilityText?: Maybe<Scalars["String"]>;
  expandedLabel: Scalars["String"];
};

export type EgdsExpandoListItem = EgdsExpando & {
  __typename?: "EGDSExpandoListItem";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedLabel: Scalars["String"];
  triggerPosition?: Maybe<ExpandoTriggerPosition>;
};

export type EgdsExpandoPeek = EgdsExpando & {
  __typename?: "EGDSExpandoPeek";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedAccessibilityText?: Maybe<Scalars["String"]>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedAccessibilityText?: Maybe<Scalars["String"]>;
  expandedLabel: Scalars["String"];
  items?: Maybe<Scalars["Int"]>;
  lines?: Maybe<Scalars["Int"]>;
  /** @deprecated Removed after EGDS graduated of Expando. Replace with `lines` or `items`. */
  minimalHeight?: Maybe<Scalars["Int"]>;
  /** @deprecated Removed after EGDS graduated of Expando. */
  threshold?: Maybe<Scalars["Int"]>;
};

export type EgdsFullScreenDialog = EgdsDialog & {
  __typename?: "EGDSFullScreenDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  toolbar: EgdsDialogToolbar;
};

export interface EgdsGalleryCarousel {
  accessibilityHeadingText: Scalars["String"];
  media: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]>;
  previousButtonText?: Maybe<Scalars["String"]>;
}

export type EgdsGraphicMenu = EgdsMenu & {
  __typename?: "EGDSGraphicMenu";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  graphic: UiGraphic;
  subMenus: Array<EgdsSubMenu>;
  title: Scalars["String"];
};

export type EgdsGraphicText = EgdsText & {
  __typename?: "EGDSGraphicText";
  accessibility?: Maybe<Scalars["String"]>;
  graphic: UiGraphic;
  text: Scalars["String"];
  trailingGraphics?: Maybe<Array<UiGraphic>>;
};

export type EgdsHeading = EgdsElement &
  EgdsText & {
    __typename?: "EGDSHeading";
    accessibility?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated DO NOT USE. Client should handle heading tags. */
    headingType?: Maybe<EgdsHeadingStyle>;
    text: Scalars["String"];
  };

export type EgdsHeadingStyle = "H1" | "H2" | "H3" | "H4" | "H5" | "H6" | "H7" | "H8";

export type EgdsIconPosition = "LEADING" | "TRAILING";

export type EgdsIconRating = EgdsRating & {
  __typename?: "EGDSIconRating";
  accessibility?: Maybe<Scalars["String"]>;
  icon: Icon;
  rating: Scalars["Float"];
};

export type EgdsIconText = EgdsText & {
  __typename?: "EGDSIconText";
  icon: Icon;
  text: Scalars["String"];
};

export type EgdsImageCard = {
  __typename?: "EGDSImageCard";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type EgdsImageLink = EgdsElement & {
  __typename?: "EGDSImageLink";
  action: UiLinkAction;
  egdsElementId?: Maybe<Scalars["String"]>;
  image: Image;
};

export type EgdsImageLinkCard = EgdsElement & {
  __typename?: "EGDSImageLinkCard";
  description?: Maybe<Scalars["String"]>;
  egdsElementId?: Maybe<Scalars["String"]>;
  link: EgdsImageLink;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type EgdsInfantSeat = "IN_SEAT" | "ON_LAP";

export type EgdsInfantSeatRadioButton = EgdsElement &
  EgdsRadioButton & {
    __typename?: "EGDSInfantSeatRadioButton";
    description?: Maybe<Scalars["String"]>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelSuffix?: Maybe<Scalars["String"]>;
    seatType: EgdsInfantSeat;
    value: Scalars["String"];
  };

export type EgdsInlineDialogFooter = EgdsDialogFooter & {
  __typename?: "EGDSInlineDialogFooter";
  buttons: Array<EgdsButton>;
};

export type EgdsInlineLink = EgdsText & {
  __typename?: "EGDSInlineLink";
  action: UiLinkAction;
  disabled: Scalars["Boolean"];
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export interface EgdsInputField {
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
}

export interface EgdsInputValidation {
  errorMessage: Scalars["String"];
}

export type EgdsLevelList = {
  __typename?: "EGDSLevelList";
  items: Array<EgdsLevelListItem>;
};

export type EgdsLevelListItem = {
  __typename?: "EGDSLevelListItem";
  level: Scalars["Int"];
  text: EgdsText;
  type: EgdsTextListType;
};

export type EgdsLinkList = EgdsElement & {
  __typename?: "EGDSLinkList";
  egdsElementId?: Maybe<Scalars["String"]>;
  listItems: Array<EgdsStandardLink>;
};

export type EgdsLinkSize = "LARGE" | "MEDIUM" | "SMALL";

export interface EgdsList {
  /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
  items: Array<EgdsText>;
}

export type EgdsLoaderDialog = EgdsDialog & {
  __typename?: "EGDSLoaderDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
};

export type EgdsLocalizedText = EgdsBasicLocalizedText | EgdsCardinalLocalizedText | EgdsCompositeLocalizedText;

export type EgdsLocalizedTextTemplate = EgdsElement &
  EgdsText & {
    __typename?: "EGDSLocalizedTextTemplate";
    egdsElementId?: Maybe<Scalars["String"]>;
    template: Scalars["String"];
    text: Scalars["String"];
    token: Scalars["String"];
  };

export type EgdsLoyaltyBadge = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSLoyaltyBadge";
    accessibility?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
    theme?: Maybe<Scalars["String"]>;
  };

export type EgdsMapBounds = {
  __typename?: "EGDSMapBounds";
  northeast: Coordinates;
  southwest: Coordinates;
};

export type EgdsMapCenteredBoundsOptions = {
  __typename?: "EGDSMapCenteredBoundsOptions";
  centeredOn: Coordinates;
  coordinates: Array<Coordinates>;
};

export type EgdsMapComputedBoundsOptions = {
  __typename?: "EGDSMapComputedBoundsOptions";
  coordinates: Array<Coordinates>;
  gaiaId?: Maybe<Scalars["String"]>;
  interpolationRatio?: Maybe<Scalars["Float"]>;
  lowerQuantile?: Maybe<Scalars["Float"]>;
  marginMultiplier?: Maybe<Scalars["Float"]>;
  minMargin?: Maybe<Scalars["Int"]>;
  minimumPins?: Maybe<Scalars["Int"]>;
  upperQuantile?: Maybe<Scalars["Float"]>;
};

export type EgdsMapFeature = EgdsMapMarker & {
  __typename?: "EGDSMapFeature";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerIconType?: Maybe<Array<Scalars["String"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  onEnterAccessibilityMessage?: Maybe<Scalars["String"]>;
  onSelectAccessibilityMessage?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  qualifiers: Array<Scalars["String"]>;
  score?: Maybe<Scalars["Float"]>;
  /** @deprecated Use name (label) or description (sublabel) */
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<EgdsMapFeatureType>;
};

export type EgdsMapFeatureType = "PLACE" | "PRODUCT" | "PROPERTY";

export type EgdsMapInitialViewport =
  | "BOUNDS"
  | "CENTERED_COMPUTED_BOUNDS"
  | "CENTER_AND_ZOOM"
  | "COMPUTED_BOUNDS"
  | "FIT_MARKERS";

export interface EgdsMapMarker {
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerIconType?: Maybe<Array<Scalars["String"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerType?: Maybe<Scalars["String"]>;
  /** @deprecated Use name (label) or description (sublabel) */
  text?: Maybe<Scalars["String"]>;
}

export type EgdsMapMarkerStatus = "AVAILABLE" | "FILTERED" | "SOLD_OUT" | "UNKNOWN";

export type EgdsMapPositionMode = "FIT_BOUNDS" | "ZOOM_AND_CENTER";

export type EgdsMapProvider = "GOOGLE" | "MAPBOX" | "MAPKIT";

export type EgdsMark =
  | "AARP"
  | "AARP_MEMBER_BENEFITS"
  | "ADDON"
  | "AIR_NEW_ZEALAND"
  | "APPLE"
  | "APPLE_MAPS"
  | "APP_CTX"
  | "APP_EBOOK"
  | "APP_EXP"
  | "APP_HOTWIRE"
  | "APP_ORB"
  | "APP_TVLY"
  | "APP_WOTIF"
  | "BONUS_EBOOK"
  | "COMMISSION_TIER_FOUR"
  | "COMMISSION_TIER_ONE"
  | "COMMISSION_TIER_THREE"
  | "COMMISSION_TIER_TWO"
  | "FACEBOOK"
  | "FLAGS__AR"
  | "FLAGS__AS"
  | "FLAGS__AT"
  | "FLAGS__AU"
  | "FLAGS__BE"
  | "FLAGS__BO"
  | "FLAGS__BR"
  | "FLAGS__BZ"
  | "FLAGS__CA"
  | "FLAGS__CH"
  | "FLAGS__CL"
  | "FLAGS__CN"
  | "FLAGS__CO"
  | "FLAGS__CR"
  | "FLAGS__CZ"
  | "FLAGS__DE"
  | "FLAGS__DK"
  | "FLAGS__EC"
  | "FLAGS__EE"
  | "FLAGS__EG"
  | "FLAGS__ES"
  | "FLAGS__EU"
  | "FLAGS__FI"
  | "FLAGS__FR"
  | "FLAGS__GR"
  | "FLAGS__GT"
  | "FLAGS__GY"
  | "FLAGS__HK"
  | "FLAGS__HN"
  | "FLAGS__HR"
  | "FLAGS__HU"
  | "FLAGS__ID"
  | "FLAGS__IE"
  | "FLAGS__IL"
  | "FLAGS__IN"
  | "FLAGS__IS"
  | "FLAGS__IT"
  | "FLAGS__JP"
  | "FLAGS__KR"
  | "FLAGS__L1"
  | "FLAGS__LT"
  | "FLAGS__LV"
  | "FLAGS__MX"
  | "FLAGS__MY"
  | "FLAGS__NI"
  | "FLAGS__NL"
  | "FLAGS__NO"
  | "FLAGS__NZ"
  | "FLAGS__PA"
  | "FLAGS__PE"
  | "FLAGS__PH"
  | "FLAGS__PL"
  | "FLAGS__PT"
  | "FLAGS__PY"
  | "FLAGS__RU"
  | "FLAGS__SA"
  | "FLAGS__SE"
  | "FLAGS__SG"
  | "FLAGS__SK"
  | "FLAGS__SR"
  | "FLAGS__SV"
  | "FLAGS__TH"
  | "FLAGS__TR"
  | "FLAGS__TW"
  | "FLAGS__UA"
  | "FLAGS__UAE"
  | "FLAGS__UK"
  | "FLAGS__US"
  | "FLAGS__UY"
  | "FLAGS__VE"
  | "FLAGS__VN"
  | "FLAGS__WW"
  | "FLAGS__ZA"
  | "GOOGLE"
  | "GOOGLE_LOGO"
  | "GOOGLE_MAP"
  | "GOOGLE_TRANSLATED"
  | "HAWAIIAN"
  | "HOTWIRE_HOTRATE_CIRCLE"
  | "LINE"
  | "LOYALTY_HOTELS__HIGH_TIER"
  | "LOYALTY_HOTELS__LOW_TIER"
  | "LOYALTY_HOTELS__MIDDLE_TIER"
  | "LOYALTY_HOTELS__REWARDS"
  | "LOYALTY_HOTELS__STAMP_EMPTY"
  | "LOYALTY_HOTELS__STAMP_PENDING"
  | "LOYALTY_HOTELS__STAMP_SEARCHED"
  | "LOYALTY_TVLY"
  | "MAPBOX"
  | "MIP_PRIMARY"
  | "MIP_REVERSE"
  | "MIP_SECONDARY"
  | "MOD_CTX"
  | "MOD_EBOOK"
  | "MOD_EXP"
  | "MOD_HOTELS"
  | "MOD_ORB"
  | "MOD_TVLY"
  | "MOD_WOTIF"
  | "NAVER"
  | "ONEKEY__ABRITEL"
  | "ONEKEY__BLUE"
  | "ONEKEY__BOOKABACH"
  | "ONEKEY__CASH"
  | "ONEKEY__EXPEDIA"
  | "ONEKEY__FEWODIREKT"
  | "ONEKEY__GOLD"
  | "ONEKEY__HOTELS__CHINESE"
  | "ONEKEY__HOTELS__ENGLISH"
  | "ONEKEY__HOTELS__PORTUGUESE"
  | "ONEKEY__HOTELS__SPANISH"
  | "ONEKEY__KNOCKEDOUT__ALWAYS_LIGHT"
  | "ONEKEY__PLATINUM"
  | "ONEKEY__SILVER"
  | "ONEKEY__STANDARD"
  | "ONEKEY__STANDARD__ALWAYS_LIGHT"
  | "ONEKEY__STAYZ"
  | "ONEKEY__VRBO"
  | "ONEKEY__WORDMARK"
  | "ONEKEY__WORDMARK__ALWAYS_LIGHT"
  | "PAYMENT__7_ELEVEN"
  | "PAYMENT__AFFIRM"
  | "PAYMENT__AIRPLUS"
  | "PAYMENT__ALIPAY"
  | "PAYMENT__AMEX"
  | "PAYMENT__APPLEPAY"
  | "PAYMENT__CHASE"
  | "PAYMENT__CLICKTOPAY"
  | "PAYMENT__DINERS_CLUB_INTERNATIONAL"
  | "PAYMENT__DISCOVER"
  | "PAYMENT__GOOGLE_PAY"
  | "PAYMENT__IDEAL"
  | "PAYMENT__JAPAN_CREDIT_BUREAU"
  | "PAYMENT__KLARNA"
  | "PAYMENT__MAESTRO"
  | "PAYMENT__MASTERCARD"
  | "PAYMENT__MASTERPASS"
  | "PAYMENT__PAYPAL"
  | "PAYMENT__SAMSUNG_CARD"
  | "PAYMENT__UATP"
  | "PAYMENT__UNION_PAY"
  | "PAYMENT__VISA"
  | "PLUS_CIRCLE"
  | "PLUS_CIRCLE_CTX"
  | "PLUS_CIRCLE_EBOOK"
  | "PLUS_CIRCLE_ORB"
  | "PLUS_CIRCLE_TVLY"
  | "PLUS_CIRCLE_WOTIF"
  | "PREMIUM_TVLY"
  | "REWARDS_ORB"
  | "TRIPADVISOR"
  | "TRIVAGO"
  | "VAP_HOTELS__HIGH_TIER"
  | "VAP_HOTELS__LOW_TIER"
  | "VAP_HOTELS__MIDDLE_TIER"
  | "VIP_BEX"
  | "VIP_CIRCLE_BEX"
  | "VIP_CIRCLE_EBOOKERS"
  | "VIP_CIRCLE_HOTELS"
  | "VIP_CIRCLE_ORBITZ"
  | "VIP_EBOOKERS"
  | "VIP_ORBITZ"
  | "VIRGIN_AU"
  | "VRBO";

export type EgdsMarkCard = {
  __typename?: "EGDSMarkCard";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  mark?: Maybe<Mark>;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type EgdsMarkerUrls = {
  __typename?: "EGDSMarkerUrls";
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type EgdsMaxLengthInputValidation = EgdsInputValidation & {
  __typename?: "EGDSMaxLengthInputValidation";
  errorMessage: Scalars["String"];
  maxLength: Scalars["Int"];
};

export type EgdsMediaCarouselItem = EgdsCarouselItem & {
  __typename?: "EGDSMediaCarouselItem";
  id?: Maybe<Scalars["String"]>;
  media: MediaItem;
};

export interface EgdsMenu {
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  subMenus: Array<EgdsSubMenu>;
  title: Scalars["String"];
}

export type EgdsMenuListIconTextItem = EgdsMenuListItem & {
  __typename?: "EGDSMenuListIconTextItem";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  graphic: UiGraphic;
  title: Scalars["String"];
};

export interface EgdsMenuListItem {
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  title: Scalars["String"];
}

export type EgdsMenuStandardItem = EgdsMenuListItem & {
  __typename?: "EGDSMenuStandardItem";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title: Scalars["String"];
};

export type EgdsMenuTextItem = EgdsMenuListItem & {
  __typename?: "EGDSMenuTextItem";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  title: Scalars["String"];
};

export type EgdsMinLengthInputValidation = EgdsInputValidation & {
  __typename?: "EGDSMinLengthInputValidation";
  errorMessage: Scalars["String"];
  minLength: Scalars["Int"];
};

export type EgdsMoreInfoTrigger = {
  __typename?: "EGDSMoreInfoTrigger";
  icon: Icon;
  text: Scalars["String"];
  triggerAction: EgdsMoreInfoTriggerAction;
};

export type EgdsMoreInfoTriggerAction = {
  __typename?: "EGDSMoreInfoTriggerAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  sheet: EgdsSheet;
  text: Scalars["String"];
};

export type EgdsNestedLevelOneIconListItem = EgdsNestedLevelOneListItem & {
  __typename?: "EGDSNestedLevelOneIconListItem";
  icon: Icon;
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars["String"];
};

export type EgdsNestedLevelOneList = {
  __typename?: "EGDSNestedLevelOneList";
  items: Array<EgdsNestedLevelOneListItem>;
  size: EgdsTextListSize;
  type: EgdsTextListType;
};

export interface EgdsNestedLevelOneListItem {
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars["String"];
}

export type EgdsNestedLevelOneStandardListItem = EgdsNestedLevelOneListItem & {
  __typename?: "EGDSNestedLevelOneStandardListItem";
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars["String"];
};

export type EgdsNumberInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSNumberInputField";
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    max?: Maybe<Scalars["Float"]>;
    min?: Maybe<Scalars["Float"]>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    step?: Maybe<Scalars["Float"]>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsOpenDatePickerAction = UiAction & {
  __typename?: "EGDSOpenDatePickerAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  datePicker: EgdsDatePicker;
};

export type EgdsOpenTravelerSelectorAction = UiAction & {
  __typename?: "EGDSOpenTravelerSelectorAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  travelerSelector: EgdsTravelerSelector;
};

export interface EgdsOption {
  label: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  value: Scalars["String"];
}

export type EgdsOrderedList = EgdsElement &
  EgdsList &
  EgdsTextList & {
    __typename?: "EGDSOrderedList";
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
    items: Array<EgdsText>;
    listItems: Array<EgdsTextListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export type EgdsOverlayButton = EgdsButton &
  EgdsElement & {
    __typename?: "EGDSOverlayButton";
    accessibility?: Maybe<Scalars["String"]>;
    action?: Maybe<UiLinkAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
  };

export type EgdsParagraph = EgdsElement &
  EgdsText & {
    __typename?: "EGDSParagraph";
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated DO NOT USE. Client should handle styling size. */
    size: Scalars["Int"];
    style: EgdsParagraphStyle;
    text: Scalars["String"];
  };

export type EgdsParagraphStyle = "P1" | "P2" | "P3";

export type EgdsPasswordInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSPasswordInputField";
    autofill?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    passwordVisible?: Maybe<Scalars["Boolean"]>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsPathType = "CYCLING" | "DRIVING" | "FLYING" | "TRANSIT" | "WALKING";

export type EgdsPhotosphereCameraMovement = "AUTO_PAN" | "DISABLED" | "FULL_MOVE";

export type EgdsPhotosphereImage = {
  __typename?: "EGDSPhotosphereImage";
  /** @deprecated Use description instead */
  altText?: Maybe<Scalars["String"]>;
  aspectRatio?: Maybe<AspectRatio>;
  cameraMovement?: Maybe<EgdsPhotosphereCameraMovement>;
  description: Scalars["String"];
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars["URL"];
};

export type EgdsPhotosphereImageUrlArgs = {
  dimensions?: InputMaybe<DimensionsInput>;
};

export interface EgdsPill {
  accessibility?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
}

export type EgdsPlainText = EgdsText & {
  __typename?: "EGDSPlainText";
  accessibility?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type EgdsPriceLockup = {
  __typename?: "EGDSPriceLockup";
  accessibilityPrice: Scalars["String"];
  accessibilityStrikeThroughDialogTrigger?: Maybe<Scalars["String"]>;
  isPointsFirst: Scalars["Boolean"];
  isStrikeThroughFirst: Scalars["Boolean"];
  leadingCaption?: Maybe<Scalars["String"]>;
  lockupFormattedPoints?: Maybe<Scalars["String"]>;
  lockupPrice?: Maybe<Scalars["String"]>;
  priceSubtextBold?: Maybe<Scalars["String"]>;
  priceSubtextFirst?: Maybe<Array<Scalars["String"]>>;
  priceSubtextStandard?: Maybe<Array<Scalars["String"]>>;
  strikeThroughPrice?: Maybe<Scalars["String"]>;
};

export type EgdsProductRatingLink = {
  __typename?: "EGDSProductRatingLink";
  action: EgdsProductRatingShowTextAction;
  link: EgdsStandardLink;
};

export type EgdsProductRatingShowTextAction = {
  __typename?: "EGDSProductRatingShowTextAction";
  text: Scalars["String"];
};

export type EgdsProductRatingSummary = {
  __typename?: "EGDSProductRatingSummary";
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
  supportingMessages: Array<EgdsProductRatingSupportingMessage>;
};

export type EgdsProductRatingSupportingMessage = EgdsPlainText | EgdsProductRatingLink;

export type EgdsProgramBadge = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSProgramBadge";
    accessibility?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
    theme?: Maybe<Scalars["String"]>;
  };

export type EgdsProgressBar = {
  __typename?: "EGDSProgressBar";
  accessibilityLabel: Scalars["String"];
  percent: Scalars["Int"];
  progressDescription?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export interface EgdsRadioButton {
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  label: Scalars["String"];
  labelSuffix?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
}

export interface EgdsRadioGroup {
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]>;
}

export type EgdsRangeInputValidation = EgdsInputValidation & {
  __typename?: "EGDSRangeInputValidation";
  errorMessage: Scalars["String"];
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export interface EgdsRating {
  accessibility?: Maybe<Scalars["String"]>;
  rating: Scalars["Float"];
}

export type EgdsRegexInputValidation = EgdsInputValidation & {
  __typename?: "EGDSRegexInputValidation";
  errorMessage: Scalars["String"];
  pattern: Scalars["String"];
};

export type EgdsRemovablePill = UiRemovablePill & {
  __typename?: "EGDSRemovablePill";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  remove?: Maybe<Icon>;
  selected: Scalars["Boolean"];
};

export type EgdsRequiredInputValidation = EgdsInputValidation & {
  __typename?: "EGDSRequiredInputValidation";
  errorMessage: Scalars["String"];
};

export type EgdsRoomsTravelerSelector = EgdsElement &
  EgdsTravelerSelector & {
    __typename?: "EGDSRoomsTravelerSelector";
    addAnotherRoomButton: EgdsButton;
    closeButton: EgdsButton;
    doneButton: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated Use infantsOnLap and infantsInSeat instead. */
    infantSeatSelection?: Maybe<EgdsTravelerInfantSeatSelection>;
    maxRoomCount?: Maybe<Scalars["Int"]>;
    rooms: Array<EgdsTravelerSelectorRoom>;
    title: Scalars["String"];
    validations?: Maybe<Array<EgdsInputValidation>>;
  };

export type EgdsSameValueInputValidation = EgdsInputValidation & {
  __typename?: "EGDSSameValueInputValidation";
  errorMessage: Scalars["String"];
};

export interface EgdsSearchForm {
  actionURL: Scalars["String"];
  elements: Array<EgdsSearchFormElement>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  playback?: Maybe<EgdsSearchPlayback>;
  search?: Maybe<EgdsButton>;
}

export type EgdsSearchFormAction =
  | EgdsOpenDatePickerAction
  | EgdsOpenTravelerSelectorAction
  | EgdsSearchFormSelectAction
  | EgdsShowSearchFormAction
  | EgdsSubmitSearchFormAction
  | OpenDurationSpanAction
  | OpenTypeaheadAction;

export type EgdsSearchFormButton = EgdsButton &
  EgdsElement & {
    __typename?: "EGDSSearchFormButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
     *         or a union type with a manageable number of members
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    onClick?: Maybe<EgdsSearchFormAction>;
    primary?: Maybe<Scalars["String"]>;
    style?: Maybe<EgdsButtonStyle>;
  };

export type EgdsSearchFormDatePickerField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "EGDSSearchFormDatePickerField";
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"];
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsSearchFormElement =
  | EgdsSearchFormDatePickerField
  | EgdsSearchFormInputField
  | EgdsSearchFormLocationField
  | EgdsSearchFormLocationsField
  | EgdsSearchFormTravelersField
  | SearchFormDurationSpanField;

export interface EgdsSearchFormField {
  action?: Maybe<EgdsSearchFormAction>;
}

export type EgdsSearchFormInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSSearchFormInputField";
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"];
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsSearchFormLocationField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "EGDSSearchFormLocationField";
    action?: Maybe<EgdsSearchFormAction>;
    changeAccessibility?: Maybe<Scalars["String"]>;
    changeAnalytics?: Maybe<ClientSideAnalytics>;
    closeAccessibility?: Maybe<Scalars["String"]>;
    closeAnalytics?: Maybe<ClientSideAnalytics>;
    debounceRate?: Maybe<Scalars["Int"]>;
    egdsElementId: Scalars["String"];
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    /** @deprecated Use multiLocations */
    locations?: Maybe<Array<Scalars["String"]>>;
    multiLocations?: Maybe<Array<EgdsSearchFormSelectedLocation>>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    regionId?: Maybe<Scalars["String"]>;
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsSearchFormLocationsField = EgdsElement & {
  __typename?: "EGDSSearchFormLocationsField";
  destination: EgdsSearchFormLocationField;
  egdsElementId: Scalars["String"];
  horizontalSwapper?: Maybe<EgdsButton>;
  origin: EgdsSearchFormLocationField;
  validations?: Maybe<Array<EgdsInputValidation>>;
  verticalSwapper?: Maybe<EgdsButton>;
};

export type EgdsSearchFormSelectAction = {
  __typename?: "EGDSSearchFormSelectAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  apply?: Maybe<EgdsButton>;
  label?: Maybe<Scalars["String"]>;
  options: Array<EgdsOption>;
};

export type EgdsSearchFormSelectedLocation = {
  __typename?: "EGDSSearchFormSelectedLocation";
  text: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type EgdsSearchFormTravelersField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "EGDSSearchFormTravelersField";
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"];
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export interface EgdsSearchInputs {
  shoppingSearchCriteria?: Maybe<ShoppingSearchCriteria>;
}

export type EgdsSearchPlayback = {
  __typename?: "EGDSSearchPlayback";
  hideButton: EgdsButton;
  primary: EgdsText;
  primaryTemplate?: Maybe<EgdsLocalizedText>;
  secondaries: Array<EgdsText>;
  showAction: EgdsSearchFormAction;
};

export type EgdsSectionContainer = EgdsElement &
  EgdsElements & {
    __typename?: "EGDSSectionContainer";
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsElement>;
    /** @deprecated Use sectionHeading instead. It allows allows more formatting options. */
    heading?: Maybe<Scalars["String"]>;
    sectionHeading?: Maybe<EgdsSectionHeading>;
    /** @deprecated This leads to ambiguity as section themes are not found in EGDS */
    theme?: Maybe<ContainerTheme>;
  };

export interface EgdsSectionHeading {
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
}

export interface EgdsSelect {
  disabled: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  options: Array<EgdsOption>;
  readOnly: Scalars["Boolean"];
}

export type EgdsSheet = {
  __typename?: "EGDSSheet";
  closeAccessibility?: Maybe<Scalars["String"]>;
  closeAnalytics: ClientSideAnalytics;
  closeText?: Maybe<Scalars["String"]>;
  /** @deprecated Use sheetTypes instead */
  sheetType?: Maybe<EgdsSheetType>;
  sheetTypes?: Maybe<Array<EgdsSheetTypePerViewSize>>;
};

export type EgdsSheetType = "BOTTOM" | "CENTERED" | "FULLSCREEN" | "POPOVER" | "SIDE";

export type EgdsSheetTypePerViewSize = {
  __typename?: "EGDSSheetTypePerViewSize";
  sheetType?: Maybe<EgdsSheetType>;
  viewSize?: Maybe<EgdsSheetTypeViewSize>;
};

export type EgdsSheetTypeViewSize = "LARGE" | "MEDIUM" | "SMALL";

export type EgdsShowSearchFormAction = {
  __typename?: "EGDSShowSearchFormAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  icon: Icon;
  resource: Uri;
};

export type EgdsSingleDatePicker = EgdsDatePicker & {
  __typename?: "EGDSSingleDatePicker";
  buttonText: Scalars["String"];
  clearButtonText?: Maybe<Scalars["String"]>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars["String"];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars["String"]>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars["Boolean"]>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars["String"];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
};

export type EgdsSortAndFilterAppliedFilter = ShoppingSortAndFilterAppliedFilter & {
  __typename?: "EGDSSortAndFilterAppliedFilter";
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: UiRemovablePill;
};

export type EgdsSpannableDialog = EgdsDialog & {
  __typename?: "EGDSSpannableDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  toolbar: EgdsDialogSpannableToolbar;
};

export type EgdsSpannableList = {
  __typename?: "EGDSSpannableList";
  items: Array<EgdsSpannableListItem>;
  listType: EgdsTextListType;
};

export type EgdsSpannableListItem = {
  __typename?: "EGDSSpannableListItem";
  graphic?: Maybe<UiGraphic>;
  nestedList?: Maybe<EgdsSpannableNestedLevelOneList>;
  subText?: Maybe<Scalars["String"]>;
  text: EgdsSpannableText;
};

export type EgdsSpannableNestedLevelOneList = {
  __typename?: "EGDSSpannableNestedLevelOneList";
  items: Array<EgdsSpannableNestedLevelOneListItem>;
  listType: EgdsTextListType;
};

export type EgdsSpannableNestedLevelOneListItem = {
  __typename?: "EGDSSpannableNestedLevelOneListItem";
  graphic?: Maybe<UiGraphic>;
  subText?: Maybe<Scalars["String"]>;
  text: EgdsSpannableText;
};

export type EgdsSpannableText = EgdsElement &
  EgdsText & {
    __typename?: "EGDSSpannableText";
    /** @deprecated Please use inlineContent */
    contents: Array<EgdsText>;
    egdsElementId?: Maybe<Scalars["String"]>;
    inlineContent: Array<EgdsSpannableTextItem>;
    text: Scalars["String"];
  };

export type EgdsSpannableTextItem =
  | EgdsGraphicText
  | EgdsHeading
  | EgdsInlineLink
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStylizedText;

export type EgdsStackedDialogFooter = EgdsDialogFooter & {
  __typename?: "EGDSStackedDialogFooter";
  buttons: Array<EgdsButton>;
};

export type EgdsStandardBadge = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSStandardBadge";
    accessibility?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
    theme?: Maybe<Scalars["String"]>;
  };

export type EgdsStandardLink = EgdsElement &
  EgdsText & {
    __typename?: "EGDSStandardLink";
    action: UiLinkAction;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    iconPosition?: Maybe<EgdsIconPosition>;
    size: EgdsLinkSize;
    text: Scalars["String"];
  };

export type EgdsStandardMessagingCard = EgdsElement & {
  __typename?: "EGDSStandardMessagingCard";
  background?: Maybe<Scalars["String"]>;
  buttons?: Maybe<Array<EgdsButton>>;
  dismiss?: Maybe<EgdsDismissAction>;
  egdsElementId?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  message: Scalars["String"];
  rightIcon?: Maybe<Icon>;
  themes?: Maybe<Array<EgdsCardThemes>>;
};

export type EgdsStandardSubMenu = EgdsSubMenu & {
  __typename?: "EGDSStandardSubMenu";
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]>;
};

export type EgdsStandardSwitch = UiToggle & {
  __typename?: "EGDSStandardSwitch";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  id?: Maybe<Scalars["String"]>;
  /** @deprecated Use checked or unchecked label */
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type EgdsStaticMapConfig = EgdsBaseMapConfig & {
  __typename?: "EGDSStaticMapConfig";
  accessToken: Scalars["String"];
  config?: Maybe<MapConfiguration>;
  egdsMapProvider: EgdsMapProvider;
  markerUrls: Array<EgdsMarkerUrls>;
  positionMode?: Maybe<EgdsMapPositionMode>;
};

export interface EgdsStepInput {
  decreaseText?: Maybe<Scalars["String"]>;
  increaseText?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  max?: Maybe<Scalars["Int"]>;
  min?: Maybe<Scalars["Int"]>;
  step: Scalars["Int"];
  subLabel?: Maybe<Scalars["String"]>;
  value: Scalars["Int"];
}

export type EgdsStylizedText = EgdsText & {
  __typename?: "EGDSStylizedText";
  accessibility?: Maybe<Scalars["String"]>;
  decorative?: Maybe<EgdsDecorativeText>;
  size?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export interface EgdsSubMenu {
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]>;
}

export type EgdsSubmitSearchFormAction = UiAction & {
  __typename?: "EGDSSubmitSearchFormAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  fixedInputs?: Maybe<EgdsSearchInputs>;
};

export interface EgdsTab {
  accessibility?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  tabId: Scalars["String"];
}

export interface EgdsTabs {
  selectedTabId?: Maybe<Scalars["String"]>;
  tabs: Array<EgdsTab>;
}

export interface EgdsTemplateModels {
  models: Array<TemplateModel>;
}

export interface EgdsText {
  text: Scalars["String"];
}

export type EgdsTextAreaInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSTextAreaInputField";
    disabled?: Maybe<Scalars["Boolean"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    maxRows?: Maybe<Scalars["Int"]>;
    minRows?: Maybe<Scalars["Int"]>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export interface EgdsTextContainer {
  contents: Array<EgdsText>;
}

export type EgdsTextIconLinkListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextIconLinkListItem";
    action: UiLinkAction;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon: Icon;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    size?: Maybe<EgdsLinkSize>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
  };

export type EgdsTextIconList = EgdsElement &
  EgdsTextList & {
    __typename?: "EGDSTextIconList";
    egdsElementId?: Maybe<Scalars["String"]>;
    listItems: Array<EgdsTextIconListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export type EgdsTextIconListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextIconListItem";
    accessibilityLabel?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    icon: Icon;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    nestedLevelOneList?: Maybe<EgdsNestedLevelOneList>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
  };

export type EgdsTextInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSTextInputField";
    autofill?: Maybe<Scalars["String"]>;
    disabled?: Maybe<Scalars["Boolean"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export interface EgdsTextList {
  listItems: Array<EgdsTextListItem>;
  size?: Maybe<EgdsTextListSize>;
}

export interface EgdsTextListContainer {
  list: EgdsTextList;
}

export interface EgdsTextListItem {
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
}

export type EgdsTextListItemStyle = "DEFAULT" | "EMPHASIS" | "LOYALTY" | "NEGATIVE" | "POSITIVE";

export type EgdsTextListSection = EgdsElement &
  EgdsTextListContainer & {
    __typename?: "EGDSTextListSection";
    egdsElementId?: Maybe<Scalars["String"]>;
    heading?: Maybe<EgdsSectionHeading>;
    list: EgdsTextList;
  };

export type EgdsTextListSize = "LIST_1" | "LIST_2" | "LIST_3";

export type EgdsTextListType = "BULLET" | "ICON" | "NO_BULLET" | "NUMBER";

export type EgdsTextMenu = EgdsMenu & {
  __typename?: "EGDSTextMenu";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  subMenus: Array<EgdsSubMenu>;
  title: Scalars["String"];
};

export type EgdsTextSection = EgdsElement &
  EgdsTextContainer & {
    __typename?: "EGDSTextSection";
    contents: Array<EgdsText>;
    egdsElementId?: Maybe<Scalars["String"]>;
    heading?: Maybe<EgdsSectionHeading>;
  };

export type EgdsTextStandardListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextStandardListItem";
    accessibilityLabel?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    nestedLevelOneList?: Maybe<EgdsNestedLevelOneList>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
  };

export type EgdsTextWithMarkListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextWithMarkListItem";
    egdsElementId?: Maybe<Scalars["String"]>;
    mark?: Maybe<Mark>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
  };

export interface EgdsThumbnailMediaGallery {
  tabs?: Maybe<EgdsTabs>;
  thumbnails: Array<MediaItem>;
  toolbar: EgdsDialogToolbar;
}

export type EgdsToast = EgdsElement & {
  __typename?: "EGDSToast";
  button?: Maybe<UiPrimaryButton>;
  egdsElementId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type EgdsToggleButton = {
  __typename?: "EGDSToggleButton";
  accessibility?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<UiGraphic>;
  name?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
  selectedAnalytics?: Maybe<ClientSideAnalytics>;
  subText?: Maybe<Scalars["String"]>;
  unselectedAnalytics?: Maybe<ClientSideAnalytics>;
};

export type EgdsToggleButtonGroup = {
  __typename?: "EGDSToggleButtonGroup";
  options: Array<EgdsToggleButton>;
};

export type EgdsToolbarActionItem = {
  __typename?: "EGDSToolbarActionItem";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clickAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]>;
  isEnabled: Scalars["Boolean"];
  navigationType?: Maybe<NavigationType>;
  title?: Maybe<EgdsSpannableText>;
};

export interface EgdsTraveler {
  ages?: Maybe<Array<EgdsSelect>>;
  count: EgdsStepInput;
}

export type EgdsTravelerChildAgeSelect = EgdsElement &
  EgdsSelect & {
    __typename?: "EGDSTravelerChildAgeSelect";
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    label: Scalars["String"];
    labelTemplate: EgdsLocalizedText;
    options: Array<EgdsOption>;
    readOnly: Scalars["Boolean"];
    value?: Maybe<Scalars["Int"]>;
  };

export type EgdsTravelerChildren = EgdsTraveler & {
  __typename?: "EGDSTravelerChildren";
  ageTemplate: EgdsSelect;
  ages: Array<EgdsSelect>;
  count: EgdsStepInput;
  /** @deprecated Please use the one defined in EGDSTravelerSelector or EGDSTravelerSelectorRoom instead. */
  travelerNote?: Maybe<Scalars["String"]>;
};

export type EgdsTravelerInfant = EgdsTraveler & {
  __typename?: "EGDSTravelerInfant";
  ageTemplate: EgdsSelect;
  ages: Array<EgdsSelect>;
  count: EgdsStepInput;
  /** @deprecated Please use the one defined in EGDSTravelerSelector or EGDSTravelerSelectorRoom instead. */
  travelerNote?: Maybe<Scalars["String"]>;
};

export type EgdsTravelerInfantSeatSelection = EgdsElement &
  EgdsRadioGroup & {
    __typename?: "EGDSTravelerInfantSeatSelection";
    airlineAgeRule: EgdsStandardLink;
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage: Scalars["String"];
    groupName: Scalars["String"];
    label: Scalars["String"];
    labelTemplate: EgdsLocalizedText;
    options: Array<EgdsRadioButton>;
    selected: Scalars["String"];
  };

export interface EgdsTravelerSelector {
  closeButton: EgdsButton;
  doneButton: EgdsButton;
  title: Scalars["String"];
}

export type EgdsTravelerSelectorRoom = {
  __typename?: "EGDSTravelerSelectorRoom";
  adults: EgdsStepInput;
  children: EgdsTraveler;
  /** @deprecated Use infantsOnLap and infantsInSeat instead. */
  infants?: Maybe<EgdsTraveler>;
  infantsInSeat?: Maybe<EgdsTraveler>;
  infantsOnLap?: Maybe<EgdsTraveler>;
  label: EgdsLocalizedText;
  removeRoomButton?: Maybe<EgdsButton>;
  travelerNote?: Maybe<EgdsLocalizedText>;
};

export type EgdsTravelerStepInput = EgdsElement &
  EgdsStepInput & {
    __typename?: "EGDSTravelerStepInput";
    /** @deprecated Need specific tracking for increase and decrease count event. Hence, deprecating the action and adding new analytics fields for increase and decrease count. */
    action?: Maybe<EgdsSearchFormAction>;
    decreaseAnalytics: ClientSideAnalytics;
    decreaseText?: Maybe<Scalars["String"]>;
    decreaseTextTemplate?: Maybe<EgdsLocalizedText>;
    egdsElementId?: Maybe<Scalars["String"]>;
    increaseAnalytics: ClientSideAnalytics;
    increaseText?: Maybe<Scalars["String"]>;
    increaseTextTemplate?: Maybe<EgdsLocalizedText>;
    key?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    max?: Maybe<Scalars["Int"]>;
    min?: Maybe<Scalars["Int"]>;
    step: Scalars["Int"];
    subLabel?: Maybe<Scalars["String"]>;
    value: Scalars["Int"];
  };

export type EgdsTravelerType = "ADULT" | "CHILD" | "INFANT" | "SENIOR" | "YOUTH";

export type EgdsTravelers = {
  __typename?: "EGDSTravelers";
  adults: EgdsStepInput;
  children?: Maybe<EgdsTraveler>;
  infantsInSeat?: Maybe<EgdsTraveler>;
  infantsOnLap?: Maybe<EgdsTraveler>;
  pets?: Maybe<EgdsCheckBox>;
  seniors?: Maybe<EgdsStepInput>;
  travelerNote?: Maybe<EgdsLocalizedText>;
};

export type EgdsTravelersInputValidation = EgdsInputValidation & {
  __typename?: "EGDSTravelersInputValidation";
  errorMessage: Scalars["String"];
  errorSummary?: Maybe<EgdsErrorSummary>;
  infantsInSeatPerAdult?: Maybe<Scalars["Int"]>;
  maxCount?: Maybe<Scalars["Int"]>;
  minAgeToHoldInfant?: Maybe<Scalars["Int"]>;
  type: EgdsTravelersValidationType;
};

export type EgdsTravelersValidationType =
  | "CHILD_AGE_REQUIRED"
  | "MANY_INFANT_IN_SEAT"
  | "MANY_INFANT_ON_LAP"
  | "MAX_TRAVELERS";

export type EgdsTypeaheadInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSTypeaheadInputField";
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    renderType?: Maybe<EgdsTypeaheadRenderType>;
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    typeaheadEmptyResultPlaceholder?: Maybe<Scalars["String"]>;
    typeaheadInfo: TypeaheadInfo;
    typeaheadPlaceholder?: Maybe<Scalars["String"]>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type EgdsTypeaheadMultiSelect = {
  __typename?: "EGDSTypeaheadMultiSelect";
  clearAllText?: Maybe<Scalars["String"]>;
  isMultiLocation?: Maybe<Scalars["Boolean"]>;
  selectedItemHeading?: Maybe<Scalars["String"]>;
  showPills: Scalars["Boolean"];
};

export type EgdsTypeaheadRenderType = "ADAPTIVE" | "DIALOG" | "MENU";

export type EgdsUnorderedList = EgdsElement &
  EgdsList &
  EgdsTextList & {
    __typename?: "EGDSUnorderedList";
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
    items: Array<EgdsText>;
    listItems: Array<EgdsTextListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export type EgDynamicMap = {
  __typename?: "EGDynamicMap";
  action?: Maybe<UiPrimaryButton>;
  adaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  adaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  autoSearchOnMapMove?: Maybe<EgdsBasicCheckBox>;
  filterPlacement?: Maybe<FilterPlacement>;
  /** @deprecated Replace by title from map toolbar */
  heading?: Maybe<EgdsHeading>;
  map?: Maybe<EgdsBasicMap>;
  mapToolbar?: Maybe<MapToolbar>;
  /** @deprecated Deprecated. Use mapTrigger */
  staticMapSrc?: Maybe<HttpUri>;
  subheading?: Maybe<EgdsHeading>;
};

export type EgReferrerEventType = "CLICK" | "IMPRESSION";

export type EgUisMicroMessage = {
  __typename?: "EGUisMicroMessage";
  messageContent: Scalars["String"];
  schemaName: Scalars["String"];
};

export type EmCardComponents = {
  __typename?: "EMCardComponents";
  card: EgdsImageLinkCard;
};

export type EmClickAction = {
  __typename?: "EMClickAction";
  action?: Maybe<UiLinkAction>;
  clickActionType: ClickActionType;
  description?: Maybe<Scalars["String"]>;
};

export type EmContentMetaData = {
  __typename?: "EMContentMetaData";
  contentId: Scalars["String"];
};

export type EmExperienceCardComponents = {
  __typename?: "EMExperienceCardComponents";
  clickActions?: Maybe<Array<Maybe<EmClickAction>>>;
  contentMetadata?: Maybe<EmContentMetaData>;
  mediaAssets?: Maybe<EmMediaComponents>;
  textComponents?: Maybe<EmRichTextComponents>;
};

export type EmImage = {
  __typename?: "EMImage";
  description: Scalars["String"];
  sourceSet?: Maybe<Array<EmImageSource>>;
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars["URL"];
};

export type EmImageSize = "L" | "M" | "S" | "XL" | "XS";

export type EmImageSource = {
  __typename?: "EMImageSource";
  aspectRatio: AspectRatio;
  dimensions: Dimensions;
  size: EmImageSize;
  url: Scalars["URL"];
};

export type EmMediaComponents = {
  __typename?: "EMMediaComponents";
  items?: Maybe<Array<Maybe<MediaAsset>>>;
};

export type EmMediaType = EmImage | Image | Video;

export type EmRichTextComponents = {
  __typename?: "EMRichTextComponents";
  description: Array<Description>;
  header?: Maybe<EgdsHeading>;
  subHeader?: Maybe<EgdsHeading>;
};

export type EmRichTextMediaActions = {
  __typename?: "EMRichTextMediaActions";
  clickActions?: Maybe<Array<Maybe<EmClickAction>>>;
  contentMetadata?: Maybe<EmContentMetaData>;
  mediaAssets?: Maybe<EmMediaComponents>;
  textComponents: EmRichTextComponents;
};

export type EmTextComponents = {
  __typename?: "EMTextComponents";
  description?: Maybe<Scalars["String"]>;
  header?: Maybe<EgdsHeading>;
  subHeader?: Maybe<EgdsHeading>;
};

export type EmTextListComponents = {
  __typename?: "EMTextListComponents";
  list: List;
  textComponents: EmTextComponents;
};

export type EmTextMediaActions = {
  __typename?: "EMTextMediaActions";
  clickActions?: Maybe<Array<Maybe<EmClickAction>>>;
  mediaAssets?: Maybe<EmMediaComponents>;
  textComponents: EmTextComponents;
};

export type EssCategory = {
  __typename?: "ESSCategory";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type EarnMessageContainerListItem = EgdsTextListItem & {
  __typename?: "EarnMessageContainerListItem";
  graphic: UiGraphic;
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  stylizedText?: Maybe<EgdsSpannableText>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type EditUniversalProfileResponse = {
  __typename?: "EditUniversalProfileResponse";
  changeEmail: UniversalProfileActionResponse;
  deleteAccount: UniversalProfileActionResponse;
  editBasicInformation: UniversalProfileActionResponse;
  editCommunicationPreference: UniversalProfileActionResponse;
  editContactInformation: UniversalProfileActionResponse;
  editContactInformationPhoneNumber: UniversalProfileActionResponse;
  editEmailCommunicationPreferences: UniversalProfileActionResponse;
  editPassportInformation: UniversalProfileActionResponse;
  editTravelerCompanion: UniversalProfileActionResponse;
  editTsaInformation: UniversalProfileActionResponse;
  signOut: UniversalProfileActionResponse;
  unsubscribeFromMultiplePreference: UniversalProfileActionResponse;
  unsubscribePreference: UniversalProfileActionResponse;
};

export type EditUniversalProfileResponseChangeEmailArgs = {
  request: UniversalProfileChangeEmailRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseDeleteAccountArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditBasicInformationArgs = {
  request: UniversalProfileBasicInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditCommunicationPreferenceArgs = {
  request: UniversalProfileCommunicationPreferenceRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditContactInformationArgs = {
  request: UniversalProfileContactInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditContactInformationPhoneNumberArgs = {
  request: UniversalProfileSmsContactInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditEmailCommunicationPreferencesArgs = {
  request: UniversalProfileCommunicationPreferenceEmailRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditPassportInformationArgs = {
  request: UniversalProfilePassportInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditTravelerCompanionArgs = {
  request: UniversalProfileTravelerCompanionRequestInput;
  universalProfileContext: UniversalProfileAdditionalTravelerContextInput;
};

export type EditUniversalProfileResponseEditTsaInformationArgs = {
  request: UniversalProfileTsaInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseSignOutArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseUnsubscribeFromMultiplePreferenceArgs = {
  request: UniversalProfileCommunicationPreferenceUnsubscribeCategoriesRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseUnsubscribePreferenceArgs = {
  request: UniversalProfileCommunicationPreferenceUnsubscribePreferenceRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditorialComponent = {
  __typename?: "EditorialComponent";
  content: EditorialComponentContent;
};

export type EditorialComponentContent =
  | EmCardComponents
  | EmExperienceCardComponents
  | EmMediaComponents
  | EmRichTextMediaActions
  | EmTextComponents
  | EmTextListComponents
  | EmTextMediaActions
  | StructuredContentMediaGalleryCarousel;

export type EditorialContent = TravelExperienceContent & {
  __typename?: "EditorialContent";
  id: Scalars["ID"];
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated */
  unformattedHtml?: Maybe<Scalars["String"]>;
};

export type EgAdditionalContext = {
  __typename?: "EgAdditionalContext";
  userInterface?: Maybe<EgUserInterface>;
};

export type EgClickStreamContractingPartnerCommonData = {
  __typename?: "EgClickStreamContractingPartnerCommonData";
  resource_id?: Maybe<Scalars["String"]>;
};

export type EgClickStreamPartnerCommon = {
  __typename?: "EgClickStreamPartnerCommon";
  promotion_id?: Maybe<Scalars["String"]>;
  resource_id?: Maybe<Scalars["String"]>;
};

export type EgClickstreamAdditionalContext = {
  __typename?: "EgClickstreamAdditionalContext";
  userInterface?: Maybe<EgClickstreamUserInterface>;
};

export type EgClickstreamData = IEgClickstreamData & {
  __typename?: "EgClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
};

export type EgClickstreamEvent = {
  __typename?: "EgClickstreamEvent";
  action_location?: Maybe<Scalars["String"]>;
  event_name: Scalars["String"];
  event_type: Scalars["String"];
  event_version: Scalars["String"];
};

export type EgClickstreamExperience = {
  __typename?: "EgClickstreamExperience";
  app_name?: Maybe<Scalars["String"]>;
  app_version?: Maybe<Scalars["String"]>;
  experience_platform?: Maybe<Scalars["String"]>;
  page_name?: Maybe<Scalars["String"]>;
  page_name_detailed?: Maybe<Scalars["String"]>;
  page_product_line?: Maybe<Scalars["String"]>;
};

export type EgClickstreamLodgingProduct = {
  __typename?: "EgClickstreamLodgingProduct";
  property_brand_id?: Maybe<Scalars["Int"]>;
  property_city_name?: Maybe<Scalars["String"]>;
  property_country_name?: Maybe<Scalars["String"]>;
  property_market?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  property_sub_market?: Maybe<Scalars["String"]>;
  property_type?: Maybe<Scalars["String"]>;
  structure_type_id?: Maybe<Scalars["Int"]>;
};

export type EgClickstreamPartnerAdditionalContextData = {
  __typename?: "EgClickstreamPartnerAdditionalContextData";
  user_interface?: Maybe<EgClickstreamPartnerUserInterfaceData>;
};

export type EgClickstreamPartnerCommonData = {
  __typename?: "EgClickstreamPartnerCommonData";
  resource_id?: Maybe<Scalars["String"]>;
  resource_type?: Maybe<Scalars["String"]>;
};

export type EgClickstreamPartnerFormData = {
  __typename?: "EgClickstreamPartnerFormData";
  form_id?: Maybe<Scalars["String"]>;
  full_field_name?: Maybe<Scalars["String"]>;
  short_field_name?: Maybe<Scalars["String"]>;
};

export type EgClickstreamPartnerUserInterfaceData = {
  __typename?: "EgClickstreamPartnerUserInterfaceData";
  component_element_text?: Maybe<Scalars["String"]>;
};

export type EgClickstreamProductListData = {
  __typename?: "EgClickstreamProductListData";
  lodging_product: EgClickstreamLodgingProduct;
};

export type EgClickstreamUserInterface = {
  __typename?: "EgClickstreamUserInterface";
  componentBadgeName?: Maybe<Scalars["String"]>;
  componentElementText?: Maybe<Scalars["String"]>;
  componentName?: Maybe<Scalars["String"]>;
};

export type EgClickstreamVirtualMarketCoachData = {
  __typename?: "EgClickstreamVirtualMarketCoachData";
  opportunities_available?: Maybe<Scalars["Int"]>;
  sort_position?: Maybe<Scalars["Int"]>;
};

export type EgNotificationAttributes = {
  __typename?: "EgNotificationAttributes";
  hasUnreadNotifications: Scalars["Boolean"];
  /** @deprecated No longer supported */
  notificationBool?: Maybe<Scalars["Int"]>;
  notificationCount?: Maybe<Scalars["Int"]>;
};

export type EgPropertyCount = {
  __typename?: "EgPropertyCount";
  propertyCount?: Maybe<Scalars["Int"]>;
};

export type EgSuggestionList = {
  __typename?: "EgSuggestionList";
  textDisplayed?: Maybe<Scalars["String"]>;
};

export type EgTypeAHead = {
  __typename?: "EgTypeAHead";
  searchInput?: Maybe<Scalars["String"]>;
  suggestionList?: Maybe<Array<Maybe<EgSuggestionList>>>;
};

export type EgTypeahead = {
  __typename?: "EgTypeahead";
  searchInput?: Maybe<Scalars["String"]>;
  suggestionList?: Maybe<Array<Maybe<EgTypeaheadSuggestionList>>>;
};

export type EgTypeaheadAdditionalContext = {
  __typename?: "EgTypeaheadAdditionalContext";
  userInterface?: Maybe<EgTypeaheadUserInterface>;
};

export type EgTypeaheadSuggestionList = {
  __typename?: "EgTypeaheadSuggestionList";
  textDisplayed?: Maybe<Scalars["String"]>;
};

export type EgTypeaheadUserInterface = {
  __typename?: "EgTypeaheadUserInterface";
  componentBadgeName?: Maybe<Scalars["String"]>;
  componentElementText?: Maybe<Scalars["String"]>;
};

export type EgUserInterface = {
  __typename?: "EgUserInterface";
  componentBadgeName?: Maybe<Scalars["String"]>;
  componentElementText?: Maybe<Scalars["String"]>;
};

export type EmailUsFormView = ContactUsErrorView | EmailUsSheet;

export type EmailUsSheet = {
  __typename?: "EmailUsSheet";
  back: Scalars["String"];
  customerEmail: EgdsEmailInputField;
  customerName: EgdsTextInputField;
  heading: Scalars["String"];
  message: EgdsTextAreaInputField;
  send: UiPrimaryButton;
  subheading: Scalars["String"];
  subject: EgdsTextInputField;
  toolBar: ContactUsToolBar;
};

export type EmptyStateType = "ENABLE_PUSH" | "EXPLORE_TRIPS" | "SIGN_IN";

export type EnhancedCleanlinessDialog = {
  __typename?: "EnhancedCleanlinessDialog";
  carouselButtonAllyStrings?: Maybe<Array<Scalars["String"]>>;
  content: Array<EnhancedCleanlinessDialogContent>;
  description: Scalars["String"];
  imageList?: Maybe<Array<Image>>;
  images?: Maybe<Array<HttpUri>>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  title: Scalars["String"];
};

export type EnhancedCleanlinessDialogContent = {
  __typename?: "EnhancedCleanlinessDialogContent";
  infos: Array<Scalars["String"]>;
  title: EnhancedCleanlinessInfoLineItem;
};

export type EnhancedCleanlinessInfo = {
  __typename?: "EnhancedCleanlinessInfo";
  dialog: EnhancedCleanlinessDialog;
  infoProviderText: Scalars["String"];
  infos: Array<EnhancedCleanlinessInfoLineItem>;
  seeAllAction: CarActionableItem;
  title: Scalars["String"];
  vendorCleanlinessBadge: HttpUri;
};

export type EnhancedCleanlinessInfoLineItem = {
  __typename?: "EnhancedCleanlinessInfoLineItem";
  icon: Icon;
  text: Scalars["String"];
};

export type EnrichedDetailsList = {
  __typename?: "EnrichedDetailsList";
  additionalDescription?: Maybe<EgdsText>;
  list?: Maybe<EgdsTextList>;
};

export type EntryData = {
  __typename?: "EntryData";
  id: Scalars["String"];
  timestamp: Scalars["String"];
  value: Scalars["Int"];
};

export type Error = {
  __typename?: "Error";
  errorCode: Scalars["String"];
  header: EgdsHeading;
  retryButton: UiPrimaryButton;
};

export interface ErrorMessagingPresentation {
  egcsErrorAnalytics: EgClickstreamEvent;
  errorAnalytics: ClientSideImpressionEventAnalytics;
}

export type EssentialInfoItem = {
  __typename?: "EssentialInfoItem";
  itemId: Scalars["String"];
};

export interface EssentialInfoItemInput {
  itemId: Scalars["String"];
}

export type EtpDialog = LodgingDialog & {
  __typename?: "EtpDialog";
  content: LodgingPaymentOptionsDialogContent;
  toolbar: LodgingDialogToolbar;
  trigger: LodgingDialogTriggerMessage;
};

export interface EventContentInput {
  typingIndicatorEventContent?: InputMaybe<TypingIndicatorEventContentInput>;
}

export type EventType =
  | "CancelledTripsItemDetailViewed"
  | "DeleteTripAlertPresented"
  | "DeleteTripDialogPresented"
  | "DeleteTripErrorToastPresented"
  | "DeleteTripFailed"
  | "DeleteTripSucceeded"
  | "DeleteTripSuccessToastPresented"
  | "HeroModuleCampaign"
  | "HeroModuleColdStart"
  | "ItinActiveCar"
  | "ItinActiveCruise"
  | "ItinActiveFlight"
  | "ItinActiveGT"
  | "ItinActiveHotel"
  | "ItinActiveLX"
  | "ItinActivePackageFH"
  | "ItinActiveRail"
  | "ItinCancellation"
  | "MoveItemDrawerPresented"
  | "MoveItemErrorToastPresented"
  | "MoveItemSubmitted"
  | "MoveItemSuccessToastPresented"
  | "MoveItemSuceeded"
  | "MoveLinkedItemsDialogPresented"
  | "RemoveItemDialogPresented"
  | "TripItemMenuPresented"
  | "TripSignInDialogPresented"
  | "TripsCancelledItemDetailViewed"
  | "TripsCollabInviteConfirmationViewed"
  | "TripsCollabInviteFailureViewed"
  | "TripsCollabInviteSucceeded"
  | "TripsCollabJoinGroupFailed"
  | "TripsCollabJoinGroupSucceeded"
  | "TripsCollabJoinTripConfirmationViewed"
  | "TripsCollabJoinTripErrorToastPresented"
  | "TripsCollabLandingScreenRedirectToTripOverView"
  | "TripsCollabLandingScreenViewed"
  | "TripsCollabManageGroupViewed"
  | "TripsCollabViewPresentedActivities"
  | "TripsCollabViewPresentedCars"
  | "TripsCollabViewPresentedCruise"
  | "TripsCollabViewPresentedFlight"
  | "TripsCollabViewPresentedHotel"
  | "TripsCollabViewPresentedVacationRental"
  | "TripsEditItemSucceeded"
  | "TripsEditTripErrorToastPresented"
  | "TripsEditTripPresented"
  | "TripsEditTripSubmitted"
  | "TripsEditTripSuccessToastPresented"
  | "TripsFlightScheduleChangeBannerDisplayed"
  | "TripsHeroModuleCurrentTrip"
  | "TripsHeroModulePossibleActivityTrip"
  | "TripsHeroModulePossibleAirTrip"
  | "TripsHeroModulePossibleCarTrip"
  | "TripsHeroModulePossibleHotelTrip"
  | "TripsHeroModulePossiblePackageHFTrip"
  | "TripsHeroModulePossibleTrip"
  | "TripsHeroModulePotentialTrip"
  | "TripsHeroModuleUpcomingTrip"
  | "TripsItemDetailViewed"
  | "TripsListViewed"
  | "TripsModuleDestinationGuide"
  | "TripsModuleRecommendedActivity"
  | "TripsModuleRecommendedCar"
  | "TripsModuleRecommendedHotel"
  | "TripsOverviewViewed"
  | "TripsTravelPlannerItinNumberPresent"
  | "Unknown";

export type ExitIntent = {
  __typename?: "ExitIntent";
  backgroundImageUrl?: Maybe<Scalars["String"]>;
  clickHereLinkUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  message: ExitIntentMessage;
};

export type ExitIntentMessage = {
  __typename?: "ExitIntentMessage";
  clickHere?: Maybe<Scalars["String"]>;
  couponCode?: Maybe<Scalars["String"]>;
  headingOne?: Maybe<Scalars["String"]>;
  headingThree?: Maybe<Scalars["String"]>;
  headingTwo?: Maybe<Scalars["String"]>;
  percentOff?: Maybe<Scalars["String"]>;
  termsAndConditionsOne?: Maybe<Scalars["String"]>;
  termsAndConditionsTwo?: Maybe<Scalars["String"]>;
};

export type ExpLineOfBusiness =
  | "ACTIVITIES"
  | "CAR"
  | "CRUISES"
  | "FLIGHT"
  | "GROUNDTRANSFERS"
  | "HOTEL"
  | "PACKAGES"
  | "RAILS"
  | "UNCLASSIFIED";

export type ExpandButton = {
  __typename?: "ExpandButton";
  collapseButton: Scalars["String"];
  expandButton: Scalars["String"];
};

export type ExpandoPeekOptions = {
  __typename?: "ExpandoPeekOptions";
  hiddenThreshold: Scalars["Int"];
  seeLessLabel: Scalars["String"];
  seeMoreLabel: Scalars["String"];
};

export type ExpandoTriggerPosition = "LEADING" | "TRAILING";

export type Experience = {
  __typename?: "Experience";
  appName: Scalars["String"];
  appVersion: Scalars["String"];
  pageName: Scalars["String"];
  pageNameDetailed: Scalars["String"];
};

export type ExperienceScoreBody = EgdsGraphicText | EgdsPlainText;

export type ExperienceScoreBundle = {
  __typename?: "ExperienceScoreBundle";
  icon?: Maybe<EgdsGraphicText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  message?: Maybe<ExperienceScoreBundleMessage>;
  title?: Maybe<Scalars["String"]>;
};

export type ExperienceScoreBundleMessage = {
  __typename?: "ExperienceScoreBundleMessage";
  body?: Maybe<Array<ExperienceScoreBody>>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer?: Maybe<EgdsPlainText>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
};

export type ExperienceScoreInfo = {
  __typename?: "ExperienceScoreInfo";
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  primary?: Maybe<ExperienceScorePrimarySection>;
  secondary?: Maybe<Array<ExperienceScoreBundle>>;
};

export type ExperienceScorePrimarySection = {
  __typename?: "ExperienceScorePrimarySection";
  experienceScoreRating?: Maybe<ExperienceScoreRating>;
  productAttribute?: Maybe<ProductTextInfoSection>;
  productRating?: Maybe<ExperienceScoreProductRating>;
};

export type ExperienceScoreProductRating = {
  __typename?: "ExperienceScoreProductRating";
  analytics?: Maybe<ClientSideAnalytics>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  rating: ProductRatingSummary;
};

export type ExperienceScoreRating = {
  __typename?: "ExperienceScoreRating";
  disclaimer?: Maybe<ProductTextInfoSection>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
  supportingMessages?: Maybe<Array<Scalars["String"]>>;
};

export type Experiment = {
  __typename?: "Experiment";
  experimentId?: Maybe<Scalars["String"]>;
  experimentValue?: Maybe<Scalars["Int"]>;
};

export type ExperimentType = {
  __typename?: "ExperimentType";
  bucket: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ExperimentalAction = {
  __typename?: "ExperimentalAction";
  analytics?: Maybe<ExperimentalAnalytics>;
  data: ExperimentalLink;
  type: ActionType;
};

export type ExperimentalAnalytics = {
  __typename?: "ExperimentalAnalytics";
  appendToReferrerId: Scalars["String"];
  client: ClientSideAnalytics;
};

export type ExperimentalButton = {
  __typename?: "ExperimentalButton";
  action: ExperimentalAction;
  type: EgdsButtonStyle;
};

export type ExperimentalCardConfig = {
  __typename?: "ExperimentalCardConfig";
  background?: Maybe<Scalars["String"]>;
  border: Scalars["Boolean"];
  featuredHeader?: Maybe<ExperimentalCardFeaturedHeader>;
  featuredImage?: Maybe<Image>;
  link?: Maybe<ExperimentalCardLink>;
  overflow: Scalars["Boolean"];
  padded: Scalars["Boolean"];
  selected: Scalars["Boolean"];
};

export type ExperimentalCardFeaturedHeader = {
  __typename?: "ExperimentalCardFeaturedHeader";
  tag?: Maybe<ConfigTag>;
  text: Scalars["String"];
};

export type ExperimentalCardLink = {
  __typename?: "ExperimentalCardLink";
  action: ExperimentalLinkAction;
  text: Scalars["String"];
};

export type ExperimentalCarouselAnalytics = {
  __typename?: "ExperimentalCarouselAnalytics";
  scrollEvent?: Maybe<ClientSideAnalytics>;
  viewed?: Maybe<ClientSideAnalytics>;
};

export type ExperimentalCarouselConfig = {
  __typename?: "ExperimentalCarouselConfig";
  analytics?: Maybe<ExperimentalCarouselAnalytics>;
  hideNav: Scalars["Boolean"];
  imagesList?: Maybe<Array<Image>>;
  itemsVisible?: Maybe<BreakpointConfig>;
  nextButton?: Maybe<Scalars["String"]>;
  prevButton?: Maybe<Scalars["String"]>;
};

export type ExperimentalComponent = ExperimentalRaOneComponent & {
  __typename?: "ExperimentalComponent";
  config: ExperimentalConfig;
  metadata: ExperimentalMetadata;
  type: ExperimentalElements;
  typeClass: Scalars["String"];
};

export type ExperimentalComponentImplementation = ExperimentalRaOneComponent & {
  __typename?: "ExperimentalComponentImplementation";
  children?: Maybe<Array<ExperimentalComponentImplementation>>;
  config: ExperimentalConfig;
  metadata: ExperimentalMetadata;
  type: ExperimentalElements;
};

export type ExperimentalComponentWrapper = {
  __typename?: "ExperimentalComponentWrapper";
  childrenIds: Array<Scalars["String"]>;
  element: ExperimentalComponent;
  id: Scalars["String"];
};

export type ExperimentalConfig = {
  __typename?: "ExperimentalConfig";
  card?: Maybe<ExperimentalCardConfig>;
  carousel?: Maybe<ExperimentalCarouselConfig>;
  expando?: Maybe<ExperimentalExpandoConfig>;
  grid?: Maybe<ExperimentalLayoutGrid>;
  infoSection?: Maybe<ExperimentalInfoSection>;
  intersection?: Maybe<ExperimentalIntersectionTracker>;
  modal?: Maybe<ExperimentalModalConfig>;
  rating?: Maybe<ExperimentalRatingConfig>;
  spacing?: Maybe<ExperimentalSpacing>;
  tabs?: Maybe<ExperimentalTabsConfig>;
  textIconTooltip?: Maybe<ProductTextInfoSection>;
};

export type ExperimentalElements =
  | "Card"
  | "Carousel"
  | "Expando"
  | "Grid"
  | "InfoSection"
  | "IntersectionTracker"
  | "Modal"
  | "Rating"
  | "Spacing"
  | "Tabs"
  | "TextIconTooltip";

export type ExperimentalExpandoConfig = {
  __typename?: "ExperimentalExpandoConfig";
  analytics?: Maybe<ExperimentalAnalytics>;
  collapseLabel?: Maybe<Scalars["String"]>;
  expandLabel: Scalars["String"];
  hideScrim: Scalars["Boolean"];
  isList: Scalars["Boolean"];
  itemsToShow?: Maybe<Scalars["Int"]>;
  linesToShow?: Maybe<Scalars["Int"]>;
};

export type ExperimentalInfoSection = {
  __typename?: "ExperimentalInfoSection";
  badge?: Maybe<EgdsStandardBadge>;
  buttons?: Maybe<Array<ExperimentalButton>>;
  descriptions?: Maybe<ExperimentalInfoSectionDescriptions>;
  links?: Maybe<Array<ExperimentalAction>>;
  subTitle?: Maybe<Array<ExperimentalInfoSectionSubTitle>>;
  title?: Maybe<ExperimentalInfoSectionTitle>;
};

export type ExperimentalInfoSectionDescriptions = {
  __typename?: "ExperimentalInfoSectionDescriptions";
  showAsList: Scalars["Boolean"];
  texts?: Maybe<Array<Scalars["String"]>>;
};

export type ExperimentalInfoSectionSubTitle = {
  __typename?: "ExperimentalInfoSectionSubTitle";
  space?: Maybe<Space>;
  text?: Maybe<EgdsStylizedText>;
};

export type ExperimentalInfoSectionTitle = {
  __typename?: "ExperimentalInfoSectionTitle";
  spacing?: Maybe<ExperimentalSpacing>;
  text?: Maybe<EgdsStylizedText>;
};

export type ExperimentalIntersectionTracker = {
  __typename?: "ExperimentalIntersectionTracker";
  analytics: ClientSideAnalytics;
  trackOnce: Scalars["Boolean"];
};

export type ExperimentalLayoutGrid = {
  __typename?: "ExperimentalLayoutGrid";
  columns: Scalars["Int"];
  gridItem?: Maybe<Array<ExperimentalLayoutGridItem>>;
  space?: Maybe<Space>;
};

export type ExperimentalLayoutGridItem = {
  __typename?: "ExperimentalLayoutGridItem";
  colSpan: Scalars["Int"];
};

export type ExperimentalLink = {
  __typename?: "ExperimentalLink";
  action: ExperimentalLinkAction;
  disabled?: Maybe<Scalars["Boolean"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  text: Scalars["String"];
};

export type ExperimentalLinkAction = {
  __typename?: "ExperimentalLinkAction";
  clickEvent?: Maybe<ClientSideAnalytics>;
  modal?: Maybe<ExperimentalModal>;
  target?: Maybe<UiLinkTarget>;
  url?: Maybe<Scalars["String"]>;
};

export type ExperimentalMetadata = {
  __typename?: "ExperimentalMetadata";
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type ExperimentalModal = {
  __typename?: "ExperimentalModal";
  id: Scalars["String"];
  queryKey?: Maybe<Scalars["String"]>;
};

export type ExperimentalModalConfig = {
  __typename?: "ExperimentalModalConfig";
  fullWidth: Scalars["Boolean"];
  isExpanded: Scalars["Boolean"];
  modalId: ExperimentalModal;
  position: ExperimentalModalPosition;
  sideSheetLayout: ModalSideSheetLayout;
  toolbar?: Maybe<ExperimentalModalToolbar>;
  type: ExperimentalModalType;
};

export type ExperimentalModalPosition = "LEADING" | "TRAILING";

export type ExperimentalModalToolbar = {
  __typename?: "ExperimentalModalToolbar";
  analytics?: Maybe<ExperimentalAnalytics>;
  closeLabel: Scalars["String"];
  navType: ModalNavType;
  title: Scalars["String"];
};

export type ExperimentalModalType = "BOTTOM" | "CENTERED" | "FULL" | "POPOVER" | "SIDE";

export interface ExperimentalRaOneComponent {
  config: ExperimentalConfig;
  metadata: ExperimentalMetadata;
  type: ExperimentalElements;
}

export type ExperimentalRatingConfig = {
  __typename?: "ExperimentalRatingConfig";
  accessibility?: Maybe<Scalars["String"]>;
  iconId?: Maybe<Scalars["String"]>;
  rating: Scalars["Float"];
};

export type ExperimentalSpacing = {
  __typename?: "ExperimentalSpacing";
  /** @deprecated Conflicts with the native definition, use marginSpace instead */
  margin?: Maybe<Spacing>;
  marginSpace?: Maybe<SpacingOptions>;
  /** @deprecated Conflicts with the native definition, use paddingSpace instead */
  padding?: Maybe<Spacing>;
  paddingSpace?: Maybe<SpacingOptions>;
};

export type ExperimentalTab = {
  __typename?: "ExperimentalTab";
  analytics?: Maybe<ExperimentalAnalytics>;
  disabled: Scalars["Boolean"];
  target: Scalars["String"];
  title: Scalars["String"];
};

export type ExperimentalTabsConfig = {
  __typename?: "ExperimentalTabsConfig";
  border: Scalars["Boolean"];
  isJumpLink: Scalars["Boolean"];
  scrollTo: Scalars["Boolean"];
  selectedIndex: Scalars["Int"];
  tabs: Array<ExperimentalTab>;
  type: TabType;
};

export type ExploreCard = {
  __typename?: "ExploreCard";
  button?: Maybe<EgdsButton>;
  heading?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  message?: Maybe<Scalars["String"]>;
};

export type ExploreContent = {
  __typename?: "ExploreContent";
  button?: Maybe<EgdsButton>;
  recommendations?: Maybe<DestinationRecommendationGroup>;
  sheet?: Maybe<EgdsSheet>;
};

export interface ExploreDestinationCriteriaInput {
  destinationId: Scalars["String"];
  exploreIds?: InputMaybe<Array<Scalars["String"]>>;
}

export type ExploreResults = {
  __typename?: "ExploreResults";
  card?: Maybe<ExploreCard>;
  content?: Maybe<ExploreContent>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type Exposure = {
  __typename?: "Exposure";
  bucket: Scalars["Int"];
  id: Scalars["String"];
};

export interface ExposureInput {
  bucket: Scalars["Int"];
  id: Scalars["String"];
}

export interface FopItemInput {
  id: Scalars["String"];
  paymentContextualInfo?: InputMaybe<PaymentContextualInfoInput>;
  paymentMethod: PaymentMethodType;
  state: Scalars["String"];
  storedCardInstrument?: InputMaybe<StoredCardInstrumentInput>;
}

export interface FailureReason {
  description: Scalars["String"];
  message: Scalars["String"];
}

export type FallBack = {
  __typename?: "FallBack";
  message: Scalars["String"];
  moduleName: ModuleName;
  operation: FallbackOperation;
  signals: Array<CouponSignal>;
  type: FallBackType;
};

export type FallBackType = "ERROR";

export type FallbackOperation = "APPLY_COUPON" | "REMOVE_COUPON";

export type FareAction = {
  __typename?: "FareAction";
  action: FlightsActionButton;
  clickAdaptExAnalytics: Array<FlightsAdaptExAnalytics>;
  dialog?: Maybe<EgdsDialog>;
  states: Array<FareState>;
};

export type FareChoiceInformationDynamicElement = {
  __typename?: "FareChoiceInformationDynamicElement";
  farePricePerPerson: Scalars["String"];
};

export type FareDetails = {
  __typename?: "FareDetails";
  changeAndCancellationFees?: Maybe<FlightsChangeAndCancellationFees>;
  changePolicies?: Maybe<FlightsTextSection>;
  defaultBaggageInformation?: Maybe<FlightsBaggageInformation>;
  /** @deprecated Use ancillarySummaryCard query for Seat Map in the selected fare. */
  fareSeatMapInformation?: Maybe<FareSeatMapInformation>;
  fareSummary?: Maybe<FareSummary>;
};

export type FareHeading = {
  __typename?: "FareHeading";
  badge?: Maybe<EgdsBadge>;
  fareDetails?: Maybe<Array<EgdsText>>;
  farePricing: Array<FarePricing>;
};

export type FareInformationCard = {
  __typename?: "FareInformationCard";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  action: Array<FareAction>;
  amenities: FlightsCategorizedList;
  /** @deprecated Not needed as we now use the analytics from the action button on the fare card */
  clickEventAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  displayAdaptExAnalytics: Array<FlightsAdaptExAnalytics>;
  fareCardIdentifier: Scalars["String"];
  fareDetails: Array<EgdsText>;
  fareMessage?: Maybe<EgdsStylizedText>;
  fareTabs?: Maybe<EgdsBasicTabs>;
  featuredHeader?: Maybe<Scalars["String"]>;
  heading: FareHeading;
  highlighted: Scalars["Boolean"];
  isPopularFare: Scalars["Boolean"];
  isRefundableFare: Scalars["Boolean"];
  journeyContinuationId: Scalars["String"];
  priceMatchPromise?: Maybe<FarePriceMatchPromisePresentation>;
  scrollAnalytics: Array<ClientSideAnalytics>;
  sponsoredContent?: Maybe<FlightsSponsoredFareInformation>;
  /** @deprecated Moved field to be under FlightListingsAnalytics */
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  state: FareState;
};

export type FareMediaSection = {
  __typename?: "FareMediaSection";
  badge?: Maybe<EgdsStandardBadge>;
  featureImage: Image;
};

export type FareNameAvailable = "AVAILABLE" | "NOT_AVAILABLE";

export interface FarePriceMatchPromiseCard {
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  subHeading: Scalars["String"];
}

export type FarePriceMatchPromiseCardHeadingType = EgdsBasicSectionHeading | EgdsGraphicText;

export type FarePriceMatchPromisePresentation = {
  __typename?: "FarePriceMatchPromisePresentation";
  cards: Array<FarePriceMatchPromiseCard>;
  priceMatchPromiseState: PriceMatchPromiseState;
};

export type FarePriceMatchPromiseRemovableCard = FarePriceMatchPromiseCard & {
  __typename?: "FarePriceMatchPromiseRemovableCard";
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  removeAction: FlightsActionLink;
  subHeading: Scalars["String"];
};

export type FarePriceMatchPromiseSelectableCard = FarePriceMatchPromiseCard & {
  __typename?: "FarePriceMatchPromiseSelectableCard";
  checkbox: FlightsActionCheckbox;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use headingSection for representing header */
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  headingSection: FarePriceMatchPromiseCardHeadingType;
  infoTrigger: FlightsDialogTrigger;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  price: Scalars["String"];
  priceInfo?: Maybe<Scalars["String"]>;
  priceMatchPromiseState: PriceMatchPromiseState;
  /** @deprecated Use description instead of subheading favoring optionality */
  subHeading: Scalars["String"];
};

export type FarePriceMatchPromiseStaticCard = FarePriceMatchPromiseCard & {
  __typename?: "FarePriceMatchPromiseStaticCard";
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  subHeading: Scalars["String"];
};

export type FarePricing = {
  __typename?: "FarePricing";
  addOnIcons?: Maybe<Array<Icon>>;
  priceLockup: EgdsPriceLockup;
  priceSection?: Maybe<FlightsDetailsAndFaresPriceSection>;
  states: Array<FareState>;
};

export type FareSeatMapAlertDialog = {
  __typename?: "FareSeatMapAlertDialog";
  alert: FlightsDialogStandard;
  id: FlightsActionType;
};

export type FareSeatMapAvailableFlightLegSelection = {
  __typename?: "FareSeatMapAvailableFlightLegSelection";
  errorAnalytics: FlightsAnalytics;
  identifier: Scalars["String"];
  isSelected: Scalars["Boolean"];
  seatMapLegSummary?: Maybe<FlightsSeatMapLegSummary>;
  seatSegment?: Maybe<SeatSegment>;
  selectedCard: FlightsIconPlacard;
  unselectedCard: FlightsIconPlacard;
};

export type FareSeatMapAvailableFlightLegsDetails = {
  __typename?: "FareSeatMapAvailableFlightLegsDetails";
  flightLegSelectionList: Array<FareSeatMapAvailableFlightLegSelection>;
  offerIdentifier: Scalars["String"];
  onViewedAnalytics?: Maybe<Array<FlightsAnalytics>>;
  pricingConstruct?: Maybe<Scalars["String"]>;
};

export type FareSeatMapDynamicElement = {
  __typename?: "FareSeatMapDynamicElement";
  flightLegsDetails?: Maybe<FareSeatMapAvailableFlightLegsDetails>;
  onSelectAnalytics: Array<FlightsAnalytics>;
};

export type FareSeatMapInformation = {
  __typename?: "FareSeatMapInformation";
  /** @deprecated This field is now deprecated., replace with Use baseOfferAncillaryID */
  ancillaryId?: Maybe<Scalars["String"]>;
  details?: Maybe<FlightsAction>;
  enableSeatSelection?: Maybe<Scalars["Boolean"]>;
  /** @deprecated This field is now deprecated., replace with Use the sibling node with list of FlightsSeatSelectionErrorMessage */
  errorMessage?: Maybe<Scalars["String"]>;
  errorMessages?: Maybe<Array<FlightsSeatSelectionErrorMessage>>;
  fareSeatMapViewDetails?: Maybe<FareSeatMapViewDetails>;
  message: Scalars["String"];
  selectedSeatDetails?: Maybe<Array<FlightsSelectedSeatDetail>>;
  skeletonCount: Scalars["Int"];
  title: Scalars["String"];
};

export type FareSeatMapLegendsInformation = {
  __typename?: "FareSeatMapLegendsInformation";
  accessibilityMessage: Scalars["String"];
  dismissSeatMapLegends?: Maybe<FlightsAnalytics>;
  legendDetails: FlightsSeatMapLegends;
  viewLegendButton?: Maybe<FlightsActionableIcon>;
};

export type FareSeatMapViewDetails = {
  __typename?: "FareSeatMapViewDetails";
  dismissSeatMapDialog: FlightsActionableIcon;
  errorMessage?: Maybe<FlightsIconPlacard>;
  fareSeatMapLegendsInformation?: Maybe<FareSeatMapLegendsInformation>;
  flightLegsDetails?: Maybe<FareSeatMapAvailableFlightLegsDetails>;
  onLoadAccessibilityMessage?: Maybe<Scalars["String"]>;
  seatMapAlertDialog?: Maybe<Array<FareSeatMapAlertDialog>>;
  seatMapDialogAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
  title: Scalars["String"];
};

export type FareSectionTitle = {
  __typename?: "FareSectionTitle";
  fareNameAvailable?: Maybe<FareNameAvailable>;
  icon?: Maybe<Icon>;
  title?: Maybe<EgdsHeading>;
};

export type FareState =
  | "META_UNSELECTED"
  | "SELECTED"
  | "SELECTED_PMP_SELECTED"
  | "UNSELECTED"
  | "UNSELECTED_PMP_SELECTED";

export type FareSummary = {
  __typename?: "FareSummary";
  additionalDetails?: Maybe<FlightsCategorizedList>;
  details: FlightsAction;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  flightsFareNudgeMessage?: Maybe<FlightsIconAndLabel>;
  /** @deprecated Replaced with details and fares node */
  flightsSelectedJourneyAvailableFaresInformation?: Maybe<FlightsSelectedJourneyAvailableFares>;
  message: Scalars["String"];
  title: Scalars["String"];
  upsellNudgingFeatureEnabled: Scalars["Boolean"];
};

export type FaresLoadingAnalytics = {
  __typename?: "FaresLoadingAnalytics";
  continueButtonOnClickAnalytics: ClientSideAnalytics;
  faresLoadErrorAnalytics: ClientSideAnalytics;
  faresLoadingDisplayedAnalytics: ClientSideAnalytics;
  faresLoadingTimeAnalytics: ClientSideAnalytics;
};

export type FaresTypesLoadingAnalytics = {
  __typename?: "FaresTypesLoadingAnalytics";
  continueButtonOnClickAnalytics: FlightsAnalytics;
  faresLoadErrorAnalytics: FlightsAnalytics;
  faresLoadingDisplayedAnalytics: FlightsAnalytics;
  faresLoadingTimeAnalytics: FlightsAnalytics;
};

export type FederationPropertyNaturalKey = {
  __typename?: "FederationPropertyNaturalKey";
  checkIn: FederationPropertyNaturalKeyDate;
  checkOut: FederationPropertyNaturalKeyDate;
  id: Scalars["String"];
  inventoryType: InventoryType;
  noCreditCard?: Maybe<Scalars["Boolean"]>;
  ratePlanId: Scalars["String"];
  ratePlanType?: Maybe<Scalars["String"]>;
  roomTypeId: Scalars["String"];
  rooms: Array<FederationPropertyNaturalKeyRoom>;
  shoppingPath?: Maybe<ShoppingPathType>;
};

export type FederationPropertyNaturalKeyDate = {
  __typename?: "FederationPropertyNaturalKeyDate";
  day: Scalars["Int"];
  month: Scalars["Int"];
  year: Scalars["Int"];
};

export type FederationPropertyNaturalKeyRoom = {
  __typename?: "FederationPropertyNaturalKeyRoom";
  childAges: Array<Scalars["Int"]>;
  numberOfAdults: Scalars["Int"];
};

export type FeeType =
  | "CARD_ON_FILE_DAMAGE_LIABILITY"
  | "DEFAULT"
  | "DEFAULT_SUPPLIER_COLLECTED"
  | "GROUPED_MANDATORY_FEES"
  | "L26_TAX_BREAKOUT"
  | "NORMAL"
  | "OTHER"
  | "PER_PERSON_PER_NIGHT"
  | "PROPERTY"
  | "REFUNDABLE_DAMAGE_DEPOSIT"
  | "RESORT";

export type Feedback = {
  __typename?: "Feedback";
  action?: Maybe<FeedbackAction>;
};

export type FeedbackAction = {
  __typename?: "FeedbackAction";
  text?: Maybe<Scalars["String"]>;
};

export type FeedbackIndicator = AccessibilityField & {
  __typename?: "FeedbackIndicator";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
};

export type FeedbackOption = {
  __typename?: "FeedbackOption";
  inputHeading: Scalars["String"];
  inputTextPlaceholder: Scalars["String"];
  option: CarActionableItem;
};

export type Fees = {
  __typename?: "Fees";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  inSaleCurrency?: Maybe<Scalars["Boolean"]>;
  included?: Maybe<Scalars["Boolean"]>;
  price?: Maybe<Money>;
  type?: Maybe<FeeType>;
};

export type File = {
  __typename?: "File";
  error?: Maybe<Scalars["String"]>;
  errorEntries?: Maybe<Scalars["Int"]>;
  fileCategory: Scalars["String"];
  fileLocation: Scalars["String"];
  fileName: Scalars["String"];
  fileType: Scalars["String"];
  fileUploadDateTime: Scalars["String"];
  id: Scalars["Int"];
  outputFilePath?: Maybe<Scalars["String"]>;
  processedEntries?: Maybe<Scalars["Int"]>;
  rerunCount?: Maybe<Scalars["Int"]>;
  runStatus?: Maybe<Scalars["String"]>;
  totalEntries?: Maybe<Scalars["Int"]>;
  userEmail: Scalars["String"];
  userId: Scalars["String"];
};

export type FilterCard = EgdsCarouselItem & {
  __typename?: "FilterCard";
  actions: Array<EgdsButton>;
  filters: Array<EgdsButton>;
  heading: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  illustration: Illustration;
  pageIndex: Scalars["String"];
  subheading: Scalars["String"];
};

export type FilterIntentCarouselCard = EgdsCarouselItem & {
  __typename?: "FilterIntentCarouselCard";
  criteria: ShoppingSearchCriteria;
  heading: Scalars["String"];
  icon: Icon;
  id?: Maybe<Scalars["String"]>;
  list: EgdsTextIconList;
  selectedAnalytics?: Maybe<ClientSideAnalytics>;
  tapAnalytics?: Maybe<ClientSideAnalytics>;
};

export type FilterIntentMissingIntentCarouselCard = EgdsCarouselItem & {
  __typename?: "FilterIntentMissingIntentCarouselCard";
  filterIntentTypeInputSheet?: Maybe<FilterIntentTypeInputSheet>;
  id?: Maybe<Scalars["String"]>;
  selectedAnalytics: ClientSideAnalytics;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type FilterIntentSheet = {
  __typename?: "FilterIntentSheet";
  button: UiPrimaryButton;
  carousel: EgdsCarousel;
  closeAnalytics: ClientSideAnalytics;
  presentedAnalytics: ClientSideAnalytics;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type FilterIntentTypeConfirmationSheet = {
  __typename?: "FilterIntentTypeConfirmationSheet";
  autoCloseTimeout?: Maybe<Scalars["Int"]>;
  closeAnalytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type FilterIntentTypeInputSheet = {
  __typename?: "FilterIntentTypeInputSheet";
  closeAnalytics: ClientSideAnalytics;
  confirmationSheet?: Maybe<FilterIntentTypeConfirmationSheet>;
  input: EgdsTextInputField;
  inputAnalytics?: Maybe<ClientSideAnalytics>;
  primaryButton: UiPrimaryButton;
  secondaryButton: UiSecondaryButton;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type FilterItem = {
  __typename?: "FilterItem";
  field: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type FilterOption = UiToggle & {
  __typename?: "FilterOption";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  filteredValues: Array<Scalars["String"]>;
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type FilterOptionRange = {
  __typename?: "FilterOptionRange";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
};

export type FilterPlacement = "OVERLAY_BOTTOM" | "OVERLAY_TOP" | "TOOLBAR";

export type FilterPolicyType = "NO_CHANGE_FEE";

export type FilterTravelAndBaggageType =
  | "CARRY_ON_BAG"
  | "FIRST_CHECKED_BAG"
  | "NO_CANCEL_FEE"
  | "NO_CHANGE_FEE"
  | "SEAT_CHOICE";

export type FilterViewModel = {
  __typename?: "FilterViewModel";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated No longer used, was part of a losing variant in tnl 42928 */
  dropdownOptions?: Maybe<ShowDropdownOptions>;
  graphic?: Maybe<UiGraphic>;
  initiatedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  isRange?: Maybe<Scalars["Boolean"]>;
  label?: Maybe<Scalars["String"]>;
  max?: Maybe<Scalars["Int"]>;
  min?: Maybe<Scalars["Int"]>;
  /** @deprecated This field will be deprecated in the future, use title instead */
  name: Scalars["String"];
  options: Array<OptionViewModel>;
  placeholder?: Maybe<Scalars["String"]>;
  /** @deprecated This value has been refactored into showListOptions and is no longer used */
  seeMoreOptionsToggleThreshold?: Maybe<Scalars["Int"]>;
  selected: Scalars["Boolean"];
  showListOptions?: Maybe<ExpandoPeekOptions>;
  step?: Maybe<Scalars["Int"]>;
  subLabel?: Maybe<Scalars["String"]>;
  submittedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export type FiltersWrapper = {
  __typename?: "FiltersWrapper";
  carousel: EgdsCarousel;
  heading: Scalars["String"];
};

export type FindAnswersDialog = {
  __typename?: "FindAnswersDialog";
  dialogName: Scalars["String"];
  header: LodgingDialogToolbar;
  trigger: FindAnswersDialogTrigger;
};

export interface FindAnswersDialogTrigger {
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
}

export type FindAnswersMessage = {
  __typename?: "FindAnswersMessage";
  dialog: FindAnswersDialog;
  supportingText?: Maybe<Scalars["String"]>;
};

export type FlavorType = "DEMO" | "INT" | "LAB" | "PROD" | "SANDBOX";

export type FlexAlignItems = "CENTER" | "END" | "START" | "STRETCH";

export type FlexDirection = "ROW";

export type FlexJustifyContent = "CENTER" | "END" | "SPACE_AROUND" | "SPACE_BETWEEN" | "START";

export type FlexWrap = "NOWRAP";

export type FlexibleDateSearchButton = {
  __typename?: "FlexibleDateSearchButton";
  button?: Maybe<UiSecondaryButton>;
  datePeriods?: Maybe<Array<DatePeriod>>;
};

export type FlexibleDateSearchCard = {
  __typename?: "FlexibleDateSearchCard";
  changeDatesButton?: Maybe<UiSecondaryButton>;
  content?: Maybe<EgdsStandardMessagingCard>;
  flexibleDateSearchButtons?: Maybe<Array<FlexibleDateSearchButton>>;
};

export type FlexibleSearchCriteria = {
  __typename?: "FlexibleSearchCriteria";
  flexibility?: Maybe<Array<DatePeriod>>;
  /** @deprecated Please use searchRange */
  flexibleSearchMonths?: Maybe<Array<Scalars["Int"]>>;
  flexibleSearchRequirement?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated Please use searchRange */
  flexibleSearchTripDurationType?: Maybe<FlexibleSearchTripDurationType>;
  /** @deprecated Please use flexibility */
  numFlexibleDays?: Maybe<Scalars["Int"]>;
  searchRange?: Maybe<Array<DateRange>>;
};

export interface FlexibleSearchCriteriaInput {
  flexibility?: InputMaybe<Array<DatePeriodInput>>;
  flexibleSearchMonths?: InputMaybe<Array<Scalars["Int"]>>;
  flexibleSearchRequirement?: InputMaybe<Array<Scalars["String"]>>;
  flexibleSearchTripDurationType?: InputMaybe<FlexibleSearchTripDurationType>;
  numFlexibleDays?: InputMaybe<Scalars["Int"]>;
  searchRange?: InputMaybe<Array<DateRangeInput>>;
}

export type FlexibleSearchTripDurationType = "MONTH" | "WEEK" | "WEEKEND";

export type FlightAmenities = {
  __typename?: "FlightAmenities";
  amenities?: Maybe<EgdsTextIconList>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  title: EgdsSpannableText;
};

export type FlightAncillariesPerTraveler = {
  __typename?: "FlightAncillariesPerTraveler";
  ancillaryOptions?: Maybe<Array<FlightSelectableAncillary>>;
  errorMessage?: Maybe<EgdsText>;
  /** @deprecated This must be replaced by travellerIdentifier because when there is only one traveller we do not show heading */
  heading: EgdsHeading;
  travelerIndex: Scalars["Int"];
  travellerIdentifier?: Maybe<EgdsText>;
};

export type FlightAncillaryBagSelection = {
  __typename?: "FlightAncillaryBagSelection";
  availableOptionsCount?: Maybe<Scalars["Int"]>;
  selectedBagIndex: Scalars["Int"];
  selectedBagPrice?: Maybe<Scalars["Float"]>;
};

export interface FlightAncillaryBagSelectionInput {
  availableOptionsCount?: InputMaybe<Scalars["Int"]>;
  selectedBagIndex: Scalars["Int"];
  selectedBagPrice?: InputMaybe<Scalars["Float"]>;
}

export type FlightAncillaryJourneySelection = {
  __typename?: "FlightAncillaryJourneySelection";
  applySameAncillaryForAllTravellers: Scalars["Boolean"];
  checkedBagIncluded?: Maybe<Scalars["Int"]>;
  flightAncillaryTravelerSelections: Array<FlightAncillaryTravelerSelection>;
  journeyIndex: Scalars["Int"];
};

export interface FlightAncillaryJourneySelectionInput {
  applySameAncillaryForAllTravellers: Scalars["Boolean"];
  checkedBagIncluded?: InputMaybe<Scalars["Int"]>;
  flightAncillaryTravelerSelections: Array<FlightAncillaryTravelerSelectionInput>;
  journeyIndex: Scalars["Int"];
}

export type FlightAncillaryTravelerSelection = {
  __typename?: "FlightAncillaryTravelerSelection";
  combinedAncillaryIndex?: Maybe<Scalars["Int"]>;
  flightAncillaryBagSelections: Array<FlightAncillaryBagSelection>;
  travelerIndex: Scalars["Int"];
};

export interface FlightAncillaryTravelerSelectionInput {
  combinedAncillaryIndex?: InputMaybe<Scalars["Int"]>;
  flightAncillaryBagSelections: Array<FlightAncillaryBagSelectionInput>;
  travelerIndex: Scalars["Int"];
}

export type FlightBaggageInformationDetail = {
  __typename?: "FlightBaggageInformationDetail";
  baggageDetail: Scalars["String"];
  /** @deprecated Will start sending all details for baggage in baggageDetail node */
  detail: Scalars["String"];
  topic: Scalars["String"];
  unit?: Maybe<Scalars["String"]>;
};

export type FlightCabinClassInfo = "BUSINESS" | "COACH" | "FIRST" | "PREMIUM_ECONOMY";

export type FlightCardDurationTimeline = {
  __typename?: "FlightCardDurationTimeline";
  /** @deprecated Use 'secondary' field to support different screen sizes */
  additionalInfo: Scalars["String"];
  end: DurationTimelineSideSection;
  /** @deprecated Use 'primary' field to support different screen sizes */
  heading: Scalars["String"];
  primary: EgdsStylizedText;
  secondary: EgdsTextSection;
  start: DurationTimelineSideSection;
  stops: Scalars["Int"];
};

export type FlightCost = {
  __typename?: "FlightCost";
  byDepartureMonth: Array<CostPercentilesByMonth>;
  yearly?: Maybe<CostPercentiles>;
};

export type FlightCrossSellProductType = "CAR" | "HOTEL";

export type FlightDestinationInsights = {
  __typename?: "FlightDestinationInsights";
  nonStop: FlightDestinationResults;
  popular: Array<InsightsDestination>;
  underCost: FlightDestinationResults;
  underDuration: FlightDestinationResults;
};

export type FlightDestinationInsightsNonStopArgs = {
  destinationHLR?: InputMaybe<Scalars["String"]>;
  maxResults?: InputMaybe<Scalars["Int"]>;
};

export type FlightDestinationInsightsPopularArgs = {
  maxResults?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<MonthId>;
};

export type FlightDestinationInsightsUnderCostArgs = {
  destinationHLR?: InputMaybe<Scalars["String"]>;
  maxCost: Scalars["Int"];
  maxResults?: InputMaybe<Scalars["Int"]>;
};

export type FlightDestinationInsightsUnderDurationArgs = {
  destinationHLR?: InputMaybe<Scalars["String"]>;
  maxDuration: Scalars["Int"];
  maxResults?: InputMaybe<Scalars["Int"]>;
};

export type FlightDestinationResults = {
  __typename?: "FlightDestinationResults";
  domestic: Array<Maybe<InsightsAirport>>;
  international: Array<Maybe<InsightsAirport>>;
};

export type FlightInsights = {
  __typename?: "FlightInsights";
  cost?: Maybe<FlightCost>;
  purchaseWindowCost: Array<CostPercentilesByWeek>;
  shortestFlightSummary?: Maybe<FlightSummary>;
};

export type FlightInsightsCostArgs = {
  carrier?: InputMaybe<Scalars["String"]>;
};

export type FlightInsightsShortestFlightSummaryArgs = {
  carrier?: InputMaybe<Scalars["String"]>;
};

export type FlightJourneyAncillariesSection = {
  __typename?: "FlightJourneyAncillariesSection";
  ancillariesWrapper: FlightsCategorizedList;
  selectedItems?: Maybe<FlightsCategorizedList>;
};

export type FlightJourneyStatus = "FAILURE" | "SUCCESS";

export type FlightLeg = {
  __typename?: "FlightLeg";
  arrivalAirport?: Maybe<Scalars["String"]>;
  arrivalDate?: Maybe<ZonedDateTime>;
  departureAirport?: Maybe<Scalars["String"]>;
  departureDate?: Maybe<ZonedDateTime>;
  segmentList?: Maybe<Array<FlightSegment>>;
  stopCount?: Maybe<Scalars["Int"]>;
};

export type FlightMediaItem = MediaItem & {
  __typename?: "FlightMediaItem";
  media: Media;
};

export type FlightNaturalKey = {
  __typename?: "FlightNaturalKey";
  offerToken: Scalars["String"];
  productTokens: Array<Scalars["String"]>;
  travelers: Array<TravelerDetails>;
};

export interface FlightNaturalKeyInput {
  offerToken: Scalars["String"];
  productTokens: Array<Scalars["String"]>;
  travelers: Array<TravelerDetailsInput>;
}

export type FlightOfferItem = OfferItem & {
  __typename?: "FlightOfferItem";
  airlineImage?: Maybe<Image>;
  airlineName?: Maybe<Scalars["String"]>;
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  destinationAirportCode?: Maybe<Scalars["String"]>;
  destinationCity?: Maybe<Scalars["String"]>;
  flightClass?: Maybe<Scalars["String"]>;
  flightOffer?: Maybe<OfferData>;
  image: Image;
  name: Scalars["String"];
  originAirportCode?: Maybe<Scalars["String"]>;
  originCity?: Maybe<Scalars["String"]>;
  secondaryContent?: Maybe<Scalars["String"]>;
  tripType?: Maybe<Scalars["String"]>;
};

export type FlightPassengers = {
  __typename?: "FlightPassengers";
  adults?: Maybe<Scalars["Int"]>;
  agesOfChildren?: Maybe<Array<Scalars["Int"]>>;
  infantsInLap?: Maybe<Scalars["Int"]>;
  infantsInSeat?: Maybe<Scalars["Int"]>;
};

export type FlightProduct = {
  __typename?: "FlightProduct";
  airlineNames?: Maybe<Array<Scalars["String"]>>;
};

export interface FlightProductInput {
  airlineNames?: InputMaybe<Array<Scalars["String"]>>;
  flightType?: InputMaybe<FlightsTripType>;
}

export type FlightScheduleStatus = "CANCELLED" | "DELAYED" | "ON_TIME";

export type FlightSearchCriteria = {
  __typename?: "FlightSearchCriteria";
  primary: PrimaryFlightCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface FlightSearchCriteriaInput {
  primary: PrimaryFlightCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type FlightSearchCriteriaInputType = {
  __typename?: "FlightSearchCriteriaInputType";
  primary: PrimaryFlightCriteriaInputType;
};

export type FlightSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "FlightSearchForm";
    actionURL: Scalars["String"];
    addLeg?: Maybe<EgdsButton>;
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    flightTypeSelector: EgdsSearchFormInputField;
    legHeading?: Maybe<EgdsLocalizedText>;
    legs: Array<FlightSearchFormLeg>;
    localDateFormat?: Maybe<Scalars["String"]>;
    maxAllowedLegs?: Maybe<Scalars["Int"]>;
    playback?: Maybe<EgdsSearchPlayback>;
    removeLeg?: Maybe<EgdsButton>;
    search?: Maybe<EgdsButton>;
    softPackages?: Maybe<SoftPackages>;
    valuePropMessage?: Maybe<SearchFormMessaging>;
  };

export type FlightSearchFormLeg = {
  __typename?: "FlightSearchFormLeg";
  date: EgdsSearchFormDatePickerField;
  id: Scalars["String"];
  locations: EgdsSearchFormLocationsField;
};

export type FlightSearchInputForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "FlightSearchInputForm";
    actionURL: Scalars["String"];
    addLeg?: Maybe<EgdsButton>;
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    flightTypeSelector: EgdsSearchFormInputField;
    legHeading?: Maybe<EgdsLocalizedText>;
    playback?: Maybe<EgdsSearchPlayback>;
    removeLeg?: Maybe<EgdsButton>;
    search?: Maybe<EgdsButton>;
  };

export type FlightSearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "FlightSearchMultiItemShoppingAction";
  journeyContinuationId?: Maybe<Scalars["String"]>;
  shoppingContext: ShoppingContext;
};

export type FlightSearchPreferences = {
  __typename?: "FlightSearchPreferences";
  airline?: Maybe<Scalars["String"]>;
  cabinClass: FlightCabinClassInfo;
  isNonStopOnly: Scalars["Boolean"];
  isRefundableOnly: Scalars["Boolean"];
};

export type FlightSeatFeatures = {
  __typename?: "FlightSeatFeatures";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  features?: Maybe<EgdsUnorderedList>;
  title: EgdsSpannableText;
};

export type FlightSeatImages = {
  __typename?: "FlightSeatImages";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  images?: Maybe<EgdsCarousel>;
};

export type FlightSegment = {
  __typename?: "FlightSegment";
  airline?: Maybe<HistoryAirline>;
  arrivalAirport?: Maybe<Scalars["String"]>;
  arrivalDate?: Maybe<ZonedDateTime>;
  departureAirport?: Maybe<Scalars["String"]>;
  departureDate?: Maybe<ZonedDateTime>;
  flightNumber?: Maybe<Scalars["String"]>;
  totalDurationInMinutes?: Maybe<Scalars["String"]>;
};

export type FlightSelectableAncillary = EgdsRadioGroup & {
  __typename?: "FlightSelectableAncillary";
  errorMessage?: Maybe<Scalars["String"]>;
  groupHeading: EgdsText;
  /** @deprecated Use groupHeading as this field needs to be bold */
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  options: Array<FlightSelectableAncillaryOption>;
  selected?: Maybe<Scalars["String"]>;
  subHeading: EgdsText;
};

export type FlightSelectableAncillaryOption = EgdsRadioButton & {
  __typename?: "FlightSelectableAncillaryOption";
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  label: Scalars["String"];
  labelSuffix?: Maybe<Scalars["String"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  price: Scalars["Float"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
  weight: Scalars["String"];
};

export type FlightSelectableAncillaryOptionValuesType = "NONE" | "VALID";

export type FlightSelectableBaggageAncillary = EgdsRadioGroup & {
  __typename?: "FlightSelectableBaggageAncillary";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  options: Array<FlightSelectableBaggageAncillaryOption>;
  selected?: Maybe<Scalars["String"]>;
  subHeading: Scalars["String"];
};

export type FlightSelectableBaggageAncillaryOption = EgdsRadioButton & {
  __typename?: "FlightSelectableBaggageAncillaryOption";
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  label: Scalars["String"];
  labelSuffix?: Maybe<Scalars["String"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  selectableAncillaryToken: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
  weight: Scalars["String"];
};

export type FlightSelectedLegOption = {
  __typename?: "FlightSelectedLegOption";
  action: FlightsClientAction;
  label: Scalars["String"];
  selectedJourneyIndex: Scalars["Int"];
};

export type FlightServicingBookingEmptyState = {
  __typename?: "FlightServicingBookingEmptyState";
  presentation: UiEmptyState;
  primaryAction: FlightsClientAction;
};

export type FlightServicingBookingFailure = {
  __typename?: "FlightServicingBookingFailure";
  emptyState?: Maybe<FlightServicingBookingEmptyState>;
  encodedChallengeConfig?: Maybe<Scalars["String"]>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  onChallengeAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  placards?: Maybe<Array<FlightsUiPlacard>>;
};

export type FlightServicingBookingSuccess = {
  __typename?: "FlightServicingBookingSuccess";
  action: FlightsResourceRedirectAction;
};

export type FlightShoppingCardDurationTimeline = {
  __typename?: "FlightShoppingCardDurationTimeline";
  end: FlightShoppingDurationTimelineSideSection;
  primary: EgdsStylizedText;
  secondary: EgdsTextSection;
  start: FlightShoppingDurationTimelineSideSection;
  stops: Scalars["Int"];
};

export type FlightShoppingDurationTimelineSideSection = {
  __typename?: "FlightShoppingDurationTimelineSideSection";
  differentDayArrival?: Maybe<Scalars["String"]>;
  primary: Scalars["String"];
  secondaryMessage: FlightsResponsiveMessage;
};

export type FlightSortAndFilterAppliedFilter = ShoppingSortAndFilterAppliedFilter & {
  __typename?: "FlightSortAndFilterAppliedFilter";
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: UiRemovablePill;
};

export interface FlightStatusInput {
  airlineCode: Scalars["String"];
  demo?: InputMaybe<Scalars["Int"]>;
  flightDate: DateInput;
  flightNumber: Scalars["String"];
}

export type FlightStatusMinimalView = {
  __typename?: "FlightStatusMinimalView";
  value?: Maybe<Scalars["String"]>;
};

export type FlightStatusQueryResponse = {
  __typename?: "FlightStatusQueryResponse";
  destination: Airport;
  flightNumber: Scalars["String"];
  flightStatus: Scalars["String"];
  minimalView: FlightStatusMinimalView;
  origin: Airport;
  progress?: Maybe<Scalars["Float"]>;
  scheduleStatus?: Maybe<FlightScheduleStatus>;
  supplementalInfo?: Maybe<Scalars["String"]>;
  upNext?: Maybe<Scalars["String"]>;
};

export type FlightStepIndicatorChangeSelection = StepIndicatorChangeSelection & {
  __typename?: "FlightStepIndicatorChangeSelection";
  a11yLabel: Scalars["String"];
  action: UiLinkAction;
  journeyContinuationId: Scalars["String"];
  label: Scalars["String"];
  multiItemShoppingAction?: Maybe<FlightSearchMultiItemShoppingAction>;
};

export type FlightStepIndicatorLabel = StepIndicatorLabel & {
  __typename?: "FlightStepIndicatorLabel";
  destination: Scalars["String"];
  icon: Icon;
  origin: Scalars["String"];
  text: Scalars["String"];
};

export type FlightSummary = {
  __typename?: "FlightSummary";
  carrier?: Maybe<Scalars["String"]>;
  flownDuration?: Maybe<Duration>;
  stops: Array<InsightsAirport>;
  tripDuration?: Maybe<Duration>;
};

export interface FlightTripInfoDataInput {
  legNumber?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Scalars["String"]>;
  tripViewId?: InputMaybe<Scalars["String"]>;
}

export type FlightsAccessibilityMessage = {
  __typename?: "FlightsAccessibilityMessage";
  onCompletion?: Maybe<Scalars["String"]>;
  usage?: Maybe<Scalars["String"]>;
};

export type FlightsAction = {
  __typename?: "FlightsAction";
  accessibilityActionMessage?: Maybe<FlightsAccessibilityMessage>;
  accessibilityCheckoutMessage?: Maybe<Scalars["String"]>;
  /** @deprecated This will be replaced by accessibilityShowMoreMessage */
  accessibilityMessage?: Maybe<Scalars["String"]>;
  accessibilityUsageMessage?: Maybe<FlightsSimpleLocalizationTemplate>;
  /** @deprecated This will be replaced by analyticsList */
  analytics: FlightsAnalytics;
  /** @deprecated This will be replaced by clientSideAnalyticsList */
  analyticsList: Array<FlightsAnalytics>;
  cheapestListingInfo?: Maybe<FlightsCheapestListingInfo>;
  clientSideAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  displayAction: Scalars["String"];
  displayAnalytics?: Maybe<FlightsAnalytics>;
  displayType: FlightsActionDisplayType;
  enable?: Maybe<Scalars["Boolean"]>;
  flightsDetailCriteria?: Maybe<FlightsDetailCriteria>;
  icon?: Maybe<Icon>;
  journeySearchCriteria?: Maybe<FlightsJourneySearchCriteria>;
  relativePackageableURI?: Maybe<HttpUri>;
  relativeURI?: Maybe<HttpUri>;
  searchPagination?: Maybe<Pagination>;
  shoppingContext?: Maybe<ShoppingContext>;
  stepIndicator?: Maybe<FlightsStepIndicator>;
  switchToggle?: Maybe<FlightsSwitchToggle>;
  type: FlightsActionType;
};

export type FlightsActionAndLabel = {
  __typename?: "FlightsActionAndLabel";
  label: Scalars["String"];
  selectAction: FlightsAction;
};

export type FlightsActionButton = EgdsButton &
  EgdsElement & {
    __typename?: "FlightsActionButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
     *         or a union type with a manageable number of members
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    clientAction: FlightsClientAction;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    iconPosition?: Maybe<FlightsIconPosition>;
    primary?: Maybe<Scalars["String"]>;
    style: EgdsButtonStyle;
    width?: Maybe<FlightsButtonWidth>;
  };

export type FlightsActionCheckbox = EgdsCheckBox &
  EgdsElement & {
    __typename?: "FlightsActionCheckbox";
    checkedAccessibility?: Maybe<Scalars["String"]>;
    checkedAnalytics?: Maybe<ClientSideAnalytics>;
    clientAction: FlightsClientAction;
    description?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    enabled: Scalars["Boolean"];
    errorMessage?: Maybe<Scalars["String"]>;
    label: EgdsText;
    name?: Maybe<Scalars["String"]>;
    required: Scalars["Boolean"];
    state: EgdsCheckBoxState;
    uncheckedAccessibility?: Maybe<Scalars["String"]>;
    uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
    value?: Maybe<Scalars["String"]>;
  };

export type FlightsActionDisplayType =
  | "CHECKBOX"
  | "PRIMARY"
  | "RADIO_BUTTON"
  | "SECONDARY"
  | "SWITCH"
  | "TERTIARY"
  | "TOGGLE";

export type FlightsActionLink = EgdsElement & {
  __typename?: "FlightsActionLink";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: FlightsClientAction;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]>;
};

export type FlightsActionType =
  | "ADD_PRICE_MATCH_PROMISE"
  | "ALL_SEATS_SELECTED_FOR_A_LEG"
  | "APPLY_FARE"
  | "CHOOSE_FARE"
  | "CLICKING_NEXT_FLIGHT_BEFORE_FINISHING_CURRENT_FLIGHT_SEAT_SELECTION_DIALOG"
  | "CONTINUE_BOOKING_WITHOUT_SELECTING_SEATS_DIALOG"
  | "COUPON_CREDIT"
  | "DISMISS"
  | "ENABLE_SUGGESTED_FILTER"
  | "FETCH_SEARCH_LISTINGS"
  | "GO_TO_AUTH"
  | "GO_TO_CHECKOUT"
  | "GO_TO_CONFIRMATION"
  | "GO_TO_DETAILS"
  | "GO_TO_MERCH_HUB"
  | "GO_TO_NEXT_JOURNEY"
  | "GO_TO_PACKAGES"
  | "GO_TO_SEARCH"
  | "GO_TO_STOREFRONT"
  | "GO_TO_TRIPS"
  | "LOAD_CROSS_SELL_DIALOG"
  | "LOAD_STANDARD_OFFER_FARE_RULE_MODULE"
  | "LOAD_TAX_BREAKDOWN_DIALOG"
  | "NO_SEATS_SELECTED_FOR_A_LEG"
  | "OPEN_BAGS_SELECTION_ALERT_DIALOG_ON_CHECKOUT"
  | "OPEN_BAGS_SELECTION_SHEET"
  | "OPEN_BOOK_DIRECTLY_WITH_AIRLINES_DIALOG"
  | "OPEN_CREDIT_DIALOG"
  | "OPEN_FARE_SEAT_MAP_SEAT_SHEET"
  | "OPEN_FARE_SEAT_MAP_VIEW"
  | "OPEN_FARE_SEAT_SELECT_VIEW"
  | "OPEN_FLIGHT_LEG_DROP_DOWN_IN_SEAT_MAP_DIALOG"
  | "OPEN_LEGEND_INFO_SHEET_MOBILE"
  | "OPEN_PRICE_SUMMARY"
  | "OPEN_SEAT_SELECTION_ALERT_DIALOG_ON_CHECKOUT"
  | "REDIRECT_TO_AIRLINES_FOR_BOOKING"
  | "REFRESH"
  | "SEARCH_FORM_ADD_JOURNEY"
  | "SEARCH_FORM_CLOSE_BUTTON"
  | "SEARCH_FORM_REMOVE_JOURNEY"
  | "SEAT_MAP_CLOSE_WITHOUT_SELECTING_SEATS_DIALOG"
  | "SEAT_MAP_CONTINUE_BOOKING"
  | "SEAT_MAP_GO_TO_NEXT_FLIGHT"
  | "SEAT_MAP_NEXT_FLIGHT"
  | "SEAT_MAP_SAVE_AND_EXIT"
  | "SEAT_MAP_STAY_HERE"
  | "SEAT_MAP_STAY_ON_THIS_PAGE"
  | "SEAT_SELECT_DIALOG_CONTINUE_BOOKING"
  | "SEAT_SELECT_PREVIEW_FOR_TRAVELER"
  | "SELECT_FLIGHT_LEG_IN_SEAT_MAP_DIALOG"
  | "SELECT_SEAT"
  | "SHARE_FEEDBACK"
  | "SHOW_FREE_PRICE_MATCH_PROMISE_DETAILS"
  | "SHOW_PRICE_MATCH_PROMISE_DETAILS"
  | "SOME_SEATS_SELECTED_FOR_A_LEG"
  | "SUBSCRIBE_TO_EMAILS"
  | "TOGGLE_PRICE_ALERTS"
  | "UNSELECT_SEAT"
  | "UPGRADE_TO_NEXT_FARE";

export type FlightsActionableErrorMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsActionableErrorMessaging";
  action: FlightsAction;
  errorRecoveryButtons?: Maybe<Array<FlightsAction>>;
  icon: Icon;
  message: Scalars["String"];
  subMessage?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type FlightsActionableIcon = {
  __typename?: "FlightsActionableIcon";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  analytics: FlightsAnalytics;
  displayText?: Maybe<Scalars["String"]>;
  icon: Icon;
  type: FlightsActionType;
};

export type FlightsActionableLabel = {
  __typename?: "FlightsActionableLabel";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  active: Scalars["Boolean"];
  analytics?: Maybe<FlightsAnalytics>;
  label: Scalars["String"];
  url?: Maybe<Scalars["URL"]>;
};

export type FlightsAdaptExAnalytics = {
  __typename?: "FlightsAdaptExAnalytics";
  campaignId: Scalars["String"];
  events: Array<FlightsAdaptExAnalyticsEvent>;
};

export type FlightsAdaptExAnalyticsEvent = {
  __typename?: "FlightsAdaptExAnalyticsEvent";
  banditDisplayed: Scalars["Boolean"];
  eventProperties: Array<AdaptExEventProperty>;
  eventTarget: Scalars["String"];
  eventType: Scalars["String"];
  payloadId: Scalars["String"];
};

export type FlightsAdvancedFilterType = "NONSTOP" | "REFUNDABLE";

export interface FlightsAirAncillarySelectionDetailInput {
  travelerIndex: Scalars["Int"];
  unselectedAirAncillaryProductToken: Scalars["String"];
}

export type FlightsAirlineAmenityGroup = {
  __typename?: "FlightsAirlineAmenityGroup";
  amenities?: Maybe<Array<FlightsIconAndLabel>>;
  headerText?: Maybe<Scalars["String"]>;
};

export type FlightsAirlineCreditsResponse = {
  __typename?: "FlightsAirlineCreditsResponse";
  flightsCreditSelectionPresentation: FlightsCreditSelectionPresentation;
};

export type FlightsAirportCodeFilterValue = {
  __typename?: "FlightsAirportCodeFilterValue";
  airportCode: Scalars["String"];
};

export interface FlightsAirportCodeFilterValueInput {
  airportCode: Scalars["String"];
}

export type FlightsAnalytics = {
  __typename?: "FlightsAnalytics";
  linkName: Scalars["String"];
  referrerId: Scalars["String"];
};

export type FlightsAncillaryAccessibilityMessage = {
  __typename?: "FlightsAncillaryAccessibilityMessage";
  accessibilityMessage: Scalars["String"];
  id: FlightsAncillarySelectionAccessibilityMsgType;
};

export type FlightsAncillaryAlertDialog = {
  __typename?: "FlightsAncillaryAlertDialog";
  alertDialog: FlightsDialogStandard;
  id: FlightsActionType;
};

export type FlightsAncillaryAvailabilityDetail = {
  __typename?: "FlightsAncillaryAvailabilityDetail";
  selectedJourneyIndex: Scalars["Int"];
  /** @deprecated It supports only text, to support suffix `- added` use staticAncillariesInfo */
  staticAncillaries: Array<EgdsIconText>;
  staticAncillariesInfo: Array<EgdsSpannableText>;
};

export type FlightsAncillaryAvailableFlightLegSelection = {
  __typename?: "FlightsAncillaryAvailableFlightLegSelection";
  additionalInfo?: Maybe<EgdsText>;
  ancillariesPerTravelerList?: Maybe<Array<FlightAncillariesPerTraveler>>;
  ancillaryNotAvailableMessage?: Maybe<FlightsAncillaryMessage>;
  /** @deprecated New node required for checkbox with analytics, replace with applySameAncillaryCheckBox */
  applySameAncillary?: Maybe<EgdsBasicCheckBox>;
  applySameAncillaryCheckBox?: Maybe<ActionCheckBox>;
  availabilityDetails?: Maybe<FlightsAncillaryAvailabilityDetail>;
  selectedJourneyIndex: Scalars["Int"];
};

export type FlightsAncillaryCard = {
  __typename?: "FlightsAncillaryCard";
  action?: Maybe<FlightsDialogTrigger>;
  content?: Maybe<FlightsCategorizedList>;
  /** @deprecated Use: val expandToggle: EGDSExpandoPeek? = null */
  displayToggle: EgdsExpandoPeek;
  expandToggle?: Maybe<EgdsExpandoPeek>;
  heading: Scalars["String"];
  highlightMessage?: Maybe<EgdsText>;
  /** @deprecated Use: val content: FlightsCategorizedList, all static and selected items are combined */
  sections?: Maybe<Array<FlightJourneyAncillariesSection>>;
};

export type FlightsAncillaryContinueAction = FlightsClientAction & {
  __typename?: "FlightsAncillaryContinueAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export interface FlightsAncillaryCriteriaInput {
  ancillaryContent?: InputMaybe<AncillaryContentInput>;
  ancillaryId?: InputMaybe<Scalars["String"]>;
  ancillaryJourneySelections?: InputMaybe<Array<AncillaryJourneySelectionsInput>>;
  ancillaryShoppingPath?: InputMaybe<AncillaryShoppingPath>;
  ancillaryType: FlightsAncillaryType;
  flightAncillaryJourneySelections?: InputMaybe<Array<FlightAncillaryJourneySelectionInput>>;
  journeyContinuationId: Scalars["String"];
  selectedOfferToken?: InputMaybe<Scalars["String"]>;
}

export type FlightsAncillaryDetails = {
  __typename?: "FlightsAncillaryDetails";
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
  flightsAncillaryAlertDialogList?: Maybe<Array<FlightsDialog>>;
  /** @deprecated New implementation on Shared-UI requires new type, replace with flightsAncillaryAlertDialogList */
  flightsAncillaryAlertDialogs?: Maybe<Array<FlightsAncillaryAlertDialog>>;
  footer: FlightsAncillaryFooter;
  journeyAncillaryDetails: Array<FlightsAncillaryAvailableFlightLegSelection>;
  scrollAnalytics?: Maybe<ClientSideAnalytics>;
  selectedJourneyIndex: Scalars["Int"];
};

export type FlightsAncillaryDetailsDialog = {
  __typename?: "FlightsAncillaryDetailsDialog";
  flightsContinueBookingDialog: FlightsDialog;
  /** @deprecated Use flightsDialogCompleteSelectionClose  and flightsDialogCompleteSelectionClose instead */
  flightsDialogClose: FlightsDialog;
  flightsDialogCompleteSelectionClose: FlightsDialog;
  flightsDialogIncompleteSelectionClose: FlightsDialog;
};

export type FlightsAncillaryDetailsLoading = {
  __typename?: "FlightsAncillaryDetailsLoading";
  analytics?: Maybe<ClientSideAnalytics>;
  flightsAncillaryDialog: FlightsAncillaryDetailsDialog;
  footer: FlightsAncillaryFooter;
  heading: EgdsHeading;
  journeySelectionDropDown?: Maybe<JourneySelectionDropDown>;
  selectedJourneyIndex: Scalars["Int"];
};

export type FlightsAncillaryDetailsPrefetch = {
  __typename?: "FlightsAncillaryDetailsPrefetch";
  availabilityDetails?: Maybe<Array<FlightsAncillaryAvailabilityDetail>>;
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
};

export type FlightsAncillaryDisplayAction = "NONE" | "PREFETCH";

export type FlightsAncillaryErrorMessage = {
  __typename?: "FlightsAncillaryErrorMessage";
  accessibilityMessage?: Maybe<FlightsResponsiveAccessibilityMessage>;
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<EgdsStylizedText>;
  id: FlightsAncillarySelectionErrorType;
  message: EgdsStylizedText;
};

export type FlightsAncillaryFooter = {
  __typename?: "FlightsAncillaryFooter";
  globalFooter: FlightsAncillaryGlobalFooter;
  journeyFooter: Array<FlightsAncillaryJourneyFooter>;
};

export type FlightsAncillaryGlobalFooter = {
  __typename?: "FlightsAncillaryGlobalFooter";
  label: EgdsText;
  selectedCount: Scalars["Int"];
  /** @deprecated use selectedMessageTemplates for future development */
  selectedMessage: EgdsLocalizedTextTemplate;
  selectedMessageTemplates: Array<SelectedAncillaryTemplate>;
  templateAnalytics?: Maybe<Array<TemplateAnalytics>>;
  totalPrice: EgdsLocalizedTextTemplate;
};

export type FlightsAncillaryInformation = {
  __typename?: "FlightsAncillaryInformation";
  additionalInfo?: Maybe<EgdsPlainText>;
  flightsAncillaryAvailabilityDetails: FlightsAncillaryAvailabilityDetail;
  heading?: Maybe<EgdsHeading>;
  selectedAncillarySummary?: Maybe<Array<FlightsAncillaryTravelerSummaryItem>>;
};

export type FlightsAncillaryJourneyFooter = {
  __typename?: "FlightsAncillaryJourneyFooter";
  buttonAction: FlightsActionButton;
  buttonActionTypes: Array<FlightsLegAncillarySummaryActionType>;
  selectedJourneyIndex: Scalars["Int"];
  templateAnalytics?: Maybe<Array<TemplateAnalytics>>;
};

export type FlightsAncillaryMessage = {
  __typename?: "FlightsAncillaryMessage";
  accessibilityMessage?: Maybe<FlightsResponsiveAccessibilityMessage>;
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<EgdsHeading>;
  id: FlightsAncillarySelectionErrorType;
  message: EgdsText;
  theme: TextTheme;
};

export type FlightsAncillaryResponse = {
  __typename?: "FlightsAncillaryResponse";
  ancillaryDetail?: Maybe<FlightsAncillaryDetails>;
  ancillarySummary?: Maybe<FlightsAncillarySummaryPresentation>;
  ancillarySummaryCard?: Maybe<FlightsAncillarySummaryCardPresentation>;
  baggageDetails?: Maybe<FlightsBaggageAncillaryDetailsPresentation>;
  seatAncillaryDetails?: Maybe<FlightsSeatAncillaryDetailsPresentation>;
  seatCellDetails?: Maybe<FlightsSeatCellDetailsPresentation>;
};

export type FlightsAncillarySelect = {
  __typename?: "FlightsAncillarySelect";
  optionLabels: Array<Scalars["String"]>;
  selectOptions: EgdsSelect;
};

export type FlightsAncillarySelectedItem = {
  __typename?: "FlightsAncillarySelectedItem";
  assignedAncillaryLabel: EgdsPlainText;
  price: EgdsPlainText;
};

export type FlightsAncillarySelectionAccessibilityMsgType = "SELECTION_FAILED" | "SELECTION_SUCCESS";

export type FlightsAncillarySelectionErrorType =
  | "BAGGAGE_DETAILS_LOADED_FAILED"
  | "DETAILS_LOADING_FAILED"
  | "PRE_SELECTION_FAILED"
  | "SELECTION_FAILED"
  | "SELECTION_UNAVAILABLE";

export type FlightsAncillarySummary = FlightsAncillarySummaryPresentation & {
  __typename?: "FlightsAncillarySummary";
  accessibilityMessages: Array<FlightsAncillaryAccessibilityMessage>;
  airOfferToken?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  ancillariesPerJourney?: Maybe<Array<FlightsAncillaryInformation>>;
  ancillaryDetailsLoading: FlightsAncillaryDetailsLoading;
  displayToggle: EgdsExpando;
  editAncillariesAction?: Maybe<FlightsActionLink>;
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
  flightAncillaryJourneySelections?: Maybe<Array<FlightAncillaryJourneySelection>>;
  flightsAncillaryDetailsPrefetch: FlightsAncillaryDetailsPrefetch;
  heading: EgdsHeading;
  highlightMessage?: Maybe<EgdsHeading>;
  loadAncillariesAction?: Maybe<FlightsActionButton>;
  scrollAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsAncillarySummaryAnalytics = {
  __typename?: "FlightsAncillarySummaryAnalytics";
  analyticsList: Array<ClientSideAnalytics>;
  scrollAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsAncillarySummaryCard = {
  __typename?: "FlightsAncillarySummaryCard";
  accessibilityMessages: Array<FlightsAncillaryAccessibilityMessage>;
  additionalShoppedAncillaries?: Maybe<Array<AdditionalShoppedAncillaries>>;
  analytics: FlightsAncillarySummaryAnalytics;
  ancillaryCard: FlightsAncillaryCard;
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
  onDisplayAction: FlightsAncillaryDisplayAction;
};

export type FlightsAncillarySummaryCardLoading = {
  __typename?: "FlightsAncillarySummaryCardLoading";
  errorMessage: EgdsStylizedText;
  heading: Scalars["String"];
};

export type FlightsAncillarySummaryCardPresentation = FlightsAncillarySummaryCard | FlightsAncillarySummaryCardLoading;

export type FlightsAncillarySummaryLoading = FlightsAncillarySummaryPresentation & {
  __typename?: "FlightsAncillarySummaryLoading";
  heading: EgdsHeading;
};

export interface FlightsAncillarySummaryPresentation {
  heading: EgdsHeading;
}

export type FlightsAncillaryTravelerSummaryItem = {
  __typename?: "FlightsAncillaryTravelerSummaryItem";
  ancillaryItemDetails: Array<FlightsAncillarySelectedItem>;
  label: EgdsStylizedText;
  travelerIndex: Scalars["Int"];
};

export type FlightsAncillaryType = "BAGS" | "BAGS_V2" | "SEATS";

export type FlightsAncillaryUpdateResponse = {
  __typename?: "FlightsAncillaryUpdateResponse";
  ancillaryId?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export type FlightsAppliedFilter = {
  __typename?: "FlightsAppliedFilter";
  accessibilityMessage: Scalars["String"];
  analytics: FlightsAnalytics;
  dismissIcon: Icon;
  dismissIconAccessibility?: Maybe<Scalars["String"]>;
  identifier: Scalars["String"];
  label: Scalars["String"];
  nextSearchSortFilterCriteria?: Maybe<FlightsSearchSortAndFilterCriteria>;
};

export type FlightsAppliedFiltersSummary = {
  __typename?: "FlightsAppliedFiltersSummary";
  accessibility?: Maybe<Scalars["String"]>;
  appliedFilters?: Maybe<Array<FlightsAppliedFilter>>;
};

export type FlightsArithmeticResponse = {
  __typename?: "FlightsArithmeticResponse";
  formattedResult: EgdsText;
};

export type FlightsAttributeTogglesPresentation = {
  __typename?: "FlightsAttributeTogglesPresentation";
  badgeMessage?: Maybe<FlightsBadgeMessage>;
  tileField: ShoppingMultiSelectionTileField;
};

export type FlightsAuthenticatedUser = {
  __typename?: "FlightsAuthenticatedUser";
  accessibility: Scalars["String"];
  email: Scalars["String"];
  heading: Scalars["String"];
  message: Scalars["String"];
};

export interface FlightsAvailableFares {
  applySelection?: Maybe<FlightsAction>;
  dismissDetailedJourneyInformation: FlightsActionableIcon;
  fareChoiceInformation?: Maybe<FlightsFareChoiceInformation>;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  openAccessibility: Scalars["String"];
}

export type FlightsBadge = {
  __typename?: "FlightsBadge";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
  type: FlightsBadgeTheme;
};

export type FlightsBadgeMessage = {
  __typename?: "FlightsBadgeMessage";
  badge: EgdsStandardBadge;
  message: EgdsStylizedText;
  subtext?: Maybe<Scalars["String"]>;
};

export type FlightsBadgeTheme =
  | "BRAND"
  | "DEAL_BUNDLED"
  | "DEAL_GENERIC"
  | "DEAL_MEMBER"
  | "FAMILY_FRIENDLY"
  | "NOTIFICATION"
  | "SAVED"
  | "SPONSORED"
  | "SUPPLIER_PROMO"
  | "VIEWED"
  | "VIP";

export type FlightsBaggageAncillariesPerTraveler = {
  __typename?: "FlightsBaggageAncillariesPerTraveler";
  ancillaryOptions?: Maybe<Array<FlightSelectableBaggageAncillary>>;
  errorMessage?: Maybe<Scalars["String"]>;
  travelerIndex: Scalars["Int"];
  travellerIdentifier?: Maybe<Scalars["String"]>;
};

export type FlightsBaggageAncillaryDetailsContent = {
  __typename?: "FlightsBaggageAncillaryDetailsContent";
  flightsAncillaryDialog?: Maybe<FlightsAncillaryDetailsDialog>;
  journeyAncillaryDetails: Array<FlightsJourneyBaggageSelectionPresentation>;
  journeySelect?: Maybe<FlightsAncillarySelect>;
};

export type FlightsBaggageAncillaryDetailsLoaded = {
  __typename?: "FlightsBaggageAncillaryDetailsLoaded";
  baggageDetailsWrapper: FlightsDialogSheet;
  content: FlightsBaggageAncillaryDetailsContent;
  footer: FlightsBaggageAncillaryFooter;
  offerIdentifier?: Maybe<Scalars["String"]>;
};

export type FlightsBaggageAncillaryDetailsLoading = {
  __typename?: "FlightsBaggageAncillaryDetailsLoading";
  baggageDetailsWrapper: FlightsDialogSheet;
  errorMessage: FlightsAncillaryErrorMessage;
};

export type FlightsBaggageAncillaryDetailsPresentation =
  | FlightsBaggageAncillaryDetailsLoaded
  | FlightsBaggageAncillaryDetailsLoading
  | FlightsBaggageAncillaryDetailsSelectionChange;

export type FlightsBaggageAncillaryDetailsSelectionChange = {
  __typename?: "FlightsBaggageAncillaryDetailsSelectionChange";
  footer: FlightsBaggageAncillaryFooter;
};

export type FlightsBaggageAncillaryFooter = {
  __typename?: "FlightsBaggageAncillaryFooter";
  globalFooter: FlightsBaggageAncillaryGlobalFooter;
  journeyFooter: Array<FlightsBaggageAncillaryJourneyFooter>;
};

export type FlightsBaggageAncillaryGlobalFooter = {
  __typename?: "FlightsBaggageAncillaryGlobalFooter";
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  label: Scalars["String"];
  selectedMessage: Scalars["String"];
  totalPrice: Scalars["String"];
};

export type FlightsBaggageAncillaryJourneyFooter = {
  __typename?: "FlightsBaggageAncillaryJourneyFooter";
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  buttonAction: FlightsActionButton;
  selectedJourneyIndex: Scalars["Int"];
};

export type FlightsBaggageAvailableFlightLegSelection = {
  __typename?: "FlightsBaggageAvailableFlightLegSelection";
  additionalInfo?: Maybe<Scalars["String"]>;
  ancillariesPerTravelerList?: Maybe<Array<FlightsBaggageAncillariesPerTraveler>>;
  ancillaryNotAvailableMessage?: Maybe<FlightsAncillaryMessage>;
  applySameAncillaryCheckBox?: Maybe<FlightsActionCheckbox>;
  staticAncillaries?: Maybe<FlightsCategorizedList>;
  weightConceptMessage?: Maybe<EgdsSpannableText>;
};

export type FlightsBaggageInformation = {
  __typename?: "FlightsBaggageInformation";
  bagFeesMoreInfo: Array<FlightsPhrase>;
  defaultBagSelectionMessage?: Maybe<Scalars["String"]>;
  details?: Maybe<Array<FlightBaggageInformationDetail>>;
  dismissAction?: Maybe<Scalars["String"]>;
  /** @deprecated Will start sending all display analytics for baggage in displayAnalyticsItems node */
  displayAnalytics?: Maybe<FlightsAnalytics>;
  displayAnalyticsItems?: Maybe<Array<FlightsAnalytics>>;
  displayDetailsAction?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
};

export type FlightsBannerError = FlightsOnLoadError & {
  __typename?: "FlightsBannerError";
  accessibility: Scalars["String"];
  action: FlightsActionLink;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type FlightsBannerPlacard = FlightsUiPlacard & {
  __typename?: "FlightsBannerPlacard";
  banner: UiBanner;
  displayType: FlightsPlacardDisplayType;
  /** @deprecated Use onViewedAnalyticsList for future use. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsBargainFareOffer = FlightsOffer & {
  __typename?: "FlightsBargainFareOffer";
  accessibilityHeading?: Maybe<Scalars["String"]>;
  accessibilityMessage: Scalars["String"];
  bargainPricingInformation?: Maybe<FlightsPricingInformation>;
  description: FlightsPhrase;
  graphic: UiGraphic;
  header: FlightsPhrase;
  headerImage?: Maybe<Image>;
  /** @deprecated Replaced with graphic to use UIGraphic */
  image: Image;
  journeyContinuationId?: Maybe<Scalars["String"]>;
  journeys: Array<FlightsOfferJourney>;
  onClickAnalyticsList: Array<FlightsAnalytics>;
  sanitizedOfferToken?: Maybe<Scalars["String"]>;
  selectOfferAction: FlightsAction;
  stepIndicatorJcid?: Maybe<Scalars["String"]>;
};

export type FlightsBargainFareSelectedJourneyDetails = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsBargainFareSelectedJourneyDetails";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsBargainJourneySummary = FlightsJourneySummary & {
  __typename?: "FlightsBargainJourneySummary";
  arrivalDayInformation: Scalars["String"];
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  formattedPrice?: Maybe<FlightsPhrase>;
  heading: FlightsMessageAndAccessibility;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
  totalPrice?: Maybe<Scalars["String"]>;
  tripTypePerTraveler?: Maybe<Scalars["String"]>;
};

export type FlightsBooking = {
  __typename?: "FlightsBooking";
  action?: Maybe<FlightsActionButton>;
  links: EgdsLinkList;
  onViewedAnalytics: ClientSideAnalytics;
  /** @deprecated This will be replaced with action */
  primaryAction: FlightsBookingAction;
  /** @deprecated This will be replaced with action */
  primaryButton: UiPrimaryButton;
  /** @deprecated This will be replaced with primaryText */
  primaryCopy: Scalars["String"];
  primaryText?: Maybe<EgdsPlainText>;
  secondaryCopy: EgdsIconText;
};

export type FlightsBookingAction = FlightsClientAction & {
  __typename?: "FlightsBookingAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsBoundNavigation = FlightsClientAction & {
  __typename?: "FlightsBoundNavigation";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  jcid: Scalars["String"];
  mipt?: Maybe<Scalars["String"]>;
  pageId: Scalars["String"];
  stepIndicatorJcid: Scalars["String"];
};

export type FlightsButtonWidth = "DEFAULT" | "FULL_WIDTH";

export type FlightsCabinClass = "BUSINESS" | "COACH" | "FIRST" | "PREMIUM_ECONOMY";

export type FlightsCardBackgroundTheme = "HIGHLIGHT" | "PRIMARY" | "SECONDARY";

export type FlightsCardsDisplayFilterItem = FlightsFilterItem & {
  __typename?: "FlightsCardsDisplayFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  card: Icon;
  defaultSelected: Scalars["Boolean"];
  deselectAnalytics: FlightsAnalytics;
  details?: Maybe<Scalars["String"]>;
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"];
  label: Scalars["String"];
  selectAnalytics: FlightsAnalytics;
  selected: Scalars["Boolean"];
  subLabel: Scalars["String"];
  value: FlightsFilterValue;
};

export type FlightsCardsDisplayFilterOption = FlightsFilterOption & {
  __typename?: "FlightsCardsDisplayFilterOption";
  filterAppliedAnalytics?: Maybe<FlightsAnalytics>;
  items: Array<FlightsCardsDisplayFilterItem>;
  label: Scalars["String"];
  supportsMultipleSelection: Scalars["Boolean"];
  type: FlightsFilterType;
};

export type FlightsCarryOnBagBasedFilterValue = {
  __typename?: "FlightsCarryOnBagBasedFilterValue";
  carryOnBag: CarryOnBagType;
  displayToast?: Maybe<Scalars["Boolean"]>;
};

export interface FlightsCarryOnBagBasedFilterValueInput {
  carryOnBag: CarryOnBagType;
  displayToast?: InputMaybe<Scalars["Boolean"]>;
}

export type FlightsCarryOverFiltersCard = {
  __typename?: "FlightsCarryOverFiltersCard";
  /** @deprecated Replaced with field that allows links or buttons, replace with actionElement */
  actionButton: FlightsActionButton;
  actionElement: FlightsClientActionElement;
  /** @deprecated Replaced with a lightweight solution, replace with message */
  content: EgdsStandardMessagingCard;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type FlightsCategorizedList = {
  __typename?: "FlightsCategorizedList";
  sections: Array<FlightsCategorizedListSection>;
};

export type FlightsCategorizedListItem = {
  __typename?: "FlightsCategorizedListItem";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  primary: EgdsSpannableText;
  /** @deprecated secondary replaces with secondaryContent as it supports EGDSSpannableText */
  secondary?: Maybe<Scalars["String"]>;
  secondaryContent?: Maybe<EgdsSpannableText>;
  tertiary?: Maybe<Scalars["String"]>;
};

export type FlightsCategorizedListSection = {
  __typename?: "FlightsCategorizedListSection";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  additionalInfo?: Maybe<Array<Scalars["String"]>>;
  category?: Maybe<FlightsCategorizedListSectionType>;
  footnote?: Maybe<Array<Scalars["String"]>>;
  heading?: Maybe<Scalars["String"]>;
  items: Array<FlightsCategorizedListItem>;
};

export type FlightsCategorizedListSectionType = "BAG" | "SEAT";

export type FlightsChangeAndCancellationFees = {
  __typename?: "FlightsChangeAndCancellationFees";
  changeAndCancellationDetails: Array<FlightsLabelAndValue>;
  displayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  heading: Scalars["String"];
};

export type FlightsCheapestListingInfo = {
  __typename?: "FlightsCheapestListingInfo";
  /** @deprecated We now need 2 nodes to represent 2 different types of analytics */
  analytics: FlightsAnalytics;
  deviationAnalytics?: Maybe<FlightsAnalytics>;
  nffAnalytics: FlightsAnalytics;
  price: Scalars["String"];
};

export type FlightsCheckedBagBasedFilterValue = {
  __typename?: "FlightsCheckedBagBasedFilterValue";
  displayToast?: Maybe<Scalars["Boolean"]>;
  numOfCheckedBags: Scalars["Int"];
};

export interface FlightsCheckedBagBasedFilterValueInput {
  displayToast?: InputMaybe<Scalars["Boolean"]>;
  numOfCheckedBags: Scalars["Int"];
}

export type FlightsClickAction = FlightsClientAction & {
  __typename?: "FlightsClickAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export interface FlightsClientAction {
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
}

export type FlightsClientActionElement = FlightsActionButton | FlightsActionLink;

export type FlightsClientMetadata = FlightsPwaMetadata;

export type FlightsConfirmation = {
  __typename?: "FlightsConfirmation";
  action?: Maybe<FlightsActionButton>;
  itinerary: EgdsStylizedText;
  label: EgdsPlainText;
  list: EgdsUnorderedList;
  /** @deprecated This will be replaced with action */
  primaryAction: FlightsBookingAction;
  /** @deprecated This will be replaced with action */
  primaryButton: UiPrimaryButton;
};

export type FlightsConnection = {
  __typename?: "FlightsConnection";
  aircraftModel?: Maybe<Scalars["String"]>;
  airlineInfo: Scalars["String"];
  cabinClassAndBookingCode: Scalars["String"];
  connectionArrival: CoordinateInformation;
  connectionDeparture: CoordinateInformation;
  duration: Scalars["String"];
  durationAccessibilityMessage: Scalars["String"];
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  operatedBy?: Maybe<Scalars["String"]>;
};

export type FlightsConnectionInformation = {
  __typename?: "FlightsConnectionInformation";
  flightsConnection: FlightsConnection;
  governmentApprovalMessage?: Maybe<Scalars["String"]>;
};

export type FlightsCouponCredit = {
  __typename?: "FlightsCouponCredit";
  couponCreditAction: FlightsAction;
  imageListingLabel: FlightsImageListingLabel;
};

export type FlightsCreditClearAction = FlightsClientAction & {
  __typename?: "FlightsCreditClearAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsCreditEligibleFilterValue = {
  __typename?: "FlightsCreditEligibleFilterValue";
  creditEligible: CreditEligibleFilterType;
};

export interface FlightsCreditEligibleFilterValueInput {
  creditEligible: CreditEligibleFilterType;
}

export type FlightsCreditRedemptionHeadsUpContent =
  | FlightsCreditRedemptionHeadsUpError
  | FlightsCreditRedemptionHeadsUpSuccess;

export type FlightsCreditRedemptionHeadsUpContentResponse = {
  __typename?: "FlightsCreditRedemptionHeadsUpContentResponse";
  flightsCreditRedemptionHeadsUpContent: FlightsCreditRedemptionHeadsUpContent;
};

export type FlightsCreditRedemptionHeadsUpError = {
  __typename?: "FlightsCreditRedemptionHeadsUpError";
  error: Scalars["String"];
};

export type FlightsCreditRedemptionHeadsUpFooter = {
  __typename?: "FlightsCreditRedemptionHeadsUpFooter";
  button: UiPrimaryButton;
  text: Scalars["String"];
};

export interface FlightsCreditRedemptionHeadsUpInput {
  airlineName: Scalars["String"];
}

export type FlightsCreditRedemptionHeadsUpSuccess = {
  __typename?: "FlightsCreditRedemptionHeadsUpSuccess";
  contents: Array<EgdsBasicSectionHeading>;
  footer?: Maybe<FlightsCreditRedemptionHeadsUpFooter>;
  heading: Scalars["String"];
};

export type FlightsCreditResidualMessaging = {
  __typename?: "FlightsCreditResidualMessaging";
  analytics: Array<ClientSideAnalytics>;
  heading: EgdsHeading;
  messages: Array<EgdsElement>;
};

export type FlightsCreditSelectionAction = FlightsClientAction & {
  __typename?: "FlightsCreditSelectionAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  originalBookingId: Scalars["String"];
};

export type FlightsCreditSelectionElement = FlightsElementsWrapper | FlightsOfferCard;

export type FlightsCreditSelectionPresentation = {
  __typename?: "FlightsCreditSelectionPresentation";
  /** @deprecated Deprecated to support loading state, replace with airlineCreditsPresentation */
  airlineCreditsContent: AirlineCreditsPresentation;
  airlineCreditsPresentation: AirlineCreditsContentPresentation;
  airlineCreditsWrapper: FlightsDialogSheet;
  dialogs?: Maybe<Array<FlightsDialog>>;
  trigger: FlightsDialogTrigger;
};

export type FlightsCreditSelectionPresentationSection = {
  __typename?: "FlightsCreditSelectionPresentationSection";
  elements: Array<FlightsCreditSelectionElement>;
};

export type FlightsCreditSelectorOpenAction = FlightsClientAction & {
  __typename?: "FlightsCreditSelectorOpenAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsCreditSelectorOpenUiAction = UiAction & {
  __typename?: "FlightsCreditSelectorOpenUIAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type FlightsCreditsAvailableListing = {
  __typename?: "FlightsCreditsAvailableListing";
  immediateDialogAction?: Maybe<FlightsDialogTriggerUiAction>;
  messagingCardPlacard: FlightsMessagingCardPlacard;
  standardMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated This field is now deprecated., replace with toastNotification */
  toast: EgdsToast;
  toastNotification?: Maybe<EgdsToast>;
};

export type FlightsCrossSellMessage = {
  __typename?: "FlightsCrossSellMessage";
  /** @deprecated We don't need FlightsActionableLabel as this will be replaced with a shared-ui component */
  crossSellLabel: FlightsActionableLabel;
  /** @deprecated We don't need FlightsPhrase as this will be replaced with a shared-ui component */
  message: FlightsPhrase;
  multiItemFlightCrossSellPrimer: MultiItemFlightCrossSellPrimer;
};

export type FlightsCrossSellOffer = {
  __typename?: "FlightsCrossSellOffer";
  /** @deprecated Not in usage */
  accessibilityHeading?: Maybe<Scalars["String"]>;
  /** @deprecated Not in usage */
  accessibilityMessage: Scalars["String"];
  /** @deprecated Not in usage */
  displayDetailsAction: Scalars["String"];
  /** @deprecated Not in usage */
  displayDetailsActionCollapseAnalytics: FlightsAnalytics;
  /** @deprecated Not in usage */
  displayDetailsActionExpandAnalytics: FlightsAnalytics;
  /** @deprecated Not in usage */
  journeyContinuationId?: Maybe<Scalars["String"]>;
  /** @deprecated Not in usage */
  journeys: Array<FlightsOfferJourney>;
  multiItemFlightCrossSellOfferPrimer?: Maybe<MultiItemFlightCrossSellOfferPrimer>;
  /** @deprecated Not in usage */
  onClickAnalyticsList: Array<FlightsAnalytics>;
  /** @deprecated Not in usage */
  savings: FlightsSearchCrossSellOfferSavings;
  /** @deprecated Not in usage */
  selectOfferAction: FlightsAction;
};

export type FlightsCrossSortItem = {
  __typename?: "FlightsCrossSortItem";
  analytics?: Maybe<FlightsAnalytics>;
  defaultWeight: Scalars["Int"];
  label: FlightsIconAndLabel;
  type: FlightsCrossSortType;
};

export type FlightsCrossSortOption = {
  __typename?: "FlightsCrossSortOption";
  type: FlightsCrossSortType;
  weight: Scalars["Int"];
};

export interface FlightsCrossSortOptionInput {
  type: FlightsCrossSortType;
  weight: Scalars["Int"];
}

export type FlightsCrossSortOptionType = {
  __typename?: "FlightsCrossSortOptionType";
  crossSortOption: Array<FlightsCrossSortOption>;
};

export interface FlightsCrossSortOptionTypeInput {
  crossSortOption: Array<FlightsCrossSortOptionInput>;
}

export interface FlightsCrossSortPresentation {
  ceiling?: Maybe<Scalars["Int"]>;
  crossSortItems: Array<FlightsCrossSortItem>;
  floor?: Maybe<Scalars["Int"]>;
  heading: Scalars["String"];
  step?: Maybe<Scalars["Int"]>;
  subHeading: Scalars["String"];
}

export type FlightsCrossSortPresentationResult = FlightsCrossSortPresentation & {
  __typename?: "FlightsCrossSortPresentationResult";
  ceiling?: Maybe<Scalars["Int"]>;
  crossSortItems: Array<FlightsCrossSortItem>;
  floor?: Maybe<Scalars["Int"]>;
  heading: Scalars["String"];
  step?: Maybe<Scalars["Int"]>;
  subHeading: Scalars["String"];
};

export type FlightsCrossSortType =
  | "CABIN_CLASS"
  | "CANCELLATION"
  | "CARRY_ON_BAG"
  | "CHANGE_ALLOWED"
  | "CHECKED_BAG"
  | "DURATION"
  | "NUM_OF_STOPS"
  | "PRICE"
  | "SEAT_CHOICE";

export type FlightsDatePickerCellContent = {
  __typename?: "FlightsDatePickerCellContent";
  accessibility?: Maybe<Scalars["String"]>;
  content: FlightsFareCalendarPrice;
  date: ZonedDateTime;
  selectAnalytics: FlightsAnalytics;
};

export interface FlightsDetailAncillaryUpdateCriteriaInput {
  airAncillaryDetails: Array<FlightsAirAncillarySelectionDetailInput>;
  ancillaryJourneySelections?: InputMaybe<Array<AncillaryJourneySelectionsInput>>;
  ancillaryShoppingPath?: InputMaybe<AncillaryShoppingPath>;
  ancillaryType?: InputMaybe<FlightsAncillaryType>;
  flightAncillaryJourneySelections?: InputMaybe<Array<FlightAncillaryJourneySelectionInput>>;
  journeyContinuationId: Scalars["String"];
  journeyIdentifier: Scalars["String"];
}

export interface FlightsDetailComponentsCriteriaInput {
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  journeyIdentifier: Scalars["String"];
  originalPrice?: InputMaybe<MoneyInput>;
  queryParams?: InputMaybe<Array<GraphQlPairInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  travelerDetails: Array<FlightsTravelerDetailsInput>;
  upsellOfferToken?: InputMaybe<Scalars["String"]>;
}

export type FlightsDetailCriteria = {
  __typename?: "FlightsDetailCriteria";
  additionalShoppedAncillaries?: Maybe<Array<AdditionalShoppedAncillaries>>;
  ancillaryId?: Maybe<Scalars["String"]>;
  channelType?: Maybe<ChannelType>;
  journeyContinuationId: Scalars["String"];
  originalBookingId?: Maybe<Scalars["String"]>;
  upsellOfferToken?: Maybe<Scalars["String"]>;
};

export interface FlightsDetailCriteriaInput {
  additionalShoppedAncillaries?: InputMaybe<Array<AdditionalShoppedAncillaries>>;
  ancillaryId?: InputMaybe<Scalars["String"]>;
  channelType?: InputMaybe<ChannelType>;
  journeyContinuationId: Scalars["String"];
  originalBookingId?: InputMaybe<Scalars["String"]>;
  upsellOfferToken?: InputMaybe<Scalars["String"]>;
}

export type FlightsDetailedJourneyInformation = {
  __typename?: "FlightsDetailedJourneyInformation";
  badges?: Maybe<Array<EgdsBadge>>;
  details: FlightsToggle;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  flightJourneyDetails: FlightsJourneyDetails;
};

export type FlightsDetailsAndFaresContent = {
  __typename?: "FlightsDetailsAndFaresContent";
  dialogId: FlightsDialogId;
  displayAnalytics: Array<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  nestedDialogs?: Maybe<Array<FlightsDialog>>;
  priceMatchPromiseNestedDialogs?: Maybe<Array<FlightsDialog>>;
  sections: Array<FlightsDetailsAndFaresSection>;
};

export type FlightsDetailsAndFaresFooter = {
  __typename?: "FlightsDetailsAndFaresFooter";
  action: FlightsActionButton;
  dialogId: FlightsDialogId;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  priceLockup: EgdsPriceLockup;
};

export type FlightsDetailsAndFaresPresentation = {
  __typename?: "FlightsDetailsAndFaresPresentation";
  content: FlightsDetailsAndFaresContent;
  flightsFaresWrapper: FlightsDialogSheet;
  footer?: Maybe<FlightsDetailsAndFaresFooter>;
  preloadedInfo?: Maybe<PreloadedInfo>;
  trigger: FlightsDialogTrigger;
};

export type FlightsDetailsAndFaresPresentationElements = {
  __typename?: "FlightsDetailsAndFaresPresentationElements";
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  journeyContinuationId: Scalars["String"];
};

export type FlightsDetailsAndFaresPriceSection = {
  __typename?: "FlightsDetailsAndFaresPriceSection";
  leadingPrice?: Maybe<EgdsSpannableText>;
  price: EgdsSpannableText;
  secondaryTexts?: Maybe<Array<EgdsSpannableText>>;
  trailingPrice?: Maybe<EgdsSpannableText>;
};

export type FlightsDetailsAndFaresSection =
  | FlightsFaresInformation
  | FlightsHygieneAmenitiesSection
  | FlightsJourneyDetailsInformation
  | FlightsJourneyHeaderInformation
  | FlightsJourneyWithDetails
  | FlightsMessagingCard
  | FlightsSustainabilityCard
  | FlightsSustainabilityMessaging
  | TextWrapper
  | TextWrapperFlightsPhrase;

export interface FlightsDevicePermissionsInput {
  pushNotificationsEnabled: Array<NotificationsType>;
}

export type FlightsDialog = {
  __typename?: "FlightsDialog";
  content?: Maybe<Array<EgdsElement>>;
  dialog: EgdsDialog;
  dialogId: FlightsDialogId;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
};

export type FlightsDialogAction = "CLOSE" | "OPEN" | "TOGGLE";

export type FlightsDialogAnimation = "FADE" | "NONE";

export type FlightsDialogChangeFlight = FlightsDialogPresentation & {
  __typename?: "FlightsDialogChangeFlight";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsDialogCloseAction = FlightsClientAction & {
  __typename?: "FlightsDialogCloseAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsDialogCloseUiAction = UiAction & {
  __typename?: "FlightsDialogCloseUIAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type FlightsDialogId =
  | "AIRLINE_CREDITS"
  | "BAGGAGE_CLOSE_COMPLETE_DIALOG"
  | "BAGGAGE_CLOSE_DIALOG"
  | "BAGGAGE_CLOSE_INCOMPLETE_DIALOG"
  | "BAGGAGE_CONTINUE_BOOKING"
  | "BAGGAGE_DETAILS_DIALOG"
  | "BAGS_POPUP_ON_CHECKOUT"
  | "CHECK_CREDITS_DIALOG"
  | "CKO_REASSURANCE_DISCLAIMER_DIALOG"
  | "FLIGHTS_DETAILS_AND_FARES"
  | "FORCED_CHOICE_MODAL"
  | "FSR_HOW_DEALS_WORK_SHEET"
  | "FSR_OUT_DISCLAIMER_DIALOG"
  | "HOW_CREDITS_WORK"
  | "LEAVE_TO_COUPONS"
  | "MERCHANDISING_SELECTIONS_BOOKING_LOADING"
  | "MERCHANDISING_SELECTIONS_EXPIRY"
  | "ONE_CLICK_FARE_DOWNGRADE_DIALOG"
  | "PAID_BAGS_DIALOG"
  | "PMP_INFO_DIALOG"
  | "PMP_REMOVE_DIALOG"
  | "SEATS_POPUP_ON_CHECKOUT"
  | "SEAT_CELL_DETAILS_DIALOG"
  | "SEAT_CLOSE_COMPLETE_DIALOG"
  | "SEAT_CONTINUE_BOOKING"
  | "SEAT_LEGEND_DIALOG"
  | "SEAT_SELECTION_DIALOG"
  | "TRAVELER_MISMATCH_DIALOG";

export type FlightsDialogMultiPassengerCredit = FlightsDialogPresentation & {
  __typename?: "FlightsDialogMultiPassengerCredit";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message: Scalars["String"];
  secondaries?: Maybe<Array<EgdsStylizedText>>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsDialogPreAction = "CLOSE_ALL_DIALOGS";

export interface FlightsDialogPresentation {
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
}

export type FlightsDialogServerError = FlightsDialogPresentation & {
  __typename?: "FlightsDialogServerError";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsDialogSheet = {
  __typename?: "FlightsDialogSheet";
  dialog: FlightsDialog;
  dialogId: FlightsDialogId;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  /** @deprecated use sheetInfo instead */
  sheet: FlightsSideSheet;
  sheetInfo?: Maybe<FlightsSheet>;
};

export type FlightsDialogStaleSession = FlightsDialogPresentation & {
  __typename?: "FlightsDialogStaleSession";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  timeoutInSeconds: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
};

export type FlightsDialogStandard = FlightsDialogPresentation & {
  __typename?: "FlightsDialogStandard";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsDialogTrigger = EgdsButton &
  EgdsElement & {
    __typename?: "FlightsDialogTrigger";
    /** @deprecated Will be provided in trigger field */
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
     *         or a union type with a manageable number of members
     *
     */
    action?: Maybe<UiAction>;
    /** @deprecated Will be provided in trigger field */
    analytics?: Maybe<ClientSideAnalytics>;
    dialogAction: FlightsDialogAction;
    dialogId: FlightsDialogId;
    dialogPreAction?: Maybe<FlightsDialogPreAction>;
    /** @deprecated Will be provided in trigger field */
    disabled: Scalars["Boolean"];
    dynamicDialogId?: Maybe<Scalars["String"]>;
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated Will be provided in trigger field */
    icon?: Maybe<Icon>;
    /** @deprecated Will be provided in trigger field */
    primary?: Maybe<Scalars["String"]>;
    trigger: FlightsDialogTriggerType;
  };

export type FlightsDialogTriggerAction = FlightsClientAction & {
  __typename?: "FlightsDialogTriggerAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialog: EgdsDialog;
  dialogContents: Array<EgdsElement>;
};

export type FlightsDialogTriggerType =
  | FlightsActionButton
  | FlightsActionLink
  | FlightsExperienceActionButton
  | FlightsExperienceActionLink;

export type FlightsDialogTriggerUiAction = UiAction & {
  __typename?: "FlightsDialogTriggerUIAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  dialog: EgdsDialog;
  dialogContents: Array<EgdsElement>;
};

export type FlightsDisplayComponent = "SMART_NFF_ALTERNATE_SUGGESTION";

export type FlightsDisplayComponentCountingValue = FlightsDisplayComponentValue & {
  __typename?: "FlightsDisplayComponentCountingValue";
  displayCount: Scalars["Int"];
  flightsDisplayComponent: FlightsDisplayComponent;
};

export interface FlightsDisplayComponentCountingValueInput {
  displayCount: Scalars["Int"];
  flightsDisplayComponent: FlightsDisplayComponent;
}

export interface FlightsDisplayComponentValue {
  flightsDisplayComponent: FlightsDisplayComponent;
}

export interface FlightsDisplayComponentValuesInput {
  displayCountingValues?: InputMaybe<Array<FlightsDisplayComponentCountingValueInput>>;
}

export type FlightsDurationBasedFilterValue = {
  __typename?: "FlightsDurationBasedFilterValue";
  maxDuration: Scalars["Int"];
};

export interface FlightsDurationBasedFilterValueInput {
  maxDuration: Scalars["Int"];
}

export type FlightsDurationTimeline = {
  __typename?: "FlightsDurationTimeline";
  /** @deprecated Use 'secondary' field to support different screen sizes */
  additionalInfo: Scalars["String"];
  end: DurationTimelineSideSection;
  /** @deprecated Use 'primary' field to support different screen sizes */
  heading: Scalars["String"];
  primary: FlightsResponsiveMessage;
  secondary: FlightsResponsiveMessage;
  start: DurationTimelineSideSection;
  stops: Scalars["Int"];
};

export interface FlightsDynamicElements {
  dynamicElementsGroupTrackEventAnalytics?: Maybe<FlightsDynamicElementsTrackEventAnalytics>;
  flightsJourneyDetailsDynamicElements?: Maybe<Array<FlightsJourneyDetailsDynamicElements>>;
}

export type FlightsDynamicElementsTrackEventAnalytics = {
  __typename?: "FlightsDynamicElementsTrackEventAnalytics";
  analyticsList: Array<FlightsAnalytics>;
  stringifiedAllFlightProducts: Scalars["String"];
};

export type FlightsElementsWrapper = {
  __typename?: "FlightsElementsWrapper";
  element: EgdsElement;
};

export type FlightsEmailSubscription = {
  __typename?: "FlightsEmailSubscription";
  icon: Icon;
  label: Scalars["String"];
  submitAction: FlightsAction;
  title: Scalars["String"];
};

export interface FlightsErrorMessagingPresentation {
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
}

export type FlightsErrorSummary = {
  __typename?: "FlightsErrorSummary";
  displayAnalytics: FlightsAnalytics;
  header: Scalars["String"];
  linkAnalytics: FlightsAnalytics;
  linkText: Scalars["String"];
};

export interface FlightsEventAnalytics {
  eventCategory: Scalars["String"];
  eventType: Scalars["String"];
  pageName: Scalars["String"];
}

export type FlightsExpandableBottomSheet = FlightsSheet & {
  __typename?: "FlightsExpandableBottomSheet";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  content?: Maybe<Array<EgdsElement>>;
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  sheetState?: Maybe<SheetState>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsExpando = {
  __typename?: "FlightsExpando";
  contents: EgdsTextContainer;
  expando: EgdsExpando;
};

export type FlightsExperienceActionButton = EgdsButton & {
  __typename?: "FlightsExperienceActionButton";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: FlightsClientAction;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]>;
  style: EgdsButtonStyle;
  width?: Maybe<FlightsButtonWidth>;
};

export type FlightsExperienceActionLink = {
  __typename?: "FlightsExperienceActionLink";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: FlightsClientAction;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]>;
};

export type FlightsExperienceBargainFareOffer = {
  __typename?: "FlightsExperienceBargainFareOffer";
  accessibilityMessage: Scalars["String"];
  bargainPricingInformation: FlightsSmartShoppingPriceSummary;
  description: Scalars["String"];
  header: Scalars["String"];
  offerDialogTrigger: FlightsExperienceDialogTrigger;
  sanitizedOfferToken?: Maybe<Scalars["String"]>;
};

export type FlightsExperienceDialog = {
  __typename?: "FlightsExperienceDialog";
  dialog: EgdsDialog;
  dialogId: FlightsDialogId;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
};

export type FlightsExperienceDialogSheet = {
  __typename?: "FlightsExperienceDialogSheet";
  /** @deprecated This will be replaced by experienceDialog */
  dialog: FlightsExperienceDialog;
  dialogId: FlightsDialogId;
  experienceDialog: FlightsDialog;
  experienceSheet: FlightsSideSheet;
  /** @deprecated This will be replaced by experienceSheet */
  sheet: FlightsExperienceSideSheet;
};

export type FlightsExperienceDialogTrigger = {
  __typename?: "FlightsExperienceDialogTrigger";
  dialogAction: FlightsDialogAction;
  dialogId: FlightsDialogId;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  trigger: FlightsDialogTriggerType;
};

export type FlightsExperienceSideSheet = {
  __typename?: "FlightsExperienceSideSheet";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsFareCalendarPrice = {
  __typename?: "FlightsFareCalendarPrice";
  price?: Maybe<Money>;
  priceTheme: FlightsFareCalendarPriceTheme;
  valueText: Scalars["String"];
};

export type FlightsFareCalendarPriceTheme = "NEGATIVE" | "POSITIVE" | "UNDEFINED";

export type FlightsFareCalendarResponse = {
  __typename?: "FlightsFareCalendarResponse";
  coverageAnalytics: FlightsAnalytics;
  datePickerCellContents: Array<FlightsDatePickerCellContent>;
  disclaimerText: Scalars["String"];
  priceLockupText?: Maybe<Scalars["String"]>;
};

export type FlightsFareChoiceInformation = {
  __typename?: "FlightsFareChoiceInformation";
  accessibleHeading: Scalars["String"];
  availableFaresCount: Scalars["Int"];
  convertAnalytics?: Maybe<Array<FlightsAnalytics>>;
  defaultBaggageInformation: FlightsBaggageInformation;
  dialogs?: Maybe<Array<FlightsDialog>>;
  /** @deprecated This field will be replaced by displayAnalyticsList */
  displayAnalytics: FlightsAnalytics;
  displayAnalyticsList: Array<FlightsAnalytics>;
  errorAnalytics?: Maybe<Array<FlightsAnalytics>>;
  fareTypes: Array<FlightsJourneyFare>;
  fareTypesSkeletonCount: Scalars["Int"];
  fares?: Maybe<Array<FlightsJourneyFareBase>>;
  faresTypesLoadingAnalytics: FaresTypesLoadingAnalytics;
  heading: FlightsResponsiveMessage;
  message: Scalars["String"];
  nextFareChoiceAccessibilityMessage: Scalars["String"];
  percentageDelaysAndCancellationLink?: Maybe<FlightsLinkAction>;
  previousFareChoiceAccessibilityMessage: Scalars["String"];
  priceMatchPromiseDialogs?: Maybe<Array<FlightsDialog>>;
  scrollAnalytics: FlightsAnalytics;
  sustainabilityInformation?: Maybe<FlightsIconPhrases>;
  tripTypePerTraveler: Scalars["String"];
};

export type FlightsFareDetailsError = {
  __typename?: "FlightsFareDetailsError";
  error: Scalars["String"];
};

export interface FlightsFareDetailsInput {
  orderNumber: Scalars["String"];
}

export type FlightsFareDetailsSuccess = {
  __typename?: "FlightsFareDetailsSuccess";
  primaryDetails: LabeledValue;
  secondaryDetails?: Maybe<Array<LabeledValue>>;
};

export type FlightsFareDetailsView = FlightsFareDetailsError | FlightsFareDetailsSuccess;

export type FlightsFareRule = {
  __typename?: "FlightsFareRule";
  displayName: FlightsIconAndLabel;
  value?: Maybe<Scalars["String"]>;
};

export type FlightsFareTypeSelectionAction = FlightsClientAction & {
  __typename?: "FlightsFareTypeSelectionAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogId?: Maybe<Scalars["String"]>;
  nextPage: Uri;
  packagePage?: Maybe<Uri>;
};

export type FlightsFaresInformation = {
  __typename?: "FlightsFaresInformation";
  /** @deprecated Deprecated in favor of faresCarousel */
  carousel: EgdsCarouselContainer;
  displayAnalytics: Array<ClientSideAnalytics>;
  fares: Array<FareInformationCard>;
  faresCarousel: EgdsCarousel;
  faresLoadingAnalytics?: Maybe<FaresLoadingAnalytics>;
  heading?: Maybe<Scalars["String"]>;
  scrollAnalytics: Array<ClientSideAnalytics>;
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  subheading?: Maybe<Scalars["String"]>;
  totalFareCount: Scalars["Int"];
};

export type FlightsFaresSeparationType =
  | "BASE_AND_UPSELL"
  | "NONSTOP_BASE_AND_UPSELL"
  | "NONSTOP_ONLY_BASE"
  | "NONSTOP_ONLY_UPSELL"
  | "ONLY_BASE"
  | "ONLY_UPSELL";

export type FlightsFeedback = {
  __typename?: "FlightsFeedback";
  feedbackLinkAction: FlightsAction;
  iconAndLabel: FlightsIconAndLabel;
};

export interface FlightsFilterItem {
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  defaultSelected: Scalars["Boolean"];
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"];
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  value: FlightsFilterValue;
}

export type FlightsFilterItemAnalytics = {
  __typename?: "FlightsFilterItemAnalytics";
  category?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  max?: Maybe<Scalars["String"]>;
  min?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type FlightsFilterLoadedPresentation = FlightsFilterPresentation & {
  __typename?: "FlightsFilterLoadedPresentation";
  accessibility: FlightsAccessibilityMessage;
  emptyFiltersMessage?: Maybe<Scalars["String"]>;
  eventAnalytics: FlightsFiltersEventAnalytics;
  options?: Maybe<Array<FlightsFilterOption>>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsFilterLoadingPresentation = FlightsFilterPresentation & {
  __typename?: "FlightsFilterLoadingPresentation";
  accessibility: FlightsAccessibilityMessage;
  options: Array<FlightsFilterSkeletonOption>;
  skeletonCount: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
};

export interface FlightsFilterOption {
  label: Scalars["String"];
  supportsMultipleSelection: Scalars["Boolean"];
  type: FlightsFilterType;
}

export interface FlightsFilterPresentation {
  accessibility: FlightsAccessibilityMessage;
  title?: Maybe<Scalars["String"]>;
}

export type FlightsFilterSkeletonOption = {
  __typename?: "FlightsFilterSkeletonOption";
  itemCount: Scalars["Int"];
  label: Scalars["String"];
  priceFromLabel?: Maybe<Scalars["String"]>;
};

export type FlightsFilterType =
  | "ARRIVAL_AIRPORT"
  | "ARRIVAL_TIME"
  | "ARRIVAL_TIME_SLIDER"
  | "CARRY_ON_BAG"
  | "CHECKED_BAG"
  | "CREDIT_ELIGIBLE"
  | "DEPARTURE_AIRPORT"
  | "DEPARTURE_TIME"
  | "DEPARTURE_TIME_SLIDER"
  | "DURATION"
  | "FLEXIBLE_CHANGE_POLICIES"
  | "LAYOVER_AIRPORT"
  | "LAYOVER_TIME_SLIDER"
  | "NO_CANCEL_FEE"
  | "NO_CHANGE_FEE"
  | "NUM_OF_STOPS"
  | "PREFERRED_AIRLINE"
  | "PRICE"
  | "SEAT_CHOICE"
  | "TRAVEL_AND_BAGGAGE";

export type FlightsFilterValue =
  | FlightsAirportCodeFilterValue
  | FlightsCarryOnBagBasedFilterValue
  | FlightsCheckedBagBasedFilterValue
  | FlightsCreditEligibleFilterValue
  | FlightsDurationBasedFilterValue
  | FlightsFlexibleChangePolicyBasedFilterValue
  | FlightsNoCancelFeeBasedFilterValue
  | FlightsNoChangeFeeBasedFilterValue
  | FlightsPreferredAirlineFilterValue
  | FlightsSeatChoiceBasedFilterValue
  | FlightsSliderFilterValue
  | FlightsStopBasedFilterValue
  | FlightsTimeBasedFilterValue
  | FlightsTravelAndBaggageBasedFilterValue;

export type FlightsFiltersEventAnalytics = FlightsEventAnalytics & {
  __typename?: "FlightsFiltersEventAnalytics";
  eventCategory: Scalars["String"];
  eventType: Scalars["String"];
  filterAppliedEventName: Scalars["String"];
  filterRemovedEventName: Scalars["String"];
  pageName: Scalars["String"];
};

export type FlightsFlexibleChangePolicyBasedFilterValue = {
  __typename?: "FlightsFlexibleChangePolicyBasedFilterValue";
  policyName: FilterPolicyType;
};

export interface FlightsFlexibleChangePolicyBasedFilterValueInput {
  policyName: FilterPolicyType;
}

export type FlightsFlexibleGridType = "FLEX_SEARCH" | "FLEX_SEARCH_GRID" | "FLEX_SEARCH_NOT_APPLICABLE";

export interface FlightsFlexibleSearchCell {
  accessibility: FlightsFlexibleSearchCellAccessibility;
  labelText?: Maybe<Scalars["String"]>;
  valueText?: Maybe<Scalars["String"]>;
}

export type FlightsFlexibleSearchCellAccessibility = {
  __typename?: "FlightsFlexibleSearchCellAccessibility";
  departureDate?: Maybe<Scalars["String"]>;
  highlightDescription?: Maybe<Scalars["String"]>;
  imageDescription?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  returnDate?: Maybe<Scalars["String"]>;
  selectionDescription?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchGridDateHeaders = {
  __typename?: "FlightsFlexibleSearchGridDateHeaders";
  returnDateHeaders: Array<FlightsFlexibleSearchCell>;
};

export type FlightsFlexibleSearchGridLegendBadge = {
  __typename?: "FlightsFlexibleSearchGridLegendBadge";
  accessibility: Scalars["String"];
  icon?: Maybe<Icon>;
  labelText?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchGridTrigger = {
  __typename?: "FlightsFlexibleSearchGridTrigger";
  accessibility: FlightsAccessibilityMessage;
  calendarIcon?: Maybe<Icon>;
  collapseAction?: Maybe<Scalars["String"]>;
  collapseAnalytics: FlightsAnalytics;
  expandAction: Scalars["String"];
  expandAnalytics: FlightsAnalytics;
  flexGridTransitionIcon?: Maybe<Icon>;
  subText?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchHeaderCell = FlightsFlexibleSearchCell & {
  __typename?: "FlightsFlexibleSearchHeaderCell";
  accessibility: FlightsFlexibleSearchCellAccessibility;
  labelText?: Maybe<Scalars["String"]>;
  theme: FlightsFlexibleSearchHeaderCellTheme;
  valueText?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchHeaderCellTheme = "DEFAULT" | "SEARCHED_DATE_HEADER";

export type FlightsFlexibleSearchLegendCell = FlightsFlexibleSearchCell & {
  __typename?: "FlightsFlexibleSearchLegendCell";
  accessibility: FlightsFlexibleSearchCellAccessibility;
  accessibilityMessage?: Maybe<FlightsAccessibilityMessage>;
  icon?: Maybe<Icon>;
  labelText?: Maybe<Scalars["String"]>;
  theme: FlightsFlexibleSearchLegendCellTheme;
  valueText?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchLegendCellTheme = "DEFAULT";

export type FlightsFlexibleSearchResponse = {
  __typename?: "FlightsFlexibleSearchResponse";
  accuracyDeviationAnalytics?: Maybe<FlightsAnalytics>;
  accuracyNffAnalytics?: Maybe<FlightsAnalytics>;
  analytics: FlightsFlexibleSearchResponseAnalytics;
  cheapestPrice?: Maybe<Scalars["String"]>;
  coverageAnalytics: FlightsAnalytics;
  coverageNotValidAnalytics?: Maybe<FlightsAnalytics>;
  departureLegend?: Maybe<FlightsFlexibleSearchGridLegendBadge>;
  dismiss?: Maybe<Icon>;
  dismissGridAccessibility: FlightsAccessibilityMessage;
  dismissGridAnalytics: FlightsAnalytics;
  flexGridType: FlightsFlexibleGridType;
  gridTrigger?: Maybe<FlightsFlexibleSearchGridTrigger>;
  loadingStateAccessibility: Scalars["String"];
  resultsGrid: FlightsFlexibleSearchResultsGrid;
  returnDateHeaders?: Maybe<FlightsFlexibleSearchGridDateHeaders>;
  searchTriggerAction?: Maybe<Scalars["String"]>;
  showFlexGrid: Scalars["Boolean"];
  subTitle: Scalars["String"];
  title: Scalars["String"];
  titleAccessibility: Scalars["String"];
};

export type FlightsFlexibleSearchResponseAnalytics = {
  __typename?: "FlightsFlexibleSearchResponseAnalytics";
  priceFillRate: Scalars["String"];
};

export type FlightsFlexibleSearchResultAnalytics = {
  __typename?: "FlightsFlexibleSearchResultAnalytics";
  departureDateDifferential?: Maybe<Scalars["String"]>;
  priceDifferential?: Maybe<Scalars["String"]>;
  returnDateDifferential?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchResultCell = FlightsFlexibleSearchCell & {
  __typename?: "FlightsFlexibleSearchResultCell";
  accessibility: FlightsFlexibleSearchCellAccessibility;
  analytics?: Maybe<FlightsFlexibleSearchResultAnalytics>;
  domain?: Maybe<FlightsFlexibleSearchResultCellDomain>;
  isLink: Scalars["Boolean"];
  journeyDate?: Maybe<Scalars["String"]>;
  labelText?: Maybe<Scalars["String"]>;
  nextSearchCriteria?: Maybe<FlightsNextJourneyCriteria>;
  selectAnalytics: FlightsAnalytics;
  selectButtonActive?: Maybe<Scalars["Boolean"]>;
  summary?: Maybe<Scalars["String"]>;
  theme: FlightsFlexibleSearchResultCellTheme;
  valueText?: Maybe<Scalars["String"]>;
};

export type FlightsFlexibleSearchResultCellDomain = {
  __typename?: "FlightsFlexibleSearchResultCellDomain";
  price: Money;
};

export type FlightsFlexibleSearchResultCellTheme =
  | "DEFAULT_PRICE"
  | "HIGHEST_PRICE"
  | "LOWEST_PRICE"
  | "NO_PRICE"
  | "SEARCHED_DATE"
  | "SEARCHED_DATE_LOWEST_PRICE"
  | "SEARCHED_DATE_NO_PRICE"
  | "SEARCHED_DATE_UNAVAILABLE_FLIGHTS"
  | "UNAVAILABLE_FLIGHTS";

export type FlightsFlexibleSearchResultsGrid = {
  __typename?: "FlightsFlexibleSearchResultsGrid";
  results: Array<Array<FlightsFlexibleSearchCell>>;
};

export type FlightsForcedChoiceCrossSellDialog = FlightsDialogPresentation & {
  __typename?: "FlightsForcedChoiceCrossSellDialog";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  annotation: FlightsPhrase;
  /** @deprecated Replaced with checkInDatePicker to use EGDSSearchFormDatePickerField */
  checkInDate?: Maybe<FlightsSearchFormDateField>;
  checkInDatePicker?: Maybe<EgdsSearchFormDatePickerField>;
  /** @deprecated Replaced with checkOutDatePicker to use EGDSSearchFormDatePickerField */
  checkOutDate?: Maybe<FlightsSearchFormDateField>;
  checkOutDatePicker?: Maybe<EgdsSearchFormDatePickerField>;
  displayBlockedAnalytics: FlightsAnalytics;
  displayCounter?: Maybe<Scalars["Int"]>;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"];
  hotelDatesTitle?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  messageBody: Array<EgdsText>;
  /** @deprecated Deprecating to introduce a new field of type EGDSText to handle different copy use cases */
  subHeader: FlightsIconAndPhrase;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsHeaderListing = {
  __typename?: "FlightsHeaderListing";
  contents: Array<EgdsSpannableText>;
  /** @deprecated Being replaced by contents */
  heading: Scalars["String"];
  headingSize: FlightsHeadingSize;
  /** @deprecated Being replaced by contents */
  subHeading?: Maybe<Scalars["String"]>;
};

export type FlightsHeadingSize = "EIGHT" | "FIVE" | "FOUR" | "ONE" | "SEVEN" | "SIX" | "THREE";

export type FlightsHygieneAmenitiesPresentation = {
  __typename?: "FlightsHygieneAmenitiesPresentation";
  airlineAmenityGroups: Array<FlightsAirlineAmenityGroup>;
  amenitiesAction?: Maybe<FlightsTextSection>;
  disclaimerMessage: Scalars["String"];
  /** @deprecated This will be replaced by amenitiesAction */
  displayAction?: Maybe<FlightsTextSection>;
  flightInformationLink?: Maybe<FlightsLinkAction>;
};

export type FlightsHygieneAmenitiesSection = {
  __typename?: "FlightsHygieneAmenitiesSection";
  amenities: Array<EgdsIconText>;
  footer: Scalars["String"];
  header: Scalars["String"];
};

export type FlightsIconAndAccessibility = {
  __typename?: "FlightsIconAndAccessibility";
  accessibility?: Maybe<Scalars["String"]>;
  icon: Icon;
};

export type FlightsIconAndHeading = {
  __typename?: "FlightsIconAndHeading";
  heading: Scalars["String"];
  icons: Array<FlightsIconAndLabel>;
};

export type FlightsIconAndLabel = {
  __typename?: "FlightsIconAndLabel";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated Use displayAnalyticsList of type ClientSideAnalytics instead  of this */
  displayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  displayAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  icon: Icon;
  label: Scalars["String"];
  subLabel?: Maybe<Scalars["String"]>;
  subText?: Maybe<EgdsStylizedText>;
  theme: TextTheme;
  value?: Maybe<Scalars["String"]>;
};

export type FlightsIconAndPhrase = {
  __typename?: "FlightsIconAndPhrase";
  icon: Icon;
  label: FlightsPhrase;
};

export type FlightsIconPhrases = FlightsText & {
  __typename?: "FlightsIconPhrases";
  icon?: Maybe<Icon>;
  text: Array<FlightsPhrase>;
};

export type FlightsIconPlacard = FlightsPlacard & {
  __typename?: "FlightsIconPlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]>;
  headingWithMoreInfo?: Maybe<FlightsMoreInfoLink>;
  icon?: Maybe<Icon>;
  message: Scalars["String"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  placardHeading?: Maybe<EgdsHeading>;
};

export type FlightsIconPosition = "LEADING" | "TRAILING";

export type FlightsImageAndLabel = {
  __typename?: "FlightsImageAndLabel";
  image: Image;
  /** @deprecated Everywhere in the code this phrase is styled as STANDARD, which should not be required by PWA, instead a simple string can be sent, for strings which need phrases use FlightsImagePhrases */
  label: FlightsPhrase;
  text: Scalars["String"];
};

export type FlightsImageListingLabel = {
  __typename?: "FlightsImageListingLabel";
  image: Image;
  label: Scalars["String"];
  subLabel?: Maybe<Scalars["String"]>;
};

export type FlightsImagePhrases = FlightsText & {
  __typename?: "FlightsImagePhrases";
  image: Image;
  text: Array<FlightsPhrase>;
};

export type FlightsImagePlacard = FlightsPlacard & {
  __typename?: "FlightsImagePlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]>;
  image: Image;
  message: Scalars["String"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
};

export type FlightsImportantTripInfo = {
  __typename?: "FlightsImportantTripInfo";
  heading: EgdsHeading;
  rules: EgdsOrderedList;
};

export type FlightsInfoLink = {
  __typename?: "FlightsInfoLink";
  actionLink: FlightsExperienceActionLink;
  sheet?: Maybe<FlightsSheetType>;
};

export type FlightsInfoMessagingPresentation = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsInfoMessagingPresentation";
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export type FlightsInformationAnalytics = {
  __typename?: "FlightsInformationAnalytics";
  footerOnViewedAnalytics?: Maybe<FlightsAnalytics>;
  onLoadAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
};

export type FlightsInformationDynamicElements = FlightsDynamicElements & {
  __typename?: "FlightsInformationDynamicElements";
  airOfferToken: Scalars["String"];
  dynamicElementsGroupTrackEventAnalytics?: Maybe<FlightsDynamicElementsTrackEventAnalytics>;
  fareChoiceInformationDynamicElement?: Maybe<FareChoiceInformationDynamicElement>;
  fareChoiceInformationDynamicElementWithPMP?: Maybe<FareChoiceInformationDynamicElement>;
  flightsJourneyDetailsDynamicElements?: Maybe<Array<FlightsJourneyDetailsDynamicElements>>;
  priceSummary: FlightsLoadedPriceSummary;
  priceSummaryWithPMP?: Maybe<FlightsLoadedPriceSummary>;
  totalPrice: Scalars["String"];
  totalPriceWithPMP?: Maybe<Scalars["String"]>;
};

export type FlightsInformationDynamicElementsGroup = {
  __typename?: "FlightsInformationDynamicElementsGroup";
  ancillaryId?: Maybe<Scalars["String"]>;
  dynamicElements: FlightsDynamicElements;
  identifier: Scalars["String"];
};

export type FlightsInformationPriceMatchPromise = {
  __typename?: "FlightsInformationPriceMatchPromise";
  card?: Maybe<FlightsMessagingCardPlacard>;
  dialogs?: Maybe<Array<FlightsDialog>>;
  /** @deprecated Use placementCard instead */
  merchandisingToggleCard?: Maybe<PriceMatchPromiseToggleableCard>;
  placementCard?: Maybe<PriceDropProtectionPresentation>;
  surveyElementId?: Maybe<Scalars["String"]>;
};

export type FlightsInformationResponse = {
  __typename?: "FlightsInformationResponse";
  additionalShopping?: Maybe<Array<AdditionalShopping>>;
  analytics?: Maybe<FlightsInformationAnalytics>;
  banners?: Maybe<Array<FlightsPlacard>>;
  baseOfferAncillaryID?: Maybe<Scalars["String"]>;
  brandPolicies?: Maybe<FlightsTextSection>;
  clientMetadata?: Maybe<FlightsClientMetadata>;
  dynamicElementsGroups?: Maybe<Array<FlightsInformationDynamicElementsGroup>>;
  flightsInformationPriceMatchPromise?: Maybe<FlightsInformationPriceMatchPromise>;
  flightsJourneyPlaybackExperience?: Maybe<JourneyExperience>;
  flightsOneClickFareUpgrade?: Maybe<FlightsOneClickFareUpgradeCardPresentation>;
  flightsOpinionLab?: Maybe<FlightsActionAndLabel>;
  flightsSelectedJourneyDetails?: Maybe<Array<FlightsSelectedJourneyDetails>>;
  flightsSelectedJourneyInformation?: Maybe<FlightsSelectedJourneyDetailsInformation>;
  /** @deprecated This will be replaced by FlightsSelectedJourneyDetails interface */
  flightsSelectedJourneyReview?: Maybe<Array<FlightsSelectedJourneyReview>>;
  flightsSupportedAncillaries?: Maybe<FlightsSupportedAncillaryInfo>;
  freeCancellation?: Maybe<FlightsPlacard>;
  freePriceMatchPromise?: Maybe<FreePriceMatchPromise>;
  gaiaId?: Maybe<Scalars["String"]>;
  gaiaIds?: Maybe<Array<Scalars["String"]>>;
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyContinuationId?: Maybe<Scalars["String"]>;
  journeyStartEndDates?: Maybe<Array<JourneyStartEndDates>>;
  messagingCards?: Maybe<Array<FlightsPlacard>>;
  multiItemDynamicCrossSellPrimer?: Maybe<MultiItemDynamicCrossSellPrimer>;
  pageTitle?: Maybe<FlightsPageTitle>;
  partnerLoyaltyEarnMessage?: Maybe<FlightsMarkPlacard>;
  priceSummary?: Maybe<FlightsPriceSummary>;
  separateTicketBanner?: Maybe<FlightsIconPlacard>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  similarFlights?: Maybe<SimilarFlightsInfo>;
  smartShoppingExperienceEligibility?: Maybe<Scalars["Boolean"]>;
  stepIndicator?: Maybe<FlightsStepIndicator>;
};

export type FlightsInformationResponseBaseOfferAncillaryIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseBrandPoliciesArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseClientMetadataArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseDynamicElementsGroupsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsOpinionLabArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsSelectedJourneyReviewArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsSupportedAncillariesArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseGaiaIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseGaiaIdsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseHygieneAmenitiesPresentationArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseJourneyContinuationIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseJourneyStartEndDatesArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseMultiItemDynamicCrossSellPrimerArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponsePageTitleArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponsePriceSummaryArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseSimilarFlightsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationText = {
  __typename?: "FlightsInformationText";
  content: Array<FlightsInformationTextItem>;
};

export type FlightsInformationTextItem =
  | BadgeWrapper
  | IconWrapper
  | LinkWrapper
  | MarkWrapper
  | SuperlativeText
  | TextWrapper
  | TextWrapperFlightsPhrase;

export type FlightsJourneyAmenities = {
  __typename?: "FlightsJourneyAmenities";
  accessibilityMessage: Scalars["String"];
  amenities: Array<FlightsIconAndLabel>;
  analytics?: Maybe<FlightsAnalytics>;
};

export type FlightsJourneyAmenitiesWithLabel = {
  __typename?: "FlightsJourneyAmenitiesWithLabel";
  label: Scalars["String"];
  rules: FlightsJourneyAmenities;
};

export type FlightsJourneyAmenityRules = {
  __typename?: "FlightsJourneyAmenityRules";
  amenities: Array<FlightsIconAndLabel>;
  itemsDefaultDisplayCount: Scalars["Int"];
};

export type FlightsJourneyAvailableFares = FlightsAvailableFares & {
  __typename?: "FlightsJourneyAvailableFares";
  applySelection: FlightsAction;
  dismissDetailedJourneyInformation: FlightsActionableIcon;
  fareChoiceInformation?: Maybe<FlightsFareChoiceInformation>;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  openAccessibility: Scalars["String"];
  signInMessagingCard?: Maybe<EgdsStandardMessagingCard>;
};

export type FlightsJourneyAvailableFaresInformationElements = {
  __typename?: "FlightsJourneyAvailableFaresInformationElements";
  flightsJourneyAvailableFaresInformation?: Maybe<FlightsJourneyAvailableFares>;
  journeyContinuationId: Scalars["String"];
  /** @deprecated Not in usage */
  stringifiedFlightsJourneyAvailableFaresInformation?: Maybe<Scalars["String"]>;
};

export type FlightsJourneyBaggageSelectionFailure = {
  __typename?: "FlightsJourneyBaggageSelectionFailure";
  errorMessage: FlightsAncillaryErrorMessage;
  staticAncillaries?: Maybe<FlightsCategorizedList>;
};

export type FlightsJourneyBaggageSelectionPresentation =
  | FlightsJourneyBaggageSelectionFailure
  | FlightsJourneyBaggageSelectionSuccess;

export type FlightsJourneyBaggageSelectionSuccess = {
  __typename?: "FlightsJourneyBaggageSelectionSuccess";
  journeyContent: FlightsBaggageAvailableFlightLegSelection;
};

export type FlightsJourneyCriteria = {
  __typename?: "FlightsJourneyCriteria";
  arrivalDate?: Maybe<Date>;
  departureDate: Date;
  destination: Scalars["String"];
  destinationAirportLocationType?: Maybe<AirportLocationType>;
  /** @deprecated Use cabinClass in FlightsSearchPreferences, replace with FlightsSearchPreferences.CabinClass */
  flightsCabinClass?: Maybe<FlightsCabinClass>;
  origin: Scalars["String"];
  originAirportLocationType?: Maybe<AirportLocationType>;
};

export interface FlightsJourneyCriteriaInput {
  arrivalDate?: InputMaybe<DateInput>;
  departureDate: DateInput;
  destination: Scalars["String"];
  destinationAirportLocationType?: InputMaybe<AirportLocationType>;
  flightsCabinClass?: InputMaybe<FlightsCabinClass>;
  origin: Scalars["String"];
  originAirportLocationType?: InputMaybe<AirportLocationType>;
}

export type FlightsJourneyCriteriaInputType = {
  __typename?: "FlightsJourneyCriteriaInputType";
  arrivalDate?: Maybe<DateInputType>;
  departureDate: DateInputType;
  destination: Scalars["String"];
  destinationAirportLocationType?: Maybe<AirportLocationType>;
  flightsCabinClass?: Maybe<FlightsCabinClass>;
  origin: Scalars["String"];
  originAirportLocationType?: Maybe<AirportLocationType>;
};

export type FlightsJourneyDetails = {
  __typename?: "FlightsJourneyDetails";
  badge?: Maybe<JourneyDetailsBadge>;
  journeyAmenities?: Maybe<Array<FlightsIconAndLabel>>;
  /** @deprecated This field will be replaced by journeySections */
  journeyParts: Array<JourneyPart>;
  journeySections: Array<JourneySection>;
};

export type FlightsJourneyDetailsDynamicElements = {
  __typename?: "FlightsJourneyDetailsDynamicElements";
  ancillaryId?: Maybe<Scalars["String"]>;
  details?: Maybe<FlightsAction>;
  fareChangeAction?: Maybe<FlightsAction>;
  fareName?: Maybe<Scalars["String"]>;
  fareSeatMapDynamicElement?: Maybe<FareSeatMapDynamicElement>;
  flightsFareNudgeMessage?: Maybe<FlightsIconAndLabel>;
  journeyPartsDynamicElements: Array<JourneyPartsDynamicElements>;
  message?: Maybe<Scalars["String"]>;
  seatMapTitle?: Maybe<Scalars["String"]>;
  selectedSeatDetails?: Maybe<Array<FlightsSelectedSeatDetail>>;
  /** @deprecated This field will be replaced by seatMapTitle */
  title: Scalars["String"];
  upsellNudgingFeatureEnabled: Scalars["Boolean"];
};

export type FlightsJourneyDetailsInformation = {
  __typename?: "FlightsJourneyDetailsInformation";
  /** @deprecated Use expandoContent instead of this type */
  content: Array<FlightsJourneyDetailsInformationSection>;
  expando: EgdsExpandoLink;
  expandoContent: Array<FlightsJourneyDetailsSection>;
};

export type FlightsJourneyDetailsInformationSection = FlightsJourneyDetails;

export type FlightsJourneyDetailsSection = {
  __typename?: "FlightsJourneyDetailsSection";
  /** @deprecated Will be replaced by journeyAmenities */
  amenities?: Maybe<Array<EgdsGraphicText>>;
  badge?: Maybe<JourneyDetailsBadge>;
  decorator?: Maybe<Array<FlightsTextSectionDecorator>>;
  details: Array<EgdsText>;
  graphic?: Maybe<UiGraphic>;
  journeyAmenities?: Maybe<Array<EgdsText>>;
};

export type FlightsJourneyFare = FlightsJourneyFareBase & {
  __typename?: "FlightsJourneyFare";
  accessibilityMessage: Scalars["String"];
  amenityHierarchyRules?: Maybe<Array<FlightsJourneyAmenitiesWithLabel>>;
  amenityRules: FlightsJourneyAmenityRules;
  badges?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation: FlightsBaggageInformation;
  cabinClass: Scalars["String"];
  cabinClassAndBookingCodes?: Maybe<Array<Scalars["String"]>>;
  changeCancellationMessages?: Maybe<FlightsTextSection>;
  /** @deprecated This will be replaced by selectFareAction */
  chooseFareAction: FlightsAction;
  /** @deprecated This will be replaced by selectFareActionAltText */
  chooseFareActionAltText?: Maybe<Scalars["String"]>;
  clickEventAnalytics: FlightsAnalytics;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  collapsedRules: FlightsJourneyAmenities;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  expandedRules: Array<FlightsJourneyAmenitiesWithLabel>;
  fareCardIdentifier: Scalars["String"];
  fareJourneyPolicies?: Maybe<FlightsTextSection>;
  fareScrollAnalytics: FlightsAnalytics;
  flightsOfferNaturalKeys?: Maybe<FlightsOfferNaturalKeys>;
  formattedMainPrice: FlightsPhrase;
  formattedPrice: FlightsPhrase;
  highlighted: Scalars["Boolean"];
  identifier: Scalars["String"];
  journeyFareTotalPrice?: Maybe<Scalars["String"]>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  multiItemAction?: Maybe<MultiItemAction>;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  packageOfferId?: Maybe<Scalars["String"]>;
  partnerLoyaltyEarnAward?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated Replaced by formattedPrice */
  price: Money;
  priceAccessibilityMessage: Scalars["String"];
  priceLockup: EgdsPriceLockup;
  /** @deprecated Replaced by badges */
  recommendation?: Maybe<FlightsBadge>;
  safetyLocationAccessibilityMessage?: Maybe<Scalars["String"]>;
  selectFareAction: FlightsAction;
  selectFareActionAltText?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
  selectedFareAccessibilityMessage: Scalars["String"];
  showMoreAmenitiesToggle?: Maybe<FlightsToggle>;
  /** @deprecated Replaced by showMoreAmenitiesToggle */
  showMoreToggle: FlightsToggle;
  /** @deprecated Replaced by journeyFareTotalPrice */
  totalPrice: Scalars["String"];
  upsellOfferToken: Scalars["String"];
};

export interface FlightsJourneyFareBase {
  accessibilityMessage: Scalars["String"];
  amenityHierarchyRules?: Maybe<Array<FlightsJourneyAmenitiesWithLabel>>;
  amenityRules: FlightsJourneyAmenityRules;
  badges?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation: FlightsBaggageInformation;
  cabinClass: Scalars["String"];
  cabinClassAndBookingCodes?: Maybe<Array<Scalars["String"]>>;
  changeCancellationMessages?: Maybe<FlightsTextSection>;
  /** @deprecated This will be replaced by selectFareAction */
  chooseFareAction: FlightsAction;
  /** @deprecated This will be replaced by selectFareActionAltText */
  chooseFareActionAltText?: Maybe<Scalars["String"]>;
  clickEventAnalytics: FlightsAnalytics;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  collapsedRules: FlightsJourneyAmenities;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  expandedRules: Array<FlightsJourneyAmenitiesWithLabel>;
  fareCardIdentifier: Scalars["String"];
  fareJourneyPolicies?: Maybe<FlightsTextSection>;
  fareScrollAnalytics: FlightsAnalytics;
  flightsOfferNaturalKeys?: Maybe<FlightsOfferNaturalKeys>;
  /** @deprecated Replaced by priceLockup */
  formattedMainPrice: FlightsPhrase;
  /** @deprecated Replaced by priceLockup */
  formattedPrice: FlightsPhrase;
  highlighted: Scalars["Boolean"];
  identifier: Scalars["String"];
  /** @deprecated Replaced by priceLockup */
  journeyFareTotalPrice?: Maybe<Scalars["String"]>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  multiItemAction?: Maybe<MultiItemAction>;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  packageOfferId?: Maybe<Scalars["String"]>;
  partnerLoyaltyEarnAward?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated Replaced by priceLockup */
  price: Money;
  priceAccessibilityMessage: Scalars["String"];
  priceLockup: EgdsPriceLockup;
  /** @deprecated Replaced by badges */
  recommendation?: Maybe<FlightsBadge>;
  safetyLocationAccessibilityMessage?: Maybe<Scalars["String"]>;
  selectFareAction: FlightsAction;
  selectFareActionAltText?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
  selectedFareAccessibilityMessage: Scalars["String"];
  showMoreAmenitiesToggle?: Maybe<FlightsToggle>;
  /** @deprecated Replaced by showMoreAmenitiesToggle */
  showMoreToggle: FlightsToggle;
  /** @deprecated Replaced by priceLockup */
  totalPrice: Scalars["String"];
  upsellOfferToken: Scalars["String"];
}

export type FlightsJourneyHeaderInformation = {
  __typename?: "FlightsJourneyHeaderInformation";
  /** @deprecated Use JourneyHeaderInformation please */
  content: Array<FlightsInformationText>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  headerInformation: Array<EgdsText>;
  journeyHeaderInformation?: Maybe<Array<FlightsJourneyHeaderInformationContent>>;
};

export type FlightsJourneyHeaderInformationContent = {
  __typename?: "FlightsJourneyHeaderInformationContent";
  badges?: Maybe<Array<EgdsBadge>>;
  link?: Maybe<EgdsStandardLink>;
  textContent?: Maybe<Array<EgdsText>>;
};

export type FlightsJourneyHeaders = {
  __typename?: "FlightsJourneyHeaders";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  changeFlight?: Maybe<FlightsAction>;
  flightsJourneySubheading: Scalars["String"];
  /** @deprecated Use instead flightsJourneySubheadingMark - new field using the EGDS Mark instead of Image */
  flightsJourneySubheadingImage?: Maybe<Image>;
  flightsJourneySubheadingMark?: Maybe<Mark>;
  heading: Scalars["String"];
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  journeyDurationAndStopsSubheading?: Maybe<FlightsMessageAndAccessibility>;
  journeyTimeAndStopsSubheading: FlightsJourneyTimeAndStops;
};

export type FlightsJourneyHighlights = {
  __typename?: "FlightsJourneyHighlights";
  flightsJourneyHeaders: FlightsJourneyHeaders;
  /** @deprecated This is a non-nullable field and will be replaced by flightsJourneySummaryDetail */
  flightsJourneySummary: FlightsJourneySummary;
  flightsJourneySummaryDetail?: Maybe<FlightsJourneySummary>;
};

export type FlightsJourneyInfoSection = {
  __typename?: "FlightsJourneyInfoSection";
  airlineLogo?: Maybe<MarkWrapper>;
  airlineName?: Maybe<EgdsPlainText>;
  journeyTitle?: Maybe<EgdsHeading>;
};

export type FlightsJourneyRating = {
  __typename?: "FlightsJourneyRating";
  analytics: FlightsAnalytics;
  description: Scalars["String"];
  label: Scalars["String"];
};

export type FlightsJourneyReviewCommon = {
  __typename?: "FlightsJourneyReviewCommon";
  changeFlight: FlightsAction;
  fareSummarySkeletonCount: Scalars["Int"];
  flightSummarySkeletonCount: Scalars["Int"];
};

export type FlightsJourneySearchCriteria = {
  __typename?: "FlightsJourneySearchCriteria";
  flightsSearchContext: FlightsSearchContext;
  journeyCriteria: Array<FlightsJourneyCriteria>;
  previousFlightSelections?: Maybe<Array<JourneyFlightSelection>>;
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelerDetails: Array<FlightsTravelerDetails>;
};

export type FlightsJourneySeatAncillaryCabinFailure = {
  __typename?: "FlightsJourneySeatAncillaryCabinFailure";
  errorMessage: FlightsMessagingCardPlacard;
};

export type FlightsJourneySeatAncillaryCabinSuccess = {
  __typename?: "FlightsJourneySeatAncillaryCabinSuccess";
  journeySeatContent: FlightsJourneySeatContent;
};

export type FlightsJourneySeatContent = {
  __typename?: "FlightsJourneySeatContent";
  cabinToastMessages?: Maybe<FlightsSeatSelectionCabinToastMessages>;
  displayAnalytics: Array<ClientSideAnalytics>;
  seatMapGridDetails: FlightsSeatMapGridDetails;
  /** @deprecated Will be sent inside FlightsSeatAncillaryDetailsLoaded */
  seatSelectionMessages: FlightsSeatSelectionNotificationMessages;
  seatsConfirmedDetails?: Maybe<Array<FlightsSeatsConfirmedDetails>>;
};

export type FlightsJourneySeatMapCabinContent =
  | FlightsJourneySeatAncillaryCabinFailure
  | FlightsJourneySeatAncillaryCabinSuccess;

export interface FlightsJourneySummary {
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  heading: FlightsMessageAndAccessibility;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
}

export type FlightsJourneyTimeAndStops = {
  __typename?: "FlightsJourneyTimeAndStops";
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  durationAndStops: FlightsMessageAndAccessibility;
  flightTimes: FlightsMessageAndAccessibility;
};

export type FlightsJourneyWithDetails = {
  __typename?: "FlightsJourneyWithDetails";
  details: Array<EgdsText>;
  footnotes: Array<EgdsText>;
  header: Scalars["String"];
  subheader: Scalars["String"];
};

export type FlightsLabelAndValue = {
  __typename?: "FlightsLabelAndValue";
  accessibilityMessage: Scalars["String"];
  label: Scalars["String"];
  value: Scalars["String"];
};

export type FlightsLegAncillarySummaryActionType = {
  __typename?: "FlightsLegAncillarySummaryActionType";
  analyticsList: Array<ClientSideAnalytics>;
  id: FlightsActionType;
};

export type FlightsLegDropdownItemSelection = FlightsClientAction & {
  __typename?: "FlightsLegDropdownItemSelection";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsLinkAction = {
  __typename?: "FlightsLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: FlightsAnalytics;
  displayAction: Scalars["String"];
  url: Scalars["URL"];
};

export type FlightsLinkTarget = "EXTERNAL" | "INTERNAL";

export type FlightsListDisplayFilterItem = FlightsFilterItem & {
  __typename?: "FlightsListDisplayFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  analytics: FlightsFilterItemAnalytics;
  defaultSelected: Scalars["Boolean"];
  deselectAnalytics: FlightsAnalytics;
  flightsSearchFilterValues: FlightsSearchFilterValues;
  formattedMultiItemStartingPrice?: Maybe<Scalars["String"]>;
  identifier: Scalars["String"];
  label: Scalars["String"];
  selectAnalytics: FlightsAnalytics;
  selectPositionAnalytics?: Maybe<FlightsAnalytics>;
  selected: Scalars["Boolean"];
  startingPrice?: Maybe<Money>;
  value: FlightsFilterValue;
};

export type FlightsListDisplayFilterOption = FlightsFilterOption & {
  __typename?: "FlightsListDisplayFilterOption";
  items: Array<FlightsListDisplayFilterItem>;
  itemsDefaultDisplayCount: Scalars["Int"];
  label: Scalars["String"];
  priceFromLabel?: Maybe<Scalars["String"]>;
  showMoreToggle?: Maybe<FlightsToggle>;
  supportsMultipleSelection: Scalars["Boolean"];
  type: FlightsFilterType;
};

export type FlightsListing =
  | EgdsElementWrapper
  | FlightsBargainFareOffer
  | FlightsCarryOverFiltersCard
  | FlightsCouponCredit
  | FlightsCreditsAvailableListing
  | FlightsCrossSellMessage
  | FlightsCrossSellOffer
  | FlightsElementsWrapper
  | FlightsEmailSubscription
  | FlightsFeedback
  | FlightsHeaderListing
  | FlightsListingMessagingCard
  | FlightsListingOffer
  | FlightsOfferCard
  | FlightsOneKeyBannerListing
  | FlightsPriceAlertsListing
  | FlightsShoppingStandardOfferCard
  | FlightsSocialProofMessage
  | FlightsSponsoredContentAdListing
  | FlightsStandardOffer
  | FlightsSuggestedFilterMessageListing
  | FlightsSustainabilityMessage
  | InsurtechPrimingCardListing;

export type FlightsListingMessagingCard = {
  __typename?: "FlightsListingMessagingCard";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  messagingCard: FlightsMessagingCardPlacard;
  standardMessagingCard?: Maybe<EgdsStandardMessagingCard>;
};

export type FlightsListingOffer = {
  __typename?: "FlightsListingOffer";
  card: FlightsOfferCard;
  detailsAndFares: FlightsDetailsAndFaresPresentation;
};

export type FlightsListingResult = FlightsLoadedListingResult | FlightsLoadingListingResult | FlightsProgressBarDetails;

export type FlightsListingsAnalytics = {
  __typename?: "FlightsListingsAnalytics";
  amenitiesFillRateAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated best bets sort is replaced by recommended sort */
  bestBetsDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  bestSortDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  fareCalendarAnalytics?: Maybe<FlightsAnalytics>;
  fareUpsellPercentageAnalytics?: Maybe<FlightsAnalytics>;
  moreListingsDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  moreListingsErrorAnalytics?: Maybe<FlightsAnalytics>;
  moreListingsLoadTimeAnalytics?: Maybe<FlightsAnalytics>;
  nonstopSectionDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated This is not needed. Handle last offer analytics in offer node itself */
  onViewedAnalytics: FlightsAnalytics;
  onViewedAnalyticsList: Array<FlightsAnalytics>;
  pinnedListingDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  urgencyMessageAnalytics?: Maybe<FlightsAnalytics>;
};

export type FlightsListingsPagination = {
  __typename?: "FlightsListingsPagination";
  faresSeparationType: FlightsFaresSeparationType;
  pagination: Pagination;
  tripTypeFilter?: Maybe<Array<FlightsTripType>>;
};

export type FlightsListingsViewType = "MIXED" | "SINGLE";

export type FlightsLoadAncillariesAction = FlightsClientAction & {
  __typename?: "FlightsLoadAncillariesAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsLoadedListingResult = {
  __typename?: "FlightsLoadedListingResult";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  appliedFilterAccessibility?: Maybe<Scalars["String"]>;
  appliedSortingAccessibility?: Maybe<Scalars["String"]>;
  flightsListingsAnalytics: FlightsListingsAnalytics;
  listings?: Maybe<Array<FlightsListing>>;
  listingsAction?: Maybe<FlightsAction>;
  moreListingsAvailable?: Maybe<Scalars["String"]>;
  pinnedListingUnavailableMessage?: Maybe<Scalars["String"]>;
  showMoreListingLoadedAccessibilityMessage?: Maybe<Scalars["String"]>;
  toasts?: Maybe<Array<EgdsToast>>;
};

export type FlightsLoadedMerchandisingDetailsCard = FlightsMerchandisingDetailsCard & {
  __typename?: "FlightsLoadedMerchandisingDetailsCard";
  confirmationDetails?: Maybe<EgdsTextContainer>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: EgdsHeading;
  journeyDetails: Array<JourneyDetails>;
  priceDetails?: Maybe<PricePresentation>;
  subHeading: EgdsPlainText;
};

export type FlightsLoadedMerchandisingPagePresentation = FlightsMerchandisingPagePresentation & {
  __typename?: "FlightsLoadedMerchandisingPagePresentation";
  backButtonAction?: Maybe<FlightsExperienceActionLink>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  header: FlightsMessaging;
  /** @deprecated moved this outside of page presentation */
  reviewTitle?: Maybe<FlightsMessaging>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  /** @deprecated moved this outside of page presentation */
  title: FlightsMessaging;
};

export type FlightsLoadedPriceSummary = FlightsPriceSummary & {
  __typename?: "FlightsLoadedPriceSummary";
  bannerMessage?: Maybe<PricePresentationLineItem>;
  bookWithAirlinesDialog?: Maybe<BookWithAirlinesDialog>;
  breakdown: Array<FlightsPriceSummaryGroup>;
  buttonAction: FlightsAction;
  /** @deprecated No longer in use, to be cleaned up */
  creditShoppingRedirectAction?: Maybe<FlightsAction>;
  /** @deprecated No longer in use, to be cleaned up */
  creditShoppingRedirectMessaging?: Maybe<Scalars["String"]>;
  dismiss: FlightsActionableIcon;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  flightsAncillaryAlertDialogList?: Maybe<Array<FlightsDialog>>;
  /** @deprecated New implementation on Shared-UI requires new type, replace with flightsAncillaryAlertDialogList */
  flightsAncillaryAlertDialogs?: Maybe<Array<FlightsAncillaryAlertDialog>>;
  policies: FlightsTextSection;
  pricePresentation?: Maybe<PricePresentation>;
  selectedAncillaries?: Maybe<Array<FlightsAncillaryType>>;
  signInMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  title: Scalars["String"];
  totals?: Maybe<Array<FlightsPriceSummaryGroup>>;
  tripTotalDetails: FlightsPriceSummaryTripTotalItem;
  tripTotalLabel: Scalars["String"];
  urgencyMessage?: Maybe<EgdsBadge>;
  viewPriceSummary: FlightsAction;
};

export type FlightsLoadedSearchForm = FlightsSearchForm & {
  __typename?: "FlightsLoadedSearchForm";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  addJourneyAction?: Maybe<FlightsAction>;
  airportDropdownEnabled?: Maybe<Scalars["Boolean"]>;
  appliedFilterCount?: Maybe<Scalars["Int"]>;
  availableRoutes?: Maybe<AvailableRoutes>;
  bookingChangeLink?: Maybe<FlightsResourceLinkAction>;
  closeActionAccessibility?: Maybe<Scalars["String"]>;
  closeFormAction?: Maybe<FlightsAction>;
  collapseFormAnalytics: FlightsAnalytics;
  collapsedFormAccessibility?: Maybe<FlightsResponsiveAccessibilityMessage>;
  departureAndReturnDates?: Maybe<FlightsResponsiveMessage>;
  errorSummary: FlightsErrorSummary;
  expandFilterAction: FlightsIconAndLabel;
  expandFormAnalytics: FlightsAnalytics;
  expandSearchFormAction: Icon;
  journeys: Array<FlightsSearchFormJourneyCriteria>;
  /** @deprecated This field is now deprecated */
  mDestEnabled?: Maybe<Scalars["Boolean"]>;
  newJourneyTemplate: FlightsSearchFormJourneyCriteria;
  originAndDestinationLocations?: Maybe<Scalars["String"]>;
  searchAction?: Maybe<Scalars["String"]>;
  searchActionAccessibility?: Maybe<Scalars["String"]>;
  searchActionActive: Scalars["Boolean"];
  searchActionAnalytics: FlightsAnalytics;
  searchOptions: Array<FlightsSearchFormOption>;
  travelersSummary: FlightsMultiPluralLocalizationTemplate;
};

export type FlightsLoadedSortAndFilterResult = FlightsSortAndFilterResult & {
  __typename?: "FlightsLoadedSortAndFilterResult";
  appliedFiltersSummary?: Maybe<FlightsAppliedFiltersSummary>;
  applyAction: FlightsSortAndFilterAction;
  clearAllSelectionLabel: FlightsActionableLabel;
  clearSelectionLabel: FlightsActionableLabel;
  dismiss: Icon;
  dismissAccessibility?: Maybe<Scalars["String"]>;
  dismissAnalytics: FlightsAnalytics;
  filterAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
  filterPresentation?: Maybe<FlightsFilterLoadedPresentation>;
  revealAction: FlightsSortAndFilterRevealAction;
  revealActionAnalytics: FlightsAnalytics;
  sortPresentation: FlightsSortLoadedPresentation;
  title: Scalars["String"];
};

export type FlightsLoadingListingResult = {
  __typename?: "FlightsLoadingListingResult";
  accessibility?: Maybe<Scalars["String"]>;
  flightsPlacard?: Maybe<FlightsPlacard>;
  image: Image;
  listingsSkeletonCount: Scalars["Int"];
  message: Scalars["String"];
  originDestination: Scalars["String"];
  paginationConfig?: Maybe<Array<FlightsListingsPagination>>;
  preFilterWrapper?: Maybe<FiltersWrapper>;
  /** @deprecated We don't need it now since we have created the Progress Details which will be null when not needed */
  progressBar: FlightsProgressBarDisplay;
  progressBarDetails?: Maybe<FlightsProgressBarDetails>;
  toast?: Maybe<EgdsToast>;
};

export type FlightsLoadingMerchandisingDetailsCard = FlightsMerchandisingDetailsCard & {
  __typename?: "FlightsLoadingMerchandisingDetailsCard";
  heading: EgdsHeading;
};

export type FlightsLoadingMerchandisingPagePresentation = FlightsMerchandisingPagePresentation & {
  __typename?: "FlightsLoadingMerchandisingPagePresentation";
  action?: Maybe<FlightsClientAction>;
  availableMerchandise: Array<FlightsAncillaryType>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
};

export type FlightsLoadingPriceSummary = FlightsPriceSummary & {
  __typename?: "FlightsLoadingPriceSummary";
  buttonAction: FlightsAction;
  skeletonCount: Scalars["Int"];
  title: Scalars["String"];
  tripTotalLabel: Scalars["String"];
};

export type FlightsLoadingSearchForm = FlightsSearchForm & {
  __typename?: "FlightsLoadingSearchForm";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  addJourneyAction?: Maybe<FlightsAction>;
  airportDropdownEnabled?: Maybe<Scalars["Boolean"]>;
  appliedFilterCount?: Maybe<Scalars["Int"]>;
  availableRoutes?: Maybe<AvailableRoutes>;
  bookingChangeLink?: Maybe<FlightsResourceLinkAction>;
  closeActionAccessibility?: Maybe<Scalars["String"]>;
  closeFormAction?: Maybe<FlightsAction>;
  collapseFormAnalytics: FlightsAnalytics;
  collapsedFormAccessibility?: Maybe<FlightsResponsiveAccessibilityMessage>;
  departureAndReturnDates?: Maybe<FlightsResponsiveMessage>;
  errorSummary: FlightsErrorSummary;
  expandFilterAction: FlightsIconAndLabel;
  expandFormAnalytics: FlightsAnalytics;
  expandSearchFormAction: Icon;
  journeys: Array<FlightsSearchFormJourneyCriteria>;
  /** @deprecated This field is now deprecated */
  mDestEnabled?: Maybe<Scalars["Boolean"]>;
  newJourneyTemplate: FlightsSearchFormJourneyCriteria;
  originAndDestinationLocations?: Maybe<Scalars["String"]>;
  searchAction?: Maybe<Scalars["String"]>;
  searchActionAccessibility?: Maybe<Scalars["String"]>;
  searchActionActive: Scalars["Boolean"];
  searchOptions: Array<FlightsSearchFormOption>;
  travelersSummary: FlightsMultiPluralLocalizationTemplate;
};

export type FlightsLoadingSortAndFilterResult = FlightsSortAndFilterResult & {
  __typename?: "FlightsLoadingSortAndFilterResult";
  filterPresentation: FlightsFilterLoadingPresentation;
  revealAction: FlightsSortAndFilterRevealAction;
  sortPresentation: FlightsSortLoadingPresentation;
  title: Scalars["String"];
};

export interface FlightsLocalizationTemplate {
  initialValue?: Maybe<Scalars["String"]>;
  tokens: Array<Scalars["String"]>;
}

export type FlightsLoyaltyPointsOption = {
  __typename?: "FlightsLoyaltyPointsOption";
  accessibility: Scalars["String"];
  formattedPoints: Scalars["String"];
  isPointsFirst: Scalars["Boolean"];
  isStrikeThroughFirst: Scalars["Boolean"];
  leadingCaption: Scalars["String"];
  overageAmount?: Maybe<Scalars["String"]>;
  strikeThroughFormattedPoints?: Maybe<Scalars["String"]>;
};

export type FlightsMarkAndLabel = {
  __typename?: "FlightsMarkAndLabel";
  accessibility?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  mark?: Maybe<Mark>;
  stylisedText?: Maybe<EgdsSpannableText>;
  subLabel?: Maybe<Scalars["String"]>;
};

export type FlightsMarkPlacard = FlightsPlacard & {
  __typename?: "FlightsMarkPlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]>;
  mark?: Maybe<Mark>;
  message: Scalars["String"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  stylisedText?: Maybe<EgdsSpannableText>;
};

export type FlightsMerchandisingAvailCheckFailureResponse = FlightsMerchandisingAvailCheckResponse & {
  __typename?: "FlightsMerchandisingAvailCheckFailureResponse";
  action: FlightsClientAction;
  content?: Maybe<FlightsMerchandisingDialogAndSheet>;
  dynamicId?: Maybe<Scalars["String"]>;
};

export interface FlightsMerchandisingAvailCheckResponse {
  action: FlightsClientAction;
}

export type FlightsMerchandisingAvailCheckSuccessResponse = FlightsMerchandisingAvailCheckResponse & {
  __typename?: "FlightsMerchandisingAvailCheckSuccessResponse";
  action: FlightsClientAction;
  loadingDialog?: Maybe<FlightsDialog>;
};

export type FlightsMerchandisingBookAction = FlightsClientAction & {
  __typename?: "FlightsMerchandisingBookAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsMerchandisingBookFailureResponse = FlightsMerchandisingBookResponse & {
  __typename?: "FlightsMerchandisingBookFailureResponse";
  action: FlightsClientAction;
  dynamicId?: Maybe<Scalars["String"]>;
};

export interface FlightsMerchandisingBookResponse {
  action: FlightsClientAction;
}

export type FlightsMerchandisingBookSuccessResponse = FlightsMerchandisingBookResponse & {
  __typename?: "FlightsMerchandisingBookSuccessResponse";
  action: FlightsClientAction;
};

export interface FlightsMerchandisingCheckoutPagePresentation {
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
}

export type FlightsMerchandisingCheckoutResponse = {
  __typename?: "FlightsMerchandisingCheckoutResponse";
  actionButton: FlightsExperienceActionButton;
  expando?: Maybe<FlightsExpando>;
  loadingDialog: FlightsDialog;
  messagingCard: EgdsTextSection;
  pagePresentation?: Maybe<FlightsMerchandisingCheckoutPagePresentation>;
  payment: FlightsPayment;
  placards?: Maybe<Array<FlightsUiPlacard>>;
};

export interface FlightsMerchandisingConfirmationPagePresentation {
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
}

export type FlightsMerchandisingConfirmationResponse = {
  __typename?: "FlightsMerchandisingConfirmationResponse";
  actionButton: FlightsExperienceActionButton;
  confirmationCard?: Maybe<FlightsUiPlacard>;
  pagePresentation?: Maybe<FlightsMerchandisingConfirmationPagePresentation>;
  placards?: Maybe<Array<FlightsUiPlacard>>;
  priceDetails?: Maybe<PricePresentation>;
};

export interface FlightsMerchandisingDetailsCard {
  heading?: Maybe<EgdsHeading>;
}

export type FlightsMerchandisingDialogAndSheet = {
  __typename?: "FlightsMerchandisingDialogAndSheet";
  dialog?: Maybe<FlightsDialog>;
  dialogId: FlightsDialogId;
  sheet: FlightsMerchandisingSheet;
};

export type FlightsMerchandisingLoadedCheckoutPagePresentation = FlightsMerchandisingCheckoutPagePresentation & {
  __typename?: "FlightsMerchandisingLoadedCheckoutPagePresentation";
  /** @deprecated moved this outside of PagePresentation */
  buttonAction: FlightsAction;
  errors?: Maybe<Array<FlightsUiPlacard>>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export type FlightsMerchandisingLoadedConfirmationPagePresentation = FlightsMerchandisingConfirmationPagePresentation & {
  __typename?: "FlightsMerchandisingLoadedConfirmationPagePresentation";
  /** @deprecated moved this outside of PagePresentation */
  buttonAction: FlightsAction;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export type FlightsMerchandisingLoadingCheckoutPagePresentation = FlightsMerchandisingCheckoutPagePresentation & {
  __typename?: "FlightsMerchandisingLoadingCheckoutPagePresentation";
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export type FlightsMerchandisingLoadingConfirmationPagePresentation = FlightsMerchandisingConfirmationPagePresentation & {
  __typename?: "FlightsMerchandisingLoadingConfirmationPagePresentation";
  action?: Maybe<FlightsClientAction>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export interface FlightsMerchandisingPagePresentation {
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
}

export type FlightsMerchandisingResponse = {
  __typename?: "FlightsMerchandisingResponse";
  checkAvailability: FlightsMerchandisingAvailCheckResponse;
  messagingCards?: Maybe<Array<FlightsMessagingCardPlacard>>;
  pagePresentation?: Maybe<FlightsMerchandisingPagePresentation>;
  placards?: Maybe<Array<FlightsUiPlacard>>;
  priceSummary?: Maybe<FlightsPriceSummary>;
  title?: Maybe<FlightsMessaging>;
};

export type FlightsMerchandisingSheet = {
  __typename?: "FlightsMerchandisingSheet";
  content: FlightsMerchandisingSheetContent;
  initialState?: Maybe<FlightsDialogAction>;
  sheet: EgdsSheet;
};

export type FlightsMerchandisingSheetContent = {
  __typename?: "FlightsMerchandisingSheetContent";
  graphic: UiGraphic;
  heading: Scalars["String"];
  message: Scalars["String"];
  primaryButton: FlightsExperienceActionButton;
  tertiaryButton?: Maybe<FlightsExperienceActionButton>;
};

export type FlightsMessageAndAccessibility = {
  __typename?: "FlightsMessageAndAccessibility";
  accessibilityMessage: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  text: Scalars["String"];
  theme?: Maybe<TextTheme>;
};

export type FlightsMessaging = {
  __typename?: "FlightsMessaging";
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
};

export type FlightsMessagingCard = {
  __typename?: "FlightsMessagingCard";
  border: Scalars["Boolean"];
  cardContent: EgdsStandardMessagingCard;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsMessagingCardPlacard = FlightsUiPlacard & {
  __typename?: "FlightsMessagingCardPlacard";
  actionElement?: Maybe<FlightsClientActionElement>;
  /** @deprecated This field is now deprecated because it is becoming so overloaded., replace with FlightsActionButton.FlightsClientAction */
  actions?: Maybe<Array<FlightsAction>>;
  /** @deprecated Please from now on use FlightsClientActionElement for button if present */
  button?: Maybe<FlightsActionButton>;
  displayType: FlightsPlacardDisplayType;
  messagingCard: UiMessagingCard;
  /** @deprecated Use onViewedAnalyticsList for future use. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsMetadata = {
  __typename?: "FlightsMetadata";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  messaging?: Maybe<FlightsMessaging>;
  pageAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  pageName?: Maybe<Scalars["String"]>;
};

export type FlightsMetricName = "LISTINGS_SUPPLY_RESPONSE_TIME";

export type FlightsMoreInfoLink = {
  __typename?: "FlightsMoreInfoLink";
  action: FlightsClientAction;
  dialog?: Maybe<FlightsDialog>;
  icon: Icon;
  sheet?: Maybe<FlightsSheet>;
  text: EgdsText;
};

export type FlightsMultiPluralLocalizationTemplate = FlightsLocalizationTemplate & {
  __typename?: "FlightsMultiPluralLocalizationTemplate";
  fewTemplate: Scalars["String"];
  initialValue?: Maybe<Scalars["String"]>;
  manyTemplate: Scalars["String"];
  oneTemplate: Scalars["String"];
  otherTemplate: Scalars["String"];
  tokens: Array<Scalars["String"]>;
  twoTemplate: Scalars["String"];
  zeroTemplate: Scalars["String"];
};

export type FlightsNavigateToDetails = FlightsClientAction & {
  __typename?: "FlightsNavigateToDetails";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogId: Scalars["String"];
  jcid?: Maybe<Scalars["String"]>;
  nextPage: Uri;
  obid?: Maybe<Scalars["String"]>;
  packagesUrl?: Maybe<Uri>;
  stepIndicatorJcid?: Maybe<Scalars["String"]>;
};

export type FlightsNextJourneyCriteria = FlightsNextJourneyCriteriaLink | FlightsNextJourneyCriteriaValues;

export type FlightsNextJourneyCriteriaLink = {
  __typename?: "FlightsNextJourneyCriteriaLink";
  link: Scalars["URL"];
};

export type FlightsNextJourneyCriteriaValues = {
  __typename?: "FlightsNextJourneyCriteriaValues";
  journeyCriteria: Array<Maybe<FlightsJourneyCriteria>>;
  searchPreferences: FlightSearchPreferences;
};

export type FlightsNoCancelFeeBasedFilterValue = {
  __typename?: "FlightsNoCancelFeeBasedFilterValue";
  noCancelFee: NoCancelFeeType;
};

export interface FlightsNoCancelFeeBasedFilterValueInput {
  noCancelFee: NoCancelFeeType;
}

export type FlightsNoChangeFeeBasedFilterValue = {
  __typename?: "FlightsNoChangeFeeBasedFilterValue";
  noChangeFee: NoChangeFeeType;
};

export interface FlightsNoChangeFeeBasedFilterValueInput {
  noChangeFee: NoChangeFeeType;
}

export type FlightsNoFlightsFoundMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsNoFlightsFoundMessaging";
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export type FlightsNoFlightsFoundWithFilterMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsNoFlightsFoundWithFilterMessaging";
  /** @deprecated Conflicting with action in FlightsActionableErrorMessaging. Replaced with clearFiltersAction */
  action?: Maybe<FlightsAction>;
  clearFiltersAction?: Maybe<FlightsAction>;
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export interface FlightsOffer {
  accessibilityHeading?: Maybe<Scalars["String"]>;
  accessibilityMessage?: Maybe<Scalars["String"]>;
  journeyContinuationId?: Maybe<Scalars["String"]>;
  journeys: Array<FlightsOfferJourney>;
  onClickAnalyticsList: Array<FlightsAnalytics>;
  selectOfferAction: FlightsAction;
  stepIndicatorJcid?: Maybe<Scalars["String"]>;
}

export type FlightsOfferAnalytics = {
  __typename?: "FlightsOfferAnalytics";
  amenitiesInfoNotAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated This field is replaced by the onViewedAnalyticsList */
  onViewedAnalytics: FlightsAnalytics;
  onViewedAnalyticsList: Array<FlightsAnalytics>;
};

export type FlightsOfferBaggageFeesInformation = {
  __typename?: "FlightsOfferBaggageFeesInformation";
  analytics: FlightsAnalytics;
  icon: Icon;
  label: Scalars["String"];
  link?: Maybe<Scalars["URL"]>;
};

export type FlightsOfferCard = {
  __typename?: "FlightsOfferCard";
  additionalContentSections?: Maybe<Array<EgdsElement>>;
  clickAction?: Maybe<FlightsClientAction>;
  content: FlightsOfferContent;
  featuredHeader?: Maybe<FlightsOfferFeaturedHeader>;
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"];
};

export type FlightsOfferContent = {
  __typename?: "FlightsOfferContent";
  badges?: Maybe<Array<EgdsStandardBadge>>;
  priceSection: FlightsOfferPriceSection;
  primarySection: EgdsTextSection;
  secondarySection?: Maybe<EgdsTextSection>;
  tertiarySection?: Maybe<EgdsTextSection>;
};

export type FlightsOfferFareRulesPresentation = {
  __typename?: "FlightsOfferFareRulesPresentation";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  displayAction: FlightsToggle;
  fareTrayMessage?: Maybe<FlightsResponsiveMessage>;
  fareTypes: Array<FlightsOfferFareType>;
};

export type FlightsOfferFareType = {
  __typename?: "FlightsOfferFareType";
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  rules: Array<FlightsFareRule>;
  selectFareAction: FlightsAction;
};

export type FlightsOfferFeaturedHeader = {
  __typename?: "FlightsOfferFeaturedHeader";
  heading: EgdsHeading;
  icon?: Maybe<Icon>;
};

export type FlightsOfferJourney = {
  __typename?: "FlightsOfferJourney";
  adjustedStayDatesMessage?: Maybe<FlightsIconPhrases>;
  airlineList: Array<EgdsGraphicText>;
  /** @deprecated deprecated in favor of airlineList */
  airlines: Array<FlightsImageAndLabel>;
  cabinClass: Scalars["String"];
  departureAndArrivalLocations: Scalars["String"];
  departureAndArrivalTime: FlightsPhrase;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  differentDayArrival?: Maybe<Scalars["String"]>;
  differentDayArrivalAccessibilityMessage?: Maybe<Scalars["String"]>;
  displayAction: Icon;
  durationAndStops: Scalars["String"];
  flightOperatedBy?: Maybe<Scalars["String"]>;
  /** @deprecated Please use detailsAndFares instead as this node is not nullable */
  flightsDetailsAndFaresPresentation: FlightsDetailsAndFaresPresentation;
  /** @deprecated Replaced with detailsAndFares */
  flightsJourneyAvailableFaresInformation?: Maybe<FlightsJourneyAvailableFares>;
  highlightRelevantFaresAnalytics?: Maybe<Array<FlightsAnalytics>>;
  hygieneAmenitiesMessage?: Maybe<Scalars["String"]>;
  /** @deprecated This node will be moved to FlightsJourneyAvailableFares */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  journeyAmenitiesNotAvailableAnalytics?: Maybe<FlightsAnalytics>;
  label: Scalars["String"];
  layoverInformation?: Maybe<Scalars["String"]>;
  positiveHighlightsMessage?: Maybe<Scalars["String"]>;
  rating?: Maybe<FlightsJourneyRating>;
  restrictiveAmenityIcons?: Maybe<Array<Icon>>;
  /** @deprecated Not in usage */
  stringifiedFlightsJourneyAvailableFaresInformation?: Maybe<Scalars["String"]>;
  urgencyAccessibilityMessage?: Maybe<Scalars["String"]>;
  urgencyMessage?: Maybe<Scalars["String"]>;
};

export type FlightsOfferNaturalKeys = {
  __typename?: "FlightsOfferNaturalKeys";
  flightNaturalKey: FlightNaturalKey;
  packagedProductsNaturalKeys?: Maybe<Array<ProductNaturalKey>>;
};

export type FlightsOfferPriceSection = {
  __typename?: "FlightsOfferPriceSection";
  priceLockup: EgdsPriceLockup;
  primaryText?: Maybe<Array<EgdsStylizedText>>;
  subTexts?: Maybe<Array<EgdsText>>;
};

export type FlightsOfferSectionHeading = EgdsSectionHeading & {
  __typename?: "FlightsOfferSectionHeading";
  additionalIcon?: Maybe<Icon>;
  heading: EgdsHeading;
  mark?: Maybe<Mark>;
  subheading?: Maybe<Scalars["String"]>;
};

export type FlightsOfferSponsoredAirline = {
  __typename?: "FlightsOfferSponsoredAirline";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  adUnitID?: Maybe<Scalars["String"]>;
  advertiserID?: Maybe<Scalars["String"]>;
  airlineCode?: Maybe<Scalars["String"]>;
  airlineImage?: Maybe<Image>;
  airlineName?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with sponsoredBeacons */
  clickTrackingURL?: Maybe<Scalars["String"]>;
  creativeID?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with sponsoredBeacons */
  delayedImpressionURL?: Maybe<Scalars["String"]>;
  lineItemID?: Maybe<Scalars["String"]>;
  orderID?: Maybe<Scalars["String"]>;
  sponsoredBeacons?: Maybe<SponsoredTrackingBeacons>;
  sponsoredHeader?: Maybe<Scalars["String"]>;
  sponsoredText?: Maybe<Scalars["String"]>;
};

export type FlightsOfferSponsoredUpsell = {
  __typename?: "FlightsOfferSponsoredUpsell";
  action: FlightsDialogTrigger;
  badge: EgdsStandardBadge;
  details: FlightsSponsoredFareInformation;
  expando: EgdsExpando;
  onViewedAnalytics: ClientSideAnalytics;
  sponsoredFareAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsOfferTag = {
  __typename?: "FlightsOfferTag";
  image: Image;
  message: Scalars["String"];
};

export interface FlightsOnLoadError {
  accessibility: Scalars["String"];
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  message: Scalars["String"];
}

export type FlightsOneClickFareUpgradeCard = {
  __typename?: "FlightsOneClickFareUpgradeCard";
  accessibility?: Maybe<Scalars["String"]>;
  cardStatus?: Maybe<CardStatus>;
  fareScrollIndex?: Maybe<Scalars["Int"]>;
  journeyInfoSection?: Maybe<FlightsJourneyInfoSection>;
  selectedFareHeading?: Maybe<EgdsHeading>;
  selectedFareSection?: Maybe<FlightsSelectedFareSection>;
  upgradeFareSection?: Maybe<FlightsUpgradeFareSection>;
};

export type FlightsOneClickFareUpgradeCardLoaded = FlightsOneClickFareUpgradeCardPresentation & {
  __typename?: "FlightsOneClickFareUpgradeCardLoaded";
  actionToast?: Maybe<EgdsToast>;
  cardStatus?: Maybe<CardStatus>;
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
  scrollIndex: Scalars["Int"];
  selectedFareHeading?: Maybe<EgdsHeading>;
};

export type FlightsOneClickFareUpgradeCardLoading = FlightsOneClickFareUpgradeCardPresentation & {
  __typename?: "FlightsOneClickFareUpgradeCardLoading";
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
};

export interface FlightsOneClickFareUpgradeCardPresentation {
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
}

export type FlightsOneKeyBannerListing = {
  __typename?: "FlightsOneKeyBannerListing";
  _noop?: Maybe<Scalars["Boolean"]>;
};

export type FlightsOpinionLab = {
  __typename?: "FlightsOpinionLab";
  analytics: FlightsAnalytics;
  iconAndLabel: FlightsIconAndLabel;
  link: Scalars["URL"];
};

export type FlightsPmpJourneyFare = FlightsJourneyFareBase & {
  __typename?: "FlightsPMPJourneyFare";
  accessibilityMessage: Scalars["String"];
  amenityHierarchyRules?: Maybe<Array<FlightsJourneyAmenitiesWithLabel>>;
  amenityRules: FlightsJourneyAmenityRules;
  badges?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation: FlightsBaggageInformation;
  cabinClass: Scalars["String"];
  cabinClassAndBookingCodes?: Maybe<Array<Scalars["String"]>>;
  changeCancellationMessages?: Maybe<FlightsTextSection>;
  /** @deprecated This will be replaced by selectFareAction */
  chooseFareAction: FlightsAction;
  /** @deprecated This will be replaced by selectFareActionAltText */
  chooseFareActionAltText?: Maybe<Scalars["String"]>;
  clickEventAnalytics: FlightsAnalytics;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  collapsedRules: FlightsJourneyAmenities;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  expandedRules: Array<FlightsJourneyAmenitiesWithLabel>;
  fareCardIdentifier: Scalars["String"];
  fareJourneyPolicies?: Maybe<FlightsTextSection>;
  fareScrollAnalytics: FlightsAnalytics;
  flightsOfferNaturalKeys?: Maybe<FlightsOfferNaturalKeys>;
  formattedMainPrice: FlightsPhrase;
  formattedPrice: FlightsPhrase;
  highlighted: Scalars["Boolean"];
  identifier: Scalars["String"];
  isPmpSelected: Scalars["Boolean"];
  journeyFareTotalPrice?: Maybe<Scalars["String"]>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  multiItemAction?: Maybe<MultiItemAction>;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  packageOfferId?: Maybe<Scalars["String"]>;
  partnerLoyaltyEarnAward?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated Replaced by formattedPrice */
  price: Money;
  priceAccessibilityMessage: Scalars["String"];
  priceLockup: EgdsPriceLockup;
  priceLockupWithPMP: EgdsPriceLockup;
  priceMatchPromiseSelectedCardSection?: Maybe<PriceMatchPromiseSelectedCardSection>;
  priceMatchPromiseSelectionCardSection?: Maybe<PriceMatchPromiseSelectionCardSection>;
  priceMatchPromiseToggleCard?: Maybe<PriceMatchPromiseToggleableCard>;
  /** @deprecated Replaced by badges */
  recommendation?: Maybe<FlightsBadge>;
  safetyLocationAccessibilityMessage?: Maybe<Scalars["String"]>;
  selectFareAction: FlightsAction;
  selectFareActionAltText?: Maybe<Scalars["String"]>;
  selectFareActionWithPMP: FlightsAction;
  selected: Scalars["Boolean"];
  selectedFareAccessibilityMessage: Scalars["String"];
  showMoreAmenitiesToggle?: Maybe<FlightsToggle>;
  /** @deprecated Replaced by showMoreAmenitiesToggle */
  showMoreToggle: FlightsToggle;
  /** @deprecated Replaced by journeyFareTotalPrice */
  totalPrice: Scalars["String"];
  upsellOfferToken: Scalars["String"];
};

export type FlightsPwaMetadata = {
  __typename?: "FlightsPWAMetadata";
  evaluatedExperiments?: Maybe<Array<Exposure>>;
  pageAnalytics?: Maybe<ClientSideAnalytics>;
  pageName: Scalars["String"];
  /** @deprecated This will be replaced with pageAnalytics */
  pageNameAnalytics: FlightsAnalytics;
  responseMetrics?: Maybe<Array<FlightsResponseTimeMetrics>>;
  responseTags?: Maybe<Array<ResponseTagType>>;
};

export type FlightsPageTitle = {
  __typename?: "FlightsPageTitle";
  date?: Maybe<FlightsResponsiveMessage>;
  disclaimerSubTitle?: Maybe<FlightsPhrase>;
  pageAccessibility?: Maybe<Scalars["String"]>;
  /** @deprecated This field is now deprecated., replace with disclaimerSubTitle */
  subTitle?: Maybe<FlightsResponsiveMessage>;
  title: FlightsResponsiveMessage;
};

export type FlightsPayment = {
  __typename?: "FlightsPayment";
  onAbsentPaymentAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  paymentPresentation?: Maybe<FlightsPaymentPresentation>;
};

export type FlightsPaymentPresentation = {
  __typename?: "FlightsPaymentPresentation";
  heading: Scalars["String"];
  iconMessages?: Maybe<Array<EgdsIconText>>;
  initializeConfig: Scalars["String"];
  onAvailablePaymentAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  onViewedPaymentAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsPhrase = {
  __typename?: "FlightsPhrase";
  completeText: Scalars["String"];
  delimeter?: Maybe<Scalars["String"]>;
  items: Array<FlightsPhrasePart>;
  size?: Maybe<Scalars["Int"]>;
  theme?: Maybe<TextTheme>;
};

export type FlightsPhraseDialogNode = FlightsPhrasePart & {
  __typename?: "FlightsPhraseDialogNode";
  accessibility?: Maybe<Scalars["String"]>;
  actionableIcon?: Maybe<Icon>;
  analytics: FlightsAnalytics;
  dialog: FlightsDialogPresentation;
  /** @deprecated This field will be replaced by actionableIcon */
  icon?: Maybe<Scalars["String"]>;
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"];
};

export type FlightsPhraseLinkNode = FlightsPhrasePart & {
  __typename?: "FlightsPhraseLinkNode";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: FlightsAnalytics;
  displayAnalytics?: Maybe<FlightsAnalytics>;
  icon?: Maybe<Scalars["String"]>;
  link: Scalars["URL"];
  scrollAnalytics?: Maybe<FlightsAnalytics>;
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"];
};

export interface FlightsPhrasePart {
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"];
}

export type FlightsPhrasePartStyle = "EMPHASIS" | "IMPORTANT" | "STANDARD";

export type FlightsPhraseTextNode = FlightsPhrasePart & {
  __typename?: "FlightsPhraseTextNode";
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"];
};

export interface FlightsPlacard {
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
}

export type FlightsPlacardDisplayType = "IMMEDIATE" | "SORT_FILTERS_SERVER_ERROR";

export type FlightsPlacardResponse = {
  __typename?: "FlightsPlacardResponse";
  placards?: Maybe<Array<FlightsUiPlacard>>;
};

export type FlightsPlacardTheme = "HIGHLIGHT" | "PRIMARY" | "SECONDARY" | "STANDARD";

export type FlightsPlacardType =
  | "FREE_CANCELLATION"
  | "HOTEL_ATTACH"
  | "LOYALTY_REWARD"
  | "NO_CHANGE_FEES"
  | "PASSPORT"
  | "PRICE_CHANGE"
  | "SPLIT_TICKET";

export type FlightsPreferredAirlineFilterValue = {
  __typename?: "FlightsPreferredAirlineFilterValue";
  carrierCode: Scalars["String"];
};

export interface FlightsPreferredAirlineFilterValueInput {
  carrierCode: Scalars["String"];
}

export type FlightsPriceAlertsListing = {
  __typename?: "FlightsPriceAlertsListing";
  priceAlerts: FlightsIconPlacard;
};

export type FlightsPriceAlertsOptInResponse = {
  __typename?: "FlightsPriceAlertsOptInResponse";
  optInStatus: Scalars["Boolean"];
  toast: EgdsToast;
};

export type FlightsPriceInfoSummary = {
  __typename?: "FlightsPriceInfoSummary";
  accessibilityInfo: Scalars["String"];
  primaryText?: Maybe<EgdsSpannableText>;
  secondaryText?: Maybe<Array<EgdsSpannableText>>;
};

export interface FlightsPriceSummary {
  buttonAction: FlightsAction;
  title: Scalars["String"];
  tripTotalLabel: Scalars["String"];
}

export type FlightsPriceSummaryGroup = {
  __typename?: "FlightsPriceSummaryGroup";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  entries: Array<FlightsPriceSummaryItem>;
  title?: Maybe<FlightsPriceSummaryItem>;
};

export type FlightsPriceSummaryItem = {
  __typename?: "FlightsPriceSummaryItem";
  details?: Maybe<FlightsPriceSummaryItemDetails>;
  label: Scalars["String"];
  loyaltyPoints?: Maybe<Scalars["String"]>;
  price: Scalars["String"];
  roundedPrice?: Maybe<Scalars["String"]>;
  styles?: Maybe<Array<FlightsPhrasePartStyle>>;
};

export type FlightsPriceSummaryItemDetails = {
  __typename?: "FlightsPriceSummaryItemDetails";
  action: FlightsActionableIcon;
  breakdown: FlightsPriceSummaryItemGroup;
};

export type FlightsPriceSummaryItemError = {
  __typename?: "FlightsPriceSummaryItemError";
  analytics: FlightsAnalytics;
  message: Scalars["String"];
};

export type FlightsPriceSummaryItemGroup = {
  __typename?: "FlightsPriceSummaryItemGroup";
  action: FlightsAction;
  entries: Array<FlightsPriceSummaryItem>;
  error?: Maybe<FlightsPriceSummaryItemError>;
  title: Scalars["String"];
};

export type FlightsPriceSummaryPaymentItem = {
  __typename?: "FlightsPriceSummaryPaymentItem";
  messages?: Maybe<Array<FlightsPhrase>>;
  title: FlightsPriceSummaryItem;
};

export type FlightsPriceSummaryTripTotalItem = {
  __typename?: "FlightsPriceSummaryTripTotalItem";
  ancillaryIncludesText?: Maybe<Scalars["String"]>;
  messages?: Maybe<Array<FlightsTextSection>>;
  paymentBreakdown?: Maybe<Array<FlightsPriceSummaryPaymentItem>>;
  /** @deprecated This will be replaced by ratesCurrencyText */
  ratesCurrencyMessage: Scalars["String"];
  ratesCurrencyText?: Maybe<Scalars["String"]>;
  tripTotal: FlightsPriceSummaryItem;
};

export type FlightsPricingInformation = {
  __typename?: "FlightsPricingInformation";
  cancellationMessage?: Maybe<Scalars["String"]>;
  loyaltyEarnMessage?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated this is replaced by loyaltyEarnMessage */
  loyaltyMessage?: Maybe<FlightsImageAndLabel>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  mainPrice: FlightsPhrase;
  partnerLoyaltyEarnMessage?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated Being replaced with mainPrice to denote the main price shown on flight cards */
  price: FlightsPhrase;
  priceLockup: EgdsPriceLockup;
  pricePerTraveler: FlightsPhrase;
  pricingInformationDisclaimer?: Maybe<FlightsPricingInformationDisclaimer>;
  subPrice?: Maybe<FlightsPhrase>;
  subText?: Maybe<Array<Scalars["String"]>>;
  subTexts?: Maybe<Array<EgdsText>>;
  taxesLabel?: Maybe<Scalars["String"]>;
  totalLabel: Scalars["String"];
  totalPriceForAllTravelers?: Maybe<FlightsPhrase>;
  /** @deprecated Being replaced by subText */
  tripType?: Maybe<Scalars["String"]>;
};

export type FlightsPricingInformationDisclaimer = {
  __typename?: "FlightsPricingInformationDisclaimer";
  a11yStrikeThroughDialogTriggerText: Scalars["String"];
  actionDialog: EgdsActionDialog;
  content: Scalars["String"];
  dialogId: FlightsDialogId;
  triggerAnalytics: Array<ClientSideAnalytics>;
};

export type FlightsProgressBarDetails = {
  __typename?: "FlightsProgressBarDetails";
  /** @deprecated We don't need it now since we have created the accessibilityMessage */
  accessibility: FlightsSimpleLocalizationTemplate;
  accessibilityMessage?: Maybe<Scalars["String"]>;
  listingsLatency: Scalars["Int"];
  sortAndFilterLatency: Scalars["Int"];
};

export type FlightsProgressBarDisplay = "DISABLED" | "ENABLED";

export type FlightsQueryState = "LOADED" | "LOADING";

export type FlightsRedirectOrDialog = FlightsClientAction & {
  __typename?: "FlightsRedirectOrDialog";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogId: Scalars["String"];
  nextPage: Uri;
};

export type FlightsRefreshAction = FlightsClientAction & {
  __typename?: "FlightsRefreshAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsRefundCalculatorContent = {
  __typename?: "FlightsRefundCalculatorContent";
  baseFare?: Maybe<EgdsNumberInputField>;
  name: Scalars["String"];
  nonRefundableFees?: Maybe<Scalars["Float"]>;
  nonRefundableFeesWaived?: Maybe<EgdsBasicRadioButton>;
  nonRefundableTaxes?: Maybe<EgdsNumberInputField>;
  originalPrice: Scalars["Float"];
  penalty?: Maybe<EgdsNumberInputField>;
  penaltyWaivedFullAmount?: Maybe<EgdsBasicRadioButton>;
  penaltyWaivedPartialAmount?: Maybe<EgdsNumberInputField>;
  refundableTaxes?: Maybe<EgdsNumberInputField>;
  selectTravelers?: Maybe<EgdsBasicCheckBox>;
  totalRefund: Scalars["Float"];
};

export type FlightsRefundCalculatorError = {
  __typename?: "FlightsRefundCalculatorError";
  error: Scalars["String"];
};

export type FlightsRefundCalculatorHeader = {
  __typename?: "FlightsRefundCalculatorHeader";
  baseFare?: Maybe<EgdsTextStandardListItem>;
  name: Scalars["String"];
  nonRefundableFees: EgdsTextStandardListItem;
  nonRefundableFeesWaived?: Maybe<EgdsTextStandardListItem>;
  nonRefundableTaxes?: Maybe<EgdsTextStandardListItem>;
  originalPrice: EgdsTextStandardListItem;
  penalty: EgdsTextStandardListItem;
  penaltyWaived?: Maybe<EgdsTextStandardListItem>;
  penaltyWaivedFullAmount?: Maybe<Scalars["String"]>;
  penaltyWaivedPartialAmount?: Maybe<Scalars["String"]>;
  refundableTaxes?: Maybe<EgdsTextStandardListItem>;
  selectTravelers: Scalars["String"];
  totalRefund: EgdsTextStandardListItem;
};

export interface FlightsRefundCalculatorInput {
  orderNumber: Scalars["String"];
  paymentSource: PaymentSource;
  waivingSource: WaivingSource;
}

export type FlightsRefundCalculatorSuccess = {
  __typename?: "FlightsRefundCalculatorSuccess";
  contents: Array<FlightsRefundCalculatorContent>;
  header: FlightsRefundCalculatorHeader;
  resetTable: UiSecondaryButton;
};

export type FlightsRefundCalculatorView = FlightsRefundCalculatorError | FlightsRefundCalculatorSuccess;

export type FlightsRegulatoryStatements = {
  __typename?: "FlightsRegulatoryStatements";
  header?: Maybe<Scalars["String"]>;
  text?: Maybe<Array<EgdsText>>;
};

export type FlightsRemovePriceMatchPromiseAction = FlightsClientAction & {
  __typename?: "FlightsRemovePriceMatchPromiseAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  journeyContinuationId: Scalars["String"];
};

export type FlightsResourceLinkAction = FlightsClientAction & {
  __typename?: "FlightsResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  resource: Uri;
  target: FlightsLinkTarget;
};

export type FlightsResourceRedirectAction = FlightsClientAction & {
  __typename?: "FlightsResourceRedirectAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  resource: Uri;
};

export type FlightsResponseTimeMetrics = {
  __typename?: "FlightsResponseTimeMetrics";
  name: FlightsMetricName;
  value: Scalars["String"];
};

export type FlightsResponsiveAccessibilityMessage = {
  __typename?: "FlightsResponsiveAccessibilityMessage";
  longMessage?: Maybe<Scalars["String"]>;
  shortMessage?: Maybe<Scalars["String"]>;
};

export type FlightsResponsiveIcon = {
  __typename?: "FlightsResponsiveIcon";
  largeView: Icon;
  smallView: Icon;
};

export type FlightsResponsiveMessage = {
  __typename?: "FlightsResponsiveMessage";
  longMessage: Scalars["String"];
  shortMessage?: Maybe<Scalars["String"]>;
};

export type FlightsRestrictiveFareRules = {
  __typename?: "FlightsRestrictiveFareRules";
  dismissLabel: Scalars["String"];
  displayAction: FlightsIconAndPhrase;
  rules: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type FlightsReviewAndBook = {
  __typename?: "FlightsReviewAndBook";
  heading: EgdsHeading;
  /** @deprecated This field is now deprecated., replace with text */
  message?: Maybe<EgdsPlainText>;
  subText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type FlightsReviewLoadingElements = {
  __typename?: "FlightsReviewLoadingElements";
  clientMetaData: FlightsPwaMetadata;
  flightsJourneyReviewCommon: Array<FlightsJourneyReviewCommon>;
  freeCancellation: FlightsPlacard;
  pageTitle: FlightsPageTitle;
  priceSummary: FlightsLoadingPriceSummary;
};

export type FlightsRewardsOption = {
  __typename?: "FlightsRewardsOption";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  /** @deprecated This field will be replaced by identifier */
  id: FlightsRewardsOptionId;
  identifier: RewardsOption;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
};

export type FlightsRewardsOptionId = "ALTAIR" | "AVION" | "NONAVION";

export type FlightsRewardsPricingInfo = {
  __typename?: "FlightsRewardsPricingInfo";
  loyaltyBanners?: Maybe<Array<FlightsPlacard>>;
  /** @deprecated Using stale version of components not used on UI, replace with loyaltyBanners */
  loyaltyMessagingCards?: Maybe<Array<FlightsMessagingCardPlacard>>;
  options?: Maybe<Array<FlightsRewardsOption>>;
  subText: Array<FlightsPhrase>;
  title: FlightsResponsiveMessage;
};

export type FlightsSearchAnalytics = {
  __typename?: "FlightsSearchAnalytics";
  universalSortAndFilterAnalytics?: Maybe<UniversalSortAndFilterAnalytics>;
};

export interface FlightsSearchComponentCriteriaInput {
  isMetaTraffic?: InputMaybe<Scalars["Boolean"]>;
  journeyIdentifier?: InputMaybe<Scalars["String"]>;
  queryParams?: InputMaybe<Array<GraphQlPairInput>>;
}

export type FlightsSearchContext = {
  __typename?: "FlightsSearchContext";
  hasCreditRedemptionIntent?: Maybe<Scalars["Boolean"]>;
  journeysContinuationId?: Maybe<Scalars["String"]>;
  originalBookingId?: Maybe<Scalars["String"]>;
  previousFlightSelections?: Maybe<Array<JourneyFlightSelection>>;
  previousOriginalBookingId?: Maybe<Scalars["String"]>;
  searchCriteriaMismatch?: Maybe<Array<FlightsSearchCriteriaMismatch>>;
  tripType: FlightsTripType;
};

export interface FlightsSearchContextInput {
  hasCreditRedemptionIntent?: InputMaybe<Scalars["Boolean"]>;
  journeysContinuationId?: InputMaybe<Scalars["String"]>;
  originalBookingId?: InputMaybe<Scalars["String"]>;
  previousFlightSelections?: InputMaybe<Array<JourneyFlightSelectionInput>>;
  previousOriginalBookingId?: InputMaybe<Scalars["String"]>;
  searchCriteriaMismatch?: InputMaybe<Array<FlightsSearchCriteriaMismatch>>;
  tripType: FlightsTripType;
}

export type FlightsSearchCriteriaChangeAction = FlightsClientAction & {
  __typename?: "FlightsSearchCriteriaChangeAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  searchCriteria?: Maybe<FlightsJourneySearchCriteria>;
};

export type FlightsSearchCriteriaMismatch = "TRAVELER";

export type FlightsSearchCrossSellOffer = {
  __typename?: "FlightsSearchCrossSellOffer";
  multiItemFlightCrossSellOfferPrimer: MultiItemFlightSearchCrossSellOfferPrimer;
};

export type FlightsSearchCrossSellOfferSavings = {
  __typename?: "FlightsSearchCrossSellOfferSavings";
  description: Scalars["String"];
  dismissAnalytics: FlightsAnalytics;
  dismissLabel: Scalars["String"];
  displayAnalytics: FlightsAnalytics;
  tagline: Scalars["String"];
};

export type FlightsSearchFilterValues = {
  __typename?: "FlightsSearchFilterValues";
  arrivalAirportCodeFilterValue?: Maybe<FlightsAirportCodeFilterValue>;
  arrivalTimeFilterValue?: Maybe<FlightsTimeBasedFilterValue>;
  arrivalTimeSliderFilterValue?: Maybe<FlightsSliderFilterValue>;
  carryOnBagBasedFilterValue?: Maybe<FlightsCarryOnBagBasedFilterValue>;
  checkedBagBasedFilterValue?: Maybe<FlightsCheckedBagBasedFilterValue>;
  creditEligibleFilterValue?: Maybe<FlightsCreditEligibleFilterValue>;
  departureAirportCodeFilterValue?: Maybe<FlightsAirportCodeFilterValue>;
  departureTimeFilterValue?: Maybe<FlightsTimeBasedFilterValue>;
  departureTimeSliderFilterValue?: Maybe<FlightsSliderFilterValue>;
  durationFilterValue?: Maybe<FlightsDurationBasedFilterValue>;
  flexibleChangePolicyBasedFilterValue?: Maybe<FlightsFlexibleChangePolicyBasedFilterValue>;
  layoverAirportCodeFilterValue?: Maybe<FlightsAirportCodeFilterValue>;
  layoverDurationFilterValue?: Maybe<FlightsSliderFilterValue>;
  noCancelFeeBasedFilterValue?: Maybe<FlightsNoCancelFeeBasedFilterValue>;
  /** @deprecated Depercated in favour of FlightsFilterType */
  noChangeFeeBasedFilterValue?: Maybe<FlightsNoChangeFeeBasedFilterValue>;
  numOfStopFilterValue?: Maybe<FlightsStopBasedFilterValue>;
  preferredAirlineFilterValue?: Maybe<FlightsPreferredAirlineFilterValue>;
  priceFilterValue?: Maybe<FlightsSliderFilterValue>;
  seatChoiceBasedFilterValue?: Maybe<FlightsSeatChoiceBasedFilterValue>;
  /** @deprecated Deprecating travelAndBaggageBasedFilterValue to introduce values in separated fields */
  travelAndBaggageBasedFilterValue?: Maybe<FlightsTravelAndBaggageBasedFilterValue>;
};

export interface FlightsSearchFilterValuesInput {
  arrivalAirportCodeFilterValue?: InputMaybe<FlightsAirportCodeFilterValueInput>;
  arrivalTimeFilterValue?: InputMaybe<FlightsTimeBasedFilterValueInput>;
  arrivalTimeSliderFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  carryOnBagBasedFilterValue?: InputMaybe<FlightsCarryOnBagBasedFilterValueInput>;
  checkedBagBasedFilterValue?: InputMaybe<FlightsCheckedBagBasedFilterValueInput>;
  creditEligibleFilterValue?: InputMaybe<FlightsCreditEligibleFilterValueInput>;
  departureAirportCodeFilterValue?: InputMaybe<FlightsAirportCodeFilterValueInput>;
  departureTimeFilterValue?: InputMaybe<FlightsTimeBasedFilterValueInput>;
  departureTimeSliderFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  durationFilterValue?: InputMaybe<FlightsDurationBasedFilterValueInput>;
  flexibleChangePolicyBasedFilterValue?: InputMaybe<FlightsFlexibleChangePolicyBasedFilterValueInput>;
  layoverAirportCodeFilterValue?: InputMaybe<FlightsAirportCodeFilterValueInput>;
  layoverDurationFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  noCancelFeeBasedFilterValue?: InputMaybe<FlightsNoCancelFeeBasedFilterValueInput>;
  noChangeFeeBasedFilterValue?: InputMaybe<FlightsNoChangeFeeBasedFilterValueInput>;
  numOfStopFilterValue?: InputMaybe<FlightsStopBasedFilterValueInput>;
  preferredAirlineFilterValue?: InputMaybe<FlightsPreferredAirlineFilterValueInput>;
  priceFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  seatChoiceBasedFilterValue?: InputMaybe<FlightsSeatChoiceBasedFilterValueInput>;
  travelAndBaggageBasedFilterValue?: InputMaybe<FlightsTravelAndBaggageBasedFilterValueInput>;
}

export interface FlightsSearchForm {
  accessibilityMessage?: Maybe<Scalars["String"]>;
  addJourneyAction?: Maybe<FlightsAction>;
  airportDropdownEnabled?: Maybe<Scalars["Boolean"]>;
  appliedFilterCount?: Maybe<Scalars["Int"]>;
  availableRoutes?: Maybe<AvailableRoutes>;
  bookingChangeLink?: Maybe<FlightsResourceLinkAction>;
  closeActionAccessibility?: Maybe<Scalars["String"]>;
  closeFormAction?: Maybe<FlightsAction>;
  collapseFormAnalytics: FlightsAnalytics;
  collapsedFormAccessibility?: Maybe<FlightsResponsiveAccessibilityMessage>;
  departureAndReturnDates?: Maybe<FlightsResponsiveMessage>;
  errorSummary: FlightsErrorSummary;
  expandFilterAction: FlightsIconAndLabel;
  expandFormAnalytics: FlightsAnalytics;
  expandSearchFormAction: Icon;
  journeys: Array<FlightsSearchFormJourneyCriteria>;
  /** @deprecated This field is now deprecated */
  mDestEnabled?: Maybe<Scalars["Boolean"]>;
  newJourneyTemplate: FlightsSearchFormJourneyCriteria;
  originAndDestinationLocations?: Maybe<Scalars["String"]>;
  searchAction?: Maybe<Scalars["String"]>;
  searchActionAccessibility?: Maybe<Scalars["String"]>;
  searchActionActive: Scalars["Boolean"];
  searchOptions: Array<FlightsSearchFormOption>;
  travelersSummary: FlightsMultiPluralLocalizationTemplate;
}

export type FlightsSearchFormAirlineOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormAirlineOption";
  applyAnalytics: FlightsAnalytics;
  cancelAnalytics: FlightsAnalytics;
  closeIcon?: Maybe<FlightsIconAndAccessibility>;
  doneAction: Scalars["String"];
  doneActionAccessibility?: Maybe<Scalars["String"]>;
  fieldIcon: Icon;
  icon: Icon;
  items: Array<FlightsSearchFormAirlineOptionItem>;
  label: Scalars["String"];
  labelAccessibility?: Maybe<Scalars["String"]>;
  openAnalytics: FlightsAnalytics;
  selectedItem: FlightsSearchFormAirlineOptionItem;
};

export type FlightsSearchFormAirlineOptionItem = {
  __typename?: "FlightsSearchFormAirlineOptionItem";
  airline: Scalars["String"];
  airlineSelectedAnalytics: FlightsAnalytics;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
};

export type FlightsSearchFormCabinClassOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormCabinClassOption";
  fieldIcon: Icon;
  icon: Icon;
  items: Array<FlightsSearchFormCabinClassOptionItem>;
  label: Scalars["String"];
  labelAccessibility?: Maybe<Scalars["String"]>;
  openAnalytics: FlightsAnalytics;
  selectedItem: FlightsSearchFormCabinClassOptionItem;
};

export type FlightsSearchFormCabinClassOptionItem = {
  __typename?: "FlightsSearchFormCabinClassOptionItem";
  cabinClass: FlightsCabinClass;
  cabinClassSelectedAnalytics: FlightsAnalytics;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
};

export type FlightsSearchFormDateField = {
  __typename?: "FlightsSearchFormDateField";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  analytics: FlightsAnalytics;
  currentError?: Maybe<Scalars["String"]>;
  date?: Maybe<Date>;
  dateFormat: Scalars["String"];
  daysBookableInAdvance: Scalars["Int"];
  doneAction: Scalars["String"];
  handleClickAnalytics: FlightsAnalytics;
  label: Scalars["String"];
  maxStayDurationAllowed?: Maybe<Scalars["Int"]>;
  placeholder: Scalars["String"];
  possibleErrors: Array<FlightsSearchFormError>;
  possibleErrorsHeader?: Maybe<FlightsIconAndPhrase>;
};

export type FlightsSearchFormError = {
  __typename?: "FlightsSearchFormError";
  analytics: FlightsAnalytics;
  message: Scalars["String"];
  type: FlightsSearchFormErrorType;
};

export type FlightsSearchFormErrorType =
  | "CHILD_AGE_MISSING"
  | "DATE_BEFORE_PREVIOUS_DATE"
  | "DATE_NOT_IN_VALID_RANGE"
  | "DATE_NOT_IN_VALID_RANGE_TO_DEFAULT_DATE"
  | "INFANT_AGE_MISSING"
  | "INVALID_DATE_FORMAT"
  | "INVALID_TRAVELER_COUNT"
  | "LESS_THAN_MINIMUM_ADULT"
  | "LOCATION_EMPTY"
  | "LOCATION_SAME"
  | "MAX_STAY_DURATION_ERROR"
  | "SAME_CITY"
  | "SPECIAL_CHARACTER_NOT_ALLOWED"
  | "TOO_SHORT_AIRPORT_STRING"
  | "UNATTENDED_INFANT_IN_LAP"
  | "UNATTENDED_INFANT_IN_SEAT";

export type FlightsSearchFormJourneyCriteria = {
  __typename?: "FlightsSearchFormJourneyCriteria";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  departureDate: FlightsSearchFormDateField;
  destinationLocation: FlightsSearchFormLocationField;
  label: FlightsSimpleLocalizationTemplate;
  originLocation: FlightsSearchFormLocationField;
  removeJourneyAction?: Maybe<FlightsAction>;
  returnDate?: Maybe<FlightsSearchFormDateField>;
  swapLocationsAction: FlightsSwapLocationAction;
};

export type FlightsSearchFormLocationField = {
  __typename?: "FlightsSearchFormLocationField";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  airportCode?: Maybe<Scalars["String"]>;
  airportLocationType?: Maybe<AirportLocationType>;
  analytics: FlightsAnalytics;
  currentError?: Maybe<Scalars["String"]>;
  icon: Icon;
  label: Scalars["String"];
  nearbyAirports?: Maybe<FlightsSearchFormNearbyAirportsPresentation>;
  onTypeAnalytics: FlightsAnalytics;
  placeholder: Scalars["String"];
  possibleErrors: Array<FlightsSearchFormError>;
  value?: Maybe<Scalars["String"]>;
};

export type FlightsSearchFormMoreFiltersOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormMoreFiltersOption";
  doneAction: Scalars["String"];
  icon: Icon;
  items: Array<FlightsSearchFormMoreFiltersOptionItem>;
  label: Scalars["String"];
  labelAccessibility?: Maybe<Scalars["String"]>;
  openAnalytics: FlightsAnalytics;
};

export type FlightsSearchFormMoreFiltersOptionItem = {
  __typename?: "FlightsSearchFormMoreFiltersOptionItem";
  advancedFilterRemoveAnalytics: FlightsAnalytics;
  advancedFilterSelectedAnalytics: FlightsAnalytics;
  advancedFilterType: FlightsAdvancedFilterType;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
};

export type FlightsSearchFormNearbyAirportItem = {
  __typename?: "FlightsSearchFormNearbyAirportItem";
  airportName: Scalars["String"];
  analytics: FlightsAnalytics;
  label: Scalars["String"];
};

export type FlightsSearchFormNearbyAirportsPresentation = {
  __typename?: "FlightsSearchFormNearbyAirportsPresentation";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  analytics: FlightsAnalytics;
  displayAction: Scalars["String"];
  header?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<FlightsSearchFormNearbyAirportItem>>;
};

export interface FlightsSearchFormOption {
  icon: Icon;
  label: Scalars["String"];
  labelAccessibility?: Maybe<Scalars["String"]>;
  openAnalytics: FlightsAnalytics;
}

export type FlightsSearchFormTravelerAgeOptionItem = {
  __typename?: "FlightsSearchFormTravelerAgeOptionItem";
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["Int"];
};

export type FlightsSearchFormTravelerInfantOptionItem = FlightsSearchFormTravelerItem & {
  __typename?: "FlightsSearchFormTravelerInfantOptionItem";
  caption?: Maybe<Scalars["String"]>;
  currentError?: Maybe<Scalars["String"]>;
  decreaseCountAccessibility?: Maybe<Scalars["String"]>;
  decreaseCountAnalytics: FlightsAnalytics;
  increaseCountAccessibility?: Maybe<Scalars["String"]>;
  increaseCountAnalytics: FlightsAnalytics;
  infantsOption: FlightsSearchFormTravelerInfantsOption;
  label: Scalars["String"];
  max: Scalars["Int"];
  minCount: Scalars["Int"];
  travelerAgeSelectorTemplate?: Maybe<FlightsTravelerAgeSelector>;
  travelerAges?: Maybe<Array<FlightsTravelerAgeSelector>>;
  travelerCount: Scalars["Int"];
  type: FlightsTravelerType;
};

export type FlightsSearchFormTravelerInfantsOption = {
  __typename?: "FlightsSearchFormTravelerInfantsOption";
  items: Array<FlightsSearchFormTravelerInfantsSelection>;
  label: Scalars["String"];
};

export type FlightsSearchFormTravelerInfantsSelection = {
  __typename?: "FlightsSearchFormTravelerInfantsSelection";
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  type: FlightsTravelerType;
};

export interface FlightsSearchFormTravelerItem {
  caption?: Maybe<Scalars["String"]>;
  currentError?: Maybe<Scalars["String"]>;
  decreaseCountAccessibility?: Maybe<Scalars["String"]>;
  decreaseCountAnalytics: FlightsAnalytics;
  increaseCountAccessibility?: Maybe<Scalars["String"]>;
  increaseCountAnalytics: FlightsAnalytics;
  label: Scalars["String"];
  max: Scalars["Int"];
  minCount: Scalars["Int"];
  travelerAgeSelectorTemplate?: Maybe<FlightsTravelerAgeSelector>;
  travelerAges?: Maybe<Array<FlightsTravelerAgeSelector>>;
  travelerCount: Scalars["Int"];
  type: FlightsTravelerType;
}

export type FlightsSearchFormTravelersOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormTravelersOption";
  adultSelectedAnalytics: FlightsAnalytics;
  ageRulesLink: FlightsLinkAction;
  applyAnalytics: FlightsAnalytics;
  cancelAction: Scalars["String"];
  cancelActionAccessibility?: Maybe<Scalars["String"]>;
  cancelAnalytics: FlightsAnalytics;
  changeAnalytics: FlightsAnalytics;
  childrenSelectedAnalytics: FlightsAnalytics;
  closeIcon?: Maybe<FlightsIconAndAccessibility>;
  currentErrors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  doneAction: Scalars["String"];
  doneActionAccessibility?: Maybe<Scalars["String"]>;
  fieldIcon: Icon;
  icon: Icon;
  infantLapAnalytics: FlightsAnalytics;
  infantSeatAnalytics: FlightsAnalytics;
  infantSelectedAnalytics: FlightsAnalytics;
  items: Array<FlightsSearchFormTravelerItem>;
  label: Scalars["String"];
  labelAccessibility?: Maybe<Scalars["String"]>;
  openAnalytics: FlightsAnalytics;
  possibleErrors: Array<FlightsSearchFormError>;
  travelerLimitLabel: Scalars["String"];
  valueTemplate: FlightsMultiPluralLocalizationTemplate;
};

export type FlightsSearchFormTravelersOptionItem = FlightsSearchFormTravelerItem & {
  __typename?: "FlightsSearchFormTravelersOptionItem";
  caption?: Maybe<Scalars["String"]>;
  currentError?: Maybe<Scalars["String"]>;
  decreaseCountAccessibility?: Maybe<Scalars["String"]>;
  decreaseCountAnalytics: FlightsAnalytics;
  increaseCountAccessibility?: Maybe<Scalars["String"]>;
  increaseCountAnalytics: FlightsAnalytics;
  label: Scalars["String"];
  max: Scalars["Int"];
  minCount: Scalars["Int"];
  travelerAgeSelectorTemplate?: Maybe<FlightsTravelerAgeSelector>;
  travelerAges?: Maybe<Array<FlightsTravelerAgeSelector>>;
  travelerCount: Scalars["Int"];
  type: FlightsTravelerType;
};

export type FlightsSearchFormTripTypeOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormTripTypeOption";
  icon: Icon;
  items: Array<FlightsSearchFormTripTypeOptionItem>;
  label: Scalars["String"];
  labelAccessibility?: Maybe<Scalars["String"]>;
  openAnalytics: FlightsAnalytics;
};

export type FlightsSearchFormTripTypeOptionItem = {
  __typename?: "FlightsSearchFormTripTypeOptionItem";
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  tripType: FlightsTripType;
  tripTypeSelectedAnalytics: FlightsAnalytics;
};

export type FlightsSearchPreferences = {
  __typename?: "FlightsSearchPreferences";
  advancedFilters?: Maybe<Array<FlightsAdvancedFilterType>>;
  airline?: Maybe<Scalars["String"]>;
  cabinClass: FlightsCabinClass;
};

export interface FlightsSearchPreferencesInput {
  advancedFilters?: InputMaybe<Array<FlightsAdvancedFilterType>>;
  airline?: InputMaybe<Scalars["String"]>;
  cabinClass: FlightsCabinClass;
}

export type FlightsSearchPreferencesInputType = {
  __typename?: "FlightsSearchPreferencesInputType";
  advancedFilters?: Maybe<Array<FlightsAdvancedFilterType>>;
  airline?: Maybe<Scalars["String"]>;
  cabinClass: FlightsCabinClass;
};

export type FlightsSearchResponse = {
  __typename?: "FlightsSearchResponse";
  allBanners?: Maybe<Array<FlightsPlacard>>;
  attributeButtonToggles?: Maybe<FlightsAttributeTogglesPresentation>;
  /** @deprecated This will be replaced by new node - detailsAndFaresInformation */
  availableFares?: Maybe<Array<FlightsJourneyAvailableFaresInformationElements>>;
  /** @deprecated This will be replaced by new node - allBanners */
  banners?: Maybe<Array<FlightsPlacard>>;
  cheapestListingInfo?: Maybe<FlightsCheapestListingInfo>;
  clientMetadata?: Maybe<FlightsClientMetadata>;
  crossSort?: Maybe<FlightsCrossSortPresentationResult>;
  detailsAndFaresInformation?: Maybe<Array<FlightsDetailsAndFaresPresentationElements>>;
  dialogs?: Maybe<Array<FlightsDialogPresentation>>;
  failedRequestMessaging?: Maybe<FlightsActionableErrorMessaging>;
  flexibleSearchResponse?: Maybe<FlightsFlexibleSearchResponse>;
  flightsOpinionLab?: Maybe<FlightsActionAndLabel>;
  flightsReviewLoadingElements?: Maybe<FlightsReviewLoadingElements>;
  flightsSearchAnalytics?: Maybe<FlightsSearchAnalytics>;
  flightsSheet?: Maybe<FlightsSheetType>;
  freePriceMatchPromise?: Maybe<FreePriceMatchPromise>;
  gaiaId?: Maybe<Scalars["String"]>;
  journeysContinuationId?: Maybe<Scalars["String"]>;
  listingResult?: Maybe<FlightsListingResult>;
  listingsHeaderPlacards?: Maybe<Array<FlightsMessagingCardPlacard>>;
  /** @deprecated Use attributeButtonToggles instead */
  newAddOnFeatureBadgeMessage?: Maybe<FlightsBadgeMessage>;
  noListingMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  /** @deprecated This will be replaced by new Opinion Lab button at the bottom of the listings */
  opinionLab?: Maybe<FlightsOpinionLab>;
  pageTitle?: Maybe<FlightsPageTitle>;
  rewardsPricingInfo?: Maybe<FlightsRewardsPricingInfo>;
  searchForm?: Maybe<FlightsSearchForm>;
  selectedJourneyPresentations?: Maybe<Array<FlightsSelectedJourneyPresentation>>;
  shoppingContext?: Maybe<ShoppingContext>;
  smartShoppingExperienceEligibility?: Maybe<Scalars["Boolean"]>;
  sortAndFilterResult?: Maybe<FlightsSortAndFilterResult>;
  stepIndicator?: Maybe<FlightsStepIndicator>;
  universalSortAndFilterResult?: Maybe<ShoppingSortAndFilters>;
};

export type FlightsSearchResponseCheapestListingInfoArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseClientMetadataArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseFlightsOpinionLabArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseFlightsReviewLoadingElementsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseGaiaIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseJourneysContinuationIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseListingResultArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseNewAddOnFeatureBadgeMessageArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseOpinionLabArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponsePageTitleArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseRewardsPricingInfoArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseSearchFormArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseSortAndFilterResultArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseUniversalSortAndFilterResultArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResultsDynamicElements = FlightsDynamicElements & {
  __typename?: "FlightsSearchResultsDynamicElements";
  dynamicElementsGroupTrackEventAnalytics?: Maybe<FlightsDynamicElementsTrackEventAnalytics>;
  flightsJourneyDetailsDynamicElements?: Maybe<Array<FlightsJourneyDetailsDynamicElements>>;
};

export type FlightsSearchSortAndFilterCriteria = {
  __typename?: "FlightsSearchSortAndFilterCriteria";
  filterCriteria: Array<FlightsSearchFilterValues>;
};

export type FlightsSeatAncillaryDetailsContent = {
  __typename?: "FlightsSeatAncillaryDetailsContent";
  journeySeatMapCabinSections: Array<FlightsJourneySeatMapCabinContent>;
  journeySelectionDropDown: FlightsAncillarySelect;
  legendButton: UiFloatingActionButton;
  onLoadAccessibilityMessage: Scalars["String"];
  travelerInfo?: Maybe<EgdsBasicTabs>;
  /** @deprecated This field may get replaced by travelerInfo */
  travelerTabs: EgdsBasicTabs;
};

export type FlightsSeatAncillaryDetailsLoaded = {
  __typename?: "FlightsSeatAncillaryDetailsLoaded";
  content: FlightsSeatAncillaryDetailsContent;
  flightsAncillaryDialog: FlightsAncillaryDetailsDialog;
  flightsExperience: FlightsSeatsExperienceType;
  footer: FlightsSeatAncillaryFooter;
  offerIdentifier?: Maybe<Scalars["String"]>;
  seatDetailsWrapper: FlightsDialogSheet;
  seatSelectionToastMessages?: Maybe<FlightsSeatSelectionToastMessages>;
};

export type FlightsSeatAncillaryDetailsLoading = {
  __typename?: "FlightsSeatAncillaryDetailsLoading";
  errorMessage: FlightsMessagingCardPlacard;
  /** @deprecated Will be used in Ancillary summary card query */
  onDisplayAction: FlightsAncillaryDisplayAction;
  seatDetailsWrapper: FlightsDialogSheet;
};

export type FlightsSeatAncillaryDetailsOnSelection = {
  __typename?: "FlightsSeatAncillaryDetailsOnSelection";
  footer: FlightsSeatAncillaryFooter;
};

export type FlightsSeatAncillaryDetailsPresentation =
  | FlightsSeatAncillaryDetailsLoaded
  | FlightsSeatAncillaryDetailsLoading
  | FlightsSeatAncillaryDetailsOnSelection;

export type FlightsSeatAncillaryFooter = {
  __typename?: "FlightsSeatAncillaryFooter";
  journeyFooters: Array<FlightsSeatAncillaryJourneyFooter>;
  legendFooters: Array<FlightsSeatAncillaryLegendDetailsFooter>;
};

export type FlightsSeatAncillaryJourneyFooter = {
  __typename?: "FlightsSeatAncillaryJourneyFooter";
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  buttonAction: FlightsActionButton;
  selectedSeatsSummary: FlightsSelectedSeatsSummary;
};

export type FlightsSeatAncillaryLegendDetailsFooter = {
  __typename?: "FlightsSeatAncillaryLegendDetailsFooter";
  close: FlightsActionButton;
  legendVariants: Array<FlightsSeatLegendVariant>;
  title: Scalars["String"];
};

export type FlightsSeatAncillaryMediaContent =
  | FlightsSeatAncillaryMediaContentError
  | FlightsSeatAncillaryMediaContentSuccess;

export type FlightsSeatAncillaryMediaContentError = {
  __typename?: "FlightsSeatAncillaryMediaContentError";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  icon: Icon;
  reloadButton: FlightsExperienceActionButton;
  subText: Scalars["String"];
  text: Scalars["String"];
};

export type FlightsSeatAncillaryMediaContentSuccess = {
  __typename?: "FlightsSeatAncillaryMediaContentSuccess";
  airplaneAmenities?: Maybe<FlightAmenities>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  mediaContentNotAvailable?: Maybe<FlightsInfoMessagingPresentation>;
  seatFeatures?: Maybe<FlightSeatFeatures>;
  seatImages?: Maybe<FlightSeatImages>;
};

export type FlightsSeatCardStyles = "BORDER" | "WITHOUT_BORDER";

export type FlightsSeatCell = {
  __typename?: "FlightsSeatCell";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  action?: Maybe<FlightsAction>;
  clickAnalytics?: Maybe<Array<FlightsAnalytics>>;
  icon?: Maybe<Icon>;
  info?: Maybe<FlightsSeatInfo>;
  isEmpty: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  span?: Maybe<Scalars["Int"]>;
  variant: SeatVariant;
};

export type FlightsSeatCellDetailsLoaded = {
  __typename?: "FlightsSeatCellDetailsLoaded";
  content: FlightsSeatAncillaryMediaContent;
  seatDetailsWrapper: FlightsDialogSheet;
};

export type FlightsSeatCellDetailsLoading = {
  __typename?: "FlightsSeatCellDetailsLoading";
  error?: Maybe<FlightsSeatAncillaryMediaContentError>;
  seatDetailsWrapper: FlightsDialogSheet;
};

export type FlightsSeatCellDetailsPresentation = FlightsSeatCellDetailsLoaded | FlightsSeatCellDetailsLoading;

export type FlightsSeatChoiceBasedFilterValue = {
  __typename?: "FlightsSeatChoiceBasedFilterValue";
  displayToast?: Maybe<Scalars["Boolean"]>;
  seatChoice: SeatChoiceType;
};

export interface FlightsSeatChoiceBasedFilterValueInput {
  displayToast?: InputMaybe<Scalars["Boolean"]>;
  seatChoice: SeatChoiceType;
}

export type FlightsSeatInfo = {
  __typename?: "FlightsSeatInfo";
  amenities?: Maybe<EgdsUnorderedList>;
  ancillaryToken?: Maybe<Scalars["String"]>;
  closeSeat: FlightsActionButton;
  priceInfoSummary?: Maybe<FlightsPriceInfoSummary>;
  /** @deprecated Use FlightsSeatPriceSummary instead */
  priceLockup: EgdsPriceLockup;
  selectSeat: FlightsActionButton;
  unselectSeat: FlightsActionButton;
};

export type FlightsSeatLegendCellCard = {
  __typename?: "FlightsSeatLegendCellCard";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  hasBorder: FlightsSeatCardStyles;
  icon?: Maybe<Icon>;
  text?: Maybe<EgdsStylizedText>;
};

export type FlightsSeatLegendVariant = {
  __typename?: "FlightsSeatLegendVariant";
  legendCard: FlightsSeatLegendCellCard;
  title: Scalars["String"];
};

export type FlightsSeatMapCellCard = {
  __typename?: "FlightsSeatMapCellCard";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
};

export type FlightsSeatMapGridDetails = {
  __typename?: "FlightsSeatMapGridDetails";
  cabinDimensionDetails: SeatMapCabinDimensionDetails;
  seatMapRows: Array<FlightsSeatMapRow>;
};

export type FlightsSeatMapLegSummary = {
  __typename?: "FlightsSeatMapLegSummary";
  buttonAction: FlightsAction;
  buttonActionTypes: Array<FlightsSeatMapLegSummaryActionType>;
  seatTotalDetails: FlightsSeatMapTotalDetails;
  travelerSummary: FlightsSeatMapTravelersSummary;
};

export type FlightsSeatMapLegSummaryActionType = {
  __typename?: "FlightsSeatMapLegSummaryActionType";
  analyticsList: Array<FlightsAnalytics>;
  id: FlightsActionType;
};

export type FlightsSeatMapLegendVariant = {
  __typename?: "FlightsSeatMapLegendVariant";
  legendCard: FlightsSeatMapCellCard;
  title: Scalars["String"];
};

export type FlightsSeatMapLegends = {
  __typename?: "FlightsSeatMapLegends";
  close: FlightsActionButton;
  legendVariants: Array<FlightsSeatMapLegendVariant>;
  title: Scalars["String"];
};

export type FlightsSeatMapRow = {
  __typename?: "FlightsSeatMapRow";
  seatMapRowSeats?: Maybe<Array<FlightsSeatCell>>;
};

export type FlightsSeatMapSeatDetails = {
  __typename?: "FlightsSeatMapSeatDetails";
  assignedSeat?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  price: FlightsSeatMapSeatPrice;
};

export type FlightsSeatMapSeatPrice = {
  __typename?: "FlightsSeatMapSeatPrice";
  amount?: Maybe<Scalars["Float"]>;
  default: Scalars["String"];
  selected?: Maybe<FlightsSimpleLocalizationTemplate>;
};

export type FlightsSeatMapTotalDetails = {
  __typename?: "FlightsSeatMapTotalDetails";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  label: FlightsResponsiveMessage;
  noSeatSelected: Scalars["String"];
  seatDelimiter: Scalars["String"];
  seatsSelectedMessage: FlightsSimpleLocalizationTemplate;
  selectedSeats: Array<FlightsSeatMapSeatDetails>;
  totalPrice: FlightsSimpleLocalizationTemplate;
};

export type FlightsSeatMapTravelerSummaryItem = {
  __typename?: "FlightsSeatMapTravelerSummaryItem";
  action: FlightsActionAndLabel;
  id: Scalars["Int"];
  seatDetails: FlightsSeatMapSeatDetails;
};

export type FlightsSeatMapTravelersSummary = {
  __typename?: "FlightsSeatMapTravelersSummary";
  title: FlightsResponsiveMessage;
  travelers: Array<FlightsSeatMapTravelerSummaryItem>;
};

export type FlightsSeatSelectionCabinToastMessages = {
  __typename?: "FlightsSeatSelectionCabinToastMessages";
  continueSeatSelectionMessage: EgdsBasicLocalizedText;
  selectSeatForNextFlightMessage: EgdsBasicLocalizedText;
};

export type FlightsSeatSelectionErrorMessage = {
  __typename?: "FlightsSeatSelectionErrorMessage";
  analytics?: Maybe<Array<FlightsAnalytics>>;
  id: FlightsSeatSelectionErrorType;
  message: Scalars["String"];
  theme: TextTheme;
};

export type FlightsSeatSelectionErrorType = "SELECTION_FAILED" | "SELECTION_UNAVAILABLE";

export type FlightsSeatSelectionNotificationMessages = {
  __typename?: "FlightsSeatSelectionNotificationMessages";
  seatSelected: FlightsSimpleLocalizationTemplate;
  seatUnselected?: Maybe<FlightsSimpleLocalizationTemplate>;
  seatsSelectedForFlight: FlightsSimpleLocalizationTemplate;
  seatsSelectedForTrip: FlightsSimpleLocalizationTemplate;
};

export type FlightsSeatSelectionToastMessages = {
  __typename?: "FlightsSeatSelectionToastMessages";
  seatSelectionErrorMessage: EgdsBasicLocalizedText;
  seatSelectionForTripMessage: EgdsBasicLocalizedText;
  seatUnselectionErrorMessage: EgdsBasicLocalizedText;
  /** @deprecated Use seatUnselectionSuccessMessage instead */
  seatUnselectionMessage: EgdsBasicLocalizedText;
  seatUnselectionSuccessMessage?: Maybe<EgdsBasicLocalizedText>;
  selectSeatForNextTravelerMessage: EgdsBasicLocalizedText;
};

export type FlightsSeatsConfirmedDetails = {
  __typename?: "FlightsSeatsConfirmedDetails";
  ancillaryToken: Scalars["String"];
  name: Scalars["String"];
  travelerIndex: Scalars["Int"];
};

export type FlightsSeatsExperienceType =
  | "CURRENT_SEATS_EXPERIENCE"
  | "NEW_SEATS_EXPERIENCE"
  | "SEATS_RICH_CONTENT_EXPERIENCE";

export type FlightsSelectedFareSection = {
  __typename?: "FlightsSelectedFareSection";
  amenities?: Maybe<FlightsCategorizedList>;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  title?: Maybe<FareSectionTitle>;
};

export type FlightsSelectedJourneyAvailableFares = FlightsAvailableFares & {
  __typename?: "FlightsSelectedJourneyAvailableFares";
  applySelection?: Maybe<FlightsAction>;
  dismissDetailedJourneyInformation: FlightsActionableIcon;
  fareChoiceInformation: FlightsFareChoiceInformation;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  openAccessibility: Scalars["String"];
  signInMessagingCard?: Maybe<EgdsStandardMessagingCard>;
};

export interface FlightsSelectedJourneyDetails {
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
}

export type FlightsSelectedJourneyDetailsInformation = {
  __typename?: "FlightsSelectedJourneyDetailsInformation";
  flightsSelectedJourneyDetails?: Maybe<Array<FlightsSelectedJourneyDetails>>;
};

export type FlightsSelectedJourneyPresentation = {
  __typename?: "FlightsSelectedJourneyPresentation";
  airlines: Array<FlightsImageAndLabel>;
  airportStops: Scalars["String"];
  changeFlightLabelPresentation: ChangeFlightLabelPresentation;
  departureAndArrivalTime: FlightsPhrase;
  durationAndStops: Scalars["String"];
  fareRulesPresentation?: Maybe<FlightsOfferFareRulesPresentation>;
  flightOperatedBy: Scalars["String"];
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  pricingInformation: FlightsPricingInformation;
  title: FlightsPhrase;
};

export interface FlightsSelectedJourneyReview {
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  collapseAnalytics?: Maybe<FlightsAnalytics>;
  expandAnalytics?: Maybe<FlightsAnalytics>;
  fareDetails: FareDetails;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  /** @deprecated This field is now deprecated., replace with Use the sibling of FlightsSelectedJourneyReview */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyHighlights?: Maybe<Array<FlightsJourneyHighlights>>;
  journeyInformation?: Maybe<JourneyInformation>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
}

export type FlightsSelectedSeatDetail = {
  __typename?: "FlightsSelectedSeatDetail";
  flightInformation?: Maybe<Scalars["String"]>;
  travelerSeatsInformation?: Maybe<Array<Scalars["String"]>>;
};

export type FlightsSelectedSeatsSummary = {
  __typename?: "FlightsSelectedSeatsSummary";
  accessibilityMessage: Scalars["String"];
  label: EgdsSpannableText;
  message: EgdsStylizedText;
  totalPrice: EgdsPriceLockup;
};

export type FlightsSelectionAction = FlightsClientAction & {
  __typename?: "FlightsSelectionAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  value: Scalars["String"];
};

export type FlightsServerError = FlightsOnLoadError & {
  __typename?: "FlightsServerError";
  accessibility: Scalars["String"];
  action: FlightsActionButton;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type FlightsServicingBookingResponse = {
  __typename?: "FlightsServicingBookingResponse";
  failure?: Maybe<FlightServicingBookingFailure>;
  success?: Maybe<FlightServicingBookingSuccess>;
};

export interface FlightsServicingConfirmationCriteriaInput {
  journeyContinuationId?: InputMaybe<Scalars["String"]>;
  originalBookingId: Scalars["String"];
  tripId?: InputMaybe<Scalars["String"]>;
}

export type FlightsServicingConfirmationResponse = {
  __typename?: "FlightsServicingConfirmationResponse";
  clientMetadata: FlightsPwaMetadata;
  confirmationHeading: Scalars["String"];
  errorMessaging?: Maybe<FlightServicingBookingFailure>;
  hotelAttach?: Maybe<FlightsMessagingCardPlacard>;
  mainContent: FlightsConfirmation;
};

export interface FlightsServicingCriteriaInput {
  ancillaryId?: InputMaybe<Scalars["String"]>;
  isResumeChange?: InputMaybe<Scalars["Boolean"]>;
  journeyContinuationId: Scalars["String"];
  originalBookingId?: InputMaybe<Scalars["String"]>;
  tripId?: InputMaybe<Scalars["String"]>;
  upsellOfferToken?: InputMaybe<Scalars["String"]>;
}

export type FlightsServicingResponse = {
  __typename?: "FlightsServicingResponse";
  addCollectRequired?: Maybe<Scalars["Boolean"]>;
  authenticatedUser: FlightsAuthenticatedUser;
  booking: FlightsBooking;
  clientMetadata: FlightsPwaMetadata;
  creditResidualMessaging?: Maybe<FlightsCreditResidualMessaging>;
  importantTripInfo: FlightsImportantTripInfo;
  payment: FlightsPayment;
  placards?: Maybe<Array<FlightsUiPlacard>>;
  priceSummary?: Maybe<FlightsPriceSummary>;
  regulatoryStatements: FlightsRegulatoryStatements;
  reviewAndBook: FlightsReviewAndBook;
  serverErrorMessaging?: Maybe<FlightServicingBookingFailure>;
  tripSummary?: Maybe<FlightsTripSummary>;
};

export type FlightsServicingResponsePriceSummaryArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsServicingResponseTripSummaryArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export interface FlightsSheet {
  content?: Maybe<Array<EgdsElement>>;
}

export type FlightsSheetActionContent = {
  __typename?: "FlightsSheetActionContent";
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<EgdsHeading>;
  message: Array<EgdsParagraph>;
  primaryButton?: Maybe<FlightsExperienceActionButton>;
};

export type FlightsSheetContent = FlightsSheetActionContent | FlightsSheetInfoContent;

export type FlightsSheetInfoContent = {
  __typename?: "FlightsSheetInfoContent";
  heading?: Maybe<EgdsHeading>;
  message: Array<EgdsParagraph>;
};

export type FlightsSheetType = {
  __typename?: "FlightsSheetType";
  content: FlightsSheetContent;
  id: FlightsDialogId;
  sheet: EgdsSheet;
  title?: Maybe<EgdsHeading>;
};

export type FlightsShoppingOfferContent = {
  __typename?: "FlightsShoppingOfferContent";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badges?: Maybe<Array<EgdsBadge>>;
  priceSection?: Maybe<FlightsShoppingPriceSummary>;
  primarySection: Array<FlightsShoppingPrimarySection>;
  secondaryBadges?: Maybe<Array<EgdsBadge>>;
  secondarySection: Array<FlightsShoppingSecondarySection>;
  tertiarySection: Array<FlightsShoppingTertiarySection>;
};

export type FlightsShoppingOfferFeaturedHeader = {
  __typename?: "FlightsShoppingOfferFeaturedHeader";
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
};

export type FlightsShoppingPriceSummary = {
  __typename?: "FlightsShoppingPriceSummary";
  leadingPrice?: Maybe<EgdsSpannableText>;
  price: EgdsSpannableText;
  primaryText?: Maybe<Array<EgdsSpannableText>>;
  secondaryText?: Maybe<Array<EgdsSpannableText>>;
  trailingPrice?: Maybe<EgdsSpannableText>;
};

export type FlightsShoppingPrimarySection = EgdsTextSection;

export type FlightsShoppingSecondarySection = EgdsTextSection;

export type FlightsShoppingStandardOfferCard = {
  __typename?: "FlightsShoppingStandardOfferCard";
  additionalContentSections: Array<FlightsSmartShoppingAdditionalContent>;
  content: FlightsShoppingOfferContent;
  detailsAndFares: FlightsDetailsAndFaresPresentation;
  featuredHeader?: Maybe<FlightsShoppingOfferFeaturedHeader>;
  journeyContinuationId: Scalars["String"];
  onDisplayAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  sponsoredListingBeacons?: Maybe<SponsoredTrackingBeacons>;
};

export type FlightsShoppingTertiarySection = EgdsGraphicText | EgdsTextSection | FlightShoppingCardDurationTimeline;

export type FlightsSideSheet = FlightsSheet & {
  __typename?: "FlightsSideSheet";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  closeText?: Maybe<Scalars["String"]>;
  content?: Maybe<Array<EgdsElement>>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsSimpleLocalizationTemplate = FlightsLocalizationTemplate & {
  __typename?: "FlightsSimpleLocalizationTemplate";
  initialValue?: Maybe<Scalars["String"]>;
  template: Scalars["String"];
  tokens: Array<Scalars["String"]>;
};

export type FlightsSliderDisplayFilterOption = FlightsFilterOption & {
  __typename?: "FlightsSliderDisplayFilterOption";
  filterAppliedAnalytics?: Maybe<FlightsAnalytics>;
  items: Array<FlightsSliderFilterItem>;
  label: Scalars["String"];
  selectedIndex: Scalars["Int"];
  supportsMultipleSelection: Scalars["Boolean"];
  type: FlightsFilterType;
};

export type FlightsSliderFilterItem = FlightsFilterItem & {
  __typename?: "FlightsSliderFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  defaultSelected: Scalars["Boolean"];
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"];
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  value: FlightsFilterValue;
};

export type FlightsSliderFilterValue = {
  __typename?: "FlightsSliderFilterValue";
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export interface FlightsSliderFilterValueInput {
  max: Scalars["Int"];
  min: Scalars["Int"];
}

export type FlightsSmartNoFlightsFoundMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsSmartNoFlightsFoundMessaging";
  alternateAirportOptions?: Maybe<AlternateAirportOptions>;
  alternateDateOptions?: Maybe<AlternateDateOptions>;
  editSearchButton?: Maybe<FlightsAction>;
  flightsDisplayComponentCountingValue?: Maybe<FlightsDisplayComponentCountingValue>;
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export type FlightsSmartShoppingActionButton = EgdsButton & {
  __typename?: "FlightsSmartShoppingActionButton";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  buttonClientAction: FlightsClientAction;
  buttonIconPosition?: Maybe<FlightsIconPosition>;
  buttonWidth?: Maybe<FlightsButtonWidth>;
  /** @deprecated deprecated, replace with buttonClientAction */
  clientAction: FlightsSmartShoppingClientAction;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  /** @deprecated deprecated, replace with buttonIconPosition */
  iconPosition?: Maybe<FlightsSmartShoppingIconPosition>;
  primary?: Maybe<Scalars["String"]>;
  style: EgdsButtonStyle;
  /** @deprecated deprecated, replace with buttonWidth */
  width?: Maybe<FlightsSmartShoppingButtonWidth>;
};

export type FlightsSmartShoppingActionLink = {
  __typename?: "FlightsSmartShoppingActionLink";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated deprecated, replace with linkClientAction */
  clientAction: FlightsSmartShoppingClientAction;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  /** @deprecated deprecated, replace with linkIconPosition */
  iconPosition?: Maybe<FlightsSmartShoppingIconPosition>;
  linkClientAction: FlightsClientAction;
  linkIconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]>;
};

export type FlightsSmartShoppingAdditionalContent =
  | EgdsPlainText
  | FlightsExperienceActionLink
  | FlightsSmartShoppingActionLink;

export type FlightsSmartShoppingBannerError = FlightsOnLoadError & {
  __typename?: "FlightsSmartShoppingBannerError";
  accessibility: Scalars["String"];
  /** @deprecated deprecated, replace with errorAction */
  action: FlightsSmartShoppingActionLink;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  errorAction: FlightsExperienceActionLink;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type FlightsSmartShoppingButtonWidth = "DEFAULT" | "FULL_WIDTH";

export type FlightsSmartShoppingClickAction = FlightsSmartShoppingClientAction & {
  __typename?: "FlightsSmartShoppingClickAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export interface FlightsSmartShoppingClientAction {
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
}

export type FlightsSmartShoppingDialog = {
  __typename?: "FlightsSmartShoppingDialog";
  dialog: EgdsDialog;
  dialogId: FlightsSmartShoppingDialogId;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  experienceDialogId: FlightsDialogId;
  footer?: Maybe<Array<EgdsElement>>;
};

export type FlightsSmartShoppingDialogAction = "CLOSE" | "OPEN" | "TOGGLE";

export type FlightsSmartShoppingDialogId =
  | "AIRLINE_CREDITS"
  | "BAGGAGE_CLOSE_COMPLETE_DIALOG"
  | "BAGGAGE_CLOSE_INCOMPLETE_DIALOG"
  | "BAGGAGE_CONTINUE_BOOKING"
  | "BAGGAGE_DETAILS_DIALOG"
  | "BAGS_POPUP_ON_CHECKOUT"
  | "CHECK_CREDITS_DIALOG"
  | "FLIGHTS_DETAILS_AND_FARES"
  | "FORCED_CHOICE_MODAL"
  | "HOW_CREDITS_WORK"
  | "LEAVE_TO_COUPONS"
  | "PAID_BAGS_DIALOG"
  | "PMP_INFO_DIALOG"
  | "PMP_REMOVE_DIALOG"
  | "SEATS_POPUP_ON_CHECKOUT"
  | "SEAT_CLOSE_COMPLETE_DIALOG"
  | "SEAT_CONTINUE_BOOKING"
  | "SEAT_SELECTION_DIALOG"
  | "TRAVELER_MISMATCH_DIALOG";

export type FlightsSmartShoppingDialogSheet = {
  __typename?: "FlightsSmartShoppingDialogSheet";
  /** @deprecated deprecated, replace with experienceDialog */
  dialog: FlightsSmartShoppingDialog;
  /** @deprecated deprecated, replace with flightsDialogId */
  dialogId: FlightsSmartShoppingDialogId;
  experienceDialog: FlightsDialog;
  experienceSideSheet: FlightsSideSheet;
  /** @deprecated deprecated, replace with experienceDialog */
  flightsDialog: FlightsExperienceDialog;
  flightsDialogId: FlightsDialogId;
  /** @deprecated deprecated, replace with experienceSideSheet */
  flightsSideSheet: FlightsExperienceSideSheet;
  /** @deprecated deprecated, replace with experienceSideSheet */
  sheet: FlightsSmartShoppingSideSheet;
};

export type FlightsSmartShoppingDialogTrigger = {
  __typename?: "FlightsSmartShoppingDialogTrigger";
  /** @deprecated deprecated, replace with flightsDialogAction */
  dialogAction: FlightsSmartShoppingDialogAction;
  /** @deprecated deprecated, replace with flightsDialogId */
  dialogId: FlightsSmartShoppingDialogId;
  dialogTrigger: FlightsDialogTriggerType;
  dynamicDialogId?: Maybe<Scalars["String"]>;
  flightsDialogAction: FlightsDialogAction;
  flightsDialogId: FlightsDialogId;
  /** @deprecated deprecated, replace with dialogTrigger */
  trigger: FlightsSmartShoppingDialogTriggerType;
};

export type FlightsSmartShoppingDialogTriggerType = FlightsSmartShoppingActionButton | FlightsSmartShoppingActionLink;

export type FlightsSmartShoppingIconPosition = "LEADING" | "TRAILING";

export type FlightsSmartShoppingLinkTarget = "EXTERNAL" | "INTERNAL";

export type FlightsSmartShoppingListing =
  | EgdsStylizedText
  | FlightsExperienceBargainFareOffer
  | FlightsSearchCrossSellOffer
  | FlightsSmartShoppingOfferCard
  | SponsoredContentPlacement;

export type FlightsSmartShoppingListingsError = {
  __typename?: "FlightsSmartShoppingListingsError";
  errorResponse: FlightsOnLoadError;
};

export type FlightsSmartShoppingListingsResults =
  | FlightsSmartShoppingListingsError
  | FlightsSmartShoppingLoadedListings
  | FlightsSmartShoppingLoadingListings;

export type FlightsSmartShoppingLoadedListings = {
  __typename?: "FlightsSmartShoppingLoadedListings";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  /** @deprecated An error response will be provided in FlightsSmartShoppingListingsError */
  errorResponse?: Maybe<FlightsOnLoadError>;
  /** @deprecated deprecated, replace with flightsSearchDetailsAndFaresWrapper */
  flightsDetailsAndFares?: Maybe<FlightsSmartShoppingDialogSheet>;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  /** @deprecated deprecated, replace with flightsSearchDetailsAndFaresWrapper */
  flightsDetailsAndFaresWrapper: FlightsExperienceDialogSheet;
  flightsListingsAnalytics: Array<ClientSideAnalytics>;
  flightsSearchDetailsAndFaresWrapper: FlightsDialogSheet;
  listings: Array<FlightsSmartShoppingListing>;
  pinnedListingUnavailableMessage?: Maybe<Scalars["String"]>;
  responseMetrics?: Maybe<Array<FlightsResponseTimeMetrics>>;
  responseTags?: Maybe<Array<ResponseTagType>>;
  toast?: Maybe<EgdsToast>;
};

export type FlightsSmartShoppingLoadingListings = {
  __typename?: "FlightsSmartShoppingLoadingListings";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated An error response will be provided in FlightsSmartShoppingListingsError */
  errorResponse: FlightsOnLoadError;
  image: Image;
  listingsSkeletonCount: Scalars["Int"];
  message: Scalars["String"];
  originDestination: Scalars["String"];
};

export type FlightsSmartShoppingOfferCard = {
  __typename?: "FlightsSmartShoppingOfferCard";
  additionalContentSections: Array<FlightsSmartShoppingAdditionalContent>;
  /** @deprecated deprecated, replace with offerClientAction */
  clientAction: FlightsSmartShoppingClientAction;
  content: FlightsSmartShoppingOfferContent;
  /** @deprecated deprecated, replace with offerDialogTrigger */
  dialogTrigger: FlightsSmartShoppingDialogTrigger;
  featuredHeader?: Maybe<FlightsSmartShoppingOfferFeaturedHeader>;
  journeyContinuationId: Scalars["String"];
  offerClientAction: FlightsClientAction;
  offerDialogTrigger: FlightsExperienceDialogTrigger;
  /** @deprecated deprecated, replace with onDisplayAnalyticsList */
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
  onDisplayAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  sanitizedOfferToken?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
  sponsoredListingBeacons?: Maybe<SponsoredTrackingBeacons>;
};

export type FlightsSmartShoppingOfferContent = {
  __typename?: "FlightsSmartShoppingOfferContent";
  badges?: Maybe<Array<EgdsBadge>>;
  priceSection?: Maybe<FlightsSmartShoppingPriceSummary>;
  primarySection: Array<FlightsSmartShoppingPrimarySection>;
  secondaryBadges?: Maybe<Array<EgdsBadge>>;
  secondarySection: Array<FlightsSmartShoppingSecondarySection>;
  tertiarySection: Array<FlightsSmartShoppingTertiarySection>;
};

export type FlightsSmartShoppingOfferFeaturedHeader = {
  __typename?: "FlightsSmartShoppingOfferFeaturedHeader";
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
};

export type FlightsSmartShoppingOverFilteringError = FlightsOnLoadError & {
  __typename?: "FlightsSmartShoppingOverFilteringError";
  accessibility: Scalars["String"];
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated deprecated, replace with errorAction */
  button: FlightsSmartShoppingActionButton;
  errorAction: FlightsExperienceActionButton;
  filterPills: Array<ShoppingAppliedFilter>;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type FlightsSmartShoppingPriceSummary = {
  __typename?: "FlightsSmartShoppingPriceSummary";
  leadingPrice?: Maybe<EgdsSpannableText>;
  price: EgdsSpannableText;
  primaryText?: Maybe<Array<EgdsSpannableText>>;
  secondaryText?: Maybe<Array<EgdsSpannableText>>;
  trailingPrice?: Maybe<EgdsSpannableText>;
};

export type FlightsSmartShoppingPrimarySection = EgdsTextSection;

export type FlightsSmartShoppingResourceLinkAction = FlightsSmartShoppingClientAction & {
  __typename?: "FlightsSmartShoppingResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  linkTarget: FlightsLinkTarget;
  resource: Uri;
  /** @deprecated deprecated, replace with linkTarget */
  target: FlightsSmartShoppingLinkTarget;
};

export type FlightsSmartShoppingSecondarySection = EgdsTextSection;

export type FlightsSmartShoppingSelectionAction = FlightsSmartShoppingClientAction & {
  __typename?: "FlightsSmartShoppingSelectionAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  value: Scalars["String"];
};

export type FlightsSmartShoppingServerError = FlightsOnLoadError & {
  __typename?: "FlightsSmartShoppingServerError";
  accessibility: Scalars["String"];
  /** @deprecated deprecated, replace with errorAction */
  action: FlightsSmartShoppingActionButton;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  errorAction: FlightsExperienceActionButton;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type FlightsSmartShoppingSideSheet = {
  __typename?: "FlightsSmartShoppingSideSheet";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  footer?: Maybe<Array<EgdsElement>>;
  title?: Maybe<Scalars["String"]>;
};

export type FlightsSmartShoppingTertiarySection =
  | EgdsGraphicText
  | EgdsTextSection
  | FlightCardDurationTimeline
  | FlightsDurationTimeline;

export interface FlightsSmartShoppingTravelerDetailsInput {
  ages?: InputMaybe<Array<Scalars["Int"]>>;
  count: Scalars["Int"];
  travelerType: TravelerDetailsType;
}

export type FlightsSocialProofMessage = {
  __typename?: "FlightsSocialProofMessage";
  icon: Icon;
  message: FlightsPhrase;
};

export type FlightsSortAndFilterAction = {
  __typename?: "FlightsSortAndFilterAction";
  accessibility: FlightsAccessibilityMessage;
  analytics: FlightsAnalytics;
  details?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
};

export interface FlightsSortAndFilterResult {
  filterPresentation?: Maybe<FlightsFilterPresentation>;
  revealAction: FlightsSortAndFilterRevealAction;
  sortPresentation: FlightsSortPresentation;
  title: Scalars["String"];
}

export type FlightsSortAndFilterRevealAction = {
  __typename?: "FlightsSortAndFilterRevealAction";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  appliedFilterCount?: Maybe<Scalars["Int"]>;
  enabled: Scalars["Boolean"];
  iconAndLabel: FlightsIconAndLabel;
};

export type FlightsSortLoadedPresentation = FlightsSortPresentation & {
  __typename?: "FlightsSortLoadedPresentation";
  defaultSelectedOption: FlightsSortOptionItem;
  label: Scalars["String"];
  options: Array<FlightsSortOptionItem>;
  selected: Icon;
  selectedOption: FlightsSortOptionItem;
  selectionActive: Scalars["Boolean"];
  unselected: Icon;
};

export type FlightsSortLoadingPresentation = FlightsSortPresentation & {
  __typename?: "FlightsSortLoadingPresentation";
  label: Scalars["String"];
  selectedOption: FlightsSortOptionItem;
  selectionActive: Scalars["Boolean"];
};

export type FlightsSortOptionItem = {
  __typename?: "FlightsSortOptionItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  analytics: FlightsAnalytics;
  label: Scalars["String"];
  sortType: FlightsSortOptionType;
};

export type FlightsSortOptionType = {
  __typename?: "FlightsSortOptionType";
  crossSortOptionList?: Maybe<Array<FlightsCrossSortOption>>;
  /** @deprecated Use crossSortOptionList instead */
  crossSortOptionType?: Maybe<FlightsCrossSortOptionType>;
  sortOrder: FlightsSortOrder;
  sortType: FlightsSortType;
};

export interface FlightsSortOptionTypeInput {
  crossSortOptionList?: InputMaybe<Array<FlightsCrossSortOptionInput>>;
  crossSortOptionType?: InputMaybe<FlightsCrossSortOptionTypeInput>;
  sortOrder: FlightsSortOrder;
  sortType: FlightsSortType;
}

export type FlightsSortOrder = "DECREASING" | "INCREASING";

export interface FlightsSortPresentation {
  label: Scalars["String"];
  selectedOption: FlightsSortOptionItem;
  selectionActive: Scalars["Boolean"];
}

export type FlightsSortType =
  | "ARRIVAL"
  | "BEST_BETS"
  | "BEST_SORT"
  | "CROSS_SORT"
  | "DEPARTURE"
  | "DURATION"
  | "NONSTOP"
  | "PRICE"
  | "RECOMMENDED";

export type FlightsSponsoredContentAdListing = {
  __typename?: "FlightsSponsoredContentAdListing";
  sponsoredContentPlacement: SponsoredContentPlacement;
};

export type FlightsSponsoredFareInformation = {
  __typename?: "FlightsSponsoredFareInformation";
  bulletpoints?: Maybe<EgdsUnorderedList>;
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<EgdsHeading>;
  mediaSection: FareMediaSection;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  /** @deprecated the tab click event will be used, instead of this view event */
  onViewedAnalytics?: Maybe<ClientSideAnalytics>;
  priceLockup?: Maybe<EgdsPriceLockup>;
  showMoreExpando?: Maybe<EgdsExpandoPeek>;
  sponsoredFareAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsStandardFareSelectedJourneyDetails = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsStandardFareSelectedJourneyDetails";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  fareDetails: FareDetails;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  upsellFareUpdatedMessage?: Maybe<TextWrapper>;
};

export type FlightsStandardFareSelectedJourneyDetailsNoFare = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsStandardFareSelectedJourneyDetailsNoFare";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsStandardOffer = FlightsOffer & {
  __typename?: "FlightsStandardOffer";
  accessibilityHeading?: Maybe<Scalars["String"]>;
  accessibilityMessage?: Maybe<Scalars["String"]>;
  additionalMessaging?: Maybe<FlightsPhrase>;
  /** @deprecated Use badgesList as that one uses EGDSBadge */
  badges?: Maybe<Array<FlightsBadge>>;
  badgesList?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation?: Maybe<FlightsOfferBaggageFeesInformation>;
  displayDetailsAction: FlightsToggle;
  flightsOfferAnalytics: FlightsOfferAnalytics;
  flightsOfferFareRulesPresentation?: Maybe<FlightsOfferFareRulesPresentation>;
  flightsShoppingOfferContent?: Maybe<FlightsShoppingOfferContent>;
  freeCancellation: FreeCancellationApplicability;
  journeyContinuationId?: Maybe<Scalars["String"]>;
  journeys: Array<FlightsOfferJourney>;
  offerIdentifier?: Maybe<Scalars["String"]>;
  offerInfoLink?: Maybe<FlightsInfoLink>;
  onClickAnalyticsList: Array<FlightsAnalytics>;
  pinnedOfferMessage?: Maybe<Scalars["String"]>;
  pricingInformation: FlightsPricingInformation;
  restrictiveFareRules?: Maybe<FlightsRestrictiveFareRules>;
  sanitizedOfferToken?: Maybe<Scalars["String"]>;
  selectOfferAction: FlightsAction;
  specialFareType?: Maybe<Scalars["String"]>;
  sponsoredAirline?: Maybe<FlightsOfferSponsoredAirline>;
  sponsoredUpsell?: Maybe<FlightsOfferSponsoredUpsell>;
  /** @deprecated Moved field to be under FlightListingsAnalytics */
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  stepIndicatorJcid?: Maybe<Scalars["String"]>;
  tag?: Maybe<FlightsOfferTag>;
};

export type FlightsStepIndicator = {
  __typename?: "FlightsStepIndicator";
  errorAnalytics: FlightsAnalytics;
  journeyContinuationId: Scalars["String"];
  skeletonCount?: Maybe<Scalars["Int"]>;
};

export type FlightsStopBasedFilterValue = {
  __typename?: "FlightsStopBasedFilterValue";
  stopInfo: FlightsStopInfo;
};

export interface FlightsStopBasedFilterValueInput {
  stopInfo: FlightsStopInfoInput;
}

export type FlightsStopFilterOperation = "EQUAL" | "GREATER_THAN_EQUAL" | "LESS_THAN_EQUAL";

export type FlightsStopInfo = {
  __typename?: "FlightsStopInfo";
  numberOfStops: Scalars["Int"];
  stopFilterOperation: FlightsStopFilterOperation;
};

export interface FlightsStopInfoInput {
  numberOfStops: Scalars["Int"];
  stopFilterOperation: FlightsStopFilterOperation;
}

export type FlightsSuggestedFilterItem = FlightsFilterItem & {
  __typename?: "FlightsSuggestedFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  defaultSelected: Scalars["Boolean"];
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"];
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  shoppingFilterValue: SelectedValue;
  type: FlightsFilterType;
  value: FlightsFilterValue;
};

export type FlightsSuggestedFilterMessageListing = {
  __typename?: "FlightsSuggestedFilterMessageListing";
  accessibilityMessage: Scalars["String"];
  suggestedFilter: FlightsSuggestedFilterItem;
  suggestedFilterDetails: FlightsImagePlacard;
};

export type FlightsSupportedAncillaryInfo = {
  __typename?: "FlightsSupportedAncillaryInfo";
  ancillaryTypes: Array<FlightsAncillaryType>;
  journeyContinuationId: Scalars["String"];
};

export type FlightsSustainabilityCard = {
  __typename?: "FlightsSustainabilityCard";
  cardContent: FlightsIconPhrases;
};

export type FlightsSustainabilityDialogDetails = {
  __typename?: "FlightsSustainabilityDialogDetails";
  closingText: FlightsTextSection;
  dismiss: FlightsAction;
  heading: Scalars["String"];
  iconRow: FlightsIconAndHeading;
  introduction: Array<FlightsPhrase>;
};

export type FlightsSustainabilityMessage = {
  __typename?: "FlightsSustainabilityMessage";
  details: FlightsSustainabilityDialogDetails;
  information: FlightsIconPlacard;
};

export type FlightsSustainabilityMessaging = {
  __typename?: "FlightsSustainabilityMessaging";
  messageContent: EgdsSpannableText;
};

export type FlightsSwapLocationAction = {
  __typename?: "FlightsSwapLocationAction";
  accessibilityMessage: Scalars["String"];
  accessibilityUsageMessage: FlightsSimpleLocalizationTemplate;
  analytics: FlightsAnalytics;
  icon: FlightsResponsiveIcon;
  multiDestAccessibilityMessage: FlightsSimpleLocalizationTemplate;
};

export type FlightsSwitchToggle = UiToggle & {
  __typename?: "FlightsSwitchToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  clientAction: FlightsClientAction;
  enabled: Scalars["Boolean"];
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type FlightsSwitchToggleAction = FlightsClientAction & {
  __typename?: "FlightsSwitchToggleAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  checkedAccessibilityAnnouncement?: Maybe<Scalars["String"]>;
  uncheckedAccessibilityAnnouncement?: Maybe<Scalars["String"]>;
};

export interface FlightsText {
  text: Array<FlightsPhrase>;
}

export type FlightsTextContainerListItem = EgdsTextListItem & {
  __typename?: "FlightsTextContainerListItem";
  content: EgdsText;
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type FlightsTextSection = {
  __typename?: "FlightsTextSection";
  displayAction?: Maybe<FlightsToggle>;
  displayAnalytics?: Maybe<FlightsAnalytics>;
  displayAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
  heading?: Maybe<Scalars["String"]>;
  messages: Array<FlightsPhrase>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
};

export type FlightsTextSectionDecorator = "BOTTOMSEPARATOR" | "TOPSEPARATOR";

export type FlightsTimeBasedFilterValue = {
  __typename?: "FlightsTimeBasedFilterValue";
  timeOfDay: FlightsTimeOfDay;
};

export interface FlightsTimeBasedFilterValueInput {
  timeOfDay: FlightsTimeOfDay;
}

export type FlightsTimeOfDay = "AFTERNOON" | "EARLY_MORNING" | "EVENING" | "MORNING";

export type FlightsTimePeriodOfDay = "AFTERNOON" | "EARLY_MORNING" | "EVENING" | "MORNING";

export interface FlightsToastContextInput {
  type?: InputMaybe<FlightsFilterType>;
}

export type FlightsToggle = {
  __typename?: "FlightsToggle";
  collapseActionable: TogglePhase;
  expandActionable: TogglePhase;
  expanded: Scalars["Boolean"];
};

export type FlightsTravelAndBaggageBasedFilterValue = {
  __typename?: "FlightsTravelAndBaggageBasedFilterValue";
  travelAndBaggageName: FilterTravelAndBaggageType;
};

export interface FlightsTravelAndBaggageBasedFilterValueInput {
  travelAndBaggageName: FilterTravelAndBaggageType;
}

export type FlightsTravelerAgeSelector = {
  __typename?: "FlightsTravelerAgeSelector";
  analytics: FlightsAnalytics;
  icon: Icon;
  items: Array<FlightsSearchFormTravelerAgeOptionItem>;
  label: FlightsSimpleLocalizationTemplate;
  placeholder: Scalars["String"];
};

export type FlightsTravelerDetails = {
  __typename?: "FlightsTravelerDetails";
  ages?: Maybe<Array<Scalars["Int"]>>;
  count: Scalars["Int"];
  travelerType: FlightsTravelerType;
};

export interface FlightsTravelerDetailsInput {
  ages?: InputMaybe<Array<Scalars["Int"]>>;
  count: Scalars["Int"];
  travelerType: FlightsTravelerType;
}

export type FlightsTravelerType = "ADULT" | "CHILD" | "INFANT_IN_LAP" | "INFANT_IN_SEAT";

export type FlightsTriggerDialogOrSheetAction = FlightsClientAction & {
  __typename?: "FlightsTriggerDialogOrSheetAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogAction: FlightsDialogAction;
  dialogId: FlightsDialogId;
};

export type FlightsTripSummary = {
  __typename?: "FlightsTripSummary";
  heading: Scalars["String"];
  journeys: Array<FlightsTripSummaryJourney>;
  subheading: Scalars["String"];
};

export type FlightsTripSummaryJourney = {
  __typename?: "FlightsTripSummaryJourney";
  accessibility?: Maybe<Scalars["String"]>;
  badges?: Maybe<Array<EgdsBadge>>;
  /** @deprecated This will be replaced with items */
  contentItems: Array<FlightsInformationText>;
  header?: Maybe<EgdsHeading>;
  /** @deprecated This will be replaced by header & accessibility fields */
  heading: FlightsMessageAndAccessibility;
  items: Array<FlightsTripSummaryJourneyItem>;
};

export type FlightsTripSummaryJourneyExpandable = {
  __typename?: "FlightsTripSummaryJourneyExpandable";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  expando: EgdsExpandoLink;
  expandoContent: EgdsTextList;
};

export type FlightsTripSummaryJourneyItem = EgdsSpannableText | FlightsTripSummaryJourneyExpandable;

export type FlightsTripType = "MULTI_DESTINATION" | "ONE_WAY" | "ROUND_TRIP";

export interface FlightsUiPlacard {
  displayType: FlightsPlacardDisplayType;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
}

export type FlightsUpgradeFareSection = {
  __typename?: "FlightsUpgradeFareSection";
  amenities?: Maybe<FlightsCategorizedList>;
  cardTheme?: Maybe<FlightsCardBackgroundTheme>;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  title?: Maybe<FareSectionTitle>;
  /** @deprecated Deprecating this FlightActionButton inside FareAction is deprecated */
  upgradeAction?: Maybe<FareAction>;
  upgradeFarePrice?: Maybe<FlightsPriceInfoSummary>;
  upgradedAction?: Maybe<FlightsExperienceActionButton>;
};

export type FloorPlanDialog = LodgingDialog & {
  __typename?: "FloorPlanDialog";
  images: Array<PropertyImage>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type FlowType = "AUTOMATED" | "AUTOMATED_MANUAL" | "MANUAL";

export type Foo = {
  __typename?: "Foo";
  foo: Scalars["String"];
  something: Scalars["String"];
  somethingElse: Scalars["String"];
  somethingMore: Scalars["String"];
};

export type Footer = {
  __typename?: "Footer";
  footerHeadingLabel?: Maybe<Scalars["String"]>;
  minimal?: Maybe<Scalars["Boolean"]>;
  sectionData: Array<Maybe<FooterSectionData>>;
};

export type FooterButton = {
  __typename?: "FooterButton";
  target?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  trackingEvent?: Maybe<Scalars["String"]>;
  type: FooterButtonType;
  url?: Maybe<Scalars["URL"]>;
};

export type FooterButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type FooterCobrandLogo = {
  __typename?: "FooterCobrandLogo";
  mark: Mark;
  text?: Maybe<Scalars["String"]>;
};

export type FooterData =
  | FooterButton
  | FooterCobrandLogo
  | FooterImage
  | FooterLink
  | FooterSeparator
  | FooterText
  | NorthstarFooterImage
  | NorthstarFooterSection
  | NorthstarFooterSectionLink
  | NorthstarFooterText;

export type FooterImage = {
  __typename?: "FooterImage";
  altText?: Maybe<Scalars["String"]>;
  imgSrc?: Maybe<Scalars["URL"]>;
  newWindowText?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type FooterLink = {
  __typename?: "FooterLink";
  target?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  trackingEvent?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type FooterSectionButtons = FooterSectionData & {
  __typename?: "FooterSectionButtons";
  data: Array<Maybe<FooterData>>;
};

export type FooterSectionCobrand = FooterSectionData & {
  __typename?: "FooterSectionCobrand";
  data: Array<Maybe<FooterCobrandLogo>>;
};

export interface FooterSectionData {
  data: Array<Maybe<FooterData>>;
}

export type FooterSectionImages = FooterSectionData & {
  __typename?: "FooterSectionImages";
  data: Array<Maybe<FooterImage>>;
};

export type FooterSectionLinks = FooterSectionData & {
  __typename?: "FooterSectionLinks";
  data: Array<Maybe<FooterLink>>;
};

export type FooterSectionText = FooterSectionData & {
  __typename?: "FooterSectionText";
  data: Array<Maybe<FooterText>>;
};

export type FooterSeparator = {
  __typename?: "FooterSeparator";
  type: FooterSeparatorType;
};

export type FooterSeparatorType = "HORIZONTAL_RULE";

export type FooterText = {
  __typename?: "FooterText";
  text?: Maybe<Scalars["String"]>;
};

export type Forecast = {
  __typename?: "Forecast";
  date?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  precipitationProbablity?: Maybe<Scalars["String"]>;
  seeMoreLink?: Maybe<Scalars["String"]>;
  seeMoreLinkText?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Array<TemperatureRange>>;
  weatherConditionId: Scalars["Int"];
  weatherDescription?: Maybe<Scalars["String"]>;
};

export type ForecastsResponseData = {
  __typename?: "ForecastsResponseData";
  dailyForecasts: Array<Forecast>;
  expires?: Maybe<DateTime>;
  severity: Scalars["Int"];
};

export type FormattedMoney = AccessibilityField & {
  __typename?: "FormattedMoney";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  formatted: Scalars["String"];
  /** @deprecated Use accessibilityLabel., replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
};

export type FormattedNumber = {
  __typename?: "FormattedNumber";
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["Float"];
};

export type FraudComponentDetails = {
  __typename?: "FraudComponentDetails";
  placement: Scalars["String"];
  reportingSegment: Scalars["String"];
  url: Scalars["String"];
  webSessionProviderMethod: Scalars["String"];
  webSessionProviderMethodParam: Scalars["String"];
};

export type FreeCancellationApplicability = "ALL_JOURNEYS" | "CURRENT_JOUNRNEY" | "NOT_APPLICABLE";

export type FreePmpDetailsModal = {
  __typename?: "FreePmpDetailsModal";
  cancellationRule: Scalars["String"];
  productFeatures: Array<FlightsIconAndLabel>;
  title: Scalars["String"];
};

export type FreePriceMatchPromise = {
  __typename?: "FreePriceMatchPromise";
  card: FlightsImagePlacard;
  detailsModal: FreePmpDetailsModal;
};

export type FrequentlyAskedQuestions = TravelExperienceContent & {
  __typename?: "FrequentlyAskedQuestions";
  id: Scalars["ID"];
  questionsAndAnswers: Array<QuestionAndAnswer>;
  seeLess: EgdsButton;
  seeMore: EgdsButton;
  seoStructuredData?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type FunnelLocation =
  | "CHECKOUT"
  | "CHECKOUT_ERROR"
  | "CONFIRMATION"
  | "DETAILS"
  | "ENTRY"
  | "FEATURES"
  | "PAYMENT_ERROR"
  | "POST_BOOKING"
  | "SEARCH_RESULTS"
  | "SYSTEM"
  | "USER";

export interface GdsBannerInput {
  flowType: FlowType;
  partnerType: PartnerType;
  refundType: RefundType;
  screenType: ScreenType;
}

export type GdsBannerView = {
  __typename?: "GDSBannerView";
  description: Scalars["String"];
  heading: Scalars["String"];
  icon: Icon;
};

export type Gaia = {
  __typename?: "Gaia";
  destination: Scalars["Int"];
  origin?: Maybe<Scalars["Int"]>;
  pointOfInterest?: Maybe<Scalars["String"]>;
};

export type GenericDiscoveryCard = {
  __typename?: "GenericDiscoveryCard";
  badges?: Maybe<DiscoveryCardBadges>;
  cardAction: UiLinkAction;
  closeCardAction?: Maybe<DiscoveryCloseCardAction>;
  detailsList?: Maybe<DiscoveryDetailsList>;
  heading?: Maybe<DiscoveryHeading>;
  id?: Maybe<Scalars["String"]>;
  impression: ClientSideAnalytics;
  legacyPrice?: Maybe<PropertyPrice>;
  location?: Maybe<DiscoveryCardLocation>;
  media?: Maybe<DiscoveryCardMedia>;
  rating?: Maybe<ProductRatingSummary>;
  reviewSnippet?: Maybe<DiscoveryReviewSnippet>;
  seoEntityUrl?: Maybe<Scalars["URL"]>;
  starRating?: Maybe<DiscoveryStarRating>;
  tripSaveItem?: Maybe<TripsSaveItem>;
  uniqueness?: Maybe<DiscoveryProductUniqueness>;
};

export type GenericProductGroup = DiscoveryProductGroupMap;

export type GeoLocationResult = {
  __typename?: "GeoLocationResult";
  accessibility?: Maybe<Scalars["String"]>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon: Icon;
  isClickEnabled: Scalars["Boolean"];
  location: Scalars["String"];
  viewAnalytics?: Maybe<ClientSideAnalytics>;
};

export type GeoType = "AIRPORT" | "DESTINATION" | "PROPERTY";

export type GeoUri = Uri & {
  __typename?: "GeoURI";
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
  uncertainty?: Maybe<Scalars["Int"]>;
  value: Scalars["String"];
};

export type GeographicFeature = {
  __typename?: "GeographicFeature";
  coordinates: Coordinates;
  fullName: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  type: RegionType;
};

export interface GetReviewQuestionsRequestInput {
  campaignId?: InputMaybe<Scalars["String"]>;
  htid?: InputMaybe<Scalars["String"]>;
  itin?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["String"]>;
  orderLineId?: InputMaybe<Scalars["String"]>;
  reviewId?: InputMaybe<Scalars["String"]>;
  tuid?: InputMaybe<Scalars["String"]>;
}

export interface GiftCardRequestInput {
  sessionId: Scalars["String"];
}

export type GlobalNavigationLineOfBusiness = "ACTIVITY" | "CAR" | "CRUISE" | "FLIGHT" | "HOTEL" | "PACKAGE" | "UNKNOWN";

export type GoogleOneTap = HeaderSharedUi & {
  __typename?: "GoogleOneTap";
  skipSsr: Scalars["Boolean"];
};

export type GraphQlPair = {
  __typename?: "GraphQLPair";
  key: Scalars["String"];
  value: Scalars["String"];
};

export interface GraphQlPairInput {
  key: Scalars["String"];
  value: Scalars["String"];
}

export type GroundTransfersAction =
  | GroundTransfersBookAction
  | GroundTransfersDetailsOpenAction
  | GroundTransfersDismissAction
  | GroundTransfersLinkClickAction
  | GroundTransfersMultiItemTripUpdateAction
  | GroundTransfersSelectionClickAction;

export type GroundTransfersActionCallbackMessages = {
  __typename?: "GroundTransfersActionCallbackMessages";
  failure: Scalars["String"];
  success: Scalars["String"];
};

export type GroundTransfersActionText = GroundTransfersText & {
  __typename?: "GroundTransfersActionText";
  action: GroundTransfersAction;
  style: GroundTransfersStyle;
  text: Scalars["String"];
};

export type GroundTransfersBadge = {
  __typename?: "GroundTransfersBadge";
  graphic?: Maybe<UiGraphic>;
  label?: Maybe<Scalars["String"]>;
  theme: GroundTransfersBadgeTheme;
};

export type GroundTransfersBadgeTheme = "DEAL_BUNDLED" | "DEAL_GENERIC" | "DEAL_MEMBER";

export type GroundTransfersBookAction = UiAction & {
  __typename?: "GroundTransfersBookAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  url: Scalars["String"];
};

export type GroundTransfersCard = GroundTransfersResultCard | GroundTransfersUiMessagingCard;

export type GroundTransfersDetailsContent = {
  __typename?: "GroundTransfersDetailsContent";
  /** @deprecated use tabs now */
  details: Array<GroundTransfersText>;
  heading?: Maybe<GroundTransfersText>;
  tabs: Array<GroundTransfersDetailsTab>;
};

export type GroundTransfersDetailsOpenAction = UiAction & {
  __typename?: "GroundTransfersDetailsOpenAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  details: GroundTransfersDetailsPresentation;
};

export type GroundTransfersDetailsPresentation = {
  __typename?: "GroundTransfersDetailsPresentation";
  bookButton: UiPrimaryButton;
  /** @deprecated Use structuredContent now */
  content: Array<GroundTransfersDetailsContent>;
  dismissButton: UiTertiaryButton;
  structuredContent: Array<GroundTransfersDetailsStructuredContent>;
  title?: Maybe<Scalars["String"]>;
};

export type GroundTransfersDetailsStructuredContent =
  | GroundTransfersPlainStructuredContent
  | GroundTransfersTabularStructuredContent;

export type GroundTransfersDetailsTab = {
  __typename?: "GroundTransfersDetailsTab";
  details: Array<GroundTransfersStructuredSection>;
  footer: Array<GroundTransfersText>;
  label: Scalars["String"];
};

export type GroundTransfersDirection = "FROM_AIRPORT" | "ROUNDTRIP" | "TO_AIRPORT";

export type GroundTransfersDismissAction = UiAction & {
  __typename?: "GroundTransfersDismissAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type GroundTransfersDropdownField = ShoppingSortAndFilterField & {
  __typename?: "GroundTransfersDropdownField";
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type GroundTransfersFigure = {
  __typename?: "GroundTransfersFigure";
  badge?: Maybe<GroundTransfersBadge>;
  image?: Maybe<Image>;
};

export type GroundTransfersGraphicText = GroundTransfersText & {
  __typename?: "GroundTransfersGraphicText";
  accessibilityText?: Maybe<Scalars["String"]>;
  action?: Maybe<GroundTransfersAction>;
  leading?: Maybe<UiGraphic>;
  style: GroundTransfersStyle;
  text: Scalars["String"];
  trailing?: Maybe<UiGraphic>;
};

export type GroundTransfersGroup = {
  __typename?: "GroundTransfersGroup";
  analytics: Array<ClientSideAnalytics>;
  cards: Array<GroundTransfersCard>;
  heading?: Maybe<GroundTransfersText>;
  navigationActions?: Maybe<GroundTransfersNavigationActions>;
  viewType: GroundTransfersGroupViewType;
};

export type GroundTransfersGroupViewType = "CARDS_LIST";

export type GroundTransfersJourneyCriteria = {
  __typename?: "GroundTransfersJourneyCriteria";
  dateTime: DateTime;
  destination: GroundTransfersLocation;
  origin: GroundTransfersLocation;
};

export interface GroundTransfersJourneyCriteriaInput {
  dateTime: DateTimeInput;
  destination: GroundTransfersLocationInput;
  origin: GroundTransfersLocationInput;
}

export type GroundTransfersLinkClickAction = UiAction & {
  __typename?: "GroundTransfersLinkClickAction";
  accessibility?: Maybe<Scalars["String"]>;
  accessibilityText: Scalars["String"];
  analytics: ClientSideAnalytics;
  anchorTagUrl?: Maybe<Scalars["String"]>;
  linkTagUrl?: Maybe<Scalars["String"]>;
  opensInNewTab: Scalars["Boolean"];
};

export type GroundTransfersLocation = {
  __typename?: "GroundTransfersLocation";
  coordinates?: Maybe<Coordinates>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type: GeoType;
};

export interface GroundTransfersLocationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  type: GeoType;
}

export type GroundTransfersLoyaltyPointsOption = {
  __typename?: "GroundTransfersLoyaltyPointsOption";
  formattedPoints: Scalars["String"];
  leadingCaption: Scalars["String"];
  pointsFirst: Scalars["Boolean"];
};

export type GroundTransfersMap = {
  __typename?: "GroundTransfersMap";
  accessibilityText: Scalars["String"];
  dismiss: UiTertiaryButton;
  expand: GroundTransfersAction;
  header: Scalars["String"];
  labels: Array<GroundTransfersText>;
  markers: Array<GroundTransfersMarker>;
  routes: Array<GroundTransfersMapRoute>;
};

export type GroundTransfersMapRoute = {
  __typename?: "GroundTransfersMapRoute";
  transit: GroundTransfersMapTransitOption;
  travelMode: GroundTransfersMapTravelMode;
};

export type GroundTransfersMapTransitOption = "BUS" | "NONE" | "TRAIN";

export type GroundTransfersMapTravelMode = "DRIVING" | "TRANSIT";

export type GroundTransfersMarker = {
  __typename?: "GroundTransfersMarker";
  analytics: ClientSideAnalytics;
  coordinates: Coordinates;
  details: GroundTransfersMarkerDetails;
  selectGraphic: UiGraphic;
  unselectGraphic: UiGraphic;
};

export type GroundTransfersMarkerDetails = {
  __typename?: "GroundTransfersMarkerDetails";
  entityType: Scalars["String"];
  primary: Scalars["String"];
  secondary: Array<Scalars["String"]>;
};

export type GroundTransfersMetaInfo = {
  __typename?: "GroundTransfersMetaInfo";
  title: Scalars["String"];
};

export type GroundTransfersMultiItemTripUpdateAction = UiAction & {
  __typename?: "GroundTransfersMultiItemTripUpdateAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  callbackMessages: GroundTransfersActionCallbackMessages;
  naturalKey: GroundTransfersNaturalKey;
  /** @deprecated Use naturalKey */
  offerProductNaturalKey: Scalars["String"];
  updateTripType: UpdateTripType;
};

export type GroundTransfersNaturalKey = {
  __typename?: "GroundTransfersNaturalKey";
  offerToken: Scalars["String"];
};

export interface GroundTransfersNaturalKeyInput {
  offerToken: Scalars["String"];
}

export type GroundTransfersNavigationAction = {
  __typename?: "GroundTransfersNavigationAction";
  accessibility: Scalars["String"];
  analytics?: Maybe<ClientSideAnalytics>;
};

export type GroundTransfersNavigationActions = {
  __typename?: "GroundTransfersNavigationActions";
  next: GroundTransfersNavigationAction;
  previous: GroundTransfersNavigationAction;
};

export type GroundTransfersNoResultsFoundMessage = {
  __typename?: "GroundTransfersNoResultsFoundMessage";
  graphic?: Maybe<UiGraphic>;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type GroundTransfersPlainStructuredContent = {
  __typename?: "GroundTransfersPlainStructuredContent";
  heading?: Maybe<GroundTransfersText>;
  sections: Array<GroundTransfersStructuredSection>;
};

export type GroundTransfersPlainText = GroundTransfersText & {
  __typename?: "GroundTransfersPlainText";
  accessibilityText?: Maybe<Scalars["String"]>;
  style: GroundTransfersStyle;
  text: Scalars["String"];
};

export type GroundTransfersPriceInfo = {
  __typename?: "GroundTransfersPriceInfo";
  accessibilityLabel: Scalars["String"];
  displayPrice: Scalars["String"];
  loyaltyPointsOption?: Maybe<GroundTransfersLoyaltyPointsOption>;
  strikeThroughFirst: Scalars["Boolean"];
  strikeThroughPrice: Scalars["String"];
  subtexts: Array<Scalars["String"]>;
};

export type GroundTransfersRemovablePill = UiRemovablePill & {
  __typename?: "GroundTransfersRemovablePill";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  remove?: Maybe<Icon>;
  selected: Scalars["Boolean"];
};

export type GroundTransfersResultCard = {
  __typename?: "GroundTransfersResultCard";
  accessibilityText: Scalars["String"];
  action: GroundTransfersAction;
  badge?: Maybe<GroundTransfersBadge>;
  details: GroundTransfersText;
  enhancedCleaning?: Maybe<GroundTransfersText>;
  featuredHeader?: Maybe<Scalars["String"]>;
  features: Array<GroundTransfersText>;
  figure: GroundTransfersFigure;
  freeCancellation: Array<GroundTransfersText>;
  loyaltyEarnAward?: Maybe<GroundTransfersGraphicText>;
  priceInfo: GroundTransfersPriceInfo;
  primary?: Maybe<Scalars["String"]>;
  reviews: Array<GroundTransfersText>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  tripUpdateButton?: Maybe<EgdsButton>;
  /** @deprecated Use tripUpdateButton */
  tripUpdateButtons?: Maybe<GroundTransfersTripUpdateButtons>;
};

export interface GroundTransfersSearchCriteriaInput {
  primary: PrimaryGroundTransfersCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type GroundTransfersSearchPlayback = {
  __typename?: "GroundTransfersSearchPlayback";
  dateTimeRange?: Maybe<DateRange>;
  direction: GroundTransfersDirection;
  locations: Array<GroundTransfersLocation>;
  travelers: Array<GroundTransfersTravelerDetails>;
};

export type GroundTransfersSearchResults = {
  __typename?: "GroundTransfersSearchResults";
  map?: Maybe<GroundTransfersMap>;
  noResultsFoundMessage?: Maybe<GroundTransfersNoResultsFoundMessage>;
  searchGroups: Array<GroundTransfersGroup>;
  searchSummary?: Maybe<GroundTransfersSearchSummary>;
  sortAndFilter: ShoppingSortAndFilters;
};

export type GroundTransfersSearchSummary = {
  __typename?: "GroundTransfersSearchSummary";
  heading?: Maybe<Scalars["String"]>;
  isOneKeyBannerEnabled: Scalars["Boolean"];
  metaInfo: GroundTransfersMetaInfo;
  playback: GroundTransfersSearchPlayback;
  shopWithPoints?: Maybe<UiToggle>;
  sortDisclaimer?: Maybe<GroundTransfersText>;
  subheading?: Maybe<Scalars["String"]>;
};

export type GroundTransfersSectionStyle = "BULLETED" | "STANDARD";

export type GroundTransfersSelectionClickAction = UiAction & {
  __typename?: "GroundTransfersSelectionClickAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  selections: Array<SelectedValue>;
  selectionsToRemove: Array<SelectedValue>;
};

export type GroundTransfersSortAndFilterAppliedFilter = ShoppingSortAndFilterAppliedFilter & {
  __typename?: "GroundTransfersSortAndFilterAppliedFilter";
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: GroundTransfersRemovablePill;
};

export type GroundTransfersStructuredSection = {
  __typename?: "GroundTransfersStructuredSection";
  heading?: Maybe<GroundTransfersText>;
  items: Array<GroundTransfersText>;
  style: GroundTransfersSectionStyle;
};

export type GroundTransfersStyle = "EMPHASISED" | "LOYALTY" | "POSITIVE" | "STANDARD";

export type GroundTransfersTabularStructuredContent = {
  __typename?: "GroundTransfersTabularStructuredContent";
  heading?: Maybe<GroundTransfersText>;
  tabs: Array<GroundTransfersDetailsTab>;
};

export interface GroundTransfersText {
  style: GroundTransfersStyle;
  text: Scalars["String"];
}

export type GroundTransfersToggle = UiToggle & {
  __typename?: "GroundTransfersToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  /** @deprecated Replace with the checked/unchecked variants */
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type GroundTransfersTravelerDetails = {
  __typename?: "GroundTransfersTravelerDetails";
  count: Scalars["Int"];
  type: GroundTransfersTravelerDetailsType;
};

export interface GroundTransfersTravelerDetailsInput {
  count: Scalars["Int"];
  type: GroundTransfersTravelerDetailsType;
}

export type GroundTransfersTravelerDetailsType = "ADULT" | "CHILD" | "INFANT";

export type GroundTransfersTripUpdateButtons = {
  __typename?: "GroundTransfersTripUpdateButtons";
  addToTrip: UiSecondaryButton;
  removeFromTrip: UiSecondaryButton;
};

export type GroundTransfersUiMessagingCard = {
  __typename?: "GroundTransfersUIMessagingCard";
  actions?: Maybe<UiMessagingCardActions>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type GroundTransportationSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "GroundTransportationSearchForm";
    actionURL: Scalars["String"];
    airportLocation?: Maybe<EgdsSearchFormLocationField>;
    dates?: Maybe<EgdsSearchFormDatePickerField>;
    dropoffTime?: Maybe<EgdsSelect>;
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    groundTransportationSelection?: Maybe<GroundTransportationSearchFormSelection>;
    hotelLocation?: Maybe<EgdsSearchFormLocationField>;
    pickupTime?: Maybe<EgdsSelect>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    travelers?: Maybe<EgdsSearchFormTravelersField>;
  };

export type GroundTransportationSearchFormSelection = {
  __typename?: "GroundTransportationSearchFormSelection";
  airportToHotel: EgdsBasicPill;
  hotelToAirport: EgdsBasicPill;
  roundTrip: EgdsBasicPill;
};

export type Header = {
  __typename?: "Header";
  bsaBanner?: Maybe<HeaderBanner>;
  expandedNavigation?: Maybe<HeaderSection>;
  externalSharedUIComponents?: Maybe<Array<Maybe<HeaderSharedUi>>>;
  inverseThemePrimaryRow?: Maybe<Scalars["Boolean"]>;
  inverseThemeSecondaryRow?: Maybe<Scalars["Boolean"]>;
  logos?: Maybe<HeaderSection>;
  primaryNavigation?: Maybe<HeaderSection>;
  secondaryNavigation?: Maybe<HeaderSection>;
  subHeader?: Maybe<SubHeader>;
  viewSize: HeaderViewSize;
};

export interface HeaderAction {
  analytics: ClientSideAnalytics;
}

export type HeaderAlignment = "END" | "START";

export type HeaderBanner = {
  __typename?: "HeaderBanner";
  button?: Maybe<HeaderButton>;
  subText: Scalars["String"];
  text: Scalars["String"];
};

export type HeaderButton = {
  __typename?: "HeaderButton";
  action: UiLinkAction;
  buttonType: HeaderButtonType;
  headerAction: HeaderAction;
  linkTarget: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type HeaderButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type HeaderDialog = {
  __typename?: "HeaderDialog";
  icon?: Maybe<Icon>;
  iconPosition: HeaderItemPosition;
  id: Scalars["String"];
  items: Array<Maybe<HeaderMenuItem>>;
  text: Scalars["String"];
  trackingEvent: Scalars["String"];
};

export type HeaderItemPosition = "LEFT" | "RIGHT";

export type HeaderLayout = "DEFAULT" | "MINIMAL" | "SIMPLIFIED";

export type HeaderLinkAction = HeaderAction & {
  __typename?: "HeaderLinkAction";
  analytics: ClientSideAnalytics;
  isRelative: Scalars["Boolean"];
  linkTarget: Scalars["String"];
  resource: Uri;
};

export type HeaderLocaleLinkAction = HeaderAction & {
  __typename?: "HeaderLocaleLinkAction";
  analytics: ClientSideAnalytics;
  langid: Scalars["Int"];
  locale: Scalars["String"];
};

export type HeaderLogo = {
  __typename?: "HeaderLogo";
  action: UiLinkAction;
  altText: Scalars["String"];
  imgSrc: Image;
};

export type HeaderLoyaltyAccountSummaryWidget = HeaderSharedUi & {
  __typename?: "HeaderLoyaltyAccountSummaryWidget";
  skipSsr: Scalars["Boolean"];
};

export type HeaderLoyaltyMemberStatus = HeaderSharedUi & {
  __typename?: "HeaderLoyaltyMemberStatus";
  skipSsr: Scalars["Boolean"];
};

export interface HeaderMarketingInput {
  googleClickIdentifier?: InputMaybe<Scalars["String"]>;
  metaDistributionPartnersDetails?: InputMaybe<Scalars["String"]>;
}

export type HeaderMemberWallet = HeaderSharedUi & {
  __typename?: "HeaderMemberWallet";
  skipSsr: Scalars["Boolean"];
};

export type HeaderMenu = {
  __typename?: "HeaderMenu";
  autoOpen: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  iconPosition: HeaderItemPosition;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<Maybe<HeaderMenuItem>>;
  /** @deprecated use triggerAnalytics instead */
  linkName?: Maybe<Scalars["String"]>;
  menuCookies: Array<NavigationCookie>;
  menuPosition: HeaderItemPosition;
  menuState?: Maybe<HeaderState>;
  text: Scalars["String"];
  /** @deprecated use triggerAnalytics instead */
  trackingEvent: Scalars["String"];
  triggerAnalytics?: Maybe<ClientSideAnalytics>;
};

export type HeaderMenuIllustration = {
  __typename?: "HeaderMenuIllustration";
  illustration: Illustration;
};

export type HeaderMenuImages = {
  __typename?: "HeaderMenuImages";
  images: Array<Image>;
};

export type HeaderMenuItem =
  | CommunicationCenter
  | GoogleOneTap
  | HeaderButton
  | HeaderDialog
  | HeaderLoyaltyAccountSummaryWidget
  | HeaderLoyaltyMemberStatus
  | HeaderMemberWallet
  | HeaderMenuIllustration
  | HeaderMenuImages
  | HeaderMenuLink
  | HeaderPicker
  | HeaderSeparator
  | HeaderSignInButton
  | HeaderSignOutMenuLink
  | HeaderSignUpButton
  | HeaderText
  | MobileAppDownloadButton
  | NotificationCenter
  | PartnerLoyaltyPointsComponent
  | TripsNavigation;

export type HeaderMenuLink = {
  __typename?: "HeaderMenuLink";
  action: UiLinkAction;
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"];
  newTabText: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export interface HeaderMessage {
  value: Scalars["String"];
}

export type HeaderNavButton = {
  __typename?: "HeaderNavButton";
  action: UiLinkAction;
  active: Scalars["Boolean"];
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"];
  newTabText: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type HeaderNavLink = {
  __typename?: "HeaderNavLink";
  action: UiLinkAction;
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"];
  newTabText: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type HeaderNotificationCenterState = {
  __typename?: "HeaderNotificationCenterState";
  messageSeen: Scalars["Boolean"];
};

export type HeaderOpenVirtualAgentAction = HeaderAction & {
  __typename?: "HeaderOpenVirtualAgentAction";
  analytics: ClientSideAnalytics;
  conversationProperties?: Maybe<VaConversationProperties>;
  fallbackAction: HeaderAction;
};

export type HeaderPicker = HeaderSharedUi & {
  __typename?: "HeaderPicker";
  skipSsr: Scalars["Boolean"];
};

export type HeaderSection = {
  __typename?: "HeaderSection";
  alignment: HeaderAlignment;
  sectionData: Array<HeaderSectionData>;
};

export type HeaderSectionData =
  | CommunicationCenter
  | GoogleOneTap
  | HeaderButton
  | HeaderLogo
  | HeaderLoyaltyAccountSummaryWidget
  | HeaderLoyaltyMemberStatus
  | HeaderMemberWallet
  | HeaderMenu
  | HeaderMenuLink
  | HeaderNavButton
  | HeaderNavLink
  | HeaderPicker
  | HeaderSignInButton
  | HeaderSignOutMenuLink
  | HeaderSignUpButton
  | MobileAppDownloadButton
  | NotificationCenter
  | PartnerLoyaltyPointsComponent
  | SubHeaderLogo
  | TripsNavigation;

export type HeaderSeparator = {
  __typename?: "HeaderSeparator";
  type: HeaderSeparatorType;
};

export type HeaderSeparatorType = "HORIZONTAL_RULE";

export interface HeaderSharedUi {
  skipSsr: Scalars["Boolean"];
}

export type HeaderSignInButton = {
  __typename?: "HeaderSignInButton";
  action: UiLinkAction;
  buttonType: HeaderButtonType;
  headerAction: HeaderAction;
  linkTarget: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type HeaderSignOutMenuLink = {
  __typename?: "HeaderSignOutMenuLink";
  action: UiLinkAction;
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"];
  newTabText: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type HeaderSignUpButton = {
  __typename?: "HeaderSignUpButton";
  action: UiLinkAction;
  buttonType: HeaderButtonType;
  headerAction: HeaderAction;
  linkTarget: Scalars["String"];
  testId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type HeaderState = HeaderNotificationCenterState;

export type HeaderText = {
  __typename?: "HeaderText";
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  textSize?: Maybe<HeaderTextSize>;
  textType: HeaderTextType;
};

export type HeaderTextSize = "LARGE" | "SMALL";

export type HeaderTextType = "HEADING" | "SUBHEADING";

export type HeaderViewSize = "EXTRA_LARGE" | "LARGE" | "MEDIUM" | "SMALL";

export type HeadsUpError = {
  __typename?: "HeadsUpError";
  content: BookingServicingRulesAndRestrictionsPresentation;
  graphic?: Maybe<UiGraphic>;
};

export type HeadsUpFooter = {
  __typename?: "HeadsUpFooter";
  button: BookingServicingButton;
  footerText: Scalars["String"];
};

export type HeadsUpResponse = HeadsUpError | HeadsUpSuccess;

export type HeadsUpSuccess = {
  __typename?: "HeadsUpSuccess";
  content: BookingServicingRulesAndRestrictionsPresentation;
  /** @deprecated Deprecated in favor of BookingServicingExitDialog, replace with BookingServicingExitDialog */
  exitDialog?: Maybe<BookingServicingCancelExitDialog>;
  footer: HeadsUpFooter;
};

export type HelpAndFeedbackHelpCenterOptionsType = "OWNER" | "PROPERTY_MANAGER" | "TRAVELER" | "TRUST_AND_SAFETY";

export type HelpAndFeedbackOptionsType =
  | "BRAND_CALL"
  | "CHAT_NOW"
  | "CREDIT_CARD"
  | "RATE_OUR_APP"
  | "SHARE_YOUR_FEEDBACK"
  | "VISIT_HELP_CENTER";

export type HelpArticle = {
  __typename?: "HelpArticle";
  helpArticleId: Scalars["String"];
  icon: Icon;
  product: Scalars["String"];
  productId: Scalars["String"];
  title: Scalars["String"];
};

export type HelpArticleCard = HelpArticlesLayout & {
  __typename?: "HelpArticleCard";
  helpArticleHeadingSection: HelpArticleHeadingSection;
  helpArticlesInCard: Array<HelpArticle>;
  moreHelpArticleLink?: Maybe<HelpArticleMoreSection>;
};

export type HelpArticleClickStreamAnalytics = {
  __typename?: "HelpArticleClickStreamAnalytics";
  article?: Maybe<HelpArticleEventPayload>;
  event: EgClickstreamEvent;
  experience: Experience;
  workFlowList: Array<WorkFlowItem>;
};

export type HelpArticleData = {
  __typename?: "HelpArticleData";
  categories?: Maybe<Array<HelpArticleHeadingSection>>;
  contactUsPreFill?: Maybe<ContactUsPreFill>;
  heading: HelpArticleHeadingSection;
  helpArticleClickStreamAnalytics: HelpArticleClickStreamAnalytics;
  helpArticleContent?: Maybe<HelpArticlePortableTextContent>;
  moreAboutCategory?: Maybe<Scalars["String"]>;
  productId: Scalars["String"];
  productTitle: Scalars["String"];
};

export type HelpArticleDataView = HelpArticleData | HelpArticleErrorView;

export type HelpArticleDialog = HelpArticlesLayout & {
  __typename?: "HelpArticleDialog";
  articlesList: Array<HelpArticle>;
  helpArticleHeadingSection: HelpArticleHeadingSection;
};

export type HelpArticleErrorView = {
  __typename?: "HelpArticleErrorView";
  errorHeading: Scalars["String"];
  errorMessage: Scalars["String"];
};

export type HelpArticleEventPayload = {
  __typename?: "HelpArticleEventPayload";
  articleGroup?: Maybe<Scalars["String"]>;
  articleLink?: Maybe<Scalars["String"]>;
  articleLinkType?: Maybe<Scalars["String"]>;
  articleName?: Maybe<Scalars["String"]>;
  documentId?: Maybe<Scalars["String"]>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type HelpArticleFeedbackSection = {
  __typename?: "HelpArticleFeedbackSection";
  heading: Scalars["String"];
  negativeFeedbackIcon: Icon;
  negativeFeedbackSection: HelpArticleNegativeFeedbackSection;
  positiveFeedbackIcon: Icon;
  thankYouMessage: Scalars["String"];
  thankYouMessageDisplayTimeInMilliSec: Scalars["Int"];
};

export type HelpArticleHeadingSection = {
  __typename?: "HelpArticleHeadingSection";
  headingText: Scalars["String"];
  icon?: Maybe<Icon>;
};

export type HelpArticleMoreSection = {
  __typename?: "HelpArticleMoreSection";
  accessibility: Scalars["String"];
  icon: Icon;
  linkName: Scalars["String"];
  product: Scalars["String"];
  productId: Scalars["String"];
  viewIcon: Icon;
};

export type HelpArticleNegativeFeedbackSection = {
  __typename?: "HelpArticleNegativeFeedbackSection";
  options: EgdsCheckBoxGroup;
  optionsTitle: Scalars["String"];
  submitButton: EgdsButton;
};

export type HelpArticlePortableTextContent = {
  __typename?: "HelpArticlePortableTextContent";
  helpArticlePortableTextContent: Array<HelpArticlePortableTextEgds>;
};

export type HelpArticlePortableTextEgds =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSearchFormSelectAction
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsUnorderedList
  | HelpArticleTable;

export type HelpArticleSearchErrorEgdsContent = EgdsBulletedList | EgdsPlainText;

export type HelpArticleSearchErrorView = {
  __typename?: "HelpArticleSearchErrorView";
  helpArticleSearchErrorMessage: Array<HelpArticleSearchErrorEgdsContent>;
  helpArticleSearchSubHeading: Scalars["String"];
  searchTerm?: Maybe<Scalars["String"]>;
};

export type HelpArticleSummarizationPoweredByLlm = {
  __typename?: "HelpArticleSummarizationPoweredByLLM";
  copyrightText: Scalars["String"];
  feedbackClickstreamPayload: ArticlesSummarizationClickStreamAnalytics;
  feedbackLabel: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
  negativeFeedbackIcon: Icon;
  positiveFeedbackIcon: Icon;
  summarizedResponseByLLM: Array<SummarizedResponseEgds>;
  thankYouMessage: EgdsGraphicText;
};

export type HelpArticleTable = {
  __typename?: "HelpArticleTable";
  body: HelpArticleTableBody;
  caption?: Maybe<Scalars["String"]>;
  header: Array<Scalars["String"]>;
};

export type HelpArticleTableBody = {
  __typename?: "HelpArticleTableBody";
  rows: Array<HelpArticleTableRow>;
};

export type HelpArticleTableCell = {
  __typename?: "HelpArticleTableCell";
  content: Array<HelpArticleTableCellContent>;
};

export type HelpArticleTableCellContent =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText;

export type HelpArticleTableRow = {
  __typename?: "HelpArticleTableRow";
  cells: Array<HelpArticleTableCell>;
};

export type HelpArticlesContactUsWidgetSection = {
  __typename?: "HelpArticlesContactUsWidgetSection";
  contactUsButton: UiSecondaryButton;
  headingText: Scalars["String"];
};

export interface HelpArticlesLayout {
  helpArticleHeadingSection: HelpArticleHeadingSection;
}

export type HelpArticlesResultsView = {
  __typename?: "HelpArticlesResultsView";
  allArticles: AllHelpArticlesView;
  articleFeedbackSection?: Maybe<HelpArticleFeedbackSection>;
  articlesById: HelpArticleDataView;
  articlesBySearchTerm: SearchHelpArticlesView;
  articlesSummarizationBySearchTerm: ArticlesSummarizationView;
  helpArticleSearchBar: HelpCenterSearchBar;
  moreArticles: MoreHelpArticlesView;
  relevantArticlesBySearchTerm: RelevantHelpArticlesView;
  summarizationFeedback: SummarizationFeedbackView;
};

export type HelpArticlesResultsViewArticlesByIdArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewArticlesBySearchTermArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewArticlesSummarizationBySearchTermArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewMoreArticlesArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewRelevantArticlesBySearchTermArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export interface HelpArticlesSearchRequestInput {
  category?: InputMaybe<Scalars["String"]>;
  helpArticleId?: InputMaybe<Scalars["String"]>;
  helpArticlesSearchTerm?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Scalars["String"]>;
}

export type HelpCenterCspArticlePair = {
  __typename?: "HelpCenterCSPArticlePair";
  cspArticleId: Scalars["String"];
  hcArticleId: Scalars["String"];
};

export type HelpCenterComponentsView = {
  __typename?: "HelpCenterComponentsView";
  helpCenterPersonalizedView: HelpCenterPersonalizationData;
  helpCenterTripView?: Maybe<HelpCenterTrip>;
};

export type HelpCenterIntentButtons = {
  __typename?: "HelpCenterIntentButtons";
  name: UiPrimaryButton;
  payload?: Maybe<Scalars["String"]>;
  skillName: Scalars["String"];
  vaText?: Maybe<Scalars["String"]>;
};

export type HelpCenterPersonalizationData = {
  __typename?: "HelpCenterPersonalizationData";
  banner: HelpCenterWelcomeBanner;
  contactUsButton?: Maybe<UiSecondaryButton>;
  cspToHelpCenterArticleMap?: Maybe<Array<HelpCenterCspArticlePair>>;
  helpArticleTitle: Scalars["String"];
  intentButtons?: Maybe<Array<HelpCenterIntentButtons>>;
  intentButtonsHeading: Scalars["String"];
  isVacEnabled?: Maybe<Scalars["Boolean"]>;
  pageId?: Maybe<Scalars["String"]>;
  taapAcademyComponent?: Maybe<TaapAcademyComponent>;
  travelerTripDetailedOverview?: Maybe<HelpCenterTripDetailedOverview>;
  travelerTripVAPersonalization?: Maybe<HelpCenterTripOverview>;
  /** @deprecated This is only temporary use until Help Center UI is onboarded to EGTnL */
  vaLayoutVariant?: Maybe<Scalars["Int"]>;
};

export type HelpCenterSearchBar = {
  __typename?: "HelpCenterSearchBar";
  searchBar: EgdsTextInputField;
  searchButton: UiPrimaryButton;
  waitMessage?: Maybe<Scalars["String"]>;
};

export type HelpCenterTrip = {
  __typename?: "HelpCenterTrip";
  trip?: Maybe<HelpCenterTripDetailedOverview>;
};

export type HelpCenterTripDetailedOverview = {
  __typename?: "HelpCenterTripDetailedOverview";
  allTrips?: Maybe<Array<TripCategory>>;
};

export type HelpCenterTripInfo = {
  __typename?: "HelpCenterTripInfo";
  backToTripButton: Scalars["String"];
  bookingIcons?: Maybe<Array<Icon>>;
  bookings?: Maybe<Array<Booking>>;
  header?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  numberOfBooking?: Maybe<Scalars["String"]>;
  timePeriod?: Maybe<Scalars["String"]>;
  tripName?: Maybe<Scalars["String"]>;
};

export type HelpCenterTripOverview = {
  __typename?: "HelpCenterTripOverview";
  header: Scalars["String"];
  tripInfos?: Maybe<Array<HelpCenterTripInfo>>;
};

export type HelpCenterWelcomeBanner = {
  __typename?: "HelpCenterWelcomeBanner";
  heading: Scalars["String"];
  heroImage?: Maybe<Image>;
  subHeading?: Maybe<Scalars["String"]>;
};

export type HelperConfigMap =
  | "And"
  | "CapitalizeFirst"
  | "DeviceTypeEvaluator"
  | "Equals"
  | "EvaluateExperiment"
  | "EvaluateExperimentAndLog"
  | "ExperimentEvaluator"
  | "FormatDoubleNumber"
  | "FormatIntegerNumber"
  | "GetSystemTimeInMs"
  | "GetUsableURN"
  | "Greater"
  | "GreaterAndEquals"
  | "HTTPLink"
  | "Join"
  | "Limit"
  | "Localize"
  | "Not"
  | "Or";

export type HelperMetadata = {
  __typename?: "HelperMetadata";
  description?: Maybe<Scalars["String"]>;
  name: HelperConfigMap;
  usage?: Maybe<Scalars["String"]>;
};

export type HeroComponent = {
  __typename?: "HeroComponent";
  background?: Maybe<Image>;
  elements?: Maybe<Array<TripsComposableElement>>;
  icons?: Maybe<Array<Icon>>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type Highlights = {
  __typename?: "Highlights";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  reviewSummaryHighlight?: Maybe<ReviewSummaryHighlight>;
};

export type History = {
  __typename?: "History";
  items?: Maybe<Array<Maybe<HistoryItem>>>;
  type?: Maybe<HistoryType>;
};

export type HistoryActivityDetail = HistoryProductInfo & {
  __typename?: "HistoryActivityDetail";
  discount?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["String"]>;
  freeCancellation?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  imageUrl?: Maybe<Image>;
  leadTicketType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  referencePrice?: Maybe<Money>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]>;
  searchUrl?: Maybe<Scalars["String"]>;
};

export type HistoryActivitySearch = HistoryProductInfo & {
  __typename?: "HistoryActivitySearch";
  numberOfResults?: Maybe<Scalars["Int"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]>;
  searchUrl?: Maybe<Scalars["String"]>;
};

export type HistoryAirline = {
  __typename?: "HistoryAirline";
  airlineCode?: Maybe<Scalars["String"]>;
  airlineName?: Maybe<Scalars["String"]>;
};

export type HistoryCarDetail = HistoryProductInfo & {
  __typename?: "HistoryCarDetail";
  carCategory?: Maybe<Scalars["String"]>;
  carType?: Maybe<Scalars["String"]>;
  carVendor?: Maybe<Scalars["String"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  dropOffRegionDetail?: Maybe<GeographicFeature>;
  dropOffRegionId?: Maybe<Scalars["String"]>;
  imageURL?: Maybe<Image>;
  periodPrice?: Maybe<Money>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  pickUpRegionDetail?: Maybe<GeographicFeature>;
  piid?: Maybe<Scalars["String"]>;
  pricePeriod?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["String"]>;
  searchKey?: Maybe<Scalars["String"]>;
};

export type HistoryCarSearch = HistoryProductInfo & {
  __typename?: "HistoryCarSearch";
  dailyPrice?: Maybe<Money>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  dropOffRegionDetail?: Maybe<GeographicFeature>;
  dropOffRegionId?: Maybe<Scalars["String"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  pickUpRegionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]>;
  searchKey?: Maybe<Scalars["String"]>;
};

export type HistoryCruiseSearch = HistoryProductInfo & {
  __typename?: "HistoryCruiseSearch";
  regionId?: Maybe<Scalars["String"]>;
  regionName?: Maybe<Scalars["String"]>;
};

export type HistoryFlightDetail = HistoryProductInfo & {
  __typename?: "HistoryFlightDetail";
  cheapestOfferPerPerson?: Maybe<Money>;
  destinationAirportCode?: Maybe<Scalars["String"]>;
  destinationRegionId?: Maybe<Scalars["String"]>;
  destinationRegionName?: Maybe<Scalars["String"]>;
  flightLegList?: Maybe<Array<FlightLeg>>;
  flightPassengers?: Maybe<FlightPassengers>;
  localizedFlightName?: Maybe<Scalars["String"]>;
  originAirportCode?: Maybe<Scalars["String"]>;
  originRegionId?: Maybe<Scalars["String"]>;
  originRegionName?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["String"]>;
  routeType?: Maybe<Scalars["String"]>;
  searchUrl?: Maybe<Scalars["String"]>;
};

export type HistoryFlightSearch = HistoryProductInfo & {
  __typename?: "HistoryFlightSearch";
  cheapestOfferPerPerson?: Maybe<Money>;
  destinationAirportCode?: Maybe<Scalars["String"]>;
  destinationRegionId?: Maybe<Scalars["String"]>;
  destinationRegionName?: Maybe<Scalars["String"]>;
  flightPassengers?: Maybe<FlightPassengers>;
  localizedFlightName?: Maybe<Scalars["String"]>;
  originAirportCode?: Maybe<Scalars["String"]>;
  originRegionId?: Maybe<Scalars["String"]>;
  originRegionName?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["String"]>;
  routeType?: Maybe<Scalars["String"]>;
  searchUrl?: Maybe<Scalars["String"]>;
};

export type HistoryGroup = {
  __typename?: "HistoryGroup";
  displayData: SearchHistoryDisplayData;
  filterCriteria: SearchHistoryFilterCriteria;
};

export type HistoryItem = {
  __typename?: "HistoryItem";
  endDate: ZonedDateTime;
  id?: Maybe<Scalars["String"]>;
  lastViewedDate: ZonedDateTime;
  localizedAsOfDateTime?: Maybe<Scalars["String"]>;
  localizedDateRange?: Maybe<Scalars["String"]>;
  originalSearchPrice?: Maybe<Money>;
  productInfo?: Maybe<HistoryProductInfo>;
  startDate: ZonedDateTime;
};

export type HistoryMutationResponse = {
  __typename?: "HistoryMutationResponse";
  message?: Maybe<Scalars["String"]>;
};

export type HistoryPackageSearch = HistoryProductInfo & {
  __typename?: "HistoryPackageSearch";
  arrivalAirport?: Maybe<Scalars["String"]>;
  departureAirport?: Maybe<Scalars["String"]>;
  infantsInLap: Scalars["Boolean"];
  localizedPackageName?: Maybe<Scalars["String"]>;
  localizedPackagesTemplate?: Maybe<Scalars["String"]>;
  localizedRegionName?: Maybe<Scalars["String"]>;
  nonStop: Scalars["Boolean"];
  numberOfInfantsInLap?: Maybe<Scalars["Int"]>;
  numberOfRooms?: Maybe<Scalars["Int"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]>;
  roomOccupants?: Maybe<Array<RoomOccupants>>;
  searchUrl?: Maybe<Scalars["String"]>;
};

export interface HistoryProductInfo {
  regionId?: Maybe<Scalars["String"]>;
}

export type HistoryPropertyDetail = HistoryProductInfo & {
  __typename?: "HistoryPropertyDetail";
  checkinDate: ZonedDateTime;
  /** @deprecated Checkin date in posix format. Ony to be used by federation queries */
  checkinDateRaw: Scalars["String"];
  checkoutDate: ZonedDateTime;
  /** @deprecated Checkout date in posix format. Ony to be used by federation queries */
  checkoutDateRaw: Scalars["String"];
  /** @deprecated DO NOT USE: The field exists for the federation migration only */
  deprecatedContext: DeprecatedCustomerContext;
  formattedRating: Scalars["String"];
  formattedReviewCount: Scalars["String"];
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyImageGallery */
  gallery?: Maybe<PropertyGallery>;
  numberOfRooms?: Maybe<Scalars["Int"]>;
  numberOfTravelers?: Maybe<Scalars["Int"]>;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyOffers */
  offers?: Maybe<OfferDetails>;
  propertyId?: Maybe<Scalars["String"]>;
  propertyImageGallery?: Maybe<PropertyImageGallery>;
  propertyOffers?: Maybe<OfferDetails>;
  /** @deprecated Deprecated field: don't use it */
  propertyReviews?: Maybe<PropertyReviews>;
  propertySummary?: Maybe<PropertySummary>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]>;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyReviews */
  reviewInfo?: Maybe<PropertyReviews>;
  roomOccupants?: Maybe<Array<RoomOccupants>>;
  searchUrl?: Maybe<Scalars["String"]>;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertySummary */
  summary?: Maybe<PropertySummary>;
};

export type HistoryPropertyDetailPropertyImageGalleryArgs = {
  context: ContextInput;
};

export type HistoryPropertyDetailPropertyOffersArgs = {
  context: ContextInput;
};

export type HistoryPropertyDetailPropertyReviewsArgs = {
  context: ContextInput;
};

export type HistoryPropertyDetailPropertySummaryArgs = {
  context: ContextInput;
};

export type HistoryPropertySearch = HistoryProductInfo & {
  __typename?: "HistoryPropertySearch";
  localizedRoomsTemplate?: Maybe<Scalars["String"]>;
  numberOfRooms?: Maybe<Scalars["Int"]>;
  numberOfTravelers?: Maybe<Scalars["Int"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]>;
  roomOccupants?: Maybe<Array<RoomOccupants>>;
  searchUrl?: Maybe<Scalars["String"]>;
};

export type HistoryType =
  | "ACTIVITY_DETAIL"
  | "ACTIVITY_SEARCH"
  | "CAR_DETAIL"
  | "CAR_SEARCH"
  | "CRUISE_SEARCH"
  | "FLIGHT_DETAIL"
  | "FLIGHT_SEARCH"
  | "PACKAGE_SEARCH"
  | "PROPERTY_DETAIL"
  | "PROPERTY_SEARCH";

export type HomeCard = {
  __typename?: "HomeCard";
  buttons?: Maybe<Array<EgdsButton>>;
  dismissAction?: Maybe<HomeCardDismissAction>;
  heading?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  secondaries?: Maybe<Array<EgdsText>>;
};

export type HomeCardDismissAction = {
  __typename?: "HomeCardDismissAction";
  dismissAction: EgdsDismissAction;
  dismissIcon?: Maybe<Icon>;
};

export type HomeMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "HomeMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type HomeResponse = HeroComponent;

export type HomeTextLinkListItem = EgdsElement &
  EgdsTextListItem & {
    __typename?: "HomeTextLinkListItem";
    action: UiLinkAction;
    egdsElementId?: Maybe<Scalars["String"]>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
  };

export type HotelAmenity = {
  __typename?: "HotelAmenity";
  code: Scalars["Int"];
};

export type HotelDetailsCard = {
  __typename?: "HotelDetailsCard";
  address: Scalars["String"];
  contactInfo: Array<LabeledValue>;
  name: Scalars["String"];
};

export type HotelOfferItem = OfferItem & {
  __typename?: "HotelOfferItem";
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discountBadge?: Maybe<CampaignOfferBadge>;
  freeCancellationMessage?: Maybe<Scalars["String"]>;
  guestReviewCount?: Maybe<Scalars["String"]>;
  guestReviewQualifierText?: Maybe<Scalars["String"]>;
  guestReviewRating?: Maybe<Scalars["String"]>;
  guestReviewRatingQualifierText?: Maybe<Scalars["String"]>;
  guestReviewText?: Maybe<Scalars["String"]>;
  image: Image;
  location?: Maybe<Scalars["String"]>;
  lodgingOffer?: Maybe<OfferData>;
  mod?: Maybe<ModItem>;
  name: Scalars["String"];
  secondaryContent?: Maybe<Scalars["String"]>;
  starRating?: Maybe<Scalars["Float"]>;
  vapMessage?: Maybe<Scalars["String"]>;
  vipMessage?: Maybe<Scalars["String"]>;
};

export type HotelProduct = {
  __typename?: "HotelProduct";
  displayedAmenities: Array<HotelAmenity>;
  productId: Scalars["Int"];
  productType?: Maybe<Scalars["String"]>;
};

export interface HttpHeaderInput {
  name: Scalars["String"];
  values: Array<Scalars["String"]>;
}

export type HttpStatus =
  | "ACCEPTED"
  | "ALREADY_REPORTED"
  | "BAD_GATEWAY"
  | "BAD_REQUEST"
  | "BANDWIDTH_LIMIT_EXCEEDED"
  | "CHECKPOINT"
  | "CONFLICT"
  | "CONTINUE"
  | "CREATED"
  | "DESTINATION_LOCKED"
  | "EXPECTATION_FAILED"
  | "FAILED_DEPENDENCY"
  | "FORBIDDEN"
  | "FOUND"
  | "GATEWAY_TIMEOUT"
  | "GONE"
  | "HTTP_VERSION_NOT_SUPPORTED"
  | "IM_USED"
  | "INSUFFICIENT_SPACE_ON_RESOURCE"
  | "INSUFFICIENT_STORAGE"
  | "INTERNAL_SERVER_ERROR"
  | "I_AM_A_TEAPOT"
  | "LENGTH_REQUIRED"
  | "LOCKED"
  | "LOOP_DETECTED"
  | "METHOD_FAILURE"
  | "METHOD_NOT_ALLOWED"
  | "MOVED_PERMANENTLY"
  | "MOVED_TEMPORARILY"
  | "MULTIPLE_CHOICES"
  | "MULTI_STATUS"
  | "NETWORK_AUTHENTICATION_REQUIRED"
  | "NON_AUTHORITATIVE_INFORMATION"
  | "NOT_ACCEPTABLE"
  | "NOT_EXTENDED"
  | "NOT_FOUND"
  | "NOT_IMPLEMENTED"
  | "NOT_MODIFIED"
  | "NO_CONTENT"
  | "OK"
  | "PARTIAL_CONTENT"
  | "PAYLOAD_TOO_LARGE"
  | "PAYMENT_REQUIRED"
  | "PERMANENT_REDIRECT"
  | "PRECONDITION_FAILED"
  | "PRECONDITION_REQUIRED"
  | "PROCESSING"
  | "PROXY_AUTHENTICATION_REQUIRED"
  | "REQUESTED_RANGE_NOT_SATISFIABLE"
  | "REQUEST_ENTITY_TOO_LARGE"
  | "REQUEST_HEADER_FIELDS_TOO_LARGE"
  | "REQUEST_TIMEOUT"
  | "REQUEST_URI_TOO_LONG"
  | "RESET_CONTENT"
  | "SEE_OTHER"
  | "SERVICE_UNAVAILABLE"
  | "SWITCHING_PROTOCOLS"
  | "TEMPORARY_REDIRECT"
  | "TOO_EARLY"
  | "TOO_MANY_REQUESTS"
  | "UNAUTHORIZED"
  | "UNAVAILABLE_FOR_LEGAL_REASONS"
  | "UNPROCESSABLE_ENTITY"
  | "UNSUPPORTED_MEDIA_TYPE"
  | "UPGRADE_REQUIRED"
  | "URI_TOO_LONG"
  | "USE_PROXY"
  | "VARIANT_ALSO_NEGOTIATES";

export type HttpUri = Uri & {
  __typename?: "HttpURI";
  relativePath: Scalars["String"];
  value: Scalars["String"];
};

export interface IEgClickstreamData {
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
}

export type Ifj_ContactOptionsSheet = {
  __typename?: "IFJ_ContactOptionsSheet";
  heading: Scalars["String"];
  options: Array<Ifj_ContactUsOption>;
  subheading: Scalars["String"];
  toolBar: Ifj_ContactUsToolBar;
};

export type Ifj_ContactUsErrorView = {
  __typename?: "IFJ_ContactUsErrorView";
  errorMessage?: Maybe<Scalars["String"]>;
  errorTitle?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  icon: Icon;
  subheading?: Maybe<Scalars["String"]>;
  toolBar: Ifj_ContactUsToolBar;
};

export interface Ifj_ContactUsFormContentInput {
  category: Scalars["String"];
  intent: Scalars["String"];
  itineraryNumber: Scalars["String"];
  phoneNumber: Scalars["String"];
}

export type Ifj_ContactUsOption = {
  __typename?: "IFJ_ContactUsOption";
  leftIcon: Icon;
  option: Ifj_ContactUsOptionType;
  optionLabel: Scalars["String"];
  rightIcon: Icon;
  waitTime?: Maybe<Scalars["String"]>;
};

export type Ifj_ContactUsOptionType =
  | "CALL_BACK_WITH_AGENT"
  | "CALL_TO_AGENT"
  | "CHAT_BACK_WITH_AGENT"
  | "CHAT_WITH_AGENT"
  | "SEND_EMAIL_AGENT";

export type Ifj_ContactUsOptionsView = Ifj_ContactOptionsSheet | Ifj_ContactUsErrorView;

export type Ifj_ContactUsSendEmailFailed = {
  __typename?: "IFJ_ContactUsSendEmailFailed";
  button: UiPrimaryButton;
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export interface Ifj_ContactUsSendEmailRequestInput {
  customerEmail: Scalars["String"];
  customerName: Scalars["String"];
  message: Scalars["String"];
  subject: Scalars["String"];
}

export type Ifj_ContactUsSendEmailSuccess = {
  __typename?: "IFJ_ContactUsSendEmailSuccess";
  button: UiPrimaryButton;
  icon: Icon;
  message: Scalars["String"];
  title: Scalars["String"];
};

export type Ifj_ContactUsSendEmailView = Ifj_ContactUsSendEmailFailed | Ifj_ContactUsSendEmailSuccess;

export type Ifj_ContactUsSheet = {
  __typename?: "IFJ_ContactUsSheet";
  customerFormContent: Ifj_ContactUsSheetContent;
  heading: Scalars["String"];
  seeContactUsOptions: UiPrimaryButton;
  subheading: Scalars["String"];
  toolBar: Ifj_ContactUsToolBar;
};

export type Ifj_ContactUsSheetContent = {
  __typename?: "IFJ_ContactUsSheetContent";
  categorySelect: EgdsBasicSelect;
  intentSelect: EgdsBasicSelect;
  itineraryNumber: EgdsTextInputField;
  phoneNumber: EgdsTextInputField;
};

export type Ifj_ContactUsSheetView = Ifj_ContactUsErrorView | Ifj_ContactUsSheet;

export type Ifj_ContactUsToolBar = {
  __typename?: "IFJ_ContactUsToolBar";
  icon: Icon;
  navIconLabel?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type Ifj_ContactUsView = {
  __typename?: "IFJ_ContactUsView";
  contactUsOptionsView: Ifj_ContactUsOptionsView;
  contactUsSheetView: Ifj_ContactUsSheetView;
  contactUsSheetViewWithPayload: Ifj_ContactUsSheetView;
  emailUsFormView: Ifj_EmailUsFormView;
  speakNextAvailableAgentView: Ifj_SpeakNextAvailableAgentView;
};

export type Ifj_ContactUsViewContactUsOptionsViewArgs = {
  searchFormInput: Ifj_ContactUsFormContentInput;
};

export type Ifj_ContactUsViewContactUsSheetViewWithPayloadArgs = {
  articleIntent?: InputMaybe<Scalars["String"]>;
  articleProduct?: InputMaybe<Scalars["String"]>;
};

export type Ifj_ContactUsViewEmailUsFormViewArgs = {
  optionType: Ifj_ContactUsOptionType;
};

export type Ifj_ContactUsViewSpeakNextAvailableAgentViewArgs = {
  optionType: Ifj_ContactUsOptionType;
};

export interface Ifj_ContextInput {
  currency?: InputMaybe<Scalars["String"]>;
  debugContext?: InputMaybe<DebugContextInput>;
  device: DeviceInput;
  eapid?: InputMaybe<Scalars["Int"]>;
  locale: Scalars["String"];
  privacyTrackingState?: InputMaybe<PrivacyTrackingState>;
  siteId: Scalars["Int"];
  tpid?: InputMaybe<Scalars["Int"]>;
}

export type Ifj_EmailUsFormView = Ifj_ContactUsErrorView | Ifj_EmailUsSheet;

export type Ifj_EmailUsSheet = {
  __typename?: "IFJ_EmailUsSheet";
  back: Scalars["String"];
  customerEmail: EgdsEmailInputField;
  customerName: EgdsTextInputField;
  heading: Scalars["String"];
  message: EgdsTextAreaInputField;
  send: UiPrimaryButton;
  subheading: Scalars["String"];
  subject: EgdsTextInputField;
  toolBar: Ifj_ContactUsToolBar;
};

export type Ifj_SpeakNextAvailableAgentSheet = {
  __typename?: "IFJ_SpeakNextAvailableAgentSheet";
  back: Scalars["String"];
  heading: Scalars["String"];
  phoneNumberList: Array<PhoneNumberData>;
  subheading: Scalars["String"];
  toolBar: Ifj_ContactUsToolBar;
};

export type Ifj_SpeakNextAvailableAgentView = Ifj_ContactUsErrorView | Ifj_SpeakNextAvailableAgentSheet;

export interface IPriceInsightsDialog {
  closeAnalytics?: Maybe<EgClickstreamEvent>;
  /** @deprecated Use egcsAnalytics instead of displayAnalytics */
  displayAnalytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  footer: PriceInsightsTrackingDialogFooter;
  heading?: Maybe<Scalars["String"]>;
  paragraph: Scalars["String"];
}

export interface ISupplyPaymentLineItem {
  amount: SupplyPaymentDetailsAmountLineItem;
  footerNoteText?: Maybe<EgdsStylizedText>;
  lineItemText: EgdsStylizedText;
  originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
  sideNoteText?: Maybe<EgdsStylizedText>;
}

export interface ISupplyPaymentMoreInfo {
  tooltip?: Maybe<SupplyPaymentTooltip>;
}

export interface ISupplyReservationsPaymentLineItem {
  amount: SupplyReservationsPaymentDetailsAmountLineItem;
  footerNoteText?: Maybe<EgdsStylizedText>;
  lineItemText: EgdsStylizedText;
  originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
  sideNoteText?: Maybe<EgdsStylizedText>;
}

export interface ISupplyReservationsPaymentMoreInfo {
  tooltip?: Maybe<SupplyReservationsToolTip>;
}

export interface ISupplyScoreFactorPanelContent {
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
}

export type Icon = {
  __typename?: "Icon";
  description: Scalars["String"];
  id: Scalars["String"];
  size?: Maybe<IconSize>;
  spotLight?: Maybe<IconSpotLight>;
  theme?: Maybe<IconTheme>;
  title?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  withBackground?: Maybe<Scalars["Boolean"]>;
};

export type IconSize = "EXTRA_SMALL" | "LARGE" | "MEDIUM" | "MEDIUM_LARGE" | "SMALL" | "XLARGE";

export type IconSpotLight = "INFO" | "POSITIVE" | "STANDARD" | "WARNING";

export type IconTheme = "default" | "emphasis" | "inverse" | "negative" | "positive";

export type IconWrapper = {
  __typename?: "IconWrapper";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  icons: Array<Icon>;
};

export type IdentifierMetadata = {
  __typename?: "IdentifierMetadata";
  description?: Maybe<Scalars["String"]>;
  name: IdentifierType;
};

export type IdentifierType =
  | "ACTIVITY_ID"
  | "CART_ID"
  | "CHECKOUT_SESSION_ID"
  | "ENTITY_ID"
  | "LEGACY_ORDER_ID"
  | "OFFER_ID"
  | "ORDER_ID"
  | "PROPERTY_ID";

export type Identity = {
  __typename?: "Identity";
  authState?: Maybe<AuthenticationState>;
  duaid: Scalars["UUID"];
  expUserId?: Maybe<Scalars["String"]>;
  tuid?: Maybe<Scalars["String"]>;
};

export type IdentityAccountCreationState = "INVALID" | "NOT_UPGRADED" | "UPGRADED";

export type IdentityAccountCreationType = "INVALID" | "REGISTERED" | "UNREGISTERED";

export type IdentityAccountResponse = IdentityCreateAccountErrorResponse | IdentityCreateAccountSuccessResponse;

export type IdentityAccountTakeOverDevice = {
  __typename?: "IdentityAccountTakeOverDevice";
  payload: Scalars["String"];
  type: IdentityAccountTakeOverDeviceType;
};

export interface IdentityAccountTakeOverDeviceInput {
  payload: Scalars["String"];
  type: IdentityAccountTakeOverDeviceType;
}

export type IdentityAccountTakeOverDeviceType = "TRUST_WIDGET";

export interface IdentityAccountTakeOverInput {
  inputs: Array<GraphQlPairInput>;
  type: Scalars["String"];
}

export type IdentityAccountTakeOverShield = {
  __typename?: "IdentityAccountTakeOverShield";
  accountTakeOverTokens?: Maybe<Array<IdentityAccountTakeOverToken>>;
  crossSiteRequestForgeryData: IdentityCrossSiteRequestForgery;
  devices?: Maybe<Array<IdentityAccountTakeOverDevice>>;
  placement?: Maybe<Scalars["String"]>;
};

export interface IdentityAccountTakeOverShieldInput {
  accountTakeOverTokens?: InputMaybe<Array<IdentityAccountTakeOverTokenInput>>;
  crossSiteRequestForgeryData: IdentityCrossSiteRequestForgeryInput;
  devices?: InputMaybe<Array<IdentityAccountTakeOverDeviceInput>>;
  placement?: InputMaybe<Scalars["String"]>;
}

export type IdentityAccountTakeOverToken = {
  __typename?: "IdentityAccountTakeOverToken";
  name: Scalars["String"];
  value: Scalars["String"];
};

export interface IdentityAccountTakeOverTokenInput {
  name: Scalars["String"];
  value: Scalars["String"];
}

export type IdentityAccountTakeOverType =
  | "CAPTCHA_ARKOSE_MOBILE_APP"
  | "CAPTCHA_SAFETYNET_ANDROID"
  | "CSRF"
  | "DEVICE_CHECK"
  | "TRUST_WIDGET";

export interface IdentityAccountTakeOverWidgetAction {
  inputKeys: Array<Scalars["String"]>;
  type: IdentityAccountTakeOverType;
}

export interface IdentityAction {
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
}

export type IdentityAnalyticsEvent =
  | IdentityAnalyticsImpressionEvent
  | IdentityAnalyticsInteractionEvent
  | IdentityAnalyticsOutcomeEvent;

export type IdentityAnalyticsImpressionEvent = {
  __typename?: "IdentityAnalyticsImpressionEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type IdentityAnalyticsInteractionEvent = {
  __typename?: "IdentityAnalyticsInteractionEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type IdentityAnalyticsOutcomeEvent = {
  __typename?: "IdentityAnalyticsOutcomeEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type IdentityAnalyticsPayloadType = "EG_CLICK_STREAM" | "UIS_PRIME";

export type IdentityAvailabilityRule = {
  __typename?: "IdentityAvailabilityRule";
  elements: Array<Scalars["String"]>;
  initialAvailability: Scalars["Boolean"];
};

export type IdentityBasicBlock = EgdsElement &
  EgdsElements &
  IdentityBlock & {
    __typename?: "IdentityBasicBlock";
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsElement>;
    properties?: Maybe<IdentityBlockProperties>;
  };

export type IdentityBasicBlockProperties = IdentityBlockProperties & {
  __typename?: "IdentityBasicBlockProperties";
  alignment?: Maybe<Alignment>;
  enabled: Scalars["Boolean"];
  hidden: Scalars["Boolean"];
  internalSpace?: Maybe<IdentitySpaceSize>;
  topSpace?: Maybe<IdentitySpaceSize>;
};

export interface IdentityBlock {
  properties?: Maybe<IdentityBlockProperties>;
}

export interface IdentityBlockProperties {
  hidden: Scalars["Boolean"];
  topSpace?: Maybe<IdentitySpaceSize>;
}

export type IdentityButton = EgdsElement & {
  __typename?: "IdentityButton";
  action: IdentityAction;
  egdsElementId?: Maybe<Scalars["String"]>;
  element: EgdsButton;
  rules: Array<IdentityRule>;
};

export type IdentityCsrfAto = {
  __typename?: "IdentityCSRFAto";
  placement: Scalars["String"];
  token: Scalars["String"];
  type: IdentityAccountTakeOverType;
};

export type IdentityCaptchaArkoseMobileAppWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCaptchaArkoseMobileAppWidgetAction";
  inputKeys: Array<Scalars["String"]>;
  publicKey: Scalars["String"];
  type: IdentityAccountTakeOverType;
};

export type IdentityCaptchaSafetyNetAndroidWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCaptchaSafetyNetAndroidWidgetAction";
  inputKeys: Array<Scalars["String"]>;
  siteKey: Scalars["String"];
  type: IdentityAccountTakeOverType;
};

export type IdentityChannelType = "ANDROID" | "DESKTOP" | "IOS";

export interface IdentityClientInfoInput {
  appName: Scalars["String"];
  appVersion: Scalars["String"];
  channelType: IdentityChannelType;
  deviceOS: Scalars["String"];
  deviceType: DeviceType;
}

export type IdentityCommonWordsValidation = EgdsInputValidation & {
  __typename?: "IdentityCommonWordsValidation";
  errorMessage: Scalars["String"];
};

export type IdentityComponentLayout =
  | IdentityForgotPasswordComponentLayout
  | IdentityResetPasswordComponentLayout
  | IdentitySingleColumnComponentLayout
  | IdentitySuccessComponentLayout;

export type IdentityConsentNestedCheckBox = EgdsElement & {
  __typename?: "IdentityConsentNestedCheckBox";
  egdsElementId: Scalars["String"];
  selectAllCheckbox: EgdsBasicCheckBox;
  subCheckboxes: Array<EgdsBasicCheckBox>;
  validationRules?: Maybe<Array<EgdsInputValidation>>;
};

export type IdentityCreateAccountErrorResponse = {
  __typename?: "IdentityCreateAccountErrorResponse";
  accountTakeOverShield: IdentityAccountTakeOverShield;
  error: Scalars["String"];
  message: Scalars["String"];
};

export interface IdentityCreateAccountRequestInput {
  accountTakeOverShield: IdentityAccountTakeOverShieldInput;
  channelType?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  phoneDetails?: InputMaybe<IdentityPhoneDetailsInput>;
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
}

export type IdentityCreateAccountSuccessResponse = {
  __typename?: "IdentityCreateAccountSuccessResponse";
  accountTakeOverShield: IdentityAccountTakeOverShield;
  accountType: IdentityAccountCreationType;
  accountUpgradeStatus: IdentityAccountCreationState;
  egUserId: Scalars["String"];
};

export type IdentityCrossSiteRequestForgery = {
  __typename?: "IdentityCrossSiteRequestForgery";
  token: Scalars["String"];
};

export interface IdentityCrossSiteRequestForgeryInput {
  token: Scalars["String"];
}

export type IdentityCsrfWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCsrfWidgetAction";
  inputKeys: Array<Scalars["String"]>;
  token: Scalars["String"];
  type: IdentityAccountTakeOverType;
};

export type IdentityDeviceCheckWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityDeviceCheckWidgetAction";
  inputKeys: Array<Scalars["String"]>;
  type: IdentityAccountTakeOverType;
};

export interface IdentityErrorField {
  elementId: Scalars["String"];
  errorText: Scalars["String"];
}

export type IdentityErrorResponse = IdentityResponse & {
  __typename?: "IdentityErrorResponse";
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  errorFields: Array<IdentityErrorField>;
  updateAction?: Maybe<IdentityUpdateComponentAction>;
};

export type IdentityForbiddenInputsValidation = EgdsInputValidation & {
  __typename?: "IdentityForbiddenInputsValidation";
  errorMessage: Scalars["String"];
  inputIds: Array<Scalars["String"]>;
};

export type IdentityForgotPasswordComponentLayout = {
  __typename?: "IdentityForgotPasswordComponentLayout";
  description: EgdsSpannableText;
  emailInputField: EgdsEmailInputField;
  errorSummaryCard: EgdsStandardMessagingCard;
  heading: EgdsHeading;
  sendEmailButton: IdentityButton;
  toolbar?: Maybe<UiToolbar>;
};

export type IdentityGoogleOneTapAuthConfiguration = IdentitySocialAuthConfiguration & {
  __typename?: "IdentityGoogleOneTapAuthConfiguration";
  clientId?: Maybe<Scalars["String"]>;
};

export type IdentityGoogleOneTapMutationResponse = IdentityResponse & {
  __typename?: "IdentityGoogleOneTapMutationResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  cmsToken?: Maybe<Scalars["String"]>;
  flow?: Maybe<Scalars["String"]>;
  scenario?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
  variant?: Maybe<Scalars["String"]>;
};

export type IdentityGoogleOneTapResponse = IdentityResponse & {
  __typename?: "IdentityGoogleOneTapResponse";
  action: IdentityOneTapSubmitAction;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  configuration: IdentitySocialAuthConfiguration;
  title: Scalars["String"];
};

export type IdentityImage = EgdsElement & {
  __typename?: "IdentityImage";
  egdsElementId?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
};

export type IdentityImageGallery = EgdsElement & {
  __typename?: "IdentityImageGallery";
  egdsElementId: Scalars["String"];
  markTokenList: Array<EgdsMark>;
  placement?: Maybe<Placement>;
};

export type IdentityInfoLinkComponent = EgdsElement & {
  __typename?: "IdentityInfoLinkComponent";
  contactUs: EgdsStandardLink;
  deleteData: EgdsStandardLink;
  egdsElementId?: Maybe<Scalars["String"]>;
  traderInformation: IdentityTraderInfoLink;
};

export interface IdentityInput {
  authState?: InputMaybe<AuthenticationState>;
  duaid: Scalars["UUID"];
  expUserId?: InputMaybe<Scalars["String"]>;
  tuid?: InputMaybe<Scalars["String"]>;
}

export type IdentityLayout =
  | "FORGOT_PASSWORD"
  | "GET_RESENT_OTP_ATO"
  | "GET_SEND_OTP_ATO"
  | "GET_SET_PASSWORD_ATO"
  | "GET_SIGN_IN_ATO"
  | "GET_SIGN_IN_VERIFY_OTP_ATO"
  | "GET_SIGN_UP_VERIFY_OTP_ATO"
  | "GET_SOCIAL_LOGIN_APPLE_ATO"
  | "GET_SOCIAL_LOGIN_FACEBOOK_ATO"
  | "GET_SOCIAL_LOGIN_GOOGLE_ATO"
  | "GET_USER_DETAILS_ATO"
  | "LAUNCH_ONE_IDENTITY"
  | "RESET_PASSWORD"
  | "SIGNIN"
  | "SIGNUP";

export type IdentityLayoutSection = {
  __typename?: "IdentityLayoutSection";
  blocks: Array<IdentityBlock>;
};

export type IdentityLoginScenario = "SIGN_IN" | "SIGN_UP";

export type IdentityNoErrorRule = {
  __typename?: "IdentityNoErrorRule";
  elements: Array<Scalars["String"]>;
};

export type IdentityOneTapSelection = {
  __typename?: "IdentityOneTapSelection";
  cancelAnalytics: ClientSideAnalytics;
  clientFailureAnalytics: ClientSideAnalytics;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  emailInputId: Scalars["String"];
  googleSocialButtonId?: Maybe<Scalars["String"]>;
  inputIdsMapping?: Maybe<IdentitySignInInputIdsMapping>;
  submitAction: IdentityAction;
  submitButtonId: Scalars["String"];
};

export type IdentityOneTapSubmitAction = IdentityAction & {
  __typename?: "IdentityOneTapSubmitAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  atoActions: Array<IdentityAccountTakeOverWidgetAction>;
  clickStreamAnalytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  context: Scalars["String"];
  inputIds: Array<Scalars["String"]>;
  nonce: Scalars["String"];
  requestInputPairList: Array<IdentityRequestInputPair>;
  socialType: IdentitySocialType;
};

export type IdentityPasswordStrengthComponent = EgdsElement & {
  __typename?: "IdentityPasswordStrengthComponent";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  defaultAccessibilityMessage?: Maybe<Scalars["String"]>;
  egdsElementId?: Maybe<Scalars["String"]>;
  errorAccessibilityMessage?: Maybe<Scalars["String"]>;
  excludeValidationsList: IdentityValidationList;
  includeValidationsList: IdentityValidationList;
  passwordInput: EgdsPasswordInputField;
  progressBar: IdentityProgressBar;
};

export interface IdentityPhoneDetailsInput {
  countryCode: Scalars["String"];
  locale: Scalars["String"];
  phoneNumber: Scalars["String"];
}

export type IdentityProgressBar = {
  __typename?: "IdentityProgressBar";
  states: Array<IdentityValidationState>;
  title: Scalars["String"];
};

export type IdentityRegexCustomValidation = EgdsInputValidation & {
  __typename?: "IdentityRegexCustomValidation";
  errorMessage: Scalars["String"];
  pattern: Scalars["String"];
  successAccessibilityMessage?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type IdentityRequestInputPair = {
  __typename?: "IdentityRequestInputPair";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type IdentityResendButton = EgdsElement & {
  __typename?: "IdentityResendButton";
  action: IdentityAction;
  buttonElement: EgdsButton;
  egdsElementId?: Maybe<Scalars["String"]>;
  maxTimerInSeconds: Scalars["Int"];
  timerText: Scalars["String"];
};

export type IdentityResendPasscodeSubmitResponse = IdentityResponse & {
  __typename?: "IdentityResendPasscodeSubmitResponse";
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  updateAction?: Maybe<IdentityUpdateComponentAction>;
  updateBlock: Array<IdentityUpdateBlock>;
  updateFields: Array<IdentityUpdateSummary>;
};

export type IdentityResetPasswordComponentLayout = {
  __typename?: "IdentityResetPasswordComponentLayout";
  errorSummaryCard: EgdsStandardMessagingCard;
  heading: EgdsHeading;
  passwordInputField: EgdsPasswordInputField;
  savePasswordButton: IdentityButton;
  toolbar?: Maybe<UiToolbar>;
};

export interface IdentityResponse {
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
}

export type IdentityRetrieveAtoResponse = IdentityResponse & {
  __typename?: "IdentityRetrieveATOResponse";
  atoActions?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  status: Scalars["Boolean"];
};

export type IdentityRule = IdentityAvailabilityRule | IdentityNoErrorRule | IdentityVisibilityRule;

export type IdentitySaveCredentialsInputIdsMapping = {
  __typename?: "IdentitySaveCredentialsInputIdsMapping";
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type IdentitySession = {
  __typename?: "IdentitySession";
  domain: Scalars["String"];
  expires?: Maybe<Scalars["String"]>;
  httpOnly: Scalars["Boolean"];
  maxAge: Scalars["String"];
  name: Scalars["String"];
  path: Scalars["String"];
  secure: Scalars["Boolean"];
  value: Scalars["String"];
};

export type IdentitySessionAction = IdentityAction & {
  __typename?: "IdentitySessionAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  session: Array<IdentitySession>;
};

export type IdentitySignInInputIdsMapping = {
  __typename?: "IdentitySignInInputIdsMapping";
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type IdentitySingleColumnComponentLayout = EgdsElement & {
  __typename?: "IdentitySingleColumnComponentLayout";
  egdsElementId?: Maybe<Scalars["String"]>;
  identityOneTapSelection?: Maybe<IdentityOneTapSelection>;
  main?: Maybe<IdentityLayoutSection>;
  toolbar?: Maybe<UiToolbar>;
  top?: Maybe<IdentityLayoutSection>;
};

export interface IdentitySocialAuthConfiguration {
  clientId?: Maybe<Scalars["String"]>;
}

export type IdentitySocialButton = EgdsElement & {
  __typename?: "IdentitySocialButton";
  action: IdentitySocialSubmitAction;
  egdsElementId?: Maybe<Scalars["String"]>;
  inputIdsMapping?: Maybe<IdentitySocialSignInInputIdsMapping>;
  label: Scalars["String"];
  mark: Mark;
  type: IdentitySocialButtonType;
};

export type IdentitySocialButtonType = "PRIMARY" | "SECONDARY";

export interface IdentitySocialInput {
  nonce: Scalars["String"];
  socialType: IdentitySocialType;
  token: Scalars["String"];
  tokenType: IdentitySocialTokenType;
}

export type IdentitySocialSignInInputIdsMapping = {
  __typename?: "IdentitySocialSignInInputIdsMapping";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type IdentitySocialSubmitAction = IdentityAction & {
  __typename?: "IdentitySocialSubmitAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  atoActions: Array<IdentityAccountTakeOverWidgetAction>;
  cancelAnalytics: ClientSideAnalytics;
  clickStreamAnalytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  clientFailureAnalytics: ClientSideAnalytics;
  context: Scalars["String"];
  inputIds: Array<Scalars["String"]>;
  nonce: Scalars["String"];
  requestInputPairList: Array<IdentityRequestInputPair>;
  retrieveAtoLayout?: Maybe<Scalars["String"]>;
  socialType: IdentitySocialType;
  submitAnalytics: ClientSideAnalytics;
};

export type IdentitySocialTokenType = "Access" | "AuthCode" | "ID";

export type IdentitySocialType = "APPLE" | "FACEBOOK" | "GOOGLE";

export type IdentitySpaceSize =
  | "S0X"
  | "S0XH"
  | "S1X"
  | "S2X"
  | "S3X"
  | "S4X"
  | "S5X"
  | "S6X"
  | "S8X"
  | "S10X"
  | "S11X"
  | "S12X"
  | "S14X"
  | "S16X"
  | "S18X";

export type IdentitySubmitAction = IdentityAction & {
  __typename?: "IdentitySubmitAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  atoActions: Array<IdentityAccountTakeOverWidgetAction>;
  clickStreamAnalytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  context: Scalars["String"];
  inputIds: Array<Scalars["String"]>;
  requestInputPairList: Array<IdentityRequestInputPair>;
  retrieveAtoLayout?: Maybe<Scalars["String"]>;
  saveCredentialsInputIds?: Maybe<IdentitySaveCredentialsInputIdsMapping>;
};

export interface IdentitySubmitRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  context: Scalars["String"];
  input: Array<GraphQlPairInput>;
  socialInput?: InputMaybe<IdentitySocialInput>;
}

export type IdentitySuccessComponentLayout = {
  __typename?: "IdentitySuccessComponentLayout";
  icon: Icon;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
};

export type IdentitySuccessResponse = IdentityResponse & {
  __typename?: "IdentitySuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  identityComponentLayout?: Maybe<IdentityComponentLayout>;
  identityLoginScenario?: Maybe<IdentityLoginScenario>;
  identityRedirectAction?: Maybe<UiLinkAction>;
  identitySaveCredentials?: Maybe<Scalars["Boolean"]>;
  identitySessionAction?: Maybe<IdentitySessionAction>;
  identitySuccessType?: Maybe<IdentitySuccessType>;
  profile?: Maybe<Profile>;
};

export type IdentitySuccessType = "ONE_KEY_ONBOARDING" | "ORIGIN";

export type IdentityText = EgdsElement & {
  __typename?: "IdentityText";
  egdsElementId?: Maybe<Scalars["String"]>;
  text: EgdsText;
};

export type IdentityTraderInfoAction = IdentityAction & {
  __typename?: "IdentityTraderInfoAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionResponse: IdentityTraderInfoComponent;
  analytics: ClientSideAnalytics;
};

export type IdentityTraderInfoComponent = {
  __typename?: "IdentityTraderInfoComponent";
  contents: Array<Scalars["String"]>;
  heading: Scalars["String"];
};

export type IdentityTraderInfoLink = {
  __typename?: "IdentityTraderInfoLink";
  action: IdentityTraderInfoAction;
  label: Scalars["String"];
};

export type IdentityTrustWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityTrustWidgetAction";
  inputKeys: Array<Scalars["String"]>;
  type: IdentityAccountTakeOverType;
};

export type IdentityUpdateBlock = {
  __typename?: "IdentityUpdateBlock";
  block?: Maybe<IdentityBlock>;
  blockId: Scalars["String"];
  properties?: Maybe<IdentityBasicBlockProperties>;
};

export type IdentityUpdateComponentAction = {
  __typename?: "IdentityUpdateComponentAction";
  action: IdentityAction;
  actionForElement: Scalars["String"];
};

export type IdentityUpdateErrorSummary = IdentityErrorField & {
  __typename?: "IdentityUpdateErrorSummary";
  blockId: Scalars["String"];
  elementId: Scalars["String"];
  errorText: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
};

export type IdentityUpdateSummary = {
  __typename?: "IdentityUpdateSummary";
  blockId: Scalars["String"];
  elementId: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
  updateText: Scalars["String"];
};

export type IdentityValidationList = {
  __typename?: "IdentityValidationList";
  icon: Scalars["String"];
  title: Scalars["String"];
  validations: Array<EgdsInputValidation>;
};

export type IdentityValidationState = {
  __typename?: "IdentityValidationState";
  stateAccessibilityMessage?: Maybe<Scalars["String"]>;
  strengthIndicator: Scalars["String"];
  validationSum: Scalars["Int"];
};

export type IdentityVisibilityRule = {
  __typename?: "IdentityVisibilityRule";
  elements: Array<Scalars["String"]>;
  initialVisibility: Scalars["Boolean"];
};

export type IdleDelay = {
  __typename?: "IdleDelay";
  inMilliseconds: Scalars["Int"];
  inSeconds: Scalars["Int"];
};

export type Illustration = EgdsElement & {
  __typename?: "Illustration";
  description: Scalars["String"];
  egdsElementId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  url: Scalars["URL"];
};

export type Image = {
  __typename?: "Image";
  /** @deprecated Use description instead */
  altText?: Maybe<Scalars["String"]>;
  aspectRatio?: Maybe<AspectRatio>;
  description: Scalars["String"];
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars["URL"];
};

export type ImageUrlArgs = {
  dimensions?: InputMaybe<DimensionsInput>;
};

export type ImageAnalytics = {
  __typename?: "ImageAnalytics";
  analytics?: Maybe<ClientSideAnalytics>;
  imageId?: Maybe<Scalars["String"]>;
  subjectId?: Maybe<Scalars["Int"]>;
  trackingId?: Maybe<Scalars["String"]>;
};

export type ImportantInfoContent = EgdsBulletedList;

export type ImportantInfoDetails = {
  __typename?: "ImportantInfoDetails";
  importantInfoButton: LegalDialogLink;
  importantInfoSection?: Maybe<ImportantInfoSection>;
};

export type ImportantInfoSection = {
  __typename?: "ImportantInfoSection";
  content: ImportantInfoContent;
  title: Scalars["String"];
};

export type InboxNotification = ContextualNotification & {
  __typename?: "InboxNotification";
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  displayEndDateTime?: Maybe<NotificationApiDateTime>;
  displayStartDateTime?: Maybe<NotificationApiDateTime>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  revealActions?: Maybe<Array<NotificationAction>>;
  subType?: Maybe<NotificationSubType>;
  timeStamp?: Maybe<Scalars["String"]>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
};

export type InboxNotificationDeleteResponse = {
  __typename?: "InboxNotificationDeleteResponse";
  operationStatus: OperationStatus;
};

export type InboxNotificationUpdateResponse = {
  __typename?: "InboxNotificationUpdateResponse";
  messageType: NotificationMessageType;
  notificationId: Scalars["String"];
};

export type InboxNotificationUpdateStateResponse = {
  __typename?: "InboxNotificationUpdateStateResponse";
  notificationId: Scalars["String"];
  notificationState: NotificationState;
  operationStatus: OperationStatus;
};

export type IncentivesActionLink = {
  __typename?: "IncentivesActionLink";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated in favour of EG Clickstream. Use 'presentedAnalytics' or 'selectedAnalytics' instead. */
  analytics: ClientSideAnalytics;
  button?: Maybe<EgdsButton>;
  clickAnalytics: ClientSideAnalytics;
  impressionAnalytics: ClientSideAnalytics;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  resource: HttpUri;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesCollection = {
  __typename?: "IncentivesCollection";
  actionLink?: Maybe<IncentivesActionLink>;
  collectionBody?: Maybe<IncentivesCollectionBody>;
  collectionHeader?: Maybe<IncentivesCollectionHeader>;
  collectionItems?: Maybe<Array<IncentivesCollectionItem>>;
  egRecommendationResponseId?: Maybe<Scalars["String"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesCollectionBody =
  | CollectionCarouselHeroTilesBody
  | CollectionCarouselMediaTilesBody
  | IncentivesHeroTilesBody
  | IncentivesMediaTilesBody;

export type IncentivesCollectionError = {
  __typename?: "IncentivesCollectionError";
  action?: Maybe<IncentivesActionLink>;
  header?: Maybe<IncentivesCollectionHeader>;
  heroImage?: Maybe<CollectionHeroImage>;
};

export type IncentivesCollectionHeader = {
  __typename?: "IncentivesCollectionHeader";
  carouselCTAButton?: Maybe<IncentivesActionLink>;
  description?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  termsAndConditionsDialog?: Maybe<TermsAndConditions>;
  title: Scalars["String"];
};

export type IncentivesCollectionItem = IncentivesContentItem;

export type IncentivesCollectionSuccess = {
  __typename?: "IncentivesCollectionSuccess";
  collectionBody?: Maybe<IncentivesCollectionBody>;
  collectionItems?: Maybe<Array<IncentivesCollectionItem>>;
  egRecommendationResponseId?: Maybe<Scalars["String"]>;
  header?: Maybe<IncentivesCollectionHeader>;
  heroImage?: Maybe<CollectionHeroImage>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesCollectionView = IncentivesCollectionError | IncentivesCollectionSuccess;

export type IncentivesContentItem = {
  __typename?: "IncentivesContentItem";
  actionLink: IncentivesActionLink;
  cardSize: CardSize;
  description?: Maybe<Scalars["String"]>;
  incentivesImage: IncentivesImage;
  recommendationId?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type IncentivesHeroTile = {
  __typename?: "IncentivesHeroTile";
  action: IncentivesActionLink;
  content: IncentivesTileContent;
  image: Image;
  offers: Array<IncentivesOfferTile>;
};

export type IncentivesHeroTilesBody = {
  __typename?: "IncentivesHeroTilesBody";
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<IncentivesHeroTile>;
};

export type IncentivesImage = {
  __typename?: "IncentivesImage";
  attribution?: Maybe<IncentivesImageAttribution>;
  image: Image;
};

export type IncentivesImageAttribution = {
  __typename?: "IncentivesImageAttribution";
  dialog: IncentivesImageAttributionDialog;
  dialogTriggerAccessibility: Scalars["String"];
};

export type IncentivesImageAttributionDialog = {
  __typename?: "IncentivesImageAttributionDialog";
  closeText: Scalars["String"];
  text: Scalars["String"];
};

export type IncentivesJourney = IncentivesTiles;

export type IncentivesMedia = {
  __typename?: "IncentivesMedia";
  badges: Array<EgdsBadge>;
  gallery: EgdsGalleryCarousel;
};

export type IncentivesMediaItem = MediaItem & {
  __typename?: "IncentivesMediaItem";
  media: Image;
};

export type IncentivesMediaTile = {
  __typename?: "IncentivesMediaTile";
  action?: Maybe<IncentivesActionLink>;
  cardLink: IncentivesActionLink;
  content: IncentivesTileContent;
  media: IncentivesMedia;
  price: IncentivesMediaTilePrice;
  rating: EgdsSpannableText;
};

export type IncentivesMediaTilePrice = {
  __typename?: "IncentivesMediaTilePrice";
  badge?: Maybe<EgdsBadge>;
  propertyPrice?: Maybe<PropertyPrice>;
};

export type IncentivesMediaTilesBody = {
  __typename?: "IncentivesMediaTilesBody";
  loadMoreAction?: Maybe<CollectionPaginationAction>;
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<IncentivesMediaTile>;
};

export type IncentivesOfferTile = {
  __typename?: "IncentivesOfferTile";
  cardLink: IncentivesActionLink;
  options: Array<IncentivesOfferTileOption>;
  price: EgdsSpannableText;
};

export type IncentivesOfferTileOption = {
  __typename?: "IncentivesOfferTileOption";
  badge: EgdsStandardBadge;
  destination: EgdsGraphicText;
  info: EgdsGraphicText;
  origin: EgdsGraphicText;
  time: EgdsSpannableText;
};

export type IncentivesResultsSummary = {
  __typename?: "IncentivesResultsSummary";
  dealsDate: EgdsSpannableText;
  message?: Maybe<Scalars["String"]>;
};

export type IncentivesTileContent = {
  __typename?: "IncentivesTileContent";
  description?: Maybe<Scalars["String"]>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type IncentivesTiles = {
  __typename?: "IncentivesTiles";
  tiles: Array<IncentivesCollectionItem>;
};

export type IncludedFeeFooter = {
  __typename?: "IncludedFeeFooter";
  description: Scalars["String"];
  title: Scalars["String"];
};

export type IndicatorStep = {
  __typename?: "IndicatorStep";
  a11yLabel: Scalars["String"];
  changeSelection?: Maybe<StepIndicatorChangeSelection>;
  currentStep: Scalars["Boolean"];
  label: StepIndicatorLabel;
};

export type InlineNotification = ContextualNotification & {
  __typename?: "InlineNotification";
  attributes?: Maybe<Array<NotificationAttribute>>;
  backgroundImage?: Maybe<Image>;
  bannerContainer?: Maybe<NotificationContainer>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  customQrCode?: Maybe<NotificationQrCode>;
  dismiss?: Maybe<NotificationDismiss>;
  featuredImages?: Maybe<Array<Image>>;
  form?: Maybe<NotificationForm>;
  icon?: Maybe<Icon>;
  legalText?: Maybe<NotificationPhrase>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  notificationLocation?: Maybe<NotificationLocationOnPage>;
  qrCode?: Maybe<Image>;
  revealActions?: Maybe<Array<NotificationAction>>;
  subBody?: Maybe<Array<BodyElements>>;
  switch?: Maybe<EgdsStandardSwitch>;
  /** @deprecated Use bannerContainer instead to define banner theme */
  theme?: Maybe<NotificationTheme>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
};

export type InlinePriceLineText = {
  __typename?: "InlinePriceLineText";
  inlineItems: Array<PriceLineText>;
};

export type InquiryFormDialogAction = {
  __typename?: "InquiryFormDialogAction";
  dialogAnalytics?: Maybe<ClientSideAnalytics>;
  inquiryFormModal?: Maybe<InquiryFormLayout>;
};

export interface InquiryFormInput {
  petsIncluded?: InputMaybe<Scalars["Boolean"]>;
  primaryPropertyCriteriaInput?: InputMaybe<PrimaryPropertyCriteriaInput>;
}

export type InquiryFormInputElement =
  | EgdsBasicCheckBox
  | EgdsBasicSelect
  | EgdsSearchFormTravelersField
  | EgdsTextAreaInputField
  | EgdsTextInputField
  | InquiryPhoneInputField;

export type InquiryFormLayout = {
  __typename?: "InquiryFormLayout";
  analytics?: Maybe<ClientSideAnalytics>;
  datePicker?: Maybe<EgdsDateRangePicker>;
  disclaimer?: Maybe<Array<EgdsText>>;
  featuredMessage?: Maybe<EgdsStandardMessagingCard>;
  formInput?: Maybe<Array<InquiryFormInputElement>>;
  header?: Maybe<Scalars["String"]>;
  submitButton?: Maybe<UiPrimaryButton>;
};

export type InquiryPhoneInputField = {
  __typename?: "InquiryPhoneInputField";
  countryCode: EgdsBasicSelect;
  phoneNumber: EgdsTextInputField;
};

export type InquiryProminence = "HIGH" | "LOW" | "MEDIUM";

export interface InquiryRequestInput {
  inquirerPhoneCountryCode?: InputMaybe<Scalars["String"]>;
  inquirerPhoneNumber?: InputMaybe<Scalars["String"]>;
  message: Scalars["String"];
  optInForMarketing?: InputMaybe<Scalars["Boolean"]>;
  petsIncluded?: InputMaybe<Scalars["Boolean"]>;
  primaryPropertyCriteriaInput: PrimaryPropertyCriteriaInput;
}

export type InquiryResponse = {
  __typename?: "InquiryResponse";
  analytics?: Maybe<ClientSideAnalytics>;
  body?: Maybe<Array<Scalars["String"]>>;
  doneButton?: Maybe<UiPrimaryButton>;
  /** @deprecated Deprecated in favour of title */
  header?: Maybe<Scalars["String"]>;
  title?: Maybe<EgdsGraphicText>;
};

export type InsightsAirport = {
  __typename?: "InsightsAirport";
  code: Scalars["String"];
  name: Scalars["String"];
};

export type InsightsDestination = {
  __typename?: "InsightsDestination";
  regionId: Scalars["String"];
};

export interface InsightsLocationInput {
  airportCode?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
}

export type InstallmentPlan = {
  __typename?: "InstallmentPlan";
  totalPrice: Scalars["String"];
};

export type InstallmentPlanAttribute = {
  __typename?: "InstallmentPlanAttribute";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type InstallmentPlanDetails = AffirmInstallmentPlanDetails;

export type InstallmentPlanLink = {
  __typename?: "InstallmentPlanLink";
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
};

export type InstallmentPlanPlacement = {
  __typename?: "InstallmentPlanPlacement";
  subTextRequired: Scalars["Boolean"];
  totalPrice: Scalars["String"];
};

export type InstallmentPlanResponse = {
  __typename?: "InstallmentPlanResponse";
  impressionAnalytics: ClientSideAnalytics;
  installmentPlanDetails: InstallmentPlanDetails;
};

export type InsurTechComponentPlacement = {
  __typename?: "InsurTechComponentPlacement";
  placementContext: InsurTechComponentPlacementContext;
};

export type InsurTechComponentPlacementContext = {
  __typename?: "InsurTechComponentPlacementContext";
  lob: LineOfBusinessDomain;
  packageType?: Maybe<PackageType>;
  path: PageLocation;
  placement?: Maybe<InsurTechPagePlacementType>;
};

export interface InsurTechComponentPlacementContextInput {
  lob: LineOfBusinessDomain;
  packageType?: InputMaybe<PackageType>;
  path: PageLocation;
  placement?: InputMaybe<InsurTechPagePlacementType>;
}

export interface InsurTechComponentPlacementInput {
  placementContext: InsurTechComponentPlacementContextInput;
}

export type InsurTechDialogActionType = "CLOSE" | "OPEN" | "OPEN_QUERY";

export interface InsurTechDialogTriggerAction {
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
}

export type InsurTechDialogTriggerClickAction = InsurTechDialogTriggerAction & {
  __typename?: "InsurTechDialogTriggerClickAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type InsurTechExperienceDialogTrigger = EgdsButton & {
  __typename?: "InsurTechExperienceDialogTrigger";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: InsurTechDialogTriggerAction;
  dialogAction: InsurTechDialogActionType;
  dialogId: Scalars["String"];
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  triggerType: InsurTechExperienceDialogTriggerType;
};

export type InsurTechExperienceDialogTriggerType = "BUTTON" | "LINK";

export type InsurTechPagePlacementType = "LISTING";

export type InsuranceAdditionalInfo = {
  __typename?: "InsuranceAdditionalInfo";
  infoDialog?: Maybe<InsuranceInfoDialog>;
};

export type InsuranceBadge = {
  __typename?: "InsuranceBadge";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
};

export type InsuranceBanner = {
  __typename?: "InsuranceBanner";
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<InsuranceLink>;
  message: Scalars["String"];
};

export type InsuranceBenefit = {
  __typename?: "InsuranceBenefit";
  details?: Maybe<InsurancePhrase>;
  header?: Maybe<InsurancePhrase>;
  solicitations: InsuranceSolicitationList;
};

export interface InsuranceBenefitDetailsQueryRequestInput {
  benefitId: Scalars["String"];
  isEmailSrc?: InputMaybe<Scalars["Boolean"]>;
  lob?: InputMaybe<Scalars["String"]>;
  orderNumber?: InputMaybe<Scalars["String"]>;
  tripId?: InputMaybe<Scalars["String"]>;
}

export type InsuranceBenefitDetailsQueryResponse = {
  __typename?: "InsuranceBenefitDetailsQueryResponse";
  attributes: TripCareAttributes;
  header: TripCareHeader;
  sections: Array<TripCareLayoutComponent>;
};

export interface InsuranceBookingCancellationRequestInput {
  orderId: Scalars["String"];
  orderLineId: Scalars["String"];
}

export type InsuranceBookingCancellationResponse = {
  __typename?: "InsuranceBookingCancellationResponse";
  errorCode?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type InsuranceBookingOverviewErrorResponse = {
  __typename?: "InsuranceBookingOverviewErrorResponse";
  errorMessages: Array<TripCareError>;
};

export interface InsuranceBookingOverviewRequestInput {
  lob?: InputMaybe<Scalars["String"]>;
  orderNumber?: InputMaybe<Scalars["String"]>;
  tripId?: InputMaybe<Scalars["String"]>;
}

export type InsuranceBookingOverviewResponse = {
  __typename?: "InsuranceBookingOverviewResponse";
  attributes: TripCareAttributes;
  header: TripCareHeader;
  insuranceOverview: TripCareSectionComponent;
};

export interface InsuranceClaimDetailsRequestInput {
  orderNumber?: InputMaybe<Scalars["String"]>;
}

export type InsuranceClaimDetailsResponse = {
  __typename?: "InsuranceClaimDetailsResponse";
  attributes: TripCareAttributes;
  header: TripCareHeader;
  sections: Array<TripCareLayoutComponent>;
};

export type InsuranceCountry = {
  __typename?: "InsuranceCountry";
  code: Scalars["String"];
  defaultLanguage?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  regions?: Maybe<Array<InsuranceRegion>>;
};

export type InsuranceCriteria = {
  __typename?: "InsuranceCriteria";
  freePmpCustomerPlacement?: Maybe<Scalars["String"]>;
  insuranceOfferToken?: Maybe<Scalars["String"]>;
  selectedProductId?: Maybe<Scalars["String"]>;
};

export interface InsuranceCriteriaInput {
  freePmpCustomerPlacement?: InputMaybe<Scalars["String"]>;
  insuranceOfferToken?: InputMaybe<Scalars["String"]>;
  selectedProductId?: InputMaybe<Scalars["String"]>;
}

export type InsuranceDetailPrimingCard = {
  __typename?: "InsuranceDetailPrimingCard";
  footer?: Maybe<InsuranceFooter>;
  header?: Maybe<InsuranceHeader>;
  products: Array<InsurancePrimingProduct>;
};

export interface InsuranceDetailPrimingForCarInput {
  carOfferToken: Scalars["String"];
  continuationContextualId?: InputMaybe<Scalars["String"]>;
  dropOffLocation?: InputMaybe<InsuranceLocationInput>;
  pickUpLocation: InsuranceLocationInput;
  rewardPointsSelection?: InputMaybe<InsuranceRewardsPointSelectionEnum>;
  selectedAccessories?: InputMaybe<Array<Scalars["String"]>>;
}

export interface InsuranceDetailPrimingRequestInput {
  carRequest: InsuranceDetailPrimingForCarInput;
}

export type InsuranceDialog = {
  __typename?: "InsuranceDialog";
  buttonPrimary: InsuranceDialogButton;
  header?: Maybe<InsuranceHeader>;
  id?: Maybe<Scalars["String"]>;
  texts: Array<Scalars["String"]>;
  type: InsuranceMessageType;
};

export type InsuranceDialogButton = {
  __typename?: "InsuranceDialogButton";
  text: Scalars["String"];
};

export type InsuranceDialogToggle = {
  __typename?: "InsuranceDialogToggle";
  analytics: ClientSideAnalytics;
  toggleText: Scalars["String"];
};

export interface InsuranceFileAClaimRequestInput {
  benefitId: Scalars["String"];
  isEmailSrc?: InputMaybe<Scalars["Boolean"]>;
  lob?: InputMaybe<Scalars["String"]>;
  orderNumber?: InputMaybe<Scalars["String"]>;
  tripId?: InputMaybe<Scalars["String"]>;
}

export type InsuranceFileAClaimResponse = {
  __typename?: "InsuranceFileAClaimResponse";
  header: TripCareHeader;
  sections: Array<TripCareLayoutComponent>;
};

export type InsuranceFooter = {
  __typename?: "InsuranceFooter";
  text: Scalars["String"];
};

export type InsuranceHeader = {
  __typename?: "InsuranceHeader";
  badge?: Maybe<InsuranceBadge>;
  icon?: Maybe<Icon>;
  illustration?: Maybe<Illustration>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type InsuranceHeaderOld = {
  __typename?: "InsuranceHeaderOld";
  recommended?: Maybe<Scalars["String"]>;
  title?: Maybe<InsurancePhrase>;
};

export type InsuranceInfoDialog = {
  __typename?: "InsuranceInfoDialog";
  dialog: InsuranceDialog;
  toggle: InsuranceDialogToggle;
};

export type InsuranceLanguageChangeOption = {
  __typename?: "InsuranceLanguageChangeOption";
  label: Scalars["String"];
  languages: Array<InsuranceLanguageModuleContent>;
};

export type InsuranceLanguageModuleContent = {
  __typename?: "InsuranceLanguageModuleContent";
  language: Scalars["String"];
  locale: Scalars["String"];
};

export type InsuranceLink = {
  __typename?: "InsuranceLink";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"];
  url: HttpUri;
};

export type InsuranceListLayout = "SINGLE_COLUMN";

export type InsuranceListType = "ORDERED" | "UNORDERED";

export type InsuranceListTypeOld = "ICON" | "NUMBERED";

export type InsuranceListUiConfigurations = {
  __typename?: "InsuranceListUIConfigurations";
  icon?: Maybe<Icon>;
  layout?: Maybe<InsuranceListLayout>;
  sublistUIConfigurations?: Maybe<InsuranceSublistUiConfigurations>;
  type?: Maybe<InsuranceListType>;
};

export interface InsuranceLocationInput {
  regionId: Scalars["String"];
  searchTerm: Scalars["String"];
}

export type InsuranceMessage = {
  __typename?: "InsuranceMessage";
  content: Scalars["String"];
  icon?: Maybe<Icon>;
  type: InsuranceMessageType;
};

export type InsuranceMessageType = "ERROR" | "INFO" | "WARNING";

export type InsuranceModule = {
  __typename?: "InsuranceModule";
  banner?: Maybe<InsuranceBanner>;
  disclaimer?: Maybe<InsuranceParagraph>;
  disclosure?: Maybe<InsuranceParagraph>;
  header: InsuranceHeader;
  insuranceLanguageChangeOption?: Maybe<InsuranceLanguageChangeOption>;
  insuranceUpdateDetails?: Maybe<InsuranceUpdateDetails>;
  messages?: Maybe<Array<InsuranceMessage>>;
  offers?: Maybe<Array<InsuranceOffer>>;
  residencyModule?: Maybe<InsuranceResidencyModule>;
  subHeaders?: Maybe<Array<InsuranceHeader>>;
  updateRequestId: Scalars["String"];
};

export type InsuranceMutationStatus = "FAILED" | "SUCCESS";

export type InsuranceMutationType = "ADD_INSURANCE" | "REMOVE_INSURANCE";

export type InsuranceOffer = {
  __typename?: "InsuranceOffer";
  declineOfferCard?: Maybe<InsuranceSelectionCard>;
  forcedChoiceText?: Maybe<Scalars["String"]>;
  header?: Maybe<InsuranceHeader>;
  options: Array<InsuranceOption>;
  orText?: Maybe<Scalars["String"]>;
  selectionIntimationMessage?: Maybe<Scalars["String"]>;
  termsAndConditionsLink?: Maybe<InsuranceLink>;
};

export type InsuranceOfferOld = {
  __typename?: "InsuranceOfferOld";
  label?: Maybe<InsurancePhrase>;
  offerSelectionMessage: InsuranceOptionSelectedMessage;
  offerTypeId?: Maybe<Scalars["Int"]>;
  price?: Maybe<Money>;
  priceBreakdown?: Maybe<InsurancePhrase>;
};

export type InsuranceOption = {
  __typename?: "InsuranceOption";
  acceptOptionCard: InsuranceSelectionCard;
  insuranceProduct?: Maybe<InsuranceProduct>;
  testimonial?: Maybe<InsuranceTestimonial>;
};

export type InsuranceOptionSelectedMessage = {
  __typename?: "InsuranceOptionSelectedMessage";
  message?: Maybe<InsurancePhrase>;
  reconsider?: Maybe<Scalars["String"]>;
};

export type InsuranceOptions = {
  __typename?: "InsuranceOptions";
  errorLabel: InsurancePhrase;
  offers?: Maybe<Array<InsuranceOfferOld>>;
  selectionLabel: InsurancePhrase;
};

export type InsuranceParagraph = {
  __typename?: "InsuranceParagraph";
  icon?: Maybe<Icon>;
  link?: Maybe<InsuranceLink>;
  message: Scalars["String"];
};

export type InsurancePhrase = {
  __typename?: "InsurancePhrase";
  completeText?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<InsurancePhrasePart>>;
};

export type InsurancePhraseLinkNode = InsurancePhrasePart & {
  __typename?: "InsurancePhraseLinkNode";
  link: Scalars["URL"];
  styles?: Maybe<Array<InsurancePhrasePartStyle>>;
  text?: Maybe<Scalars["String"]>;
};

export interface InsurancePhrasePart {
  styles?: Maybe<Array<InsurancePhrasePartStyle>>;
  text?: Maybe<Scalars["String"]>;
}

export type InsurancePhrasePartStyle = "COLOR_GREEN" | "COLOR_RED" | "EMPHASIS" | "IMPORTANT" | "STANDARD";

export type InsurancePhraseTextNode = InsurancePhrasePart & {
  __typename?: "InsurancePhraseTextNode";
  styles?: Maybe<Array<InsurancePhrasePartStyle>>;
  text?: Maybe<Scalars["String"]>;
};

export type InsurancePrice = {
  __typename?: "InsurancePrice";
  subtextFirst?: Maybe<Scalars["String"]>;
  subtextStandard?: Maybe<Array<Scalars["String"]>>;
  value: Scalars["String"];
};

export type InsurancePrimingProduct = {
  __typename?: "InsurancePrimingProduct";
  additionalInfo?: Maybe<Array<InsuranceAdditionalInfo>>;
  divider?: Maybe<Scalars["Boolean"]>;
  footer?: Maybe<InsuranceFooter>;
  header?: Maybe<InsuranceHeader>;
  price?: Maybe<InsurancePrice>;
  solicitations: Array<InsuranceSolicitation>;
};

export type InsuranceProduct = {
  __typename?: "InsuranceProduct";
  header?: Maybe<Scalars["String"]>;
  solicitations: Array<InsuranceSolicitation>;
  solicitationsUIConfigurations?: Maybe<InsuranceListUiConfigurations>;
  termsAndConditionsLink: InsuranceLink;
};

export type InsuranceRegion = {
  __typename?: "InsuranceRegion";
  code: Scalars["String"];
  name: Scalars["String"];
};

export type InsuranceResidencyModule = {
  __typename?: "InsuranceResidencyModule";
  countries: Array<InsuranceCountry>;
  countryCode?: Maybe<Scalars["String"]>;
  countryLabel: Scalars["String"];
  header: InsuranceParagraph;
  provinceCode?: Maybe<Scalars["String"]>;
  provinceLabel: Scalars["String"];
  residencyNotAvailableMessage?: Maybe<InsuranceParagraph>;
  toolTip?: Maybe<InsuranceToolTipWithDialogBox>;
};

export type InsuranceRewardsPointSelectionEnum = "APPLY_REWARD_POINTS" | "DO_NOT_APPLY_REWARD_POINTS";

export type InsuranceSelectionCard = {
  __typename?: "InsuranceSelectionCard";
  badge?: Maybe<InsuranceBadge>;
  description: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  insuranceProduct?: Maybe<InsuranceProduct>;
  messageOnSelectionFail: InsuranceDialog;
  messageOnSelectionSuccess: InsuranceToast;
  price?: Maybe<InsurancePrice>;
  selected?: Maybe<Scalars["Boolean"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  subDescription?: Maybe<Scalars["String"]>;
};

export interface InsuranceSelectionDetailsInput {
  existingInsuranceProductIds?: InputMaybe<Array<Scalars["String"]>>;
}

export interface InsuranceShoppingRequestInput {
  countryCode?: InputMaybe<Scalars["String"]>;
  insuranceSelectionDetails?: InputMaybe<InsuranceSelectionDetailsInput>;
  regionCode?: InputMaybe<Scalars["String"]>;
  tripId: Scalars["UUID"];
  versionHash?: InputMaybe<Scalars["String"]>;
}

export type InsuranceShoppingResponse = {
  __typename?: "InsuranceShoppingResponse";
  insuranceModules: Array<LocalizedInsuranceModule>;
};

export type InsuranceShoppingResponseOld = {
  __typename?: "InsuranceShoppingResponseOld";
  benefit?: Maybe<InsuranceBenefit>;
  header?: Maybe<InsuranceHeaderOld>;
  options?: Maybe<InsuranceOptions>;
  searchId?: Maybe<Scalars["String"]>;
  socialProof?: Maybe<InsuranceSocialProof>;
  urgency?: Maybe<InsurancePhrase>;
};

export type InsuranceSocialProof = {
  __typename?: "InsuranceSocialProof";
  dynamicSocialProof: InsurancePhrase;
  testimonial: InsuranceTestimonialOld;
};

export type InsuranceSolicitation = {
  __typename?: "InsuranceSolicitation";
  details?: Maybe<Array<Scalars["String"]>>;
  sentence: Scalars["String"];
};

export type InsuranceSolicitationList = {
  __typename?: "InsuranceSolicitationList";
  solicitations: Array<InsuranceSolicitationOld>;
  type: InsuranceListTypeOld;
};

export type InsuranceSolicitationOld = {
  __typename?: "InsuranceSolicitationOld";
  description?: Maybe<InsurancePhrase>;
  title: InsurancePhrase;
};

export type InsuranceSublistUiConfigurations = {
  __typename?: "InsuranceSublistUIConfigurations";
  type?: Maybe<InsuranceListType>;
};

export type InsuranceSwitchToggleAction = FlightsClientAction & {
  __typename?: "InsuranceSwitchToggleAction";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  checkedAccessibilityAnnouncement?: Maybe<Scalars["String"]>;
  insuranceCriteria?: Maybe<InsuranceCriteria>;
  jcid?: Maybe<Scalars["String"]>;
  uncheckedAccessibilityAnnouncement?: Maybe<Scalars["String"]>;
};

export type InsuranceTestimonial = {
  __typename?: "InsuranceTestimonial";
  author: Scalars["String"];
  text: Scalars["String"];
};

export type InsuranceTestimonialOld = {
  __typename?: "InsuranceTestimonialOld";
  name: InsurancePhrase;
  testimonial: InsurancePhrase;
};

export type InsuranceToast = {
  __typename?: "InsuranceToast";
  text: Scalars["String"];
};

export type InsuranceToolTipWithDialogBox = {
  __typename?: "InsuranceToolTipWithDialogBox";
  content: InsuranceDialog;
  label: Scalars["String"];
};

export interface InsuranceTripMutationRequestInput {
  action: InsuranceMutationType;
  insuranceSearchId?: InputMaybe<Scalars["String"]>;
  offerTypeId?: InputMaybe<Scalars["Int"]>;
}

export type InsuranceTripMutationResponse = {
  __typename?: "InsuranceTripMutationResponse";
  message?: Maybe<InsurancePhrase>;
  status: InsuranceMutationStatus;
};

export type InsuranceUpdateDetails = {
  __typename?: "InsuranceUpdateDetails";
  removedInsuranceProductIds?: Maybe<Array<Scalars["String"]>>;
};

export interface InsurtechAction {
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
}

export type InsurtechActionDialogFooter = {
  __typename?: "InsurtechActionDialogFooter";
  actions: Array<InsurtechActionElement>;
};

export type InsurtechActionElement = EgdsStandardLink | UiPrimaryButton;

export interface InsurtechAddTripRequestInput {
  offerId: Scalars["String"];
  price: Scalars["String"];
  responseId: Scalars["String"];
  tripId: Scalars["String"];
}

export type InsurtechBanner = {
  __typename?: "InsurtechBanner";
  banner: UiBanner;
};

export type InsurtechBasicCardSection = InsurtechCardSection & {
  __typename?: "InsurtechBasicCardSection";
  body: Array<InsurtechPrimingCardElement>;
  content: Array<InsurtechCardElement>;
};

export type InsurtechBenefitDialogContent = {
  __typename?: "InsurtechBenefitDialogContent";
  benefitSection: InsurtechBenefitSection;
  disclaimer?: Maybe<Scalars["String"]>;
  link?: Maybe<EgdsStandardLink>;
};

export type InsurtechBenefitDialogHeading = {
  __typename?: "InsurtechBenefitDialogHeading";
  graphic?: Maybe<UiGraphic>;
  header: Scalars["String"];
  subHeader?: Maybe<InsurtechSpannableText>;
};

export type InsurtechBenefitSection = {
  __typename?: "InsurtechBenefitSection";
  dialogTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
  heading?: Maybe<Scalars["String"]>;
  list: InsurtechTextList;
};

export type InsurtechBreakupLineItem = {
  __typename?: "InsurtechBreakupLineItem";
  graphic?: Maybe<UiGraphic>;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
};

export type InsurtechBreakupSection = {
  __typename?: "InsurtechBreakupSection";
  content: Array<InsurtechBreakupLineItem>;
  title?: Maybe<Scalars["String"]>;
};

export interface InsurtechButton {
  action: InsurtechAction;
}

export type InsurtechCardElement = EgdsStylizedText | EgdsTextIconList | InsurtechDialogTrigger;

export interface InsurtechCardSection {
  /** @deprecated use content field instead */
  body: Array<InsurtechPrimingCardElement>;
  content: Array<InsurtechCardElement>;
}

export type InsurtechCheckBoxWrapper = {
  __typename?: "InsurtechCheckBoxWrapper";
  action: InsurtechAction;
  checkBox: EgdsCheckBox;
  price?: Maybe<EgdsPriceLockup>;
};

export type InsurtechCheckboxAction = InsurtechAction & {
  __typename?: "InsurtechCheckboxAction";
  accessibility?: Maybe<Scalars["String"]>;
  addInsuranceMessage?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  insuranceOfferToken?: Maybe<Scalars["String"]>;
  removeInsuranceMessage?: Maybe<Scalars["String"]>;
};

export type InsurtechClickstreamAnalyticsData = {
  __typename?: "InsurtechClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]>;
  eventVersion?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["AnalyticsPayload"]>;
  referenceId?: Maybe<Scalars["String"]>;
};

export type InsurtechClientId = "CAR_INFO_SITE" | "EG_CHECKOUT" | "FCR_CHECKOUT";

export type InsurtechDialog = {
  __typename?: "InsurtechDialog";
  dialog: EgdsDialog;
  dialogContent: InsurtechDialogContent;
  dialogFooter?: Maybe<InsurtechDialogFooter>;
  dialogHeading?: Maybe<InsurtechDialogHeading>;
  dialogId: Scalars["String"];
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
};

export type InsurtechDialogBasicHeading = {
  __typename?: "InsurtechDialogBasicHeading";
  heading: Scalars["String"];
};

export type InsurtechDialogContent =
  | InsurtechBenefitDialogContent
  | InsurtechHeadingTextDialog
  | InsurtechLearnMoreDialogContent
  | InsurtechTextDialog;

export type InsurtechDialogFooter = InsurtechActionDialogFooter | InsurtechSelectionButtonFooter;

export type InsurtechDialogHeading = InsurtechBenefitDialogHeading | InsurtechDialogBasicHeading;

export type InsurtechDialogTrigger = {
  __typename?: "InsurtechDialogTrigger";
  action: InsurtechLink;
  dialog: EgdsDialog;
  dialogContent: InsurtechDialogContent;
  dialogId: Scalars["String"];
};

export type InsurtechDialogTriggerAction = InsurtechAction & {
  __typename?: "InsurtechDialogTriggerAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type InsurtechDomainInfo = {
  __typename?: "InsurtechDomainInfo";
  name: Scalars["String"];
  refId: Scalars["String"];
};

export type InsurtechElement =
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsTextIconList
  | EgdsUnorderedList
  | InsurtechCheckBoxWrapper
  | InsurtechLink
  | InsurtechLinkList
  | InsurtechNotice
  | InsurtechRadioGroupWrapper
  | InsurtechSpannableText
  | InsurtechTextList
  | UiBanner;

export interface InsurtechFooterSection {
  label: Scalars["String"];
}

export type InsurtechGenericAction = InsurtechAction & {
  __typename?: "InsurtechGenericAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionType: InsurtechGenericActionType;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type InsurtechGenericActionType = "REDIRECT_CHECKOUT_URL";

export type InsurtechHeadingTextDialog = {
  __typename?: "InsurtechHeadingTextDialog";
  content: Array<Scalars["String"]>;
  heading: Scalars["String"];
};

export type InsurtechIconListItem = InsurtechListItem & {
  __typename?: "InsurtechIconListItem";
  content: Array<EgdsText>;
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  icon: Icon;
  label: Scalars["String"];
  subLabel?: Maybe<Scalars["String"]>;
};

export type InsurtechLearnMoreDialogContent = {
  __typename?: "InsurtechLearnMoreDialogContent";
  content: Array<InsurtechTextElement>;
  graphic: UiGraphic;
  heading: Scalars["String"];
};

export type InsurtechLink = {
  __typename?: "InsurtechLink";
  accessibility?: Maybe<Scalars["String"]>;
  action: InsurtechAction;
  icon?: Maybe<Icon>;
  linkType: InsurtechLinkType;
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export type InsurtechLinkList = {
  __typename?: "InsurtechLinkList";
  links: Array<InsurtechLink>;
};

export type InsurtechLinkType = "MORE_INFO" | "STANDARD_LINK";

export interface InsurtechListItem {
  content: Array<EgdsText>;
  label: Scalars["String"];
}

export type InsurtechMessagingCard = {
  __typename?: "InsurtechMessagingCard";
  actions: InsurtechLinkList;
  egClickstreamAnalyticsData?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  errorMessage?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type InsurtechModule = {
  __typename?: "InsurtechModule";
  badge?: Maybe<EgdsBadge>;
  contentSections: Array<InsurtechSection>;
  heading: Scalars["String"];
  residencySection: Array<InsurtechElement>;
};

export type InsurtechModuleHeading = {
  __typename?: "InsurtechModuleHeading";
  badge?: Maybe<EgdsBadge>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"];
  subHeading?: Maybe<Scalars["String"]>;
};

export type InsurtechMutationStatus = "FAILED" | "SUCCESS";

export type InsurtechNotice = {
  __typename?: "InsurtechNotice";
  content: InsurtechSpannableText;
  icon: Icon;
};

export type InsurtechOfferState = "CHANGED_OFFER" | "NOT_SELECTED" | "UNCHANGED_OFFER";

export type InsurtechPageLevelInfo = {
  __typename?: "InsurtechPageLevelInfo";
  banners?: Maybe<Array<InsurtechBanner>>;
  dialogs?: Maybe<Array<InsurtechDialog>>;
  insurtechContinuationToken?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<UiToolbar>;
};

export type InsurtechPricePresentation = {
  __typename?: "InsurtechPricePresentation";
  substring: Scalars["String"];
  text: Scalars["String"];
};

export type InsurtechPriceRadioButton = InsurtechRadioButton & {
  __typename?: "InsurtechPriceRadioButton";
  item: EgdsRadioButton;
  price?: Maybe<InsurtechPricePresentation>;
};

export type InsurtechPriceSummary = {
  __typename?: "InsurtechPriceSummary";
  description?: Maybe<Array<Scalars["String"]>>;
  disclaimer?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  price?: Maybe<InsurtechSpannableText>;
  primaryAction: InsurtechButton;
  secondaryAction: InsurtechButton;
};

export type InsurtechPrimaryButton = InsurtechButton & {
  __typename?: "InsurtechPrimaryButton";
  action: InsurtechAction;
  button: UiPrimaryButton;
};

export type InsurtechPrimaryProductDetails = {
  __typename?: "InsurtechPrimaryProductDetails";
  /** @deprecated Replaced by productSections */
  body?: Maybe<Array<InsurtechBreakupLineItem>>;
  expando?: Maybe<EgdsExpandoCard>;
  /** @deprecated Replaced by productSections */
  footer?: Maybe<Array<InsurtechBreakupLineItem>>;
  header?: Maybe<EgdsBasicSectionHeading>;
  /** @deprecated Replaced by header and productSummary */
  heading: EgdsImageCard;
  productSections?: Maybe<Array<InsurtechBreakupSection>>;
  productSummary?: Maybe<EgdsImageCard>;
};

export type InsurtechPrimingCard = {
  __typename?: "InsurtechPrimingCard";
  description?: Maybe<Scalars["String"]>;
  displayAnalytics?: Maybe<Array<EgClickstreamEvent>>;
  evaluatedExperiments?: Maybe<Array<Experiment>>;
  graphic?: Maybe<UiGraphic>;
  header?: Maybe<EgdsHeading>;
  /** @deprecated deprecate heading use header instead */
  heading?: Maybe<Scalars["String"]>;
  viewType: InsurtechPrimingCardViewType;
};

export type InsurtechPrimingCardElement = EgdsStylizedText | EgdsTextIconList | InsurtechDialogTrigger;

export type InsurtechPrimingCardListing = {
  __typename?: "InsurtechPrimingCardListing";
  placement: InsurTechComponentPlacement;
};

export interface InsurtechPrimingCardRequestInput {
  offer: OfferIdentityInput;
}

export type InsurtechPrimingCardResponse = {
  __typename?: "InsurtechPrimingCardResponse";
  body: Array<InsurtechCardSection>;
  footer: Scalars["String"];
  heading: EgdsHeading;
  illustration?: Maybe<UiGraphic>;
};

export type InsurtechPrimingCardViewType = "BASIC_PRIMING_CARD";

export type InsurtechPrimingQuery = {
  __typename?: "InsurtechPrimingQuery";
  card?: Maybe<InsurtechPrimingCard>;
};

export type InsurtechPrimingQueryCardArgs = {
  placement: InsurTechComponentPlacementInput;
};

export type InsurtechProductCarousel = {
  __typename?: "InsurtechProductCarousel";
  carousel: EgdsCarousel;
  heading?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<InsurtechProductCarouselCard>>;
};

export type InsurtechProductCarouselCard = InsurtechStandardMessagingCard;

export type InsurtechProductCollection = {
  __typename?: "InsurtechProductCollection";
  carousel?: Maybe<InsurtechProductCarousel>;
};

export interface InsurtechRadioButton {
  item: EgdsRadioButton;
}

export type InsurtechRadioGroup = {
  __typename?: "InsurtechRadioGroup";
  options: Array<InsurtechRadioButton>;
  radioGroup: EgdsRadioGroup;
};

export type InsurtechRadioGroupWrapper = InsurtechFooterSection & {
  __typename?: "InsurtechRadioGroupWrapper";
  action: InsurtechAction;
  coveredOfferTokens?: Maybe<Array<OfferIdentity>>;
  insurtechRadioGroup: InsurtechRadioGroup;
  label: Scalars["String"];
  /** @deprecated Deprecated this in favor of using InsurtechRadioGroup */
  radioButtonGroup: EgdsRadioGroup;
  required?: Maybe<Scalars["Boolean"]>;
  selectedOffer?: Maybe<OfferIdentity>;
  state?: Maybe<InsurtechOfferState>;
  targetOfferIdentity: OfferIdentity;
};

export interface InsurtechRemoveTripRequestInput {
  offerId: Scalars["String"];
  price: Scalars["String"];
  tripId: Scalars["String"];
}

export type InsurtechSecondaryButton = InsurtechButton & {
  __typename?: "InsurtechSecondaryButton";
  action: InsurtechAction;
  button: UiSecondaryButton;
};

export type InsurtechSection = {
  __typename?: "InsurtechSection";
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  elements: Array<InsurtechElement>;
  heading?: Maybe<Scalars["String"]>;
};

export type InsurtechSelectionButtonFooter = {
  __typename?: "InsurtechSelectionButtonFooter";
  selectionSection: InsurtechSelectionButtonSection;
};

export type InsurtechSelectionButtonSection = {
  __typename?: "InsurtechSelectionButtonSection";
  action: InsurtechButton;
  price: InsurtechPricePresentation;
};

export type InsurtechSetResidencyAction = InsurtechAction & {
  __typename?: "InsurtechSetResidencyAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  /** @deprecated deprecate this to use residencyState instead */
  isResidencyApplied?: Maybe<Scalars["Boolean"]>;
  residencyState?: Maybe<ResidencyState>;
  value?: Maybe<ResidencyDetails>;
};

export interface InsurtechShoppingCriteriaInput {
  identifier: InsurtechShoppingIdentifierInput;
  insuranceContinuationToken?: InputMaybe<Scalars["String"]>;
  residencyDetails?: InputMaybe<ResidencyDetailsInput>;
}

export interface InsurtechShoppingIdentifierInput {
  type: InsurtechShoppingIdentifierType;
  value: Scalars["String"];
}

export type InsurtechShoppingIdentifierType =
  | "CHECKOUT_SESSION_ID"
  | "JCID"
  | "OFFER_TOKEN"
  | "RESPONSE_ID"
  | "TRIP_ID";

export type InsurtechShoppingModule = {
  __typename?: "InsurtechShoppingModule";
  content: Array<InsurtechShoppingModuleSection>;
  footer?: Maybe<InsurtechShoppingModuleFooter>;
  heading: InsurtechModuleHeading;
};

export type InsurtechShoppingModuleDisclaimerFooter = {
  __typename?: "InsurtechShoppingModuleDisclaimerFooter";
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  disclaimer: Scalars["String"];
};

export type InsurtechShoppingModuleFooter =
  | InsurtechShoppingModuleDisclaimerFooter
  | InsurtechShoppingModuleSelectionButtonFooter;

export type InsurtechShoppingModuleSection =
  | InsurtechBenefitSection
  | InsurtechSelectionButtonSection
  | InsurtechTestimonial
  | InsurtechTextWrapper;

export type InsurtechShoppingModuleSelectionButtonFooter = {
  __typename?: "InsurtechShoppingModuleSelectionButtonFooter";
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  selectionSection: InsurtechSelectionButtonSection;
};

export type InsurtechShoppingResponse = {
  __typename?: "InsurtechShoppingResponse";
  module?: Maybe<InsurtechShoppingModule>;
  pageLevelInfo?: Maybe<InsurtechPageLevelInfo>;
  priceSummary?: Maybe<InsurtechPriceSummary>;
  primaryProductDetails?: Maybe<InsurtechPrimaryProductDetails>;
};

export type InsurtechShoppingResponseModuleArgs = {
  viewType: InsurtechShoppingViewType;
};

export type InsurtechShoppingResponsePrimaryProductDetailsArgs = {
  encodedProductDetails: Scalars["String"];
};

export type InsurtechShoppingViewType = "CHECKOUT_SHOPPING" | "DETAILS_SHOPPING" | "SUMMARY";

export type InsurtechSignal = {
  __typename?: "InsurtechSignal";
  createdDateTime?: Maybe<Scalars["String"]>;
  domainInfoList?: Maybe<Array<InsurtechDomainInfo>>;
  id?: Maybe<Scalars["String"]>;
  signal: SignalType;
};

export type InsurtechSpannableText = {
  __typename?: "InsurtechSpannableText";
  contents: Array<InsurtechSpannableTextElement>;
};

export type InsurtechSpannableTextElement =
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStylizedText
  | InsurtechDialogTrigger
  | InsurtechLink
  | InsurtechPricePresentation;

export type InsurtechStandardLinkWrapper = {
  __typename?: "InsurtechStandardLinkWrapper";
  link: EgdsStandardLink;
};

export type InsurtechStandardMessagingCard = {
  __typename?: "InsurtechStandardMessagingCard";
  card: EgdsStandardMessagingCard;
  linkAction?: Maybe<UiLinkAction>;
};

export type InsurtechTertiaryButton = InsurtechButton & {
  __typename?: "InsurtechTertiaryButton";
  action: InsurtechAction;
  button: UiTertiaryButton;
};

export type InsurtechTestimonial = {
  __typename?: "InsurtechTestimonial";
  expando?: Maybe<EgdsExpandoPeek>;
  heading: EgdsIconText;
  message?: Maybe<Scalars["String"]>;
};

export type InsurtechTextDialog = {
  __typename?: "InsurtechTextDialog";
  text: Scalars["String"];
};

export type InsurtechTextElement = EgdsBulletedList | EgdsParagraph;

export type InsurtechTextList = {
  __typename?: "InsurtechTextList";
  items: Array<InsurtechListItem>;
};

export type InsurtechTextListWrapper = {
  __typename?: "InsurtechTextListWrapper";
  header?: Maybe<Scalars["String"]>;
  textList: EgdsTextList;
};

export type InsurtechTextWrapper = {
  __typename?: "InsurtechTextWrapper";
  text: InsurtechTextWrapperElement;
};

export type InsurtechTextWrapperElement = InsurtechSpannableText | InsurtechStandardLinkWrapper;

export type InsurtechToggleOfferAction = InsurtechAction & {
  __typename?: "InsurtechToggleOfferAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  failedMessage: Scalars["String"];
};

export type InsurtechUpdateOfferAction = InsurtechAction & {
  __typename?: "InsurtechUpdateOfferAction";
  /** @deprecated wrong naming convention */
  AddOfferConfirmationMessage: Scalars["String"];
  /** @deprecated wrong naming convention */
  RemoveOfferConfirmationMessage: Scalars["String"];
  accessibility?: Maybe<Scalars["String"]>;
  addInsurtechOfferConfirmationMessage: Scalars["String"];
  analytics?: Maybe<ClientSideAnalytics>;
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  failedMessage: Scalars["String"];
  removeInsurtechOfferConfirmationMessage: Scalars["String"];
};

export type InsurtechUpdateOperationType = "ADD" | "MODAL" | "REMOVE";

export type InsurtechUpdateProductSelectionAction = InsurtechAction & {
  __typename?: "InsurtechUpdateProductSelectionAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  operationType: InsurtechUpdateOperationType;
  productId: Scalars["String"];
  responseId: Scalars["String"];
};

export interface InsurtechUpdateProductSelectionRequestInput {
  operationType: InsurtechUpdateOperationType;
  productId: Scalars["String"];
  responseId: Scalars["String"];
}

export type InsurtechUpdateProductSelectionResponse = {
  __typename?: "InsurtechUpdateProductSelectionResponse";
  insuranceContinuationToken?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  status: InsurtechMutationStatus;
};

export type InsurtechUpdateTripAction = InsurtechAction & {
  __typename?: "InsurtechUpdateTripAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  offerId: Scalars["String"];
  operation: InsurtechUpdateOperationType;
  price: Scalars["String"];
  responseId?: Maybe<Scalars["String"]>;
};

export type InteractionEventName = "BOOK" | "CHECKOUT_PAGE_LOAD" | "CONFIRMATION_PAGE_LOAD";

export type InteractionEventResponse = {
  __typename?: "InteractionEventResponse";
  feedbackMessage?: Maybe<EgdsToast>;
  uisPrimeMicroMessage?: Maybe<Scalars["String"]>;
};

export type InteractionMode = "FREE" | "RESTRICTED";

export type Interest = {
  __typename?: "Interest";
  desmetId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export interface InterestInput {
  desmetId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
}

export type InventoryType =
  | "AICTRAVELGROUP"
  | "AMOMA"
  | "ANA"
  | "BESTBUYHOTEL"
  | "BONOTEL"
  | "CNTRAVEL"
  | "CTRIP"
  | "DESPEGAR"
  | "DIDATRAVEL"
  | "DIRECT_AGENCY"
  | "EUROPLAYAS"
  | "EXCITEHOLIDAYS"
  | "EZZEGO_1"
  | "GETAROOM"
  | "HOME_AWAY"
  | "HOTELDOBESTDAYTRAVEL"
  | "HOTEL_BEDS"
  | "HRS"
  | "JAC_TRAVEL"
  | "JAL"
  | "JALAN"
  | "JTB"
  | "JUMBOTOURS"
  | "MAGICROOMS"
  | "MERCHANT"
  | "MGBEDBANK"
  | "MIKI"
  | "MTS"
  | "NTA"
  | "NUITEE"
  | "OPAQUE"
  | "OSTROVOK"
  | "PEGASUS"
  | "RAKUTEN"
  | "RESTEL"
  | "ROOMER"
  | "SMYROOMS"
  | "SUNHOTELS"
  | "THOMASCOOK_EUR"
  | "THOMASCOOK_UK"
  | "TOURICO"
  | "TRAVELLANDA"
  | "TRAVELREPUBLIC"
  | "VENERE"
  | "VRBO"
  | "VRBO_MUVR"
  | "WELCOME_BEDS"
  | "WOORI"
  | "WORLDHOTELLINK"
  | "WORLDSPAN"
  | "WOTIF"
  | "YOUTRAVEL"
  | "ZUMATA";

export type ItineraryCarousel = {
  __typename?: "ItineraryCarousel";
  buttonText: CruiseCarouselButtonText;
  cards: Array<CruiseItineraryItem>;
};

export type ItineraryDetails = {
  __typename?: "ItineraryDetails";
  apiKey: Scalars["String"];
  itineraryMapItems: Array<ItineraryItem>;
};

export type ItineraryDynamicMapDetails = {
  __typename?: "ItineraryDynamicMapDetails";
  close: Icon;
  itineraryCarousel: ItineraryCarousel;
  itineraryMap: ItineraryMap;
};

export type ItineraryDynamicMapItem = {
  __typename?: "ItineraryDynamicMapItem";
  button?: Maybe<EgdsOverlayButton>;
  coordinates: Coordinates;
  description: Scalars["String"];
  id: Scalars["String"];
};

export type ItineraryItem = {
  __typename?: "ItineraryItem";
  coordinates: Coordinates;
  id: Scalars["String"];
  marker: Image;
};

export type ItineraryMap = {
  __typename?: "ItineraryMap";
  apiKey: Scalars["String"];
  markers: Array<ItineraryDynamicMapItem>;
};

export type ItineraryStaticMapDetails = {
  __typename?: "ItineraryStaticMapDetails";
  large: Image;
  medium: Image;
  small: Image;
};

export type JourneyConnectionInformation = AdditionalInformation | FlightsConnectionInformation;

export type JourneyDetails = {
  __typename?: "JourneyDetails";
  journeyHeader: EgdsTextContainer;
  merchandisingDetails: FlightsCategorizedList;
};

export type JourneyDetailsBadge = {
  __typename?: "JourneyDetailsBadge";
  badge: EgdsBadge;
  link?: Maybe<EgdsStandardLink>;
};

export type JourneyExperience = "NEW_JOURNEY_PLAYBACK" | "OLD_JOURNEY_PLAYBACK";

export type JourneyFlightSelection = {
  __typename?: "JourneyFlightSelection";
  journeyIndex: Scalars["Int"];
  offerIdentifier: Scalars["String"];
};

export interface JourneyFlightSelectionInput {
  journeyIndex: Scalars["Int"];
  offerIdentifier: Scalars["String"];
}

export type JourneyInformation = {
  __typename?: "JourneyInformation";
  destinationAirportCode: Scalars["String"];
  originAirportCode: Scalars["String"];
  tripType: Scalars["String"];
};

export type JourneyPart = {
  __typename?: "JourneyPart";
  connectionAdditionalInformation?: Maybe<AdditionalInformation>;
  flightsConnectionInformation: FlightsConnectionInformation;
};

export type JourneyPartsDynamicElements = {
  __typename?: "JourneyPartsDynamicElements";
  cabinClassAndBookingCode: Scalars["String"];
};

export type JourneySection = {
  __typename?: "JourneySection";
  journeyConnectionInformation: Array<JourneyConnectionInformation>;
};

export type JourneySelectionDropDown = {
  __typename?: "JourneySelectionDropDown";
  icon: Icon;
  journeySelectionOptions: Array<FlightSelectedLegOption>;
};

export type JourneyStartEndDates = {
  __typename?: "JourneyStartEndDates";
  endDate: Scalars["String"];
  startDate: Scalars["String"];
};

export type KeyPhrase = {
  __typename?: "KeyPhrase";
  text?: Maybe<Scalars["String"]>;
};

export type KeyPhrasesClientType = "DEFAULT" | "FILTER" | "SNIPPET";

export type KeyPhrasesComponent = {
  __typename?: "KeyPhrasesComponent";
  label?: Maybe<Scalars["String"]>;
  phrases?: Maybe<Array<KeyPhrase>>;
};

export type KlarnaAppDetails = {
  __typename?: "KlarnaAppDetails";
  link: EgdsStandardLink;
  logo: UiGraphic;
  secondaryText?: Maybe<EgdsPlainText>;
  text: EgdsStylizedText;
};

export type KlarnaDetails = {
  __typename?: "KlarnaDetails";
  analytics: ClientSideAnalytics;
  elementAttributes: Array<InstallmentPlanAttribute>;
  scriptAttributes: Array<InstallmentPlanAttribute>;
};

export type LlmSearchErrorView = {
  __typename?: "LLMSearchErrorView";
  errorMessage: Scalars["String"];
};

export type LabeledList = {
  __typename?: "LabeledList";
  elements: Array<Scalars["String"]>;
  label: Scalars["String"];
};

export type LabeledValue = {
  __typename?: "LabeledValue";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type LandingFeaturedSupplier = LandingFeaturedSupplierLogo;

export type LandingFeaturedSupplierLogo = {
  __typename?: "LandingFeaturedSupplierLogo";
  image: Image;
  uisPrimeClick?: Maybe<ClientSideAnalytics>;
  uisPrimeImpression?: Maybe<ClientSideAnalytics>;
};

export type LandingFeaturedSuppliers = {
  __typename?: "LandingFeaturedSuppliers";
  heading?: Maybe<LandingFeaturedSuppliersHeading>;
  suppliers: Array<LandingFeaturedSupplier>;
  uisPrimeImpression?: Maybe<ClientSideAnalytics>;
};

export type LandingFeaturedSuppliersSuppliersArgs = {
  maxSize?: InputMaybe<Scalars["Int"]>;
  minSize?: InputMaybe<Scalars["Int"]>;
};

export type LandingFeaturedSuppliersHeading = {
  __typename?: "LandingFeaturedSuppliersHeading";
  subTitle?: Maybe<EgdsText>;
  title: EgdsText;
};

export type LanguageSelector = LocalePreferenceSelector & {
  __typename?: "LanguageSelector";
  heading: Scalars["String"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<LanguageSelectorOption>;
  queryParameterName: Scalars["String"];
  trackingEvent: Scalars["String"];
};

export type LanguageSelectorOption = LocaleSelectorOption & {
  __typename?: "LanguageSelectorOption";
  name: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
};

export type LayoutPosition = "CENTER" | "LEFT";

export interface LegacyPropertySearchCriteriaInput {
  amenities?: InputMaybe<Array<Scalars["String"]>>;
  guestRating?: InputMaybe<Scalars["String"]>;
  paymentType?: InputMaybe<Array<Scalars["String"]>>;
  priceBuckets?: InputMaybe<Array<Scalars["Int"]>>;
  privacyTrackingState?: InputMaybe<PrivacyTrackingState>;
  sort?: InputMaybe<Scalars["String"]>;
  stars?: InputMaybe<Scalars["String"]>;
  structureTypes?: InputMaybe<Array<Scalars["String"]>>;
}

export type LegacyWidget = {
  __typename?: "LegacyWidget";
  icons?: Maybe<Array<Scalars["String"]>>;
  imageURL?: Maybe<Scalars["URL"]>;
  productDetailViewModels?: Maybe<Array<TripsWidgetTripProductDetailViewModel>>;
  productsCountText?: Maybe<Scalars["String"]>;
  relevance?: Maybe<TripsWidgetTripDetailItemRelevance>;
  subtitleText?: Maybe<Scalars["String"]>;
  textWithIconViewModels?: Maybe<Array<TripsWidgetTextWithIconViewModel>>;
  titleText?: Maybe<Scalars["String"]>;
  tripId?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type LegacyWidgetComponent = {
  __typename?: "LegacyWidgetComponent";
  trips: Array<LegacyWidget>;
};

export type LegalConcept =
  | "LEGAL_CONCEPT_COOKIES"
  | "LEGAL_CONCEPT_INVALID"
  | "LEGAL_CONCEPT_PRIVACY"
  | "LEGAL_CONCEPT_SORT_AND_FILTER"
  | "LEGAL_CONCEPT_TERMS_AND_CONDITIONS";

export type LegalDetails = {
  __typename?: "LegalDetails";
  disclaimerMessage?: Maybe<Array<LegalSection>>;
  disclaimerMessageWithInsurance?: Maybe<LegalSection>;
};

export type LegalDialogErrorView = {
  __typename?: "LegalDialogErrorView";
  errorMessage: Scalars["String"];
  goBackText: Scalars["String"];
  tryAgainText: Scalars["String"];
};

export type LegalDialogLink = {
  __typename?: "LegalDialogLink";
  action: UiLinkAction;
  dialogId: Scalars["String"];
  errorViewDetails: LegalDialogErrorView;
  text: Scalars["String"];
  toolbar?: Maybe<EgdsDialogToolbar>;
};

export type LegalElement = EgdsInlineLink | EgdsPlainText | LegalDialogLink;

export type LegalSection = {
  __typename?: "LegalSection";
  elements: Array<LegalElement>;
};

export type LevelOneList = {
  __typename?: "LevelOneList";
  items: Array<LevelOneListItem>;
  type: EgdsTextListType;
};

export type LevelOneListItem = {
  __typename?: "LevelOneListItem";
  children?: Maybe<LevelTwoList>;
  text: EgdsText;
};

export type LevelThreeList = {
  __typename?: "LevelThreeList";
  items: Array<LevelThreeListItem>;
  type: EgdsTextListType;
};

export type LevelThreeListItem = {
  __typename?: "LevelThreeListItem";
  text: EgdsText;
};

export type LevelTwoList = {
  __typename?: "LevelTwoList";
  items: Array<LevelTwoListItem>;
  type: EgdsTextListType;
};

export type LevelTwoListItem = {
  __typename?: "LevelTwoListItem";
  children?: Maybe<LevelThreeList>;
  text: EgdsText;
};

export type LimitedResultMessageOnRecommendationCard = CarRecommendationCard & {
  __typename?: "LimitedResultMessageOnRecommendationCard";
  action?: Maybe<CarAction>;
  icon?: Maybe<Icon>;
  recommendationDialog?: Maybe<CarsDialog>;
};

export type LineOfBusinessDomain =
  | "ACTIVITIES"
  | "CAR"
  | "CRUISES"
  | "FLIGHT"
  | "GROUNDTRANSFERS"
  | "LODGING"
  | "PACKAGES"
  | "RAILS"
  | "UNCLASSIFIED";

export type LinkWrapper = {
  __typename?: "LinkWrapper";
  link: EgdsStandardLink;
};

export type Listings = "ADS_ONLY";

export interface LiveAnnounce {
  liveAnnounceMessage?: Maybe<Scalars["String"]>;
  liveAnnouncePoliteness?: Maybe<LiveAnnouncePoliteness>;
}

export type LiveAnnouncePoliteness = "ASSERTIVE" | "OFF" | "POLITE";

export type LoadedFlightsSelectedJourneyReview = FlightsSelectedJourneyReview & {
  __typename?: "LoadedFlightsSelectedJourneyReview";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  collapseAnalytics?: Maybe<FlightsAnalytics>;
  expandAnalytics?: Maybe<FlightsAnalytics>;
  fareDetails: FareDetails;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  /** @deprecated This field is now deprecated., replace with Use the sibling of FlightsSelectedJourneyReview */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyHighlights?: Maybe<Array<FlightsJourneyHighlights>>;
  journeyInformation?: Maybe<JourneyInformation>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  upsellNudgingFeatureEnabled: Scalars["Boolean"];
};

export type LoadedFlightsStandardJourneySummary = FlightsJourneySummary & {
  __typename?: "LoadedFlightsStandardJourneySummary";
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  heading: FlightsMessageAndAccessibility;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
};

export type LoadingFlightsSelectedJourneyReview = FlightsSelectedJourneyReview & {
  __typename?: "LoadingFlightsSelectedJourneyReview";
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  collapseAnalytics?: Maybe<FlightsAnalytics>;
  expandAnalytics?: Maybe<FlightsAnalytics>;
  fareDetails: FareDetails;
  fareSummarySkeletonCount: Scalars["Int"];
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  /** @deprecated This field is now deprecated., replace with Use the sibling of FlightsSelectedJourneyReview */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyHighlights?: Maybe<Array<FlightsJourneyHighlights>>;
  journeyInformation?: Maybe<JourneyInformation>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  upsellNudgingFeatureEnabled: Scalars["Boolean"];
};

export type LoadingFlightsStandardJourneySummary = FlightsJourneySummary & {
  __typename?: "LoadingFlightsStandardJourneySummary";
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  heading: FlightsMessageAndAccessibility;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
  skeletonCount: Scalars["Int"];
};

export interface LocAirport {
  airportCode: Scalars["String"];
  airportName?: Maybe<Scalars["String"]>;
}

export type LocaleInfo = {
  __typename?: "LocaleInfo";
  baseName: Scalars["Locale"];
  textDirection: TextDirection;
};

export interface LocalePreferenceSelector {
  heading: Scalars["String"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<Maybe<LocaleSelectorOption>>;
  queryParameterName: Scalars["String"];
  /** @deprecated Replaced by onChangeAnalytics, better name and correct type */
  trackingEvent: Scalars["String"];
}

export interface LocaleSelectorOption {
  name: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
}

export type LocalizedInsuranceModule = {
  __typename?: "LocalizedInsuranceModule";
  insuranceModule: InsuranceModule;
  locale: Scalars["Locale"];
};

export type LocationRecommendationCard = CarRecommendationCard & {
  __typename?: "LocationRecommendationCard";
  action?: Maybe<CarAction>;
  city?: Maybe<CarPhraseText>;
  distanceText?: Maybe<CarPhraseText>;
  icon?: Maybe<Icon>;
  location?: Maybe<CarsRichText>;
  pickUpLocation?: Maybe<CarSearchLocation>;
  type?: Maybe<Scalars["String"]>;
};

export type LodgingAdaptExAnalyticsEvent = {
  __typename?: "LodgingAdaptExAnalyticsEvent";
  campaignId: Scalars["String"];
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  events: Array<LodgingAdaptExEvent>;
};

export type LodgingAdaptExEvent = {
  __typename?: "LodgingAdaptExEvent";
  banditDisplayed: Scalars["Boolean"];
  eventTarget: Scalars["String"];
  eventType: Scalars["String"];
  payloadId: Scalars["String"];
};

export type LodgingAmenitiesDialog = LodgingDialog & {
  __typename?: "LodgingAmenitiesDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingBadgesGroup = {
  __typename?: "LodgingBadgesGroup";
  primaryBadge?: Maybe<EgdsStandardBadge>;
  secondaryBadge?: Maybe<EgdsStandardBadge>;
  tertiaryBadge?: Maybe<EgdsStandardBadge>;
};

export type LodgingBasicContentActionDialog = {
  __typename?: "LodgingBasicContentActionDialog";
  content: Array<Scalars["String"]>;
  dialog: EgdsActionDialog;
};

export type LodgingBookingPaymentDetails = {
  __typename?: "LodgingBookingPaymentDetails";
  amountPaid: Scalars["Float"];
  amountPaidLabel: Scalars["String"];
  bookingBalance: Scalars["Float"];
  bookingBalanceLabel: Scalars["String"];
  previouslyRefunded: Scalars["Float"];
  previouslyRefundedLabel: Scalars["String"];
};

export type LodgingButton = {
  __typename?: "LodgingButton";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  adaptExSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  adaptExSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  analytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use adaptExSuccessEvents, replace with adaptExSuccessEvents */
  lodgingClientSideAnalyticsSuccess?: Maybe<LodgingClientSideAnalyticsSuccess>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  text: Scalars["String"];
};

export interface LodgingButtonAction {
  analytics?: Maybe<ClientSideAnalytics>;
  text?: Maybe<Scalars["String"]>;
}

export type LodgingButtonsGroup = {
  __typename?: "LodgingButtonsGroup";
  primaryButton?: Maybe<AdTransparencyTrigger>;
  secondaryButton?: Maybe<AdTransparencyTrigger>;
  tertiaryButton?: Maybe<AdTransparencyTrigger>;
};

export type LodgingCalculator = {
  __typename?: "LodgingCalculator";
  bookingBalance: Scalars["Float"];
  bookingLineItems: Array<BookingLineItem>;
  currency: Scalars["String"];
  errorMessage: Scalars["String"];
  footnote: Scalars["String"];
  header: BookingLineItemHeader;
  refundInput: LodgingCalculatorInput;
  reset: UiSecondaryButton;
  summary: BookingSummaryItem;
};

export type LodgingCalculatorInput = {
  __typename?: "LodgingCalculatorInput";
  nightlyTotal: LodgingCalculatorInputItem;
  partialAmount: LodgingCalculatorInputItem;
  percentage: LodgingCalculatorInputItem;
};

export type LodgingCalculatorInputItem = {
  __typename?: "LodgingCalculatorInputItem";
  errorMessage?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  placeholder?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type LodgingCancellationPolicyDialog = LodgingDialog & {
  __typename?: "LodgingCancellationPolicyDialog";
  content: Array<CancellationPolicyDialogContentEntry>;
  primaryUIButton?: Maybe<UiPrimaryButton>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingCancellationPolicyInfo = {
  __typename?: "LodgingCancellationPolicyInfo";
  policyContent: LodgingDetailedTimeline;
  title: EgdsStylizedText;
};

export type LodgingCard = {
  __typename?: "LodgingCard";
  adaptexSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  callOut?: Maybe<EgdsText>;
  /** @deprecated Deprecated in favor of clickActionId */
  cardLink?: Maybe<UiLinkAction>;
  clickActionId?: Maybe<Scalars["String"]>;
  clickstreamEvents: LodgingCardClickstreamEvents;
  compareSection?: Maybe<LodgingCardCompareSection>;
  contents?: Maybe<Array<ShoppingProductCardContentContainer>>;
  dataAttributes: Array<GraphQlPair>;
  dateSection?: Maybe<LodgingCardDateSection>;
  featuredHeader?: Maybe<LodgingCardFeaturedHeader>;
  /** @deprecated Deprecated in favor of headingSection.featuredMessages */
  featuredMessages: Array<LodgingCardFeaturedMessage>;
  footerActions: Array<LodgingCardFooterAction>;
  footers?: Maybe<Array<ShoppingProductCardFooter>>;
  headingSection?: Maybe<LodgingCardHeadingSection>;
  id: Scalars["String"];
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  mediaSection: LodgingCardMediaSection;
  priceSection?: Maybe<LodgingCardPriceSection>;
  /** @deprecated Deprecated in favor of headingSection.heading */
  propertyName: EgdsHeading;
  /** @deprecated Deprecated in favor of headingSection.productRating */
  propertyRating?: Maybe<EgdsRating>;
  shoppingInvokeFunctionParams?: Maybe<Array<ShoppingProductCardFunctionParametersContainers>>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
  summarySections: Array<LodgingCardSection>;
};

export type LodgingCardBadgedRating = {
  __typename?: "LodgingCardBadgedRating";
  badge: EgdsBadge;
  phrases?: Maybe<Array<LodgingCardPhrase>>;
};

export type LodgingCardChangeAction = LodgingCardChangeUnitDialog | LodgingCardChangeUnitSheet;

export type LodgingCardChangeUnitDialog = {
  __typename?: "LodgingCardChangeUnitDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardChangeUnitDialogAction = {
  __typename?: "LodgingCardChangeUnitDialogAction";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardChangeUnitSheet = {
  __typename?: "LodgingCardChangeUnitSheet";
  sheet: EgdsSheet;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardChangeUnitSheetAction = {
  __typename?: "LodgingCardChangeUnitSheetAction";
  sheet: EgdsSheet;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardClickstreamEvents = {
  __typename?: "LodgingCardClickstreamEvents";
  productPresented?: Maybe<LodgingProductEvent>;
  productSelected?: Maybe<LodgingProductEvent>;
};

export type LodgingCardCompareAction = EgdsBasicCheckBox;

export type LodgingCardCompareSection = {
  __typename?: "LodgingCardCompareSection";
  compareAction: LodgingCardCompareAction;
};

export type LodgingCardDateSection = {
  __typename?: "LodgingCardDateSection";
  date?: Maybe<LodgingCardDateSectionText>;
};

export type LodgingCardDateSectionText = EgdsStylizedText;

export type LodgingCardDetailsAction = LodgingCardPropertyDetailsDialog | LodgingCardUnitDetailsDialog;

export type LodgingCardFeaturedHeader = {
  __typename?: "LodgingCardFeaturedHeader";
  header: EgdsHeading;
};

export type LodgingCardFeaturedMessage = {
  __typename?: "LodgingCardFeaturedMessage";
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type LodgingCardFooterAction = LodgingCardLinkAction | ShoppingButton;

export type LodgingCardHeadingSection = {
  __typename?: "LodgingCardHeadingSection";
  amenities: Array<EgdsIconText>;
  featuredMessages: Array<LodgingCardFeaturedMessage>;
  heading: Scalars["String"];
  messages: Array<EgdsText>;
  missingFilters?: Maybe<EgdsStylizedText>;
  perkBadges?: Maybe<Array<EgdsLoyaltyBadge>>;
  productRating?: Maybe<EgdsRating>;
  standardBadges?: Maybe<Array<EgdsStandardBadge>>;
};

export type LodgingCardLinkAction = {
  __typename?: "LodgingCardLinkAction";
  link: EgdsStandardLink;
};

export type LodgingCardMediaSection = {
  __typename?: "LodgingCardMediaSection";
  /** @deprecated Deprecated in favor of mediaSection.badges.primaryBadge */
  badge?: Maybe<EgdsStandardBadge>;
  badges?: Maybe<LodgingBadgesGroup>;
  buttons?: Maybe<LodgingButtonsGroup>;
  gallery?: Maybe<LodgingGalleryCarousel>;
  /** @deprecated Deprecated in favor of saveTripItem */
  saveItem?: Maybe<LodgingSaveItem>;
  saveTripItem?: Maybe<TripsSaveItem>;
  /** @deprecated Deprecated in favor of mediaSection.badges.secondaryBadge */
  secondaryBadge?: Maybe<EgdsStandardBadge>;
};

export type LodgingCardMoreDetailsTrigger = {
  __typename?: "LodgingCardMoreDetailsTrigger";
  accessibilityLabel: Scalars["String"];
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
};

export type LodgingCardPhrase = {
  __typename?: "LodgingCardPhrase";
  accessibilityLabel: Scalars["String"];
  /** @deprecated Deprecated in favor of phraseParts */
  parts: Array<EgdsText>;
  phraseParts: Array<LodgingCardPhrasePart>;
};

export type LodgingCardPhrasePart =
  | EgdsIconRating
  | EgdsPlainText
  | EgdsStylizedText
  | ShoppingActionableIcon
  | ShoppingLink;

export type LodgingCardPriceSection = {
  __typename?: "LodgingCardPriceSection";
  /** @deprecated Deprecated in favor of standardBadge */
  badge?: Maybe<EgdsStandardBadge>;
  button?: Maybe<LodgingPrimaryAltButton>;
  priceSummary?: Maybe<PropertyPrice>;
  standardBadge?: Maybe<LodgingStandardBadge>;
};

export type LodgingCardProductSummarySection = {
  __typename?: "LodgingCardProductSummarySection";
  amenities: Array<EgdsIconText>;
  /** @deprecated Deprecated in favor of changeActionDialog and changeActionSheet. */
  changeAction?: Maybe<LodgingCardChangeAction>;
  changeActionDialog?: Maybe<LodgingCardChangeUnitDialogAction>;
  changeActionSheet?: Maybe<LodgingCardChangeUnitSheetAction>;
  descriptionSection?: Maybe<LodgingCardPropertyDescriptionSection>;
  /** @deprecated Deprecated in favor of detailsLink. */
  detailsAction?: Maybe<LodgingCardDetailsAction>;
  detailsLink?: Maybe<ShoppingLink>;
  footerMessages?: Maybe<EgdsTextList>;
  /** @deprecated Deprecated in favor of guestRatingSection. */
  guestRating?: Maybe<LodgingCardPhrase>;
  /** @deprecated Deprecated in favor of guestRatingSectionV2. */
  guestRatingSection?: Maybe<LodgingCardRatingSection>;
  guestRatingSectionV2?: Maybe<LodgingCardRatingSectionV2>;
  heading?: Maybe<EgdsHeading>;
  messages: Array<EgdsText>;
  /** @deprecated Deprecated in favor of footerMessages */
  valuePropositions?: Maybe<EgdsTextIconList>;
};

export type LodgingCardPropertyDescriptionSection = {
  __typename?: "LodgingCardPropertyDescriptionSection";
  description?: Maybe<EgdsParagraph>;
  heading?: Maybe<EgdsHeading>;
  image?: Maybe<Image>;
  link?: Maybe<EgdsStandardLink>;
};

export type LodgingCardPropertyDetailsDialog = {
  __typename?: "LodgingCardPropertyDetailsDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardRating = {
  __typename?: "LodgingCardRating";
  badge?: Maybe<EgdsBadge>;
  phrases?: Maybe<Array<LodgingCardPhrase>>;
};

export type LodgingCardRatingSection = LodgingCardBadgedRating | LodgingCardPhrase;

export type LodgingCardRatingSectionV2 = LodgingCardPhrase | LodgingCardRating;

export type LodgingCardSection = LodgingCardProductSummarySection;

export type LodgingCardUnitDetailsDialog = {
  __typename?: "LodgingCardUnitDetailsDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCarouselContainer = {
  __typename?: "LodgingCarouselContainer";
  carousel?: Maybe<EgdsCarousel>;
  heading: LodgingCarouselContainerHeading;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<LodgingCarouselContainerItem>;
  /** @deprecated Deprecated. This is meant to not be used outside of restricted scenarios. */
  seoRichContentMetaData?: Maybe<Scalars["String"]>;
};

export type LodgingCarouselContainerHeading = EgdsExpandoCard | EgdsStylizedText;

export type LodgingCarouselContainerItem = LodgingCard | LodgingUnitCard;

export type LodgingCategorizedUnit = AccessibilityField & {
  __typename?: "LodgingCategorizedUnit";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated in favor of highlightedMessages */
  cancellationPolicy?: Maybe<RatePlanMessage>;
  /** @deprecated This is for a TNL, it is temporary. TNL ID 47076 */
  cardExpandAction?: Maybe<LodgingCategorizedUnitExpandAction>;
  /** @deprecated Deprecated in favor of PropertyUnit.detailsDialog */
  detailsDialog?: Maybe<LodgingDialog>;
  expandoItemsCard?: Maybe<LodgingExpandoItemsCard>;
  featureHeader?: Maybe<EgdsHeading>;
  features?: Maybe<Array<PropertyInfoItem>>;
  /** @deprecated Do not use - to be deleted */
  footerActions?: Maybe<Array<UiPrimaryButton>>;
  footerButton?: Maybe<EgdsButton>;
  header?: Maybe<LodgingHeader>;
  highlightedMessages: Array<RatePlanMessage>;
  /** @deprecated Do not use - field to be deleted */
  includedPerks?: Maybe<PropertyInfoContent>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  primaryHeader?: Maybe<LodgingOfferSelectionHeader>;
  primarySelections?: Maybe<Array<LodgingPrimaryOfferSelection>>;
  secondaryHeader?: Maybe<LodgingOfferSelectionHeader>;
  tertiaryHeader?: Maybe<LodgingOfferSelectionHeader>;
  unitId?: Maybe<Scalars["String"]>;
};

export type LodgingCategorizedUnitExpandAction = {
  __typename?: "LodgingCategorizedUnitExpandAction";
  collapseButton?: Maybe<UiSecondaryButton>;
  expandButton?: Maybe<UiPrimaryButton>;
  expanded: Scalars["Boolean"];
};

export type LodgingChangeForm = {
  __typename?: "LodgingChangeForm";
  calculator: LodgingCalculator;
};

export type LodgingChangePlayback = {
  __typename?: "LodgingChangePlayback";
  criteriaPlayback: LodgingCriteriaPlayback;
  pricePlayback: LodgingChangePricePlayback;
};

export type LodgingChangePriceDetails = {
  __typename?: "LodgingChangePriceDetails";
  content?: Maybe<PricePresentation>;
  expando?: Maybe<LodgingPricePresentationExpando>;
};

export type LodgingChangePricePlayback = {
  __typename?: "LodgingChangePricePlayback";
  originalTotalPriceDetails?: Maybe<LodgingChangePriceDetails>;
  updatedTotalPriceDetails?: Maybe<LodgingChangePriceDetails>;
};

export type LodgingChangeReasonCalculator = LodgingCalculator | LodgingWaiverCalculator;

export type LodgingChatbot = {
  __typename?: "LodgingChatbot";
  action?: Maybe<UiPrimaryButton>;
  botNoLabel: Scalars["String"];
  botSkipLabel: Scalars["String"];
  botYesLabel: Scalars["String"];
  content?: Maybe<Scalars["String"]>;
  feedbackContentLabel: Scalars["String"];
  feedbackFormContentLabel: Scalars["String"];
  feedbackFormLabel: Scalars["String"];
  feedbackFormSubmitLabel: Scalars["String"];
  feedbackFormTitleLabel: Scalars["String"];
  header?: Maybe<LodgingHeader>;
};

export type LodgingClientSideAnalyticsSuccess = {
  __typename?: "LodgingClientSideAnalyticsSuccess";
  campaignId: Scalars["String"];
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  events: Array<LodgingClientSideAnalyticsSuccessEvents>;
};

export type LodgingClientSideAnalyticsSuccessEvents = {
  __typename?: "LodgingClientSideAnalyticsSuccessEvents";
  banditDisplayed: Scalars["Boolean"];
  eventTarget: Scalars["String"];
  eventType: Scalars["String"];
  payloadId: Scalars["String"];
};

export type LodgingCompareActionSheet = {
  __typename?: "LodgingCompareActionSheet";
  compareAction?: Maybe<UiPrimaryButton>;
  compareActionPrompt?: Maybe<Scalars["String"]>;
  compareActionStatus?: Maybe<EgdsCardinalLocalizedText>;
  itemTemplate?: Maybe<LodgingCompareActionSheetItemTemplate>;
  maxCount: Scalars["Int"];
  sheet?: Maybe<EgdsSheet>;
  toolbar?: Maybe<LodgingCompareActionSheetToolbar>;
};

export type LodgingCompareActionSheetItemTemplate = {
  __typename?: "LodgingCompareActionSheetItemTemplate";
  accessibilityLabel: EgdsBasicLocalizedText;
  button?: Maybe<EgdsOverlayButton>;
};

export type LodgingCompareActionSheetToolbar = {
  __typename?: "LodgingCompareActionSheetToolbar";
  collapseAccessibilityLabel: Scalars["String"];
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  expandAccessibilityLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
};

export type LodgingCompareAttribute = {
  __typename?: "LodgingCompareAttribute";
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<EgdsStylizedText>;
};

export type LodgingCompareCell = {
  __typename?: "LodgingCompareCell";
  content?: Maybe<LodgingCompareCellContent>;
};

export type LodgingCompareCellContent =
  | EgdsIconRating
  | EgdsStandardBadge
  | LodgingCardPhrase
  | LodgingCardPriceSection
  | LodgingCompareTextItems;

export type LodgingCompareRemoveAction = {
  __typename?: "LodgingCompareRemoveAction";
  action?: Maybe<UiTertiaryButton>;
  propertyId?: Maybe<Scalars["String"]>;
};

export type LodgingCompareRow = {
  __typename?: "LodgingCompareRow";
  cells: Array<Maybe<LodgingCompareCell>>;
  theme: LodgingCompareRowTheme;
};

export type LodgingCompareRowTheme = "COMBINE_NEXT" | "STANDARD";

export type LodgingCompareSection = {
  __typename?: "LodgingCompareSection";
  accessibilityHeaderRow: Array<Maybe<Scalars["String"]>>;
  compareRows: Array<Maybe<LodgingCompareRow>>;
  mediaRow?: Maybe<Array<Maybe<LodgingCardMediaSection>>>;
  removeRow?: Maybe<Array<Maybe<LodgingCompareRemoveAction>>>;
  viewRow?: Maybe<Array<Maybe<UiPrimaryButton>>>;
};

export type LodgingCompareSectionView = {
  __typename?: "LodgingCompareSectionView";
  content?: Maybe<LodgingCompareSection>;
  error?: Maybe<LodgingErrorResponse>;
};

export type LodgingCompareTextItems = {
  __typename?: "LodgingCompareTextItems";
  items?: Maybe<Array<Maybe<LodgingCompareAttribute>>>;
};

export type LodgingComplexDialog = LodgingDialog & {
  __typename?: "LodgingComplexDialog";
  content?: Maybe<Array<DialogContent>>;
  primaryUIButton?: Maybe<UiPrimaryButton>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingComplexLinkMessage = {
  __typename?: "LodgingComplexLinkMessage";
  actionLink: LodgingLinkMessage;
  text: LodgingPlainMessage;
};

export type LodgingCriteriaPlayback = {
  __typename?: "LodgingCriteriaPlayback";
  changeIndicatorAccessibilityLabel?: Maybe<Scalars["String"]>;
  original?: Maybe<LodgingCriteriaPlaybackCard>;
  updated?: Maybe<LodgingCriteriaPlaybackCard>;
};

export type LodgingCriteriaPlaybackCard = {
  __typename?: "LodgingCriteriaPlaybackCard";
  criteria?: Maybe<Array<EgdsTextListItem>>;
  criteriaType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type LodgingCrossSellProductType = "CAR";

export type LodgingDestinationResults = {
  __typename?: "LodgingDestinationResults";
  datedSearches: LodgingList;
};

export type LodgingDestinationResultsDatedSearchesArgs = {
  dataType: Scalars["String"];
  pageType: Scalars["String"];
};

export type LodgingDetailedTimeline = {
  __typename?: "LodgingDetailedTimeline";
  policies: Array<LodgingPolicy>;
  title?: Maybe<LodgingDetailedTimelineText>;
};

export type LodgingDetailedTimelineText = EgdsGraphicText | EgdsStylizedText;

export interface LodgingDialog {
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
}

export type LodgingDialogErrorResponse = {
  __typename?: "LodgingDialogErrorResponse";
  body: Scalars["String"];
  button: UiPrimaryButton;
};

export type LodgingDialogToolbar = {
  __typename?: "LodgingDialogToolbar";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]>;
};

export type LodgingDialogTrigger = {
  __typename?: "LodgingDialogTrigger";
  actionDetails?: Maybe<DialogAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  dialogName: Scalars["String"];
  /** @deprecated Replaced with the more flexible ClientSideAnalytics type., replace with clientSideAnalytics.referrerId */
  referrerId?: Maybe<Scalars["String"]>;
};

export type LodgingDialogTriggerMessage = FindAnswersDialogTrigger &
  HeaderMessage & {
    __typename?: "LodgingDialogTriggerMessage";
    accessibilityLabel?: Maybe<Scalars["String"]>;
    adaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
    clientSideAnalytics?: Maybe<ClientSideAnalytics>;
    /** @deprecated Use this trigger message as part of a dialog. */
    dialogTrigger: LodgingDialogTrigger;
    icon?: Maybe<Icon>;
    mark?: Maybe<Mark>;
    secondaryValue?: Maybe<Scalars["String"]>;
    theme?: Maybe<LodgingMessageTheme>;
    value: Scalars["String"];
  };

export type LodgingEnrichedMessage = AccessibilityField & {
  __typename?: "LodgingEnrichedMessage";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  badge?: Maybe<Badge>;
  egdsMark?: Maybe<Mark>;
  icon?: Maybe<Icon>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  mark?: Maybe<LodgingMark>;
  state?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use this field. Deprecated in favor of a new type that will include two message types that can be used to display main text and sub text. */
  subText?: Maybe<Scalars["String"]>;
  subtexts?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated Use state to indicate theme. */
  theme?: Maybe<LodgingMessageTheme>;
  value: Scalars["String"];
};

export type LodgingErrorResponse = {
  __typename?: "LodgingErrorResponse";
  body?: Maybe<Scalars["String"]>;
  button: UiPrimaryButton;
  errorRecoveryButtons?: Maybe<Array<UiTertiaryButton>>;
  header?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
};

export interface LodgingExpando {
  collapseButton: LodgingButton;
  expandButton: LodgingButton;
  items?: Maybe<Scalars["Int"]>;
  lines?: Maybe<Scalars["Int"]>;
}

export type LodgingExpandoItemsCard = {
  __typename?: "LodgingExpandoItemsCard";
  background?: Maybe<Scalars["String"]>;
  expando?: Maybe<EgdsExpando>;
  header?: Maybe<LodgingHeader>;
  items: Array<EgdsTextIconListItem>;
};

export type LodgingExpandoPeek = LodgingExpando & {
  __typename?: "LodgingExpandoPeek";
  collapseButton: LodgingButton;
  expandButton: LodgingButton;
  items?: Maybe<Scalars["Int"]>;
  lines?: Maybe<Scalars["Int"]>;
};

export type LodgingExperiment = {
  __typename?: "LodgingExperiment";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type LodgingExperimentOwner = {
  __typename?: "LodgingExperimentOwner";
  channels: Array<Scalars["String"]>;
  org: Scalars["String"];
  team: Scalars["String"];
};

export interface LodgingExperimentalFeature {
  description: Scalars["String"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
}

export type LodgingExperimentalMetaData = LodgingExperimentalFeature & {
  __typename?: "LodgingExperimentalMetaData";
  description: Scalars["String"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
};

export interface LodgingExperimentalSection {
  features: Array<LodgingExperimentalMetaData>;
}

export type LodgingExperimentalSectionFeaturesArgs = {
  createdAfter?: InputMaybe<DateInput>;
  createdBefore?: InputMaybe<DateInput>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  org?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<Scalars["String"]>;
};

export type LodgingFilterAttributes = {
  __typename?: "LodgingFilterAttributes";
  category: Scalars["String"];
  id: Scalars["String"];
  position: Scalars["Int"];
  value?: Maybe<Scalars["String"]>;
};

export type LodgingFilterClickstreamEvents = {
  __typename?: "LodgingFilterClickstreamEvents";
  filterDeselected?: Maybe<LodgingFilterEvent>;
  filterSelected?: Maybe<LodgingFilterEvent>;
};

export type LodgingFilterDialogPill = {
  __typename?: "LodgingFilterDialogPill";
  filterDialog?: Maybe<LodgingFilterSelectionDialog>;
  triggerPill?: Maybe<EgdsBasicTriggerPill>;
};

export type LodgingFilterEvent = {
  __typename?: "LodgingFilterEvent";
  event: EgClickstreamEvent;
  search_request: LodgingSearchRequest;
};

export type LodgingFilterPill = EgdsBasicPill | LodgingFilterDialogPill;

export type LodgingFilterSelectionDialog = {
  __typename?: "LodgingFilterSelectionDialog";
  clearAll?: Maybe<EgdsButton>;
  dialog: EgdsActionDialog;
  filterSection?: Maybe<ShoppingSortAndFilterField>;
  toolbar: EgdsDialogToolbar;
};

export type LodgingForm = {
  __typename?: "LodgingForm";
  action: Scalars["String"];
  inputs?: Maybe<Array<LodgingFormInput>>;
  method: LodgingFormMethod;
  submit: LodgingButton;
};

export type LodgingFormFieldSelector = EgdsBasicCheckBox | EgdsBasicSelect | EgdsBasicStepInput;

export type LodgingFormInput = LodgingTextInput;

export type LodgingFormMethod = "GET" | "POST";

export type LodgingFormSelector = {
  __typename?: "LodgingFormSelector";
  primary: LodgingFormFieldSelector;
  secondary?: Maybe<LodgingSelectorSection>;
};

export type LodgingGalleryCarousel = EgdsGalleryCarousel & {
  __typename?: "LodgingGalleryCarousel";
  accessibilityHeadingText: Scalars["String"];
  adaptExAnalyticsAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  adaptExAnalyticsSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  exitAnalytics?: Maybe<ClientSideAnalytics>;
  imageClickAnalytics?: Maybe<ClientSideAnalytics>;
  intersectionAnalytics?: Maybe<ClientSideAnalytics>;
  media: Array<LodgingMediaItem>;
  navClickAnalytics?: Maybe<ClientSideAnalytics>;
  nextButtonText?: Maybe<Scalars["String"]>;
  previousButtonText?: Maybe<Scalars["String"]>;
};

export type LodgingGalleryDialog = LodgingDialog & {
  __typename?: "LodgingGalleryDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingGraphic = EgdsStandardBadge | Icon | Illustration | Mark;

export type LodgingGuestGroup = {
  __typename?: "LodgingGuestGroup";
  adults: LodgingFormSelector;
  children?: Maybe<LodgingFormSelector>;
  maxChildren?: Maybe<Scalars["Int"]>;
  maxGuest: Scalars["Int"];
};

export type LodgingGuestGroups = LodgingGuestGroup | LodgingPetGroup;

export type LodgingHeader = AccessibilityField & {
  __typename?: "LodgingHeader";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use EGDSStandardBadge */
  badge?: Maybe<Badge>;
  egdsStandardBadge?: Maybe<EgdsStandardBadge>;
  headerImage?: Maybe<Image>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  label?: Maybe<Scalars["String"]>;
  mark?: Maybe<Mark>;
  subText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type LodgingHeading = {
  __typename?: "LodgingHeading";
  /** @deprecated No longer used in latest version of uitk-react-text */
  level: Scalars["Int"];
  size: Scalars["Int"];
  tag?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type LodgingIllustration = {
  __typename?: "LodgingIllustration";
  /** @deprecated Do not use. Use Illustration.url instead. */
  assetUri?: Maybe<HttpUri>;
  /** @deprecated Do not use. use Illustration.description instead. */
  description?: Maybe<Scalars["String"]>;
};

export type LodgingInputDialogTrigger = FindAnswersDialogTrigger & {
  __typename?: "LodgingInputDialogTrigger";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  component: LodgingTextInput;
};

export type LodgingInputType = "EMAIL" | "HIDDEN" | "PASSWORD" | "TEL" | "TEXT" | "URL";

export type LodgingInstallmentPlan = {
  __typename?: "LodgingInstallmentPlan";
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  installmentPlanDetails: InstallmentPlanDetails;
};

export type LodgingInstallmentPlanOptions = {
  __typename?: "LodgingInstallmentPlanOptions";
  textRequired: Scalars["Boolean"];
  totalPrice: Scalars["String"];
};

export type LodgingIntentCard = {
  __typename?: "LodgingIntentCard";
  gallery: LodgingGalleryCarousel;
  headingTitle: Scalars["String"];
  impressionTracking: ClientSideImpressionEventAnalytics;
  message?: Maybe<Scalars["String"]>;
  primaryButton: UiPrimaryButton;
};

export interface LodgingJumpLink {
  icon?: Maybe<Icon>;
  localizedName: Scalars["String"];
  sectionName: Scalars["String"];
}

export type LodgingLeadingGraphic = PropertyImage;

export type LodgingLink = {
  __typename?: "LodgingLink";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Don't use this., replace with clientSideAnalytics */
  referrerId?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  uri: HttpUri;
  /** @deprecated use uri, replace with uri */
  url: Scalars["URL"];
};

export type LodgingLinkButton = {
  __typename?: "LodgingLinkButton";
  action?: Maybe<UiLinkAction>;
  button: EgdsButton;
};

export type LodgingLinkMessage = AccessibilityField &
  HeaderMessage & {
    __typename?: "LodgingLinkMessage";
    accessibilityLabel?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
    label?: Maybe<Scalars["String"]>;
    link: LodgingLink;
    value: Scalars["String"];
  };

export type LodgingList = {
  __typename?: "LodgingList";
  items: Array<LodgingListLineItem>;
  title?: Maybe<LodgingHeader>;
};

export type LodgingListLineItem = {
  __typename?: "LodgingListLineItem";
  label?: Maybe<EgdsText>;
  link?: Maybe<EgdsStandardLink>;
};

export type LodgingListingDivider = {
  __typename?: "LodgingListingDivider";
  actions: Array<EgdsButton>;
  backgroundTheme?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<EgdsHeading>;
};

export type LodgingMapDialog = LodgingDialog & {
  __typename?: "LodgingMapDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingMapMarker = EgdsMapMarker & {
  __typename?: "LodgingMapMarker";
  card?: Maybe<LodgingCard>;
  markerIconType: Array<Scalars["String"]>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType: Scalars["String"];
  text: Scalars["String"];
};

export type LodgingMapTrigger = {
  __typename?: "LodgingMapTrigger";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]>;
  large?: Maybe<Image>;
  medium?: Maybe<Image>;
  small?: Maybe<Image>;
};

export type LodgingMark = {
  __typename?: "LodgingMark";
  description: Scalars["String"];
  id: Scalars["String"];
};

export type LodgingMediaItem = MediaItem & {
  __typename?: "LodgingMediaItem";
  id?: Maybe<Scalars["String"]>;
  media: Media;
  trackingId?: Maybe<Scalars["String"]>;
};

export type LodgingMemberSignInDialog = {
  __typename?: "LodgingMemberSignInDialog";
  actionDialog?: Maybe<EgdsActionDialog>;
  dialogContent?: Maybe<EgdsParagraph>;
  title?: Maybe<Scalars["String"]>;
  trigger?: Maybe<LodgingDialogTriggerMessage>;
};

export type LodgingMessageTheme = "AGGRESSIVE" | "EMPHASIS" | "HIGHLIGHT" | "STRONG";

export type LodgingMicroCard = {
  __typename?: "LodgingMicroCard";
  contents: Array<LodgingMicroCardText>;
  heading?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
};

export type LodgingMicroCardSection = {
  __typename?: "LodgingMicroCardSection";
  card: LodgingMicroCard;
  title?: Maybe<Scalars["String"]>;
};

export type LodgingMicroCardText = EgdsGraphicText | EgdsPlainText | EgdsStylizedText;

export type LodgingNotificationsCard = {
  __typename?: "LodgingNotificationsCard";
  header?: Maybe<LodgingHeader>;
  messages: Array<RatePlanMessage>;
};

export type LodgingOfferFilters = {
  __typename?: "LodgingOfferFilters";
  accessibilityHeader?: Maybe<Scalars["String"]>;
  filterPills?: Maybe<Array<LodgingFilterPill>>;
  heading?: Maybe<EgdsHeading>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  liveAnnounce?: Maybe<Scalars["String"]>;
};

export type LodgingOfferListing = LodgingCategorizedUnit | LodgingHeader | MessageResult;

export type LodgingOfferOption = LiveAnnounce & {
  __typename?: "LodgingOfferOption";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  description: Scalars["String"];
  dialog?: Maybe<LodgingPlainDialog>;
  enabled: Scalars["Boolean"];
  liveAnnounceMessage?: Maybe<Scalars["String"]>;
  liveAnnouncePoliteness?: Maybe<LiveAnnouncePoliteness>;
  optionId?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  selected: Scalars["Boolean"];
  stylizedSubTexts?: Maybe<Array<EgdsStylizedText>>;
  /** @deprecated Use stylizedSubText instead */
  subText?: Maybe<Scalars["String"]>;
};

export type LodgingOfferSelectionHeader = {
  __typename?: "LodgingOfferSelectionHeader";
  complexDialog?: Maybe<LodgingOfferSelectionHeaderDialog>;
  /** @deprecated Use complexDialog instead */
  dialog?: Maybe<LodgingComplexDialog>;
  title: LodgingHeader;
};

export type LodgingOfferSelectionHeaderDialog = LodgingComplexDialog | LodgingPolicyDialog;

export type LodgingOneKeyBurnSwitch = {
  __typename?: "LodgingOneKeyBurnSwitch";
  graphic?: Maybe<UiGraphic>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  switch: EgdsStandardSwitch;
};

export type LodgingPagination = {
  __typename?: "LodgingPagination";
  paginationLabel?: Maybe<Scalars["String"]>;
  subSets?: Maybe<LodgingPaginationSubSets>;
  trigger?: Maybe<LodgingPaginationTrigger>;
};

export type LodgingPaginationNavigationButtons = {
  __typename?: "LodgingPaginationNavigationButtons";
  nextButton: UiPrimaryButton;
  previousButton: UiPrimaryButton;
};

export type LodgingPaginationSubSet = {
  __typename?: "LodgingPaginationSubSet";
  size: Scalars["Int"];
  startingIndex: Scalars["Int"];
};

export type LodgingPaginationSubSets = {
  __typename?: "LodgingPaginationSubSets";
  nextSubSet?: Maybe<LodgingPaginationSubSet>;
  previousSubset?: Maybe<LodgingPaginationSubSet>;
};

export type LodgingPaginationTrigger = LodgingPaginationNavigationButtons | LodgingScrollTrigger | UiPrimaryButton;

export type LodgingPaymentOptionsDialogContent = {
  __typename?: "LodgingPaymentOptionsDialogContent";
  messages: Array<EgdsGraphicText>;
};

export type LodgingPetGroup = {
  __typename?: "LodgingPetGroup";
  input: LodgingFormSelector;
};

export type LodgingPill = {
  __typename?: "LodgingPill";
  pillLabel: Scalars["String"];
  query?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type LodgingPlainDialog = LodgingDialog & {
  __typename?: "LodgingPlainDialog";
  content: Array<Scalars["String"]>;
  /** @deprecated use primaryUIButton, replace with primaryUIButton */
  primaryButton?: Maybe<LodgingButton>;
  primaryUIButton?: Maybe<UiPrimaryButton>;
  /** @deprecated use secondaryUIButton, replace with secondaryUIButton */
  secondaryButton?: Maybe<LodgingButton>;
  secondaryUIButton?: Maybe<UiSecondaryButton>;
  /** @deprecated use tertiaryUIButton, replace with tertiaryUIButton */
  tertiaryButton?: Maybe<LodgingButton>;
  tertiaryUIButton?: Maybe<UiTertiaryButton>;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingPlainMessage = HeaderMessage & {
  __typename?: "LodgingPlainMessage";
  value: Scalars["String"];
};

export type LodgingPlottableMapMarker = LodgingMapMarker;

export type LodgingPolicy = {
  __typename?: "LodgingPolicy";
  /** @deprecated Use policyClientSideAnalytics instead */
  clientSideAnalytics: ClientSideAnalytics;
  messageSections: Array<MessageSection>;
  policyClientSideAnalytics?: Maybe<ClientSideAnalytics>;
  stepIndicator: LodgingStepIndicator;
  title?: Maybe<EgdsStylizedText>;
};

export type LodgingPolicyDialog = {
  __typename?: "LodgingPolicyDialog";
  policyContent: LodgingDetailedTimeline;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingPolicyPopover = {
  __typename?: "LodgingPolicyPopover";
  policyPrimaryText: EgdsInlineLink;
  policySecondaryText?: Maybe<EgdsStylizedText>;
  sheet?: Maybe<EgdsSheet>;
  sheetContent?: Maybe<Array<EgdsParagraph>>;
};

export type LodgingPrepareChangeCheckout = {
  __typename?: "LodgingPrepareChangeCheckout";
  orderLineGuid: Scalars["String"];
  orderNumber: Scalars["String"];
  propertyNaturalKey: PropertyNaturalKey;
};

export interface LodgingPrepareChangeCheckoutInput {
  orderLineGuid: Scalars["String"];
  orderNumber: Scalars["String"];
  propertyNaturalKey: PropertyNaturalKeyInput;
}

export type LodgingPrepareCheckout = {
  __typename?: "LodgingPrepareCheckout";
  action: LodgingPrepareCheckoutAction;
  checkoutButton: UiPrimaryButton;
};

export type LodgingPrepareCheckoutAction = {
  __typename?: "LodgingPrepareCheckoutAction";
  adaptExSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  /** @deprecated Use analyticsList instead */
  analytics?: Maybe<ClientSideAnalytics>;
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  checkoutOptions?: Maybe<Array<CheckoutOptions>>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  moreDetailsAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  offerTokens?: Maybe<Array<OfferToken>>;
  totalPrice?: Maybe<Money>;
};

export type LodgingPriceBreakDown = {
  __typename?: "LodgingPriceBreakDown";
  /** @deprecated This field will hold button/CTA localized content and will be behind TnL - 34934,34935. */
  ctaLabel?: Maybe<Scalars["String"]>;
  footer?: Maybe<LodgingPriceBreakDownEntry>;
  header: Scalars["String"];
  priceBreakDownEntries?: Maybe<Array<LodgingPriceBreakDownEntry>>;
  unbundledEntries?: Maybe<Array<LodgingPriceBreakDownEntry>>;
};

export type LodgingPriceBreakDownEntry = {
  __typename?: "LodgingPriceBreakDownEntry";
  amount?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  detail?: Maybe<Scalars["String"]>;
};

export type LodgingPricePresentationExpando = {
  __typename?: "LodgingPricePresentationExpando";
  collapsedAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel?: Maybe<PricePresentationLineItem>;
  expandedAnalytics?: Maybe<ClientSideAnalytics>;
  expandedLabel?: Maybe<PricePresentationLineItem>;
};

export type LodgingPrimaryAltButton = {
  __typename?: "LodgingPrimaryAltButton";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiLinkAction>;
  adaptexSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  analytics?: Maybe<ClientSideAnalytics>;
  attemptEvents: Array<LodgingAdaptExAnalyticsEvent>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  /** @deprecated Use adaptexSuccessActionTracking and a remote source of event detail to reduce duplication. e.g. PropertySearchResults.propertyListingAdaptExAnalyticSuccessEvents */
  successEvents: Array<LodgingAdaptExAnalyticsEvent>;
};

export type LodgingPrimaryOfferSelection = {
  __typename?: "LodgingPrimaryOfferSelection";
  primarySelection?: Maybe<LodgingOfferOption>;
  propertyUnit: PropertyUnit;
  secondarySelections?: Maybe<Array<LodgingSecondaryOfferSelection>>;
};

export type LodgingProduct = {
  __typename?: "LodgingProduct";
  product_id: Scalars["String"];
};

export type LodgingProductEvent = {
  __typename?: "LodgingProductEvent";
  event: EgClickstreamEvent;
  product_list: Array<LodgingProduct>;
  search_request: LodgingSearchRequest;
};

export type LodgingProductView = LodgingProductViewSuccess;

export type LodgingProductViewSuccess = {
  __typename?: "LodgingProductViewSuccess";
  card: LodgingCard;
};

export type LodgingPropertyActivitiesLinkButton = {
  __typename?: "LodgingPropertyActivitiesLinkButton";
  accessibility?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  text: Scalars["String"];
};

export type LodgingPropertyCompare = {
  __typename?: "LodgingPropertyCompare";
  actionSheet?: Maybe<LodgingCompareActionSheet>;
  compareSection?: Maybe<LodgingCompareSectionView>;
  dialog?: Maybe<EgdsFullScreenDialog>;
};

export type LodgingPropertyCompareCompareSectionArgs = {
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  propertyIds?: InputMaybe<Array<Scalars["String"]>>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type LodgingRebound = {
  __typename?: "LodgingRebound";
  itemDetails?: Maybe<Array<LodgingReboundDetail>>;
  messagingCard?: Maybe<UiMessagingCard>;
  messagingDetails?: Maybe<MessagingDetails>;
};

export type LodgingReboundDetail = {
  __typename?: "LodgingReboundDetail";
  bookingDateRange?: Maybe<DateRange>;
  chainId?: Maybe<Scalars["String"]>;
  chainName?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  percentage: Scalars["Int"];
  propertyId?: Maybe<Scalars["String"]>;
  stayDateRange?: Maybe<DateRange>;
};

export type LodgingRecommendationClickstreamEvent = {
  __typename?: "LodgingRecommendationClickstreamEvent";
  recommendationResponseId?: Maybe<Scalars["String"]>;
};

export type LodgingRefundForm = {
  __typename?: "LodgingRefundForm";
  /** @deprecated Move to the AgentToolsChangeOrCancelReason */
  calculator: LodgingCalculator;
  cancelButton: UiSecondaryButton;
  closeButton: UiSecondaryButton;
  disabled?: Maybe<Scalars["Boolean"]>;
  formValidationErrorText: Scalars["String"];
  goBackButton: UiSecondaryButton;
  loadingText: Scalars["String"];
  paymentDetails: LodgingBookingPaymentDetails;
  reviewButton: UiSecondaryButton;
  sections: LodgingRefundFormSections;
  stepIndicator: AgentToolsStepIndicator;
  submitButton: UiSecondaryButton;
};

export type LodgingRefundFormSections = {
  __typename?: "LodgingRefundFormSections";
  additionalRefundReason: AgentToolsChangeOrCancelReason;
  /** @deprecated use additionalRefundReason */
  bookingAdditionalRefundEntry?: Maybe<BookingRefundEntry>;
  /** @deprecated use refundReason */
  bookingRefundEntry?: Maybe<BookingRefundEntry>;
  refundReason?: Maybe<AgentToolsChangeOrCancelReason>;
};

export type LodgingReviewSummary = {
  __typename?: "LodgingReviewSummary";
  checkInScore?: Maybe<SummaryUnit>;
  locationScore?: Maybe<SummaryUnit>;
  propertyId: Scalars["String"];
  roomsScore?: Maybe<Array<RoomSummary>>;
};

export type LodgingSaveItem = {
  __typename?: "LodgingSaveItem";
  analytics?: Maybe<ClientSideAnalytics>;
  initialChecked: Scalars["Boolean"];
  items: Array<LodgingSaveItemItem>;
  labels?: Maybe<LodgingSaveItemLabels>;
  messages?: Maybe<LodgingSaveItemMessages>;
};

export type LodgingSaveItemItem = {
  __typename?: "LodgingSaveItemItem";
  itemId: Scalars["String"];
};

export type LodgingSaveItemLabels = {
  __typename?: "LodgingSaveItemLabels";
  removeLabel?: Maybe<Scalars["String"]>;
  saveLabel?: Maybe<Scalars["String"]>;
};

export type LodgingSaveItemMessages = {
  __typename?: "LodgingSaveItemMessages";
  removeMessage?: Maybe<Scalars["String"]>;
  saveMessage?: Maybe<Scalars["String"]>;
};

export type LodgingScrollTrigger = {
  __typename?: "LodgingScrollTrigger";
  analytics?: Maybe<ClientSideAnalytics>;
  triggerAfterPosition?: Maybe<Scalars["Int"]>;
};

export type LodgingSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "LodgingSearchForm";
    actionURL: Scalars["String"];
    adaptExSuccessEvent?: Maybe<SearchFormAdaptExSuccessEvent>;
    button: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    lodgingSearchFormInputIds: LodgingSearchFormInputIds;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    softPackages?: Maybe<SoftPackages>;
    valuePropMessage?: Maybe<SearchFormMessaging>;
  };

export type LodgingSearchFormFixedInputs = EgdsSearchInputs & {
  __typename?: "LodgingSearchFormFixedInputs";
  shoppingSearchCriteria?: Maybe<ShoppingSearchCriteria>;
};

export type LodgingSearchFormInputIds = {
  __typename?: "LodgingSearchFormInputIds";
  dateRangeInputId: Scalars["String"];
  destinationInputId: Scalars["String"];
  shoppingSearchCriteriaIds?: Maybe<Array<Scalars["String"]>>;
  travelerSelectorId: Scalars["String"];
};

export type LodgingSearchRequest = {
  __typename?: "LodgingSearchRequest";
  filters?: Maybe<Array<LodgingFilterAttributes>>;
  search_id: Scalars["String"];
};

export type LodgingSearchResultHeaderMessage = EgdsPlainText | EgdsStandardLink | ShoppingLink;

export type LodgingSecondaryOfferSelection = {
  __typename?: "LodgingSecondaryOfferSelection";
  /** @deprecated Deprecated for TNL 41893 - ABS recommendations */
  recommendedSelection?: Maybe<Scalars["Boolean"]>;
  secondarySelection?: Maybe<LodgingOfferOption>;
  tertiarySelections?: Maybe<Array<LodgingOfferOption>>;
};

export type LodgingSelectorSection = {
  __typename?: "LodgingSelectorSection";
  inputs: Array<LodgingFormFieldSelector>;
  title?: Maybe<LodgingEnrichedMessage>;
};

export type LodgingShoppingAction = SelectPackageActionInput;

export type LodgingSimilarSelectionOptions = {
  __typename?: "LodgingSimilarSelectionOptions";
  options: Array<Maybe<LodgingLinkMessage>>;
  title?: Maybe<Scalars["String"]>;
};

export type LodgingStandardBadge = {
  __typename?: "LodgingStandardBadge";
  impressionEventAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  standardBadge: EgdsStandardBadge;
};

export type LodgingStepIndicator = {
  __typename?: "LodgingStepIndicator";
  accessibilityLabel: Scalars["String"];
  steppers: Array<LodgingStepper>;
};

export type LodgingStepper = {
  __typename?: "LodgingStepper";
  state: LodgingStepperState;
  text: EgdsStylizedText;
  track?: Maybe<LodgingTrack>;
};

export type LodgingStepperState = "COMPLETE" | "CURRENT" | "INCOMPLETE";

export type LodgingStickyButton = LodgingButtonAction & {
  __typename?: "LodgingStickyButton";
  adaptExSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  analytics?: Maybe<ClientSideAnalytics>;
  targetRef?: Maybe<PropertySectionRef>;
  text?: Maybe<Scalars["String"]>;
};

export type LodgingSurveyTrigger = {
  __typename?: "LodgingSurveyTrigger";
  view: Scalars["String"];
};

export type LodgingTextInput = {
  __typename?: "LodgingTextInput";
  icon?: Maybe<Icon>;
  name?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  type?: Maybe<LodgingInputType>;
  value?: Maybe<Scalars["String"]>;
};

export type LodgingToastMessage = {
  __typename?: "LodgingToastMessage";
  value: Scalars["String"];
};

export type LodgingToggle = UiToggle & {
  __typename?: "LodgingToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics: ClientSideAnalytics;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics: ClientSideAnalytics;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
};

export type LodgingTrack = {
  __typename?: "LodgingTrack";
  position: LodgingTrackPosition;
  text?: Maybe<EgdsStylizedText>;
};

export type LodgingTrackPosition = "END" | "STANDARD" | "START";

export type LodgingTripUpgrade = {
  __typename?: "LodgingTripUpgrade";
  callToAction?: Maybe<LodgingTripUpgradeCallToAction>;
  /** @deprecated Replace with PrepareChangeCheckoutResponse.error */
  error?: Maybe<LodgingTripUpgradeError>;
  /** @deprecated Replace with callToAction */
  form?: Maybe<LodgingForm>;
  heading?: Maybe<LodgingHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items?: Maybe<Array<LodgingTripUpgradeItem>>;
};

export type LodgingTripUpgradeAction = LodgingTripUpgradePrepareChangeCheckout | LodgingTripUpgradeRedirectAction;

export type LodgingTripUpgradeCallToAction = {
  __typename?: "LodgingTripUpgradeCallToAction";
  action?: Maybe<LodgingTripUpgradeAction>;
  button?: Maybe<EgdsButton>;
};

export type LodgingTripUpgradeError = {
  __typename?: "LodgingTripUpgradeError";
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  toast?: Maybe<EgdsToast>;
};

export type LodgingTripUpgradeItem = {
  __typename?: "LodgingTripUpgradeItem";
  primary?: Maybe<Scalars["String"]>;
};

export type LodgingTripUpgradePrepareChangeCheckout = {
  __typename?: "LodgingTripUpgradePrepareChangeCheckout";
  lodgingInput: LodgingPrepareChangeCheckout;
  options: Array<PrepareChangeCheckoutOption>;
  tripId: Scalars["String"];
};

export type LodgingTripUpgradeRedirectAction = {
  __typename?: "LodgingTripUpgradeRedirectAction";
  url: Scalars["URL"];
};

export type LodgingTripUpgrades = {
  __typename?: "LodgingTripUpgrades";
  heading?: Maybe<Scalars["String"]>;
  upgrades?: Maybe<Array<LodgingTripUpgrade>>;
};

export type LodgingUnitCard = {
  __typename?: "LodgingUnitCard";
  /** @deprecated callout is deprecated, use trigger.value with theme:AGGRESSIVE */
  callOut?: Maybe<EgdsStylizedText>;
  /** @deprecated cardLink is deprecated, use unitId to open more-details-modal */
  cardLink?: Maybe<UiLinkAction>;
  header?: Maybe<EgdsHeading>;
  image?: Maybe<Image>;
  /** @deprecated link is deprecated, use trigger field to display pricing */
  link?: Maybe<EgdsInlineLink>;
  trigger?: Maybe<LodgingDialogTriggerMessage>;
  unitId?: Maybe<Scalars["String"]>;
};

export type LodgingUnitGroup = {
  __typename?: "LodgingUnitGroup";
  inputs: Array<LodgingGuestGroups>;
};

export type LodgingWaiverCalculator = {
  __typename?: "LodgingWaiverCalculator";
  amountInput: AgentToolsNumberInput;
  penaltyAmount: Scalars["Float"];
  percentageInput: AgentToolsNumberInput;
  waiverType: EgdsBasicRadioGroup;
};

export type LogoutResponse = {
  __typename?: "LogoutResponse";
  logoutUrl: Scalars["String"];
  presenceResponse: UpdatePresenceResponse;
};

export type Loyalty = {
  __typename?: "Loyalty";
  /** @deprecated Used with the legacy loyalty status only. Will be removed in the future */
  member: LoyaltyMember;
  /** @deprecated Used with the legacy loyalty status only. Will be removed in the future */
  memberStatus: Scalars["String"];
};

export type LoyaltyAccountBadge = {
  __typename?: "LoyaltyAccountBadge";
  creditCardIcon?: Maybe<UiGraphic>;
  separator?: Maybe<Scalars["String"]>;
  tier: EgdsStandardBadge;
};

export type LoyaltyAccountSummary = {
  __typename?: "LoyaltyAccountSummary";
  availableValueSection?: Maybe<LoyaltyRewardsValueSection>;
  badge?: Maybe<LoyaltyAccountBadge>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated tierBadge field is deprecated, use tier field instead., replace with tier */
  tierBadge?: Maybe<EgdsStandardBadge>;
  traveler?: Maybe<LoyaltyAccountTraveler>;
  viewRewardsLinkSection?: Maybe<LoyaltyViewRewardsLinkSection>;
};

export type LoyaltyAccountTraveler = {
  __typename?: "LoyaltyAccountTraveler";
  emailAddress: Scalars["String"];
  title: Scalars["String"];
};

export type LoyaltyAnchoredCurrencyDescription = {
  __typename?: "LoyaltyAnchoredCurrencyDescription";
  balance: EgdsStylizedText;
  subtitle: EgdsStylizedText;
};

export type LoyaltyAnchoredCurrencySection = {
  __typename?: "LoyaltyAnchoredCurrencySection";
  anchoredBalance: LoyaltyAnchoredCurrencyDescription;
  posaBalance: LoyaltyAnchoredCurrencyDescription;
  transferIcon: Icon;
};

export type LoyaltyBodyTypes = LoyaltyEarnCalculationWidgetContentWithCta | LoyaltyEarnCalculationWidgetSpannableText;

export type LoyaltyBookingContentSection = {
  __typename?: "LoyaltyBookingContentSection";
  content: Array<LoyaltyBookingSectionDetailsList>;
  /** @deprecated details field is deprecated, use content field instead (contains the OneKeyCash earn breakdown and pending date), replace with content */
  details: LoyaltyBookingSectionDetails;
  header: Scalars["String"];
};

export type LoyaltyBookingExpandoContent = {
  __typename?: "LoyaltyBookingExpandoContent";
  header?: Maybe<Scalars["String"]>;
  sections: Array<LoyaltyBookingContentSection>;
  /** @deprecated title field is deprecated, use header field instead (same but can be null)., replace with header */
  title: Scalars["String"];
};

export type LoyaltyBookingSectionDetails = EgdsPlainText | EgdsStandardLink;

export type LoyaltyBookingSectionDetailsList = EgdsPlainText | EgdsStandardLink | EgdsStylizedText;

export type LoyaltyConversionExpandoContent = {
  __typename?: "LoyaltyConversionExpandoContent";
  link?: Maybe<EgdsStandardLink>;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type LoyaltyEarnCalculationDetailsCard = {
  __typename?: "LoyaltyEarnCalculationDetailsCard";
  backgroundTheme?: Maybe<Scalars["String"]>;
  body: LoyaltyBodyTypes;
  containerLink?: Maybe<EgdsInlineLink>;
  graphic?: Maybe<UiGraphic>;
  triggerIcon?: Maybe<Icon>;
  /** @deprecated userAccountIcon field is deprecated, use graphic field instead, replace with graphic */
  userAccountIcon?: Maybe<Icon>;
};

export type LoyaltyEarnCalculationWidgetCta = {
  __typename?: "LoyaltyEarnCalculationWidgetCTA";
  widgetButton: UiTertiaryButton;
  widgetContent: LoyaltyEarnCalculationWidgetCtaContent;
};

export type LoyaltyEarnCalculationWidgetCtaContent = {
  __typename?: "LoyaltyEarnCalculationWidgetCTAContent";
  list: EgdsBulletedList;
  title: EgdsHeading;
};

export type LoyaltyEarnCalculationWidgetContentWithCta = {
  __typename?: "LoyaltyEarnCalculationWidgetContentWithCTA";
  text: Array<LoyaltyEarnMessageSpannableTextTypes>;
  viewDetailsCTA?: Maybe<LoyaltyEarnCalculationWidgetCta>;
};

export type LoyaltyEarnCalculationWidgetSpannableText = {
  __typename?: "LoyaltyEarnCalculationWidgetSpannableText";
  contents: Array<LoyaltyEarnMessageSpannableTextTypes>;
  text: Scalars["String"];
};

export type LoyaltyEarnCalculationWidgetSubMenu = EgdsSubMenu & {
  __typename?: "LoyaltyEarnCalculationWidgetSubMenu";
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]>;
};

export type LoyaltyEarnMessageSpannableTextTypes = EgdsPlainText | EgdsStylizedText | EgdsTextMenu;

export type LoyaltyEducation = {
  __typename?: "LoyaltyEducation";
  action?: Maybe<LoyaltyEducationAction>;
  content: LoyaltyEducationContent;
  title?: Maybe<Scalars["String"]>;
};

export type LoyaltyEducationAction = {
  __typename?: "LoyaltyEducationAction";
  href?: Maybe<Scalars["String"]>;
  rfrr?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type LoyaltyEducationContent = LoyaltyPointsEducation | LoyaltyProgramEducation;

export type LoyaltyEducationPerks = {
  __typename?: "LoyaltyEducationPerks";
  descriptions: Array<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
};

export type LoyaltyEducationSection = {
  __typename?: "LoyaltyEducationSection";
  description?: Maybe<Scalars["String"]>;
  perks?: Maybe<LoyaltyEducationPerks>;
  title?: Maybe<Scalars["String"]>;
};

export type LoyaltyExpandoCardItem = {
  __typename?: "LoyaltyExpandoCardItem";
  expandoCard: EgdsExpandoCard;
  icon: Icon;
  status: LoyaltyRewardStatusSection;
};

export type LoyaltyExperienceStrategy = "HIDE_TRAVELER_INFO_AND_REWARDS_LINK" | "SHOW_TRAVELER_INFO_AND_REWARDS_LINK";

export type LoyaltyGenericExpandoContent = {
  __typename?: "LoyaltyGenericExpandoContent";
  /** @deprecated content field is deprecated, use contents field instead, replace with contents */
  content: Scalars["String"];
  contents: Array<Scalars["String"]>;
  link?: Maybe<EgdsStandardLink>;
  title: Scalars["String"];
};

export type LoyaltyIconAndDescription = {
  __typename?: "LoyaltyIconAndDescription";
  iconInfoContentSection: LoyaltyIconInfoContentSection;
  infoIcon: Icon;
};

export type LoyaltyIconInfoContentSection = {
  __typename?: "LoyaltyIconInfoContentSection";
  actionText: Scalars["String"];
  contents?: Maybe<Array<LoyaltyIconInfoOverlaySubContent>>;
  /** @deprecated Deprecated. Use contents instead */
  description: Scalars["String"];
  heading: Scalars["String"];
};

export type LoyaltyIconInfoOverlaySubContent = LoyaltyAnchoredCurrencySection | LoyaltyStylizedText;

export type LoyaltyLegacyOnboardingButton = {
  __typename?: "LoyaltyLegacyOnboardingButton";
  action: UiLinkAction;
  button: EgdsButton;
};

export type LoyaltyLegacyOnboardingGettingStarted = {
  __typename?: "LoyaltyLegacyOnboardingGettingStarted";
  headerBrandLogo?: Maybe<Image>;
  headerBrandLogoDark?: Maybe<Image>;
  heroImage?: Maybe<Image>;
  image?: Maybe<Image>;
  primaryButton: LoyaltyLegacyOnboardingButton;
  subTitles: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type LoyaltyLegacyOnboardingScreen = {
  __typename?: "LoyaltyLegacyOnboardingScreen";
  screen: LoyaltyLegacyOnboardingScreenResult;
};

export type LoyaltyLegacyOnboardingScreenResult = LoyaltyLegacyOnboardingGettingStarted;

export type LoyaltyMediaItem = MediaItem & {
  __typename?: "LoyaltyMediaItem";
  media: Media;
};

export type LoyaltyMember = {
  __typename?: "LoyaltyMember";
  /** @deprecated Used with the legacy loyalty status only. Will be removed in the future */
  firstName: Scalars["String"];
};

export type LoyaltyMessagingCard = {
  __typename?: "LoyaltyMessagingCard";
  card?: Maybe<EgdsStandardMessagingCard>;
};

export type LoyaltyNoRecentActivity = {
  __typename?: "LoyaltyNoRecentActivity";
  description?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  header: Scalars["String"];
};

export type LoyaltyPointsEducation = {
  __typename?: "LoyaltyPointsEducation";
  description?: Maybe<Scalars["String"]>;
  heroImage?: Maybe<Image>;
  programFeatures: Array<LoyaltyEducationSection>;
};

export type LoyaltyPointsOption = {
  __typename?: "LoyaltyPointsOption";
  formattedPoints?: Maybe<Scalars["String"]>;
  leadingCaption: Scalars["String"];
  pointsAmount?: Maybe<FormattedNumber>;
  pointsFirst: Scalars["Boolean"];
  pointsUnit?: Maybe<Scalars["String"]>;
};

export type LoyaltyPrice = {
  __typename?: "LoyaltyPrice";
  amount?: Maybe<FormattedNumber>;
  promotionValue?: Maybe<Scalars["String"]>;
  totalStrikeOutPoints?: Maybe<FormattedNumber>;
  unit?: Maybe<Scalars["String"]>;
};

export type LoyaltyProgramEducation = {
  __typename?: "LoyaltyProgramEducation";
  joinProgramText?: Maybe<Scalars["String"]>;
  perks: LoyaltyEducationPerks;
};

export type LoyaltyProgramTitle = {
  __typename?: "LoyaltyProgramTitle";
  infoIconAndDescription?: Maybe<LoyaltyIconAndDescription>;
  superScript?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type LoyaltyRewardStatusSection = {
  __typename?: "LoyaltyRewardStatusSection";
  amountChanged: EgdsStylizedText;
  description?: Maybe<EgdsStylizedText>;
  rewardsProgramGraphic?: Maybe<UiGraphic>;
  /** @deprecated Deprecated as this can be null now., replace with description */
  status: EgdsStylizedText;
};

export type LoyaltyRewardsActivity = {
  __typename?: "LoyaltyRewardsActivity";
  content: LoyaltyRewardsActivityHistoryContent;
  rewardFilter: EgdsSelect;
  title?: Maybe<Scalars["String"]>;
};

export type LoyaltyRewardsActivityExpandoContent =
  | LoyaltyBookingExpandoContent
  | LoyaltyConversionExpandoContent
  | LoyaltyGenericExpandoContent;

export type LoyaltyRewardsActivityHistoryContent = LoyaltyNoRecentActivity | LoyaltyRewardsActivityItems;

export type LoyaltyRewardsActivityHistoryRecord = {
  __typename?: "LoyaltyRewardsActivityHistoryRecord";
  content: LoyaltyRewardsActivityExpandoContent;
  expando: LoyaltyExpandoCardItem;
};

export type LoyaltyRewardsActivityItems = {
  __typename?: "LoyaltyRewardsActivityItems";
  records: Array<LoyaltyRewardsActivityHistoryRecord>;
};

export type LoyaltyRewardsAdditionalInformation = {
  __typename?: "LoyaltyRewardsAdditionalInformation";
  card: EgdsStandardMessagingCard;
  onClick: UiLinkAction;
  onClickAnalytics?: Maybe<ClickstreamAnalyticsData>;
};

export type LoyaltyRewardsAdditionalInformations = {
  __typename?: "LoyaltyRewardsAdditionalInformations";
  cards: Array<LoyaltyRewardsAdditionalInformation>;
  subtitles?: Maybe<Array<EgdsStylizedText>>;
  title?: Maybe<Scalars["String"]>;
};

export type LoyaltyRewardsHeading = {
  __typename?: "LoyaltyRewardsHeading";
  backButton?: Maybe<EgdsButton>;
  text: Scalars["String"];
};

export type LoyaltyRewardsResults = {
  __typename?: "LoyaltyRewardsResults";
  additionalInformation: Array<LoyaltyRewardsAdditionalInformations>;
  heading?: Maybe<LoyaltyRewardsHeading>;
};

export type LoyaltyRewardsValueSection = {
  __typename?: "LoyaltyRewardsValueSection";
  rewardsAmount: Scalars["String"];
  supportingMessages?: Maybe<Array<EgdsText>>;
  title: LoyaltyProgramTitle;
};

export type LoyaltyStandardLink = {
  __typename?: "LoyaltyStandardLink";
  analytics?: Maybe<ClickstreamAnalyticsData>;
  link: EgdsStandardLink;
};

export type LoyaltyStylizedText = {
  __typename?: "LoyaltyStylizedText";
  stylizedText: EgdsStylizedText;
};

export type LoyaltyTierBenefitsDetails = {
  __typename?: "LoyaltyTierBenefitsDetails";
  collapseAnalytics?: Maybe<ClickstreamAnalyticsData>;
  expandAnalytics?: Maybe<ClickstreamAnalyticsData>;
  expando?: Maybe<EgdsExpandoCard>;
  sections?: Maybe<Array<LoyaltyTierBenefitsSection>>;
};

export type LoyaltyTierBenefitsSection = {
  __typename?: "LoyaltyTierBenefitsSection";
  contents: Array<LoyaltyTierBenefitsSectionContent>;
};

export type LoyaltyTierBenefitsSectionContent = EgdsLoyaltyBadge | EgdsStylizedText | LoyaltyStandardLink;

export type LoyaltyTierProgression = {
  __typename?: "LoyaltyTierProgression";
  sections: Array<LoyaltyTierProgressionSection>;
};

export type LoyaltyTierProgressionDetails = {
  __typename?: "LoyaltyTierProgressionDetails";
  description?: Maybe<Scalars["String"]>;
  tierProgressionDetails: EgdsProgressBar;
  /** @deprecated Deprecated as tierProgressionDetails.title is no longer null, replace with tierProgressionDetails.title */
  title: Scalars["String"];
};

export type LoyaltyTierProgressionSection = {
  __typename?: "LoyaltyTierProgressionSection";
  content: LoyaltyTierProgressionSectionContent;
};

export type LoyaltyTierProgressionSectionContent =
  | EgdsBasicSectionHeading
  | EgdsStandardLink
  | LoyaltyTierBenefitsDetails
  | LoyaltyTierProgressionDetails
  | StandardLink;

export type LoyaltyViewRewardsLinkSection = {
  __typename?: "LoyaltyViewRewardsLinkSection";
  containerLink?: Maybe<EgdsInlineLink>;
  containerLinkAnalytics?: Maybe<ClickstreamAnalyticsData>;
  rewardsIcon?: Maybe<Icon>;
  rewardsText: Scalars["String"];
  triggerIcon: Icon;
};

export type MfaVerificationMethod = "CALL" | "SMS";

export type Moderation_Rule = "ADVISE" | "BLOCK";

export type Moderation_Type = "CHARACTER" | "WORD";

export type ModItem = {
  __typename?: "MODItem";
  modAvailabilityText?: Maybe<Scalars["String"]>;
  modSignInText?: Maybe<Scalars["String"]>;
  modTagText?: Maybe<Scalars["String"]>;
  signInUrl?: Maybe<HttpUri>;
};

export type ModItemSignInUrlArgs = {
  returnPath?: InputMaybe<Scalars["String"]>;
};

export type MailToUri = Uri & {
  __typename?: "MailToURI";
  emailAddress: Scalars["String"];
  value: Scalars["String"];
};

export type ManagementResponse = {
  __typename?: "ManagementResponse";
  date?: Maybe<Scalars["String"]>;
  displayLocale?: Maybe<Scalars["String"]>;
  header?: Maybe<LodgingHeader>;
  id?: Maybe<Scalars["String"]>;
  response?: Maybe<Scalars["String"]>;
  userNickname?: Maybe<Scalars["String"]>;
};

export type MandatoryFees = {
  __typename?: "MandatoryFees";
  dailyFees: Money;
  totalFees: Money;
  totalPriceWithFees?: Maybe<Money>;
};

export type ManualPriceCheckSection = {
  __typename?: "ManualPriceCheckSection";
  dialog: EgdsDialog;
  loader?: Maybe<PriceMatchPromiseLoader>;
  mainCopy: Array<EgdsComposableTextElement>;
  priceCheckButton?: Maybe<UiPrimaryButton>;
  secondaryCopy?: Maybe<Array<EgdsComposableTextElement>>;
};

export interface MapConfiguration {
  /** @deprecated Use accessToken from EGDSDynamicMapConfig */
  api: Scalars["String"];
  /** @deprecated Use the field from EGDSDynamicMapConfig */
  externalConfigEndpoint?: Maybe<HttpUri>;
  /** @deprecated Map provider api are provider by uitk-react-map or eg-maps in order to ensure that the version has been tested so dont use this field */
  url: Scalars["URL"];
}

export type MapFeatureEvent = EgdsMapMarker & {
  __typename?: "MapFeatureEvent";
  mapFeature: EgdsMapFeature;
  mapPinSelectedEvent: Scalars["AnalyticsPayload"];
  markerIconType?: Maybe<Array<Scalars["String"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type MapIconType =
  | "AIRPORT"
  | "CIRCLE"
  | "GROCERY"
  | "HOTEL"
  | "LANDMARK"
  | "METRO"
  | "RESTAURANT"
  | "SOLD_OUT"
  | "TRAIN"
  | "VACATION_RENTAL";

export type MapMarker = {
  __typename?: "MapMarker";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon: MapIconType;
  label: Scalars["String"];
  latLong: Coordinates;
  type?: Maybe<MarkerType>;
};

export type MapToolbar = {
  __typename?: "MapToolbar";
  actions: Array<MapToolbarActionsType>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type MapToolbarActionsType = "FILTERS";

export type Mark = {
  __typename?: "Mark";
  description: Scalars["String"];
  id: Scalars["String"];
  orientation?: Maybe<Orientation>;
  size?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  url?: Maybe<HttpUri>;
};

export type MarkWrapper = {
  __typename?: "MarkWrapper";
  mark: Mark;
};

export type MarkerType = "HOTEL" | "POI";

export type MarketingFeeDetails = {
  __typename?: "MarketingFeeDetails";
  egdsMark?: Maybe<Mark>;
  formattedFee?: Maybe<Scalars["String"]>;
  mark?: Maybe<LodgingMark>;
  /** @deprecated Obtain through marketingFeeMessageDialog., replace with marketingFeeMessageDialog */
  marketingFeeMessage: LodgingPlainMessage;
  marketingFeeMessageDialog?: Maybe<LodgingBasicContentActionDialog>;
  /** @deprecated Obtain through the tierMessage field instead, replace with tierMessage */
  tierIcon: Scalars["String"];
  tierMessage: LodgingEnrichedMessage;
  /** @deprecated Obtain through the tierMessage field instead, replace with tierMessage */
  tierName: Scalars["String"];
};

export interface MarketingInfoInput {
  googleClickIdentifier?: InputMaybe<Scalars["String"]>;
  metaDistributionPartnersChannelIdentifier?: InputMaybe<Scalars["String"]>;
  metaDistributionPartnersDetails?: InputMaybe<Scalars["String"]>;
}

export type MarketingSection = {
  __typename?: "MarketingSection";
  /** @deprecated This is deprecated. Please use feeDialog instead */
  feeDetails: MarketingFeeDetails;
  feeDialog: LodgingPlainDialog;
  paymentDetails?: Maybe<LodgingEnrichedMessage>;
  title?: Maybe<LodgingHeader>;
};

export type MarkupText = {
  __typename?: "MarkupText";
  markupType: MarkupTextType;
  text: Scalars["String"];
};

export type MarkupTextType = "HTML";

export type MarqueeCardType = "CENTER_STAGE" | "COLLECTION" | "FULL_BLEED";

export type MaxRoomsFailure = FailureReason & {
  __typename?: "MaxRoomsFailure";
  description: Scalars["String"];
  groupLinkMessage?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
};

export type MealPlanFilterAmenity = "ALL_INCLUSIVE" | "FREE_BREAKFAST" | "FULL_BOARD" | "HALF_BOARD";

export type Media = EgdsPhotosphereImage | Image | Video;

export type MediaAsset = {
  __typename?: "MediaAsset";
  /** @deprecated This field is deprecated as it is a duplicate of what is in the mediaType */
  altText?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  credit?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  media: EmMediaType;
  mediaAssetType?: Maybe<Scalars["String"]>;
  mediaLibraryId?: Maybe<Scalars["String"]>;
  /** @deprecated This field is deprecated as it is a duplicate please use media instead */
  mediaType: Media;
  targetURL?: Maybe<Scalars["String"]>;
  usage?: Maybe<Scalars["String"]>;
};

export interface MediaItem {
  media: Media;
}

export type MediaTile = ActivityMediaItem | EgdsCarousel;

export type MediaType = "IMAGE" | "VIDEO";

export type MemberPricing = {
  __typename?: "MemberPricing";
  discountPercentage?: Maybe<Scalars["Int"]>;
  leadInMemberOffer?: Maybe<Scalars["Boolean"]>;
  memberOfferAvailable?: Maybe<Scalars["Boolean"]>;
};

export interface MemberPricingInput {
  discountPercentage?: InputMaybe<Scalars["Int"]>;
  leadInMemberOffer?: InputMaybe<Scalars["Boolean"]>;
  memberOfferAvailable?: InputMaybe<Scalars["Boolean"]>;
}

export type MemberProfile = {
  __typename?: "MemberProfile";
  action?: Maybe<UiLinkAction>;
  headerText?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  memberTheme: Scalars["String"];
  subText?: Maybe<Scalars["String"]>;
};

export type MemberWallet = {
  __typename?: "MemberWallet";
  content: MemberWalletContent;
  details: MemberWalletDetails;
  info: MemberWalletInfo;
  oneKeyUserEnabled: Scalars["Boolean"];
};

export type MemberWalletBadge = UiAction & {
  __typename?: "MemberWalletBadge";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  text: Scalars["String"];
  theme: MemberWalletBadgeTheme;
};

export type MemberWalletBadgeTheme = "LOYALTY_HIGH_TIER" | "LOYALTY_LOW_TIER" | "LOYALTY_MIDDLE_TIER";

export type MemberWalletCallToActionLink = {
  __typename?: "MemberWalletCallToActionLink";
  action: UiLinkAction;
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type MemberWalletContent = {
  __typename?: "MemberWalletContent";
  items: Array<MemberWalletContentItems>;
};

export type MemberWalletContentItems = MemberWalletCallToActionLink;

export type MemberWalletCoupons = {
  __typename?: "MemberWalletCoupons";
  label: Scalars["String"];
  linkAction?: Maybe<UiLinkAction>;
  text: Scalars["String"];
};

export type MemberWalletDetails = {
  __typename?: "MemberWalletDetails";
  items: Array<MemberWalletDetailsItems>;
  moreInfo?: Maybe<MemberWalletMoreInfo>;
};

export type MemberWalletDetailsItem = {
  __typename?: "MemberWalletDetailsItem";
  action?: Maybe<UiAction>;
  label: Scalars["String"];
  mark?: Maybe<Mark>;
  text: Scalars["String"];
};

export type MemberWalletDetailsItems = MemberWalletCoupons | MemberWalletDetailsItem | MemberWalletPoints;

export type MemberWalletHeading = {
  __typename?: "MemberWalletHeading";
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type MemberWalletInfo = {
  __typename?: "MemberWalletInfo";
  items: Array<MemberWalletInfoItems>;
};

export type MemberWalletInfoItems = MemberWalletBadge | MemberWalletHeading;

export type MemberWalletMoreInfo = {
  __typename?: "MemberWalletMoreInfo";
  moreInfoLinkAction?: Maybe<UiLinkAction>;
  moreInfoLinkText?: Maybe<Scalars["String"]>;
  moreInfoText: Scalars["String"];
};

export type MemberWalletPoints = {
  __typename?: "MemberWalletPoints";
  action?: Maybe<MemberWalletUiAction>;
  information: PointsInformation;
  label: Scalars["String"];
  text: Scalars["String"];
};

export type MemberWalletUiAction = UiAction & {
  __typename?: "MemberWalletUIAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type MerchCoupons = {
  __typename?: "MerchCoupons";
  coupons: Array<CouponDetail>;
};

export type MerchandisingDetailsCardPath = "CHECKOUT" | "CONFIRMATION";

export type Message = {
  __typename?: "Message";
  alignment: CommunicationCenterMessageAlignment;
  attachments?: Maybe<Array<CommunicationCenterButton>>;
  bookingUpdates?: Maybe<Array<BookingUpdate>>;
  callToActionButtons?: Maybe<Array<CommunicationCenterButton>>;
  description?: Maybe<MessageDescription>;
  icon?: Maybe<Icon>;
  senderName?: Maybe<Scalars["String"]>;
  timeStamp: Scalars["String"];
  title?: Maybe<EgdsStylizedText>;
};

export type MessageContent = {
  __typename?: "MessageContent";
  actions?: Maybe<Array<MessageLink>>;
  analyticsKeyword: Scalars["String"];
  /** @deprecated No longer used - Message Module will use analyticsKeyboard for tracking purposes */
  eventReasonUrn?: Maybe<Scalars["String"]>;
  /** @deprecated No longer used - Message Module will use analyticsKeyboard for tracking purposes */
  eventUrn: Scalars["String"];
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]>;
  /** @deprecated No longer used - Please use graphic */
  icon?: Maybe<UiGraphic>;
  message?: Maybe<Scalars["String"]>;
  theme: MessageContentTheme;
};

export type MessageContentTheme = "CRITICAL" | "ERROR";

export type MessageDescription = {
  __typename?: "MessageDescription";
  text?: Maybe<Scalars["String"]>;
  title?: Maybe<EgdsStylizedText>;
};

export type MessageInput = {
  __typename?: "MessageInput";
  input: EgdsTextAreaInputField;
  sendMessageButton: CommunicationCenterButton;
};

export type MessageLink = {
  __typename?: "MessageLink";
  target?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  uri: Scalars["URL"];
};

export type MessageResponse = {
  __typename?: "MessageResponse";
  messageRC: Scalars["String"];
};

export type MessageResult = AccessibilityField & {
  __typename?: "MessageResult";
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.action.accessibility instead. */
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links instead. */
  action?: Maybe<MessagingResultAction>;
  /** @deprecated Do not use. Use PropertyListingsIconTextExpando.expandedContent instead. */
  content: Array<EgdsIconText>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.graphic instead. */
  featuredImage?: Maybe<Image>;
  footerText?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. */
  index?: Maybe<Scalars["Int"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.message instead. */
  subtitle?: Maybe<MessagingResultTitle>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.heading instead. */
  title?: Maybe<MessagingResultTitle>;
  /** @deprecated This will be removed in the future. */
  type?: Maybe<MessageType>;
};

export type MessageSection = {
  __typename?: "MessageSection";
  primaryInfo: PrimaryInfo;
  secondaryInfo: SecondaryInfo;
};

export type MessageType =
  | "ADDON"
  | "BRANDED_THEME_ACCENT"
  | "BRANDED_THEME_DARK"
  | "BRANDED_THEME_LIGHT"
  | "COMPRESSION"
  | "COUPON"
  | "DOUBLE_POINTS"
  | "FREE_CANCELLATION"
  | "FREE_LX"
  | "HOTRATE"
  | "LEGAL"
  | "LOGIN"
  | "LOYALTY"
  | "NO_CC"
  | "OVERFILTERED"
  | "PARTNER_LINKOFF"
  | "VACATION_RENTALS";

export type MessagingAction = {
  __typename?: "MessagingAction";
  /** @deprecated Do not use. Use EGDSStandardLink.action instead. */
  actionDetails?: Maybe<ActionDetails>;
  /** @deprecated Do not use. Use EGDSStandardLink.action.analytics instead. */
  analytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use the linkUrl field instead, replace with linkUrl */
  href: Scalars["String"];
  /** @deprecated Do not use. Use EGDSStandardLink.icon instead. */
  icon?: Maybe<Icon>;
  /** @deprecated Do not use. Use EGDSStandardLink.action.resource instead. */
  linkUrl: Scalars["String"];
  /** @deprecated Use analytics */
  referrerId?: Maybe<Scalars["String"]>;
  /** @deprecated Use the referrerId field instead, replace with referrerId */
  rfrr?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. Use EGDSStandardLink.text instead. */
  text?: Maybe<Scalars["String"]>;
};

export type MessagingDetails = {
  __typename?: "MessagingDetails";
  elements?: Maybe<Array<CustomUiComposableElement>>;
  heading?: Maybe<Scalars["String"]>;
};

export type MessagingResultAction = {
  __typename?: "MessagingResultAction";
  primary?: Maybe<MessagingAction>;
  secondary?: Maybe<MessagingAction>;
};

export type MessagingResultTitle = {
  __typename?: "MessagingResultTitle";
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.action.accessibility instead. */
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. Use Mark in EGDSStandardMessagingCard.graphic instead. */
  egdsMark?: Maybe<Mark>;
  /** @deprecated Do not use. Use Icon in EGDSStandardMessagingCard.graphic instead. */
  icon?: Maybe<Icon>;
  /** @deprecated Do not use. Use Illustration in EGDSStandardMessagingCard.graphic instead. */
  illustration?: Maybe<LodgingIllustration>;
  /** @deprecated Use egdsMark */
  mark?: Maybe<LodgingMark>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.heading or EGDSStandardMessagingCard.message instead. */
  text?: Maybe<Scalars["String"]>;
  /** @deprecated Do not use. */
  theme?: Maybe<Theme>;
};

export interface MetaDataRequestInput {
  category: Scalars["String"];
  filename?: InputMaybe<Scalars["String"]>;
}

export type MetadataResponse = {
  __typename?: "MetadataResponse";
  values: Array<File>;
};

export type MinNightStayInputField = {
  __typename?: "MinNightStayInputField";
  stepInput?: Maybe<EgdsBasicStepInput>;
};

export type MinNightStayResult = {
  __typename?: "MinNightStayResult";
  minNightStayValue: Scalars["Int"];
};

export type MishopDetailsUiAction = UiAction & {
  __typename?: "MishopDetailsUIAction";
  accessibility?: Maybe<Scalars["String"]>;
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  resource: Uri;
};

export type MishopDetailsUiPrimer = {
  __typename?: "MishopDetailsUIPrimer";
  bookByPhonePrimer?: Maybe<MishopUiBookByPhonePrimer>;
  changePackageWizardPrimer?: Maybe<MishopUiChangePackageFormPrimer>;
  checkoutButtonPrimer?: Maybe<MishopUiCheckoutButtonPrimer>;
  crossSellPrimer?: Maybe<MishopUiCrossSellPrimer>;
  error?: Maybe<MishopUiPrimerError>;
  heading?: Maybe<MishopUiHeaderText>;
  legalContentPrimer?: Maybe<MishopUiLegalContentPrimer>;
  notificationPrimer?: Maybe<MishopUiNotificationPrimer>;
  pricingDetailsPrimer?: Maybe<MishopUiPricingDetailsPrimer>;
  productContentPrimer?: Maybe<MishopUiProductContentPrimer>;
  state?: Maybe<MishopDetailsUiState>;
  stepIndicatorPrimer?: Maybe<MishopUiStepIndicatorPrimer>;
};

export type MishopDetailsUiState = {
  __typename?: "MishopDetailsUIState";
  multiItemSessionId: Scalars["String"];
};

export interface MishopRecoveryNaturalKeysInput {
  flightNaturalKeys: Array<FlightNaturalKeyInput>;
  propertyNaturalKeys: Array<PropertyNaturalKeyInput>;
}

export type MishopUiActivitiesCrossSellPrimer = {
  __typename?: "MishopUIActivitiesCrossSellPrimer";
  searchContext: PrimaryActivitiesCriteria;
};

export type MishopUiAffirmPromo = {
  __typename?: "MishopUIAffirmPromo";
  impressionAnalytics: ClientSideAnalytics;
  totalPrice: Scalars["Int"];
};

export type MishopUiAmenityGroup = {
  __typename?: "MishopUIAmenityGroup";
  icon: Icon;
  primary: MishopUiHeaderText;
  secondaries: Array<MishopUiText>;
};

export type MishopUiBanner = {
  __typename?: "MishopUIBanner";
  banner: UiBanner;
};

export type MishopUiBookByPhonePrimer = {
  __typename?: "MishopUIBookByPhonePrimer";
  propertyId: Scalars["String"];
};

export interface MishopUiButton {
  accessibility: Scalars["String"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
}

export type MishopUiCarContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUICarContentPrimer";
  naturalKey: CarNaturalKey;
};

export type MishopUiCarousel = {
  __typename?: "MishopUICarousel";
  analytics: ClientSideAnalytics;
  carouselActions: MishopUiCarouselActions;
  content: MishopUiCarouselContent;
  name: Scalars["String"];
};

export type MishopUiCarouselAction = {
  __typename?: "MishopUICarouselAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type MishopUiCarouselActions = {
  __typename?: "MishopUICarouselActions";
  next: MishopUiCarouselAction;
  previous: MishopUiCarouselAction;
};

export type MishopUiCarouselContent = PackageUiPreBundledCards;

export type MishopUiChangePackageConfirmationDialogContent = {
  __typename?: "MishopUIChangePackageConfirmationDialogContent";
  message: EgdsParagraph;
  primary: EgdsHeading;
};

export type MishopUiChangePackageForm = EgdsSearchForm & {
  __typename?: "MishopUIChangePackageForm";
  actionURL: Scalars["String"];
  changePackageCriteria: ChangePackageSearchCriteria;
  elements: Array<EgdsSearchFormElement>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  playback?: Maybe<EgdsSearchPlayback>;
  search?: Maybe<EgdsButton>;
  updatePricesDialog: ChangePackageConfirmationDialog;
};

export type MishopUiChangePackageFormPrimer = {
  __typename?: "MishopUIChangePackageFormPrimer";
  accessibility: Scalars["String"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  multiItemSessionId: Scalars["String"];
  searchCriteria: PackageSearchCriteria;
};

export interface MishopUiChangePackageFormPrimerInput {
  multiItemSessionId: Scalars["String"];
}

export type MishopUiCheckoutButtonPrimer = {
  __typename?: "MishopUICheckoutButtonPrimer";
  accessibility: Scalars["String"];
  checkoutOptions: Array<CheckoutOption>;
  clickAnalytics: ClientSideAnalytics;
  errorAnalytics: ClientSideAnalytics;
  products: MultiItemProducts;
  responseOptions: Array<PrepareCheckoutResponseOption>;
  text: Scalars["String"];
  totalPrice?: Maybe<Money>;
};

export type MishopUiCollapsableSection = {
  __typename?: "MishopUICollapsableSection";
  content: Array<EgdsElement>;
  title: EgdsText;
};

export interface MishopUiContentPrimer {
  naturalKey: ProductNaturalKey;
}

export type MishopUiCrossSellPrimer = {
  __typename?: "MishopUICrossSellPrimer";
  crossSellProductPrimers: Array<MishopUiCrossSellProductPrimer>;
};

export type MishopUiCrossSellProductPrimer = MishopUiActivitiesCrossSellPrimer | MishopUiGroundTransfersCrossSellPrimer;

export type MishopUiCustomerNotification = {
  __typename?: "MishopUICustomerNotification";
  regionId?: Maybe<Scalars["String"]>;
};

export type MishopUiDefaultNavigationBar = EgdsTabs & {
  __typename?: "MishopUIDefaultNavigationBar";
  selectedTabId?: Maybe<Scalars["String"]>;
  tabs: Array<MishopUiNavigationBarTab>;
};

export type MishopUiDetailGalleryMediaItem = MediaItem & {
  __typename?: "MishopUIDetailGalleryMediaItem";
  media: Media;
};

export type MishopUiDetailMediaGallery = EgdsDetailMediaGallery & {
  __typename?: "MishopUIDetailMediaGallery";
  closeAnalytics: ClientSideAnalytics;
  closeText: Scalars["String"];
  mediaItems: Array<MishopUiDetailGalleryMediaItem>;
  navigationAnalytics: ClientSideAnalytics;
  nextButtonText?: Maybe<Scalars["String"]>;
  previousButtonText?: Maybe<Scalars["String"]>;
};

export type MishopUiDetailMediaGalleryAction = {
  __typename?: "MishopUIDetailMediaGalleryAction";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"];
  mediaDetailGallery: MishopUiDetailMediaGallery;
};

export interface MishopUiDialog {
  closeAnalytics: ClientSideAnalytics;
  content: MishopUiDialogContent;
  trigger: MishopUiDialogTrigger;
}

export type MishopUiDialogAutoOpenTrigger = MishopUiDialogTrigger & {
  __typename?: "MishopUIDialogAutoOpenTrigger";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"];
};

export type MishopUiDialogButtonType = "PRIMARY" | "TERTIARY";

export type MishopUiDialogCloseButton = MishopUiDialogFooterButton & {
  __typename?: "MishopUIDialogCloseButton";
  analytics: ClientSideAnalytics;
  text: Scalars["String"];
  type: MishopUiDialogButtonType;
};

export type MishopUiDialogContent = {
  __typename?: "MishopUIDialogContent";
  elements: Array<MishopUiDialogContentElement>;
};

export type MishopUiDialogContentElement =
  | EgdsStandardMessagingCard
  | MishopUiDialogErrorElement
  | MishopUiParagraphSection
  | MishopUiPricePresentation
  | MishopUiPropertyCleanliness
  | MishopUiReassuranceCard
  | MishopUiRoomDetails
  | MishopUiStrikeThroughPriceDisclaimer;

export type MishopUiDialogErrorElement = {
  __typename?: "MishopUIDialogErrorElement";
  primary: MishopUiHeaderText;
  secondaries: Array<MishopUiText>;
};

export type MishopUiDialogFooter = {
  __typename?: "MishopUIDialogFooter";
  buttons: Array<MishopUiDialogFooterButton>;
  stacked?: Maybe<Scalars["Boolean"]>;
};

export interface MishopUiDialogFooterButton {
  analytics: ClientSideAnalytics;
  text: Scalars["String"];
  type: MishopUiDialogButtonType;
}

export type MishopUiDialogHomeButton = MishopUiDialogFooterButton & {
  __typename?: "MishopUIDialogHomeButton";
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  resource: Uri;
  text: Scalars["String"];
  type: MishopUiDialogButtonType;
};

export type MishopUiDialogLinkTrigger = MishopUiDialogTrigger & {
  __typename?: "MishopUIDialogLinkTrigger";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"];
  icon?: Maybe<Icon>;
  label: Scalars["String"];
};

export type MishopUiDialogPriceLockupTrigger = MishopUiDialogTrigger & {
  __typename?: "MishopUIDialogPriceLockupTrigger";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"];
  priceLockup?: Maybe<MishopUiPriceLockup>;
};

export type MishopUiDialogRetryButton = MishopUiDialogFooterButton & {
  __typename?: "MishopUIDialogRetryButton";
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  text: Scalars["String"];
  type: MishopUiDialogButtonType;
};

export interface MishopUiDialogTrigger {
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"];
}

export type MishopUiEmptyStateError = {
  __typename?: "MishopUIEmptyStateError";
  action?: Maybe<MishopUiButton>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsPlainText>;
};

export type MishopUiEmptyStateErrorElement = {
  __typename?: "MishopUIEmptyStateErrorElement";
  action: MishopUiButton;
  icon: Icon;
  primary: MishopUiHeaderText;
  secondaries: Array<MishopUiText>;
};

export type MishopUiExternalLinkButton = MishopUiButton & {
  __typename?: "MishopUIExternalLinkButton";
  accessibility: Scalars["String"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
  resource?: Maybe<Uri>;
};

export type MishopUiFlightContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUIFlightContentPrimer";
  /** @deprecated Remove actionBar after migrating to shared-ui journey-summary */
  actionBar: Array<MishopDetailsUiAction>;
  contentHeader?: Maybe<MishopUiProductHeader>;
  detailsCriteria: MishopUiFlightDetailsCriteria;
  /** @deprecated Will be replaced in favor of errorFallback */
  errorMessage: MishopUiText;
  fallbackError: PackageUiMessagingCard;
  /** @deprecated Will be removed once 41511 is at 100% */
  flightChangeLink: Uri;
  naturalKey: FlightNaturalKey;
  /** @deprecated Nullable replacement for flightChangeLink introduced to not break client. Will be removed once 41511 is at 100% */
  overrideChangeFlightUrl?: Maybe<Uri>;
  upsellOfferToken: Scalars["String"];
};

export type MishopUiFlightDetailsCriteria = {
  __typename?: "MishopUIFlightDetailsCriteria";
  journeyCriteria: Array<MishopUiFlightsJourneyCriteria>;
  shoppingContext: ShoppingContext;
  travelerDetails: Array<MishopUiTravelerDetails>;
  tripType: FlightsTripType;
};

export type MishopUiFlightsJourneyCriteria = {
  __typename?: "MishopUIFlightsJourneyCriteria";
  departureDate: Date;
  destination: Scalars["String"];
  flightsCabinClass: FlightsCabinClass;
  origin: Scalars["String"];
};

export type MishopUiFullscreenDialog = MishopUiDialog & {
  __typename?: "MishopUIFullscreenDialog";
  closeAnalytics: ClientSideAnalytics;
  content: MishopUiDialogContent;
  fillViewport: Scalars["Boolean"];
  toolbar: MishopUiToolbar;
  trigger: MishopUiDialogTrigger;
};

export type MishopUiGalleryCarousel = EgdsGalleryCarousel & {
  __typename?: "MishopUIGalleryCarousel";
  accessibilityHeadingText: Scalars["String"];
  galleryOverlayButton?: Maybe<MishopUiThumbnailMediaGalleryAction>;
  media: Array<MishopUiGalleryMediaItem>;
  mediaAction?: Maybe<MishopUiDetailMediaGalleryAction>;
  navigationAnalytics?: Maybe<ClientSideAnalytics>;
  nextButtonText?: Maybe<Scalars["String"]>;
  previousButtonText?: Maybe<Scalars["String"]>;
};

export type MishopUiGalleryMediaItem = MediaItem & {
  __typename?: "MishopUIGalleryMediaItem";
  media: Media;
  mediaAction?: Maybe<MishopUiDetailMediaGalleryAction>;
};

export type MishopUiGroundTransfersCrossSellPrimer = {
  __typename?: "MishopUIGroundTransfersCrossSellPrimer";
  searchContext: PrimaryGroundTransfersCriteria;
};

export type MishopUiHeaderText = {
  __typename?: "MishopUIHeaderText";
  text: Scalars["String"];
};

export type MishopUiInstallmentPlan = {
  __typename?: "MishopUIInstallmentPlan";
  clientId?: Maybe<Scalars["String"]>;
  impressionAnalytics: ClientSideAnalytics;
  locale?: Maybe<Scalars["String"]>;
  totalPrice: Scalars["String"];
};

export type MishopUiJumpLinkButton = {
  __typename?: "MishopUIJumpLinkButton";
  accessibility: Scalars["String"];
  jumpTo: Scalars["String"];
  label: Scalars["String"];
};

export type MishopUiJumpLinkNavigationBar = EgdsTabs & {
  __typename?: "MishopUIJumpLinkNavigationBar";
  selectedTabId?: Maybe<Scalars["String"]>;
  tabs: Array<MishopUiNavigationBarTab>;
};

export type MishopUiLegalContentPrimer = {
  __typename?: "MishopUILegalContentPrimer";
  shoppedProducts: Array<ShoppedProduct>;
};

export type MishopUiLegalInformationView = MishopUiLegalInformationViewSuccess;

export type MishopUiLegalInformationViewSuccess = {
  __typename?: "MishopUILegalInformationViewSuccess";
  sections: Array<MishopUiCollapsableSection>;
};

export type MishopUiLinkedHeaderText = {
  __typename?: "MishopUILinkedHeaderText";
  action: MishopDetailsUiAction;
};

export type MishopUiNavigationBarTab = EgdsTab & {
  __typename?: "MishopUINavigationBarTab";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
  tabId: Scalars["String"];
};

export type MishopUiNonFullscreenDialog = MishopUiDialog & {
  __typename?: "MishopUINonFullscreenDialog";
  closeAnalytics: ClientSideAnalytics;
  content: MishopUiDialogContent;
  footer: MishopUiDialogFooter;
  trigger: MishopUiDialogTrigger;
};

export type MishopUiNotification = MishopUiCustomerNotification | MishopUiPlacardNotification;

export type MishopUiNotificationPrimer = {
  __typename?: "MishopUINotificationPrimer";
  notifications: Array<MishopUiNotification>;
};

export type MishopUiOneKeyBannerPrimer = {
  __typename?: "MishopUIOneKeyBannerPrimer";
  /** @deprecated Use priceToken field instead */
  multiItemPriceToken: Scalars["String"];
  priceToken?: Maybe<Scalars["String"]>;
  products: MultiItemProducts;
};

export type MishopUiOperationFailureDialog = {
  __typename?: "MishopUIOperationFailureDialog";
  content: EgdsParagraph;
  dialog: EgdsActionDialog;
};

export type MishopUiPackageSummaryContent = MishopUiPackageSummaryContentSuccess;

export type MishopUiPackageSummaryContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUIPackageSummaryContentPrimer";
  native?: Maybe<Scalars["Boolean"]>;
  naturalKey: PackageNaturalKey;
};

export interface MishopUiPackageSummaryContentPrimerInput {
  native?: InputMaybe<Scalars["Boolean"]>;
  naturalKey: PackageNaturalKeyInput;
}

export type MishopUiPackageSummaryContentSuccess = {
  __typename?: "MishopUIPackageSummaryContentSuccess";
  customizeButton: MishopUiJumpLinkButton;
  descriptions: Array<EgdsText>;
  gallery: MishopUiGalleryCarousel;
  heading: MishopUiSectionHeading;
  navigationBar: MishopUiJumpLinkNavigationBar;
};

export type MishopUiParagraph = {
  __typename?: "MishopUIParagraph";
  text: Scalars["String"];
};

export type MishopUiParagraphSection = {
  __typename?: "MishopUIParagraphSection";
  content: Array<MishopUiParagraph>;
  header: MishopUiHeaderText;
};

export type MishopUiPlacard = MishopUiBanner;

export type MishopUiPlacardNotification = {
  __typename?: "MishopUIPlacardNotification";
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  placardType: MishopUiPlacardType;
  priceDetailsOptions?: Maybe<PriceDetailsOptions>;
  shoppedProducts: Array<ShoppedProduct>;
};

export interface MishopUiPlacardNotificationInput {
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  placardType: MishopUiPlacardType;
  priceDetailsOptions?: InputMaybe<PriceDetailsOptionsInput>;
  shoppedProducts: Array<ShoppedProductInput>;
}

export type MishopUiPlacardType = "PRICE_CHANGE";

export type MishopUiPriceDetailView = MishopUiPriceDetailViewFailure | MishopUiPriceDetailViewSuccess;

export type MishopUiPriceDetailViewFailure = {
  __typename?: "MishopUIPriceDetailViewFailure";
  content: MishopUiEmptyStateErrorElement;
};

export type MishopUiPriceDetailViewSuccess = {
  __typename?: "MishopUIPriceDetailViewSuccess";
  /** @deprecated We will use installmentPlan for both Affirm and Klarna. */
  affirmPromo?: Maybe<MishopUiAffirmPromo>;
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  detail: MishopUiPricePresentation;
  earnMessage?: Maybe<EarnMessageContainerListItem>;
  installmentPlan?: Maybe<MishopUiInstallmentPlan>;
  pricePresentationAndSummary: MishopUiPricePresentationAndSummary;
  reassuranceCard?: Maybe<EgdsStandardMessagingCard>;
  reassuranceExpandoCard?: Maybe<MishopUiReassuranceCard>;
  /** @deprecated Will be removed once all clients have stopped requesting for this field */
  tabTitles: Array<Scalars["String"]>;
};

export type MishopUiPriceLockup = {
  __typename?: "MishopUIPriceLockup";
  accessiblePrice: Scalars["String"];
  accessibleStrikeThroughDialogTrigger: Scalars["String"];
  strikeThroughPrice: Scalars["String"];
};

export type MishopUiPricePresentation = {
  __typename?: "MishopUIPricePresentation";
  pricePresentation: PricePresentation;
};

export type MishopUiPricePresentationAndSummary = {
  __typename?: "MishopUIPricePresentationAndSummary";
  pricePresentationDialog: MishopUiFullscreenDialog;
  priceSummary: MishopUiPriceSummary;
};

export type MishopUiPriceSummary = {
  __typename?: "MishopUIPriceSummary";
  accessibility?: Maybe<Scalars["String"]>;
  displayPrice: MishopUiText;
  strikeThroughPriceDialog?: Maybe<MishopUiNonFullscreenDialog>;
  title: MishopUiText;
};

export type MishopUiPriceView = {
  __typename?: "MishopUIPriceView";
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  detail: MishopUiPricePresentation;
  pricePresentationAndSummary: MishopUiPricePresentationAndSummary;
};

export type MishopUiPricingDetailsPrimer = {
  __typename?: "MishopUIPricingDetailsPrimer";
  errorMessage?: Maybe<MishopUiText>;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
  priceDetailsOptions: PriceDetailsOptions;
  shoppedProducts: Array<ShoppedProduct>;
};

export type MishopUiPrimerError = {
  __typename?: "MishopUIPrimerError";
  content: Array<MishopUiPrimerErrorContentElement>;
  icon: Icon;
};

export type MishopUiPrimerErrorContentElement =
  | MishopDetailsUiAction
  | MishopUiEmptyStateErrorElement
  | MishopUiHeaderText;

export type MishopUiProduct = {
  __typename?: "MishopUIProduct";
  content: Array<MishopUiProductContentElement>;
  header: MishopUiProductHeader;
};

export interface MishopUiProductCard {
  actionBar?: Maybe<Array<MishopDetailsUiAction>>;
}

export type MishopUiProductContentElement = MishopUiPropertyProductCard | MishopUiPropertyProductCardFailure;

export type MishopUiProductContentPrimer = {
  __typename?: "MishopUIProductContentPrimer";
  contentPrimers: Array<MishopUiContentPrimer>;
};

export type MishopUiProductHeader = {
  __typename?: "MishopUIProductHeader";
  icon?: Maybe<Icon>;
  primary: MishopUiHeaderText;
  referenceId: Scalars["String"];
  secondaries: Array<MishopUiText>;
};

export type MishopUiProductType = "ACTIVITY" | "CAR" | "FLIGHT" | "GROUND_TRANSFER" | "PROPERTY";

export type MishopUiPropertyCardContentElement =
  | MishopDetailsUiAction
  | MishopUiFullscreenDialog
  | MishopUiHeaderText
  | MishopUiLinkedHeaderText
  | MishopUiNonFullscreenDialog
  | MishopUiPropertyRating
  | MishopUiText;

export type MishopUiPropertyCardContentSection = {
  __typename?: "MishopUIPropertyCardContentSection";
  content: Array<MishopUiPropertyCardContentElement>;
};

export type MishopUiPropertyCleanliness = {
  __typename?: "MishopUIPropertyCleanliness";
  cleanlinessInformationSections: Array<MishopUiPropertyCleanlinessElement>;
  primary: MishopUiHeaderText;
};

export type MishopUiPropertyCleanlinessElement = MishopUiAmenityGroup | MishopUiText;

export type MishopUiPropertyContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUIPropertyContentPrimer";
  changePropertyAction: PropertySearchMultiItemShoppingAction;
  changeRoomAction: PropertyDetailsMultiItemShoppingAction;
  fallbackErrorCard: PackageUiMessagingCard;
  naturalKey: PropertyNaturalKey;
  /** @deprecated Use relativeChangePropertyLink or relativeChangeRoomLink */
  propertyChangeLink?: Maybe<Scalars["String"]>;
  relativeChangePropertyLink: Scalars["String"];
  relativeChangeRoomLink: Scalars["String"];
};

export interface MishopUiPropertyContentPrimerInput {
  changePropertyAction: PropertySearchMultiItemShoppingActionInput;
  changeRoomAction: PropertyDetailsMultiItemShoppingActionInput;
  naturalKey: PropertyNaturalKeyInput;
  propertyChangeLink?: InputMaybe<Scalars["String"]>;
  relativeChangePropertyLink: Scalars["String"];
  relativeChangeRoomLink: Scalars["String"];
}

export type MishopUiPropertyProductCard = MishopUiProductCard & {
  __typename?: "MishopUIPropertyProductCard";
  actionBar?: Maybe<Array<MishopDetailsUiAction>>;
  contentSections: Array<MishopUiPropertyCardContentSection>;
  image?: Maybe<Image>;
};

export type MishopUiPropertyProductCardActionableError = {
  __typename?: "MishopUIPropertyProductCardActionableError";
  content: MishopUiEmptyStateErrorElement;
};

export type MishopUiPropertyProductCardFailure = MishopUiProductCard & {
  __typename?: "MishopUIPropertyProductCardFailure";
  actionBar?: Maybe<Array<MishopDetailsUiAction>>;
  actionableError: MishopUiPropertyProductCardActionableError;
  failureMsg: MishopUiText;
};

export type MishopUiPropertyRating = {
  __typename?: "MishopUIPropertyRating";
  numericRating: MishopUiText;
  reviewsText: MishopUiText;
};

export type MishopUiReassuranceCard = {
  __typename?: "MishopUIReassuranceCard";
  expando?: Maybe<ReassuranceExpando>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<EgdsSpannableText>;
};

export type MishopUiRoomDetails = {
  __typename?: "MishopUIRoomDetails";
  naturalKey: PropertyNaturalKey;
};

export type MishopUiSectionHeading = EgdsSectionHeading & {
  __typename?: "MishopUISectionHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
};

export type MishopUiSimplePropertyContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUISimplePropertyContentPrimer";
  naturalKey: PropertyNaturalKey;
};

export type MishopUiStepIndicatorPrimer = {
  __typename?: "MishopUIStepIndicatorPrimer";
  multiItemSessionId: Scalars["String"];
};

export type MishopUiStrikeThroughPriceDisclaimer = {
  __typename?: "MishopUIStrikeThroughPriceDisclaimer";
  header?: Maybe<Scalars["String"]>;
  messageItems: Array<Scalars["String"]>;
  /** @deprecated DO NOT USE: Use messageItems field instead */
  text: MishopUiText;
};

export type MishopUiText = {
  __typename?: "MishopUIText";
  accessibility?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type MishopUiTextContainer = {
  __typename?: "MishopUITextContainer";
  elements: Array<EgdsText>;
};

export type MishopUiTextContainerListItem = EgdsTextListItem & {
  __typename?: "MishopUITextContainerListItem";
  element: MishopUiTextContainer;
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type MishopUiThumbnailMediaGallery = EgdsThumbnailMediaGallery & {
  __typename?: "MishopUIThumbnailMediaGallery";
  analytics: ClientSideAnalytics;
  mediaAction?: Maybe<MishopUiDetailMediaGalleryAction>;
  tabs?: Maybe<MishopUiDefaultNavigationBar>;
  thumbnails: Array<MishopUiGalleryMediaItem>;
  toolbar: EgdsDialogToolbar;
};

export type MishopUiThumbnailMediaGalleryAction = {
  __typename?: "MishopUIThumbnailMediaGalleryAction";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"];
  mediaThumbnailGallery: MishopUiThumbnailMediaGallery;
};

export type MishopUiToolbar = {
  __typename?: "MishopUIToolbar";
  iconLabel: Scalars["String"];
  title: Scalars["String"];
};

export type MishopUiTravelerDetails = {
  __typename?: "MishopUITravelerDetails";
  ages?: Maybe<Array<Scalars["Int"]>>;
  count: Scalars["Int"];
  travelerType: TravelerDetailsType;
};

export type MobileAppDownloadButton = HeaderSharedUi & {
  __typename?: "MobileAppDownloadButton";
  skipSsr: Scalars["Boolean"];
};

export type MobileAppUri = Uri & {
  __typename?: "MobileAppURI";
  value: Scalars["String"];
};

export type ModalNavType = "BACK" | "CLOSE" | "EXPAND_DOWN" | "EXPAND_UP";

export type ModalSideSheetLayout = "EIGHT" | "FOUR" | "ONE" | "SIX" | "THREE" | "TWELVE" | "TWO";

export type ModuleName = "COUPON_MODULE";

export interface MojoContextInput {
  pageName: Scalars["String"];
  placementId: Scalars["String"];
}

export type MojoSection = {
  __typename?: "MojoSection";
  data: Scalars["String"];
};

export type Money = {
  __typename?: "Money";
  amount: Scalars["Float"];
  /** @deprecated Currency should be calculated from the context which may not be present so we need to make it nullable going forward, replace with currencyInfo */
  currency: Currency;
  /** @deprecated Use platform formatters and return localized strings instead */
  currencyInfo?: Maybe<Currency>;
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"];
  /** @deprecated Needed for backwards compatibility, replace with amount */
  raw: Scalars["Float"];
};

export type MoneyFormattedArgs = {
  formatWholeValue?: InputMaybe<Scalars["Boolean"]>;
};

export interface MoneyInput {
  amount: Scalars["Float"];
  currency?: InputMaybe<Scalars["String"]>;
}

export type Month = {
  __typename?: "Month";
  id: MonthId;
  name: Scalars["String"];
  ordinal: Scalars["Int"];
};

export type MonthId =
  | "APRIL"
  | "AUGUST"
  | "DECEMBER"
  | "FEBRUARY"
  | "JANUARY"
  | "JULY"
  | "JUNE"
  | "MARCH"
  | "MAY"
  | "NOVEMBER"
  | "OCTOBER"
  | "SEPTEMBER";

export type MonthsForecastTab = {
  __typename?: "MonthsForecastTab";
  accessibility?: Maybe<Scalars["String"]>;
  badges: Array<EgdsStandardBadge>;
  click: ClientSideAnalytics;
  description?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  monthName: Scalars["String"];
  temperature: DestinationTemperature;
};

export type MonthsForecastsTabs = {
  __typename?: "MonthsForecastsTabs";
  accessibility?: Maybe<Scalars["String"]>;
  monthlyHotelCards?: Maybe<Array<DestinationPriceInsightModule>>;
  monthsTabs: Array<MonthsForecastTab>;
  overallMaxTemperature: Scalars["Int"];
  overallMinTemperature: Scalars["Int"];
};

export type MoreHelpArticles = HelpArticlesLayout & {
  __typename?: "MoreHelpArticles";
  articlesList: Array<HelpArticle>;
  helpArticleHeadingSection: HelpArticleHeadingSection;
  /** @deprecated refactored its props to this level.  */
  helpArticlesInDialog: Array<HelpArticleDialog>;
};

export type MoreHelpArticlesView = HelpArticleErrorView | MoreHelpArticles;

export type MoreInfoDialog = {
  __typename?: "MoreInfoDialog";
  dialog: LodgingDialogToolbar;
  trigger: LodgingDialogTriggerMessage;
};

export type MultiEmailValidation = EgdsInputValidation & {
  __typename?: "MultiEmailValidation";
  errorMessage: Scalars["String"];
};

export type MultiItemAction = MultiItemSelectPackageAction | MultiItemSelectProductsAction;

export type MultiItemAnalyticsMutationResponse = {
  __typename?: "MultiItemAnalyticsMutationResponse";
  success: Scalars["Boolean"];
};

export type MultiItemContext = {
  __typename?: "MultiItemContext";
  id: Scalars["String"];
  packageType: PackageType;
  priceToken?: Maybe<Scalars["String"]>;
};

export interface MultiItemContextInput {
  id: Scalars["String"];
  packageType: PackageType;
  priceToken?: InputMaybe<Scalars["String"]>;
}

export type MultiItemCrossSellUiBadge = {
  __typename?: "MultiItemCrossSellUIBadge";
  text: Scalars["String"];
};

export type MultiItemCrossSellUiButtonAction = UiAction & {
  __typename?: "MultiItemCrossSellUIButtonAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
  resource: Uri;
};

export type MultiItemCrossSellUiCheckoutButtonAction = UiAction & {
  __typename?: "MultiItemCrossSellUICheckoutButtonAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
  responseOptions: Array<PrepareCheckoutResponseOption>;
  shoppedProducts: MultiItemProducts;
  totalPrice?: Maybe<Money>;
};

export type MultiItemCrossSellUiElement =
  | MultiItemCrossSellUiBadge
  | MultiItemCrossSellUiButtonAction
  | MultiItemCrossSellUiCheckoutButtonAction
  | MultiItemCrossSellUiHeaderText
  | MultiItemCrossSellUiImage
  | MultiItemCrossSellUiLinkAction
  | MultiItemCrossSellUiPriceDetails
  | MultiItemCrossSellUiText;

export type MultiItemCrossSellUiHeaderText = {
  __typename?: "MultiItemCrossSellUIHeaderText";
  accessibility?: Maybe<Scalars["String"]>;
  level: Scalars["Int"];
  size: Scalars["Int"];
  text: Scalars["String"];
};

export type MultiItemCrossSellUiImage = {
  __typename?: "MultiItemCrossSellUIImage";
  image: Image;
};

export type MultiItemCrossSellUiLinkAction = UiAction & {
  __typename?: "MultiItemCrossSellUILinkAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  resource: Uri;
};

export type MultiItemCrossSellUiPriceDetails = {
  __typename?: "MultiItemCrossSellUIPriceDetails";
  accessiblePrice: Scalars["String"];
  price: Scalars["String"];
  priceSubtext: Scalars["String"];
  priceSubtextStandard?: Maybe<Scalars["String"]>;
  strikethroughPrice?: Maybe<Scalars["String"]>;
};

export type MultiItemCrossSellUiProductCard = {
  __typename?: "MultiItemCrossSellUIProductCard";
  content: Array<MultiItemCrossSellUiElement>;
};

export type MultiItemCrossSellUiProductHeader = {
  __typename?: "MultiItemCrossSellUIProductHeader";
  messageItems: Array<CrossSellSecondary>;
  primary: MultiItemCrossSellUiHeaderText;
  /** @deprecated use messageItems instead */
  secondaries: Array<MultiItemCrossSellUiText>;
};

export type MultiItemCrossSellUiResponse = {
  __typename?: "MultiItemCrossSellUIResponse";
  actionBar: Array<MultiItemCrossSellUiLinkAction>;
  header: MultiItemCrossSellUiProductHeader;
  impressionAnalytics: ClientSideAnalytics;
  productCards: Array<MultiItemCrossSellUiProductCard>;
};

export type MultiItemCrossSellUiText = {
  __typename?: "MultiItemCrossSellUIText";
  accessibility?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  theme?: Maybe<TextTheme>;
};

export interface MultiItemDetailsPrimersQuery {
  primers: MishopDetailsUiPrimer;
}

export type MultiItemDetailsQuery = {
  __typename?: "MultiItemDetailsQuery";
  changePackageForm: MishopUiChangePackageForm;
  legalInformation: MishopUiLegalInformationView;
  oneKeyBanner?: Maybe<EgdsStandardMessagingCard>;
  packageSummaryContent: MishopUiPackageSummaryContent;
  placard?: Maybe<MishopUiPlacard>;
  policies: PackageUiPolicies;
  priceDetails: MishopUiPriceDetailView;
  /** @deprecated Use the `priceDetails` query instead */
  pricingDetails: MishopUiPriceView;
  productChangedBanner: Array<MishopUiBanner>;
  productHeader?: Maybe<MishopUiProductHeader>;
  propertyContent: MishopUiProduct;
  seeAllPackagesLink: PackageUiSeeAllPackagesLink;
};

export type MultiItemDetailsQueryChangePackageFormArgs = {
  primer: MishopUiChangePackageFormPrimerInput;
};

export type MultiItemDetailsQueryLegalInformationArgs = {
  shoppedProducts: Array<ShoppedProductInput>;
};

export type MultiItemDetailsQueryOneKeyBannerArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  products?: InputMaybe<MultiItemProductsInput>;
};

export type MultiItemDetailsQueryPackageSummaryContentArgs = {
  packageSummaryContentPrimer: MishopUiPackageSummaryContentPrimerInput;
};

export type MultiItemDetailsQueryPlacardArgs = {
  placardNotificationPrimer: MishopUiPlacardNotificationInput;
};

export type MultiItemDetailsQueryPoliciesArgs = {
  products: MultiItemProductsInput;
};

export type MultiItemDetailsQueryPriceDetailsArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  priceDetailsOptions?: InputMaybe<PriceDetailsOptionsInput>;
  sessionId?: InputMaybe<Scalars["String"]>;
  shoppedProducts: Array<ShoppedProductInput>;
};

export type MultiItemDetailsQueryPricingDetailsArgs = {
  shoppedProducts: Array<ShoppedProductInput>;
};

export type MultiItemDetailsQueryProductChangedBannerArgs = {
  productChangedBannerPrimers: Array<PackageUiProductChangedBannerPrimerInput>;
};

export type MultiItemDetailsQueryProductHeaderArgs = {
  productHeaderPrimer: PackageUiProductHeaderPrimerInput;
};

export type MultiItemDetailsQueryPropertyContentArgs = {
  propertyContentPrimer: MishopUiPropertyContentPrimerInput;
};

export type MultiItemDetailsQuerySeeAllPackagesLinkArgs = {
  searchCriteria: PackageSearchCriteriaInput;
};

export type MultiItemDynamicCrossSellPrimer = {
  __typename?: "MultiItemDynamicCrossSellPrimer";
  multiItemSearchContext: MultiItemSearchContext;
  multiItemSelectedFlightProducts: MultiItemSelectedFlightProducts;
};

export type MultiItemFlightCrossSellOfferPrimer = {
  __typename?: "MultiItemFlightCrossSellOfferPrimer";
  brandedDealFallBack?: Maybe<BrandedDealCrossSellFallback>;
  multiItemPackageableProduct?: Maybe<MultiItemPackageableProduct>;
  multiItemSearchContext: MultiItemSearchContext;
  price?: Maybe<BrandedDealPrice>;
};

export type MultiItemFlightCrossSellPrimer = {
  __typename?: "MultiItemFlightCrossSellPrimer";
  flightCrossSellProductType?: Maybe<FlightCrossSellProductType>;
  multiItemSearchContext: MultiItemSearchContext;
};

export type MultiItemFlightSearchCrossSellOfferPrimer = {
  __typename?: "MultiItemFlightSearchCrossSellOfferPrimer";
  brandedDealFallBack?: Maybe<BrandedDealCrossSellFallback>;
  multiItemPackageableProduct?: Maybe<MultiItemPackageableProduct>;
  multiItemSearchContext: MultiItemSearchContext;
  price?: Maybe<BrandedDealSearchPrice>;
};

export type MultiItemMessage = {
  __typename?: "MultiItemMessage";
  message: Array<Scalars["String"]>;
};

export type MultiItemPackageableProduct = {
  __typename?: "MultiItemPackageableProduct";
  flights: Array<MultiItemSelectedFlightNaturalKey>;
};

export type MultiItemPickUpDropOffMessage = {
  __typename?: "MultiItemPickUpDropOffMessage";
  message: CarPhrase;
};

export type MultiItemPlayBackSecondaryMessage = {
  __typename?: "MultiItemPlayBackSecondaryMessage";
  accessibilityMessage: CarPhrase;
  longMessage: CarPhrase;
  shortMessage: CarPhrase;
};

export type MultiItemPlayBackTitleBar = {
  __typename?: "MultiItemPlayBackTitleBar";
  primary: CarPhrase;
  secondary: MultiItemPlayBackSecondaryMessage;
};

export type MultiItemProducts = {
  __typename?: "MultiItemProducts";
  activities?: Maybe<Array<ActivityNaturalKey>>;
  cars?: Maybe<Array<CarNaturalKey>>;
  flights?: Maybe<Array<FlightNaturalKey>>;
  groundTransfers?: Maybe<Array<GroundTransfersNaturalKey>>;
  packages?: Maybe<Array<PackageNaturalKey>>;
  properties?: Maybe<Array<PropertyNaturalKey>>;
};

export interface MultiItemProductsInput {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  packages?: InputMaybe<Array<PackageNaturalKeyInput>>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
}

export type MultiItemSearchContext = {
  __typename?: "MultiItemSearchContext";
  cars?: Maybe<Array<CarSearchCriteria>>;
  flights?: Maybe<Array<FlightSearchCriteria>>;
  properties?: Maybe<Array<PropertySearchCriteria>>;
};

export type MultiItemSearchContextActionableError = {
  __typename?: "MultiItemSearchContextActionableError";
  content: MultiItemSearchContextErrorContentElement;
};

export type MultiItemSearchContextCreatedResponse = {
  __typename?: "MultiItemSearchContextCreatedResponse";
  action: MultiItemShoppingAction;
  message?: Maybe<Scalars["String"]>;
  redirectUrl?: Maybe<Scalars["URL"]>;
  /** @deprecated Use action.shoppingContext */
  shoppingContext: ShoppingContext;
  target?: Maybe<UiLinkTarget>;
};

export type MultiItemSearchContextErrorContentElement = MishopUiEmptyStateErrorElement;

export type MultiItemSearchContextErrorResponse = {
  __typename?: "MultiItemSearchContextErrorResponse";
  /** @deprecated Use errorContent instead */
  actionableError: MultiItemSearchContextActionableError;
  /** @deprecated Use errorContent instead */
  content?: Maybe<MishopUiEmptyStateError>;
  /** @deprecated WIP - This error is meant to replace the existing error content on the page */
  errorContent?: Maybe<PackageUiEmptyStateError>;
  failureDialog?: Maybe<MishopUiOperationFailureDialog>;
  message?: Maybe<Scalars["String"]>;
};

export interface MultiItemSearchContextInput {
  cars?: InputMaybe<Array<CarSearchCriteriaInput>>;
  flights?: InputMaybe<Array<FlightSearchCriteriaInput>>;
  properties?: InputMaybe<Array<PropertySearchCriteriaInput>>;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type MultiItemSearchContextResult = MultiItemSearchContextCreatedResponse | MultiItemSearchContextErrorResponse;

export type MultiItemSelectPackage = FlightsClientAction & {
  __typename?: "MultiItemSelectPackage";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  packageOfferId: Scalars["String"];
};

export type MultiItemSelectPackageAction = {
  __typename?: "MultiItemSelectPackageAction";
  packageOfferId: Scalars["String"];
};

export type MultiItemSelectProducts = FlightsClientAction & {
  __typename?: "MultiItemSelectProducts";
  accessibility?: Maybe<Scalars["String"]>;
  analyticsList: Array<ClientSideAnalytics>;
  flightsOfferNaturalKeys: FlightsOfferNaturalKeys;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
};

export type MultiItemSelectProductsAction = {
  __typename?: "MultiItemSelectProductsAction";
  flightsOfferNaturalKeys: FlightsOfferNaturalKeys;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
};

export type MultiItemSelectedFlightNaturalKey = {
  __typename?: "MultiItemSelectedFlightNaturalKey";
  offerToken: Scalars["String"];
  productTokens: Array<Scalars["String"]>;
  travelers: Array<TravelerDetails>;
};

export type MultiItemSelectedFlightProducts = {
  __typename?: "MultiItemSelectedFlightProducts";
  flights: Array<MultiItemSelectedFlightNaturalKey>;
};

export type MultiItemSessionMutations = {
  __typename?: "MultiItemSessionMutations";
  addProducts: MultiItemSearchContextResult;
  changePackage: MultiItemSearchContextResult;
  initiate: MultiItemSearchContextResult;
  removeProducts: MultiItemSearchContextResult;
  selectPackage: MultiItemSearchContextResult;
  selectProducts: MultiItemSearchContextResult;
  updateProduct: MultiItemSearchContextResult;
};

export type MultiItemSessionMutationsAddProductsArgs = {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  sessionId: Scalars["String"];
};

export type MultiItemSessionMutationsChangePackageArgs = {
  searchCriteria: PackageSearchCriteriaInput;
  sessionId: Scalars["String"];
};

export type MultiItemSessionMutationsInitiateArgs = {
  searchContext: MultiItemSearchContextInput;
};

export type MultiItemSessionMutationsRemoveProductsArgs = {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  sessionId: Scalars["String"];
};

export type MultiItemSessionMutationsSelectPackageArgs = {
  packageOfferId: Scalars["String"];
  sessionId: Scalars["String"];
};

export type MultiItemSessionMutationsSelectProductsArgs = {
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  dealMarker?: InputMaybe<Scalars["String"]>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  priceToken?: InputMaybe<Scalars["String"]>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
  sessionId: Scalars["String"];
};

export type MultiItemSessionMutationsUpdateProductArgs = {
  activity?: InputMaybe<ActivityUpdateInput>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  priceToken?: InputMaybe<Scalars["String"]>;
  sessionId: Scalars["String"];
};

export interface MultiItemShoppingAction {
  shoppingContext: ShoppingContext;
}

export type Mutation = {
  __typename?: "Mutation";
  acceptConfirmation: BookingServicingAcceptConfirmation;
  accountFormSubmit: ProfileAccountFormComponent;
  addChatMessage: SupplyReservationsInteractionMessageGroup;
  addGlobalInsurance: AddInsuranceResponse;
  addInsurance: AddInsuranceResponse;
  addInsuranceOld: AddInsuranceResponseOld;
  addInsurtechOfferToTrip: UpdateInsurtechOfferResponse;
  addSupplyPhotos: SupplyPhotosAddExperience;
  agencyDeleteQuote: AgencyQuoteDeleteStatus;
  agencyQuoteBuilderAdd: AgencyQuoteBuilderResponse;
  agencyQuoteBuilderHandoff: AgencyQuoteBuilderResponse;
  agencyQuoteBuilderRemove: AgencyQuoteBuilderResponse;
  agencySaveQuote: AgencyQuoteSaveResponse;
  applyCoupon: AppliedCoupon;
  applyPaymentMethod: PaymentResponse;
  approveCancellationWaiver: SupplyCancellationWaiverApprovalResponseContent;
  askProductQuestion?: Maybe<Scalars["String"]>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  batchUpdateInboxNotificationsByUserId: Array<CustomerNotification>;
  book: BookResponse;
  bookingServicingChangeLodgingDetails: BookingServicingChangeLodgingMutationResponse;
  bulkAddInventoryUpdate: BulkAddInventoryResponse;
  cancelConfirmationLoaded: BookingServicingCancelConfirmationLoaded;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  cartItems: CartMutations;
  changeNotificationState?: Maybe<RetailTravelAgentMutateNotificationResult>;
  checkoutAvailabilityCheck: CheckoutSessionDetailsResponse;
  closeAffiliateToolbar: AffiliatesCloseToolbarResponse;
  confirmAffiliateAccountLink: AffiliatesConfirmAccountLinkResponse;
  consentMgmtPrefSubmit: SavePrefResponse;
  contactUsOptions: ContactUsViewMutation;
  createAffiliateLink: AffiliatesCreateLinkResponse;
  createAffiliateTag: AffiliatesCreateAffiliateTagResponse;
  createAffiliateVanityLink: AffiliatesCreateVanityLinkResponse;
  createMultiItemSession: MultiItemSearchContextResult;
  createPaymentTokenization: PaymentTokenResponse;
  createPriceTracking: PriceInsightsTrackingView;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  createSessionCart: Cart;
  createSupplyReservationsAccessInstructions: SupplyReservationsAccessInstructions;
  createTrip: TripsUiCreateTripResponse;
  createTripInvite: TripsUiCreateInviteResponse;
  creditCardApplicationFormUpdate: CreditCardApplicationStepResponse;
  creditCardCheckoutSessionValidate: CreditCardCheckoutSessionValidateResponse;
  declineCancellationWaiver: SupplyCancellationWaiverDeclineSuccessContent;
  deleteNotification: CommunicationCenterNotificationMutationResponse;
  deleteNotificationById: InboxNotificationDeleteResponse;
  deleteOwnersAccount: SupplyDeleteAccountMutationResponse;
  deleteSupplyPhotos: SupplyPhotosDeleteExperience;
  deleteSupplyReservationsAccessInstructions: Scalars["Boolean"];
  directFeedbackSubmit: DirectFeedbackFormValidation;
  disablePriceTracking: PriceInsightsTrackingView;
  discardAbandonedCheckout?: Maybe<AbandonedCheckoutDiscardStatus>;
  editUniversalProfile: EditUniversalProfileResponse;
  flightsAncillaryUpdate?: Maybe<FlightsAncillaryUpdateResponse>;
  flightsMerchandisingBook: FlightsMerchandisingBookResponse;
  flightsServicingCompleteBooking: FlightsServicingBookingResponse;
  googleOneTapSubmit?: Maybe<IdentityResponse>;
  hideAllSearchHistories?: Maybe<HistoryMutationResponse>;
  hideSearchHistory?: Maybe<HistoryMutationResponse>;
  identityCreateAccount: IdentityAccountResponse;
  identityFormSubmit?: Maybe<IdentityResponse>;
  insuranceAddRemove?: Maybe<InsuranceTripMutationResponse>;
  insuranceBookingCancellation?: Maybe<InsuranceBookingCancellationResponse>;
  insurtechUpdateProductSelection: InsurtechUpdateProductSelectionResponse;
  interactions: SupplyReservationsInteractionUpdateResponse;
  lodgingRefund?: Maybe<RefundFormMutationResponse>;
  logout: LogoutResponse;
  markAnswerHelpful?: Maybe<Scalars["Boolean"]>;
  /** @deprecated this mutation is deprecated but required for UIS Prime, it may be removed with the move to eg clickstream */
  mergeMultiItemAnalytics: MultiItemAnalyticsMutationResponse;
  multiItemShopping: MultiItemSessionMutations;
  oneKeyCashBurnToggle: OneKeyCashBurnToggleResult;
  oneKeyOnboardingOperation: OneKeyOnboardingOperationResult;
  preloadPropertySearch: PropertySearchPreloadResults;
  prepareChangeCheckout: PrepareChangeCheckoutResponse;
  prepareCheckout: PrepareCheckoutResponse;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  prepareCheckoutByCartId: PrepareCheckoutResponse;
  prepareCheckoutBySessionId: PrepareCheckoutResponse;
  prepareFop: PaymentPrepareFopResponse;
  priceMatchPromiseCheckPrice?: Maybe<PriceCheckMutationResult>;
  publishInteractionEvent: InteractionEventResponse;
  rejectReview: Scalars["Boolean"];
  removeCoupon: CouponRedemption;
  removeGlobalInsurance: RemoveInsuranceResponse;
  removeInsurance: RemoveInsuranceResponse;
  removeInsuranceOld: RemoveInsuranceResponseOld;
  removeInsurtechOfferFromTrip: UpdateInsurtechOfferResponse;
  removePaymentMethod: PaymentResponse;
  removeShortlistItem: RemoveItemResults;
  /** @deprecated Use updateTripItemTrip instead for removing an item from trip. */
  removeTripItem: TripsUiRemoveTripItemResponse;
  saveAnswers: ReviewAnswersResponse;
  saveReservationModifiersCancel: SupplyReservationModifiers;
  saveReservationModifiersChangeDates: SupplyReservationModifiers;
  saveReservationModifiersGuestSatisfactionRefund: SupplyReservationModifiers;
  saveReservationModifiersNoShow: SupplyReservationModifiers;
  saveShortlistItem?: Maybe<SavedItem>;
  saveSupplyRegulatorySettings?: Maybe<SupplyRegulatoryFlow>;
  saveTripItemComment: TripsUiSaveTripItemCommentResponse;
  saveTripItems: TripsUiSaveTripItemsResponse;
  selectFop: SelectFopDataModule;
  sendCommunicationCenterMessage: CommunicationCenterMessageMutationResponse;
  sendCommunicationCenterMessageNonLegacy: CommunicationCenterMessageMutationResponse;
  sendConversationEvent: VirtualAgentControlConversationEventResponse;
  sendMessage?: Maybe<VirtualAgentControlSendMessageResult>;
  sendTextToDownloadApp: NotificationTextToDownloadAppResponse;
  setFlightPriceAlertOptIn?: Maybe<FlightsPriceAlertsOptInResponse>;
  setMinNightStay?: Maybe<MinNightStayResult>;
  shopGlobalInsurance?: Maybe<InsuranceShoppingResponse>;
  smartForm: SmartFormMutation;
  submitInquiry: InquiryResponse;
  supplyCoachingTipPostpone: SupplyPostponeSubmitResponse;
  supplyCoachingTipQuickActionSubmit: SupplyQuickActionSubmitResponse;
  supplyContentUpdateAdditionalInfo: SupplyContentDescriptionUpdate;
  supplyContentUpdateHeadlineDescription: SupplyContentDescriptionUpdate;
  supplyContentUpdateLocation: SupplyContentUpdateLocationResult;
  supplyContentUpdateManualTranslation: SupplyContentManualTranslationUpdate;
  supplyContentUpdateYourProperty: SupplyContentDescriptionUpdate;
  supplyContentUpdateYourStory: SupplyContentDescriptionUpdate;
  supplyContractingOffersAcceptance: SupplyContractingMutationResponse;
  supplyFeesUpdate: SupplyFees;
  supplyIndirectTaxNavigateBack: SupplyIndirectTaxNavigate;
  supplyIndirectTaxUpdate: SupplyIndirectTaxNavigate;
  supplyLoyalty?: Maybe<SupplyLoyaltyMutation>;
  supplyMarketplaceFeedItemUpdate: SupplyMarketplaceFeedItemUpdateResponse;
  supplyMarketplaceFeedUpdate: SupplyMarketplaceFeedDismiss;
  supplyMultiFactorCodeGeneration: SupplyMultiFactorAuthenticationExperience;
  supplyMultiFactorCodeVerification: SupplyMultiFactorAuthenticationExperience;
  supplyNavigationNotificationActivity: SupplyNavigationNotificationActivityNotificationIds;
  supplyOnboardingAvailabilityUpdate?: Maybe<SupplyOnboardingAvailabilityUpdate>;
  supplyOnboardingPublishListing?: Maybe<SupplyOnboardingPublishListingResult>;
  supplyOnboardingWelcomeNavigate: SupplyOnboardingWelcomeNavigationUpdate;
  supplyOnboardingWorkFlowNavigate: SupplyOnboardingWorkFlowNavigationUpdate;
  supplyProductCreationBaseRateManagement: SupplyProductCreationManagementResult;
  supplyProductCreationBreakfastRatePlanManagement: SupplyProductCreationRatePlanManagementResult;
  supplyProductCreationChildRatePlanManagement: SupplyProductCreationChildRatePlanManagementResult;
  supplyProductCreationDeleteRatePlans: SupplyProductCreationRatePlanManagementResult;
  supplyProductCreationPricingModelUpdate?: Maybe<SupplyProductCreationManagementResult>;
  supplyProductCreationRoomInfoManagement: SupplyProductCreationManagementResult;
  supplyProductCreationRoomNameManagement: SupplyProductCreationRoomNameManagementResult;
  supplyProductCreationRoomNameUpdate: SupplyProductCreationRoomNameManagementResult;
  supplyPromotionCreateTieredMembersOnlyDeals: SupplyPromotionMutationResponse;
  supplyPromotionUpdateStackingOptions: SupplyPromotionMutationResponse;
  supplyPromotionUpdateTieredMembersOnlyDeal: SupplyPromotionMutationResponse;
  supplyRoomTypeMappingPropertyValidate: SupplyRoomTypeMappingSubmitResponse;
  supplyRoomTypeMappingSubmit: SupplyRoomTypeMappingSubmitResponse;
  supplyTaxesUpdate?: Maybe<SupplyTaxesEditor>;
  supplyUpdateNewListingOfferEnrollment: SupplyUpdateNewListingOfferEnrollment;
  supplyUpdatePartnerAttestation: SupplyOnboardingPartnerAttestationResult;
  supplyUpdatePartnerBillingAddress: SupplyOnboardingPartnerBillingAddress;
  supplyUpdatePartnerBusinessName: SupplyOnboardingPartnerBusinessNameResult;
  supplyUpdatePartnerHostType: SupplyOnboardingHostTypeResult;
  supplyUpdatePartnerLegalName: SupplyOnboardingPartnerLegalNameResult;
  supplyUpdatePartnerPayoutDeferredAccount: SupplyUpdatePartnerPayoutDeferredAccount;
  supplyUpdatePropertyRates: SupplyUpdatePropertyRates;
  sweepstakesDisenroll: SweepstakesDisenrollResponse;
  sweepstakesUpdate: SweepstakesDashboardQuery;
  sweepstakesUserWipe: SweepstakesUserWipeQuery;
  textToDownloadApp: CustomerNotificationTextToDownloadAppResponse;
  /** @deprecated Use userDismissTask mutation instead */
  topTaskDismiss: SupplyTopTaskDismiss;
  trip: TripMutation;
  tripsRefreshItineraryItem: TripsUiTripItineraryItemResponse;
  tripsRemoveItineraryItem: TripsUiTripItineraryItemResponse;
  triviaStart: TriviaStartQuery;
  triviaSubmit: TriviaFinishedQuery;
  unhideAllSearchHistories?: Maybe<HistoryMutationResponse>;
  unhideSearchHistory?: Maybe<HistoryMutationResponse>;
  updateAgentPresence: UpdatePresence;
  updateConversationState: CommunicationCenterConversationMutationResponse;
  updateFops: PaymentUpdateFopResponse;
  updateInbox?: Maybe<Array<InboxNotificationUpdateResponse>>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  updateInboxNotification: Array<CustomerNotification>;
  updateInboxState?: Maybe<Array<InboxNotificationUpdateStateResponse>>;
  updateInsurtechOffer: UpdateInsurtechOfferResponse;
  /** @deprecated Use interactions top level mutation instead */
  updateInteractions: SupplyReservationsInteractionsListItemActionResponse;
  updateMessageState: CommunicationCenterMutationResponse;
  updateNewListingOfferEnrollment: SupplyUpdateNewListingOfferEnrollment;
  updateNotificationsState: Array<CommunicationCenterNotificationMutationResponse>;
  updatePartnerPayoutDeferredAccount: SupplyUpdatePartnerPayoutDeferredAccount;
  updatePaymentMethod: PaymentResponse;
  updatePaymentSession: PaymentModuleSession;
  updatePriceTracking: PriceInsightsTrackingView;
  updatePropertyRates: SupplyUpdatePropertyRates;
  updateSupplyAmenities: SupplyAmenitiesGroup;
  updateSupplyAttributes: SupplyAttributesAdminExperience;
  updateSupplyDismissibleCard: SupplyDismissibleCardResponse;
  updateSupplyReservationsAccessInstructions: SupplyReservationsAccessInstructions;
  updateSupplyReservationsPartnerNote: SupplyReservationsPartnerNotes;
  updateSupplySelectedWaivers: UpdateSupplySelectedWaiversResponse;
  updateTripItemDates: TripsUiUpdateTripItemDatesResponse;
  updateTripItemPriceAlertStatus: TripsUiUpdateTripItemPriceAlertStatusResponse;
  updateTripItemTrip: TripsUiUpdateTripItemTripResponse;
  updateTripItemVote?: Maybe<TripsUiUpdateTripItemVoteResponse>;
  /** @deprecated Use upvotePropertyReviewWithAccessibility */
  upvotePropertyReview: Scalars["String"];
  upvotePropertyReviewWithAccessibility: UpvotePropertyReviewWithAccessibility;
  userDismissTask: SupplyTopTaskDismiss;
  userProfileWizardSubmit: ProfileWizardResponse;
  userSettingFormSubmit: ProfileComponent;
  virtualAgentControlCreateConversation?: Maybe<CreateConversationResult>;
  virtualAgentFlightCheckout: VirtualAgentCheckoutResponse;
};

export type MutationAcceptConfirmationArgs = {
  context: ContextInput;
  criteria: BookingServicingAcceptFlightInput;
};

export type MutationAccountFormSubmitArgs = {
  actionContext: Scalars["String"];
  context: ContextInput;
  inputs: Array<GraphQlPairInput>;
};

export type MutationAddChatMessageArgs = {
  clientData?: InputMaybe<SupplyReservationsInteractionMessagesClientData>;
  context: ContextInput;
  propertyContext: PropertyContext;
  request: SupplyReservationsInteractionAddChatMessageRequest;
};

export type MutationAddGlobalInsuranceArgs = {
  addInsuranceRequest: AddInsuranceRequestInput;
  context: ContextInput;
};

export type MutationAddInsuranceArgs = {
  addInsuranceRequest: AddInsuranceRequestInput;
  context: ContextInput;
};

export type MutationAddInsuranceOldArgs = {
  addInsuranceRequest: AddInsuranceRequestOldInput;
  context: ContextInput;
};

export type MutationAddInsurtechOfferToTripArgs = {
  context: ContextInput;
  insurtechAddTripRequest: InsurtechAddTripRequestInput;
};

export type MutationAddSupplyPhotosArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  photos: Array<SupplyPhotosAddPhotoInput>;
  propertyContext: PropertyContext;
};

export type MutationAgencyDeleteQuoteArgs = {
  context: ContextInput;
  quoteDeleteInput: AgencyQuoteDeleteInput;
};

export type MutationAgencyQuoteBuilderAddArgs = {
  context: ContextInput;
  rateCard: AgencyQuoteBuilderRateCardInput;
};

export type MutationAgencyQuoteBuilderHandoffArgs = {
  context: ContextInput;
};

export type MutationAgencyQuoteBuilderRemoveArgs = {
  context: ContextInput;
  removeRateCards: AgencyQuoteBuilderRemoveInput;
};

export type MutationAgencySaveQuoteArgs = {
  context: ContextInput;
  quoteSaveInput: AgencyQuoteSaveInput;
};

export type MutationApplyCouponArgs = {
  context: ContextInput;
  coupons: Array<CouponIdentifierInput>;
  sessionId: Scalars["String"];
  sessionToken: Scalars["String"];
};

export type MutationApplyPaymentMethodArgs = {
  applyPaymentMethodRequest: ApplyPaymentMethodRequestInput;
  context?: InputMaybe<ContextInput>;
};

export type MutationApproveCancellationWaiverArgs = {
  approvalRequestContext: SupplyCancellationWaiverApprovalRequestContext;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationAskProductQuestionArgs = {
  context: ContextInput;
  email: Scalars["String"];
  productId: Scalars["String"];
  productQuestionTopic: ProductQuestionTopic;
  question: Scalars["String"];
  questioner?: InputMaybe<Scalars["String"]>;
};

export type MutationBatchUpdateInboxNotificationsByUserIdArgs = {
  context: ContextInput;
  expUserId: Scalars["String"];
  modifiableAttributes: CustomerNotificationModifiableAttributesInput;
};

export type MutationBookArgs = {
  checkoutSessionInfo: CheckoutSessionInfoInput;
  context: ContextInput;
  domainInfoList?: InputMaybe<Array<CheckoutDomainInfoInput>>;
};

export type MutationBookingServicingChangeLodgingDetailsArgs = {
  changeLodgingInput: BookingServicingChangeLodgingInput;
  context: ContextInput;
  travelerDetailsInput: BookingServicingTravelerDetailsInput;
};

export type MutationBulkAddInventoryUpdateArgs = {
  addInventoryInput: BulkAddInventoryInput;
  propertyContext: PropertyContext;
};

export type MutationCancelConfirmationLoadedArgs = {
  context: ContextInput;
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type MutationCartItemsArgs = {
  context: ContextInput;
  id?: InputMaybe<Scalars["UUID"]>;
};

export type MutationChangeNotificationStateArgs = {
  context: ContextInput;
  id: Scalars["ID"];
  state: Scalars["String"];
};

export type MutationCheckoutAvailabilityCheckArgs = {
  context: ContextInput;
  sessionID: Scalars["UUID"];
};

export type MutationCloseAffiliateToolbarArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type MutationConfirmAffiliateAccountLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type MutationConsentMgmtPrefSubmitArgs = {
  context: ContextInput;
  request: SavePrefRequestInput;
};

export type MutationContactUsOptionsArgs = {
  context: ContextInput;
};

export type MutationCreateAffiliateLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateLinkRequestInput;
};

export type MutationCreateAffiliateTagArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateAffiliateTagRequestInput;
};

export type MutationCreateAffiliateVanityLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateVanityLinkRequestInput;
};

export type MutationCreateMultiItemSessionArgs = {
  context: ContextInput;
  searchContext: MultiItemSearchContextInput;
};

export type MutationCreatePaymentTokenizationArgs = {
  context?: InputMaybe<ContextInput>;
  paymentTokenRequest: PaymentTokenRequestInput;
};

export type MutationCreatePriceTrackingArgs = {
  context: ContextInput;
  priceInsightsOperationData?: InputMaybe<PriceInsightsCreateMutationDataInput>;
  priceInsightsSearchContext: PriceInsightsSearchContextInput;
  priceShown?: InputMaybe<Scalars["String"]>;
  timeSeriesTierShown?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreateSessionCartArgs = {
  cartOptions: CartOptionsInput;
  context: ContextInput;
};

export type MutationCreateSupplyReservationsAccessInstructionsArgs = {
  accessIdentifier: SupplyReservationsAccessIdentifierInput;
  accessInstructions: SupplyReservationsAccessInstructionsInput;
  context: ContextInput;
  locale?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type MutationCreateTripArgs = {
  context: ContextInput;
  description?: InputMaybe<Scalars["String"]>;
  itemId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  operationType?: InputMaybe<Scalars["String"]>;
  tripContext?: InputMaybe<TripsUiTripContextInput>;
};

export type MutationCreateTripInviteArgs = {
  context: ContextInput;
  tripId: Scalars["String"];
};

export type MutationCreditCardApplicationFormUpdateArgs = {
  context: ContextInput;
  input: CreditCardApplicationFormUpdateInput;
};

export type MutationCreditCardCheckoutSessionValidateArgs = {
  context: ContextInput;
  request: CreditCardCheckoutSessionValidateRequestInput;
};

export type MutationDeclineCancellationWaiverArgs = {
  context: ContextInput;
  declineRequestContext: CancellationWaiverDeclineRequestContext;
  propertyContext: PropertyContext;
};

export type MutationDeleteNotificationArgs = {
  context: ContextInput;
  notificationId: Scalars["String"];
};

export type MutationDeleteNotificationByIdArgs = {
  context?: InputMaybe<ContextInput>;
  notificationId: Scalars["String"];
};

export type MutationDeleteOwnersAccountArgs = {
  context: ContextInput;
};

export type MutationDeleteSupplyPhotosArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  photoIds: Array<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type MutationDeleteSupplyReservationsAccessInstructionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationUuid: Scalars["UUID"];
};

export type MutationDirectFeedbackSubmitArgs = {
  context: ContextInput;
  feedback: DirectFeedbackValuesInput;
  id: Scalars["ID"];
};

export type MutationDisablePriceTrackingArgs = {
  context: ContextInput;
  notificationPreference?: InputMaybe<NotificationPreferenceType>;
  priceInsightsOperationData?: InputMaybe<PriceInsightsDisableMutationDataInput>;
  priceShown?: InputMaybe<Scalars["String"]>;
  subscriptionId: Scalars["String"];
};

export type MutationDiscardAbandonedCheckoutArgs = {
  context: ContextInput;
};

export type MutationEditUniversalProfileArgs = {
  context: ContextInput;
  universalProfileClientInfo?: InputMaybe<UniversalProfileClientInfoInput>;
};

export type MutationFlightsAncillaryUpdateArgs = {
  context: ContextInput;
  detailAncillaryUpdateCriteria: FlightsDetailAncillaryUpdateCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type MutationFlightsMerchandisingBookArgs = {
  context: ContextInput;
  edgeToken?: InputMaybe<Scalars["String"]>;
  paymentSessionId?: InputMaybe<Scalars["String"]>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"];
};

export type MutationFlightsServicingCompleteBookingArgs = {
  context: ContextInput;
  edgeToken: Scalars["String"];
  flightsServicingCriteria: FlightsServicingCriteriaInput;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  paymentSessionId?: InputMaybe<Scalars["String"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type MutationGoogleOneTapSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  submitRequest: IdentitySubmitRequestInput;
};

export type MutationHideAllSearchHistoriesArgs = {
  context: ContextInput;
};

export type MutationHideSearchHistoryArgs = {
  context: ContextInput;
  historyTypes?: InputMaybe<Array<HistoryType>>;
  interactionId?: InputMaybe<Scalars["String"]>;
};

export type MutationIdentityCreateAccountArgs = {
  context: ContextInput;
  createAccountRequest: IdentityCreateAccountRequestInput;
};

export type MutationIdentityFormSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  submitRequest: IdentitySubmitRequestInput;
};

export type MutationInsuranceAddRemoveArgs = {
  addRemoveInsuranceRequest: InsuranceTripMutationRequestInput;
  context: ContextInput;
};

export type MutationInsuranceBookingCancellationArgs = {
  context: ContextInput;
  insuranceBookingCancellationRequest: InsuranceBookingCancellationRequestInput;
};

export type MutationInsurtechUpdateProductSelectionArgs = {
  insuranceContinuationToken?: InputMaybe<Scalars["String"]>;
  insurtechUpdateProductSelectionRequest: InsurtechUpdateProductSelectionRequestInput;
};

export type MutationInteractionsArgs = {
  action: SupplyReservationsInteractionListItemAction;
  context: ContextInput;
  interactionIds: Array<Scalars["String"]>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type MutationLodgingRefundArgs = {
  bookingItemId: Scalars["String"];
  context: ContextInput;
  formInput: RefundFormMutationInput;
  orderNumber: Scalars["String"];
  step: Scalars["Int"];
};

export type MutationLogoutArgs = {
  context: ContextInput;
  flavor: FlavorType;
  idToken: Scalars["String"];
  partnerId: Scalars["String"];
  roleId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationMarkAnswerHelpfulArgs = {
  answerId: Scalars["String"];
  questionId: Scalars["String"];
};

export type MutationMergeMultiItemAnalyticsArgs = {
  context: ContextInput;
  messageType: Scalars["String"];
  traceIds: Array<Scalars["String"]>;
};

export type MutationMultiItemShoppingArgs = {
  context: ContextInput;
};

export type MutationOneKeyCashBurnToggleArgs = {
  checkoutSessionId: Scalars["String"];
  context: ContextInput;
};

export type MutationOneKeyOnboardingOperationArgs = {
  additionalContext?: InputMaybe<AdditionalOnboardingOperationContextInput>;
  context: ContextInput;
  onboardingOperation: Scalars["String"];
};

export type MutationPreloadPropertySearchArgs = {
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type MutationPrepareChangeCheckoutArgs = {
  context: ContextInput;
  lodgingInput?: InputMaybe<LodgingPrepareChangeCheckoutInput>;
  options?: InputMaybe<Array<PrepareChangeCheckoutOptionInput>>;
  tripId: Scalars["String"];
};

export type MutationPrepareCheckoutArgs = {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  checkoutOptions?: InputMaybe<Array<CheckoutOptionInput>>;
  context: ContextInput;
  cookies?: InputMaybe<Scalars["String"]>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  offerTokens?: InputMaybe<Array<OfferTokenInput>>;
  packages?: InputMaybe<Array<PackageNaturalKeyInput>>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
  responseOptions?: InputMaybe<Array<PrepareCheckoutResponseOption>>;
  totalPrice?: InputMaybe<MoneyInput>;
};

export type MutationPrepareCheckoutByCartIdArgs = {
  cartId: Scalars["UUID"];
  checkoutOptions?: InputMaybe<Array<CheckoutOptionInput>>;
  context: ContextInput;
  marketingChannelId?: InputMaybe<Scalars["Int"]>;
  totalPrice?: InputMaybe<MoneyInput>;
};

export type MutationPrepareCheckoutBySessionIdArgs = {
  checkoutOptions?: InputMaybe<Array<CheckoutOptionInput>>;
  context: ContextInput;
  cookies?: InputMaybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
};

export type MutationPrepareFopArgs = {
  context?: InputMaybe<ContextInput>;
  paymentPrepareFOPRequest: PaymentPrepareFopRequestInput;
};

export type MutationPriceMatchPromiseCheckPriceArgs = {
  context: ContextInput;
  tripContextToken?: InputMaybe<Scalars["String"]>;
  tripNumber: Scalars["String"];
};

export type MutationPublishInteractionEventArgs = {
  context: ContextInput;
  errorToken?: InputMaybe<Scalars["String"]>;
  eventName: InteractionEventName;
  id?: InputMaybe<Scalars["String"]>;
  sessionId?: InputMaybe<Scalars["UUID"]>;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type MutationRejectReviewArgs = {
  brandType: Scalars["String"];
  context: ContextInput;
  flagCode: Array<Scalars["String"]>;
  id: Scalars["String"];
};

export type MutationRemoveCouponArgs = {
  context: ContextInput;
  coupons: Array<CouponIdentifierInput>;
  sessionId: Scalars["String"];
  sessionToken: Scalars["String"];
};

export type MutationRemoveGlobalInsuranceArgs = {
  context: ContextInput;
  removeInsuranceRequest: RemoveInsuranceRequestInput;
};

export type MutationRemoveInsuranceArgs = {
  context: ContextInput;
  removeInsuranceRequest: RemoveInsuranceRequestInput;
};

export type MutationRemoveInsuranceOldArgs = {
  context: ContextInput;
  removeInsuranceRequest: RemoveInsuranceRequestOldInput;
};

export type MutationRemoveInsurtechOfferFromTripArgs = {
  context: ContextInput;
  insurtechRemoveTripRequest: InsurtechRemoveTripRequestInput;
};

export type MutationRemovePaymentMethodArgs = {
  context?: InputMaybe<ContextInput>;
  removePaymentMethodRequest: RemovePaymentMethodRequestInput;
};

export type MutationRemoveShortlistItemArgs = {
  context: ContextInput;
  pageName?: InputMaybe<Scalars["String"]>;
  productId: Scalars["String"];
  productType: ProductType;
};

export type MutationRemoveTripItemArgs = {
  context: ContextInput;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type MutationSaveAnswersArgs = {
  context: ContextInput;
  request: SaveReviewAnswersRequestInput;
};

export type MutationSaveReservationModifiersCancelArgs = {
  cancellationFee: Scalars["String"];
  cancellationReason: Scalars["String"];
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]>;
  originalBookedDates: Scalars["String"];
  originalBookingAmount: Scalars["String"];
  originalBookingCurrency: Scalars["String"];
  paymentMethod: Scalars["String"];
  previewChanges: Scalars["Boolean"];
  propertyContext: PropertyContext;
  reservationId: Scalars["String"];
  travelerName: Scalars["String"];
};

export type MutationSaveReservationModifiersChangeDatesArgs = {
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]>;
  newCheckInDate: Scalars["String"];
  newCheckOutDate: Scalars["String"];
  originalBookedDates: Scalars["String"];
  originalBookingAmount: Scalars["String"];
  originalBookingCurrency: Scalars["String"];
  paymentMethod: Scalars["String"];
  previewChanges: Scalars["Boolean"];
  propertyContext: PropertyContext;
  reasonForChange: Scalars["String"];
  reservationId: Scalars["String"];
  travelerName: Scalars["String"];
};

export type MutationSaveReservationModifiersGuestSatisfactionRefundArgs = {
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]>;
  originalBookedDates: Scalars["String"];
  originalBookingAmount: Scalars["String"];
  originalBookingCurrency: Scalars["String"];
  paymentMethod: Scalars["String"];
  previewChanges: Scalars["Boolean"];
  propertyContext: PropertyContext;
  reasonForRefund: Scalars["String"];
  refundAmount: Scalars["String"];
  reservationId: Scalars["String"];
  travelerName: Scalars["String"];
};

export type MutationSaveReservationModifiersNoShowArgs = {
  cancellationFee: Scalars["String"];
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]>;
  originalBookedDates: Scalars["String"];
  originalBookingAmount: Scalars["String"];
  originalBookingCurrency: Scalars["String"];
  paymentMethod: Scalars["String"];
  previewChanges: Scalars["Boolean"];
  propertyContext: PropertyContext;
  reservationId: Scalars["String"];
  travelerName: Scalars["String"];
};

export type MutationSaveShortlistItemArgs = {
  context: ContextInput;
  metadata?: InputMaybe<Array<ShortlistMetadataInput>>;
  pageName?: InputMaybe<Scalars["String"]>;
  productId: Scalars["String"];
  productType: ProductType;
};

export type MutationSaveSupplyRegulatorySettingsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  regulatoryType?: InputMaybe<Scalars["String"]>;
  settings: Array<SupplyRegulatorySettingInput>;
};

export type MutationSaveTripItemCommentArgs = {
  comment: Scalars["String"];
  commentId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type MutationSaveTripItemsArgs = {
  context: ContextInput;
  criteria: TripsUiSaveTripItemsCriteriaInput;
  pageLocation?: InputMaybe<PageLocation>;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type MutationSelectFopArgs = {
  context?: InputMaybe<ContextInput>;
  paymentSelectFOPRequest: PaymentSelectFopRequestInput;
};

export type MutationSendCommunicationCenterMessageArgs = {
  context: ContextInput;
  conversationId: Scalars["String"];
  messageText: Scalars["String"];
};

export type MutationSendCommunicationCenterMessageNonLegacyArgs = {
  context: ContextInput;
  conversationId: Scalars["String"];
  messageText: Scalars["String"];
};

export type MutationSendConversationEventArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext: ConversationContextInput;
  event: VirtualAgentControlConversationEventInput;
};

export type MutationSendMessageArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext: ConversationContextInput;
  messageInput: VirtualAgentControlMessageInput;
};

export type MutationSendTextToDownloadAppArgs = {
  context: ContextInput;
  customerInput: NotificationUserInput;
  placementId: Scalars["String"];
};

export type MutationSetFlightPriceAlertOptInArgs = {
  context: ContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  lowestPrice: Scalars["String"];
  optInStatus: Scalars["Boolean"];
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  travelerDetails?: InputMaybe<Array<FlightsTravelerDetailsInput>>;
};

export type MutationSetMinNightStayArgs = {
  context: ContextInput;
  minNightStayValue: Scalars["Int"];
  propertyContext: PropertyContext;
};

export type MutationShopGlobalInsuranceArgs = {
  context: ContextInput;
  insuranceShoppingRequest: InsuranceShoppingRequestInput;
};

export type MutationSmartFormArgs = {
  context: ContextInput;
  sessionId: Scalars["String"];
  sessionToken?: InputMaybe<Scalars["String"]>;
};

export type MutationSubmitInquiryArgs = {
  context: ContextInput;
  inquiryRequest?: InputMaybe<InquiryRequestInput>;
};

export type MutationSupplyCoachingTipPostponeArgs = {
  context: ContextInput;
  dismiss?: InputMaybe<Scalars["Boolean"]>;
  duration?: InputMaybe<Scalars["String"]>;
  postponeContext: Array<SupplyQuickActionFormContextInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyCoachingTipQuickActionSubmitArgs = {
  actionInputs?: InputMaybe<Array<SupplyQuickActionFormContextInput>>;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateAdditionalInfoArgs = {
  context: ContextInput;
  params: SupplyContentAdditionalInfoUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateHeadlineDescriptionArgs = {
  context: ContextInput;
  params?: InputMaybe<SupplyContentHeadlineDescriptionUpdateInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateLocationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  updateLocationInput: SupplyContentUpdateLocationInput;
};

export type MutationSupplyContentUpdateManualTranslationArgs = {
  context: ContextInput;
  params: SupplyContentManualTranslationUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateYourPropertyArgs = {
  context: ContextInput;
  params: SupplyContentYourPropertyUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateYourStoryArgs = {
  context: ContextInput;
  params: SupplyContentYourStoryUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContractingOffersAcceptanceArgs = {
  context: ContextInput;
  offerIds: Array<Scalars["String"]>;
  program?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type MutationSupplyFeesUpdateArgs = {
  context: ContextInput;
  feesInput: Array<SupplyFeesInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyIndirectTaxNavigateBackArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyIndirectTaxUpdateArgs = {
  context: ContextInput;
  indirectTaxInput: SupplyIndirectTaxInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyLoyaltyArgs = {
  context: ContextInput;
};

export type MutationSupplyMarketplaceFeedItemUpdateArgs = {
  context: ContextInput;
  input: SupplyMarketplaceFeedItemInput;
};

export type MutationSupplyMarketplaceFeedUpdateArgs = {
  actionInputs: SupplyMarketplaceFeedInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyMultiFactorCodeGenerationArgs = {
  context: ContextInput;
  mfaCodeGenerationInput: SupplyMultiFactorCodeGenerationInput;
};

export type MutationSupplyMultiFactorCodeVerificationArgs = {
  context: ContextInput;
  mfaCodeVerificationInput: SupplyMultiFactorCodeVerificationInput;
};

export type MutationSupplyNavigationNotificationActivityArgs = {
  context: ContextInput;
  input: SupplyNavigationNotificationActivityInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingAvailabilityUpdateArgs = {
  availabilityType?: InputMaybe<SupplyOnboardingAvailabilityType>;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingPublishListingArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingWelcomeNavigateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingWorkFlowNavigateArgs = {
  context: ContextInput;
  currentStep: Scalars["String"];
  propertyContext: PropertyContext;
  trustPayload?: InputMaybe<Scalars["String"]>;
  type: SupplyOnboardingButtonType;
};

export type MutationSupplyProductCreationBaseRateManagementArgs = {
  context: ContextInput;
  params: SupplyProductCreationBaseRateManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationBreakfastRatePlanManagementArgs = {
  context: ContextInput;
  params: SupplyProductCreationIndependentRateManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationChildRatePlanManagementArgs = {
  context: ContextInput;
  params: SupplyProductCreationChildRateManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationDeleteRatePlansArgs = {
  context: ContextInput;
  params: SupplyProductCreationDeleteRatePlansInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationPricingModelUpdateArgs = {
  context: ContextInput;
  pricingType: PricingType;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomInfoManagementArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomInfoManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomNameManagementArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameAttributesInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomNameUpdateArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyPromotionCreateTieredMembersOnlyDealsArgs = {
  context: ContextInput;
  membersOnlyDealsTiersInput: SupplyPromotionCreateMembersOnlyDealsTiersInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyPromotionUpdateStackingOptionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  stackingOptions: Array<StackingOptionInput>;
};

export type MutationSupplyPromotionUpdateTieredMembersOnlyDealArgs = {
  context: ContextInput;
  membersOnlyDealsTiersInput: SupplyPromotionUpdateMembersOnlyDealsTiersInput;
  promotionId: Scalars["String"];
  propertyContext: PropertyContext;
};

export type MutationSupplyRoomTypeMappingPropertyValidateArgs = {
  actionContext: SupplyRoomTypeMappingPropertyValidationInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyRoomTypeMappingSubmitArgs = {
  actionContext: SupplyRoomTypeMappingActionContext;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyTaxesUpdateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  taxesInput?: InputMaybe<SupplyTaxesInput>;
};

export type MutationSupplyUpdateNewListingOfferEnrollmentArgs = {
  context: ContextInput;
  input?: InputMaybe<SupplyNewListingOfferEnrollmentInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePartnerAttestationArgs = {
  attestationInput: SupplyOnboardingPartnerAttestationInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePartnerBillingAddressArgs = {
  billingAddressInput: SupplyOnboardingPartnerBillingAddressInput;
  context: ContextInput;
};

export type MutationSupplyUpdatePartnerBusinessNameArgs = {
  businessNameInput: SupplyOnboardingPartnerBusinessNameInput;
  context: ContextInput;
};

export type MutationSupplyUpdatePartnerHostTypeArgs = {
  context: ContextInput;
  hostType: Scalars["String"];
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePartnerLegalNameArgs = {
  context: ContextInput;
  legalNameInput: SupplyOnboardingPartnerLegalNameInput;
};

export type MutationSupplyUpdatePartnerPayoutDeferredAccountArgs = {
  context: ContextInput;
  input: SupplyPartnerPayoutDeferredAccountInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePropertyRatesArgs = {
  context: ContextInput;
  input: SupplyPropertyRateInput;
  propertyContext: PropertyContext;
};

export type MutationSweepstakesDisenrollArgs = {
  context: ContextInput;
};

export type MutationSweepstakesUpdateArgs = {
  context: ContextInput;
  updateAction: UpdateAction;
};

export type MutationSweepstakesUserWipeArgs = {
  apiKey: Scalars["String"];
  campaignId: Scalars["String"];
};

export type MutationTextToDownloadAppArgs = {
  context: ContextInput;
  customerInput: CustomerNotificationUserInput;
  placementId: Scalars["String"];
};

export type MutationTopTaskDismissArgs = {
  actionInputs: TopTaskCardAction;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationTripArgs = {
  context: ContextInput;
};

export type MutationTripsRefreshItineraryItemArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"];
  itineraryItemId: Scalars["String"];
};

export type MutationTripsRemoveItineraryItemArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"];
  itineraryItemId: Scalars["String"];
};

export type MutationTriviaStartArgs = {
  context: ContextInput;
  request: TriviaStartRequestInput;
};

export type MutationTriviaSubmitArgs = {
  context: ContextInput;
  request: TriviaSubmitRequestInput;
};

export type MutationUnhideAllSearchHistoriesArgs = {
  context: ContextInput;
};

export type MutationUnhideSearchHistoryArgs = {
  context: ContextInput;
  historyTypes?: InputMaybe<Array<HistoryType>>;
  interactionId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAgentPresenceArgs = {
  context: ContextInput;
  flavor: FlavorType;
  partnerId: Scalars["String"];
  roleId: Scalars["String"];
  updatePresenceTo: AgentStateInput;
  userId: Scalars["String"];
};

export type MutationUpdateConversationStateArgs = {
  author?: InputMaybe<Scalars["String"]>;
  authorChannel?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  conversationId: Scalars["String"];
  sentDateTime?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateFopsArgs = {
  context?: InputMaybe<ContextInput>;
  updateFopRequest: PaymentUpdateFopRequestInput;
};

export type MutationUpdateInboxArgs = {
  context?: InputMaybe<ContextInput>;
  modifiableAttributes: Array<NotificationSpecificationsInput>;
};

export type MutationUpdateInboxNotificationArgs = {
  modifiableAttributes: Array<CustomerNotificationModifiableAttributesInput>;
};

export type MutationUpdateInboxStateArgs = {
  context?: InputMaybe<ContextInput>;
  notificationIds: Array<Scalars["String"]>;
  state: NotificationState;
};

export type MutationUpdateInsurtechOfferArgs = {
  context: ContextInput;
  updateInsurtechOfferRequest: UpdateInsurtechOfferRequestInput;
};

export type MutationUpdateInteractionsArgs = {
  action: SupplyReservationsInteractionListItemAction;
  context: ContextInput;
  interactionIds: Array<Scalars["String"]>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type MutationUpdateMessageStateArgs = {
  messageId: Scalars["String"];
  state: CommunicationCenterMessagePreviewState;
};

export type MutationUpdateNewListingOfferEnrollmentArgs = {
  context: ContextInput;
  input?: InputMaybe<SupplyNewListingOfferEnrollmentInput>;
  propertyContext: PropertyContext;
};

export type MutationUpdateNotificationsStateArgs = {
  context: ContextInput;
  notificationIds: Array<Scalars["String"]>;
  state: CommunicationCenterNotificationState;
};

export type MutationUpdatePartnerPayoutDeferredAccountArgs = {
  context: ContextInput;
  input: SupplyPartnerPayoutDeferredAccountInput;
  propertyContext: PropertyContext;
};

export type MutationUpdatePaymentMethodArgs = {
  context?: InputMaybe<ContextInput>;
  updatePaymentMethodRequest: UpdatePaymentMethodRequestInput;
};

export type MutationUpdatePaymentSessionArgs = {
  paymentSessionId: Scalars["String"];
  tokenizedPaymentCard: Scalars["String"];
};

export type MutationUpdatePriceTrackingArgs = {
  context: ContextInput;
  priceInsightsOperationData?: InputMaybe<PriceInsightsUpdateMutationDataInput>;
  priceInsightsSearchContext?: InputMaybe<PriceInsightsSearchContextInput>;
  priceShown?: InputMaybe<Scalars["String"]>;
  subscriptionId: Scalars["String"];
};

export type MutationUpdatePropertyRatesArgs = {
  context: ContextInput;
  input: SupplyPropertyRateInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyAmenitiesArgs = {
  context: ContextInput;
  input: SupplyAmenitiesUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyAttributesArgs = {
  context: ContextInput;
  input: SupplyAttributesUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyDismissibleCardArgs = {
  context: ContextInput;
  input: SupplyDismissibleCardInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyReservationsAccessInstructionsArgs = {
  accessIdentifier: SupplyReservationsAccessIdentifierInput;
  accessInstructions: SupplyReservationsAccessInstructionsInput;
  context: ContextInput;
  locale?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
  reservationUuid: Scalars["UUID"];
};

export type MutationUpdateSupplyReservationsPartnerNoteArgs = {
  context: ContextInput;
  note: Scalars["String"];
  propertyContext: PropertyContext;
  reservationId: Scalars["String"];
};

export type MutationUpdateSupplySelectedWaiversArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  selectedWaivers: Array<InputMaybe<Scalars["Int"]>>;
};

export type MutationUpdateTripItemDatesArgs = {
  context: ContextInput;
  dateRange: DateRangeInput;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type MutationUpdateTripItemPriceAlertStatusArgs = {
  alertEnabled: Scalars["Boolean"];
  context: ContextInput;
  itemId: Scalars["String"];
  subscriptionId: Scalars["String"];
  tripId: Scalars["String"];
};

export type MutationUpdateTripItemTripArgs = {
  context: ContextInput;
  itemId: Scalars["String"];
  operationType: Scalars["String"];
  pageLocation?: InputMaybe<PageLocation>;
  tripContext: TripsUiTripContextInput;
};

export type MutationUpdateTripItemVoteArgs = {
  context: ContextInput;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type MutationUpvotePropertyReviewArgs = {
  id: Scalars["String"];
};

export type MutationUpvotePropertyReviewWithAccessibilityArgs = {
  context: ContextInput;
  index: Scalars["Int"];
  reviewId: Scalars["String"];
};

export type MutationUserDismissTaskArgs = {
  actionInputs: TopTaskAction;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationUserProfileWizardSubmitArgs = {
  collectedActionContexts: Array<Scalars["String"]>;
  context: ContextInput;
  wizardContext: ProfileWizardContextInput;
};

export type MutationUserSettingFormSubmitArgs = {
  actionContext: Scalars["String"];
  context: ContextInput;
  inputs: Array<GraphQlPairInput>;
};

export type MutationVirtualAgentControlCreateConversationArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationInitializationConfigs: ConversationInitializationConfigsInput;
};

export type MutationVirtualAgentFlightCheckoutArgs = {
  context: ContextInput;
  flightsDetailsCriteria: FlightsDetailCriteriaInput;
  virtualAgentContext: VirtualAgentContextInput;
};

export type NavTab = {
  __typename?: "NavTab";
  analytics: CarAnalytics;
  name: Scalars["String"];
};

export type NavigationCookie = {
  __typename?: "NavigationCookie";
  expires: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type NavigationTab = {
  __typename?: "NavigationTab";
  /** @deprecated Use clickAction instead */
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityClickAction>;
  label: Scalars["String"];
  /** @deprecated Use clickAction instead */
  sectionRef: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
};

export type NavigationTabs = {
  __typename?: "NavigationTabs";
  tabs: Array<PropertyNavigationBarTab>;
};

export type NavigationType = "COLLAPSE" | "EXPAND";

export type NearbyAirportsSuggestion = {
  __typename?: "NearbyAirportsSuggestion";
  content: PackageUiNearbyAirports;
  expando: EgdsExpandoCard;
};

export type Neighborhood = {
  __typename?: "Neighborhood";
  name: Scalars["String"];
  regionId: Scalars["String"];
};

export type NestedListingContainer = {
  __typename?: "NestedListingContainer";
  heading?: Maybe<ShoppingListingContainerHeading>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<NestedListingContainerItems>;
  layout: NestedListingContainerLayout;
};

export type NestedListingContainerItems = ShoppingListingContainer;

export type NestedListingContainerLayout = EgdsBasicTabs;

export type NightlyRate = {
  __typename?: "NightlyRate";
  available: Scalars["Boolean"];
  date?: Maybe<DateTime>;
  displayPrice?: Maybe<Money>;
  strikeOutPrice?: Maybe<Money>;
};

export type NoCancelFeeType = "NO_CANCEL_FEE";

export type NoChangeFeeType = "NO_CHANGE_FEE";

export type NoMatchesFoundComponent = {
  __typename?: "NoMatchesFoundComponent";
  analytics: ClientSideAnalytics;
  filterPills?: Maybe<Array<ShoppingAppliedFilter>>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<Scalars["String"]>;
  removeAllFiltersAction?: Maybe<ActivityLinkClickAction>;
  removeAllFiltersButton?: Maybe<EgdsButton>;
  title: Scalars["String"];
};

export type NoMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "NoMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type NoResultsMessage = UiEmptyState | UiMessagingCard;

export type NorthstarFooterImage = {
  __typename?: "NorthstarFooterImage";
  altText?: Maybe<Scalars["String"]>;
  imgSrc?: Maybe<Scalars["URL"]>;
  newWindowText?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type NorthstarFooterSection = {
  __typename?: "NorthstarFooterSection";
  data?: Maybe<Array<NorthstarFooterSectionLink>>;
  text?: Maybe<Scalars["String"]>;
};

export type NorthstarFooterSectionImages = FooterSectionData & {
  __typename?: "NorthstarFooterSectionImages";
  data: Array<Maybe<NorthstarFooterImage>>;
};

export type NorthstarFooterSectionLink = {
  __typename?: "NorthstarFooterSectionLink";
  target?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  trackingEvent?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type NorthstarFooterSectionLinks = FooterSectionData & {
  __typename?: "NorthstarFooterSectionLinks";
  data: Array<Maybe<FooterData>>;
  minimal?: Maybe<Scalars["Boolean"]>;
};

export type NorthstarFooterSectionText = FooterSectionData & {
  __typename?: "NorthstarFooterSectionText";
  data: Array<Maybe<NorthstarFooterText>>;
  minimal?: Maybe<Scalars["Boolean"]>;
};

export type NorthstarFooterText = {
  __typename?: "NorthstarFooterText";
  text?: Maybe<Scalars["String"]>;
};

export type NorthstarMinimalFooterSectionLinks = FooterSectionData & {
  __typename?: "NorthstarMinimalFooterSectionLinks";
  data: Array<Maybe<FooterData>>;
};

export interface NotificationAccessibilityField {
  accessibilityLabel?: Maybe<Scalars["String"]>;
}

export type NotificationAction =
  | NotificationAnalytics
  | NotificationAnalyticsEgClickstream
  | NotificationCookie
  | NotificationMarketing
  | NotificationSchema
  | NotificationSpecifications;

export type NotificationAnalytics = {
  __typename?: "NotificationAnalytics";
  description?: Maybe<Scalars["String"]>;
  referrerId: Scalars["String"];
  schema?: Maybe<Array<NotificationSchema>>;
};

export type NotificationAnalyticsEgClickstream = {
  __typename?: "NotificationAnalyticsEgClickstream";
  description?: Maybe<Scalars["String"]>;
  event: EgClickstreamEvent;
  schema?: Maybe<Array<NotificationSchema>>;
};

export type NotificationAnalyticsSchemaAttribute = {
  __typename?: "NotificationAnalyticsSchemaAttribute";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type NotificationApiDateTime = {
  __typename?: "NotificationApiDateTime";
  day: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  epochSeconds: Scalars["String"];
  formatted: Scalars["String"];
  fullDateFormat?: Maybe<Scalars["String"]>;
  hour: Scalars["Int"];
  longDateFormat?: Maybe<Scalars["String"]>;
  mediumDateFormat?: Maybe<Scalars["String"]>;
  mediumTimeFormat?: Maybe<Scalars["String"]>;
  minute: Scalars["Int"];
  month: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["String"];
  second: Scalars["Int"];
  shortDateFormat?: Maybe<Scalars["String"]>;
  shortTimeFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use ZonedDateTime if you want time zone information, replace with ZonedDateTime */
  timeZoneOffsetSeconds: Scalars["Int"];
  year: Scalars["Int"];
};

export type NotificationApiDateTimeFormattedArgs = {
  format: Scalars["String"];
};

export type NotificationAttribute = {
  __typename?: "NotificationAttribute";
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type NotificationBannerTheme = {
  __typename?: "NotificationBannerTheme";
  themeValue?: Maybe<BannerTheme>;
};

export type NotificationCardTheme = {
  __typename?: "NotificationCardTheme";
  cardBorder?: Maybe<Scalars["Boolean"]>;
  themeValue?: Maybe<CardTheme>;
};

export type NotificationCenter = HeaderSharedUi & {
  __typename?: "NotificationCenter";
  skipSsr: Scalars["Boolean"];
};

export type NotificationCenterItem = {
  __typename?: "NotificationCenterItem";
  deleteActionForm?: Maybe<NotificationForm>;
  emptyState?: Maybe<NotificationEmptyState>;
  icon?: Maybe<Icon>;
  notifications?: Maybe<Array<NotificationInbox>>;
  redirectPath?: Maybe<Scalars["String"]>;
  revealActions?: Maybe<Array<NotificationAction>>;
  title?: Maybe<NotificationPhrase>;
  unseen?: Maybe<Scalars["Int"]>;
};

export type NotificationContainer = {
  __typename?: "NotificationContainer";
  layout: LayoutPosition;
  theme?: Maybe<NotificationTheme>;
};

export type NotificationContainerLink = NotificationLink & {
  __typename?: "NotificationContainerLink";
  actions?: Maybe<Array<NotificationAction>>;
  id?: Maybe<Scalars["String"]>;
  target?: Maybe<NotificationLinkTarget>;
  uri?: Maybe<Uri>;
};

export type NotificationCookie = {
  __typename?: "NotificationCookie";
  expires: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type NotificationDismiss = {
  __typename?: "NotificationDismiss";
  actions?: Maybe<Array<NotificationAction>>;
  icon: Icon;
};

export type NotificationEgdsItems = NotificationEgdsLinkNode | NotificationEgdsTextNode;

export type NotificationEgdsLinkNode = {
  __typename?: "NotificationEGDSLinkNode";
  action: UiLinkAction;
  disabled: Scalars["Boolean"];
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export type NotificationEgdsTextNode = {
  __typename?: "NotificationEGDSTextNode";
  text: Scalars["String"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type NotificationEmptyState = {
  __typename?: "NotificationEmptyState";
  body?: Maybe<Array<NotificationPhrase>>;
  icon?: Maybe<Icon>;
  title?: Maybe<NotificationPhrase>;
};

export type NotificationForm = {
  __typename?: "NotificationForm";
  errorFormAnalytics?: Maybe<Array<NotificationAction>>;
  feedbackMessages?: Maybe<Array<NotificationMessage>>;
  sections: Array<NotificationFormSection>;
  successFormAnalytics?: Maybe<Array<NotificationAction>>;
  title?: Maybe<Scalars["String"]>;
};

export type NotificationFormButtonType =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export interface NotificationFormField {
  label: Scalars["String"];
}

export type NotificationFormSection = {
  __typename?: "NotificationFormSection";
  fields: Array<NotificationFormField>;
  title?: Maybe<Scalars["String"]>;
};

export interface NotificationInModuleMessageInput {
  moduleName?: InputMaybe<NotificationInModuleModuleName>;
  name: NotificationInModuleMessageName;
}

export type NotificationInModuleMessageName =
  | "COUPON_REMOVAL_FAILED_MESSAGE"
  | "CPM_CC_ON_FILE_MESSAGE"
  | "CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE"
  | "CPM_NO_PAYMENT_DUE_MESSAGE"
  | "CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE"
  | "OKC_ADD_FAILED_MESSAGE"
  | "OKC_REMOVAL_FAILED_MESSAGE"
  | "PAY_NOW_CC_ON_FILE_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_TOTAL_AMOUNT"
  | "PAY_NOW_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_TOTAL_AMOUNT";

export type NotificationInModuleModuleName = "COMMON_PAYMENT_MODULE" | "COUPON_MODULE" | "LOYALTY_BURN_MODULE";

export type NotificationInbox = ContextualNotification & {
  __typename?: "NotificationInbox";
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  notificationId?: Maybe<Scalars["String"]>;
  revealActions?: Maybe<Array<NotificationAction>>;
  state: NotificationState;
  subType?: Maybe<NotificationSubType>;
  timeStamp?: Maybe<Scalars["String"]>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
  userActions?: Maybe<Array<NotificationUserAction>>;
};

export interface NotificationLink {
  actions?: Maybe<Array<NotificationAction>>;
  id?: Maybe<Scalars["String"]>;
  target?: Maybe<NotificationLinkTarget>;
  uri?: Maybe<Uri>;
}

export type NotificationLinkTarget = "NEW_TAB" | "SAME_TAB";

export type NotificationLinkType =
  | "ANCHOR"
  | "APP_DOWNLOAD_LINK"
  | "BUTTON"
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export type NotificationLocation =
  | "AFTER_HEADER"
  | "AFTER_WIZARD"
  | "FOOTER"
  | "IN_PAGE"
  | "IN_PAGE_AD"
  | "LEGAL"
  | "POPUP"
  | "PRE_HEADER";

export type NotificationLocationOnPage =
  | "AFTER_HEADER"
  | "AFTER_WIZARD"
  | "ERROR"
  | "FOOTER"
  | "FULL_PAGE"
  | "IN_MODAL"
  | "IN_MODULE"
  | "IN_PAGE"
  | "IN_PAGE_AD"
  | "LEGAL"
  | "POPUP"
  | "PRE_HEADER";

export type NotificationMarketing = {
  __typename?: "NotificationMarketing";
  marketingCode: Scalars["String"];
  marketingCodeDetail: Scalars["String"];
};

export type NotificationMessage = {
  __typename?: "NotificationMessage";
  displayType: NotificationMessageDisplayType;
  level: NotificationMessageLevel;
  name: Scalars["String"];
  text: Scalars["String"];
  title: Scalars["String"];
};

export type NotificationMessageCard = {
  __typename?: "NotificationMessageCard";
  body: Array<NotificationPhrase>;
  graphicElement?: Maybe<UiGraphic>;
  layout: NotificationMessageCardLayout;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  title: NotificationPhrase;
};

export type NotificationMessageCardLayout = "ICON_ON_SIDE" | "ICON_ON_TOP" | "WITH_BORDER";

export type NotificationMessageDisplayType = "FORM" | "PAGE";

export type NotificationMessageLevel = "ERROR" | "INFO" | "SUCCESS" | "WARNING";

export type NotificationMessageType = "INVALID_INPUT" | "SERVICE_ERROR" | "SUCCESS";

export type NotificationMultiBody = {
  __typename?: "NotificationMultiBody";
  body: Array<NotificationPhrase>;
  title: NotificationPhrase;
};

export interface NotificationOptionalContextInput {
  errorToken?: InputMaybe<Scalars["String"]>;
  inModuleMessage?: InputMaybe<NotificationInModuleMessageInput>;
  itineraryNumber?: InputMaybe<Scalars["String"]>;
  journeyCriterias?: InputMaybe<Array<NotificationOptionalContextJourneyCriteriaInput>>;
  rafInfo?: InputMaybe<NotificationRafInfoInput>;
  referer?: InputMaybe<Scalars["URL"]>;
  sessionId?: InputMaybe<Scalars["String"]>;
  travelApiId?: InputMaybe<Scalars["String"]>;
  tripFolderId?: InputMaybe<Scalars["String"]>;
  tripItemId?: InputMaybe<Scalars["String"]>;
}

export interface NotificationOptionalContextJourneyCriteriaFiltersInput {
  airlinesFilter?: InputMaybe<Array<Scalars["String"]>>;
  amenitiesFilter?: InputMaybe<Array<AmenityFilter>>;
  arrivalTimeFilter?: InputMaybe<Array<FlightsTimePeriodOfDay>>;
  departureTimeFilter?: InputMaybe<Array<FlightsTimePeriodOfDay>>;
  numberOfStopsFilter?: InputMaybe<Array<Scalars["Int"]>>;
}

export interface NotificationOptionalContextJourneyCriteriaInput {
  cabinClass?: InputMaybe<FlightsCabinClass>;
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<NotificationOptionalContextJourneyCriteriaLocationInput>;
  filter?: InputMaybe<Array<NotificationOptionalContextJourneyCriteriaFiltersInput>>;
  origin?: InputMaybe<NotificationOptionalContextJourneyCriteriaLocationInput>;
  scheduleChangeStatus?: InputMaybe<TripScheduleChangeStatus>;
  travelerDetail?: InputMaybe<Array<TravelerDetailsInput>>;
  tripType?: InputMaybe<FlightsTripType>;
  tripsData?: InputMaybe<TripsDataInput>;
}

export interface NotificationOptionalContextJourneyCriteriaLocationInput {
  airportTLA?: InputMaybe<Scalars["String"]>;
  propertyId?: InputMaybe<Scalars["String"]>;
  regionId?: InputMaybe<Scalars["String"]>;
}

export type NotificationPhrase = NotificationAccessibilityField & {
  __typename?: "NotificationPhrase";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  completeText: Scalars["String"];
  egdsItems: Array<NotificationEgdsItems>;
  egdsTextItems: Array<EgdsComposableTextElement>;
  items: Array<NotificationPhrasePart>;
};

export type NotificationPhraseLinkNode = NotificationLink &
  NotificationPhrasePart & {
    __typename?: "NotificationPhraseLinkNode";
    actions?: Maybe<Array<NotificationAction>>;
    icon?: Maybe<Icon>;
    id?: Maybe<Scalars["String"]>;
    linkStyle?: Maybe<NotificationPhraseLinkStyle>;
    linkTheme?: Maybe<NotificationPhraseLinkTheme>;
    styles: Array<NotificationPhrasePartStyle>;
    target?: Maybe<NotificationLinkTarget>;
    text: Scalars["String"];
    type: NotificationLinkType;
    uri?: Maybe<Uri>;
  };

export type NotificationPhraseLinkStyle =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export type NotificationPhraseLinkTheme = "DEFAULT" | "INVERSE";

export interface NotificationPhrasePart {
  styles: Array<NotificationPhrasePartStyle>;
  text: Scalars["String"];
}

export type NotificationPhrasePartStyle = "EMPHASIS" | "IMPORTANT" | "STANDARD";

export type NotificationPhraseTextNode = NotificationPhrasePart & {
  __typename?: "NotificationPhraseTextNode";
  styles: Array<NotificationPhrasePartStyle>;
  text: Scalars["String"];
};

export type NotificationPreferenceType = "EMAIL" | "PUSH";

export type NotificationQrCode = {
  __typename?: "NotificationQRCode";
  image: Image;
  title: Scalars["String"];
};

export type NotificationQuery = {
  __typename?: "NotificationQuery";
  inboxNotification: Array<InboxNotification>;
  inlineNotification: InlineNotification;
  mobileAppDownloadButton: AppDownloadButton;
  notificationCenter: NotificationCenterItem;
};

export type NotificationQueryInlineNotificationArgs = {
  lineOfBusiness: LineOfBusinessDomain;
  notificationLocation: NotificationLocationOnPage;
  optionalContext?: InputMaybe<NotificationOptionalContextInput>;
  optionalPageId?: InputMaybe<Scalars["String"]>;
  pageLocation: PageLocation;
};

export type NotificationQueryMobileAppDownloadButtonArgs = {
  optionalPageId?: InputMaybe<Scalars["String"]>;
  referrer?: InputMaybe<Scalars["String"]>;
};

export interface NotificationRafInfoInput {
  rafLoginScenario?: InputMaybe<IdentityLoginScenario>;
  rafPlacementId?: InputMaybe<Scalars["String"]>;
  refcode: Scalars["String"];
}

export type NotificationSchema = {
  __typename?: "NotificationSchema";
  messageContent: Array<NotificationAnalyticsSchemaAttribute>;
  name: Scalars["String"];
};

export type NotificationSelectionField = NotificationFormField & {
  __typename?: "NotificationSelectionField";
  id: Scalars["String"];
  label: Scalars["String"];
  options?: Maybe<Array<NotificationSelectionOption>>;
};

export type NotificationSelectionOption = {
  __typename?: "NotificationSelectionOption";
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  selected: Scalars["Boolean"];
  shortLabel?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  value: Scalars["String"];
};

export type NotificationSimpleBody = {
  __typename?: "NotificationSimpleBody";
  title: NotificationPhrase;
};

export type NotificationSpecifications = {
  __typename?: "NotificationSpecifications";
  dismiss?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  read?: Maybe<Scalars["Boolean"]>;
  seen?: Maybe<Scalars["Boolean"]>;
};

export interface NotificationSpecificationsInput {
  dismiss?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  read?: InputMaybe<Scalars["Boolean"]>;
  seen?: InputMaybe<Scalars["Boolean"]>;
}

export type NotificationState = "READ" | "SEEN" | "UNKNOWN" | "UNSEEN";

export type NotificationSubType = "COUPON_CAMPAIGN" | "DEFAULT";

export type NotificationSubmitButton = NotificationFormField & {
  __typename?: "NotificationSubmitButton";
  actions?: Maybe<Array<NotificationAction>>;
  buttonType?: Maybe<NotificationFormButtonType>;
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  label: Scalars["String"];
};

export type NotificationTextInputField = NotificationFormField & {
  __typename?: "NotificationTextInputField";
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  label: Scalars["String"];
  placeholder: Scalars["String"];
};

export type NotificationTextToDownloadAppResponse = {
  __typename?: "NotificationTextToDownloadAppResponse";
  invalidFields?: Maybe<Array<Scalars["String"]>>;
  messageBody?: Maybe<Scalars["String"]>;
  messageType: NotificationMessageType;
};

export type NotificationTheme = NotificationBannerTheme | NotificationCardTheme;

export type NotificationType =
  | "ALERT"
  | "APP_DOWNLOAD"
  | "APP_HERO"
  | "BOTTOM_SHEET"
  | "CENTERED_SHEET"
  | "CITI_COBRAND"
  | "CITI_COBRAND_VERTICAL"
  | "CLEANLINESS"
  | "CONFIGURABLE"
  | "ERROR"
  | "FULL_PAGE"
  | "FULL_SHEET"
  | "FULL_SHEET_MEDIA"
  | "GENERIC"
  | "HEADER"
  | "INBOX"
  | "MAD_MARQUEE"
  | "NONE"
  | "ONETRUST"
  | "ONE_KEY"
  | "OSANO"
  | "POPOVER"
  | "RAF_BANNER"
  | "TAKEOVER"
  | "TRAVEL_ADVISORY"
  | "TRUST_MSG_BANNER"
  | "UNRECOVERABLE_ERROR";

export type NotificationUserAction = {
  __typename?: "NotificationUserAction";
  actions?: Maybe<Array<NotificationAction>>;
  icon?: Maybe<Icon>;
  text: Scalars["String"];
  type: NotificationUserActionType;
};

export type NotificationUserActionType = "DELETE" | "MARK_AS_READ" | "MARK_AS_UNREAD" | "UNKNOWN";

export interface NotificationUserInput {
  inputs: Array<NotificationUserInputElementInput>;
}

export interface NotificationUserInputElementInput {
  type: Scalars["String"];
  value: Scalars["String"];
}

export type NotificationsType = "ALL";

export type NumberValue = {
  __typename?: "NumberValue";
  id: Scalars["String"];
  value: Scalars["Int"];
};

export interface NumberValueInput {
  id: Scalars["String"];
  value: Scalars["Int"];
}

export type Offer = {
  __typename?: "Offer";
  action?: Maybe<LodgingShoppingAction>;
  availability?: Maybe<PropertyAvailability>;
  /** @deprecated deprecated in favor of offerBookButton */
  bookButton?: Maybe<LodgingForm>;
  cancellationPolicy?: Maybe<PropertyCancellationPolicy>;
  dataAttributes?: Maybe<GraphQlPair>;
  dealMarker?: Maybe<Scalars["String"]>;
  deposit?: Maybe<Money>;
  depositPolicies: Array<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  dynamicRateRule?: Maybe<DynamicRateRule>;
  etpModalPolicies?: Maybe<Array<Scalars["String"]>>;
  feeMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated FeeMessages will contain the messaging previously found here */
  fees: Array<Fees>;
  hotelCollect?: Maybe<Scalars["Boolean"]>;
  /** @deprecated deprecated - adding tempararily for pwa support, logic will be moved serverside */
  installmentPayable: Scalars["Boolean"];
  lodgingPrepareCheckout?: Maybe<LodgingPrepareCheckout>;
  /** @deprecated deprecated in favour of priceBreakDownSummary, replace with priceBreakDownSummary */
  lodgingPriceBreakDown?: Maybe<LodgingPriceBreakDown>;
  loyaltyMessage?: Maybe<LodgingEnrichedMessage>;
  mandatoryFees?: Maybe<MandatoryFees>;
  /** @deprecated Look for minRoomsLeft in availability.minRoomsLeft */
  minRoomsLeft?: Maybe<Scalars["Int"]>;
  nightlyRates: Array<NightlyRate>;
  noCreditCard: Scalars["Boolean"];
  offerBookButton?: Maybe<LodgingForm>;
  optionTitle?: Maybe<LodgingHeader>;
  paymentChoice?: Maybe<Scalars["Boolean"]>;
  paymentModel?: Maybe<PropertyPaymentModel>;
  pointsApplied?: Maybe<Scalars["String"]>;
  price?: Maybe<PropertyPrice>;
  priceAfterLoyaltyPointsApplied?: Maybe<PropertyPrice>;
  priceBreakDownSummary?: Maybe<PriceSummary>;
  priceLabel?: Maybe<Scalars["String"]>;
  pricePresentation?: Maybe<PricePresentation>;
  pricePresentationDialog?: Maybe<PricePresentationDialog>;
  pricingScheme?: Maybe<PricingScheme>;
  /** @deprecated deprecated in favor of propertyNaturalKeys to support MRP offer, replace with propertyNaturalKeys */
  propertyNaturalKey?: Maybe<PropertyNaturalKey>;
  propertyNaturalKeys: Array<PropertyNaturalKey>;
  providerId?: Maybe<Scalars["Int"]>;
  ratePlanId?: Maybe<Scalars["String"]>;
  roomTypeId?: Maybe<Scalars["String"]>;
  saleCurrency?: Maybe<Scalars["String"]>;
  showNightlyRateMessage?: Maybe<Scalars["Boolean"]>;
  showTaxesAndFeesIncludedMessage?: Maybe<Scalars["Boolean"]>;
  showTotalPrice?: Maybe<Scalars["Boolean"]>;
  sourceType?: Maybe<SourceType>;
  supplyCurrency?: Maybe<Scalars["String"]>;
  totalPriceMessage?: Maybe<Scalars["String"]>;
  variableMargin?: Maybe<Scalars["Int"]>;
};

export type OfferActionLink = {
  __typename?: "OfferActionLink";
  href: Scalars["String"];
  referrerId?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type OfferAttribute = {
  __typename?: "OfferAttribute";
  icon?: Maybe<Icon>;
  type?: Maybe<OfferAttributeType>;
};

export type OfferAttributeType = "FREE_BREAKFAST" | "FREE_CANCELLATION" | "NO_CC_REQUIRED" | "PAYMENT_CHOICE" | "VIP";

export type OfferBadge = {
  __typename?: "OfferBadge";
  primary?: Maybe<Badge>;
  secondary?: Maybe<Badge>;
  tertiary?: Maybe<Badge>;
};

export type OfferData = {
  __typename?: "OfferData";
  actionLink: OfferActionLink;
  price: OfferPrice;
  strikeThroughPrice?: Maybe<OfferPrice>;
};

export type OfferDetails = {
  __typename?: "OfferDetails";
  alternateAvailabilityMsg?: Maybe<LodgingComplexLinkMessage>;
  alternateDates?: Maybe<AlternateDatesCard>;
  categorizedListings?: Maybe<Array<LodgingOfferListing>>;
  errorMessage?: Maybe<MessageResult>;
  /** @deprecated Deprecated. Contents are volatile and subject to change at any moment. Use at your own risk! */
  experimental?: Maybe<PropertyOffersExperimentalSection>;
  /** @deprecated Use offerFilters */
  filterPills?: Maybe<Array<LodgingPill>>;
  highlightedBenefits?: Maybe<EgdsTextIconList>;
  id: Scalars["String"];
  includedPerks?: Maybe<PropertyInfoContent>;
  /** @deprecated For COVID-19 Usage only */
  legalBanner?: Maybe<MessageResult>;
  legalDisclaimer?: Maybe<DisclaimerContentMarkup>;
  listings?: Maybe<Array<PropertyInfoOffersListing>>;
  listingsHeader: LodgingHeader;
  loading?: Maybe<OfferDetailsLoading>;
  lodgingOneKeyBurnSwitch?: Maybe<LodgingOneKeyBurnSwitch>;
  loyaltyDiscount?: Maybe<PropertyLoyaltyDiscount>;
  marketingChannelId: Scalars["Int"];
  offerAdaptExSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  offerFilters?: Maybe<LodgingOfferFilters>;
  offerLevelMessages: Array<MessageResult>;
  partnerPropertySearchLink?: Maybe<LodgingLinkMessage>;
  /** @deprecated Moved under ratePlans to include marketing section details, replace with units.ratePlans */
  paymentPolicy: Array<PropertyPaymentPolicyInfo>;
  priceInsight: PriceInsight;
  pricingFrequentlyAskedQuestions?: Maybe<Array<PropertyFaq>>;
  propertyContentSectionGroup?: Maybe<PropertyContentSectionGroup>;
  propertyHighlightSection?: Maybe<PropertyHighlightSection>;
  propertyLevelOffersCard?: Maybe<EgdsStandardMessagingCard>;
  propertyLevelOffersMessage?: Maybe<MessageResult>;
  propertySearchLink?: Maybe<LodgingLinkMessage>;
  searchCriteria?: Maybe<PropertySearchCriteria>;
  serpMetadata?: Maybe<SerpMetadata>;
  shoppingContext?: Maybe<ShoppingContext>;
  singleUnitOffer?: Maybe<SingleUnitOfferDetails>;
  singleUnitOfferDialog?: Maybe<SingleUnitOfferDialog>;
  soldOut: Scalars["Boolean"];
  spaceDetails?: Maybe<SpaceDetails>;
  stickyBar?: Maybe<PropertyDetailsStickyBar>;
  toastMessage?: Maybe<LodgingToastMessage>;
  tripSummary?: Maybe<TripSummaryContent>;
  units: Array<PropertyUnit>;
  unitsCarousel?: Maybe<LodgingCarouselContainer>;
};

export type OfferDetailsLoading = AccessibilityField & {
  __typename?: "OfferDetailsLoading";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
};

export type OfferIdentity = {
  __typename?: "OfferIdentity";
  type: OfferIdentityType;
  value: Scalars["String"];
};

export interface OfferIdentityInput {
  type: OfferIdentityType;
  value: Scalars["String"];
}

export type OfferIdentityType = "EG_OFFER_ID" | "EG_OFFER_TOKEN";

export interface OfferItem {
  badge?: Maybe<CampaignOfferBadge>;
  description?: Maybe<Scalars["String"]>;
  image: Image;
  name: Scalars["String"];
  secondaryContent?: Maybe<Scalars["String"]>;
}

export type OfferPrice = {
  __typename?: "OfferPrice";
  additionalPriceInformation?: Maybe<Scalars["String"]>;
  allyText?: Maybe<Scalars["String"]>;
  checkPriceAltText?: Maybe<Scalars["String"]>;
  checkPriceText?: Maybe<Scalars["String"]>;
  disclaimerA11yText?: Maybe<Scalars["String"]>;
  disclaimerCloseDialogText?: Maybe<Scalars["String"]>;
  disclaimerText: Scalars["String"];
  includesTaxesAndFeesText?: Maybe<Scalars["String"]>;
  prefixText?: Maybe<Scalars["String"]>;
  qualifierText: Scalars["String"];
  value: Scalars["String"];
};

export type OfferSummary = {
  __typename?: "OfferSummary";
  attributes?: Maybe<Array<OfferAttribute>>;
  /** @deprecated Use messages: List< OfferSummaryMessage> to get multiple messages. */
  message?: Maybe<Scalars["String"]>;
  messages?: Maybe<Array<OfferSummaryMessage>>;
  /** @deprecated Use messages: List< OfferSummaryMessage> to get multiple themes. */
  theme?: Maybe<Theme>;
};

export type OfferSummaryMessage = {
  __typename?: "OfferSummaryMessage";
  mark?: Maybe<Mark>;
  message?: Maybe<Scalars["String"]>;
  moreInfoDialog?: Maybe<LodgingPlainDialog>;
  strikethroughText?: Maybe<Scalars["String"]>;
  theme?: Maybe<Theme>;
  type?: Maybe<Scalars["String"]>;
};

export type OfferToken = {
  __typename?: "OfferToken";
  lineOfBusiness: LineOfBusinessDomain;
  token: Scalars["String"];
};

export type OfferTokenDetails = {
  __typename?: "OfferTokenDetails";
  checkIn: Scalars["String"];
  checkOut: Scalars["String"];
  offerToken: Scalars["String"];
  propertyId: Scalars["String"];
  ratePlanId: Scalars["String"];
  unitTypeId: Scalars["String"];
};

export interface OfferTokenInput {
  lineOfBusiness: LineOfBusinessDomain;
  token: Scalars["String"];
}

export interface OffersRecommendationAvailabilityFilterInput {
  dates?: InputMaybe<OptionalDateTimeRangeInput>;
  destination?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Scalars["String"]>;
  travelMemberGroups?: InputMaybe<Array<TravelMemberGroupInput>>;
}

export type OffersRecommendationBadges = {
  __typename?: "OffersRecommendationBadges";
  heading: Array<EgdsStandardBadge>;
  price?: Maybe<EgdsStandardBadge>;
};

export type OffersRecommendationCard = {
  __typename?: "OffersRecommendationCard";
  badges?: Maybe<OffersRecommendationBadges>;
  cardAction: UiLinkAction;
  details: Array<OffersRecommendationCardDetail>;
  heading: OffersRecommendationHeading;
  impression: ClientSideAnalytics;
  media: OffersRecommendationCardMedia;
  price: PropertyPrice;
  tripSaveItem?: Maybe<TripsSaveItem>;
};

export type OffersRecommendationCardDetail = {
  __typename?: "OffersRecommendationCardDetail";
  content: OffersRecommendationCardDetailContent;
  heading?: Maybe<Scalars["String"]>;
};

export type OffersRecommendationCardDetailContent = EgdsParagraph | EgdsTextIconList | ProductRatingSummary;

export type OffersRecommendationCardMedia = Image | RecommendationMediaItem;

export type OffersRecommendationContainer = EgdsCarousel;

export type OffersRecommendationContainerType = "CAROUSEL" | "GRID";

export type OffersRecommendationGroup = {
  __typename?: "OffersRecommendationGroup";
  cards: Array<OffersRecommendationCard>;
  container?: Maybe<OffersRecommendationContainer>;
  heading?: Maybe<OffersRecommendationHeading>;
  impression?: Maybe<ClientSideAnalytics>;
  moreOffers?: Maybe<EgdsStandardLink>;
};

export type OffersRecommendationHeading = {
  __typename?: "OffersRecommendationHeading";
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export interface OffersRecommendationProductIdentifierInput {
  lineOfBusiness: LineOfBusinessDomain;
  productId: Scalars["String"];
}

export type OffersRecommendationStrategy = "ALTERNATE" | "ATTACH" | "CO_CLICK" | "NEARBY" | "POPULAR" | "TRENDING";

export type OffersTravelerSelector = {
  __typename?: "OffersTravelerSelector";
  travelers?: Maybe<EgdsSearchFormTravelersField>;
};

export type OffersUrl = {
  __typename?: "OffersUrl";
  type: RecommendedType;
  url: Scalars["URL"];
};

export type OfflineRateDetails = {
  __typename?: "OfflineRateDetails";
  phoneNumber?: Maybe<CruisePhoneNumber>;
  text: Scalars["String"];
};

export interface OnboardingContext {
  onboardingSource: Scalars["String"];
}

export type OnboardingOperationAction = {
  __typename?: "OnboardingOperationAction";
  actionType: Scalars["String"];
};

export type OneKeyBurnSwitch = {
  __typename?: "OneKeyBurnSwitch";
  graphic?: Maybe<UiGraphic>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  switch: EgdsStandardSwitch;
};

export type OneKeyCashBurnToggleResult = {
  __typename?: "OneKeyCashBurnToggleResult";
  success: Scalars["Boolean"];
};

export type OneKeyCheckoutBurn = {
  __typename?: "OneKeyCheckoutBurn";
  burnAmount?: Maybe<Scalars["String"]>;
  element: OneKeyCheckoutBurnElementTypes;
  title: Scalars["String"];
};

export type OneKeyCheckoutBurnElementTypes = EgdsStandardMessagingCard | EgdsStandardSwitch;

export type OneKeyContextualOnboarding = {
  __typename?: "OneKeyContextualOnboarding";
  brandLogos: Array<UiGraphic>;
  headerBrandLogo?: Maybe<UiGraphic>;
  link: EgdsStandardLink;
  oneKeyCard: EgdsCard;
  primaryButton: OneKeyOnboardingButton;
  subText: Scalars["String"];
  title: Scalars["String"];
  titleImage: UiGraphic;
};

export type OneKeyDetails = {
  __typename?: "OneKeyDetails";
  analytics?: Maybe<CarAnalytics>;
  earnBanner?: Maybe<CarOfferBadge>;
  isOfferEligibleForBurn: Scalars["Boolean"];
  isOneKeyEnrolled: Scalars["Boolean"];
};

export type OneKeyInfo = {
  __typename?: "OneKeyInfo";
  isOneKeyEnrolled: Scalars["Boolean"];
};

export type OneKeyLoyaltyBanner = {
  __typename?: "OneKeyLoyaltyBanner";
  element: OneKeyLoyaltyBannerTypes;
};

export type OneKeyLoyaltyBannerTypes = EgdsStandardMessagingCard | LoyaltyMessagingCard | OneKeyBurnSwitch;

export type OneKeyOnboardingAccountMerge = {
  __typename?: "OneKeyOnboardingAccountMerge";
  headerBrandLogo?: Maybe<UiGraphic>;
  images: Array<UiGraphic>;
  link?: Maybe<EgdsStandardLink>;
  primaryButton: OneKeyOnboardingButton;
  subTitles: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type OneKeyOnboardingButton = {
  __typename?: "OneKeyOnboardingButton";
  action?: Maybe<OneKeyOnboardingButtonAction>;
  button: EgdsButton;
};

export type OneKeyOnboardingButtonAction = EgdsDismissAction | OnboardingOperationAction | UiLinkAction;

export type OneKeyOnboardingClaimIncentive = {
  __typename?: "OneKeyOnboardingClaimIncentive";
  bannerImage?: Maybe<Image>;
  brandLogos: Array<UiGraphic>;
  headerBrandLogo?: Maybe<UiGraphic>;
  link: EgdsStandardLink;
  primaryButton: OneKeyOnboardingButton;
  subText: Scalars["String"];
  title: Scalars["String"];
  titleImage: UiGraphic;
};

export type OneKeyOnboardingGettingStarted = {
  __typename?: "OneKeyOnboardingGettingStarted";
  carousel?: Maybe<EgdsCarousel>;
  consentText?: Maybe<Scalars["String"]>;
  dismissButton?: Maybe<EgdsButton>;
  headerBrandLogo?: Maybe<UiGraphic>;
  headerImage?: Maybe<Image>;
  images: Array<UiGraphic>;
  link: EgdsStandardLink;
  primaryButton: OneKeyOnboardingButton;
  subTitles: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type OneKeyOnboardingInterstitialLoading = {
  __typename?: "OneKeyOnboardingInterstitialLoading";
  brandLogos: Array<UiGraphic>;
  headerBrandLogo?: Maybe<UiGraphic>;
  onLoadOperation: OnboardingOperationAction;
  primaryButton: OneKeyOnboardingButton;
  timeoutMessage: Scalars["String"];
  titleImage: UiGraphic;
  titleMessage: Scalars["String"];
  waitingMessage: Scalars["String"];
  welcomeMessage: Scalars["String"];
};

export type OneKeyOnboardingOperationResult = {
  __typename?: "OneKeyOnboardingOperationResult";
  operationResult?: Maybe<OneKeyOperationResult>;
  operationStatus: OneKeyOnboardingOperationStatus;
};

export type OneKeyOnboardingOperationStatus = "FAILED" | "SUCCEEDED" | "TIMED_OUT";

export type OneKeyOnboardingResult =
  | OneKeyContextualOnboarding
  | OneKeyOnboardingAccountMerge
  | OneKeyOnboardingClaimIncentive
  | OneKeyOnboardingGettingStarted
  | OneKeyOnboardingInterstitialLoading
  | OneKeyOnboardingTermsAndConditions;

export type OneKeyOnboardingSheet = {
  __typename?: "OneKeyOnboardingSheet";
  element: OneKeyOnboardingResult;
};

export type OneKeyOnboardingTermsAndConditions = {
  __typename?: "OneKeyOnboardingTermsAndConditions";
  additionalText: Scalars["String"];
  dismissButton?: Maybe<EgdsButton>;
  headerBrandLogo?: Maybe<UiGraphic>;
  images: Array<UiGraphic>;
  links: Array<EgdsStandardLink>;
  onDismissOperation?: Maybe<OnboardingOperationAction>;
  primaryButton: OneKeyOnboardingButton;
  secondaryButton?: Maybe<EgdsButton>;
  subText: Scalars["String"];
  title: Scalars["String"];
};

export type OneKeyOperationResult =
  | EgdsDismissAction
  | OneKeyContextualOnboarding
  | OneKeyOnboardingInterstitialLoading
  | UiLinkAction;

export type OneKeyUniversalOnboardingBrandAssetCollection = {
  __typename?: "OneKeyUniversalOnboardingBrandAssetCollection";
  brandLogos: Array<UiGraphic>;
  titleImage: UiGraphic;
};

export type OneKeyUniversalOnboardingButton = {
  __typename?: "OneKeyUniversalOnboardingButton";
  action?: Maybe<OneKeyUniversalOnboardingButtonAction>;
  button: OneKeyUniversalOnboardingButtonElement;
};

export type OneKeyUniversalOnboardingButtonAction =
  | EgdsDismissAction
  | OneKeyUniversalOnboardingOperationAction
  | UiLinkAction;

export type OneKeyUniversalOnboardingButtonElement = EgdsOverlayButton | UiPrimaryButton;

export type OneKeyUniversalOnboardingOperationAction = {
  __typename?: "OneKeyUniversalOnboardingOperationAction";
  actionType: Scalars["String"];
};

export type OneKeyUniversalOnboardingScreen = {
  __typename?: "OneKeyUniversalOnboardingScreen";
  screen: OneKeyUniversalOnboardingScreenResult;
};

export type OneKeyUniversalOnboardingScreenResult = OneKeyUniversalOnboardingWelcomeScreen;

export type OneKeyUniversalOnboardingWelcomeScreen = {
  __typename?: "OneKeyUniversalOnboardingWelcomeScreen";
  brandAssets?: Maybe<OneKeyUniversalOnboardingBrandAssetCollection>;
  consentText?: Maybe<Scalars["String"]>;
  dismissButton?: Maybe<OneKeyUniversalOnboardingButton>;
  heroImage: Image;
  learnMoreLink: EgdsStandardLink;
  primaryButton: OneKeyUniversalOnboardingButton;
  privacyLink?: Maybe<EgdsStandardLink>;
  subTitles: Array<Scalars["String"]>;
  termsAndConditionsLink?: Maybe<EgdsStandardLink>;
  title: Scalars["String"];
};

export type OpenDurationSpanAction = {
  __typename?: "OpenDurationSpanAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  durationSpan: DurationSpan;
};

export type OpenTypeaheadAction = UiAction & {
  __typename?: "OpenTypeaheadAction";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated Use info.allowExactMatch */
  allowExactMatch: Scalars["Boolean"];
  analytics: ClientSideAnalytics;
  /** @deprecated Use info.categorize */
  categorize: Scalars["Boolean"];
  /** @deprecated Use info.typeaheadMultiSelect.clearAllText */
  clearAllText?: Maybe<Scalars["String"]>;
  /** @deprecated Use info.dismissButtonText */
  dismissButtonText?: Maybe<Scalars["String"]>;
  /** @deprecated Use info.emptyResultsPlaceholder */
  emptyResultsPlaceholder?: Maybe<Scalars["String"]>;
  info: TypeaheadInfo;
  /** @deprecated Use info.listTitle */
  listTitle: Scalars["Boolean"];
  /** @deprecated Use info.typeaheadMultiSelect.selectedItemHeading */
  selectedItemHeading?: Maybe<Scalars["String"]>;
  /** @deprecated Use info.typeaheadMultiSelect.showPills */
  showPills: Scalars["Boolean"];
};

export type Operation = "ADDITION" | "MULTIPLICATION";

export type OperationStatus = "FAILURE" | "INVALID_INPUT" | "SUCCESS";

export type OpinionLabFeedback = {
  __typename?: "OpinionLabFeedback";
  opinionSurveyTitle: Scalars["String"];
  options: Array<FeedbackOption>;
  submit: CarActionableItem;
  submitConfirmation: Scalars["String"];
};

export type OptionViewModel = {
  __typename?: "OptionViewModel";
  ariaLabel?: Maybe<Scalars["String"]>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  clickstreamEvents?: Maybe<LodgingFilterClickstreamEvents>;
  count?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  disabled: Scalars["Boolean"];
  /** @deprecated Don't get/set value from this field, replace with optionValue */
  enumValue?: Maybe<Scalars["String"]>;
  filterCategory?: Maybe<Scalars["String"]>;
  filterType?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated we are replacing icon with UIGraphic, which is a union type of Icon, Illustration and Mark */
  icon?: Maybe<Scalars["String"]>;
  isSelected: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  optionValue?: Maybe<Scalars["String"]>;
  range?: Maybe<FilterOptionRange>;
  selectedLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Don't get/set value from this field, replace with optionValue */
  value?: Maybe<Scalars["String"]>;
};

export interface OptionalContextInput {
  dropdownView?: InputMaybe<Scalars["Boolean"]>;
  isDarkMode?: InputMaybe<Scalars["Boolean"]>;
  messageCountEnabled?: InputMaybe<Scalars["Boolean"]>;
  pushEnabled?: InputMaybe<Scalars["Boolean"]>;
}

export type OptionalDateTimeRange = {
  __typename?: "OptionalDateTimeRange";
  end?: Maybe<DateTime>;
  start?: Maybe<DateTime>;
};

export interface OptionalDateTimeRangeInput {
  end?: InputMaybe<DateTimeInput>;
  start?: InputMaybe<DateTimeInput>;
}

export interface OrderInfoInput {
  orderId: Scalars["String"];
  orderLine?: InputMaybe<Scalars["String"]>;
}

export type Orientation = "LANDSCAPE" | "PORTRAIT";

export type OriginAirport = LocAirport & {
  __typename?: "OriginAirport";
  airportCode: Scalars["String"];
  airportName?: Maybe<Scalars["String"]>;
  destinationCountries: Array<DestinationCountry>;
};

export type OriginCountry = {
  __typename?: "OriginCountry";
  countryName?: Maybe<Scalars["String"]>;
  origins: Array<OriginAirport>;
};

export type PackageCard = {
  __typename?: "PackageCard";
  accessibility: Scalars["String"];
  bundleDetails: Array<PackageCardProductDetails>;
  cardAction?: Maybe<PackageUiCardAction>;
  headerSection?: Maybe<PackageUiHeaderSection>;
  identifier: Scalars["String"];
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  mediaSection: PackageCardMediaSection;
  priceSection: PackageCardPriceSection;
};

export type PackageCardFlightSection = {
  __typename?: "PackageCardFlightSection";
  productDetails: Array<PackageUiProductDetail>;
  productTitle: EgdsGraphicText;
};

export type PackageCardLodgingSection = {
  __typename?: "PackageCardLodgingSection";
  productDetails: Array<PackageUiProductDetail>;
  productTitle: EgdsGraphicText;
};

export type PackageCardMediaSection = {
  __typename?: "PackageCardMediaSection";
  badge?: Maybe<EgdsBadge>;
  gallery: MishopUiGalleryCarousel;
};

export type PackageCardPriceSection = {
  __typename?: "PackageCardPriceSection";
  badge?: Maybe<EgdsBadge>;
  priceSummary: PackageUiPrice;
};

export type PackageCardProductDetails = PackageCardFlightSection | PackageCardLodgingSection;

export type PackageDestinationQueries = {
  __typename?: "PackageDestinationQueries";
  morePackageDestinations: PackageUiMoreDestinationsResponse;
  packageDestinationSummary: PackageUiDestinationSummaryResponse;
};

export type PackageDestinationQueriesMorePackageDestinationsArgs = {
  searchCriteria: PackageSearchCriteriaInput;
};

export type PackageDetailsMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PackageDetailsMultiItemShoppingAction";
  priceToken?: Maybe<Scalars["String"]>;
  shoppingContext: ShoppingContext;
};

export type PackageDetailsPrimersError = {
  __typename?: "PackageDetailsPrimersError";
  content: PackageUiEmptyStateError;
};

export type PackageDetailsProductContentPrimer = {
  __typename?: "PackageDetailsProductContentPrimer";
  headerPrimer: PackageUiProductHeaderPrimer;
  productChangedBannerPrimer?: Maybe<PackageUiProductChangedBannerPrimer>;
  productContentPrimer: PackageUiProductContentPrimer;
};

export type PackageLocation = {
  __typename?: "PackageLocation";
  airportCode: Scalars["String"];
  coordinates?: Maybe<Coordinates>;
  regionId?: Maybe<Scalars["String"]>;
  regionName?: Maybe<Scalars["String"]>;
};

export interface PackageLocationInput {
  airportCode: Scalars["String"];
  coordinates?: InputMaybe<CoordinatesInput>;
  regionId?: InputMaybe<Scalars["String"]>;
  regionName?: InputMaybe<Scalars["String"]>;
}

export type PackageNaturalKey = {
  __typename?: "PackageNaturalKey";
  offerToken: Scalars["String"];
};

export interface PackageNaturalKeyInput {
  offerToken: Scalars["String"];
}

export type PackageOfferItem = OfferItem & {
  __typename?: "PackageOfferItem";
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  flightOfferItem?: Maybe<FlightOfferItem>;
  hotelOfferItem?: Maybe<HotelOfferItem>;
  image: Image;
  name: Scalars["String"];
  packageOffer?: Maybe<OfferData>;
  secondaryContent?: Maybe<Scalars["String"]>;
};

export type PackagePrimersQueries = {
  __typename?: "PackagePrimersQueries";
  dynamicPackageDetailsPrimers: DynamicPackageDetailsPrimers;
  prebundlePackageDetailsPrimers: PrebundlePackageDetailsPrimers;
};

export type PackagePrimersQueriesDynamicPackageDetailsPrimersArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
  recoveryNaturalKeys?: InputMaybe<MishopRecoveryNaturalKeysInput>;
};

export type PackagePrimersQueriesPrebundlePackageDetailsPrimersArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
};

export type PackageSearchCriteria = {
  __typename?: "PackageSearchCriteria";
  primary: PrimaryPackageSearchCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface PackageSearchCriteriaInput {
  primary: PrimaryPackageSearchCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type PackageSearchForm = EgdsSearchForm & {
  __typename?: "PackageSearchForm";
  actionURL: Scalars["String"];
  elements: Array<EgdsSearchFormElement>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  playback?: Maybe<EgdsSearchPlayback>;
  search?: Maybe<EgdsButton>;
  searchCriteria: PackageSearchCriteria;
};

export type PackageSearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PackageSearchMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type PackageSearchResultsErrorResponse = {
  __typename?: "PackageSearchResultsErrorResponse";
  errorAction: PackageUiButton;
  heading: Scalars["String"];
  icon: Icon;
  secondaries: Array<EgdsText>;
};

export type PackageSearchResultsListing =
  | EgdsHeading
  | EgdsStandardMessagingCard
  | PackageCard
  | PackageUiClientIllustrationCard
  | PackageUiMessagingCard;

export type PackageSearchResultsQueries = {
  __typename?: "PackageSearchResultsQueries";
  packageSearch: PackageSearchResultsResponse;
  sortAndFilter?: Maybe<ShoppingSortAndFilters>;
};

export type PackageSearchResultsQueriesPackageSearchArgs = {
  searchContext: MultiItemSearchContextInput;
};

export type PackageSearchResultsQueriesSortAndFilterArgs = {
  searchContext: MultiItemSearchContextInput;
};

export type PackageSearchResultsResponse = PackageSearchResultsErrorResponse | PackageSearchResultsSuccessResponse;

export type PackageSearchResultsSuccessResponse = {
  __typename?: "PackageSearchResultsSuccessResponse";
  adaptExEvents: Array<EgUisMicroMessage>;
  disclaimer: Scalars["String"];
  mapAction?: Maybe<PackagesOpenMapAction>;
  packageSearchResults: Array<PackageSearchResultsListing>;
  recommendedPropertiesSelectionInfo?: Maybe<EgdsStandardLink>;
  searchContext: MultiItemSearchContext;
};

export type PackageSort = "PRICE_HIGH_TO_LOW" | "PRICE_LOW_TO_HIGH" | "RECOMMENDED";

export type PackageType = "FLIGHT_CAR" | "FLIGHT_HOTEL" | "FLIGHT_HOTEL_CAR" | "HOTEL_ACTIVITY" | "HOTEL_CAR";

export type PackageUiActionDialogFooterButton = EgdsButton & {
  __typename?: "PackageUIActionDialogFooterButton";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  dialogAction: PackageUiActionDialogFooterButtonAction;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  style: EgdsButtonStyle;
};

export type PackageUiActionDialogFooterButtonAction =
  | PackageUiActionDialogFooterButtonCloseAction
  | PackageUiActionDialogSearchLinkAction;

export type PackageUiActionDialogFooterButtonCloseAction = {
  __typename?: "PackageUIActionDialogFooterButtonCloseAction";
  analytics: ClientSideAnalytics;
};

export type PackageUiActionDialogSearchLinkAction = {
  __typename?: "PackageUIActionDialogSearchLinkAction";
  analytics: ClientSideAnalytics;
  newTabAccessibilityText?: Maybe<Scalars["String"]>;
  resource: Uri;
  target: UiLinkTarget;
};

export type PackageUiActivityHeaderPrimer = {
  __typename?: "PackageUIActivityHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiActivityHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiButton = {
  __typename?: "PackageUIButton";
  action: PackageUiButtonAction;
  button: EgdsButton;
};

export type PackageUiButtonAction = PackageUiHomepageAction | PackageUiResetFiltersAction | UiLinkAction;

export type PackageUiCardAction = {
  __typename?: "PackageUICardAction";
  accessibility: Scalars["String"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
};

export type PackageUiCarouselByTheme = {
  __typename?: "PackageUICarouselByTheme";
  carousel: MishopUiCarousel;
  recommendationsAnalytics: Array<RecommendationAnalytics>;
  theme: Scalars["String"];
};

export type PackageUiChangePackageFormPrimer = {
  __typename?: "PackageUIChangePackageFormPrimer";
  accessibility: Scalars["String"];
  searchCriteria: PackageSearchCriteria;
};

export type PackageUiClientIllustrationCard = {
  __typename?: "PackageUIClientIllustrationCard";
  heading?: Maybe<Scalars["String"]>;
  illustration: ClientSideIllustrationAsset;
};

export type PackageUiDescriptionExpando = {
  __typename?: "PackageUIDescriptionExpando";
  content: Scalars["String"];
  item: EgdsExpandoPeek;
};

export type PackageUiDestination = {
  __typename?: "PackageUIDestination";
  imageAction: PackageUiDestinationAction;
  name: Scalars["String"];
};

export type PackageUiDestinationAction = {
  __typename?: "PackageUIDestinationAction";
  imageLink: EgdsImageLink;
};

export type PackageUiDestinationEditorial = {
  __typename?: "PackageUIDestinationEditorial";
  /**
   * @deprecated
   *         This is a temporary component with unformatted html content that we are using for our web launch of prebundled packages.
   *
   *         A new component is being worked on that will have structured content that we will use to replace this html content when it's available for both web and app.
   *
   *         https://jira.expedia.biz/browse/POT-19295
   *
   *         https://pages.github.expedia.biz/Expedia-UI/shared-ui/components/retail/travel-content/editorial/editorial
   *
   */
  htmlContent: Scalars["String"];
  icon: Icon;
  title: Scalars["String"];
};

export type PackageUiDestinationEditorialExpando = {
  __typename?: "PackageUIDestinationEditorialExpando";
  /**
   * @deprecated
   *         This is a temporary component with unformatted html content that we are using for our web launch of prebundled packages.
   *
   *         A new component is being worked on that will have structured content that we will use to replace this html content when it's available for both web and app.
   *
   *         https://jira.expedia.biz/browse/POT-19295
   *
   *         https://pages.github.expedia.biz/Expedia-UI/shared-ui/components/retail/travel-content/editorial/editorial
   *
   */
  htmlContent: Scalars["String"];
  item: EgdsExpandoListItem;
};

export type PackageUiDestinationSummary = {
  __typename?: "PackageUIDestinationSummary";
  accessibilityHeading?: Maybe<Scalars["String"]>;
  editorialExpandos: Array<PackageUiDestinationEditorialExpando>;
  editorials: Array<PackageUiDestinationEditorial>;
  header: PackageUiDestinationSummaryHeader;
  travelGuide?: Maybe<PackageUiTravelGuideButton>;
};

export type PackageUiDestinationSummaryHeader = {
  __typename?: "PackageUIDestinationSummaryHeader";
  image: Image;
  primary: Scalars["String"];
  secondaries: Array<Scalars["String"]>;
};

export type PackageUiDestinationSummaryResponse = PackageUiDestinationSummary;

export type PackageUiDisclaimerDialog = {
  __typename?: "PackageUIDisclaimerDialog";
  analytics: ClientSideAnalytics;
  dialog: EgdsActionDialog;
  graphic: UiGraphic;
  text: Scalars["String"];
};

export interface PackageUiEmptyStateError {
  errorAction?: Maybe<PackageUiErrorAction>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
}

export type PackageUiEntryCardAction = PackageUiButton | PackageUiFloatingButton;

export type PackageUiEntryCardSubHeading = EgdsPlainText;

export interface PackageUiErrorAction {
  analytics: ClientSideAnalytics;
  button: EgdsButton;
}

export type PackageUiExternalLinkAction = PackageUiErrorAction & {
  __typename?: "PackageUIExternalLinkAction";
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  button: EgdsButton;
  resource?: Maybe<Uri>;
};

export type PackageUiExternalLinkEmptyStateError = PackageUiEmptyStateError & {
  __typename?: "PackageUIExternalLinkEmptyStateError";
  errorAction?: Maybe<PackageUiExternalLinkAction>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
};

export type PackageUiFlightHeaderPrimer = {
  __typename?: "PackageUIFlightHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiFlightHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiFloatingButton = {
  __typename?: "PackageUIFloatingButton";
  accessibility?: Maybe<Scalars["String"]>;
  action: PackageUiButtonAction;
  primary?: Maybe<Scalars["String"]>;
};

export type PackageUiGroundTransferHeaderPrimer = {
  __typename?: "PackageUIGroundTransferHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiGroundTransferHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiHeaderSection = PackageUiMerchandisingHeaderSection;

export type PackageUiHideSearchFormAction = EgdsButton & {
  __typename?: "PackageUIHideSearchFormAction";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  style: EgdsButtonStyle;
};

export type PackageUiHomepageAction = {
  __typename?: "PackageUIHomepageAction";
  analytics: ClientSideAnalytics;
};

export type PackageUiIncludedProducts = PackageUiIncludedProductsTitles;

export type PackageUiIncludedProductsDetails = {
  __typename?: "PackageUIIncludedProductsDetails";
  includedProducts: PackageUiIncludedProducts;
  title: EgdsPlainText;
};

export type PackageUiIncludedProductsTitles = {
  __typename?: "PackageUIIncludedProductsTitles";
  titles: EgdsTextIconList;
};

export type PackageUiMerchandisingHeaderSection = {
  __typename?: "PackageUIMerchandisingHeaderSection";
  badge?: Maybe<EgdsBadge>;
  text?: Maybe<Scalars["String"]>;
};

export type PackageUiMessageItem = EgdsPlainText | EgdsStylizedText;

export type PackageUiMessagingCard = {
  __typename?: "PackageUIMessagingCard";
  action?: Maybe<PackageUiButton>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<EgdsHeading>;
  secondaries: Array<EgdsText>;
};

export type PackageUiMoreDestinations = {
  __typename?: "PackageUIMoreDestinations";
  destinations: Array<PackageUiDestination>;
  title: Scalars["String"];
};

export type PackageUiMoreDestinationsResponse = PackageUiMoreDestinations;

export type PackageUiNearbyAirports = {
  __typename?: "PackageUINearbyAirports";
  airports: Array<EgdsStandardLink>;
  primary: Scalars["String"];
};

export type PackageUiPolicies = PackageUiPoliciesSuccess;

export type PackageUiPoliciesPrimer = {
  __typename?: "PackageUIPoliciesPrimer";
  products: MultiItemProducts;
};

export type PackageUiPoliciesSuccess = {
  __typename?: "PackageUIPoliciesSuccess";
  policyContentSections: Array<PackageUiPolicyContentSection>;
  referenceId: Scalars["String"];
  title: Scalars["String"];
};

export type PackageUiPoliciesText = EgdsIconText | EgdsPlainText;

export type PackageUiPolicyContentErrorSection = PackageUiPolicyContentSection & {
  __typename?: "PackageUIPolicyContentErrorSection";
  errorMessage: EgdsStylizedText;
  sectionTitle: Scalars["String"];
};

export interface PackageUiPolicyContentSection {
  sectionTitle: Scalars["String"];
}

export type PackageUiPolicyContentSuccessSection = PackageUiPolicyContentSection & {
  __typename?: "PackageUIPolicyContentSuccessSection";
  productPolicies: Array<PackageUiProductPolicy>;
  sectionTitle: Scalars["String"];
};

export type PackageUiPolicyOverview = PackageUiPolicyContentSection & {
  __typename?: "PackageUIPolicyOverview";
  description: Scalars["String"];
  sectionTitle: Scalars["String"];
};

export type PackageUiPreBundledCard = {
  __typename?: "PackageUIPreBundledCard";
  action: PackageUiSelectPackageButton;
  analytics: ClientSideAnalytics;
  /** @deprecated Displaying only 1 image per card so deprecating this node in favor of the image node */
  gallery: MishopUiGalleryCarousel;
  image?: Maybe<Image>;
  includedProductsDetails: PackageUiIncludedProductsDetails;
  /** @deprecated Deprecating this node in favor of priceSummary node */
  price: EgdsPriceLockup;
  priceSummary: PackageUiPrice;
  theme: EgdsHeading;
  title: EgdsHeading;
};

export type PackageUiPreBundledCards = {
  __typename?: "PackageUIPreBundledCards";
  cards: Array<PackageUiPreBundledCard>;
};

export type PackageUiPreBundledPackageSearchEntryContent = EgdsBulletedList | EgdsParagraph;

export type PackageUiPrice = {
  __typename?: "PackageUIPrice";
  accessibility: Scalars["String"];
  messageItems: Array<PackageUiMessageItem>;
  priceDisplay: PackageUiPriceDisplay;
};

export type PackageUiPriceDisplay = {
  __typename?: "PackageUIPriceDisplay";
  price: Scalars["String"];
  strikethroughPrice?: Maybe<PackageUiStrikethroughPrice>;
};

export type PackageUiProductChangedBannerPrimer = {
  __typename?: "PackageUIProductChangedBannerPrimer";
  currentPackageOfferId: PackageNaturalKey;
  previousOffers: MultiItemProducts;
  previousPackageOfferId: PackageNaturalKey;
};

export interface PackageUiProductChangedBannerPrimerInput {
  currentPackageOfferId: PackageNaturalKeyInput;
  previousOffers: MultiItemProductsInput;
  previousPackageOfferId: PackageNaturalKeyInput;
}

export type PackageUiProductContentPrimer =
  | MishopUiActivitiesCrossSellPrimer
  | MishopUiCarContentPrimer
  | MishopUiFlightContentPrimer
  | MishopUiGroundTransfersCrossSellPrimer
  | MishopUiLegalContentPrimer
  | MishopUiPropertyContentPrimer
  | MishopUiSimplePropertyContentPrimer;

export type PackageUiProductDetail = EgdsPlainText | EgdsSpannableText;

export type PackageUiProductHeaderPrimer = {
  __typename?: "PackageUIProductHeaderPrimer";
  activityHeaderPrimer?: Maybe<PackageUiActivityHeaderPrimer>;
  flightHeaderPrimer?: Maybe<PackageUiFlightHeaderPrimer>;
  groundTransferHeaderPrimer?: Maybe<PackageUiGroundTransferHeaderPrimer>;
  propertyHeaderPrimer?: Maybe<PackageUiPropertyHeaderPrimer>;
};

export interface PackageUiProductHeaderPrimerInput {
  activityHeaderPrimer?: InputMaybe<PackageUiActivityHeaderPrimerInput>;
  flightHeaderPrimer?: InputMaybe<PackageUiFlightHeaderPrimerInput>;
  groundTransferHeaderPrimer?: InputMaybe<PackageUiGroundTransferHeaderPrimerInput>;
  propertyHeaderPrimer?: InputMaybe<PackageUiPropertyHeaderPrimerInput>;
}

export type PackageUiProductPolicy = {
  __typename?: "PackageUIProductPolicy";
  policyDescriptions: Array<PackageUiPoliciesText>;
  productTitle: Scalars["String"];
};

export type PackageUiPropertyHeaderPrimer = {
  __typename?: "PackageUIPropertyHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiPropertyHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiResetFiltersAction = {
  __typename?: "PackageUIResetFiltersAction";
  analytics: ClientSideAnalytics;
};

export type PackageUiSeeAllPackagesLink = PackageUiSeeAllPackagesLinkSuccess;

export type PackageUiSeeAllPackagesLinkPrimer = {
  __typename?: "PackageUISeeAllPackagesLinkPrimer";
  searchCriteria: PackageSearchCriteria;
};

export type PackageUiSeeAllPackagesLinkSuccess = {
  __typename?: "PackageUISeeAllPackagesLinkSuccess";
  seeAllPackages: EgdsStandardLink;
};

export type PackageUiSelectPackageButton = MishopUiButton & {
  __typename?: "PackageUISelectPackageButton";
  accessibility: Scalars["String"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
  recommendationsAnalytics: Array<RecommendationAnalytics>;
};

export type PackageUiStrikethroughPrice = {
  __typename?: "PackageUIStrikethroughPrice";
  disclaimer: PackageUiDisclaimerDialog;
  price: Scalars["String"];
};

export type PackageUiTravelGuideAction = {
  __typename?: "PackageUITravelGuideAction";
  regionId: Scalars["String"];
  resource: Uri;
};

export type PackageUiTravelGuideButton = EgdsButton & {
  __typename?: "PackageUITravelGuideButton";
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  buttonAction: PackageUiTravelGuideAction;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  style: EgdsButtonStyle;
};

export type PackageUiTripSaveItemPrimer = {
  __typename?: "PackageUITripSaveItemPrimer";
  encodedItemId: Scalars["String"];
};

export type PackagesMap = {
  __typename?: "PackagesMap";
  cards: Array<PackageCard>;
  closeAnalytics: ClientSideAnalytics;
  map: EgdsBasicMap;
};

export type PackagesOpenMapAction = {
  __typename?: "PackagesOpenMapAction";
  accessibility?: Maybe<Scalars["String"]>;
  map: PackagesMap;
  openAnalytics: ClientSideAnalytics;
};

export type PackagesSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "PackagesSearchForm";
    actionURL: Scalars["String"];
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    packageSelection?: Maybe<PackagesSearchFormSelection>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    valuePropMessage?: Maybe<SearchFormMessaging>;
  };

export type PackagesSearchFormSelection = {
  __typename?: "PackagesSearchFormSelection";
  car?: Maybe<EgdsBasicPill>;
  flight?: Maybe<EgdsBasicPill>;
  noSelectionMessage: UiEmptyState;
  stay?: Maybe<EgdsBasicPill>;
};

export interface PageContextInput {
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  propertySearch?: InputMaybe<PropertySearchCriteriaInput>;
}

export type PageLayoutExperimentalFeature = LodgingExperimentalFeature & {
  __typename?: "PageLayoutExperimentalFeature";
  description: Scalars["String"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
  templateName?: Maybe<Scalars["String"]>;
};

export type PageLocation =
  | "CFAR_DETAILS"
  | "CHECKOUT"
  | "CHECKOUT_ERROR"
  | "CONFIRMATION"
  | "DETAILS"
  | "ENTRY"
  | "FEATURES"
  | "INSURANCE_DETAILS"
  | "ITINERARY"
  | "PAYMENT_ERROR"
  | "POST_BOOKING"
  | "SEARCH_RESULTS"
  | "SYSTEM"
  | "USER";

export type PageType = "HOME_PAGE" | "TRAVEL_GUIDE";

export type Pagination = {
  __typename?: "Pagination";
  size?: Maybe<Scalars["Int"]>;
  startingIndex?: Maybe<Scalars["Int"]>;
};

export type PaginationDetails = {
  __typename?: "PaginationDetails";
  next?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  previous?: Maybe<Scalars["Int"]>;
};

export interface PaginationDetailsInput {
  next?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  previous?: InputMaybe<Scalars["Int"]>;
}

export interface PaginationInput {
  size?: InputMaybe<Scalars["Int"]>;
  startingIndex?: InputMaybe<Scalars["Int"]>;
}

export type PaidBagsTemplateAnalytics = TemplateAnalytics & {
  __typename?: "PaidBagsTemplateAnalytics";
  analytics: ClientSideAnalytics;
  analyticsIdentifier: AnalyticsKey;
  bagsQuantity?: Maybe<BagsSelectionType>;
};

export interface ParticipantInput {
  subscriptionId?: InputMaybe<Scalars["String"]>;
}

export interface PartnerContextInput {
  detailsSiteConfiguration?: InputMaybe<TravelStoreV1CarDetailsInput>;
  partnerAccountId: Scalars["String"];
  searchSiteConfiguration?: InputMaybe<TravelStoreV1CarSearchInput>;
}

export type PartnerLoyaltyPoints = {
  __typename?: "PartnerLoyaltyPoints";
  balance?: Maybe<EgdsStylizedText>;
  description?: Maybe<EgdsStylizedText>;
  shortDescription?: Maybe<EgdsStylizedText>;
};

export type PartnerLoyaltyPointsComponent = HeaderSharedUi & {
  __typename?: "PartnerLoyaltyPointsComponent";
  skipSsr: Scalars["Boolean"];
};

export type PartnerRecommendationCard = CarRecommendationCard & {
  __typename?: "PartnerRecommendationCard";
  action?: Maybe<CarAction>;
  analytics?: Maybe<CarAnalytics>;
  button?: Maybe<CarActionableItem>;
  dialog?: Maybe<PartnerRecommendationDialog>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  subText?: Maybe<CarPhrase>;
  title?: Maybe<CarPhrase>;
};

export type PartnerRecommendationDialog = {
  __typename?: "PartnerRecommendationDialog";
  button: CarActionableItem;
  closeDialog: CarAction;
  content: PartnerRecommendationDialogContent;
  image: Image;
  subTitle: CarPhrase;
  text: Array<Array<CarPhrase>>;
  title: CarPhrase;
};

export type PartnerRecommendationDialogContent = {
  __typename?: "PartnerRecommendationDialogContent";
  body: Array<Array<CarPhrase>>;
  confidenceMessage: Array<CarPhrase>;
  title: CarPhrase;
};

export type PartnerType = "AGENCY" | "MERCHANT" | "MERCHANT_AGENCY";

export type PassengerFareDetails = {
  __typename?: "PassengerFareDetails";
  roomPrice: Scalars["String"];
  roomType: Scalars["String"];
  serviceChargeDetail?: Maybe<ServiceChargeDetail>;
  taxesAndFees: Scalars["String"];
  taxesAndFeesLabel: Scalars["String"];
  totalChargesAccessibleText?: Maybe<Scalars["String"]>;
  travelerLabel: Scalars["String"];
  travelerPrice: Scalars["String"];
};

export type PasswordWithStrengthMeter = {
  __typename?: "PasswordWithStrengthMeter";
  hints: Array<PasswordWithStrengthMeterHint>;
  password: PasswordWithStrengthMeterInputField;
  progressBar: PasswordWithStrengthMeterProgressBar;
};

export type PasswordWithStrengthMeterCustomInputValidation = EgdsInputValidation & {
  __typename?: "PasswordWithStrengthMeterCustomInputValidation";
  errorMessage: Scalars["String"];
  pattern: Scalars["String"];
  state: Scalars["String"];
};

export type PasswordWithStrengthMeterHint = {
  __typename?: "PasswordWithStrengthMeterHint";
  state: Scalars["String"];
  text: Scalars["String"];
};

export type PasswordWithStrengthMeterInputField = {
  __typename?: "PasswordWithStrengthMeterInputField";
  analytics: Array<EgClickstreamEvent>;
  password: EgdsPasswordInputField;
};

export type PasswordWithStrengthMeterInputStrength = "STRONG" | "VERY_STRONG" | "WEAK";

export type PasswordWithStrengthMeterProgressBar = {
  __typename?: "PasswordWithStrengthMeterProgressBar";
  progressBar: EgdsProgressBar;
  progressBarCriteria: Array<PasswordWithStrengthMeterProgressBarCriteria>;
};

export type PasswordWithStrengthMeterProgressBarCriteria = {
  __typename?: "PasswordWithStrengthMeterProgressBarCriteria";
  description: Scalars["String"];
  strength: PasswordWithStrengthMeterInputStrength;
  theme: PasswordWithStrengthMeterProgressBarTheme;
};

export type PasswordWithStrengthMeterProgressBarTheme = "MUTED" | "POSITIVE";

export interface PaymentAction {
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
}

export interface PaymentActionableComponent {
  hideOnAction?: Maybe<Scalars["String"]>;
  showOnAction?: Maybe<Scalars["String"]>;
}

export type PaymentAffirmDetails = PaymentFopDetails & {
  __typename?: "PaymentAffirmDetails";
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  metadata?: Maybe<ContextualInfo>;
  priceDetails?: Maybe<PaymentPriceDetails>;
  title: Scalars["String"];
};

export type PaymentAffirmDetailsMessageCard = {
  __typename?: "PaymentAffirmDetailsMessageCard";
  content: UiMessagingCard;
};

export type PaymentAllowedBrandValidation = EgdsInputValidation & {
  __typename?: "PaymentAllowedBrandValidation";
  allowedValues: Array<Scalars["String"]>;
  errorMessage: Scalars["String"];
};

export type PaymentCard = {
  __typename?: "PaymentCard";
  card: EgdsStandardMessagingCard;
  cardActionLinks?: Maybe<Array<PaymentLink>>;
  cardContent?: Maybe<Array<PaymentText>>;
  clickAction?: Maybe<PaymentAction>;
  leftIcon?: Maybe<Icon>;
};

export type PaymentCardConfiguration = {
  __typename?: "PaymentCardConfiguration";
  maxLength: Scalars["Int"];
  pattern: Scalars["String"];
};

export type PaymentCardDetails = {
  __typename?: "PaymentCardDetails";
  brand: Scalars["String"];
  cardLogo?: Maybe<Mark>;
  cardType?: Maybe<Scalars["String"]>;
  spacingPattern: Scalars["String"];
};

export type PaymentCardDetailsBillingZipCodeField = {
  __typename?: "PaymentCardDetailsBillingZipCodeField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export type PaymentCardDetailsCvvField = PaymentDetailsEmbeddedUiElement & {
  __typename?: "PaymentCardDetailsCVVField";
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
  interactiveIcon: PaymentInteractiveIcon;
};

export type PaymentCardDetailsExpirationMonthField = {
  __typename?: "PaymentCardDetailsExpirationMonthField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsBasicSelect;
  validations: Array<EgdsInputValidation>;
};

export type PaymentCardDetailsExpirationYearField = {
  __typename?: "PaymentCardDetailsExpirationYearField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsBasicSelect;
  validations: Array<EgdsInputValidation>;
};

export type PaymentCardDetailsNameField = {
  __typename?: "PaymentCardDetailsNameField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export type PaymentCardDetailsNumberField = PaymentDetailsEmbeddedUiElement & {
  __typename?: "PaymentCardDetailsNumberField";
  brandNameValidations: Array<EgdsInputValidation>;
  cardConfig: PaymentCardConfiguration;
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
};

export type PaymentCardDetailsTokenizationField = PaymentDetailsEmbeddedUiElement & {
  __typename?: "PaymentCardDetailsTokenizationField";
  accessTokenForCardTokenization: Scalars["String"];
  cardTokenizationServiceURI: Scalars["String"];
  config: PaymentEmbeddedElementConfig;
};

export type PaymentCardExpirationDateValidation = EgdsInputValidation & {
  __typename?: "PaymentCardExpirationDateValidation";
  errorMessage: Scalars["String"];
  expirationMonthId: Scalars["String"];
  expirationYearId: Scalars["String"];
};

export type PaymentCardNumberLuhnValidation = EgdsInputValidation & {
  __typename?: "PaymentCardNumberLuhnValidation";
  errorMessage: Scalars["String"];
};

export type PaymentCheckboxField = {
  __typename?: "PaymentCheckboxField";
  content: EgdsBasicCheckBox;
};

export type PaymentCheckoutElement = {
  __typename?: "PaymentCheckoutElement";
  fopModule: Array<PaymentUiComponent>;
};

export type PaymentCheckoutIdentifier = {
  __typename?: "PaymentCheckoutIdentifier";
  sessionId?: Maybe<Scalars["String"]>;
  sessionToken?: Maybe<Scalars["String"]>;
};

export interface PaymentCheckoutIdentifierInput {
  sessionId?: InputMaybe<Scalars["String"]>;
  sessionToken?: InputMaybe<Scalars["String"]>;
}

export type PaymentClickstreamAnalyticsData = {
  __typename?: "PaymentClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]>;
  eventVersion?: Maybe<Scalars["String"]>;
  payload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type PaymentConfidenceNotes = {
  __typename?: "PaymentConfidenceNotes";
  confidenceNotes: Array<PaymentIconAndDescription>;
};

export type PaymentConfigurationInfo = {
  __typename?: "PaymentConfigurationInfo";
  entryUrn: PaymentEntryUrn;
  key: Scalars["String"];
};

export interface PaymentConfigurationItemInput {
  entryUrn: PaymentEntryUrn;
  key: Scalars["String"];
  value: Scalars["String"];
}

export interface PaymentContextualInfoInput {
  bin?: InputMaybe<Scalars["String"]>;
  etzToken?: InputMaybe<Scalars["String"]>;
  providerConfirmationStatus?: InputMaybe<Scalars["String"]>;
}

export type PaymentCreditCardDetails = PaymentFopDetails & {
  __typename?: "PaymentCreditCardDetails";
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  newCardView?: Maybe<PaymentInstrumentView>;
  priceDetails?: Maybe<PaymentPriceDetails>;
  savedCardView?: Maybe<PaymentInstrumentView>;
  title: Scalars["String"];
};

export interface PaymentDetailsEmbeddedUiElement {
  config: PaymentEmbeddedElementConfig;
}

export type PaymentDetailsUiElement =
  | PaymentAffirmDetailsMessageCard
  | PaymentCardDetailsBillingZipCodeField
  | PaymentCardDetailsCvvField
  | PaymentCardDetailsExpirationMonthField
  | PaymentCardDetailsExpirationYearField
  | PaymentCardDetailsNameField
  | PaymentCardDetailsNumberField
  | PaymentCardDetailsTokenizationField
  | PaymentCheckboxField
  | PaymentEvenColumnsField
  | PaymentInformativeInputField
  | PaymentInputCheckBoxField
  | PaymentInputSelectField
  | PaymentInputSelectFieldWithValidation
  | PaymentInputTextEmbeddedField
  | PaymentInputTextEmbeddedFieldWithConfiguration
  | PaymentInputTextField
  | PaymentInputTextFieldGc
  | PaymentInputTextFieldWithConfiguration
  | StoredInstrumentBillingZipCodeField;

export type PaymentDialog = {
  __typename?: "PaymentDialog";
  elements: Array<PaymentUiComponent>;
};

export type PaymentDialogButton = {
  __typename?: "PaymentDialogButton";
  /** @deprecated Not in use now. Will be removed in future */
  action?: Maybe<PaymentDialogButtonAction>;
  text: Scalars["String"];
  type: EgdsButtonStyle;
};

export type PaymentDialogButtonAction = UiAction & {
  __typename?: "PaymentDialogButtonAction";
  /** @deprecated Not in use now. Will be removed in future */
  accessibility?: Maybe<Scalars["String"]>;
  actionIdentifier?: Maybe<Scalars["String"]>;
  /** @deprecated Not in use now. Will be removed in future */
  analytics: ClientSideAnalytics;
};

export interface PaymentDialogContent {
  identifier: Scalars["String"];
}

export type PaymentDialogElement = UiAction & {
  __typename?: "PaymentDialogElement";
  /** @deprecated Not in use now. Will be removed in future */
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated Not in use now. Will be removed in future */
  analytics: ClientSideAnalytics;
  buttons: Array<PaymentDialogButton>;
  content: Array<PaymentDialogContent>;
  identifier: Scalars["String"];
  /** @deprecated Not in use now. Will be removed in future */
  secondaryButton?: Maybe<UiSecondaryButton>;
};

export type PaymentEmbeddedElementConfig = {
  __typename?: "PaymentEmbeddedElementConfig";
  url?: Maybe<Scalars["String"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type PaymentEntryUrn = "PAYMENT_BILLING_ZIPCODE";

export type PaymentEvenColumnsField = {
  __typename?: "PaymentEvenColumnsField";
  elements: Array<PaymentDetailsUiElement>;
};

export type PaymentFop = {
  __typename?: "PaymentFOP";
  contextualInfo: ContextualInfo;
  id: Scalars["String"];
  isDefault: Scalars["Boolean"];
  paymentAmount: PaymentPriceDetails;
  paymentOption: PaymentOptionDetails;
  state: PaymentState;
};

export type PaymentFopSelector = {
  __typename?: "PaymentFOPSelector";
  bottomPopoverTitle: Scalars["String"];
  label: Scalars["String"];
  selectOptions: Array<PaymentInstrumentContainer>;
};

export interface PaymentFopDetails {
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  priceDetails?: Maybe<PaymentPriceDetails>;
  title: Scalars["String"];
}

export type PaymentFopModuleDetail = {
  __typename?: "PaymentFopModuleDetail";
  fopSpecificDetails: Array<PaymentFopDetails>;
};

export type PaymentForm = {
  __typename?: "PaymentForm";
  elements: Array<PaymentUiComponent>;
  formId: Scalars["String"];
};

export type PaymentGiftCardApplyAction = PaymentAction & {
  __typename?: "PaymentGiftCardApplyAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentGiftCardClearAction = PaymentAction & {
  __typename?: "PaymentGiftCardClearAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentGiftCardRemoveAction = PaymentAction & {
  __typename?: "PaymentGiftCardRemoveAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHeading = {
  __typename?: "PaymentHeading";
  heading: EgdsHeading;
};

export type PaymentHideGiftCardSheetAction = PaymentAction & {
  __typename?: "PaymentHideGiftCardSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHideHelpSheetAction = PaymentAction & {
  __typename?: "PaymentHideHelpSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHideWarningSheetAction = PaymentAction & {
  __typename?: "PaymentHideWarningSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHorizontalLine = {
  __typename?: "PaymentHorizontalLine";
  visible: Scalars["Boolean"];
};

export type PaymentIcon = {
  __typename?: "PaymentIcon";
  action?: Maybe<PaymentAction>;
  icon: Icon;
};

export type PaymentIconAndDescription = {
  __typename?: "PaymentIconAndDescription";
  description: Scalars["String"];
};

export type PaymentIconDialog = PaymentInteractiveIcon & {
  __typename?: "PaymentIconDialog";
  dialog: PaymentDialogElement;
  icon: Icon;
  label?: Maybe<Scalars["String"]>;
};

export type PaymentInformativeInputField = PaymentInputFieldNamed & {
  __typename?: "PaymentInformativeInputField";
  content: EgdsTextInputField;
  interactiveIcon?: Maybe<PaymentIcon>;
  name?: Maybe<Scalars["String"]>;
};

export type PaymentInputCheckBoxField = {
  __typename?: "PaymentInputCheckBoxField";
  content: EgdsBasicCheckBox;
};

export interface PaymentInputFieldNamed {
  name?: Maybe<Scalars["String"]>;
}

export type PaymentInputSelectField = {
  __typename?: "PaymentInputSelectField";
  content: EgdsBasicSelect;
};

export type PaymentInputSelectFieldWithValidation = {
  __typename?: "PaymentInputSelectFieldWithValidation";
  content: EgdsBasicSelect;
  validations: Array<PaymentInputValidation>;
};

export type PaymentInputTextEmbeddedField = {
  __typename?: "PaymentInputTextEmbeddedField";
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
  interactiveIcon: PaymentInteractiveIcon;
};

export type PaymentInputTextEmbeddedFieldWithConfiguration = {
  __typename?: "PaymentInputTextEmbeddedFieldWithConfiguration";
  cardConfig?: Maybe<CardConfiguration>;
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
  interactiveIcon: PaymentInteractiveIcon;
};

export type PaymentInputTextField = {
  __typename?: "PaymentInputTextField";
  content: EgdsTextInputField;
};

export type PaymentInputTextFieldGc = PaymentInputFieldNamed & {
  __typename?: "PaymentInputTextFieldGC";
  content: EgdsTextInputField;
  name?: Maybe<Scalars["String"]>;
};

export type PaymentInputTextFieldWithConfiguration = {
  __typename?: "PaymentInputTextFieldWithConfiguration";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export type PaymentInputValidation = {
  __typename?: "PaymentInputValidation";
  validation: EgdsInputValidation;
};

export type PaymentInstrument = {
  __typename?: "PaymentInstrument";
  component: Scalars["String"];
  elementId?: Maybe<Scalars["String"]>;
  fopId: Scalars["String"];
  fopText: EgdsPlainText;
  isDefault: Scalars["Boolean"];
  isExpired?: Maybe<Scalars["Boolean"]>;
  isStoredCard?: Maybe<Scalars["Boolean"]>;
  logo: PaymentTypeLogo;
  paymentInstrumentType?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethodType>;
  state: PaymentState;
  storedCardConfig?: Maybe<StoredCardConfig>;
  viewType?: Maybe<Scalars["String"]>;
};

export type PaymentInstrumentContainer = {
  __typename?: "PaymentInstrumentContainer";
  instruments?: Maybe<Array<PaymentInstrument>>;
  title?: Maybe<Scalars["String"]>;
};

export type PaymentInstrumentView = {
  __typename?: "PaymentInstrumentView";
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  label?: Maybe<Scalars["String"]>;
  required: Scalars["Boolean"];
};

export interface PaymentInteractiveIcon {
  icon: Icon;
}

export type PaymentLink = {
  __typename?: "PaymentLink";
  action?: Maybe<PaymentAction>;
  text: Scalars["String"];
};

export type PaymentList = {
  __typename?: "PaymentList";
  list: EgdsBulletedList;
};

export type PaymentMessageMetaData = {
  __typename?: "PaymentMessageMetaData";
  insuranceAmount?: Maybe<Scalars["Float"]>;
};

export type PaymentMetaInfoParameters = {
  __typename?: "PaymentMetaInfoParameters";
  cardOnFile: Scalars["Boolean"];
  hasStoredCards: Scalars["Boolean"];
  insuranceDueNow: Scalars["Boolean"];
  paymentPayType: PaymentPayType;
};

export interface PaymentMethodAttributeInput {
  key: Scalars["String"];
  value: Scalars["String"];
}

export interface PaymentMethodInput {
  data?: InputMaybe<Array<PaymentMethodAttributeInput>>;
  paymentMethodType: PaymentMethodType;
}

export type PaymentMethodType = "AFFIRM" | "CREDIT_CARD" | "GIFT_CARD" | "INVALID" | "LOYALTY" | "UNRECOGNIZED";

export type PaymentModule = {
  __typename?: "PaymentModule";
  accessTokenForCardTokenization: Scalars["String"];
  checkoutIdentifier: PaymentCheckoutIdentifier;
  components: Array<PaymentUiComponent>;
  metaData: PaymentModuleMetaData;
};

export type PaymentModuleMetaData = {
  __typename?: "PaymentModuleMetaData";
  countryCode: Scalars["String"];
  locale: Scalars["String"];
  paymentParameters: PaymentMetaInfoParameters;
};

export type PaymentModuleSession = {
  __typename?: "PaymentModuleSession";
  encodedInitConfig?: Maybe<Scalars["String"]>;
  paymentSessionId?: Maybe<Scalars["String"]>;
  verificationRequired?: Maybe<Scalars["Boolean"]>;
  verificationType?: Maybe<Scalars["String"]>;
};

export type PaymentNavigateToExternalLink = PaymentAction & {
  __typename?: "PaymentNavigateToExternalLink";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  navigationLink: Scalars["String"];
};

export type PaymentNoCpmView = {
  __typename?: "PaymentNoCPMView";
  noView: Scalars["Boolean"];
};

export type PaymentNotificationContext = {
  __typename?: "PaymentNotificationContext";
  messages: Array<PaymentNotificationMessage>;
  /** @deprecated notificationModuleName will be used */
  moduleName: PaymentNotificationModuleName;
  notificationModuleName: Scalars["String"];
};

export type PaymentNotificationLocation = "IN_MODULE";

export type PaymentNotificationMessage = {
  __typename?: "PaymentNotificationMessage";
  messageIdentifier: Scalars["String"];
  /** @deprecated To be revisited later with dynamic messaging by MM */
  metaData: Array<PaymentMessageMetaData>;
  /** @deprecated messageIdentifier to be used as String */
  name: PaymentNotificationMessageName;
};

export type PaymentNotificationMessageName =
  | "CPM_CC_ON_FILE_MESSAGE"
  | "CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE"
  | "CPM_NO_PAYMENT_DUE_MESSAGE"
  | "CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE"
  | "INVALID_MESSAGE"
  | "PAY_NOW_OKC_TOTAL_AMOUNT";

export type PaymentNotificationMetadata = {
  __typename?: "PaymentNotificationMetadata";
  cardOnFile: Scalars["Boolean"];
  fullAmountCoveredByLoyalty?: Maybe<Scalars["Boolean"]>;
  insuranceDueNow: Scalars["Boolean"];
  paymentPayType: PaymentPayType;
};

export type PaymentNotificationModuleName = "COMMON_PAYMENT_MODULE";

export type PaymentOptionDetails = {
  __typename?: "PaymentOptionDetails";
  paymentMethod: PaymentMethodType;
  paymentSubMethods: Array<Scalars["String"]>;
  rank: Scalars["Int"];
  splittable: Scalars["Boolean"];
  storedPaymentInstruments?: Maybe<Array<StoredPaymentInstrumentInfo>>;
};

export type PaymentOptionsLogoList = {
  __typename?: "PaymentOptionsLogoList";
  paymentLogos: Array<PaymentTypeLogo>;
};

export type PaymentParagraph = {
  __typename?: "PaymentParagraph";
  egdsText: EgdsText;
};

export type PaymentPayType = "PAY_LATER" | "PAY_NOW";

export interface PaymentPrepareFopRequestInput {
  bookingParameters?: InputMaybe<Array<PaymentConfigurationItemInput>>;
  checkoutIdentifier?: InputMaybe<PaymentCheckoutIdentifierInput>;
  fopItems: Array<FopItemInput>;
  paymentSessionId: Scalars["String"];
  sessionId?: InputMaybe<Scalars["String"]>;
  userInfo: PaymentUserInfoInput;
}

export type PaymentPrepareFopResponse = {
  __typename?: "PaymentPrepareFOPResponse";
  checkoutIdentifier?: Maybe<PaymentCheckoutIdentifier>;
  updatedFops: Array<PaymentFop>;
};

export type PaymentPriceDetails = {
  __typename?: "PaymentPriceDetails";
  amount: Scalars["Float"];
  currencyCode?: Maybe<Scalars["String"]>;
  decimalPlaces: Scalars["Int"];
};

export type PaymentPrimaryButton = {
  __typename?: "PaymentPrimaryButton";
  action?: Maybe<PaymentAction>;
  button: UiPrimaryButton;
};

export type PaymentPublishSignalAction = PaymentAction & {
  __typename?: "PaymentPublishSignalAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  signals: Array<Scalars["String"]>;
};

export type PaymentQuery = {
  __typename?: "PaymentQuery";
  giftCard: PaymentResponse;
};

export type PaymentQueryGiftCardArgs = {
  giftCardRequest: GiftCardRequestInput;
};

export type PaymentResponse = {
  __typename?: "PaymentResponse";
  actions?: Maybe<Array<PaymentAction>>;
  elements?: Maybe<Array<PaymentUiComponent>>;
};

export interface PaymentSelectFopRequestInput {
  checkoutIdentifier: PaymentCheckoutIdentifierInput;
  fopItems: Array<FopItemInput>;
  paymentSessionId: Scalars["String"];
  sessionId?: InputMaybe<Scalars["String"]>;
}

export type PaymentSheet = PaymentActionableComponent & {
  __typename?: "PaymentSheet";
  content?: Maybe<Array<PaymentUiComponent>>;
  footer?: Maybe<Array<PaymentUiComponent>>;
  header?: Maybe<Array<PaymentUiComponent>>;
  hideOnAction?: Maybe<Scalars["String"]>;
  isVisible: Scalars["Boolean"];
  sheetId: Scalars["String"];
  showOnAction?: Maybe<Scalars["String"]>;
};

export type PaymentShowDialogAction = PaymentAction & {
  __typename?: "PaymentShowDialogAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  dialog: PaymentDialog;
};

export type PaymentShowErrorMessageAction = PaymentAction & {
  __typename?: "PaymentShowErrorMessageAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  error: MessageContent;
};

export type PaymentShowGiftCardSheetAction = PaymentAction & {
  __typename?: "PaymentShowGiftCardSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentShowHelpSheetAction = PaymentAction & {
  __typename?: "PaymentShowHelpSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentShowToastMessageAction = PaymentAction & {
  __typename?: "PaymentShowToastMessageAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  toast: EgdsToast;
};

export type PaymentShowWarningAction = PaymentAction & {
  __typename?: "PaymentShowWarningAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentShowWarningSheetAction = PaymentAction & {
  __typename?: "PaymentShowWarningSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentSource = "FTC" | "REFUND";

export type PaymentState =
  | "STATE_CANCELLED"
  | "STATE_DISABLED"
  | "STATE_INVALID"
  | "STATE_REMOVED"
  | "STATE_SELECTED"
  | "STATE_UNSELECTED"
  | "UNRECOGNIZED";

export type PaymentTertiaryButton = {
  __typename?: "PaymentTertiaryButton";
  action?: Maybe<PaymentAction>;
  button: UiTertiaryButton;
};

export type PaymentText = {
  __typename?: "PaymentText";
  egdsText: EgdsText;
};

export type PaymentTitle = {
  __typename?: "PaymentTitle";
  title: Scalars["String"];
};

export type PaymentTitledImageDialogContent = PaymentDialogContent & {
  __typename?: "PaymentTitledImageDialogContent";
  identifier: Scalars["String"];
  imageContent: Image;
  textContent: Scalars["String"];
};

export interface PaymentTokenRequestInput {
  sessionId: Scalars["String"];
  storedPaymentInstrumentId: Scalars["String"];
}

export type PaymentTokenResponse = {
  __typename?: "PaymentTokenResponse";
  storedInstrumentToken: Scalars["String"];
};

export type PaymentToolbar = {
  __typename?: "PaymentToolbar";
  leftIcon?: Maybe<PaymentIcon>;
  rightIcon?: Maybe<PaymentIcon>;
  title?: Maybe<PaymentText>;
};

export type PaymentTravelerNotification = {
  __typename?: "PaymentTravelerNotification";
  context: PaymentNotificationContext;
  /** @deprecated Will be used as string to support native, replace with PaymentNotificationLocation.IN_MODULE */
  location: PaymentNotificationLocation;
  /** @deprecated Redundant object - already present at the components level */
  metaData: PaymentNotificationMetadata;
  notificationLocation: Scalars["String"];
};

export type PaymentType =
  | "COUPON_ELIGIBLE"
  | "FREE_CANCELLATION"
  | "GIFT_CARD"
  | "PAY_LATER"
  | "PAY_LATER_WITH_DEPOSIT"
  | "PAY_WITHOUT_CREDITCARD"
  | "PAY_WITH_AFFIRM"
  | "PAY_WITH_AFTERPAY"
  | "PAY_WITH_KLARNA";

export type PaymentTypeLogo = {
  __typename?: "PaymentTypeLogo";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  size: PaymentTypeLogoSize;
};

export type PaymentTypeLogoSize = "LARGE" | "SMALL";

export type PaymentUiComponent =
  | PaymentCard
  | PaymentCheckoutElement
  | PaymentConfidenceNotes
  | PaymentDialog
  | PaymentFopSelector
  | PaymentFopModuleDetail
  | PaymentForm
  | PaymentHeading
  | PaymentHorizontalLine
  | PaymentIcon
  | PaymentInformativeInputField
  | PaymentInputCheckBoxField
  | PaymentInputSelectField
  | PaymentInputSelectFieldWithValidation
  | PaymentInputTextEmbeddedField
  | PaymentInputTextEmbeddedFieldWithConfiguration
  | PaymentInputTextField
  | PaymentInputTextFieldGc
  | PaymentInputTextFieldWithConfiguration
  | PaymentLink
  | PaymentList
  | PaymentNoCpmView
  | PaymentOptionsLogoList
  | PaymentParagraph
  | PaymentPrimaryButton
  | PaymentSheet
  | PaymentTertiaryButton
  | PaymentText
  | PaymentTitle
  | PaymentToolbar
  | PaymentTravelerNotification;

export interface PaymentUpdateFopRequestInput {
  checkoutIdentifier: PaymentCheckoutIdentifierInput;
  fopItems: Array<FopItemInput>;
  paymentSessionId: Scalars["String"];
  sessionId?: InputMaybe<Scalars["String"]>;
}

export type PaymentUpdateFopResponse = {
  __typename?: "PaymentUpdateFOPResponse";
  checkoutIdentifier?: Maybe<PaymentCheckoutIdentifier>;
  updatedFops: Array<PaymentFop>;
};

export interface PaymentUserInfoInput {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
}

export type PenaltiesAndRestrictions = {
  __typename?: "PenaltiesAndRestrictions";
  content: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type PersonalizedOffersRecommendations = {
  __typename?: "PersonalizedOffersRecommendations";
  offers: Array<OffersRecommendationGroup>;
  title?: Maybe<Scalars["String"]>;
};

export type PhoneNumberData = {
  __typename?: "PhoneNumberData";
  call?: Maybe<UiPrimaryButton>;
  colon: Scalars["String"];
  phoneNumber: Scalars["String"];
  phoneType: Scalars["String"];
  /** @deprecated replaced with colon */
  separator: Icon;
};

export type Picker = {
  __typename?: "Picker";
  bannerText?: Maybe<Scalars["String"]>;
  closeDialogText: Scalars["String"];
  /** @deprecated Replaced by urlToSubmit and queryParamsToSubmit for better granularity */
  domainUrlToSubmit?: Maybe<Scalars["URL"]>;
  heading: Scalars["String"];
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  oneKeyMessagingHeading?: Maybe<Scalars["String"]>;
  oneKeyMessagingSubHeading?: Maybe<Scalars["String"]>;
  preferences: Array<Maybe<LocalePreferenceSelector>>;
  queryParamsToSubmit?: Maybe<Array<GraphQlPair>>;
  /** @deprecated Replaced by bannerText, better named and optional */
  regionBannerText: Scalars["String"];
  saveAnalytics?: Maybe<ClientSideAnalytics>;
  submitText: Scalars["String"];
  submitTrackingEvent: Scalars["String"];
  trigger: PickerTrigger;
  urlToSubmit?: Maybe<Scalars["URL"]>;
};

export type PickerTrigger = {
  __typename?: "PickerTrigger";
  displayType: PickerTriggerDisplayType;
  flagName?: Maybe<Scalars["String"]>;
  iconName?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  trackingEvent: Scalars["String"];
};

export type PickerTriggerDisplayType = "GLOBAL_NAV_BUTTON" | "GLOBAL_NAV_LINK";

export type PickerTriggerPlacementType = "MENU_ITEM" | "NAV_LINK";

export type Pinned = {
  __typename?: "Pinned";
  tileBottom?: Maybe<Icon>;
  tileTop?: Maybe<Icon>;
};

export type PinnedPropertyModel = {
  __typename?: "PinnedPropertyModel";
  heading: Scalars["String"];
};

export type PlaceCardDetails = {
  __typename?: "PlaceCardDetails";
  closeAction?: Maybe<UiTertiaryButton>;
  description?: Maybe<EgdsText>;
  directionsPath?: Maybe<Array<DirectionsPath>>;
  footerText?: Maybe<EgdsText>;
  id: Scalars["String"];
  media?: Maybe<PlaceCardMedia>;
  subtitle?: Maybe<EgdsText>;
  title: EgdsText;
  tripsSaveItem?: Maybe<TripsSaveItem>;
};

export type PlaceCardMedia = EgdsCarousel | Image;

export type Placement = "Horizontal" | "Vertical";

export type PlacesDialog = {
  __typename?: "PlacesDialog";
  dialog?: Maybe<EgdsFullScreenDialog>;
  placesDialogEGDSTextsContentSection?: Maybe<PlacesDialogEgdsTextsContentSection>;
  placesDialogTextInputFieldContentSection?: Maybe<PlacesDialogTextInputFieldContentSection>;
  placesDialogTypeAheadContentSection?: Maybe<PlacesDialogTypeAheadContentSection>;
  placesDialogUITogglesContentSection?: Maybe<PlacesDialogUiTogglesContentSection>;
  trigger?: Maybe<EgdsOverlayButton>;
};

export type PlacesDialogEgdsTextsContentSection = {
  __typename?: "PlacesDialogEGDSTextsContentSection";
  clearSavedPlaceIcon?: Maybe<Icon>;
  content?: Maybe<Array<EgdsText>>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlacesDialogTextInputFieldContentSection = {
  __typename?: "PlacesDialogTextInputFieldContentSection";
  content?: Maybe<EgdsTextInputField>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlacesDialogTypeAheadContentSection = {
  __typename?: "PlacesDialogTypeAheadContentSection";
  content?: Maybe<EgdsTypeaheadInputField>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlacesDialogUiTogglesContentSection = {
  __typename?: "PlacesDialogUITogglesContentSection";
  content?: Maybe<Array<UiToggle>>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export interface Plottable {
  mapMarker?: Maybe<MapMarker>;
}

export type PluralCategory = "FEW" | "MANY" | "ONE" | "OTHER" | "TWO" | "ZERO";

export type PoiContent = {
  __typename?: "PoiContent";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  image?: Maybe<Image>;
};

export type PointOfInterest = Plottable &
  PropertyDetailPlottable & {
    __typename?: "PointOfInterest";
    /** @deprecated For PWA use only as a part of PDP migration. */
    id?: Maybe<Scalars["String"]>;
    mapMarker: MapMarker;
    poiContent?: Maybe<PoiContent>;
    subtitle?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
  };

export type Points = "SHOP_WITHOUT_POINTS" | "SHOP_WITH_POINTS";

export type PointsInformation = {
  __typename?: "PointsInformation";
  closeLabel: Scalars["String"];
  text: Scalars["String"];
};

export type PolicyContent = EgdsBulletedList | EgdsPlainText | PolicyHtmlContent;

export type PolicyContentSection = EgdsBulletedList | EgdsStandardLink | PolicyExpandoList;

export type PolicyDetailsSection = {
  __typename?: "PolicyDetailsSection";
  policyA11yText?: Maybe<Scalars["String"]>;
  theme: Scalars["String"];
  title: EgdsStylizedText;
  tooltipContent?: Maybe<Array<Scalars["String"]>>;
  tooltipIcon?: Maybe<Icon>;
};

export type PolicyExpandoList = {
  __typename?: "PolicyExpandoList";
  expandoSections: Array<PolicyExpandoSection>;
};

export type PolicyExpandoSection = {
  __typename?: "PolicyExpandoSection";
  content: Array<PolicyContent>;
  heading: EgdsExpando;
  urn: Scalars["String"];
};

export interface PolicyFetchParamsInput {
  cartId?: InputMaybe<Scalars["String"]>;
  offerToken?: InputMaybe<OfferTokenInput>;
  orderInfo?: InputMaybe<OrderInfoInput>;
  sessionId?: InputMaybe<Scalars["String"]>;
}

export type PolicyHtmlContent = {
  __typename?: "PolicyHTMLContent";
  htmlContent: Array<Scalars["String"]>;
};

export type PolicyHeaderSection = {
  __typename?: "PolicyHeaderSection";
  productLogoUrl?: Maybe<Scalars["String"]>;
  productName: Scalars["String"];
  productSummary?: Maybe<Array<ProductSummary>>;
};

export type PopOverContent = {
  __typename?: "PopOverContent";
  heading: Scalars["String"];
  paragraph: Scalars["String"];
};

export type PopUpMessage = {
  __typename?: "PopUpMessage";
  close?: Maybe<CloseType>;
  content?: Maybe<Array<ProductSupportingMessage>>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
  title?: Maybe<Scalars["String"]>;
};

export type PopoverSheet = {
  __typename?: "PopoverSheet";
  clickableLink?: Maybe<ActionLink>;
  icon?: Maybe<Icon>;
  popupInfo?: Maybe<PopupComponent>;
};

export type PopupComponent = {
  __typename?: "PopupComponent";
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<EgdsButton>;
  /** @deprecated Replacing with messages */
  message?: Maybe<EgdsText>;
  messages?: Maybe<Messages>;
};

export type PortableTextHtmlTableSection = {
  __typename?: "PortableTextHTMLTableSection";
  rows?: Maybe<Array<Maybe<SectionRow>>>;
};

export type PortableTextWithLink = {
  __typename?: "PortableTextWithLink";
  fileLink?: Maybe<EgdsStandardLink>;
  portableTextContent: Array<PortableTextContent>;
};

export type PosSelector = LocalePreferenceSelector & {
  __typename?: "PosSelector";
  heading: Scalars["String"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<PosSelectorOption>;
  queryParameterName: Scalars["String"];
  trackingEvent: Scalars["String"];
};

export type PosSelectorOption = LocaleSelectorOption & {
  __typename?: "PosSelectorOption";
  flag: Scalars["String"];
  name: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
};

export type PreBundledCuratedCardsEntryResponse = PreBundledCuratedCardsEntrySuccessResponse;

export type PreBundledCuratedCardsEntrySuccessResponse = {
  __typename?: "PreBundledCuratedCardsEntrySuccessResponse";
  action: PackageUiEntryCardAction;
  description: PackageUiDescriptionExpando;
  gallery: MishopUiGalleryCarousel;
  header: Scalars["String"];
  includedProductsDetails: PackageUiIncludedProductsDetails;
  theme: Scalars["String"];
};

export type PreBundledPackageEntryQueries = {
  __typename?: "PreBundledPackageEntryQueries";
  prebundledPackageEntryCuratedCard?: Maybe<PreBundledCuratedCardsEntryResponse>;
};

export type PreBundledPackageEntryQueriesPrebundledPackageEntryCuratedCardArgs = {
  templateId: Scalars["String"];
};

export type PreBundledPackageSearchCarouselsSuccessResponse = {
  __typename?: "PreBundledPackageSearchCarouselsSuccessResponse";
  accessibilityHeading?: Maybe<Scalars["String"]>;
  carousels: Array<PackageUiCarouselByTheme>;
};

export type PreBundledPackageSearchEmptyStateErrorResponse = PackageUiEmptyStateError & {
  __typename?: "PreBundledPackageSearchEmptyStateErrorResponse";
  errorAction?: Maybe<PackageUiExternalLinkAction>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
};

export type PreBundledPackageSearchEntryCardResponse = {
  __typename?: "PreBundledPackageSearchEntryCardResponse";
  action: PackageUiEntryCardAction;
  heading: Scalars["String"];
  images: Array<Image>;
  impressionAnalytics: ClientSideAnalytics;
  subHeadings: Array<PackageUiEntryCardSubHeading>;
};

export type PreBundledPackageSearchEntryResponse = {
  __typename?: "PreBundledPackageSearchEntryResponse";
  contentSections: Array<PackageUiPreBundledPackageSearchEntryContent>;
  dialog: EgdsActionDialog;
  header: Scalars["String"];
  image?: Maybe<Image>;
  impressionAnalytics: ClientSideAnalytics;
};

export type PreBundledPackageSearchResponse =
  | PreBundledPackageSearchCarouselsSuccessResponse
  | PreBundledPackageSearchEmptyStateErrorResponse
  | PreBundledPackageSearchSuccessResponse;

export type PreBundledPackageSearchSuccessResponse = {
  __typename?: "PreBundledPackageSearchSuccessResponse";
  accessibilityHeading?: Maybe<Scalars["String"]>;
  cards: Array<PackageUiPreBundledCard>;
  carousel: MishopUiCarousel;
  header: EgdsHeading;
  recommendationsAnalytics: Array<RecommendationAnalytics>;
  subHeader: EgdsPlainText;
};

export type PrebundlePackageDetailsPrimers = PackageDetailsPrimersError | PrebundlePackageDetailsPrimersSuccess;

export type PrebundlePackageDetailsPrimersSuccess = {
  __typename?: "PrebundlePackageDetailsPrimersSuccess";
  criticalModuleFailureError: PackageUiExternalLinkEmptyStateError;
  heading: EgdsHeading;
  primers: PrebundlePackageDetailsSuccessPrimers;
  state: MishopDetailsUiState;
};

export type PrebundlePackageDetailsSuccessPrimers = {
  __typename?: "PrebundlePackageDetailsSuccessPrimers";
  /** @deprecated Deprecate in favor of nullable searchWizardPrimer */
  changePackageWizardPrimer: MishopUiChangePackageFormPrimer;
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  notificationPrimer: MishopUiNotificationPrimer;
  oneKeyBannerPrimer?: Maybe<MishopUiOneKeyBannerPrimer>;
  packageSummaryContentPrimer: MishopUiPackageSummaryContentPrimer;
  policiesPrimer: PackageUiPoliciesPrimer;
  pricingDetailsPrimer: MishopUiPricingDetailsPrimer;
  productContentPrimers: Array<PackageDetailsProductContentPrimer>;
  searchWizardPrimer?: Maybe<PackageUiChangePackageFormPrimer>;
  seeAllPackagesLinkPrimer?: Maybe<PackageUiSeeAllPackagesLinkPrimer>;
};

export type PreloadedInfo = {
  __typename?: "PreloadedInfo";
  durationAndStops?: Maybe<Scalars["String"]>;
  header?: Maybe<Scalars["String"]>;
  journeyDate?: Maybe<Scalars["String"]>;
};

export type PremierHostCriteriaSnapshot = {
  __typename?: "PremierHostCriteriaSnapshot";
  datesSelect?: Maybe<EgdsSelect>;
  snapshots?: Maybe<Array<PremierHostSnapshot>>;
};

export type PremierHostDownloadCard = {
  __typename?: "PremierHostDownloadCard";
  /** @deprecated No longer used */
  action?: Maybe<UiLinkAction>;
  /** @deprecated Rather use supplyLoyalty.premierHost.download instead */
  data?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<Array<PremierHostText>>;
  type?: Maybe<SupplyPremierHostDownloadType>;
};

export type PremierHostDownloads = {
  __typename?: "PremierHostDownloads";
  downloadCards?: Maybe<Array<PremierHostDownloadCard>>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostErrorCard = {
  __typename?: "PremierHostErrorCard";
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<Array<PremierHostText>>;
};

export type PremierHostProgress = {
  __typename?: "PremierHostProgress";
  label?: Maybe<PremierHostText>;
  progressBar?: Maybe<EgdsProgressBar>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostScorecard = {
  __typename?: "PremierHostScorecard";
  criteriaSnapshot?: Maybe<PremierHostCriteriaSnapshot>;
  description?: Maybe<Array<Scalars["String"]>>;
  downloads?: Maybe<PremierHostDownloads>;
  error?: Maybe<PremierHostErrorCard>;
  progress?: Maybe<PremierHostProgress>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostSnapshot = {
  __typename?: "PremierHostSnapshot";
  cards?: Maybe<Array<PremierHostSnapshotCard>>;
  label?: Maybe<PremierHostText>;
};

export type PremierHostSnapshotCard = {
  __typename?: "PremierHostSnapshotCard";
  description?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<PremierHostText>;
  title?: Maybe<Array<PremierHostText>>;
};

export type PremierHostStatus = {
  __typename?: "PremierHostStatus";
  badge?: Maybe<EgdsBadge>;
  description?: Maybe<Array<Scalars["String"]>>;
  error?: Maybe<PremierHostErrorCard>;
  summary?: Maybe<PremierHostText>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostText = {
  __typename?: "PremierHostText";
  text?: Maybe<EgdsText>;
};

export type PrepareChangeCheckoutOption = {
  __typename?: "PrepareChangeCheckoutOption";
  type: Scalars["String"];
  value: Scalars["String"];
};

export interface PrepareChangeCheckoutOptionInput {
  type: Scalars["String"];
  value: Scalars["String"];
}

export type PrepareChangeCheckoutResponse = {
  __typename?: "PrepareChangeCheckoutResponse";
  checkoutUrl?: Maybe<Scalars["URL"]>;
  error?: Maybe<BookingServicingError>;
};

export type PrepareCheckoutResponse = {
  __typename?: "PrepareCheckoutResponse";
  checkoutErrorToken?: Maybe<Scalars["String"]>;
  checkoutSessionId?: Maybe<Scalars["String"]>;
  checkoutSessionToken?: Maybe<Scalars["String"]>;
  checkoutUrl?: Maybe<Scalars["URL"]>;
  failureReason?: Maybe<FailureReason>;
  tripId?: Maybe<Scalars["UUID"]>;
};

export type PrepareCheckoutResponseOption = "ALTERNATIVE_ACTION_ON_FAILURE";

export type PriceCheckHistory = {
  __typename?: "PriceCheckHistory";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
  heading: EgdsHeading;
  message?: Maybe<PriceMatchPromiseMessage>;
  priceMatchPromiseTabs: Array<PriceMatchPromiseTab>;
};

export type PriceCheckMutationResult = {
  __typename?: "PriceCheckMutationResult";
  message?: Maybe<Array<EgdsComposableTextElement>>;
  priceMatchPromiseDetails?: Maybe<PriceMatchPromiseDetails>;
  toast?: Maybe<PriceMatchPromiseToast>;
};

export type PriceDetails = {
  __typename?: "PriceDetails";
  analyticsData?: Maybe<Scalars["AnalyticsPayload"]>;
  pricePresentation: PricePresentation;
  /** @deprecated Used only for tracking purposes */
  trackingData?: Maybe<TrackingData>;
};

export type PriceDetailsOptions = {
  __typename?: "PriceDetailsOptions";
  metaDeeplink: Scalars["Boolean"];
  native?: Maybe<Scalars["Boolean"]>;
};

export interface PriceDetailsOptionsInput {
  metaDeeplink: Scalars["Boolean"];
  native?: InputMaybe<Scalars["Boolean"]>;
}

export type PriceDisclaimer = {
  __typename?: "PriceDisclaimer";
  content: Scalars["String"];
  priceDisclaimerTrigger: PriceDisclaimerTrigger;
  tertiaryButton: UiTertiaryButton;
};

export type PriceDisclaimerTrigger = {
  __typename?: "PriceDisclaimerTrigger";
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type PriceDisplayMessage = {
  __typename?: "PriceDisplayMessage";
  lineItems: Array<PriceDisplayMessageItem>;
};

export type PriceDisplayMessageItem = DisplayPrice | LodgingEnrichedMessage;

export type PriceDropProtectionAdditionalInfoPopover = {
  __typename?: "PriceDropProtectionAdditionalInfoPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  content: Array<Scalars["String"]>;
  heading: Scalars["String"];
  icon: Icon;
};

export type PriceDropProtectionInfoExpandoSection = {
  __typename?: "PriceDropProtectionInfoExpandoSection";
  expandedContent: PriceDropProtectionInfoSection;
  header: EgdsExpandoListItem;
};

export type PriceDropProtectionInfoSection = {
  __typename?: "PriceDropProtectionInfoSection";
  additionalInfo?: Maybe<PriceDropProtectionAdditionalInfoPopover>;
  content: Array<InsurtechTextListWrapper>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use additionalInfo which supports direct use case of new presentation */
  infoTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
};

export type PriceDropProtectionPresentation =
  | FarePriceMatchPromiseSelectableCard
  | FlightsMessagingCard
  | PriceMatchPromiseToggleableCard;

export type PriceFilterPills = {
  __typename?: "PriceFilterPills";
  aggregate?: Maybe<PriceFilterPillsAggregate>;
  buckets?: Maybe<Array<Scalars["Int"]>>;
};

export type PriceFilterPillsAggregate = {
  __typename?: "PriceFilterPillsAggregate";
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type PriceInsight = {
  __typename?: "PriceInsight";
  analytics: PriceInsightAnalytics;
  analyticsPayload: Scalars["AnalyticsPayload"];
  card?: Maybe<PriceInsightCard>;
  disclaimer?: Maybe<PriceInsightDisclaimer>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
};

export type PriceInsightAnalytics = {
  __typename?: "PriceInsightAnalytics";
  content?: Maybe<PriceInsightClickstreamContent>;
  pageLoadEvent: EgClickstreamEvent;
};

export type PriceInsightCard = {
  __typename?: "PriceInsightCard";
  expando: EgdsExpandoCard;
  rangeIndicator: RangeIndicator;
};

export type PriceInsightClickstreamContent = {
  __typename?: "PriceInsightClickstreamContent";
  currency: Scalars["String"];
  header: Scalars["String"];
  price: Scalars["String"];
  priceDiffPercentage: Scalars["String"];
};

export type PriceInsightDisclaimer = {
  __typename?: "PriceInsightDisclaimer";
  closeEvent: ClientSideAnalytics;
  description: EgdsStylizedText;
  openEvent: ClientSideAnalytics;
  title: EgdsStylizedText;
};

export type PriceInsights = {
  __typename?: "PriceInsights";
  priceInsightsCard?: Maybe<PriceInsightsCard>;
  /** @deprecated This resolver is deprecated, use priceInsightsCard resolver instead. */
  priceInsightsCardHeader?: Maybe<PriceInsightsCardHeader>;
  priceInsightsTrackingCarousel?: Maybe<PriceInsightsTrackingCarouselContainer>;
  priceInsightsTrackingLivePrice?: Maybe<Array<PriceInsightsTrackingLivePrice>>;
  priceInsightsTrackingView?: Maybe<PriceInsightsTrackingView>;
};

export type PriceInsightsPriceInsightsCardArgs = {
  deepLinkQueryParams?: InputMaybe<Array<PriceInsightsDeepLinkQueryParamInput>>;
  searchContext: PriceInsightsSearchContextInput;
};

export type PriceInsightsPriceInsightsTrackingLivePriceArgs = {
  displayedIndex?: InputMaybe<Scalars["Int"]>;
  subscriptionIds: Array<Scalars["String"]>;
};

export type PriceInsightsPriceInsightsTrackingViewArgs = {
  deepLinkQueryParams?: InputMaybe<Array<PriceInsightsDeepLinkQueryParamInput>>;
  searchContext: PriceInsightsSearchContextInput;
};

export interface PriceInsightsAnalytics {
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
}

export interface PriceInsightsButtonAction {
  /** @deprecated Use egcsAnalytics instead of analytics */
  analytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
}

export type PriceInsightsCard = PriceInsightsAnalytics & {
  __typename?: "PriceInsightsCard";
  accessibility?: Maybe<ClientAccessibility>;
  content?: Maybe<PriceInsightsCardContent>;
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  errorMessaging?: Maybe<ErrorMessagingPresentation>;
  forecast?: Maybe<PriceInsightsCardForecast>;
  header?: Maybe<PriceInsightsCardHeader>;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  priceInsightsTrackingToast?: Maybe<PriceInsightsTrackingToast>;
  /** @deprecated Taken as separate component 'PriceInsightsTrackingView' */
  priceTrackingToggle?: Maybe<PriceInsightsTrackingToggle>;
  /** @deprecated Toast has been moved in separate component 'PriceInsightsTrackingView' */
  toast?: Maybe<EgdsToast>;
  trackingDialog?: Maybe<IPriceInsightsDialog>;
};

export interface PriceInsightsCardContent {
  accessibility?: Maybe<ClientAccessibility>;
  legend: PriceInsightsGraphLegend;
}

export type PriceInsightsCardDisclaimerSheet = {
  __typename?: "PriceInsightsCardDisclaimerSheet";
  content: Array<PriceInsightsCardDisclaimerSheetSubContent>;
  sheet: EgdsSheet;
};

export type PriceInsightsCardDisclaimerSheetSubContent = {
  __typename?: "PriceInsightsCardDisclaimerSheetSubContent";
  content: EgdsStylizedText;
  heading?: Maybe<EgdsHeading>;
  /** @deprecated Use heading instead of title */
  title: EgdsHeading;
};

export type PriceInsightsCardDisclaimerToolTip = {
  __typename?: "PriceInsightsCardDisclaimerToolTip";
  accessibility?: Maybe<ClientAccessibility>;
  action: PriceInsightsCardDisclaimerToolTipAction;
  icon: Icon;
};

export type PriceInsightsCardDisclaimerToolTipAction = UiAction & {
  __typename?: "PriceInsightsCardDisclaimerToolTipAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  disclaimer: PriceInsightsCardDisclaimerSheet;
};

export type PriceInsightsCardErrorMessaging = ErrorMessagingPresentation & {
  __typename?: "PriceInsightsCardErrorMessaging";
  egcsErrorAnalytics: EgClickstreamEvent;
  errorAnalytics: ClientSideImpressionEventAnalytics;
};

export type PriceInsightsCardForecast = {
  __typename?: "PriceInsightsCardForecast";
  message: EgdsIconText;
};

export type PriceInsightsCardHeader = {
  __typename?: "PriceInsightsCardHeader";
  accessibility?: Maybe<ClientAccessibility>;
  cardIllustration?: Maybe<Illustration>;
  cardSubHeading?: Maybe<EgdsHeading>;
  /** @deprecated This field is replaced with cardSubHeading */
  cardSubTitle: EgdsHeading;
  /** @deprecated This field is replaced with cardSubTitleDescriptionText */
  cardSubTitleDescription: EgdsStylizedText;
  cardSubTitleDescriptionText?: Maybe<EgdsStylizedText>;
  cardSubTitleText?: Maybe<EgdsSpannableText>;
  cardTitle: EgdsHeading;
  cardTitleDescription?: Maybe<EgdsSpannableText>;
  disclaimerToolTip?: Maybe<PriceInsightsCardDisclaimerToolTip>;
  /** @deprecated This field is replaced with cardSubTitleDescription */
  subTitle: EgdsStylizedText;
  /** @deprecated This field is replaced with cardSubTitle */
  title: EgdsStylizedText;
};

export type PriceInsightsCardMessage = {
  __typename?: "PriceInsightsCardMessage";
  accessibility?: Maybe<ClientAccessibility>;
  message: EgdsIconText;
};

export type PriceInsightsCarouselTrackingCard = PriceInsightsAnalytics & {
  __typename?: "PriceInsightsCarouselTrackingCard";
  accessibility: ClientAccessibility;
  action?: Maybe<UiLinkAction>;
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  displayIndex?: Maybe<Scalars["Int"]>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  livePriceUnavailableAccessibility: ClientAccessibility;
  livePriceUnavailableLabel: EgdsIconText;
  loadingLivePriceAccessibility: ClientAccessibility;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  primary: Scalars["String"];
  secondaries: Array<Scalars["String"]>;
  subscriptionId: Scalars["String"];
};

export type PriceInsightsCloseDialogButtonAction = PriceInsightsButtonAction & {
  __typename?: "PriceInsightsCloseDialogButtonAction";
  /** @deprecated Use egcsAnalytics instead of analytics */
  analytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
};

export interface PriceInsightsCreateMutationDataInput {
  lowestPredictedPrice?: InputMaybe<Scalars["Float"]>;
  lowestPredictedPriceDate?: InputMaybe<Scalars["String"]>;
  priceTrend?: InputMaybe<Scalars["String"]>;
  timeSeriesTierShown: Scalars["Int"];
}

export interface PriceInsightsDeepLinkQueryParamInput {
  key: Scalars["String"];
  value: Scalars["String"];
}

export type PriceInsightsDialog = IPriceInsightsDialog & {
  __typename?: "PriceInsightsDialog";
  closeAnalytics?: Maybe<EgClickstreamEvent>;
  /** @deprecated Use egcsAnalytics instead of displayAnalytics */
  displayAnalytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  footer: PriceInsightsTrackingDialogFooter;
  heading?: Maybe<Scalars["String"]>;
  paragraph: Scalars["String"];
};

export interface PriceInsightsDisableMutationDataInput {
  lowestPredictedPrice?: InputMaybe<Scalars["Float"]>;
  lowestPredictedPriceDate?: InputMaybe<Scalars["String"]>;
  priceTrend?: InputMaybe<Scalars["String"]>;
  timeSeriesTierShown: Scalars["Int"];
}

export type PriceInsightsExistingDialogButtonAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleActionData &
  UiAction & {
    __typename?: "PriceInsightsExistingDialogButtonAction";
    accessibility?: Maybe<Scalars["String"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    egcsOnClickAnalytics?: Maybe<EgClickstreamEvent>;
    lowestPredictedPrice?: Maybe<Scalars["Float"]>;
    lowestPredictedPriceDate?: Maybe<Scalars["String"]>;
    priceShown: Scalars["String"];
    priceTrend?: Maybe<Scalars["String"]>;
    subscriptionId: Scalars["String"];
    timeSeriesTierShown: Scalars["Int"];
  };

export type PriceInsightsExistingTrackingDialogAction = EgdsDialog &
  PriceInsightsTrackingToggleAction & {
    __typename?: "PriceInsightsExistingTrackingDialogAction";
    accessibility?: Maybe<Scalars["String"]>;
    closeAnalytics?: Maybe<ClientSideAnalytics>;
    description: Scalars["String"];
    dialogFooter: EgdsInlineDialogFooter;
    displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    egcsDisplayAnalytics?: Maybe<EgClickstreamEvent>;
    title: Scalars["String"];
  };

export type PriceInsightsGraphArea = {
  __typename?: "PriceInsightsGraphArea";
  bottomLeft: PriceInsightsGraphPoint;
  bottomRight: PriceInsightsGraphPoint;
  topLeft: PriceInsightsGraphPoint;
  topRight: PriceInsightsGraphPoint;
};

export type PriceInsightsGraphBasedContent = PriceInsightsCardContent & {
  __typename?: "PriceInsightsGraphBasedContent";
  accessibility?: Maybe<ClientAccessibility>;
  data: Array<PriceInsightsGraphData>;
  legend: PriceInsightsGraphLegend;
  maxValue: Scalars["Int"];
  minValue: Scalars["Int"];
  shading?: Maybe<Array<PriceInsightsGraphShading>>;
  totalHorizontalPoints: Scalars["Int"];
};

export type PriceInsightsGraphData =
  | PriceInsightsGraphDataDottedLine
  | PriceInsightsGraphDataMarker
  | PriceInsightsGraphDataSolidLine;

export type PriceInsightsGraphDataDottedLine = {
  __typename?: "PriceInsightsGraphDataDottedLine";
  points: Array<PriceInsightsGraphPoint>;
};

export type PriceInsightsGraphDataMarker = {
  __typename?: "PriceInsightsGraphDataMarker";
  points: Array<PriceInsightsGraphPoint>;
};

export type PriceInsightsGraphDataSolidLine = {
  __typename?: "PriceInsightsGraphDataSolidLine";
  points: Array<PriceInsightsGraphPoint>;
};

export type PriceInsightsGraphLabel = {
  __typename?: "PriceInsightsGraphLabel";
  content: EgdsStylizedText;
  normalisedPosition: Scalars["Float"];
  /** @deprecated This field is deprecated and replaced with field named content. */
  text: EgdsStylizedText;
};

export type PriceInsightsGraphLegend = {
  __typename?: "PriceInsightsGraphLegend";
  averagePriceLabel?: Maybe<PriceInsightsGraphLabel>;
  horizontalLabels?: Maybe<Array<PriceInsightsGraphLabel>>;
  pinLabel?: Maybe<EgdsText>;
  verticalLabels: Array<PriceInsightsGraphLabel>;
};

export type PriceInsightsGraphPoint = {
  __typename?: "PriceInsightsGraphPoint";
  normalizedValue: Scalars["Float"];
  offsetHorizontal: Scalars["Int"];
  value: Scalars["Int"];
};

export type PriceInsightsGraphShading = PriceInsightsGraphShadingHatch | PriceInsightsGraphShadingSolid;

export type PriceInsightsGraphShadingHatch = {
  __typename?: "PriceInsightsGraphShadingHatch";
  area: Array<PriceInsightsGraphArea>;
};

export type PriceInsightsGraphShadingSolid = {
  __typename?: "PriceInsightsGraphShadingSolid";
  area: Array<PriceInsightsGraphArea>;
};

export type PriceInsightsOptOutDialogButtonAction = PriceInsightsButtonAction & {
  __typename?: "PriceInsightsOptOutDialogButtonAction";
  /** @deprecated Use egcsAnalytics instead of analytics */
  analytics?: Maybe<EgClickstreamEvent>;
  clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  operationData: PriceInsightsOptOutOperationData;
};

export type PriceInsightsOptOutOperationData = {
  __typename?: "PriceInsightsOptOutOperationData";
  notificationPreferenceType: NotificationPreferenceType;
  subscriptionId: Scalars["String"];
};

export type PriceInsightsRetryTrackingDialogButtonAction = UiAction & {
  __typename?: "PriceInsightsRetryTrackingDialogButtonAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionType: DialogButtonActionType;
  analytics: ClientSideAnalytics;
};

export interface PriceInsightsSearchContextInput {
  carSearchCriteria?: InputMaybe<CarSearchCriteriaInput>;
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
  propertySearchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
}

export type PriceInsightsSubscriptionSeenAction = {
  __typename?: "PriceInsightsSubscriptionSeenAction";
  accessibility?: Maybe<Scalars["String"]>;
  priceValue: Scalars["String"];
  subscriptionId: Scalars["String"];
};

export type PriceInsightsTextBasedContent = PriceInsightsCardContent & {
  __typename?: "PriceInsightsTextBasedContent";
  /** @deprecated Changed to optIn and optOut accessibility content */
  accessibility?: Maybe<ClientAccessibility>;
  initialCardMessage: PriceInsightsCardMessage;
  legend: PriceInsightsGraphLegend;
  /** @deprecated This will be replaced by optInMessage and optOutMessage */
  message: EgdsIconText;
  optInCardMessage: PriceInsightsCardMessage;
  /** @deprecated Changed to provide accessibility */
  optInMessage: EgdsIconText;
  optOutCardMessage: PriceInsightsCardMessage;
  /** @deprecated Changed to provide accessibility */
  optOutMessage: EgdsIconText;
};

export type PriceInsightsTrackingCarouselContainer = {
  __typename?: "PriceInsightsTrackingCarouselContainer";
  elements: Array<PriceInsightsCarouselTrackingCard>;
  heading?: Maybe<Scalars["String"]>;
};

export type PriceInsightsTrackingDialogFooter = {
  __typename?: "PriceInsightsTrackingDialogFooter";
  buttons: Array<PriceInsightsUiButton>;
};

export type PriceInsightsTrackingLivePrice = PriceInsightsAnalytics & {
  __typename?: "PriceInsightsTrackingLivePrice";
  accessibility: ClientAccessibility;
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  lastSeenPriceLabel?: Maybe<EgdsText>;
  livePriceLabel?: Maybe<EgdsText>;
  livePriceUnavailableLabel?: Maybe<EgdsIconText>;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  seenAction?: Maybe<PriceInsightsSubscriptionSeenAction>;
  subscriptionId: Scalars["String"];
};

export type PriceInsightsTrackingRetryDialogAction = EgdsDialog &
  PriceInsightsTrackingToggleAction & {
    __typename?: "PriceInsightsTrackingRetryDialogAction";
    accessibility?: Maybe<Scalars["String"]>;
    closeAnalytics?: Maybe<ClientSideAnalytics>;
    content: Scalars["String"];
    dialogFooter: EgdsInlineDialogFooter;
    displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
    heading: Scalars["String"];
    paragraph: Scalars["String"];
  };

export type PriceInsightsTrackingToast = {
  __typename?: "PriceInsightsTrackingToast";
  actionAnalytics?: Maybe<EgClickstreamAnalytics>;
  egcsAnalytics: EgClickstreamAnalytics;
  toast: EgdsToast;
};

export type PriceInsightsTrackingToggle = UiToggle & {
  __typename?: "PriceInsightsTrackingToggle";
  checked: Scalars["Boolean"];
  checkedAccessibilityDescription?: Maybe<Scalars["String"]>;
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription: Scalars["String"];
  checkedLabel: Scalars["String"];
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsCheckedAnalytics?: Maybe<EgClickstreamEvent>;
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsUnCheckedAnalytics?: Maybe<EgClickstreamEvent>;
  enabled: Scalars["Boolean"];
  label: Scalars["String"];
  toggleOffAction: PriceInsightsTrackingToggleOffAction;
  toggleOnAction: PriceInsightsTrackingToggleOnAction;
  uncheckedAccessibilityDescription?: Maybe<Scalars["String"]>;
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription: Scalars["String"];
  uncheckedLabel: Scalars["String"];
};

export interface PriceInsightsTrackingToggleAction {
  accessibility?: Maybe<Scalars["String"]>;
}

export interface PriceInsightsTrackingToggleActionData {
  lowestPredictedPrice?: Maybe<Scalars["Float"]>;
  lowestPredictedPriceDate?: Maybe<Scalars["String"]>;
  priceTrend?: Maybe<Scalars["String"]>;
  timeSeriesTierShown: Scalars["Int"];
}

export type PriceInsightsTrackingToggleOffAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleAction &
  PriceInsightsTrackingToggleActionData & {
    __typename?: "PriceInsightsTrackingToggleOffAction";
    accessibility?: Maybe<Scalars["String"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    lowestPredictedPrice?: Maybe<Scalars["Float"]>;
    lowestPredictedPriceDate?: Maybe<Scalars["String"]>;
    priceShown: Scalars["String"];
    priceTrend?: Maybe<Scalars["String"]>;
    subscriptionId: Scalars["String"];
    timeSeriesTierShown: Scalars["Int"];
  };

export type PriceInsightsTrackingToggleOnAction =
  | PriceInsightsExistingTrackingDialogAction
  | UiCreatePriceInsightsTrackingAction
  | UiValidateTravelerLoggedInAction;

export type PriceInsightsTrackingView = {
  __typename?: "PriceInsightsTrackingView";
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  priceInsightsTrackingToast?: Maybe<PriceInsightsTrackingToast>;
  priceTrackingToggle?: Maybe<PriceInsightsTrackingToggle>;
  /** @deprecated Use 'trackingDialog' instead of retryTrackingDialog */
  retryTrackingDialog?: Maybe<PriceInsightsTrackingRetryDialogAction>;
  /** @deprecated Use PriceInsightsTrackingToast instead of toast */
  toast?: Maybe<EgdsToast>;
  trackingDialog?: Maybe<PriceInsightsUiDialog>;
};

export interface PriceInsightsUiButton {
  accessibility?: Maybe<Scalars["String"]>;
  action: PriceInsightsButtonAction;
  analytics?: Maybe<EgClickstreamEvent>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
}

export type PriceInsightsUiDialog = PriceInsightsDialog | PriceInsightsTrackingRetryDialogAction;

export type PriceInsightsUiPrimaryButton = PriceInsightsUiButton & {
  __typename?: "PriceInsightsUIPrimaryButton";
  accessibility?: Maybe<Scalars["String"]>;
  action: PriceInsightsButtonAction;
  analytics?: Maybe<EgClickstreamEvent>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type PriceInsightsUiTertiaryButton = PriceInsightsUiButton & {
  __typename?: "PriceInsightsUITertiaryButton";
  accessibility?: Maybe<Scalars["String"]>;
  action: PriceInsightsButtonAction;
  analytics?: Maybe<EgClickstreamEvent>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export interface PriceInsightsUpdateMutationDataInput {
  lowestPredictedPrice?: InputMaybe<Scalars["Float"]>;
  lowestPredictedPriceDate?: InputMaybe<Scalars["String"]>;
  priceTrend?: InputMaybe<Scalars["String"]>;
  timeSeriesTierShown: Scalars["Int"];
}

export type PriceLineBadge = {
  __typename?: "PriceLineBadge";
  badge?: Maybe<EgdsStandardBadge>;
  programBadge?: Maybe<EgdsProgramBadge>;
};

export type PriceLineHeading = {
  __typename?: "PriceLineHeading";
  /** @deprecated Use `additionalInformation` instead */
  additionalInfo?: Maybe<AdditionalInformationPopover>;
  additionalInformation?: Maybe<PricePresentationAdditionalInformation>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  size: Scalars["String"];
  tag: Scalars["String"];
};

export type PriceLineText = {
  __typename?: "PriceLineText";
  /** @deprecated Use `additionalInformation` instead */
  additionalInfo?: Maybe<AdditionalInformationPopover>;
  additionalInformation?: Maybe<PricePresentationAdditionalInformation>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use `graphic` instead. */
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type PriceMatchPromiseChartType = "BAR" | "LINE";

export type PriceMatchPromiseDetails = {
  __typename?: "PriceMatchPromiseDetails";
  dialogs?: Maybe<Array<InsurtechDialog>>;
  headingCopy?: Maybe<Array<EgdsComposableTextElement>>;
  infoSection?: Maybe<PriceMatchPromiseInfo>;
  krazyGlueScriptUrl?: Maybe<Scalars["String"]>;
  manualPriceCheck?: Maybe<ManualPriceCheckSection>;
  message?: Maybe<PriceMatchPromiseMessage>;
  priceCheckHistory?: Maybe<PriceCheckHistory>;
  recap?: Maybe<PriceMatchPromiseRecap>;
  tripContextToken?: Maybe<Scalars["String"]>;
};

export type PriceMatchPromiseHistoryChart = {
  __typename?: "PriceMatchPromiseHistoryChart";
  chartType: PriceMatchPromiseChartType;
  data: Array<PriceMatchPromiseHistoryChartDataPoint>;
  horizontalLabelFormatter?: Maybe<Scalars["String"]>;
  horizontalScaleType: PriceMatchPromiseScaleType;
  verticalValueFormatter?: Maybe<Scalars["String"]>;
};

export type PriceMatchPromiseHistoryChartDataPoint = {
  __typename?: "PriceMatchPromiseHistoryChartDataPoint";
  x: Scalars["String"];
  y: Scalars["String"];
};

export type PriceMatchPromiseHistoryList = {
  __typename?: "PriceMatchPromiseHistoryList";
  highlight?: Maybe<PriceMatchPromiseHistoryListHighlight>;
  priceChecks: Array<PriceMatchPromiseHistoryListItem>;
  showLessAction?: Maybe<EgdsStandardLink>;
  showMoreAction?: Maybe<EgdsStandardLink>;
};

export type PriceMatchPromiseHistoryListHighlight = {
  __typename?: "PriceMatchPromiseHistoryListHighlight";
  heading: Scalars["String"];
  message: Scalars["String"];
};

export type PriceMatchPromiseHistoryListItem = {
  __typename?: "PriceMatchPromiseHistoryListItem";
  additionalPriceChecks?: Maybe<Array<PriceMatchPromiseHistoryListItem>>;
  additionalPriceChecksDialogTitle?: Maybe<Scalars["String"]>;
  additionalPriceChecksLink?: Maybe<EgdsStandardLink>;
  copy: Array<EgdsComposableTextElement>;
  priceDelta?: Maybe<PriceMatchPromisePriceDelta>;
};

export type PriceMatchPromiseInfo = {
  __typename?: "PriceMatchPromiseInfo";
  /** @deprecated Deprecating due to latest changes for US Content, will be using expandoSections */
  copy: Array<EgdsComposableTextElement>;
  expandoSections?: Maybe<Array<PriceDropProtectionInfoExpandoSection>>;
  /** @deprecated Deprecating due to latest changes for US Content, will be using expandoSections */
  heading: EgdsHeading;
  links?: Maybe<EgdsLinkList>;
};

export type PriceMatchPromiseLoader = {
  __typename?: "PriceMatchPromiseLoader";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
};

export type PriceMatchPromiseMessage = {
  __typename?: "PriceMatchPromiseMessage";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
  icon?: Maybe<Icon>;
};

export type PriceMatchPromisePriceDelta = {
  __typename?: "PriceMatchPromisePriceDelta";
  icon?: Maybe<Icon>;
  price: Scalars["String"];
};

export type PriceMatchPromisePriceHighlight = {
  __typename?: "PriceMatchPromisePriceHighlight";
  description?: Maybe<Scalars["String"]>;
  price: Scalars["String"];
  textTheme?: Maybe<TextTheme>;
};

export type PriceMatchPromisePriceHighlightsSection = {
  __typename?: "PriceMatchPromisePriceHighlightsSection";
  copy?: Maybe<EgdsSpannableText>;
  priceHighlights?: Maybe<Array<PriceMatchPromisePriceHighlight>>;
};

export type PriceMatchPromiseRecap = {
  __typename?: "PriceMatchPromiseRecap";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
  priceHighlightsSection?: Maybe<PriceMatchPromisePriceHighlightsSection>;
};

export type PriceMatchPromiseScaleType = "DATE" | "LABEL" | "TIME";

export type PriceMatchPromiseSelectedCardSection = {
  __typename?: "PriceMatchPromiseSelectedCardSection";
  description: Scalars["String"];
  header: Scalars["String"];
  icon: Icon;
  onDisplayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  removeActionLink?: Maybe<FlightsActionLink>;
};

export type PriceMatchPromiseSelectionCardSection = {
  __typename?: "PriceMatchPromiseSelectionCardSection";
  description: Scalars["String"];
  header: Scalars["String"];
  icon: Icon;
  infoTrigger?: Maybe<FlightsDialogTrigger>;
  onDisplayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  price?: Maybe<Scalars["String"]>;
  toggleCheckbox?: Maybe<FlightsActionCheckbox>;
};

export type PriceMatchPromiseState = "REMOVABLE" | "SELECTABLE" | "STATIC" | "TOGGLEABLE";

export type PriceMatchPromiseTab = {
  __typename?: "PriceMatchPromiseTab";
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"];
  tabData: PriceMatchPromiseTabData;
};

export type PriceMatchPromiseTabData = PriceMatchPromiseHistoryChart | PriceMatchPromiseHistoryList;

export type PriceMatchPromiseToast = {
  __typename?: "PriceMatchPromiseToast";
  message: Scalars["String"];
};

export type PriceMatchPromiseToggleableCard = FarePriceMatchPromiseCard & {
  __typename?: "PriceMatchPromiseToggleableCard";
  contentSection: ToggleableCardContentSection;
  /** @deprecated Use headingSection instead of header */
  header: EgdsGraphicText;
  /** @deprecated Use headingSection instead of heading */
  heading: EgdsIconText;
  headingSection: ToggleableCardHeadingSection;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  /** @deprecated Use subHeading from headingSection instead of subHeading */
  subHeading: Scalars["String"];
};

export type PricePresentation = {
  __typename?: "PricePresentation";
  footer?: Maybe<PricePresentationFooter>;
  /** @deprecated Use title of type PricePresentationTitle */
  header: PricePresentationHeader;
  sections: Array<PricePresentationSection>;
  title?: Maybe<PricePresentationTitle>;
};

export interface PricePresentationAdditionalInformation {
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
}

export type PricePresentationAdditionalInformationDialog = PricePresentationAdditionalInformation & {
  __typename?: "PricePresentationAdditionalInformationDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  footer?: Maybe<EgdsDialogFooter>;
  icon?: Maybe<Icon>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
};

export type PricePresentationAdditionalInformationPopover = PricePresentationAdditionalInformation & {
  __typename?: "PricePresentationAdditionalInformationPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  closeLabel?: Maybe<Scalars["String"]>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
};

export type PricePresentationDialog = LodgingDialog & {
  __typename?: "PricePresentationDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type PricePresentationFooter = {
  __typename?: "PricePresentationFooter";
  header?: Maybe<Scalars["String"]>;
  messages: Array<PricePresentationLineItemMessage>;
};

export type PricePresentationHeader = {
  __typename?: "PricePresentationHeader";
  primary: Scalars["String"];
};

export type PricePresentationLineItem = {
  __typename?: "PricePresentationLineItem";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  enrichedValue?: Maybe<PricePresentationLineItemEntry>;
  name: PricePresentationLineItemEntry;
  /** @deprecated Use enrichedValue of type PricePresentationLineItemEntry */
  value?: Maybe<PriceLineText>;
};

export type PricePresentationLineItemEntry = {
  __typename?: "PricePresentationLineItemEntry";
  primaryMessage: PricePresentationLineItemMessage;
  secondaryMessages: Array<PricePresentationLineItemMessage>;
};

export type PricePresentationLineItemMessage = InlinePriceLineText | PriceLineBadge | PriceLineHeading | PriceLineText;

export type PricePresentationSection = {
  __typename?: "PricePresentationSection";
  header?: Maybe<PricePresentationLineItem>;
  subSections: Array<PricePresentationSubSection>;
};

export type PricePresentationSubSection = {
  __typename?: "PricePresentationSubSection";
  header?: Maybe<PricePresentationLineItem>;
  items: Array<PricePresentationLineItem>;
};

export type PricePresentationTitle = {
  __typename?: "PricePresentationTitle";
  primary: Scalars["String"];
};

export type PriceRange = CriteriaRange & {
  __typename?: "PriceRange";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
};

export interface PriceRangeInput {
  max?: InputMaybe<Scalars["Float"]>;
  min?: InputMaybe<Scalars["Float"]>;
}

export type PriceRangeInputValidation = EgdsInputValidation & {
  __typename?: "PriceRangeInputValidation";
  errorMessage: Scalars["String"];
  max: Scalars["Float"];
  min: Scalars["Float"];
};

export type PriceSummary = {
  __typename?: "PriceSummary";
  disclaimers?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Use PriceSummarySection::sectionFooter instead */
  footer?: Maybe<PriceSummaryFooter>;
  /** @deprecated Use priceSummaryHeading instead */
  heading: Scalars["String"];
  priceSummaryHeading?: Maybe<LodgingEnrichedMessage>;
  sections: Array<PriceSummarySection>;
};

export type PriceSummaryFooter = {
  __typename?: "PriceSummaryFooter";
  /** @deprecated Use PriceSummary.disclaimers instead */
  disclaimerMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  footerHeading?: Maybe<LodgingEnrichedMessage>;
  footerMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Use footerHeading instead */
  heading?: Maybe<Scalars["String"]>;
  /** @deprecated Use footerMessages instead */
  messages?: Maybe<Array<Scalars["String"]>>;
};

export type PriceSummaryLineItem = {
  __typename?: "PriceSummaryLineItem";
  name: PriceSummaryLineItemEntry;
  value: PriceSummaryLineItemEntry;
};

export type PriceSummaryLineItemEntry = {
  __typename?: "PriceSummaryLineItemEntry";
  /** @deprecated Use primaryMessage instead */
  primary: Scalars["String"];
  primaryMessage: LodgingEnrichedMessage;
  /** @deprecated Use secondaryMessages instead */
  secondary?: Maybe<Array<Scalars["String"]>>;
  secondaryMessages?: Maybe<Array<LodgingEnrichedMessage>>;
};

export type PriceSummarySection = {
  __typename?: "PriceSummarySection";
  /** @deprecated Use sectionFooter instead */
  footer?: Maybe<PriceSummaryLineItem>;
  /** @deprecated Use priceSummaryHeading instead */
  heading?: Maybe<Scalars["String"]>;
  items: Array<PriceSummaryLineItem>;
  sectionFooter?: Maybe<PriceSummaryFooter>;
  sectionHeading?: Maybe<LodgingEnrichedMessage>;
};

export type PricingScheme = {
  __typename?: "PricingScheme";
  type: PricingSchemeType;
};

export type PricingSchemeType = "DAILY_RATE" | "TOTAL_RATE";

export type PricingType = "OccupancyBasedPricing" | "PerDayPricing";

export type PrimaryActivitiesCriteria = {
  __typename?: "PrimaryActivitiesCriteria";
  dateRange?: Maybe<ActivityDateRange>;
  destination?: Maybe<ActivityDestination>;
  filters?: Maybe<Array<ActivityFilter>>;
  pinnedActivityIds?: Maybe<Array<Scalars["String"]>>;
  selectedOffers?: Maybe<Array<ActivityOfferNaturalKey>>;
};

export interface PrimaryActivitySearchCriteriaInput {
  dateRange?: InputMaybe<ActivitySearchCriteriaDateRangeInput>;
  destination?: InputMaybe<ActivitySearchCriteriaLocationInput>;
}

export type PrimaryCarCriteria = {
  __typename?: "PrimaryCarCriteria";
  dropOffDateTime: DateTime;
  dropOffLocation?: Maybe<CarRentalLocation>;
  pickUpDateTime: DateTime;
  pickUpLocation: CarRentalLocation;
};

export interface PrimaryCarCriteriaInput {
  dropOffDateTime: DateTimeInput;
  dropOffLocation?: InputMaybe<CarRentalLocationInput>;
  pickUpDateTime: DateTimeInput;
  pickUpLocation: CarRentalLocationInput;
}

export interface PrimaryCruiseCriteriaInput {
  adultCount?: InputMaybe<Scalars["Int"]>;
  childCount?: InputMaybe<Scalars["Int"]>;
  children?: InputMaybe<Array<CruiseChildInput>>;
  departureDateRange?: InputMaybe<DateRangeInput>;
  destinations?: InputMaybe<Array<Scalars["String"]>>;
  durationRange?: InputMaybe<CruiseDurationRangeInput>;
}

export type PrimaryFlightCriteria = {
  __typename?: "PrimaryFlightCriteria";
  journeyCriterias: Array<FlightsJourneyCriteria>;
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelers: Array<TravelerDetails>;
  tripType: FlightsTripType;
};

export interface PrimaryFlightCriteriaInput {
  journeyCriterias: Array<FlightsJourneyCriteriaInput>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  travelers: Array<TravelerDetailsInput>;
  tripType: FlightsTripType;
}

export type PrimaryFlightCriteriaInputType = {
  __typename?: "PrimaryFlightCriteriaInputType";
  journeyCriterias: Array<FlightsJourneyCriteriaInputType>;
  searchPreferences?: Maybe<FlightsSearchPreferencesInputType>;
  travelers: Array<TravelerDetailsInputType>;
  tripType: FlightsTripType;
};

export type PrimaryGroundTransfersCriteria = {
  __typename?: "PrimaryGroundTransfersCriteria";
  /** @deprecated Use journeyCriteria instead */
  dateRange: OptionalDateTimeRange;
  /** @deprecated Use journeyCriteria instead */
  direction: GroundTransfersDirection;
  journeyCriteria?: Maybe<Array<GroundTransfersJourneyCriteria>>;
  /** @deprecated Use journeyCriteria instead */
  locations: Array<GroundTransfersLocation>;
  /** @deprecated Use pinnedOfferIds instead */
  pinnedOffers?: Maybe<Array<GroundTransfersNaturalKey>>;
  pinnedProductIds?: Maybe<Array<Scalars["String"]>>;
  selectedOffers?: Maybe<Array<GroundTransfersNaturalKey>>;
  shoppingPath?: Maybe<ShoppingPathType>;
  travelerDetails: Array<GroundTransfersTravelerDetails>;
};

export interface PrimaryGroundTransfersCriteriaInput {
  dateRange: OptionalDateTimeRangeInput;
  direction: GroundTransfersDirection;
  journeyCriteria?: InputMaybe<Array<GroundTransfersJourneyCriteriaInput>>;
  locations: Array<GroundTransfersLocationInput>;
  pinnedOffers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  pinnedProductIds?: InputMaybe<Array<Scalars["String"]>>;
  selectedOffers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
  travelerDetails: Array<GroundTransfersTravelerDetailsInput>;
}

export type PrimaryInfo = {
  __typename?: "PrimaryInfo";
  heading: EgdsStylizedText;
  subheading: EgdsStylizedText;
};

export type PrimaryPackageSearchCriteria = {
  __typename?: "PrimaryPackageSearchCriteria";
  cabinClass?: Maybe<FlightsCabinClass>;
  dateRange?: Maybe<DateRange>;
  destination?: Maybe<PackageLocation>;
  origin?: Maybe<PackageLocation>;
  travelerOccupancyGroups?: Maybe<Array<TravelerOccupancyGroup>>;
};

export interface PrimaryPackageSearchCriteriaInput {
  cabinClass?: InputMaybe<FlightsCabinClass>;
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<PackageLocationInput>;
  origin?: InputMaybe<PackageLocationInput>;
  travelerOccupancyGroups?: InputMaybe<Array<TravelerOccupancyGroupInput>>;
}

export interface PrimaryProductShoppingCriteriaInput {
  cruise?: InputMaybe<PrimaryCruiseCriteriaInput>;
  groundTransportation?: InputMaybe<PrimaryGroundTransfersCriteriaInput>;
  property?: InputMaybe<PrimaryPropertyCriteriaInput>;
}

export type PrimaryPropertyCriteria = {
  __typename?: "PrimaryPropertyCriteria";
  dateRange?: Maybe<PropertyDateRange>;
  destination: Destination;
  flexSearchCriteria?: Maybe<FlexibleSearchCriteria>;
  rooms: Array<Room>;
};

export interface PrimaryPropertyCriteriaInput {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  destination: DestinationInput;
  flexSearchCriteria?: InputMaybe<FlexibleSearchCriteriaInput>;
  rooms: Array<RoomInput>;
}

export type PrivacySellState = "CAN_NOT_SELL" | "CAN_SELL";

export type PrivacyTrackingState = "CAN_NOT_TRACK" | "CAN_TRACK";

export type ProductAmenitiesSection = ProductDetailsElement & {
  __typename?: "ProductAmenitiesSection";
  /** @deprecated Replaced with `list`. Will implement EGDSTextList to support broader use case, replace with list */
  amenities?: Maybe<EgdsTextIconList>;
  elementID: Scalars["String"];
  list?: Maybe<EgdsTextList>;
  title?: Maybe<EgdsStylizedText>;
};

export type ProductAttributesSection = ProductDetailsElement & {
  __typename?: "ProductAttributesSection";
  attributes: EgdsTextIconList;
  elementID: Scalars["String"];
};

export type ProductBenefitsSection = ProductDetailsElement & {
  __typename?: "ProductBenefitsSection";
  benefits: Array<EgdsStylizedText>;
  elementID: Scalars["String"];
};

export type ProductCarouselContainer = LodgingCarouselContainer;

export type ProductCarouselResults = {
  __typename?: "ProductCarouselResults";
  carousels: Array<ProductCarouselContainer>;
};

export type ProductCategorizedImages = {
  __typename?: "ProductCategorizedImages";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  images: Array<ProductImageInfo>;
};

export interface ProductDetailsElement {
  elementID: Scalars["String"];
}

export type ProductDetailsHeader = {
  __typename?: "ProductDetailsHeader";
  description?: Maybe<Scalars["String"]>;
  headerImage?: Maybe<ProductImage>;
  heading?: Maybe<EgdsHeading>;
  /** @deprecated Deprecated for type 'ProductImage' to support width, replace with headerImage */
  image?: Maybe<Image>;
  /** @deprecated Deprecated for type 'ProductRatingSummary', replace with ratingSummary */
  rating?: Maybe<EgdsProductRatingSummary>;
  ratingSummary?: Maybe<ProductRatingSummary>;
};

export type ProductDetailsLayout = {
  __typename?: "ProductDetailsLayout";
  body?: Maybe<Array<ProductDetailsElement>>;
  elements: Array<Maybe<ProductDetailsElement>>;
  footer: Array<Maybe<ProductDetailsElement>>;
  header: ProductDetailsHeader;
};

export type ProductDialogToolbar = {
  __typename?: "ProductDialogToolbar";
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]>;
};

export type ProductDialogTriggerMessage = {
  __typename?: "ProductDialogTriggerMessage";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  dialogName?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  value: Scalars["String"];
};

export type ProductGalleryDialog = {
  __typename?: "ProductGalleryDialog";
  toolbar?: Maybe<ProductDialogToolbar>;
  trigger?: Maybe<ProductDialogTriggerMessage>;
};

export interface ProductGroupContextInput {
  affinityURNs?: InputMaybe<Array<Scalars["String"]>>;
  configurationIdentifier?: InputMaybe<Scalars["String"]>;
  destinationId?: InputMaybe<Scalars["String"]>;
  lineOfBusinessDomain?: InputMaybe<LineOfBusinessDomain>;
  marketingChannelTypeCode?: InputMaybe<Scalars["Int"]>;
  userLocation?: InputMaybe<CoordinatesInput>;
}

export type ProductGroupsContainer = ProductListComponent | TopTenProductsCarouselComponent;

export type ProductHeadline = {
  __typename?: "ProductHeadline";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  featuredMessages: Array<ProductSupportingMessage>;
  primary: Scalars["String"];
  rating?: Maybe<EgdsIconRating>;
  supportingMessages: Array<ProductSupportingMessage>;
};

export type ProductHighlights = ProductUniqueness;

export interface ProductIdentifierInput {
  id: Scalars["String"];
  searchCriteriaSelections?: InputMaybe<Array<SearchCriteriaSelectionsInput>>;
  travelSearchCriteria?: InputMaybe<TravelSearchCriteriaInput>;
  type: IdentifierType;
}

export type ProductImage = {
  __typename?: "ProductImage";
  /** @deprecated Deprecated for 'description' */
  altText?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  aspectRatio?: Maybe<AspectRatio>;
  description?: Maybe<Scalars["String"]>;
  fitment: ProductImageFitment;
  url?: Maybe<Scalars["URL"]>;
  width?: Maybe<ProductImageWidth>;
};

export type ProductImageFitment = "CONTAIN" | "COVER";

export type ProductImageGallery = {
  __typename?: "ProductImageGallery";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  categorizedImages: Array<ProductCategorizedImages>;
  categoryIdAll: Scalars["String"];
  featured?: Maybe<ProductImageInfo>;
  galleryButtons?: Maybe<Array<EgdsOverlayButton>>;
  mediaGalleryDialog?: Maybe<ProductGalleryDialog>;
  thumbnailGalleryDialog?: Maybe<ProductGalleryDialog>;
};

export type ProductImageInfo = {
  __typename?: "ProductImageInfo";
  actionAccessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ImageAnalytics>;
  description?: Maybe<Scalars["String"]>;
  image: ProductImage;
  lowResImage?: Maybe<ProductImage>;
  thumbnail?: Maybe<ProductImage>;
};

export type ProductImageWidth = "FULL_WIDTH" | "HALF_WIDTH" | "QUARTER_WIDTH" | "THIRD_WIDTH";

export interface ProductInfo {
  type?: Maybe<ProductType>;
}

export type ProductLine =
  | "ACTIVITY"
  | "CAR"
  | "CRUISE"
  | "FLIGHT"
  | "GROUND_TRANSFER"
  | "INSURANCE"
  | "LODGING"
  | "PACKAGE"
  | "PRODUCT_LINE_INVALID"
  | "RAIL"
  | "UNRECOGNIZED";

export type ProductListComponent = {
  __typename?: "ProductListComponent";
  cards: Array<ActivityCard>;
  heading?: Maybe<Scalars["String"]>;
  loadMoreAction?: Maybe<ActivityClickAction>;
  loadMoreButton?: Maybe<EgdsButton>;
};

export type ProductListContentEventAttributes = {
  __typename?: "ProductListContentEventAttributes";
  productId: Scalars["String"];
  productLine: Scalars["String"];
};

export type ProductNaturalKey =
  | ActivityNaturalKey
  | CarNaturalKey
  | FlightNaturalKey
  | GroundTransfersNaturalKey
  | PackageNaturalKey
  | PropertyNaturalKey;

export type ProductNavigationBar = {
  __typename?: "ProductNavigationBar";
  tabs: Array<ProductNavigationBarTab>;
};

export type ProductNavigationBarTab = {
  __typename?: "ProductNavigationBarTab";
  sectionName: Scalars["String"];
  text: Scalars["String"];
};

export type ProductPoliciesSection = ProductDetailsElement & {
  __typename?: "ProductPoliciesSection";
  details?: Maybe<ProductTextInfoSection>;
  elementID: Scalars["String"];
  policyDescription?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated to support icon and tooltip too, replace with details */
  policyDetails: PolicyDetailsSection;
};

export type ProductPolicy = {
  __typename?: "ProductPolicy";
  policyContentSection: PolicyContentSection;
  policyHeaderSection: PolicyHeaderSection;
};

export interface ProductQuestionFiltersInput {
  searchTerm?: InputMaybe<Scalars["String"]>;
}

export type ProductQuestionTopic = "HOTEL";

export type ProductQuestions = {
  __typename?: "ProductQuestions";
  questions: Array<UserQuestion>;
  totalCount?: Maybe<FormattedNumber>;
};

export type ProductRating = {
  __typename?: "ProductRating";
  info?: Maybe<ProductTextInfoSection>;
  metadata: ProductRatingMetadata;
  sectionHeadingAccessibilityText?: Maybe<Scalars["String"]>;
  summary: ProductRatingSummary;
};

export type ProductRatingLink = {
  __typename?: "ProductRatingLink";
  action: ProductRatingShowTextAction;
  link: EgdsStandardLink;
};

export type ProductRatingMetadata = {
  __typename?: "ProductRatingMetadata";
  seoData: Array<SeoStructuredData>;
};

export type ProductRatingShowTextAction = {
  __typename?: "ProductRatingShowTextAction";
  text: Scalars["String"];
};

export type ProductRatingSummary = {
  __typename?: "ProductRatingSummary";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
  supportingMessages: Array<ProductRatingSupportingMessage>;
  theme?: Maybe<Scalars["String"]>;
};

export type ProductRatingSupportingMessage = EgdsPlainText | ProductRatingLink;

export type ProductResultsSummaryContent = ProductResultsSummaryTextContent;

export type ProductResultsSummaryContentContainer = {
  __typename?: "ProductResultsSummaryContentContainer";
  content: ProductResultsSummaryContent;
  contentId: Scalars["String"];
};

export type ProductResultsSummaryTextContent = {
  __typename?: "ProductResultsSummaryTextContent";
  textList?: Maybe<Array<Scalars["String"]>>;
  title?: Maybe<Scalars["String"]>;
};

export interface ProductShoppingCriteriaInput {
  primarySearchCriteria: PrimaryProductShoppingCriteriaInput;
  secondaryCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type ProductSummary = EgdsInlineLink | EgdsPlainText;

export interface ProductSummaryInput {
  id: Scalars["String"];
  idType: IdentifierType;
  selections?: InputMaybe<Array<SelectedValueInput>>;
}

export type ProductSupplierSection = ProductDetailsElement & {
  __typename?: "ProductSupplierSection";
  description?: Maybe<Scalars["String"]>;
  elementID: Scalars["String"];
  logo: Image;
};

export type ProductSupportingMessage = EgdsGraphicText | EgdsPlainText | EgdsStandardBadge;

export type ProductTextInfoSection = {
  __typename?: "ProductTextInfoSection";
  accessibilityText?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  message?: Maybe<PopUpMessage>;
  /** @deprecated Deprecated for a more generic type, replace with accessibilityText */
  policyA11yText?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated for a shared type, replace with theme */
  policyTheme: Scalars["String"];
  theme: IconTheme;
  title: EgdsStylizedText;
  /** @deprecated Deprecated for a more generic type, replace with message */
  tooltipContent?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated Deprecated for a more generic type, replace with icon */
  tooltipIcon?: Maybe<Icon>;
};

export type ProductTravelScheduleSection = ProductDetailsElement & {
  __typename?: "ProductTravelScheduleSection";
  bulletins?: Maybe<Array<Maybe<ProductTextInfoSection>>>;
  elementID: Scalars["String"];
  label: EgdsStylizedText;
  /** @deprecated Deprecated to support icon and tooltip too, replace with bulletins */
  messages: Array<EgdsStylizedText>;
};

export type ProductType =
  | "ARTICLE"
  | "CAR_DETAILS"
  | "DESTINATION"
  | "DESTINATION_EXPERIENCE"
  | "EXPERIENCE"
  | "EXPERT_TIP"
  | "FLIGHT_SEARCH"
  | "LX_DETAILS"
  | "NEIGHBORHOOD"
  | "POI_GAIA"
  | "PROPERTY_DETAILS";

export type ProductUniqueness = {
  __typename?: "ProductUniqueness";
  details?: Maybe<EgdsSpannableList>;
  impression: ClientSideImpressionEventAnalytics;
  title?: Maybe<EgdsGraphicText>;
};

export type Profile = {
  __typename?: "Profile";
  emailAddress: Scalars["String"];
  expUserId: Scalars["Int"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName: Scalars["String"];
  tuid: Scalars["Int"];
};

export type ProfileAccountFormBottomSheetComponent = {
  __typename?: "ProfileAccountFormBottomSheetComponent";
  bottomSheetElements: Array<ProfileBottomSheetElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ProfileAccountFormCenteredElement = UiEmptyState;

export type ProfileAccountFormCenteredVerticalComponent = {
  __typename?: "ProfileAccountFormCenteredVerticalComponent";
  centeredElements: Array<ProfileAccountFormCenteredElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ProfileAccountFormComponent =
  | ProfileAccountFormBottomSheetComponent
  | ProfileAccountFormCenteredVerticalComponent
  | ProfileAccountFormVerticalComponent
  | ProfileActionDialogComponent
  | ProfilePopUiToRoot
  | ProfilePresentUi
  | ProfilePushUi;

export type ProfileAccountFormContent =
  | ProfileAccountFormBottomSheetComponent
  | ProfileAccountFormCenteredVerticalComponent
  | ProfileAccountFormVerticalComponent
  | ProfileActionDialogComponent;

export type ProfileAccountFormElement =
  | EgdsBasicSelect
  | EgdsCheckBoxGroup
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsParagraph
  | EgdsTypeaheadInputField
  | ProfileAccountFormSectionContainer
  | ProfileEgdsSectionContainer
  | ProfileFormSection
  | ProfileSlimCard
  | UiEmptyState
  | UiMessagingCard
  | UiPrimaryButton;

export type ProfileAccountFormSectionContainer = {
  __typename?: "ProfileAccountFormSectionContainer";
  heading?: Maybe<Scalars["String"]>;
  sectionContainerElements: Array<ProfileAccountFormSectionElement>;
};

export type ProfileAccountFormSectionElement = Illustration | UiPrimaryButton | UiTertiaryButton;

export type ProfileAccountFormSubmitAction = ProfileAction &
  UiAction & {
    __typename?: "ProfileAccountFormSubmitAction";
    accessibility?: Maybe<Scalars["String"]>;
    actionContext: Scalars["String"];
    analytics: ClientSideAnalytics;
    inputIds: Array<Scalars["String"]>;
  };

export type ProfileAccountFormVerticalComponent = {
  __typename?: "ProfileAccountFormVerticalComponent";
  /** @deprecated Use anchoredButtons instead since that supports multiple buttons */
  anchoredButton?: Maybe<ProfileButton>;
  anchoredButtons?: Maybe<Array<ProfileButton>>;
  anchoredButtonsLayout?: Maybe<ProfileButtonLayout>;
  elements: Array<ProfileAccountFormElement>;
  /** @deprecated Use anchoredButton profile component instead */
  floatingButton?: Maybe<UiPrimaryButton>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  toast?: Maybe<EgdsToast>;
};

export type ProfileAccountLink = EgdsElement & {
  __typename?: "ProfileAccountLink";
  action: ProfileAction;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export interface ProfileAction {
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
}

export type ProfileActionButtonCard = EgdsElement & {
  __typename?: "ProfileActionButtonCard";
  cardAction?: Maybe<ProfileAction>;
  egdsElementId?: Maybe<Scalars["String"]>;
  impressionTracking?: Maybe<ClientSideImpressionEventAnalytics>;
  leftIcon?: Maybe<Icon>;
  primaries?: Maybe<Array<EgdsStylizedText>>;
  rightButton?: Maybe<ProfileButton>;
  secondaries?: Maybe<Array<EgdsStylizedText>>;
  tertiaries?: Maybe<Array<EgdsStylizedText>>;
};

export type ProfileActionDialogComponent = {
  __typename?: "ProfileActionDialogComponent";
  actionButtonLayout: ProfileButtonLayout;
  actionButtons: Array<ProfileButton>;
  content?: Maybe<Array<EgdsText>>;
  disableBackground: Scalars["Boolean"];
  heading?: Maybe<Scalars["String"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ProfileAddMembershipAction = UiAction & {
  __typename?: "ProfileAddMembershipAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileAddPassportAction = UiAction & {
  __typename?: "ProfileAddPassportAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileBottomSheetElement =
  | EgdsBasicSelect
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsTextInputField
  | UiPrimaryButton
  | UiSecondaryButton;

export type ProfileBulletedList = EgdsElement & {
  __typename?: "ProfileBulletedList";
  egdsElementId?: Maybe<Scalars["String"]>;
  listItems: Array<EgdsText>;
};

export type ProfileButton = {
  __typename?: "ProfileButton";
  action: ProfileAction;
  button: EgdsButton;
};

export type ProfileButtonLayout = "INLINE" | "STACKED";

export type ProfileCarouselContainer = ProfileContainer & {
  __typename?: "ProfileCarouselContainer";
  elements: Array<ProfileComposableElement>;
  scrollToSelectedItem: Scalars["Int"];
};

export type ProfileComponent = {
  __typename?: "ProfileComponent";
  elements: Array<EgdsElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  toast?: Maybe<EgdsToast>;
};

export type ProfileComposableElement =
  | ProfileCarouselContainer
  | ProfileContentCard
  | ProfileFlexContainer
  | ProfileFullBleedImageCard
  | ProfileSectionContainer
  | ProfileSlimCard
  | ProfileUiPill;

export interface ProfileContainer {
  elements: Array<ProfileComposableElement>;
}

export interface ProfileContent {
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
}

export type ProfileContentCard = {
  __typename?: "ProfileContentCard";
  contentPrimary?: Maybe<Scalars["String"]>;
  rows: Array<ProfileContent>;
};

export type ProfileContentItem = ProfileContentTextItem;

export type ProfileContentParagraph = ProfileContent & {
  __typename?: "ProfileContentParagraph";
  items?: Maybe<Array<ProfileContentItem>>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type ProfileContentTextItem = {
  __typename?: "ProfileContentTextItem";
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type ProfileDismissAction = ProfileAction & {
  __typename?: "ProfileDismissAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileEgdsSectionContainer = EgdsElement &
  EgdsElements & {
    __typename?: "ProfileEGDSSectionContainer";
    egdsElementId?: Maybe<Scalars["String"]>;
    elements: Array<EgdsElement>;
    heading?: Maybe<Scalars["String"]>;
    theme?: Maybe<ContainerTheme>;
  };

export type ProfileExitFlowAction = ProfileAction & {
  __typename?: "ProfileExitFlowAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileExitFlowAndSignOutAction = ProfileAction & {
  __typename?: "ProfileExitFlowAndSignOutAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileFlexContainer = {
  __typename?: "ProfileFlexContainer";
  elements: Array<ProfileComposableElement>;
};

export type ProfileFormHorizontalContainer = EgdsElement & {
  __typename?: "ProfileFormHorizontalContainer";
  egdsElementId?: Maybe<Scalars["String"]>;
  elements: Array<EgdsElement>;
  errorMessage?: Maybe<Scalars["String"]>;
};

export type ProfileFormSection = EgdsElement & {
  __typename?: "ProfileFormSection";
  egdsElementId?: Maybe<Scalars["String"]>;
  elements: Array<EgdsElement>;
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
};

export type ProfileFormSubmitAction = UiAction & {
  __typename?: "ProfileFormSubmitAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
  inputIds: Array<Scalars["String"]>;
};

export type ProfileFullBleedImageCard = {
  __typename?: "ProfileFullBleedImageCard";
  action?: Maybe<UiAction>;
  background?: Maybe<Image>;
  fullBleedPrimary?: Maybe<Scalars["String"]>;
  icons?: Maybe<Array<Icon>>;
  impressionTracking?: Maybe<ClientSideImpressionEventAnalytics>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type ProfileLink = EgdsElement & {
  __typename?: "ProfileLink";
  action: UiAction;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export type ProfileLinkAction = UiAction & {
  __typename?: "ProfileLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: ProfileLinkTarget;
};

export type ProfileLinkTarget = "EXTERNAL" | "INTERNAL";

export type ProfileNumberField = EgdsElement &
  EgdsInputField & {
    __typename?: "ProfileNumberField";
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    hasError: Scalars["Boolean"];
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required: Scalars["Boolean"];
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type ProfilePassportFormAction = ProfileAction & {
  __typename?: "ProfilePassportFormAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type ProfilePhoneNumberField = EgdsElement &
  EgdsInputField & {
    __typename?: "ProfilePhoneNumberField";
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    hasError: Scalars["Boolean"];
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required: Scalars["Boolean"];
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type ProfilePopUiToRoot = {
  __typename?: "ProfilePopUIToRoot";
  content: ProfileAccountFormContent;
};

export type ProfilePresentUi = {
  __typename?: "ProfilePresentUI";
  content: ProfileAccountFormContent;
};

export type ProfilePushUi = {
  __typename?: "ProfilePushUI";
  content: ProfileAccountFormContent;
  title: Scalars["String"];
};

export type ProfileRadioButton = EgdsElement &
  EgdsRadioButton & {
    __typename?: "ProfileRadioButton";
    description?: Maybe<Scalars["String"]>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    label: Scalars["String"];
    labelSuffix?: Maybe<Scalars["String"]>;
    submitAnalytics?: Maybe<ClientSideAnalytics>;
    value: Scalars["String"];
  };

export type ProfileRadioButtonGroup = EgdsElement &
  EgdsRadioGroup & {
    __typename?: "ProfileRadioButtonGroup";
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    groupName: Scalars["String"];
    label?: Maybe<Scalars["String"]>;
    options: Array<EgdsRadioButton>;
    selected?: Maybe<Scalars["String"]>;
  };

export type ProfileReloadAction = UiAction & {
  __typename?: "ProfileReloadAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileRewardProgramFormAction = UiAction & {
  __typename?: "ProfileRewardProgramFormAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type ProfileSectionContainer = ProfileContainer & {
  __typename?: "ProfileSectionContainer";
  elements: Array<ProfileComposableElement>;
  heading?: Maybe<Scalars["String"]>;
  theme?: Maybe<ContainerTheme>;
};

export type ProfileSelectField = EgdsElement & {
  __typename?: "ProfileSelectField";
  egdsElementId?: Maybe<Scalars["String"]>;
  hasError: Scalars["Boolean"];
  label: Scalars["String"];
  options: Array<ProfileSwitchOption>;
};

export type ProfileSignOutAction = ProfileAction & {
  __typename?: "ProfileSignOutAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileSlimCard = EgdsElement & {
  __typename?: "ProfileSlimCard";
  /** @deprecated Use profileAction instead */
  action?: Maybe<UiAction>;
  alertMessages?: Maybe<Array<Scalars["String"]>>;
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  impressionTracking?: Maybe<ClientSideImpressionEventAnalytics>;
  profileAction?: Maybe<ProfileAction>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  slimCardPrimary?: Maybe<Scalars["String"]>;
  tertiaries?: Maybe<Array<Scalars["String"]>>;
};

export type ProfileSwitchOption = EgdsElement & {
  __typename?: "ProfileSwitchOption";
  egdsElementId?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  value: Scalars["String"];
};

export type ProfileTextField = EgdsElement &
  EgdsInputField & {
    __typename?: "ProfileTextField";
    egdsElementId?: Maybe<Scalars["String"]>;
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required: Scalars["Boolean"];
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type ProfileUiPill = UiPill & {
  __typename?: "ProfileUIPill";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated Use profileAction instead */
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  profileAction?: Maybe<ProfileAction>;
  selected: Scalars["Boolean"];
};

export type ProfileUpdateMembershipAction = UiAction & {
  __typename?: "ProfileUpdateMembershipAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type ProfileUpdatePassportAction = UiAction & {
  __typename?: "ProfileUpdatePassportAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type ProfileWizardComponent = {
  __typename?: "ProfileWizardComponent";
  elements: Array<ProfileComposableElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export interface ProfileWizardContextInput {
  lineOfBusiness: LineOfBusinessDomain;
  pageLocation: PageLocation;
}

export type ProfileWizardResponse = ProfileWizardComponent | WizardAdditionalUserInputRequiredAction;

export type Property = Plottable & {
  __typename?: "Property";
  addressMessaging?: Maybe<Scalars["String"]>;
  amenities: Array<PropertyAmenityValue>;
  availability: PropertyAvailability;
  clickstreamEvents: LodgingCardClickstreamEvents;
  dataAttributes?: Maybe<GraphQlPair>;
  destinationInfo: PropertyDestinationInfo;
  /** @deprecated Look for errors in response.errors instead */
  errors?: Maybe<Array<Scalars["String"]>>;
  featuredCheckinDate?: Maybe<Scalars["String"]>;
  featuredCheckoutDate?: Maybe<Scalars["String"]>;
  featuredMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Deprecated, replace with propertyGallery */
  gallery?: Maybe<Array<Image>>;
  /** @deprecated Use highlightedPropertyDetails */
  highlightedPropertyDetail?: Maybe<Scalars["String"]>;
  highlightedPropertyDetails: Array<Scalars["String"]>;
  id: Scalars["String"];
  /** @deprecated replaced by propertyImage */
  image?: Maybe<Image>;
  imageGallery?: Maybe<PropertyImageGallery>;
  /** @deprecated Don't use this anymore., replace with propertyDetailsLink */
  infositeURL: Scalars["String"];
  /** @deprecated Moved to mapMarker.latLong */
  latLong?: Maybe<Coordinates>;
  legalDisclaimer?: Maybe<PropertyLegalDisclaimer>;
  listingFooter?: Maybe<MessagingAction>;
  /** @deprecated deprecated in favour of priceBreakDownSummary, replace with priceBreakDownSummary */
  lodgingPriceBreakDown?: Maybe<LodgingPriceBreakDown>;
  mapMarker?: Maybe<MapMarker>;
  name: Scalars["String"];
  neighborhood?: Maybe<Region>;
  offerBadge?: Maybe<OfferBadge>;
  offerSummary: OfferSummary;
  pinnedDetails?: Maybe<PinnedPropertyModel>;
  price?: Maybe<PropertyPrice>;
  priceAfterLoyaltyPointsApplied?: Maybe<PropertyPrice>;
  priceBreakDownSummary?: Maybe<PriceSummary>;
  priceMetadata: PropertyPriceMetadata;
  propertyDetailsLink?: Maybe<LodgingLink>;
  propertyFees?: Maybe<Array<Fees>>;
  /** @deprecated Use imageGallery */
  propertyGallery?: Maybe<Array<PropertyImage>>;
  propertyImage?: Maybe<PropertyImage>;
  propertyPaymentFlags: Array<PropertyPaymentFlags>;
  propertySource?: Maybe<PropertySource>;
  /** @deprecated Experiment stopped and field is no more supported */
  propertyTypeBadge?: Maybe<Scalars["String"]>;
  providerId?: Maybe<Scalars["String"]>;
  ratePlanId?: Maybe<Scalars["String"]>;
  /** @deprecated Use featuredMessages */
  recentlyViewed?: Maybe<Icon>;
  regionId: Scalars["String"];
  reviews: PropertyReviewsSummary;
  roomTypeId?: Maybe<Scalars["String"]>;
  saveTripItem?: Maybe<TripsSaveItem>;
  /** @deprecated Deprecated in favor of saveTripItem */
  shortList?: Maybe<ShortList>;
  sponsoredListing?: Maybe<SponsoredListing>;
  star?: Maybe<Scalars["Float"]>;
  starRatingIcon: Scalars["String"];
  /** @deprecated Discontinued due to feature being dismissed after tnl 44162 */
  starRatingText?: Maybe<Scalars["String"]>;
  structureType: StructureType;
  supportingMessages?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated Moved to image. This field does not return a thumbor image. */
  thumborImage?: Maybe<Image>;
  /** @deprecated Use vipMessaging */
  vip: Scalars["Boolean"];
  vipMessaging?: Maybe<Scalars["String"]>;
};

export type PropertyActivitiesCrossSellAction = {
  __typename?: "PropertyActivitiesCrossSellAction";
  actionType: PropertyActivitiesCrossSellActionType;
  activityId?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type PropertyActivitiesCrossSellActionType = "DISMISS" | "EDIT" | "OPEN" | "REMOVE";

export type PropertyActivitiesCrossSellDetails = {
  __typename?: "PropertyActivitiesCrossSellDetails";
  accessibility: Scalars["String"];
  actions: Array<PropertyActivitiesCrossSellLinkButton>;
  activityId: Scalars["String"];
  description: EgdsPlainText;
  heading: LodgingHeader;
  liveAnnounceMessage: Scalars["String"];
  offerActivityDescription?: Maybe<EgdsSpannableText>;
  price?: Maybe<DisplayPrice>;
  showTracking: ClientSideAnalytics;
  sideSheet?: Maybe<PropertyActivitiesCrossSellSideSheet>;
};

export type PropertyActivitiesCrossSellLinkButton = {
  __typename?: "PropertyActivitiesCrossSellLinkButton";
  action: Array<PropertyActivitiesCrossSellAction>;
  button: LodgingPropertyActivitiesLinkButton;
};

export type PropertyActivitiesCrossSellSideSheet = {
  __typename?: "PropertyActivitiesCrossSellSideSheet";
  dismissButtonAccessibilityText?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
};

export type PropertyAddress = {
  __typename?: "PropertyAddress";
  addressLine?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  firstAddressLine?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  secondAddressLine?: Maybe<Scalars["String"]>;
};

export type PropertyAmenities = {
  __typename?: "PropertyAmenities";
  /** @deprecated Only used on Mobile app, moved into amenities field */
  allUnits?: Maybe<PropertyInfoSection>;
  /** @deprecated Property and Room amenities are deprecated. Make use of propertyAmenities and roomAmenities inside propertyContentSectionGroups */
  amenities: Array<PropertyInfoSection>;
  amenitiesAdaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  /** @deprecated The summary structure should be refactored, prevent use that structure on future implementation */
  amenitiesDialog?: Maybe<LodgingAmenitiesDialog>;
  /** @deprecated Only used on Mobile app, moved into amenities field */
  property?: Maybe<PropertyInfoSection>;
  propertyContentPreferences?: Maybe<PropertyContent>;
  relevantAmenities: Array<PropertyInfoSection>;
  /** @deprecated Use amenitiesAdaptExSuccessEvents */
  reserveAdaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  takeover?: Maybe<PropertyTakeoverAmenities>;
  /** @deprecated Use topAmenities */
  top: Array<PropertyInfoItem>;
  topAmenities?: Maybe<PropertyInfoContent>;
  /** @deprecated This list may send attempt events from wrong section */
  viewAmenitiesAdaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
};

export type PropertyAmenity = {
  __typename?: "PropertyAmenity";
  icon?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type PropertyAmenityCategory = {
  __typename?: "PropertyAmenityCategory";
  /** @deprecated Use type instead of code */
  code?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  type?: Maybe<PropertyAmenityCategoryType>;
};

export type PropertyAmenityCategoryType =
  | "ACCESSIBLE_ROLL_IN_SHOWER"
  | "AIR_CONDITIONING"
  | "ALL_INCLUSIVE"
  | "ARCADE"
  | "BABYSITTING"
  | "BAR"
  | "BOWLING"
  | "BREAKFAST_AVAILABLE"
  | "BUSINESS_SERVICES"
  | "CHILDREN_CLUB"
  | "DAILY_HOUSEKEEPING"
  | "EXTRA_BED"
  | "FITNESS_EQUIPMENT"
  | "FREE_AIRPORT_TRANSPORTATION"
  | "FREE_BREAKFAST"
  | "FREE_PARKING"
  | "FREE_WELCOME_DRINK"
  | "FRONT_DESK_24_HOURS"
  | "GROCERY"
  | "HIGH_SPEED_INTERNET"
  | "HOT_TUB"
  | "IN_ROOM_INTERNET"
  | "KIDS_POOL"
  | "KITCHEN"
  | "LAUNDRY"
  | "LIVING_AREA"
  | "MICROWAVE"
  | "MINI_GOLF"
  | "PARKING"
  | "PETS_ALLOWED"
  | "PLAYGROUND"
  | "POOL"
  | "RESTAURANT_IN_HOTEL"
  | "ROOM_SERVICE"
  | "SMOKE_FREE"
  | "SOUNDPROOF_ROOM"
  | "SPA_SERVICES_ON_SITE"
  | "SPECIAL_DEAL"
  | "TENNIS_COURT"
  | "TOYS"
  | "WATERSLIDE";

export type PropertyAmenitySection = {
  __typename?: "PropertyAmenitySection";
  amenities: Array<PropertyAmenity>;
  description: Array<Scalars["String"]>;
  subType?: Maybe<PropertyAmenityType>;
  title: Scalars["String"];
  type: PropertyAmenityType;
};

export type PropertyAmenityType =
  | "ACCESSIBILITY"
  | "BATHROOM"
  | "BEDROOM"
  | "BUSINESS_SERVICES"
  | "CONVENIENCES"
  | "ENTERTAINMENT"
  | "FAMILY_FRIENDLY"
  | "FOOD_DRINK"
  | "GUEST_SERVICES"
  | "INTERNET"
  | "MORE"
  | "OUTDOOR"
  | "OUTDOOR_SPACE"
  | "PARKING"
  | "PROPERTY"
  | "ROOM"
  | "SPA"
  | "THINGS_TO_DO";

export type PropertyAmenityValue = {
  __typename?: "PropertyAmenityValue";
  icon: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type PropertyAvailability = {
  __typename?: "PropertyAvailability";
  available: Scalars["Boolean"];
  minRoomsLeft?: Maybe<Scalars["Int"]>;
  scarcityMessage?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  theme?: Maybe<Theme>;
  unavailableReasons: Array<PropertyUnavailableValue>;
};

export type PropertyCalendar = {
  __typename?: "PropertyCalendar";
  disclaimer: LodgingPlainMessage;
  legend?: Maybe<Array<PropertyCalendarLegend>>;
  propertyCalendarRates: Array<PropertyCalendarRate>;
};

export interface PropertyCalendarLegend {
  label: Scalars["String"];
}

export type PropertyCalendarPriceTrend = "NEGATIVE" | "POSITIVE";

export type PropertyCalendarPriceTrendLegend = PropertyCalendarLegend & {
  __typename?: "PropertyCalendarPriceTrendLegend";
  label: Scalars["String"];
  symbol: Scalars["String"];
  trend: PropertyCalendarPriceTrend;
};

export type PropertyCalendarRate = {
  __typename?: "PropertyCalendarRate";
  accessibilityLabel: Scalars["String"];
  date: Date;
  displayPrice: Scalars["String"];
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated in favour of displayPrice */
  price?: Maybe<Money>;
  theme?: Maybe<LodgingMessageTheme>;
};

export type PropertyCancellationPolicy = {
  __typename?: "PropertyCancellationPolicy";
  /** @deprecated Use cancellationWindow since the date time can be nullable */
  cancellationDateTime: ZonedDateTime;
  cancellationWindow?: Maybe<ZonedDateTime>;
  text: Scalars["String"];
  type: PropertyCancellationPolicyType;
};

export type PropertyCancellationPolicyType =
  | "NON_REFUNDABLE"
  | "REFUNDABLE_WITH_NO_PENALTY"
  | "REFUNDABLE_WITH_PENALTY";

export type PropertyCartItem = CartItem & {
  __typename?: "PropertyCartItem";
  /** @deprecated DO NOT USE: This is required for the federation migration. Clients should only ever pass the context as input */
  deprecatedContext: DeprecatedMultiItemContext;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyImageGallery */
  gallery?: Maybe<PropertyGallery>;
  id: Scalars["UUID"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  offers: Array<PropertyOffer>;
  /** @deprecated Replaced by the type PropertyImageGallery as part of BFF to BEX-API migration, replace with propertyImageGallery */
  propertyGallery?: Maybe<PropertyGallery>;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  propertyId: Scalars["String"];
  propertyImageGallery?: Maybe<PropertyImageGallery>;
  propertySummary?: Maybe<PropertySummary>;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  searchContext: CartPropertySearchContext;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertySummary */
  summary?: Maybe<PropertySummary>;
};

export type PropertyCartItemPropertyGalleryArgs = {
  context: ContextInput;
};

export type PropertyCartItemPropertyImageGalleryArgs = {
  context: ContextInput;
};

export type PropertyCartItemPropertySummaryArgs = {
  context: ContextInput;
};

export type PropertyContent = {
  __typename?: "PropertyContent";
  /** @deprecated Use expandoPeek instead, replace with expandoPeek */
  expando?: Maybe<LodgingExpando>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<LodgingHeader>;
  items: Array<PropertyContentItem>;
  leadingGraphic?: Maybe<LodgingLeadingGraphic>;
  maxColumns?: Maybe<Scalars["Int"]>;
  structuredData?: Maybe<SeoStructuredData>;
};

export type PropertyContentElement = EgdsStandardMessagingCard | PropertyContent;

export type PropertyContentElementGroup = {
  __typename?: "PropertyContentElementGroup";
  elements: Array<PropertyContentElement>;
  maxColumns: Scalars["Int"];
};

export type PropertyContentItem =
  | LodgingLinkMessage
  | PropertyContentItemGraphics
  | PropertyContentItemImages
  | PropertyContentItemMarkup
  | PropertyContentItemText
  | PropertyContentItemTexts;

export type PropertyContentItemGraphics = {
  __typename?: "PropertyContentItemGraphics";
  /** @deprecated graphics is deprecated, use graphicsV2 */
  graphics: Array<UiGraphic>;
  graphicsV2: Array<LodgingGraphic>;
};

export type PropertyContentItemImages = {
  __typename?: "PropertyContentItemImages";
  contents: Array<PropertyImage>;
};

export type PropertyContentItemMarkup = {
  __typename?: "PropertyContentItemMarkup";
  content: MarkupText;
};

export type PropertyContentItemText = {
  __typename?: "PropertyContentItemText";
  content: PropertyContentText;
  expando?: Maybe<EgdsExpandoPeek>;
};

export type PropertyContentItemTexts = {
  __typename?: "PropertyContentItemTexts";
  contents: Array<PropertyContentText>;
};

export type PropertyContentSection = {
  __typename?: "PropertyContentSection";
  action?: Maybe<PropertyContentSectionAction>;
  /** @deprecated Do not use; please follow same pattern as PropertyContentSectionGroup., replace with sectionName */
  anchor?: Maybe<Scalars["String"]>;
  bodySubSections: Array<PropertyContentSubSection>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<LodgingHeader>;
  headingContentElements: Array<PropertyContentElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sectionName?: Maybe<Scalars["String"]>;
};

export type PropertyContentSectionAction =
  | LodgingLinkMessage
  | PropertyContentSectionDetailedDialog
  | PropertyContentSectionDialog
  | PropertyContentSectionListTypeDialog;

export type PropertyContentSectionDetailedDialog = {
  __typename?: "PropertyContentSectionDetailedDialog";
  dialog: EgdsDialog;
  trigger: PropertyContentSectionDialogTrigger;
};

export type PropertyContentSectionDialog = {
  __typename?: "PropertyContentSectionDialog";
  content: EgdsSpannableText;
  dialog: EgdsDialog;
  trigger: PropertyContentSectionDialogTrigger;
};

export type PropertyContentSectionDialogTrigger = {
  __typename?: "PropertyContentSectionDialogTrigger";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  message: Scalars["String"];
};

export type PropertyContentSectionGroup = {
  __typename?: "PropertyContentSectionGroup";
  action?: Maybe<PropertyContentSectionAction>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sectionName?: Maybe<Scalars["String"]>;
  sections: Array<PropertyContentSection>;
};

export type PropertyContentSectionGroups = {
  __typename?: "PropertyContentSectionGroups";
  aboutTheLocation?: Maybe<PropertyContentSectionGroup>;
  aboutThisHost?: Maybe<PropertyContentSectionGroup>;
  aboutThisProperty?: Maybe<PropertyContentSectionGroup>;
  amenities?: Maybe<PropertyContentSectionGroup>;
  /** @deprecated cleanliness is deprecated. This content should be part of aboutThisProperty sectionGroup */
  cleanliness?: Maybe<PropertyContentSectionGroup>;
  closures?: Maybe<PropertyContentSectionGroup>;
  feesInformation?: Maybe<PropertyContentSectionGroup>;
  importantInfo?: Maybe<PropertyContentSectionGroup>;
  policies?: Maybe<PropertyContentSectionGroup>;
  specialFeatures?: Maybe<PropertyContentSectionGroup>;
};

export type PropertyContentSectionListTypeDialog = {
  __typename?: "PropertyContentSectionListTypeDialog";
  content: Array<PropertyInfoContent>;
  dialog: EgdsDialog;
  trigger: PropertyContentSectionDialogTrigger;
};

export type PropertyContentSectionRef =
  | "AVAILABILITY_CALENDAR_START_DATE"
  | "PRICE_DISPLAY_SERVICE_RESPONSE"
  | "RECOMMENDED_OFFER"
  | "ROOMS_AND_RATES";

export type PropertyContentSubSection = {
  __typename?: "PropertyContentSubSection";
  actions?: Maybe<Array<PropertyContentSectionAction>>;
  /** @deprecated Use elementsV2., replace with elementsV2 */
  contents: Array<PropertyContent>;
  elements: Array<PropertyContentElement>;
  elementsV2: Array<PropertyContentElementGroup>;
  expando?: Maybe<LodgingExpando>;
  header?: Maybe<LodgingHeader>;
  maxColumns: Scalars["Int"];
};

export type PropertyContentText = {
  __typename?: "PropertyContentText";
  primary: LodgingEnrichedMessage;
  secondary?: Maybe<LodgingPlainMessage>;
};

export type PropertyContentType = "DETAILED" | "SUMMARY";

export interface PropertyContext {
  propertyId: Scalars["String"];
}

export type PropertyDateRange = {
  __typename?: "PropertyDateRange";
  checkInDate: Date;
  checkOutDate: Date;
};

export interface PropertyDateRangeInput {
  checkInDate: DateInput;
  checkOutDate: DateInput;
}

export type PropertyDestinationInfo = {
  __typename?: "PropertyDestinationInfo";
  distanceFromDestination?: Maybe<Distance>;
  distanceFromMessaging?: Maybe<Scalars["String"]>;
  includedFromSupplementalSearch: Scalars["Boolean"];
  latLong?: Maybe<Coordinates>;
  regionId: Scalars["String"];
};

export interface PropertyDetailPlottable {
  mapMarker: MapMarker;
}

export type PropertyDetails = {
  __typename?: "PropertyDetails";
  contentSections: Array<PropertyInfoContent>;
  description?: Maybe<EgdsParagraph>;
  gallery?: Maybe<PropertyImageGallery>;
  heading: EgdsHeading;
  rating?: Maybe<EgdsRating>;
  reviewSummary?: Maybe<PropertyReviewSummary>;
};

export type PropertyDetailsExperimentalSection = LodgingExperimentalSection & {
  __typename?: "PropertyDetailsExperimentalSection";
  features: Array<LodgingExperimentalMetaData>;
  pageLayoutExperimentalFeature: PageLayoutExperimentalFeature;
  propertyTaglineExperimentalFeature: PropertyTaglineExperimentalFeature;
};

export type PropertyDetailsExperimentalSectionFeaturesArgs = {
  createdAfter?: InputMaybe<DateInput>;
  createdBefore?: InputMaybe<DateInput>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  org?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<Scalars["String"]>;
};

export type PropertyDetailsMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PropertyDetailsMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export interface PropertyDetailsMultiItemShoppingActionInput {
  shoppingContext: ShoppingContextInput;
}

export type PropertyDetailsPropertyType = "HOTEL" | "VACATION_RENTAL";

export type PropertyDetailsStickyBar = {
  __typename?: "PropertyDetailsStickyBar";
  /** @deprecated Use propertyPrice instead */
  displayPrice?: Maybe<Scalars["String"]>;
  /** @deprecated Use propertyPrice instead */
  price?: Maybe<PropertyPriceOption>;
  pricePresentation?: Maybe<PricePresentation>;
  pricePresentationDialog?: Maybe<PricePresentationDialog>;
  propertyPrice?: Maybe<PropertyPrice>;
  /** @deprecated Use propertyPrice instead */
  qualifier?: Maybe<Scalars["String"]>;
  stickyButton?: Maybe<LodgingStickyButton>;
  structuredData?: Maybe<PropertySeoStructuredData>;
  /** @deprecated Use propertyPrice instead */
  subText?: Maybe<Scalars["String"]>;
};

export type PropertyEditorial = {
  __typename?: "PropertyEditorial";
  content: Array<Scalars["String"]>;
  /** @deprecated Use crossLinks, replace with crossLinks */
  crossLink?: Maybe<LodgingLinkMessage>;
  crossLinks?: Maybe<Array<LodgingLinkMessage>>;
  title: Scalars["String"];
};

export type PropertyFaq = {
  __typename?: "PropertyFAQ";
  answer: PropertyFaqContent;
  question: PropertyFaqContent;
};

export type PropertyFaqContent = {
  __typename?: "PropertyFAQContent";
  content: MarkupText;
  /** @deprecated Will be remove in favor of content property which it can contains markup */
  text: Scalars["String"];
  tracking?: Maybe<ClientSideAnalytics>;
};

export type PropertyFaQs = {
  __typename?: "PropertyFAQs";
  /** @deprecated Property FAQs action. No longer in use */
  action?: Maybe<Array<SpannableTextDialog>>;
  /** @deprecated Describes the ask a question button. No longer in use */
  askAQuestion?: Maybe<UiSecondaryButton>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  faqs: Array<PropertyFaq>;
  title: LodgingHeader;
};

export type PropertyFees = {
  __typename?: "PropertyFees";
  mandatory?: Maybe<PropertyInfoSubSection>;
  optional?: Maybe<PropertyInfoSubSection>;
};

export type PropertyFilterAccessibility =
  | "ACCESSIBLE_BATHROOM"
  | "ACCESSIBLE_PARKING"
  | "ELEVATOR"
  | "IN_ROOM_ACCESSIBLE"
  | "ROLL_IN_SHOWER"
  | "SERVICE_ANIMAL"
  | "SIGN_LANGUAGE_INTERPRETER"
  | "STAIR_FREE_PATH";

export type PropertyFilterAmenity =
  | "AIR_CONDITIONING"
  | "ALL_INCLUSIVE"
  | "BALCONY_OR_TERRACE"
  | "BAR"
  | "BEACH"
  | "BEACH_FRONT"
  | "BED_LINENS"
  | "CASINO"
  | "CRIB"
  | "DISHWASHER"
  | "DRYER"
  | "ELECTRIC_CAR"
  | "FIREPLACE"
  | "FREE_AIRPORT_TRANSPORTATION"
  | "FREE_BREAKFAST"
  | "FREE_PARKING"
  | "GOLF"
  | "GOLF_COURSE"
  | "GYM"
  | "HOT_SPRING"
  | "HOT_TUB"
  | "KITCHEN_KITCHEN"
  | "KITCHEN_KITCHENETTE"
  | "OCEAN_VIEW"
  | "PARKING"
  | "PETS"
  | "POOL"
  | "RESTAURANT_IN_HOTEL"
  | "SPA_ON_SITE"
  | "TELEVISION"
  | "WASHER"
  | "WASHER_DRYER"
  | "WATER_PARK"
  | "WIFI";

export type PropertyFilterMetadata = {
  __typename?: "PropertyFilterMetadata";
  amenities: Array<PropertyAmenityValue>;
  neighborhoods: Array<Neighborhood>;
  priceRange: PriceRange;
  structureTypes: Array<StructureTypeValue>;
};

export type PropertyFilteredReviewsDialog = {
  __typename?: "PropertyFilteredReviewsDialog";
  ratingPercentage: EgdsFullScreenDialog;
  trigger: LodgingDialogTriggerMessage;
};

export type PropertyGallery = {
  __typename?: "PropertyGallery";
  featured?: Maybe<Image>;
  images: Array<Image>;
};

export type PropertyGalleryCategory = {
  __typename?: "PropertyGalleryCategory";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  images: Array<PropertyImage>;
  name: Scalars["String"];
};

export type PropertyHeading = EgdsSectionHeading & {
  __typename?: "PropertyHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
};

export type PropertyHighlightSection = AccessibilityField & {
  __typename?: "PropertyHighlightSection";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  footerLink?: Maybe<LodgingLinkMessage>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  subSections: Array<PropertyHighlightSubSection>;
};

export type PropertyHighlightSubSection = AccessibilityField & {
  __typename?: "PropertyHighlightSubSection";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  contents: Array<LodgingEnrichedMessage>;
  description?: Maybe<Scalars["String"]>;
  footerLink?: Maybe<LodgingLinkMessage>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  title?: Maybe<LodgingEnrichedMessage>;
};

export type PropertyImage = {
  __typename?: "PropertyImage";
  actionAccessibility?: Maybe<Scalars["String"]>;
  alt?: Maybe<Scalars["String"]>;
  fallbackImage?: Maybe<Image>;
  image: Image;
  imageId?: Maybe<Scalars["String"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  lowResImage?: Maybe<Image>;
  previewImage?: Maybe<Image>;
  subjectId?: Maybe<Scalars["Int"]>;
  thumbnail?: Maybe<Image>;
  trackingId?: Maybe<Scalars["String"]>;
};

export type PropertyImageGallery = AccessibilityField & {
  __typename?: "PropertyImageGallery";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<PropertyGalleryCategory>>;
  featured?: Maybe<PropertyImage>;
  galleryButtons?: Maybe<Array<EgdsOverlayButton>>;
  images: Array<PropertyImage>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  mediaGalleryDialog?: Maybe<LodgingGalleryDialog>;
  primaryButton?: Maybe<PropertyLodgingStickyButton>;
  thumbnailGalleryDialog?: Maybe<LodgingGalleryDialog>;
};

export type PropertyInfo = {
  __typename?: "PropertyInfo";
  /** @deprecated for federation use.  Do not use this. */
  deprecatedPropertyContext: DeprecatedPropertyContext;
  /** @deprecated for federation use.  Do not use this. */
  deprecatedShoppingContext?: Maybe<DeprecatedShoppingContext>;
  directFeedback?: Maybe<DirectFeedback>;
  /** @deprecated Deprecated. Contents are volatile and subject to change at any moment. Use at your own risk! */
  experimental: PropertyDetailsExperimentalSection;
  feedback: Feedback;
  frequentlyAskedQuestions?: Maybe<PropertyFaQs>;
  /** @deprecated Replaced by propertyGallery as part of BFF to BEX-API migration */
  gallery?: Maybe<PropertyGallery>;
  id: Scalars["String"];
  /** @deprecated Use the one in Offers */
  infositeUrl?: Maybe<Scalars["URL"]>;
  lodgingProduct: LodgingProductView;
  offer?: Maybe<OfferDetails>;
  /** @deprecated do not use. This is for federation use */
  offerOptions?: Maybe<Array<PropertyInfoOptions>>;
  offers?: Maybe<OfferDetails>;
  propertyCalendar: PropertyCalendar;
  propertyContentSectionGroups: PropertyContentSectionGroups;
  propertyDetails: PropertyDetails;
  propertyGallery?: Maybe<PropertyImageGallery>;
  /** @deprecated Moved to PropertySummary, replace with PropertySummary.spaceOverview: PropertyInfoContent? */
  propertyHighlightedDetails?: Maybe<PropertyInfoContent>;
  propertyIdDisplay?: Maybe<EgdsSpannableText>;
  /** @deprecated do not use. This is for federation use */
  propertyName?: Maybe<Scalars["String"]>;
  /** @deprecated do not use. This is for federation use */
  referrer?: Maybe<Scalars["String"]>;
  review?: Maybe<PropertyReview>;
  reviewInfo: PropertyReviews;
  saveTripItem?: Maybe<TripsSaveItem>;
  shareInfo?: Maybe<ShareInfo>;
  shoppingShareLinks?: Maybe<ShoppingShareLinks>;
  /** @deprecated Deprecated in favor of tripsSaveItem */
  shortList?: Maybe<ShortList>;
  similarProperties?: Maybe<SimilarProperties>;
  summary?: Maybe<PropertySummary>;
  telesales?: Maybe<Telesales>;
  topReviews?: Maybe<PropertyTopReviews>;
};

export type PropertyInfoInfositeUrlArgs = {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  roomInput?: InputMaybe<Array<RoomInput>>;
};

export type PropertyInfoLodgingProductArgs = {
  searchCriteria: PropertySearchCriteriaInput;
  searchOffer: SearchOfferInput;
};

export type PropertyInfoOfferArgs = {
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  naturalKeys: Array<PropertyNaturalKeyInput>;
};

export type PropertyInfoOffersArgs = {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  roomInput?: InputMaybe<Array<RoomInput>>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type PropertyInfoPropertyCalendarArgs = {
  dateRange: PropertyDateRangeInput;
  roomInput: Array<RoomInput>;
};

export type PropertyInfoPropertyContentSectionGroupsArgs = {
  amenitiesPropertyContentType?: InputMaybe<PropertyContentType>;
  damageLiability?: InputMaybe<DamageLiability>;
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoPropertyDetailsArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoPropertyIdDisplayArgs = {
  identifier?: InputMaybe<Scalars["String"]>;
};

export type PropertyInfoReviewArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["Locale"]>;
};

export type PropertyInfoReviewInfoArgs = {
  end?: InputMaybe<Scalars["Int"]>;
  filters?: InputMaybe<PropertyReviewFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  sortBy?: InputMaybe<PropertyReviewSort>;
  start?: InputMaybe<Scalars["Int"]>;
};

export type PropertyInfoSaveTripItemArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoShoppingShareLinksArgs = {
  context: ContextInput;
  propertyId: Scalars["String"];
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoSimilarPropertiesArgs = {
  propertyRecommendationStrategy?: InputMaybe<PropertyRecommendationStrategy>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoTopReviewsArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoAction = PropertyInfoJumpLink;

export type PropertyInfoContent = {
  __typename?: "PropertyInfoContent";
  adaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  adaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Use header instead */
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  iconAnalytics?: Maybe<ClientSideAnalytics>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<PropertyInfoItem>;
  jumpLink?: Maybe<PropertyInfoJumpLink>;
  seeMoreAction?: Maybe<SeeMoreAction>;
  structuredData?: Maybe<SeoStructuredData>;
  /** @deprecated UI needs a nullable label. Use heading instead. */
  title: Scalars["String"];
};

export type PropertyInfoItem = AccessibilityField & {
  __typename?: "PropertyInfoItem";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use 'primary' field instead */
  graphic?: Maybe<UiGraphic>;
  highlights?: Maybe<Highlights>;
  /** @deprecated Use 'primary' field instead */
  icon?: Maybe<Icon>;
  /** @deprecated Use 'primary' field instead */
  label?: Maybe<Scalars["String"]>;
  moreInfo?: Maybe<Scalars["String"]>;
  moreInfoDialog?: Maybe<LodgingPlainDialog>;
  primary?: Maybe<EgdsGraphicText>;
  state?: Maybe<Scalars["String"]>;
  structuredData?: Maybe<SeoStructuredData>;
  subItems?: Maybe<EgdsTextIconList>;
  text: Scalars["String"];
};

export type PropertyInfoJumpLink = LodgingJumpLink & {
  __typename?: "PropertyInfoJumpLink";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  localizedName: Scalars["String"];
  sectionName: Scalars["String"];
};

export type PropertyInfoMap = LodgingDialog & {
  __typename?: "PropertyInfoMap";
  /** @deprecated No longer used, replace with egdsBasicMap.bounds */
  bounds?: Maybe<Bounds>;
  callToAction?: Maybe<PropertyInfoMapButton>;
  /** @deprecated No longer used, replace with egdsBasicMap.center */
  center?: Maybe<Coordinates>;
  dialogs?: Maybe<Array<PlacesDialog>>;
  egdsBasicMap: EgdsBasicMap;
  isPrebundledPath: Scalars["Boolean"];
  mapInfoCards: Array<EgdsImageCard>;
  /** @deprecated No longer used */
  markerPills: Array<PropertyInfoMapPill>;
  /** @deprecated No longer used, replace with egdsBasicMap.markers */
  markers?: Maybe<Array<PropertyDetailPlottable>>;
  /** @deprecated No longer used */
  staticMapSrc?: Maybe<Scalars["String"]>;
  toolbar: LodgingDialogToolbar;
  /** @deprecated No longer used */
  trigger: LodgingDialogTriggerMessage;
  /** @deprecated No longer used, replace with egdsBasicMap.zoom */
  zoom?: Maybe<Scalars["Int"]>;
};

export type PropertyInfoMapAction = {
  __typename?: "PropertyInfoMapAction";
  targetRef?: Maybe<Scalars["String"]>;
};

export type PropertyInfoMapButton = {
  __typename?: "PropertyInfoMapButton";
  action?: Maybe<PropertyInfoMapAction>;
  button: UiPrimaryFloatingActionButton;
  event?: Maybe<EgClickstreamEvent>;
};

export type PropertyInfoMapPill = {
  __typename?: "PropertyInfoMapPill";
  isSelected: Scalars["Boolean"];
  label: Scalars["String"];
  type: Scalars["String"];
};

export type PropertyInfoOffersListing = MessageResult | PropertyUnit | RecommendedOffer;

export type PropertyInfoOptions = "SHOP_WITH_POINTS";

export type PropertyInfoSection = {
  __typename?: "PropertyInfoSection";
  action?: Maybe<PropertyInfoAction>;
  contents: Array<PropertyInfoContent>;
  /** @deprecated Label used by the PWA to drive automation testing */
  dataLabel?: Maybe<Scalars["String"]>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated PropertyInfoSubSection is competing similar model as PropertyInfoContent but is less flexible. Replaced by contents field. */
  sections: Array<PropertyInfoSubSection>;
  /** @deprecated Replaced by the more flexible LodgingHeader type, replace with header.text */
  title?: Maybe<Scalars["String"]>;
};

export type PropertyInfoSubSection = {
  __typename?: "PropertyInfoSubSection";
  body: Array<Scalars["String"]>;
  descriptions: Array<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type PropertyItemDetails = {
  __typename?: "PropertyItemDetails";
  destination?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  tripTitle?: Maybe<Scalars["String"]>;
};

export type PropertyLegalDisclaimer = {
  __typename?: "PropertyLegalDisclaimer";
  disclaimerContents?: Maybe<Array<Scalars["String"]>>;
  title?: Maybe<Scalars["String"]>;
};

export type PropertyListingPlaceholderMetadata = {
  __typename?: "PropertyListingPlaceholderMetadata";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type PropertyListingsIconTextExpando = {
  __typename?: "PropertyListingsIconTextExpando";
  expandedContent?: Maybe<Array<EgdsGraphicText>>;
  expandoCard?: Maybe<EgdsExpandoCard>;
  graphic?: Maybe<UiGraphic>;
};

export type PropertyLocation = {
  __typename?: "PropertyLocation";
  address?: Maybe<PropertyAddress>;
  coordinates?: Maybe<Coordinates>;
  /** @deprecated The summary structure should be refactored, prevent use that structure on future implementation */
  mapDialog?: Maybe<LodgingMapDialog>;
  mapTrigger?: Maybe<LodgingMapTrigger>;
  multiCityRegion?: Maybe<Region>;
  /** @deprecated Replaced by 'staticImage' field. */
  overviewStaticMapSrc?: Maybe<Scalars["String"]>;
  parentRegion?: Maybe<Region>;
  /** @deprecated PROPEXP-1830 removed as part of TNL finalisation */
  ratings?: Maybe<ProductRatingSummary>;
  /** @deprecated Replaced by 'mapTrigger' field. */
  staticImage?: Maybe<Image>;
  whatsAround?: Maybe<PropertyWhatsAround>;
};

export type PropertyLodgingStickyButton = {
  __typename?: "PropertyLodgingStickyButton";
  button?: Maybe<LodgingButton>;
  propertyTargetRef?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecating to use a more flexible field, replace with propertyTargetRef */
  targetRef?: Maybe<PropertyContentSectionRef>;
};

export type PropertyLoyaltyDiscount = {
  __typename?: "PropertyLoyaltyDiscount";
  /** @deprecated Legacy message used on HSR for showing loyalty points, replace with saveWithPointsMessage, saveWithPointsActionMessage */
  pointsAppliedMessage?: Maybe<Scalars["String"]>;
  pointsDiscount?: Maybe<Money>;
  pointsToggle?: Maybe<UiToggle>;
  pointsVIPDiscount?: Maybe<Money>;
  saveWithPointsActionMessage?: Maybe<Scalars["String"]>;
  saveWithPointsMessage?: Maybe<Scalars["String"]>;
  tierName?: Maybe<Scalars["String"]>;
};

export type PropertyLoyaltyMessage = {
  __typename?: "PropertyLoyaltyMessage";
  badge?: Maybe<EgdsLoyaltyBadge>;
  linkMessage?: Maybe<LodgingLinkMessage>;
  text?: Maybe<Scalars["String"]>;
};

export type PropertyMarketingInfo = {
  __typename?: "PropertyMarketingInfo";
  affcid?: Maybe<Scalars["String"]>;
  afflid?: Maybe<Scalars["String"]>;
  afmcid?: Maybe<Scalars["String"]>;
  brandcid?: Maybe<Scalars["String"]>;
  emlcid?: Maybe<Scalars["String"]>;
  emldtl?: Maybe<Scalars["String"]>;
  gclid?: Maybe<Scalars["String"]>;
  icmcid?: Maybe<Scalars["String"]>;
  icmdtl?: Maybe<Scalars["String"]>;
  k_user_id?: Maybe<Scalars["String"]>;
  kword?: Maybe<Scalars["String"]>;
  lnkloc?: Maybe<Scalars["String"]>;
  marketingChannels?: Maybe<Array<PropertyMarketingTag>>;
  marketingPrices?: Maybe<Array<PropertyMarketingTag>>;
  mctc?: Maybe<Scalars["Int"]>;
  mdpcid?: Maybe<Scalars["String"]>;
  mdpdtl?: Maybe<Scalars["String"]>;
  olacid?: Maybe<Scalars["String"]>;
  oladtl?: Maybe<Scalars["String"]>;
  semcid?: Maybe<Scalars["String"]>;
  semdtl?: Maybe<Scalars["String"]>;
  seocid?: Maybe<Scalars["String"]>;
};

export interface PropertyMarketingInfoInput {
  affcid?: InputMaybe<Scalars["String"]>;
  afflid?: InputMaybe<Scalars["String"]>;
  afmcid?: InputMaybe<Scalars["String"]>;
  brandcid?: InputMaybe<Scalars["String"]>;
  emlcid?: InputMaybe<Scalars["String"]>;
  emldtl?: InputMaybe<Scalars["String"]>;
  gclid?: InputMaybe<Scalars["String"]>;
  icmcid?: InputMaybe<Scalars["String"]>;
  icmdtl?: InputMaybe<Scalars["String"]>;
  k_user_id?: InputMaybe<Scalars["String"]>;
  kword?: InputMaybe<Scalars["String"]>;
  lnkloc?: InputMaybe<Scalars["String"]>;
  marketingChannels?: InputMaybe<Array<PropertyMarketingTagInput>>;
  marketingPrices?: InputMaybe<Array<PropertyMarketingTagInput>>;
  mctc?: InputMaybe<Scalars["Int"]>;
  mdpcid?: InputMaybe<Scalars["String"]>;
  mdpdtl?: InputMaybe<Scalars["String"]>;
  olacid?: InputMaybe<Scalars["String"]>;
  oladtl?: InputMaybe<Scalars["String"]>;
  semcid?: InputMaybe<Scalars["String"]>;
  semdtl?: InputMaybe<Scalars["String"]>;
  seocid?: InputMaybe<Scalars["String"]>;
}

export type PropertyMarketingTag = {
  __typename?: "PropertyMarketingTag";
  key: Scalars["String"];
  value: Scalars["String"];
};

export interface PropertyMarketingTagInput {
  key: Scalars["String"];
  value: Scalars["String"];
}

export type PropertyNaturalKey = {
  __typename?: "PropertyNaturalKey";
  businessModelType?: Maybe<BusinessModelType>;
  checkIn: Date;
  checkOut: Date;
  id: Scalars["String"];
  inventoryType: InventoryType;
  noCreditCard?: Maybe<Scalars["Boolean"]>;
  petsIncluded?: Maybe<Scalars["Boolean"]>;
  ratePlanId: Scalars["String"];
  ratePlanType?: Maybe<Scalars["String"]>;
  roomTypeId: Scalars["String"];
  rooms: Array<PropertyRoom>;
  shoppingPath?: Maybe<ShoppingPathType>;
};

export interface PropertyNaturalKeyInput {
  businessModelType?: InputMaybe<BusinessModelType>;
  checkIn: DateInput;
  checkOut: DateInput;
  id: Scalars["String"];
  inventoryType: InventoryType;
  noCreditCard?: InputMaybe<Scalars["Boolean"]>;
  petsIncluded?: InputMaybe<Scalars["Boolean"]>;
  ratePlanId: Scalars["String"];
  ratePlanType?: InputMaybe<Scalars["String"]>;
  roomTypeId: Scalars["String"];
  rooms: Array<PropertyRoomInput>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
}

export type PropertyNavigationBarTab = LodgingJumpLink & {
  __typename?: "PropertyNavigationBarTab";
  icon?: Maybe<Icon>;
  localizedName: Scalars["String"];
  sectionName: Scalars["String"];
  state?: Maybe<Scalars["String"]>;
  tabLink?: Maybe<LodgingLinkMessage>;
};

export type PropertyOffer = {
  __typename?: "PropertyOffer";
  /** @deprecated DO NOT USE: This is required for the federation migration */
  deprecatedContext?: Maybe<DeprecatedMultiItemContext>;
  /** @deprecated DO NOT USE: This is required for the federation migration */
  federationNaturalKeys?: Maybe<Array<FederationPropertyNaturalKey>>;
  naturalKeys: Array<PropertyNaturalKey>;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyOffer */
  offer?: Maybe<OfferDetails>;
  offerIndex: Scalars["Int"];
  propertyId?: Maybe<Scalars["String"]>;
  propertyOffer?: Maybe<OfferDetails>;
};

export type PropertyOfferPropertyOfferArgs = {
  context: ContextInput;
};

export interface PropertyOfferInput {
  naturalKeys: Array<PropertyNaturalKeyInput>;
  offerIndex: Scalars["Int"];
}

export type PropertyOfferItem = OfferItem & {
  __typename?: "PropertyOfferItem";
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discountBadge?: Maybe<CampaignOfferBadge>;
  freeCancellationMessage?: Maybe<Scalars["String"]>;
  guestReviewCount?: Maybe<Scalars["String"]>;
  guestReviewQualifierText?: Maybe<Scalars["String"]>;
  guestReviewRating?: Maybe<Scalars["String"]>;
  guestReviewRatingQualifierText?: Maybe<Scalars["String"]>;
  guestReviewText?: Maybe<Scalars["String"]>;
  image: Image;
  location?: Maybe<Scalars["String"]>;
  lodgingOffer?: Maybe<OfferData>;
  mod?: Maybe<ModItem>;
  name: Scalars["String"];
  secondaryContent?: Maybe<Scalars["String"]>;
  starRating?: Maybe<Scalars["Float"]>;
  vapMessage?: Maybe<Scalars["String"]>;
  vipMessage?: Maybe<Scalars["String"]>;
};

export type PropertyOffersExperimentalSection = LodgingExperimentalSection & {
  __typename?: "PropertyOffersExperimentalSection";
  features: Array<LodgingExperimentalMetaData>;
};

export type PropertyOffersExperimentalSectionFeaturesArgs = {
  createdAfter?: InputMaybe<DateInput>;
  createdBefore?: InputMaybe<DateInput>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  org?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<Scalars["String"]>;
};

export type PropertyOverview = AccessibilityField & {
  __typename?: "PropertyOverview";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  inventoryType?: Maybe<InventoryType>;
  label?: Maybe<Scalars["String"]>;
  propertyRating?: Maybe<EgdsIconRating>;
  propertyTagline?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  /** @deprecated Use propertyRating */
  starRating?: Maybe<Scalars["Float"]>;
  vipMessaging?: Maybe<Scalars["String"]>;
};

export type PropertyPaymentFlags = "ARTIFICIALLY_FENCED_RATE" | "MIXED_RATE_PLAN" | "NO_CREDIT_CARD";

export type PropertyPaymentModel = "PAY_LATER" | "PAY_LATER_WITH_DEPOSIT" | "PAY_NOW";

export type PropertyPaymentOption = {
  __typename?: "PropertyPaymentOption";
  image?: Maybe<Scalars["URL"]>;
  name: Scalars["String"];
};

export type PropertyPaymentPolicyInfo = {
  __typename?: "PropertyPaymentPolicyInfo";
  descriptions: Array<PropertyInfoContent>;
  heading: Scalars["String"];
  paymentType: PropertyPaymentType;
  price?: Maybe<PropertyPrice>;
};

export type PropertyPaymentType = "PAY_LATER" | "PAY_LATER_WITH_DEPOSIT" | "PAY_NOW";

export type PropertyPolicies = {
  __typename?: "PropertyPolicies";
  checkinEnd?: Maybe<Scalars["String"]>;
  checkinInstructions: Array<Scalars["String"]>;
  checkinMinAge?: Maybe<Scalars["String"]>;
  checkinPolicies: Array<Scalars["String"]>;
  checkinStart?: Maybe<Scalars["String"]>;
  checkoutPolicies: Array<Scalars["String"]>;
  checkoutTime?: Maybe<Scalars["String"]>;
  childAndBed?: Maybe<PropertyInfoSubSection>;
  needToKnow?: Maybe<PropertyInfoSubSection>;
  paymentOptions: Array<PropertyPaymentOption>;
  pets?: Maybe<PropertyInfoSubSection>;
  shouldMention?: Maybe<PropertyInfoSubSection>;
};

export type PropertyPrice = {
  __typename?: "PropertyPrice";
  /** @deprecated UI should determine the alignment */
  alignment?: Maybe<Scalars["String"]>;
  /** @deprecated Legal disclaimer, replace with disclaimer in PropertyPriceOption */
  disclaimer?: Maybe<LodgingPlainMessage>;
  displayMessages?: Maybe<Array<PriceDisplayMessage>>;
  /** @deprecated Replace with list of options */
  lead?: Maybe<Money>;
  marketingFeeDetails?: Maybe<MarketingFeeDetails>;
  multiItemPriceToken?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<PropertyPriceOption>>;
  /** @deprecated Use priceMessaging instead */
  priceMessages?: Maybe<Array<LodgingPlainMessage>>;
  priceMessaging?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Replace with list of priceMessages */
  roomNightMessage?: Maybe<Scalars["String"]>;
  /** @deprecated Replace with list of options */
  strikeOut?: Maybe<Money>;
  /** @deprecated Replace with list of options */
  strikeOutType?: Maybe<StrikeOutType>;
  total?: Maybe<Money>;
};

export type PropertyPriceMetadata = {
  __typename?: "PropertyPriceMetadata";
  discountType?: Maybe<RateDiscountType>;
  rateDiscount?: Maybe<RateDiscount>;
  saleCurrency?: Maybe<Scalars["String"]>;
  supplyCurrency?: Maybe<Scalars["String"]>;
  totalDiscountPercentage?: Maybe<Scalars["Int"]>;
};

export type PropertyPriceOption = AccessibilityField & {
  __typename?: "PropertyPriceOption";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated This is deprecated. Please use priceDisclaimer instead */
  disclaimer?: Maybe<LodgingPlainMessage>;
  displayPrice?: Maybe<Money>;
  formattedDisplayPrice?: Maybe<Scalars["String"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  leadingCaption?: Maybe<Scalars["String"]>;
  loyaltyPrice?: Maybe<LoyaltyPrice>;
  priceDisclaimer?: Maybe<LodgingPlainDialog>;
  priceHeaderDialog?: Maybe<LodgingPlainDialog>;
  strikeOut?: Maybe<Money>;
};

export type PropertyRating = EgdsRating & {
  __typename?: "PropertyRating";
  accessibility?: Maybe<Scalars["String"]>;
  rating: Scalars["Float"];
};

export type PropertyRecommendationStrategy = "NEARBY_PROPERTIES" | "SIMILAR_PROPERTIES";

export type PropertyRenovations = {
  __typename?: "PropertyRenovations";
  header?: Maybe<Scalars["String"]>;
  renovations?: Maybe<Array<Scalars["String"]>>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type PropertyResultsSummary = {
  __typename?: "PropertyResultsSummary";
  availablePropertiesSize: Scalars["Int"];
  contents?: Maybe<Array<ProductResultsSummaryContentContainer>>;
  /** @deprecated Replaces with generic message models., replace with resultsSummary */
  headerMessages?: Maybe<Array<ResultsHeaderMessage>>;
  /** @deprecated Deprecating as we are moving away from dialogs style models along with its' contents */
  loyaltyEducation?: Maybe<LoyaltyEducation>;
  loyaltyInfo: PropertyLoyaltyDiscount;
  marketSize: Scalars["Int"];
  matchedPropertiesSize: Scalars["Int"];
  /** @deprecated Use PropertySearchResults.propertySearchListings - noop */
  messages?: Maybe<Array<MessageResult>>;
  messagingCard?: Maybe<SearchResultMessagingCard>;
  messagingModule?: Maybe<SearchResultMessagingModule>;
  messagingSheet?: Maybe<SearchResultMessagingSheet>;
  /** @deprecated Not needed, pageView beacon is fired in experience-api-lodging-search */
  pageViewBeacon: Scalars["String"];
  pricedHotelSize: Scalars["Int"];
  pricingScheme?: Maybe<PricingScheme>;
  propertyTypeCarousel?: Maybe<SearchCarousel>;
  region?: Maybe<Region>;
  /** @deprecated Unavailable now that call to Urgency Service has been removed */
  regionCompression?: Maybe<RegionCompression>;
  resultMessages: Array<LodgingSearchResultHeaderMessage>;
  /** @deprecated Use PropertySearchResults.propertySearchListings */
  resultSectionHeadings?: Maybe<Array<ResultSectionHeading>>;
  resultsHeading?: Maybe<Scalars["String"]>;
  /** @deprecated Use resultMessages, replace with resultMessages */
  resultsSummary?: Maybe<Array<HeaderMessage>>;
  /** @deprecated Need to return a model instead of a string, replace with resultsSummary */
  resultsTitle?: Maybe<Scalars["String"]>;
  /** @deprecated Need to return a list of models instead of a single title, replace with resultsSummary */
  resultsTitleModel?: Maybe<ResultTitleModel>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type PropertyReview = {
  __typename?: "PropertyReview";
  /** @deprecated Deprecated along with the userLocation in favor of the reviewAuthorAttribution which combines them */
  author?: Maybe<Scalars["String"]>;
  brandType?: Maybe<Scalars["String"]>;
  contentDirectFeedbackPromptId?: Maybe<Scalars["ID"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  feedbackIndicator?: Maybe<FeedbackIndicator>;
  /** @deprecated Deprecated in favor of the reviewInteractionSections */
  helpfulReviewVotes?: Maybe<Scalars["String"]>;
  highlightedText?: Maybe<EgdsSpannableText>;
  id: Scalars["String"];
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  locale: Scalars["Locale"];
  locationRemarks?: Maybe<Scalars["String"]>;
  managementResponses: Array<ManagementResponse>;
  negativeRemarks?: Maybe<Scalars["String"]>;
  photoSection?: Maybe<LodgingGalleryCarousel>;
  photos: Array<Image>;
  positiveRemarks?: Maybe<Scalars["String"]>;
  propertyReviewSource?: Maybe<PropertyReviewSource>;
  ratingOverall: Scalars["Float"];
  ratingOverallLabel: LodgingEnrichedMessage;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  readMore?: Maybe<EgdsStandardLink>;
  reviewAuthorAttribution?: Maybe<LodgingHeader>;
  reviewFooter?: Maybe<PropertyReviewFooterSection>;
  reviewInteractionSections?: Maybe<Array<PropertyReviewInteraction>>;
  /** @deprecated Deprecated in favor of the PropertyReviewFooterSection */
  reviewRegion?: Maybe<Mark>;
  reviewScoreWithDescription?: Maybe<LodgingEnrichedMessage>;
  reviewerVerified: Scalars["Boolean"];
  seeMoreAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Deprecated in favor of the PropertyReviewFooterSection */
  stayDuration?: Maybe<Scalars["String"]>;
  /** @deprecated submissionTime is deprecated. Use submissionTimeLocalized. */
  submissionTime: DateTime;
  submissionTimeLocalized: Scalars["String"];
  superlative?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  themes?: Maybe<Array<ReviewThemes>>;
  title: Scalars["String"];
  translationInfo?: Maybe<PropertyReviewTranslationInfo>;
  travelers: Array<Scalars["String"]>;
  /** @deprecated Consolidated into author field, replace with author */
  userDisplayName: Scalars["String"];
  userLocation: Scalars["String"];
  /** @deprecated Consolidated into author field, replace with author */
  userNickname: Scalars["String"];
};

export type PropertyReviewAggregates = {
  __typename?: "PropertyReviewAggregates";
  popularMentions?: Maybe<KeyPhrasesComponent>;
  propertySentiment?: Maybe<Array<PropertySentiment>>;
  propertySummaries?: Maybe<Array<LodgingReviewSummary>>;
};

export type PropertyReviewAggregatesPopularMentionsArgs = {
  client?: InputMaybe<KeyPhrasesClientType>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type PropertyReviewCategory =
  | "BUSINESS_TRAVELERS"
  | "COUPLES"
  | "EVERYONE"
  | "FAMILIES"
  | "GETAWAY_WITH_FRIENDS"
  | "GOLF"
  | "OTHER"
  | "OVERNIGHT_STAY"
  | "PERSONAL_EVENT"
  | "SHOPPING"
  | "SPA"
  | "STUDENTS";

export type PropertyReviewContent = {
  __typename?: "PropertyReviewContent";
  submissionDetails: Scalars["String"];
  text: Scalars["String"];
};

export type PropertyReviewCountDetails = {
  __typename?: "PropertyReviewCountDetails";
  fullDescription?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
};

export interface PropertyReviewFiltersInput {
  category?: InputMaybe<PropertyReviewCategory>;
  includeRatingsOnlyReviews?: InputMaybe<Scalars["Boolean"]>;
  includeRecentReviews?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["Locale"]>;
  ratings?: InputMaybe<Array<Scalars["Float"]>>;
  reviewType?: InputMaybe<PropertyReviewType>;
  roomTypeId?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  sortRating?: InputMaybe<Array<Scalars["String"]>>;
  travelCompanion?: InputMaybe<Array<Scalars["String"]>>;
  travelerType?: InputMaybe<Array<Scalars["String"]>>;
  tripReason?: InputMaybe<Array<Scalars["String"]>>;
}

export type PropertyReviewFlagDialog = LodgingDialog & {
  __typename?: "PropertyReviewFlagDialog";
  content: PropertyReviewFlagDialogContent;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type PropertyReviewFlagDialogContent = {
  __typename?: "PropertyReviewFlagDialogContent";
  communityInfo: Scalars["String"];
  flagOptions: FilterViewModel;
  partnerInfo: Scalars["String"];
  partnerLink: LodgingLinkMessage;
  primaryUIButton: UiPrimaryButton;
};

export type PropertyReviewFooterMessage = {
  __typename?: "PropertyReviewFooterMessage";
  seoStructuredData?: Maybe<SeoStructuredData>;
  text: PropertyReviewFooterMessageText;
};

export type PropertyReviewFooterMessageText = EgdsGraphicText | EgdsPlainText;

export type PropertyReviewFooterSection = {
  __typename?: "PropertyReviewFooterSection";
  messages: Array<PropertyReviewFooterMessage>;
};

export type PropertyReviewInteraction = AccessibilityField & {
  __typename?: "PropertyReviewInteraction";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  feedbackAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  primaryDisplayString?: Maybe<Scalars["String"]>;
  reviewInteractionType: ReviewInteractionType;
};

export type PropertyReviewRatingCount = AccessibilityField & {
  __typename?: "PropertyReviewRatingCount";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  count: FormattedNumber;
  description: Scalars["String"];
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  percent: Scalars["Int"];
  rating: Scalars["Int"];
};

export type PropertyReviewSort =
  | "HIGHEST_TO_LOWEST_RATED"
  | "LOWEST_TO_HIGHEST_RATED"
  | "NEWEST_TO_OLDEST"
  | "NEWEST_TO_OLDEST_BY_LANGUAGE"
  | "OLDEST_TO_NEWEST"
  | "OLDEST_TO_NEWEST_BY_LANGUAGE";

export type PropertyReviewSource = AccessibilityField & {
  __typename?: "PropertyReviewSource";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  graphic?: Maybe<Mark>;
  label?: Maybe<Scalars["String"]>;
  text: LodgingPlainMessage;
};

export type PropertyReviewSummary = AccessibilityField & {
  __typename?: "PropertyReviewSummary";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  amenityScore?: Maybe<FormattedNumber>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  amenityScoreOverMax?: Maybe<Scalars["String"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  amenityScorePercent?: Maybe<Scalars["Int"]>;
  /** @deprecated use averageOverallRatingSection, replace with averageOverallRatingSection */
  averageOverallRating: FormattedNumber;
  averageOverallRatingSection?: Maybe<LodgingEnrichedMessage>;
  /** @deprecated use averageOverallRatingSection, replace with averageOverallRatingSection */
  averageOverallRatingValue?: Maybe<FormattedNumber>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  cleanliness: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  cleanlinessOverMax?: Maybe<Scalars["String"]>;
  /** @deprecated Use localized and generic alternative, replace with reviewSummaryDetails */
  cleanlinessPercent: Scalars["Int"];
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  convenienceOfLocation: FormattedNumber;
  /** @deprecated Deprecating for highlightMessageCard to use the standardized EGDSStandardMessagingCard type, replace with highlightMessageCard */
  highlightMessage?: Maybe<MessageResult>;
  highlightMessageCard?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  hotelCondition: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  hotelConditionOverMax?: Maybe<Scalars["String"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  hotelConditionPercent: Scalars["Int"];
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  lastIndex: Scalars["Int"];
  neighborhoodSatisfaction: FormattedNumber;
  /** @deprecated overall score with description, instead use overallScoreWithDescriptionA11y , replace with overallScoreWithDescriptionA11y */
  overallScoreWithDescription?: Maybe<Scalars["String"]>;
  overallScoreWithDescriptionA11y?: Maybe<LodgingEnrichedMessage>;
  paginateAction?: Maybe<LodgingButton>;
  propertyId: Scalars["String"];
  propertyReviewCountDetails?: Maybe<PropertyReviewCountDetails>;
  ratingCounts?: Maybe<Array<PropertyReviewRatingCount>>;
  /** @deprecated The data is no longer available */
  recommendedPercent: FormattedNumber;
  /** @deprecated This data should be accessed from the propertyReviewCountDetails property, replace with propertyReviewCountDetails */
  reviewCountLocalized?: Maybe<Scalars["String"]>;
  /** @deprecated Use reviewDisclaimerValues for handling further multiline use-cases, replace with reviewDisclaimerValues */
  reviewDisclaimer?: Maybe<Scalars["String"]>;
  reviewDisclaimerAccessibilityLabel?: Maybe<Scalars["String"]>;
  reviewDisclaimerAnalytics?: Maybe<ClientSideAnalytics>;
  reviewDisclaimerHeading?: Maybe<Scalars["String"]>;
  reviewDisclaimerLabel?: Maybe<Scalars["String"]>;
  reviewDisclaimerUrl?: Maybe<LodgingLinkMessage>;
  reviewDisclaimerValues?: Maybe<Array<EgdsStylizedText>>;
  reviewHighlights?: Maybe<PropertyInfoContent>;
  reviewRating?: Maybe<LodgingCardRatingSection>;
  reviewSummaryDetails?: Maybe<Array<ReviewSummaryDetail>>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  roomComfort: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  roomComfortOverMax?: Maybe<Scalars["String"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  roomComfortPercent: Scalars["Int"];
  seeAllAction?: Maybe<PropertyFilteredReviewsDialog>;
  seeAllReviewsCallToActionLabel?: Maybe<Scalars["String"]>;
  seoData: Array<SeoStructuredData>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  serviceAndStaff: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  serviceAndStaffOverMax?: Maybe<Scalars["String"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  serviceAndStaffPercent: Scalars["Int"];
  source?: Maybe<LodgingEnrichedMessage>;
  strategy?: Maybe<ReviewSource>;
  superlative?: Maybe<Scalars["String"]>;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  title?: Maybe<Scalars["String"]>;
  totalCount: FormattedNumber;
  /** @deprecated Raw number only, instead use FormattedNumber in totalCount, replace with totalCount */
  totalReviewCount: Scalars["Int"];
};

export type PropertyReviewTranslationInfo = {
  __typename?: "PropertyReviewTranslationInfo";
  loadingTranslationText: Scalars["String"];
  originalReviewLocale: Scalars["Locale"];
  seeOriginalText: Scalars["String"];
  targetLocale?: Maybe<Scalars["Locale"]>;
  translatedBy: Mark;
  translationCallToActionLabel: Scalars["String"];
};

export type PropertyReviewType = "CRITICAL" | "POSITIVE" | "RECENT";

export type PropertyReviews = AccessibilityField & {
  __typename?: "PropertyReviews";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  impressionAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  noResultsMessage?: Maybe<NoResultsMessage>;
  reviewFlag?: Maybe<ReviewFlag>;
  reviews: Array<PropertyReview>;
  reviewsExpando?: Maybe<EgdsExpandoPeek>;
  sortAndFilter: SortAndFilterViewModel;
  summary: PropertyReviewSummary;
  writeReviewLink?: Maybe<LodgingLinkMessage>;
};

export type PropertyReviewsSummary = {
  __typename?: "PropertyReviewsSummary";
  egdsMark?: Maybe<Mark>;
  localizedScore?: Maybe<Scalars["String"]>;
  /** @deprecated Localized subtitle, instead use localizedSubtitleA11y , replace with localizedSubtitleA11y */
  localizedSubtitle?: Maybe<Scalars["String"]>;
  localizedSubtitleA11y?: Maybe<LodgingEnrichedMessage>;
  localizedTotal?: Maybe<Scalars["String"]>;
  mark?: Maybe<LodgingMark>;
  relevantRecentReview?: Maybe<PropertyReviewContent>;
  score?: Maybe<Scalars["Float"]>;
  scoreDenominator: Scalars["Int"];
  superlative?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type PropertyRoom = {
  __typename?: "PropertyRoom";
  childAges: Array<Scalars["Int"]>;
  numberOfAdults: Scalars["Int"];
};

export type PropertyRoomCard = {
  __typename?: "PropertyRoomCard";
  cancellationPolicy: AgentQuoteSwitchableMessage;
  extras?: Maybe<EgdsTextSection>;
  messages?: Maybe<Array<EgdsText>>;
  priceSummary: AgentQuoteSwitchablePriceSummary;
  propertyAmenities?: Maybe<Array<EgdsIconText>>;
  propertyGallery: Array<Image>;
  propertyName: EgdsHeading;
  propertyRating?: Maybe<EgdsRating>;
  roomFeatures?: Maybe<Array<EgdsIconText>>;
  roomSummary: EgdsTextSection;
};

export interface PropertyRoomInput {
  childAges: Array<Scalars["Int"]>;
  numberOfAdults: Scalars["Int"];
}

export type PropertySeoStructuredData = {
  __typename?: "PropertySEOStructuredData";
  content?: Maybe<Scalars["String"]>;
  itemprop: Scalars["String"];
  itemscope: Scalars["Boolean"];
  itemtype?: Maybe<Scalars["URL"]>;
};

export type PropertySearchCriteria = {
  __typename?: "PropertySearchCriteria";
  primary: PrimaryPropertyCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface PropertySearchCriteriaInput {
  primary: PrimaryPropertyCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type PropertySearchExperimentalSection = LodgingExperimentalSection & {
  __typename?: "PropertySearchExperimentalSection";
  features: Array<LodgingExperimentalMetaData>;
  pageLayoutFeatureExperiment: PageLayoutExperimentalFeature;
};

export type PropertySearchExperimentalSectionFeaturesArgs = {
  createdAfter?: InputMaybe<DateInput>;
  createdBefore?: InputMaybe<DateInput>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  org?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<Scalars["String"]>;
};

export type PropertySearchFilters = {
  __typename?: "PropertySearchFilters";
  accessibility?: Maybe<Array<PropertyFilterAccessibility>>;
  agencyBusinessModels?: Maybe<Array<AgencyBusinessModel>>;
  amenities?: Maybe<Array<PropertyFilterAmenity>>;
  bedroomFilter?: Maybe<Array<Scalars["Int"]>>;
  chain?: Maybe<Scalars["String"]>;
  cleaningAndSafetyPractices?: Maybe<Array<CleaningAndSafetyPractice>>;
  commissionTiers?: Maybe<Array<CommissionTier>>;
  deals?: Maybe<Array<DealType>>;
  mealPlan?: Maybe<Array<MealPlanFilterAmenity>>;
  neighborhood?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<Array<PaymentType>>;
  poi?: Maybe<Scalars["String"]>;
  price?: Maybe<PriceRange>;
  priceBuckets?: Maybe<Array<Scalars["Int"]>>;
  propertyName?: Maybe<Scalars["String"]>;
  propertyStyles?: Maybe<Array<PropertyStyle>>;
  reviewScore?: Maybe<ReviewScoreRange>;
  rewards?: Maybe<Array<Scalars["String"]>>;
  showAvailableOnly?: Maybe<Scalars["Boolean"]>;
  starList?: Maybe<Array<Scalars["Float"]>>;
  stars?: Maybe<StarRange>;
  stayTypes?: Maybe<Array<StayType>>;
  structureTypes?: Maybe<Array<StructureType>>;
  theme?: Maybe<Scalars["String"]>;
  travelerType?: Maybe<Array<TravelerType>>;
  unmappedFilters?: Maybe<Array<UnmappedFilter>>;
  vipOnly?: Maybe<Scalars["Boolean"]>;
};

export interface PropertySearchFiltersInput {
  accessibility?: InputMaybe<Array<PropertyFilterAccessibility>>;
  agencyBusinessModels?: InputMaybe<Array<AgencyBusinessModel>>;
  amenities?: InputMaybe<Array<PropertyFilterAmenity>>;
  bedroomFilter?: InputMaybe<Array<Scalars["Int"]>>;
  chain?: InputMaybe<Scalars["String"]>;
  cleaningAndSafetyPractices?: InputMaybe<Array<CleaningAndSafetyPractice>>;
  commissionTiers?: InputMaybe<Array<CommissionTier>>;
  deals?: InputMaybe<Array<DealType>>;
  mealPlan?: InputMaybe<Array<MealPlanFilterAmenity>>;
  neighborhood?: InputMaybe<Scalars["String"]>;
  paymentType?: InputMaybe<Array<PaymentType>>;
  poi?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<PriceRangeInput>;
  priceBuckets?: InputMaybe<Array<Scalars["Int"]>>;
  propertyName?: InputMaybe<Scalars["String"]>;
  propertyStyles?: InputMaybe<Array<PropertyStyle>>;
  reviewScore?: InputMaybe<ReviewScoreRangeInput>;
  rewards?: InputMaybe<Array<Scalars["String"]>>;
  showAvailableOnly?: InputMaybe<Scalars["Boolean"]>;
  starList?: InputMaybe<Array<Scalars["Float"]>>;
  stars?: InputMaybe<StarRangeInput>;
  stayTypes?: InputMaybe<Array<StayType>>;
  structureTypes?: InputMaybe<Array<StructureType>>;
  theme?: InputMaybe<Scalars["String"]>;
  travelerType?: InputMaybe<Array<TravelerType>>;
  unmappedFilters?: InputMaybe<Array<UnmappedFilterInput>>;
  vipOnly?: InputMaybe<Scalars["Boolean"]>;
}

export type PropertySearchListing =
  | EgdsStandardMessagingCard
  | FlexibleDateSearchCard
  | LodgingCard
  | LodgingHeading
  | LodgingIntentCard
  | LodgingListingDivider
  | LodgingSurveyTrigger
  | MessageResult
  | NestedListingContainer
  | Property
  | PropertyListingsIconTextExpando
  | PropertySearchListingPlaceholder
  | PropertySearchListingsOverfilteredCard
  | PropertySearchListingsSoldOutCard
  | RecentlyViewedPropertiesCarouselWrapper
  | ShoppingListingContainer
  | SponsoredContentPlacement
  | StaticLodgingMultiItemCrossSell;

export type PropertySearchListingPlaceholder = {
  __typename?: "PropertySearchListingPlaceholder";
  loadingComponent: Scalars["String"];
  placeholderMetadata?: Maybe<Array<PropertyListingPlaceholderMetadata>>;
};

export type PropertySearchListingsOverfilteredCard = {
  __typename?: "PropertySearchListingsOverfilteredCard";
  action?: Maybe<LodgingLinkButton>;
  content?: Maybe<EgdsStandardMessagingCard>;
  filterPills?: Maybe<Array<ShoppingAppliedFilter>>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type PropertySearchListingsSoldOutCard = {
  __typename?: "PropertySearchListingsSoldOutCard";
  actionButton?: Maybe<EgdsButton>;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  content?: Maybe<EgdsStandardMessagingCard>;
};

export type PropertySearchMap = {
  __typename?: "PropertySearchMap";
  action?: Maybe<UiPrimaryButton>;
  bounds: Bounds;
  center: Coordinates;
  mapMarkers?: Maybe<Array<LodgingPlottableMapMarker>>;
  markers: Array<Plottable>;
  messagingModel?: Maybe<MessageResult>;
  staticMapSrc?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  zoom: Scalars["Int"];
};

export type PropertySearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PropertySearchMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export interface PropertySearchMultiItemShoppingActionInput {
  shoppingContext: ShoppingContextInput;
}

export type PropertySearchOptions = "ADS_ONLY" | "SHOP_WITH_POINTS";

export type PropertySearchPreloadResults = {
  __typename?: "PropertySearchPreloadResults";
  criteria: PropertySearchCriteria;
};

export type PropertySearchResults = {
  __typename?: "PropertySearchResults";
  /** @deprecated Clickstream adoption is deprecated and unused */
  clickstream: SearchClickstreamEvents;
  criteria: PropertySearchCriteria;
  dialogErrorResponse?: Maybe<LodgingDialogErrorResponse>;
  dynamicMap?: Maybe<EgDynamicMap>;
  errorResponse?: Maybe<LodgingErrorResponse>;
  /** @deprecated This is to support the comet exit intent without impacting the migration effort */
  exitIntent?: Maybe<ExitIntent>;
  /** @deprecated Deprecated. Contents are volatile and subject to change at any moment. Use at your own risk! */
  experimental: PropertySearchExperimentalSection;
  /** @deprecated This is to support the native apps. Do not use, replace with sortAndFilter */
  filterMetadata: PropertyFilterMetadata;
  legalDisclaimer?: Maybe<DisclaimerContentMarkup>;
  /** @deprecated Use dynamicMap instead */
  map?: Maybe<PropertySearchMap>;
  mapTrigger: LodgingMapTrigger;
  multiItemSearchContext?: Maybe<MultiItemSearchContext>;
  navigationTabs?: Maybe<NavigationTabs>;
  pagination?: Maybe<LodgingPagination>;
  /** @deprecated Use propertySearchListings */
  properties: Array<Property>;
  propertyListingAdaptexAnalyticsSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  /** @deprecated For use with the experimental enhanced neighborhood filter experience */
  propertyListingsToast?: Maybe<EgdsToast>;
  propertySearchListings: Array<PropertySearchListing>;
  searchCriteria: SearchCriteria;
  shoppingContext?: Maybe<ShoppingContext>;
  sortAndFilter: SortAndFilter;
  summary: PropertyResultsSummary;
  universalSortAndFilter: ShoppingSortAndFilters;
};

export type PropertySectionHeading = EgdsSectionHeading & {
  __typename?: "PropertySectionHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
};

export type PropertySectionRef =
  | "AVAILABILITY_CALENDAR_START_DATE"
  | "PRICE_DISPLAY_SERVICE_RESPONSE"
  | "RECOMMENDED_OFFER"
  | "ROOMS_AND_RATES";

export type PropertySentiment = {
  __typename?: "PropertySentiment";
  subtext?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type PropertyShopOptions = {
  __typename?: "PropertyShopOptions";
  listings?: Maybe<Listings>;
  paandiRedirection?: Maybe<Scalars["String"]>;
  points?: Maybe<Points>;
  typeaheadRegionType?: Maybe<Scalars["Int"]>;
};

export interface PropertyShopOptionsInput {
  listings?: InputMaybe<Listings>;
  paandiRedirection?: InputMaybe<Scalars["String"]>;
  points?: InputMaybe<Points>;
  typeaheadRegionType?: InputMaybe<Scalars["Int"]>;
}

export type PropertySort =
  | "AARP_MEMBER_DISCOUNT"
  | "BEST_DEAL"
  | "DEPARTURE_EARLY_TO_LATE"
  | "DEPARTURE_LATE_TO_EARLY"
  | "DISTANCE"
  | "DURATION_LONG_TO_SHORT"
  | "DURATION_SHORT_TO_LONG"
  | "MAXIMUM_SAVINGS"
  | "MAXIMUM_SAVINGS_PERCENT"
  | "MEMBER_PRICING"
  | "PACKAGE_SAVINGS"
  | "PRICE_HIGH_TO_LOW"
  | "PRICE_LOW_TO_HIGH"
  | "PRICE_RELEVANT"
  | "PROPERTY_CLASS"
  | "PROPERTY_NAME"
  | "RECOMMENDED"
  | "REVIEW"
  | "REVIEW_COUNT"
  | "REVIEW_PRICE"
  | "REVIEW_RELEVANT"
  | "VACATION_RENTAL";

export type PropertySource = AccessibilityField & {
  __typename?: "PropertySource";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  label?: Maybe<Scalars["String"]>;
  text: LodgingPlainMessage;
};

export type PropertyStarRating = EgdsRating & {
  __typename?: "PropertyStarRating";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated This field will be replaced with ratingIcon field, so we can support more than two icons */
  isStarRating: Scalars["Boolean"];
  rating: Scalars["Float"];
  ratingIcon: Scalars["String"];
};

export type PropertyStyle = "BOUTIQUE" | "HOMES" | "HOTELS" | "LGBT" | "LUXURY" | "OUTDOOR" | "UNIQUE";

export type PropertySummary = {
  __typename?: "PropertySummary";
  amenities?: Maybe<PropertyAmenities>;
  /** @deprecated Deprecating for amenities.top, icons to be passed directly rather than amenity categories, replace with amenities.top */
  amenityCategories: Array<PropertyAmenityCategory>;
  /** @deprecated No longer used, replace with amenities */
  amenitySections: Array<PropertyAmenitySection>;
  /** @deprecated Deprecating for bannerMessageCard for bannerMessageCard that uses the standardized EGDSStandardMessagingCard type, replace with bannerMessageCard */
  bannerMessage?: Maybe<MessageResult>;
  bannerMessageCard?: Maybe<EgdsStandardMessagingCard>;
  cleaningAndSafety?: Maybe<PropertyInfoContent>;
  detailsTitle?: Maybe<Scalars["String"]>;
  featuredMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  fees?: Maybe<PropertyFees>;
  /** @deprecated Intent: Move to Reviews when orchestration permits. */
  findAnswersDialog?: Maybe<FindAnswersDialog>;
  findAnswersMessage?: Maybe<FindAnswersMessage>;
  id: Scalars["String"];
  latinAlphabetName: Scalars["String"];
  location?: Maybe<PropertyLocation>;
  lodgingChatbot?: Maybe<LodgingChatbot>;
  map?: Maybe<PropertyInfoMap>;
  messages: Array<PropertySummaryMessages>;
  name: Scalars["String"];
  navigationBarTabs?: Maybe<Array<PropertyNavigationBarTab>>;
  nearby?: Maybe<PropertyInfoSection>;
  nearbyPOIs?: Maybe<PropertyInfoContent>;
  overview?: Maybe<PropertyOverview>;
  /** @deprecated No longer used, replace with propertyContentSectionGroups.policies */
  policies?: Maybe<PropertyPolicies>;
  propertyHeaderMessages?: Maybe<Array<HeaderMessage>>;
  propertySource?: Maybe<PropertyInfoItem>;
  /** @deprecated Use featuredMessages */
  propertyTypeBadge?: Maybe<Scalars["String"]>;
  /** @deprecated No longer used, replace with propertyContentSectionGroups.policies.sections */
  renovations?: Maybe<PropertyRenovations>;
  spaceOverview?: Maybe<PropertyInfoContent>;
  starRatingIcon?: Maybe<Icon>;
  structure?: Maybe<StructureType>;
  tagline?: Maybe<Scalars["String"]>;
  /** @deprecated No longer used, replace with propertyInfo.telesales */
  telesalesPhoneNumber: Scalars["String"];
  /** @deprecated Only use for TnL 49135 */
  vipAccessMinimizeModule?: Maybe<PropertyLoyaltyMessage>;
};

export type PropertySummaryAmenitiesArgs = {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertySummaryMessages = EgdsPlainText;

export type PropertyTaglineExperimentalFeature = LodgingExperimentalFeature & {
  __typename?: "PropertyTaglineExperimentalFeature";
  description: Scalars["String"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
  propertyTagline?: Maybe<Scalars["String"]>;
};

export type PropertyTakeoverAmenities = {
  __typename?: "PropertyTakeoverAmenities";
  amenityClosures?: Maybe<PropertyInfoContent>;
  highlight: Array<PropertyInfoContent>;
  property: Array<PropertyInfoContent>;
};

export type PropertyTopReviews = {
  __typename?: "PropertyTopReviews";
  critical?: Maybe<PropertyReview>;
  highlighted?: Maybe<LodgingEnrichedMessage>;
  positive?: Maybe<PropertyReview>;
};

export type PropertyTravelAdTrackingInfo = {
  __typename?: "PropertyTravelAdTrackingInfo";
  beaconIssued?: Maybe<Scalars["String"]>;
  candidateHmGuid?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  rank?: Maybe<Scalars["String"]>;
  slots?: Maybe<Scalars["String"]>;
  testVersionOverride?: Maybe<Scalars["String"]>;
  trackingData: Scalars["String"];
};

export interface PropertyTravelAdTrackingInfoInput {
  beaconIssued?: InputMaybe<Scalars["String"]>;
  candidateHmGuid?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  rank?: InputMaybe<Scalars["String"]>;
  slots?: InputMaybe<Scalars["String"]>;
  testVersionOverride?: InputMaybe<Scalars["String"]>;
  trackingData: Scalars["String"];
}

export type PropertyTypesCarouselSection = {
  __typename?: "PropertyTypesCarouselSection";
  carousel: EgdsCarousel;
  heading: EgdsHeading;
};

export type PropertyUnavailableValue = {
  __typename?: "PropertyUnavailableValue";
  code: Scalars["Int"];
  reason: Scalars["String"];
};

export type PropertyUnit = AccessibilityField & {
  __typename?: "PropertyUnit";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  availabilityCallToAction?: Maybe<AvailabilityCallToAction>;
  /** @deprecated This data is now contained in the schema for components where it's used, e.g features, detailsDialog */
  bedOptions: Array<BedOptions>;
  bookingUrl?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  detailsDialog?: Maybe<LodgingDialog>;
  features: Array<PropertyInfoItem>;
  /** @deprecated Deprecated in favor of more flexible PropertyImageGallery type, replace with unitGallery */
  gallery: Array<PropertyImage>;
  header?: Maybe<LodgingHeader>;
  id: Scalars["String"];
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  matchingFilterIds?: Maybe<Array<Scalars["String"]>>;
  maxOccupancy?: Maybe<UnitOccupancy>;
  /** @deprecated Deprecated in favor of more flexible LodgingHeader type, replace with header.text */
  name?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated in favor of more flexible PropertyImageGallery type, replace with unitGallery */
  photos: Array<Image>;
  ratePlans: Array<RatePlan>;
  ratePlansExpando: RatePlansExpando;
  roomAmenities?: Maybe<PropertyContentSection>;
  roomHighlights?: Maybe<PropertyInfoContent>;
  size?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated - use spaceDetails */
  sleepingArrangement?: Maybe<SleepingArrangement>;
  spaceDetails?: Maybe<SpaceDetails>;
  /** @deprecated Deprecated in favor of availabilityCallToAction, replace with availabilityCallToAction */
  unavailableRatePlans: Array<RatePlan>;
  unitGallery?: Maybe<PropertyImageGallery>;
};

export type PropertyUnitCard = {
  __typename?: "PropertyUnitCard";
  agencyPropertyUnitKey: AgencyPropertyUnitKey;
  cancellationPolicy: AgencyQuoteSwitchableMessage;
  detailsLink?: Maybe<EgdsStandardLink>;
  extras?: Maybe<EgdsTextSection>;
  messages?: Maybe<Array<EgdsText>>;
  priceSummary: AgencyQuoteSwitchablePriceSummary;
  propertyAmenities?: Maybe<Array<EgdsIconText>>;
  propertyGallery: Array<Image>;
  propertyName: EgdsHeading;
  propertyRating?: Maybe<EgdsRating>;
  regulatoryMessages?: Maybe<Array<EgdsText>>;
  removeRateLink?: Maybe<EgdsStandardLink>;
  roomFeatures?: Maybe<Array<EgdsIconText>>;
  roomSummary: EgdsTextSection;
  tripSummary?: Maybe<EgdsTextSection>;
};

export type PropertyUnitCardFailure = UnitCard & {
  __typename?: "PropertyUnitCardFailure";
  message: EgdsText;
  removeLink?: Maybe<EgdsStandardLink>;
};

export type PropertyUnitCardSuccess = UnitCard & {
  __typename?: "PropertyUnitCardSuccess";
  agencyPropertyUnitKey: AgencyPropertyUnitKey;
  cancellationPolicy: AgencyQuoteSwitchableMessage;
  confirmationDialog?: Maybe<ConfirmationDialog>;
  detailsLink?: Maybe<EgdsStandardLink>;
  extras?: Maybe<EgdsTextSection>;
  messages?: Maybe<Array<EgdsText>>;
  packageRateDisclaimer?: Maybe<DisclaimerMessage>;
  priceSummary: AgencyQuoteSwitchablePriceSummary;
  propertyAmenities?: Maybe<Array<EgdsIconText>>;
  propertyGallery: Array<Image>;
  propertyName: EgdsHeading;
  propertyRating?: Maybe<EgdsRating>;
  regulatoryMessages?: Maybe<Array<EgdsText>>;
  removeLink?: Maybe<EgdsStandardLink>;
  roomFeatures?: Maybe<Array<EgdsIconText>>;
  roomSummary: EgdsTextSection;
  tripSummary?: Maybe<EgdsTextSection>;
};

export type PropertyUnitDetails = {
  __typename?: "PropertyUnitDetails";
  details?: Maybe<PropertyInfoSection>;
  dialogFeatures?: Maybe<EgdsTextIconList>;
  gallery: Array<PropertyImage>;
  ratePlanStickyBars: Array<RatePlanStickyBar>;
  ratePlanTitle?: Maybe<LodgingHeader>;
  ratePlans: Array<RatePlan>;
  /** @deprecated Sleeping Arrangements details for properties with multiple units. Use PropertyUnit.spaceDetails */
  sleepingArrangement?: Maybe<SleepingArrangement>;
};

export type PropertyUnitDetailsDialog = LodgingDialog & {
  __typename?: "PropertyUnitDetailsDialog";
  content: PropertyUnitDetails;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type PropertyWhatsAround = {
  __typename?: "PropertyWhatsAround";
  editorial?: Maybe<PropertyEditorial>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated The summary structure should be refactored, prevent use that structure on future implementation */
  mapDialog?: Maybe<LodgingMapDialog>;
  mapTrigger?: Maybe<LodgingMapTrigger>;
  nearbyPOIs: Array<PropertyInfoContent>;
  /** @deprecated Replaced by 'mapTrigger' field. */
  staticImage?: Maybe<Image>;
  /** @deprecated Replaced by 'staticImage' field. */
  staticMapSrc?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type QualtricsConfig = {
  __typename?: "QualtricsConfig";
  zoneId: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  SupplyConnectivitySettingProviderService: SupplyConnectivitySettingProviderServiceView;
  SupplyConnectivitySettings: SupplyConnectivitySettingsExperience;
  abandonedCheckout?: Maybe<AbandonedCheckout>;
  accessibilityContent?: Maybe<ContentSectionDetails>;
  activeSessionsForm: ProfileAccountFormComponent;
  activityCrossSellSearch: ActivityCrossSellSearchResults;
  activityInfo: ActivityInfo;
  activityModules: ActivityModulesResponse;
  activityReviews: ActivityReviewsInfo;
  activitySearch: ActivitySearchResults;
  activitySeoContent: ActivitySeoContent;
  advertiserReportingForm: AdvertiserReportingForm;
  affiliatesInitiateAccountLink: AffiliatesInitiateAccountLinkResponse;
  affiliatesStateCheck: AffiliatesStateCheckResponse;
  affiliatesStats: AffiliatesStatsResponse;
  affiliatesStripe?: Maybe<AffiliatesStripeResponse>;
  affiliatesTravelerCollectionDetails: AffiliatesTravelerCollectionDetailsResponse;
  agencyConfirmationDialog: ConfirmationDialog;
  agencyQuoteBuilder: AgencyQuoteBuilderResponse;
  agencyQuoteInfo: AgencyQuoteDetails;
  agencyQuoteList: AgencyQuoteListResponse;
  agentQuoteInfo: AgentQuoteDetails;
  agentToolsLodgingCostBreakdown: AgentToolsLodgingCostBreakdown;
  agentToolsSummaryCard: AgentToolsSummaryCard;
  amenities: ShoppingProductContentSectionGroup;
  annualSummary?: Maybe<AnnualSummaryResult>;
  annualSummaryHome?: Maybe<AnnualSummaryHomeResult>;
  answeringTravellerQuestions?: Maybe<TravellerSearch>;
  aqyilaMetaData: MetadataResponse;
  associatedPropertySearch: SupplyPropertySearchResultContent;
  bestTimeToGo?: Maybe<BestTimeToGo>;
  bookingCredits: BookingServicingCreditsResult;
  bookingServicingAccept: BookingServicingAccept;
  bookingServicingCancel?: Maybe<BookingServiceCancel>;
  bookingServicingChange?: Maybe<BookingServiceChange>;
  bookingServicingChangeLodging?: Maybe<BookingServicingChangeLodging>;
  breadcrumbs: Breadcrumbs;
  bulkAddInventory: SupplyBulkAddInventoryResponse;
  bulkReviewAddInventory: SupplyBulkReviewAddInventoryResponse;
  campaignRecommendations?: Maybe<RecommendedCampaigns>;
  cancellationPolicyInfo?: Maybe<LodgingCancellationPolicyInfo>;
  cancellationWaiverApproval: SupplyCancellationWaiverApprovalSection;
  cancellationWaiverDetails: SupplyCancellationWaiverCardDetails;
  carDetail: CarDetailsResult;
  carDropOffSearch: DropOffSearchResults;
  carRulesAndRestrictions: CarRulesAndRestrictionsResult;
  carSearchOrRecommendations: CarsSearchResultsWrapper;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  cart: Cart;
  changeOffers: ChangeOfferDetails;
  chatbotConfig?: Maybe<ChatbotConfig>;
  checkZuora: MessageResponse;
  checkout: CheckoutDetails;
  checkoutAccountCreation: CheckoutAccountCreationResponse;
  clickToClaimToast?: Maybe<ClickToClaimToast>;
  coBrandBanner: CoBrandBannerData;
  collectionFlightsCarousel: IncentivesCollection;
  collectionLodgingCarousel: IncentivesCollection;
  communicationCenter: CommunicationCenterQuery;
  communicationCenterEntryPoint: CommunicationCenterEntryPoint;
  communicationCenterPreview: CommunicationCenterPreview;
  comparableDeals: ComparableDealsResultsWrapper;
  confirmationSummary: ConfirmationSummary;
  consentMgmtPage: ConsentMgmt;
  contactHost?: Maybe<ContactHostSection>;
  contactUsOptions: ContactUsView;
  contractTerms: SupplyContractTerms;
  couponRedemption: CouponRedemption;
  couponsAndCredits: CouponsAndCredits;
  creditCardApplicationForm?: Maybe<CreditCardApplicationForm>;
  creditCardApplicationFormSdk?: Maybe<CreditCardApplicationFormSdk>;
  creditCardApplicationOfferScreen?: Maybe<CreditCardApplicationOfferScreen>;
  creditCardApplicationPlacement?: Maybe<CreditCardApplicationPlacement>;
  creditCardApplicationSdkEarlyExitDialogs: Array<CreditCardApplicationSdkEarlyExitDialog>;
  creditCardApplicationSdkHeaders: Array<CreditCardApplicationSdkHeader>;
  creditCardApplicationThankYouScreen: CreditCardApplicationThankYouScreen;
  creditCardAwarenessPlacement?: Maybe<CreditCardAwarenessPlacement>;
  creditCardBenefitsSection?: Maybe<CreditCardBenefitsSection>;
  creditCardComparisonHeader?: Maybe<CreditCardComparisonHeader>;
  creditCardComparisonTableSection?: Maybe<CreditCardComparisonTableSection>;
  creditCardEncodedContent?: Maybe<CreditCardEncodedContent>;
  creditCardErrorScreen?: Maybe<CreditCardErrorScreen>;
  creditCardFAQSection: CreditCardFaqSection;
  creditCardFraudOTPScreen?: Maybe<CreditCardFraudOtpScreen>;
  creditCardOneKeyCashSection: CreditCardOneKeyCashSection;
  creditCardPromotion?: Maybe<CreditCardPromotionResult>;
  creditCardTermsAndConditions: CreditCardTermsAndConditions;
  creditRedemptionInsurtechOffers?: Maybe<CreditRedemptionInsurtechOffersResponse>;
  crossSell: CrossSellQueries;
  crosslinkList?: Maybe<CrossLinkList>;
  crosslinkLocations?: Maybe<CrossLinkLocations>;
  crosslinksGroups?: Maybe<CrossLinksGroups>;
  cruiseBundleDetails: CruiseBundleDetailsResults;
  cruiseConsultation: CruiseConsultationResults;
  cruiseOrderSummaryRoomUnavailableView: CruiseOrderSummaryRoomUnavailableViewResults;
  cruisePromotionsTermsAndConditionsDetails: CruisePromotionsTermsAndConditionsResult;
  cruiseRates: CruiseOrderSummaryRatesResults;
  cruiseSearch: CruiseSearchResults;
  customer: Customer;
  customerProfile: CustomerProfile;
  dailyWeatherForecast: ForecastsResponseData;
  dashboardHeader: SupplyDashboardHeader;
  deferredPaymentReport?: Maybe<DeferredPaymentReport>;
  deleteAccountForm: ProfileAccountFormComponent;
  destinationBestTimeToGoInformation?: Maybe<DestinationBestTimeToGoInformation>;
  destinationCategory?: Maybe<DestinationRecommendationGroup>;
  destinationExpertTips: DestinationExpertTips;
  destinationHeading?: Maybe<DestinationHeading>;
  destinationHeadingTip?: Maybe<DestinationHeadingTip>;
  destinationHero?: Maybe<TravelExperienceContent>;
  destinationHeroMedia: DestinationHeroMedia;
  destinationInsights: DestinationInsights;
  destinationNeighborhoods?: Maybe<DestinationRecommendationGroup>;
  destinationPriceInsights?: Maybe<DestinationPriceInsights>;
  destinationPropertiesCarousel?: Maybe<LodgingCarouselContainer>;
  destinationRecommendation?: Maybe<DestinationRecommendationGroup>;
  directFeedbackPromptById?: Maybe<DirectFeedbackPrompt>;
  discoverDestinations?: Maybe<DestinationRecommendationGroup>;
  discoveryAlternativeLinkCardGroup: DiscoveryAlternativeLinkCardGroup;
  discoveryOverlayModule: DiscoveryOverlay;
  /** @deprecated Use interactions top level query instead */
  downloadInteractions: Scalars["String"];
  encodeOfferToken: OfferTokenDetails;
  evcManagement: SupplyReservationsEvcManagerContent;
  evcManagementCardData: SupplyReservationsEvcManagerSearchResults;
  evcManagementTableData: SupplyReservationsEvcManagerTable;
  execute_SOCR_ContactUsForm_GRAPH_VIP_V1: Ifj_ContactUsView;
  experienceScoreInfo?: Maybe<ExperienceScoreInfo>;
  exploreAllGuides?: Maybe<DestinationRecommendationGroup>;
  exploreDestination?: Maybe<ExploreResults>;
  fcrHeadsUpQuery: FlightsCreditRedemptionHeadsUpContentResponse;
  flexibleSearch?: Maybe<FlightsFlexibleSearchResponse>;
  flightDestinationInsights: FlightDestinationInsights;
  flightInsights: FlightInsights;
  flightPreferencesForm: ProfileAccountFormComponent;
  flightStatus: FlightStatusQueryResponse;
  flightsAirlineCredits: FlightsAirlineCreditsResponse;
  flightsAncillary: FlightsAncillaryResponse;
  /** @deprecated This is a stop-gap solution for finding the sum total of bag selections on merchandising hub.The ideal approach is to let backend figure out the price of selected bags using identifiers and not expose this operation to client.Refer https://jira.expedia.biz/browse/AIRMRCH-1453 for details. */
  flightsBaggagePriceCalculations: FlightsArithmeticResponse;
  flightsDetail: FlightsInformationResponse;
  flightsFareCalendar?: Maybe<FlightsFareCalendarResponse>;
  flightsFareDetailsQuery: FlightsFareDetailsView;
  flightsListings: FlightsSmartShoppingListingsResults;
  flightsMerchandising: FlightsMerchandisingResponse;
  flightsMerchandisingCheckout: FlightsMerchandisingCheckoutResponse;
  flightsMerchandisingConfirmation: FlightsMerchandisingConfirmationResponse;
  flightsMerchandisingDetailsCard?: Maybe<FlightsMerchandisingDetailsCard>;
  /** @deprecated Placards info will be find on specific queries */
  flightsPlacard: FlightsPlacardResponse;
  flightsRefundCalculatorQuery: FlightsRefundCalculatorView;
  flightsSearch: FlightsSearchResponse;
  flightsSearchMetadata: FlightsMetadata;
  flightsServicing: FlightsServicingResponse;
  flightsServicingConfirmation: FlightsServicingConfirmationResponse;
  flightsUserAuthentication: UserAuthenticationResponse;
  foo: Foo;
  frequentlyAskedQuestions?: Maybe<Array<Maybe<TravelExperienceContent>>>;
  gdsBannerQuery: GdsBannerView;
  geoLocation?: Maybe<GeoLocationResult>;
  getAgentPresence: AgentPresence;
  getAllCampaigns: Array<Campaign>;
  /** @deprecated Improper naming, use evcManagementCardData instead */
  getEvcCardData: SupplyReservationsEvcManagerSearchResults;
  /** @deprecated Improper naming, use evcManagementTableData instead */
  getEvcTableData: SupplyReservationsEvcManagerTable;
  getSupplyDismissibleCard: SupplyDismissibleCard;
  getSupplyOnboardingPageTitle?: Maybe<SupplyOnboardingPageTitle>;
  globalFooter: Footer;
  globalHeader: Header;
  globalInsuranceSearch?: Maybe<InsuranceShoppingResponse>;
  googleOneTap?: Maybe<IdentityResponse>;
  groundTransfersSearch: GroundTransfersSearchResults;
  helloWithGraphQLContextaqyilatransformerexperienceapi: Scalars["String"];
  helloWithGraphQLContextexperienceapiadvertiser: Scalars["String"];
  helloaqyilatransformerexperienceapi: Scalars["String"];
  helloexperienceapiadvertiser: Scalars["String"];
  helpCenterPersonalization: HelpCenterComponentsView;
  hotelDetailsCard: HotelDetailsCard;
  identityLayout: IdentityResponse;
  importantInfo: ImportantInfoDetails;
  incentivesCollection: IncentivesCollection;
  incentivesCollectionView: IncentivesCollectionView;
  incentivesJourney: IncentivesJourney;
  installmentPlan?: Maybe<InstallmentPlanResponse>;
  insuranceBenefitDetails?: Maybe<InsuranceBenefitDetailsQueryResponse>;
  insuranceBookingOverview?: Maybe<InsuranceBookingOverviewResponse>;
  insuranceBookingOverviewError?: Maybe<InsuranceBookingOverviewErrorResponse>;
  insuranceClaimDetails?: Maybe<InsuranceClaimDetailsResponse>;
  insuranceDetailPriming?: Maybe<InsuranceDetailPrimingCard>;
  insuranceFileAClaim?: Maybe<InsuranceFileAClaimResponse>;
  insuranceSearch?: Maybe<InsuranceShoppingResponseOld>;
  insurtechDialog?: Maybe<InsurtechDialog>;
  insurtechPriming: InsurtechPrimingQuery;
  insurtechPrimingCard: InsurtechPrimingCardResponse;
  insurtechProductCollection: InsurtechProductCollection;
  insurtechShopping: InsurtechShoppingResponse;
  /** @deprecated Use interactions top level query instead */
  interactionList: SupplyReservationsInteractionView;
  interactionMessages: SupplyReservationsInteractionMessagesResponse;
  interactions: SupplyReservationsInteractionResponse;
  landingFeaturedSuppliers: LandingFeaturedSuppliers;
  /** @deprecated Only to be used by iOS to move from legacy to bex-api, will be removed in future */
  legacyWidget: WidgetResponse;
  legal: LegalDetails;
  localePreferences: Picker;
  lodgingChangeForm?: Maybe<LodgingChangeForm>;
  lodgingDestination: LodgingDestinationResults;
  lodgingRefundForm?: Maybe<LodgingRefundForm>;
  lodgingTripUpgrades?: Maybe<LodgingTripUpgrades>;
  /** @deprecated Only used for the legacy loyalty experience.  Will be replaced in the near future with the memberWallet query */
  loyalty: Loyalty;
  loyaltyAccountSummary?: Maybe<LoyaltyAccountSummary>;
  loyaltyEarnCalculationDetails?: Maybe<LoyaltyEarnCalculationDetailsCard>;
  loyaltyLegacyOnboarding: Array<LoyaltyLegacyOnboardingScreen>;
  loyaltyRewards: LoyaltyRewardsResults;
  loyaltyRewardsActivity?: Maybe<LoyaltyRewardsActivity>;
  loyaltyTierProgression: LoyaltyTierProgression;
  mapConfig: EgdsDynamicMapConfig;
  memberProfile?: Maybe<MemberProfile>;
  memberWallet: MemberWallet;
  merchCoupons?: Maybe<MerchCoupons>;
  mojoSection?: Maybe<MojoSection>;
  multiItemStepIndicator: StepIndicator;
  nearbyAirportsSuggestion?: Maybe<NearbyAirportsSuggestion>;
  notification: NotificationQuery;
  offersRecommendationByProduct?: Maybe<OffersRecommendationGroup>;
  offersTravelerSelector?: Maybe<OffersTravelerSelector>;
  /** @deprecated Deprecated in favor of supplyOnboardingPropertyRatesExperience */
  onboardingPropertyRatesExperience: SupplyOnboardingPropertyRatesExperience;
  oneKeyCheckoutBurn?: Maybe<OneKeyCheckoutBurn>;
  oneKeyLoyaltyBanner?: Maybe<OneKeyLoyaltyBanner>;
  oneKeyLoyaltyMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  oneKeyOnboarding: Array<OneKeyOnboardingSheet>;
  oneKeyUniversalOnboarding: Array<OneKeyUniversalOnboardingScreen>;
  /** @deprecated Work in progress: Do not use! Still subject to breaking changes */
  packageDestination: PackageDestinationQueries;
  packageDetails: MultiItemDetailsQuery;
  packagePrimers: PackagePrimersQueries;
  packageSearchResults: PackageSearchResultsQueries;
  partnerLoyaltyPoints: PartnerLoyaltyPoints;
  passportsForm: ProfileAccountFormComponent;
  passwordStrengthMeter: PasswordWithStrengthMeter;
  payment: PaymentQuery;
  paymentDetails: SupplyPaymentDetails;
  paymentModule: PaymentModule;
  paymentModuleCardInfo: PaymentCardDetails;
  penaltiesAndRestrictions: PenaltiesAndRestrictions;
  performanceSummary: SupplyPerformanceSummary;
  personalInfoForm: ProfileComponent;
  personalizedModuleList: DiscoveryPersonalizedModuleList;
  personalizedOffersRecommendation?: Maybe<PersonalizedOffersRecommendations>;
  placeCardDetails?: Maybe<PlaceCardDetails>;
  preBundledPackageEntry: PreBundledPackageEntryQueries;
  preBundledPackageSearch: PreBundledPackageSearchResponse;
  preBundledPackageSearchEntry?: Maybe<PreBundledPackageSearchEntryResponse>;
  preBundledPackageSearchEntryCard?: Maybe<PreBundledPackageSearchEntryCardResponse>;
  preBundledPackageSearchForm: PackageSearchForm;
  priceDetails: PriceDetails;
  priceInsights?: Maybe<PriceInsights>;
  priceMatchPromise?: Maybe<PriceMatchPromiseDetails>;
  productGallery?: Maybe<ProductImageGallery>;
  productGroup?: Maybe<GenericProductGroup>;
  productHeadline?: Maybe<ProductHeadline>;
  productHighlights?: Maybe<ProductHighlights>;
  /** @deprecated Only for Hackathon purposes, do not use */
  productListingCarousels?: Maybe<ProductCarouselResults>;
  productNavigationBar?: Maybe<ProductNavigationBar>;
  productQuestions?: Maybe<ProductQuestions>;
  productRatingSummary?: Maybe<ProductRating>;
  productReviewSummary?: Maybe<SummarizedReviews>;
  productShoppingCrossSellDetails?: Maybe<LodgingCarouselContainer>;
  productSummary?: Maybe<ProductDetailsLayout>;
  propertyActivitiesCrossSell?: Maybe<PropertyActivitiesCrossSellDetails>;
  propertyAvailabilityCalendars?: Maybe<Array<AvailabilityCalendar>>;
  propertyCompare?: Maybe<LodgingPropertyCompare>;
  propertyFilter: SupplyPropertyFilterSection;
  propertyFilterIntentSheet: FilterIntentSheet;
  propertyInfo: PropertyInfo;
  propertyOffers?: Maybe<OfferDetails>;
  propertyReview?: Maybe<PropertyReview>;
  propertyReviewAggregates: PropertyReviewAggregates;
  propertyReviewInfo: PropertyReviews;
  propertyReviewSummaries: Array<PropertyReviewSummary>;
  propertySearch: PropertySearchResults;
  propertySpaces?: Maybe<PropertyContentSectionGroup>;
  propertyTopReviews?: Maybe<PropertyTopReviews>;
  propertyTypesCarousel?: Maybe<PropertyTypesCarouselSection>;
  qualtricsConfig?: Maybe<QualtricsConfig>;
  randomAccessOne: RandomAccessOneContentResponse;
  randomAccessOneSetup?: Maybe<RandomAccessOneSetup>;
  reasonCodes: AgentToolsReasonCodes;
  recentActivitiesModule?: Maybe<DiscoveryItemsGroup>;
  recentlyViewedPropertiesCarousel?: Maybe<LodgingCarouselContainer>;
  recommendationCarousel?: Maybe<DestinationChatCarousel>;
  recommendationsModule?: Maybe<DiscoveryItemsGroup>;
  relevantContent?: Maybe<RelevantContent>;
  requestContext: ClientRequestContext;
  reservationDetails: SupplyReservationsDetailsContent;
  reservationModifiers: SupplyReservationModifiers;
  retailTravelAgentNotificationCenter: RetailTravelAgentNotificationResult;
  retailTravelAgentRewards?: Maybe<RetailTravelAgentRewardsResult>;
  reviewQuestions: ReviewsCollectionForm;
  rewardProgramsForm: ProfileAccountFormComponent;
  rulesAndRestrictions: RnRDetails;
  sailingCabinCategories: SailingCabinCategories;
  sailingDeals: SailingDeals;
  sailingDetails: SailingDetailsResults;
  sailingOffers: CruiseOffersResults;
  searchForm: SearchFormResults;
  searchHelpArticles: HelpArticlesResultsView;
  serviceAutomation: ServiceAutomationQuery;
  shopInsurtechOffers: ShopInsurtechOffersResponse;
  shoppingPathPrimers: MultiItemDetailsPrimersQuery;
  shortlist: Shortlist;
  similarDestinations?: Maybe<DestinationRecommendationGroup>;
  /** @deprecated DO NOT USE: TNL 40644 is being rolled-back, clean-up instruction here https://confluence.expedia.biz/display/EWM/Similar+Properties+with+Deals */
  similarPropertiesDeals: SimilarPropertiesDeals;
  siteConfigurations: Array<SiteConfiguration>;
  smartForm: SmartFormQuery;
  sponsoredContent: SponsoredContentQuery;
  sponsoredPropertiesCarousel?: Maybe<LodgingCarouselContainer>;
  staticMultiItemCrossSell: StaticMultiItemCrossSellResponse;
  stepReviewInlineEditingSheet?: Maybe<StepReviewInlineEditingSheet>;
  supplyAmenitiesGroup: SupplyAmenitiesGroup;
  supplyAttributesAdminExperience: SupplyAttributesAdminExperience;
  supplyAutoCancelWaiver: SupplyAutoCancelWaiver;
  supplyBusinessPartnerInformation: SupplyPartnerInformation;
  supplyCancellationWaiverDecline: SupplyCancellationWaiverDeclineFlowContent;
  supplyCoachingActionPanel?: Maybe<SupplyCoachingActionPanel>;
  supplyCoachingTipsBlock: SupplyCoachingTipsBlock;
  supplyCoachingTipsPostponeForm: SupplyCoachingTipPostponeForm;
  supplyContentAdditionalInfo: SupplyContentAdditionalInfo;
  supplyContentHeadlineDescription: SupplyContentHeadlineDescription;
  supplyContentHomePage?: Maybe<SupplyContentHomePage>;
  supplyContentHub?: Maybe<SupplyContentHub>;
  supplyContentHubNavigation?: Maybe<SupplyContentHubNavigation>;
  supplyContentLocationSharing: SupplyContentLocationSharing;
  supplyContentManualTranslationSetting: SupplyContentManualTranslationSettingDialog;
  supplyContentSaveButton: SupplyContentSaveButton;
  supplyContentScore?: Maybe<SupplyContentScoreResp>;
  supplyContentYourProperty: SupplyContentYourProperty;
  supplyContentYourStory: SupplyContentYourStory;
  supplyContractingLicensePlate: SupplyContractingLicensePlate;
  supplyContractingProgramEnrollment: SupplyContractingProgramEnrollment;
  supplyDeleteAccount: SupplyDeleteAccount;
  supplyFees: SupplyFees;
  supplyHelpContactUs: SupplyHelpContactUs;
  supplyHomepage: SupplyHomepage;
  supplyIndirectTax: SupplyIndirectTax;
  supplyLoyalty?: Maybe<SupplyLoyalty>;
  supplyMarketMakerGrid: SupplyMarketMakerGrid;
  supplyMarketplaceFeed: SupplyMarketplaceFeed;
  supplyMultiFactorAuthenticationView: SupplyMultiFactorAuthenticationExperience;
  supplyNavigation: SupplyNavigation;
  supplyNavigationLinkItemBadge: SupplyNavigationItemLinkBadge;
  supplyNavigationNotification: SupplyNavigationNotificationContent;
  supplyNavigationPropertyDetails: SupplyNavigationMenuContent;
  supplyNavigationReservationSearchContent: SupplyNavigationReservationSearchContent;
  supplyNavigationUserImpersonationBanner?: Maybe<SupplyNavigationUserImpersonationBanner>;
  supplyOnboardingAvailability?: Maybe<SupplyOnboardingAvailability>;
  supplyOnboardingNavigationHeader?: Maybe<SupplyOnboardingNavigationHeader>;
  supplyOnboardingPaymentTermsAndConditions: SupplyOnboardingPaymentTermsAndConditionsView;
  supplyOnboardingPropertyRatesExperience: SupplyOnboardingPropertyRatesExperience;
  supplyOnboardingPublishListing?: Maybe<SupplyOnboardingPublishListing>;
  supplyOnboardingReadiness?: Maybe<SupplyOnboardingReadiness>;
  supplyOnboardingReview?: Maybe<SupplyOnboardingReviewExperience>;
  supplyOnboardingTipCard: SupplyOnboardingTipCard;
  supplyOnboardingUnfinishedSteps?: Maybe<StepReviewList>;
  supplyOnboardingWelcome?: Maybe<SupplyOnboardingWelcome>;
  supplyOnboardingWorkFlowNavigation?: Maybe<SupplyOnboardingWorkFlowNavigation>;
  supplyOpportunityHubLicensePlate?: Maybe<SupplyOpportunityHubLicensePlate>;
  supplyPageLevelBanner: SupplyPageLevelBanner;
  supplyPartnerSelect: SupplyPartnerSelectResponse;
  supplyPartnerSignUpEstimateResults: SupplyPartnerSignUpEstimateResults;
  supplyPhotoUploaderExperience: SupplyPhotoUploaderExperience;
  supplyPhotosExperience: SupplyPhotosExperience;
  /** @deprecated Use supplyLoyalty.premierHost instead */
  supplyPremierHost?: Maybe<SupplyPremierHost>;
  supplyProductCreationExperience: SupplyProductCreationExperience;
  supplyProductCreationPreviewRates?: Maybe<SupplyProductCreationPreviewRates>;
  supplyProductCreationPreviewRoomName: SupplyPreviewRoomNameResult;
  supplyProductCreationPricingModel: SupplyProductCreationPricingModel;
  supplyProductCreationRatePlanSideSheet: SupplyProductCreationRatePlanSideSheet;
  supplyProductCreationRoomInfoExperience: SupplyProductCreationRoomInfoExperience;
  supplyProductCreationRoomNameExperience: SupplyProductCreationRoomNameExperience;
  supplyProductCreationTipCard: SupplyProductCreationTipCard;
  supplyPromotionCopyTieredMembersOnlyDeal: SupplyPromotionCreateTieredMembersOnlyDeal;
  supplyPromotionCreateTieredMembersOnlyDeal: SupplyPromotionCreateTieredMembersOnlyDeal;
  supplyPromotionPlayground: SupplyPromotionPlayground;
  supplyPromotionPlaygroundGetBestDiscount: SupplyPromotionPlaygroundResult;
  supplyPromotionStackingOptionsSheet: SupplyPromotionStackingOptionsSheet;
  supplyPromotionUpdateTieredMembersOnlyDeal: SupplyPromotionUpdateTieredMembersOnlyDeal;
  supplyPropertyEditorPage?: Maybe<SupplyPropertyEditorPage>;
  supplyRegulatoryFlow: SupplyRegulatoryFlow;
  supplyRegulatoryFlowThirdPartyValidation: SupplyRegulatoryFlowThirdPartyValidation;
  /** @deprecated Move title to SupplyRegulatoryFlow and deprecated SupplyRegulatoryFlowTitle */
  supplyRegulatoryFlowTitle: SupplyRegulatoryFlowTitle;
  supplyReservationsHeader: SupplyReservationsHeader;
  supplyRoomTypeMapping: SupplyRoomTypeMappingExperience;
  supplyScoreDetails: SupplyScoreDetails;
  supplyScoreExternalModuleOverview: SupplyScoreExternalModuleOverview;
  supplyScoreFactorAuditTrail: SupplyScoreFactorAuditTrail;
  supplyTaxes: SupplyTaxes;
  /** @deprecated use travelTriviaBanner */
  sweepstakesBanner: SweepstakesBannerQuery;
  sweepstakesDashboard: SweepstakesDashboardQuery;
  sweepstakesUserActionHistoryQuery: SweepstakesUserActionHistoryQuery;
  termsAndConditions: TermsAndConditionsContent;
  testAirTopCarriersService: Scalars["String"];
  testAirlineEntityService: Scalars["String"];
  testGaiaServiceTopAirportsWithinLocation: Scalars["String"];
  testGaiaServiceTopRelevantAirports: Scalars["String"];
  /** @deprecated DO NOT USE! For testing only!!! */
  testingQueryOffers?: Maybe<OfferDetails>;
  thingsToDo?: Maybe<DestinationRecommendationGroups>;
  topRatedAttribute?: Maybe<ExperienceScoreInfo>;
  topTasks: SupplyTopTask;
  travelAgentAuthenticationOptions: TravelAgentAuthenticationOptionsResponse;
  travelAgentDashboard?: Maybe<TravelAgentDashboard>;
  /** @deprecated This does not facilitate server-driven UI, not a good basis for future schema. */
  travelAgentSearchWizardConfig?: Maybe<TravelAgentSearchWizardConfig>;
  travelContentEditorial?: Maybe<Array<Maybe<TravelExperienceContent>>>;
  travelExperienceContent?: Maybe<Array<Maybe<TravelExperienceContent>>>;
  travelExperienceManagerContent: TravelExperienceContentResults;
  travelGuideRecommendation?: Maybe<DestinationRecommendationGroup>;
  travelTriviaBanner?: Maybe<TravelTriviaBannerQuery>;
  travelerCoupons: TravelerCoupons;
  travelerSelector?: Maybe<TravelerSelectorForm>;
  travellerSearch?: Maybe<TravellerSearchResultsResponse>;
  trip: Trip;
  tripExperienceTemplate: TripItemTemplateResponse;
  tripItemCommentsAndVotes: TripsUiCommentsAndVotesResponse;
  tripItemContextualCards: TripItemContextualCardsResponse;
  tripItemImageGallery: TripsUiImageGalleryResponse;
  tripItemPrice: TripsUiItemPriceResponse;
  tripItems?: Maybe<TripItemsResponse>;
  tripItinerary: TripsUiItineraryResponse;
  tripManageParticipants: TripsUiManageParticipantsResponse;
  tripMap?: Maybe<TripMapResponse>;
  tripOverviewTabs: TripsUiOverviewTabs;
  tripPlanning: TripsUiPlanningResponse;
  tripSaveItem: TripsUiSaveItemResponse;
  trips: Trips;
  tripsAttachSavings?: Maybe<TripsUiAttachSavings>;
  tripsChatGPTEntrypoint?: Maybe<TripsUiChatGptEntrypointResponse>;
  tripsCollection: TripsCollection;
  tripsMultiItemPurchase?: Maybe<TripsUiAttachSavingsBanner>;
  tripsNotAuthorizedView: TripsNotAuthorizedView;
  tripsReviewCollection: TripsReviewCollectionResponse;
  tripsWebView: TripsWebView;
  triviaHowTo: TriviaHowToQuery;
  tsaForm: ProfileComponent;
  universalProfile: UniversalProfileResponse;
  /** @deprecated Use interactions top level query instead */
  upcomingInteractions: SupplyReservationsInteractionsUpcomingView;
  updateJourneyContinuationId: UpdateJourneyContinuationIdResponse;
  userProfileNextWizard: ProfileWizardComponent;
  vacChatContent?: Maybe<VirtualAgentControlChatContent>;
  vacInboundMessageAndroidParse?: Maybe<VirtualAgentControlAndroidEvent>;
  vacOrchestrator: VacOrchestrator;
  valuePropositionBanner?: Maybe<ValuePropositionBanner>;
  versions: Array<Maybe<Spinner>>;
  virtualAgentControl?: Maybe<VirtualAgentControl>;
  virtualAgentControlConfiguration: VirtualAgentControlConfig;
  virtualAgentControlMessages?: Maybe<VirtualAgentControlMessages>;
  voiceOfTheCustomer: VoiceOfTheCustomer;
  voiceOfTheCustomerOptional?: Maybe<VoiceOfTheCustomer>;
  wishlist?: Maybe<WishlistResult>;
  worker: WorkerInfo;
};

export type QuerySupplyConnectivitySettingProviderServiceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  providerId?: InputMaybe<Scalars["String"]>;
  serviceIds?: InputMaybe<Array<Scalars["String"]>>;
  stepName: Scalars["String"];
};

export type QuerySupplyConnectivitySettingsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryAbandonedCheckoutArgs = {
  context: ContextInput;
};

export type QueryAccessibilityContentArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryActiveSessionsFormArgs = {
  context: ContextInput;
};

export type QueryActivityCrossSellSearchArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
  filters?: InputMaybe<ActivitySearchFiltersInput>;
  pinnedActivityIds?: InputMaybe<Array<Scalars["String"]>>;
  selectedOffers?: InputMaybe<Array<ActivityOfferNaturalKeyInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
};

export type QueryActivityInfoArgs = {
  activityId: Scalars["String"];
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
  detailsViewOptions?: InputMaybe<Array<ActivityDetailViewOptions>>;
  marketing?: InputMaybe<ActivityMarketingInfoInput>;
  offerFilters?: InputMaybe<ActivityOfferFilterInput>;
  priceOptions?: InputMaybe<Array<ActivitySearchOptions>>;
  selectedOffers?: InputMaybe<Array<ActivityOfferNaturalKeyInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
  travelerDetails?: InputMaybe<Array<ActivityTravelerDetailsInput>>;
  viewOptions?: InputMaybe<Array<ActivitySearchOptions>>;
};

export type QueryActivityModulesArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
};

export type QueryActivityReviewsArgs = {
  activityId: Scalars["String"];
  context: ContextInput;
  pagination?: InputMaybe<PaginationInput>;
  selections?: InputMaybe<Array<ActivitySelectedValueInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
};

export type QueryActivitySearchArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
  filters?: InputMaybe<ActivitySearchFiltersInput>;
  groupType?: InputMaybe<Array<ActivityGroupType>>;
  marketing?: InputMaybe<ActivityMarketingInfoInput>;
  pagination?: InputMaybe<PaginationInput>;
  searchOptions?: InputMaybe<Array<ActivitySearchOptions>>;
  searchViewOptions?: InputMaybe<Array<ActivitySearchViewOptions>>;
  sort?: InputMaybe<ActivitySort>;
  travelerInfo?: InputMaybe<ActivityTravelerInfoInput>;
};

export type QueryActivitySeoContentArgs = {
  activityId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  pageType: ActivityPageType;
};

export type QueryAdvertiserReportingFormArgs = {
  reportingContext: AdvertiserReportingContext;
};

export type QueryAffiliatesInitiateAccountLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAffiliatesStateCheckArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAffiliatesStatsArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesStatsRequestInput;
};

export type QueryAffiliatesStripeArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAffiliatesTravelerCollectionDetailsArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesTravelerCollectionDetailsRequestInput;
};

export type QueryAgencyConfirmationDialogArgs = {
  confirmationDialogInput: ConfirmationDialogInput;
  context: ContextInput;
};

export type QueryAgencyQuoteBuilderArgs = {
  context: ContextInput;
};

export type QueryAgencyQuoteInfoArgs = {
  cacheId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  naturalKey?: InputMaybe<AgencyQuoteNaturalKeyInput>;
  quoteId?: InputMaybe<Scalars["String"]>;
};

export type QueryAgencyQuoteListArgs = {
  context: ContextInput;
  retrieveInput: AgencyQuoteListInput;
};

export type QueryAgentQuoteInfoArgs = {
  context: ContextInput;
  naturalKey: AgentQuoteNaturalKeyInput;
};

export type QueryAgentToolsLodgingCostBreakdownArgs = {
  bookingItemId: Scalars["String"];
  changeAction: AgentToolsLodgingActionInput;
  context: ContextInput;
  orderNumber: Scalars["String"];
  penaltyWaiverAmount: Scalars["Float"];
};

export type QueryAgentToolsSummaryCardArgs = {
  bookingItemId?: InputMaybe<Scalars["String"]>;
  componentType: AgentToolsSummaryCardComponentType;
  context: ContextInput;
  futureTravelCredit?: InputMaybe<AgentToolsFutureTravelCreditInput>;
  isHotelDeposit?: InputMaybe<Scalars["Boolean"]>;
  orderNumber?: InputMaybe<Scalars["String"]>;
  paymentAmount?: InputMaybe<Scalars["Float"]>;
  penaltyAmount?: InputMaybe<Scalars["Float"]>;
  refundAmount?: InputMaybe<Scalars["Float"]>;
};

export type QueryAmenitiesArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryAnnualSummaryArgs = {
  context: ContextInput;
};

export type QueryAnnualSummaryHomeArgs = {
  context: ContextInput;
};

export type QueryAnsweringTravellerQuestionsArgs = {
  context: ContextInput;
};

export type QueryAqyilaMetaDataArgs = {
  aqyilaMetaDataRequest: MetaDataRequestInput;
};

export type QueryAssociatedPropertySearchArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  keyword: Scalars["String"];
};

export type QueryBestTimeToGoArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryBookingCreditsArgs = {
  context: ContextInput;
};

export type QueryBookingServicingAcceptArgs = {
  context: ContextInput;
  criteria: BookingServicingAcceptFlightInput;
};

export type QueryBookingServicingCancelArgs = {
  context: ContextInput;
};

export type QueryBookingServicingChangeArgs = {
  context: ContextInput;
};

export type QueryBookingServicingChangeLodgingArgs = {
  context: ContextInput;
};

export type QueryBreadcrumbsArgs = {
  context: ContextInput;
  uriContext: UriContextInput;
};

export type QueryBulkAddInventoryArgs = {
  context: ContextInput;
  opportunityId?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type QueryBulkReviewAddInventoryArgs = {
  addInventoryInput: BulkAddInventoryInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryCampaignRecommendationsArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaignParameters?: InputMaybe<CampaignParametersInput>;
  context: ContextInput;
  destinationId?: InputMaybe<Scalars["String"]>;
  destinationImageDimensions?: InputMaybe<DestinationImageDimensionsInput>;
  displayPropertyCount?: InputMaybe<Scalars["Boolean"]>;
  enableLivePricing?: InputMaybe<Scalars["Boolean"]>;
  enableTripAttach?: InputMaybe<Scalars["Boolean"]>;
  includeDeals?: InputMaybe<Scalars["Boolean"]>;
  maxNumberOfCampaigns?: InputMaybe<Scalars["Int"]>;
  modulePlacement?: InputMaybe<Scalars["String"]>;
  numberOfCampaigns?: InputMaybe<Scalars["Int"]>;
  pageNumber?: InputMaybe<Scalars["Int"]>;
  productType?: InputMaybe<Array<InputMaybe<CampaignProductType>>>;
  recommendationType?: InputMaybe<Array<InputMaybe<RecommendedType>>>;
  referrer?: InputMaybe<Scalars["String"]>;
  userLocation?: InputMaybe<UserLocationInput>;
  userTripState?: InputMaybe<Scalars["String"]>;
};

export type QueryCancellationPolicyInfoArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"];
  referrer?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryCancellationWaiverApprovalArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: SupplyCancellationWaiverReservationContext;
};

export type QueryCancellationWaiverDetailsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: SupplyCancellationWaiverReservationContext;
};

export type QueryCarDetailArgs = {
  carDetailContext: CarDetailContextInput;
  carsDomainRequestContext?: InputMaybe<CarsDomainRequestContextInput>;
  context: ContextInput;
};

export type QueryCarDropOffSearchArgs = {
  context: ContextInput;
  primaryCarSearchCriteria: PrimaryCarCriteriaInput;
  secondaryCriteria: ShoppingSearchCriteriaInput;
};

export type QueryCarRulesAndRestrictionsArgs = {
  carRulesAndRestrictionsCriteria: CarRulesAndRestrictionsCriteriaInput;
  context: ContextInput;
};

export type QueryCarSearchOrRecommendationsArgs = {
  context: ContextInput;
  primaryCarSearchCriteria: PrimaryCarCriteriaInput;
  secondaryCriteria: ShoppingSearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryCartArgs = {
  context: ContextInput;
  id?: InputMaybe<Scalars["UUID"]>;
};

export type QueryChangeOffersArgs = {
  changeType?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  orderLineNumber?: InputMaybe<Scalars["String"]>;
  orderNumber?: InputMaybe<Scalars["String"]>;
  searchCriteria: PropertySearchCriteriaInput;
  tripId: Scalars["String"];
};

export type QueryChatbotConfigArgs = {
  applicationName: Scalars["String"];
  context: ContextInput;
  deviceIdentifiers?: InputMaybe<ChatbotDeviceIdentifiersInput>;
  hotelId?: InputMaybe<Scalars["String"]>;
  intentMessage?: InputMaybe<Array<ChatbotIntentValueInput>>;
  pagePath?: InputMaybe<Scalars["String"]>;
};

export type QueryCheckoutArgs = {
  context: ContextInput;
};

export type QueryCheckoutAccountCreationArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
};

export type QueryClickToClaimToastArgs = {
  context: ContextInput;
  offerCode: Scalars["String"];
};

export type QueryCoBrandBannerArgs = {
  context: ContextInput;
};

export type QueryCollectionFlightsCarouselArgs = {
  collectionId: Scalars["String"];
  context: ContextInput;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext?: InputMaybe<CollectionPaginationContextInput>;
};

export type QueryCollectionLodgingCarouselArgs = {
  collectionId: Scalars["String"];
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  context: ContextInput;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext: CollectionPaginationContextInput;
};

export type QueryCommunicationCenterArgs = {
  context: ContextInput;
};

export type QueryCommunicationCenterEntryPointArgs = {
  context: ContextInput;
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type QueryCommunicationCenterPreviewArgs = {
  context: ContextInput;
  optionalContext?: InputMaybe<OptionalContextInput>;
  selectedTab?: InputMaybe<Scalars["String"]>;
  unseenCountForUnselected?: InputMaybe<Scalars["Int"]>;
};

export type QueryComparableDealsArgs = {
  carOfferToken: Scalars["String"];
  comparableDealsToken: Scalars["String"];
  context: ContextInput;
  primaryCarSearchCriteria: PrimaryCarCriteriaInput;
  secondaryCriteria: ShoppingSearchCriteriaInput;
};

export type QueryConfirmationSummaryArgs = {
  context: ContextInput;
  orderId: Scalars["String"];
};

export type QueryConsentMgmtPageArgs = {
  context: ContextInput;
  needEditorial: Scalars["Boolean"];
};

export type QueryContactHostArgs = {
  context: ContextInput;
  inquiryFormInput?: InputMaybe<InquiryFormInput>;
  prominence?: InputMaybe<InquiryProminence>;
};

export type QueryContactUsOptionsArgs = {
  context: ContextInput;
};

export type QueryContractTermsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryCouponRedemptionArgs = {
  context: ContextInput;
  sessionId: Scalars["String"];
};

export type QueryCouponsAndCreditsArgs = {
  context: ContextInput;
  sourceReferrer?: InputMaybe<Scalars["String"]>;
};

export type QueryCreditCardApplicationFormArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardApplicationFormQueryInput>;
};

export type QueryCreditCardApplicationFormSdkArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  landingUrl?: InputMaybe<Scalars["String"]>;
  sourcePage: CreditCardSourcePage;
};

export type QueryCreditCardApplicationOfferScreenArgs = {
  context: ContextInput;
  input: CreditCardApplicationOfferInput;
};

export type QueryCreditCardApplicationPlacementArgs = {
  context: ContextInput;
};

export type QueryCreditCardApplicationSdkEarlyExitDialogsArgs = {
  context: ContextInput;
  sourcePage: CreditCardSourcePage;
};

export type QueryCreditCardApplicationSdkHeadersArgs = {
  cardType: CreditCardType;
  context: ContextInput;
  sourcePage: CreditCardSourcePage;
};

export type QueryCreditCardApplicationThankYouScreenArgs = {
  context: ContextInput;
  input: CreditCardApplicationThankYouInput;
};

export type QueryCreditCardAwarenessPlacementArgs = {
  context: ContextInput;
};

export type QueryCreditCardBenefitsSectionArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardBenefitsInput>;
};

export type QueryCreditCardComparisonHeaderArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardComparisonHeaderInput>;
};

export type QueryCreditCardComparisonTableSectionArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardComparisonTableInput>;
};

export type QueryCreditCardEncodedContentArgs = {
  context: ContextInput;
  input: CreditCardEncodedContentInput;
};

export type QueryCreditCardErrorScreenArgs = {
  context: ContextInput;
  input: CreditCardErrorScreenInput;
};

export type QueryCreditCardFaqSectionArgs = {
  context: ContextInput;
};

export type QueryCreditCardFraudOtpScreenArgs = {
  context: ContextInput;
  input: CreditCardFraudOtpInput;
};

export type QueryCreditCardOneKeyCashSectionArgs = {
  context: ContextInput;
};

export type QueryCreditCardPromotionArgs = {
  actionType: CreditCardApplyButtonActionType;
  context: ContextInput;
};

export type QueryCreditCardTermsAndConditionsArgs = {
  context: ContextInput;
  input: CreditCardEncodedContentInput;
};

export type QueryCreditRedemptionInsurtechOffersArgs = {
  clientId: InsurtechClientId;
  context: ContextInput;
  residencyInformation: ResidencyDetailsInput;
  tripId: Scalars["String"];
};

export type QueryCrossSellArgs = {
  context: ContextInput;
  searchContext: MultiItemSearchContextInput;
  selectedProducts?: InputMaybe<MultiItemProductsInput>;
};

export type QueryCrosslinkListArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  parameters?: InputMaybe<Array<CrossLinksParameterInput>>;
  strategyName: Scalars["String"];
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryCrosslinkLocationsArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  parameters?: InputMaybe<Array<CrossLinksParameterInput>>;
  strategyName: Scalars["String"];
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryCrosslinksGroupsArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  parameters?: InputMaybe<Array<CrossLinksParameterInput>>;
  strategyName: Scalars["String"];
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryCruiseBundleDetailsArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  promotionCodes: Array<Scalars["String"]>;
};

export type QueryCruiseConsultationArgs = {
  adultCount?: InputMaybe<Scalars["Int"]>;
  cabinType?: InputMaybe<Scalars["String"]>;
  childCount?: InputMaybe<Scalars["Int"]>;
  context: ContextInput;
  cruiseConsultationFormInput?: InputMaybe<CruiseConsultationFormInput>;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingToken?: InputMaybe<Scalars["String"]>;
};

export type QueryCruiseOrderSummaryRoomUnavailableViewArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  orderSummaryRatesInput: CruiseOrderSummaryRatesInput;
};

export type QueryCruisePromotionsTermsAndConditionsDetailsArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  promotionCodesInput: Array<Scalars["String"]>;
};

export type QueryCruiseRatesArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  orderSummaryRatesInput: CruiseOrderSummaryRatesInput;
  pageDataInput?: InputMaybe<CruiseOrderSummaryRatesPageDataInput>;
};

export type QueryCruiseSearchArgs = {
  context?: InputMaybe<ContextInput>;
  searchInput: CruiseSearchCriteriaInput;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortOrder?: InputMaybe<Scalars["String"]>;
};

export type QueryCustomerArgs = {
  context: ContextInput;
  semdtl?: InputMaybe<Scalars["String"]>;
};

export type QueryCustomerProfileArgs = {
  context: ContextInput;
};

export type QueryDailyWeatherForecastArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<DateRangeInput>;
  regionId: Scalars["String"];
  unit?: InputMaybe<Scalars["String"]>;
};

export type QueryDashboardHeaderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryDeferredPaymentReportArgs = {
  context: ContextInput;
};

export type QueryDeleteAccountFormArgs = {
  context: ContextInput;
};

export type QueryDestinationBestTimeToGoInformationArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryDestinationCategoryArgs = {
  categoryId: Scalars["String"];
  context: ContextInput;
};

export type QueryDestinationExpertTipsArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryDestinationHeadingArgs = {
  context: ContextInput;
  headingCriteria: DestinationHeadingCriteriaInput;
};

export type QueryDestinationHeadingTipArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryDestinationHeroArgs = {
  context: ContextInput;
  travelContentAttributes?: InputMaybe<TravelContentAttributesInput>;
  uriContext: UriContextInput;
};

export type QueryDestinationHeroMediaArgs = {
  context: ContextInput;
  destinationHeroMediaCriteria: DestinationHeroMediaCriteriaInput;
};

export type QueryDestinationInsightsArgs = {
  context: ContextInput;
  regionId: Scalars["String"];
};

export type QueryDestinationNeighborhoodsArgs = {
  context: ContextInput;
  destinationNeighborhoodsContextInput?: InputMaybe<DestinationNeighborhoodsContextInput>;
};

export type QueryDestinationPriceInsightsArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryDestinationPropertiesCarouselArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  dataType?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type QueryDestinationRecommendationArgs = {
  context: ContextInput;
  recommendationCriteria: DestinationRecommendationCriteriaInput;
};

export type QueryDirectFeedbackPromptByIdArgs = {
  context: ContextInput;
  id: Scalars["ID"];
};

export type QueryDiscoverDestinationsArgs = {
  context: ContextInput;
};

export type QueryDiscoveryAlternativeLinkCardGroupArgs = {
  context: ContextInput;
  discoveryAlternativeLinkCardCriteria: DiscoveryAlternativeLinkCardCriteriaInput;
};

export type QueryDiscoveryOverlayModuleArgs = {
  context: ContextInput;
  placeId?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Scalars["String"]>;
};

export type QueryDownloadInteractionsArgs = {
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type QueryEncodeOfferTokenArgs = {
  propertyId: Scalars["String"];
  ratePlanId: Scalars["String"];
  startDaysInAdvance: Scalars["Int"];
  unitTypeId: Scalars["String"];
};

export type QueryEvcManagementArgs = {
  context: ContextInput;
  defaultView?: InputMaybe<SupplyReservationsEvcManagerTabType>;
  evcSearchInput?: InputMaybe<SupplyReservationsEvcSearchInput>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QueryEvcManagementCardDataArgs = {
  context: ContextInput;
  evcSearchInput: SupplyReservationsEvcSearchInput;
};

export type QueryEvcManagementTableDataArgs = {
  context: ContextInput;
  paginationInput?: InputMaybe<SupplyReservationsEvcSearchPageInput>;
  propertyContext: PropertyContext;
  tableType?: InputMaybe<SupplyReservationsEvcManagerTableType>;
};

export type QueryExecute_Socr_ContactUsForm_Graph_Vip_V1Args = {
  articleIntent?: InputMaybe<Scalars["String"]>;
  articleProduct?: InputMaybe<Scalars["String"]>;
  context: Ifj_ContextInput;
  journeyId?: InputMaybe<Scalars["ID"]>;
  partnerId?: InputMaybe<Scalars["String"]>;
};

export type QueryExperienceScoreInfoArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryExploreAllGuidesArgs = {
  context: ContextInput;
};

export type QueryExploreDestinationArgs = {
  context: ContextInput;
  destinationCriteria: ExploreDestinationCriteriaInput;
};

export type QueryFcrHeadsUpQueryArgs = {
  context: ContextInput;
  flightsCreditRedemptionHeadsUpInput: FlightsCreditRedemptionHeadsUpInput;
};

export type QueryFlexibleSearchArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  rewardsOption?: InputMaybe<RewardsOption>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightDestinationInsightsArgs = {
  context: ContextInput;
  origin: InsightsLocationInput;
};

export type QueryFlightInsightsArgs = {
  context: ContextInput;
  destination: InsightsLocationInput;
  origin: InsightsLocationInput;
};

export type QueryFlightPreferencesFormArgs = {
  context: ContextInput;
};

export type QueryFlightStatusArgs = {
  context: ContextInput;
  flightStatusInput: FlightStatusInput;
};

export type QueryFlightsAirlineCreditsArgs = {
  context: ContextInput;
  flightsSearchContext?: InputMaybe<FlightsSearchContextInput>;
  journeyCriteria?: InputMaybe<Array<FlightsJourneyCriteriaInput>>;
  originalBookingId?: InputMaybe<Scalars["String"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelerDetails?: InputMaybe<Array<FlightsTravelerDetailsInput>>;
};

export type QueryFlightsAncillaryArgs = {
  ancillaryCriteria: FlightsAncillaryCriteriaInput;
  context: ContextInput;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsBaggagePriceCalculationsArgs = {
  arithmeticOperationsCriteria: ArithmeticOperationsCriteriaInput;
  context: ContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsDetailArgs = {
  context: ContextInput;
  flightsDetailComponentsCriteria?: InputMaybe<FlightsDetailComponentsCriteriaInput>;
  flightsDetailCriteria?: InputMaybe<FlightsDetailCriteriaInput>;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  sideSheetQueryContext?: InputMaybe<SideSheetQueryContextInput>;
  virtualAgentContext?: InputMaybe<VirtualAgentContextInput>;
};

export type QueryFlightsFareCalendarArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsFareDetailsQueryArgs = {
  context: ContextInput;
  flightsFareDetailsInput: FlightsFareDetailsInput;
};

export type QueryFlightsListingsArgs = {
  context: ContextInput;
  flightSearchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  requestToast?: InputMaybe<FlightsFilterType>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  shoppingSearchCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
  sortOption?: InputMaybe<FlightsSortOptionTypeInput>;
  travelerDetails: Array<FlightsSmartShoppingTravelerDetailsInput>;
};

export type QueryFlightsMerchandisingArgs = {
  ancillaryCriteria: FlightsAncillaryCriteriaInput;
  context: ContextInput;
  queryState?: InputMaybe<FlightsQueryState>;
  requestedAncillaries?: InputMaybe<Array<FlightsAncillaryType>>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripInfoData?: InputMaybe<FlightTripInfoDataInput>;
};

export type QueryFlightsMerchandisingCheckoutArgs = {
  context: ContextInput;
  merchandisingId?: InputMaybe<Scalars["String"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"];
};

export type QueryFlightsMerchandisingConfirmationArgs = {
  context: ContextInput;
  merchandisingId?: InputMaybe<Scalars["String"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"];
};

export type QueryFlightsMerchandisingDetailsCardArgs = {
  context: ContextInput;
  merchandisingDetailsCardPath: MerchandisingDetailsCardPath;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"];
};

export type QueryFlightsPlacardArgs = {
  context: ContextInput;
  flightsDetailComponentsCriteria?: InputMaybe<FlightsDetailComponentsCriteriaInput>;
  flightsDetailCriteria?: InputMaybe<FlightsDetailCriteriaInput>;
  flightsPlacardsTypeList: Array<FlightsPlacardType>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsRefundCalculatorQueryArgs = {
  context: ContextInput;
  flightsRefundCalculatorInput: FlightsRefundCalculatorInput;
};

export type QueryFlightsSearchArgs = {
  context: ContextInput;
  fareCalendarPrice?: InputMaybe<Scalars["String"]>;
  faresSeparationType?: InputMaybe<FlightsFaresSeparationType>;
  flightsDevicePermissionsInput?: InputMaybe<FlightsDevicePermissionsInput>;
  flightsDisplayComponentValues?: InputMaybe<FlightsDisplayComponentValuesInput>;
  flightsListingsViewType?: InputMaybe<FlightsListingsViewType>;
  flightsSearchComponentCriteria?: InputMaybe<FlightsSearchComponentCriteriaInput>;
  flightsSearchContext?: InputMaybe<FlightsSearchContextInput>;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  rewardsOption?: InputMaybe<RewardsOption>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPagination?: InputMaybe<PaginationInput>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  shoppingSearchCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
  sideSheetQueryContext?: InputMaybe<SideSheetQueryContextInput>;
  similarFlightsContext?: InputMaybe<SimilarFlightsContextInput>;
  sortOption?: InputMaybe<FlightsSortOptionTypeInput>;
  toastContext?: InputMaybe<FlightsToastContextInput>;
  travelerDetails?: InputMaybe<Array<FlightsTravelerDetailsInput>>;
  virtualAgentContext?: InputMaybe<VirtualAgentContextInput>;
};

export type QueryFlightsSearchMetadataArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: FlightsJourneyCriteriaInput;
};

export type QueryFlightsServicingArgs = {
  context: ContextInput;
  flightsServicingCriteria: FlightsServicingCriteriaInput;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  virtualAgentContext?: InputMaybe<VirtualAgentContextInput>;
};

export type QueryFlightsServicingConfirmationArgs = {
  context: ContextInput;
  flightsServicingConfirmationCriteria: FlightsServicingConfirmationCriteriaInput;
  queryState?: InputMaybe<FlightsQueryState>;
};

export type QueryFlightsUserAuthenticationArgs = {
  context: ContextInput;
  shoppingPath: Scalars["String"];
  tripIdentifier: Scalars["String"];
};

export type QueryFrequentlyAskedQuestionsArgs = {
  contentTopic?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  limit: Scalars["Int"];
  uriContext?: InputMaybe<TravelContentUriContextInput>;
  uriContextV1: UriContextInput;
};

export type QueryGdsBannerQueryArgs = {
  context: ContextInput;
  gdsBannerInput: GdsBannerInput;
};

export type QueryGeoLocationArgs = {
  context: ContextInput;
  coordinates?: InputMaybe<CoordinatesInput>;
};

export type QueryGetAgentPresenceArgs = {
  context: ContextInput;
  flavor: FlavorType;
  partnerId: Scalars["String"];
  roleId: Scalars["String"];
  userId: Scalars["String"];
};

export type QueryGetEvcCardDataArgs = {
  context: ContextInput;
  evcSearchInput: SupplyReservationsEvcSearchInput;
};

export type QueryGetEvcTableDataArgs = {
  context: ContextInput;
  paginationInput?: InputMaybe<SupplyReservationsEvcSearchPageInput>;
  tableType?: InputMaybe<SupplyReservationsEvcManagerTableType>;
};

export type QueryGetSupplyDismissibleCardArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryGetSupplyOnboardingPageTitleArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryGlobalFooterArgs = {
  context: ContextInput;
  minimal?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGlobalHeaderArgs = {
  context: ContextInput;
  layout?: InputMaybe<HeaderLayout>;
  lineOfBusiness?: InputMaybe<GlobalNavigationLineOfBusiness>;
  marketing?: InputMaybe<HeaderMarketingInput>;
  minimal?: InputMaybe<Scalars["Boolean"]>;
  theme?: InputMaybe<Scalars["String"]>;
  viewSize?: InputMaybe<HeaderViewSize>;
};

export type QueryGlobalInsuranceSearchArgs = {
  context: ContextInput;
  insuranceShoppingRequest: InsuranceShoppingRequestInput;
};

export type QueryGoogleOneTapArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
};

export type QueryGroundTransfersSearchArgs = {
  context: ContextInput;
  searchCriteria: GroundTransfersSearchCriteriaInput;
};

export type QueryHelloWithGraphQlContextaqyilatransformerexperienceapiArgs = {
  context: ContextInput;
};

export type QueryHelloWithGraphQlContextexperienceapiadvertiserArgs = {
  context: ContextInput;
};

export type QueryHelpCenterPersonalizationArgs = {
  context: ContextInput;
};

export type QueryHotelDetailsCardArgs = {
  bookingItemId: Scalars["String"];
  context: ContextInput;
  orderNumber: Scalars["String"];
};

export type QueryIdentityLayoutArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  layout: IdentityLayout;
  pageLocation?: InputMaybe<PageLocation>;
};

export type QueryImportantInfoArgs = {
  cartId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  sessionId?: InputMaybe<Scalars["String"]>;
};

export type QueryIncentivesCollectionArgs = {
  collectionId: Scalars["String"];
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  context: ContextInput;
  expectedCollectionType?: InputMaybe<CollectionType>;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext?: InputMaybe<CollectionPaginationContextInput>;
};

export type QueryIncentivesCollectionViewArgs = {
  collectionId: Scalars["String"];
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  context: ContextInput;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext?: InputMaybe<CollectionPaginationContextInput>;
};

export type QueryIncentivesJourneyArgs = {
  context: ContextInput;
  selectedIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryInstallmentPlanArgs = {
  context: ContextInput;
  installmentPriceAmount?: InputMaybe<Scalars["String"]>;
  multiItemSessionId?: InputMaybe<Scalars["String"]>;
};

export type QueryInsuranceBenefitDetailsArgs = {
  context: ContextInput;
  insuranceBenefitDetailsRequest: InsuranceBenefitDetailsQueryRequestInput;
};

export type QueryInsuranceBookingOverviewArgs = {
  context: ContextInput;
  insuranceBookingOverviewRequest: InsuranceBookingOverviewRequestInput;
};

export type QueryInsuranceBookingOverviewErrorArgs = {
  context: ContextInput;
};

export type QueryInsuranceClaimDetailsArgs = {
  context: ContextInput;
  insuranceClaimDetailsRequest: InsuranceClaimDetailsRequestInput;
};

export type QueryInsuranceDetailPrimingArgs = {
  context: ContextInput;
  insuranceDetailPrimingRequest: InsuranceDetailPrimingRequestInput;
};

export type QueryInsuranceFileAClaimArgs = {
  context: ContextInput;
  insuranceFileAClaimRequest: InsuranceFileAClaimRequestInput;
};

export type QueryInsuranceSearchArgs = {
  context: ContextInput;
  insuranceShoppingRequest: InsuranceShoppingRequestInput;
};

export type QueryInsurtechDialogArgs = {
  context: ContextInput;
  dialogId: Scalars["String"];
};

export type QueryInsurtechPrimingArgs = {
  context: ContextInput;
};

export type QueryInsurtechPrimingCardArgs = {
  context: ContextInput;
  insurtechPrimingCardRequest: InsurtechPrimingCardRequestInput;
};

export type QueryInsurtechProductCollectionArgs = {
  context: ContextInput;
};

export type QueryInsurtechShoppingArgs = {
  context: ContextInput;
  placement: InsurTechComponentPlacementInput;
  shoppingCriteria: InsurtechShoppingCriteriaInput;
};

export type QueryInteractionListArgs = {
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type QueryInteractionMessagesArgs = {
  clientData?: InputMaybe<SupplyReservationsInteractionMessagesClientData>;
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext?: InputMaybe<ReservationContext>;
};

export type QueryInteractionsArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type QueryLandingFeaturedSuppliersArgs = {
  context: ContextInput;
  uriContext: UriContextInput;
};

export type QueryLegacyWidgetArgs = {
  context: ContextInput;
};

export type QueryLegalArgs = {
  cartId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  messageId?: InputMaybe<Scalars["String"]>;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type QueryLocalePreferencesArgs = {
  context: ContextInput;
  lineOfBusiness?: InputMaybe<GlobalNavigationLineOfBusiness>;
  pickerTriggerPlacementType?: InputMaybe<PickerTriggerPlacementType>;
  selectedSiteId?: InputMaybe<Scalars["Int"]>;
};

export type QueryLodgingChangeFormArgs = {
  bookingItemId: Scalars["String"];
  context: ContextInput;
  orderNumber: Scalars["String"];
};

export type QueryLodgingDestinationArgs = {
  context: ContextInput;
  destination: DestinationInput;
  filters: PropertySearchFiltersInput;
};

export type QueryLodgingRefundFormArgs = {
  bookingItemId: Scalars["String"];
  context: ContextInput;
  orderNumber: Scalars["String"];
};

export type QueryLodgingTripUpgradesArgs = {
  context: ContextInput;
  itineraryNumber?: InputMaybe<Scalars["String"]>;
};

export type QueryLoyaltyArgs = {
  context: ContextInput;
};

export type QueryLoyaltyAccountSummaryArgs = {
  context: ContextInput;
  strategy: LoyaltyExperienceStrategy;
  viewId?: InputMaybe<Scalars["String"]>;
};

export type QueryLoyaltyEarnCalculationDetailsArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  loyaltyDomainId?: InputMaybe<Scalars["String"]>;
};

export type QueryLoyaltyLegacyOnboardingArgs = {
  context: ContextInput;
};

export type QueryLoyaltyRewardsArgs = {
  context: ContextInput;
};

export type QueryLoyaltyRewardsActivityArgs = {
  context: ContextInput;
  rewardFilterSelection?: InputMaybe<Scalars["String"]>;
  useHardCodedTestAccount?: InputMaybe<Scalars["Boolean"]>;
  useMockData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryLoyaltyTierProgressionArgs = {
  context: ContextInput;
};

export type QueryMapConfigArgs = {
  context: ContextInput;
  pageId?: InputMaybe<Scalars["String"]>;
  pageLocation: PageLocation;
};

export type QueryMemberProfileArgs = {
  context: ContextInput;
};

export type QueryMemberWalletArgs = {
  context: ContextInput;
};

export type QueryMerchCouponsArgs = {
  context: ContextInput;
  couponIds: Array<Scalars["String"]>;
};

export type QueryMojoSectionArgs = {
  context: ContextInput;
  mojoContext: MojoContextInput;
};

export type QueryMultiItemStepIndicatorArgs = {
  additionalChangeQueryParams?: InputMaybe<AdditionalChangeQueryParamsInput>;
  context: ContextInput;
  journeyContinuationId?: InputMaybe<Scalars["String"]>;
  lockupPrice?: InputMaybe<MoneyInput>;
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  multiItemSessionId?: InputMaybe<Scalars["String"]>;
  originalBookingId?: InputMaybe<Scalars["String"]>;
  savingsPrice?: InputMaybe<MoneyInput>;
};

export type QueryNearbyAirportsSuggestionArgs = {
  context: ContextInput;
  sessionId: Scalars["String"];
};

export type QueryNotificationArgs = {
  context: ContextInput;
};

export type QueryOffersRecommendationByProductArgs = {
  availabilityFilter?: InputMaybe<OffersRecommendationAvailabilityFilterInput>;
  containerType?: InputMaybe<OffersRecommendationContainerType>;
  context: ContextInput;
  identifier?: InputMaybe<OffersRecommendationProductIdentifierInput>;
  outputRecommendationProductLine?: InputMaybe<LineOfBusinessDomain>;
  productId?: InputMaybe<Scalars["String"]>;
  productLine?: InputMaybe<LineOfBusinessDomain>;
  strategy?: InputMaybe<OffersRecommendationStrategy>;
};

export type QueryOffersTravelerSelectorArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"];
  referrer?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryOnboardingPropertyRatesExperienceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryOneKeyCheckoutBurnArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  oneKeyCashApplied?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryOneKeyLoyaltyBannerArgs = {
  context: ContextInput;
  lineOfBusiness: LineOfBusinessDomain;
  marketing?: InputMaybe<MarketingInfoInput>;
  packageLOB?: InputMaybe<LineOfBusinessDomain>;
  packageType?: InputMaybe<PackageType>;
  pageLocation: PageLocation;
  useLoyaltyCurrency?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryOneKeyLoyaltyMessagingCardArgs = {
  context: ContextInput;
  lineOfBusiness: LineOfBusinessDomain;
  pageLocation: PageLocation;
  useLoyaltyCurrency?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryOneKeyOnboardingArgs = {
  additionalContext?: InputMaybe<AdditionalOnboardingContextInput>;
  context: ContextInput;
};

export type QueryOneKeyUniversalOnboardingArgs = {
  context: ContextInput;
};

export type QueryPackageDestinationArgs = {
  context: ContextInput;
  regionId: Scalars["String"];
};

export type QueryPackageDetailsArgs = {
  context: ContextInput;
};

export type QueryPackagePrimersArgs = {
  context: ContextInput;
};

export type QueryPackageSearchResultsArgs = {
  context: ContextInput;
};

export type QueryPartnerLoyaltyPointsArgs = {
  context: ContextInput;
};

export type QueryPassportsFormArgs = {
  context: ContextInput;
  formContext?: InputMaybe<Scalars["String"]>;
};

export type QueryPasswordStrengthMeterArgs = {
  context: ContextInput;
};

export type QueryPaymentArgs = {
  context: ContextInput;
};

export type QueryPaymentDetailsArgs = {
  bookingId: Scalars["String"];
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryPaymentModuleArgs = {
  context?: InputMaybe<ContextInput>;
  paymentSessionId: Scalars["String"];
  sessionId?: InputMaybe<Scalars["String"]>;
};

export type QueryPaymentModuleCardInfoArgs = {
  cardIssueBankNumber: Scalars["String"];
};

export type QueryPenaltiesAndRestrictionsArgs = {
  bookingItemId: Scalars["String"];
  context: ContextInput;
  orderNumber: Scalars["String"];
};

export type QueryPerformanceSummaryArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryPersonalInfoFormArgs = {
  context: ContextInput;
};

export type QueryPersonalizedModuleListArgs = {
  contentSize: DiscoveryCardContentSize;
  context: ContextInput;
  orderId?: InputMaybe<Scalars["String"]>;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type QueryPersonalizedOffersRecommendationArgs = {
  context: ContextInput;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type QueryPlaceCardDetailsArgs = {
  context: ContextInput;
  directionsTo?: InputMaybe<DirectionsReferenceInput>;
  placeId: Scalars["String"];
};

export type QueryPreBundledPackageEntryArgs = {
  context: ContextInput;
};

export type QueryPreBundledPackageSearchArgs = {
  context: ContextInput;
  searchCriteria: PackageSearchCriteriaInput;
  sort?: InputMaybe<PackageSort>;
};

export type QueryPreBundledPackageSearchEntryArgs = {
  context: ContextInput;
  sessionId: Scalars["String"];
};

export type QueryPreBundledPackageSearchEntryCardArgs = {
  context: ContextInput;
  sessionId: Scalars["String"];
};

export type QueryPreBundledPackageSearchFormArgs = {
  context: ContextInput;
  searchCriteria: PackageSearchCriteriaInput;
};

export type QueryPriceDetailsArgs = {
  cartId?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  entityToken?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["String"]>;
  sessionId?: InputMaybe<Scalars["String"]>;
};

export type QueryPriceInsightsArgs = {
  context: ContextInput;
};

export type QueryPriceMatchPromiseArgs = {
  context: ContextInput;
  tripNumber: Scalars["String"];
};

export type QueryProductGalleryArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductGroupArgs = {
  context: ContextInput;
  productGroupContextInput?: InputMaybe<ProductGroupContextInput>;
};

export type QueryProductHeadlineArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductHighlightsArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductListingCarouselsArgs = {
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
};

export type QueryProductNavigationBarArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductQuestionsArgs = {
  context: ContextInput;
  filters?: InputMaybe<ProductQuestionFiltersInput>;
  productId: Scalars["String"];
  productQuestionTopic: ProductQuestionTopic;
  questionPagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<QuestionSortType>;
};

export type QueryProductRatingSummaryArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductReviewSummaryArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductShoppingCrossSellDetailsArgs = {
  context: ContextInput;
  productShoppingCriteriaInput: ProductShoppingCriteriaInput;
};

export type QueryProductSummaryArgs = {
  context: ContextInput;
  productSummaryInput: ProductSummaryInput;
};

export type QueryPropertyActivitiesCrossSellArgs = {
  activityNaturalKey?: InputMaybe<ActivityNaturalKeyInput>;
  context: ContextInput;
  propertyId: Scalars["String"];
  searchCriteria: PropertySearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryPropertyAvailabilityCalendarsArgs = {
  context?: InputMaybe<ContextInput>;
  dateRange?: InputMaybe<DateRangeInput>;
  eid: Scalars["ID"];
};

export type QueryPropertyCompareArgs = {
  context: ContextInput;
};

export type QueryPropertyFilterArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  listingLifecycleStates?: InputMaybe<Array<Scalars["String"]>>;
  propertyContext?: InputMaybe<PropertyContext>;
  showSeeAll?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryPropertyFilterIntentSheetArgs = {
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
  dateRange: PropertyDateRangeInput;
};

export type QueryPropertyInfoArgs = {
  context: ContextInput;
  offerOptions?: InputMaybe<Array<PropertyInfoOptions>>;
  propertyId: Scalars["String"];
  referrer?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryPropertyOffersArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"];
  referrer?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryPropertyReviewArgs = {
  context: ContextInput;
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryPropertyReviewAggregatesArgs = {
  context: ContextInput;
  propertyIds: Array<Scalars["String"]>;
};

export type QueryPropertyReviewInfoArgs = {
  context: ContextInput;
  propertyId: Scalars["String"];
  propertyName?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryPropertyReviewSummariesArgs = {
  context: ContextInput;
  propertyIds: Array<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryPropertySearchArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  destination: DestinationInput;
  filters?: InputMaybe<PropertySearchFiltersInput>;
  legacyCriteria?: InputMaybe<LegacyPropertySearchCriteriaInput>;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  productShoppingCriteriaInput?: InputMaybe<ProductShoppingCriteriaInput>;
  propertyShopOptions?: InputMaybe<PropertyShopOptionsInput>;
  pwaLegacySort?: InputMaybe<Scalars["String"]>;
  returnPropertyType?: InputMaybe<Scalars["Boolean"]>;
  rooms?: InputMaybe<Array<RoomInput>>;
  searchIntent?: InputMaybe<SearchIntentInput>;
  searchOptions?: InputMaybe<Array<PropertySearchOptions>>;
  searchPagination?: InputMaybe<PaginationInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  sort?: InputMaybe<PropertySort>;
};

export type QueryPropertySpacesArgs = {
  context: ContextInput;
  propertyContentType: PropertyContentType;
  propertyId: Scalars["String"];
};

export type QueryPropertyTopReviewsArgs = {
  context: ContextInput;
  propertyId: Scalars["String"];
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryPropertyTypesCarouselArgs = {
  context: ContextInput;
};

export type QueryQualtricsConfigArgs = {
  context: ContextInput;
};

export type QueryRandomAccessOneArgs = {
  context: ContextInput;
  featureId: Scalars["String"];
  productIdentifier: ProductIdentifierInput;
};

export type QueryRandomAccessOneSetupArgs = {
  context: ContextInput;
};

export type QueryReasonCodesArgs = {
  context: ContextInput;
};

export type QueryRecentActivitiesModuleArgs = {
  configurationId?: InputMaybe<Scalars["String"]>;
  containerType?: InputMaybe<DiscoveryContainerType>;
  contentSize?: InputMaybe<DiscoveryCardContentSize>;
  context: ContextInput;
  discoveryRecentActivityContext?: InputMaybe<DiscoveryRecentActivityContextInput>;
  regionId?: InputMaybe<Scalars["String"]>;
  typeFilter?: InputMaybe<Array<RecentActivityType>>;
};

export type QueryRecentlyViewedPropertiesCarouselArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryRecommendationCarouselArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryRecommendationsModuleArgs = {
  configurationIdentifier?: InputMaybe<Scalars["String"]>;
  containerType?: InputMaybe<DiscoveryContainerType>;
  contentSize: DiscoveryCardContentSize;
  context: ContextInput;
  input: Array<DiscoveryRecommendationInputContext>;
  offeringType: DiscoveryOfferingType;
  placementId: Scalars["String"];
  priceStrategy?: InputMaybe<DiscoveryPriceStrategy>;
  recommendationContext: DiscoveryRecommendationContextInput;
  strategy: DiscoveryRecommendationStrategy;
};

export type QueryRelevantContentArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryRequestContextArgs = {
  clientRequest: ClientRequestInput;
};

export type QueryReservationDetailsArgs = {
  components?: InputMaybe<Array<InputMaybe<SupplyReservationDetailsComponentType>>>;
  context: ContextInput;
  conversationId?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
  reservationContext?: InputMaybe<ReservationContext>;
  reservationId?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryReservationModifiersArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationId: Scalars["String"];
  reservationModifiersType?: InputMaybe<ReservationModifiersType>;
};

export type QueryRetailTravelAgentNotificationCenterArgs = {
  context: ContextInput;
};

export type QueryRetailTravelAgentRewardsArgs = {
  context: ContextInput;
};

export type QueryReviewQuestionsArgs = {
  context?: InputMaybe<ContextInput>;
  request: GetReviewQuestionsRequestInput;
};

export type QueryRewardProgramsFormArgs = {
  context: ContextInput;
  formContext?: InputMaybe<Scalars["String"]>;
};

export type QueryRulesAndRestrictionsArgs = {
  context: ContextInput;
  policyFetchParams: PolicyFetchParamsInput;
};

export type QuerySailingCabinCategoriesArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingCabinCategoriesInput: CruiseSailingCabinCategoriesInput;
  sailingToken: Scalars["String"];
};

export type QuerySailingDealsArgs = {
  context?: InputMaybe<ContextInput>;
  cruiseRequestContextInput?: InputMaybe<CruiseRequestContextInput>;
  cruiseSearchFiltersInput?: InputMaybe<CruiseSearchFiltersInput>;
  cruiseSearchOptionsInput: CruiseSearchOptionsInput;
};

export type QuerySailingDetailsArgs = {
  adults: Scalars["Int"];
  cabinType?: InputMaybe<Scalars["String"]>;
  children: Scalars["Int"];
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingToken: Scalars["String"];
};

export type QuerySailingOffersArgs = {
  adultCount: Scalars["Int"];
  cabinExperience?: InputMaybe<Scalars["String"]>;
  cabinType?: InputMaybe<Scalars["String"]>;
  childAges?: InputMaybe<Array<CruiseChildInput>>;
  childCount: Scalars["Int"];
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingToken: Scalars["String"];
};

export type QuerySearchFormArgs = {
  context: ContextInput;
};

export type QuerySearchHelpArticlesArgs = {
  context: ContextInput;
};

export type QueryServiceAutomationArgs = {
  context: ContextInput;
};

export type QueryShopInsurtechOffersArgs = {
  context: ContextInput;
  shopInsurtechOffersRequest: ShopInsurtechOffersRequestInput;
};

export type QueryShoppingPathPrimersArgs = {
  context: ContextInput;
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  multiItemSessionId: Scalars["String"];
  recoveryNaturalKeys?: InputMaybe<MishopRecoveryNaturalKeysInput>;
};

export type QueryShortlistArgs = {
  context: ContextInput;
};

export type QuerySimilarDestinationsArgs = {
  context: ContextInput;
  similarDestinationsCriteria: SimilarDestinationsCriteriaInput;
};

export type QuerySimilarPropertiesDealsArgs = {
  campaignParameters: CampaignParametersInput;
  context: ContextInput;
};

export type QuerySiteConfigurationsArgs = {
  request: SiteConfigurationsRequestInput;
};

export type QuerySmartFormArgs = {
  context: ContextInput;
  sessionId: Scalars["String"];
};

export type QuerySponsoredPropertiesCarouselArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryStaticMultiItemCrossSellArgs = {
  context: ContextInput;
  crossSellProduct: CrossSellProductType;
  searchContext: MultiItemSearchContextInput;
};

export type QueryStepReviewInlineEditingSheetArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyAmenitiesGroupArgs = {
  context: ContextInput;
  input: SupplyAmenitiesGroupInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyAttributesAdminExperienceArgs = {
  context: ContextInput;
  input: SupplyAttributesAdminExperienceInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyAutoCancelWaiverArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyBusinessPartnerInformationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  requestedBillingCountryCode?: InputMaybe<Scalars["String"]>;
};

export type QuerySupplyCancellationWaiverDeclineArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: SupplyCancellationWaiverReservationContext;
};

export type QuerySupplyCoachingActionPanelArgs = {
  contentActionPanelInput: ContentActionPanelInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipsBlockArgs = {
  coachingTipStates?: InputMaybe<Array<Scalars["String"]>>;
  coachingTipTypes?: InputMaybe<Array<Scalars["String"]>>;
  context: ContextInput;
  propertyContext: PropertyContext;
  view?: InputMaybe<CoachingTipsView>;
};

export type QuerySupplyCoachingTipsPostponeFormArgs = {
  coachingTipType: Scalars["String"];
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentAdditionalInfoArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHeadlineDescriptionArgs = {
  context: ContextInput;
  navigationLevel?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHomePageArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHubArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHubNavigationArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentLocationSharingArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentManualTranslationSettingArgs = {
  context: ContextInput;
  locale?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentSaveButtonArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentScoreArgs = {
  contentScoreInput: ContentScoreInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentYourPropertyArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentYourStoryArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContractingLicensePlateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContractingProgramEnrollmentArgs = {
  context: ContextInput;
  program: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyDeleteAccountArgs = {
  context: ContextInput;
};

export type QuerySupplyFeesArgs = {
  context: ContextInput;
  feeFilter?: InputMaybe<SupplyFeeFilterInput>;
  propertyContext: PropertyContext;
};

export type QuerySupplyHelpContactUsArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
  supportTaxonomy?: InputMaybe<Scalars["String"]>;
};

export type QuerySupplyHomepageArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyIndirectTaxArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyLoyaltyArgs = {
  context: ContextInput;
};

export type QuerySupplyMarketMakerGridArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyMarketplaceFeedArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyMultiFactorAuthenticationViewArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  navigationContext?: InputMaybe<SupplyNavigationContext>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationLinkItemBadgeArgs = {
  badgeType: Scalars["String"];
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationNotificationArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationPropertyDetailsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyNavigationReservationSearchContentArgs = {
  context: ContextInput;
  keyword?: InputMaybe<Scalars["String"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyNavigationUserImpersonationBannerArgs = {
  context: ContextInput;
};

export type QuerySupplyOnboardingAvailabilityArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingNavigationHeaderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingPaymentTermsAndConditionsArgs = {
  context: ContextInput;
};

export type QuerySupplyOnboardingPropertyRatesExperienceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingPublishListingArgs = {
  context: ContextInput;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  onboardingContext?: InputMaybe<OnboardingContext>;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingReadinessArgs = {
  context: ContextInput;
  onboardingContext?: InputMaybe<OnboardingContext>;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingReviewArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingTipCardArgs = {
  context: ContextInput;
  tipCardId?: InputMaybe<SupplyOnboardingTipCardId>;
};

export type QuerySupplyOnboardingUnfinishedStepsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingWelcomeArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingWorkFlowNavigationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOpportunityHubLicensePlateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPageLevelBannerArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyPartnerSelectArgs = {
  context: ContextInput;
};

export type QuerySupplyPartnerSignUpEstimateResultsArgs = {
  context: ContextInput;
  estimateResultsInput: SupplyPartnerSignUpEstimateResultsInput;
};

export type QuerySupplyPhotoUploaderExperienceArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  propertyContext: PropertyContext;
};

export type QuerySupplyPhotosExperienceArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  propertyContext: PropertyContext;
};

export type QuerySupplyPremierHostArgs = {
  context: ContextInput;
};

export type QuerySupplyProductCreationExperienceArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationPreviewRatesArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  roomTypeKey: Scalars["String"];
};

export type QuerySupplyProductCreationPreviewRoomNameArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameAttributesInput;
};

export type QuerySupplyProductCreationPricingModelArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationRatePlanSideSheetArgs = {
  context: ContextInput;
  params: SupplyProductCreationRatePlanSideSheetInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationRoomInfoExperienceArgs = {
  context: ContextInput;
  previewBedConfigurationInput?: InputMaybe<SupplyProductCreationPreviewBedConfigurationInput>;
  propertyContext: PropertyContext;
  roomInfoInput?: InputMaybe<SupplyProductCreationRoomInfoInput>;
};

export type QuerySupplyProductCreationRoomNameExperienceArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationTipCardArgs = {
  context: ContextInput;
  input: SupplyProductCreationTipCardInput;
};

export type QuerySupplyPromotionCopyTieredMembersOnlyDealArgs = {
  context: ContextInput;
  promotionId: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionCreateTieredMembersOnlyDealArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionPlaygroundArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionPlaygroundGetBestDiscountArgs = {
  context: ContextInput;
  playgroundInputs?: InputMaybe<SupplyPromotionPlaygroundInputs>;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionStackingOptionsSheetArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionUpdateTieredMembersOnlyDealArgs = {
  context: ContextInput;
  promotionId: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyPropertyEditorPageArgs = {
  context: ContextInput;
  page: Scalars["String"];
};

export type QuerySupplyRegulatoryFlowArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  regulatoryType?: InputMaybe<Scalars["String"]>;
};

export type QuerySupplyRegulatoryFlowThirdPartyValidationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  validation: SupplyRegulatoryFlowThirdPartyValidationInput;
};

export type QuerySupplyRegulatoryFlowTitleArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyReservationsHeaderArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientInput>;
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: ReservationContext;
};

export type QuerySupplyRoomTypeMappingArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  viewOption?: InputMaybe<SupplyRoomTypeMappingViewOption>;
};

export type QuerySupplyScoreDetailsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyScoreExternalModuleOverviewArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyScoreFactorAuditTrailArgs = {
  context: ContextInput;
  factorType: Scalars["String"];
  propertyContext: PropertyContext;
};

export type QuerySupplyTaxesArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySweepstakesBannerArgs = {
  context: ContextInput;
};

export type QuerySweepstakesDashboardArgs = {
  context: ContextInput;
};

export type QuerySweepstakesUserActionHistoryQueryArgs = {
  apiKey: Scalars["String"];
  campaignId: Scalars["String"];
};

export type QueryTermsAndConditionsArgs = {
  context: ContextInput;
  couponInstanceId?: InputMaybe<Scalars["String"]>;
  rewardId?: InputMaybe<Scalars["String"]>;
};

export type QueryTestAirTopCarriersServiceArgs = {
  context: ContextInput;
  uriContext: UriContextInput;
};

export type QueryTestAirlineEntityServiceArgs = {
  context: ContextInput;
};

export type QueryTestGaiaServiceTopAirportsWithinLocationArgs = {
  context: ContextInput;
  id: Scalars["String"];
  top: Scalars["Int"];
};

export type QueryTestGaiaServiceTopRelevantAirportsArgs = {
  context: ContextInput;
  id: Scalars["String"];
};

export type QueryTestingQueryOffersArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  offerOptions?: InputMaybe<Array<PropertyInfoOptions>>;
  propertyId: Scalars["String"];
  referrer?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryThingsToDoArgs = {
  context: ContextInput;
  placeId: Scalars["String"];
};

export type QueryTopRatedAttributeArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryTopTasksArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryTravelAgentAuthenticationOptionsArgs = {
  context: ContextInput;
};

export type QueryTravelAgentDashboardArgs = {
  context: ContextInput;
  dashboardType?: InputMaybe<DashboardType>;
};

export type QueryTravelAgentSearchWizardConfigArgs = {
  context: ContextInput;
};

export type QueryTravelContentEditorialArgs = {
  contentIds?: InputMaybe<Array<Scalars["String"]>>;
  contentPurpose?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  pageType?: InputMaybe<Scalars["String"]>;
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryTravelExperienceContentArgs = {
  context: ContextInput;
  ids: Array<Scalars["String"]>;
  pageType: Scalars["String"];
};

export type QueryTravelExperienceManagerContentArgs = {
  contentTopic?: InputMaybe<Scalars["String"]>;
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]>;
  experienceType?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  requestDate?: InputMaybe<Scalars["String"]>;
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryTravelGuideRecommendationArgs = {
  context: ContextInput;
  policy: DestinationTravelGuidePolicy;
  travelGuidePageContextInput?: InputMaybe<TravelGuidePageContextInput>;
};

export type QueryTravelTriviaBannerArgs = {
  context: ContextInput;
};

export type QueryTravelerCouponsArgs = {
  context: ContextInput;
};

export type QueryTravelerSelectorArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"];
  referrer?: InputMaybe<Scalars["String"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryTravellerSearchArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type QueryTripArgs = {
  context: ContextInput;
};

export type QueryTripExperienceTemplateArgs = {
  context: ContextInput;
  tripItemId: Scalars["String"];
  tripViewId: Scalars["String"];
};

export type QueryTripItemCommentsAndVotesArgs = {
  context: ContextInput;
  defaultTab?: InputMaybe<Scalars["String"]>;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type QueryTripItemContextualCardsArgs = {
  context: ContextInput;
  tripItemId: Scalars["String"];
  tripViewId?: InputMaybe<Scalars["String"]>;
};

export type QueryTripItemImageGalleryArgs = {
  context: ContextInput;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type QueryTripItemPriceArgs = {
  context: ContextInput;
  itemId: Scalars["String"];
};

export type QueryTripItemsArgs = {
  context: ContextInput;
  groups: Array<TripsItemsGroupInput>;
  tripId: Scalars["String"];
};

export type QueryTripItineraryArgs = {
  context: ContextInput;
  tripId: Scalars["String"];
};

export type QueryTripManageParticipantsArgs = {
  context: ContextInput;
  tripId: Scalars["String"];
};

export type QueryTripMapArgs = {
  context: ContextInput;
  tripId: Scalars["String"];
};

export type QueryTripOverviewTabsArgs = {
  context: ContextInput;
  tripId: Scalars["String"];
};

export type QueryTripPlanningArgs = {
  context: ContextInput;
  tripItemContext?: InputMaybe<TripsUiTripItemContextInput>;
};

export type QueryTripSaveItemArgs = {
  context: ContextInput;
  encodedItemId: Scalars["String"];
};

export type QueryTripsArgs = {
  context: ContextInput;
};

export type QueryTripsAttachSavingsArgs = {
  context: ContextInput;
  lob?: InputMaybe<ExpLineOfBusiness>;
  orderId?: InputMaybe<Scalars["String"]>;
  qualificationType?: InputMaybe<TripsUiAttachQualificationType>;
  tripId: Scalars["String"];
  variant?: InputMaybe<TripsUiAttachSavingsVariant>;
};

export type QueryTripsChatGptEntrypointArgs = {
  context: ContextInput;
  tripId: Scalars["String"];
  tripSource?: InputMaybe<Scalars["String"]>;
};

export type QueryTripsCollectionArgs = {
  context: ContextInput;
};

export type QueryTripsMultiItemPurchaseArgs = {
  context: ContextInput;
  qualificationType?: InputMaybe<TripsUiAttachQualificationType>;
  tripId: Scalars["String"];
};

export type QueryTripsNotAuthorizedViewArgs = {
  context: ContextInput;
  input?: InputMaybe<TripsNotAuthorizedInput>;
};

export type QueryTripsReviewCollectionArgs = {
  context: ContextInput;
};

export type QueryTripsWebViewArgs = {
  context: ContextInput;
  input: TripsWebViewInput;
};

export type QueryTriviaHowToArgs = {
  context: ContextInput;
  request: TriviaStartRequestInput;
};

export type QueryTsaFormArgs = {
  context: ContextInput;
};

export type QueryUniversalProfileArgs = {
  context: ContextInput;
  universalProfileClientInfo?: InputMaybe<UniversalProfileClientInfoInput>;
};

export type QueryUpcomingInteractionsArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QueryUpdateJourneyContinuationIdArgs = {
  updateJourneyContinuationIdRequest: UpdateJourneyContinuationIdRequestInput;
};

export type QueryUserProfileNextWizardArgs = {
  context: ContextInput;
  wizardContext: ProfileWizardContextInput;
};

export type QueryVacChatContentArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
};

export type QueryVacOrchestratorArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  clientId: Scalars["String"];
  context: ContextInput;
};

export type QueryValuePropositionBannerArgs = {
  context: ContextInput;
};

export type QueryVirtualAgentControlArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext?: InputMaybe<ConversationContextInput>;
  variant?: InputMaybe<Scalars["String"]>;
};

export type QueryVirtualAgentControlConfigurationArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  variant: Scalars["String"];
};

export type QueryVirtualAgentControlMessagesArgs = {
  authenticationConfig: AuthenticationConfigInput;
  context: ContextInput;
  conversationContext: ConversationContextInput;
};

export type QueryVoiceOfTheCustomerArgs = {
  context: ContextInput;
};

export type QueryVoiceOfTheCustomerOptionalArgs = {
  context: ContextInput;
  surveyContext?: InputMaybe<Scalars["String"]>;
};

export type QueryWishlistArgs = {
  context: ContextInput;
  wishlistContext?: InputMaybe<WishlistContextInput>;
};

export type QuestionAndAnswer = {
  __typename?: "QuestionAndAnswer";
  analytics: ClientSideAnalytics;
  /** @deprecated contains raw html */
  answer: Scalars["String"];
  question: Scalars["String"];
};

export type QuestionSortType = "NEWEST_TO_OLDEST" | "OLDEST_TO_NEWEST";

export type QuoteAction = UiAction & {
  __typename?: "QuoteAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionIdentifier: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type QuoteBuilderType = "BOTTOM" | "RIGHT_SIDE" | "SIDE";

export type RandomAccessOneContentResponse = {
  __typename?: "RandomAccessOneContentResponse";
  id: Scalars["String"];
  mapping: Array<ExperimentalComponentWrapper>;
};

export type RandomAccessOneSetup = {
  __typename?: "RandomAccessOneSetup";
  helpers: Array<HelperMetadata>;
  identifiers: Array<IdentifierMetadata>;
};

export type RangeIndicator = {
  __typename?: "RangeIndicator";
  accessibility: Scalars["String"];
  milestones: Array<RangeIndicatorMilestone>;
  segments: Array<RangeIndicatorSegment>;
};

export type RangeIndicatorMilestone = {
  __typename?: "RangeIndicatorMilestone";
  label: EgdsStylizedText;
};

export type RangeIndicatorPin = {
  __typename?: "RangeIndicatorPin";
  badge: EgdsStandardBadge;
  icon?: Maybe<UiGraphic>;
  percentage: Scalars["Int"];
};

export type RangeIndicatorSegment = {
  __typename?: "RangeIndicatorSegment";
  pins: Array<RangeIndicatorPin>;
  style?: Maybe<RangeIndicatorSegmentStyle>;
  theme: RangeIndicatorSegmentTheme;
};

export type RangeIndicatorSegmentStyle = "EMPHASIS";

export type RangeIndicatorSegmentTheme = "NEGATIVE" | "NEUTRAL" | "POSITIVE" | "VERY_POSITIVE";

export type RangeValue = {
  __typename?: "RangeValue";
  id: Scalars["String"];
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export interface RangeValueInput {
  id: Scalars["String"];
  max: Scalars["Int"];
  min: Scalars["Int"];
}

export type RateAdjustmentType = "Amount" | "Percent";

export type RateCategory = {
  __typename?: "RateCategory";
  groupRate?: Maybe<Scalars["Boolean"]>;
};

export interface RateCategoryInput {
  groupRate?: InputMaybe<Scalars["Boolean"]>;
}

export type RateDiscount = {
  __typename?: "RateDiscount";
  description?: Maybe<Scalars["String"]>;
  descriptionId: Scalars["String"];
  expiration: Duration;
  sameDay: Scalars["Boolean"];
};

export type RateDiscountType = "MEMBER_DEAL" | "MOBILE_EXCLUSIVE" | "REGULAR" | "TONIGHT_ONLY";

export type RatePlan = {
  __typename?: "RatePlan";
  amenities: Array<RatePlanAmenity>;
  /** @deprecated Deprecated in favor of standardBadge */
  badge?: Maybe<Badge>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  confidenceMessage?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Feature will be moved. */
  etpDialogTopMessage?: Maybe<MessageResult>;
  headerMessage?: Maybe<LodgingEnrichedMessage>;
  highlightedMessages?: Maybe<Array<Maybe<RatePlanMessage>>>;
  id: Scalars["String"];
  loyaltyMessage?: Maybe<LodgingEnrichedMessage>;
  marketingChannelMessage?: Maybe<LodgingEnrichedMessage>;
  marketingSection?: Maybe<MarketingSection>;
  name?: Maybe<Scalars["String"]>;
  offerNotifications?: Maybe<LodgingNotificationsCard>;
  paymentPolicy: Array<PropertyPaymentPolicyInfo>;
  paymentReassuranceMessage?: Maybe<LodgingEnrichedMessage>;
  priceDetails: Array<Offer>;
  reserveCallToAction?: Maybe<RatePlanCallToAction>;
  shareUrl?: Maybe<LodgingLinkMessage>;
  showRecommendedRateMessage: Scalars["Boolean"];
  standardBadge?: Maybe<LodgingStandardBadge>;
};

export type RatePlanAmenity = {
  __typename?: "RatePlanAmenity";
  additionalInformation?: Maybe<Scalars["String"]>;
  category?: Maybe<AmenityCategory>;
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["Int"]>;
};

export type RatePlanCallToAction = DatelessCheckAvailability | EtpDialog | LodgingForm | LodgingMemberSignInDialog;

export interface RatePlanInfo {
  childRatePlanDisplayName: Scalars["String"];
  childRatePlanID?: InputMaybe<Scalars["String"]>;
  parentRatePlanID: Scalars["String"];
  parentRatePlanName: Scalars["String"];
}

export type RatePlanMessage = LodgingEnrichedMessage | LodgingPlainDialog | LodgingPlainMessage | LodgingPolicyDialog;

export type RatePlanStickyBar = {
  __typename?: "RatePlanStickyBar";
  ratePlanId: Scalars["String"];
  stickyBar: PropertyDetailsStickyBar;
};

export type RatePlansExpando = LodgingExpando & {
  __typename?: "RatePlansExpando";
  collapseButton: LodgingButton;
  content: RatePlansExpandoContent;
  expandButton: LodgingButton;
  items?: Maybe<Scalars["Int"]>;
  lines?: Maybe<Scalars["Int"]>;
};

export type RatePlansExpandoContent = {
  __typename?: "RatePlansExpandoContent";
  ratePlans: Array<RatePlan>;
};

export type RatesEgClickstreamData = {
  __typename?: "RatesEGClickstreamData";
  additional_context?: Maybe<SupplyOnboardingRatesEgClickstreamAdditionalContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
  product_list?: Maybe<Array<SupplyOnboardingRatesEgClickstreamProductList>>;
};

export type ReasonCodePrimaryElements = EgdsBasicSelect | SecondaryReasonList;

export type ReasonCodeSecondaryElements = EgdsTextAreaInputField | ResponsibleEntity;

export interface ReasonCodesMutationInput {
  agentID?: InputMaybe<Scalars["String"]>;
  agentNotes?: InputMaybe<Scalars["String"]>;
  primaryReason: Scalars["String"];
  responsibleEntity: Scalars["String"];
  secondaryReason: Scalars["String"];
}

export type ReassuranceExpando = {
  __typename?: "ReassuranceExpando";
  content: Array<ReassuranceExpandoContentListItem>;
  item?: Maybe<EgdsExpandoListItem>;
};

export type ReassuranceExpandoContentListItem = {
  __typename?: "ReassuranceExpandoContentListItem";
  graphic?: Maybe<UiGraphic>;
  price?: Maybe<Scalars["String"]>;
  subText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type RecentActivityType = "BROWSING_HISTORY" | "RECENT_SEARCHES" | "VIEWED_OFFERS" | "VIEWED_PRODUCTS";

export type RecentlyViewedPropertiesCarouselWrapper = {
  __typename?: "RecentlyViewedPropertiesCarouselWrapper";
  position?: Maybe<Scalars["Int"]>;
};

export type RecommendationAnalytics = {
  __typename?: "RecommendationAnalytics";
  event: EgClickstreamEvent;
  recommendationModule: RecommendationModule;
};

export type RecommendationAttribution = {
  __typename?: "RecommendationAttribution";
  closeContent?: Maybe<EgdsButton>;
  content: EgdsSpannableText;
  showContent?: Maybe<EgdsButton>;
};

export type RecommendationMediaItem = {
  __typename?: "RecommendationMediaItem";
  attribution?: Maybe<RecommendationAttribution>;
  media?: Maybe<Media>;
};

export type RecommendationModule = {
  __typename?: "RecommendationModule";
  recommendationResponseId: Scalars["String"];
  recommendationTitle?: Maybe<Scalars["String"]>;
  recommendations: Array<Recommendations>;
};

export type Recommendations = {
  __typename?: "Recommendations";
  presentationDetailsCurrencyCode?: Maybe<Scalars["String"]>;
  presentationDetailsPriceDisplayed?: Maybe<Scalars["String"]>;
  presentationDetailsRecommendationName?: Maybe<Scalars["String"]>;
  recommendationId: Scalars["String"];
  recommendationPosition?: Maybe<Scalars["String"]>;
};

export type RecommendedCampaigns = {
  __typename?: "RecommendedCampaigns";
  campaigns: Array<CampaignDetail>;
  paginationInfo: CampaignPagination;
  responseId: Scalars["UUID"];
};

export type RecommendedOffer = AccessibilityField & {
  __typename?: "RecommendedOffer";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  priceDetails: Offer;
  units: Array<PropertyUnit>;
};

export type RecommendedType = "BESPOKE" | "DESTINATIONS" | "OFFERS";

export type ReducedDepositDetails = {
  __typename?: "ReducedDepositDetails";
  originalDepositAmount: Scalars["String"];
  primaryText: Scalars["String"];
  reducedDepositAmount: Scalars["String"];
  secondaryText: Scalars["String"];
};

export type RefundExpediaPortionValidation = EgdsInputValidation & {
  __typename?: "RefundExpediaPortionValidation";
  errorMessage: Scalars["String"];
};

export type RefundFormMutationElement = AgentToolsSummaryCard | BookingConfirmation | UiBanner;

export interface RefundFormMutationInput {
  additionalRefundInput?: InputMaybe<Scalars["String"]>;
  approverContact?: InputMaybe<Scalars["String"]>;
  approverFirstName?: InputMaybe<Scalars["String"]>;
  approverLastName?: InputMaybe<Scalars["String"]>;
  approverTitle?: InputMaybe<Scalars["String"]>;
  expediaPortionRefund?: InputMaybe<Scalars["Float"]>;
  reasonCodes?: InputMaybe<ReasonCodesMutationInput>;
  reasonCodesAdditional?: InputMaybe<ReasonCodesMutationInput>;
  supplierPortionRefund?: InputMaybe<Scalars["Float"]>;
  waivingSource?: InputMaybe<Scalars["String"]>;
}

export type RefundFormMutationResponse = {
  __typename?: "RefundFormMutationResponse";
  refundFormMutationElement: RefundFormMutationElement;
  status: RefundStatus;
};

export type RefundSourcePortionErrorMessages = {
  __typename?: "RefundSourcePortionErrorMessages";
  inputRequired: Scalars["String"];
  withinRange: Scalars["String"];
};

export type RefundStatus = "Failure" | "Success";

export type RefundType = "FTC" | "NO_VALUE" | "REFUND";

export type RefundableType = "FULLY" | "NON" | "PARTIALLY" | "UNKNOWN";

export type Region = {
  __typename?: "Region";
  id: Scalars["String"];
  name: Scalars["String"];
  shortName: Scalars["String"];
  type?: Maybe<RegionType>;
};

export type RegionCompression = {
  __typename?: "RegionCompression";
  level: CompressionLevel;
  regionId: Scalars["String"];
  regionName: Scalars["String"];
  score: Scalars["Int"];
};

export type RegionType =
  | "ADDRESS"
  | "AIRPORT"
  | "CITY"
  | "CONTINENT"
  | "COUNTRY"
  | "HIGH_LEVEL_REGION"
  | "METRO_STATION"
  | "MULTI_CITY_VICINITY"
  | "NEIGHBORHOOD"
  | "POINT_OF_INTEREST"
  | "PROVINCE_STATE"
  | "TRAIN_STATION"
  | "UNKNOWN";

export type RelativeUri = Uri & {
  __typename?: "RelativeURI";
  value: Scalars["String"];
};

export type RelevantArticles = {
  __typename?: "RelevantArticles";
  helpArticleId: Scalars["String"];
  title: Scalars["String"];
};

export type RelevantContent = {
  __typename?: "RelevantContent";
  content: ProductAmenitiesSection;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type RelevantHelpArticleSection = {
  __typename?: "RelevantHelpArticleSection";
  articleLinkIcon?: Maybe<Icon>;
  relevantArticles?: Maybe<Array<RelevantArticles>>;
  /** @deprecated waitMessage is moved to getNewSearchBar query */
  waitMessage?: Maybe<Scalars["String"]>;
};

export type RelevantHelpArticlesView = HelpArticleSearchErrorView | RelevantHelpArticleSection;

export interface RemoveInsuranceRequestInput {
  insuranceProductIds: Array<Scalars["String"]>;
  sourceId: Scalars["String"];
  updateRequestId: Scalars["String"];
}

export interface RemoveInsuranceRequestOldInput {
  insuranceSearchId: Scalars["String"];
  offerTypeId?: InputMaybe<Scalars["Int"]>;
}

export type RemoveInsuranceResponse = {
  __typename?: "RemoveInsuranceResponse";
  status: InsuranceMutationStatus;
};

export type RemoveInsuranceResponseOld = {
  __typename?: "RemoveInsuranceResponseOld";
  message: InsurancePhrase;
  status: InsuranceMutationStatus;
};

export type RemoveItemResults = {
  __typename?: "RemoveItemResults";
  result: Result;
};

export interface RemovePaymentMethodRequestInput {
  paymentMethod: PaymentMethodInput;
  sessionId: Scalars["String"];
  sessionToken: Scalars["String"];
}

export type RentalProtectionCard = {
  __typename?: "RentalProtectionCard";
  clickAction: CarActionableItem;
  dialog: RentalProtectionDialog;
  infos: Array<RentalProtectionInfoLineItem>;
  offerBadge?: Maybe<CarOfferBadge>;
  pricePeriod?: Maybe<Scalars["String"]>;
  priceSummary?: Maybe<Scalars["String"]>;
  seeDetailsAction: CarActionableItem;
  selected: Scalars["Boolean"];
  title: Scalars["String"];
};

export type RentalProtectionDialog = {
  __typename?: "RentalProtectionDialog";
  content: Array<RentalProtectionDialogContent>;
  description: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type RentalProtectionDialogContent = {
  __typename?: "RentalProtectionDialogContent";
  description: Array<RentalProtectionInfoLineItem>;
  title: Scalars["String"];
};

export type RentalProtectionInfo = {
  __typename?: "RentalProtectionInfo";
  rentalProtectionCard: Array<RentalProtectionCard>;
  title: Scalars["String"];
};

export type RentalProtectionInfoLineItem = {
  __typename?: "RentalProtectionInfoLineItem";
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type ReportFilterMenu = {
  __typename?: "ReportFilterMenu";
  closeIcon: Icon;
  doneButton: UiPrimaryButton;
  pill: EgdsBasicPill;
  searchTypeahead: EgdsTypeaheadMultiSelect;
  typeAheadData: Array<Scalars["String"]>;
};

export type ReportPillMenu = {
  __typename?: "ReportPillMenu";
  menu: EgdsTextMenu;
  pill: EgdsBasicPill;
};

export type ReportProblemForm = {
  __typename?: "ReportProblemForm";
  analytics?: Maybe<Array<AnalyticsElement>>;
  comments?: Maybe<EgdsTextAreaInputField>;
  dismissForm: EgdsButton;
  options?: Maybe<Array<VirtualAgentControlFormInput>>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
  submitForm: EgdsButton;
};

export type ReportingFilters = ReportFilterMenu | ReportPillMenu;

export type ReportingOptionFilters = {
  __typename?: "ReportingOptionFilters";
  clearAllButton: EgdsBasicPill;
  filters: Array<ReportingFilters>;
};

export interface ReservationContext {
  conversationId?: InputMaybe<Scalars["String"]>;
  isManualBookingVr?: InputMaybe<Scalars["Boolean"]>;
  reservationId?: InputMaybe<Scalars["String"]>;
}

export type ReservationModifiersCallout = {
  __typename?: "ReservationModifiersCallout";
  content: Scalars["String"];
  icon: Icon;
  title: Scalars["String"];
};

export type ReservationModifiersCancellationFeeCustomOption = {
  __typename?: "ReservationModifiersCancellationFeeCustomOption";
  a11yLabel?: Maybe<Scalars["String"]>;
  inputA11yLabel?: Maybe<Scalars["String"]>;
  inputLabel: Scalars["String"];
  inputNote: Scalars["String"];
  label: Scalars["String"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  optionType: ReservationModifiersCancellationFeeOptionType;
};

export type ReservationModifiersCancellationFeeOption =
  | ReservationModifiersCancellationFeeCustomOption
  | ReservationModifiersCancellationFeeStandardOption;

export type ReservationModifiersCancellationFeeOptionType = "CUSTOM_FEE" | "FULL_FEE" | "NO_FEE";

export type ReservationModifiersCancellationFeeStandardOption = {
  __typename?: "ReservationModifiersCancellationFeeStandardOption";
  a11yLabel?: Maybe<Scalars["String"]>;
  amount: Scalars["String"];
  label: Scalars["String"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  optionType: ReservationModifiersCancellationFeeOptionType;
  sublabel?: Maybe<Scalars["String"]>;
};

export type ReservationModifiersChangeInfo = {
  __typename?: "ReservationModifiersChangeInfo";
  label: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  onTooltipClosedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onTooltipSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  strikethroughValue?: Maybe<Scalars["Boolean"]>;
  tooltipA11yLabel?: Maybe<Scalars["String"]>;
  tooltipText?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type ReservationModifiersErrorBanner = {
  __typename?: "ReservationModifiersErrorBanner";
  details: Scalars["String"];
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export type ReservationModifiersMenuOption = {
  __typename?: "ReservationModifiersMenuOption";
  icon: Icon;
  label: Scalars["String"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  type: ReservationModifiersType;
};

export type ReservationModifiersMenuScreen = {
  __typename?: "ReservationModifiersMenuScreen";
  errorBanner: ReservationModifiersErrorBanner;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  options: Array<ReservationModifiersMenuOption>;
  preface: Scalars["String"];
  screenType: ReservationModifiersScreenType;
};

export type ReservationModifiersRefundAmountCustomOption = {
  __typename?: "ReservationModifiersRefundAmountCustomOption";
  customAmountRegex: Scalars["String"];
  decimalPlaces: Scalars["Int"];
  errorMessage: Scalars["String"];
  inputLabel: Scalars["String"];
  label: Scalars["String"];
  maxRefundAmount: Scalars["String"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  optionType: ReservationModifiersRefundAmountOptionType;
};

export type ReservationModifiersRefundAmountOption =
  | ReservationModifiersRefundAmountCustomOption
  | ReservationModifiersRefundAmountPercentageOption;

export type ReservationModifiersRefundAmountOptionType = "CUSTOM_AMOUNT" | "PERCENTAGE_AMOUNT";

export type ReservationModifiersRefundAmountPercentageOption = {
  __typename?: "ReservationModifiersRefundAmountPercentageOption";
  amountLabel: Scalars["String"];
  bookingAmount: Scalars["String"];
  currency: Scalars["String"];
  errorMessage: Scalars["String"];
  inputLabel: Scalars["String"];
  label: Scalars["String"];
  locale: Scalars["String"];
  maxRefundPercentage: Scalars["String"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  optionType: ReservationModifiersRefundAmountOptionType;
};

export type ReservationModifiersReviewChangesScreen = {
  __typename?: "ReservationModifiersReviewChangesScreen";
  action?: Maybe<SupplyReservationsAction>;
  content: Array<ReservationModifiersChangeInfo>;
  errorBanner: ReservationModifiersErrorBanner;
  icon: Icon;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  screenType: ReservationModifiersScreenType;
  title: Scalars["String"];
};

export type ReservationModifiersScreen =
  | ReservationModifiersMenuScreen
  | ReservationModifiersReviewChangesScreen
  | ReservationModifiersSetCancellationFeeScreen
  | ReservationModifiersSetCancellationReasonScreen
  | ReservationModifiersSetMessageToGuestScreen
  | ReservationModifiersSetRefundAmountScreen
  | ReservationModifiersSetRefundReasonScreen
  | ReservationModifiersSetStayDatesScreen
  | ReservationModifiersSuccessScreen;

export type ReservationModifiersScreenType =
  | "MENU"
  | "REVIEW_CHANGES"
  | "SET_CANCELLATION_FEE"
  | "SET_CANCELLATION_REASON"
  | "SET_MESSAGE_TO_GUEST"
  | "SET_REFUND_AMOUNT"
  | "SET_REFUND_REASON"
  | "SET_STAY_DATES"
  | "SUCCESS";

export type ReservationModifiersSetCancellationFeeScreen = {
  __typename?: "ReservationModifiersSetCancellationFeeScreen";
  action?: Maybe<SupplyReservationsAction>;
  currency: Scalars["String"];
  customFeeErrorMessage: Scalars["String"];
  customFeeRegex: Scalars["String"];
  decimalPlaces: Scalars["Int"];
  errorBanner: ReservationModifiersErrorBanner;
  fullFeeAmount: Scalars["String"];
  guestPaymentAmount: Scalars["String"];
  guestPaymentText: Scalars["String"];
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  options: Array<ReservationModifiersCancellationFeeOption>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type ReservationModifiersSetCancellationReasonScreen = {
  __typename?: "ReservationModifiersSetCancellationReasonScreen";
  action?: Maybe<SupplyReservationsAction>;
  dropdown: EgdsSelect;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onOpenedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  screenType: ReservationModifiersScreenType;
  title: Scalars["String"];
};

export type ReservationModifiersSetMessageToGuestScreen = {
  __typename?: "ReservationModifiersSetMessageToGuestScreen";
  action?: Maybe<SupplyReservationsAction>;
  errorBanner: ReservationModifiersErrorBanner;
  input: EgdsTextAreaInputField;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  screenType: ReservationModifiersScreenType;
  title: Scalars["String"];
};

export type ReservationModifiersSetRefundAmountScreen = {
  __typename?: "ReservationModifiersSetRefundAmountScreen";
  action?: Maybe<SupplyReservationsAction>;
  availableAmount: Scalars["String"];
  availableAmountLabel: Scalars["String"];
  errorBanner: ReservationModifiersErrorBanner;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  refundAmountOptions: Array<ReservationModifiersRefundAmountOption>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type ReservationModifiersSetRefundReasonScreen = {
  __typename?: "ReservationModifiersSetRefundReasonScreen";
  action?: Maybe<SupplyReservationsAction>;
  dropdown: EgdsSelect;
  dropdownPrompt: Scalars["String"];
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onOpenedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type ReservationModifiersSetStayDatesDateSelector = {
  __typename?: "ReservationModifiersSetStayDatesDateSelector";
  changeInfo: Scalars["String"];
  endDateInputLabel: Scalars["String"];
  onCheckinSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onCheckoutSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  originalCheckInDate: Scalars["String"];
  originalCheckOutDate: Scalars["String"];
  startDateInputLabel: Scalars["String"];
  submitButtonLabel: Scalars["String"];
};

export type ReservationModifiersSetStayDatesScreen = {
  __typename?: "ReservationModifiersSetStayDatesScreen";
  action?: Maybe<SupplyReservationsAction>;
  dateSelector: ReservationModifiersSetStayDatesDateSelector;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onReasonOpenedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onReasonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  reasonForChangeDropdown: EgdsSelect;
  reasonForChangeInvalid: Scalars["String"];
  reasonForChangeLabel: Scalars["String"];
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type ReservationModifiersSubsidiaryData = {
  __typename?: "ReservationModifiersSubsidiaryData";
  originalBookedDates: Scalars["String"];
  originalBookingAmount: Scalars["String"];
  originalBookingCurrency: Scalars["String"];
  paymentMethod: Scalars["String"];
  travelerName: Scalars["String"];
};

export type ReservationModifiersSuccessScreen = {
  __typename?: "ReservationModifiersSuccessScreen";
  action?: Maybe<SupplyReservationsAction>;
  callout?: Maybe<ReservationModifiersCallout>;
  details: Scalars["String"];
  icon: Icon;
  nextStepItems?: Maybe<Array<Scalars["String"]>>;
  nextStepTitle?: Maybe<Scalars["String"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  screenType: ReservationModifiersScreenType;
};

export type ReservationModifiersToolbar = {
  __typename?: "ReservationModifiersToolbar";
  primaryAction: SupplyReservationsAction;
  secondaryAction?: Maybe<SupplyReservationsAction>;
  subtitle?: Maybe<Scalars["String"]>;
  subtitleA11yLabel?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ReservationModifiersType = "CANCEL" | "CHANGE_DATES" | "GUEST_SATISFACTION_REFUND" | "NO_SHOW";

export type ResidencyDetails = {
  __typename?: "ResidencyDetails";
  countryCode?: Maybe<Scalars["String"]>;
  regionCode?: Maybe<Scalars["String"]>;
};

export interface ResidencyDetailsInput {
  countryCode?: InputMaybe<Scalars["String"]>;
  regionCode?: InputMaybe<Scalars["String"]>;
}

export type ResidencyState = "APPLIED" | "AVAILABLE";

export type ResourceIdentifier = {
  __typename?: "ResourceIdentifier";
  id: Scalars["String"];
  type: DataSourceType;
};

export type ResponseTagType =
  | "CACHE_SOURCE_AIRBLAZE"
  | "CACHE_SOURCE_BYOT_AIRBLAZE"
  | "EXPERIENCE_SEARCH_CACHE_HYDRATOR"
  | "LISTINGS_PAGINATED_SLICED"
  | "LISTING_CACHE_SOURCE"
  | "LISTING_DOWNLEVEL_SOURCE"
  | "MAIN_SEARCH_STACK"
  | "RESPONSE_SUMMARY_CACHED_CONFIG"
  | "RESPONSE_SUMMARY_CACHED_ML"
  | "RESPONSE_SUMMARY_HYBRID"
  | "RESPONSE_SUMMARY_LIVE"
  | "RESPONSE_SUMMARY_REFINEMENTS_CACHE_LIVE"
  | "RESPONSE_SUMMARY_REFINEMENTS_CACHE_REDIS"
  | "RESPONSE_SUMMARY_REFINEMENTS_USER_CACHE"
  | "UNRECOGNIZED";

export type ResponsibleEntity = {
  __typename?: "ResponsibleEntity";
  responsibleEntities: Array<ResponsibleEntityElements>;
};

export type ResponsibleEntityElements = EgdsBasicSelect | EgdsTextInputField;

export type Result = "ERROR" | "SUCCESS";

export type ResultSectionHeading = {
  __typename?: "ResultSectionHeading";
  heading: Scalars["String"];
  index: Scalars["Int"];
};

export type ResultTitleModel = {
  __typename?: "ResultTitleModel";
  header?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<Scalars["URL"]>;
  subHeader?: Maybe<Scalars["String"]>;
};

export type ResultsHeaderMessage = {
  __typename?: "ResultsHeaderMessage";
  subMessages?: Maybe<Array<ResultsHeaderSubMessage>>;
};

export type ResultsHeaderSubMessage = {
  __typename?: "ResultsHeaderSubMessage";
  icon?: Maybe<Icon>;
  link?: Maybe<Scalars["URL"]>;
  message?: Maybe<Scalars["String"]>;
  referrerId?: Maybe<Scalars["String"]>;
};

export type RetailTravelAgentMutateNotificationResult = {
  __typename?: "RetailTravelAgentMutateNotificationResult";
  message?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type RetailTravelAgentNotification = {
  __typename?: "RetailTravelAgentNotification";
  content: EgdsStylizedText;
  date: Scalars["String"];
  dismissButton: RetailTravelAgentNotificationDismissButton;
  heading: EgdsStylizedText;
  icon?: Maybe<Icon>;
  id: Scalars["ID"];
  link: EgdsStandardLink;
  readIndicator?: Maybe<Icon>;
};

export type RetailTravelAgentNotificationDismissButton = {
  __typename?: "RetailTravelAgentNotificationDismissButton";
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type RetailTravelAgentNotificationResult = {
  __typename?: "RetailTravelAgentNotificationResult";
  footerText: Scalars["String"];
  notifications: Array<RetailTravelAgentNotification>;
  title: Scalars["String"];
};

export type RetailTravelAgentRewardsResult = {
  __typename?: "RetailTravelAgentRewardsResult";
  heading: EgdsStylizedText;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  inLineLink: EgdsStandardLink;
  justEnrolledButton?: Maybe<UiTertiaryButton>;
  justEnrolledPopOver?: Maybe<PopOverContent>;
  paragraph: EgdsStylizedText;
  refreshedAt: EgdsStylizedText;
  rewardsProgramState: RewardsProgramState;
  startEarningPointsButton?: Maybe<UiSecondaryButton>;
  tierCards?: Maybe<Array<RetailTravelAgentTierCards>>;
  title: Scalars["String"];
};

export type RetailTravelAgentTierCards = {
  __typename?: "RetailTravelAgentTierCards";
  background: BackgroundType;
  descriptionPoints: EgdsStylizedText;
  descriptionPointsValue: EgdsStylizedText;
  headingPoints: EgdsStylizedText;
  id: Scalars["ID"];
  infoIcon: Icon;
  moreInfoPopOver: PopOverContent;
  popOverCTA: EgdsStandardLink;
  redeemYourPointsButton?: Maybe<UiSecondaryButton>;
  token: Scalars["String"];
};

export type RetryMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "RetryMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type ReviewAnswer = {
  __typename?: "ReviewAnswer";
  answer?: Maybe<ReviewsFormRatingValue>;
  questionUrn: Scalars["String"];
};

export interface ReviewAnswerInput {
  answer?: InputMaybe<ReviewsFormRatingValueInput>;
  questionUrn: Scalars["String"];
}

export type ReviewAnswersResponse = {
  __typename?: "ReviewAnswersResponse";
  reviewAnswers?: Maybe<Array<ReviewAnswer>>;
};

export type ReviewBreakdown = {
  __typename?: "ReviewBreakdown";
  description: Scalars["String"];
  heading: Scalars["String"];
  percentage: Scalars["Int"];
  score?: Maybe<Scalars["String"]>;
};

export type ReviewCancellationFooter = {
  __typename?: "ReviewCancellationFooter";
  button: BookingServicingButton;
  footerText: Scalars["String"];
};

export type ReviewCancellationPresentation = {
  __typename?: "ReviewCancellationPresentation";
  content: Array<BookingServicingCancellationContentElement>;
  displayAnalytics: Array<ClientSideAnalytics>;
  heading: Scalars["String"];
};

export type ReviewFlag = {
  __typename?: "ReviewFlag";
  reviewFlagDialog?: Maybe<PropertyReviewFlagDialog>;
  reviewFlagToast?: Maybe<LodgingToastMessage>;
};

export type ReviewFooter = {
  __typename?: "ReviewFooter";
  primary?: Maybe<EgdsStylizedText>;
  text?: Maybe<Scalars["String"]>;
};

export type ReviewInteractionType = "HELPFUL_REVIEW" | "REVIEW_REPORT_FLAG";

export type ReviewScoreRange = CriteriaRange & {
  __typename?: "ReviewScoreRange";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
};

export interface ReviewScoreRangeInput {
  max?: InputMaybe<Scalars["Float"]>;
  min?: InputMaybe<Scalars["Float"]>;
}

export type ReviewSource = "EXTERNAL_REVIEWS" | "INTERNAL_REVIEWS";

export type ReviewSummary = {
  __typename?: "ReviewSummary";
  footer?: Maybe<ReviewFooter>;
  highlightedText: EgdsSpannableText;
};

export type ReviewSummaryDetail = {
  __typename?: "ReviewSummaryDetail";
  /** @deprecated Overall formatted rating over maximum rating, instead use formattedRatingOutOfMaxA11y , replace with formattedRatingOutOfMaxA11y */
  formattedRatingOutOfMax: Scalars["String"];
  formattedRatingOutOfMaxA11y: LodgingEnrichedMessage;
  label: Scalars["String"];
  ratingPercentage: Scalars["Int"];
};

export type ReviewSummaryHighlight = {
  __typename?: "ReviewSummaryHighlight";
  content: Array<Scalars["String"]>;
  header?: Maybe<EgdsGraphicText>;
};

export type ReviewSummaryInfo = {
  __typename?: "ReviewSummaryInfo";
  content: ProductAmenitiesSection;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ReviewThemes = {
  __typename?: "ReviewThemes";
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]>;
};

export type ReviewsCollectionForm = {
  __typename?: "ReviewsCollectionForm";
  button?: Maybe<ReviewsCollectionFormSubmitButton>;
  message: Scalars["String"];
  productDetails: EgdsCard;
  sectionGroups: Array<ReviewsCollectionFormSectionGroup>;
};

export type ReviewsCollectionFormBoolRating = {
  __typename?: "ReviewsCollectionFormBoolRating";
  boolValue?: Maybe<Scalars["Boolean"]>;
  default?: Maybe<Scalars["Boolean"]>;
  falseValue: ReviewsCollectionFormRatingValue;
  trueValue: ReviewsCollectionFormRatingValue;
};

export type ReviewsCollectionFormCustomValue = {
  __typename?: "ReviewsCollectionFormCustomValue";
  customValue?: Maybe<ReviewsCollectionFormStringRating>;
  value?: Maybe<ReviewsCollectionFormRatingValue>;
};

export type ReviewsCollectionFormDependentQuestions = {
  __typename?: "ReviewsCollectionFormDependentQuestions";
  answers: Array<Scalars["String"]>;
  questionUrn?: Maybe<Scalars["String"]>;
};

export type ReviewsCollectionFormNumberRating = {
  __typename?: "ReviewsCollectionFormNumberRating";
  default: Scalars["Int"];
  maxValue: Scalars["Int"];
  minValue: Scalars["Int"];
  numberValue: Scalars["Int"];
  values: Array<ReviewsCollectionFormRatingValue>;
};

export type ReviewsCollectionFormQuestion = {
  __typename?: "ReviewsCollectionFormQuestion";
  dependentQuestions: Array<ReviewsCollectionFormDependentQuestions>;
  description: Scalars["String"];
  isRequired: Scalars["Boolean"];
  name: Scalars["String"];
  rating: ReviewsCollectionFormQuestionRating;
  urn: Scalars["String"];
};

export type ReviewsCollectionFormQuestionRating =
  | ReviewsCollectionFormBoolRating
  | ReviewsCollectionFormNumberRating
  | ReviewsCollectionFormStringRating
  | ReviewsCollectionFormTagRating;

export type ReviewsCollectionFormRatingValue = {
  __typename?: "ReviewsCollectionFormRatingValue";
  description: Scalars["String"];
  label: Scalars["String"];
  name: Scalars["String"];
  tagUrn: Scalars["String"];
};

export type ReviewsCollectionFormSection = {
  __typename?: "ReviewsCollectionFormSection";
  description: Scalars["String"];
  questions: Array<ReviewsCollectionFormQuestion>;
};

export type ReviewsCollectionFormSectionGroup = {
  __typename?: "ReviewsCollectionFormSectionGroup";
  description: Scalars["String"];
  sections: Array<ReviewsCollectionFormSection>;
};

export type ReviewsCollectionFormStringRating = {
  __typename?: "ReviewsCollectionFormStringRating";
  default: Scalars["String"];
  maxLength: Scalars["Int"];
  minLength: Scalars["Int"];
  placeholder: Scalars["String"];
  regex: Scalars["String"];
  stringValue: Scalars["String"];
};

export type ReviewsCollectionFormSubmitButton = {
  __typename?: "ReviewsCollectionFormSubmitButton";
  submitButton: EgdsButton;
};

export type ReviewsCollectionFormTagRating = {
  __typename?: "ReviewsCollectionFormTagRating";
  customTagValue?: Maybe<ReviewsCollectionFormCustomValue>;
  isMultiValue: Scalars["Boolean"];
  selectedTagValues: Array<ReviewsCollectionFormRatingValue>;
  tagUrns: Array<Scalars["String"]>;
  tagValues: Array<ReviewsCollectionFormRatingValue>;
};

export type ReviewsFormRatingValue = {
  __typename?: "ReviewsFormRatingValue";
  boolValue?: Maybe<Scalars["Boolean"]>;
  numberValue?: Maybe<Scalars["Int"]>;
  stringValue?: Maybe<Scalars["String"]>;
  tagValue?: Maybe<ReviewsFormTagValue>;
};

export interface ReviewsFormRatingValueInput {
  boolValue?: InputMaybe<Scalars["Boolean"]>;
  numberValue?: InputMaybe<Scalars["Int"]>;
  stringValue?: InputMaybe<Scalars["String"]>;
  tagValue?: InputMaybe<ReviewsFormTagValueInput>;
}

export type ReviewsFormTagValue = {
  __typename?: "ReviewsFormTagValue";
  customValue?: Maybe<Scalars["String"]>;
  tagUrns: Array<Scalars["String"]>;
};

export interface ReviewsFormTagValueInput {
  customValue?: InputMaybe<Scalars["String"]>;
  tagUrns: Array<Scalars["String"]>;
}

export type RewardsOption = "ALTAIR" | "AVION" | "NONAVION";

export type RewardsPointSelectionEnum = "APPLY_REWARD_POINTS" | "DO_NOT_APPLY_REWARD_POINTS";

export type RewardsProgramState = "ENROLLED" | "NOT_ENROLLED";

export type RichText = {
  __typename?: "RichText";
  value?: Maybe<Scalars["String"]>;
};

export type RnRContainer = {
  __typename?: "RnRContainer";
  rnrProductPolicies: Array<ProductPolicy>;
  rnrToolbarTitle: Scalars["String"];
};

export type RnRDetails = {
  __typename?: "RnRDetails";
  rnrContainer: RnRContainer;
};

export type Room = {
  __typename?: "Room";
  adults: Scalars["Int"];
  children?: Maybe<Array<Child>>;
};

export interface RoomInput {
  adults: Scalars["Int"];
  children?: InputMaybe<Array<ChildInput>>;
}

export type RoomOccupants = {
  __typename?: "RoomOccupants";
  adults?: Maybe<Scalars["Int"]>;
  agesOfChildren?: Maybe<Array<Scalars["Int"]>>;
  totalOccupants?: Maybe<Scalars["Int"]>;
};

export type RoomSoldOutDialog = {
  __typename?: "RoomSoldOutDialog";
  actionButton: UiTertiaryButton;
  description: Scalars["String"];
  primaryButton: EgdsButton;
  title: Scalars["String"];
};

export type RoomSoldOutMessaging = {
  __typename?: "RoomSoldOutMessaging";
  dialog: RoomSoldOutDialog;
};

export type RoomSummary = {
  __typename?: "RoomSummary";
  id: Scalars["String"];
  roomRatingListTitle?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["String"]>;
  scoreLabel?: Maybe<Scalars["String"]>;
  tags: Array<Tag>;
};

export type Rooms = {
  __typename?: "Rooms";
  childrenAges?: Maybe<Array<Scalars["Int"]>>;
  numAdults: Scalars["Int"];
  numChildren?: Maybe<Scalars["Int"]>;
};

export type RouteType = "MULTI_DESTINATION" | "ONE_WAY" | "ROUND_TRIP";

export type SeoStructuredData = {
  __typename?: "SEOStructuredData";
  content?: Maybe<Scalars["String"]>;
  itemprop: Scalars["String"];
  itemscope: Scalars["Boolean"];
  itemtype?: Maybe<Scalars["URL"]>;
};

export type Socr_ContactUsForm_Graph_Vip_Data = {
  __typename?: "SOCR_ContactUsForm_GRAPH_VIP_Data";
  contactOptions?: Maybe<Ifj_ContactUsView>;
};

export interface Socr_ContactUsForm_Graph_Vip_Request {
  articleIntent?: InputMaybe<Scalars["String"]>;
  articleProduct?: InputMaybe<Scalars["String"]>;
  context?: InputMaybe<ContextInput>;
  partnerId?: InputMaybe<Scalars["String"]>;
}

export type Socr_ContactUsForm_Graph_Vip_Response = {
  __typename?: "SOCR_ContactUsForm_GRAPH_VIP_Response";
  data?: Maybe<Socr_ContactUsForm_Graph_Vip_Data>;
};

export type Sailing = {
  __typename?: "Sailing";
  action: UiLinkAction;
  cruiseProductGallery?: Maybe<CruiseProductGallery>;
  /** @deprecated Use subtitle inside offer instead */
  description?: Maybe<Scalars["String"]>;
  exploreMapButton: CruiseExploreMapButton;
  /** @deprecated Use iconMedia which used EGDS MediaItem instead */
  icon?: Maybe<CruiseMedia>;
  iconMedia?: Maybe<CruiseMediaItem>;
  /** @deprecated Use imageMedia which used EGDS MediaItem instead */
  image?: Maybe<CruiseMedia>;
  imageMedia?: Maybe<CruiseMediaItem>;
  images?: Maybe<Array<CruiseMediaItem>>;
  /** @deprecated Use logoMedia which used EGDS MediaItem instead */
  logo?: Maybe<CruiseMedia>;
  logoMedia?: Maybe<CruiseMediaItem>;
  offer?: Maybe<SailingOffer>;
  pinnedSailingText?: Maybe<Scalars["String"]>;
  price?: Maybe<CruisePrice>;
  sailingToken?: Maybe<Scalars["String"]>;
  signInLink?: Maybe<CruiseSailingSearchLink>;
  sponsoredSailingAdText?: Maybe<Scalars["String"]>;
  /** @deprecated Use title inside offer instead */
  title?: Maybe<Scalars["String"]>;
};

export type SailingAction = {
  __typename?: "SailingAction";
  action: UiAction;
  content: Scalars["String"];
  descriptionText?: Maybe<Scalars["String"]>;
};

export type SailingCabinCategories = {
  __typename?: "SailingCabinCategories";
  cabinCategorySection: CruiseCabinCategorySection;
};

export type SailingDeals = {
  __typename?: "SailingDeals";
  description?: Maybe<Scalars["String"]>;
  list?: Maybe<Array<Sailing>>;
  navigation: SailingDealsNavigation;
  searchNavigation?: Maybe<CruiseSearchNavigation>;
  title?: Maybe<Scalars["String"]>;
};

export type SailingDealsNavigation = {
  __typename?: "SailingDealsNavigation";
  analytics: ClientSideAnalytics;
  nextText?: Maybe<Scalars["String"]>;
  previousText?: Maybe<Scalars["String"]>;
};

export type SailingDetailsResults = {
  __typename?: "SailingDetailsResults";
  itinerary: ItineraryDetails;
  itineraryDynamicMap: ItineraryDynamicMapDetails;
  itineraryMap: ItineraryStaticMapDetails;
  itineraryStaticMap: ItineraryStaticMapDetails;
};

export type SailingDialog = {
  __typename?: "SailingDialog";
  close?: Maybe<SailingAction>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use iconMediaItem instead */
  icon?: Maybe<CruiseMedia>;
  iconMediaItem?: Maybe<CruiseMediaItem>;
  itinerary: SailingItinerary;
  open?: Maybe<SailingAction>;
  sailingToken?: Maybe<Scalars["String"]>;
  select?: Maybe<CruiseDialog>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SailingItinerary = CruiseCommonItinerary & {
  __typename?: "SailingItinerary";
  callToAction?: Maybe<UiPrimaryButton>;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
};

export type SailingList = Sailing | SponsoredContentPlacement;

export type SailingNavigationBarTab = {
  __typename?: "SailingNavigationBarTab";
  clientSideAnalytics: ClientSideAnalytics;
  label: Scalars["String"];
  sectionName: Scalars["String"];
};

export type SailingOffer = {
  __typename?: "SailingOffer";
  a11yText?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  dialog?: Maybe<SailingDialog>;
  heading: Scalars["String"];
  subtitle?: Maybe<Scalars["String"]>;
  /** @deprecated Use a11yText instead */
  text?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SailingPagination = {
  __typename?: "SailingPagination";
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  showMoreButton?: Maybe<UiSecondaryButton>;
};

export type SailingSearch = {
  __typename?: "SailingSearch";
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use sailingList instead */
  list?: Maybe<Array<Sailing>>;
  liveAnnouncement?: Maybe<Scalars["String"]>;
  messagingCard: CruiseMessagingCard;
  noResultsMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  pagination: SailingPagination;
  relaxedSearchHeader?: Maybe<Scalars["String"]>;
  sailingList?: Maybe<Array<SailingList>>;
  signInLinkAppended?: Maybe<Scalars["Boolean"]>;
};

export type SailingSearchResults = {
  __typename?: "SailingSearchResults";
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  memberSignInBanner?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated Use the messaggingCard inside SailingSearch class instead. */
  messagingCard: CruiseMessagingCard;
  pageTitle: Scalars["String"];
  sailings: SailingSearch;
  termsAndConditions: CruiseSubsection;
};

export interface SavePrefRequestInput {
  canSell: Scalars["Boolean"];
}

export type SavePrefResponse = {
  __typename?: "SavePrefResponse";
  success: Scalars["Boolean"];
};

export interface SaveReviewAnswersRequestInput {
  orderId?: InputMaybe<Scalars["String"]>;
  orderLineId?: InputMaybe<Scalars["String"]>;
  reviewAnswers?: InputMaybe<Array<ReviewAnswerInput>>;
  reviewId?: InputMaybe<Scalars["String"]>;
}

export type SavedItem = {
  __typename?: "SavedItem";
  id?: Maybe<Scalars["String"]>;
  productId: Scalars["String"];
  productType?: Maybe<ProductType>;
};

export type ScreenType = "ADJUSTMENT" | "CANCEL" | "REVIEW";

export type SearchAmountModel = {
  __typename?: "SearchAmountModel";
  amount: Scalars["Float"];
  currency: Scalars["String"];
};

export type SearchCarousel = {
  __typename?: "SearchCarousel";
  options?: Maybe<Array<SearchCarouselModel>>;
  title?: Maybe<Scalars["String"]>;
};

export type SearchCarouselModel = {
  __typename?: "SearchCarouselModel";
  description?: Maybe<Scalars["String"]>;
  href?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars["String"]>;
  primaryHeading?: Maybe<Scalars["String"]>;
};

export type SearchClickstreamEvents = {
  __typename?: "SearchClickstreamEvents";
  searchResultsViewed?: Maybe<SearchResultsViewed>;
};

export type SearchCriteria = {
  __typename?: "SearchCriteria";
  dateRange: PropertyDateRange;
  destination: Destination;
  filters?: Maybe<PropertySearchFilters>;
  marketing?: Maybe<PropertyMarketingInfo>;
  originalDateRange?: Maybe<PropertyDateRange>;
  privacyTrackingState?: Maybe<PrivacyTrackingState>;
  propertyShopOptions?: Maybe<PropertyShopOptions>;
  resolvedDateRange?: Maybe<PropertyDateRange>;
  rooms: Array<Room>;
  searchId?: Maybe<Scalars["String"]>;
  searchIntent?: Maybe<SearchIntent>;
  searchOptions?: Maybe<Array<PropertySearchOptions>>;
  searchPagination?: Maybe<Pagination>;
  shopWithPoints: Scalars["Boolean"];
  sort?: Maybe<PropertySort>;
};

export interface SearchCriteriaSelectionsInput {
  id: Scalars["String"];
  value: Scalars["String"];
}

export type SearchFormAdaptExSuccessEvent = {
  __typename?: "SearchFormAdaptExSuccessEvent";
  datePrefill?: Maybe<EgUisMicroMessage>;
};

export type SearchFormDurationSpanField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "SearchFormDurationSpanField";
    accessibilityTemplate?: Maybe<EgdsLocalizedText>;
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"];
    errorMessage?: Maybe<Scalars["String"]>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export type SearchFormMessaging = {
  __typename?: "SearchFormMessaging";
  icon?: Maybe<Icon>;
  moreInfoTrigger?: Maybe<EgdsMoreInfoTrigger>;
  text?: Maybe<SearchFormMessagingText>;
};

export type SearchFormMessagingText = {
  __typename?: "SearchFormMessagingText";
  replacementTexts: Array<Scalars["String"]>;
  template: Scalars["String"];
};

export type SearchFormProduct = {
  __typename?: "SearchFormProduct";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  targetURI?: Maybe<Scalars["String"]>;
  type: SearchFormProductType;
};

export type SearchFormProductSelector = {
  __typename?: "SearchFormProductSelector";
  products: Array<SearchFormProduct>;
};

export type SearchFormProductType = "ACTIVITIES" | "CARS" | "CRUISES" | "FLIGHTS" | "LODGING" | "PACKAGES";

export type SearchFormResults = {
  __typename?: "SearchFormResults";
  activity: EgdsSearchForm;
  car: EgdsSearchForm;
  cruise: EgdsSearchForm;
  flight: EgdsSearchForm;
  groundTransportation: EgdsSearchForm;
  lodging: EgdsSearchForm;
  packages: EgdsSearchForm;
  productSelector: SearchFormProductSelector;
};

export type SearchFormResultsActivityArgs = {
  activitySearchCriteria?: InputMaybe<ActivitiySearchCriteriaInput>;
};

export type SearchFormResultsCarArgs = {
  carSearchCriteria?: InputMaybe<CarSearchCriteriaInput>;
};

export type SearchFormResultsCruiseArgs = {
  cruiseSearchCriteria?: InputMaybe<CruiseSearchCriteriaInput>;
};

export type SearchFormResultsFlightArgs = {
  disabledCabins?: InputMaybe<Array<FlightsCabinClass>>;
  features?: InputMaybe<Array<Scalars["String"]>>;
  fixedTravelers?: InputMaybe<Scalars["Boolean"]>;
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
};

export type SearchFormResultsGroundTransportationArgs = {
  groundTransportationSearchCriteria?: InputMaybe<ProductShoppingCriteriaInput>;
};

export type SearchFormResultsLodgingArgs = {
  features?: InputMaybe<Array<Scalars["String"]>>;
  propertySearchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type SearchFormResultsPackagesArgs = {
  packageSearchCriteria?: InputMaybe<PackageSearchCriteriaInput>;
  packageType?: InputMaybe<PackageType>;
};

export type SearchHelpArticlesView = HelpArticleSearchErrorView | MoreHelpArticles;

export type SearchHistoryDayRange = "SEVEN_DAYS" | "THREE_DAYS";

export type SearchHistoryDestinationType = "METRO_CITY";

export type SearchHistoryDisplayData = {
  __typename?: "SearchHistoryDisplayData";
  image?: Maybe<Image>;
  localizedDateRange?: Maybe<Scalars["String"]>;
  localizedTripName: Scalars["String"];
};

export type SearchHistoryFilterCriteria = {
  __typename?: "SearchHistoryFilterCriteria";
  endDate: Scalars["String"];
  regionId: Scalars["String"];
  regionType?: Maybe<Scalars["String"]>;
  startDate: Scalars["String"];
};

export interface SearchHistoryFilterCriteriaInput {
  endDate: Scalars["String"];
  regionId: Scalars["String"];
  regionType?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["String"];
}

export interface SearchHistoryGroupingCriteriaInput {
  dayRange?: InputMaybe<SearchHistoryDayRange>;
  destinationType?: InputMaybe<SearchHistoryDestinationType>;
}

export type SearchIntent = {
  __typename?: "SearchIntent";
  interests?: Maybe<Array<Interest>>;
  semDtl?: Maybe<Scalars["String"]>;
  semdtl?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  userIntent?: Maybe<Scalars["String"]>;
};

export interface SearchIntentInput {
  interests?: InputMaybe<Array<InterestInput>>;
  semDtl?: InputMaybe<Scalars["String"]>;
  semdtl?: InputMaybe<Scalars["String"]>;
  theme?: InputMaybe<Scalars["String"]>;
  userIntent?: InputMaybe<Scalars["String"]>;
}

export interface SearchOfferInput {
  offerDetails?: InputMaybe<Array<SelectedValueInput>>;
  offerPrice?: InputMaybe<SearchOfferPriceInput>;
  ratePlanId?: InputMaybe<Scalars["String"]>;
  roomTypeId?: InputMaybe<Scalars["String"]>;
}

export interface SearchOfferPriceInput {
  multiItemPriceToken?: InputMaybe<Scalars["String"]>;
  offerTimestamp?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<MoneyInput>;
}

export type SearchResultMessagingCard = SearchResultMessagingModule | ShoppingFiltersMessagingCard;

export type SearchResultMessagingHeader = EgdsHeading;

export type SearchResultMessagingModule = {
  __typename?: "SearchResultMessagingModule";
  header?: Maybe<SearchResultMessagingHeader>;
};

export type SearchResultMessagingSheet = ShoppingFiltersMessagingSheet;

export type SearchResultsViewed = {
  __typename?: "SearchResultsViewed";
  event: EgClickstreamEvent;
  search_request: LodgingSearchRequest;
};

export type SeatChoiceType = "SEAT_CHOICE";

export type SeatMapCabinDimensionDetails = {
  __typename?: "SeatMapCabinDimensionDetails";
  aislesCount: Scalars["Int"];
  columnsCount: Scalars["Int"];
  exitsCount: Scalars["Int"];
  rowsCount: Scalars["Int"];
  wingDetails?: Maybe<SeatMapCabinWingDetails>;
};

export type SeatMapCabinWingDetails = {
  __typename?: "SeatMapCabinWingDetails";
  endRow: Scalars["Int"];
  startRow: Scalars["Int"];
};

export type SeatSegment = {
  __typename?: "SeatSegment";
  fareBasisCode: Scalars["String"];
  identifier: Scalars["String"];
  operatingCarrierCode: Scalars["String"];
};

export type SeatVariant =
  | "ACCESSIBLE"
  | "AISLE"
  | "AVAILABLE"
  | "COLUMN"
  | "EXIT"
  | "GALLEY"
  | "PREFERRED"
  | "RESTROOM"
  | "ROW"
  | "SELECTED"
  | "UNAVAILABLE";

export type SecondaryInfo = {
  __typename?: "SecondaryInfo";
  message: Array<Scalars["String"]>;
  title?: Maybe<EgdsStylizedText>;
};

export type SecondaryReasonList = {
  __typename?: "SecondaryReasonList";
  secondaryReason: EgdsBasicSelect;
  secondaryReasonOptions: Array<SecondaryReasonOption>;
};

export type SecondaryReasonOption = {
  __typename?: "SecondaryReasonOption";
  options: Array<EgdsBasicOption>;
  primaryReason: Scalars["String"];
};

export type SectionRow = {
  __typename?: "SectionRow";
  rowContent?: Maybe<Array<Maybe<PortableTextWithLink>>>;
};

export type SectionType = "ORDERED_LIST" | "PARAGRAPH" | "UNORDERED_LIST";

export type SeeAllCategoriesDialog = EgdsDialog & {
  __typename?: "SeeAllCategoriesDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  contents?: Maybe<Array<EgdsCard>>;
  toolbar: EgdsDialogToolbar;
};

export type SeeAllCategoriesLink = {
  __typename?: "SeeAllCategoriesLink";
  dialog?: Maybe<SeeAllCategoriesDialog>;
  trigger: SeeAllCategoriesTrigger;
};

export type SeeAllCategoriesTrigger = {
  __typename?: "SeeAllCategoriesTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  label: Scalars["String"];
};

export type SeeMoreAction = AmenitiesDialog | CleanlinessDialog | MoreInfoDialog;

export type SelectFopDataModule = {
  __typename?: "SelectFOPDataModule";
  binSpec?: Maybe<BinSpec>;
  checkoutIdentifier: PaymentCheckoutIdentifier;
  updatedPaymentCheckoutElement: PaymentUiComponent;
};

export type SelectPackageActionInput = {
  __typename?: "SelectPackageActionInput";
  packageOfferId: Scalars["String"];
};

export type SelectPackageMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "SelectPackageMultiItemShoppingAction";
  packageOfferId: Scalars["String"];
  shoppingContext: ShoppingContext;
};

export type SelectedAncillaryTemplate = {
  __typename?: "SelectedAncillaryTemplate";
  template: EgdsLocalizedText;
  type: SelectedAncillaryTemplateType;
};

export type SelectedAncillaryTemplateType = "MULTIPLE" | "NONE" | "ONE";

export type SelectedValue = {
  __typename?: "SelectedValue";
  id: Scalars["String"];
  value: Scalars["String"];
};

export interface SelectedValueInput {
  id: Scalars["String"];
  value: Scalars["String"];
}

export type SelfReferencedUri = Uri & {
  __typename?: "SelfReferencedURI";
  fragment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type SerpMetadata = {
  __typename?: "SerpMetadata";
  priceRange?: Maybe<LodgingPlainMessage>;
  priceRating?: Maybe<LodgingPlainMessage>;
};

export type ServiceActionListItem = {
  __typename?: "ServiceActionListItem";
  connectDisconnectButton?: Maybe<Array<ConnectivitySettingsButton>>;
  icon?: Maybe<Icon>;
  serviceId: Scalars["String"];
  showBorder?: Maybe<Scalars["Boolean"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ServiceAutomationAction = ServiceAutomationOpenSheetAction | ServiceAutomationVirtualAgentButtonAction;

export type ServiceAutomationButton = {
  __typename?: "ServiceAutomationButton";
  action: ServiceAutomationAction;
  button: EgdsButton;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
};

export type ServiceAutomationElement = EgdsHeading | EgdsPlainText | EgdsStylizedText | ServiceAutomationButton;

export type ServiceAutomationOpenSheetAction = {
  __typename?: "ServiceAutomationOpenSheetAction";
  sheet: ServiceAutomationSheet;
};

export type ServiceAutomationQuery = {
  __typename?: "ServiceAutomationQuery";
  tripDetailsSupportRequests?: Maybe<ServiceAutomationSupportRequestsView>;
};

export type ServiceAutomationQueryTripDetailsSupportRequestsArgs = {
  input: TripDetailsSupportRequestsInput;
};

export type ServiceAutomationSection = {
  __typename?: "ServiceAutomationSection";
  elements?: Maybe<Array<ServiceAutomationElement>>;
};

export type ServiceAutomationSectionContainer = {
  __typename?: "ServiceAutomationSectionContainer";
  heading?: Maybe<Scalars["String"]>;
  sections?: Maybe<Array<ServiceAutomationSection>>;
};

export type ServiceAutomationSheet = {
  __typename?: "ServiceAutomationSheet";
  containers: Array<ServiceAutomationSectionContainer>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  sheet: EgdsSheet;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<ServiceAutomationToolbar>;
};

export type ServiceAutomationSupportRequestsView = {
  __typename?: "ServiceAutomationSupportRequestsView";
  button: ServiceAutomationButton;
  requestInformation?: Maybe<Scalars["String"]>;
};

export type ServiceAutomationToolbar = {
  __typename?: "ServiceAutomationToolbar";
  elevation?: Maybe<ServiceAutomationToolbarElevation>;
  navigationContent?: Maybe<ServiceAutomationToolbarNavigationContent>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  titleTag?: Maybe<EgdsHeadingStyle>;
};

export type ServiceAutomationToolbarElevation = "FLAT" | "FLOATING";

export type ServiceAutomationToolbarNavType = "BACK" | "CLOSE" | "EXPAND_UP";

export type ServiceAutomationToolbarNavigationContent = {
  __typename?: "ServiceAutomationToolbarNavigationContent";
  navType?: Maybe<ServiceAutomationToolbarNavType>;
};

export interface ServiceAutomationVirtualAgent {
  clientOverrides?: Maybe<ServiceAutomationVirtualAgentClientOverrides>;
}

export type ServiceAutomationVirtualAgentAnalytics = {
  __typename?: "ServiceAutomationVirtualAgentAnalytics";
  analytics: ClientSideAnalytics;
  event: Scalars["String"];
};

export type ServiceAutomationVirtualAgentButtonAction = ServiceAutomationVirtualAgent & {
  __typename?: "ServiceAutomationVirtualAgentButtonAction";
  clientOverrides?: Maybe<ServiceAutomationVirtualAgentClientOverrides>;
};

export type ServiceAutomationVirtualAgentClientOverrides = {
  __typename?: "ServiceAutomationVirtualAgentClientOverrides";
  conversationProperties?: Maybe<ServiceAutomationVirtualAgentConversationProperties>;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]>;
  intent?: Maybe<Scalars["String"]>;
  intentArguments?: Maybe<Array<ServiceAutomationVirtualAgentIntentArgument>>;
  subscribedEvents?: Maybe<Array<Scalars["String"]>>;
};

export type ServiceAutomationVirtualAgentConversationProperties = {
  __typename?: "ServiceAutomationVirtualAgentConversationProperties";
  launchPoint?: Maybe<Scalars["String"]>;
  pageName?: Maybe<Scalars["String"]>;
  skipWelcome?: Maybe<Scalars["String"]>;
};

export type ServiceAutomationVirtualAgentInit = ServiceAutomationVirtualAgent & {
  __typename?: "ServiceAutomationVirtualAgentInit";
  analytics?: Maybe<Array<ServiceAutomationVirtualAgentAnalytics>>;
  applicationName: Scalars["String"];
  clientOverrides: ServiceAutomationVirtualAgentClientOverrides;
  pageName: Scalars["String"];
};

export type ServiceAutomationVirtualAgentIntentArgument = {
  __typename?: "ServiceAutomationVirtualAgentIntentArgument";
  id: Scalars["String"];
  value: Scalars["String"];
};

export type ServiceChargeDetail = {
  __typename?: "ServiceChargeDetail";
  charges: Scalars["String"];
  closeIcon: Icon;
  infoIcon: Icon;
  label: Scalars["String"];
  text: Scalars["String"];
};

export type SettingProviderTypeaheadEssAdapterConfig = {
  __typename?: "SettingProviderTypeaheadESSAdapterConfig";
  client: Scalars["String"];
  dest: Scalars["Boolean"];
  domain?: Maybe<Scalars["String"]>;
  domainId?: Maybe<Scalars["String"]>;
  features: Scalars["String"];
  lob: Scalars["String"];
  locale: Scalars["String"];
  maxResults: Scalars["Int"];
  regionType: Scalars["Int"];
  siteId: Scalars["Int"];
  userId: Scalars["String"];
};

export type ShareInfo = {
  __typename?: "ShareInfo";
  facebookAppId: Scalars["String"];
  linkCopiedMessage: Scalars["String"];
  linkList: Array<LodgingLinkMessage>;
  menuName: Scalars["String"];
  sharedText: Scalars["String"];
};

export type SheetState = "COLLAPSED" | "FULL" | "HALF";

export interface ShopInsurtechOffersRequestInput {
  coveredOfferItemsId?: InputMaybe<Scalars["String"]>;
  insurtechClientId: InsurtechClientId;
  residencyDetails?: InputMaybe<ResidencyDetailsInput>;
  residencyState?: InputMaybe<ResidencyState>;
  sessionIdentifier?: InputMaybe<Scalars["String"]>;
}

export type ShopInsurtechOffersResponse = {
  __typename?: "ShopInsurtechOffersResponse";
  badge?: Maybe<EgdsBadge>;
  contentSections: Array<InsurtechSection>;
  egClickstreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  /** @deprecated Deprecating as Footer Section will come as an element in Content sections */
  footerSection: InsurtechRadioGroupWrapper;
  heading: Scalars["String"];
  residencyCard?: Maybe<InsurtechMessagingCard>;
};

export type ShoppedProduct = {
  __typename?: "ShoppedProduct";
  products: MultiItemProducts;
  shoppingPath: ShoppingPathType;
};

export interface ShoppedProductInput {
  products: MultiItemProductsInput;
  shoppingPath: ShoppingPathType;
}

export interface ShoppingAction {
  accessibility?: Maybe<Scalars["String"]>;
  actionId: Scalars["String"];
  analytics: ClientSideAnalytics;
}

export type ShoppingActionableIcon = {
  __typename?: "ShoppingActionableIcon";
  actionId: Scalars["String"];
  icon: Icon;
};

export interface ShoppingAppliedFilter {
  filter?: Maybe<EgdsPill>;
  nextSearchCriteria?: Maybe<ShoppingSearchCriteria>;
}

export type ShoppingBasicFunctionParams = {
  __typename?: "ShoppingBasicFunctionParams";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type ShoppingButton = {
  __typename?: "ShoppingButton";
  accessibility?: Maybe<Scalars["String"]>;
  actionId: Scalars["String"];
  buttonType: ShoppingButtonType;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type ShoppingButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type ShoppingCarouselButton = {
  __typename?: "ShoppingCarouselButton";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type ShoppingCarouselFormat = {
  __typename?: "ShoppingCarouselFormat";
  itemsVisible?: Maybe<Scalars["Int"]>;
  nextButton?: Maybe<ShoppingCarouselButton>;
  previousButton?: Maybe<ShoppingCarouselButton>;
};

export type ShoppingContext = {
  __typename?: "ShoppingContext";
  multiItem?: Maybe<MultiItemContext>;
};

export interface ShoppingContextInput {
  multiItem?: InputMaybe<MultiItemContextInput>;
}

export type ShoppingCopyLink = {
  __typename?: "ShoppingCopyLink";
  analytics?: Maybe<ClientSideAnalytics>;
  copiedUrl: Scalars["String"];
  icon?: Maybe<Icon>;
  name: Scalars["String"];
  toast: EgdsToast;
};

export type ShoppingDateField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingDateField";
  analytics: ClientSideAnalytics;
  end?: Maybe<DateValue>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
  start?: Maybe<DateValue>;
};

export type ShoppingDisplayPrice = {
  __typename?: "ShoppingDisplayPrice";
  clickActionId?: Maybe<Scalars["String"]>;
  price: ShoppingFormattedMoney;
  role: Scalars["String"];
};

export type ShoppingDropdownField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingDropdownField";
  id?: Maybe<Scalars["String"]>;
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingEnrichedMessage = {
  __typename?: "ShoppingEnrichedMessage";
  state?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type ShoppingFiltersMessagingCard = {
  __typename?: "ShoppingFiltersMessagingCard";
  filters?: Maybe<Array<BasicShoppingAppliedFilter>>;
  heading?: Maybe<ShoppingFiltersMessagingHeading>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
};

export type ShoppingFiltersMessagingHeading = {
  __typename?: "ShoppingFiltersMessagingHeading";
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ShoppingFiltersMessagingSheet = {
  __typename?: "ShoppingFiltersMessagingSheet";
  applyAction?: Maybe<UiPrimaryButton>;
  clearAll?: Maybe<UiSecondaryButton>;
  filters?: Maybe<ShoppingMessagingSheetFilters>;
  heading?: Maybe<ShoppingFiltersMessagingHeading>;
  sheet?: Maybe<EgdsSheet>;
};

export type ShoppingFormattedMoney = {
  __typename?: "ShoppingFormattedMoney";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  formatted: Scalars["String"];
};

export type ShoppingFunctionType = "CALLBACK" | "SHARED_UI";

export type ShoppingGlobal = {
  __typename?: "ShoppingGlobal";
  classType?: Maybe<Scalars["String"]>;
  destinationCode: Scalars["String"];
  destinationName?: Maybe<Scalars["String"]>;
  gaia?: Maybe<Gaia>;
  loyaltyMembership?: Maybe<Scalars["String"]>;
  numAdults?: Maybe<Scalars["Int"]>;
  numChildren?: Maybe<Scalars["Int"]>;
  numInfants?: Maybe<Scalars["Int"]>;
  numSeniors?: Maybe<Scalars["Int"]>;
  numYouth?: Maybe<Scalars["Int"]>;
  originCode?: Maybe<Scalars["String"]>;
  originName?: Maybe<Scalars["String"]>;
  shoppingHotel: ShoppingHotel;
  travelers?: Maybe<Array<Traveler>>;
  tripEndDate?: Maybe<Scalars["String"]>;
  tripEndTime?: Maybe<Scalars["String"]>;
  tripStartDate?: Maybe<Scalars["String"]>;
  tripStartTime?: Maybe<Scalars["String"]>;
};

export type ShoppingGlobalData = {
  __typename?: "ShoppingGlobalData";
  messageContent: ShoppingGlobal;
  schemaName: Scalars["String"];
};

export type ShoppingHotel = {
  __typename?: "ShoppingHotel";
  numRooms: Scalars["Int"];
  rooms: Array<Rooms>;
};

export type ShoppingInvokeFunction = ShoppingAction & {
  __typename?: "ShoppingInvokeFunction";
  accessibility?: Maybe<Scalars["String"]>;
  actionId: Scalars["String"];
  analytics: ClientSideAnalytics;
  functionType: ShoppingFunctionType;
  name: Scalars["String"];
  paramsId?: Maybe<Scalars["String"]>;
};

export type ShoppingJoinListContainer = {
  __typename?: "ShoppingJoinListContainer";
  actions?: Maybe<Array<ShoppingAction>>;
  overlays?: Maybe<Array<ShoppingOverlayContainer>>;
};

export type ShoppingLink = {
  __typename?: "ShoppingLink";
  actionId: Scalars["String"];
  text: Scalars["String"];
  trailingIcon?: Maybe<Icon>;
};

export type ShoppingListingContainer = {
  __typename?: "ShoppingListingContainer";
  clickstreamAnalytics?: Maybe<ShoppingListingContainerClickstreamEvents>;
  format?: Maybe<ShoppingListingContainerFormat>;
  heading?: Maybe<ShoppingListingContainerHeading>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<ShoppingListingContainerItems>;
  listingId?: Maybe<Scalars["String"]>;
};

export type ShoppingListingContainerAdditionalContext = {
  __typename?: "ShoppingListingContainerAdditionalContext";
  componentId: Scalars["String"];
  componentName: Scalars["String"];
  componentPosition: Scalars["Int"];
  contentId: Scalars["String"];
};

export type ShoppingListingContainerClickstreamEvents = {
  __typename?: "ShoppingListingContainerClickstreamEvents";
  additionalContext: ShoppingListingContainerAdditionalContext;
  contentListAttributes: Array<ShoppingListingContentListAttributes>;
  eventContext: Array<ShoppingListingContainerContextValue>;
  experienceTemplateId?: Maybe<Scalars["String"]>;
  presented?: Maybe<EgClickstreamEvent>;
  searchId?: Maybe<Scalars["String"]>;
  selected?: Maybe<EgClickstreamEvent>;
};

export type ShoppingListingContainerContextValue = {
  __typename?: "ShoppingListingContainerContextValue";
  id: Scalars["String"];
  type: Scalars["String"];
};

export type ShoppingListingContainerFormat = ShoppingCarouselFormat;

export type ShoppingListingContainerHeading = EgdsHeading | ShoppingListingHeader;

export type ShoppingListingContainerItems = LodgingCard;

export type ShoppingListingContentListAttributes =
  | DestinationListContentEventAttributes
  | ProductListContentEventAttributes;

export type ShoppingListingHeader = {
  __typename?: "ShoppingListingHeader";
  disclaimer?: Maybe<LodgingPlainDialog>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ShoppingLoadSharedUi = {
  __typename?: "ShoppingLoadSharedUI";
  componentName: Scalars["String"];
};

export type ShoppingMessagingSheetFilters = ShoppingMultiSelectionField;

export type ShoppingMultiSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMultiSelectionField";
  accessibility?: Maybe<Scalars["String"]>;
  expando?: Maybe<ShoppingSelectionExpando>;
  id?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]>;
  primaryIcon?: Maybe<Icon>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingMultiSelectionStackedTileField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMultiSelectionStackedTileField";
  expando?: Maybe<ShoppingSelectionExpando>;
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingMultiSelectionTileField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMultiSelectionTileField";
  expando?: Maybe<ShoppingSelectionExpando>;
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingMutexField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMutexField";
  accessibility?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<ShoppingMutexFieldOption>>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingMutexFieldOption = {
  __typename?: "ShoppingMutexFieldOption";
  option: ShoppingSortAndFilterField;
  value: ShoppingSelectableFilterOption;
};

export type ShoppingNavigateToOverlay = ShoppingAction & {
  __typename?: "ShoppingNavigateToOverlay";
  accessibility?: Maybe<Scalars["String"]>;
  actionId: Scalars["String"];
  analytics: ClientSideAnalytics;
  overlayId: Scalars["String"];
};

export type ShoppingNavigateToUri = ShoppingAction & {
  __typename?: "ShoppingNavigateToURI";
  accessibility?: Maybe<Scalars["String"]>;
  actionId: Scalars["String"];
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"];
};

export type ShoppingOverlay = EgdsSheet;

export type ShoppingOverlayContainer = {
  __typename?: "ShoppingOverlayContainer";
  contentId: Scalars["String"];
  overlay: ShoppingOverlay;
  overlayId: Scalars["String"];
};

export type ShoppingPathPrimersQuery = MultiItemDetailsPrimersQuery & {
  __typename?: "ShoppingPathPrimersQuery";
  primers: MishopDetailsUiPrimer;
};

export type ShoppingPathType = "ATTACH" | "PACKAGE" | "STANDALONE";

export type ShoppingPillSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingPillSelectionField";
  id?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingPrice = {
  __typename?: "ShoppingPrice";
  displayMessages?: Maybe<Array<ShoppingPriceDisplayMessage>>;
};

export type ShoppingPriceDisplayMessage = {
  __typename?: "ShoppingPriceDisplayMessage";
  lineItems: Array<ShoppingPriceDisplayMessageItem>;
};

export type ShoppingPriceDisplayMessageItem = ShoppingDisplayPrice | ShoppingEnrichedMessage;

export type ShoppingProductCardContent =
  | ShoppingLoadSharedUi
  | ShoppingProductCardSheetListContent
  | ShoppingProductCardSheetSectionalContent
  | ShoppingProductCardSheetTabListContent
  | ShoppingProductCardSheetTabularContent;

export type ShoppingProductCardContentContainer = {
  __typename?: "ShoppingProductCardContentContainer";
  content: ShoppingProductCardContent;
  contentId: Scalars["String"];
};

export type ShoppingProductCardFooter = ShoppingProductCardFooterLineItem;

export type ShoppingProductCardFooterAction = ShoppingLink;

export type ShoppingProductCardFooterLineItem = {
  __typename?: "ShoppingProductCardFooterLineItem";
  actions?: Maybe<Array<ShoppingProductCardFooterAction>>;
  texts?: Maybe<Array<EgdsStylizedText>>;
};

export type ShoppingProductCardFunctionParametersContainer = {
  __typename?: "ShoppingProductCardFunctionParametersContainer";
  keyValueParams?: Maybe<Array<ShoppingBasicFunctionParams>>;
  paramsId: Scalars["String"];
};

export type ShoppingProductCardFunctionParametersContainers = {
  __typename?: "ShoppingProductCardFunctionParametersContainers";
  keyValueParams?: Maybe<Array<ShoppingBasicFunctionParams>>;
  paramsId: Scalars["String"];
};

export type ShoppingProductCardSheetListBodySection = {
  __typename?: "ShoppingProductCardSheetListBodySection";
  heading?: Maybe<ShoppingStylizedIconText>;
  items: EgdsTextList;
};

export type ShoppingProductCardSheetListContent = {
  __typename?: "ShoppingProductCardSheetListContent";
  body?: Maybe<Array<ShoppingProductCardSheetListBodySection>>;
  footer?: Maybe<ShoppingProductCardSheetPriceAndButtonFooter>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ShoppingProductCardSheetPriceAndButtonFooter = {
  __typename?: "ShoppingProductCardSheetPriceAndButtonFooter";
  /** @deprecated Marking this as deprecated because we want this button nullable and not show Book button on More details link for Ground Transfer. Also, making change here would be a breaking change as many clients are using it. */
  button: ShoppingButton;
  buttonV2?: Maybe<ShoppingButton>;
  oldPriceSection?: Maybe<PropertyPrice>;
  /** @deprecated While we want to eventually use this, it doesn't support all usecases yet, so we need to use the oldPriceSection until then. Marking this as deprecated for now */
  priceSection: ShoppingPrice;
};

export type ShoppingProductCardSheetSection = {
  __typename?: "ShoppingProductCardSheetSection";
  lineItems?: Maybe<Array<ShoppingProductCardSheetSectionLineItem>>;
};

export type ShoppingProductCardSheetSectionLineItem = {
  __typename?: "ShoppingProductCardSheetSectionLineItem";
  icons?: Maybe<Array<Icon>>;
  price?: Maybe<ShoppingPrice>;
  texts?: Maybe<Array<EgdsStylizedText>>;
};

export type ShoppingProductCardSheetSectionalContent = {
  __typename?: "ShoppingProductCardSheetSectionalContent";
  header?: Maybe<Array<EgdsSpannableText>>;
  sections?: Maybe<Array<ShoppingProductCardSheetSection>>;
};

export type ShoppingProductCardSheetTabListContent = {
  __typename?: "ShoppingProductCardSheetTabListContent";
  content?: Maybe<Array<ShoppingProductCardSheetListBodySection>>;
};

export type ShoppingProductCardSheetTabularContent = {
  __typename?: "ShoppingProductCardSheetTabularContent";
  body: ShoppingTabs;
  footer?: Maybe<ShoppingProductCardSheetPriceAndButtonFooter>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ShoppingProductContent = {
  __typename?: "ShoppingProductContent";
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<ShoppingProductContentItem>;
  maxColumns: Scalars["Int"];
};

export type ShoppingProductContentElement = ShoppingProductContent;

export type ShoppingProductContentElementGroup = {
  __typename?: "ShoppingProductContentElementGroup";
  elements: Array<ShoppingProductContentElement>;
  maxColumns: Scalars["Int"];
};

export type ShoppingProductContentGraphicsItem = {
  __typename?: "ShoppingProductContentGraphicsItem";
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  leadingIcon?: Maybe<Icon>;
  state?: Maybe<Scalars["String"]>;
  structuredData?: Maybe<SeoStructuredData>;
  text: Scalars["String"];
  trailingIcon?: Maybe<ShoppingActionableIcon>;
};

export type ShoppingProductContentItem = EgdsPlainText | ShoppingProductContentGraphicsItem;

export type ShoppingProductContentSection = {
  __typename?: "ShoppingProductContentSection";
  action?: Maybe<ShoppingProductContentSectionAction>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  subSections: Array<ShoppingProductContentSubSection>;
};

export type ShoppingProductContentSectionAction = ShoppingLink;

export type ShoppingProductContentSectionGroup = {
  __typename?: "ShoppingProductContentSectionGroup";
  contents?: Maybe<Array<ShoppingProductContentSectionGroupContainer>>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sections: Array<ShoppingProductContentSection>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type ShoppingProductContentSectionGroupContainer = {
  __typename?: "ShoppingProductContentSectionGroupContainer";
  content?: Maybe<Array<ShoppingProductContentSectionGroupContent>>;
  contentId: Scalars["String"];
};

export type ShoppingProductContentSectionGroupContent = ShoppingProductContentSection;

export type ShoppingProductContentSubSection = {
  __typename?: "ShoppingProductContentSubSection";
  elements: Array<ShoppingProductContentElementGroup>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  maxColumns: Scalars["Int"];
};

export type ShoppingProductHeader = {
  __typename?: "ShoppingProductHeader";
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  subText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export interface ShoppingQuickAccessFilter {
  filterPill?: Maybe<EgdsPill>;
}

export type ShoppingRangeCharacteristics = {
  __typename?: "ShoppingRangeCharacteristics";
  labels?: Maybe<Array<ShoppingRangeLabel>>;
  max: Scalars["Int"];
  maxLabel: Scalars["String"];
  min: Scalars["Int"];
  minLabel: Scalars["String"];
  minStepDistance?: Maybe<Scalars["Int"]>;
  step: Scalars["Int"];
};

export type ShoppingRangeField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingRangeField";
  id?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  range?: Maybe<ShoppingRangeFilterOption>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingRangeFilterOption = ShoppingSortAndFilterOption & {
  __typename?: "ShoppingRangeFilterOption";
  analytics?: Maybe<ClientSideAnalytics>;
  characteristics: ShoppingRangeCharacteristics;
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Replaced with UIGraphic */
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  maxAccessibility?: Maybe<Scalars["String"]>;
  minAccessibility?: Maybe<Scalars["String"]>;
  primary: Scalars["String"];
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]>;
  selected: RangeValue;
  sliderType: ShoppingSortAndFilterSliderType;
};

export type ShoppingRangeLabel = {
  __typename?: "ShoppingRangeLabel";
  label: Scalars["String"];
  value: Scalars["Int"];
};

export type ShoppingRangeTextInputField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingRangeTextInputField";
  id?: Maybe<Scalars["String"]>;
  lowerBoundAccessibility?: Maybe<Scalars["String"]>;
  maxValueAccessibility?: Maybe<EgdsLocalizedText>;
  maxValueAnalytics?: Maybe<ClientSideAnalytics>;
  maxValueInput: EgdsTextInputField;
  minValueAccessibility?: Maybe<EgdsLocalizedText>;
  minValueAnalytics?: Maybe<ClientSideAnalytics>;
  minValueInput: EgdsTextInputField;
  numberFormat?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  range: ShoppingRangeFilterOption;
  secondary?: Maybe<Scalars["String"]>;
  upperBoundAccessibility?: Maybe<Scalars["String"]>;
};

export type ShoppingSearchCriteria = {
  __typename?: "ShoppingSearchCriteria";
  booleans?: Maybe<Array<BooleanValue>>;
  counts?: Maybe<Array<NumberValue>>;
  dates?: Maybe<Array<DateValue>>;
  ranges?: Maybe<Array<RangeValue>>;
  selections?: Maybe<Array<SelectedValue>>;
};

export interface ShoppingSearchCriteriaInput {
  booleans?: InputMaybe<Array<BooleanValueInput>>;
  counts?: InputMaybe<Array<NumberValueInput>>;
  dates?: InputMaybe<Array<DateValueInput>>;
  ranges?: InputMaybe<Array<RangeValueInput>>;
  selections?: InputMaybe<Array<SelectedValueInput>>;
}

export type ShoppingSelectableFilterOption = ShoppingSortAndFilterOption & {
  __typename?: "ShoppingSelectableFilterOption";
  accessibility?: Maybe<Scalars["String"]>;
  /** @deprecated Use selectAnalytics and deselectAnalytics instead */
  analytics: ClientSideAnalytics;
  default: Scalars["Boolean"];
  defaultRegionId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  deselectAnalytics: ClientSideAnalytics;
  disabled: Scalars["Boolean"];
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Replaced with UIGraphic */
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  nestedOptions: Array<ShoppingSelectableFilterOption>;
  primary: Scalars["String"];
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]>;
  selectAnalytics: ClientSideAnalytics;
  selected: Scalars["Boolean"];
  value: Scalars["String"];
};

export type ShoppingSelectedFilter = ShoppingAppliedFilter & {
  __typename?: "ShoppingSelectedFilter";
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  filter?: Maybe<EgdsPill>;
  nextSearchCriteria?: Maybe<ShoppingSearchCriteria>;
};

export type ShoppingSelectedFiltersField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingSelectedFiltersField";
  accessibility?: Maybe<EgdsLocalizedText>;
  primary?: Maybe<Scalars["String"]>;
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]>;
  selectedFiltersOrdering?: Maybe<Array<Scalars["String"]>>;
  shoppingSelectedFilters?: Maybe<Array<ShoppingSelectedFilter>>;
};

export type ShoppingSelectionExpando = {
  __typename?: "ShoppingSelectionExpando";
  /** @deprecated Use expandAnalytics and collapseAnalytics instead */
  analytics: ClientSideAnalytics;
  collapseAccessibilityLabel?: Maybe<Scalars["String"]>;
  collapseAnalytics: ClientSideAnalytics;
  collapseLabel: Scalars["String"];
  expandAccessibilityLabel?: Maybe<Scalars["String"]>;
  expandAnalytics: ClientSideAnalytics;
  expandLabel: Scalars["String"];
  minimalHeight?: Maybe<Scalars["Int"]>;
  threshold?: Maybe<Scalars["Int"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ShoppingSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingSelectionField";
  accessibility?: Maybe<Scalars["String"]>;
  expando?: Maybe<ShoppingSelectionExpando>;
  id?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingShareContent = {
  __typename?: "ShoppingShareContent";
  description: Scalars["String"];
  image?: Maybe<Image>;
  shareLinks: Array<ShoppingShareLink>;
};

export type ShoppingShareLink = ShoppingCopyLink | ShoppingSocialMediaLink;

export type ShoppingShareLinkAction = {
  __typename?: "ShoppingShareLinkAction";
  content?: Maybe<ShoppingShareContent>;
  sheet: EgdsSheet;
};

export type ShoppingShareLinks = {
  __typename?: "ShoppingShareLinks";
  action?: Maybe<ShoppingShareLinkAction>;
  button: UiTertiaryButton;
};

export type ShoppingSocialMediaLink = {
  __typename?: "ShoppingSocialMediaLink";
  analytics?: Maybe<ClientSideAnalytics>;
  embeddableSource: Scalars["String"];
  graphic?: Maybe<UiGraphic>;
  name: Scalars["String"];
  url: Scalars["String"];
};

export type ShoppingSortAndFilterAction = UiAction & {
  __typename?: "ShoppingSortAndFilterAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionType: ShoppingSortAndFilterActionType;
  analytics: ClientSideAnalytics;
};

export type ShoppingSortAndFilterActionType =
  | "CLEAR_DATA"
  | "CLOSE_AND_APPLY"
  | "CLOSE_AND_DO_NOT_APPLY"
  | "OPEN_MODAL";

export interface ShoppingSortAndFilterAppliedFilter {
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: UiRemovablePill;
}

export type ShoppingSortAndFilterButtonTrigger = ShoppingQuickAccessFilter & {
  __typename?: "ShoppingSortAndFilterButtonTrigger";
  analytics?: Maybe<ClientSideAnalytics>;
  container: ShoppingSortAndFilterContainer;
  filterPill?: Maybe<EgdsPill>;
};

export interface ShoppingSortAndFilterContainer {
  view: SortAndFiltersViewType;
}

export type ShoppingSortAndFilterDialogContainer = ShoppingSortAndFilterContainer & {
  __typename?: "ShoppingSortAndFilterDialogContainer";
  applyAction?: Maybe<UiFloatingActionButton>;
  revealAction?: Maybe<UiFloatingActionButton>;
  toolbar?: Maybe<UiToolbar>;
  view: SortAndFiltersViewType;
};

export interface ShoppingSortAndFilterField {
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
}

export interface ShoppingSortAndFilterOption {
  analytics?: Maybe<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated we are replacing icon with UIGraphic, which is a union type of Icon, Illustration and Mark */
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
}

export type ShoppingSortAndFilterQuickFilter = ShoppingQuickAccessFilter & {
  __typename?: "ShoppingSortAndFilterQuickFilter";
  analytics?: Maybe<ClientSideAnalytics>;
  applyAction?: Maybe<EgdsButton>;
  clearAll?: Maybe<EgdsButton>;
  fields: Array<ShoppingSortAndFilterField>;
  filterPill?: Maybe<EgdsPill>;
  resultCountLabel?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<UiToolbar>;
};

export type ShoppingSortAndFilterSection = {
  __typename?: "ShoppingSortAndFilterSection";
  fields: Array<ShoppingSortAndFilterField>;
  id?: Maybe<Scalars["String"]>;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ShoppingSortAndFilterSliderType = "DUAL" | "SINGLE";

export type ShoppingSortAndFilterToggleFilter = ShoppingQuickAccessFilter & {
  __typename?: "ShoppingSortAndFilterToggleFilter";
  analytics?: Maybe<ClientSideAnalytics>;
  filterPill?: Maybe<EgdsPill>;
  option: ShoppingSortAndFilterOption;
};

export type ShoppingSortAndFilters = {
  __typename?: "ShoppingSortAndFilters";
  /** @deprecated Use revealAction.badge */
  appliedFilterCount: Scalars["Int"];
  /** @deprecated Use `filtersApplied` instead for displaying removable pills for filters applied. */
  appliedFilters?: Maybe<Array<ShoppingSortAndFilterAppliedFilter>>;
  /** @deprecated Use `containers` instead. */
  applyAction?: Maybe<UiFloatingActionButton>;
  carousel?: Maybe<EgdsCarousel>;
  clearAll?: Maybe<UiTertiaryButton>;
  /** @deprecated Use toolbar.actions.secondaries */
  clearButtonTitle: Scalars["String"];
  /** @deprecated Use toolbar.actions.primary */
  close: Icon;
  containers?: Maybe<Array<ShoppingSortAndFilterContainer>>;
  filterSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  filtersApplied?: Maybe<Array<ShoppingAppliedFilter>>;
  footerLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use quickAccessFilters for filter pills view */
  pillSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  quickAccessFilters?: Maybe<Array<ShoppingQuickAccessFilter>>;
  /** @deprecated Use `containers` instead. */
  revealAction?: Maybe<UiFloatingActionButton>;
  scrollEndAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use sortSections and filterSections separately */
  sections: Array<ShoppingSortAndFilterSection>;
  sortSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  /** @deprecated Use sortSections with ShoppingTabSelectionField type for tabs view */
  tabSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  /** @deprecated Use toolbar.primary */
  title: Scalars["String"];
  toggleButton?: Maybe<EgdsStandardSwitch>;
  /** @deprecated Use `containers` instead. */
  toolbar?: Maybe<UiToolbar>;
};

export type ShoppingStepInputField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingStepInputField";
  accessibility?: Maybe<Scalars["String"]>;
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  id?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]>;
  stepInput: EgdsBasicStepInput;
};

export type ShoppingStylizedIconText = {
  __typename?: "ShoppingStylizedIconText";
  icon: Icon;
  text: Scalars["String"];
  theme: TextTheme;
};

export type ShoppingTab = {
  __typename?: "ShoppingTab";
  accessibility?: Maybe<Scalars["String"]>;
  clickAnalytics: ClientSideAnalytics;
  contentId: Scalars["String"];
  label: Scalars["String"];
  tabId: Scalars["String"];
};

export type ShoppingTabSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingTabSelectionField";
  id?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type ShoppingTabs = {
  __typename?: "ShoppingTabs";
  selectedTabId?: Maybe<Scalars["String"]>;
  tabs?: Maybe<Array<ShoppingTab>>;
};

export type ShoppingTextInputField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingTextInputField";
  action?: Maybe<ShoppingSortAndFilterAction>;
  analytics: ClientSideAnalytics;
  autoSuggest?: Maybe<AutoSuggestInfo>;
  clearButtonAnalytics?: Maybe<ClientSideAnalytics>;
  clearIcon?: Maybe<Icon>;
  icon?: Maybe<Icon>;
  id: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  /** @deprecated Use multiSelections */
  multiSelected?: Maybe<Array<Scalars["String"]>>;
  multiSelections?: Maybe<Array<ShoppingTextMultiSelection>>;
  placeholder?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
  selected?: Maybe<Scalars["String"]>;
  typeaheadInfo?: Maybe<TypeaheadInfo>;
};

export type ShoppingTextMultiSelection = {
  __typename?: "ShoppingTextMultiSelection";
  text: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type ShortList = {
  __typename?: "ShortList";
  button: LodgingToggle;
  /** @deprecated DO NOT USE! - ShortList clickTracking will be moved to UCS */
  clickTracking: Array<ShortListDataMap>;
  favorited: Scalars["Boolean"];
  /** @deprecated Don't use this, it's no longer necessary. */
  metaData: ShortListMetaData;
  removeUrl: Scalars["String"];
  saveUrl: Scalars["String"];
  toast?: Maybe<LodgingToastMessage>;
};

export type ShortListDataMap = AllHotelProductsData | ShoppingGlobalData;

export type ShortListMetaData = {
  __typename?: "ShortListMetaData";
  chkIn: Scalars["String"];
  chkOut: Scalars["String"];
  hotelId: Scalars["String"];
  roomConfiguration: Scalars["String"];
};

export type Shortlist = {
  __typename?: "Shortlist";
  details?: Maybe<Array<ShortlistItem>>;
  summaries?: Maybe<Array<ShortlistSummaryItem>>;
};

export type ShortlistDetailsArgs = {
  destinationFilter?: InputMaybe<ShortlistDestinationFilterInput>;
  productType?: InputMaybe<ProductType>;
};

export type ShortlistSummariesArgs = {
  productType?: InputMaybe<ProductType>;
};

export interface ShortlistDestinationFilterInput {
  destinationType: RegionType;
  regionId: Scalars["String"];
}

export type ShortlistFlight = ProductInfo & {
  __typename?: "ShortlistFlight";
  dateDisplayString?: Maybe<Scalars["String"]>;
  originDestination?: Maybe<Scalars["String"]>;
  routeType?: Maybe<RouteType>;
  type?: Maybe<ProductType>;
};

export type ShortlistGeneric = ProductInfo & {
  __typename?: "ShortlistGeneric";
  type?: Maybe<ProductType>;
};

export type ShortlistItem = {
  __typename?: "ShortlistItem";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageURL?: Maybe<Scalars["URL"]>;
  lastModifiedDate?: Maybe<DateTime>;
  link?: Maybe<Scalars["URL"]>;
  metadata?: Maybe<Array<ShortlistMetadata>>;
  productId: Scalars["String"];
  productInfo?: Maybe<ProductInfo>;
  productType?: Maybe<ProductType>;
  title?: Maybe<Scalars["String"]>;
};

export type ShortlistMetadata = {
  __typename?: "ShortlistMetadata";
  key: Scalars["String"];
  value: Scalars["String"];
};

export interface ShortlistMetadataInput {
  key: Scalars["String"];
  value: Scalars["String"];
}

export type ShortlistProperty = ProductInfo & {
  __typename?: "ShortlistProperty";
  guestRating?: Maybe<ShortlistRating>;
  numReviews?: Maybe<ShortlistReviews>;
  price?: Maybe<Money>;
  regionId?: Maybe<Scalars["String"]>;
  regionName?: Maybe<Scalars["String"]>;
  star?: Maybe<Scalars["Float"]>;
  type?: Maybe<ProductType>;
};

export type ShortlistRating = {
  __typename?: "ShortlistRating";
  ratingOverall?: Maybe<Scalars["Float"]>;
};

export type ShortlistReviews = {
  __typename?: "ShortlistReviews";
  total?: Maybe<Scalars["Int"]>;
};

export type ShortlistSummaryItem = {
  __typename?: "ShortlistSummaryItem";
  productId: Scalars["String"];
  productType?: Maybe<ProductType>;
};

export type ShowDropdownOptions = {
  __typename?: "ShowDropdownOptions";
  isDropDownVisible?: Maybe<ActionDetailsType>;
};

export interface SideSheetQueryContextInput {
  addOnRequested: Array<AddOnRequested>;
  isFirstLoad?: InputMaybe<Scalars["Boolean"]>;
  journeyContinuationId: Scalars["String"];
}

export type SideSheetType = "BREAKFAST" | "NONREFUNDABLE" | "PACKAGE";

export type SignIn = {
  __typename?: "SignIn";
  buttonAction: FlightsAction;
  heading: Scalars["String"];
};

export type SignalType =
  | "BOOKING_SUCCESS"
  | "CONFIGURATION_ADDED"
  | "DUPLICATE_REQUEST"
  | "INVALID_EMAIL"
  | "OFFER_ADDED"
  | "OFFER_CHANGED"
  | "OFFER_REMOVED"
  | "OFFER_UNAVAILABLE"
  | "OPERATION_FAILED"
  | "PAYMENT_ERROR"
  | "PAYMENT_METHODS_APPLIED"
  | "PAYMENT_METHOD_UPDATED"
  | "PRICE_ADJUSTED"
  | "PRICE_CHANGED"
  | "REQUEST_CONFLICT"
  | "SESSION_EXPIRED"
  | "SESSION_TOKEN_UPDATED"
  | "SIGNAL_TYPE_INVALID"
  | "UNRECOGNIZED"
  | "UNRECOVERABLE_FAILURE";

export type SignedInUser = {
  __typename?: "SignedInUser";
  accessibility: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export interface SimilarDestinationsCriteriaInput {
  destinationId: Scalars["String"];
  includeOrigin?: InputMaybe<Scalars["Boolean"]>;
}

export interface SimilarFlightsContextInput {
  offerIdentifier?: InputMaybe<Scalars["String"]>;
}

export type SimilarFlightsInfo = {
  __typename?: "SimilarFlightsInfo";
  flightsJourneyCriteria?: Maybe<Array<SimilarFlightsJourneyCriteria>>;
  offerIdentifier: Scalars["String"];
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelerDetails?: Maybe<Array<FlightsTravelerDetails>>;
  tripType?: Maybe<FlightsTripType>;
};

export type SimilarFlightsJourneyCriteria = {
  __typename?: "SimilarFlightsJourneyCriteria";
  arrivalDate?: Maybe<Date>;
  departureDate?: Maybe<Date>;
  destination?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
};

export type SimilarProperties = {
  __typename?: "SimilarProperties";
  footer?: Maybe<Scalars["String"]>;
  galleryCarousel?: Maybe<EgdsGalleryCarousel>;
  header?: Maybe<Scalars["String"]>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  properties: Array<SimilarProperty>;
  selectionOptions?: Maybe<LodgingSimilarSelectionOptions>;
};

export type SimilarPropertiesDeals = {
  __typename?: "SimilarPropertiesDeals";
  analytics?: Maybe<ClientSideAnalytics>;
  carouselActions?: Maybe<CarouselActions>;
  header?: Maybe<Scalars["String"]>;
  propertyOffers?: Maybe<Array<PropertyOfferItem>>;
};

export type SimilarProperty = {
  __typename?: "SimilarProperty";
  action: MessagingAction;
  /** @deprecated Deprecated in favour of featuredMessages */
  featuredMessage?: Maybe<LodgingEnrichedMessage>;
  featuredMessages: Array<EgdsGraphicText>;
  image?: Maybe<PropertyImage>;
  items: Array<LodgingEnrichedMessage>;
  price?: Maybe<SimilarPropertyPriceInfo>;
  /** @deprecated Used only for tracking, and will be removed in the future. */
  propertyId?: Maybe<Scalars["String"]>;
  reviews?: Maybe<PropertyReviewsSummary>;
  title: LodgingHeader;
};

export type SimilarPropertyPriceInfo = {
  __typename?: "SimilarPropertyPriceInfo";
  price: PropertyPrice;
  qualifier?: Maybe<LodgingEnrichedMessage>;
  subText?: Maybe<LodgingHeader>;
};

export type SimpleStepIndicatorChangeSelection = StepIndicatorChangeSelection & {
  __typename?: "SimpleStepIndicatorChangeSelection";
  a11yLabel: Scalars["String"];
  action: UiLinkAction;
  label: Scalars["String"];
  multiItemShoppingAction?: Maybe<MultiItemShoppingAction>;
};

export type SimpleStepIndicatorLabel = StepIndicatorLabel & {
  __typename?: "SimpleStepIndicatorLabel";
  detailText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type SingleEmailValidation = EgdsInputValidation & {
  __typename?: "SingleEmailValidation";
  errorMessage: Scalars["String"];
};

export type SingleUnitOfferDetails = AccessibilityField & {
  __typename?: "SingleUnitOfferDetails";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  availabilityCallToAction?: Maybe<AvailabilityCallToAction>;
  displayPrice?: Maybe<DisplayPriceSummaryItem>;
  id: Scalars["String"];
  installmentPlan?: Maybe<LodgingInstallmentPlan>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  ratePlans?: Maybe<Array<Maybe<RatePlan>>>;
  totalPrice?: Maybe<TotalPriceSummary>;
  view?: Maybe<Scalars["String"]>;
};

export type SingleUnitOfferDialog = LodgingDialog & {
  __typename?: "SingleUnitOfferDialog";
  content: SingleUnitOfferDetails;
  toolbar: LodgingDialogToolbar;
  trigger: LodgingDialogTriggerMessage;
};

export type SiteConfiguration = {
  __typename?: "SiteConfiguration";
  domain: Scalars["String"];
  eapid: Scalars["Int"];
  siteId: Scalars["Int"];
  tpid: Scalars["Int"];
};

export interface SiteConfigurationsRequestInput {
  siteIds: Array<Scalars["Int"]>;
}

export type SiteContext = {
  __typename?: "SiteContext";
  brandId: Scalars["String"];
  domain: Scalars["String"];
  langId: Scalars["Int"];
  pointOfSaleId: Scalars["String"];
  privacySellState: PrivacySellState;
  shortDateFormat: Scalars["String"];
  tpid: Scalars["Int"];
  uiBrandId: Scalars["String"];
};

export type SleepingArrangement = {
  __typename?: "SleepingArrangement";
  spaces?: Maybe<Array<SleepingArrangementSpace>>;
  title: Scalars["String"];
};

export type SleepingArrangementLayout = {
  __typename?: "SleepingArrangementLayout";
  description: Scalars["String"];
  icons?: Maybe<Array<Icon>>;
};

export type SleepingArrangementSpace = {
  __typename?: "SleepingArrangementSpace";
  layouts?: Maybe<Array<SleepingArrangementLayout>>;
  name: Scalars["String"];
};

export interface SmartFormAction {
  clientSideAnalytics: ClientSideAnalytics;
}

export type SmartFormButton = {
  __typename?: "SmartFormButton";
  actions?: Maybe<Array<SmartFormAction>>;
  button: EgdsButton;
};

export type SmartFormCard = {
  __typename?: "SmartFormCard";
  card: EgdsStandardMessagingCard;
  clickAction: SmartFormOpenSheetAction;
};

export type SmartFormCheckBoxGroup = {
  __typename?: "SmartFormCheckBoxGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  options: Array<SmartFormCheckbox>;
};

export type SmartFormCheckbox = {
  __typename?: "SmartFormCheckbox";
  checkbox: EgdsBasicCheckBox;
  trackingId?: Maybe<Scalars["String"]>;
};

export type SmartFormCheckboxGroupInputComponent = {
  __typename?: "SmartFormCheckboxGroupInputComponent";
  inputId: Scalars["String"];
  label: Scalars["String"];
  smartFormCheckBoxGroup: SmartFormCheckBoxGroup;
};

export type SmartFormClickTrackingAction = SmartFormAction & {
  __typename?: "SmartFormClickTrackingAction";
  clientSideAnalytics: ClientSideAnalytics;
};

export type SmartFormContainer = {
  __typename?: "SmartFormContainer";
  elements: Array<SmartFormContainerElement>;
};

export type SmartFormContainerElement = SmartFormSection;

export interface SmartFormDataInput {
  timestamp: Scalars["String"];
  userInput: SmartFormUserInput;
}

export type SmartFormFieldSet = {
  __typename?: "SmartFormFieldSet";
  description?: Maybe<Scalars["String"]>;
  elements: Array<SmartFormFieldSetElement>;
  label: Scalars["String"];
  required: Scalars["Boolean"];
};

export type SmartFormFieldSetElement = SmartFormInput | SmartFormText;

export type SmartFormHeading = {
  __typename?: "SmartFormHeading";
  size: SmartFormHeadingSizeInput;
  tag: SmartFormHeadingTagInput;
  text: Scalars["String"];
};

export type SmartFormHeadingSizeInput = "EIGHT" | "FIVE" | "FOUR" | "ONE" | "SEVEN" | "SIX" | "THREE" | "TWO";

export type SmartFormHeadingTagInput = "H1" | "H2" | "H3" | "H4" | "H5" | "H6";

export type SmartFormInput = {
  __typename?: "SmartFormInput";
  actions?: Maybe<Array<SmartFormAction>>;
  inputComponent: SmartFormInputComponent;
  /** @deprecated inputId are moved inside each inputComponent. Try to use that one */
  inputId: Scalars["String"];
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SmartFormInputComponent =
  | SmartFormCheckboxGroupInputComponent
  | SmartFormPhoneNumberInputComponentV2
  | SmartFormRadioGroupInputComponent
  | SmartFormSelectInputComponent
  | SmartFormTextInputComponent
  | SmartFormTypeaheadInputComponent;

export type SmartFormMutation = {
  __typename?: "SmartFormMutation";
  updateFormData: SmartFormMutationBaseResponse;
};

export type SmartFormMutationUpdateFormDataArgs = {
  data: SmartFormDataInput;
};

export type SmartFormMutationBaseResponse = {
  __typename?: "SmartFormMutationBaseResponse";
  sessionToken: Scalars["String"];
  success: Scalars["Boolean"];
};

export type SmartFormOpenSheetAction = SmartFormAction & {
  __typename?: "SmartFormOpenSheetAction";
  clientSideAnalytics: ClientSideAnalytics;
  sheet: SmartFormSheet;
};

export type SmartFormPhoneNumberInputComponentV2 = {
  __typename?: "SmartFormPhoneNumberInputComponentV2";
  countryCode: SmartFormSelectInputComponent;
  phoneNumber: SmartFormTextInputComponent;
};

export type SmartFormPrefillData = {
  __typename?: "SmartFormPrefillData";
  inputId: Scalars["String"];
  value: Scalars["String"];
};

export type SmartFormPrefillOption = {
  __typename?: "SmartFormPrefillOption";
  label: Scalars["String"];
  leftIcon?: Maybe<Icon>;
  prefillDataList: Array<SmartFormPrefillData>;
  selected?: Maybe<Scalars["Boolean"]>;
};

export type SmartFormPrefillSelection = {
  __typename?: "SmartFormPrefillSelection";
  label: Scalars["String"];
  leftIcon?: Maybe<Icon>;
  listenerInputIds?: Maybe<Array<Scalars["String"]>>;
  noPrefillOption?: Maybe<SmartFormPrefillOption>;
  options: Array<SmartFormPrefillOption>;
  required: Scalars["Boolean"];
  rightIcon?: Maybe<Icon>;
};

export type SmartFormQuery = {
  __typename?: "SmartFormQuery";
  view: SmartFormViewResponse;
};

export type SmartFormRadio = {
  __typename?: "SmartFormRadio";
  radioButton: EgdsBasicRadioButton;
  trackingId?: Maybe<Scalars["String"]>;
};

export type SmartFormRadioGroup = {
  __typename?: "SmartFormRadioGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  options: Array<SmartFormRadio>;
  selected?: Maybe<Scalars["String"]>;
};

export type SmartFormRadioGroupInputComponent = {
  __typename?: "SmartFormRadioGroupInputComponent";
  inputId: Scalars["String"];
  label: Scalars["String"];
  smartFormRadioGroup: SmartFormRadioGroup;
};

export type SmartFormSection = {
  __typename?: "SmartFormSection";
  elements: Array<SmartFormSectionElement>;
  smartFormHeading?: Maybe<SmartFormHeading>;
};

export type SmartFormSectionElement =
  | SmartFormCard
  | SmartFormFieldSet
  | SmartFormInput
  | SmartFormPrefillSelection
  | SmartFormText;

export type SmartFormSelectInputComponent = EgdsSelect & {
  __typename?: "SmartFormSelectInputComponent";
  disabled: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  inputId: Scalars["String"];
  label: Scalars["String"];
  options: Array<EgdsOption>;
  readOnly: Scalars["Boolean"];
  required: Scalars["Boolean"];
};

export type SmartFormSelectInputOption = EgdsOption & {
  __typename?: "SmartFormSelectInputOption";
  label: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  shortLabel: Scalars["String"];
  value: Scalars["String"];
};

export type SmartFormShareableInputId = {
  __typename?: "SmartFormShareableInputId";
  externalKey: Scalars["String"];
  internalInputId: Scalars["String"];
};

export type SmartFormSheet = SmartFormSubForm & {
  __typename?: "SmartFormSheet";
  description?: Maybe<Scalars["String"]>;
  elements: Array<SmartFormSheetElement>;
  heading: Scalars["String"];
  saveButton?: Maybe<SmartFormButton>;
  sheet: EgdsSheet;
  subFormId: Scalars["String"];
};

export type SmartFormSheetElement = SmartFormFieldSet;

export type SmartFormSignalAction = {
  __typename?: "SmartFormSignalAction";
  csrfData: IdentityCsrfAto;
  mutationToCall: Scalars["String"];
  requiredFields: Array<SmartFormSignalRequiredFields>;
};

export type SmartFormSignalRequiredFields = {
  __typename?: "SmartFormSignalRequiredFields";
  externalKey: Scalars["String"];
  internalInputId: Scalars["String"];
};

export interface SmartFormSubForm {
  subFormId: Scalars["String"];
}

export type SmartFormText = {
  __typename?: "SmartFormText";
  textComponent: EgdsComposableTextElement;
};

export type SmartFormTextInputComponent = EgdsInputField & {
  __typename?: "SmartFormTextInputComponent";
  errorMessage?: Maybe<Scalars["String"]>;
  inputId: Scalars["String"];
  instructions?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  required: Scalars["Boolean"];
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]>;
  variant: SmartFormTextInputVariant;
};

export type SmartFormTextInputVariant = "EMAIL" | "NUMERIC" | "TEL" | "TEXT" | "TEXTAREA";

export type SmartFormToastAction = SmartFormAction & {
  __typename?: "SmartFormToastAction";
  clientSideAnalytics: ClientSideAnalytics;
  toast?: Maybe<EgdsToast>;
};

export type SmartFormTypeaheadInputComponent = {
  __typename?: "SmartFormTypeaheadInputComponent";
  domainId?: Maybe<Scalars["String"]>;
  inputId: Scalars["String"];
  typeahead: EgdsTypeaheadInputField;
};

export interface SmartFormUserFieldInput {
  inputId: Scalars["String"];
  value: Array<Scalars["String"]>;
}

export interface SmartFormUserInput {
  userFieldInputs: Array<SmartFormUserFieldInput>;
}

export type SmartFormViewResponse = {
  __typename?: "SmartFormViewResponse";
  containers: Array<SmartFormContainer>;
  shareableInputIds: Array<SmartFormShareableInputId>;
};

export interface SmokingTypeSelectedInput {
  nonSmokingSelected: Scalars["Boolean"];
  smokingSelected: Scalars["Boolean"];
}

export type SoftPackages = {
  __typename?: "SoftPackages";
  actionURL?: Maybe<Scalars["String"]>;
  addCar?: Maybe<EgdsBasicCheckBox>;
  addFlight?: Maybe<EgdsBasicCheckBox>;
  addHotel?: Maybe<EgdsBasicCheckBox>;
  /** @deprecated Use addCar */
  car?: Maybe<EgdsPill>;
  datesForHotelStay?: Maybe<EgdsSearchFormDatePickerField>;
  /** @deprecated Use addFlight */
  flight?: Maybe<EgdsPill>;
  maxBookableTravelers?: Maybe<Scalars["Int"]>;
  maxTravelersErrorMessage?: Maybe<Scalars["String"]>;
  origin?: Maybe<EgdsSearchFormLocationField>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
};

export type SortAndFilter = {
  __typename?: "SortAndFilter";
  defaults?: Maybe<SortAndFilterDefaults>;
  options?: Maybe<SortAndFilterViewModel>;
  /** @deprecated This is redundant information that can be found elsewhere and pwa specific, replace with SortAndFilterOptions isSelected boolean or the SearchCriteria.Filters */
  selected?: Maybe<SortAndFilterSelected>;
};

export type SortAndFilterBasicFilterPayload = {
  __typename?: "SortAndFilterBasicFilterPayload";
  filterId: Scalars["String"];
  type: SortAndFilterBasicFilterPayloadType;
};

export type SortAndFilterBasicFilterPayloadType = "OPTION" | "REMOVE";

export type SortAndFilterDefaults = {
  __typename?: "SortAndFilterDefaults";
  regionId?: Maybe<Scalars["String"]>;
  sort: PropertySort;
};

export type SortAndFilterSelected = {
  __typename?: "SortAndFilterSelected";
  accessibility?: Maybe<Array<Scalars["String"]>>;
  agencyBusinessModels?: Maybe<Array<Scalars["String"]>>;
  amenities?: Maybe<Array<Scalars["String"]>>;
  cleaningAndSafetyType?: Maybe<Array<Scalars["String"]>>;
  commissionTiers?: Maybe<Array<Scalars["String"]>>;
  deals?: Maybe<Array<Scalars["String"]>>;
  destination?: Maybe<Scalars["String"]>;
  guestRating?: Maybe<Scalars["String"]>;
  hotelName?: Maybe<Scalars["String"]>;
  lodging?: Maybe<Array<Scalars["String"]>>;
  paymentType?: Maybe<Array<Scalars["String"]>>;
  poi?: Maybe<Scalars["String"]>;
  /** @deprecated More information is needed so moving list into an object , replace with priceFilterPills */
  priceFilterBuckets?: Maybe<Array<Scalars["Int"]>>;
  priceFilterPills?: Maybe<PriceFilterPills>;
  propertyStyles?: Maybe<Array<Scalars["String"]>>;
  regionId?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
  star?: Maybe<Scalars["String"]>;
  stayTypes?: Maybe<Array<Scalars["String"]>>;
  travelerType?: Maybe<Array<TravelerType>>;
};

export type SortAndFilterSelectedFilterPillPayload = {
  __typename?: "SortAndFilterSelectedFilterPillPayload";
  deselectionSignalId?: Maybe<Scalars["String"]>;
  filter: EgdsPill;
  filterName: SortAndFilterSignalDataSource;
};

export type SortAndFilterSignalCondition =
  | "DEFAULT_TO_NON_DEFAULT"
  | "NON_DEFAULT_TO_DEFAULT"
  | "NON_DEFAULT_TO_NON_DEFAULT"
  | "SELECTED"
  | "UNSELECTED";

export type SortAndFilterSignalDataSource = EgdsBasicLocalizedText | EgdsCardinalLocalizedText | EgdsPlainText;

export type SortAndFilterSignalEmitter = {
  __typename?: "SortAndFilterSignalEmitter";
  condition: SortAndFilterSignalCondition;
  payload: SortAndFilterSignalPayload;
  signalId: Scalars["String"];
};

export type SortAndFilterSignalPayload = SortAndFilterBasicFilterPayload | SortAndFilterSelectedFilterPillPayload;

export type SortAndFilterSignalReceiver = {
  __typename?: "SortAndFilterSignalReceiver";
  signalId: Scalars["String"];
};

export type SortAndFilterViewModel = AccessibilityField & {
  __typename?: "SortAndFilterViewModel";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  appliedFilterCount: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  sortAndFilter: Array<FilterViewModel>;
};

export type SortAndFiltersViewType =
  | "APPLIED_FILTERS"
  | "FILTERS"
  | "FILTERS_PILLS"
  | "FILTER_BAR"
  | "FILTER_DIALOG"
  | "SORT"
  | "SORT_AND_FILTERS"
  | "SORT_TABS"
  | "USE_REWARDS";

export type SourceType = "ESR" | "GDS";

export type Space = "four" | "half" | "one" | "six" | "three" | "twelve" | "two";

export type SpaceDetails = {
  __typename?: "SpaceDetails";
  floorPlan?: Maybe<FloorPlanDialog>;
  header: LodgingHeader;
  spaces?: Maybe<Array<Spaces>>;
  summary?: Maybe<Array<Scalars["String"]>>;
  virtualTourPrompt?: Maybe<VirtualTourPrompt>;
};

export type Spaces = {
  __typename?: "Spaces";
  description?: Maybe<Array<Scalars["String"]>>;
  icons?: Maybe<Array<Icon>>;
  name: Scalars["String"];
};

export type Spacing = "four" | "half" | "one" | "six" | "sixteen" | "three" | "twelve" | "two" | "unset";

export type SpacingOptions = "FOUR" | "HALF" | "ONE" | "SIX" | "SIXTEEN" | "THREE" | "TWELVE" | "TWO" | "UNSET";

export type SpannableTextDialog = {
  __typename?: "SpannableTextDialog";
  contents: Array<EgdsSpannableText>;
  dialog: EgdsDialog;
  trigger: EgdsText;
};

export type SpeakNextAvailableAgentSheet = {
  __typename?: "SpeakNextAvailableAgentSheet";
  back: Scalars["String"];
  heading: Scalars["String"];
  phoneNumberList: Array<PhoneNumberData>;
  subheading: Scalars["String"];
  toolBar: ContactUsToolBar;
};

export interface SpeakNextAvailableAgentSheetRequestInput {
  category: Scalars["String"];
  intent: Scalars["String"];
  optionType: ContactUsOptionType;
}

export type SpeakNextAvailableAgentSheetView = ContactUsErrorView | SpeakNextAvailableAgentSheet;

export type SpeakNextAvailableAgentView = ContactUsErrorView | SpeakNextAvailableAgentSheet;

export type SpecialEquipment =
  | "ADDITIONAL_DRIVER"
  | "BICYCLE_RACK"
  | "BOOSTER_SEAT"
  | "INFANT_SEAT"
  | "LEFT_HAND_CONTROL"
  | "LUGGAGE_RACK"
  | "NAV_SYSTEM"
  | "RIGHT_HAND_CONTROL"
  | "ROAD_CONGESTION_PAYMENT_SCHEME"
  | "SATELLITE_RADIO"
  | "SKI_RACK"
  | "SNOW_CHAINS"
  | "SNOW_TIRES"
  | "TODDLER_SEAT"
  | "TOLL_PAYMENT_TAG_PASS"
  | "TRAILER_HITCH"
  | "WIFI_ACCESS"
  | "WINTER_PACKAGE";

export type Spinner = {
  __typename?: "Spinner";
  name: Scalars["String"];
  sdk?: Maybe<Scalars["String"]>;
  sdl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
  version?: Maybe<Scalars["String"]>;
};

export type SponsoredContentBeacons = {
  __typename?: "SponsoredContentBeacons";
  /** @deprecated This field is deprecated, please use 'renderBeacons' */
  renderBeacon: Scalars["URL"];
  renderBeacons: Array<Scalars["URL"]>;
  /** @deprecated This field is deprecated, please use 'renderBeacons' */
  thirdPartyImpressions: Array<Scalars["URL"]>;
  viewabilityBeacon?: Maybe<Scalars["URL"]>;
};

export type SponsoredContentBrandResultListing = {
  __typename?: "SponsoredContentBrandResultListing";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  feature: Image;
  heading: Scalars["String"];
  /** @deprecated PayloadAnalytics is the replacement for ClientSideAnalytics */
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"];
  video?: Maybe<SponsoredContentVideo>;
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]>;
};

export type SponsoredContentBrandResultListingCars = {
  __typename?: "SponsoredContentBrandResultListingCars";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge: EgdsStandardBadge;
  beacons?: Maybe<SponsoredContentBeacons>;
  callToAction?: Maybe<EgdsInlineLink>;
  heading: Scalars["String"];
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]>;
};

export type SponsoredContentBrandResultListingFlights = {
  __typename?: "SponsoredContentBrandResultListingFlights";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  advertiserName: Scalars["String"];
  badge: EgdsStandardBadge;
  beacons?: Maybe<SponsoredContentBeacons>;
  callToAction?: Maybe<EgdsInlineLink>;
  heading: Scalars["String"];
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]>;
};

export type SponsoredContentBrandResultListingLodging = {
  __typename?: "SponsoredContentBrandResultListingLodging";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  /** @deprecated No need for CTA on BRL anymore */
  callToAction?: Maybe<EgdsInlineLink>;
  feature: Image;
  heading: Scalars["String"];
  /** @deprecated PayloadAnalytics is the replacement for ClientSideAnalytics */
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]>;
};

export type SponsoredContentContext = {
  __typename?: "SponsoredContentContext";
  pageName: Scalars["String"];
  sponsoredContentId: Scalars["String"];
  targeting?: Maybe<SponsoredContentTargeting>;
  variant?: Maybe<Scalars["String"]>;
};

export interface SponsoredContentContextInput {
  pageName: Scalars["String"];
  sponsoredContentId: Scalars["String"];
  targeting?: InputMaybe<SponsoredContentTargetingInput>;
  variant?: InputMaybe<Scalars["String"]>;
}

export type SponsoredContentImageGallery = {
  __typename?: "SponsoredContentImageGallery";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  heading: Scalars["String"];
  /** @deprecated PayloadAnalytics is the replacement for ClientSideAnalytics */
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  link?: Maybe<UiLinkAction>;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"];
  tiles: Array<Maybe<EgdsImageLinkCard>>;
};

export type SponsoredContentInterstitialAd = {
  __typename?: "SponsoredContentInterstitialAd";
  beacons?: Maybe<SponsoredContentBeacons>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use heroImage instead for top level image */
  feature: Image;
  heading: Scalars["String"];
  heroImage?: Maybe<Image>;
  impressionAnalytics: ClientSideAnalytics;
  logo?: Maybe<SponsoredContentLogo>;
  /** @deprecated Use optional description instead */
  paragraph: Scalars["String"];
  sponsoredLabel?: Maybe<Scalars["String"]>;
};

export type SponsoredContentLocationResolver = "GAIA" | "TLA";

export type SponsoredContentLocationResolverInput = "GAIA" | "TLA";

export type SponsoredContentLogo = {
  __typename?: "SponsoredContentLogo";
  darkMode?: Maybe<Mark>;
  standard?: Maybe<Mark>;
};

export type SponsoredContentMarquee = {
  __typename?: "SponsoredContentMarquee";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  cardType?: Maybe<MarqueeCardType>;
  heading: Scalars["String"];
  image: Image;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]>;
};

export type SponsoredContentMarquees = {
  __typename?: "SponsoredContentMarquees";
  primary: Array<SponsoredContentMarquee>;
  secondary: Array<SponsoredContentMarquee>;
};

export type SponsoredContentMultiListingAd = {
  __typename?: "SponsoredContentMultiListingAd";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  advertisedIds: Array<Scalars["Int"]>;
  advertiserName: Scalars["String"];
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  brandProperties?: Maybe<Array<LodgingCard>>;
  callToAction: EgdsInlineLink;
  carouselAnalytics?: Maybe<ClientSideAnalytics>;
  heading?: Maybe<Scalars["String"]>;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph?: Maybe<Scalars["String"]>;
};

export type SponsoredContentMultiListingAdBrandPropertiesArgs = {
  context: ContextInput;
  searchCriteriaInput: MultiItemSearchContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type SponsoredContentMultiListingAdCallToActionArgs = {
  context: ContextInput;
  searchCriteriaInput: MultiItemSearchContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type SponsoredContentPlacement = {
  __typename?: "SponsoredContentPlacement";
  productType: SponsoredContentProductType;
  sponsoredContentContext: SponsoredContentContext;
};

export type SponsoredContentProductType =
  | "ALTERNATE_DESTINATIONS_CAROUSEL"
  | "BRAND_RESULT_LISTING"
  | "BRAND_RESULT_LISTING_CARS"
  | "BRAND_RESULT_LISTING_CRUISES"
  | "BRAND_RESULT_LISTING_FLIGHTS"
  | "BRAND_RESULT_LISTING_LODGING"
  | "BRAND_RESULT_LISTING_LX"
  | "DESTINATION_EXPERIENCE"
  | "MULTI_LISTING_AD"
  | "SPONSORED_PROPERTIES_CAROUSEL";

export type SponsoredContentQuery = {
  __typename?: "SponsoredContentQuery";
  brandResultListing?: Maybe<SponsoredContentBrandResultListing>;
  brandResultListingCars?: Maybe<SponsoredContentBrandResultListingCars>;
  /** @deprecated Schema evolving. Use at your own risk */
  brandResultListingFlights?: Maybe<SponsoredContentBrandResultListingFlights>;
  /** @deprecated Schema evolving. Use at your own risk */
  brandResultListingLodging?: Maybe<SponsoredContentBrandResultListingLodging>;
  imageGallery?: Maybe<SponsoredContentImageGallery>;
  interstitialAd?: Maybe<SponsoredContentInterstitialAd>;
  marquee?: Maybe<SponsoredContentMarquee>;
  marquees?: Maybe<SponsoredContentMarquees>;
  multiListingAd?: Maybe<SponsoredContentMultiListingAd>;
  transparencyOverlay?: Maybe<TransparencyOverlay>;
};

export type SponsoredContentQueryBrandResultListingArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryBrandResultListingCarsArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryBrandResultListingFlightsArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryBrandResultListingLodgingArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryImageGalleryArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryInterstitialAdArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryMarqueeArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryMarqueesArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryMultiListingAdArgs = {
  context: ContextInput;
  searchCriteriaInput: MultiItemSearchContextInput;
  sponsoredContentContextInput: SponsoredContentContextInput;
};

export type SponsoredContentQueryTransparencyOverlayArgs = {
  adTransparencyId: Scalars["String"];
  context: ContextInput;
};

export type SponsoredContentTargeting = {
  __typename?: "SponsoredContentTargeting";
  adults?: Maybe<Scalars["Int"]>;
  dateEnd?: Maybe<Date>;
  dateStart?: Maybe<Date>;
  destination?: Maybe<Scalars["String"]>;
  kids?: Maybe<Scalars["Int"]>;
  locationResolver?: Maybe<SponsoredContentLocationResolver>;
  origin?: Maybe<Scalars["String"]>;
};

export interface SponsoredContentTargetingInput {
  adults?: InputMaybe<Scalars["Int"]>;
  dateEnd?: InputMaybe<DateInput>;
  dateStart?: InputMaybe<DateInput>;
  destination?: InputMaybe<Scalars["String"]>;
  kids?: InputMaybe<Scalars["Int"]>;
  locationResolver?: InputMaybe<SponsoredContentLocationResolverInput>;
  origin?: InputMaybe<Scalars["String"]>;
}

export type SponsoredContentVideo = {
  __typename?: "SponsoredContentVideo";
  description: Scalars["String"];
  firstQuartileAnalytics?: Maybe<ClientSideAnalytics>;
  link?: Maybe<UiLinkAction>;
  midpointAnalytics?: Maybe<ClientSideAnalytics>;
  muteAnalytics?: Maybe<ClientSideAnalytics>;
  thirdQuartileAnalytics?: Maybe<ClientSideAnalytics>;
  thumbnail: Uri;
  unmuteAnalytics?: Maybe<ClientSideAnalytics>;
  url: Uri;
  videoAccessibility?: Maybe<Scalars["String"]>;
  videoBufferingAnalytics?: Maybe<ClientSideAnalytics>;
  videoCompleteAnalytics?: Maybe<ClientSideAnalytics>;
  viewAnalytics?: Maybe<ClientSideAnalytics>;
  volumeButtonAccessibility?: Maybe<Scalars["String"]>;
};

export type SponsoredListing = {
  __typename?: "SponsoredListing";
  brand?: Maybe<Scalars["String"]>;
  clickRedirectLinkoffTrackingUrl?: Maybe<Scalars["URL"]>;
  clickRedirectTrackingUrl?: Maybe<Scalars["URL"]>;
  /** @deprecated click tracking should no longer be done by the client. Please pass PropertyTravelAdTrackingInfo in propertyInfo Offers subquery for click tracking */
  clickTrackingUrl?: Maybe<Scalars["URL"]>;
  details?: Maybe<Scalars["String"]>;
  detailsHeadline?: Maybe<Scalars["String"]>;
  hotelImage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  impressionTrackingUrl?: Maybe<Scalars["URL"]>;
  logo?: Maybe<Scalars["String"]>;
  lotagline?: Maybe<Scalars["String"]>;
  /** @deprecated Use position in PropertyTravelAdTrackingInfo */
  position?: Maybe<Scalars["String"]>;
  /** @deprecated Use rank in PropertyTravelAdTrackingInfo */
  rank?: Maybe<Scalars["String"]>;
  rating?: Maybe<Scalars["String"]>;
  /** @deprecated Use slots in PropertyTravelAdTrackingInfo */
  slots?: Maybe<Scalars["String"]>;
  /** @deprecated Use trackingData in PropertyTravelAdTrackingInfo */
  trackingData?: Maybe<Scalars["String"]>;
  trackingInfo: PropertyTravelAdTrackingInfo;
  vanityUrl?: Maybe<Scalars["URL"]>;
};

export type SponsoredTrackingBeacons = {
  __typename?: "SponsoredTrackingBeacons";
  clickBeacons?: Maybe<Array<Scalars["URL"]>>;
  renderBeacons?: Maybe<Array<Scalars["URL"]>>;
  viewBeacons?: Maybe<Array<Scalars["URL"]>>;
};

export interface StackingOptionInput {
  allowStacking?: InputMaybe<Scalars["Boolean"]>;
  promotionId: Scalars["String"];
}

export type StandardLink = {
  __typename?: "StandardLink";
  analytics?: Maybe<ClickstreamAnalyticsData>;
  link: EgdsStandardLink;
};

export type StarRange = {
  __typename?: "StarRange";
  max?: Maybe<Scalars["Float"]>;
  min?: Maybe<Scalars["Float"]>;
};

export interface StarRangeInput {
  max?: InputMaybe<Scalars["Float"]>;
  min?: InputMaybe<Scalars["Float"]>;
}

export type StaticCrossSellQueries = {
  __typename?: "StaticCrossSellQueries";
  brandedDealListing: BrandedDealCrossSellUiResponse;
  searchResultListing: StaticSearchResultListingCrossSellResponse;
};

export type StaticCrossSellQueriesBrandedDealListingArgs = {
  price: BrandedDealProductPriceInput;
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type StaticCrossSellQueriesSearchResultListingArgs = {
  crossSellProductType?: InputMaybe<CrossSellProductType>;
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type StaticLicensePlate = {
  __typename?: "StaticLicensePlate";
  description: Scalars["String"];
  feedbackLink: EgdsStandardLink;
  links: Array<EgdsStandardLink>;
  title: Scalars["String"];
};

export type StaticLodgingMultiItemCrossSell = {
  __typename?: "StaticLodgingMultiItemCrossSell";
  crossSellProductType: LodgingCrossSellProductType;
  fallbackListing?: Maybe<StaticLodgingMultiItemCrossSellFallbackListing>;
  multiItemSearchContext: MultiItemSearchContext;
};

export type StaticLodgingMultiItemCrossSellFallbackListing = MessageResult;

export type StaticMapImage = {
  __typename?: "StaticMapImage";
  accessibility?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type StaticMultiItemCrossSellResponse = {
  __typename?: "StaticMultiItemCrossSellResponse";
  crossSellOptions: Array<CrossSellOption>;
  impressionAnalytics: ClientSideAnalytics;
};

export type StaticSearchResultListingCrossSellResponse = {
  __typename?: "StaticSearchResultListingCrossSellResponse";
  crossSell: CrossSellOption;
  impressionAnalytics: ClientSideAnalytics;
};

export type StayConstraints = {
  __typename?: "StayConstraints";
  maximumStayInDays: Scalars["Int"];
  minimumStayInDays: Scalars["Int"];
  stayIncrementInDays: Scalars["Int"];
};

export type StayType = "CONVENTIONAL" | "VACATION_RENTALS";

export type StepIndicator = {
  __typename?: "StepIndicator";
  priceLockup?: Maybe<StepIndicatorPrice>;
  steps: Array<IndicatorStep>;
};

export interface StepIndicatorChangeSelection {
  a11yLabel: Scalars["String"];
  action: UiLinkAction;
  label: Scalars["String"];
  multiItemShoppingAction?: Maybe<MultiItemShoppingAction>;
}

export type StepIndicatorItem = {
  __typename?: "StepIndicatorItem";
  disclaimer?: Maybe<PackageUiDisclaimerDialog>;
  message: PackageUiMessageItem;
};

export interface StepIndicatorLabel {
  text: Scalars["String"];
}

export type StepIndicatorPrice = {
  __typename?: "StepIndicatorPrice";
  /** @deprecated Use packageUIPrice instead */
  a11yPrice: Scalars["String"];
  badge?: Maybe<EgdsProgramBadge>;
  /** @deprecated Use installmentPlanPlacement instead */
  installmentPlan?: Maybe<InstallmentPlan>;
  installmentPlanPlacement?: Maybe<InstallmentPlanPlacement>;
  /** @deprecated Use packageUIPrice instead */
  lockupPrice: Scalars["String"];
  /** @deprecated Use packageUIPrice instead */
  priceDisclaimer?: Maybe<PriceDisclaimer>;
  /** @deprecated Use packageUIPrice instead */
  priceSubtextStandard?: Maybe<Array<Scalars["String"]>>;
  priceSummary: PackageUiPrice;
  /** @deprecated Use packageUIPrice instead */
  strikeThroughDialogText?: Maybe<Scalars["String"]>;
  /** @deprecated Use packageUIPrice instead */
  strikeThroughPrice?: Maybe<Scalars["String"]>;
  topItems: Array<StepIndicatorItem>;
};

export type StepReviewCard = {
  __typename?: "StepReviewCard";
  analytics: StepReviewClickstreamData;
  cardLink: StepReviewCardAction;
  description?: Maybe<Scalars["String"]>;
  editIcon?: Maybe<Icon>;
  editLink?: Maybe<StepReviewEditLink>;
  heading: Scalars["String"];
  statusIcon: Icon;
};

export type StepReviewCardAction = StepReviewLinkAction | StepReviewReturnAction;

export type StepReviewClickstreamData = IEgClickstreamData & {
  __typename?: "StepReviewClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
  product_list?: Maybe<Array<EgClickstreamProductListData>>;
  virtual_market_coach?: Maybe<EgClickstreamVirtualMarketCoachData>;
};

export type StepReviewEditLink = {
  __typename?: "StepReviewEditLink";
  action: StepReviewCardAction;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  size: EgdsLinkSize;
  text: Scalars["String"];
};

export type StepReviewHeader = {
  __typename?: "StepReviewHeader";
  description: Scalars["String"];
  heading: Scalars["String"];
};

export type StepReviewInlineEditingSheet = {
  __typename?: "StepReviewInlineEditingSheet";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  closeText?: Maybe<Scalars["String"]>;
  content: StepReviewInlineEditingSheetContent;
  footer: StepReviewInlineEditingSheetFooter;
  heading: Scalars["String"];
  showAction: SupplySubscribeSignalAction;
};

export type StepReviewInlineEditingSheetButtonAction =
  | StepReviewInlineEditingSheetButtonActionCancel
  | StepReviewInlineEditingSheetButtonActionSave;

export type StepReviewInlineEditingSheetButtonActionCancel = {
  __typename?: "StepReviewInlineEditingSheetButtonActionCancel";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type StepReviewInlineEditingSheetButtonActionSave = {
  __typename?: "StepReviewInlineEditingSheetButtonActionSave";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type StepReviewInlineEditingSheetButtonType = "PRIMARY" | "SECONDARY";

export type StepReviewInlineEditingSheetContent = {
  __typename?: "StepReviewInlineEditingSheetContent";
  description: Scalars["String"];
  sections: Array<StepReviewInlineEditingSheetSection>;
};

export type StepReviewInlineEditingSheetFooter = {
  __typename?: "StepReviewInlineEditingSheetFooter";
  buttons: Array<StepReviewInlineEditingSheetFooterButton>;
};

export type StepReviewInlineEditingSheetFooterButton = {
  __typename?: "StepReviewInlineEditingSheetFooterButton";
  action: StepReviewInlineEditingSheetButtonAction;
  text: Scalars["String"];
  type: StepReviewInlineEditingSheetButtonType;
};

export type StepReviewInlineEditingSheetSection = MinNightStayInputField;

export type StepReviewLinkAction = {
  __typename?: "StepReviewLinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: StepReviewClickstreamData;
  resource: Uri;
  target: UiLinkTarget;
  viewed?: Maybe<Scalars["String"]>;
};

export type StepReviewList = {
  __typename?: "StepReviewList";
  accessibilityLabel: Scalars["String"];
  announcement: Scalars["String"];
  cards: Array<StepReviewCard>;
  header?: Maybe<StepReviewHeader>;
  toast?: Maybe<EgdsToast>;
};

export type StepReviewReturnAction = {
  __typename?: "StepReviewReturnAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: StepReviewClickstreamData;
  icon?: Maybe<Icon>;
  stepName?: Maybe<Scalars["String"]>;
  viewed?: Maybe<Scalars["String"]>;
};

export type StickyBottom = {
  __typename?: "StickyBottom";
  reserveButton: UiPrimaryButton;
  secondaryText: Scalars["String"];
  title: Scalars["String"];
  viewPriceSummaryA11yText: Scalars["String"];
  viewPriceSummaryIcon: Icon;
  viewPriceSummaryLink: Scalars["String"];
};

export type StoredBankCardPaymentInstrumentInfo = StoredPaymentInstrumentInfo & {
  __typename?: "StoredBankCardPaymentInstrumentInfo";
  brand: Scalars["String"];
  id: Scalars["String"];
  lastFour: Scalars["String"];
  postalCode: Scalars["String"];
  subMethod: Scalars["String"];
};

export type StoredCardConfig = {
  __typename?: "StoredCardConfig";
  postalCode?: Maybe<Scalars["String"]>;
  storedPaymentInstrumentId: Scalars["String"];
};

export type StoredCardInstrument = StoredPaymentInstrumentInfo & {
  __typename?: "StoredCardInstrument";
  authorized?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
};

export interface StoredCardInstrumentInput {
  authorized?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
}

export type StoredInstrumentBillingZipCodeField = {
  __typename?: "StoredInstrumentBillingZipCodeField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export interface StoredPaymentInstrumentInfo {
  id?: Maybe<Scalars["String"]>;
}

export type StrikeOutType = "ATTACH" | "INVALID" | "LOYALTY" | "PACKAGE" | "PRICE_SURVEY" | "STANDALONE";

export type StructureType =
  | "AGRITOURISM"
  | "ALL_INCLUSIVE"
  | "APARTMENT"
  | "APART_HOTEL"
  | "BED_AND_BREAKFAST"
  | "CABIN"
  | "CAPSULE_HOTEL"
  | "CARAVAN_PARK"
  | "CASTLE"
  | "CHALET"
  | "CONDO"
  | "CONDO_RESORT"
  | "COTTAGE"
  | "COUNTRY_HOUSE"
  | "CRUISE"
  | "ECO_HOTEL"
  | "GUEST_HOUSE"
  | "HOLIDAY_PARK"
  | "HOSTAL"
  | "HOSTEL"
  | "HOTEL"
  | "HOTEL_RESORT"
  | "HOUSE_BOAT"
  | "INN"
  | "LODGE"
  | "LONGHOUSE"
  | "MOBILE_HOME"
  | "MOTEL"
  | "OVERWATER"
  | "PALACE"
  | "PENSION"
  | "POUSADA_BRAZIL"
  | "POUSADA_PORTUGAL"
  | "RANCH"
  | "RESIDENCE"
  | "RIAD"
  | "RYOKAN"
  | "SAFARI"
  | "SERVICED_APARTMENT"
  | "TOWNHOUSE"
  | "TRADITIONAL_BUILDING"
  | "TREE_HOUSE"
  | "UNKNOWN"
  | "VACATION_HOME"
  | "VACATION_RENTAL"
  | "VACATION_RENTALS"
  | "VILLA"
  | "VIP";

export type StructureTypeValue = {
  __typename?: "StructureTypeValue";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type StructuredContentMediaGalleryCarousel = EgdsGalleryCarousel & {
  __typename?: "StructuredContentMediaGalleryCarousel";
  accessibilityHeadingText: Scalars["String"];
  media: Array<MediaItem>;
  nextButtonText: Scalars["String"];
  previousButtonText: Scalars["String"];
};

export type SubHeader = {
  __typename?: "SubHeader";
  button?: Maybe<HeaderButton>;
  logo?: Maybe<SubHeaderLogo>;
  partnerLoyaltyPoints?: Maybe<PartnerLoyaltyPointsComponent>;
  text: Scalars["String"];
};

export type SubHeaderLogo = {
  __typename?: "SubHeaderLogo";
  action: UiLinkAction;
  imgSrc: Image;
  text: Scalars["String"];
};

export type SummarizationFeedbackErrorView = {
  __typename?: "SummarizationFeedbackErrorView";
  errorMessage: UiBanner;
  heading: Scalars["String"];
  okButton: UiSecondaryButton;
};

export type SummarizationFeedbackOption = {
  __typename?: "SummarizationFeedbackOption";
  description: Scalars["String"];
  isInputMandatory: Scalars["Boolean"];
  text: Scalars["String"];
};

export type SummarizationFeedbackView = SummarizationFeedbackErrorView | SummarizationNegativeFeedbackView;

export type SummarizationNegativeFeedbackView = {
  __typename?: "SummarizationNegativeFeedbackView";
  cancelButton: UiSecondaryButton;
  feedbackComment: EgdsTextInputField;
  heading: Scalars["String"];
  legalDisclaimer?: Maybe<Scalars["String"]>;
  subHeading: Scalars["String"];
  submitButton: UiPrimaryButton;
  summarizationFeedbackOptions: Array<SummarizationFeedbackOption>;
};

export type SummarizedResponseEgds = EgdsUnorderedList;

export type SummarizedReviews = {
  __typename?: "SummarizedReviews";
  disclaimer?: Maybe<EgdsGraphicText>;
  summary?: Maybe<Array<ReviewSummaryInfo>>;
};

export type SummaryUnit = {
  __typename?: "SummaryUnit";
  score?: Maybe<Scalars["String"]>;
  tags: Array<Tag>;
};

export type SuperlativeText = {
  __typename?: "SuperlativeText";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  text: EgdsStylizedText;
};

export type SupplyAmenitiesCoachingActionPanel = SupplyCoachingActionPanel & {
  __typename?: "SupplyAmenitiesCoachingActionPanel";
  confirmedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  dialog: SupplyContentLeaveWithoutSavingDialog;
  groupConfigs: Array<SupplyAmenitiesGroupConfig>;
  saveButton: SupplyContentButton;
  singleStepText?: Maybe<Scalars["String"]>;
  stepText: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyAmenitiesGroup = {
  __typename?: "SupplyAmenitiesGroup";
  analytics?: Maybe<Array<SupplyContentEgClickstreamData>>;
  contents: Array<SupplyAmenitiesGroupContent>;
  description?: Maybe<Scalars["String"]>;
  header?: Maybe<Scalars["String"]>;
};

export type SupplyAmenitiesGroupConfig = {
  __typename?: "SupplyAmenitiesGroupConfig";
  description: Scalars["String"];
  groupId: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyAmenitiesGroupContent = SupplyAmenitiesList | SupplyAmenitiesSection | SupplyAmenitiesSubSection;

export interface SupplyAmenitiesGroupInput {
  groupId: Scalars["String"];
  roomTypeId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["String"]>;
}

export type SupplyAmenitiesList = {
  __typename?: "SupplyAmenitiesList";
  amenities: Array<SupplyAmenityElement>;
  column: Scalars["Int"];
};

export type SupplyAmenitiesSection = {
  __typename?: "SupplyAmenitiesSection";
  subSections: Array<SupplyAmenitiesSubSection>;
  title: Scalars["String"];
};

export type SupplyAmenitiesSubSection = {
  __typename?: "SupplyAmenitiesSubSection";
  amenitiesLists: Array<SupplyAmenitiesList>;
  divider: Scalars["Boolean"];
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export interface SupplyAmenitiesUpdateInput {
  amenities: Array<SupplyAmenityInput>;
  groupId: Scalars["String"];
  roomTypeId?: InputMaybe<Scalars["String"]>;
}

export type SupplyAmenityElement = {
  __typename?: "SupplyAmenityElement";
  analytics?: Maybe<Array<SupplyContentEgClickstreamData>>;
  boldTitle?: Maybe<Scalars["Boolean"]>;
  defaultValue?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  element: SupplyAmenityUiElement;
  elementId: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type SupplyAmenityInformativeInput = {
  __typename?: "SupplyAmenityInformativeInput";
  field: EgdsNumberInputField;
  popoverText?: Maybe<Scalars["String"]>;
};

export interface SupplyAmenityInput {
  elementId: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
}

export type SupplyAmenityUiElement =
  | EgdsBasicCheckBox
  | EgdsBasicLocalizedText
  | EgdsBasicRadioGroup
  | EgdsBasicSelect
  | EgdsBasicStepInput
  | EgdsNumberInputField
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStandardSwitch
  | EgdsTextInputField
  | SupplyAmenityValueAndUnit;

export type SupplyAmenityValueAndUnit = {
  __typename?: "SupplyAmenityValueAndUnit";
  mappingValidations: Array<Maybe<SupplyAmenityValueOrUnitValidations>>;
  unit: SupplyAmenityValueOrUnit;
  value: SupplyAmenityValueOrUnit;
};

export type SupplyAmenityValueAndUnitElement = EgdsBasicSelect | EgdsNumberInputField | SupplyAmenityInformativeInput;

export type SupplyAmenityValueOrUnit = {
  __typename?: "SupplyAmenityValueOrUnit";
  defaultValue?: Maybe<Scalars["String"]>;
  element: SupplyAmenityValueAndUnitElement;
  elementId: Scalars["String"];
};

export type SupplyAmenityValueOrUnitValidations = {
  __typename?: "SupplyAmenityValueOrUnitValidations";
  unit: Scalars["String"];
  validations: Array<Maybe<EgdsInputValidation>>;
};

export type SupplyAssociatedPropertiesSummary = {
  __typename?: "SupplyAssociatedPropertiesSummary";
  associatedPropertiesSummary: Array<SupplyPropertyListItemActionContent>;
};

export type SupplyAttributeDescription = {
  __typename?: "SupplyAttributeDescription";
  addLinkBtn: EgdsStandardLink;
  input: EgdsTextAreaInputField;
};

export type SupplyAttributeDetail = {
  __typename?: "SupplyAttributeDetail";
  attribute: SupplyAttributeInputField;
  locale: EgdsBasicSelect;
};

export interface SupplyAttributeInput {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
}

export type SupplyAttributeInputField = {
  __typename?: "SupplyAttributeInputField";
  fieldSet: EgdsTextAreaInputField;
  title: Scalars["String"];
};

export type SupplyAttributeItemCell = {
  __typename?: "SupplyAttributeItemCell";
  content?: Maybe<SupplyAttributeItemContent>;
};

export type SupplyAttributeItemContent =
  | EgdsBasicCheckBox
  | EgdsPlainText
  | SupplyAttributeDescription
  | SupplyAttributeDetail
  | SupplyAttributeInputField
  | SupplyAttributeLastUpdateByInfo
  | SupplyAttributeSelect;

export type SupplyAttributeItemRow = {
  __typename?: "SupplyAttributeItemRow";
  attributeId: Scalars["String"];
  cells: Array<Maybe<SupplyAttributeItemCell>>;
  id: Scalars["String"];
};

export type SupplyAttributeLastUpdateByInfo = {
  __typename?: "SupplyAttributeLastUpdateByInfo";
  lastUpdateBy: Scalars["String"];
  lastUpdateByDate: Scalars["String"];
};

export type SupplyAttributeSelect = {
  __typename?: "SupplyAttributeSelect";
  fieldSet: EgdsBasicSelect;
  title: Scalars["String"];
};

export type SupplyAttributesAdminExperience = {
  __typename?: "SupplyAttributesAdminExperience";
  attributeSections: Array<SupplyAttributesSection>;
  ownerSelects: SupplyAttributesOwnerSelects;
  searchInput: EgdsTextInputField;
};

export interface SupplyAttributesAdminExperienceInput {
  ratePlanId?: InputMaybe<Scalars["String"]>;
  roomTypeId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["String"]>;
}

export type SupplyAttributesOwnerSelects = {
  __typename?: "SupplyAttributesOwnerSelects";
  ratePlanSelect: EgdsBasicSelect;
  roomSelect: EgdsBasicSelect;
  spaceSelect: EgdsBasicSelect;
};

export type SupplyAttributesSection = {
  __typename?: "SupplyAttributesSection";
  attributesTable: SupplyAttributesTable;
  expandoTitle: Scalars["String"];
};

export type SupplyAttributesTable = {
  __typename?: "SupplyAttributesTable";
  headers: Array<Scalars["String"]>;
  rows: Array<SupplyAttributeItemRow>;
};

export interface SupplyAttributesUpdateInput {
  attributes: Array<SupplyAttributeInput>;
  ratePlanId?: InputMaybe<Scalars["String"]>;
  roomTypeId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["String"]>;
}

export type SupplyAutoCancelWaiver = {
  __typename?: "SupplyAutoCancelWaiver";
  banner: SupplyAutoCancelWaiverBanner;
  exception: SupplyAutoCancelWaiverException;
  footer: SupplyAutoCancelWaiverFooter;
  header: SupplyAutoCancelWaiverHeader;
  waivers: SupplyAutoCancelWaiverSection;
};

export type SupplyAutoCancelWaiverBanner = {
  __typename?: "SupplyAutoCancelWaiverBanner";
  error: Scalars["String"];
  loading: Scalars["String"];
  success: Scalars["String"];
};

export type SupplyAutoCancelWaiverException = {
  __typename?: "SupplyAutoCancelWaiverException";
  descriptions: Array<SupplyAutoCancelWaiverExceptionContent>;
  title: Scalars["String"];
};

export type SupplyAutoCancelWaiverExceptionContent = {
  __typename?: "SupplyAutoCancelWaiverExceptionContent";
  action: SupplyReservationsAction;
  text: Scalars["String"];
};

export type SupplyAutoCancelWaiverFooter = {
  __typename?: "SupplyAutoCancelWaiverFooter";
  cancel: SupplyReservationsAction;
  save: SupplyReservationsAction;
};

export type SupplyAutoCancelWaiverHeader = {
  __typename?: "SupplyAutoCancelWaiverHeader";
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyAutoCancelWaiverSection = {
  __typename?: "SupplyAutoCancelWaiverSection";
  subtitle: Scalars["String"];
  title: Scalars["String"];
  waiverRules: EgdsCheckBoxGroup;
};

export type SupplyBulkAddInventoryResponse = {
  __typename?: "SupplyBulkAddInventoryResponse";
  addInventoryPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  applySelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  content?: Maybe<Array<SupplyLocalizedString>>;
  dates?: Maybe<Array<Date>>;
  editDatesButtonPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  editDatesButtonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  editRoomTypeButtonPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  editRoomTypeButtonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  maxIncrement?: Maybe<Scalars["Int"]>;
  roomTypes?: Maybe<Array<SupplyRoomType>>;
  startingIncrement?: Maybe<Scalars["Int"]>;
};

export type SupplyBulkReview = {
  __typename?: "SupplyBulkReview";
  inventoryReviews?: Maybe<Array<SupplyInventoryReview>>;
  roomType: SupplyRoomType;
};

export type SupplyBulkReviewAddInventoryResponse = {
  __typename?: "SupplyBulkReviewAddInventoryResponse";
  bulkReviews?: Maybe<Array<SupplyBulkReview>>;
};

export type SupplyCancelWaiverActionButton = EgdsButton & {
  __typename?: "SupplyCancelWaiverActionButton";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
};

export type SupplyCancellationPolicyDetailsExpandoLink = EgdsExpando & {
  __typename?: "SupplyCancellationPolicyDetailsExpandoLink";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedContent?: Maybe<SupplyCancellationPolicyDetailsSection>;
  expandedLabel: Scalars["String"];
};

export type SupplyCancellationPolicyDetailsSection = {
  __typename?: "SupplyCancellationPolicyDetailsSection";
  policyName: EgdsStandardLink;
  policyRules: Array<SupplyGuestItemContent>;
  policyTip?: Maybe<EgdsIconText>;
};

export type SupplyCancellationWaiverApprovalContent = {
  __typename?: "SupplyCancellationWaiverApprovalContent";
  cancellationReason: SupplyGuestItemContent;
  expando: SupplyCancellationPolicyDetailsExpandoLink;
  refundInformation: SupplyGuestItemContent;
  subTitle: Scalars["String"];
  title: Scalars["String"];
};

export interface SupplyCancellationWaiverApprovalRequestContext {
  bookingItemId: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
}

export type SupplyCancellationWaiverApprovalResponseContent = SupplyRefundDecision & {
  __typename?: "SupplyCancellationWaiverApprovalResponseContent";
  content: Scalars["String"];
  disclaimerLink: EgdsStandardLink;
  disclaimerText: Scalars["String"];
  icon: Icon;
  /** @deprecated Replaced by disclaimerLink */
  link: SupplyRefundDisclaimerInlineLink;
  primaryButton: EgdsButton;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyCancellationWaiverApprovalSection = {
  __typename?: "SupplyCancellationWaiverApprovalSection";
  approvalContent: SupplyCancellationWaiverApprovalContent;
  guestMessage: EgdsTextInputField;
  header: EgdsIconText;
  messageCard: EgdsStandardMessagingCard;
  primaryButton: SupplyCancelWaiverActionButton;
  secondaryButton: EgdsButton;
};

export type SupplyCancellationWaiverCardDetails = {
  __typename?: "SupplyCancellationWaiverCardDetails";
  cancellationReason: SupplyGuestItemContent;
  expando: SupplyCancellationPolicyDetailsExpandoLink;
  primaryButton: EgdsButton;
  refundInformation: EgdsStylizedText;
  secondaryButton: EgdsButton;
  title: Scalars["String"];
};

export type SupplyCancellationWaiverDeclineFlowContent = {
  __typename?: "SupplyCancellationWaiverDeclineFlowContent";
  declineReasonInput: SupplyCancellationWaiverDeclineReasonInput;
  header: EgdsIconText;
  messageCard: EgdsStandardMessagingCard;
  primaryButton: SupplyCancelWaiverActionButton;
  secondaryButton: EgdsButton;
};

export type SupplyCancellationWaiverDeclineReasonInput = {
  __typename?: "SupplyCancellationWaiverDeclineReasonInput";
  guestMessage: EgdsTextInputField;
  selector: EgdsBasicSelect;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyCancellationWaiverDeclineSuccessContent = SupplyRefundDecision & {
  __typename?: "SupplyCancellationWaiverDeclineSuccessContent";
  content: Scalars["String"];
  expando: SupplyCancellationPolicyDetailsExpandoLink;
  icon: Icon;
  primaryButton: EgdsButton;
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export interface SupplyCancellationWaiverReservationContext {
  bookingItemId: Scalars["String"];
}

export interface SupplyCoachingActionPanel {
  title: Scalars["String"];
}

export interface SupplyCoachingParameters {
  coachingTipId?: InputMaybe<Scalars["String"]>;
  coachingTipSourceId?: InputMaybe<Scalars["String"]>;
  proofPointStr?: InputMaybe<Scalars["String"]>;
}

export type SupplyCoachingTipBlockEmptyState = {
  __typename?: "SupplyCoachingTipBlockEmptyState";
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
};

export type SupplyCoachingTipCard = {
  __typename?: "SupplyCoachingTipCard";
  /** @deprecated Please use tipCardAction */
  cardAction?: Maybe<SupplyQuickActionOpenSheetAction>;
  cardInfo: SupplyCoachingTipCardInfo;
  clickStreamData?: Maybe<SupplyCoachingTipCardClickStreamData>;
  tagIds?: Maybe<Array<Scalars["String"]>>;
  tipCardAction?: Maybe<SupplyCoachingTipCardAction>;
};

export type SupplyCoachingTipCardAction =
  | SupplyQuickActionActionedSummaryViewAction
  | SupplyQuickActionOpenHostedViewAction
  | SupplyQuickActionOpenSheetAction
  | SupplyQuickActionSendCardInteractionSignal;

export type SupplyCoachingTipCardClickStreamData = {
  __typename?: "SupplyCoachingTipCardClickStreamData";
  /** @deprecated This field will be deprecated in the future, use cardPresentedAnalyticsPayload instead */
  cardPresented?: Maybe<SupplyVirtualMarketCoachEgClickStreamData>;
  cardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future, use cardSelectedAnalyticsPayload instead */
  cardSelected?: Maybe<SupplyVirtualMarketCoachEgClickStreamData>;
  cardSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyCoachingTipCardInfo = {
  __typename?: "SupplyCoachingTipCardInfo";
  actionIcon?: Maybe<Icon>;
  deepLink?: Maybe<UiLinkAction>;
  description: EgdsSpannableText;
  icon: Icon;
  primaryButton?: Maybe<EgdsButton>;
  secondaryButton?: Maybe<EgdsButton>;
  title: Scalars["String"];
};

export type SupplyCoachingTipCount = EgdsStandardLink;

export type SupplyCoachingTipPostponeAnalytics = {
  __typename?: "SupplyCoachingTipPostponeAnalytics";
  postponeSheetClosed?: Maybe<Scalars["AnalyticsPayload"]>;
  postponeSheetPresented?: Maybe<Scalars["AnalyticsPayload"]>;
  postponeSubmitted?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyCoachingTipPostponeForm = {
  __typename?: "SupplyCoachingTipPostponeForm";
  clickStreamData?: Maybe<SupplyCoachingTipPostponeAnalytics>;
  durationSelectionPromptTitle?: Maybe<Scalars["String"]>;
  durationSelectionPrompts?: Maybe<SupplyPostponeSingleSelection>;
  heading: Scalars["String"];
  notInterestedPromptSubtitle?: Maybe<Scalars["String"]>;
  notInterestedPromptTitle?: Maybe<Scalars["String"]>;
  notInterestedPrompts?: Maybe<SupplyPostponeMultipleSelection>;
  saveForLaterPromptTitle?: Maybe<Scalars["String"]>;
  saveForLaterPrompts?: Maybe<SupplyPostponeSingleSelection>;
  submitButtonLabel: Scalars["String"];
};

export type SupplyCoachingTipProofPointEgClickstreamCustomAttribute = {
  __typename?: "SupplyCoachingTipProofPointEGClickstreamCustomAttribute";
  comparative_set?: Maybe<Scalars["String"]>;
  metric?: Maybe<Scalars["String"]>;
  option?: Maybe<Scalars["String"]>;
  option_percent?: Maybe<Scalars["String"]>;
  type_id?: Maybe<Scalars["Int"]>;
  uplift_percentage?: Maybe<Scalars["String"]>;
};

export type SupplyCoachingTipTag = {
  __typename?: "SupplyCoachingTipTag";
  /** @deprecated This field will be removed in the future; please use optionalIcon */
  icon: Icon;
  optionalIcon?: Maybe<Icon>;
  tagDeselectedAnalyticsEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  tagId: Scalars["String"];
  tagSelectedAnalyticsEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  text: Scalars["String"];
};

export type SupplyCoachingTipsBlock = {
  __typename?: "SupplyCoachingTipsBlock";
  coachingTipBlockEmptyState?: Maybe<SupplyCoachingTipBlockEmptyState>;
  coachingTipCards: Array<SupplyCoachingTipCard>;
  coachingTipCount?: Maybe<SupplyCoachingTipCount>;
  coachingTipTags?: Maybe<Array<SupplyCoachingTipTag>>;
  lastUpdatedSubtitle?: Maybe<Scalars["String"]>;
  opportunitiesPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future due to library update, use opportunitiesPresentedAnalyticsPayload instead */
  opportunitiesPresentedClickstreamData?: Maybe<SupplyVirtualMarketCoachEgClickStreamData>;
  title?: Maybe<EgdsHeading>;
};

export type SupplyConnectivitySettingProviderServiceContent = {
  __typename?: "SupplyConnectivitySettingProviderServiceContent";
  providerTypeAhead?: Maybe<ConnectivitySettingsTypeahead>;
  serviceCard?: Maybe<Array<ServiceActionListItem>>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyConnectivitySettingProviderServiceView = {
  __typename?: "SupplyConnectivitySettingProviderServiceView";
  buttons: Array<ConnectivitySettingsButton>;
  content?: Maybe<SupplyConnectivitySettingProviderServiceContent>;
  stepIndicator?: Maybe<ConnectivitySettingsStepIndicator>;
  stepName: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyConnectivitySettingsExperience = {
  __typename?: "SupplyConnectivitySettingsExperience";
  header: ConnectivitySettingsHeader;
  main: ConnectivitySettingsMain;
};

export type SupplyContactMethod = {
  __typename?: "SupplyContactMethod";
  action: SupplyContactMethodAction;
  description: EgdsSpannableText;
  heading: Scalars["String"];
  icon: Icon;
};

export type SupplyContactMethodAction = {
  __typename?: "SupplyContactMethodAction";
  action: Scalars["String"];
  form?: Maybe<SupplyContactMethodForm>;
  linkAction?: Maybe<UiLinkAction>;
  linkLabel: Scalars["String"];
  supportOptionSelectedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  taxonomyId?: Maybe<Scalars["String"]>;
  vaIntent?: Maybe<Scalars["String"]>;
};

export type SupplyContactMethodForm = SupplyContactMethodSendMessageForm;

export type SupplyContactMethodSendMessageForm = {
  __typename?: "SupplyContactMethodSendMessageForm";
  cancel: EgdsButton;
  email: EgdsInputField;
  firstName: EgdsInputField;
  lastName: EgdsInputField;
  message: EgdsTextAreaInputField;
  submit: EgdsButton;
};

export type SupplyContentAdditionalInfo = {
  __typename?: "SupplyContentAdditionalInfo";
  bathrooms?: Maybe<SupplyContentDescriptionSection>;
  bedrooms?: Maybe<SupplyContentDescriptionSection>;
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  header: SupplyContentHubHeader;
  property?: Maybe<SupplyContentDescriptionSection>;
};

export interface SupplyContentAdditionalInfoUpdateInput {
  bathrooms?: InputMaybe<Scalars["String"]>;
  bedrooms?: InputMaybe<Scalars["String"]>;
  property?: InputMaybe<Scalars["String"]>;
}

export type SupplyContentAmenity = {
  __typename?: "SupplyContentAmenity";
  amenities: Array<SupplyContentAmenityItem>;
  heading: Scalars["String"];
};

export interface SupplyContentAmenityInput {
  uuid: Scalars["String"];
  value: Scalars["String"];
}

export type SupplyContentAmenityItem = {
  __typename?: "SupplyContentAmenityItem";
  element: EgdsBasicCheckBox;
  uuid: Scalars["String"];
};

export type SupplyContentAmenitySection = SupplyContentAmenity | SupplyContentErrorSection;

export type SupplyContentButton = {
  __typename?: "SupplyContentButton";
  button: EgdsButton;
  buttonSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentCarousel = {
  __typename?: "SupplyContentCarousel";
  cards: Array<SupplyContentCarouselCard>;
  carouselViewed?: Maybe<SupplyContentEgClickstreamData>;
  nextButtonText: Scalars["String"];
  previousButtonText: Scalars["String"];
};

export type SupplyContentCarouselCard = {
  __typename?: "SupplyContentCarouselCard";
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["URL"]>;
  text: Scalars["String"];
};

export type SupplyContentDescriptionBanner = {
  __typename?: "SupplyContentDescriptionBanner";
  description: Scalars["String"];
  heading: Scalars["String"];
  icon: Scalars["String"];
};

export type SupplyContentDescriptionDeleteRecommendationSection = {
  __typename?: "SupplyContentDescriptionDeleteRecommendationSection";
  deleteLink: EgdsStandardLink;
  deleteLinkSelected?: Maybe<SupplyContentEgClickstreamData>;
  toast: EgdsToast;
  undoSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentDescriptionInputSection = {
  __typename?: "SupplyContentDescriptionInputSection";
  description: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
  recommendationResult?: Maybe<SupplyContentEgClickstreamData>;
  textArea: SupplyContentTextArea;
  tips?: Maybe<SupplyContentTips>;
};

export type SupplyContentDescriptionLearnMoreSheet = {
  __typename?: "SupplyContentDescriptionLearnMoreSheet";
  content: EgdsTextListSection;
  description: Scalars["String"];
  toolbarTitle: Scalars["String"];
};

export type SupplyContentDescriptionRecommendationSection = {
  __typename?: "SupplyContentDescriptionRecommendationSection";
  deleteRecommendationSection: SupplyContentDescriptionDeleteRecommendationSection;
  recommendationTipCard: SupplyContentDescriptionRecommendationTipCard;
  recommendationValue: Scalars["String"];
};

export type SupplyContentDescriptionRecommendationTipCard = {
  __typename?: "SupplyContentDescriptionRecommendationTipCard";
  heading: Scalars["String"];
  icon: Scalars["String"];
  instruction: SupplyContentSpannableText;
  learnMoreSheet: SupplyContentDescriptionLearnMoreSheet;
};

export type SupplyContentDescriptionSection = SupplyContentDescriptionInputSection | SupplyContentErrorSection;

export type SupplyContentDescriptionSectionName =
  | "ABOUT_YOU"
  | "BATHROOMS_ADDITIONAL_INFO"
  | "BEDROOMS_ADDITIONAL_INFO"
  | "DESCRIPTION"
  | "HEADLINE"
  | "INTERIOR_SIZE"
  | "MANUAL_TRANSLATION"
  | "PROPERTY_ADDITIONAL_INFO"
  | "PROPERTY_NAME"
  | "PROPERTY_TYPE"
  | "SUITABILITY_AMENITY"
  | "UNIQUE_BENEFITS"
  | "WHY_THIS_PROPERTY";

export type SupplyContentDescriptionUpdate = {
  __typename?: "SupplyContentDescriptionUpdate";
  contentSaved: Array<Maybe<SupplyContentEgClickstreamData>>;
  failures?: Maybe<Array<SupplyContentDescriptionUpdateFailure>>;
  toast?: Maybe<EgdsToast>;
};

export type SupplyContentDescriptionUpdateFailure = {
  __typename?: "SupplyContentDescriptionUpdateFailure";
  error: SupplyContentErrorMessage;
  sectionName: SupplyContentDescriptionSectionName;
};

export type SupplyContentEgClickstreamData = IEgClickstreamData & {
  __typename?: "SupplyContentEgClickstreamData";
  customizedAttributes?: Maybe<Scalars["String"]>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
};

export type SupplyContentError = {
  __typename?: "SupplyContentError";
  contentError?: Maybe<SupplyContentEgClickstreamData>;
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  refresh?: Maybe<SupplyContentButton>;
  suggestion?: Maybe<Scalars["String"]>;
};

export type SupplyContentErrorMessage = {
  __typename?: "SupplyContentErrorMessage";
  icon: Icon;
  message: Scalars["String"];
};

export type SupplyContentErrorSection = {
  __typename?: "SupplyContentErrorSection";
  error: SupplyContentErrorMessage;
  heading: Scalars["String"];
};

export type SupplyContentExistingManualTranslation = {
  __typename?: "SupplyContentExistingManualTranslation";
  deleteButton: SupplyContentButton;
  locale: Scalars["String"];
  text: Scalars["String"];
  viewButton: SupplyContentButton;
};

export type SupplyContentHeadlineDescription = {
  __typename?: "SupplyContentHeadlineDescription";
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  description?: Maybe<SupplyContentDescriptionSection>;
  header?: Maybe<SupplyContentHubHeader>;
  headline?: Maybe<SupplyContentDescriptionSection>;
  manualTranslation?: Maybe<SupplyContentManualTranslationSection>;
  propertyName?: Maybe<SupplyContentDescriptionSection>;
  warningBanner?: Maybe<SupplyContentDescriptionBanner>;
};

export interface SupplyContentHeadlineDescriptionUpdateInput {
  description?: InputMaybe<Scalars["String"]>;
  headline?: InputMaybe<Scalars["String"]>;
  propertyName?: InputMaybe<Scalars["String"]>;
}

export type SupplyContentHomePage = {
  __typename?: "SupplyContentHomePage";
  header?: Maybe<SupplyContentHubHeader>;
  homePageViewed?: Maybe<SupplyContentEgClickstreamData>;
  propertyDetails?: Maybe<SupplyContentPropertyDetailsSection>;
  sections?: Maybe<Array<SupplyContentHubSection>>;
};

export type SupplyContentHomePageHeaderArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type SupplyContentHomePagePropertyDetailsArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type SupplyContentHomePageSectionsArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"];
  propertyContext: PropertyContext;
};

export type SupplyContentHub = {
  __typename?: "SupplyContentHub";
  contentViewed?: Maybe<SupplyContentEgClickstreamData>;
  header: SupplyContentHubHeader;
  items: Array<SupplyContentHubItem>;
  sectionName?: Maybe<Scalars["String"]>;
};

export type SupplyContentHubCrumbItem = {
  __typename?: "SupplyContentHubCrumbItem";
  crumbSelected?: Maybe<SupplyContentEgClickstreamData>;
  link?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type SupplyContentHubHeader = {
  __typename?: "SupplyContentHubHeader";
  feedback?: Maybe<SupplyContentText>;
  heading: Scalars["String"];
  subHeading?: Maybe<Scalars["String"]>;
};

export type SupplyContentHubItem = {
  __typename?: "SupplyContentHubItem";
  contentSelected?: Maybe<SupplyContentEgClickstreamData>;
  heading: Scalars["String"];
  icon: Icon;
  link: Scalars["String"];
  text: Scalars["String"];
};

export type SupplyContentHubNavigation = {
  __typename?: "SupplyContentHubNavigation";
  backButton: SupplyContentButton;
  breadCrumbs: Array<SupplyContentHubCrumbItem>;
};

export type SupplyContentHubNormalSection = {
  __typename?: "SupplyContentHubNormalSection";
  items?: Maybe<Array<SupplyContentHubItem>>;
  listStyle?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export type SupplyContentHubSection = SupplyContentError | SupplyContentHubNormalSection;

export interface SupplyContentInputValidation {
  errorMessage: EgdsLocalizedText;
}

export type SupplyContentInteriorSize = {
  __typename?: "SupplyContentInteriorSize";
  heading: Scalars["String"];
  size: EgdsInputField;
  unit: EgdsBasicSelect;
};

export type SupplyContentInteriorSizeSection = SupplyContentErrorSection | SupplyContentInteriorSize;

export type SupplyContentLeaveWithoutSavingDialog = {
  __typename?: "SupplyContentLeaveWithoutSavingDialog";
  description: Scalars["String"];
  heading: Scalars["String"];
  leaveButton: SupplyContentButton;
  stayButton: SupplyContentButton;
};

export type SupplyContentLinkStyleButton = EgdsButton & {
  __typename?: "SupplyContentLinkStyleButton";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type SupplyContentLocationSharing = {
  __typename?: "SupplyContentLocationSharing";
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  header: SupplyContentHubHeader;
  mapSetting?: Maybe<SupplyContentMapSettingsSection>;
  propertyAddress: SupplyContentPropertyAddressSection;
};

export type SupplyContentManualTranslation = {
  __typename?: "SupplyContentManualTranslation";
  addButton: SupplyContentButton;
  deleteDialog: SupplyContentManualTranslationDeleteDialog;
  description: Scalars["String"];
  existingManualTranslations?: Maybe<Array<SupplyContentExistingManualTranslation>>;
  heading: Scalars["String"];
};

export type SupplyContentManualTranslationDeleteDialog = {
  __typename?: "SupplyContentManualTranslationDeleteDialog";
  cancelButton: SupplyContentButton;
  deleteButton: SupplyContentButton;
  heading: Scalars["String"];
};

export type SupplyContentManualTranslationOperation = "ADD" | "DELETE" | "UPDATE";

export type SupplyContentManualTranslationSection = SupplyContentErrorSection | SupplyContentManualTranslation;

export type SupplyContentManualTranslationSetting = {
  __typename?: "SupplyContentManualTranslationSetting";
  description: SupplyContentTextArea;
  headline: SupplyContentTextArea;
  instruction?: Maybe<Scalars["String"]>;
  language: EgdsBasicSelect;
  saveButton: SupplyContentButton;
  translationSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentManualTranslationSettingDialog = {
  __typename?: "SupplyContentManualTranslationSettingDialog";
  contentViewed?: Maybe<SupplyContentEgClickstreamData>;
  manualTranslation: SupplyContentManualTranslationSetting;
  toolbar: EgdsDialogToolbar;
};

export type SupplyContentManualTranslationUpdate = {
  __typename?: "SupplyContentManualTranslationUpdate";
  failures?: Maybe<Array<SupplyContentDescriptionUpdateFailure>>;
  successToast?: Maybe<EgdsToast>;
};

export interface SupplyContentManualTranslationUpdateInput {
  description?: InputMaybe<Scalars["String"]>;
  headline?: InputMaybe<Scalars["String"]>;
  locale: Scalars["String"];
  operation: SupplyContentManualTranslationOperation;
  optOutAutoTranslation: Scalars["Boolean"];
}

export type SupplyContentMapSettings = {
  __typename?: "SupplyContentMapSettings";
  description?: Maybe<Scalars["String"]>;
  displayOptions?: Maybe<EgdsRadioGroup>;
  exactLatitude?: Maybe<Scalars["String"]>;
  exactLongitude?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  instruction?: Maybe<SupplyContentSpannableText>;
  mapSettingSelected?: Maybe<SupplyContentEgClickstreamData>;
  obfuscatedLatitude?: Maybe<Scalars["String"]>;
  obfuscatedLongitude?: Maybe<Scalars["String"]>;
};

export type SupplyContentMapSettingsSection = SupplyContentErrorSection | SupplyContentMapSettings;

export type SupplyContentMaxLengthInputValidation = SupplyContentInputValidation & {
  __typename?: "SupplyContentMaxLengthInputValidation";
  errorMessage: EgdsLocalizedText;
  maxLength: Scalars["Int"];
};

export type SupplyContentMinLengthInputValidation = SupplyContentInputValidation & {
  __typename?: "SupplyContentMinLengthInputValidation";
  errorMessage: EgdsLocalizedText;
  minLength: Scalars["Int"];
};

export type SupplyContentModeration = {
  __typename?: "SupplyContentModeration";
  errorMessage: SupplyContentErrorMessage;
  id: Scalars["String"];
  rule: Moderation_Rule;
  type: Moderation_Type;
  value: Array<Scalars["String"]>;
};

export type SupplyContentPropertyAddress = {
  __typename?: "SupplyContentPropertyAddress";
  address?: Maybe<SupplyContentPropertyAddressDetail>;
  description?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  instruction?: Maybe<SupplyContentSpannableText>;
};

export type SupplyContentPropertyAddressDetail = {
  __typename?: "SupplyContentPropertyAddressDetail";
  address1: Scalars["String"];
  address2?: Maybe<Scalars["String"]>;
  cityWithPostalCode: Scalars["String"];
  countryCode: Scalars["String"];
};

export type SupplyContentPropertyAddressSection = SupplyContentErrorSection | SupplyContentPropertyAddress;

export type SupplyContentPropertyAnalytics = {
  __typename?: "SupplyContentPropertyAnalytics";
  communitySelected: Array<Maybe<SupplyContentEgClickstreamData>>;
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  propertyTypeSelected?: Maybe<SupplyContentEgClickstreamData>;
  renterSelected: Array<Maybe<SupplyContentEgClickstreamData>>;
  sizeChanged?: Maybe<SupplyContentEgClickstreamData>;
  unitSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentPropertyDetails = {
  __typename?: "SupplyContentPropertyDetails";
  address?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  modificationLink?: Maybe<SupplyContentText>;
  name?: Maybe<Scalars["String"]>;
  primaryPhone?: Maybe<Scalars["String"]>;
};

export type SupplyContentPropertyDetailsSection = SupplyContentError | SupplyContentPropertyDetails;

export type SupplyContentPropertyType = {
  __typename?: "SupplyContentPropertyType";
  heading: Scalars["String"];
  type: EgdsBasicSelect;
};

export type SupplyContentPropertyTypeSection = SupplyContentErrorSection | SupplyContentPropertyType;

export type SupplyContentSaveButton = {
  __typename?: "SupplyContentSaveButton";
  button: SupplyContentButton;
  leaveWithoutSavingDialog: SupplyContentLeaveWithoutSavingDialog;
};

export type SupplyContentScore = {
  __typename?: "SupplyContentScore";
  contentScoreViewed?: Maybe<SupplyContentEgClickstreamData>;
  details?: Maybe<SupplyContentScoreDetails>;
  heading: Scalars["String"];
  summary: SupplyContentScoreSummary;
  toastMsg?: Maybe<Scalars["String"]>;
};

export type SupplyContentScoreAllCompleted = {
  __typename?: "SupplyContentScoreAllCompleted";
  furtherStepsMsg: Scalars["String"];
  heading: Scalars["String"];
  icon: Scalars["String"];
  message: Scalars["String"];
};

export type SupplyContentScoreCard = {
  __typename?: "SupplyContentScoreCard";
  actionLink: SupplyContentText;
  attention: Scalars["String"];
  description: Scalars["String"];
  improvedScoreMsg: Scalars["String"];
  score: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyContentScoreCards = {
  __typename?: "SupplyContentScoreCards";
  cards?: Maybe<Array<SupplyContentScoreCard>>;
  heading: Scalars["String"];
};

export type SupplyContentScoreDetails = SupplyContentScoreAllCompleted | SupplyContentScoreCards;

export type SupplyContentScoreResp = SupplyContentError | SupplyContentScore;

export type SupplyContentScoreSummary = {
  __typename?: "SupplyContentScoreSummary";
  denominator: Scalars["String"];
  description: Scalars["String"];
  explanation: Array<Scalars["String"]>;
  explanationLink: SupplyContentText;
  ranking: Scalars["String"];
  score: Scalars["String"];
};

export type SupplyContentSpannableText = {
  __typename?: "SupplyContentSpannableText";
  contents: Array<SupplyContentText>;
  text: Scalars["String"];
};

export type SupplyContentText = {
  __typename?: "SupplyContentText";
  action?: Maybe<SupplyContextTextAction>;
  text: EgdsText;
};

export type SupplyContentTextArea = {
  __typename?: "SupplyContentTextArea";
  contentChanged?: Maybe<SupplyContentEgClickstreamData>;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  maxRows?: Maybe<Scalars["Int"]>;
  minRows?: Maybe<Scalars["Int"]>;
  moderation?: Maybe<Array<SupplyContentModeration>>;
  moderationPresented?: Maybe<SupplyContentEgClickstreamData>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly: Scalars["Boolean"];
  recommendation?: Maybe<SupplyContentDescriptionRecommendationSection>;
  required?: Maybe<Scalars["Boolean"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<SupplyContentInputValidation>>;
  value?: Maybe<Scalars["String"]>;
};

export type SupplyContentTips = {
  __typename?: "SupplyContentTips";
  carousel: SupplyContentCarousel;
  contentSelected?: Maybe<SupplyContentEgClickstreamData>;
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  link?: Maybe<EgdsExpandoLink>;
};

export interface SupplyContentUpdateLocationInput {
  showExactLocation: Scalars["Boolean"];
}

export type SupplyContentUpdateLocationResult = {
  __typename?: "SupplyContentUpdateLocationResult";
  error?: Maybe<SupplyContentErrorMessage>;
  mapSettingSaved?: Maybe<SupplyContentEgClickstreamData>;
  toast?: Maybe<EgdsToast>;
};

export type SupplyContentYourProperty = {
  __typename?: "SupplyContentYourProperty";
  contentAnalytics?: Maybe<SupplyContentPropertyAnalytics>;
  header: SupplyContentHubHeader;
  interiorSize: SupplyContentInteriorSizeSection;
  propertyType: SupplyContentPropertyTypeSection;
  suitabilityAmenity: SupplyContentAmenitySection;
};

export interface SupplyContentYourPropertyUpdateInput {
  amenities?: InputMaybe<Array<SupplyContentAmenityInput>>;
  propertyTypeId?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Scalars["String"]>;
}

export type SupplyContentYourStory = {
  __typename?: "SupplyContentYourStory";
  aboutYou: SupplyContentDescriptionSection;
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  header: SupplyContentHubHeader;
  uniqueBenefits: SupplyContentDescriptionSection;
  whyThisProperty: SupplyContentDescriptionSection;
};

export interface SupplyContentYourStoryUpdateInput {
  aboutYou?: InputMaybe<Scalars["String"]>;
  uniqueBenefits?: InputMaybe<Scalars["String"]>;
  whyThisProperty?: InputMaybe<Scalars["String"]>;
}

export type SupplyContextTextAction = {
  __typename?: "SupplyContextTextAction";
  selectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContractExpando = {
  __typename?: "SupplyContractExpando";
  divider?: Maybe<Scalars["Boolean"]>;
  expando: SupplyContractExpandoCard;
  expandoAction?: Maybe<SupplyContractingExpandoAction>;
  icon?: Maybe<Icon>;
  text: Array<Scalars["String"]>;
};

export type SupplyContractExpandoCard = EgdsExpandoCard | EgdsSpannableText;

export type SupplyContractTerms = {
  __typename?: "SupplyContractTerms";
  banners?: Maybe<Array<UiBanner>>;
  elements: Array<SupplyContractTermsElement>;
  notAvailableMessage?: Maybe<EgdsSpannableText>;
  primary: Scalars["String"];
  primaryLink?: Maybe<EgdsStandardLink>;
};

export type SupplyContractTermsElement =
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsTextSection
  | SupplyContractExpando
  | SupplyGeneralTermsLink;

export type SupplyContractingAcceptTermsAndConditions = {
  __typename?: "SupplyContractingAcceptTermsAndConditions";
  metadata?: Maybe<SupplyContractingEnrollmentMetadata>;
  program?: Maybe<Scalars["String"]>;
  propertyId?: Maybe<Scalars["String"]>;
};

export type SupplyContractingAction = SupplyContractingButton;

export type SupplyContractingAlignment = "CENTER" | "LEFT";

export type SupplyContractingBreadCrumbMenu = {
  __typename?: "SupplyContractingBreadCrumbMenu";
  breadCrumbItems: Array<SupplyContractingLinkAction>;
};

export type SupplyContractingButton = {
  __typename?: "SupplyContractingButton";
  action?: Maybe<SupplyContractingButtonAction>;
  button: EgdsButton;
};

export type SupplyContractingButtonAction =
  | SupplyContractingAcceptTermsAndConditions
  | SupplyContractingNavigateToRatePlan
  | SupplyContractingOpenSheet;

export type SupplyContractingCard = {
  __typename?: "SupplyContractingCard";
  background?: Maybe<Scalars["String"]>;
  components?: Maybe<Array<SupplyContractingCardComponent>>;
};

export type SupplyContractingCardComponent = SupplyContractingProgramItem;

export type SupplyContractingClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyContractingClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickStreamContractingPartnerCommonData>;
};

export type SupplyContractingContent = {
  __typename?: "SupplyContractingContent";
  action?: Maybe<SupplyContractingTextAction>;
  alignment: SupplyContractingAlignment;
  text?: Maybe<SupplyContractingText>;
};

export type SupplyContractingEligibilityFeedback = {
  __typename?: "SupplyContractingEligibilityFeedback";
  alignment: SupplyContractingAlignment;
  graphic?: Maybe<UiGraphic>;
  primary: Scalars["String"];
  secondaries: Array<EgdsSpannableText>;
};

export type SupplyContractingEnrollmentMetadata = {
  __typename?: "SupplyContractingEnrollmentMetadata";
  offerIds: Array<Scalars["String"]>;
};

export type SupplyContractingExpandoAction = {
  __typename?: "SupplyContractingExpandoAction";
  action?: Maybe<SupplyContractingAction>;
  sheet?: Maybe<SupplyContractingSheet>;
};

export type SupplyContractingFrequentlyAskedQuestions = {
  __typename?: "SupplyContractingFrequentlyAskedQuestions";
  faqs: Array<SupplyContractExpando>;
  heading?: Maybe<SupplyContractingHeading>;
};

export type SupplyContractingHeading = {
  __typename?: "SupplyContractingHeading";
  alignment?: Maybe<SupplyContractingAlignment>;
  heading: EgdsHeading;
};

export type SupplyContractingIconText = {
  __typename?: "SupplyContractingIconText";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyContractingIllustration = {
  __typename?: "SupplyContractingIllustration";
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyContractingLearnMoreSection = {
  __typename?: "SupplyContractingLearnMoreSection";
  content?: Maybe<SupplyContractingContent>;
};

export type SupplyContractingLicensePlate = {
  __typename?: "SupplyContractingLicensePlate";
  breadCrumbMenu?: Maybe<SupplyContractingBreadCrumbMenu>;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type SupplyContractingLinkAction = {
  __typename?: "SupplyContractingLinkAction";
  action: EgdsStandardLink;
  analytics?: Maybe<SupplyContractingClickStreamData>;
};

export type SupplyContractingMessages =
  | SupplyContractingEligibilityFeedback
  | SupplyContractingMessagingBanner
  | SupplyContractingMessagingCard
  | SupplyContractingNotificationBanner;

export type SupplyContractingMessagingBanner = {
  __typename?: "SupplyContractingMessagingBanner";
  contents?: Maybe<Array<SupplyContractingContent>>;
  graphic?: Maybe<UiGraphic>;
  theme?: Maybe<Scalars["String"]>;
};

export type SupplyContractingMessagingCard = {
  __typename?: "SupplyContractingMessagingCard";
  background?: Maybe<Scalars["String"]>;
  contents: Array<EgdsSpannableText>;
  graphic?: Maybe<UiGraphic>;
  links?: Maybe<Array<EgdsStandardLink>>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyContractingMutationResponse = {
  __typename?: "SupplyContractingMutationResponse";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  sideEffects?: Maybe<Array<SupplyContractingSideEffects>>;
  status: SupplyContractingResponseStatus;
};

export type SupplyContractingNavigateToRatePlan = {
  __typename?: "SupplyContractingNavigateToRatePlan";
  url: Scalars["String"];
};

export type SupplyContractingNotificationBanner = {
  __typename?: "SupplyContractingNotificationBanner";
  badge?: Maybe<EgdsStandardBadge>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyContractingOpenSheet = {
  __typename?: "SupplyContractingOpenSheet";
  sheet?: Maybe<SupplyContractingSheet>;
  sheetId?: Maybe<Scalars["String"]>;
};

export type SupplyContractingOpenTermsSheet = {
  __typename?: "SupplyContractingOpenTermsSheet";
  sheetId: Scalars["String"];
};

export type SupplyContractingOptimizedDistributionComponent =
  | SupplyContractingTermsAcceptance
  | SupplyContractingWholesaleRatePlan;

export type SupplyContractingOptimizedDistributionTemplate = {
  __typename?: "SupplyContractingOptimizedDistributionTemplate";
  components?: Maybe<Array<SupplyContractingOptimizedDistributionComponent>>;
  header?: Maybe<Scalars["String"]>;
};

export type SupplyContractingProgramComponent =
  | SupplyContractingProgramEnrollmentNavigator
  | SupplyContractingTabContainer
  | SupplyContractingTabs
  | SupplyContractingTermsAndConditionsSection
  | SupplyContractingTermsAndConditionsSheet;

export type SupplyContractingProgramEnrollment = {
  __typename?: "SupplyContractingProgramEnrollment";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  components?: Maybe<Array<SupplyContractingProgramComponent>>;
  cta?: Maybe<SupplyContractingButton>;
  enroll?: Maybe<SupplyContractingEnrollmentMetadata>;
  messages?: Maybe<SupplyContractingMessages>;
  primary: Scalars["String"];
  secondary?: Maybe<Scalars["String"]>;
};

export type SupplyContractingProgramEnrollmentNavigator = {
  __typename?: "SupplyContractingProgramEnrollmentNavigator";
  contents: Array<SupplyContractingContent>;
  cta?: Maybe<SupplyContractingButton>;
  heading?: Maybe<SupplyContractingHeading>;
};

export type SupplyContractingProgramItem = {
  __typename?: "SupplyContractingProgramItem";
  contents?: Maybe<Array<SupplyContractingContent>>;
  divider?: Maybe<Scalars["Boolean"]>;
  heading?: Maybe<Scalars["String"]>;
  illustration?: Maybe<SupplyContractingIllustration>;
};

export type SupplyContractingResponseStatus = "FAIL" | "SUCCESS";

export type SupplyContractingSheet = {
  __typename?: "SupplyContractingSheet";
  closeText?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated in favor of contentTemplate */
  content: SupplyLegalTemplate;
  contentTemplate?: Maybe<SupplyContractingSheetTemplate>;
  navType?: Maybe<SupplyContractingToolbarNavType>;
  select?: Maybe<EgdsBasicSelect>;
  sheet: EgdsSheet;
  title?: Maybe<Scalars["String"]>;
  toolTip?: Maybe<SupplyContractingToolTip>;
};

export type SupplyContractingSheetTemplate = SupplyContractingOptimizedDistributionTemplate | SupplyLegalTemplate;

export type SupplyContractingSideEffects = SupplyContractingProgramEnrollment;

export type SupplyContractingTabComponent =
  | SupplyContractingCard
  | SupplyContractingFrequentlyAskedQuestions
  | SupplyContractingLearnMoreSection
  | SupplyContractingTermsAndConditionsSection;

export type SupplyContractingTabContainer = {
  __typename?: "SupplyContractingTabContainer";
  components?: Maybe<Array<SupplyContractingTabComponent>>;
  divider?: Maybe<Scalars["Boolean"]>;
  header?: Maybe<SupplyContractingHeading>;
  messageCard?: Maybe<SupplyContractingMessagingCard>;
  tabId: Scalars["String"];
};

export type SupplyContractingTabs = {
  __typename?: "SupplyContractingTabs";
  tabs: EgdsTabs;
};

export type SupplyContractingTermsAcceptance = {
  __typename?: "SupplyContractingTermsAcceptance";
  acceptTermsCta?: Maybe<SupplyContractingButton>;
  contents?: Maybe<Array<Maybe<SupplyContractingContent>>>;
  primary?: Maybe<Scalars["String"]>;
  step?: Maybe<SupplyContractingIconText>;
  viewTermsContent?: Maybe<SupplyContractingContent>;
};

export type SupplyContractingTermsAndConditionsSection = {
  __typename?: "SupplyContractingTermsAndConditionsSection";
  content?: Maybe<SupplyContractingContent>;
};

export type SupplyContractingTermsAndConditionsSheet = {
  __typename?: "SupplyContractingTermsAndConditionsSheet";
  sheet: SupplyContractingSheet;
  sheetId: Scalars["String"];
};

export type SupplyContractingText = EgdsBulletedList | EgdsPlainText | EgdsSpannableText;

export type SupplyContractingTextAction = SupplyContractingOpenTermsSheet;

export type SupplyContractingToolTip = {
  __typename?: "SupplyContractingToolTip";
  content: Array<Scalars["String"]>;
  trigger: EgdsIconText;
};

export type SupplyContractingToolbarNavType = "BACK" | "CLOSE";

export type SupplyContractingWholesaleRatePlan = {
  __typename?: "SupplyContractingWholesaleRatePlan";
  contents?: Maybe<Array<Maybe<SupplyContractingContent>>>;
  messageCard?: Maybe<SupplyContractingMessagingCard>;
  primary?: Maybe<Scalars["String"]>;
  ratePlanCta?: Maybe<SupplyContractingButton>;
  step?: Maybe<SupplyContractingIconText>;
};

export type SupplyCountryCodeOption = {
  __typename?: "SupplyCountryCodeOption";
  label: Scalars["String"];
  masks: Array<Scalars["String"]>;
  selected?: Maybe<Scalars["Boolean"]>;
  value: Scalars["String"];
};

export type SupplyCountryCodeSelection = {
  __typename?: "SupplyCountryCodeSelection";
  egdsElementId?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  options: Array<SupplyCountryCodeOption>;
};

export interface SupplyCurrentPageContext {
  path?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  view?: InputMaybe<Scalars["String"]>;
}

export type SupplyCustomPhoneNumberField = {
  __typename?: "SupplyCustomPhoneNumberField";
  countryCode: SupplyCountryCodeSelection;
  phoneNumber: EgdsTextInputField;
};

export type SupplyDashEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyDashEgClickstream";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partnerCommon?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyDashboardHeader = {
  __typename?: "SupplyDashboardHeader";
  backgroundImages: Array<Image>;
  currentSelectionName?: Maybe<Scalars["String"]>;
  /** @deprecated freezing response for old mobile client to not break */
  dashboardType?: Maybe<SupplyDashboardType>;
  dashboardTypeWithTemplating?: Maybe<SupplyDashboardType>;
  heroImagePresented?: Maybe<SupplyDashEgClickstream>;
  lifeCycleStage?: Maybe<SupplyPartnerLifeCycleStage>;
  opportunity?: Maybe<SupplyDashboardOpportunityIcon>;
  premierHost?: Maybe<SupplyDashboardPremierHost>;
  primary?: Maybe<Scalars["String"]>;
  selectorAction?: Maybe<UiLinkAction>;
};

export type SupplyDashboardOpportunityIcon = {
  __typename?: "SupplyDashboardOpportunityIcon";
  accessibility?: Maybe<Scalars["String"]>;
  iconToken: Scalars["String"];
  opportunityIconPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  opportunityIconSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyDashboardPremierHost = {
  __typename?: "SupplyDashboardPremierHost";
  graphic: UiGraphic;
  premierHostClicked?: Maybe<SupplyDashEgClickstream>;
  /** @deprecated this field is no longer used */
  premierHostIconClicked: SupplyDashEgClickstream;
  premierHostIconPresented?: Maybe<SupplyDashEgClickstream>;
  /** @deprecated this field is no longer used */
  premierHostPresented: SupplyDashEgClickstream;
  /** @deprecated this field is no longer used */
  primary: Scalars["String"];
  primaryText?: Maybe<Scalars["String"]>;
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type SupplyDashboardType = "DASHBOARD" | "LEGACY";

export type SupplyDeleteAccount = {
  __typename?: "SupplyDeleteAccount";
  deleteAccountButton?: Maybe<EgdsButton>;
  deleteAccountClosedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  deleteAccountSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  deleteAccountViewedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  doNotDeleteAccountButton?: Maybe<EgdsButton>;
  frictionAlert?: Maybe<SupplyDeleteAccountAlert>;
  sections?: Maybe<Array<SupplyDeleteAccountSection>>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyDeleteAccountAlert = {
  __typename?: "SupplyDeleteAccountAlert";
  cancelButton?: Maybe<EgdsButton>;
  confirmButton?: Maybe<EgdsButton>;
  deleteAccountAlertCancelEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  deleteAccountAlertConfirmEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  description?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyDeleteAccountMutationResponse = {
  __typename?: "SupplyDeleteAccountMutationResponse";
  alert?: Maybe<SupplyDeleteAccountAlert>;
  deleteAccountResponseEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  isSuccess?: Maybe<Scalars["Boolean"]>;
};

export type SupplyDeleteAccountSection = {
  __typename?: "SupplyDeleteAccountSection";
  description?: Maybe<Scalars["String"]>;
  disclaimer?: Maybe<Scalars["String"]>;
  list?: Maybe<Array<Scalars["String"]>>;
  title?: Maybe<Scalars["String"]>;
};

export interface SupplyDismissibleCard {
  description: Array<Scalars["String"]>;
  icon?: Maybe<Icon>;
  key: Scalars["String"];
  preferenceGroup: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
}

export interface SupplyDismissibleCardInput {
  dismissDurationDays?: InputMaybe<Scalars["Int"]>;
  key: Scalars["String"];
  preferenceGroup: Scalars["String"];
}

export type SupplyDismissibleCardResponse = {
  __typename?: "SupplyDismissibleCardResponse";
  error?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type SupplyEmptyPropertiesSearchResult = {
  __typename?: "SupplyEmptyPropertiesSearchResult";
  primary: EgdsIconText;
  secondary?: Maybe<Scalars["String"]>;
};

export interface SupplyFeeFilterInput {
  includedFees?: InputMaybe<Array<Scalars["String"]>>;
}

export type SupplyFeeInputField = {
  __typename?: "SupplyFeeInputField";
  amount: EgdsNumberInputField;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  currencySymbol?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  productCode: Scalars["String"];
  ratesAvailable: Scalars["Boolean"];
  type: EgdsSelect;
};

export type SupplyFeeSignals = {
  __typename?: "SupplyFeeSignals";
  currency: SupplySubscribeSignalAction;
  feeLoad: SupplyPublishSignalAction;
  feeUpdate: SupplyPublishSignalAction;
  rateLoad: SupplySubscribeSignalAction;
  rateReady: SupplySubscribeSignalAction;
};

export type SupplyFees = {
  __typename?: "SupplyFees";
  fields: Array<SupplyFeeInputField>;
  signals?: Maybe<SupplyFeeSignals>;
  title?: Maybe<SupplyFeesTitle>;
};

export interface SupplyFeesInput {
  amount: Scalars["Float"];
  productCode: Scalars["String"];
  type: Scalars["String"];
}

export type SupplyFeesTitle = {
  __typename?: "SupplyFeesTitle";
  heading: Scalars["String"];
  subHeading?: Maybe<Scalars["String"]>;
};

export type SupplyGeneralTermsLink = {
  __typename?: "SupplyGeneralTermsLink";
  lastUpdated?: Maybe<Scalars["String"]>;
  linkText: Scalars["String"];
  sheet: SupplyGeneralTermsSheet;
};

export type SupplyGeneralTermsSheet = {
  __typename?: "SupplyGeneralTermsSheet";
  content: SupplyLegalTemplate;
  select?: Maybe<EgdsBasicSelect>;
  sheet: EgdsSheet;
  title?: Maybe<Scalars["String"]>;
  toolTip?: Maybe<SupplyGeneralTermsToolTip>;
};

export type SupplyGeneralTermsToolTip = {
  __typename?: "SupplyGeneralTermsToolTip";
  content: Array<Scalars["String"]>;
  trigger: EgdsIconText;
};

export type SupplyGuestItemContent = {
  __typename?: "SupplyGuestItemContent";
  primary: Scalars["String"];
  secondary: Scalars["String"];
};

export type SupplyHelpContactMethods = {
  __typename?: "SupplyHelpContactMethods";
  heading: Scalars["String"];
  methods: Array<SupplyContactMethod>;
  supportOptionsPresentedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyHelpContactUs = {
  __typename?: "SupplyHelpContactUs";
  contactMethods?: Maybe<SupplyHelpContactMethods>;
  contactUsModulePresentedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  supportArticles?: Maybe<SupplyHelpSupportArticles>;
  taxonomySelector?: Maybe<SupplyHelpTaxonomySelector>;
  title: Scalars["String"];
};

export type SupplyHelpSupportArticles = {
  __typename?: "SupplyHelpSupportArticles";
  articles: Array<EgdsStandardLink>;
  heading: Scalars["String"];
  helpArticleSelectedClickstreamPayloads?: Maybe<Array<Maybe<Scalars["AnalyticsPayload"]>>>;
};

export type SupplyHelpTaxonomy = {
  __typename?: "SupplyHelpTaxonomy";
  description: EgdsSpannableText;
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  taxonomyId: Scalars["String"];
  taxonomyPresentedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  taxonomySelectedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyHelpTaxonomySelector = {
  __typename?: "SupplyHelpTaxonomySelector";
  description: EgdsSpannableText;
  heading: Scalars["String"];
  previousOptions?: Maybe<EgdsStandardLink>;
  previousOptionsSelectedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  taxonomies: Array<SupplyHelpTaxonomy>;
  travellerHelpLink?: Maybe<EgdsStandardLink>;
};

export type SupplyHomepage = {
  __typename?: "SupplyHomepage";
  layout?: Maybe<SupplyHomepageLayout>;
};

export type SupplyHomepageComponentMetadata = {
  __typename?: "SupplyHomepageComponentMetadata";
  id: Scalars["String"];
};

export type SupplyHomepageLayout = {
  __typename?: "SupplyHomepageLayout";
  components: Array<SupplyHomepageComponentMetadata>;
};

export type SupplyIndirectTax = {
  __typename?: "SupplyIndirectTax";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  buttons?: Maybe<SupplyIndirectTaxButtons>;
  taxDetails?: Maybe<Array<SupplyIndirectTaxDetail>>;
  taxDisabledDetail?: Maybe<SupplyIndirectTaxDisabledDetail>;
  title?: Maybe<SupplyIndirectTaxTitle>;
};

export type SupplyIndirectTaxButtons = {
  __typename?: "SupplyIndirectTaxButtons";
  backButton: EgdsButton;
  nextButton: EgdsButton;
};

export type SupplyIndirectTaxDetail = {
  __typename?: "SupplyIndirectTaxDetail";
  legalName?: Maybe<SupplyIndirectTaxLegalName>;
  registrationStatus?: Maybe<SupplyIndirectTaxRegistrationStatus>;
  taxId?: Maybe<SupplyIndirectTaxTaxId>;
  taxJurisdictionName: Scalars["String"];
  taxJurisdictionType: Scalars["String"];
  taxationCountry: Scalars["String"];
};

export type SupplyIndirectTaxDisabledDetail = {
  __typename?: "SupplyIndirectTaxDisabledDetail";
  description: Scalars["String"];
};

export type SupplyIndirectTaxExamplePopover = {
  __typename?: "SupplyIndirectTaxExamplePopover";
  exampleList: Array<EgdsSpannableText>;
  exampleTrigger: EgdsBasicTriggerPill;
};

export interface SupplyIndirectTaxInput {
  taxDetails: Array<SupplyIndirectTaxInputDetail>;
}

export interface SupplyIndirectTaxInputDetail {
  legalName: Scalars["String"];
  registrationStatus: Scalars["String"];
  taxId: Scalars["String"];
  taxJurisdictionName: Scalars["String"];
  taxJurisdictionType: Scalars["String"];
  taxationCountry: Scalars["String"];
}

export type SupplyIndirectTaxLegalName = {
  __typename?: "SupplyIndirectTaxLegalName";
  header: SupplyIndirectTaxTitle;
  legalName: EgdsTextInputField;
};

export type SupplyIndirectTaxNavigate = {
  __typename?: "SupplyIndirectTaxNavigate";
  relativePath: Scalars["String"];
};

export type SupplyIndirectTaxRegistrationStatus = {
  __typename?: "SupplyIndirectTaxRegistrationStatus";
  header: SupplyIndirectTaxTitle;
  options: EgdsBasicRadioGroup;
};

export type SupplyIndirectTaxTaxId = {
  __typename?: "SupplyIndirectTaxTaxId";
  examplePopover: SupplyIndirectTaxExamplePopover;
  header: SupplyIndirectTaxTitle;
  optionalHeader: SupplyIndirectTaxTitle;
  optionalTaxId: EgdsTextInputField;
  taxId: EgdsTextInputField;
};

export type SupplyIndirectTaxTitle = {
  __typename?: "SupplyIndirectTaxTitle";
  description: Scalars["String"];
  heading: Scalars["String"];
};

export type SupplyInformationPanel = {
  __typename?: "SupplyInformationPanel";
  expando?: Maybe<EgdsExpandoPeek>;
  icon: Icon;
  sections: Array<SupplyInformationPanelSection>;
};

export type SupplyInformationPanelSection = {
  __typename?: "SupplyInformationPanelSection";
  content: EgdsParagraph;
  heading: EgdsHeading;
};

export type SupplyInventoryReview = {
  __typename?: "SupplyInventoryReview";
  addedInventory: Scalars["Int"];
  currentInventory: Scalars["Int"];
  date: Date;
  newInventory: Scalars["Int"];
};

export type SupplyLegalTemplate = {
  __typename?: "SupplyLegalTemplate";
  /** @deprecated Deprecated in favor of htmls */
  html: Scalars["String"];
  htmls?: Maybe<Array<Scalars["String"]>>;
  plainText?: Maybe<Array<Scalars["String"]>>;
};

export type SupplyLegalView = {
  __typename?: "SupplyLegalView";
  legalCards: Array<EgdsStandardMessagingCard>;
};

export type SupplyLocalizedPluralString = SupplyLocalizedString & {
  __typename?: "SupplyLocalizedPluralString";
  few?: Maybe<Scalars["String"]>;
  key: Scalars["String"];
  many?: Maybe<Scalars["String"]>;
  one?: Maybe<Scalars["String"]>;
  other?: Maybe<Scalars["String"]>;
  two?: Maybe<Scalars["String"]>;
  zero?: Maybe<Scalars["String"]>;
};

export type SupplyLocalizedSimpleString = SupplyLocalizedString & {
  __typename?: "SupplyLocalizedSimpleString";
  key: Scalars["String"];
  localization: Scalars["String"];
};

export interface SupplyLocalizedString {
  key: Scalars["String"];
}

export type SupplyLoyalty = {
  __typename?: "SupplyLoyalty";
  premierHost?: Maybe<SupplyLoyaltyPremierHost>;
  vip?: Maybe<SupplyLoyaltyVip>;
};

export type SupplyLoyaltyVipArgs = {
  propertyContext: PropertyContext;
};

export type SupplyLoyaltyActionId =
  | "VIP_BENEFITS_SHEET"
  | "VIP_CONTACTS_SHEET"
  | "VIP_CONTRACT_SHEET"
  | "VIP_MEMBERS_ONLY_DISCOUNT_SHEET"
  | "VIP_ONBOARDING_ACTIVATION_SUBMIT"
  | "VIP_PROGRAM_PROGRESS_SHEET"
  | "VIP_PROGRAM_SUBMIT";

export type SupplyLoyaltyButton = {
  __typename?: "SupplyLoyaltyButton";
  accessibility?: Maybe<Scalars["String"]>;
  actionId?: Maybe<SupplyLoyaltyActionId>;
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyCallToActionCard = {
  __typename?: "SupplyLoyaltyCallToActionCard";
  description?: Maybe<Array<Scalars["String"]>>;
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyLoyaltyClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyLoyaltyComponents = SupplyLoyaltyCallToActionCard;

export type SupplyLoyaltyInput = {
  __typename?: "SupplyLoyaltyInput";
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  input?: Maybe<EgdsInputField>;
};

export type SupplyLoyaltyLicensePlate = {
  __typename?: "SupplyLoyaltyLicensePlate";
  actionMenu?: Maybe<Array<SupplyLoyaltyLinkAction>>;
  breadcrumbMenu?: Maybe<Array<SupplyLoyaltyLinkAction>>;
  description?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type SupplyLoyaltyLinkAction = {
  __typename?: "SupplyLoyaltyLinkAction";
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  target?: Maybe<UiLinkTarget>;
  text?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyMutation = {
  __typename?: "SupplyLoyaltyMutation";
  vip?: Maybe<SupplyLoyaltyVipMutation>;
};

export type SupplyLoyaltyMutationVipArgs = {
  propertyContext: PropertyContext;
};

export type SupplyLoyaltyMutationResponse = {
  __typename?: "SupplyLoyaltyMutationResponse";
  sideEffects?: Maybe<Array<SupplyLoyaltySideEffects>>;
  status: SupplyLoyaltyResponseStatus;
};

export type SupplyLoyaltyNotification = {
  __typename?: "SupplyLoyaltyNotification";
  notification: UiBanner;
};

export type SupplyLoyaltyPremierHost = {
  __typename?: "SupplyLoyaltyPremierHost";
  details?: Maybe<SupplyPremierHost>;
  download?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyPremierHostDownloadArgs = {
  type: SupplyPremierHostDownloadType;
};

export type SupplyLoyaltyResponseStatus = "FAIL" | "SUCCESS";

export type SupplyLoyaltySection = {
  __typename?: "SupplyLoyaltySection";
  components?: Maybe<Array<SupplyLoyaltyComponents>>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltySheetFooter = {
  __typename?: "SupplyLoyaltySheetFooter";
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltySideEffects = SupplyLoyaltyNotification;

export type SupplyLoyaltySlimCard = {
  __typename?: "SupplyLoyaltySlimCard";
  actionId?: Maybe<SupplyLoyaltyActionId>;
  actionLink?: Maybe<SupplyLoyaltyLinkAction>;
  description?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyToolbar = {
  __typename?: "SupplyLoyaltyToolbar";
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyVip = {
  __typename?: "SupplyLoyaltyVIP";
  vipOnboardingActivation?: Maybe<SupplyLoyaltyVipOnboardingActivation>;
  vipProgram?: Maybe<SupplyLoyaltyVipProgram>;
};

export type SupplyLoyaltyVipMutation = {
  __typename?: "SupplyLoyaltyVIPMutation";
  vipOnboardingActivation?: Maybe<SupplyLoyaltyMutationResponse>;
};

export type SupplyLoyaltyVipMutationVipOnboardingActivationArgs = {
  account: Scalars["String"];
  activationDate: DateInput;
};

export type SupplyLoyaltyVipBenefitsSheet = {
  __typename?: "SupplyLoyaltyVipBenefitsSheet";
  benefits?: Maybe<Array<SupplyLoyaltySlimCard>>;
  description?: Maybe<Scalars["String"]>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipContactsSheet = {
  __typename?: "SupplyLoyaltyVipContactsSheet";
  description?: Maybe<Scalars["String"]>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipContractSheet = {
  __typename?: "SupplyLoyaltyVipContractSheet";
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  legal?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipMembersOnlyDiscountSheet = {
  __typename?: "SupplyLoyaltyVipMembersOnlyDiscountSheet";
  datesDescription?: Maybe<Scalars["String"]>;
  datesTitle?: Maybe<Scalars["String"]>;
  discountDescription?: Maybe<Scalars["String"]>;
  discountInputs?: Maybe<Array<SupplyLoyaltyInput>>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  nameInput?: Maybe<SupplyLoyaltyInput>;
  nameInputDescription?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipOnboardingActivation = {
  __typename?: "SupplyLoyaltyVipOnboardingActivation";
  accountSelect?: Maybe<EgdsSelect>;
  accountSelectAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  accountSelectDescription?: Maybe<Scalars["String"]>;
  activateButton?: Maybe<SupplyLoyaltyButton>;
  activateButtonClickAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  activationDate?: Maybe<EgdsSingleDatePicker>;
  activationDateClickAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  activationDateTitle?: Maybe<Scalars["String"]>;
  banner?: Maybe<UiBanner>;
  clickStreamPageView?: Maybe<SupplyLoyaltyClickStreamData>;
  createAccountLink?: Maybe<SupplyLoyaltyLinkAction>;
  createAccountLinkClickAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  pageTitle?: Maybe<Scalars["String"]>;
  trainingSubtitles?: Maybe<Array<Scalars["String"]>>;
  trainingTitle?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyVipProgram = {
  __typename?: "SupplyLoyaltyVipProgram";
  benefits?: Maybe<SupplyLoyaltyVipBenefitsSheet>;
  contacts?: Maybe<SupplyLoyaltyVipContactsSheet>;
  contract?: Maybe<SupplyLoyaltyVipContractSheet>;
  licensePlate?: Maybe<SupplyLoyaltyLicensePlate>;
  membersOnlyDiscount?: Maybe<SupplyLoyaltyVipMembersOnlyDiscountSheet>;
  programProgress?: Maybe<SupplyLoyaltyVipProgramProgressSheet>;
  sections?: Maybe<Array<SupplyLoyaltySection>>;
};

export type SupplyLoyaltyVipProgramProgressBenefits = {
  __typename?: "SupplyLoyaltyVipProgramProgressBenefits";
  commonElements?: Maybe<SupplyLoyaltyVipProgramProgressCommon>;
  playbackImodDetails?: Maybe<Array<Scalars["String"]>>;
  playbackImodName?: Maybe<Scalars["String"]>;
  playbackImodSelected?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyVipProgramProgressCommon = {
  __typename?: "SupplyLoyaltyVipProgramProgressCommon";
  content?: Maybe<Array<EgdsText>>;
  editButton?: Maybe<SupplyLoyaltyButton>;
  initialButton?: Maybe<SupplyLoyaltyButton>;
  step?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyLoyaltyVipProgramProgressContacts = {
  __typename?: "SupplyLoyaltyVipProgramProgressContacts";
  commonElements?: Maybe<SupplyLoyaltyVipProgramProgressCommon>;
};

export type SupplyLoyaltyVipProgramProgressContract = {
  __typename?: "SupplyLoyaltyVipProgramProgressContract";
  commonElements?: Maybe<SupplyLoyaltyVipProgramProgressCommon>;
};

export type SupplyLoyaltyVipProgramProgressSheet = {
  __typename?: "SupplyLoyaltyVipProgramProgressSheet";
  benefits?: Maybe<SupplyLoyaltyVipProgramProgressBenefits>;
  contacts?: Maybe<SupplyLoyaltyVipProgramProgressContacts>;
  contract?: Maybe<SupplyLoyaltyVipProgramProgressContract>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  title?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyMarketMakerGrid = {
  __typename?: "SupplyMarketMakerGrid";
  competitorAverageRatesSection?: Maybe<SupplyMarketMakerGridCompetitorAverageRatesSection>;
  competitorRates?: Maybe<SupplyMarketMakerGridCompetitorRatesSection>;
  dates: Array<SupplyMarketMakerGridDate>;
  legend?: Maybe<SupplyMarketMakerGridLegend>;
  navigation?: Maybe<SupplyMarketMakerGridNavigation>;
  rates: SupplyMarketMakerGridRates;
};

export type SupplyMarketMakerGridCompetitorAverageRatesSection = {
  __typename?: "SupplyMarketMakerGridCompetitorAverageRatesSection";
  averageBookedRates?: Maybe<SupplyMarketMakerGridRates>;
  averageUnbookedRates?: Maybe<SupplyMarketMakerGridRates>;
  link?: Maybe<EgdsStandardLink>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyMarketMakerGridCompetitorRatesSection = {
  __typename?: "SupplyMarketMakerGridCompetitorRatesSection";
  collapse?: Maybe<SupplyMarketMakerIconButton>;
  competitors: Array<SupplyMarketMakerGridRates>;
  expand?: Maybe<SupplyMarketMakerIconButton>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyMarketMakerGridDate = {
  __typename?: "SupplyMarketMakerGridDate";
  monthDay?: Maybe<Scalars["String"]>;
  weekDay?: Maybe<Scalars["String"]>;
};

export type SupplyMarketMakerGridLegend = {
  __typename?: "SupplyMarketMakerGridLegend";
  items: Array<SupplyMarketMakerGridLegendItem>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyMarketMakerGridLegendItem = {
  __typename?: "SupplyMarketMakerGridLegendItem";
  icon: Icon;
  label: Scalars["String"];
};

export type SupplyMarketMakerGridNavigation = {
  __typename?: "SupplyMarketMakerGridNavigation";
  dateLabel?: Maybe<Scalars["String"]>;
  next?: Maybe<SupplyMarketMakerIconButton>;
  previous?: Maybe<SupplyMarketMakerIconButton>;
};

export type SupplyMarketMakerGridRate = {
  __typename?: "SupplyMarketMakerGridRate";
  icon?: Maybe<Icon>;
  rate?: Maybe<Scalars["String"]>;
};

export type SupplyMarketMakerGridRates = {
  __typename?: "SupplyMarketMakerGridRates";
  heading: EgdsStylizedText;
  subheading?: Maybe<EgdsStylizedText>;
  values: Array<SupplyMarketMakerGridRate>;
};

export type SupplyMarketMakerIconButton = {
  __typename?: "SupplyMarketMakerIconButton";
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type SupplyMarketplaceFeed = {
  __typename?: "SupplyMarketplaceFeed";
  feedItems: Array<SupplyMarketplaceFeedItem>;
  marketingFeedAdditionalCardsAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  modulePresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyMarketplaceFeedAction =
  | EgdsDismissAction
  | SupplyMarketplaceFeedActionItem
  | SupplyMarketplaceFeedDismissActionItem;

export interface SupplyMarketplaceFeedActionInput {
  actionType: Scalars["String"];
  actionUrl: Scalars["String"];
  iconType: Scalars["String"];
  label: Scalars["String"];
}

export type SupplyMarketplaceFeedActionItem = {
  __typename?: "SupplyMarketplaceFeedActionItem";
  actionType?: Maybe<Scalars["String"]>;
  actionUrl?: Maybe<Scalars["String"]>;
  iconType?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type SupplyMarketplaceFeedDefaultItem = SupplyMarketplaceFeedItem & {
  __typename?: "SupplyMarketplaceFeedDefaultItem";
  body: Scalars["String"];
  contextMenuActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  contextMenuClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  contextMenuSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  feedItemId?: Maybe<Scalars["String"]>;
  feedItemType?: Maybe<Scalars["String"]>;
  iconType?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  marketingFeedCardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  primaryAction?: Maybe<SupplyMarketplaceFeedAction>;
  primaryActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  secondaryActions: Array<Maybe<SupplyMarketplaceFeedAction>>;
  title: Scalars["String"];
};

export type SupplyMarketplaceFeedDismiss = {
  __typename?: "SupplyMarketplaceFeedDismiss";
  feedItemId?: Maybe<Scalars["String"]>;
};

export type SupplyMarketplaceFeedDismissActionItem = {
  __typename?: "SupplyMarketplaceFeedDismissActionItem";
  actionLabel: Scalars["String"];
  marketplaceFeedDismissActionSelected?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyMarketplaceFeedEmptyItem = SupplyMarketplaceFeedItem & {
  __typename?: "SupplyMarketplaceFeedEmptyItem";
  body: Scalars["String"];
  contextMenuActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  contextMenuClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  contextMenuSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  feedItemId?: Maybe<Scalars["String"]>;
  feedItemType?: Maybe<Scalars["String"]>;
  iconType?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  marketingFeedCardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  primaryAction?: Maybe<SupplyMarketplaceFeedAction>;
  primaryActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export interface SupplyMarketplaceFeedInput {
  feedItemActionType: Scalars["String"];
  feedItemId: Scalars["String"];
}

export interface SupplyMarketplaceFeedItem {
  body: Scalars["String"];
  contextMenuActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  contextMenuClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  contextMenuSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  feedItemId?: Maybe<Scalars["String"]>;
  feedItemType?: Maybe<Scalars["String"]>;
  iconType?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  marketingFeedCardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  primaryAction?: Maybe<SupplyMarketplaceFeedAction>;
  primaryActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
}

export interface SupplyMarketplaceFeedItemInput {
  body?: InputMaybe<Scalars["String"]>;
  feedItemId: Scalars["String"];
  feedItemType?: InputMaybe<Scalars["String"]>;
  iconType: Scalars["String"];
  imageUrl: Scalars["String"];
  primaryAction: SupplyMarketplaceFeedActionInput;
  segmentations?: InputMaybe<SupplyMarketplaceFeedSegmentationsInput>;
  timeToComplete: Scalars["Int"];
  title: Scalars["String"];
}

export type SupplyMarketplaceFeedItemUpdateResponse = {
  __typename?: "SupplyMarketplaceFeedItemUpdateResponse";
  feedItem?: Maybe<SupplyMarketplaceFeedItem>;
};

export interface SupplyMarketplaceFeedSegmentationsInput {
  cityList?: InputMaybe<Array<Scalars["String"]>>;
  countryList?: InputMaybe<Array<Scalars["String"]>>;
  localeList: Array<Scalars["String"]>;
  partnerTypeList: Array<Scalars["String"]>;
  propertyIdList: Array<Scalars["String"]>;
  stateList?: InputMaybe<Array<Scalars["String"]>>;
  userIdList?: InputMaybe<Array<Scalars["String"]>>;
}

export type SupplyMoreInfoFactor = {
  __typename?: "SupplyMoreInfoFactor";
  content: SupplyMoreInfoFactorContent;
  description: Scalars["String"];
  trigger: SupplyMoreInfoFactorTrigger;
};

export type SupplyMoreInfoFactorContent = {
  __typename?: "SupplyMoreInfoFactorContent";
  text: Scalars["String"];
};

export type SupplyMoreInfoFactorTrigger = {
  __typename?: "SupplyMoreInfoFactorTrigger";
  text: EgdsIconText;
};

export type SupplyMultiFactorAuthSelectionView = {
  __typename?: "SupplyMultiFactorAuthSelectionView";
  generateCode: EgdsButton;
  phoneNumberSelection: SupplyPhoneNumberSelectionView;
  verificationTypeView: SupplyPhoneNumberVerifyTypeView;
};

export type SupplyMultiFactorAuthenticationExperience =
  | SupplyMultiFactorLoadErrorView
  | SupplyMultiFactorResultView
  | SupplyMultiFactorSelectionView
  | SupplyMultiFactorVerificationView;

export interface SupplyMultiFactorCodeGenerationInput {
  challengeTicket?: InputMaybe<Scalars["String"]>;
  countryCode: Scalars["String"];
  phoneNumber: Scalars["String"];
  verifyMethod: MfaVerificationMethod;
}

export interface SupplyMultiFactorCodeVerificationInput {
  challengeTicket: Scalars["String"];
  code: Scalars["String"];
  countryCode: Scalars["String"];
  phoneNumber: Scalars["String"];
  verifyMethod: MfaVerificationMethod;
}

export type SupplyMultiFactorLoadErrorView = {
  __typename?: "SupplyMultiFactorLoadErrorView";
  heading: Scalars["String"];
  serviceError: Scalars["String"];
};

export type SupplyMultiFactorResult = EgdsStandardMessagingCard | UiBanner;

export type SupplyMultiFactorResultView = {
  __typename?: "SupplyMultiFactorResultView";
  clickstreamSucceeded?: Maybe<TwoFactorAuthEgClickstreamData>;
  heading: Scalars["String"];
  resultView: SupplyMultiFactorResult;
};

export type SupplyMultiFactorSelectionView = {
  __typename?: "SupplyMultiFactorSelectionView";
  clickstreamPresented?: Maybe<TwoFactorAuthEgClickstreamData>;
  heading: Scalars["String"];
  legal: SupplyLegalView;
  selectionView: SupplyMultiFactorAuthSelectionView;
  serviceError?: Maybe<Scalars["String"]>;
};

export type SupplyMultiFactorVerificationView = {
  __typename?: "SupplyMultiFactorVerificationView";
  clickstreamFailure?: Maybe<TwoFactorAuthEgClickstreamData>;
  heading: Scalars["String"];
  legal: SupplyLegalView;
  serviceError?: Maybe<Scalars["String"]>;
  verificationView: SupplyPhoneNumberVerificationView;
};

export type SupplyNavigation = {
  __typename?: "SupplyNavigation";
  /** @deprecated Temporarily adding this field so the type doesnt get pruned. */
  deprecateOldMenuSearch?: Maybe<SupplyNavigationMenuSearch>;
  header: SupplyNavigationHeader;
  sidebar: SupplyNavigationSidebar;
};

export type SupplyNavigationAction = SupplyNavigationActionOpenMenu | SupplyPublishSignalAction;

export type SupplyNavigationActionItem = {
  __typename?: "SupplyNavigationActionItem";
  link: UiLinkAction;
  text: Scalars["String"];
};

export type SupplyNavigationActionOpenMenu = {
  __typename?: "SupplyNavigationActionOpenMenu";
  menu: SupplyNavigationHeaderMenu;
};

export interface SupplyNavigationContext {
  fallbackUrls?: InputMaybe<SupplyNavigationContextFallbackUrls>;
  showSidebarTrigger: Scalars["Boolean"];
}

export interface SupplyNavigationContextFallbackUrls {
  helpAndSupportUrl?: InputMaybe<Scalars["String"]>;
  signInUrl?: InputMaybe<Scalars["String"]>;
  termsOfUseUrl?: InputMaybe<Scalars["String"]>;
}

export type SupplyNavigationEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyNavigationEgClickstream";
  additionalContext?: Maybe<EgAdditionalContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  mapProductDetail: EgPropertyCount;
  /** @deprecated Removing to be used in another clickstream type */
  notification?: Maybe<EgNotificationAttributes>;
  typeahead?: Maybe<EgTypeAHead>;
};

export type SupplyNavigationHeader = {
  __typename?: "SupplyNavigationHeader";
  accountTrigger: SupplyNavigationHeaderButton;
  logo: SupplyNavigationHeaderLogo;
  /** @deprecated Use AnalyticsPayload instead */
  navigationPresented: SupplyNavigationEgClickstream;
  navigationPresentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  notificationsTrigger?: Maybe<SupplyNavigationHeaderButton>;
  propertyDetailsTrigger?: Maybe<SupplyNavigationHeaderButton>;
  propertyFilterTrigger?: Maybe<SupplyNavigationHeaderButton>;
  searchTrigger?: Maybe<SupplyNavigationHeaderButton>;
  sidebarTrigger?: Maybe<SupplyNavigationHeaderButton>;
};

export type SupplyNavigationHeaderButton = {
  __typename?: "SupplyNavigationHeaderButton";
  accessibility: Scalars["String"];
  action: SupplyNavigationAction;
  buttonIcon: SupplyNavigationHeaderButtonIcon;
  /** @deprecated Use AnalyticsPayload instead */
  headerMenuClosedEvent?: Maybe<SupplyNavigationEgClickstream>;
  headerMenuClosedEventV2?: Maybe<Scalars["AnalyticsPayload"]>;
  navigationSideOpened?: Maybe<Scalars["AnalyticsPayload"]>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyNavigationHeaderButtonIcon = {
  __typename?: "SupplyNavigationHeaderButtonIcon";
  badge?: Maybe<EgdsBadgeNotification>;
  icon: Icon;
  inverse: Scalars["Boolean"];
};

export type SupplyNavigationHeaderLogo = {
  __typename?: "SupplyNavigationHeaderLogo";
  action: UiLinkAction;
  graphic: UiGraphic;
  /** @deprecated Use AnalyticsPayload instead */
  headerLogoSelected: SupplyNavigationEgClickstream;
  headerLogoSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyNavigationHeaderMenu = {
  __typename?: "SupplyNavigationHeaderMenu";
  content: SupplyNavigationMenuContentDetails;
  header?: Maybe<SupplyNavigationMenuContentHeader>;
};

export type SupplyNavigationHeaderMenuType =
  | "NOTIFICATIONS"
  | "PORTFOLIO_PROPERTY_FILTER"
  | "PROPERTY_DETAILS"
  | "PROPERTY_FILTER"
  | "SEARCH";

export type SupplyNavigationHeaderSearchTrigger = UiAction & {
  __typename?: "SupplyNavigationHeaderSearchTrigger";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]>;
};

export type SupplyNavigationIconText = {
  __typename?: "SupplyNavigationIconText";
  iconText: EgdsIconText;
  /** @deprecated Use AnalyticsPayload instead */
  presented: SupplyNavigationEgClickstream;
  presentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyNavigationItem = SupplyNavigationItemLink | SupplyNavigationItemMenu;

export type SupplyNavigationItemLink = {
  __typename?: "SupplyNavigationItemLink";
  active: Scalars["Boolean"];
  activeNavigationItemIds: Array<Scalars["String"]>;
  badge?: Maybe<EgdsStandardBadge>;
  badgeType?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  link: UiLinkAction;
  /** @deprecated Use AnalyticsPayload instead */
  menuSelected: SupplyNavigationEgClickstream;
  menuSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  navSideItemPresented?: Maybe<Scalars["AnalyticsPayload"]>;
  text: Scalars["String"];
};

export type SupplyNavigationItemLinkBadge = {
  __typename?: "SupplyNavigationItemLinkBadge";
  badge: EgdsStandardBadge;
};

export type SupplyNavigationItemMenu = {
  __typename?: "SupplyNavigationItemMenu";
  active: Scalars["Boolean"];
  badge?: Maybe<EgdsStandardBadge>;
  badgeType?: Maybe<Scalars["String"]>;
  children: Array<SupplyNavigationItemLink>;
  icon?: Maybe<Icon>;
  menuClosed?: Maybe<Scalars["AnalyticsPayload"]>;
  menuItemLink?: Maybe<UiLinkAction>;
  menuOpened?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated Use AnalyticsPayload instead */
  menuPresented: SupplyNavigationEgClickstream;
  menuPresentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated Use AnalyticsPayload instead */
  menuSelected: SupplyNavigationEgClickstream;
  /** @deprecated Use menu opened and menu closed instead */
  menuSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  navSideItemPresented?: Maybe<Scalars["AnalyticsPayload"]>;
  text: Scalars["String"];
};

export type SupplyNavigationLazyMenuContent = {
  __typename?: "SupplyNavigationLazyMenuContent";
  menuType: SupplyNavigationHeaderMenuType;
};

export type SupplyNavigationMenuContent = {
  __typename?: "SupplyNavigationMenuContent";
  badge?: Maybe<EgdsStandardBadge>;
  /** @deprecated Use AnalyticsPayload instead */
  menuContentPresented: SupplyNavigationEgClickstream;
  menuContentPresentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  sections: Array<SupplyNavigationMenuContentSection>;
};

export type SupplyNavigationMenuContentDetails = SupplyNavigationLazyMenuContent | SupplyNavigationMenuContent;

export type SupplyNavigationMenuContentHeader = {
  __typename?: "SupplyNavigationMenuContentHeader";
  borderSeparator: Scalars["Boolean"];
  primary: Scalars["String"];
  secondaries: Array<Scalars["String"]>;
};

export type SupplyNavigationMenuContentListItemAction = {
  __typename?: "SupplyNavigationMenuContentListItemAction";
  action: UiLinkAction;
  borderSeparator: Scalars["Boolean"];
  /** @deprecated Use AnalyticsPayload instead */
  menuContentSectionItemSelected: SupplyNavigationEgClickstream;
  menuContentSectionItemSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  primary: SupplyNavigationMenuContentListItemActionPrimary;
  secondary?: Maybe<SupplyNavigationMenuContentListItemActionSecondary>;
};

export type SupplyNavigationMenuContentListItemActionPrimary = EgdsPlainText | EgdsStylizedText;

export type SupplyNavigationMenuContentListItemActionSecondary = EgdsPlainText | EgdsStylizedText | Icon;

export type SupplyNavigationMenuContentSection =
  | SupplyNavigationMenuContentSectionWithListItemActions
  | SupplyNavigationMenuContentSectionWithSubSections
  | SupplyNavigationNotificationMenuContentSection;

export type SupplyNavigationMenuContentSectionWithListItemActions = {
  __typename?: "SupplyNavigationMenuContentSectionWithListItemActions";
  actions: Array<SupplyNavigationMenuContentListItemAction>;
  primary?: Maybe<Scalars["String"]>;
};

export type SupplyNavigationMenuContentSectionWithSubSections = {
  __typename?: "SupplyNavigationMenuContentSectionWithSubSections";
  subSections: Array<SupplyNavigationMenuContentSubSection>;
};

export type SupplyNavigationMenuContentSubSection = {
  __typename?: "SupplyNavigationMenuContentSubSection";
  actions: Array<SupplyNavigationMenuContentListItemAction>;
  primary?: Maybe<Scalars["String"]>;
};

export type SupplyNavigationMenuSearch = {
  __typename?: "SupplyNavigationMenuSearch";
  /** @deprecated This field is no longer used */
  content: SupplyNavigationMenuSearchContent;
  /** @deprecated This field is no longer used */
  trigger: SupplyNavigationHeaderSearchTrigger;
};

export type SupplyNavigationMenuSearchContent =
  | SupplyNavigationMenuSearchEmptyContent
  | SupplyNavigationMenuSearchEmptyResponseContent
  | SupplyNavigationMenuSearchSuccessContent;

export type SupplyNavigationMenuSearchEmptyContent = {
  __typename?: "SupplyNavigationMenuSearchEmptyContent";
  /** @deprecated This field is no longer used */
  helpAction: SupplyNavigationActionItem;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]>;
};

export type SupplyNavigationMenuSearchEmptyResponseContent = {
  __typename?: "SupplyNavigationMenuSearchEmptyResponseContent";
  /** @deprecated This field is no longer used */
  helpAction: SupplyNavigationActionItem;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]>;
  viewAll?: Maybe<SupplyNavigationActionItem>;
};

export type SupplyNavigationMenuSearchResultItems = {
  __typename?: "SupplyNavigationMenuSearchResultItems";
  link: UiLinkAction;
  reservationId: Scalars["String"];
  stayDates: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyNavigationMenuSearchSuccessContent = {
  __typename?: "SupplyNavigationMenuSearchSuccessContent";
  helpAction: SupplyNavigationActionItem;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]>;
  results: Array<SupplyNavigationMenuSearchResultItems>;
  seeMore?: Maybe<SupplyNavigationActionItem>;
};

export interface SupplyNavigationNotificationActivityInput {
  activityType: Scalars["String"];
  channel: Scalars["String"];
  notificationIds: Array<Scalars["String"]>;
}

export type SupplyNavigationNotificationActivityNotificationIds = {
  __typename?: "SupplyNavigationNotificationActivityNotificationIds";
  notificationIds: Array<Scalars["String"]>;
};

export type SupplyNavigationNotificationContent = EgdsIconText | SupplyNavigationIconText | SupplyNavigationMenuContent;

export type SupplyNavigationNotificationMenuContentSection = {
  __typename?: "SupplyNavigationNotificationMenuContentSection";
  expando?: Maybe<EgdsExpandoPeek>;
  expandoAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  notificationItems: Array<SupplyNavigationNotificationMenuListItem>;
};

export type SupplyNavigationNotificationMenuListItem = {
  __typename?: "SupplyNavigationNotificationMenuListItem";
  action?: Maybe<UiLinkAction>;
  badge: EgdsBadgeNotification;
  notificationId: Scalars["String"];
  /** @deprecated Use AnalyticsPayload instead */
  notificationSelected: SupplyNavigationEgClickstream;
  notificationSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  primary: SupplyNavigationMenuContentListItemActionPrimary;
  secondary: SupplyNavigationMenuContentListItemActionSecondary;
};

export type SupplyNavigationReservationAction = {
  __typename?: "SupplyNavigationReservationAction";
  action: UiLinkAction;
  navigationReservationActionSelected: Scalars["AnalyticsPayload"];
  primary: Scalars["String"];
};

export type SupplyNavigationReservationResults = {
  __typename?: "SupplyNavigationReservationResults";
  action?: Maybe<EgdsButton>;
  /** @deprecated Replaced by searchItems */
  items: Array<SupplyNavigationReservationSearchItem>;
  message?: Maybe<EgdsIconText>;
  searchItems: Array<SupplyNavigationReservationSearchItemV2>;
  secondaryAction?: Maybe<SupplyNavigationReservationAction>;
};

export type SupplyNavigationReservationSearchContent = {
  __typename?: "SupplyNavigationReservationSearchContent";
  action: SupplyNavigationReservationAction;
  navigationSearchCleared?: Maybe<Scalars["AnalyticsPayload"]>;
  navigationSearchInputted?: Maybe<Scalars["AnalyticsPayload"]>;
  navigationSearchPresented: Scalars["AnalyticsPayload"];
  navigationSearchSelected?: Maybe<Scalars["AnalyticsPayload"]>;
  results: SupplyNavigationReservationResults;
  searchInput: EgdsTextInputField;
};

export type SupplyNavigationReservationSearchContentResultsArgs = {
  keyword?: InputMaybe<Scalars["String"]>;
};

export type SupplyNavigationReservationSearchItem = {
  __typename?: "SupplyNavigationReservationSearchItem";
  action: UiLinkAction;
  primary: Scalars["String"];
  secondary: Scalars["String"];
  tertiary: Scalars["String"];
};

export type SupplyNavigationReservationSearchItemV2 = {
  __typename?: "SupplyNavigationReservationSearchItemV2";
  action: UiLinkAction;
  navigationReservationSearchItemSelected: Scalars["AnalyticsPayload"];
  primary: EgdsSpannableText;
  secondary: EgdsSpannableText;
  tertiary: EgdsSpannableText;
};

export type SupplyNavigationSidebar = {
  __typename?: "SupplyNavigationSidebar";
  footer: SupplyNavigationSidebarFooter;
  items: Array<SupplyNavigationItem>;
  /** @deprecated Use AnalyticsPayload instead */
  navigationSidePresented: SupplyNavigationEgClickstream;
  navigationSidePresentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyNavigationSidebarFooter = {
  __typename?: "SupplyNavigationSidebarFooter";
  copyright: Scalars["String"];
  legalLinks: Array<SupplyNavigationSidebarFooterLink>;
};

export type SupplyNavigationSidebarFooterLink = {
  __typename?: "SupplyNavigationSidebarFooterLink";
  footerLinkAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  icon?: Maybe<Icon>;
  link: UiLinkAction;
  text: Scalars["String"];
};

export type SupplyNavigationUserImpersonationBanner = {
  __typename?: "SupplyNavigationUserImpersonationBanner";
  icon: Icon;
  link: EgdsStandardLink;
  primary: Scalars["String"];
  secondary: Scalars["String"];
};

export interface SupplyNewListingOfferEnrollmentInput {
  enrolled: Scalars["Boolean"];
}

export type SupplyNightlyPaymentDetailsSection = {
  __typename?: "SupplyNightlyPaymentDetailsSection";
  label: EgdsStylizedText;
  nightlyPaymentLineItems: Array<SupplyPaymentDetailsLineItem>;
};

export type SupplyOnboardingAction = {
  __typename?: "SupplyOnboardingAction";
  buttonType: SupplyOnboardingButtonType;
  currentStep: Scalars["String"];
};

export type SupplyOnboardingActionResource = {
  __typename?: "SupplyOnboardingActionResource";
  relativePath: Scalars["String"];
};

export type SupplyOnboardingAttestation = {
  __typename?: "SupplyOnboardingAttestation";
  attestationCheckbox: EgdsCheckBox;
  heading?: Maybe<Scalars["String"]>;
  subHeading?: Maybe<Scalars["String"]>;
};

export type SupplyOnboardingAvailability = {
  __typename?: "SupplyOnboardingAvailability";
  availabilityCards: Array<SupplyOnboardingAvailabilityCard>;
  dialog?: Maybe<SupplyOnboardingAvailabilityConfirmation>;
  saveAction?: Maybe<SupplyPublishSignalAction>;
  selected?: Maybe<SupplyOnboardingAvailabilityType>;
  validAction?: Maybe<SupplyPublishSignalAction>;
};

export type SupplyOnboardingAvailabilityButton = {
  __typename?: "SupplyOnboardingAvailabilityButton";
  button: EgdsButton;
  selectedAnalytics: SupplyOnboardingAvailabilityClickstreamData;
};

export type SupplyOnboardingAvailabilityCard = {
  __typename?: "SupplyOnboardingAvailabilityCard";
  accessibilityLabel: Scalars["String"];
  availabilityType: SupplyOnboardingAvailabilityType;
  description: Scalars["String"];
  illustration: Illustration;
  selectedAnalytics: SupplyOnboardingAvailabilityClickstreamData;
  title: Scalars["String"];
};

export type SupplyOnboardingAvailabilityClickstreamData = {
  __typename?: "SupplyOnboardingAvailabilityClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingAvailabilityConfirmation = {
  __typename?: "SupplyOnboardingAvailabilityConfirmation";
  accessibilityLabel: Scalars["String"];
  cancelButton: SupplyOnboardingAvailabilityButton;
  confirmButton: SupplyOnboardingAvailabilityButton;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyOnboardingAvailabilityType = "BLOCK" | "OPEN";

export type SupplyOnboardingAvailabilityUpdate = {
  __typename?: "SupplyOnboardingAvailabilityUpdate";
  success: SupplyOnboardingAvailabilityType;
};

export type SupplyOnboardingBackButton = {
  __typename?: "SupplyOnboardingBackButton";
  action?: Maybe<UiLinkAction>;
  analytics: SupplyOnboardingPublishListingClickstreamData;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type SupplyOnboardingBusinessInfoEventAnalyticsData = {
  __typename?: "SupplyOnboardingBusinessInfoEventAnalyticsData";
  additional_context?: Maybe<EgClickstreamPartnerAdditionalContextData>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  form?: Maybe<EgClickstreamPartnerFormData>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingButton = {
  __typename?: "SupplyOnboardingButton";
  action: SupplyOnboardingAction;
  analytics: SupplyOnboardingNavigationClickstreamData;
  button: SupplyOnboardingNavigationButton;
  disabledAction?: Maybe<SupplySubscribeSignalAction>;
  hideAction?: Maybe<SupplySubscribeSignalAction>;
  saveAction?: Maybe<SupplySubscribeSignalAction>;
};

export type SupplyOnboardingButtonType = "BACK" | "DONE" | "LINK" | "NEXT" | "SKIP";

export type SupplyOnboardingFloatingButton = {
  __typename?: "SupplyOnboardingFloatingButton";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  egdsElementId?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type SupplyOnboardingHostTypeResult = {
  __typename?: "SupplyOnboardingHostTypeResult";
  hostType: Scalars["String"];
};

export type SupplyOnboardingIconStylizedText = {
  __typename?: "SupplyOnboardingIconStylizedText";
  icon: Icon;
  stylizedText: EgdsStylizedText;
};

export type SupplyOnboardingNavigationButton =
  | SupplyOnboardingFloatingButton
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type SupplyOnboardingNavigationClickstreamData = {
  __typename?: "SupplyOnboardingNavigationClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingNavigationHeader = {
  __typename?: "SupplyOnboardingNavigationHeader";
  logo: SupplyOnboardingNavigationHeaderLogo;
  saveIndicator?: Maybe<SupplyOnboardingSaveIndicator>;
};

export type SupplyOnboardingNavigationHeaderLogo = {
  __typename?: "SupplyOnboardingNavigationHeaderLogo";
  action: UiLinkAction;
  headerLogoSelected: EgClickstreamData;
  imgSrc: Image;
};

export type SupplyOnboardingPageTitle = {
  __typename?: "SupplyOnboardingPageTitle";
  description?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export interface SupplyOnboardingPartnerAttestationInput {
  attestation: Scalars["Boolean"];
}

export type SupplyOnboardingPartnerAttestationResult = {
  __typename?: "SupplyOnboardingPartnerAttestationResult";
  attestation: Scalars["Boolean"];
};

export type SupplyOnboardingPartnerBillingAddress = {
  __typename?: "SupplyOnboardingPartnerBillingAddress";
  city: Scalars["String"];
  country: Scalars["String"];
  postalCode: Scalars["String"];
  stateProvince: Scalars["String"];
  streetAddress: Scalars["String"];
  unit?: Maybe<Scalars["String"]>;
};

export interface SupplyOnboardingPartnerBillingAddressInput {
  city: Scalars["String"];
  country: Scalars["String"];
  isAddingAddress: Scalars["Boolean"];
  postalCode: Scalars["String"];
  stateProvince: Scalars["String"];
  streetAddress: Scalars["String"];
  unit?: InputMaybe<Scalars["String"]>;
}

export type SupplyOnboardingPartnerBillingAddressSection = {
  __typename?: "SupplyOnboardingPartnerBillingAddressSection";
  address: SupplyPartnerBillingAddress;
  analytics: SupplyPartnerInfoAnalytics;
  country: SupplyPartnerBillingCountry;
  description: Scalars["String"];
  heading: Scalars["String"];
  sameAsPropertyAddress?: Maybe<SupplyPartnerBillingAddressSameAsProperty>;
};

export interface SupplyOnboardingPartnerBusinessNameInput {
  businessName: Scalars["String"];
}

export type SupplyOnboardingPartnerBusinessNameResult = {
  __typename?: "SupplyOnboardingPartnerBusinessNameResult";
  businessName: Scalars["String"];
};

export type SupplyOnboardingPartnerBusinessNameSection = {
  __typename?: "SupplyOnboardingPartnerBusinessNameSection";
  analytics: SupplyPartnerInfoAnalytics;
  businessName: EgdsTextInputField;
  heading: Scalars["String"];
};

export type SupplyOnboardingPartnerError = {
  __typename?: "SupplyOnboardingPartnerError";
  heading: Scalars["String"];
  icon: Icon;
  message: Scalars["String"];
};

export type SupplyOnboardingPartnerHostTypeSection = {
  __typename?: "SupplyOnboardingPartnerHostTypeSection";
  analytics?: Maybe<SupplyPartnerInfoAnalytics>;
  heading: Scalars["String"];
  hostTypes: EgdsBasicRadioGroup;
};

export interface SupplyOnboardingPartnerLegalNameInput {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
}

export type SupplyOnboardingPartnerLegalNameResult = {
  __typename?: "SupplyOnboardingPartnerLegalNameResult";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type SupplyOnboardingPartnerLegalNameSection = {
  __typename?: "SupplyOnboardingPartnerLegalNameSection";
  analytics: SupplyPartnerInfoAnalytics;
  firstName: EgdsTextInputField;
  heading: Scalars["String"];
  lastName: EgdsTextInputField;
  sameAsAccountHolder: SupplyPartnerLegalNameSameAsAccountHolder;
};

export type SupplyOnboardingPartnerName = {
  __typename?: "SupplyOnboardingPartnerName";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type SupplyOnboardingPaymentTermsAndConditionsView = {
  __typename?: "SupplyOnboardingPaymentTermsAndConditionsView";
  acceptanceCheckbox: EgdsBasicCheckBox;
  heading: Scalars["String"];
};

export type SupplyOnboardingPropertyRatesExperience = {
  __typename?: "SupplyOnboardingPropertyRatesExperience";
  currencyView: SupplyPropertyRatesBankingCountryCurrencyView;
  errorView?: Maybe<SupplyPropertyRatesErrorView>;
  heading: Scalars["String"];
  offerView: SupplyPropertyRatesOfferView;
  pageDescription?: Maybe<Scalars["String"]>;
  rateView: SupplyPropertyRatesNightlyBaseRateView;
};

export type SupplyOnboardingPublishListing = {
  __typename?: "SupplyOnboardingPublishListing";
  accessibilityLabel: Scalars["String"];
  contentList?: Maybe<Array<EgdsSpannableText>>;
  description: Scalars["String"];
  heading: Scalars["String"];
  illustration?: Maybe<Illustration>;
  publishButton?: Maybe<SupplyOnboardingPublishListingButton>;
  saveErrorBanner?: Maybe<UiBanner>;
  supplyOnboardingBackButton?: Maybe<SupplyOnboardingBackButton>;
};

export type SupplyOnboardingPublishListingAction = {
  __typename?: "SupplyOnboardingPublishListingAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: SupplyOnboardingPublishListingClickstreamData;
  event?: Maybe<SupplyOnboardingPublishListingSubmissionEvent>;
  signal?: Maybe<SupplyPublishSignalAction>;
};

export type SupplyOnboardingPublishListingButton = {
  __typename?: "SupplyOnboardingPublishListingButton";
  action: SupplyOnboardingPublishListingButtonAction;
  primary: Scalars["String"];
};

export type SupplyOnboardingPublishListingButtonAction = SupplyOnboardingPublishListingAction | UiLinkAction;

export type SupplyOnboardingPublishListingClickstreamData = IEgClickstreamData & {
  __typename?: "SupplyOnboardingPublishListingClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
  product_list?: Maybe<Array<EgClickstreamProductListData>>;
  virtual_market_coach?: Maybe<EgClickstreamVirtualMarketCoachData>;
};

export type SupplyOnboardingPublishListingResult = {
  __typename?: "SupplyOnboardingPublishListingResult";
  success: Scalars["Boolean"];
};

export type SupplyOnboardingPublishListingSubmissionEvent = {
  __typename?: "SupplyOnboardingPublishListingSubmissionEvent";
  bidTarget: Scalars["String"];
  bidTargetUuid: Scalars["String"];
  eventName: Scalars["String"];
  newSupplier: Scalars["Boolean"];
};

export type SupplyOnboardingRatesEgClickstreamActivityProduct = {
  __typename?: "SupplyOnboardingRatesEGClickstreamActivityProduct";
  pricing?: Maybe<SupplyOnboardingRatesEgClickstreamActivityProductPricing>;
};

export type SupplyOnboardingRatesEgClickstreamActivityProductPricing = {
  __typename?: "SupplyOnboardingRatesEGClickstreamActivityProductPricing";
  banking_country?: Maybe<Scalars["String"]>;
  payment_currency?: Maybe<Scalars["String"]>;
  price_base_amount?: Maybe<Scalars["Float"]>;
};

export type SupplyOnboardingRatesEgClickstreamProductList = {
  __typename?: "SupplyOnboardingRatesEGClickstreamProductList";
  activity_product?: Maybe<SupplyOnboardingRatesEgClickstreamActivityProduct>;
};

export type SupplyOnboardingRatesEgClickstreamAdditionalContext = {
  __typename?: "SupplyOnboardingRatesEgClickstreamAdditionalContext";
  user_interface?: Maybe<SupplyOnboardingRatesEgClickstreamUserInterface>;
};

export type SupplyOnboardingRatesEgClickstreamUserInterface = {
  __typename?: "SupplyOnboardingRatesEgClickstreamUserInterface";
  component_badge_name?: Maybe<Scalars["String"]>;
  component_element_text?: Maybe<Scalars["String"]>;
};

export type SupplyOnboardingReadiness = {
  __typename?: "SupplyOnboardingReadiness";
  hideAction?: Maybe<SupplySubscribeSignalAction>;
  stepReviewList: StepReviewList;
};

export type SupplyOnboardingReviewError = {
  __typename?: "SupplyOnboardingReviewError";
  errorDetailList?: Maybe<Array<EgdsText>>;
  errorText: EgdsText;
  icon: Icon;
  text?: Maybe<EgdsText>;
};

export type SupplyOnboardingReviewExperience = {
  __typename?: "SupplyOnboardingReviewExperience";
  clickstreamPageView?: Maybe<EgClickstreamData>;
  informationPanelV1?: Maybe<SupplyInformationPanel>;
  sections: Array<SupplyOnboardingReviewSection>;
  signal?: Maybe<SupplyPublishSignalAction>;
};

export type SupplyOnboardingReviewPhotoList = {
  __typename?: "SupplyOnboardingReviewPhotoList";
  imageList: Array<Image>;
  scrimEdit?: Maybe<SupplyReviewEditLink>;
  scrimIcon: Icon;
};

export type SupplyOnboardingReviewSection = SupplyPlaybackSection | SupplyPlaybackSectionList;

export type SupplyOnboardingSaveIndicator = {
  __typename?: "SupplyOnboardingSaveIndicator";
  popoverText?: Maybe<Scalars["String"]>;
  savedText: Scalars["String"];
  savingText: Scalars["String"];
};

export type SupplyOnboardingTipCard = {
  __typename?: "SupplyOnboardingTipCard";
  icon: Icon;
  sections: Array<SupplyTipCardSection>;
};

export type SupplyOnboardingTipCardId = "AMENITIES" | "DESCRIPTION" | "HEADLINE" | "PHOTOS" | "REGULATORY";

export type SupplyOnboardingTipContent = EgdsParagraph | EgdsSpannableText;

export type SupplyOnboardingWelcome = {
  __typename?: "SupplyOnboardingWelcome";
  content: SupplyOnboardingWelcomeSectionContent;
  informationPanel: SupplyOnboardingWelcomeInformationPanel;
  primaryButton: SupplyOnboardingWelcomeButton;
  subTitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyOnboardingWelcomeButton = {
  __typename?: "SupplyOnboardingWelcomeButton";
  action: SupplyOnboardingWelcomeButtonAction;
  button: EgdsButton;
};

export type SupplyOnboardingWelcomeButtonAction = {
  __typename?: "SupplyOnboardingWelcomeButtonAction";
  analytics: SupplyOnboardingWelcomeClickstreamData;
};

export type SupplyOnboardingWelcomeClickstreamData = {
  __typename?: "SupplyOnboardingWelcomeClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingWelcomeInformationPanel = {
  __typename?: "SupplyOnboardingWelcomeInformationPanel";
  content: Scalars["String"];
  iconV1?: Maybe<Icon>;
};

export type SupplyOnboardingWelcomeNavigationUpdate = {
  __typename?: "SupplyOnboardingWelcomeNavigationUpdate";
  relativePath: Scalars["String"];
};

export type SupplyOnboardingWelcomeSectionContent = {
  __typename?: "SupplyOnboardingWelcomeSectionContent";
  content: EgdsBulletedList;
  title: Scalars["String"];
};

export type SupplyOnboardingWorkFlowNavigation = {
  __typename?: "SupplyOnboardingWorkFlowNavigation";
  backButton?: Maybe<SupplyOnboardingButton>;
  currentStep: Scalars["String"];
  groups: Array<SupplyOnboardingWorkFlowNavigationGroup>;
  nextButton?: Maybe<SupplyOnboardingButton>;
  skipButton?: Maybe<SupplyOnboardingButton>;
  skippable: Scalars["Boolean"];
};

export type SupplyOnboardingWorkFlowNavigationGroup = {
  __typename?: "SupplyOnboardingWorkFlowNavigationGroup";
  identifier: Scalars["String"];
  label: EgdsStylizedText;
  smallLabel?: Maybe<EgdsStylizedText>;
  steps: Array<EgdsProgressBar>;
};

export type SupplyOnboardingWorkFlowNavigationUpdate = {
  __typename?: "SupplyOnboardingWorkFlowNavigationUpdate";
  resource?: Maybe<SupplyOnboardingActionResource>;
};

export type SupplyOpportunityHubLicensePlate = {
  __typename?: "SupplyOpportunityHubLicensePlate";
  description: Scalars["String"];
  /** @deprecated Feedback link is optional. Use feedbackLinkV2 instead. */
  feedbackLink: EgdsStandardLink;
  feedbackLinkV2?: Maybe<EgdsStandardLink>;
  title: Scalars["String"];
};

export type SupplyPageLevelBanner = {
  __typename?: "SupplyPageLevelBanner";
  banners: Array<SupplyPageNotificationBanner>;
  cards: Array<SupplyPageLevelNotificationBannerCard>;
  presented?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyPageLevelBannerCardLink = {
  __typename?: "SupplyPageLevelBannerCardLink";
  actioned: Scalars["AnalyticsPayload"];
  link: UiLinkAction;
  text: Scalars["String"];
};

export type SupplyPageLevelNotificationBannerCard = {
  __typename?: "SupplyPageLevelNotificationBannerCard";
  closed?: Maybe<Scalars["AnalyticsPayload"]>;
  content: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  links: Array<SupplyPageLevelBannerCardLink>;
  presented?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyPageNotificationBanner = {
  __typename?: "SupplyPageNotificationBanner";
  closed?: Maybe<Scalars["AnalyticsPayload"]>;
  content: Scalars["String"];
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  links: Array<SupplyPageLevelBannerCardLink>;
  presented?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyPartnerAddressProvince = EgdsBasicSelect | EgdsTextInputField;

export type SupplyPartnerBillingAddress = {
  __typename?: "SupplyPartnerBillingAddress";
  city: EgdsTextInputField;
  input: Array<SupplyPartnerBillingAddressInput>;
  postalCode: EgdsTextInputField;
  state: SupplyPartnerAddressProvince;
  streetAddress: EgdsTextInputField;
  unit: EgdsTextInputField;
};

export type SupplyPartnerBillingAddressInput = EgdsBasicSelect | EgdsTextInputField;

export type SupplyPartnerBillingAddressSameAsProperty = {
  __typename?: "SupplyPartnerBillingAddressSameAsProperty";
  analytics: SupplyOnboardingBusinessInfoEventAnalyticsData;
  flagSelector?: Maybe<EgdsCheckBox>;
};

export type SupplyPartnerBillingCountry = {
  __typename?: "SupplyPartnerBillingCountry";
  analytics: SupplyOnboardingBusinessInfoEventAnalyticsData;
  countrySelector: EgdsBasicSelect;
};

export type SupplyPartnerCard = {
  __typename?: "SupplyPartnerCard";
  actions: Array<SupplyPartnerTrackedAction>;
  id: Scalars["String"];
  onSelected?: Maybe<SupplyPartnerTrackedAction>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type SupplyPartnerCardDetail = {
  __typename?: "SupplyPartnerCardDetail";
  actions: Array<SupplyPartnerTrackedAction>;
  clipboardCopyMessage?: Maybe<Scalars["String"]>;
  listTitle?: Maybe<Scalars["String"]>;
  paginatedListItems?: Maybe<SupplyPartnerPaginatedListItems>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type SupplyPartnerCardDetailPaginatedListItemsArgs = {
  context: ContextInput;
  id: Scalars["String"];
  nextPageToken?: InputMaybe<Scalars["String"]>;
};

export type SupplyPartnerInfoAnalytics = {
  __typename?: "SupplyPartnerInfoAnalytics";
  failed: SupplyOnboardingBusinessInfoEventAnalyticsData;
  selected: SupplyOnboardingBusinessInfoEventAnalyticsData;
  submitted: SupplyOnboardingBusinessInfoEventAnalyticsData;
  succeeded: SupplyOnboardingBusinessInfoEventAnalyticsData;
};

export type SupplyPartnerInformation = {
  __typename?: "SupplyPartnerInformation";
  attestation?: Maybe<SupplyOnboardingAttestation>;
  billingAddress: SupplyOnboardingPartnerBillingAddressSection;
  businessName?: Maybe<SupplyOnboardingPartnerBusinessNameSection>;
  error?: Maybe<SupplyOnboardingPartnerError>;
  heading: Scalars["String"];
  hostType: SupplyOnboardingPartnerHostTypeSection;
  legalName: SupplyOnboardingPartnerLegalNameSection;
  partnerName: SupplyOnboardingPartnerName;
  propertyAddressForBilling: SupplyOnboardingPartnerBillingAddress;
};

export type SupplyPartnerLegalNameSameAsAccountHolder = {
  __typename?: "SupplyPartnerLegalNameSameAsAccountHolder";
  analytics: SupplyOnboardingBusinessInfoEventAnalyticsData;
  flagSelector: EgdsCheckBox;
};

export type SupplyPartnerLifeCycleStage =
  | "LIFE_CYCLE_STAGE_ALL_GROWN_UP"
  | "LIFE_CYCLE_STAGE_BABY"
  | "LIFE_CYCLE_STAGE_KID"
  | "LIFE_CYCLE_STAGE_TEENAGER"
  | "LIFE_CYCLE_STAGE_YOUNG_ADULT"
  | "UNRECOGNIZED";

export type SupplyPartnerPaginatedListItems = {
  __typename?: "SupplyPartnerPaginatedListItems";
  items: Array<SupplyPartnerTrackedAction>;
  nextPageToken?: Maybe<Scalars["String"]>;
};

export interface SupplyPartnerPayoutDeferredAccountInput {
  bankingCountry: Scalars["String"];
  currency: Scalars["String"];
  currencyV1?: InputMaybe<Scalars["String"]>;
}

export type SupplyPartnerSelectResponse = {
  __typename?: "SupplyPartnerSelectResponse";
  cardDetail?: Maybe<SupplyPartnerCardDetail>;
  header: Scalars["String"];
  headerActions: Array<SupplyPartnerTrackedAction>;
  summary?: Maybe<SupplyPartnerSummary>;
};

export type SupplyPartnerSelectResponseCardDetailArgs = {
  context: ContextInput;
  id: Scalars["String"];
};

export type SupplyPartnerSelectResponseSummaryArgs = {
  context: ContextInput;
  nextPageToken?: InputMaybe<Scalars["String"]>;
};

export type SupplyPartnerSignUpButton = {
  __typename?: "SupplyPartnerSignUpButton";
  eventSubmitted?: Maybe<Scalars["AnalyticsPayload"]>;
  label: Scalars["String"];
};

export type SupplyPartnerSignUpEstimateHeading =
  | SupplyPartnerSignUpEstimateHeadingAmount
  | SupplyPartnerSignUpEstimateHeadingSubtitle
  | SupplyPartnerSignUpEstimateHeadingTitle;

export type SupplyPartnerSignUpEstimateHeadingAmount = {
  __typename?: "SupplyPartnerSignUpEstimateHeadingAmount";
  icon: Scalars["String"];
  text: Scalars["String"];
};

export type SupplyPartnerSignUpEstimateHeadingSubtitle = {
  __typename?: "SupplyPartnerSignUpEstimateHeadingSubtitle";
  text: Scalars["String"];
};

export type SupplyPartnerSignUpEstimateHeadingTitle = {
  __typename?: "SupplyPartnerSignUpEstimateHeadingTitle";
  text: Scalars["String"];
};

export type SupplyPartnerSignUpEstimateResults = {
  __typename?: "SupplyPartnerSignUpEstimateResults";
  callToActionDescription: Scalars["String"];
  callToActionTitle: Scalars["String"];
  description: Scalars["String"];
  estimatePageViewed: Scalars["AnalyticsPayload"];
  heading: Array<SupplyPartnerSignUpEstimateHeading>;
  nextButton: SupplyPartnerSignUpButton;
};

export interface SupplyPartnerSignUpEstimateResultsInput {
  bathroomNum: Scalars["Float"];
  bedroomNum: Scalars["Int"];
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
}

export type SupplyPartnerSummary = {
  __typename?: "SupplyPartnerSummary";
  banner?: Maybe<UiBanner>;
  bottomAction?: Maybe<SupplyPartnerTrackedAction>;
  cards: Array<SupplyPartnerCard>;
  cardsNextPageToken?: Maybe<Scalars["String"]>;
};

export type SupplyPartnerTrackedAction = {
  __typename?: "SupplyPartnerTrackedAction";
  button?: Maybe<UiPrimaryButton>;
  eventName?: Maybe<Scalars["String"]>;
  eventVersion?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  iconLinkAction?: Maybe<Uri>;
  link?: Maybe<UiLinkAction>;
  payload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyPaymentDetails = {
  __typename?: "SupplyPaymentDetails";
  detailedPaymentLineItems: SupplyPaymentDisplayDetailed;
  summaryPaymentLineItems: Array<SupplyPaymentDetailsLineItem>;
};

export type SupplyPaymentDetailsAmountLineItem = {
  __typename?: "SupplyPaymentDetailsAmountLineItem";
  amount: EgdsStylizedText;
  icon?: Maybe<Icon>;
};

export type SupplyPaymentDetailsDisclaimerLineItem = {
  __typename?: "SupplyPaymentDetailsDisclaimerLineItem";
  lineItemText: EgdsStylizedText;
  link?: Maybe<EgdsStandardLink>;
};

export type SupplyPaymentDetailsExpandoComponentLineItems = {
  __typename?: "SupplyPaymentDetailsExpandoComponentLineItems";
  expando: SupplyPaymentDetailsExpandoLink;
  nightlyPaymentList: Array<SupplyNightlyPaymentDetailsSection>;
  nightlyPaymentTitle?: Maybe<SupplyPaymentDetailsTitleLineItem>;
};

export type SupplyPaymentDetailsExpandoLineItem = ISupplyPaymentLineItem &
  ISupplyPaymentMoreInfo & {
    __typename?: "SupplyPaymentDetailsExpandoLineItem";
    amount: SupplyPaymentDetailsAmountLineItem;
    expando: SupplyPaymentDetailsExpandoLink;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    subLineItems: Array<SupplyPaymentDetailsSubLineItem>;
    tooltip?: Maybe<SupplyPaymentTooltip>;
  };

export type SupplyPaymentDetailsExpandoLink = EgdsExpando & {
  __typename?: "SupplyPaymentDetailsExpandoLink";
  alignment?: Maybe<SupplyPaymentDetailsExpandoPositioning>;
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedLabel: Scalars["String"];
};

export type SupplyPaymentDetailsExpandoPositioning = "LEADING" | "TRAILING";

export type SupplyPaymentDetailsLineItem =
  | SupplyPaymentDetailsDisclaimerLineItem
  | SupplyPaymentDetailsExpandoLineItem
  | SupplyPaymentDetailsSheetToggleLink
  | SupplyPaymentDetailsSubLineItem
  | SupplyPaymentDetailsTitleLineItem
  | SupplyPaymentDetailsTotalLineItem;

export type SupplyPaymentDetailsSheetComponentLineItems = {
  __typename?: "SupplyPaymentDetailsSheetComponentLineItems";
  childLineItems: Array<SupplyPaymentDetailsLineItem>;
  sheet: SupplyPaymentDisplaySidePanelProps;
};

export type SupplyPaymentDetailsSheetToggleLink = {
  __typename?: "SupplyPaymentDetailsSheetToggleLink";
  lineItemText: EgdsStylizedText;
  link: EgdsStandardLink;
};

export type SupplyPaymentDetailsSubLineItem = ISupplyPaymentLineItem &
  ISupplyPaymentMoreInfo & {
    __typename?: "SupplyPaymentDetailsSubLineItem";
    amount: SupplyPaymentDetailsAmountLineItem;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyPaymentTooltip>;
  };

export type SupplyPaymentDetailsTitleLineItem = ISupplyPaymentMoreInfo & {
  __typename?: "SupplyPaymentDetailsTitleLineItem";
  badge?: Maybe<EgdsStandardBadge>;
  footerNoteText?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  tooltip?: Maybe<SupplyPaymentTooltip>;
};

export type SupplyPaymentDetailsTotalLineItem = ISupplyPaymentLineItem &
  ISupplyPaymentMoreInfo & {
    __typename?: "SupplyPaymentDetailsTotalLineItem";
    amount: SupplyPaymentDetailsAmountLineItem;
    badge?: Maybe<EgdsStandardBadge>;
    borderSeparator: Scalars["Boolean"];
    currencyCode?: Maybe<EgdsStylizedText>;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyPaymentTooltip>;
  };

export type SupplyPaymentDisplayDetailed =
  | SupplyPaymentDetailsExpandoComponentLineItems
  | SupplyPaymentDetailsSheetComponentLineItems;

export type SupplyPaymentDisplaySidePanelProps = {
  __typename?: "SupplyPaymentDisplaySidePanelProps";
  navType?: Maybe<SupplyPaymentDisplayToolbarIcon>;
  toolbarTitle?: Maybe<Scalars["String"]>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyPaymentDisplayToolbarIcon = "BACK" | "CLOSE";

export type SupplyPaymentTooltip = {
  __typename?: "SupplyPaymentTooltip";
  contents?: Maybe<Array<SupplyPaymentTooltipContent>>;
  icon: Icon;
};

export type SupplyPaymentTooltipContent = {
  __typename?: "SupplyPaymentTooltipContent";
  tooltipHeader?: Maybe<EgdsStylizedText>;
  tooltipLink?: Maybe<EgdsStandardLink>;
  tooltipText: EgdsStylizedText;
};

export type SupplyPerformanceCard = SupplyPerformanceCurrencyCard | SupplyPerformanceSummaryAggregateCard;

export type SupplyPerformanceContent = SupplyPerformanceErrorContent | SupplyPerformanceSummaryContent;

export type SupplyPerformanceCurrencyCard = {
  __typename?: "SupplyPerformanceCurrencyCard";
  action?: Maybe<UiLinkAction>;
  amount?: Maybe<Scalars["Float"]>;
  currency?: Maybe<Scalars["String"]>;
  formattedContent?: Maybe<Scalars["String"]>;
  metricsPresented: SupplyPerformanceSummaryEgClickstream;
  metricsSelected: SupplyPerformanceSummaryEgClickstream;
  primary: Scalars["String"];
  secondary: EgdsIconText;
};

export type SupplyPerformanceErrorContent = {
  __typename?: "SupplyPerformanceErrorContent";
  button?: Maybe<EgdsButton>;
  icon?: Maybe<Icon>;
  performanceRefreshSelected: SupplyPerformanceSummaryEgClickstream;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPerformanceSummary = {
  __typename?: "SupplyPerformanceSummary";
  content: SupplyPerformanceContent;
  modulePresented: SupplyPerformanceSummaryEgClickstream;
  title: Scalars["String"];
};

export type SupplyPerformanceSummaryAggregateCard = {
  __typename?: "SupplyPerformanceSummaryAggregateCard";
  action?: Maybe<UiLinkAction>;
  content: Array<SupplyPerformanceSummaryCategoryContent>;
  metricsPresented: SupplyPerformanceSummaryEgClickstream;
  metricsSelected: SupplyPerformanceSummaryEgClickstream;
  title: Scalars["String"];
};

export type SupplyPerformanceSummaryCategoryContent = {
  __typename?: "SupplyPerformanceSummaryCategoryContent";
  content: Scalars["String"];
  metricsPresented: SupplyPerformanceSummaryEgClickstream;
  /** @deprecated Renaming this field, please use metricsPresented instead. */
  metricsSelected: SupplyPerformanceSummaryEgClickstream;
  title: Scalars["String"];
};

export type SupplyPerformanceSummaryContent = {
  __typename?: "SupplyPerformanceSummaryContent";
  summaryCards: Array<SupplyPerformanceCard>;
};

export type SupplyPerformanceSummaryEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyPerformanceSummaryEgClickstream";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
};

export type SupplyPhoneNumberSelectionView = {
  __typename?: "SupplyPhoneNumberSelectionView";
  addNewPhoneView?: Maybe<SupplyCustomPhoneNumberField>;
  heading: Scalars["String"];
  options: EgdsBasicRadioGroup;
};

export type SupplyPhoneNumberVerificationView = {
  __typename?: "SupplyPhoneNumberVerificationView";
  challengeTicket: Scalars["String"];
  changeVerifyMethod: EgdsButton;
  codeInput: EgdsTextInputField;
  generateNewCode: EgdsButton;
  heading: Scalars["String"];
  invalidCodeMessage?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  newCodeMessage?: Maybe<EgdsToast>;
  verifyCode: EgdsButton;
};

export type SupplyPhoneNumberVerifyTypeView = {
  __typename?: "SupplyPhoneNumberVerifyTypeView";
  heading: Scalars["String"];
  options: EgdsBasicRadioGroup;
};

export type SupplyPhotoCoachingActionPanel = SupplyCoachingActionPanel & {
  __typename?: "SupplyPhotoCoachingActionPanel";
  confirmedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  dialog: SupplyContentLeaveWithoutSavingDialog;
  saveButton: SupplyContentButton;
  skipButton: SupplyContentButton;
  skippedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  stepText: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyPhotoUploaderActionPhotoDelete = {
  __typename?: "SupplyPhotoUploaderActionPhotoDelete";
  confirmSheet: SupplyPhotoUploaderPhotoDeleteSheet;
  deleteIconButton: SupplyPhotosAction;
  failedErrorSignal: SupplyPhotosSignal;
};

export type SupplyPhotoUploaderActionPhotoUpload = {
  __typename?: "SupplyPhotoUploaderActionPhotoUpload";
  awsConfig: SupplyPhotoUploaderAwsConfiguration;
  bulkSizeErrorSignal: SupplyPhotosSignal;
  serverErrorSignal: SupplyPhotosSignal;
  statusToast: SupplyPhotoUploaderUploadingStatusToast;
  validations: Array<SupplyPhotosValidation>;
};

export type SupplyPhotoUploaderActions = {
  __typename?: "SupplyPhotoUploaderActions";
  delete: SupplyPhotoUploaderActionPhotoDelete;
  upload: SupplyPhotoUploaderActionPhotoUpload;
};

export type SupplyPhotoUploaderAwsConfiguration = {
  __typename?: "SupplyPhotoUploaderAwsConfiguration";
  identityId: Scalars["String"];
  poolId: Scalars["String"];
  provider: Scalars["String"];
  providerToken: Scalars["String"];
  region: Scalars["String"];
  s3Bucket: Scalars["String"];
  s3Region: Scalars["String"];
  s3UrlDomainName: Scalars["String"];
};

export type SupplyPhotoUploaderBulkFileSizeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderBulkFileSizeValidation";
  errorMessage: SupplyPhotosLocalizedTextTemplate;
  maxBulkFileSize: Scalars["Int"];
};

export type SupplyPhotoUploaderDropZone = {
  __typename?: "SupplyPhotoUploaderDropZone";
  addPhotoButton: SupplyPhotosAction;
  heading?: Maybe<Scalars["String"]>;
  requirementTips?: Maybe<SupplyPhotosPhotoRequirementTips>;
  requirementTipsSheet?: Maybe<SupplyPhotosPhotoRequirementTipsSheet>;
};

export type SupplyPhotoUploaderErrorExpandoCard = {
  __typename?: "SupplyPhotoUploaderErrorExpandoCard";
  collapsedLabel?: Maybe<SupplyPhotosLocalizedTextTemplate>;
  description: Scalars["String"];
  dismissButton: SupplyPhotosAction;
  expanded: Scalars["Boolean"];
  expandedLabel: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotoUploaderExperience = {
  __typename?: "SupplyPhotoUploaderExperience";
  actions: SupplyPhotoUploaderActions;
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  dropZone?: Maybe<SupplyPhotoUploaderDropZone>;
  errorsCard: SupplyPhotoUploaderErrorExpandoCard;
  header: SupplyPhotosHeader;
  minimumRequirement?: Maybe<SupplyPhotoUploaderPhotoMinimumRequirement>;
  photoGroups: Array<SupplyPhotoUploaderPhotoGroup>;
};

export type SupplyPhotoUploaderFileSizeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderFileSizeValidation";
  errorMessage: SupplyPhotosLocalizedTextTemplate;
  maxFileSize: Scalars["Int"];
};

export type SupplyPhotoUploaderFileTypeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderFileTypeValidation";
  acceptableTypes: Array<Scalars["String"]>;
  errorMessage: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotoUploaderGrid = {
  __typename?: "SupplyPhotoUploaderGrid";
  cells: Array<SupplyPhotoUploaderGridCell>;
  columns: Scalars["Int"];
};

export type SupplyPhotoUploaderGridCell = SupplyPhotoUploaderPhotoCell;

export type SupplyPhotoUploaderGridCellActions = {
  __typename?: "SupplyPhotoUploaderGridCellActions";
  delete?: Maybe<SupplyPhotosAction>;
};

export type SupplyPhotoUploaderPhotoCell = {
  __typename?: "SupplyPhotoUploaderPhotoCell";
  actions: SupplyPhotoUploaderGridCellActions;
  id: Scalars["String"];
  image: Image;
  label?: Maybe<Scalars["String"]>;
  rank?: Maybe<Scalars["Int"]>;
  status: SupplyPhotosPhotoStatus;
};

export type SupplyPhotoUploaderPhotoDeleteSheet = {
  __typename?: "SupplyPhotoUploaderPhotoDeleteSheet";
  cancelButton: SupplyPhotosAction;
  deleteButton: SupplyPhotosAction;
  heading: Scalars["String"];
};

export type SupplyPhotoUploaderPhotoGroup = {
  __typename?: "SupplyPhotoUploaderPhotoGroup";
  id: Scalars["String"];
  name: Scalars["String"];
  photoGrid: SupplyPhotoUploaderGrid;
};

export type SupplyPhotoUploaderPhotoMinimumRequirement = {
  __typename?: "SupplyPhotoUploaderPhotoMinimumRequirement";
  closeToGoLiveTip?: Maybe<SupplyPhotosLocalizedTextTemplate>;
  minimumPhotos: Scalars["Int"];
  readyToGoLiveTip?: Maybe<SupplyPhotosLocalizedTextTemplate>;
};

export type SupplyPhotoUploaderPhotoSizeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderPhotoSizeValidation";
  errorMessage: SupplyPhotosLocalizedTextTemplate;
  minimumHeight: Scalars["Int"];
  minimumWidth: Scalars["Int"];
};

export type SupplyPhotoUploaderUploadingStatusToast = {
  __typename?: "SupplyPhotoUploaderUploadingStatusToast";
  fail: SupplyPhotosLocalizedTextTemplate;
  processing: SupplyPhotosLocalizedTextTemplate;
  succeed: SupplyPhotosLocalizedTextTemplate;
  succeedWithError: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotosAction = {
  __typename?: "SupplyPhotosAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  disabled: Scalars["Boolean"];
  href?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  type: SupplyPhotosActionType;
};

export type SupplyPhotosActionType =
  | "LINK"
  | "LINK_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON"
  | "TOOL_BAR_BUTTON";

export type SupplyPhotosAddExperience = {
  __typename?: "SupplyPhotosAddExperience";
  addedPhotos: Array<SupplyPhotoUploaderPhotoCell>;
  failedPhotos: Array<SupplyPhotosFailedToMutatePhoto>;
};

export interface SupplyPhotosAddPhotoInput {
  fileName: Scalars["String"];
  fileUrl: Scalars["String"];
  rank?: InputMaybe<Scalars["Int"]>;
  roomIds: Array<Scalars["String"]>;
  spaceIds: Array<Scalars["String"]>;
}

export type SupplyPhotosBannerError = {
  __typename?: "SupplyPhotosBannerError";
  canDismiss?: Maybe<Scalars["Boolean"]>;
  canRetry?: Maybe<Scalars["Boolean"]>;
  heading: Scalars["String"];
  message: Scalars["String"];
};

export type SupplyPhotosDeleteExperience = {
  __typename?: "SupplyPhotosDeleteExperience";
  deletedPhotoIds: Array<Scalars["String"]>;
  failedPhotos: Array<SupplyPhotosFailedToMutatePhoto>;
};

export type SupplyPhotosErrorBanner = {
  __typename?: "SupplyPhotosErrorBanner";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  defaultError: SupplyPhotosBannerError;
  dismissLinkButton: SupplyPhotosAction;
  retryFailedError: SupplyPhotosBannerError;
  retryLinkButton: SupplyPhotosAction;
};

export type SupplyPhotosExperience = {
  __typename?: "SupplyPhotosExperience";
  errorBanner: SupplyPhotosErrorBanner;
};

export interface SupplyPhotosExperienceContext {
  category?: InputMaybe<Scalars["String"]>;
  view: Scalars["String"];
}

export type SupplyPhotosFailedToMutatePhoto = {
  __typename?: "SupplyPhotosFailedToMutatePhoto";
  id: Scalars["String"];
  image?: Maybe<Image>;
  reason?: Maybe<Scalars["String"]>;
};

export type SupplyPhotosHeader = {
  __typename?: "SupplyPhotosHeader";
  descriptions?: Maybe<Array<Scalars["String"]>>;
  heading: Scalars["String"];
};

export type SupplyPhotosLocalizedTextTemplate = {
  __typename?: "SupplyPhotosLocalizedTextTemplate";
  template: Scalars["String"];
};

export type SupplyPhotosPhotoRequirementTip = EgdsPlainText;

export type SupplyPhotosPhotoRequirementTips = {
  __typename?: "SupplyPhotosPhotoRequirementTips";
  content: Array<SupplyPhotosPhotoRequirementTip>;
  heading?: Maybe<Scalars["String"]>;
};

export type SupplyPhotosPhotoRequirementTipsSheet = {
  __typename?: "SupplyPhotosPhotoRequirementTipsSheet";
  linkButton: SupplyPhotosAction;
  requirementTips: SupplyPhotosPhotoRequirementTips;
};

export type SupplyPhotosPhotoStatus = "ACTIVE" | "PROCESSING";

export type SupplyPhotosSignal = {
  __typename?: "SupplyPhotosSignal";
  payload?: Maybe<SupplyPhotosSignalPayload>;
  type: Scalars["String"];
};

export type SupplyPhotosSignalPayload = SupplyPhotosBannerError;

export interface SupplyPhotosValidation {
  errorMessage: SupplyPhotosLocalizedTextTemplate;
}

export type SupplyPlaybackSection = {
  __typename?: "SupplyPlaybackSection";
  clickstreamInformation?: Maybe<EgClickstreamData>;
  content: SupplyReviewSectionListItemContent;
  contentListColumns?: Maybe<Scalars["Int"]>;
  editLink?: Maybe<SupplyReviewEditLink>;
  icon: Icon;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyPlaybackSectionList = {
  __typename?: "SupplyPlaybackSectionList";
  content: Array<SupplyReviewSectionListItem>;
  editLink?: Maybe<SupplyReviewEditLink>;
  icon: Icon;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyPostponeMultipleSelection = {
  __typename?: "SupplyPostponeMultipleSelection";
  options: Array<SupplyPostponeSelectionOption>;
};

export type SupplyPostponeSelectionOption = {
  __typename?: "SupplyPostponeSelectionOption";
  id: Scalars["String"];
  label: Scalars["String"];
  value: Scalars["String"];
};

export type SupplyPostponeSingleSelection = {
  __typename?: "SupplyPostponeSingleSelection";
  options: Array<SupplyPostponeSelectionOption>;
  selected?: Maybe<Scalars["String"]>;
};

export type SupplyPostponeSubmitAnalytics = {
  __typename?: "SupplyPostponeSubmitAnalytics";
  postponeFailed?: Maybe<Scalars["AnalyticsPayload"]>;
  postponeSucceeded?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyPostponeSubmitResponse = {
  __typename?: "SupplyPostponeSubmitResponse";
  clickStreamData?: Maybe<SupplyPostponeSubmitAnalytics>;
  error?: Maybe<EgdsErrorSummary>;
  success?: Maybe<SupplyPostponeSuccessResponse>;
};

export type SupplyPostponeSuccessResponse = {
  __typename?: "SupplyPostponeSuccessResponse";
  descriptions: Array<Scalars["String"]>;
  successButtonLabel: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyPremierHost = {
  __typename?: "SupplyPremierHost";
  scorecard?: Maybe<PremierHostScorecard>;
  status?: Maybe<PremierHostStatus>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPremierHostDownloadType = "BOOKING_DETAILS" | "LISTINGS_SUMMARY";

export type SupplyPreviewRoomNameResult = EgdsPlainText | SupplyProductCreationErrorSection;

export type SupplyProductCreateRateStatus = {
  __typename?: "SupplyProductCreateRateStatus";
  icon: Icon;
  status: Scalars["String"];
};

export type SupplyProductCreationAdditionalOccupantCharge = {
  __typename?: "SupplyProductCreationAdditionalOccupantCharge";
  chargeInstruction: Scalars["String"];
  heading: Scalars["String"];
  input: SupplyProductCreationInputField;
};

export interface SupplyProductCreationBaseRateManagementInput {
  additionalOccupantCharge?: InputMaybe<Scalars["Float"]>;
  baseRate?: InputMaybe<Scalars["Float"]>;
  copyRoomTypeKey?: InputMaybe<Scalars["String"]>;
  peopleIncludedInBaseRate?: InputMaybe<Scalars["Int"]>;
  priceType: PricingType;
  ratePlanID?: InputMaybe<Scalars["String"]>;
  roomMaxOccupancy?: InputMaybe<Scalars["Int"]>;
  roomTypeKey?: InputMaybe<Scalars["String"]>;
}

export type SupplyProductCreationBaseRateSection = {
  __typename?: "SupplyProductCreationBaseRateSection";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  header: SupplyProductCreationSectionHeader;
  priceType: PricingType;
  ratePlanID?: Maybe<Scalars["String"]>;
  rateSetting: SupplyProductCreationRateSetting;
  rateSummary: SupplyProductCreationRateSummary;
};

export interface SupplyProductCreationBed {
  quantity: Scalars["Int"];
  size: Scalars["String"];
  type: Scalars["String"];
}

export type SupplyProductCreationBedConfiguration = {
  __typename?: "SupplyProductCreationBedConfiguration";
  bedNumber: EgdsStepInput;
  bedType: SupplyProductCreationSelectMenuField;
  selectedBedSize?: Maybe<Scalars["String"]>;
  selectedBedType?: Maybe<Scalars["String"]>;
};

export interface SupplyProductCreationBedGroup {
  beds?: InputMaybe<Array<SupplyProductCreationBed>>;
}

export interface SupplyProductCreationBedTypeConfiguration {
  bedGroups?: InputMaybe<Array<SupplyProductCreationBedGroup>>;
}

export type SupplyProductCreationBreakfastContentSection = {
  __typename?: "SupplyProductCreationBreakfastContentSection";
  accessibility?: Maybe<Scalars["String"]>;
  baseRatePlanId: Scalars["String"];
  breakfastTypeSelect: SupplyProductCreationSelect;
  deleteSection?: Maybe<SupplyProductCreationDeleteRatePlanSection>;
  description: Scalars["String"];
  ratePlanId?: Maybe<Scalars["String"]>;
  rateSetting: SupplyProductCreationBreakfastRateSetting;
  suggestedRateHeader: SupplyProductCreationSectionHeader;
};

export type SupplyProductCreationBreakfastRateSetting = {
  __typename?: "SupplyProductCreationBreakfastRateSetting";
  rateIncreaseSection: SupplyProductCreationRateIncreaseSection;
  rateSummarySection?: Maybe<SupplyProductCreationSideSheetRateSummary>;
};

export type SupplyProductCreationButton = {
  __typename?: "SupplyProductCreationButton";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  button: EgdsButton;
  clickFailureAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  clickSuccessAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  targetUrl?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationCheckbox = {
  __typename?: "SupplyProductCreationCheckbox";
  accessibility: Scalars["String"];
  badge?: Maybe<EgdsStandardBadge>;
  conflictCategoryName?: Maybe<SupplyProductCreationRoomNameAttributeCategoryName>;
  description?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  label: Scalars["String"];
  name: SupplyProductCreationRoomNameAttributeCategoryName;
  nestedSelection?: Maybe<SupplyProductCreationSelect>;
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationChildRate = {
  __typename?: "SupplyProductCreationChildRate";
  adjustment: Scalars["String"];
  name: Scalars["String"];
  occupancyRates: Array<SupplyProductCreationOccupancyRate>;
  rate: Scalars["String"];
};

export interface SupplyProductCreationChildRateManagementInput {
  checkedRatePlans: Array<RatePlanInfo>;
  discount: Scalars["Float"];
  discountChanged: Scalars["Boolean"];
  rateType: SideSheetType;
  roomTypeKey: Scalars["String"];
  uncheckedRatePlans: Array<InputMaybe<RatePlanInfo>>;
}

export type SupplyProductCreationChildRatePlanContentSection = {
  __typename?: "SupplyProductCreationChildRatePlanContentSection";
  accessibility?: Maybe<Scalars["String"]>;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  deleteSection?: Maybe<SupplyProductCreationDeleteRatePlanSection>;
  description: Scalars["String"];
  rateSetting: SupplyProductCreationChildRateSetting;
  suggestedSettingsHeader: SupplyProductCreationSectionHeader;
};

export type SupplyProductCreationChildRatePlanManagementResult = {
  __typename?: "SupplyProductCreationChildRatePlanManagementResult";
  error?: Maybe<SupplyProductCreationErrorBanner>;
  partialSuccessSummary?: Maybe<SupplyProductCreationPartialSuccessSummary>;
  success: Scalars["Boolean"];
};

export type SupplyProductCreationChildRateSetting = {
  __typename?: "SupplyProductCreationChildRateSetting";
  discountExplanation?: Maybe<Scalars["String"]>;
  discountInput: SupplyProductCreationInput;
  ratePlanSelection: SupplyProductCreationRatePlanSelection;
  rateSummarySection: SupplyProductCreationChildRateSummary;
  rateThreshold?: Maybe<SupplyProductCreationRateThreshold>;
};

export type SupplyProductCreationChildRateSummary = {
  __typename?: "SupplyProductCreationChildRateSummary";
  currencyCode: Scalars["String"];
  description: Scalars["String"];
};

export type SupplyProductCreationDeleteRatePlanConfirmSection = {
  __typename?: "SupplyProductCreationDeleteRatePlanConfirmSection";
  cancelButton: SupplyProductCreationButton;
  deleteButton: SupplyProductCreationButton;
  heading: Scalars["String"];
};

export type SupplyProductCreationDeleteRatePlanSection = {
  __typename?: "SupplyProductCreationDeleteRatePlanSection";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  button: SupplyProductCreationButton;
  confirmationDialog: SupplyProductCreationDeleteRatePlanConfirmSection;
};

export interface SupplyProductCreationDeleteRatePlansInput {
  ratePlanIDs: Array<Scalars["String"]>;
}

export type SupplyProductCreationErrorBanner = {
  __typename?: "SupplyProductCreationErrorBanner";
  message: Scalars["String"];
};

export type SupplyProductCreationErrorSection = {
  __typename?: "SupplyProductCreationErrorSection";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  errorMessage?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationExperience = {
  __typename?: "SupplyProductCreationExperience";
  header?: Maybe<SupplyProductCreationStepHeader>;
  mainContent?: Maybe<SupplyProductCreationMainContent>;
  navigator?: Maybe<SupplyProductCreationNavigator>;
};

export type SupplyProductCreationExperienceHeaderArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationExperienceMainContentArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationExperienceNavigatorArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export interface SupplyProductCreationExperienceInputParam {
  copyRoomTypeKey?: InputMaybe<Scalars["String"]>;
  isAddPath?: InputMaybe<Scalars["Boolean"]>;
  roomTypeKey?: InputMaybe<Scalars["String"]>;
  showPricingModel?: InputMaybe<Scalars["Boolean"]>;
  step: Scalars["String"];
}

export type SupplyProductCreationGuestPay = {
  __typename?: "SupplyProductCreationGuestPay";
  accessibility: Scalars["String"];
  occupancy: Scalars["String"];
  pay: Scalars["String"];
};

export interface SupplyProductCreationIndependentRateManagementInput {
  baseRatePlanId: Scalars["String"];
  breakfastType: Scalars["String"];
  breakfastTypeChanged?: InputMaybe<Scalars["Boolean"]>;
  rateIncrease: SupplyProductCreationRateIncrease;
  rateIncreaseChanged?: InputMaybe<Scalars["Boolean"]>;
  ratePlanId?: InputMaybe<Scalars["String"]>;
  roomTypeKey: Scalars["String"];
}

export type SupplyProductCreationInformativeInputField = {
  __typename?: "SupplyProductCreationInformativeInputField";
  inputField: SupplyProductCreationInputField;
  popoverText: Scalars["String"];
};

export type SupplyProductCreationInput = {
  __typename?: "SupplyProductCreationInput";
  heading: Scalars["String"];
  input: SupplyProductCreationInputField;
};

export type SupplyProductCreationInputField = {
  __typename?: "SupplyProductCreationInputField";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  readOnly?: Maybe<Scalars["Boolean"]>;
  required?: Maybe<Scalars["Boolean"]>;
  validationFailureAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  validations?: Maybe<Array<SupplyProductCreationInputValidation>>;
  value?: Maybe<Scalars["String"]>;
  warnings?: Maybe<Array<SupplyProductCreationInputWarning>>;
};

export interface SupplyProductCreationInputValidation {
  errorMessage: Scalars["String"];
}

export interface SupplyProductCreationInputWarning {
  errorMessage: EgdsText;
}

export type SupplyProductCreationLeaveWithoutSavingDialog = {
  __typename?: "SupplyProductCreationLeaveWithoutSavingDialog";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  description: Scalars["String"];
  heading: Scalars["String"];
  leaveButton: SupplyProductCreationButton;
  stayButton: SupplyProductCreationButton;
};

export type SupplyProductCreationLessThanInputWarning = SupplyProductCreationInputWarning & {
  __typename?: "SupplyProductCreationLessThanInputWarning";
  errorMessage: EgdsText;
  value?: Maybe<Scalars["Float"]>;
};

export type SupplyProductCreationMainContent =
  | SupplyProductCreationBaseRateSection
  | SupplyProductCreationErrorSection
  | SupplyProductCreationPopularRateSection;

export type SupplyProductCreationManagementResult = {
  __typename?: "SupplyProductCreationManagementResult";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  error?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
  value?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationManagementResultMessage = {
  __typename?: "SupplyProductCreationManagementResultMessage";
  description: Scalars["String"];
  ratePlanDisplayNames: Array<Scalars["String"]>;
};

export type SupplyProductCreationMaxInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationMaxInputValidation";
  errorMessage: Scalars["String"];
  maxValue?: Maybe<Scalars["Float"]>;
};

export type SupplyProductCreationMenu = {
  __typename?: "SupplyProductCreationMenu";
  expandoLink?: Maybe<EgdsExpandoLink>;
  invisibleItems?: Maybe<Array<SupplyProductCreationMenuItem>>;
  title?: Maybe<Scalars["String"]>;
  visibleItems: Array<SupplyProductCreationMenuItem>;
};

export type SupplyProductCreationMenuItem = {
  __typename?: "SupplyProductCreationMenuItem";
  description?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  selected: Scalars["Boolean"];
  subSections?: Maybe<Array<SupplyProductCreationSubSection>>;
  value: Scalars["String"];
};

export type SupplyProductCreationMinInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationMinInputValidation";
  errorMessage: Scalars["String"];
  minValue?: Maybe<Scalars["Float"]>;
};

export type SupplyProductCreationMoreThanInputWarning = SupplyProductCreationInputWarning & {
  __typename?: "SupplyProductCreationMoreThanInputWarning";
  errorMessage: EgdsText;
  value?: Maybe<Scalars["Float"]>;
};

export type SupplyProductCreationNavigator = {
  __typename?: "SupplyProductCreationNavigator";
  /** @deprecated Use `backNavButton` instead. */
  backButton: SupplyProductCreationButton;
  backNavButton?: Maybe<SupplyProductCreationButton>;
  leaveWithoutSavingDialog?: Maybe<SupplyProductCreationLeaveWithoutSavingDialog>;
  nextButton: SupplyProductCreationButton;
  stepIndicator: Scalars["String"];
  stepIndicatorAccessibility?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationNoRateAvailableMessage = {
  __typename?: "SupplyProductCreationNoRateAvailableMessage";
  heading: Scalars["String"];
  icon: Icon;
  paragraph: Scalars["String"];
};

export type SupplyProductCreationOccupancy = {
  __typename?: "SupplyProductCreationOccupancy";
  instruction: EgdsText;
  occupancyNumber: SupplyProductCreationInputField;
};

export type SupplyProductCreationOccupancyRate = {
  __typename?: "SupplyProductCreationOccupancyRate";
  occupancy: Scalars["Int"];
  rate: Scalars["String"];
};

export type SupplyProductCreationParentRatePlanCheckbox = {
  __typename?: "SupplyProductCreationParentRatePlanCheckbox";
  checked: Scalars["Boolean"];
  childRatePlanDisplayName: Scalars["String"];
  childRatePlanID?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  parentMaxOccupancyExtraCharge?: Maybe<Scalars["Float"]>;
  parentMaxOccupancyRate?: Maybe<Scalars["Float"]>;
  parentMinOccupancyRate?: Maybe<Scalars["Float"]>;
  parentRatePlanID: Scalars["String"];
  parentRatePlanName: Scalars["String"];
  value: Scalars["String"];
};

export type SupplyProductCreationPartialSuccessSummary = {
  __typename?: "SupplyProductCreationPartialSuccessSummary";
  button: SupplyProductCreationButton;
  failureMessage: SupplyProductCreationManagementResultMessage;
  successMessage: SupplyProductCreationManagementResultMessage;
};

export type SupplyProductCreationPopularRateCard = {
  __typename?: "SupplyProductCreationPopularRateCard";
  button?: Maybe<SupplyProductCreationButton>;
  description: Scalars["String"];
  header: SupplyProductCreationPopularRateCardHeader;
  ratePlanID?: Maybe<Scalars["String"]>;
  type: SupplyProductCreationPopularRateType;
  whyThisIsValuable: SupplyProductCreationWhyThisIsValuableSection;
};

export type SupplyProductCreationPopularRateCardDecoration = EgdsStandardBadge | SupplyProductCreateRateStatus;

export type SupplyProductCreationPopularRateCardHeader = {
  __typename?: "SupplyProductCreationPopularRateCardHeader";
  decoration: SupplyProductCreationPopularRateCardDecoration;
  heading: Scalars["String"];
  rateAdjustment?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationPopularRateSection = {
  __typename?: "SupplyProductCreationPopularRateSection";
  content: SupplyProductCreationPopularRateSectionContent;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyProductCreationPopularRateSectionContent =
  | SupplyProductCreationNoRateAvailableMessage
  | SupplyProductCreationPopularRates;

export type SupplyProductCreationPopularRateType = "BREAKFAST" | "MOD" | "NONREFUNDABLE" | "PACKAGE";

export type SupplyProductCreationPopularRates = {
  __typename?: "SupplyProductCreationPopularRates";
  contractualRates?: Maybe<SupplyProductCreationRateCategory>;
  customizeRates?: Maybe<SupplyProductCreationRateCategory>;
};

export type SupplyProductCreationPreviewBedConfiguration = {
  __typename?: "SupplyProductCreationPreviewBedConfiguration";
  legacyBedIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  recommendedOccupancy?: Maybe<SupplyProductCreationRecommendOccupancy>;
};

export interface SupplyProductCreationPreviewBedConfigurationInput {
  inRoomBeds: Array<SupplyProductCreationBed>;
  uponRequestBeds?: InputMaybe<Array<SupplyProductCreationBed>>;
}

export type SupplyProductCreationPreviewBedConfigurationResult =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationPreviewBedConfiguration;

export type SupplyProductCreationPreviewRates = {
  __typename?: "SupplyProductCreationPreviewRates";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  guestNumberSelect: SupplyProductCreationSelect;
  heading: Scalars["String"];
  rates: Array<SupplyProductCreationRate>;
};

export type SupplyProductCreationPricingCard = {
  __typename?: "SupplyProductCreationPricingCard";
  contents: Array<Scalars["String"]>;
  description: Scalars["String"];
  pricingExample: SupplyProductCreationPricingExample;
  pricingType: PricingType;
  title: Scalars["String"];
};

export type SupplyProductCreationPricingDetail = {
  __typename?: "SupplyProductCreationPricingDetail";
  header: SupplyProductCreationSectionHeader;
  pricingCards: Array<SupplyProductCreationPricingCard>;
  selected?: Maybe<PricingType>;
  selectedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyProductCreationPricingExample = {
  __typename?: "SupplyProductCreationPricingExample";
  guestPayTitle: Scalars["String"];
  guestPays: Array<SupplyProductCreationGuestPay>;
  occupancyTitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyProductCreationPricingModel = {
  __typename?: "SupplyProductCreationPricingModel";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  pricingModel: SupplyProductCreationPricingModelContent;
};

export type SupplyProductCreationPricingModelContent =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationPricingModelSection;

export type SupplyProductCreationPricingModelSection = {
  __typename?: "SupplyProductCreationPricingModelSection";
  pricingDetail?: Maybe<SupplyProductCreationPricingDetail>;
  pricingState: SupplyProductCreationPricingState;
};

export type SupplyProductCreationPricingState = {
  __typename?: "SupplyProductCreationPricingState";
  connectivity?: Maybe<SupplyProductCreationText>;
  header: SupplyProductCreationSectionHeader;
  icon: Icon;
  instruction: SupplyProductCreationSpannableText;
};

export type SupplyProductCreationRangeInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRangeInputValidation";
  errorMessage: Scalars["String"];
  /** @deprecated Use maxValue */
  max: Scalars["Int"];
  maxValue: Scalars["Float"];
  /** @deprecated Use minValue */
  min: Scalars["Int"];
  minValue: Scalars["Float"];
};

export type SupplyProductCreationRate = {
  __typename?: "SupplyProductCreationRate";
  childRates?: Maybe<Array<SupplyProductCreationChildRate>>;
  currencyCode: Scalars["String"];
  name: Scalars["String"];
  occupancyRates: Array<SupplyProductCreationOccupancyRate>;
  rate: Scalars["String"];
};

export type SupplyProductCreationRateAdjustmentMaxInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRateAdjustmentMaxInputValidation";
  errorMessage: Scalars["String"];
  maxValue?: Maybe<Scalars["Float"]>;
  type: RateAdjustmentType;
};

export type SupplyProductCreationRateCategory = {
  __typename?: "SupplyProductCreationRateCategory";
  accessibility?: Maybe<Scalars["String"]>;
  header?: Maybe<SupplyProductCreationSectionHeader>;
  rates: Array<SupplyProductCreationPopularRateCard>;
};

export interface SupplyProductCreationRateIncrease {
  rateIncrease: Scalars["Float"];
  rateIncreaseType: RateAdjustmentType;
}

export type SupplyProductCreationRateIncreaseSection = {
  __typename?: "SupplyProductCreationRateIncreaseSection";
  heading: Scalars["String"];
  rateIncreaseExplanation?: Maybe<Scalars["String"]>;
  rateIncreaseTypeSelect: SupplyProductCreationSelectField;
  rateIncreaseValueInput: SupplyProductCreationInputField;
  thresholds?: Maybe<Array<SupplyProductCreationRateIncreaseThreshold>>;
};

export type SupplyProductCreationRateIncreaseThreshold = {
  __typename?: "SupplyProductCreationRateIncreaseThreshold";
  maxValue: Scalars["Float"];
  type: RateAdjustmentType;
};

export type SupplyProductCreationRatePlanManagementResult = {
  __typename?: "SupplyProductCreationRatePlanManagementResult";
  error?: Maybe<SupplyProductCreationErrorBanner>;
  success: Scalars["Boolean"];
};

export type SupplyProductCreationRatePlanSelection = {
  __typename?: "SupplyProductCreationRatePlanSelection";
  heading: Scalars["String"];
  info: Scalars["String"];
  ratePlanCheckBoxes: Array<SupplyProductCreationParentRatePlanCheckbox>;
};

export type SupplyProductCreationRatePlanSideSheet = {
  __typename?: "SupplyProductCreationRatePlanSideSheet";
  button: SupplyProductCreationButton;
  closeAccessibility?: Maybe<Scalars["String"]>;
  closeWithAllRatesDeletedAccessibility?: Maybe<Scalars["String"]>;
  contentSection?: Maybe<SupplyProductCreationRatePlanSideSheetContent>;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  openAccessibility?: Maybe<Scalars["String"]>;
  toolbarTitle: Scalars["String"];
};

export type SupplyProductCreationRatePlanSideSheetContent =
  | SupplyProductCreationBreakfastContentSection
  | SupplyProductCreationChildRatePlanContentSection
  | SupplyProductCreationErrorBanner;

export interface SupplyProductCreationRatePlanSideSheetInput {
  roomTypeKey: Scalars["String"];
  type: SideSheetType;
}

export type SupplyProductCreationRateSetting = {
  __typename?: "SupplyProductCreationRateSetting";
  additionalOccupantCharge: SupplyProductCreationAdditionalOccupantCharge;
  baseRateInput: SupplyProductCreationInputField;
  instruction: Scalars["String"];
  peopleCountSelect: SupplyProductCreationSelectField;
  rateThreshold?: Maybe<SupplyProductCreationRateThreshold>;
};

export type SupplyProductCreationRateSummary = {
  __typename?: "SupplyProductCreationRateSummary";
  accessibility?: Maybe<Scalars["String"]>;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  expandoPeek: EgdsExpando;
  expandoSelectedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  guestPayAccessibility?: Maybe<EgdsCardinalLocalizedText>;
  guestPayTitle: Scalars["String"];
  guestPayUnit: Scalars["String"];
  instruction: Scalars["String"];
  occupancyTitle: Scalars["String"];
};

export type SupplyProductCreationRateThreshold = {
  __typename?: "SupplyProductCreationRateThreshold";
  maxValue: Scalars["Float"];
  minValue: Scalars["Float"];
  type: RateAdjustmentType;
};

export type SupplyProductCreationRecommendOccupancy = {
  __typename?: "SupplyProductCreationRecommendOccupancy";
  adults?: Maybe<Scalars["Int"]>;
  children?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type SupplyProductCreationRecommendedOccupancy = {
  __typename?: "SupplyProductCreationRecommendedOccupancy";
  header: SupplyProductCreationSectionHeader;
  instruction: EgdsIconText;
  occupancy: SupplyProductCreationOccupancy;
};

export type SupplyProductCreationRegexInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRegexInputValidation";
  errorMessage: Scalars["String"];
  pattern: Scalars["String"];
};

export type SupplyProductCreationRequiredInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRequiredInputValidation";
  errorMessage: Scalars["String"];
};

export type SupplyProductCreationReviewRoomName = {
  __typename?: "SupplyProductCreationReviewRoomName";
  accessibility: Scalars["String"];
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  description: Scalars["String"];
  roomNameCard: SupplyProductCreationRoomNameCard;
};

export type SupplyProductCreationReviewRoomNameExperience =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationReviewRoomName;

export type SupplyProductCreationRoomBasicInfo = {
  __typename?: "SupplyProductCreationRoomBasicInfo";
  header: SupplyProductCreationSectionHeader;
  roomClass: SupplyProductCreationSelectMenuField;
  roomInventory: SupplyProductCreationInput;
  roomType: SupplyProductCreationSelectMenuField;
  smokingPolicy: SupplyProductCreationSelectMenu;
};

export type SupplyProductCreationRoomInfo = {
  __typename?: "SupplyProductCreationRoomInfo";
  basicInfo: SupplyProductCreationRoomBasicInfo;
  recommendedOccupancy: SupplyProductCreationRecommendedOccupancy;
  roomTypeKey?: Maybe<Scalars["String"]>;
  sleepingSpace: SupplyProductCreationSleepingSpaceSettings;
};

export type SupplyProductCreationRoomInfoExperience = {
  __typename?: "SupplyProductCreationRoomInfoExperience";
  previewBedConfiguration?: Maybe<SupplyProductCreationPreviewBedConfigurationResult>;
  roomInfo?: Maybe<SupplyProductCreationRoomInfoSection>;
};

export type SupplyProductCreationRoomInfoExperiencePreviewBedConfigurationArgs = {
  context: ContextInput;
  previewBedConfigurationInput: SupplyProductCreationPreviewBedConfigurationInput;
};

export type SupplyProductCreationRoomInfoExperienceRoomInfoArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  roomInfoInput: SupplyProductCreationRoomInfoInput;
};

export interface SupplyProductCreationRoomInfoInput {
  copyRoomTypeKey?: InputMaybe<Scalars["String"]>;
  roomTypeKey?: InputMaybe<Scalars["String"]>;
}

export interface SupplyProductCreationRoomInfoManagementInput {
  legacyBedIds?: InputMaybe<Array<Scalars["String"]>>;
  maxOccupancy: Scalars["Int"];
  numberOfBedrooms?: InputMaybe<Scalars["Int"]>;
  numberOfLivingRooms?: InputMaybe<Scalars["Int"]>;
  numberOfRooms?: InputMaybe<Scalars["Int"]>;
  roomClass?: InputMaybe<Scalars["String"]>;
  roomType: Scalars["String"];
  roomTypeKey?: InputMaybe<Scalars["String"]>;
  smokingPolicy: Scalars["String"];
  spaces: Array<SupplyProductCreationSpace>;
}

export type SupplyProductCreationRoomInfoSection = SupplyProductCreationErrorSection | SupplyProductCreationRoomInfo;

export type SupplyProductCreationRoomNameAttribute =
  | SupplyProductCreationCheckbox
  | SupplyProductCreationRoomNameRequiredAttribute;

export type SupplyProductCreationRoomNameAttributeCategoryName =
  | "ACCESSIBILITY"
  | "AREA"
  | "BEDROOM_DETAILS"
  | "FEATURE_AMENITY"
  | "OTHER_BEDROOM_DETAILS"
  | "OUTDOOR_SPACE"
  | "ROOM_CLASS"
  | "ROOM_TYPE"
  | "SMOKING_POLICY"
  | "VIEW";

export type SupplyProductCreationRoomNameAttributeGroup = {
  __typename?: "SupplyProductCreationRoomNameAttributeGroup";
  attributes: Array<SupplyProductCreationRoomNameAttribute>;
  heading: Scalars["String"];
  maxSelection?: Maybe<Scalars["Float"]>;
};

export interface SupplyProductCreationRoomNameAttributeInput {
  attributeId: Scalars["String"];
  sectionId: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
}

export interface SupplyProductCreationRoomNameAttributesInput {
  attributes: Array<SupplyProductCreationRoomNameAttributeInput>;
  roomTypeKey?: InputMaybe<Scalars["String"]>;
}

export type SupplyProductCreationRoomNameCard = {
  __typename?: "SupplyProductCreationRoomNameCard";
  button: SupplyProductCreationButton;
  icon: Scalars["String"];
  roomNameDisplay: SupplyProductCreationRoomNameDisplay;
};

export type SupplyProductCreationRoomNameContent = {
  __typename?: "SupplyProductCreationRoomNameContent";
  accessibility: Scalars["String"];
  attributes: SupplyProductCreationRoomNameAttributeGroup;
  description: Scalars["String"];
  features: SupplyProductCreationRoomNameAttributeGroup;
  roomNameDisplay: SupplyProductCreationRoomNameDisplay;
};

export type SupplyProductCreationRoomNameContentSection =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationRoomNameContent;

export type SupplyProductCreationRoomNameDisplay = {
  __typename?: "SupplyProductCreationRoomNameDisplay";
  description: Scalars["String"];
  roomName: Scalars["String"];
};

export type SupplyProductCreationRoomNameExperience = {
  __typename?: "SupplyProductCreationRoomNameExperience";
  manageNameSideSheet?: Maybe<SupplyProductCreationRoomNameSideSheetExperience>;
  reviewNameCard?: Maybe<SupplyProductCreationReviewRoomNameExperience>;
};

export type SupplyProductCreationRoomNameExperienceManageNameSideSheetArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameInput;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationRoomNameExperienceReviewNameCardArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameInput;
  propertyContext: PropertyContext;
};

export interface SupplyProductCreationRoomNameInput {
  isAddPath?: InputMaybe<Scalars["Boolean"]>;
  roomTypeKey: Scalars["String"];
}

export type SupplyProductCreationRoomNameManagementResult = {
  __typename?: "SupplyProductCreationRoomNameManagementResult";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  roomName?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type SupplyProductCreationRoomNameRequiredAttribute = {
  __typename?: "SupplyProductCreationRoomNameRequiredAttribute";
  description?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  label: Scalars["String"];
  value: Scalars["String"];
};

export type SupplyProductCreationRoomNameSideSheet = {
  __typename?: "SupplyProductCreationRoomNameSideSheet";
  button: SupplyProductCreationButton;
  closeAccessibility: Scalars["String"];
  contentSection: SupplyProductCreationRoomNameContentSection;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]>;
  openAccessibility: Scalars["String"];
  toolbarTitle: Scalars["String"];
};

export type SupplyProductCreationRoomNameSideSheetExperience =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationRoomNameSideSheet;

export interface SupplyProductCreationRoomNameUpdateInput {
  roomTypeId: Scalars["String"];
}

export type SupplyProductCreationSectionHeader = {
  __typename?: "SupplyProductCreationSectionHeader";
  accessibility?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  subHeading?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationSelect = {
  __typename?: "SupplyProductCreationSelect";
  heading: Scalars["String"];
  select: SupplyProductCreationSelectField;
};

export type SupplyProductCreationSelectField = {
  __typename?: "SupplyProductCreationSelectField";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  select: EgdsSelect;
};

export type SupplyProductCreationSelectMenu = {
  __typename?: "SupplyProductCreationSelectMenu";
  heading: Scalars["String"];
  select: SupplyProductCreationSelectMenuField;
  subHeading?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationSelectMenuField = {
  __typename?: "SupplyProductCreationSelectMenuField";
  label: Scalars["String"];
  menu: SupplyProductCreationMenu;
  required: Scalars["Boolean"];
};

export type SupplyProductCreationSideSheetRateSummary = {
  __typename?: "SupplyProductCreationSideSheetRateSummary";
  currencyCode: Scalars["String"];
  description: Scalars["String"];
  maxOccupancyBaseRate: Scalars["Float"];
  maxOccupancyExtraCharge: Scalars["Float"];
  ratePlanName?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationSleepingSpace = {
  __typename?: "SupplyProductCreationSleepingSpace";
  accessibility: Scalars["String"];
  addAnotherBedButton: SupplyProductCreationButton;
  bedConfiguration: SupplyProductCreationBedConfiguration;
  deleteButton: SupplyProductCreationButton;
  icon: Scalars["String"];
  items?: Maybe<Array<SupplyProductCreationSleepingSpaceItem>>;
  nameTemplate: Scalars["String"];
  type: Scalars["String"];
};

export type SupplyProductCreationSleepingSpaceItem = {
  __typename?: "SupplyProductCreationSleepingSpaceItem";
  accessibility: Scalars["String"];
  addAnotherBedButton: SupplyProductCreationButton;
  beds: Array<SupplyProductCreationBedConfiguration>;
  id: Scalars["String"];
  name?: Maybe<EgdsIconText>;
  type?: Maybe<Scalars["String"]>;
  typeNumber?: Maybe<Scalars["Int"]>;
};

export type SupplyProductCreationSleepingSpaceSettings = {
  __typename?: "SupplyProductCreationSleepingSpaceSettings";
  header: SupplyProductCreationSectionHeader;
  spaces?: Maybe<Array<SupplyProductCreationSleepingSpace>>;
};

export interface SupplyProductCreationSpace {
  bedTypeConfiguration?: InputMaybe<SupplyProductCreationBedTypeConfiguration>;
  id?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
  typeNumber: Scalars["Int"];
}

export type SupplyProductCreationSpannableText = {
  __typename?: "SupplyProductCreationSpannableText";
  contents: Array<SupplyProductCreationText>;
  text: Scalars["String"];
};

export type SupplyProductCreationStepHeader = {
  __typename?: "SupplyProductCreationStepHeader";
  exitButton: SupplyProductCreationButton;
  leaveWithoutSavingDialog: SupplyProductCreationLeaveWithoutSavingDialog;
  title: SupplyProductCreationStepTitle;
};

export type SupplyProductCreationStepTitle = {
  __typename?: "SupplyProductCreationStepTitle";
  heading: Scalars["String"];
  subHeading?: Maybe<Scalars["String"]>;
};

export type SupplyProductCreationSubSection = {
  __typename?: "SupplyProductCreationSubSection";
  id: Scalars["String"];
  section: SupplyProductCreationSubSectionItem;
  visible: Scalars["Boolean"];
};

export type SupplyProductCreationSubSectionItem =
  | SupplyProductCreationInformativeInputField
  | SupplyProductCreationInputField
  | SupplyProductCreationSelectMenuField;

export type SupplyProductCreationText = {
  __typename?: "SupplyProductCreationText";
  action?: Maybe<SupplyProductCreationTextAction>;
  text: EgdsText;
};

export type SupplyProductCreationTextAction = {
  __typename?: "SupplyProductCreationTextAction";
  clickedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyProductCreationTipCard = {
  __typename?: "SupplyProductCreationTipCard";
  contents: Array<SupplyProductCreationTipContent>;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
};

export interface SupplyProductCreationTipCardInput {
  cardName: Scalars["String"];
}

export type SupplyProductCreationTipContent = EgdsBulletedList | SupplyProductCreationText;

export type SupplyProductCreationWhyThisIsValuableSection = {
  __typename?: "SupplyProductCreationWhyThisIsValuableSection";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  expando?: Maybe<EgdsExpando>;
  instructions: Array<Scalars["String"]>;
};

export type SupplyPromotionActionMenu = {
  __typename?: "SupplyPromotionActionMenu";
  actions: Array<SupplyPromotionLinkAction>;
};

export type SupplyPromotionBlockoutDates = {
  __typename?: "SupplyPromotionBlockoutDates";
  addBlockoutButton: SupplyPromotionButton;
  blockEndsLabel: Scalars["String"];
  blockStartsLabel: Scalars["String"];
  blockoutStatus: Scalars["String"];
  content: Scalars["String"];
  newBlockoutDateRangePicker: EgdsDateRangePicker;
  overlappingBlockoutDatesErrorText?: Maybe<Scalars["String"]>;
  removeBlockoutButton: SupplyPromotionButton;
  selectedBlockoutDates?: Maybe<Array<EgdsDateRangePicker>>;
  title: Scalars["String"];
};

export type SupplyPromotionBookingLimit = {
  __typename?: "SupplyPromotionBookingLimit";
  action: SupplyPromotionButton;
  input: SupplyPromotionNumberInput;
  inputTitle?: Maybe<Scalars["String"]>;
  remove: SupplyPromotionButton;
  showEditMode?: Maybe<Scalars["Boolean"]>;
};

export type SupplyPromotionBookingWindow = {
  __typename?: "SupplyPromotionBookingWindow";
  earlyBooking: SupplyPromotionNumberRange;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionBreadcrumbMenu = {
  __typename?: "SupplyPromotionBreadcrumbMenu";
  breadcrumbItems: Array<SupplyPromotionLinkAction>;
};

export type SupplyPromotionButton = {
  __typename?: "SupplyPromotionButton";
  action: EgdsButton;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionCard = {
  __typename?: "SupplyPromotionCard";
  checkbox?: Maybe<SupplyPromotionCheckbox>;
  content?: Maybe<EgdsSpannableText>;
  icon?: Maybe<Icon>;
};

export type SupplyPromotionCardListComponent = {
  __typename?: "SupplyPromotionCardListComponent";
  cards: Array<SupplyPromotionCard>;
};

export type SupplyPromotionCardSelectionComponent = {
  __typename?: "SupplyPromotionCardSelectionComponent";
  cards: Array<SupplyPromotionCard>;
  selectAll?: Maybe<SupplyPromotionCheckbox>;
};

export type SupplyPromotionCheckbox = {
  __typename?: "SupplyPromotionCheckbox";
  action: EgdsBasicCheckBox;
  analyticsDeselected?: Maybe<SupplyPromotionClickStreamData>;
  analyticsSelected?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyPromotionClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickStreamPartnerCommon>;
};

export type SupplyPromotionCoachingMessage = {
  __typename?: "SupplyPromotionCoachingMessage";
  /** @deprecated Deprecated in favor of contentBlocks */
  content?: Maybe<Array<SupplyPromotionCoachingMessageText>>;
  contentBlocks?: Maybe<Array<SupplyPromotionCoachingMessageContent>>;
  icon: Icon;
  link?: Maybe<SupplyPromotionLinkAction>;
  subTitles?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type SupplyPromotionCoachingMessageContent = {
  __typename?: "SupplyPromotionCoachingMessageContent";
  content?: Maybe<Array<SupplyPromotionCoachingMessageText>>;
};

export type SupplyPromotionCoachingMessageText = EgdsBulletedList | EgdsPlainText | EgdsSpannableText;

export type SupplyPromotionComponent =
  | SupplyPromotionCardListComponent
  | SupplyPromotionCardSelectionComponent
  | SupplyPromotionCoachingMessage;

export type SupplyPromotionContainer = {
  __typename?: "SupplyPromotionContainer";
  sections: Array<SupplyPromotionSection>;
  title?: Maybe<Scalars["String"]>;
};

export interface SupplyPromotionCreateMembersOnlyDealsTiersInput {
  blockoutDates?: InputMaybe<Array<SupplyPromotionDateRangeInput>>;
  bookingDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  bookingLimit?: InputMaybe<Scalars["Int"]>;
  canStackToOtherPromotions?: InputMaybe<Scalars["Boolean"]>;
  isManaged?: InputMaybe<Scalars["Boolean"]>;
  isNegotiated?: InputMaybe<Scalars["Boolean"]>;
  memberTiersDiscounts: SupplyPromotionMemberTiersDiscountsInput;
  name: Scalars["String"];
  promotionRunDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  ratePlanIds?: InputMaybe<Array<Scalars["String"]>>;
  restrictions?: InputMaybe<SupplyPromotionRestrictionsInput>;
  stayDates?: InputMaybe<SupplyPromotionDateRangeInput>;
}

export type SupplyPromotionCreateTieredMembersOnlyDeal = {
  __typename?: "SupplyPromotionCreateTieredMembersOnlyDeal";
  cancel: SupplyPromotionButton;
  clickStreamPageView?: Maybe<SupplyPromotionClickStreamData>;
  create: SupplyPromotionButton;
  legalDisclaimer: SupplyPromotionLegalDisclaimer;
  licensePlate: SupplyPromotionLicensePlate;
  sections: Array<SupplyPromotionMemberTiersSection>;
};

export type SupplyPromotionDatePicker = {
  __typename?: "SupplyPromotionDatePicker";
  datePicker: SupplyPromotionDatePickerUnion;
  endDateInputFieldLabel?: Maybe<Scalars["String"]>;
  startDateInputFieldLabel: Scalars["String"];
};

export type SupplyPromotionDatePickerUnion = EgdsDateRangePicker | EgdsSingleDatePicker;

export interface SupplyPromotionDateRangeInput {
  endDate?: InputMaybe<DateInput>;
  startDate: DateInput;
}

export type SupplyPromotionDates = {
  __typename?: "SupplyPromotionDates";
  content?: Maybe<Scalars["String"]>;
  dateLabel?: Maybe<Scalars["String"]>;
  dateRange?: Maybe<SupplyPromotionDatePicker>;
  singleDate?: Maybe<SupplyPromotionDatePicker>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionFilters = {
  __typename?: "SupplyPromotionFilters";
  filters: Array<SupplyPromotionPill>;
  label?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionInternalSettings = {
  __typename?: "SupplyPromotionInternalSettings";
  content?: Maybe<Scalars["String"]>;
  managed?: Maybe<SupplyPromotionSwitch>;
  negotiated?: Maybe<SupplyPromotionSwitch>;
  titleText?: Maybe<SupplyPromotionText>;
};

export type SupplyPromotionLegalDisclaimer = {
  __typename?: "SupplyPromotionLegalDisclaimer";
  action?: Maybe<SupplyPromotionButton>;
  content?: Maybe<Array<Maybe<SupplyPromotionText>>>;
  sheet?: Maybe<SupplyPromotionLegalDisclaimerSheet>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionLegalDisclaimerSheet = {
  __typename?: "SupplyPromotionLegalDisclaimerSheet";
  content?: Maybe<Array<SupplyPromotionText>>;
  title?: Maybe<Scalars["String"]>;
  toolbar: SupplyPromotionToolbar;
};

export type SupplyPromotionLengthOfStay = {
  __typename?: "SupplyPromotionLengthOfStay";
  numberRange: SupplyPromotionNumberRange;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionLicensePlate = {
  __typename?: "SupplyPromotionLicensePlate";
  actionMenu: SupplyPromotionActionMenu;
  breadcrumbMenu: SupplyPromotionBreadcrumbMenu;
  content?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyPromotionLinkAction = {
  __typename?: "SupplyPromotionLinkAction";
  action: EgdsStandardLink;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionMemberTiersCombinePromotions = {
  __typename?: "SupplyPromotionMemberTiersCombinePromotions";
  content?: Maybe<Scalars["String"]>;
  editAction?: Maybe<SupplyPromotionButton>;
  status: SupplyPromotionSwitch;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionMemberTiersComponent =
  | SupplyPromotionBookingLimit
  | SupplyPromotionCoachingMessage
  | SupplyPromotionInternalSettings
  | SupplyPromotionMemberTiersCombinePromotions
  | SupplyPromotionMemberTiersDiscounts
  | SupplyPromotionName
  | SupplyPromotionRestrictions
  | SupplyPromotionRoomsAndRates
  | SupplyPromotionRunDates;

export type SupplyPromotionMemberTiersDiscount = {
  __typename?: "SupplyPromotionMemberTiersDiscount";
  discount: EgdsNumberInputField;
  discountInputAnalytics?: Maybe<SupplyPromotionClickStreamData>;
  status?: Maybe<SupplyPromotionSwitch>;
};

export type SupplyPromotionMemberTiersDiscounts = {
  __typename?: "SupplyPromotionMemberTiersDiscounts";
  content: Scalars["String"];
  link?: Maybe<SupplyPromotionLinkAction>;
  matchingNumberValidation?: Maybe<SupplyPromotionMemberTiersMultiInputNumberValidation>;
  memberDiscounts: Array<SupplyPromotionMemberTiersDiscount>;
  title: Scalars["String"];
};

export interface SupplyPromotionMemberTiersDiscountsInput {
  baseMemberDiscount: Scalars["Int"];
  middleMemberDiscount?: InputMaybe<Scalars["Int"]>;
  topMemberDiscount?: InputMaybe<Scalars["Int"]>;
}

export type SupplyPromotionMemberTiersMultiInputNumberValidation = {
  __typename?: "SupplyPromotionMemberTiersMultiInputNumberValidation";
  errorMessage: Scalars["String"];
};

export type SupplyPromotionMemberTiersSection = {
  __typename?: "SupplyPromotionMemberTiersSection";
  components: Array<SupplyPromotionMemberTiersComponent>;
  heading?: Maybe<Scalars["String"]>;
  subHeadings?: Maybe<Array<Scalars["String"]>>;
};

export type SupplyPromotionMutationResponse = {
  __typename?: "SupplyPromotionMutationResponse";
  sideEffects?: Maybe<Array<SupplyPromotionSideEffects>>;
  status: SupplyPromotionResponseStatus;
};

export type SupplyPromotionName = {
  __typename?: "SupplyPromotionName";
  content?: Maybe<Scalars["String"]>;
  nameInput: EgdsTextInputField;
  nameInputAnalytics?: Maybe<SupplyPromotionClickStreamData>;
  title: Scalars["String"];
};

export type SupplyPromotionNavigateToHub = {
  __typename?: "SupplyPromotionNavigateToHub";
  url: Scalars["String"];
};

export type SupplyPromotionNotification = {
  __typename?: "SupplyPromotionNotification";
  notification: UiBanner;
  type: SupplyPromotionNotificationType;
};

export type SupplyPromotionNotificationType = "ERROR" | "SUCCESS";

export type SupplyPromotionNumberInput = {
  __typename?: "SupplyPromotionNumberInput";
  analytics?: Maybe<SupplyPromotionClickStreamData>;
  input: EgdsNumberInputField;
};

export type SupplyPromotionNumberRange = {
  __typename?: "SupplyPromotionNumberRange";
  max: SupplyPromotionNumberInput;
  maxLowerThanMinErrorText?: Maybe<Scalars["String"]>;
  min: SupplyPromotionNumberInput;
  title?: Maybe<Scalars["String"]>;
};

export interface SupplyPromotionNumberRangeInput {
  max: Scalars["Int"];
  min: Scalars["Int"];
}

export type SupplyPromotionPill = {
  __typename?: "SupplyPromotionPill";
  action: EgdsBasicPill;
  analyticsDeselected?: Maybe<SupplyPromotionClickStreamData>;
  analyticsSelected?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionPlayground = {
  __typename?: "SupplyPromotionPlayground";
  clickStreamPageView?: Maybe<SupplyPromotionClickStreamData>;
  inputs: Array<SupplyPromotionPlaygroundInput>;
  licensePlate: SupplyPromotionLicensePlate;
  /** @deprecated Deprecated in favor of inputs */
  roomsAndRates?: Maybe<SupplyPromotionSelect>;
  submit: SupplyPromotionButton;
};

export type SupplyPromotionPlaygroundInput = SupplyPromotionDates | SupplyPromotionSelect;

export interface SupplyPromotionPlaygroundInputs {
  memberLevel: Scalars["Int"];
  reservationDate: DateInput;
  roomRatePlanId: Scalars["String"];
  travelDates: SupplyPromotionDateRangeInput;
}

export type SupplyPromotionPlaygroundResult = {
  __typename?: "SupplyPromotionPlaygroundResult";
  bestDiscount?: Maybe<SupplyPromotionCoachingMessage>;
  promotionCards?: Maybe<Array<SupplyPromotionCard>>;
};

export type SupplyPromotionRateOption = {
  __typename?: "SupplyPromotionRateOption";
  filterMeta: Array<Scalars["String"]>;
  rateOption: SupplyPromotionCheckbox;
};

export type SupplyPromotionReservationDates = {
  __typename?: "SupplyPromotionReservationDates";
  advancedMode: SupplyPromotionButton;
  bookingAfterStayEndDateValidation: Scalars["String"];
  bookingAfterStayStartDateValidation: Scalars["String"];
  bookingDates: SupplyPromotionDates;
  initialReservationMode: SupplyPromotionReservationMode;
  runDates: SupplyPromotionDates;
  standardMode: SupplyPromotionButton;
  stayDates: SupplyPromotionDates;
  stayStartDateBookingWindowValidation?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionReservationMode = "ADVANCED" | "STANDARD";

export type SupplyPromotionResponseStatus = "FAIL" | "SUCCESS";

export type SupplyPromotionRestriction = SupplyPromotionBookingWindow | SupplyPromotionLengthOfStay;

export type SupplyPromotionRestrictions = {
  __typename?: "SupplyPromotionRestrictions";
  cancel?: Maybe<SupplyPromotionButton>;
  coachingContent?: Maybe<Array<SupplyPromotionText>>;
  coachingMessage?: Maybe<SupplyPromotionCoachingMessage>;
  content?: Maybe<Scalars["String"]>;
  edit?: Maybe<SupplyPromotionButton>;
  restrictions?: Maybe<Array<SupplyPromotionRestriction>>;
  showEditMode?: Maybe<Scalars["Boolean"]>;
  summary?: Maybe<Array<SupplyPromotionText>>;
  title: Scalars["String"];
};

export interface SupplyPromotionRestrictionsInput {
  earlyBooking?: InputMaybe<SupplyPromotionNumberRangeInput>;
  lengthOfStay?: InputMaybe<SupplyPromotionNumberRangeInput>;
}

export type SupplyPromotionRoomsAndRates = {
  __typename?: "SupplyPromotionRoomsAndRates";
  content: Scalars["String"];
  edit?: Maybe<SupplyPromotionButton>;
  reactiveContent: SupplyPromotionRoomsAndRatesLabels;
  sheet?: Maybe<SupplyPromotionRoomsAndRatesSheet>;
};

export type SupplyPromotionRoomsAndRatesLabels = {
  __typename?: "SupplyPromotionRoomsAndRatesLabels";
  noRoomsAndRates: Scalars["String"];
  roomsAndRates: Scalars["String"];
  roomsAndRatesAll: Scalars["String"];
  /** @deprecated Deprecated in favor of roomsAndRates */
  roomsAndRatesMany: Scalars["String"];
  /** @deprecated Deprecated in favor of roomsAndRates */
  roomsAndRatesSingle: Scalars["String"];
  /** @deprecated Deprecated in favor of roomsAndRates */
  roomsSingle: Scalars["String"];
};

export type SupplyPromotionRoomsAndRatesOption = {
  __typename?: "SupplyPromotionRoomsAndRatesOption";
  filterMeta: Array<Scalars["String"]>;
  rateOptions: Array<SupplyPromotionRateOption>;
  roomOption: SupplyPromotionCheckbox;
};

export type SupplyPromotionRoomsAndRatesSheet = {
  __typename?: "SupplyPromotionRoomsAndRatesSheet";
  actions: Array<SupplyPromotionButton>;
  allRoomsAndRatesOption: SupplyPromotionCheckbox;
  content: Scalars["String"];
  noRoomsAndRatesFoundLabel: Scalars["String"];
  roomsAndRatesOptions: Array<SupplyPromotionRoomsAndRatesOption>;
  searchFilters: SupplyPromotionFilters;
  searchInput: EgdsTextInputField;
  selectedLabel: SupplyPromotionRoomsAndRatesLabels;
  toolbar: SupplyPromotionToolbar;
};

export type SupplyPromotionRunDates = {
  __typename?: "SupplyPromotionRunDates";
  blockoutDateOccursBeforeRunStartDateErrorText: Scalars["String"];
  blockoutDates: SupplyPromotionBlockoutDates;
  content: Scalars["String"];
  reservationDates?: Maybe<SupplyPromotionReservationDates>;
  title: Scalars["String"];
};

export type SupplyPromotionSection = {
  __typename?: "SupplyPromotionSection";
  components: Array<SupplyPromotionComponent>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionSelect = {
  __typename?: "SupplyPromotionSelect";
  action: EgdsBasicSelect;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionSheetFooter = {
  __typename?: "SupplyPromotionSheetFooter";
  buttons: Array<SupplyPromotionButton>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyPromotionSideEffects =
  | SupplyPromotionNavigateToHub
  | SupplyPromotionNotification
  | SupplyPromotionToast;

export type SupplyPromotionStackingOptionsSheet = {
  __typename?: "SupplyPromotionStackingOptionsSheet";
  actionFooter?: Maybe<SupplyPromotionSheetFooter>;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
  container?: Maybe<SupplyPromotionContainer>;
  content?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<SupplyPromotionToolbar>;
};

export type SupplyPromotionSwitch = {
  __typename?: "SupplyPromotionSwitch";
  action: UiToggle;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionText = EgdsBulletedList | EgdsPlainText | EgdsSpannableText;

export type SupplyPromotionToast = {
  __typename?: "SupplyPromotionToast";
  analytics?: Maybe<SupplyPromotionClickStreamData>;
  toast: EgdsToast;
};

export type SupplyPromotionToolbar = {
  __typename?: "SupplyPromotionToolbar";
  analyticsPrimary?: Maybe<SupplyPromotionClickStreamData>;
  toolbar?: Maybe<UiToolbar>;
};

export interface SupplyPromotionUpdateMembersOnlyDealsTiersInput {
  blockoutDates?: InputMaybe<Array<SupplyPromotionDateRangeInput>>;
  bookingDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  bookingLimit?: InputMaybe<Scalars["Int"]>;
  canStackToOtherPromotions?: InputMaybe<Scalars["Boolean"]>;
  isManaged?: InputMaybe<Scalars["Boolean"]>;
  isNegotiated?: InputMaybe<Scalars["Boolean"]>;
  memberTiersDiscounts: SupplyPromotionMemberTiersDiscountsInput;
  name: Scalars["String"];
  promotionRunDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  ratePlanIds?: InputMaybe<Array<Scalars["String"]>>;
  restrictions?: InputMaybe<SupplyPromotionRestrictionsInput>;
  stayDates?: InputMaybe<SupplyPromotionDateRangeInput>;
}

export type SupplyPromotionUpdateTieredMembersOnlyDeal = {
  __typename?: "SupplyPromotionUpdateTieredMembersOnlyDeal";
  cancel: SupplyPromotionButton;
  clickStreamPageView?: Maybe<SupplyPromotionClickStreamData>;
  legalDisclaimer: SupplyPromotionLegalDisclaimer;
  licensePlate: SupplyPromotionLicensePlate;
  sections: Array<SupplyPromotionMemberTiersSection>;
  update: SupplyPromotionButton;
};

export type SupplyPropertiesSummaryContent = EgdsIconText | SupplyAssociatedPropertiesSummary;

export type SupplyPropertyEditorPage = {
  __typename?: "SupplyPropertyEditorPage";
  header: SupplyPropertyEditorPageHeader;
};

export type SupplyPropertyEditorPageHeader = {
  __typename?: "SupplyPropertyEditorPageHeader";
  description?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyPropertyFilterEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyPropertyFilterEgClickstream";
  additionalContext?: Maybe<EgTypeaheadAdditionalContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  mapProductDetail: EgPropertyCount;
  typeahead?: Maybe<EgTypeahead>;
};

export type SupplyPropertyFilterHeader = {
  __typename?: "SupplyPropertyFilterHeader";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyPropertyFilterSection = {
  __typename?: "SupplyPropertyFilterSection";
  header?: Maybe<SupplyPropertyFilterHeader>;
  propertiesSummaryContent: SupplyPropertiesSummaryContent;
  /** @deprecated Use AnalyticsPayload instead */
  propertyFilterPresented: SupplyPropertyFilterEgClickstream;
  propertyFilterPresentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  propertySearch?: Maybe<EgdsTextInputField>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchCleared?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchClearedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchInputted?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchInputtedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchPresented?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchPresentedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchSelected?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  secondaryButton?: Maybe<SupplySeeAllPropertiesButton>;
  /** @deprecated Use AnalyticsPayload instead */
  seeAllPropertiesSelected?: Maybe<SupplyPropertyFilterEgClickstream>;
  seeAllPropertiesSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyPropertyListItemActionContent = {
  __typename?: "SupplyPropertyListItemActionContent";
  description: EgdsStylizedText;
  icon?: Maybe<Icon>;
  link?: Maybe<UiLinkAction>;
  propertyId?: Maybe<Scalars["String"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchItemSelected: SupplyPropertyFilterEgClickstream;
  propertySearchItemSelectedV2?: Maybe<Scalars["AnalyticsPayload"]>;
  statusMessage?: Maybe<EgdsStylizedText>;
  thumbnail: SupplyPropertyThumbnailImage;
  title: EgdsStylizedText;
};

export interface SupplyPropertyRateInput {
  baseRate: Scalars["Float"];
  currencyCode: Scalars["String"];
  language: Scalars["String"];
}

export type SupplyPropertyRatesBankingCountryCurrencyView = {
  __typename?: "SupplyPropertyRatesBankingCountryCurrencyView";
  bankingCountryHeading: Scalars["String"];
  bankingCountrySelect: EgdsBasicSelect;
  bankingCountrySubHeading: Scalars["String"];
  bankingCurrencySelect: SupplyPropertyRatesBankingCurrencySelect;
  bankingCurrencySelectV1?: Maybe<SupplyPropertyRatesBankingCurrencySelect>;
  clickstreamBankingInputted: RatesEgClickstreamData;
  clickstreamBankingSelected: RatesEgClickstreamData;
  clickstreamCurrencyInputted: RatesEgClickstreamData;
  clickstreamCurrencyInputtedV1?: Maybe<RatesEgClickstreamData>;
  clickstreamCurrencySelected: RatesEgClickstreamData;
  clickstreamCurrencySelectedV1?: Maybe<RatesEgClickstreamData>;
  currencyHeading: Scalars["String"];
  currencyHeadingV1?: Maybe<Scalars["String"]>;
  currencySubHeading: Scalars["String"];
  currencySubHeadingV1?: Maybe<Scalars["String"]>;
  ratesReadySignal: Scalars["String"];
  saveErrorSignal: Scalars["String"];
  signal: Scalars["String"];
};

export type SupplyPropertyRatesBankingCurrencySelect = {
  __typename?: "SupplyPropertyRatesBankingCurrencySelect";
  disabled: Scalars["Boolean"];
  label: Scalars["String"];
  options: Array<SupplyPropertyRatesCurrencyOptionV2>;
};

export type SupplyPropertyRatesCurrencyOption = EgdsOption & {
  __typename?: "SupplyPropertyRatesCurrencyOption";
  acceptedCountries: Array<Scalars["String"]>;
  currencySymbol: Scalars["String"];
  label: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  value: Scalars["String"];
};

export type SupplyPropertyRatesCurrencyOptionV2 = {
  __typename?: "SupplyPropertyRatesCurrencyOptionV2";
  acceptedCountries: Array<Scalars["String"]>;
  currencySymbol: Scalars["String"];
  label: Scalars["String"];
  selected?: Maybe<Scalars["Boolean"]>;
  value: Scalars["String"];
};

export type SupplyPropertyRatesErrorView = {
  __typename?: "SupplyPropertyRatesErrorView";
  description: Scalars["String"];
  heading: Scalars["String"];
};

export type SupplyPropertyRatesNightlyBaseRateView = {
  __typename?: "SupplyPropertyRatesNightlyBaseRateView";
  clickstreamNightlyBaseRateInputted: RatesEgClickstreamData;
  clickstreamNightlyBaseRateSelected: RatesEgClickstreamData;
  currency?: Maybe<Scalars["String"]>;
  currencySymbol?: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  iconText: EgdsIconText;
  inputHint?: Maybe<Scalars["String"]>;
  rateInput: EgdsNumberInputField;
  saveErrorSignal: Scalars["String"];
  signal: Scalars["String"];
  /** @deprecated Deprecated in favor of subHeadingV1 */
  subHeading: Scalars["String"];
  subHeadingV1?: Maybe<SupplyPropertyRatesSubHeadingUnion>;
};

export type SupplyPropertyRatesOfferView = {
  __typename?: "SupplyPropertyRatesOfferView";
  clickstreamDiscountCheckSelected: RatesEgClickstreamData;
  clickstreamDiscountLinkSelected: RatesEgClickstreamData;
  discountInfo: EgdsStandardLink;
  heading: Scalars["String"];
  options: EgdsBasicRadioGroup;
  saveErrorSignal: Scalars["String"];
  subHeading: Scalars["String"];
};

export type SupplyPropertyRatesSubHeading = {
  __typename?: "SupplyPropertyRatesSubHeading";
  text: Scalars["String"];
};

export type SupplyPropertyRatesSubHeadingRecommendation = {
  __typename?: "SupplyPropertyRatesSubHeadingRecommendation";
  clickstreamRateMLRecommendation?: Maybe<RatesEgClickstreamData>;
  icon: Icon;
  moreInfoText: Scalars["String"];
  text: EgdsSpannableText;
};

export type SupplyPropertyRatesSubHeadingUnion =
  | SupplyPropertyRatesSubHeading
  | SupplyPropertyRatesSubHeadingRecommendation;

export type SupplyPropertySearchResultContent = SupplyAssociatedPropertiesSummary | SupplyEmptyPropertiesSearchResult;

export type SupplyPropertyThumbnailImage = Icon | Image;

export type SupplyPublishSignalAction = {
  __typename?: "SupplyPublishSignalAction";
  signal: Scalars["String"];
};

export type SupplyQuickActionAcknowledgeButton = {
  __typename?: "SupplyQuickActionAcknowledgeButton";
  button: EgdsButton;
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
};

export interface SupplyQuickActionAction {
  analytics: EgClickstreamEvent;
}

export type SupplyQuickActionActionedSummaryViewAction = {
  __typename?: "SupplyQuickActionActionedSummaryViewAction";
  button: EgdsButton;
  message: EgdsErrorSummary;
};

export type SupplyQuickActionCard = {
  __typename?: "SupplyQuickActionCard";
  cardElements: Array<SupplyQuickActionCardElements>;
  link?: Maybe<EgdsStandardLink>;
};

export type SupplyQuickActionCardElements = SupplyQuickActionSpacing | SupplyQuickActionText;

export type SupplyQuickActionCheckBoxGroupInput = {
  __typename?: "SupplyQuickActionCheckBoxGroupInput";
  checkBoxGroup: EgdsCheckBoxGroup;
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SupplyQuickActionClickStreamPartnerCommon = {
  __typename?: "SupplyQuickActionClickStreamPartnerCommon";
  promotion_id?: Maybe<Scalars["String"]>;
};

export type SupplyQuickActionComparisonType = "GREATER_THAN" | "LESSER_THAN";

export type SupplyQuickActionComparisonValidation = {
  __typename?: "SupplyQuickActionComparisonValidation";
  comparator: SupplyQuickActionComparisonType;
  errorMessage: Scalars["String"];
  formIdToCompare: Scalars["String"];
};

export type SupplyQuickActionEgdsValidation = {
  __typename?: "SupplyQuickActionEGDSValidation";
  validation: EgdsInputValidation;
};

export type SupplyQuickActionEgClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyQuickActionEgClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<SupplyQuickActionClickStreamPartnerCommon>;
  virtual_market_coach?: Maybe<SupplyVmcEgClickstreamCustomAttribute>;
};

export type SupplyQuickActionElementVisibility = "HIDE_SELF" | "SHOW_SELF";

export type SupplyQuickActionExpando = {
  __typename?: "SupplyQuickActionExpando";
  collapsedLabel: Scalars["String"];
  /** @deprecated Please use expanded Items for the expando content */
  expandedContent: Scalars["String"];
  expandedItems: Array<SupplyQuickActionExpandoContent>;
  expandedLabel: Scalars["String"];
};

export type SupplyQuickActionExpandoContent =
  | EgdsBulletedList
  | EgdsPlainText
  | EgdsStandardLink
  | SupplyQuickActionSpacing
  | UiSecondaryButton
  | UiTertiaryButton;

export type SupplyQuickActionFieldProcessor = {
  __typename?: "SupplyQuickActionFieldProcessor";
  operation: SupplyQuickActionFieldProcessorOperation;
  pattern: Scalars["String"];
  publisherInputId: Scalars["String"];
};

export type SupplyQuickActionFieldProcessorOperation =
  | "PERCENT_CALC_AND_REPLACE"
  | "PLAIN_REPLACE"
  | "SUBTRACT_AND_REPLACE";

export type SupplyQuickActionFieldSet = {
  __typename?: "SupplyQuickActionFieldSet";
  description?: Maybe<Scalars["String"]>;
  direction?: Maybe<SupplyQuickActionFieldSetDirection>;
  elements: Array<SupplyQuickActionFieldSetElement>;
  label?: Maybe<Scalars["String"]>;
};

export type SupplyQuickActionFieldSetDirection = "HORIZONTAL" | "VERTICAL";

export type SupplyQuickActionFieldSetElement =
  | SupplyQuickActionCard
  | SupplyQuickActionExpando
  | SupplyQuickActionFormInput
  | SupplyQuickActionSpacing
  | SupplyQuickActionTable
  | SupplyQuickActionText;

export interface SupplyQuickActionForm {
  formId: Scalars["String"];
}

export type SupplyQuickActionFormContext = {
  __typename?: "SupplyQuickActionFormContext";
  key: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export interface SupplyQuickActionFormContextInput {
  key: Scalars["String"];
  values: Array<Scalars["String"]>;
}

export type SupplyQuickActionFormInput = SupplyQuickActionSubscribeElement & {
  __typename?: "SupplyQuickActionFormInput";
  formValidations: Array<SupplyQuickActionFormInputValidation>;
  inputComponent: SupplyQuickActionFormInputElement;
  inputId: Scalars["String"];
  publishes: Array<SupplyQuickActionPublishEvent>;
  subscribesTo: Array<SupplyQuickActionSubscribeEvent>;
  /** @deprecated This field will be deprecated in the future, use formValidations instead */
  validations: Array<EgdsInputValidation>;
};

export type SupplyQuickActionFormInputElement =
  | EgdsBasicCheckBox
  | EgdsBasicRadioGroup
  | EgdsBasicStepInput
  | EgdsCompositeDatePicker
  | EgdsNumberInputField
  | EgdsSingleDatePicker
  | EgdsStandardSwitch
  | EgdsTextInputField
  | SupplyQuickActionCheckBoxGroupInput
  | SupplyQuickActionSlider;

export type SupplyQuickActionFormInputValidation =
  | SupplyQuickActionComparisonValidation
  | SupplyQuickActionEgdsValidation;

export type SupplyQuickActionHostedViewAnalytics = {
  __typename?: "SupplyQuickActionHostedViewAnalytics";
  sheetAnalytics?: Maybe<SupplyQuickActionSheetEventAnalytics>;
  submitAnalytics?: Maybe<SupplyQuickActionSubmitAnalytics>;
};

export type SupplyQuickActionOpenHostedViewAction = {
  __typename?: "SupplyQuickActionOpenHostedViewAction";
  clickStreamData?: Maybe<SupplyQuickActionHostedViewAnalytics>;
  errorMessage: EgdsErrorSummary;
  url: HttpUri;
};

export type SupplyQuickActionOpenSheetAction = SupplyQuickActionAction & {
  __typename?: "SupplyQuickActionOpenSheetAction";
  /** @deprecated We are not using analytics field */
  analytics: EgClickstreamEvent;
  sheet: SupplyQuickActionSheet;
};

export type SupplyQuickActionPublishEvent = {
  __typename?: "SupplyQuickActionPublishEvent";
  type: Scalars["String"];
};

export type SupplyQuickActionRedirectButton = {
  __typename?: "SupplyQuickActionRedirectButton";
  button: EgdsButton;
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
  link: HttpUri;
};

export type SupplyQuickActionSendCardInteractionSignal = {
  __typename?: "SupplyQuickActionSendCardInteractionSignal";
  payload?: Maybe<SupplyQuickActionSendCardInteractionSignalPayload>;
  signalType: Scalars["String"];
};

export type SupplyQuickActionSendCardInteractionSignalPayload = {
  __typename?: "SupplyQuickActionSendCardInteractionSignalPayload";
  clickStreamData?: Maybe<SupplyQuickActionSheetEventAnalytics>;
  coachingTipId: Scalars["String"];
  submitContext?: Maybe<Array<SupplyQuickActionFormContext>>;
};

export type SupplyQuickActionSheet = SupplyQuickActionForm & {
  __typename?: "SupplyQuickActionSheet";
  clickStreamData?: Maybe<SupplyQuickActionSheetEventAnalytics>;
  elements: Array<SupplyQuickActionSheetElement>;
  formId: Scalars["String"];
  heading: Scalars["String"];
  sheet: EgdsSheet;
  submit: Array<SupplyQuickActionSubmitElements>;
};

export type SupplyQuickActionSheetElement =
  | SupplyQuickActionCard
  | SupplyQuickActionExpando
  | SupplyQuickActionFieldSet
  | SupplyQuickActionFormInput
  | SupplyQuickActionSpacing
  | SupplyQuickActionTable
  | SupplyQuickActionText
  | UiBanner;

export type SupplyQuickActionSheetEventAnalytics = {
  __typename?: "SupplyQuickActionSheetEventAnalytics";
  /** @deprecated This field will be deprecated in the future, use quickActionSheetClosedAnalyticsPayload instead */
  quickActionSheetClosed?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSheetClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionSheetPresentedAnalyticsPayload instead */
  quickActionSheetPresented?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSheetPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyQuickActionSlider = {
  __typename?: "SupplyQuickActionSlider";
  enabled?: Maybe<Scalars["Boolean"]>;
  range: RangeValue;
  step: Scalars["Int"];
};

export type SupplyQuickActionSpacing = {
  __typename?: "SupplyQuickActionSpacing";
  horizontal?: Maybe<SupplyQuickActionSpacingSize>;
  horizontalRule?: Maybe<Scalars["Boolean"]>;
  vertical?: Maybe<SupplyQuickActionSpacingSize>;
};

export type SupplyQuickActionSpacingSize =
  | "SPACING__0X__HALF"
  | "SPACING__1X"
  | "SPACING__2X"
  | "SPACING__3X"
  | "SPACING__4X"
  | "SPACING__5X"
  | "SPACING__6X"
  | "SPACING__8X"
  | "SPACING__10X"
  | "SPACING__11X"
  | "SPACING__12X"
  | "SPACING__14X"
  | "SPACING__16X";

export type SupplyQuickActionSubmitAnalytics = {
  __typename?: "SupplyQuickActionSubmitAnalytics";
  quickActionAcknowledgedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionFailedAnalyticsPayload instead */
  quickActionFailed?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionFailedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  quickActionRedirectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionSubmittedAnalyticsPayload instead */
  quickActionSubmitted?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSubmittedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionSucceededAnalyticsPayload instead */
  quickActionSucceeded?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSucceededAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyQuickActionSubmitButton = {
  __typename?: "SupplyQuickActionSubmitButton";
  actionContext?: Maybe<Array<SupplyQuickActionFormContext>>;
  alwaysDisabled?: Maybe<Scalars["Boolean"]>;
  button: EgdsButton;
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
};

export type SupplyQuickActionSubmitContentElement =
  | SupplyQuickActionSpacing
  | SupplyQuickActionSubmitDetailList
  | SupplyQuickActionText
  | UiSecondaryButton;

export type SupplyQuickActionSubmitDetailList = {
  __typename?: "SupplyQuickActionSubmitDetailList";
  submitDetailListItems: Array<SupplyQuickActionSubmitDetailListItem>;
};

export type SupplyQuickActionSubmitDetailListItem = SupplyQuickActionSpacing | SupplyQuickActionText;

export type SupplyQuickActionSubmitElements =
  | SupplyQuickActionAcknowledgeButton
  | SupplyQuickActionRedirectButton
  | SupplyQuickActionSubmitButton
  | SupplyQuickActionSubmitPostpone;

export type SupplyQuickActionSubmitPostpone = {
  __typename?: "SupplyQuickActionSubmitPostpone";
  buttonLabel: Scalars["String"];
  clickStreamData?: Maybe<Scalars["AnalyticsPayload"]>;
  /** @deprecated This field will be deprecated in the future, use button for postpone */
  link?: Maybe<EgdsStandardLink>;
  postponeContext: Array<SupplyQuickActionFormContext>;
};

export type SupplyQuickActionSubmitResponse = {
  __typename?: "SupplyQuickActionSubmitResponse";
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
  error?: Maybe<EgdsErrorSummary>;
  success?: Maybe<SupplyQuickActionSubmitSuccessResponse>;
};

export type SupplyQuickActionSubmitSuccessResponse = {
  __typename?: "SupplyQuickActionSubmitSuccessResponse";
  button?: Maybe<EgdsButton>;
  contentElements: Array<SupplyQuickActionSubmitContentElement>;
  /** @deprecated This field will be deprecated in the future, use contentElements instead */
  description: SupplyQuickActionText;
  icon: Icon;
  title: SupplyQuickActionText;
};

export interface SupplyQuickActionSubscribeElement {
  subscribesTo: Array<SupplyQuickActionSubscribeEvent>;
}

export type SupplyQuickActionSubscribeEvent =
  | SupplyQuickActionSubscribeReplaceEvent
  | SupplyQuickActionSubscribeVisibilityEvent;

export type SupplyQuickActionSubscribeReplaceEvent = {
  __typename?: "SupplyQuickActionSubscribeReplaceEvent";
  processors: Array<SupplyQuickActionFieldProcessor>;
  textWithPattern: Scalars["String"];
  type: Scalars["String"];
};

export type SupplyQuickActionSubscribeVisibilityEvent = {
  __typename?: "SupplyQuickActionSubscribeVisibilityEvent";
  type: Scalars["String"];
  visibility?: Maybe<SupplyQuickActionElementVisibility>;
};

export type SupplyQuickActionTable = {
  __typename?: "SupplyQuickActionTable";
  columnHeaders?: Maybe<Array<Scalars["String"]>>;
  rows: Array<SupplyQuickActionTableItemRows>;
  title: Scalars["String"];
};

export type SupplyQuickActionTableItemCell = {
  __typename?: "SupplyQuickActionTableItemCell";
  text: Scalars["String"];
  type?: Maybe<SupplyQuickActionTableItemCellType>;
};

export type SupplyQuickActionTableItemCellType = "NORMAL" | "STRIKETHROUGH";

export type SupplyQuickActionTableItemRows = {
  __typename?: "SupplyQuickActionTableItemRows";
  cells: Array<SupplyQuickActionTableItemCell>;
};

export type SupplyQuickActionText = SupplyQuickActionSubscribeElement & {
  __typename?: "SupplyQuickActionText";
  subscribesTo: Array<SupplyQuickActionSubscribeEvent>;
  textComponent: SupplyQuickActionTextContent;
};

export type SupplyQuickActionTextContent =
  | EgdsHeading
  | EgdsIconText
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText
  | EgdsTextIconList
  | EgdsTextListSection
  | EgdsTextSection;

export interface SupplyRefundDecision {
  content: Scalars["String"];
  icon: Icon;
  primaryButton: EgdsButton;
  subtitle: Scalars["String"];
  title: Scalars["String"];
}

export type SupplyRefundDisclaimerInlineLink = {
  __typename?: "SupplyRefundDisclaimerInlineLink";
  formatText: Scalars["String"];
  fullText: Scalars["String"];
  inlineLink: Array<EgdsInlineLink>;
  placeholderText: Scalars["String"];
};

export type SupplyRegulationAddressCountrySpecialRule = {
  __typename?: "SupplyRegulationAddressCountrySpecialRule";
  countries: Array<Scalars["String"]>;
  maxLength?: Maybe<Scalars["Int"]>;
  regExp?: Maybe<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
};

export type SupplyRegulationAddressCountryStates = {
  __typename?: "SupplyRegulationAddressCountryStates";
  country: Scalars["String"];
  stateProvinces: Array<SupplyRegulationAddressSelectOption>;
};

export type SupplyRegulationAddressFieldRules = {
  __typename?: "SupplyRegulationAddressFieldRules";
  countrySpecialRules?: Maybe<Array<SupplyRegulationAddressCountrySpecialRule>>;
  maxLength?: Maybe<Scalars["Int"]>;
  regExp?: Maybe<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
};

export type SupplyRegulationAddressFieldsConfig = {
  __typename?: "SupplyRegulationAddressFieldsConfig";
  address1?: Maybe<SupplyRegulationAddressFieldRules>;
  address2?: Maybe<SupplyRegulationAddressFieldRules>;
  city?: Maybe<SupplyRegulationAddressFieldRules>;
  postalCode?: Maybe<SupplyRegulationAddressFieldRules>;
  stateProvince?: Maybe<SupplyRegulationAddressFieldRules>;
};

export type SupplyRegulationAddressSelectOption = {
  __typename?: "SupplyRegulationAddressSelectOption";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type SupplyRegulationAddressStateProvince = {
  __typename?: "SupplyRegulationAddressStateProvince";
  inputElement: SupplyRegulationOptionalTextInputField;
  selectElement: EgdsBasicSelect;
};

export type SupplyRegulationBreadcrumbMenu = {
  __typename?: "SupplyRegulationBreadcrumbMenu";
  breadcrumbItems?: Maybe<Array<SupplyRegulationText>>;
};

export type SupplyRegulationDac = {
  __typename?: "SupplyRegulationDac";
  buttons?: Maybe<SupplyRegulationDacButtons>;
  confirmation?: Maybe<SupplyRegulationDacConfirmation>;
  header?: Maybe<SupplyRegulationDacHeader>;
  showButtonsDivide?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
};

export type SupplyRegulationDacButtons = {
  __typename?: "SupplyRegulationDacButtons";
  defaultReturnUrl: Scalars["String"];
  saveAndExitButton: EgdsButton;
  saveAndExitDialog: SupplyRegulationDacExitDialog;
  submitButton: EgdsButton;
};

export type SupplyRegulationDacConfirmation = {
  __typename?: "SupplyRegulationDacConfirmation";
  editButton: EgdsButton;
  icon: Icon;
  title: Scalars["String"];
  viewButton: EgdsButton;
};

export type SupplyRegulationDacExitDialog = {
  __typename?: "SupplyRegulationDacExitDialog";
  description: Scalars["String"];
  exitButton: EgdsButton;
  heading: Scalars["String"];
  stayButton: EgdsButton;
};

export type SupplyRegulationDacHeader = {
  __typename?: "SupplyRegulationDacHeader";
  /** @deprecated Please use descriptions */
  description?: Maybe<EgdsSpannableText>;
  descriptions?: Maybe<Array<EgdsSpannableText>>;
  pcBreadcrumbMenu?: Maybe<SupplyRegulationBreadcrumbMenu>;
  title: Scalars["String"];
};

export type SupplyRegulationDacTaxIds = {
  __typename?: "SupplyRegulationDacTaxIds";
  addButton: EgdsStandardLink;
  country: EgdsBasicSelect;
  countryOptions?: Maybe<Array<SupplyRegulationDacTaxIdsCountryOption>>;
  removeIcon: Icon;
  taxId: EgdsTextInputField;
  value: Scalars["String"];
};

export type SupplyRegulationDacTaxIdsCountryOption = {
  __typename?: "SupplyRegulationDacTaxIdsCountryOption";
  country: Scalars["String"];
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SupplyRegulationDataSharing = {
  __typename?: "SupplyRegulationDataSharing";
  buttons?: Maybe<SupplyRegulationDataSharingButtons>;
  completionView?: Maybe<SupplyRegulationDataSharingCompletionView>;
  header?: Maybe<SupplyRegulationDataSharingHeader>;
  regulatoryType?: Maybe<Scalars["String"]>;
};

export type SupplyRegulationDataSharingButtons = {
  __typename?: "SupplyRegulationDataSharingButtons";
  defaultReturnUrl: Scalars["String"];
  saveAndExitButton?: Maybe<EgdsButton>;
  saveAndExitDialog?: Maybe<SupplyRegulationDataSharingSaveDialog>;
  showButtonsDivide?: Maybe<Scalars["Boolean"]>;
  submitButton?: Maybe<EgdsButton>;
};

export type SupplyRegulationDataSharingCompletionView = {
  __typename?: "SupplyRegulationDataSharingCompletionView";
  editButton?: Maybe<EgdsButton>;
  icon?: Maybe<Icon>;
  title: Scalars["String"];
  viewButton?: Maybe<EgdsButton>;
};

export type SupplyRegulationDataSharingHeader = {
  __typename?: "SupplyRegulationDataSharingHeader";
  /** @deprecated Please use descriptions */
  description?: Maybe<EgdsSpannableText>;
  descriptions?: Maybe<Array<EgdsSpannableText>>;
  pcBreadcrumbMenu?: Maybe<SupplyRegulationBreadcrumbMenu>;
  title: Scalars["String"];
};

export type SupplyRegulationDataSharingSaveDialog = {
  __typename?: "SupplyRegulationDataSharingSaveDialog";
  description?: Maybe<Scalars["String"]>;
  exitButton?: Maybe<EgdsButton>;
  stayButton?: Maybe<EgdsButton>;
  title: Scalars["String"];
};

export type SupplyRegulationDateFormatOptions = {
  __typename?: "SupplyRegulationDateFormatOptions";
  day?: Maybe<Scalars["String"]>;
  month?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type SupplyRegulationDatePicker = {
  __typename?: "SupplyRegulationDatePicker";
  dateSelector?: Maybe<SupplyRegulationDateSelector>;
};

export type SupplyRegulationDateSelector = {
  __typename?: "SupplyRegulationDateSelector";
  allowClearDates?: Maybe<Scalars["Boolean"]>;
  autoCloseOnSelection?: Maybe<Scalars["Boolean"]>;
  clearButtonLabel?: Maybe<Scalars["String"]>;
  dateFormatOptions?: Maybe<SupplyRegulationDateFormatOptions>;
  egdsElementId?: Maybe<Scalars["String"]>;
  enablePastDates?: Maybe<Scalars["Boolean"]>;
  endDate?: Maybe<Scalars["String"]>;
  inputLabel1: Scalars["String"];
  inputLabel2?: Maybe<Scalars["String"]>;
  isSingleDateSelection?: Maybe<Scalars["Boolean"]>;
  maxDate?: Maybe<Scalars["String"]>;
  minDate?: Maybe<Scalars["String"]>;
  startDate: Scalars["String"];
  submitButtonLabel: Scalars["String"];
  validations?: Maybe<Array<SupplyRegulatoryFlowInputValidationRule>>;
};

export type SupplyRegulationDescriptionWithSheet = {
  __typename?: "SupplyRegulationDescriptionWithSheet";
  desktopSheetContent?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  mobileSheetContent?: Maybe<SupplyRegulationMobileSheetContent>;
  sheetTrigger?: Maybe<EgdsBasicTriggerPill>;
};

export type SupplyRegulationLegalAddress = {
  __typename?: "SupplyRegulationLegalAddress";
  address1?: Maybe<EgdsTextInputField>;
  address2?: Maybe<EgdsTextInputField>;
  city?: Maybe<SupplyRegulationOptionalTextInputField>;
  country?: Maybe<EgdsBasicSelect>;
  countryStates: Array<SupplyRegulationAddressCountryStates>;
  fieldRulesConfig: SupplyRegulationAddressFieldsConfig;
  postalCode?: Maybe<EgdsTextInputField>;
  stateProvince?: Maybe<SupplyRegulationAddressStateProvince>;
  value: Scalars["String"];
};

export type SupplyRegulationMobileSheetContent = {
  __typename?: "SupplyRegulationMobileSheetContent";
  description: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type SupplyRegulationOptionalTextInputField = {
  __typename?: "SupplyRegulationOptionalTextInputField";
  optionalLabel?: Maybe<Scalars["String"]>;
  optionalValidations?: Maybe<Array<EgdsInputValidation>>;
  textInputField?: Maybe<EgdsTextInputField>;
};

export type SupplyRegulationText = EgdsPlainText | EgdsStandardLink;

export type SupplyRegulatoryCheckBox = {
  __typename?: "SupplyRegulatoryCheckBox";
  checkbox: EgdsBasicCheckBox;
  descriptions?: Maybe<Array<SupplyRegulatorySettingDescription>>;
};

export type SupplyRegulatoryFlow = {
  __typename?: "SupplyRegulatoryFlow";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]>;
  dac?: Maybe<SupplyRegulationDac>;
  dataSharing?: Maybe<SupplyRegulationDataSharing>;
  navigationButtons?: Maybe<SupplyRegulatoryNavigationButtons>;
  sections: Array<SupplyRegulatoryFlowSection>;
  status?: Maybe<SupplyRegulatoryStatus>;
  title?: Maybe<SupplyRegulatoryFlowTitle>;
};

export type SupplyRegulatoryFlowInputValidationRule =
  | EgdsMaxLengthInputValidation
  | EgdsRegexInputValidation
  | EgdsRequiredInputValidation
  | SupplyRegulatoryFlowThirdPartyValidationRule;

export type SupplyRegulatoryFlowSection = {
  __typename?: "SupplyRegulatoryFlowSection";
  content?: Maybe<SupplyRegulatoryFlowSectionContent>;
  id: Scalars["String"];
  preconditions?: Maybe<Array<SupplyRegulatoryFlowSectionPrecondition>>;
  settings: Array<SupplyRegulatorySetting>;
};

export type SupplyRegulatoryFlowSectionCompletenessCondition = {
  __typename?: "SupplyRegulatoryFlowSectionCompletenessCondition";
  completed: Scalars["Boolean"];
  sectionId: Scalars["String"];
};

export type SupplyRegulatoryFlowSectionComplianceCondition = {
  __typename?: "SupplyRegulatoryFlowSectionComplianceCondition";
  compliant: Scalars["Boolean"];
  sectionId: Scalars["String"];
};

export type SupplyRegulatoryFlowSectionCondition =
  | SupplyRegulatoryFlowSectionCompletenessCondition
  | SupplyRegulatoryFlowSectionComplianceCondition
  | SupplyRegulatoryFlowSectionValidityCondition
  | SupplyRegulatoryFlowSectionValueCondition
  | SupplyRegulatoryFlowSectionVisibilityCondition;

export type SupplyRegulatoryFlowSectionContent = SupplyRegulatoryFlowVerifyAddressSectionContent;

export type SupplyRegulatoryFlowSectionPrecondition = {
  __typename?: "SupplyRegulatoryFlowSectionPrecondition";
  conditions: Array<SupplyRegulatoryFlowSectionCondition>;
};

export type SupplyRegulatoryFlowSectionValidityCondition = {
  __typename?: "SupplyRegulatoryFlowSectionValidityCondition";
  sectionId: Scalars["String"];
  valid: Scalars["Boolean"];
};

export type SupplyRegulatoryFlowSectionValueCondition = {
  __typename?: "SupplyRegulatoryFlowSectionValueCondition";
  sectionId: Scalars["String"];
  value: Array<Scalars["String"]>;
};

export type SupplyRegulatoryFlowSectionVisibilityCondition = {
  __typename?: "SupplyRegulatoryFlowSectionVisibilityCondition";
  sectionId: Scalars["String"];
  visible: Scalars["Boolean"];
};

export type SupplyRegulatoryFlowThirdPartyValidation = {
  __typename?: "SupplyRegulatoryFlowThirdPartyValidation";
  errorMessage?: Maybe<Scalars["String"]>;
};

export type SupplyRegulatoryFlowThirdPartyValidationDependentField = {
  __typename?: "SupplyRegulatoryFlowThirdPartyValidationDependentField";
  field: Scalars["String"];
  section: Scalars["String"];
};

export interface SupplyRegulatoryFlowThirdPartyValidationInput {
  field: Scalars["String"];
  options: Array<SupplyRegulatoryFlowThirdPartyValidationOption>;
  rule: Scalars["String"];
  value: Scalars["String"];
}

export interface SupplyRegulatoryFlowThirdPartyValidationOption {
  name: Scalars["String"];
  value: Scalars["String"];
}

export type SupplyRegulatoryFlowThirdPartyValidationRule = {
  __typename?: "SupplyRegulatoryFlowThirdPartyValidationRule";
  dependentFields: Array<SupplyRegulatoryFlowThirdPartyValidationDependentField>;
  errorMessage: Scalars["String"];
  field: Scalars["String"];
  rule: Scalars["String"];
};

export type SupplyRegulatoryFlowTitle = {
  __typename?: "SupplyRegulatoryFlowTitle";
  description?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyRegulatoryFlowVerifyAddressSectionContent = {
  __typename?: "SupplyRegulatoryFlowVerifyAddressSectionContent";
  additionalText?: Maybe<EgdsPlainText>;
  errorMessage?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<EgdsStandardLink>;
  refreshButton?: Maybe<EgdsButton>;
  text?: Maybe<EgdsPlainText>;
};

export type SupplyRegulatoryNavigationButtons = {
  __typename?: "SupplyRegulatoryNavigationButtons";
  nextButton: EgdsButton;
};

export type SupplyRegulatoryRadioButton = {
  __typename?: "SupplyRegulatoryRadioButton";
  button: EgdsRadioButton;
  contents?: Maybe<Array<SupplyRegulatoryRadioButtonContent>>;
};

export type SupplyRegulatoryRadioButtonContent =
  | EgdsPlainText
  | EgdsSingleDatePicker
  | SupplyRegulationDatePicker
  | SupplyRegulatoryCheckBox
  | SupplyRegulatoryRegistrationNumber;

export type SupplyRegulatoryRadioGroup = {
  __typename?: "SupplyRegulatoryRadioGroup";
  errorMessage?: Maybe<Scalars["String"]>;
  groupName: Scalars["String"];
  isOptional?: Maybe<Scalars["Boolean"]>;
  options: Array<SupplyRegulatoryRadioButton>;
  selected?: Maybe<Scalars["String"]>;
  shouldResetValue?: Maybe<Scalars["Boolean"]>;
};

export type SupplyRegulatoryRegistrationNumber = {
  __typename?: "SupplyRegulatoryRegistrationNumber";
  additionalContent?: Maybe<SupplyRegulatoryRegistrationNumberContent>;
  description: Scalars["String"];
  formatDescription?: Maybe<Scalars["String"]>;
  numberField: EgdsTextInputField;
  validations?: Maybe<Array<SupplyRegulatoryFlowInputValidationRule>>;
};

export type SupplyRegulatoryRegistrationNumberContent = SupplyRegulatoryCheckBox;

export type SupplyRegulatoryRegistrationNumberGroup = {
  __typename?: "SupplyRegulatoryRegistrationNumberGroup";
  columns?: Maybe<Scalars["Int"]>;
  groupDescription?: Maybe<Scalars["String"]>;
  groupValidationMessages?: Maybe<Array<SupplyRegulatoryValidationMessage>>;
  registrationNumbers: Array<SupplyRegulatoryRegistrationNumber>;
  valid?: Maybe<Scalars["Boolean"]>;
};

export type SupplyRegulatorySetting = {
  __typename?: "SupplyRegulatorySetting";
  descriptions?: Maybe<Array<SupplyRegulatorySettingDescription>>;
  fieldSet?: Maybe<SupplyRegulatorySettingFieldSet>;
  title: Scalars["String"];
};

export type SupplyRegulatorySettingDescription =
  | EgdsBulletedList
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | SupplyRegulationDescriptionWithSheet;

export type SupplyRegulatorySettingFieldSet =
  | EgdsBasicCheckBox
  | EgdsBasicSelect
  | EgdsSingleDatePicker
  | SupplyRegulationDacTaxIds
  | SupplyRegulationDatePicker
  | SupplyRegulationLegalAddress
  | SupplyRegulatoryRadioGroup
  | SupplyRegulatoryRegistrationNumber
  | SupplyRegulatoryRegistrationNumberGroup;

export interface SupplyRegulatorySettingInput {
  name: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
}

export type SupplyRegulatoryStatus = "COMPLIANT" | "NONE" | "NOT_COMPLIANT";

export type SupplyRegulatoryValidationMessage = {
  __typename?: "SupplyRegulatoryValidationMessage";
  content?: Maybe<Array<SupplyRegulatoryValidationMessageContent>>;
  type: Scalars["String"];
  visible?: Maybe<Scalars["Boolean"]>;
};

export type SupplyRegulatoryValidationMessageContent = EgdsIconText | EgdsStandardLink;

export type SupplyReservationDetailsComponentType =
  | "ACCESS_INSTRUCTIONS"
  | "CANCELLATION_POLICY"
  | "DAMAGE_DEPOSIT"
  | "MINI_AVAILABILITY_CALENDAR"
  | "PARTNER_NOTES"
  | "PAYMENT_DETAILS"
  | "PAYMENT_SCHEDULE"
  | "PAYMENT_SUMMARY"
  | "RENTAL_AGREEMENT"
  | "STAY_DETAILS"
  | "TRAVELER_INFO";

export type SupplyReservationModifiers = {
  __typename?: "SupplyReservationModifiers";
  errorBanner: ReservationModifiersErrorBanner;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  reservationModifiersType?: Maybe<ReservationModifiersType>;
  screens: Array<ReservationModifiersScreen>;
  subsidiaryData?: Maybe<ReservationModifiersSubsidiaryData>;
  toolbar: ReservationModifiersToolbar;
};

export type SupplyReservationStatusType =
  | "ARRIVING_TODAY"
  | "BOOKED"
  | "BOOKING_REQUEST"
  | "BOOKING_REQUEST_DECLINED"
  | "BOOKING_REQUEST_EXPIRED"
  | "CANCELED"
  | "CANCELLATION_REQUEST"
  | "CURRENT_STAY"
  | "INQUIRY"
  | "POST_STAY"
  | "PRE_APPROVAL_EXPIRED"
  | "PRE_APPROVED"
  | "REQUEST_WITHDRAWN"
  | "UNKNOWN";

export interface SupplyReservationsAccessIdentifierInput {
  id: Scalars["String"];
  idType: Scalars["String"];
}

export type SupplyReservationsAccessInstructionPhoto = {
  __typename?: "SupplyReservationsAccessInstructionPhoto";
  imageId: Scalars["UUID"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  photoUrl: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export interface SupplyReservationsAccessInstructionPhotoInput {
  imageId: Scalars["UUID"];
  photoUrl: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
}

export type SupplyReservationsAccessInstructions = {
  __typename?: "SupplyReservationsAccessInstructions";
  accessCodeSection?: Maybe<SupplyReservationsAccessInstructionsSection>;
  accessTypeSection?: Maybe<SupplyReservationsAccessInstructionsSection>;
  editAction: SupplyReservationsActions;
  /** @deprecated use gallerySection, photos are located in gallerySection.photos */
  gallery?: Maybe<Array<Maybe<SupplyReservationsAccessInstructionPhoto>>>;
  gallerySection?: Maybe<SupplyReservationsAccessInstructionsGallery>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  otherSections?: Maybe<Array<Maybe<SupplyReservationsAccessInstructionsSection>>>;
  titleSection: SupplyReservationsAccessInstructionsSection;
};

export type SupplyReservationsAccessInstructionsGallery = {
  __typename?: "SupplyReservationsAccessInstructionsGallery";
  imageCarouselSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  liveAnnounce: Scalars["String"];
  navIconLabel: Scalars["String"];
  nextLabel: Scalars["String"];
  photos?: Maybe<Array<Maybe<SupplyReservationsAccessInstructionPhoto>>>;
  prevLabel: Scalars["String"];
  title: Scalars["String"];
};

export interface SupplyReservationsAccessInstructionsInput {
  accessCode?: InputMaybe<Scalars["String"]>;
  accessType: SupplyReservationsAccessTypes;
  gallery?: InputMaybe<Array<InputMaybe<SupplyReservationsAccessInstructionPhotoInput>>>;
  instructions: Scalars["String"];
}

export type SupplyReservationsAccessInstructionsSection = {
  __typename?: "SupplyReservationsAccessInstructionsSection";
  action?: Maybe<SupplyReservationsAction>;
  collapseLabel?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  expandLabel?: Maybe<Scalars["String"]>;
  onCollapsedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onExpandedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsAccessTypes = "DOORMAN" | "KEYPAD" | "LOCKBOX" | "OTHER" | "PICKUP" | "SMART_LOCK";

export type SupplyReservationsAction = {
  __typename?: "SupplyReservationsAction";
  accessibilityLabel: Scalars["String"];
  actionType?: Maybe<SupplyReservationsActionType>;
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  disabled?: Maybe<Scalars["Boolean"]>;
  icon?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  linkAction?: Maybe<UiLinkAction>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  selectedAnalytics?: Maybe<SupplyReservationsClickstreamData>;
};

export type SupplyReservationsActionStation = {
  __typename?: "SupplyReservationsActionStation";
  actionStationPresented?: Maybe<Scalars["AnalyticsPayload"]>;
  button: UiPrimaryButton;
  countDown: Scalars["Boolean"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  status: EgdsStylizedText;
  staySummary: Scalars["String"];
  timer?: Maybe<SupplyReservationsTimer>;
};

export type SupplyReservationsActionType =
  | "ACCESS_INSTRUCTIONS_ADD"
  | "ACCESS_INSTRUCTIONS_EDIT"
  | "AUTO_CANCEL_WAIVERS_CANCEL_CHANGES"
  | "AUTO_CANCEL_WAIVERS_SAVE_CHANGES"
  | "DAMAGE_PROTECTION_REFUND_DEPOSIT"
  | "DAMAGE_PROTECTION_REPORT_DAMAGE"
  | "DAMAGE_PROTECTION_REPORT_DAMAGE_EXTERNAL"
  | "DAMAGE_PROTECTION_SEE_DAMAGE_REPORT"
  | "DAMAGE_PROTECTION_SEE_POLICY"
  | "EVC_MANAGE_CARD_MORE_INFO"
  | "EVC_MANAGE_CARD_SEARCH"
  | "EVC_MANAGE_FEEDBACK"
  | "EVC_MANAGE_RESERVATION_DETAILS"
  | "GUEST_INFO_EMAIL"
  | "GUEST_INFO_PHONE"
  | "GUEST_INFO_VIEW_PROFILE"
  | "HEADER_ARCHIVE"
  | "HEADER_DETAILS"
  | "HEADER_FEEDBACK"
  | "HEADER_HELP_CENTER"
  | "HEADER_MARK_AS_READ"
  | "HEADER_MARK_AS_SPAM"
  | "HEADER_MARK_AS_UNREAD"
  | "HEADER_PRINT"
  | "HEADER_SHARE"
  | "HEADER_UNARCHIVE"
  | "INVOICE_STATUS_ACTION"
  | "NOTES_ADD"
  | "NOTES_CANCEL"
  | "NOTES_EDIT"
  | "NOTES_SAVE"
  | "PAYMENT_REQUEST_STATUS_ACTION"
  | "PAYMENT_SCHEDULE_ADD_EXTRA_CHARGE"
  | "PAYMENT_SCHEDULE_CANCEL_PAYMENT_REQUEST"
  | "PAYMENT_SCHEDULE_EDIT_PAYMENT_SCHEDULE"
  | "PAYMENT_SCHEDULE_MARK_PAID"
  | "PAYMENT_SCHEDULE_RESEND_REQUEST"
  | "PAYMENT_SCHEDULE_SEND_A_REFUND"
  | "PAYMENT_SUMMARY_VIEW"
  | "RENTAL_AGREEMENT_VIEW"
  | "RESERVATION_MODIFIERS_BACK"
  | "RESERVATION_MODIFIERS_CLOSE"
  | "RESERVATION_MODIFIERS_NEXT"
  | "RESERVATION_MODIFIERS_SAVE_CANCEL"
  | "RESERVATION_MODIFIERS_SAVE_CHANGE_DATES"
  | "RESERVATION_MODIFIERS_SAVE_GUEST_SATISFACTION_REFUND"
  | "RESERVATION_MODIFIERS_SAVE_NO_SHOW"
  | "STAY_DETAILS_ACCEPT"
  | "STAY_DETAILS_ADD_REMOVE_GUESTS"
  | "STAY_DETAILS_CANCEL_PRE_APPROVAL"
  | "STAY_DETAILS_CANCEL_RESERVATION"
  | "STAY_DETAILS_CHANGE_DATES"
  | "STAY_DETAILS_CHANGE_TIME"
  | "STAY_DETAILS_CONFIRM_CANCELLATION"
  | "STAY_DETAILS_DECLINE"
  | "STAY_DETAILS_EDIT_DATES"
  | "STAY_DETAILS_EDIT_QUOTE"
  | "STAY_DETAILS_EDIT_RESERVATION"
  | "STAY_DETAILS_SEND_PRE_APPROVAL";

export type SupplyReservationsActions = {
  __typename?: "SupplyReservationsActions";
  actions: Array<SupplyReservationsAction>;
  actionsPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  grouping?: Maybe<Array<Scalars["Int"]>>;
  label?: Maybe<Scalars["String"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  renderHint: SupplyReservationsRenderHint;
};

export type SupplyReservationsBadge = {
  __typename?: "SupplyReservationsBadge";
  accessibilityLabel: Scalars["String"];
  label: Scalars["String"];
  theme: Scalars["String"];
};

export type SupplyReservationsCancellationPolicy = {
  __typename?: "SupplyReservationsCancellationPolicy";
  expando: SupplyReservationsCancellationPolicyDetailsExpandoLink;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export type SupplyReservationsCancellationPolicyDetailsExpandoLink = {
  __typename?: "SupplyReservationsCancellationPolicyDetailsExpandoLink";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedContent?: Maybe<SupplyReservationsCancellationPolicyDetailsSection>;
  expandedLabel: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsCancellationPolicyDetailsSection = {
  __typename?: "SupplyReservationsCancellationPolicyDetailsSection";
  policyName: EgdsStandardLink;
  policyNameClickedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  policyTip?: Maybe<EgdsIconText>;
  rules: Array<SupplyReservationsCancellationPolicyRule>;
};

export type SupplyReservationsCancellationPolicyRule = {
  __typename?: "SupplyReservationsCancellationPolicyRule";
  primary: Scalars["String"];
  secondary: Scalars["String"];
};

export type SupplyReservationsClickstreamContext = {
  __typename?: "SupplyReservationsClickstreamContext";
  cancellation_fee?: Maybe<Scalars["String"]>;
  cancellation_reason?: Maybe<Scalars["String"]>;
  error?: Maybe<ClickstreamError>;
  impression_duration?: Maybe<Scalars["Int"]>;
  no_show_cancellation_fee?: Maybe<Scalars["String"]>;
  trip_state?: Maybe<Scalars["String"]>;
  workflow?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsClickstreamData = {
  __typename?: "SupplyReservationsClickstreamData";
  additional_context?: Maybe<SupplyReservationsClickstreamContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyReservationsDamageDeposit = {
  __typename?: "SupplyReservationsDamageDeposit";
  actions?: Maybe<SupplyReservationsActions>;
  defaultExpanded: Scalars["Boolean"];
  listItems: Array<Maybe<SupplyReservationsDamageDepositListItem>>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  secondaryActions?: Maybe<SupplyReservationsActions>;
  secondaryListItems?: Maybe<Array<Maybe<SupplyReservationsDamageDepositListItem>>>;
  title: Scalars["String"];
};

export type SupplyReservationsDamageDepositListItem = {
  __typename?: "SupplyReservationsDamageDepositListItem";
  primary: EgdsStylizedText;
  secondary?: Maybe<EgdsStylizedText>;
  subtext?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsDetailsContent = {
  __typename?: "SupplyReservationsDetailsContent";
  backToConversationButton?: Maybe<EgdsStandardLink>;
  reservationDetailsCapturedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  reservationDetailsViewedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  supplyReservationsAccessInstructions?: Maybe<SupplyReservationsAccessInstructions>;
  supplyReservationsCancellationPolicy?: Maybe<SupplyReservationsCancellationPolicy>;
  supplyReservationsDamageDeposit?: Maybe<SupplyReservationsDamageDeposit>;
  supplyReservationsGuestInfo?: Maybe<SupplyReservationsGuestInfo>;
  supplyReservationsInvoiceStatuses?: Maybe<SupplyReservationsInvoiceStatuses>;
  supplyReservationsMiniAvailabilityCalendar?: Maybe<SupplyReservationsMiniAvailabilityCalendar>;
  supplyReservationsPartnerNotes?: Maybe<SupplyReservationsPartnerNotes>;
  supplyReservationsPaymentDetails?: Maybe<SupplyReservationsPaymentDetails>;
  supplyReservationsPaymentRequestStatuses?: Maybe<SupplyReservationsPaymentRequestStatuses>;
  supplyReservationsPaymentSchedule?: Maybe<SupplyReservationsPaymentSchedule>;
  supplyReservationsPaymentSummary?: Maybe<SupplyReservationsPaymentSummary>;
  supplyReservationsRentalAgreement?: Maybe<SupplyReservationsRentalAgreement>;
  supplyReservationsStayDetails?: Maybe<SupplyReservationsStayDetails>;
  supplyReservationsTravelerInfo?: Maybe<SupplyReservationsTravelerInfo>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerActivityTable = {
  __typename?: "SupplyReservationsEvcManagerActivityTable";
  additionalComputationRows?: Maybe<Array<SupplyReservationsEvcManagerCardActivityAdditionalComputationRows>>;
  cardActivityDataList?: Maybe<Array<SupplyReservationsEvcManagerCardActivity>>;
  columnHeaders?: Maybe<Array<SupplyReservationsEvcManagerTableListColumnHeader>>;
  emptyState?: Maybe<SupplyReservationsEvcManagerEmptyState>;
};

export type SupplyReservationsEvcManagerCardActivity = {
  __typename?: "SupplyReservationsEvcManagerCardActivity";
  amount?: Maybe<Scalars["String"]>;
  authCode?: Maybe<Scalars["String"]>;
  authDate?: Maybe<Scalars["String"]>;
  postedDate?: Maybe<Scalars["String"]>;
  statusOrDeclineReason?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerCardActivityAdditionalComputationRows = {
  __typename?: "SupplyReservationsEvcManagerCardActivityAdditionalComputationRows";
  label?: Maybe<Scalars["String"]>;
  strikeThroughValue?: Maybe<Scalars["Boolean"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerCardDataItemCardDetailsObject = {
  __typename?: "SupplyReservationsEvcManagerCardDataItemCardDetailsObject";
  title: Scalars["String"];
  value: Scalars["String"];
};

export type SupplyReservationsEvcManagerCardInfo = {
  __typename?: "SupplyReservationsEvcManagerCardInfo";
  activityTable?: Maybe<SupplyReservationsEvcManagerActivityTable>;
  cardDetails: SupplyReservationsEvcManagerCardInfoCardDetails;
  chargeBeforeDate?: Maybe<Scalars["String"]>;
  checkInDate?: Maybe<Scalars["String"]>;
  guestName: Scalars["String"];
  reasonToRefund?: Maybe<Scalars["String"]>;
  remainingBalance?: Maybe<Scalars["String"]>;
  reservationId: SupplyReservationsAction;
  totalToCharge?: Maybe<Scalars["String"]>;
  totalToRefund?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerCardInfoCardDetails = {
  __typename?: "SupplyReservationsEvcManagerCardInfoCardDetails";
  details: Array<SupplyReservationsEvcManagerCardDataItemCardDetailsObject>;
};

export type SupplyReservationsEvcManagerContent = {
  __typename?: "SupplyReservationsEvcManagerContent";
  heading?: Maybe<SupplyReservationsEvcManagerHeading>;
  mainContent?: Maybe<SupplyReservationsEvcManagerMainContent>;
};

export type SupplyReservationsEvcManagerEmptyState = {
  __typename?: "SupplyReservationsEvcManagerEmptyState";
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerHeading = {
  __typename?: "SupplyReservationsEvcManagerHeading";
  feedbackLink?: Maybe<SupplyReservationsAction>;
  subTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerLookupView = {
  __typename?: "SupplyReservationsEvcManagerLookupView";
  errorBanner: SupplyReservationsEvcManagerLookupViewInfoBanner;
  infoBanner?: Maybe<SupplyReservationsEvcManagerLookupViewInfoBanner>;
  searchFormSection: SupplyReservationsEvcManagerLookupViewSearchForm;
  tabTitle: Scalars["String"];
};

export type SupplyReservationsEvcManagerLookupViewInfoBanner = {
  __typename?: "SupplyReservationsEvcManagerLookupViewInfoBanner";
  icon?: Maybe<Icon>;
  subTitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsEvcManagerLookupViewSearchForm = {
  __typename?: "SupplyReservationsEvcManagerLookupViewSearchForm";
  datePicker?: Maybe<EgdsDateRangePicker>;
  heading?: Maybe<SupplyReservationsEvcManagerLookupViewSearchHeading>;
  inputForms?: Maybe<SupplyReservationsEvcManagerLookupViewSearchInputs>;
  searchAction?: Maybe<SupplyReservationsAction>;
};

export type SupplyReservationsEvcManagerLookupViewSearchHeading = {
  __typename?: "SupplyReservationsEvcManagerLookupViewSearchHeading";
  subTitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsEvcManagerLookupViewSearchInputs = {
  __typename?: "SupplyReservationsEvcManagerLookupViewSearchInputs";
  separatorText?: Maybe<Scalars["String"]>;
  textInputFields?: Maybe<Array<EgdsTextInputField>>;
};

export type SupplyReservationsEvcManagerMainContent = {
  __typename?: "SupplyReservationsEvcManagerMainContent";
  defaultView?: Maybe<SupplyReservationsEvcManagerTabType>;
  tabViews: Array<SupplyReservationsEvcManagerView>;
};

export type SupplyReservationsEvcManagerManageView = {
  __typename?: "SupplyReservationsEvcManagerManageView";
  cardsReadyToChargeSection?: Maybe<SupplyReservationsEvcManagerSearchResults>;
  cardsToRefundSection?: Maybe<SupplyReservationsEvcManagerSearchResults>;
  tabBadge?: Maybe<SupplyReservationsBadge>;
  tabTitle: Scalars["String"];
};

export type SupplyReservationsEvcManagerSearchResults = {
  __typename?: "SupplyReservationsEvcManagerSearchResults";
  addressInfoData: Scalars["String"];
  addressInfoTitle: Scalars["String"];
  badge?: Maybe<SupplyReservationsBadge>;
  cardDataTable?: Maybe<SupplyReservationsEvcManagerTable>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyReservationsEvcManagerTabType = "EVC_LOOKUP" | "EVC_MANAGE";

export type SupplyReservationsEvcManagerTable = {
  __typename?: "SupplyReservationsEvcManagerTable";
  cardDataList?: Maybe<Array<SupplyReservationsEvcManagerCardInfo>>;
  columnHeaders?: Maybe<Array<SupplyReservationsEvcManagerTableListColumnHeader>>;
  emptyState?: Maybe<SupplyReservationsEvcManagerEmptyState>;
  errorBanner?: Maybe<SupplyReservationsEvcManagerLookupViewInfoBanner>;
  pagination?: Maybe<SupplyReservationsTablePagination>;
};

export type SupplyReservationsEvcManagerTableListColumnHeader = {
  __typename?: "SupplyReservationsEvcManagerTableListColumnHeader";
  label?: Maybe<Scalars["String"]>;
  sortingIcon?: Maybe<Icon>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
  value?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsEvcManagerTableType = "EVC_CARDS_TO_CHARGE" | "EVC_CARDS_TO_REFUND";

export type SupplyReservationsEvcManagerView =
  | SupplyReservationsEvcManagerLookupView
  | SupplyReservationsEvcManagerManageView;

export interface SupplyReservationsEvcSearchInput {
  checkInDate: Scalars["String"];
  inputType: SupplyReservationsEvcSearchInputType;
  textInput: Scalars["String"];
}

export type SupplyReservationsEvcSearchInputType = "EVC_NUMBER" | "RESERVATION_ID";

export interface SupplyReservationsEvcSearchPageInput {
  currPageIdentifier?: InputMaybe<SupplyReservationsEvcTablePaginationPageInfo>;
  pageNumber?: InputMaybe<Scalars["Int"]>;
  pageRequestedIdentifier?: InputMaybe<SupplyReservationsEvcTablePaginationPageInfo>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  prevPageIdentifiers?: InputMaybe<Array<InputMaybe<SupplyReservationsEvcTablePaginationPageInfo>>>;
}

export interface SupplyReservationsEvcTablePaginationPageInfo {
  pageIdentifier?: InputMaybe<Scalars["String"]>;
  pageNum?: InputMaybe<Scalars["Int"]>;
}

export type SupplyReservationsGenericTrustSignal = {
  __typename?: "SupplyReservationsGenericTrustSignal";
  icon: Icon;
  label: Scalars["String"];
  sublabel?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsGuestInfo = {
  __typename?: "SupplyReservationsGuestInfo";
  bottomButtons: SupplyReservationsActions;
  defaultExpanded: Scalars["Boolean"];
  hideProfile?: Maybe<Scalars["Boolean"]>;
  listItems: Array<SupplyReservationsGuestInfoListItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  phoneNumberCopiedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  quickActionButtons?: Maybe<SupplyReservationsActions>;
  title?: Maybe<Scalars["String"]>;
  travelerProfile?: Maybe<SupplyReservationsTravelerProfile>;
};

export type SupplyReservationsGuestInfoListItem = EgdsTextIconLinkListItem | EgdsTextIconListItem;

export type SupplyReservationsHeader = {
  __typename?: "SupplyReservationsHeader";
  actionStation?: Maybe<SupplyReservationsActionStation>;
  backArrowSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  backUrl?: Maybe<HttpUri>;
  badges?: Maybe<Array<SupplyReservationsHeaderBadge>>;
  detailsLink?: Maybe<SupplyReservationsAction>;
  headerPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  menuActions?: Maybe<SupplyReservationsActions>;
  quickActions?: Maybe<SupplyReservationsActions>;
  subtitle?: Maybe<SupplyReservationsHeaderStatus>;
  tabs?: Maybe<Array<SupplyReservationsHeaderTab>>;
  title: Scalars["String"];
};

export type SupplyReservationsHeaderBadge = {
  __typename?: "SupplyReservationsHeaderBadge";
  accessibilityLabel: Scalars["String"];
  label: Scalars["String"];
  theme: Scalars["String"];
};

export type SupplyReservationsHeaderStatus = {
  __typename?: "SupplyReservationsHeaderStatus";
  status?: Maybe<Scalars["String"]>;
  statusTheme?: Maybe<TextTheme>;
  text: Scalars["String"];
};

export type SupplyReservationsHeaderTab = {
  __typename?: "SupplyReservationsHeaderTab";
  accessibilityLabel: Scalars["String"];
  actions: SupplyReservationsActions;
  identifier: Scalars["String"];
  label: Scalars["String"];
};

export interface SupplyReservationsInteractionAddChatMessageRequest {
  attachmentIds?: InputMaybe<Array<Scalars["String"]>>;
  interactionId: Scalars["String"];
  reply?: InputMaybe<Scalars["String"]>;
}

export type SupplyReservationsInteractionAvatar = {
  __typename?: "SupplyReservationsInteractionAvatar";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsInteractionChatDocument = {
  __typename?: "SupplyReservationsInteractionChatDocument";
  attachmentUrl: Scalars["String"];
  description: EgdsStylizedText;
  fileName: EgdsStylizedText;
  icon: Icon;
};

export type SupplyReservationsInteractionChatImageSize = "LARGE" | "SMALL";

export type SupplyReservationsInteractionChatMediaGroup = {
  __typename?: "SupplyReservationsInteractionChatMediaGroup";
  images: Array<SupplyReservationsInteractionChatMediaImage>;
  size: SupplyReservationsInteractionChatImageSize;
};

export type SupplyReservationsInteractionChatMediaImage = {
  __typename?: "SupplyReservationsInteractionChatMediaImage";
  attachmentUrl: Scalars["String"];
  image: Image;
};

export type SupplyReservationsInteractionChatMessage =
  | SupplyReservationsInteractionChatDocument
  | SupplyReservationsInteractionChatMediaGroup
  | SupplyReservationsInteractionChatTextMessage;

export type SupplyReservationsInteractionChatTextMessage = {
  __typename?: "SupplyReservationsInteractionChatTextMessage";
  primary: EgdsStylizedText;
};

export type SupplyReservationsInteractionCoachmark = {
  __typename?: "SupplyReservationsInteractionCoachmark";
  card: SupplyReservationsInteractionCoachmarkCard;
  icon: Icon;
  text: Scalars["String"];
};

export type SupplyReservationsInteractionCoachmarkCard = {
  __typename?: "SupplyReservationsInteractionCoachmarkCard";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  graphic: Icon;
  heading: Scalars["String"];
  link: Scalars["String"];
  message: Scalars["String"];
  rightIcon: Icon;
};

export type SupplyReservationsInteractionEmptyState = {
  __typename?: "SupplyReservationsInteractionEmptyState";
  action?: Maybe<SupplyReservationsInteractionEmptyStateAction>;
  columnHeaders?: Maybe<Array<SupplyReservationsInteractionListColumn>>;
  description: EgdsStylizedText;
  descriptions: Array<EgdsStylizedText>;
  error?: Maybe<SupplyReservationsInteractionErrorState>;
  icon?: Maybe<Icon>;
  image?: Maybe<Scalars["URL"]>;
  reservationsCalendarSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: EgdsStylizedText;
};

export type SupplyReservationsInteractionEmptyStateAction = EgdsStandardLink | UiSecondaryButton;

export type SupplyReservationsInteractionErrorState = {
  __typename?: "SupplyReservationsInteractionErrorState";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  button: UiPrimaryButton;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsInteractionList = {
  __typename?: "SupplyReservationsInteractionList";
  actions: SupplyReservationsInteractionListActions;
  columnHeaders: Array<SupplyReservationsInteractionListColumn>;
  interactionList: Array<SupplyReservationsInteractionListItemType>;
  pagination: SupplyReservationsInteractionsPagination;
};

export type SupplyReservationsInteractionListAction = {
  __typename?: "SupplyReservationsInteractionListAction";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  button: UiTertiaryButton;
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  listItemAction: SupplyReservationsInteractionListItemAction;
};

export type SupplyReservationsInteractionListActionDialog = {
  __typename?: "SupplyReservationsInteractionListActionDialog";
  body: Array<Scalars["String"]>;
  cancelAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  confirmAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  /** @deprecated Use footerActions for future changes */
  footer: EgdsDialogFooter;
  footerActions: SupplyReservationsInteractionListActionDialogFooterActions;
  presentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  /** @deprecated Use cancelAnalytics for future changes */
  spamCancelAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  /** @deprecated Use confirmAnalytics for future changes */
  spamConfirmAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  toolbar: EgdsDialogToolbar;
};

export type SupplyReservationsInteractionListActionDialogFooterAction = {
  __typename?: "SupplyReservationsInteractionListActionDialogFooterAction";
  actionType: SupplyReservationsInteractionListActionDialogFooterActionType;
  disabled: Scalars["Boolean"];
  text: Scalars["String"];
  /** @deprecated Use actionType for future changes */
  type: Scalars["String"];
};

export type SupplyReservationsInteractionListActionDialogFooterActionType = "PRIMARY" | "TERTIARY";

export type SupplyReservationsInteractionListActionDialogFooterActions = {
  __typename?: "SupplyReservationsInteractionListActionDialogFooterActions";
  cancelAction: SupplyReservationsInteractionListActionDialogFooterAction;
  primaryAction: SupplyReservationsInteractionListActionDialogFooterAction;
};

export type SupplyReservationsInteractionListActions = {
  __typename?: "SupplyReservationsInteractionListActions";
  actions: Array<SupplyReservationsInteractionListAction>;
  allSelectedText: Scalars["String"];
  numberOfSelectedText: Scalars["String"];
  /** @deprecated Use numberOfSelectedText */
  numberSelectedText: Scalars["String"];
  swipeActions: Array<SupplyReservationsInteractionListSwipeAction>;
};

export type SupplyReservationsInteractionListColumn = {
  __typename?: "SupplyReservationsInteractionListColumn";
  checkbox?: Maybe<EgdsBasicCheckBox>;
  horizontalAlign?: Maybe<SupplyReservationsInteractionListColumnHorizontalAlign>;
  label: Scalars["String"];
  sortingIcon?: Maybe<Icon>;
  value?: Maybe<Scalars["String"]>;
  verticalAlign?: Maybe<SupplyReservationsInteractionListColumnVerticalAlign>;
};

export type SupplyReservationsInteractionListColumnHorizontalAlign =
  | "CENTER"
  | "END"
  | "JUSTIFY"
  | "JUSTIFY_ALL"
  | "LEFT"
  | "MATCH_PARENT"
  | "RIGHT"
  | "START";

export type SupplyReservationsInteractionListColumnVerticalAlign =
  | "BASELINE"
  | "BOTTOM"
  | "MIDDLE"
  | "SUB"
  | "SUPER"
  | "TEXT_BOTTOM"
  | "TEXT_TOP"
  | "TOP";

export type SupplyReservationsInteractionListItemAction = "ARCHIVE" | "READ" | "SPAM" | "UNARCHIVE" | "UNREAD";

export type SupplyReservationsInteractionListItemHobbyist = SupplyReservationsInteractionListItemShared & {
  __typename?: "SupplyReservationsInteractionListItemHobbyist";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  arriving?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  avatar?: Maybe<SupplyReservationsInteractionAvatar>;
  callButton?: Maybe<UiSecondaryButton>;
  callButtonPresented?: Maybe<Scalars["AnalyticsPayload"]>;
  callButtonSelected?: Maybe<Scalars["AnalyticsPayload"]>;
  countdownTime?: Maybe<SupplyReservationsInteractionListItemStyledCountdown>;
  countupTime?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  detailLink?: Maybe<HttpUri>;
  firstName: SupplyReservationsInteractionListItemStyledString;
  guestCount?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  guestCountsWithStayDates: SupplyReservationsInteractionListItemStyledString;
  guestName: SupplyReservationsInteractionListItemStyledString;
  headline?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  interactionId: Scalars["String"];
  itemPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  lastMessage?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  lastName: SupplyReservationsInteractionListItemStyledString;
  /** @deprecated Use lastMessage for future changes */
  message: SupplyReservationsInteractionListItemStyledString;
  messageButton?: Maybe<UiSecondaryButton>;
  messageButtonPresented?: Maybe<Scalars["AnalyticsPayload"]>;
  messageButtonSelected?: Maybe<Scalars["AnalyticsPayload"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  propertyDetails?: Maybe<Scalars["String"]>;
  receivedTime: SupplyReservationsInteractionListItemStyledString;
  reservationReferenceNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  status: SupplyReservationsInteractionListItemStyledString;
  stayDates: SupplyReservationsInteractionListItemStyledString;
  stayDatesShort: SupplyReservationsInteractionListItemStyledString;
  unread?: Maybe<EgdsBadgeNotification>;
  validActions: Array<SupplyReservationsInteractionListItemAction>;
};

export type SupplyReservationsInteractionListItemOptimizer = SupplyReservationsInteractionListItemShared & {
  __typename?: "SupplyReservationsInteractionListItemOptimizer";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  arriving?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  avatar?: Maybe<SupplyReservationsInteractionAvatar>;
  confirmationNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  detailLink?: Maybe<HttpUri>;
  firstName: SupplyReservationsInteractionListItemStyledString;
  interactionId: Scalars["String"];
  itemPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  lastName: SupplyReservationsInteractionListItemStyledString;
  paymentAmount?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  receivedTime?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  reservationReferenceNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  roomType?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  stayDates: SupplyReservationsInteractionListItemStyledString;
  stayDatesShort: SupplyReservationsInteractionListItemStyledString;
  unread?: Maybe<EgdsBadgeNotification>;
  validActions: Array<SupplyReservationsInteractionListItemAction>;
};

export interface SupplyReservationsInteractionListItemShared {
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  arriving?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  avatar?: Maybe<SupplyReservationsInteractionAvatar>;
  detailLink?: Maybe<HttpUri>;
  firstName: SupplyReservationsInteractionListItemStyledString;
  interactionId: Scalars["String"];
  itemPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  lastName: SupplyReservationsInteractionListItemStyledString;
  receivedTime?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  reservationReferenceNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  stayDates: SupplyReservationsInteractionListItemStyledString;
  stayDatesShort: SupplyReservationsInteractionListItemStyledString;
  unread?: Maybe<EgdsBadgeNotification>;
  validActions: Array<SupplyReservationsInteractionListItemAction>;
}

export interface SupplyReservationsInteractionListItemStyledBase {
  hint: Scalars["String"];
  icon?: Maybe<Icon>;
  text: EgdsStylizedText;
}

export type SupplyReservationsInteractionListItemStyledCountdown = SupplyReservationsInteractionListItemStyledBase & {
  __typename?: "SupplyReservationsInteractionListItemStyledCountdown";
  expiresOn: Scalars["String"];
  hint: Scalars["String"];
  icon?: Maybe<Icon>;
  text: EgdsStylizedText;
};

export type SupplyReservationsInteractionListItemStyledString = SupplyReservationsInteractionListItemStyledBase & {
  __typename?: "SupplyReservationsInteractionListItemStyledString";
  backgroundColor?: Maybe<Scalars["String"]>;
  hint: Scalars["String"];
  icon?: Maybe<Icon>;
  label?: Maybe<EgdsStylizedText>;
  text: EgdsStylizedText;
};

export type SupplyReservationsInteractionListItemType =
  | SupplyReservationsInteractionListItemHobbyist
  | SupplyReservationsInteractionListItemOptimizer;

export type SupplyReservationsInteractionListSwipeAction = {
  __typename?: "SupplyReservationsInteractionListSwipeAction";
  action: SupplyReservationsInteractionListItemAction;
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  backgroundColor: Scalars["String"];
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  displayEdge: SupplyReservationsInteractionListSwipeActionEdge;
  icon: Icon;
  text: Scalars["String"];
};

export type SupplyReservationsInteractionListSwipeActionEdge = "LEADING" | "TRAILING";

export type SupplyReservationsInteractionMessage =
  | SupplyReservationsInteractionCoachmark
  | SupplyReservationsInteractionMessageGroup
  | SupplyReservationsInteractionSessionMarker
  | SupplyReservationsInteractionSystemMessage;

export type SupplyReservationsInteractionMessageGroup = {
  __typename?: "SupplyReservationsInteractionMessageGroup";
  chatMessages: Array<SupplyReservationsInteractionChatMessage>;
  footer: SupplyReservationsInteractionMessageGroupFooter;
  header: EgdsStylizedText;
  mode: SupplyReservationsInteractionMessageMode;
};

export type SupplyReservationsInteractionMessageGroupFooter = {
  __typename?: "SupplyReservationsInteractionMessageGroupFooter";
  icon?: Maybe<Icon>;
  link?: Maybe<EgdsStandardLink>;
  status?: Maybe<EgdsStylizedText>;
  text?: Maybe<EgdsStylizedText>;
  timeStamp: EgdsStylizedText;
};

export type SupplyReservationsInteractionMessageMode = "INCOMING" | "OUTGOING";

export interface SupplyReservationsInteractionMessagesClientData {
  timeZone: Scalars["String"];
}

export type SupplyReservationsInteractionMessagesDetailsContent =
  | SupplyReservationsInteractionMessagesDetailsContentList
  | SupplyReservationsInteractionMessagesDetailsErrorState;

export type SupplyReservationsInteractionMessagesDetailsContentList = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsContentList";
  entries: Array<SupplyReservationsInteractionMessagesDetailsEntry>;
};

export type SupplyReservationsInteractionMessagesDetailsEntry =
  | EgdsStandardLink
  | SupplyReservationsInteractionMessagesDetailsEntryLineItem
  | SupplyReservationsInteractionMessagesDetailsEntrySpacing;

export type SupplyReservationsInteractionMessagesDetailsEntryLineItem = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsEntryLineItem";
  amount?: Maybe<EgdsStylizedText>;
  icon?: Maybe<Icon>;
  label: EgdsStylizedText;
  rating?: Maybe<EgdsRating>;
  sublabel?: Maybe<EgdsStylizedText>;
};

export type SupplyReservationsInteractionMessagesDetailsEntrySpacing = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsEntrySpacing";
  separatorLine: Scalars["Boolean"];
};

export type SupplyReservationsInteractionMessagesDetailsErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"];
  icon: Icon;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesErrorState";
  button: UiSecondaryButton;
  description: EgdsStylizedText;
  icon: Icon;
  title: EgdsStylizedText;
};

export type SupplyReservationsInteractionMessagesGraphqlErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesGraphqlErrorState";
  button?: Maybe<UiSecondaryButton>;
  description: Scalars["String"];
  icon: Icon;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesList = {
  __typename?: "SupplyReservationsInteractionMessagesList";
  conversationMarker: UiMessagingCard;
  messages?: Maybe<Array<SupplyReservationsInteractionMessage>>;
};

export type SupplyReservationsInteractionMessagesReply = {
  __typename?: "SupplyReservationsInteractionMessagesReply";
  content: SupplyReservationsInteractionMessagesReplyContent;
  pendingMessage: SupplyReservationsInteractionReplyPendingMessage;
};

export type SupplyReservationsInteractionMessagesReplyAttachments = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachments";
  content: SupplyReservationsInteractionMessagesReplyAttachmentsContainer;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsCategory = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsCategory";
  attachments?: Maybe<Array<SupplyReservationsInteractionMessagesReplyAttachmentsEntryItem>>;
  label: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsContainer =
  | SupplyReservationsInteractionMessagesReplyAttachmentsContent
  | SupplyReservationsInteractionMessagesReplyAttachmentsEmptyState
  | SupplyReservationsInteractionMessagesReplyAttachmentsErrorState;

export type SupplyReservationsInteractionMessagesReplyAttachmentsContent = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsContent";
  acceptFileTypes: Array<Maybe<Scalars["String"]>>;
  actionButtonText: Scalars["String"];
  attachmentsServiceEndpoint: Scalars["String"];
  categories: Array<SupplyReservationsInteractionMessagesReplyAttachmentsCategory>;
  errorBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner;
  fileLimits: SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits;
  newFileSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  uploadFileButton: UiSecondaryButton;
  uploadFileSelected?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsEmptyState = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsEmptyState";
  description: Scalars["String"];
  icon: Icon;
  title: Scalars["String"];
  uploadFileButton: UiSecondaryButton;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsEntryItem = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsEntryItem";
  id: Scalars["String"];
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  subText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner";
  approachingLimitPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  errorTexts: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerTexts;
  limitReachedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  serverErrorBanner?: Maybe<SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent>;
  uploadFailedPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  uploadSuccessPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent";
  description: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerTexts = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerTexts";
  /** @deprecated Replaced by approachingFileLimitSingularBanner and approachingFileLimitPluralBanner */
  approachingFileLimitBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  approachingFileLimitPluralBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  approachingFileLimitSingularBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  attachmentsLimitReachedBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  fileTooLargeBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  uploadFailureBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"];
  errorEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  icon: Icon;
  reloadEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits";
  reachingLimit: Scalars["Int"];
  uploadLimit: Scalars["Int"];
};

export type SupplyReservationsInteractionMessagesReplyContent = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContent";
  attachments: SupplyReservationsInteractionMessagesReplyContentSheet;
  collapsedMenu: SupplyReservationsInteractionMessagesReplyContentMenu;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  sendEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  submitEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  templates: SupplyReservationsInteractionMessagesReplyContentSheet;
  textArea: SupplyReservationsInteractionMessagesReplyContentTextArea;
};

export type SupplyReservationsInteractionMessagesReplyContentMenu = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentMenu";
  menuButton: UiTertiaryButton;
  menuListItems: SupplyReservationsInteractionMessagesReplyContentMenuItems;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsInteractionMessagesReplyContentMenuItem = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentMenuItem";
  accessibility?: Maybe<Scalars["String"]>;
  iconName?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyContentMenuItems = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentMenuItems";
  attachmentsItem: SupplyReservationsInteractionMessagesReplyContentMenuItem;
  templatesItem: SupplyReservationsInteractionMessagesReplyContentMenuItem;
};

export type SupplyReservationsInteractionMessagesReplyContentModal = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentModal";
  action: UiLinkAction;
  actionLabel: Scalars["String"];
  closeEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  graphqlErrorState: SupplyReservationsInteractionMessagesGraphqlErrorState;
  manageEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyContentSheet = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentSheet";
  button: UiTertiaryButton;
  contentModal: SupplyReservationsInteractionMessagesReplyContentModal;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsInteractionMessagesReplyContentTextArea = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentTextArea";
  accessibility: Scalars["String"];
  maxLengthError: Scalars["String"];
  placeholder: Scalars["String"];
  removeEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  sendButtonAccessibility: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyTemplates = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplates";
  content: SupplyReservationsInteractionMessagesReplyTemplatesContent;
};

export type SupplyReservationsInteractionMessagesReplyTemplatesCategory = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesCategory";
  label: Scalars["String"];
  templates: Array<SupplyReservationsInteractionMessagesReplyTemplatesEntry>;
};

export type SupplyReservationsInteractionMessagesReplyTemplatesCategoryList = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesCategoryList";
  categories: Array<SupplyReservationsInteractionMessagesReplyTemplatesCategory>;
  confirmationTexts: SupplyReservationsInteractionMessagesReplyTemplatesConfirmationTexts;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsInteractionMessagesReplyTemplatesConfirmationTexts = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesConfirmationTexts";
  acceptButtonLabel: Scalars["String"];
  cancelButtonLabel: Scalars["String"];
  warningMessage: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesContent =
  | SupplyReservationsInteractionMessagesReplyTemplatesCategoryList
  | SupplyReservationsInteractionMessagesReplyTemplatesErrorState;

export type SupplyReservationsInteractionMessagesReplyTemplatesEntry =
  | SupplyReservationsInteractionMessagesReplyTemplatesEntryItem
  | SupplyReservationsInteractionMessagesReplyTemplatesEntrySeparator;

export type SupplyReservationsInteractionMessagesReplyTemplatesEntryItem = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesEntryItem";
  attachments?: Maybe<Array<SupplyReservationsInteractionMessagesReplyTemplatesEntryItemAttachment>>;
  button: UiSecondaryButton;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  text: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesEntryItemAttachment = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesEntryItemAttachment";
  attachmentType: Scalars["String"];
  attachmentUrl: Scalars["String"];
  attachmentUuid: Scalars["String"];
  thumbnailUrl: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesEntrySeparator = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesEntrySeparator";
  separatorLine: Scalars["Boolean"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"];
  icon: Icon;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  reloadEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesResponse = {
  __typename?: "SupplyReservationsInteractionMessagesResponse";
  interactionMessageDetails: SupplyReservationsInteractionMessagesDetailsContent;
  interactionMessageHeader?: Maybe<SupplyReservationsHeader>;
  interactionMessagesList: SupplyReservationsInteractionMessagesView;
  interactionMessagesReplyAttachments?: Maybe<SupplyReservationsInteractionMessagesReplyAttachments>;
  interactionMessagesReplyTemplates: SupplyReservationsInteractionMessagesReplyTemplates;
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessageDetailsArgs = {
  interactionId?: InputMaybe<Scalars["String"]>;
  interactionMessageId: Scalars["String"];
  interactionMessageType?: InputMaybe<Scalars["String"]>;
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessageHeaderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: ReservationContext;
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessagesListArgs = {
  interactionId: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessagesReplyAttachmentsArgs = {
  interactionId: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessagesReplyTemplatesArgs = {
  context: ContextInput;
  interactionId: Scalars["String"];
};

export type SupplyReservationsInteractionMessagesView = {
  __typename?: "SupplyReservationsInteractionMessagesView";
  content: SupplyReservationsInteractionMessagesViewContent;
  reply: SupplyReservationsInteractionMessagesReply;
  shouldMarkAsRead: Scalars["Boolean"];
};

export type SupplyReservationsInteractionMessagesViewContent =
  | SupplyReservationsInteractionMessagesErrorState
  | SupplyReservationsInteractionMessagesList;

export type SupplyReservationsInteractionReplyPendingMessage = {
  __typename?: "SupplyReservationsInteractionReplyPendingMessage";
  document: SupplyReservationsInteractionChatDocument;
  footer: SupplyReservationsInteractionReplyPendingMessageFooter;
  header: EgdsStylizedText;
  textMessage: SupplyReservationsInteractionChatTextMessage;
};

export type SupplyReservationsInteractionReplyPendingMessageFooter = {
  __typename?: "SupplyReservationsInteractionReplyPendingMessageFooter";
  error: SupplyReservationsInteractionMessageGroupFooter;
  retryEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  sending: SupplyReservationsInteractionMessageGroupFooter;
};

export type SupplyReservationsInteractionResponse = {
  __typename?: "SupplyReservationsInteractionResponse";
  downloadInteractions: Scalars["String"];
  interactionList: SupplyReservationsInteractionView;
  interactionsCount: SupplyReservationsInteractionsCount;
  responsivenessMetrics?: Maybe<SupplyReservationsInteractionsResponsiveness>;
  upcomingInteractions: SupplyReservationsInteractionsUpcomingView;
};

export type SupplyReservationsInteractionResponseDownloadInteractionsArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type SupplyReservationsInteractionResponseInteractionListArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type SupplyReservationsInteractionResponseInteractionsCountArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]>;
};

export type SupplyReservationsInteractionResponseResponsivenessMetricsArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type SupplyReservationsInteractionResponseUpcomingInteractionsArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type SupplyReservationsInteractionSessionMarker = {
  __typename?: "SupplyReservationsInteractionSessionMarker";
  primary: Scalars["String"];
  separatorLine?: Maybe<Scalars["Boolean"]>;
};

export type SupplyReservationsInteractionSystemMessage = {
  __typename?: "SupplyReservationsInteractionSystemMessage";
  card: EgdsStandardMessagingCard;
  dialog?: Maybe<SupplyReservationsInteractionSystemMessageDialog>;
  eyebrow: EgdsStylizedText;
};

export type SupplyReservationsInteractionSystemMessageDialog = {
  __typename?: "SupplyReservationsInteractionSystemMessageDialog";
  graphqlErrorState: SupplyReservationsInteractionMessagesGraphqlErrorState;
  interactionId?: Maybe<Scalars["String"]>;
  interactionMessageId: Scalars["String"];
  interactionMessageType?: Maybe<Scalars["String"]>;
  subtitle: Scalars["String"];
  title: Scalars["String"];
  toolbarTitle: Scalars["String"];
};

export type SupplyReservationsInteractionUpdateResponse = {
  __typename?: "SupplyReservationsInteractionUpdateResponse";
  updateInteractions: SupplyReservationsInteractionsListItemActionResponse;
};

export type SupplyReservationsInteractionUpdateResponseUpdateInteractionsArgs = {
  action: SupplyReservationsInteractionListItemAction;
  context: ContextInput;
  interactionIds: Array<Scalars["String"]>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type SupplyReservationsInteractionView = {
  __typename?: "SupplyReservationsInteractionView";
  content: SupplyReservationsInteractionViewContent;
  experimentalFlags?: Maybe<Array<Scalars["String"]>>;
  filters: SupplyReservationsInteractionsQuickFilters;
  header: SupplyReservationsInteractionsHeader;
  pageDialog?: Maybe<SupplyReservationsInteractionsPageDialog>;
  pageViewAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  searchHistory: SupplyReservationsInteractionsSearchHistory;
  searchInput: EgdsTextInputField;
  searchNameAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  searchReservationAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  selectedFiltersAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  sideSheet: SupplyReservationsInteractionsFilterSideSheet;
};

export type SupplyReservationsInteractionViewContent =
  | SupplyReservationsInteractionEmptyState
  | SupplyReservationsInteractionList;

export type SupplyReservationsInteractionViewType = "CAROUSEL_VIEW" | "LIST_VIEW";

export type SupplyReservationsInteractionsBasicPill = SupplyReservationsInteractionsBasicPillShared &
  SupplyReservationsInteractionsPillShared & {
    __typename?: "SupplyReservationsInteractionsBasicPill";
    filters: Array<Scalars["String"]>;
    pill: EgdsBasicPill;
    sort?: Maybe<SupplyReservationsInteractionsSort>;
  };

export interface SupplyReservationsInteractionsBasicPillShared {
  filters: Array<Scalars["String"]>;
  sort?: Maybe<SupplyReservationsInteractionsSort>;
}

export type SupplyReservationsInteractionsBasicTriggerPill = SupplyReservationsInteractionsPillShared & {
  __typename?: "SupplyReservationsInteractionsBasicTriggerPill";
  pill: EgdsBasicTriggerPill;
  target: SupplyReservationsInteractionsTriggerTarget;
};

export type SupplyReservationsInteractionsClickStream = IEgClickstreamData & {
  __typename?: "SupplyReservationsInteractionsClickStream";
  additionalContext?: Maybe<EgClickstreamAdditionalContext>;
  customizedAttributes?: Maybe<Scalars["String"]>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partnerCommon?: Maybe<EgClickstreamPartnerCommonData>;
};

export interface SupplyReservationsInteractionsClientDataInput {
  timezone: Scalars["String"];
}

export interface SupplyReservationsInteractionsClientInput {
  timezone: Scalars["String"];
}

export type SupplyReservationsInteractionsCount = {
  __typename?: "SupplyReservationsInteractionsCount";
  count: Scalars["Int"];
  unreadCount: Scalars["Int"];
  viewMessagesCount: Scalars["String"];
};

export type SupplyReservationsInteractionsExpandoContent = EgdsCheckBoxGroup | EgdsDateRangePicker;

export type SupplyReservationsInteractionsFilterExpandos = {
  __typename?: "SupplyReservationsInteractionsFilterExpandos";
  clear: UiTertiaryButton;
  content: SupplyReservationsInteractionsExpandoContent;
  display: EgdsExpandoListItem;
  title: Scalars["String"];
};

export interface SupplyReservationsInteractionsFilterInput {
  endDate?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<Array<Scalars["String"]>>;
  startDate?: InputMaybe<Scalars["String"]>;
}

export type SupplyReservationsInteractionsFilterPill = EgdsBasicPill | SupplyReservationsInteractionsTriggerPill;

export type SupplyReservationsInteractionsFilterSideSheet = {
  __typename?: "SupplyReservationsInteractionsFilterSideSheet";
  applyFilters: UiPrimaryFloatingActionButton;
  display: EgdsSheet;
  expandos: Array<SupplyReservationsInteractionsFilterExpandos>;
  filterSortToolbar: UiToolbar;
  sort: EgdsBasicSelect;
  toolbar: UiToolbar;
};

export type SupplyReservationsInteractionsHeader = {
  __typename?: "SupplyReservationsInteractionsHeader";
  dialog: SupplyReservationsInteractionListActionDialog;
  primaryActions: Array<UiTertiaryButton>;
  primaryButtons: Array<UiTertiaryButton>;
  primaryButtonsAnalytics?: Maybe<Array<SupplyReservationsInteractionsClickStream>>;
  secondaryButtons: Array<UiTertiaryButton>;
  secondaryButtonsAnalytics?: Maybe<Array<SupplyReservationsInteractionsClickStream>>;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionsListItemActionResponse =
  | SupplyReservationsInteractionErrorState
  | SupplyReservationsInteractionsListItemActionResponseContent;

export type SupplyReservationsInteractionsListItemActionResponseContent = {
  __typename?: "SupplyReservationsInteractionsListItemActionResponseContent";
  actionCompletedText: Scalars["String"];
  undoAction: SupplyReservationsInteractionsListItemUndoAction;
  undoCompletedText: Scalars["String"];
};

export type SupplyReservationsInteractionsListItemUndoAction = {
  __typename?: "SupplyReservationsInteractionsListItemUndoAction";
  button: UiTertiaryButton;
  interactionIds: Array<Scalars["String"]>;
  listItemAction: SupplyReservationsInteractionListItemAction;
};

export type SupplyReservationsInteractionsPageDialog = {
  __typename?: "SupplyReservationsInteractionsPageDialog";
  body: Scalars["String"];
  closedAnalytics: SupplyReservationsInteractionsClickStream;
  footer: SupplyReservationsInteractionsPageDialogFooterActions;
  heading: EgdsDialogToolbar;
  shownAnalytics: SupplyReservationsInteractionsClickStream;
};

export type SupplyReservationsInteractionsPageDialogFooterActions = {
  __typename?: "SupplyReservationsInteractionsPageDialogFooterActions";
  primaryAction: SupplyReservationsInteractionListActionDialogFooterAction;
};

export interface SupplyReservationsInteractionsPageInput {
  pageNumber?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
}

export type SupplyReservationsInteractionsPagination = {
  __typename?: "SupplyReservationsInteractionsPagination";
  paginationIndexDetails: SupplyReservationsInteractionsPaginationIndexDetails;
  paginationTrigger: SupplyReservationsInteractionsPaginationTrigger;
};

export type SupplyReservationsInteractionsPaginationIndexDetails = {
  __typename?: "SupplyReservationsInteractionsPaginationIndexDetails";
  itemsPerPage: EgdsBasicSelect;
  selectPaginationItemAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  showPaginationDropdownAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  startPageIndex: EgdsNumberInputField;
};

export type SupplyReservationsInteractionsPaginationTrigger = {
  __typename?: "SupplyReservationsInteractionsPaginationTrigger";
  nextRecords: SupplyReservationsInteractionsPaginationTriggerButton;
  previousRecords: SupplyReservationsInteractionsPaginationTriggerButton;
  recordCount: Scalars["String"];
  recordSummary: Scalars["String"];
};

export type SupplyReservationsInteractionsPaginationTriggerButton = {
  __typename?: "SupplyReservationsInteractionsPaginationTriggerButton";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  button: UiSecondaryButton;
  triggerDetails?: Maybe<SupplyReservationsInteractionsPaginationTriggerPageDetails>;
};

export type SupplyReservationsInteractionsPaginationTriggerPageDetails = {
  __typename?: "SupplyReservationsInteractionsPaginationTriggerPageDetails";
  pageNumber?: Maybe<Scalars["Int"]>;
  pageSize: Scalars["Int"];
};

export interface SupplyReservationsInteractionsPillShared {
  pill: EgdsPill;
}

export type SupplyReservationsInteractionsQuickFilters = {
  __typename?: "SupplyReservationsInteractionsQuickFilters";
  clearFilters?: Maybe<UiTertiaryButton>;
  defaultFilterPills: Array<SupplyReservationsInteractionsPillShared>;
  /** @deprecated Use defaultFilterPills instead */
  defaultPills: Array<EgdsBasicPill>;
  filterAccess: UiTertiaryButton;
  /** @deprecated Use selectedFilterPills instead */
  filterPills?: Maybe<Array<EgdsBasicRemovablePill>>;
  filterSortSheetTrigger: UiTertiaryButton;
  /** @deprecated Use selectedFilterPills instead */
  quickFilterPills: Array<SupplyReservationsInteractionsFilterPill>;
  selectedFilterPills: Array<SupplyReservationsInteractionsPillShared>;
};

export type SupplyReservationsInteractionsRemoveablePill = SupplyReservationsInteractionsBasicPillShared &
  SupplyReservationsInteractionsPillShared & {
    __typename?: "SupplyReservationsInteractionsRemoveablePill";
    filters: Array<Scalars["String"]>;
    pill: EgdsBasicRemovablePill;
    sort?: Maybe<SupplyReservationsInteractionsSort>;
  };

export type SupplyReservationsInteractionsResponsiveness = {
  __typename?: "SupplyReservationsInteractionsResponsiveness";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  banner: SupplyReservationsInteractionsResponsivenessBanner;
  sideSheet: SupplyReservationsInteractionsResponsivenessSideSheet;
};

export type SupplyReservationsInteractionsResponsivenessBanner =
  | SupplyReservationsInteractionsResponsivenessBannerMultiPropertyState
  | SupplyReservationsInteractionsResponsivenessBannerSinglePropertyState;

export type SupplyReservationsInteractionsResponsivenessBannerMultiPropertyState = {
  __typename?: "SupplyReservationsInteractionsResponsivenessBannerMultiPropertyState";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  description: EgdsStylizedText;
  icon: Icon;
  title?: Maybe<EgdsStylizedText>;
};

export type SupplyReservationsInteractionsResponsivenessBannerSinglePropertyState = {
  __typename?: "SupplyReservationsInteractionsResponsivenessBannerSinglePropertyState";
  acceptanceRateItem?: Maybe<EgdsSpannableText>;
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  closedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  openSideSheetLink?: Maybe<EgdsStandardLink>;
  openedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  responseRateItem?: Maybe<EgdsSpannableText>;
  responseTimeItem?: Maybe<EgdsSpannableText>;
  sideSheetPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  trackResponseMetricsBannerLink: EgdsStandardLink;
  trackResponseMetricsBannerLinkAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsResponsivenessCardExpandoContent = {
  __typename?: "SupplyReservationsInteractionsResponsivenessCardExpandoContent";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  description: EgdsSpannableText;
  icon?: Maybe<Icon>;
  indicator: EgdsStylizedText;
  insight?: Maybe<EgdsSpannableText>;
  label: EgdsSpannableText;
};

export type SupplyReservationsInteractionsResponsivenessExpandoCard = {
  __typename?: "SupplyReservationsInteractionsResponsivenessExpandoCard";
  closedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  content: SupplyReservationsInteractionsResponsivenessCardExpandoContent;
  display: EgdsExpandoListItem;
  openAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsResponsivenessSideSheet = {
  __typename?: "SupplyReservationsInteractionsResponsivenessSideSheet";
  description: EgdsStylizedText;
  display: EgdsSheet;
  downloadAppParagraph: EgdsSpannableText;
  downloadAppParagraphAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  expandoCards: Array<SupplyReservationsInteractionsResponsivenessExpandoCard>;
  learnMoreLink: EgdsStandardLink;
  learnMoreLinkAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  message?: Maybe<SupplyReservationsInteractionsResponsivenessSideSheetBanner>;
};

export type SupplyReservationsInteractionsResponsivenessSideSheetBanner = {
  __typename?: "SupplyReservationsInteractionsResponsivenessSideSheetBanner";
  heading?: Maybe<EgdsStylizedText>;
  icon?: Maybe<Icon>;
  message: EgdsStylizedText;
  theme?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsInteractionsSearchHistory = {
  __typename?: "SupplyReservationsInteractionsSearchHistory";
  action: Scalars["String"];
  header: Scalars["String"];
};

export type SupplyReservationsInteractionsSort =
  | "CHECKIN_DATE_ASC"
  | "CHECKIN_DATE_DESC"
  | "GUEST_ASC"
  | "GUEST_DESC"
  | "RECEIVED_ON_ASC"
  | "RECEIVED_ON_DESC";

export type SupplyReservationsInteractionsTriggerPill = {
  __typename?: "SupplyReservationsInteractionsTriggerPill";
  target: EgdsDateRangePicker;
  triggerPill: EgdsBasicTriggerPill;
};

export type SupplyReservationsInteractionsTriggerTarget = EgdsDateRangePicker | EgdsSheet;

export type SupplyReservationsInteractionsUpcomingContent = {
  __typename?: "SupplyReservationsInteractionsUpcomingContent";
  action?: Maybe<EgdsStandardLink>;
  empty?: Maybe<SupplyReservationsInteractionEmptyState>;
  /** @deprecated Use title and action */
  header: SupplyReservationsInteractionsUpcomingHeader;
  interactionList: Array<SupplyReservationsInteractionListItemType>;
  reservationViewType?: Maybe<SupplyReservationsInteractionViewType>;
  reservationViewTypePresented?: Maybe<Scalars["AnalyticsPayload"]>;
  reservationsModulePresented?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: Scalars["String"];
  viewAllReservationsSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsUpcomingErrorView = {
  __typename?: "SupplyReservationsInteractionsUpcomingErrorView";
  action?: Maybe<EgdsStandardLink>;
  content: SupplyReservationsInteractionsUpcomingErrorViewContent;
  /** @deprecated Use title and action */
  header: SupplyReservationsInteractionsUpcomingHeader;
  reservationsModulePresented?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: Scalars["String"];
  viewAllReservationsSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsUpcomingErrorViewContent = {
  __typename?: "SupplyReservationsInteractionsUpcomingErrorViewContent";
  button: UiTertiaryButton;
  description: Scalars["String"];
  icon: Icon;
  reservationsRefreshSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: Scalars["String"];
};

export type SupplyReservationsInteractionsUpcomingHeader = {
  __typename?: "SupplyReservationsInteractionsUpcomingHeader";
  action?: Maybe<EgdsStandardLink>;
  title: Scalars["String"];
  /** @deprecated this component is no longer used */
  viewAllReservationsSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsUpcomingView =
  | SupplyReservationsInteractionsUpcomingContent
  | SupplyReservationsInteractionsUpcomingErrorView;

export type SupplyReservationsInvoiceStatus = {
  __typename?: "SupplyReservationsInvoiceStatus";
  action: SupplyReservationsAction;
  description?: Maybe<Scalars["String"]>;
  invoiceId?: Maybe<Scalars["String"]>;
  invoiceIdLabel?: Maybe<Scalars["String"]>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
};

export type SupplyReservationsInvoiceStatuses = {
  __typename?: "SupplyReservationsInvoiceStatuses";
  nextButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  paginationLabel?: Maybe<Scalars["String"]>;
  previousButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  statuses: Array<SupplyReservationsInvoiceStatus>;
  title: Scalars["String"];
};

export type SupplyReservationsMiniAvailabilityCalendar = {
  __typename?: "SupplyReservationsMiniAvailabilityCalendar";
  url: Scalars["String"];
};

export type SupplyReservationsNightlyPaymentDetailsSection = {
  __typename?: "SupplyReservationsNightlyPaymentDetailsSection";
  label: EgdsStylizedText;
  nightlyPaymentLineItems: Array<SupplyReservationsPaymentDetailsLineItem>;
};

export type SupplyReservationsPartnerNotes = {
  __typename?: "SupplyReservationsPartnerNotes";
  actions: SupplyReservationsActions;
  alert: SupplyReservationsPartnerNotesEditAlert;
  collapsedLabel: Scalars["String"];
  content?: Maybe<Scalars["String"]>;
  errorMessage: Scalars["String"];
  expandedLabel: Scalars["String"];
  input?: Maybe<EgdsTextAreaInputField>;
  noteCreditCardValidationErrorMessagePresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  noteEditorDismissedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  noteEditorPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  noteSaveErrorMessagePresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  noteSaveFailedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  noteSaveSubmittedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  noteSaveSucceededEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  subTitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  toast?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsPartnerNotesEditAlert = {
  __typename?: "SupplyReservationsPartnerNotesEditAlert";
  message: Scalars["String"];
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onPrimaryActionSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onSecondaryActionSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  primaryActionTitle: Scalars["String"];
  secondaryActionTitle: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsPaymentDetails = {
  __typename?: "SupplyReservationsPaymentDetails";
  detailedPaymentLineItems: SupplyReservationsPaymentDisplayDetailed;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  summaryPaymentLineItems: Array<SupplyReservationsPaymentDetailsLineItem>;
};

export type SupplyReservationsPaymentDetailsAmountLineItem = {
  __typename?: "SupplyReservationsPaymentDetailsAmountLineItem";
  amount: EgdsStylizedText;
  icon?: Maybe<Icon>;
};

export type SupplyReservationsPaymentDetailsDisclaimerLineItem = {
  __typename?: "SupplyReservationsPaymentDetailsDisclaimerLineItem";
  lineItemText: EgdsStylizedText;
  link?: Maybe<EgdsStandardLink>;
};

export type SupplyReservationsPaymentDetailsExpandoComponentLineItems = {
  __typename?: "SupplyReservationsPaymentDetailsExpandoComponentLineItems";
  expando: SupplyReservationsPaymentDetailsExpandoLink;
  nightlyPaymentList: Array<SupplyReservationsNightlyPaymentDetailsSection>;
  nightlyPaymentTitle?: Maybe<SupplyReservationsPaymentDetailsTitleLineItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsPaymentDetailsExpandoLink = {
  __typename?: "SupplyReservationsPaymentDetailsExpandoLink";
  alignment?: Maybe<SupplyReservationsPaymentDetailsExpandoPositioning>;
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"];
  expandedLabel: Scalars["String"];
};

export type SupplyReservationsPaymentDetailsExpandoPositioning = "LEADING" | "TRAILING";

export type SupplyReservationsPaymentDetailsLineItem =
  | SupplyReservationsPaymentDetailsDisclaimerLineItem
  | SupplyReservationsPaymentDetailsSheetToggleLink
  | SupplyReservationsPaymentDetailsSubLineItem
  | SupplyReservationsPaymentDetailsTitleLineItem
  | SupplyReservationsPaymentDetailsTotalLineItem
  | SupplyReservationsPaymentExpandoLineItem;

export type SupplyReservationsPaymentDetailsSheetComponentLineItems = {
  __typename?: "SupplyReservationsPaymentDetailsSheetComponentLineItems";
  childLineItems: Array<SupplyReservationsPaymentDetailsLineItem>;
  onDismissedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  sheet: SupplyReservationsPaymentDisplaySidePanelProps;
};

export type SupplyReservationsPaymentDetailsSheetToggleLink = {
  __typename?: "SupplyReservationsPaymentDetailsSheetToggleLink";
  lineItemText: EgdsStylizedText;
  link: EgdsStandardLink;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type SupplyReservationsPaymentDetailsSubLineItem = ISupplyReservationsPaymentLineItem &
  ISupplyReservationsPaymentMoreInfo & {
    __typename?: "SupplyReservationsPaymentDetailsSubLineItem";
    amount: SupplyReservationsPaymentDetailsAmountLineItem;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyReservationsToolTip>;
  };

export type SupplyReservationsPaymentDetailsTitleLineItem = ISupplyReservationsPaymentMoreInfo & {
  __typename?: "SupplyReservationsPaymentDetailsTitleLineItem";
  badge?: Maybe<EgdsStandardBadge>;
  footerNoteText?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsPaymentDetailsTotalLineItem = ISupplyReservationsPaymentLineItem &
  ISupplyReservationsPaymentMoreInfo & {
    __typename?: "SupplyReservationsPaymentDetailsTotalLineItem";
    amount: SupplyReservationsPaymentDetailsAmountLineItem;
    badge?: Maybe<EgdsStandardBadge>;
    borderSeparator: Scalars["Boolean"];
    currencyCode?: Maybe<EgdsStylizedText>;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyReservationsToolTip>;
  };

export type SupplyReservationsPaymentDisplayDetailed =
  | SupplyReservationsPaymentDetailsExpandoComponentLineItems
  | SupplyReservationsPaymentDetailsSheetComponentLineItems;

export type SupplyReservationsPaymentDisplaySidePanelProps = {
  __typename?: "SupplyReservationsPaymentDisplaySidePanelProps";
  navType?: Maybe<SupplyReservationsPaymentDisplayToolbarIcon>;
  toolbarTitle?: Maybe<Scalars["String"]>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsPaymentDisplayToolbarIcon = "BACK" | "CLOSE";

export type SupplyReservationsPaymentExpandoLineItem = ISupplyReservationsPaymentLineItem &
  ISupplyReservationsPaymentMoreInfo & {
    __typename?: "SupplyReservationsPaymentExpandoLineItem";
    amount: SupplyReservationsPaymentDetailsAmountLineItem;
    expando: SupplyReservationsPaymentDetailsExpandoLink;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    subLineItems: Array<SupplyReservationsPaymentDetailsSubLineItem>;
    tooltip?: Maybe<SupplyReservationsToolTip>;
  };

export type SupplyReservationsPaymentRequestStatus = {
  __typename?: "SupplyReservationsPaymentRequestStatus";
  description?: Maybe<Scalars["String"]>;
  paymentRequestAction: SupplyReservationsAction;
  paymentRequestId?: Maybe<Scalars["String"]>;
  paymentRequestIdLabel?: Maybe<Scalars["String"]>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
};

export type SupplyReservationsPaymentRequestStatuses = {
  __typename?: "SupplyReservationsPaymentRequestStatuses";
  nextButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  paginationLabel?: Maybe<Scalars["String"]>;
  previousButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  statuses: Array<SupplyReservationsPaymentRequestStatus>;
  title: Scalars["String"];
};

export type SupplyReservationsPaymentSchedule = {
  __typename?: "SupplyReservationsPaymentSchedule";
  actions?: Maybe<SupplyReservationsActions>;
  contents?: Maybe<Array<SupplyReservationsPaymentScheduleItem>>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  subtitle: Scalars["String"];
  title: Scalars["String"];
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsPaymentScheduleItem = {
  __typename?: "SupplyReservationsPaymentScheduleItem";
  actions?: Maybe<SupplyReservationsActions>;
  amount: Scalars["String"];
  details: Array<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  onExpandoToggledEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  refunds?: Maybe<Array<SupplyReservationsPaymentScheduleItemRefund>>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
  title: Scalars["String"];
};

export type SupplyReservationsPaymentScheduleItemRefund = {
  __typename?: "SupplyReservationsPaymentScheduleItemRefund";
  amount: Scalars["String"];
  details: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsPaymentSummary = {
  __typename?: "SupplyReservationsPaymentSummary";
  bottomText?: Maybe<EgdsStylizedText>;
  button?: Maybe<SupplyReservationsAction>;
  lineItems: Array<Maybe<SupplyReservationsPaymentSummaryLineItem>>;
  title: Scalars["String"];
};

export type SupplyReservationsPaymentSummaryLineItem = {
  __typename?: "SupplyReservationsPaymentSummaryLineItem";
  endIcon?: Maybe<Icon>;
  endText?: Maybe<EgdsStylizedText>;
  primary?: Maybe<EgdsStylizedText>;
  secondary?: Maybe<EgdsStylizedText>;
  startIcon?: Maybe<Icon>;
  topSpacing?: Maybe<Scalars["Boolean"]>;
};

export type SupplyReservationsRenderHint = "COLLAPSED" | "LIST";

export type SupplyReservationsRentalAgreement = {
  __typename?: "SupplyReservationsRentalAgreement";
  content: SupplyReservationsRentalAgreementContent;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: Scalars["String"];
  /** @deprecated use viewRentalAgreementButton which is optional */
  viewAgreementButton: SupplyReservationsAction;
  viewRentalAgreementButton?: Maybe<SupplyReservationsAction>;
};

export type SupplyReservationsRentalAgreementContent = {
  __typename?: "SupplyReservationsRentalAgreementContent";
  content: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsStayDetails = {
  __typename?: "SupplyReservationsStayDetails";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  bookingStateMessage?: Maybe<SupplyReservationsStayDetailsMessage>;
  content: Array<SupplyReservationsStayDetailsItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title: SupplyReservationsStayDetailsTitle;
};

export type SupplyReservationsStayDetailsBookingStateAndReservationId = {
  __typename?: "SupplyReservationsStayDetailsBookingStateAndReservationId";
  bookingState: EgdsStylizedText;
  reservationId: Scalars["String"];
};

export type SupplyReservationsStayDetailsCopyButton = {
  __typename?: "SupplyReservationsStayDetailsCopyButton";
  content: Scalars["String"];
  title: Scalars["String"];
  toast: Scalars["String"];
};

export type SupplyReservationsStayDetailsCopyableReservationId = {
  __typename?: "SupplyReservationsStayDetailsCopyableReservationId";
  copyButton: SupplyReservationsStayDetailsCopyButton;
  title: Scalars["String"];
};

export type SupplyReservationsStayDetailsItem = {
  __typename?: "SupplyReservationsStayDetailsItem";
  icon: Scalars["String"];
  link?: Maybe<SupplyReservationsStayDetailsItemLink>;
  subtitle?: Maybe<Scalars["String"]>;
  subtitleExtra?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title: Scalars["String"];
};

export type SupplyReservationsStayDetailsItemExpando = {
  __typename?: "SupplyReservationsStayDetailsItemExpando";
  content: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyReservationsStayDetailsItemLink = {
  __typename?: "SupplyReservationsStayDetailsItemLink";
  content: SupplyReservationsStayDetailsItemModal;
  title: Scalars["String"];
};

export type SupplyReservationsStayDetailsItemModal = {
  __typename?: "SupplyReservationsStayDetailsItemModal";
  confirmButton: EgdsButton;
  expando: SupplyReservationsStayDetailsItemExpando;
  input: EgdsTextInputField;
  title: Scalars["String"];
};

export type SupplyReservationsStayDetailsMessage = {
  __typename?: "SupplyReservationsStayDetailsMessage";
  bookingStateMessage?: Maybe<Scalars["String"]>;
  timerBadge?: Maybe<EgdsStandardBadge>;
  timerDate?: Maybe<Scalars["String"]>;
  timerType?: Maybe<SupplyReservationsStayDetailsMessageTimerType>;
};

export type SupplyReservationsStayDetailsMessageTimerType = "COUNT_DOWN" | "COUNT_UP";

export type SupplyReservationsStayDetailsTitle =
  | SupplyReservationsStayDetailsBookingStateAndReservationId
  | SupplyReservationsStayDetailsCopyableReservationId;

export type SupplyReservationsTablePagination = {
  __typename?: "SupplyReservationsTablePagination";
  currentPageIdentifier: SupplyReservationsTablePaginationPageInfo;
  paginationIndexDetails?: Maybe<SupplyReservationsTablePaginationIndexDetails>;
  paginationTrigger: SupplyReservationsTablePaginationTrigger;
  prevPageIdentifiers?: Maybe<Array<Maybe<SupplyReservationsTablePaginationPageInfo>>>;
};

export type SupplyReservationsTablePaginationIndexDetails = {
  __typename?: "SupplyReservationsTablePaginationIndexDetails";
  itemsPerPage: EgdsBasicSelect;
  startPageIndex?: Maybe<EgdsNumberInputField>;
};

export type SupplyReservationsTablePaginationPageInfo = {
  __typename?: "SupplyReservationsTablePaginationPageInfo";
  pageIdentifier?: Maybe<Scalars["String"]>;
  pageNum?: Maybe<Scalars["Int"]>;
};

export type SupplyReservationsTablePaginationTrigger = {
  __typename?: "SupplyReservationsTablePaginationTrigger";
  nextRecords: SupplyReservationsTablePaginationTriggerButton;
  previousRecords: SupplyReservationsTablePaginationTriggerButton;
  recordCount: Scalars["String"];
  recordSummary: Scalars["String"];
};

export type SupplyReservationsTablePaginationTriggerButton = {
  __typename?: "SupplyReservationsTablePaginationTriggerButton";
  action: SupplyReservationsAction;
  triggerDetails?: Maybe<SupplyReservationsTablePaginationTriggerPageDetails>;
};

export type SupplyReservationsTablePaginationTriggerPageDetails = {
  __typename?: "SupplyReservationsTablePaginationTriggerPageDetails";
  pageInfo?: Maybe<SupplyReservationsTablePaginationPageInfo>;
  pageSize: Scalars["Int"];
};

export type SupplyReservationsTimer = {
  __typename?: "SupplyReservationsTimer";
  expiresOn?: Maybe<Scalars["String"]>;
  hint?: Maybe<Scalars["String"]>;
  icon: Icon;
  text: EgdsStylizedText;
};

export type SupplyReservationsToolTip = {
  __typename?: "SupplyReservationsToolTip";
  content?: Maybe<Array<SupplyReservationsTooltipContent>>;
  contentDismissedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  contentPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  icon?: Maybe<Icon>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyReservationsTooltipContent = {
  __typename?: "SupplyReservationsTooltipContent";
  tooltipHeader?: Maybe<Scalars["String"]>;
  tooltipLink?: Maybe<EgdsStandardLink>;
  tooltipLinkSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]>;
  tooltipText: Scalars["String"];
};

export type SupplyReservationsTravelerIdCard = {
  __typename?: "SupplyReservationsTravelerIDCard";
  hideProfile?: Maybe<Scalars["Boolean"]>;
  travelerName: Scalars["String"];
  travelerProfile: SupplyReservationsTravelerProfile;
  trustSignals: Array<SupplyReservationsTrustSignal>;
  viewProfileAction: SupplyReservationsAction;
};

export type SupplyReservationsTravelerInfo = SupplyReservationsGuestInfo | SupplyReservationsTravelerIdCard;

export type SupplyReservationsTravelerProfile = {
  __typename?: "SupplyReservationsTravelerProfile";
  closeSheetA11y: Scalars["String"];
  header: Scalars["String"];
  openSheetA11y: Scalars["String"];
  travelerName: Scalars["String"];
  trustSignals: Array<SupplyReservationsTrustSignal>;
  verifiedInfo?: Maybe<SupplyReservationsTravelerProfileVerifiedInfo>;
};

export type SupplyReservationsTravelerProfileVerifiedInfo = {
  __typename?: "SupplyReservationsTravelerProfileVerifiedInfo";
  footer: Scalars["String"];
  subtitle: Scalars["String"];
  title: Scalars["String"];
  trustSignals: Array<SupplyReservationsGenericTrustSignal>;
};

export type SupplyReservationsTravelerRating = {
  __typename?: "SupplyReservationsTravelerRating";
  label: Scalars["String"];
  sublabel: Scalars["String"];
};

export type SupplyReservationsTrustSignal = SupplyReservationsGenericTrustSignal | SupplyReservationsTravelerRating;

export type SupplyReviewEditLink = EgdsStandardLink | StepReviewReturnAction;

export type SupplyReviewRoomsAndSpacesCard = SupplyRoomsAndSpacesCard & {
  __typename?: "SupplyReviewRoomsAndSpacesCard";
  heading: Scalars["String"];
  list: EgdsBulletedList;
};

export type SupplyReviewSectionListItem = {
  __typename?: "SupplyReviewSectionListItem";
  clickstreamInformation?: Maybe<EgClickstreamData>;
  content: SupplyReviewSectionListItemContent;
  contentListColumns?: Maybe<Scalars["Int"]>;
  editLink?: Maybe<SupplyReviewEditLink>;
  expando?: Maybe<EgdsExpandoPeek>;
  heading: Scalars["String"];
};

export type SupplyReviewSectionListItemContent =
  | EgdsParagraph
  | EgdsPlainText
  | EgdsTextIconList
  | EgdsUnorderedList
  | SupplyOnboardingIconStylizedText
  | SupplyOnboardingReviewError
  | SupplyOnboardingReviewPhotoList
  | SupplyRoomsAndSpacesCarousel;

export type SupplyRoomType = {
  __typename?: "SupplyRoomType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export interface SupplyRoomTypeMappingActionContext {
  mappings: Array<Scalars["String"]>;
}

export type SupplyRoomTypeMappingContent = {
  __typename?: "SupplyRoomTypeMappingContent";
  elements: Array<SupplyRoomTypeMappingContentElement>;
};

export type SupplyRoomTypeMappingContentElement =
  | SupplyRoomTypeMappingList
  | SupplyRoomTypeMappingParagraph
  | SupplyRoomTypeMappingSpacing;

export type SupplyRoomTypeMappingDialogAction = {
  __typename?: "SupplyRoomTypeMappingDialogAction";
  analytics: Scalars["AnalyticsPayload"];
  button: EgdsButton;
};

export type SupplyRoomTypeMappingDialogContext = {
  __typename?: "SupplyRoomTypeMappingDialogContext";
  competitorProperty: SupplyRoomTypeMappingProperty;
};

export type SupplyRoomTypeMappingDialogError = {
  __typename?: "SupplyRoomTypeMappingDialogError";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  error?: Maybe<UiBanner>;
  heading: Scalars["String"];
  primaryAction?: Maybe<SupplyRoomTypeMappingDialogAction>;
};

export type SupplyRoomTypeMappingDialogSuccess = {
  __typename?: "SupplyRoomTypeMappingDialogSuccess";
  analytics?: Maybe<Scalars["AnalyticsPayload"]>;
  context: SupplyRoomTypeMappingDialogContext;
  views: Array<SupplyRoomTypeMappingView>;
};

export type SupplyRoomTypeMappingExperience = SupplyRoomTypeMappingDialogError | SupplyRoomTypeMappingDialogSuccess;

export type SupplyRoomTypeMappingFormSelect = {
  __typename?: "SupplyRoomTypeMappingFormSelect";
  component: EgdsBasicSelect;
  validations: Array<SupplyRoomTypeMappingInputValidation>;
};

export type SupplyRoomTypeMappingInputDuplicationValidation = SupplyRoomTypeMappingInputValidation & {
  __typename?: "SupplyRoomTypeMappingInputDuplicationValidation";
  errorMessage: Scalars["String"];
  scope: Array<Scalars["String"]>;
};

export interface SupplyRoomTypeMappingInputValidation {
  errorMessage: Scalars["String"];
}

export type SupplyRoomTypeMappingList = {
  __typename?: "SupplyRoomTypeMappingList";
  items: Array<SupplyRoomTypeMappingListItem>;
};

export type SupplyRoomTypeMappingListItem = {
  __typename?: "SupplyRoomTypeMappingListItem";
  cells: Array<SupplyRoomTypeMappingRoomCell>;
};

export type SupplyRoomTypeMappingParagraph = {
  __typename?: "SupplyRoomTypeMappingParagraph";
  text: EgdsSpannableText;
};

export type SupplyRoomTypeMappingProperty = {
  __typename?: "SupplyRoomTypeMappingProperty";
  id: Scalars["String"];
  name: Scalars["String"];
};

export interface SupplyRoomTypeMappingPropertyInput {
  id: Scalars["String"];
  name: Scalars["String"];
}

export interface SupplyRoomTypeMappingPropertyValidationInput {
  action: SupplyRoomTypeMappingUserAction;
  competitorProperty: SupplyRoomTypeMappingPropertyInput;
}

export type SupplyRoomTypeMappingRoomCell = {
  __typename?: "SupplyRoomTypeMappingRoomCell";
  content?: Maybe<SupplyRoomTypeMappingRoomCellContent>;
  header?: Maybe<SupplyRoomTypeMappingRoomCellHeader>;
};

export type SupplyRoomTypeMappingRoomCellContent = EgdsSpannableText | SupplyRoomTypeMappingFormSelect;

export type SupplyRoomTypeMappingRoomCellHeader = EgdsSpannableText;

export type SupplyRoomTypeMappingSpacing = {
  __typename?: "SupplyRoomTypeMappingSpacing";
  horizontal?: Maybe<SupplyRoomTypeMappingSpacingSize>;
  horizontalRule: Scalars["Boolean"];
  vertical?: Maybe<SupplyRoomTypeMappingSpacingSize>;
};

export type SupplyRoomTypeMappingSpacingSize =
  | "SPACING__0X__HALF"
  | "SPACING__1X"
  | "SPACING__2X"
  | "SPACING__3X"
  | "SPACING__4X"
  | "SPACING__5X"
  | "SPACING__6X"
  | "SPACING__8X";

export type SupplyRoomTypeMappingSubmitError = {
  __typename?: "SupplyRoomTypeMappingSubmitError";
  analytics: Scalars["AnalyticsPayload"];
  error?: Maybe<UiBanner>;
};

export type SupplyRoomTypeMappingSubmitResponse = SupplyRoomTypeMappingSubmitError | SupplyRoomTypeMappingSubmitSuccess;

export type SupplyRoomTypeMappingSubmitSuccess = {
  __typename?: "SupplyRoomTypeMappingSubmitSuccess";
  analytics: Scalars["AnalyticsPayload"];
};

export type SupplyRoomTypeMappingUserAction = "CONFIRM" | "REJECT";

export type SupplyRoomTypeMappingView = {
  __typename?: "SupplyRoomTypeMappingView";
  closeAnalytics: Scalars["AnalyticsPayload"];
  content: SupplyRoomTypeMappingContent;
  currentPhase: SupplyRoomTypeMappingViewPhase;
  heading: Scalars["String"];
  nextPhase?: Maybe<SupplyRoomTypeMappingViewPhase>;
  presentAnalytics: Scalars["AnalyticsPayload"];
  primaryAction: SupplyRoomTypeMappingDialogAction;
  secondaryAction?: Maybe<SupplyRoomTypeMappingDialogAction>;
};

export interface SupplyRoomTypeMappingViewOption {
  startingFrom?: InputMaybe<SupplyRoomTypeMappingViewPhase>;
  type?: InputMaybe<SupplyRoomTypeMappingViewType>;
}

export type SupplyRoomTypeMappingViewPhase = "VIEW_PROPERTY_MAPPING" | "VIEW_ROOM_TYPE_MAPPING";

export type SupplyRoomTypeMappingViewType = "DIALOG";

export interface SupplyRoomsAndSpacesCard {
  heading: Scalars["String"];
}

export type SupplyRoomsAndSpacesCardCarouselItem = {
  __typename?: "SupplyRoomsAndSpacesCardCarouselItem";
  card: SupplyRoomsAndSpacesCard;
  expando?: Maybe<EgdsExpandoPeek>;
  id?: Maybe<Scalars["String"]>;
};

export type SupplyRoomsAndSpacesCarousel = {
  __typename?: "SupplyRoomsAndSpacesCarousel";
  carouselId?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<Maybe<SupplyRoomsAndSpacesCardCarouselItem>>>;
  nextButton?: Maybe<EgdsButton>;
  previousButton?: Maybe<EgdsButton>;
};

export type SupplyScoreBanner = {
  __typename?: "SupplyScoreBanner";
  description: Scalars["String"];
  icon: Icon;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreBelowMinimumStandard = {
  __typename?: "SupplyScoreBelowMinimumStandard";
  acceleratorLink: SupplyScoreLink;
  activeStep: Scalars["Float"];
  clickstreamPresented: SupplyScoreEgClickstreamData;
  description?: Maybe<Array<Scalars["String"]>>;
  links?: Maybe<Array<SupplyScoreLink>>;
  step1ContentText: Scalars["String"];
  step1Label: Scalars["String"];
  step2ContentText: Scalars["String"];
  step2Label: Scalars["String"];
  step3ContentText: Scalars["String"];
  step3Label: Scalars["String"];
  step4ContentText: Scalars["String"];
  step4Label: Scalars["String"];
  step5ContentText: Scalars["String"];
  step5Label: Scalars["String"];
  title: Scalars["String"];
  travelAdsLink: SupplyScoreLink;
};

export type SupplyScoreBelowMinimumStandardChangeNotification = {
  __typename?: "SupplyScoreBelowMinimumStandardChangeNotification";
  clickstreamPresented: SupplyScoreEgClickstreamData;
  description: Array<Scalars["String"]>;
  icon: Icon;
  links: Array<SupplyScoreLink>;
  title: Scalars["String"];
};

export type SupplyScoreCategory = {
  __typename?: "SupplyScoreCategory";
  belowMinimumStandard?: Maybe<SupplyScoreBelowMinimumStandard>;
  belowMinimumStandardChangeNotification?: Maybe<SupplyScoreBelowMinimumStandardChangeNotification>;
  clickstreamFactorsPresented?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Removed from UI */
  compsetRank: EgdsStylizedText;
  content: SupplyScoreCategoryContent;
  description: Scalars["String"];
  label?: Maybe<SupplyScoreCategoryOverviewLabel>;
  maximumSubScore: Scalars["String"];
  moreInfo: SupplyScoreToolTip;
  needsAttentionFactors: Array<SupplyScoreValueFactor>;
  originalSubscore?: Maybe<Scalars["Int"]>;
  previewFactorsAllInfo?: Maybe<SupplyScorePreviewFactorsAllInfo>;
  progressAccessibility?: Maybe<Scalars["String"]>;
  scorePreviewInfo?: Maybe<SupplyScoreDetailPreviewFactorsInfo>;
  subScore: EgdsStylizedText;
  supplyScoreDismissibleCard?: Maybe<SupplyScoreDismissibleCard>;
  title: Scalars["String"];
  transitionBanner?: Maybe<SupplyScoreBanner>;
  travelerInformativeError?: Maybe<SupplyScoreTravelerInformativeError>;
  /** @deprecated Replaced by label */
  warningMessage?: Maybe<Scalars["String"]>;
};

export type SupplyScoreCategoryContent = SupplyScoreCategoryFactorContent | SupplyScoreEmptyErrorState;

export type SupplyScoreCategoryFactorContent = {
  __typename?: "SupplyScoreCategoryFactorContent";
  factors: Array<SupplyScoreFactor>;
};

export type SupplyScoreCategoryOverview = {
  __typename?: "SupplyScoreCategoryOverview";
  attentionCount?: Maybe<Scalars["Int"]>;
  label?: Maybe<SupplyScoreCategoryOverviewLabel>;
  score?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  /** @deprecated This attribute can be deleted after the dyff for NBF is enabled */
  warning?: Maybe<Scalars["String"]>;
};

export type SupplyScoreCategoryOverviewLabel = {
  __typename?: "SupplyScoreCategoryOverviewLabel";
  notificationType?: Maybe<SupplyScoreFactorNotificationType>;
  text?: Maybe<Scalars["String"]>;
};

export type SupplyScoreCategoryScoreInfo = {
  __typename?: "SupplyScoreCategoryScoreInfo";
  color: Scalars["String"];
  score: Scalars["String"];
  text: Scalars["String"];
};

export type SupplyScoreCompsetRank = {
  __typename?: "SupplyScoreCompsetRank";
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type SupplyScoreCompsetRankAudit =
  | SupplyScoreCompsetRankAuditContent
  | SupplyScoreCompsetRankAuditGeneralError
  | SupplyScoreCompsetRankAuditNonCompliantCompset;

export type SupplyScoreCompsetRankAuditContent = {
  __typename?: "SupplyScoreCompsetRankAuditContent";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  link: EgdsStandardLink;
  text: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreCompsetRankAuditGeneralError = {
  __typename?: "SupplyScoreCompsetRankAuditGeneralError";
  error: UiBanner;
  message: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreCompsetRankAuditNonCompliantCompset = {
  __typename?: "SupplyScoreCompsetRankAuditNonCompliantCompset";
  content: SupplyScoreCompsetRankAuditNonCompliantContent;
  message: EgdsSpannableText;
  title: Scalars["String"];
};

export type SupplyScoreCompsetRankAuditNonCompliantContent = {
  __typename?: "SupplyScoreCompsetRankAuditNonCompliantContent";
  buttonLink: SupplyScoreLinkButton;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"];
  link: EgdsStandardLink;
  title: Scalars["String"];
};

export type SupplyScoreDetailPreviewFactorsInfo = {
  __typename?: "SupplyScoreDetailPreviewFactorsInfo";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"];
  notificationState: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreDetails = {
  __typename?: "SupplyScoreDetails";
  clickstreamViewed?: Maybe<SupplyScoreEgClickstreamData>;
  externalModuleOverview: SupplyScoreExternalModuleOverview;
  licensePlate: StaticLicensePlate;
  sections: Array<SupplyScoreSection>;
};

export type SupplyScoreDismissibleCard = SupplyDismissibleCard & {
  __typename?: "SupplyScoreDismissibleCard";
  clickstreamCloseSelected?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Array<Scalars["String"]>;
  icon?: Maybe<Icon>;
  inlineLink?: Maybe<SupplyScoreLink>;
  key: Scalars["String"];
  links?: Maybe<Array<Maybe<SupplyScoreLink>>>;
  preferenceGroup: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreEgClickstreamCustomAttribute = {
  __typename?: "SupplyScoreEgClickstreamCustomAttribute";
  category_attention_count?: Maybe<Scalars["Int"]>;
  category_name?: Maybe<Scalars["String"]>;
  category_name_value?: Maybe<Scalars["Int"]>;
  factor_list?: Maybe<Array<Maybe<SupplyScoreFactorsEgClickstreamCustomAttribute>>>;
};

export type SupplyScoreEgClickstreamData = IEgClickstreamData & {
  __typename?: "SupplyScoreEgClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  feedback?: Maybe<SupplyScoreEgClickstreamFeedback>;
  search_request?: Maybe<SupplyScoreEgClickstreamSearchRequest>;
  supply_score_list?: Maybe<Array<Maybe<SupplyScoreEgClickstreamCustomAttribute>>>;
};

export type SupplyScoreEgClickstreamFeedback = {
  __typename?: "SupplyScoreEgClickstreamFeedback";
  feedback_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  feedback_reason?: Maybe<Scalars["String"]>;
  feedback_type?: Maybe<Scalars["String"]>;
  prompt_id?: Maybe<Scalars["String"]>;
};

export type SupplyScoreEgClickstreamSearchRequest = {
  __typename?: "SupplyScoreEgClickstreamSearchRequest";
  filters?: Maybe<Array<Maybe<SupplyScoreEgClickstreamSearchRequestFilterItem>>>;
};

export type SupplyScoreEgClickstreamSearchRequestFilterItem = {
  __typename?: "SupplyScoreEgClickstreamSearchRequestFilterItem";
  category?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SupplyScoreEmptyErrorState = {
  __typename?: "SupplyScoreEmptyErrorState";
  action: UiLinkAction;
  description: Scalars["String"];
  icon: Icon;
  linkText: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreExpandoDescription = {
  __typename?: "SupplyScoreExpandoDescription";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  showLess: Scalars["String"];
  showMore: Scalars["String"];
  text: Array<Scalars["String"]>;
};

export type SupplyScoreExternalModuleOverview = {
  __typename?: "SupplyScoreExternalModuleOverview";
  belowMinimumStandardTier6Banner?: Maybe<SupplyScoreBanner>;
  description: Scalars["String"];
  guestExperience: SupplyScoreCategoryOverview;
  productAttractiveness: SupplyScoreCategoryOverview;
  title: Scalars["String"];
};

export type SupplyScoreFactor = SupplyMoreInfoFactor | SupplyScoreValueFactor;

export type SupplyScoreFactorAuditAction = SupplyScoreFactorAuditDirectLink | SupplyScoreFactorAuditLinkAction;

export type SupplyScoreFactorAuditContentTip = {
  __typename?: "SupplyScoreFactorAuditContentTip";
  /** @deprecated Removed from UI */
  description: Scalars["String"];
  /** @deprecated Removed from UI */
  subItems: Array<SupplyScoreFactorAuditSubItems>;
  /** @deprecated Removed from UI */
  title: Scalars["String"];
};

export type SupplyScoreFactorAuditContentToolbar = {
  __typename?: "SupplyScoreFactorAuditContentToolbar";
  closeLabel: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreFactorAuditContentWrapper = {
  __typename?: "SupplyScoreFactorAuditContentWrapper";
  /** @deprecated Removed from UI */
  bmlAudit?: Maybe<SupplyScoreFactorBmlAudit>;
  clickstreamBackSelected?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamCloseSelected?: Maybe<SupplyScoreEgClickstreamData>;
  compsetRankAudit?: Maybe<SupplyScoreCompsetRankAudit>;
  expandoDescription?: Maybe<SupplyScoreExpandoDescription>;
  factorType: Scalars["String"];
  informativeSection?: Maybe<SupplyScoreFactorInformationPanel>;
  panelContent: SupplyScoreFactorPanelContent;
  scoreDisplay: SupplyScoreFactorScoreDisplay;
  supplyScoreNeedsAttentionAudit?: Maybe<SupplyScoreNeedsAttentionAudit>;
  supplyScoreNextBestFactorAudit?: Maybe<SupplyScoreNextBestFactorAudit>;
  toolbar: SupplyScoreFactorAuditContentToolbar;
};

export type SupplyScoreFactorAuditDirectLink = {
  __typename?: "SupplyScoreFactorAuditDirectLink";
  action: UiLinkAction;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFactorAuditLinkAction = {
  __typename?: "SupplyScoreFactorAuditLinkAction";
  contentWrapper: SupplyScoreFactorAuditContentWrapper;
  trigger: SupplyScoreFactorAuditTrigger;
};

export type SupplyScoreFactorAuditSubItemLink = {
  __typename?: "SupplyScoreFactorAuditSubItemLink";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  link: EgdsStandardLink;
};

export type SupplyScoreFactorAuditSubItems = {
  __typename?: "SupplyScoreFactorAuditSubItems";
  links: Array<SupplyScoreFactorAuditSubItemLink>;
  text: Scalars["String"];
};

export type SupplyScoreFactorAuditTrail =
  | SupplyScoreFactorAuditTrailError
  | SupplyScoreFactorBmlAuditContent
  | SupplyScoreFactorBmlAuditEmpty
  | SupplyScoreFactorNoNegativeReviewAuditTrail
  | SupplyScoreFactorNoReviewAuditTrail
  | SupplyScoreFactorReservationAuditTrail
  | SupplyScoreFactorReviewAuditTrail;

export type SupplyScoreFactorAuditTrailError = {
  __typename?: "SupplyScoreFactorAuditTrailError";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreFactorAuditTrigger = {
  __typename?: "SupplyScoreFactorAuditTrigger";
  accessibility: Scalars["String"];
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFactorBmlAudit = SupplyScoreFactorBmlAuditContent | SupplyScoreFactorBmlAuditEmpty;

export type SupplyScoreFactorBmlAuditContent = {
  __typename?: "SupplyScoreFactorBmlAuditContent";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  items: Array<SupplyScoreFactorBmlAuditItem>;
  title: Scalars["String"];
};

export type SupplyScoreFactorBmlAuditEmpty = {
  __typename?: "SupplyScoreFactorBmlAuditEmpty";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreFactorBmlAuditItem = {
  __typename?: "SupplyScoreFactorBmlAuditItem";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  link: Scalars["String"];
  observationType: SupplyScoreFactorBmlAuditItemObervationType;
  text: Scalars["String"];
};

export type SupplyScoreFactorBmlAuditItemObervationType = "AVAILABILITY" | "RATE";

export type SupplyScoreFactorInformationPanel = {
  __typename?: "SupplyScoreFactorInformationPanel";
  description: Scalars["String"];
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreFactorNoDataReservationAuditTrail = {
  __typename?: "SupplyScoreFactorNoDataReservationAuditTrail";
  description?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreFactorNoNegativeReviewAuditTrail = {
  __typename?: "SupplyScoreFactorNoNegativeReviewAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  content: SupplyScoreFactorNoNegativeReviewAuditTrailContent;
  description: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreFactorNoNegativeReviewAuditTrailContent = {
  __typename?: "SupplyScoreFactorNoNegativeReviewAuditTrailContent";
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  showAllReviewsClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  showAllReviewsLink: EgdsStandardLink;
  title: Scalars["String"];
};

export type SupplyScoreFactorNoReviewAuditTrail = {
  __typename?: "SupplyScoreFactorNoReviewAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"];
  requestReviewClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Deprecated in favor of requestReviewLinkText and requestReviewLinkHref */
  requestReviewLink: EgdsStandardLink;
  requestReviewLinkHref?: Maybe<Scalars["String"]>;
  requestReviewLinkText?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyScoreFactorNotification = {
  __typename?: "SupplyScoreFactorNotification";
  /** @deprecated Removed from UI */
  icon: Icon;
  notificationType: SupplyScoreFactorNotificationType;
  stylizedText: EgdsStylizedText;
};

export type SupplyScoreFactorNotificationType = "DOING_GREAT" | "NEEDS_ATTENTION" | "NEXT_BEST_FACTOR";

export type SupplyScoreFactorPanelContent = ISupplyScoreFactorPanelContent & {
  __typename?: "SupplyScoreFactorPanelContent";
  /** @deprecated duplicated in audit trail schema */
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  elements: Array<SupplyScoreFactorPanelContentElement>;
};

export type SupplyScoreFactorPanelContentBasic = {
  __typename?: "SupplyScoreFactorPanelContentBasic";
  descriptionItems: Array<SupplyScoreFactorPanelContentDescription>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreFactorPanelContentDescription = {
  __typename?: "SupplyScoreFactorPanelContentDescription";
  icon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type SupplyScoreFactorPanelContentElement =
  | SupplyScoreFactorPanelContentBasic
  | SupplyScoreFactorPanelContentImprovement
  | SupplyScoreFactorPanelContentSection;

export type SupplyScoreFactorPanelContentImprovement = {
  __typename?: "SupplyScoreFactorPanelContentImprovement";
  /** @deprecated Removed from UI */
  tip: SupplyScoreFactorAuditContentTip;
};

export type SupplyScoreFactorPanelContentSection = {
  __typename?: "SupplyScoreFactorPanelContentSection";
  items: Array<SupplyScoreFactorPanelContentSectionItem>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreFactorPanelContentSectionItem = {
  __typename?: "SupplyScoreFactorPanelContentSectionItem";
  action?: Maybe<UiLinkAction>;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  content: Array<Scalars["String"]>;
  leadingIcon?: Maybe<Icon>;
  tailingIcon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]>;
};

export type SupplyScoreFactorReservationAuditTrail = {
  __typename?: "SupplyScoreFactorReservationAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  contactUsClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  contactUsLink?: Maybe<EgdsStandardLink>;
  description: Scalars["String"];
  emptyData?: Maybe<SupplyScoreFactorNoDataReservationAuditTrail>;
  /** @deprecated Deprecated in favor of emptyData */
  emptyDataDescription?: Maybe<Scalars["String"]>;
  /** @deprecated Deprecated in favor of emptyData */
  emptyDataIcon?: Maybe<Icon>;
  /** @deprecated Deprecated in favor of emptyData */
  emptyDataTitle?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<SupplyScoreFactorReservationAuditTrailItem>>;
  noBookings?: Maybe<SupplyScoreFactorNoDataReservationAuditTrail>;
  showMoreClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  showMoreLink?: Maybe<EgdsStandardLink>;
  title: Scalars["String"];
};

export type SupplyScoreFactorReservationAuditTrailItem = {
  __typename?: "SupplyScoreFactorReservationAuditTrailItem";
  date: Scalars["String"];
  travelerNameClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  travelerNameLink: EgdsStandardLink;
  /** @deprecated use travelerNameLink instead */
  travellerNameLink: EgdsStandardLink;
};

export type SupplyScoreFactorReviewAuditTrail = {
  __typename?: "SupplyScoreFactorReviewAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"];
  filters?: Maybe<Array<SupplyScoreFactorReviewAuditTrailSentimentFilterItem>>;
  items: Array<SupplyScoreFactorReviewAuditTrailItem>;
  reportReviewClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Deprecated in favor of reportReviewLinkText and reportReviewLinkHref */
  reportReviewLink: EgdsStandardLink;
  reportReviewLinkHref?: Maybe<Scalars["String"]>;
  reportReviewLinkText?: Maybe<Scalars["String"]>;
  respondReviewClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  respondReviewLink: EgdsStandardLink;
  showMoreClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  showMoreLink?: Maybe<EgdsStandardLink>;
  sidePanelSubtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyScoreFactorReviewAuditTrailItem = {
  __typename?: "SupplyScoreFactorReviewAuditTrailItem";
  expando?: Maybe<EgdsExpandoPeek>;
  icon: Icon;
  rating: Scalars["String"];
  reservationLink: Scalars["String"];
  reviewAccess?: Maybe<SupplyScoreFactorReviewAuditTrailItemAccess>;
  reviewCreatedDate: Scalars["String"];
  reviewDetail: Scalars["String"];
  reviewDetailSeeMoreClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  reviewFromOtherSite?: Maybe<Scalars["String"]>;
  travelerName: Scalars["String"];
  travelerNameClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated use travelerName instead */
  travelerNameLink: EgdsSpannableText;
  value: Scalars["String"];
};

export type SupplyScoreFactorReviewAuditTrailItemAccess = {
  __typename?: "SupplyScoreFactorReviewAuditTrailItemAccess";
  icon?: Maybe<Icon>;
  propertyId: Scalars["String"];
  reservationId: Scalars["String"];
  reviewId: Scalars["String"];
  text: Scalars["String"];
};

export type SupplyScoreFactorReviewAuditTrailSentimentFilterItem = {
  __typename?: "SupplyScoreFactorReviewAuditTrailSentimentFilterItem";
  pill: EgdsBasicPill;
};

export type SupplyScoreFactorScoreDisplay = {
  __typename?: "SupplyScoreFactorScoreDisplay";
  notificationHeader?: Maybe<SupplyScoreFactorNotification>;
  notificationState?: Maybe<Scalars["String"]>;
  stylizedValue: EgdsStylizedText;
  tooltip?: Maybe<SupplyScoreFactorScoreDisplayTooltip>;
};

export type SupplyScoreFactorScoreDisplayTooltip = {
  __typename?: "SupplyScoreFactorScoreDisplayTooltip";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  contents: Array<Scalars["String"]>;
  icon: Icon;
};

export type SupplyScoreFactorsEgClickstreamCustomAttribute = {
  __typename?: "SupplyScoreFactorsEgClickstreamCustomAttribute";
  factor_audit_trail_count?: Maybe<Scalars["Int"]>;
  factor_audit_trail_ids?: Maybe<Array<Scalars["String"]>>;
  factor_audit_trail_state?: Maybe<Scalars["String"]>;
  factor_badge_type?: Maybe<Scalars["String"]>;
  factor_card_position?: Maybe<Scalars["Int"]>;
  factor_card_target_link?: Maybe<Scalars["String"]>;
  factor_card_target_link_type?: Maybe<Scalars["String"]>;
  factor_competitive_set_rank?: Maybe<Scalars["Int"]>;
  factor_competitive_set_size?: Maybe<Scalars["Int"]>;
  factor_type?: Maybe<Scalars["String"]>;
  factor_value?: Maybe<Scalars["String"]>;
};

export type SupplyScoreFilterMenu = {
  __typename?: "SupplyScoreFilterMenu";
  title: Scalars["String"];
  trigger: SupplyScoreFilterMenuTrigger;
};

export type SupplyScoreFilterMenuItem = {
  __typename?: "SupplyScoreFilterMenuItem";
  action: SupplyScoreFilterMenuItemAction;
  text: Scalars["String"];
  value: Scalars["String"];
};

export type SupplyScoreFilterMenuItemAction = {
  __typename?: "SupplyScoreFilterMenuItemAction";
  selectedAnalytics?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFilterMenuTrigger = {
  __typename?: "SupplyScoreFilterMenuTrigger";
  accessibility: Scalars["String"];
  contentItems: Array<SupplyScoreFilterMenuItem>;
  text: Scalars["String"];
};

export type SupplyScoreLink = {
  __typename?: "SupplyScoreLink";
  clickstreamSelected: SupplyScoreEgClickstreamData;
  href: Scalars["String"];
  text: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type SupplyScoreLinkButton = {
  __typename?: "SupplyScoreLinkButton";
  action: UiLinkAction;
  button: EgdsButton;
};

export type SupplyScoreMessaging = {
  __typename?: "SupplyScoreMessaging";
  icon?: Maybe<Icon>;
  links: Array<EgdsStandardLink>;
  messages: Array<Scalars["String"]>;
  theme: SupplyScoreMessagingTheme;
};

export type SupplyScoreMessagingTheme = "INFO" | "MESSAGE" | "WARNING";

export type SupplyScoreNeedsAttentionAudit = {
  __typename?: "SupplyScoreNeedsAttentionAudit";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  linkHref?: Maybe<Scalars["String"]>;
  linkText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScoreNextBestFactorAudit = {
  __typename?: "SupplyScoreNextBestFactorAudit";
  icon: Icon;
  text: Scalars["String"];
  title: Scalars["String"];
};

export type SupplyScorePerformanceOverview = {
  __typename?: "SupplyScorePerformanceOverview";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  filterValue?: Maybe<Scalars["String"]>;
  items: Array<SupplyScorePerformanceOverviewItem>;
};

export type SupplyScorePerformanceOverviewItem = {
  __typename?: "SupplyScorePerformanceOverviewItem";
  competitiveSetRank?: Maybe<SupplyScoreCompsetRank>;
  /** @deprecated Use competitiveSetRank */
  compsetRank?: Maybe<EgdsStylizedText>;
  moreInfo: SupplyScoreToolTip;
  title: Scalars["String"];
  value: EgdsStylizedText;
};

export type SupplyScorePreviewFactorsAllInfo = {
  __typename?: "SupplyScorePreviewFactorsAllInfo";
  categoryScoreInfo: Array<SupplyScoreCategoryScoreInfo>;
  scorePreviewInfo: SupplyScoreDetailPreviewFactorsInfo;
};

export type SupplyScoreSection = {
  __typename?: "SupplyScoreSection";
  filterMenu?: Maybe<SupplyScoreFilterMenu>;
  items: Array<SupplyScoreSectionItem>;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SupplyScoreSectionItem = SupplyScoreCategory | SupplyScoreMessaging | SupplyScorePerformanceOverview;

export type SupplyScoreToolTip = {
  __typename?: "SupplyScoreToolTip";
  content: Array<Scalars["String"]>;
  trigger: EgdsIconText;
};

export type SupplyScoreTravelerInformativeError = {
  __typename?: "SupplyScoreTravelerInformativeError";
  description: Scalars["String"];
  icon: Icon;
  title?: Maybe<EgdsStylizedText>;
};

export type SupplyScoreValueFactor = {
  __typename?: "SupplyScoreValueFactor";
  action?: Maybe<SupplyScoreFactorAuditAction>;
  compsetRank?: Maybe<SupplyScoreCompsetRank>;
  notificationHeader?: Maybe<SupplyScoreFactorNotification>;
  notificationState?: Maybe<Scalars["String"]>;
  stylizedTitle: EgdsStylizedText;
  stylizedValue: EgdsStylizedText;
};

export type SupplySeeAllPropertiesButton = {
  __typename?: "SupplySeeAllPropertiesButton";
  action: UiLinkAction;
  disabled: Scalars["Boolean"];
  primary: Scalars["String"];
};

export type SupplySubscribeSignalAction = {
  __typename?: "SupplySubscribeSignalAction";
  signal: Scalars["String"];
};

export type SupplyTaxes = SupplyTaxesEditor | SupplyTaxesMessage;

export type SupplyTaxesAmountDetails = {
  __typename?: "SupplyTaxesAmountDetails";
  age: EgdsNumberInputField;
  ageBoxEnteredAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  ageBoxSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  ageTitle: Scalars["String"];
  amount: EgdsNumberInputField;
  amountBoxEnteredAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  amountBoxSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  collectAtCheckinRadioButtonSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export interface SupplyTaxesAmountInput {
  age: Scalars["Int"];
  amount: Scalars["Float"];
}

export type SupplyTaxesEditor = {
  __typename?: "SupplyTaxesEditor";
  errorMessagePresentedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  heading: Scalars["String"];
  nextButtonInitiatedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  optionDetails: Array<SupplyTaxesOption>;
  options: EgdsBasicRadioGroup;
  subheading?: Maybe<Scalars["String"]>;
  taxPageViewedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export interface SupplyTaxesInput {
  taxesAmount?: InputMaybe<SupplyTaxesAmountInput>;
  taxesPercent?: InputMaybe<SupplyTaxesPercentInput>;
  type: SupplyTaxesType;
}

export type SupplyTaxesLink = {
  __typename?: "SupplyTaxesLink";
  href: Scalars["String"];
  text: Scalars["String"];
};

export type SupplyTaxesMessage = {
  __typename?: "SupplyTaxesMessage";
  errorMessagePresentedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  message: Scalars["String"];
  nextButtonInitiatedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  taxMessagePageViewedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyTaxesNotInclude = {
  __typename?: "SupplyTaxesNotInclude";
  collectAsRequiredRadioButtonSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  message: Scalars["String"];
};

export type SupplyTaxesOption = {
  __typename?: "SupplyTaxesOption";
  optionDetails: SupplyTaxesOptionDetails;
  parent: SupplyTaxesType;
};

export type SupplyTaxesOptionDetails = SupplyTaxesAmountDetails | SupplyTaxesNotInclude | SupplyTaxesPercentDetails;

export type SupplyTaxesPercentDetails = {
  __typename?: "SupplyTaxesPercentDetails";
  collectDuringBookingRadioButtonSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  link?: Maybe<SupplyTaxesLink>;
  percent: EgdsNumberInputField;
  percentBoxEnteredAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  percentBoxSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export interface SupplyTaxesPercentInput {
  percent: Scalars["Float"];
}

export type SupplyTaxesType = "AMOUNT" | "NOT_INCLUDED" | "PERCENT";

export type SupplyTipCardSection = {
  __typename?: "SupplyTipCardSection";
  content: SupplyOnboardingTipContent;
  /** @deprecated use title */
  heading: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type SupplyTopTask = {
  __typename?: "SupplyTopTask";
  moduleTopTaskPresented: SupplyTopTasksEgClickstream;
  refreshSelected: SupplyTopTasksEgClickstream;
  tasks: Array<SupplyTopTaskCard>;
  /** @deprecated Use toptaskHeading instead */
  title?: Maybe<Scalars["String"]>;
  topTaskAdditionalCardsViewed: SupplyTopTasksEgClickstream;
  topTaskHeading?: Maybe<Scalars["String"]>;
};

export type SupplyTopTaskButton = {
  __typename?: "SupplyTopTaskButton";
  action: UiLinkAction;
  actionLabel: Scalars["String"];
  topTaskButtonSelected: SupplyTopTasksEgClickstream;
};

export type SupplyTopTaskCard = SupplyTopTaskDefaultCard | SupplyTopTaskPlaceholderCard;

export type SupplyTopTaskCardButtonAction =
  | EgdsDismissAction
  | SupplyTopTaskButton
  | SupplyTopTaskDismissButtonComponent;

export type SupplyTopTaskCardContent = {
  __typename?: "SupplyTopTaskCardContent";
  countDownExpiredAction?: Maybe<SupplyTopTaskButton>;
  countDownExpiredLabel?: Maybe<Scalars["String"]>;
  countDownTarget?: Maybe<Scalars["String"]>;
  icon: Icon;
  label: Scalars["String"];
  primary?: Maybe<Scalars["String"]>;
  secondaries: Array<SupplyTopTaskSecondaryElements>;
  title: EgdsStylizedText;
};

export type SupplyTopTaskDefaultCard = {
  __typename?: "SupplyTopTaskDefaultCard";
  buttons: Array<SupplyTopTaskCardButtonAction>;
  content: SupplyTopTaskCardContent;
  taskId: Scalars["String"];
  taskType?: Maybe<Scalars["String"]>;
  topTaskCardPresented: SupplyTopTasksEgClickstream;
  /** @deprecated Will be replaced by SupplyTopTaskDismissButtonComponent's clickstream */
  topTaskSecondaryActionSelected: SupplyTopTasksEgClickstream;
  topTaskType: TopTaskTypes;
};

export type SupplyTopTaskDismiss = {
  __typename?: "SupplyTopTaskDismiss";
  taskId?: Maybe<Scalars["String"]>;
};

export type SupplyTopTaskDismissButtonComponent = {
  __typename?: "SupplyTopTaskDismissButtonComponent";
  actionLabel: Scalars["String"];
  topTaskDismissButtonSelected: SupplyTopTasksEgClickstream;
};

export type SupplyTopTaskPlaceholderCard = {
  __typename?: "SupplyTopTaskPlaceholderCard";
  action?: Maybe<UiLinkAction>;
  actionLabel: Scalars["String"];
  image?: Maybe<Image>;
  primary?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  topTaskPlaceholderCardPresented?: Maybe<SupplyTopTasksEgClickstream>;
  topTaskPrimaryActionClicked?: Maybe<SupplyTopTasksEgClickstream>;
};

export type SupplyTopTaskSecondaryElements = {
  __typename?: "SupplyTopTaskSecondaryElements";
  simpleText?: Maybe<Scalars["String"]>;
  timeStamp?: Maybe<Scalars["String"]>;
};

export type SupplyTopTasksEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyTopTasksEgClickstream";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partnerCommon?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyUpdateNewListingOfferEnrollment = {
  __typename?: "SupplyUpdateNewListingOfferEnrollment";
  enrolled: Scalars["Boolean"];
};

export type SupplyUpdatePartnerPayoutDeferredAccount = {
  __typename?: "SupplyUpdatePartnerPayoutDeferredAccount";
  bankingCountry: Scalars["String"];
  currency: Scalars["String"];
  currencySymbol?: Maybe<Scalars["String"]>;
  merchantAccountUrl: Scalars["String"];
  payoutConfigUrl: Scalars["String"];
  subHeading?: Maybe<SupplyPropertyRatesSubHeadingUnion>;
  validation?: Maybe<EgdsInputValidation>;
};

export type SupplyUpdatePropertyRates = {
  __typename?: "SupplyUpdatePropertyRates";
  baseRate: Scalars["Float"];
};

export type SupplyVirtualMarketCoachEgClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyVirtualMarketCoachEgClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  virtual_market_coach?: Maybe<SupplyVmcEgClickstreamCustomAttribute>;
};

export type SupplyVmcEgClickstreamCustomAttribute = {
  __typename?: "SupplyVmcEGClickstreamCustomAttribute";
  /** @deprecated This field will be deprecated in the future, use tip_id instead */
  coaching_tip_id?: Maybe<Scalars["Int"]>;
  coaching_tip_proofpoint?: Maybe<SupplyCoachingTipProofPointEgClickstreamCustomAttribute>;
  coaching_tip_source_id?: Maybe<Scalars["String"]>;
  opportunities_available?: Maybe<Scalars["Int"]>;
  /** @deprecated This field will be deprecated in the future, use tip_sort_position instead */
  sort_position?: Maybe<Scalars["String"]>;
  tip_id?: Maybe<Scalars["String"]>;
  tip_sort_position?: Maybe<Scalars["Int"]>;
};

export type SupportingText = {
  __typename?: "SupportingText";
  size?: Maybe<Scalars["Int"]>;
  text?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
};

export type SweepstakesAction = {
  __typename?: "SweepstakesAction";
  action?: Maybe<UiLinkAction>;
  actionType?: Maybe<Scalars["String"]>;
  campaignId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  rightNavIcon?: Maybe<Icon>;
  shareText?: Maybe<Scalars["String"]>;
  standardBadge?: Maybe<EgdsStandardBadge>;
  triviaId?: Maybe<Scalars["String"]>;
  uiAction: AppGrowthUiAction;
};

export type SweepstakesBannerQuery = AppGrowthQueryImpressionSupportResponse &
  AppGrowthQueryResponse & {
    __typename?: "SweepstakesBannerQuery";
    clientSideAnalytics: ClientSideAnalytics;
    clientSideAnalyticsImpression: ClientSideAnalytics;
    clientSideErrorDialog: AppGrowthDialog;
    logo?: Maybe<AppGrowthMediaItem>;
    primaryButton?: Maybe<AppGrowthButton>;
    progressBar?: Maybe<EgdsProgressBar>;
    serverSideErrorDialog?: Maybe<AppGrowthDialog>;
    summary?: Maybe<AppGrowthSummary>;
  };

export type SweepstakesBodySection = {
  __typename?: "SweepstakesBodySection";
  actions?: Maybe<Array<SweepstakesAction>>;
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
};

export type SweepstakesDashboardQuery = AppGrowthQueryResponse & {
  __typename?: "SweepstakesDashboardQuery";
  bodySection?: Maybe<SweepstakesBodySection>;
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  footerSection?: Maybe<SweepstakesFooterSection>;
  headerSection?: Maybe<SweepstakesHeaderSection>;
  notification?: Maybe<AppGrowthActionNotification>;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
  signupPrompt?: Maybe<SweepstakesSignupPrompt>;
  userNotificationState?: Maybe<UserNotificationState>;
};

export type SweepstakesDisenrollFailureResponse = {
  __typename?: "SweepstakesDisenrollFailureResponse";
  message: Scalars["String"];
};

export type SweepstakesDisenrollResponse = SweepstakesDisenrollFailureResponse | SweepstakesDisenrollSuccessResponse;

export type SweepstakesDisenrollSuccessResponse = {
  __typename?: "SweepstakesDisenrollSuccessResponse";
  message: Scalars["String"];
};

export type SweepstakesFooterSection = {
  __typename?: "SweepstakesFooterSection";
  content: EgdsSpannableText;
};

export type SweepstakesHeaderSection = {
  __typename?: "SweepstakesHeaderSection";
  /** @deprecated Sweepstakes header shouldn't be using this field and instead be using navigation. */
  backToHome?: Maybe<AppGrowthTextIconLinkListItem>;
  backgroundImageUrl?: Maybe<AppGrowthMediaItem>;
  date?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  iconLogo?: Maybe<AppGrowthMediaItem>;
  link?: Maybe<EgdsStandardLink>;
  name?: Maybe<Scalars["String"]>;
  navigation?: Maybe<AppGrowthNavigation>;
  progressBar?: Maybe<EgdsProgressBar>;
};

export type SweepstakesSignupPrompt = {
  __typename?: "SweepstakesSignupPrompt";
  backgroundImageUrl?: Maybe<AppGrowthMediaItem>;
  /** @deprecated Field marked as deprecated. It is replaced by backgroundImageUrl. */
  backgroundLogo?: Maybe<AppGrowthMediaItem>;
  checkbox: EgdsCheckBox;
  clientSideAnalytics: ClientSideAnalytics;
  clientSideAnalyticsLogin?: Maybe<ClientSideAnalytics>;
  /** @deprecated This should not be used anymore. Field to be replaced by navigation. */
  closeAction?: Maybe<AppGrowthTextIconLinkListItem>;
  content?: Maybe<EgdsSpannableText>;
  /** @deprecated don't use anymore - use content now */
  detailsText?: Maybe<Scalars["String"]>;
  iconLogo?: Maybe<AppGrowthMediaItem>;
  navigation?: Maybe<AppGrowthNavigation>;
  primaryButton?: Maybe<AppGrowthButton>;
  summary?: Maybe<AppGrowthSummary>;
};

export type SweepstakesUserActionHistoryQuery = {
  __typename?: "SweepstakesUserActionHistoryQuery";
  actionData: Array<ActionHistory>;
  maxEntries: Scalars["Int"];
  /** @deprecated Don't use this anymore */
  randomUser: Scalars["String"];
};

export type SweepstakesUserWipeQuery = {
  __typename?: "SweepstakesUserWipeQuery";
  ids: Array<Scalars["String"]>;
};

export type TaapAcademyComponent = {
  __typename?: "TaapAcademyComponent";
  academyLink?: Maybe<EgdsInlineLink>;
  description: Scalars["String"];
  heading: Scalars["String"];
  iconUrl: Scalars["String"];
};

export type TabStatus = "NOT_SELECTED" | "SELECTED";

export type TabType = "EQUAL_WIDTH" | "NATURAL";

export type Tag = {
  __typename?: "Tag";
  label: Scalars["String"];
};

export type TargetingCategories = {
  __typename?: "TargetingCategories";
  heading: EgdsHeading;
  items: EgdsBulletedList;
};

export type TelUri = Uri & {
  __typename?: "TelURI";
  phoneNumber: Scalars["String"];
  value: Scalars["String"];
};

export type Telesales = {
  __typename?: "Telesales";
  description: LodgingPlainMessage;
  telesalesNumber: TelUri;
  tracking: ClientSideAnalytics;
};

export type TemperatureRange = {
  __typename?: "TemperatureRange";
  maximum?: Maybe<Scalars["String"]>;
  minimum?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Template = {
  __typename?: "Template";
  components: Array<TemplateComponent>;
  id?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export interface TemplateAnalytics {
  analytics: ClientSideAnalytics;
  analyticsIdentifier: AnalyticsKey;
}

export type TemplateComponent = {
  __typename?: "TemplateComponent";
  children: Array<TemplateComponent>;
  config: ComponentConfig;
  metadata: ComponentMetaData;
  type: Scalars["String"];
};

export type TemplateModel = {
  __typename?: "TemplateModel";
  key: Scalars["String"];
  source: Scalars["String"];
};

export type TermsAndConditions = {
  __typename?: "TermsAndConditions";
  body: Scalars["String"];
  linkText: Scalars["String"];
  title: Scalars["String"];
};

export type TermsAndConditionsContent = {
  __typename?: "TermsAndConditionsContent";
  errorMessage?: Maybe<Scalars["String"]>;
  sections: Array<CouponDetailsContainer>;
  title?: Maybe<Scalars["String"]>;
};

export interface TextBreadcrumb {
  text: Scalars["String"];
  uiLinkAction?: Maybe<UiLinkAction>;
}

export type TextDirection = "LTR" | "RTL";

export type TextTheme = "EMPHASIS" | "INVERSE" | "LOYALTY" | "NEGATIVE" | "POSITIVE" | "PRIMARY" | "SECONDARY";

export type TextWeight = "BOLD" | "MEDIUM" | "REGULAR";

export type TextWithFile = TravelExperienceContent & {
  __typename?: "TextWithFile";
  content: Array<Maybe<EgdsElement>>;
  file?: Maybe<EgdsStandardLink>;
  id: Scalars["ID"];
};

export type TextWrapper = {
  __typename?: "TextWrapper";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  text: EgdsText;
};

export type TextWrapperFlightsPhrase = {
  __typename?: "TextWrapperFlightsPhrase";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  sustainabilityFlightPhrase: Array<FlightsPhrase>;
};

export type Theme =
  | "BRAND"
  | "BRANDED_DEAL"
  | "DEAL_ADD_ON"
  | "DEAL_BUNDLED"
  | "DEAL_GENERIC"
  | "DEAL_MEMBER"
  | "FAMILY_FRIENDLY"
  | "INFO"
  | "LINK"
  | "LOYALTY"
  | "LOYALTY_EXTRA_HIGH_TIER"
  | "LOYALTY_GLOBAL_EXTRA_HIGH_TIER"
  | "LOYALTY_GLOBAL_HIGH_TIER"
  | "LOYALTY_GLOBAL_LOW_TIER"
  | "LOYALTY_GLOBAL_MIDDLE_TIER"
  | "LOYALTY_HIGH_TIER"
  | "LOYALTY_LOW_TIER"
  | "LOYALTY_MIDDLE_TIER"
  | "NOTIFICATION"
  | "PROMOTED"
  | "SAVED"
  | "SPONSORED"
  | "STANDARD"
  | "SUCCESS"
  | "SUCCESS_MEDIUM"
  | "SUPPLIER_PROMO"
  | "URGENT"
  | "VIEWED"
  | "VIP"
  | "VIP_ACCESS";

export type Timezone = {
  __typename?: "Timezone";
  name: Scalars["String"];
  offset: Scalars["String"];
};

export type TogglePhase = {
  __typename?: "TogglePhase";
  accessibilityMessage?: Maybe<Scalars["String"]>;
  action: Scalars["String"];
  analytics: FlightsAnalytics;
  icon?: Maybe<Icon>;
};

export type ToggleableCardContentSection = {
  __typename?: "ToggleableCardContentSection";
  attachSwitchToggle?: Maybe<FlightsSwitchToggle>;
  description?: Maybe<Scalars["String"]>;
  infoDialogTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
  /** @deprecated Use infoDialogTrigger to benefit from dialog query component */
  infoTrigger: FlightsDialogTrigger;
  price?: Maybe<Scalars["String"]>;
  /** @deprecated Use description instead of subHeading favoring nullability */
  subHeading: Scalars["String"];
  /** @deprecated Use attachToggle instead of switchToggle favoring nullability */
  switchToggle: FlightsSwitchToggle;
};

export type ToggleableCardHeadingSection = {
  __typename?: "ToggleableCardHeadingSection";
  badge?: Maybe<EgdsLoyaltyBadge>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use graphic and title for more flexibility */
  header: EgdsGraphicText;
  price?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ToolbarNavType = "CLOSE" | "EXPAND_DOWN" | "EXPAND_UP";

export interface TopTaskAction {
  cardAction?: InputMaybe<CardActions>;
  taskId: Scalars["String"];
  taskType?: InputMaybe<Scalars["String"]>;
}

export interface TopTaskCardAction {
  cardAction: CardActions;
  taskId: Scalars["String"];
  taskType?: InputMaybe<Scalars["String"]>;
  topTaskType: TopTaskTypes;
}

export type TopTaskTypes =
  | "ACCELERATOR_OPPORTUNITY"
  | "ADD_AMENITIES"
  | "ADD_MINIMUM_STAY"
  | "ADD_PHOTOS"
  | "ARRIVING"
  | "BANK_DETAILS"
  | "BOOKING_REQUEST"
  | "BOOKING_SMS_OPT_IN"
  | "CANCELLATION"
  | "CANCELLATION_REQUEST"
  | "CONFIRM_CONTACT"
  | "CONFIRM_IDENTITY"
  | "CURRENT_STAY"
  | "DIRECT_DEBIT"
  | "INQUIRY"
  | "LOWER_FEES"
  | "MINIMUMINVENTORY_OPPORTUNITY"
  | "MOBILEDRR_OPPORTUNITY"
  | "OPPORTUNITY"
  | "RATE_TRAVELER"
  | "TAX_FORM"
  | "TMODDRR_OPPORTUNITY"
  | "UPDATE_TAX_INFORMATION"
  | "VRPAYMENTS_TIP";

export type TopTenProductsCarouselComponent = {
  __typename?: "TopTenProductsCarouselComponent";
  carousel: ActivityCarouselContainer;
  heading?: Maybe<Scalars["String"]>;
  seeAllLink?: Maybe<EgdsStandardLink>;
};

export type TotalPriceSummary = {
  __typename?: "TotalPriceSummary";
  amount?: Maybe<LodgingEnrichedMessage>;
  label?: Maybe<LodgingEnrichedMessage>;
};

export type TrackingData = {
  __typename?: "TrackingData";
  totalPriceAmount: Scalars["Float"];
  totalPriceCurrencyCode: Scalars["String"];
};

export type TransparencyEntity = {
  __typename?: "TransparencyEntity";
  expando?: Maybe<EgdsExpandoPeek>;
  label: Scalars["String"];
  names: Scalars["String"];
};

export type TransparencyOverlay = {
  __typename?: "TransparencyOverlay";
  callToAction: EgdsInlineLink;
  entities: Array<TransparencyEntity>;
  targetingCategories: TargetingCategories;
};

export type TransparencyOverlayError = {
  __typename?: "TransparencyOverlayError";
  allowedRetryAttempts?: Maybe<Scalars["Int"]>;
  button?: Maybe<UiPrimaryButton>;
  description: Scalars["String"];
  exceededAttemptsMessage?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
};

export type TransparencyOverlaySkeleton = {
  __typename?: "TransparencyOverlaySkeleton";
  error: TransparencyOverlayError;
  title: Scalars["String"];
};

export type TravelAgentAuthenticationOptionsResponse = {
  __typename?: "TravelAgentAuthenticationOptionsResponse";
  joinNow: UiSecondaryButton;
  logo: Mark;
  signIn: UiPrimaryButton;
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type TravelAgentDashboard = {
  __typename?: "TravelAgentDashboard";
  heading?: Maybe<Scalars["String"]>;
  sections: Array<TravelAgentDashboardSection>;
};

export type TravelAgentDashboardLabelValue = {
  __typename?: "TravelAgentDashboardLabelValue";
  label?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type TravelAgentDashboardProgress = {
  __typename?: "TravelAgentDashboardProgress";
  progressBar?: Maybe<EgdsProgressBar>;
  progressBarNote?: Maybe<Scalars["String"]>;
};

export type TravelAgentDashboardSection = {
  __typename?: "TravelAgentDashboardSection";
  cardActionLink?: Maybe<ActionLink>;
  cardLink?: Maybe<UiLinkAction>;
  clickableActionLink?: Maybe<ActionLink>;
  clickableLink?: Maybe<EgdsStandardLink>;
  content?: Maybe<TravelAgentDashboardSectionContent>;
  heading?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  subheading?: Maybe<Scalars["String"]>;
};

export type TravelAgentDashboardSectionContent = TravelAgentDashboardProgress | TravelAgentDashboardSubheadingList;

export type TravelAgentDashboardSubheadingList = {
  __typename?: "TravelAgentDashboardSubheadingList";
  labelValues?: Maybe<Array<TravelAgentDashboardLabelValue>>;
  subheadings: Array<Maybe<Scalars["String"]>>;
};

export type TravelAgentPackageRatesDetail = {
  __typename?: "TravelAgentPackageRatesDetail";
  heading?: Maybe<Scalars["String"]>;
  termsAndConditions: Scalars["String"];
};

export type TravelAgentSearchWizardConfig = {
  __typename?: "TravelAgentSearchWizardConfig";
  enabledLobs: Array<Scalars["String"]>;
  packageRatesDetail?: Maybe<TravelAgentPackageRatesDetail>;
  packageRatesEnabled: Scalars["Boolean"];
};

export interface TravelContentAttributesInput {
  contentId: Scalars["String"];
  preview: Scalars["Boolean"];
}

export interface TravelContentUriContextInput {
  attributes: UriContextAttributesInput;
  identifiers: UriContextIdentifiersInput;
}

export interface TravelExperienceContent {
  id: Scalars["ID"];
}

export type TravelExperienceContentResults = {
  __typename?: "TravelExperienceContentResults";
  editorialComponent?: Maybe<EditorialComponent>;
  portableTextDocumentWithLink?: Maybe<PortableTextWithLink>;
};

export type TravelExperienceHero = TravelExperienceContent & {
  __typename?: "TravelExperienceHero";
  alt?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image: Image;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<EgdsText>>;
};

export type TravelGuide = {
  __typename?: "TravelGuide";
  url?: Maybe<Scalars["URL"]>;
};

export interface TravelGuidePageContextInput {
  pageType: PageType;
  placeId?: InputMaybe<Scalars["String"]>;
}

export interface TravelMemberGroupInput {
  adults: Scalars["Int"];
  childrenAges?: InputMaybe<Array<Scalars["Int"]>>;
}

export interface TravelSearchCriteriaInput {
  activity?: InputMaybe<ActivitiySearchCriteriaInput>;
  property?: InputMaybe<PropertySearchCriteriaInput>;
}

export interface TravelStoreV1CarDetailsInput {
  cleaningSafetyInformation: Scalars["Boolean"];
  periodPricing: Scalars["Boolean"];
  showAds: Scalars["Boolean"];
}

export interface TravelStoreV1CarSearchInput {
  cleaningSafetyInformation: Scalars["Boolean"];
  defaultSortOption: Scalars["String"];
  filterOptions: Array<Scalars["String"]>;
  messagingOptions: Array<Scalars["String"]>;
  periodPricing: Scalars["Boolean"];
  showAds: Scalars["Boolean"];
  sortOptions: Array<Scalars["String"]>;
}

export type TravelTriviaBannerQuery = AppGrowthQueryImpressionSupportResponse &
  AppGrowthQueryResponse & {
    __typename?: "TravelTriviaBannerQuery";
    clientSideAnalytics: ClientSideAnalytics;
    clientSideAnalyticsImpression: ClientSideAnalytics;
    clientSideErrorDialog: AppGrowthDialog;
    logo?: Maybe<AppGrowthMediaItem>;
    primaryButton?: Maybe<AppGrowthButton>;
    progressBar?: Maybe<EgdsProgressBar>;
    serverSideErrorDialog?: Maybe<AppGrowthDialog>;
    summary?: Maybe<AppGrowthSummary>;
  };

export type Traveler = {
  __typename?: "Traveler";
  age?: Maybe<Scalars["Int"]>;
  ageCategory?: Maybe<Scalars["Int"]>;
  primary?: Maybe<Scalars["Boolean"]>;
};

export type TravelerCouponActive = {
  __typename?: "TravelerCouponActive";
  bookingDateEnd: Scalars["String"];
  discount: Scalars["String"];
  logo: Mark;
  name: Scalars["String"];
  termsAndConditions: UiTertiaryButton;
  travelDateEnd: Scalars["String"];
  useCoupon: UiTertiaryButton;
};

export type TravelerCouponUsedOrExpired = {
  __typename?: "TravelerCouponUsedOrExpired";
  discount: Scalars["String"];
  logo: Mark;
  name: Scalars["String"];
  usedOrExpiredDate: Scalars["String"];
};

export type TravelerCoupons = TravelerCouponsError | TravelerCouponsSuccess;

export type TravelerCouponsActiveView = {
  __typename?: "TravelerCouponsActiveView";
  coupons?: Maybe<Array<TravelerCouponActive>>;
  noCouponsDescription?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type TravelerCouponsContent = {
  __typename?: "TravelerCouponsContent";
  activeCoupons: TravelerCouponsActiveView;
  description: Scalars["String"];
  howTo: EgdsStandardLink;
  usedOrExpiredCoupons?: Maybe<TravelerCouponsUsedOrExpiredView>;
};

export type TravelerCouponsError = {
  __typename?: "TravelerCouponsError";
  error: Error;
};

export type TravelerCouponsSuccess = {
  __typename?: "TravelerCouponsSuccess";
  container: EgdsFullScreenDialog;
  content: TravelerCouponsContent;
};

export type TravelerCouponsUsedOrExpiredView = {
  __typename?: "TravelerCouponsUsedOrExpiredView";
  coupons: Array<TravelerCouponUsedOrExpired>;
  seeCoupons: EgdsExpandoLink;
};

export type TravelerDetails = {
  __typename?: "TravelerDetails";
  age?: Maybe<Scalars["Int"]>;
  type: TravelerDetailsType;
};

export interface TravelerDetailsInput {
  age?: InputMaybe<Scalars["Int"]>;
  type: TravelerDetailsType;
}

export type TravelerDetailsInputType = {
  __typename?: "TravelerDetailsInputType";
  age?: Maybe<Scalars["Int"]>;
  type: TravelerDetailsType;
};

export type TravelerDetailsType = "ADULT" | "CHILD" | "INFANT_IN_LAP" | "INFANT_IN_SEAT";

export type TravelerOccupancyGroup = {
  __typename?: "TravelerOccupancyGroup";
  travelers: Array<TravelerDetails>;
};

export interface TravelerOccupancyGroupInput {
  travelers: Array<TravelerDetailsInput>;
}

export type TravelerSelectorForm = {
  __typename?: "TravelerSelectorForm";
  submitButton: UiPrimaryButton;
  title?: Maybe<LodgingEnrichedMessage>;
  unitGroups: Array<LodgingUnitGroup>;
};

export type TravelerType = "ADULTS_ONLY" | "BUSINESS" | "ECO_CERTIFIED" | "FAMILY" | "LGBT" | "ROMANTIC";

export interface TravellerAncillarySelectionInput {
  ancillaryTokens: Array<Scalars["String"]>;
  travelerIndex: Scalars["Int"];
}

export type TravellerClickAction = {
  __typename?: "TravellerClickAction";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  clickEvent?: Maybe<EgClickstreamEvent>;
};

export type TravellerSearch = {
  __typename?: "TravellerSearch";
  heading?: Maybe<Scalars["String"]>;
  impressionAnalytics?: Maybe<TravellerClickAction>;
  paragraph?: Maybe<Scalars["String"]>;
  searchAction?: Maybe<TravellerClickAction>;
  title?: Maybe<Scalars["String"]>;
  travellerSearchDialog?: Maybe<TravellerSearchDialog>;
  travellerSearchDialogTrigger?: Maybe<TravellerSearchDialogTrigger>;
  travellerSearchInput?: Maybe<TravellerSearchInput>;
};

export type TravellerSearchButton = {
  __typename?: "TravellerSearchButton";
  button: UiPrimaryButton;
  searchAction?: Maybe<TravellerClickAction>;
};

export type TravellerSearchDialog = {
  __typename?: "TravellerSearchDialog";
  closeAction?: Maybe<TravellerClickAction>;
  dialogToolbar?: Maybe<EgdsDialogToolbar>;
  errorMessage?: Maybe<EgdsErrorSummary>;
  loadingStateAccessibility?: Maybe<Scalars["String"]>;
  scrollAction?: Maybe<TravellerClickAction>;
  /** @deprecated Deprecated in favour of travellerSearchInput */
  searchAction?: Maybe<TravellerClickAction>;
  /** @deprecated Deprecated in favour of travellerSearchInput */
  searchBox?: Maybe<EgdsTextInputField>;
  /** @deprecated Deprecated in favour of travellerSearchInput */
  searchButton?: Maybe<UiPrimaryButton>;
  travellerSearchInput?: Maybe<TravellerSearchInput>;
};

export type TravellerSearchDialogTrigger = {
  __typename?: "TravellerSearchDialogTrigger";
  button: UiSecondaryButton;
};

export type TravellerSearchFeedback = {
  __typename?: "TravellerSearchFeedback";
  title?: Maybe<Scalars["String"]>;
  travellerSearchFeedbackActions?: Maybe<Array<TravellerSearchFeedbackAction>>;
};

export type TravellerSearchFeedbackAction = {
  __typename?: "TravellerSearchFeedbackAction";
  clickEvent?: Maybe<EgClickstreamEvent>;
  directFeedbackCallToAction?: Maybe<DirectFeedbackCallToAction>;
};

export type TravellerSearchInput = {
  __typename?: "TravellerSearchInput";
  searchButton: TravellerSearchButton;
  searchInputField: TravellerSearchInputField;
};

export type TravellerSearchInputField = {
  __typename?: "TravellerSearchInputField";
  inputAction?: Maybe<TravellerClickAction>;
  textInput: EgdsTextInputField;
};

export type TravellerSearchResultsResponse = {
  __typename?: "TravellerSearchResultsResponse";
  accessibility?: Maybe<Scalars["String"]>;
  content?: Maybe<TravellerSearchSummary>;
  disclaimerText?: Maybe<Scalars["String"]>;
  noResultsMessage?: Maybe<Scalars["String"]>;
  review?: Maybe<TravellerSearchSummary>;
};

export type TravellerSearchSummary = {
  __typename?: "TravellerSearchSummary";
  heading?: Maybe<Scalars["String"]>;
  highlightedText: EgdsSpannableText;
  items?: Maybe<Array<ReviewSummary>>;
  subHeading?: Maybe<Scalars["String"]>;
  travellerSearchFeedback?: Maybe<TravellerSearchFeedback>;
};

export type Trip = {
  __typename?: "Trip";
  creation: TripsViewResponse;
  edit: TripsViewResponse;
  getPropertyDetails?: Maybe<PropertyItemDetails>;
  getTripItemDetails?: Maybe<TripItemHeader>;
  invite: TripsViewResponse;
  itemDetails: TripsViewResponse;
  itemEmailItinerary: TripsViewResponse;
  itemEssentialInfo: TripsViewResponse;
  itemItinerary: TripsViewResponse;
  itemManageBooking: TripsViewResponse;
  itemPricingAndRewards: TripsViewResponse;
  itemReceipt: TripsViewResponse;
  itemVoucher: TripsViewResponse;
  itemVoucherPrint: TripsViewResponse;
  manageGuests: TripsViewResponse;
  moveTripItem: TripsViewResponse;
  overview: TripsViewResponse;
  overviewItinerary: TripsViewResponse;
  pendingInvite: TripsViewResponse;
  savedItemPrice: TripsSavedItemPrice;
};

export type TripCreationArgs = {
  metadata?: InputMaybe<TripCreationMetadataInput>;
  source?: InputMaybe<TripCreationEntryPoint>;
};

export type TripEditArgs = {
  overview: TripOverviewInput;
};

export type TripGetPropertyDetailsArgs = {
  locale: Scalars["String"];
  propertyId: Scalars["String"];
};

export type TripGetTripItemDetailsArgs = {
  tripItemId: Scalars["String"];
  tripViewId: Scalars["String"];
};

export type TripInviteArgs = {
  overview: TripOverviewInput;
};

export type TripItemDetailsArgs = {
  item: TripItemInput;
  userToken?: InputMaybe<Scalars["String"]>;
};

export type TripItemEmailItineraryArgs = {
  item: TripItemInput;
};

export type TripItemEssentialInfoArgs = {
  essentialItemInput: EssentialInfoItemInput;
  item: TripItemInput;
};

export type TripItemItineraryArgs = {
  item: TripItemInput;
};

export type TripItemManageBookingArgs = {
  item: TripItemInput;
};

export type TripItemPricingAndRewardsArgs = {
  item: TripItemInput;
};

export type TripItemReceiptArgs = {
  item: TripItemInput;
};

export type TripItemVoucherArgs = {
  item: TripItemInput;
};

export type TripItemVoucherPrintArgs = {
  item: TripItemInput;
};

export type TripManageGuestsArgs = {
  overview: TripOverviewInput;
};

export type TripMoveTripItemArgs = {
  item: TripItemInput;
};

export type TripOverviewArgs = {
  overview: TripOverviewInput;
  userToken?: InputMaybe<Scalars["String"]>;
};

export type TripOverviewItineraryArgs = {
  overview: TripOverviewInput;
};

export type TripPendingInviteArgs = {
  tripInvite: TripInviteInput;
};

export type TripSavedItemPriceArgs = {
  item: TripItemInput;
};

export type TripCareAttributes = {
  __typename?: "TripCareAttributes";
  orderLineNumbers?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Scalars["String"]>;
};

export type TripCareBanner = {
  __typename?: "TripCareBanner";
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<TripCareLinkPhrase>;
  message: Scalars["String"];
};

export type TripCareButton = {
  __typename?: "TripCareButton";
  action: TripCareButtonAction;
  icon: Icon;
  state?: Maybe<TripCareButtonState>;
  tag: Scalars["String"];
};

export type TripCareButtonAction = "BACK" | "CANCEL_POLICY" | "DISMISS_POPUP" | "POPUP" | "RELOAD";

export type TripCareButtonState = "DISABLED" | "ENABLED";

export type TripCareCountryPhoneComponent = {
  __typename?: "TripCareCountryPhoneComponent";
  countryName: TripCareTextPhrase;
  phoneNumber: TripCarePhoneNumberComponent;
};

export type TripCareCountryPhoneListComponent = {
  __typename?: "TripCareCountryPhoneListComponent";
  countryPhoneComponents: Array<TripCareCountryPhoneComponent>;
};

export type TripCareDialog = {
  __typename?: "TripCareDialog";
  footer: TripCareDialogFooter;
  header: TripCareHeader;
  section: TripCareSection;
  tag?: Maybe<TripCareTextPhrase>;
  uniqueDialogId: Scalars["String"];
};

export type TripCareDialogFooter = {
  __typename?: "TripCareDialogFooter";
  buttonOne: TripCareButton;
  buttonThree?: Maybe<TripCareButton>;
  buttonTwo?: Maybe<TripCareButton>;
  stacked: Scalars["Boolean"];
};

export type TripCareEmailComponent = {
  __typename?: "TripCareEmailComponent";
  emailId: Scalars["String"];
  icon: Icon;
  message: TripCareTextPhrase;
};

export type TripCareError = {
  __typename?: "TripCareError";
  errorBody?: Maybe<TripCareSection>;
  errorCode: Scalars["String"];
  errorMessage: Scalars["String"];
  httpStatus: HttpStatus;
};

export type TripCareExpando = {
  __typename?: "TripCareExpando";
  analytics?: Maybe<ClientSideAnalytics>;
  details: TripCareSection;
  heading: TripCareTextPhrase;
  isVisible: Scalars["Boolean"];
};

export type TripCareExpandoPeek = {
  __typename?: "TripCareExpandoPeek";
  analytics?: Maybe<ClientSideAnalytics>;
  collapseLabel: Scalars["String"];
  details: TripCareSectionComponent;
  expandLabel: Scalars["String"];
};

export type TripCareFullScreenDialog = {
  __typename?: "TripCareFullScreenDialog";
  header: TripCareHeader;
  section: TripCareSection;
  tag: Scalars["String"];
  uniqueDialogId: Scalars["String"];
};

export type TripCareHeader = {
  __typename?: "TripCareHeader";
  additionalHeaders: Array<TripCarePhrase>;
  heading: TripCareTextPhrase;
  subHeading?: Maybe<TripCareTextPhrase>;
};

export type TripCareLayoutCard = {
  __typename?: "TripCareLayoutCard";
  columnNumber: Scalars["Int"];
  section: TripCareSectionComponent;
};

export type TripCareLayoutComponent = TripCareLayoutCard | TripCareLayoutSection;

export type TripCareLayoutSection = {
  __typename?: "TripCareLayoutSection";
  columnNumber: Scalars["Int"];
  section: TripCareSectionComponent;
};

export type TripCareLayoutStyle = "CARD_BORDER_TOP";

export type TripCareLinkPhrase = {
  __typename?: "TripCareLinkPhrase";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"];
  phraseType?: Maybe<TripCarePhraseType>;
  styles?: Maybe<Array<TripCarePhraseStyle>>;
  url: HttpUri;
};

export type TripCareListComponent = {
  __typename?: "TripCareListComponent";
  contentList: Array<TripCarePhrase>;
  heading?: Maybe<TripCareTextPhrase>;
  listType: TripCareListType;
  subHeading?: Maybe<TripCareTextPhrase>;
};

export type TripCareListType = "BULLET" | "NO_BULLET" | "NUMBERED";

export type TripCareOverviewSection = {
  __typename?: "TripCareOverviewSection";
  description: Scalars["String"];
  heading: Scalars["String"];
  link: TripCareLinkPhrase;
};

export type TripCarePhoneNumberComponent = {
  __typename?: "TripCarePhoneNumberComponent";
  icon?: Maybe<Icon>;
  identifier: Scalars["String"];
  message?: Maybe<TripCareSentence>;
  phone: Scalars["String"];
  regionDetails?: Maybe<TripCareTextPhrase>;
};

export type TripCarePhrase =
  | TripCareDialog
  | TripCareFullScreenDialog
  | TripCareLinkPhrase
  | TripCareSentence
  | TripCareTextPhrase;

export type TripCarePhraseStyle =
  | "BOLD"
  | "HR_DIVIDER_BOTTOM"
  | "HR_DIVIDER_TOP"
  | "SIZE_100"
  | "SIZE_200"
  | "SIZE_300"
  | "SIZE_400"
  | "SIZE_500"
  | "SIZE_600"
  | "SIZE_700"
  | "SIZE_800"
  | "SIZE_900"
  | "SMALL"
  | "SPACING_FOUR"
  | "SPACING_HALF"
  | "SPACING_ONE"
  | "SPACING_SIX"
  | "SPACING_THREE"
  | "SPACING_TWO"
  | "THEME_EMPHASIS"
  | "THEME_NEGATIVE"
  | "THEME_POSITIVE"
  | "WEIGHT_BOLD"
  | "WEIGHT_MEDIUM"
  | "WEIGHT_REGULAR";

export type TripCarePhraseType = "BUTTON" | "LINK" | "TEXT";

export type TripCareSection = {
  __typename?: "TripCareSection";
  footer?: Maybe<Array<TripCarePhrase>>;
  heading?: Maybe<TripCareTextPhrase>;
  sections?: Maybe<Array<TripCareSectionComponent>>;
  styles?: Maybe<Array<TripCareLayoutStyle>>;
  subHeading?: Maybe<TripCareTextPhrase>;
};

export type TripCareSectionComponent =
  | TripCareBanner
  | TripCareButton
  | TripCareCountryPhoneComponent
  | TripCareCountryPhoneListComponent
  | TripCareDialog
  | TripCareDialogFooter
  | TripCareEmailComponent
  | TripCareExpando
  | TripCareExpandoPeek
  | TripCareFullScreenDialog
  | TripCareLinkPhrase
  | TripCareListComponent
  | TripCareOverviewSection
  | TripCarePhoneNumberComponent
  | TripCareSection
  | TripCareSentence
  | TripCareTextPhrase;

export type TripCareSentence = {
  __typename?: "TripCareSentence";
  phrases: Array<TripCarePhrase>;
  styles?: Maybe<Array<TripCarePhraseStyle>>;
};

export type TripCareTextPhrase = {
  __typename?: "TripCareTextPhrase";
  content: Scalars["String"];
  icon?: Maybe<Icon>;
  phraseType?: Maybe<TripCarePhraseType>;
  styles?: Maybe<Array<TripCarePhraseStyle>>;
};

export type TripCarousel = {
  __typename?: "TripCarousel";
  accessibilityHeader?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  header?: Maybe<Scalars["String"]>;
  nextButton: TripCarouselControlButton;
  peek?: Maybe<Scalars["Boolean"]>;
  prevButton: TripCarouselControlButton;
};

export type TripCarouselControlButton = {
  __typename?: "TripCarouselControlButton";
  accessibility: Scalars["String"];
};

export type TripCategory = {
  __typename?: "TripCategory";
  header: Scalars["String"];
  tabTitle: Scalars["String"];
  tripInfos?: Maybe<Array<HelpCenterTripInfo>>;
};

export type TripContextualCardCarousel = {
  __typename?: "TripContextualCardCarousel";
  carousel: TripCarousel;
  content: Array<TripsContextualMessagingCard>;
};

export type TripCreationEntryPoint = "HOTEL_SEARCH_RESULTS" | "TRIPS_LIST" | "TRIP_OVERVIEW";

export type TripCreationMetadata = {
  __typename?: "TripCreationMetadata";
  /** @deprecated TripCreationEntryPoint has been deprecated and will be removed in future */
  entryPoint: TripCreationEntryPoint;
  /** @deprecated itemId has been deprecated., replace with saveItemInput.itemId */
  itemId?: Maybe<Scalars["String"]>;
  moveItem?: Maybe<TripItem>;
  saveItemInput?: Maybe<TripsPlan>;
};

export interface TripCreationMetadataInput {
  entryPoint?: InputMaybe<TripCreationEntryPoint>;
  itemId?: InputMaybe<Scalars["String"]>;
  moveItemInput?: InputMaybe<TripItemInput>;
  saveItemInput?: InputMaybe<TripsPlanInput>;
}

export interface TripDetailsSupportRequestsInput {
  buttonStyle: EgdsButtonStyle;
  itineraryNumber: Scalars["String"];
  lineOfBusiness: Scalars["String"];
  orderLineId: Scalars["String"];
}

export type TripEntity = "ACTIVITY_PRODUCT" | "CAR_PRODUCT" | "FLIGHT_SEARCH" | "LODGING_PRODUCT" | "PACKAGE_PRODUCT";

export type TripIntentAirport = {
  __typename?: "TripIntentAirport";
  cityRegionId?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
};

export type TripIntentDestination = {
  __typename?: "TripIntentDestination";
  airport?: Maybe<TripIntentAirport>;
  coordinates?: Maybe<Coordinates>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type TripIntentOrigin = {
  __typename?: "TripIntentOrigin";
  airport?: Maybe<TripIntentAirport>;
  coordinates?: Maybe<Coordinates>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type TripIntentPerson = {
  __typename?: "TripIntentPerson";
  age?: Maybe<Scalars["Int"]>;
  type: TripIntentPersonType;
};

export type TripIntentPersonType = "ADULT" | "CHILD";

export type TripIntentRoom = {
  __typename?: "TripIntentRoom";
  childAges: Array<Scalars["Int"]>;
  numberOfAdults: Scalars["Int"];
  numberOfChildren: Scalars["Int"];
};

export type TripIntentWhen = {
  __typename?: "TripIntentWhen";
  fromDate: ZonedDateTime;
  toDate: ZonedDateTime;
};

export type TripIntentWhere = {
  __typename?: "TripIntentWhere";
  destination?: Maybe<TripIntentDestination>;
  origin?: Maybe<TripIntentOrigin>;
};

export type TripIntentWho = {
  __typename?: "TripIntentWho";
  people: Array<TripIntentPerson>;
  rooms: Array<TripIntentRoom>;
};

export type TripIntentWhy = {
  __typename?: "TripIntentWhy";
  interests: Array<Interest>;
};

export interface TripInviteInput {
  inviteId: Scalars["String"];
}

export type TripItem = {
  __typename?: "TripItem";
  filter?: Maybe<Scalars["String"]>;
  segments?: Maybe<Array<Scalars["String"]>>;
  tripEntity?: Maybe<TripEntity>;
  tripItemId: Scalars["String"];
  tripViewId: Scalars["String"];
};

export type TripItemContextualCardsPrimer = {
  __typename?: "TripItemContextualCardsPrimer";
  placeHolder?: Maybe<Image>;
  tripItemId: Scalars["String"];
  tripViewId?: Maybe<Scalars["String"]>;
};

export type TripItemContextualCardsResponse = {
  __typename?: "TripItemContextualCardsResponse";
  cardsCarousel?: Maybe<TripContextualCardCarousel>;
  image: Image;
};

export type TripItemHeader = {
  __typename?: "TripItemHeader";
  endDate: Scalars["String"];
  productId: Scalars["String"];
  startDate: Scalars["String"];
  title: Scalars["String"];
};

export interface TripItemInput {
  filter?: InputMaybe<Scalars["String"]>;
  segments?: InputMaybe<Array<Scalars["String"]>>;
  tripEntity?: InputMaybe<TripEntity>;
  tripItemId: Scalars["String"];
  tripViewId: Scalars["String"];
}

export type TripItemTemplateResponse = {
  __typename?: "TripItemTemplateResponse";
  template: Scalars["String"];
  /** @deprecated Use template field as string type instead of templateResponse as Template */
  templateResponse?: Maybe<Template>;
};

export type TripItemsResponse = {
  __typename?: "TripItemsResponse";
  groups: Array<TripsItemsGroup>;
};

export type TripMapResponse = {
  __typename?: "TripMapResponse";
  fullScreenMap: TripsUiFullScreenMap;
  staticMap: TripsUiStaticMap;
};

export type TripMutation = {
  __typename?: "TripMutation";
  acceptInvite: TripsViewResponse;
  cancel: TripsViewResponse;
  cancelActivity: TripsViewResponse;
  cancelCar: TripsViewResponse;
  cancelInsurance: TripsViewResponse;
  createTrip: TripsViewResponse;
  deleteTrip: TripsViewResponse;
  editTrip: TripsViewResponse;
  moveTripItem: TripsViewResponse;
  saveItemToTrip: TripsViewResponse;
  /** @deprecated saveStayToTrip mutation has been deprecated, replace with saveItemToTrip mutation */
  saveStayToTrip: TripsViewResponse;
  sendInvite: TripsViewResponse;
  sendItineraryEmail: TripsViewResponse;
  unsaveItemFromTrip: TripsViewResponse;
  /** @deprecated unsaveStayFromTrip mutation has been deprecated, replace with unsaveItemFromTrip mutation */
  unsaveStayFromTrip: TripsViewResponse;
  updateItemDates: TripsViewResponse;
  updateItemPriceAlertStatus: TripsMutationResponse;
};

export type TripMutationAcceptInviteArgs = {
  tripInvite: TripInviteInput;
};

export type TripMutationCancelArgs = {
  cancellationAttributes?: InputMaybe<TripsCancellationAttributesInput>;
  cancellationType: TripsCancellationType;
  item: TripItemInput;
};

export type TripMutationCancelActivityArgs = {
  item: TripItemInput;
  orderLineNumbers?: InputMaybe<Array<Scalars["String"]>>;
  orderNumber: Scalars["String"];
};

export type TripMutationCancelCarArgs = {
  item: TripItemInput;
  orderLineNumbers: Array<Scalars["String"]>;
};

export type TripMutationCancelInsuranceArgs = {
  item: TripItemInput;
  orderLineNumber: Scalars["String"];
};

export type TripMutationCreateTripArgs = {
  inputs: Array<GraphQlPairInput>;
};

export type TripMutationDeleteTripArgs = {
  tripId: Scalars["String"];
};

export type TripMutationEditTripArgs = {
  inputs: Array<GraphQlPairInput>;
  overview: TripOverviewInput;
};

export type TripMutationMoveTripItemArgs = {
  inputs?: InputMaybe<Array<GraphQlPairInput>>;
  item: TripItemInput;
  toTripId?: InputMaybe<Scalars["String"]>;
  toTripName?: InputMaybe<Scalars["String"]>;
};

export type TripMutationSaveItemToTripArgs = {
  itemInput: TripsPlanInput;
  newTripInputs?: InputMaybe<Array<GraphQlPairInput>>;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type TripMutationSaveStayToTripArgs = {
  inputs?: InputMaybe<Array<GraphQlPairInput>>;
  stayInput: TripsSaveStayInput;
  tripId?: InputMaybe<Scalars["String"]>;
};

export type TripMutationSendInviteArgs = {
  inputs: Array<GraphQlPairInput>;
  overview: TripOverviewInput;
};

export type TripMutationSendItineraryEmailArgs = {
  inputs: Array<GraphQlPairInput>;
  item: TripItemInput;
};

export type TripMutationUnsaveItemFromTripArgs = {
  tripEntity: TripEntity;
  tripItem: TripItemInput;
};

export type TripMutationUnsaveStayFromTripArgs = {
  tripItem: TripItemInput;
};

export type TripMutationUpdateItemDatesArgs = {
  dateRange: DateRangeInput;
  tripEntity: TripEntity;
  tripItem: TripItemInput;
};

export type TripMutationUpdateItemPriceAlertStatusArgs = {
  alertStatus: TripsToggleStatus;
  tripItem: TripItemInput;
};

export type TripOverview = {
  __typename?: "TripOverview";
  filter?: Maybe<Scalars["String"]>;
  inviteId?: Maybe<Scalars["String"]>;
  segments?: Maybe<Array<Scalars["String"]>>;
  tripViewId: Scalars["String"];
};

export interface TripOverviewInput {
  filter?: InputMaybe<Scalars["String"]>;
  inviteId?: InputMaybe<Scalars["String"]>;
  segments?: InputMaybe<Array<Scalars["String"]>>;
  tripViewId: Scalars["String"];
}

export type TripRecommendation = {
  __typename?: "TripRecommendation";
  currencyCode?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  priceDisplayed: Scalars["String"];
};

export type TripRecommendationModule = {
  __typename?: "TripRecommendationModule";
  recommendations: Array<TripRecommendation>;
  responseId: Scalars["String"];
  title: Scalars["String"];
};

export type TripScheduleChangeStatus = "FLIGHT_SCHEDULE_CHANGE" | "NO_CHANGE";

export type TripSummaryContent = {
  __typename?: "TripSummaryContent";
  header: LodgingHeader;
  /** @deprecated Use installmentPlanOptions instead */
  installmentPlan?: Maybe<InstallmentPlan>;
  installmentPlanOptions?: Maybe<LodgingInstallmentPlanOptions>;
  price: PropertyPrice;
  summary: Array<LodgingEnrichedMessage>;
};

export type TripSummaryHeading = EgdsSectionHeading & {
  __typename?: "TripSummaryHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]>;
};

export type Trips = {
  __typename?: "Trips";
  customer: TripsViewResponse;
  filtered: TripsViewResponse;
  findItineraryNumber: TripsViewResponse;
  hero: HomeResponse;
  important: TripsViewResponse;
  navigation: TripsViewResponse;
  plan: TripsViewResponse;
  recentSearches: TripsRecentSearchesResponse;
  referAFriend?: Maybe<TripsReferAFriendTile>;
  saveItem?: Maybe<TripsSaveItemResponse>;
  screenshot: TripsViewResponse;
  searchBooking: TripsViewResponse;
};

export type TripsFilteredArgs = {
  filter: TripsFilterInput;
};

export type TripsFindItineraryNumberArgs = {
  inputs?: InputMaybe<Array<GraphQlPairInput>>;
  viewType: TripsFindItineraryNumberView;
};

export type TripsPlanArgs = {
  input: TripsPlanInput;
};

export type TripsSaveItemArgs = {
  input: TripsSaveItemInput;
};

export type TripsScreenshotArgs = {
  input: TripsScreenshotInput;
};

export type TripsSearchBookingArgs = {
  searchInput?: InputMaybe<Array<GraphQlPairInput>>;
  viewType: TripsSearchBookingView;
};

export type TripsAcceptInviteAndNavigateToOverviewAction = TripsAction & {
  __typename?: "TripsAcceptInviteAndNavigateToOverviewAction";
  analytics: ClientSideAnalytics;
  overview: TripOverview;
  overviewUrl?: Maybe<Scalars["URL"]>;
};

export type TripsAccessibilityData = AccessibilityData & {
  __typename?: "TripsAccessibilityData";
  hint?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export interface TripsAction {
  analytics: ClientSideAnalytics;
}

export type TripsActionList = {
  __typename?: "TripsActionList";
  accessibility?: Maybe<Scalars["String"]>;
  actionList: Array<TripsActionListItem>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsActionListItem = {
  __typename?: "TripsActionListItem";
  action: TripsNavigateToViewAction;
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsAddToCalendarAction = TripsAction & {
  __typename?: "TripsAddToCalendarAction";
  analytics: ClientSideAnalytics;
  entries: Array<TripsAddToCalendarEventAttributes>;
  tripId: Scalars["String"];
};

export type TripsAddToCalendarEventAttributes = {
  __typename?: "TripsAddToCalendarEventAttributes";
  address?: Maybe<Scalars["String"]>;
  description: Array<Scalars["String"]>;
  endDateTime?: Maybe<Scalars["String"]>;
  eventTimeZone?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["String"]>;
  startDateTime: Scalars["String"];
  title: Scalars["String"];
};

export type TripsAddToWalletAction = TripsAction & {
  __typename?: "TripsAddToWalletAction";
  analytics: ClientSideAnalytics;
  pointOfSaleOrderReferenceNumber: Scalars["String"];
};

export type TripsAlbumCard = {
  __typename?: "TripsAlbumCard";
  accessibility?: Maybe<Scalars["String"]>;
  action: UiLinkAction;
  button?: Maybe<TripsUiButton>;
  icons?: Maybe<Array<TripsAlbumCardIcon>>;
  image?: Maybe<Image>;
  impressionTracking: ClientSideAnalytics;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsAlbumCardIcon = {
  __typename?: "TripsAlbumCardIcon";
  icons: Array<Icon>;
  label: Scalars["String"];
};

export type TripsAlbumCarousel = {
  __typename?: "TripsAlbumCarousel";
  carousel: TripCarousel;
  content: Array<TripsAlbumCard>;
  moreInfoLink?: Maybe<EgdsStandardLink>;
};

export type TripsAvatar = {
  __typename?: "TripsAvatar";
  name: Scalars["String"];
  url?: Maybe<Scalars["URL"]>;
};

export type TripsAvatarGroup = TripsFlexContainerItem & {
  __typename?: "TripsAvatarGroup";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsNavigateToViewAction>;
  avatarSize: TripsAvatarSize;
  avatars: Array<TripsAvatar>;
  grow?: Maybe<Scalars["Float"]>;
  showBorder: Scalars["Boolean"];
};

export type TripsAvatarSize = "EXTRA_LARGE" | "LARGE" | "MEDIUM" | "SMALL";

export type TripsBadge = {
  __typename?: "TripsBadge";
  accessibility?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<Scalars["String"]>;
  theme?: Maybe<TripsBadgeTheme>;
};

export type TripsBadgeTheme =
  | "LOYALTY_HIGH_TIER"
  | "LOYALTY_LOW_TIER"
  | "LOYALTY_MIDDLE_TIER"
  | "SAVED"
  | "STATUS_NEGATIVE"
  | "STATUS_POSITIVE"
  | "UTILITY_STANDARD"
  | "VIP";

export type TripsBottomMenu = TripsMenu & {
  __typename?: "TripsBottomMenu";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  items: Array<TripsMenuItem>;
  menuItems: Array<TripsMenuItem>;
  menuTitle?: Maybe<Scalars["String"]>;
};

export type TripsBottomSheet = {
  __typename?: "TripsBottomSheet";
  elements: Array<TripsTertiaryButton>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export interface TripsButton {
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  width: TripsButtonWidth;
}

export type TripsButtonWidth = "MATCH_PARENT_CONTAINER" | "WRAP_CONTENT";

export type TripsCalendarSelectionType = "SINGLE" | "START_AND_END" | "START_AND_END_WITH_SAME_DATE";

export type TripsCancelActivityAction = TripsAction & {
  __typename?: "TripsCancelActivityAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  orderLineNumbers?: Maybe<Array<Scalars["String"]>>;
  orderNumber: Scalars["String"];
};

export type TripsCancelAssuranceAction = TripsAction & {
  __typename?: "TripsCancelAssuranceAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  orderLineNumber: Scalars["String"];
};

export type TripsCancelCarAction = TripsAction & {
  __typename?: "TripsCancelCarAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  orderLineNumbers: Array<Scalars["String"]>;
};

export type TripsCancelFlightAction = TripsAction & {
  __typename?: "TripsCancelFlightAction";
  airRecordLocator: Scalars["String"];
  airlineConfirmationCode: Scalars["String"];
  analytics: ClientSideAnalytics;
  item: TripItem;
  tripNumber: Scalars["String"];
  userToken: Scalars["String"];
};

export type TripsCancelInsuranceAction = TripsAction & {
  __typename?: "TripsCancelInsuranceAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  orderLineNumber: Scalars["String"];
};

export type TripsCancellationAction = TripsAction & {
  __typename?: "TripsCancellationAction";
  analytics: ClientSideAnalytics;
  cancellationAttributes?: Maybe<TripsCancellationAttributes>;
  cancellationType: TripsCancellationType;
  item?: Maybe<TripItem>;
  /** @deprecated Deprecated, please use `cancellationAttributes` instead */
  orderLineNumbers?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated Deprecated, please use `cancellationAttributes` instead */
  orderNumber?: Maybe<Scalars["String"]>;
};

export type TripsCancellationAttributes = {
  __typename?: "TripsCancellationAttributes";
  orderLineNumbers?: Maybe<Array<Scalars["String"]>>;
  orderNumber?: Maybe<Scalars["String"]>;
  penaltyAmount?: Maybe<Scalars["String"]>;
  refundAmount?: Maybe<Scalars["String"]>;
  refundInCash?: Maybe<Scalars["String"]>;
  refundInPoints?: Maybe<Scalars["String"]>;
};

export interface TripsCancellationAttributesInput {
  orderLineNumbers?: InputMaybe<Array<Scalars["String"]>>;
  orderNumber?: InputMaybe<Scalars["String"]>;
  penaltyAmount?: InputMaybe<Scalars["String"]>;
  refundAmount?: InputMaybe<Scalars["String"]>;
  refundInCash?: InputMaybe<Scalars["String"]>;
  refundInPoints?: InputMaybe<Scalars["String"]>;
}

export type TripsCancellationType = "ACTIVITY" | "CAR" | "CRUISE" | "HOTEL" | "INSURANCE";

export interface TripsCard {
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
}

export type TripsCardState = "SELECTED" | "UNSELECTED";

export type TripsCarouselAccessibilityData = AccessibilityData & {
  __typename?: "TripsCarouselAccessibilityData";
  hint?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  nextButton?: Maybe<Scalars["String"]>;
  prevButton?: Maybe<Scalars["String"]>;
};

export type TripsCarouselContainer = TripsContainer & {
  __typename?: "TripsCarouselContainer";
  accessibility?: Maybe<AccessibilityData>;
  elements: Array<TripsComposableElement>;
  heading?: Maybe<Scalars["String"]>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  subheading?: Maybe<TripsCarouselSubHeader>;
};

export type TripsCarouselSubHeader = EgdsGraphicText | EgdsPlainText;

export type TripsChangeItemDatesAction = TripsAction & {
  __typename?: "TripsChangeItemDatesAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  tripEntity: TripEntity;
};

export type TripsChangeSaveItemState = {
  __typename?: "TripsChangeSaveItemState";
  analytics: ClientSideAnalytics;
  toggleState: Scalars["Boolean"];
  tripItemId: Scalars["String"];
};

export type TripsCloseDialogAction = TripsAction & {
  __typename?: "TripsCloseDialogAction";
  analytics: ClientSideAnalytics;
};

export type TripsCloseDialogButton = {
  __typename?: "TripsCloseDialogButton";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsCloseDialogAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsCollection = {
  __typename?: "TripsCollection";
  tripsAlbumCarousel?: Maybe<TripsAlbumCarousel>;
};

export type TripsComposableDrawer = TripsDrawer & {
  __typename?: "TripsComposableDrawer";
  customerNotificationBanner?: Maybe<TripsCustomerNotificationQueryParameters>;
  elements: Array<TripsComposableElement>;
  /** @deprecated header has been deprecated in favour of tripsViewHeader */
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  tripsViewHeader?: Maybe<TripsViewHeader>;
  type?: Maybe<TripsComposableDrawerType>;
};

export type TripsComposableDrawerType = "BOTTOM" | "CENTER" | "FULL" | "SIDE";

export type TripsComposableElement =
  | TripItemContextualCardsPrimer
  | TripsAvatarGroup
  | TripsCarouselContainer
  | TripsContainerDivider
  | TripsContentCard
  | TripsEmbeddedContentCard
  | TripsFittedImageCard
  | TripsFlexContainer
  | TripsFlightPathMapCard
  | TripsForm
  | TripsFormContainer
  | TripsFullBleedImageCard
  | TripsIllustrationCard
  | TripsImageSlimCard
  | TripsImageTopCard
  | TripsItemRecallCard
  | TripsLodgingUpgradesPrimer
  | TripsMapCard
  | TripsMediaGallery
  | TripsOverlayButton
  | TripsPageBreak
  | TripsPriceAlertSwitchCard
  | TripsPricePresentation
  | TripsPrimaryButton
  | TripsSecondaryButton
  | TripsSectionContainer
  | TripsServiceRequestsButtonPrimer
  | TripsSlimCard
  | TripsSlimCardContainer
  | TripsStepIndicatorStep
  | TripsStepIndicatorVertical
  | TripsTertiaryButton
  | TripsValidatedInput;

export interface TripsContainer {
  elements: Array<TripsComposableElement>;
}

export type TripsContainerDivider = {
  __typename?: "TripsContainerDivider";
  divider: TripsDivider;
};

export interface TripsContent {
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
}

export type TripsContentCard = TripsCard & {
  __typename?: "TripsContentCard";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  rows: Array<TripsContent>;
  /** @deprecated Do not request, will always be null. */
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsContentColumns = TripsContent & {
  __typename?: "TripsContentColumns";
  columns: Array<TripsContent>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsContentInlineLinkItem = {
  __typename?: "TripsContentInlineLinkItem";
  items: Array<TripsContentItem>;
};

export type TripsContentItem = TripsContentInlineLinkItem | TripsContentLinkItem | TripsContentTextItem;

export type TripsContentLinkItem = {
  __typename?: "TripsContentLinkItem";
  /**
   * @deprecated `action` of type TripsLinkAction has been deprecated. Use `linkAction` instead.
   *          TripContentLinkItem now supports any action conforming to TripsAction.
   */
  action: TripsLinkAction;
  icon?: Maybe<Icon>;
  linkAction: TripsAction;
  linkText: Scalars["String"];
};

export type TripsContentList = TripsContent & {
  __typename?: "TripsContentList";
  items?: Maybe<Array<TripsViewContentLineItem>>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  theme: TripsContentTheme;
};

export type TripsContentOrderedList = TripsContent & {
  __typename?: "TripsContentOrderedList";
  items?: Maybe<Array<TripsContentItem>>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsContentParagraph = TripsContent & {
  __typename?: "TripsContentParagraph";
  items?: Maybe<Array<TripsContentItem>>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsContentTextItem = {
  __typename?: "TripsContentTextItem";
  action?: Maybe<TripsAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsContentTheme = "BULLETS" | "NO_BULLETS" | "ORDERED" | "PARAGRAPH";

export type TripsContentType = "PDF";

export type TripsContentUnorderedList = TripsContent & {
  __typename?: "TripsContentUnorderedList";
  items?: Maybe<Array<TripsContentItem>>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  theme?: Maybe<TripsContentUnorderedListTheme>;
};

export type TripsContentUnorderedListTheme = "BULLETS" | "NO_BULLETS";

export type TripsContextualMessagingCard = {
  __typename?: "TripsContextualMessagingCard";
  actions?: Maybe<Array<TripsContextualMessagingCardAction>>;
  card: TripsMessagingCard;
};

export type TripsContextualMessagingCardAction = TripsUiTextLinkAction | TripsVaInitAction;

export type TripsCreateEmptyTrip = {
  __typename?: "TripsCreateEmptyTrip";
  metadata?: Maybe<TripCreationMetadata>;
};

export type TripsCreateNewTripAction = TripsAction & {
  __typename?: "TripsCreateNewTripAction";
  analytics: ClientSideAnalytics;
  itemId: Scalars["String"];
};

export type TripsCreateTripFromItem = {
  __typename?: "TripsCreateTripFromItem";
  input: TripsPlan;
};

export type TripsCreateTripFromItemAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsCreateTripFromItemAction";
    analytics: ClientSideAnalytics;
    inputIds: Array<Scalars["String"]>;
    /** @deprecated itemId has been deprecated., replace with saveItemInput.itemId */
    itemId: Scalars["String"];
    saveItemInput?: Maybe<TripsPlan>;
  };

export type TripsCreateTripFromMovedItem = {
  __typename?: "TripsCreateTripFromMovedItem";
  item: TripItem;
};

export type TripsCustomerNotificationOpenInAppAction = TripsAction & {
  __typename?: "TripsCustomerNotificationOpenInAppAction";
  analytics: ClientSideAnalytics;
  notificationAttributes: TripsCustomerNotificationQueryParameters;
};

export type TripsCustomerNotificationOptionalContext = {
  __typename?: "TripsCustomerNotificationOptionalContext";
  itineraryNumber?: Maybe<Scalars["String"]>;
  journeyCriterias?: Maybe<Array<TripsCustomerNotificationOptionalContextJourneyCriteria>>;
  regionId?: Maybe<Scalars["String"]>;
  regionIds?: Maybe<Array<Scalars["String"]>>;
  tripId?: Maybe<Scalars["String"]>;
  tripItemId?: Maybe<Scalars["String"]>;
};

export type TripsCustomerNotificationOptionalContextJourneyCriteria = {
  __typename?: "TripsCustomerNotificationOptionalContextJourneyCriteria";
  dateRange?: Maybe<DateRange>;
  destination?: Maybe<TripsCustomerNotificationOptionalContextJourneyCriteriaLocation>;
  origin?: Maybe<TripsCustomerNotificationOptionalContextJourneyCriteriaLocation>;
  tripScheduleChangeStatus?: Maybe<TripsCustomerNotificationTripScheduleChangeStatus>;
  tripsData?: Maybe<TripsData>;
};

export type TripsCustomerNotificationOptionalContextJourneyCriteriaLocation = {
  __typename?: "TripsCustomerNotificationOptionalContextJourneyCriteriaLocation";
  airportTLA?: Maybe<Scalars["String"]>;
  propertyId?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["String"]>;
};

export type TripsCustomerNotificationQueryParameters = {
  __typename?: "TripsCustomerNotificationQueryParameters";
  funnelLocation?: Maybe<Scalars["String"]>;
  lineOfBusiness?: Maybe<Scalars["String"]>;
  notificationLocation?: Maybe<Scalars["String"]>;
  optionalContext?: Maybe<TripsCustomerNotificationOptionalContext>;
  xPageID?: Maybe<Scalars["String"]>;
};

export type TripsCustomerNotificationTripScheduleChangeStatus = "FLIGHT_SCHEDULE_CHANGE" | "NO_CHANGE";

export type TripsData = {
  __typename?: "TripsData";
  flightProduct?: Maybe<FlightProduct>;
};

export interface TripsDataInput {
  flightProduct?: InputMaybe<FlightProductInput>;
}

export type TripsDatePickerAnalytics = {
  __typename?: "TripsDatePickerAnalytics";
  closeAnalytics: ClientSideAnalytics;
  nextPageAnalytics: ClientSideAnalytics;
  previousPageAnalytics: ClientSideAnalytics;
};

export type TripsDatePickerAttributes = {
  __typename?: "TripsDatePickerAttributes";
  analytics: TripsDatePickerAnalytics;
  buttonText: Scalars["String"];
  calendarSelectionType: TripsCalendarSelectionType;
  changeDatesAction: TripsChangeItemDatesAction;
  daysBookableInAdvance: Scalars["Int"];
  itemDates: DateRange;
  maxDateRange: Scalars["Int"];
  maxDateRangeMessage?: Maybe<Scalars["String"]>;
  midnightBookingOffset?: Maybe<Scalars["Int"]>;
  minDateRange: Scalars["Int"];
  productId?: Maybe<Scalars["String"]>;
  tripEntity: TripEntity;
};

export type TripsDeleteTripAction = TripsAction & {
  __typename?: "TripsDeleteTripAction";
  analytics: ClientSideAnalytics;
  overview: TripOverview;
};

export interface TripsDialog {
  body: Array<TripsContent>;
  heading?: Maybe<Scalars["String"]>;
}

export type TripsDialogButtonLayout = "INLINE" | "STACKED";

export type TripsDismissAction = TripsAction & {
  __typename?: "TripsDismissAction";
  analytics: ClientSideAnalytics;
};

export type TripsDismissDrawerAction = TripsAction & {
  __typename?: "TripsDismissDrawerAction";
  analytics: ClientSideAnalytics;
};

export type TripsDisplayMode = "DEFAULT" | "MODAL" | "NEW_TAB";

export type TripsDivider = "NARROW" | "WIDE";

export interface TripsDrawer {
  elements: Array<TripsComposableElement>;
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
}

export type TripsDrawerHeader = {
  __typename?: "TripsDrawerHeader";
  action?: Maybe<TripsTertiaryButton>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsEmbeddedContentCard = TripsCard &
  TripsFlexContainerItem & {
    __typename?: "TripsEmbeddedContentCard";
    grow?: Maybe<Scalars["Float"]>;
    impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
    items: Array<TripsEmbeddedContentList>;
    primary?: Maybe<Scalars["String"]>;
  };

export type TripsEmbeddedContentItem = TripsSignalListener & {
  __typename?: "TripsEmbeddedContentItem";
  action?: Maybe<TripsLinkAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
  signal?: Maybe<TripsSignal>;
};

export type TripsEmbeddedContentLineItem = {
  __typename?: "TripsEmbeddedContentLineItem";
  items: Array<TripsEmbeddedContentItem>;
};

export type TripsEmbeddedContentList = TripsContent & {
  __typename?: "TripsEmbeddedContentList";
  items?: Maybe<Array<TripsEmbeddedContentLineItem>>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  theme: TripsContentTheme;
};

export type TripsEmblem =
  | EgdsBadgeNotification
  | EgdsLoyaltyBadge
  | EgdsProgramBadge
  | EgdsStandardBadge
  | Mark
  | TripsBadge;

export type TripsEmblemsInlineContent = TripsContent & {
  __typename?: "TripsEmblemsInlineContent";
  emblems: Array<TripsEmblem>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsEmitSignal = {
  __typename?: "TripsEmitSignal";
  signal: TripsSignal;
  values: Array<TripsSignalPair>;
};

export type TripsExploreItinerariesAction = TripsAction & {
  __typename?: "TripsExploreItinerariesAction";
  analytics: ClientSideAnalytics;
};

export type TripsFilter = {
  __typename?: "TripsFilter";
  filter: Scalars["String"];
};

export interface TripsFilterInput {
  filter: Scalars["String"];
}

export type TripsFindItineraryNumberView = "FIND_ITINERARY_OTHER_EMAIL" | "FIND_ITINERARY_USER_EMAIL" | "RESULT";

export type TripsFittedImageCard = TripsCard & {
  __typename?: "TripsFittedImageCard";
  accessibility?: Maybe<AccessibilityData>;
  image: Image;
  imageType?: Maybe<TripsFittedImageType>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsFittedImageType = "BARCODE" | "E_FRANKING_STAMP" | "QR_CODE";

export type TripsFlexContainer = {
  __typename?: "TripsFlexContainer";
  alignItems: FlexAlignItems;
  direction: FlexDirection;
  elements: Array<TripsFlexContainerItem>;
  justifyContent: FlexJustifyContent;
  wrap: FlexWrap;
};

export interface TripsFlexContainerItem {
  grow?: Maybe<Scalars["Float"]>;
}

export type TripsFlightPath = {
  __typename?: "TripsFlightPath";
  coordinates: Array<Coordinates>;
};

export type TripsFlightPathMapCard = TripsCard & {
  __typename?: "TripsFlightPathMapCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  data: TripsFlightPathMapData;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsFlightPathMapData = {
  __typename?: "TripsFlightPathMapData";
  paths: Array<TripsFlightPath>;
};

export type TripsFloatingActionButton = {
  __typename?: "TripsFloatingActionButton";
  action?: Maybe<TripsAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsFloatingActionDrawer = TripsDrawer & {
  __typename?: "TripsFloatingActionDrawer";
  elements: Array<TripsComposableElement>;
  floatingButton?: Maybe<TripsFloatingButton>;
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
};

export type TripsFloatingButton = {
  __typename?: "TripsFloatingButton";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsFloatingMenu = TripsMenu & {
  __typename?: "TripsFloatingMenu";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  items: Array<TripsMenuItem>;
  menuItems: Array<TripsMenuItem>;
  menuTitle?: Maybe<Scalars["String"]>;
};

export type TripsForm = {
  __typename?: "TripsForm";
  elements: Array<TripsFormElement>;
};

export type TripsFormAction = TripsAction & {
  __typename?: "TripsFormAction";
  analytics: ClientSideAnalytics;
  formData?: Maybe<TripsFormData>;
  type: TripsFormType;
  validatedInputAnalytics?: Maybe<Array<TripsValidatedInputAnalytics>>;
  validatedInputIds: Array<Scalars["String"]>;
};

export type TripsFormContainer = TripsContainer & {
  __typename?: "TripsFormContainer";
  elements: Array<TripsComposableElement>;
  formTheme?: Maybe<TripsFormContainerTheme>;
};

export type TripsFormContainerTheme = "MATCH_PARENT" | "WRAP_CONTENT";

export type TripsFormData =
  | TripsCreateEmptyTrip
  | TripsCreateTripFromItem
  | TripsCreateTripFromMovedItem
  | TripsInvite
  | TripsSendItineraryEmail
  | TripsUpdateTrip;

export type TripsFormElement = TripsContentTextItem | TripsPrimaryButton | TripsSecondaryButton | TripsValidatedInput;

export interface TripsFormSubmitAction {
  inputIds: Array<Scalars["String"]>;
}

export type TripsFormType =
  | "CREATE_NEW_TRIP_FROM_ITEM"
  | "FIND_ITINERARY_NUMBER"
  | "INVITE"
  | "SAVE_NEW_TRIP"
  | "SEARCH_BOOKING"
  | "SEND_ITINERARY_EMAIL"
  | "UPDATE_TRIP";

export type TripsFullBleedImageCard = TripsCard & {
  __typename?: "TripsFullBleedImageCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  background?: Maybe<Image>;
  badgeList?: Maybe<Array<EgdsBadge>>;
  /** @deprecated TripsBadge field has been deprecated. Rather use new EGDS Badges., replace with badgeList */
  badges?: Maybe<Array<TripsBadge>>;
  icons?: Maybe<Array<Icon>>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  inviteButton?: Maybe<TripsOverlayButton>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  tripsViewContentItem?: Maybe<TripsViewContentItem>;
};

export type TripsFullScreenDialog = {
  __typename?: "TripsFullScreenDialog";
  closeButton?: Maybe<TripsCloseDialogButton>;
  content?: Maybe<Array<TripsEmbeddedContentCard>>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsIcon = {
  __typename?: "TripsIcon";
  action?: Maybe<TripsAction>;
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]>;
};

export type TripsIconToggle = TripsSignalListener & {
  __typename?: "TripsIconToggle";
  disabledIcon?: Maybe<TripsIcon>;
  enabledIcon?: Maybe<TripsIcon>;
  impressionAnalytics?: Maybe<ClientSideImpressionAnalytics>;
  initialStatus: TripsToggleStatus;
  signal?: Maybe<TripsSignal>;
};

export type TripsIllustrationCard = TripsCard & {
  __typename?: "TripsIllustrationCard";
  illustration?: Maybe<Illustration>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsImageSlimCard = TripsCard &
  TripsSignalListener & {
    __typename?: "TripsImageSlimCard";
    accessibility?: Maybe<AccessibilityData>;
    badgeList?: Maybe<Array<EgdsBadge>>;
    /** @deprecated TripsBadge field has been deprecated. Rather use new EGDS Badges., replace with badgeList */
    badges?: Maybe<Array<TripsBadge>>;
    cardIcon?: Maybe<TripsIcon>;
    /** @deprecated TripsImageSlimCard icon field has been deprecated. Rather use action associated with cardIcon., replace with cardIcon */
    icon?: Maybe<Icon>;
    impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
    itemPricePrimer?: Maybe<TripsItemPricePrimer>;
    priceAlertToggle?: Maybe<TripsIconToggle>;
    primary?: Maybe<Scalars["String"]>;
    primaryAction?: Maybe<TripsAction>;
    secondaries?: Maybe<Array<Scalars["String"]>>;
    secondaryAction?: Maybe<TripsAction>;
    signal?: Maybe<TripsSignal>;
    state?: Maybe<TripsCardState>;
    thumbnail?: Maybe<Image>;
  };

export type TripsImageTopCard = TripsCard & {
  __typename?: "TripsImageTopCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  background?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsInfoToast = TripsToast & {
  __typename?: "TripsInfoToast";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"];
};

export type TripsInlineActionToast = TripsToast & {
  __typename?: "TripsInlineActionToast";
  button: TripsButton;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"];
};

export type TripsInlineContent = {
  __typename?: "TripsInlineContent";
  items: Array<TripsViewContentItem>;
};

export type TripsInlinedActionsDialog = TripsDialog & {
  __typename?: "TripsInlinedActionsDialog";
  body: Array<TripsContent>;
  buttons: Array<TripsButton>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsInlinedActionsDrawer = TripsDrawer & {
  __typename?: "TripsInlinedActionsDrawer";
  buttons: Array<TripsButton>;
  elements: Array<TripsComposableElement>;
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
};

export type TripsInputType = "MULTIPLE" | "SINGLE";

export type TripsInvite = {
  __typename?: "TripsInvite";
  overview: TripOverview;
};

export type TripsInviteAcceptAction = TripsAction & {
  __typename?: "TripsInviteAcceptAction";
  analytics: ClientSideAnalytics;
  inviteId: Scalars["String"];
};

export type TripsInviteAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsInviteAction";
    analytics: ClientSideAnalytics;
    inputIds: Array<Scalars["String"]>;
    overview: TripOverview;
  };

export type TripsItemCard =
  | TripsPlannedItemCard
  | TripsUiBookableItineraryItemCard
  | TripsUiBookedItemCard
  | TripsUiCanceledItemCard;

export type TripsItemCardMedia = Image | TripsUiItemCardMediaGalleryImage;

export type TripsItemPricePrimer = TripsSavedItemPricePrimer;

export type TripsItemRecallCard = TripsCard & {
  __typename?: "TripsItemRecallCard";
  accessibility?: Maybe<AccessibilityData>;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsItemsGroup = {
  __typename?: "TripsItemsGroup";
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  button?: Maybe<TripsUiButton>;
  buttonAction?: Maybe<UiLinkAction>;
  heading?: Maybe<Scalars["String"]>;
  messages?: Maybe<Array<Scalars["String"]>>;
  sections?: Maybe<Array<TripsItemsSection>>;
};

export interface TripsItemsGroupFilterInput {
  type: Scalars["String"];
  value: Scalars["String"];
}

export interface TripsItemsGroupInput {
  filters: Array<TripsItemsGroupFilterInput>;
  sort?: InputMaybe<Scalars["String"]>;
}

export type TripsItemsSection = {
  __typename?: "TripsItemsSection";
  cards: Array<TripsItemCard>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsLinkAction = TripsAction & {
  __typename?: "TripsLinkAction";
  analytics: ClientSideAnalytics;
  clickstreamAnalytics?: Maybe<ClickstreamAnalytics>;
  resource: Uri;
  target: TripsLinkTarget;
};

export type TripsLinkTarget = "EXTERNAL" | "INTERNAL";

export type TripsLoaderDialog = TripsDialog & {
  __typename?: "TripsLoaderDialog";
  body: Array<TripsContent>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsLodgingUpgradesPrimer = {
  __typename?: "TripsLodgingUpgradesPrimer";
  itineraryNumber?: Maybe<Scalars["String"]>;
};

export type TripsMap = {
  __typename?: "TripsMap";
  bounds?: Maybe<TripsMapBounds>;
  locale: Scalars["String"];
  mapCenter: TripsMapCenter;
  markers: Array<TripsMapMarker>;
  paths: Array<TripsFlightPathMapData>;
};

export type TripsMapAction = TripsAction & {
  __typename?: "TripsMapAction";
  analytics: ClientSideAnalytics;
  data: TripsMapData;
};

export type TripsMapBounds = {
  __typename?: "TripsMapBounds";
  ne: Coordinates;
  sw: Coordinates;
};

export type TripsMapCard = TripsCard & {
  __typename?: "TripsMapCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  data: TripsMapData;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsMapCenter = {
  __typename?: "TripsMapCenter";
  center: Coordinates;
  zoom?: Maybe<Scalars["Int"]>;
};

export type TripsMapData = {
  __typename?: "TripsMapData";
  center: Coordinates;
  zoom: Scalars["Int"];
};

export type TripsMapDirectionsAction = TripsAction & {
  __typename?: "TripsMapDirectionsAction";
  analytics: ClientSideAnalytics;
  data: TripsMapData;
  url: Scalars["URL"];
};

export type TripsMapMarker = {
  __typename?: "TripsMapMarker";
  analytics?: Maybe<ClientSideAnalytics>;
  content?: Maybe<TripsMarkerContent>;
  coordinates: Coordinates;
  label: Scalars["String"];
  type?: Maybe<TripsMarkerType>;
};

export type TripsMarkerContent = {
  __typename?: "TripsMarkerContent";
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  primary: Scalars["String"];
};

export type TripsMarkerType =
  | "ActivityEventMarker"
  | "ActivityGuestPickupMarker"
  | "ActivityLocationMarker"
  | "ActivityRedemptionMarker"
  | "AirportMarker"
  | "CarMarker"
  | "CruiseMarker"
  | "HotelMarker"
  | "PlaceMarker";

export type TripsMedia = MediaItem & {
  __typename?: "TripsMedia";
  accessibilityData?: Maybe<AccessibilityData>;
  media: Media;
};

export type TripsMediaGallery = EgdsElement &
  EgdsGalleryCarousel & {
    __typename?: "TripsMediaGallery";
    accessibilityHeadingText: Scalars["String"];
    analytics?: Maybe<ClientSideAnalytics>;
    egdsElementId?: Maybe<Scalars["String"]>;
    media: Array<TripsMedia>;
    mediaGalleryDialogToolbar: TripsToolbar;
    nextButtonText?: Maybe<Scalars["String"]>;
    previousButtonText?: Maybe<Scalars["String"]>;
  };

export interface TripsMenu {
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  items: Array<TripsMenuItem>;
  /** @deprecated TripsMenu menuItems field has been deprecated. Rather use items., replace with items */
  menuItems: Array<TripsMenuItem>;
  /** @deprecated TripsMenu menuTitle field has been deprecated. See TripsMenuTitle as a menu item., replace with TripsMenuTitle */
  menuTitle?: Maybe<Scalars["String"]>;
}

export interface TripsMenuItem {
  primary: Scalars["String"];
  /** @deprecated TripsMenuItem text field has been deprecated., replace with primary */
  text: Scalars["String"];
}

export type TripsMenuItemToggle = TripsMenuItem &
  TripsSignalListener & {
    __typename?: "TripsMenuItemToggle";
    disabledMenuItem?: Maybe<TripsMenuListItem>;
    enabledMenuItem?: Maybe<TripsMenuListItem>;
    initialStatus: TripsToggleStatus;
    primary: Scalars["String"];
    signal?: Maybe<TripsSignal>;
    text: Scalars["String"];
  };

export type TripsMenuListItem = TripsMenuItem & {
  __typename?: "TripsMenuListItem";
  accessibility: AccessibilityData;
  action: TripsAction;
  icon?: Maybe<Icon>;
  paymentType?: Maybe<Mark>;
  primary: Scalars["String"];
  text: Scalars["String"];
};

export type TripsMenuListTitle = TripsMenuItem & {
  __typename?: "TripsMenuListTitle";
  primary: Scalars["String"];
  text: Scalars["String"];
};

export type TripsMenuTitle = TripsMenuItem & {
  __typename?: "TripsMenuTitle";
  primary: Scalars["String"];
  text: Scalars["String"];
};

export type TripsMessagingCard = {
  __typename?: "TripsMessagingCard";
  accessibility: Scalars["String"];
  analytics?: Maybe<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"];
  message: Scalars["String"];
};

export type TripsMessagingLinkCard = {
  __typename?: "TripsMessagingLinkCard";
  heading?: Maybe<Scalars["String"]>;
  link: EgdsStandardLink;
  message: Scalars["String"];
  rightIcon?: Maybe<Icon>;
};

export type TripsModalDialog = {
  __typename?: "TripsModalDialog";
  buttonLayout: TripsDialogButtonLayout;
  buttons?: Maybe<Array<TripsButton>>;
  content?: Maybe<Array<TripsEmbeddedContentList>>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsMoveItemToTripAction = TripsAction & {
  __typename?: "TripsMoveItemToTripAction";
  analytics: ClientSideAnalytics;
  data: TripsMoveTripItemData;
};

export type TripsMoveTripItemData = {
  __typename?: "TripsMoveTripItemData";
  item: TripItem;
  toTripId?: Maybe<Scalars["String"]>;
  toTripName?: Maybe<Scalars["String"]>;
};

export type TripsMultiSectionDialog = {
  __typename?: "TripsMultiSectionDialog";
  accessibility: TripsAccessibilityData;
  sections: Array<TripsMultiSectionDialogSection>;
};

export type TripsMultiSectionDialogSection = {
  __typename?: "TripsMultiSectionDialogSection";
  buttonLayout: TripsDialogButtonLayout;
  buttons?: Maybe<Array<TripsButton>>;
  content?: Maybe<Array<TripsEmbeddedContentList>>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsMutationActionFailureResponse = TripsMutationActionResponse & {
  __typename?: "TripsMutationActionFailureResponse";
  action: TripsAction;
};

export interface TripsMutationActionResponse {
  action: TripsAction;
}

export type TripsMutationActionSuccessResponse = TripsMutationActionResponse & {
  __typename?: "TripsMutationActionSuccessResponse";
  action: TripsAction;
};

export type TripsMutationResponse =
  | TripsMutationActionFailureResponse
  | TripsMutationActionSuccessResponse
  | TripsMutationToastFailureResponse
  | TripsMutationToastSuccessResponse;

export type TripsMutationToastFailureResponse = TripsMutationToastResponse & {
  __typename?: "TripsMutationToastFailureResponse";
  toast: TripsToast;
};

export interface TripsMutationToastResponse {
  toast: TripsToast;
}

export type TripsMutationToastSuccessResponse = TripsMutationToastResponse & {
  __typename?: "TripsMutationToastSuccessResponse";
  action?: Maybe<TripsAction>;
  toast: TripsToast;
};

export type TripsNavigateToFilteredViewAction = TripsAction & {
  __typename?: "TripsNavigateToFilteredViewAction";
  analytics: ClientSideAnalytics;
  filter: TripsFilter;
  url: Scalars["URL"];
};

export type TripsNavigateToItemDetailsAction = TripsAction & {
  __typename?: "TripsNavigateToItemDetailsAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  url: Scalars["URL"];
};

export type TripsNavigateToItemPricingAndRewardsAction = TripsAction & {
  __typename?: "TripsNavigateToItemPricingAndRewardsAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  url: Scalars["URL"];
};

export type TripsNavigateToItemReceiptAction = TripsAction & {
  __typename?: "TripsNavigateToItemReceiptAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  url: Scalars["URL"];
};

export type TripsNavigateToItemVoucherAction = TripsAction & {
  __typename?: "TripsNavigateToItemVoucherAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  url: Scalars["URL"];
};

export type TripsNavigateToListViewAction = TripsAction & {
  __typename?: "TripsNavigateToListViewAction";
  analytics: ClientSideAnalytics;
  listViewId: Scalars["String"];
  url: Scalars["URL"];
};

export type TripsNavigateToManageBookingAction = TripsAction & {
  __typename?: "TripsNavigateToManageBookingAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
  url: Scalars["URL"];
};

export type TripsNavigateToManageTripAction = TripsAction & {
  __typename?: "TripsNavigateToManageTripAction";
  analytics: ClientSideAnalytics;
  tripViewId: Scalars["String"];
  url: Scalars["String"];
};

export type TripsNavigateToTripOverviewAction = TripsAction & {
  __typename?: "TripsNavigateToTripOverviewAction";
  analytics: ClientSideAnalytics;
  overview: TripOverview;
  url: Scalars["URL"];
};

export type TripsNavigateToViewAction = TripsAction & {
  __typename?: "TripsNavigateToViewAction";
  analytics: ClientSideAnalytics;
  displayMode: TripsDisplayMode;
  essentialInfoItem?: Maybe<EssentialInfoItem>;
  inviteId?: Maybe<Scalars["String"]>;
  tripItemId?: Maybe<Scalars["String"]>;
  tripViewId?: Maybe<Scalars["String"]>;
  updateHistory?: Maybe<UpdateHistory>;
  viewFilter?: Maybe<TripsFilter>;
  viewType: TripsViewType;
  viewUrl?: Maybe<Scalars["URL"]>;
};

export type TripsNavigation = HeaderSharedUi & {
  __typename?: "TripsNavigation";
  skipSsr: Scalars["Boolean"];
};

export interface TripsNotAuthorizedInput {
  filter?: InputMaybe<Scalars["String"]>;
  inviteId?: InputMaybe<Scalars["String"]>;
  screen?: InputMaybe<TripsScreen>;
  tripItemId?: InputMaybe<Scalars["String"]>;
  tripViewId?: InputMaybe<Scalars["String"]>;
}

export type TripsNotAuthorizedSignInButton = {
  __typename?: "TripsNotAuthorizedSignInButton";
  action: UiLinkAction;
  button: TripsUiButton;
};

export type TripsNotAuthorizedView = {
  __typename?: "TripsNotAuthorizedView";
  header: Scalars["String"];
  illustration: Illustration;
  searchForBookingCard: TripsMessagingLinkCard;
  /** @deprecated Use tripsSignInButton instead */
  signInButton?: Maybe<EgdsButton>;
  tripsSignInButton?: Maybe<TripsNotAuthorizedSignInButton>;
  viewTitle: Scalars["String"];
};

export type TripsOpenBottomSheetAction = TripsAction & {
  __typename?: "TripsOpenBottomSheetAction";
  analytics: ClientSideAnalytics;
  bottomSheet: TripsBottomSheet;
};

export type TripsOpenChangeDatesDatePickerAction = TripsAction & {
  __typename?: "TripsOpenChangeDatesDatePickerAction";
  analytics: ClientSideAnalytics;
  attributes: TripsDatePickerAttributes;
};

export type TripsOpenCreateNewTripDrawerAction = TripsAction & {
  __typename?: "TripsOpenCreateNewTripDrawerAction";
  analytics: ClientSideAnalytics;
  /** @deprecated Use TripsOpenCreateNewTripDrawerForItemAction instead if you need passing an itemId */
  itemId: Scalars["String"];
};

export type TripsOpenCreateNewTripDrawerForItemAction = TripsAction & {
  __typename?: "TripsOpenCreateNewTripDrawerForItemAction";
  analytics: ClientSideAnalytics;
  createTripMetadata?: Maybe<TripCreationMetadata>;
  /** @deprecated itemId has been deprecated., replace with createTripMetadata.saveItemInput.itemId */
  itemId: Scalars["String"];
  /** @deprecated TripCreationEntryPoint has been deprecated and will be removed in future */
  source: TripCreationEntryPoint;
};

export type TripsOpenDialogAction = TripsAction & {
  __typename?: "TripsOpenDialogAction";
  analytics: ClientSideAnalytics;
  /** @deprecated Deprecated in favour of 'modalDialog' */
  dialog: TripsDialog;
  modalDialog?: Maybe<TripsModalDialog>;
};

export type TripsOpenDrawerAction = TripsAction & {
  __typename?: "TripsOpenDrawerAction";
  analytics: ClientSideAnalytics;
  drawer: TripsStaticContentDrawer;
};

export type TripsOpenEditTripDrawerAction = TripsAction & {
  __typename?: "TripsOpenEditTripDrawerAction";
  analytics: ClientSideAnalytics;
  overview: TripOverview;
};

export type TripsOpenEmailDrawerAction = TripsAction & {
  __typename?: "TripsOpenEmailDrawerAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
};

export type TripsOpenFullScreenDialogAction = TripsAction & {
  __typename?: "TripsOpenFullScreenDialogAction";
  analytics: ClientSideAnalytics;
  dialog?: Maybe<TripsFullScreenDialog>;
  /** @deprecated Deprecated in favour of 'dialog' */
  view: TripsViewResponse;
};

export type TripsOpenInviteDrawerAction = TripsAction & {
  __typename?: "TripsOpenInviteDrawerAction";
  analytics: ClientSideAnalytics;
  overview: TripOverview;
};

export type TripsOpenMenuAction = TripsAction & {
  __typename?: "TripsOpenMenuAction";
  analytics: ClientSideAnalytics;
  bottomMenu?: Maybe<TripsBottomMenu>;
  floatingMenu?: Maybe<TripsFloatingMenu>;
};

export type TripsOpenMoveTripItemDrawerAction = TripsAction & {
  __typename?: "TripsOpenMoveTripItemDrawerAction";
  analytics: ClientSideAnalytics;
  item: TripItem;
};

export type TripsOpenMultiSectionDialogAction = TripsAction & {
  __typename?: "TripsOpenMultiSectionDialogAction";
  analytics: ClientSideAnalytics;
  multiSectionDialog: TripsMultiSectionDialog;
};

export type TripsOpenRequestSystemNotificationsDialogAction = TripsAction & {
  __typename?: "TripsOpenRequestSystemNotificationsDialogAction";
  analytics: ClientSideAnalytics;
};

export type TripsOpenSaveToTripDrawerAction = TripsAction & {
  __typename?: "TripsOpenSaveToTripDrawerAction";
  analytics: ClientSideAnalytics;
  input: TripsPlan;
};

export type TripsOverlayButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsOverlayButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"];
    grow?: Maybe<Scalars["Float"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
    width: TripsButtonWidth;
  };

export type TripsPageBreak = {
  __typename?: "TripsPageBreak";
  _empty?: Maybe<Scalars["Boolean"]>;
};

export type TripsPlan = {
  __typename?: "TripsPlan";
  attributes?: Maybe<TripsSaveItemAttributes>;
  itemId: Scalars["String"];
  pageLocation?: Maybe<PageLocation>;
  /** @deprecated `source` has been deprecated in favour of `pageLocation`. */
  source: TripsPlanSourceType;
  subscriptionAttributes?: Maybe<TripsSubscriptionAttributes>;
  tripItem?: Maybe<TripItem>;
};

export interface TripsPlanInput {
  attributes?: InputMaybe<TripsSaveItemAttributesInput>;
  itemId: Scalars["String"];
  pageLocation?: InputMaybe<PageLocation>;
  productSearchCriteria?: InputMaybe<TripsProductSearchCriteriaInput>;
  source?: InputMaybe<TripsPlanSourceType>;
  subscriptionAttributes?: InputMaybe<TripsSubscriptionAttributesInput>;
  tripItem?: InputMaybe<TripItemInput>;
}

export type TripsPlanSourceType =
  | "ACTIVITY_DETAILS"
  | "ACTIVITY_SEARCH"
  | "CAR_INFOSITE"
  | "CAR_INFOSITE_V2"
  | "CAR_SEARCH"
  | "CAR_SEARCH_V2"
  | "HOTEL_SEARCH"
  | "LODGING_DETAILS"
  | "LODGING_SEARCH"
  | "PACKAGE_DETAILS"
  | "TRIP_OVERVIEW";

export type TripsPlannedItemCard = {
  __typename?: "TripsPlannedItemCard";
  accessibility?: Maybe<Scalars["String"]>;
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  badge?: Maybe<TripsUiPlannedItemCardBadge>;
  commentsAndVotes?: Maybe<Array<TripsUiItemCardActionCount>>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  identifier: Scalars["String"];
  itemPricePrimer?: Maybe<TripsUiItemPricePrimer>;
  logo?: Maybe<Image>;
  media?: Maybe<TripsItemCardMedia>;
  menu?: Maybe<TripsUiItemCardMenu>;
  priceAlertsToggle?: Maybe<TripsUiItemCardPriceAlertsToggle>;
  primary: Scalars["String"];
  /** @deprecated Use enrichedSecondaries instead */
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsPrefetchOperation = TripsPrefetchTripItemDetails | TripsPrefetchTripOverview;

export type TripsPrefetchTripItemDetails = {
  __typename?: "TripsPrefetchTripItemDetails";
  item: TripItem;
};

export type TripsPrefetchTripOverview = {
  __typename?: "TripsPrefetchTripOverview";
  overview: TripOverview;
};

export type TripsPriceAlertAction = TripsAction & {
  __typename?: "TripsPriceAlertAction";
  alertStatus: TripsToggleStatus;
  analytics: ClientSideAnalytics;
  emitSignals?: Maybe<Array<TripsEmitSignal>>;
  tripItem: TripItem;
};

export type TripsPriceAlertSwitchCard = {
  __typename?: "TripsPriceAlertSwitchCard";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  switch: EgdsStandardSwitch;
};

export type TripsPricePresentation = {
  __typename?: "TripsPricePresentation";
  pricePresentation?: Maybe<PricePresentation>;
};

export type TripsPrimaryButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsPrimaryButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"];
    grow?: Maybe<Scalars["Float"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
    width: TripsButtonWidth;
  };

export type TripsProductSearchCriteriaAttributes = {
  __typename?: "TripsProductSearchCriteriaAttributes";
  flightSearchCriteria?: Maybe<FlightSearchCriteria>;
};

export interface TripsProductSearchCriteriaInput {
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
  propertySearchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  tripsFlightSearchCriteria?: InputMaybe<TripsSaveFlightSearchCriteriaInput>;
}

export type TripsRecentSearchPriceAlertAction = TripsAction & {
  __typename?: "TripsRecentSearchPriceAlertAction";
  analytics: ClientSideAnalytics;
  productSearchCriteria?: Maybe<TripsProductSearchCriteriaAttributes>;
  subscriptionId?: Maybe<Scalars["String"]>;
};

export type TripsRecentSearchesListResponse = {
  __typename?: "TripsRecentSearchesListResponse";
  cards: Array<TripsSlimCard>;
  expandoLink?: Maybe<EgdsExpandoLink>;
  header: Scalars["String"];
  seeAllSearches?: Maybe<EgdsStandardLink>;
};

export type TripsRecentSearchesResponse =
  | TripsRecentSearchesListResponse
  | TripsRecentSearchesResponseError
  | TripsRecentSearchesResponseSuccess;

export type TripsRecentSearchesResponseError = {
  __typename?: "TripsRecentSearchesResponseError";
  message: UiEmptyState;
  title?: Maybe<Scalars["String"]>;
};

export type TripsRecentSearchesResponseSuccess = {
  __typename?: "TripsRecentSearchesResponseSuccess";
  carousel?: Maybe<TripsCarouselContainer>;
  elements: Array<TripsActionList>;
  heading: Scalars["String"];
};

export type TripsReferAFriendTile = {
  __typename?: "TripsReferAFriendTile";
  analytics: ClientSideAnalytics;
  heading: Scalars["String"];
  icon?: Maybe<Icon>;
  impressionTracking: ClientSideImpressionAnalytics;
  message: Scalars["String"];
};

export type TripsResponseStatus = "FAIL" | "SUCCESS";

export type TripsRetryOperationAction = UiAction & {
  __typename?: "TripsRetryOperationAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type TripsReviewCollectionCard = {
  __typename?: "TripsReviewCollectionCard";
  description: EgdsStylizedText;
  heading: EgdsStylizedText;
  image: Image;
  link?: Maybe<EgdsStandardLink>;
  openedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  orderNumber?: Maybe<Scalars["String"]>;
  ratingLinks?: Maybe<Array<EgdsStandardLink>>;
  subHeading: EgdsHeading;
};

export type TripsReviewCollectionCarousel = {
  __typename?: "TripsReviewCollectionCarousel";
  carousel: EgdsCarousel;
  heading?: Maybe<EgdsHeading>;
  presentedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type TripsReviewCollectionCarouselCard = EgdsCarouselItem & {
  __typename?: "TripsReviewCollectionCarouselCard";
  card: TripsReviewCollectionCard;
  id?: Maybe<Scalars["String"]>;
  selectedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
};

export type TripsReviewCollectionResponse = {
  __typename?: "TripsReviewCollectionResponse";
  reviewsCarousel?: Maybe<TripsReviewCollectionCarousel>;
  reviewsSheet?: Maybe<TripsReviewCollectionSheet>;
};

export type TripsReviewCollectionSheet = {
  __typename?: "TripsReviewCollectionSheet";
  card: TripsReviewCollectionCard;
  closedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  heading: EgdsHeading;
  presentedAnalytics?: Maybe<Scalars["AnalyticsPayload"]>;
  sheet: EgdsSheet;
};

export type TripsSaveActivityAttributes = {
  __typename?: "TripsSaveActivityAttributes";
  dateRange?: Maybe<DateRange>;
  regionId?: Maybe<Scalars["String"]>;
};

export interface TripsSaveActivityAttributesInput {
  dateRange?: InputMaybe<DateRangeInput>;
  regionId?: InputMaybe<Scalars["String"]>;
}

export type TripsSaveCarOfferAttributes = {
  __typename?: "TripsSaveCarOfferAttributes";
  categoryCode: Scalars["String"];
  fuelAcCode: Scalars["String"];
  offerToken: Scalars["String"];
  searchCriteria: PrimaryCarCriteria;
  transmissionDriveCode: Scalars["String"];
  typeCode: Scalars["String"];
  vendorCode: Scalars["String"];
};

export interface TripsSaveCarOfferAttributesInput {
  categoryCode: Scalars["String"];
  fuelAcCode: Scalars["String"];
  offerToken: Scalars["String"];
  searchCriteria: PrimaryCarCriteriaInput;
  transmissionDriveCode: Scalars["String"];
  typeCode: Scalars["String"];
  vendorCode: Scalars["String"];
}

export type TripsSaveFlightSearchAttributes = {
  __typename?: "TripsSaveFlightSearchAttributes";
  searchCriteria: FlightSearchCriteria;
};

export type TripsSaveFlightSearchCriteria = {
  __typename?: "TripsSaveFlightSearchCriteria";
  flightSearchFilters?: Maybe<Array<FlightsSearchFilterValues>>;
  primary: PrimaryFlightCriteria;
};

export interface TripsSaveFlightSearchCriteriaInput {
  flightSearchFilters: Array<FlightsSearchFilterValuesInput>;
  primary: PrimaryFlightCriteriaInput;
}

export type TripsSaveItem = {
  __typename?: "TripsSaveItem";
  attributes?: Maybe<TripsSaveItemAttributes>;
  initialChecked: Scalars["Boolean"];
  itemId: Scalars["String"];
  remove: TripsSaveItemProperties;
  save: TripsSaveItemProperties;
  source: PageLocation;
  subscriptionAttributes?: Maybe<TripsSaveItemSubscriptionAttributes>;
};

export type TripsSaveItemAttributes =
  | TripsSaveActivityAttributes
  | TripsSaveCarOfferAttributes
  | TripsSaveFlightSearchAttributes
  | TripsSavePackageAttributes
  | TripsSaveStayAttributes;

export interface TripsSaveItemAttributesInput {
  activityAttributes?: InputMaybe<TripsSaveActivityAttributesInput>;
  carAttributes?: InputMaybe<TripsSaveCarOfferAttributesInput>;
  flightAttributes?: InputMaybe<FlightSearchCriteriaInput>;
  packageAttributes?: InputMaybe<TripsSavePackageAttributesInput>;
  stayAttributes?: InputMaybe<TripsSaveStayAttributesInput>;
}

export type TripsSaveItemDetails = {
  __typename?: "TripsSaveItemDetails";
  graphicText?: Maybe<EgdsGraphicText>;
  heading?: Maybe<Scalars["String"]>;
  notificationsToggle?: Maybe<TripsIconToggle>;
  primary: TripsInlineContent;
  saveItemButton: TripsSecondaryButton;
};

export interface TripsSaveItemInput {
  anchorPrice?: InputMaybe<Scalars["String"]>;
  productSearchCriteria: TripsProductSearchCriteriaInput;
}

export type TripsSaveItemProperties = {
  __typename?: "TripsSaveItemProperties";
  accessibility?: Maybe<Scalars["String"]>;
  adaptexSuccessCampaignIds?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  analytics?: Maybe<ClientSideAnalytics>;
  label?: Maybe<Scalars["String"]>;
};

export type TripsSaveItemResponse = {
  __typename?: "TripsSaveItemResponse";
  saveItemButton: TripsSecondaryButton;
  saveItemDetails: TripsSaveItemDetails;
  saveItemToTripToggle: TripsSaveItemToggle;
  /** @deprecated Don't use saveItemToggle, instead use saveItemToTripToggle which contains the action handler. */
  saveItemToggle?: Maybe<TripsSaveItem>;
  subscriptionAttributes: TripsSubscriptionAttributes;
};

export type TripsSaveItemSubscriptionAttributes = {
  __typename?: "TripsSaveItemSubscriptionAttributes";
  anchorPrice?: Maybe<Scalars["String"]>;
};

export type TripsSaveItemToTripAction = TripsAction & {
  __typename?: "TripsSaveItemToTripAction";
  analytics: ClientSideAnalytics;
  /** @deprecated itemId has been deprecated in favour of saveItemInput.itemId */
  itemId: Scalars["String"];
  saveItemInput?: Maybe<TripsPlan>;
  tripId: Scalars["String"];
};

export type TripsSaveItemToggle = {
  __typename?: "TripsSaveItemToggle";
  action: TripsAction;
  item: TripsSaveItem;
};

export type TripsSaveItemVariant = "CONTROL" | "VARIANT_B" | "VARIANT_C";

export type TripsSaveItemWrapper = {
  __typename?: "TripsSaveItemWrapper";
  tripsSaveItem: TripsSaveItem;
  variant?: Maybe<TripsSaveItemVariant>;
};

export type TripsSaveNewTripAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsSaveNewTripAction";
    analytics: ClientSideAnalytics;
    inputIds: Array<Scalars["String"]>;
    metadata?: Maybe<TripCreationMetadata>;
  };

export type TripsSavePackageAttributes = {
  __typename?: "TripsSavePackageAttributes";
  packageOfferId?: Maybe<Scalars["String"]>;
  sessionId: Scalars["String"];
};

export interface TripsSavePackageAttributesInput {
  packageOfferId?: InputMaybe<Scalars["String"]>;
  sessionId: Scalars["String"];
}

export type TripsSaveStayAttributes = {
  __typename?: "TripsSaveStayAttributes";
  checkInDate?: Maybe<Date>;
  checkoutDate?: Maybe<Date>;
  regionId?: Maybe<Scalars["String"]>;
  roomConfiguration?: Maybe<Array<PropertyRoom>>;
};

export interface TripsSaveStayAttributesInput {
  checkInDate?: InputMaybe<DateInput>;
  checkoutDate?: InputMaybe<DateInput>;
  regionId?: InputMaybe<Scalars["String"]>;
  roomConfiguration?: InputMaybe<Array<PropertyRoomInput>>;
}

export interface TripsSaveStayInput {
  checkInDate?: InputMaybe<DateTimeInput>;
  checkoutDate?: InputMaybe<DateTimeInput>;
  propertyId: Scalars["String"];
  regionId?: InputMaybe<Scalars["String"]>;
  roomConfiguration?: InputMaybe<Array<TripsStayRoomTravelerInfoInput>>;
  sourceType?: InputMaybe<TripsPlanSourceType>;
}

export type TripsSavedItemPrice = {
  __typename?: "TripsSavedItemPrice";
  impressionAnalytics: ClientSideImpressionAnalytics;
  leadingGraphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type TripsSavedItemPricePrimer = {
  __typename?: "TripsSavedItemPricePrimer";
  tripItem: TripItem;
};

export type TripsScreen =
  | "INVITE_LANDING_PAGE"
  | "TRIP_ITEM_DETAILS"
  | "TRIP_ITEM_ITINERARY"
  | "TRIP_ITEM_MANAGE_BOOKING"
  | "TRIP_ITEM_PRICING_REWARDS"
  | "TRIP_ITEM_RECEIPT"
  | "TRIP_MANAGE_GUESTS"
  | "TRIP_OVERVIEW"
  | "TRIP_OVERVIEW_ITINERARY";

export interface TripsScreenshotInput {
  itemId: Scalars["String"];
  pageLocation?: InputMaybe<PageLocation>;
  saveItemAttributes: TripsSaveItemAttributesInput;
}

export type TripsSearchBookingView =
  | "SEARCH_BY_ITINERARY_NUMBER"
  | "SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL"
  | "SEARCH_RESULT";

export type TripsSecondaryButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsSecondaryButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"];
    grow?: Maybe<Scalars["Float"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
    width: TripsButtonWidth;
  };

export type TripsSectionContainer = TripsContainer & {
  __typename?: "TripsSectionContainer";
  elements: Array<TripsComposableElement>;
  heading?: Maybe<Scalars["String"]>;
  subTexts?: Maybe<Array<Scalars["String"]>>;
  subheadings?: Maybe<Array<Scalars["String"]>>;
  theme?: Maybe<ContainerTheme>;
};

export type TripsSendItineraryEmail = {
  __typename?: "TripsSendItineraryEmail";
  item: TripItem;
};

export type TripsSendItineraryEmailAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsSendItineraryEmailAction";
    analytics: ClientSideAnalytics;
    inputIds: Array<Scalars["String"]>;
    item: TripItem;
  };

export type TripsServiceRequestsButtonPrimer = {
  __typename?: "TripsServiceRequestsButtonPrimer";
  buttonStyle: EgdsButtonStyle;
  itineraryNumber: Scalars["String"];
  lineOfBusiness: Scalars["String"];
  orderLineId: Scalars["String"];
};

export type TripsSideEffects =
  | CopyToClipboardAction
  | TripsAcceptInviteAndNavigateToOverviewAction
  | TripsAddToCalendarAction
  | TripsAddToWalletAction
  | TripsCancelActivityAction
  | TripsCancelAssuranceAction
  | TripsCancelCarAction
  | TripsCancelFlightAction
  | TripsCancelInsuranceAction
  | TripsCancellationAction
  | TripsChangeItemDatesAction
  | TripsChangeSaveItemState
  | TripsCloseDialogAction
  | TripsCreateNewTripAction
  | TripsCreateTripFromItemAction
  | TripsCustomerNotificationOpenInAppAction
  | TripsDeleteTripAction
  | TripsDismissAction
  | TripsDismissDrawerAction
  | TripsExploreItinerariesAction
  | TripsFormAction
  | TripsInfoToast
  | TripsInlineActionToast
  | TripsInviteAcceptAction
  | TripsInviteAction
  | TripsLinkAction
  | TripsMapAction
  | TripsMapDirectionsAction
  | TripsMoveItemToTripAction
  | TripsNavigateToFilteredViewAction
  | TripsNavigateToItemDetailsAction
  | TripsNavigateToItemPricingAndRewardsAction
  | TripsNavigateToItemReceiptAction
  | TripsNavigateToItemVoucherAction
  | TripsNavigateToListViewAction
  | TripsNavigateToManageBookingAction
  | TripsNavigateToManageTripAction
  | TripsNavigateToTripOverviewAction
  | TripsNavigateToViewAction
  | TripsOpenBottomSheetAction
  | TripsOpenChangeDatesDatePickerAction
  | TripsOpenCreateNewTripDrawerAction
  | TripsOpenCreateNewTripDrawerForItemAction
  | TripsOpenDialogAction
  | TripsOpenDrawerAction
  | TripsOpenEditTripDrawerAction
  | TripsOpenEmailDrawerAction
  | TripsOpenFullScreenDialogAction
  | TripsOpenInviteDrawerAction
  | TripsOpenMenuAction
  | TripsOpenMoveTripItemDrawerAction
  | TripsOpenMultiSectionDialogAction
  | TripsOpenRequestSystemNotificationsDialogAction
  | TripsOpenSaveToTripDrawerAction
  | TripsPriceAlertAction
  | TripsRecentSearchPriceAlertAction
  | TripsSaveItemToTripAction
  | TripsSaveNewTripAction
  | TripsSendItineraryEmailAction
  | TripsStackedActionToast
  | TripsUnsaveItemFromTripAction
  | TripsUpdateTripAction
  | TripsVirtualAgentInitAction;

export type TripsSignal = {
  __typename?: "TripsSignal";
  reference?: Maybe<Scalars["String"]>;
  type: TripsSignalType;
};

export type TripsSignalFieldIdExistingValues = {
  __typename?: "TripsSignalFieldIdExistingValues";
  ids: Array<Scalars["String"]>;
  prefixes?: Maybe<Array<Scalars["String"]>>;
};

export type TripsSignalFieldIdValue = {
  __typename?: "TripsSignalFieldIdValue";
  id: Scalars["String"];
};

export interface TripsSignalListener {
  signal?: Maybe<TripsSignal>;
}

export type TripsSignalPair = {
  __typename?: "TripsSignalPair";
  key: TripsSignalPairKey;
  value: TripsSignalPairValue;
};

export type TripsSignalPairKey = "PRIMARY" | "SECONDARY" | "STATUS";

export type TripsSignalPairValue = TripsSignalFieldIdExistingValues | TripsSignalFieldIdValue | TripsSignalValue;

export type TripsSignalType = "REMOVE" | "UPDATE";

export type TripsSignalValue = {
  __typename?: "TripsSignalValue";
  value: Scalars["String"];
};

export type TripsSlimCard = TripsCard & {
  __typename?: "TripsSlimCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use graphic */
  icon?: Maybe<Icon>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  priceAlertNotification?: Maybe<TripsIconToggle>;
  primary?: Maybe<Scalars["String"]>;
  /** @deprecated Use subTexts */
  secondaries?: Maybe<Array<Scalars["String"]>>;
  subTexts?: Maybe<Array<TripsText>>;
};

export type TripsSlimCardContainer = {
  __typename?: "TripsSlimCardContainer";
  heading?: Maybe<Scalars["String"]>;
  slimCards: Array<TripsSlimCard>;
  subHeaders?: Maybe<Array<TripsText>>;
};

export type TripsStackedActionToast = TripsToast & {
  __typename?: "TripsStackedActionToast";
  button: TripsButton;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"];
};

export type TripsStackedActionsDialog = TripsDialog & {
  __typename?: "TripsStackedActionsDialog";
  body: Array<TripsContent>;
  buttons: Array<TripsButton>;
  heading?: Maybe<Scalars["String"]>;
};

export type TripsStaticContentDrawer = {
  __typename?: "TripsStaticContentDrawer";
  buttons?: Maybe<Array<TripsButton>>;
  content?: Maybe<Array<TripsStaticContentDrawerContent>>;
  heading?: Maybe<Scalars["String"]>;
  toolbar?: Maybe<TripsToolbar>;
};

export type TripsStaticContentDrawerContent = EgdsPlainText | Image;

export interface TripsStayRoomTravelerInfoInput {
  childrenAges: Array<Scalars["Int"]>;
  numberOfAdults: Scalars["Int"];
}

export type TripsStepIndicatorStep = {
  __typename?: "TripsStepIndicatorStep";
  content?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  label: Scalars["String"];
  state: TripsTimelineStates;
  trackLabelA11y?: Maybe<Scalars["String"]>;
  trackLabelIcon?: Maybe<Scalars["String"]>;
  trackLabelText?: Maybe<Scalars["String"]>;
};

export type TripsStepIndicatorVertical = {
  __typename?: "TripsStepIndicatorVertical";
  accessibilityLabel: Scalars["String"];
  steps: Array<TripsStepIndicatorStep>;
};

export type TripsSubscriptionAttributes = {
  __typename?: "TripsSubscriptionAttributes";
  anchorPrice?: Maybe<Scalars["String"]>;
  subscriptionInputs?: Maybe<Array<GraphQlPair>>;
};

export interface TripsSubscriptionAttributesInput {
  anchorPrice?: InputMaybe<Scalars["String"]>;
  subscriptionInputs?: InputMaybe<Array<GraphQlPairInput>>;
}

export type TripsTertiaryButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsTertiaryButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"];
    grow?: Maybe<Scalars["Float"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
    width: TripsButtonWidth;
  };

export type TripsText = EgdsGraphicText | EgdsPlainText;

export type TripsTimelineStates = "CURRENT" | "FUTURE" | "PAST";

export interface TripsToast {
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"];
}

export type TripsToggleStatus = "DISABLED" | "ENABLED";

export type TripsToolbar = {
  __typename?: "TripsToolbar";
  accessibility?: Maybe<AccessibilityData>;
  actions: TripsToolbarActions;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsToolbarActions = {
  __typename?: "TripsToolbarActions";
  primary: TripsTertiaryButton;
  secondaries?: Maybe<Array<TripsTertiaryButton>>;
};

export type TripsUiActionCount = {
  __typename?: "TripsUIActionCount";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  count: Scalars["Int"];
  icon: Icon;
  id: Scalars["String"];
  timestamp: Scalars["String"];
};

export type TripsUiActionCountToggle = {
  __typename?: "TripsUIActionCountToggle";
  checked: Scalars["Boolean"];
  checkedAccessibility: Scalars["String"];
  checkedAnalytics: ClientSideAnalytics;
  checkedCount: Scalars["Int"];
  checkedIcon: Icon;
  uncheckedAccessibility: Scalars["String"];
  uncheckedAnalytics: ClientSideAnalytics;
  uncheckedCount: Scalars["Int"];
  uncheckedIcon: Icon;
};

export type TripsUiAttachQualificationType = "CARMIP" | "HOTMIP";

export type TripsUiAttachSavings = TripsUiAttachSavingsBanner | TripsUiAttachSavingsSheet;

export type TripsUiAttachSavingsBanner = {
  __typename?: "TripsUIAttachSavingsBanner";
  content: TripsUiMessagingCard;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiAttachSavingsSheet = {
  __typename?: "TripsUIAttachSavingsSheet";
  content: TripsUiAttachSavingsSheetContent;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  sheet: EgdsSheet;
};

export type TripsUiAttachSavingsSheetContent = {
  __typename?: "TripsUIAttachSavingsSheetContent";
  button: TripsUiExternalLinkPrimaryButton;
  graphic: UiGraphic;
  heading: Scalars["String"];
  message: Scalars["String"];
};

export type TripsUiAttachSavingsVariant = "BANNER" | "SHEET";

export type TripsUiAvatar = TripsUiIconAvatar | TripsUiImageAvatar | TripsUiTextAvatar;

export type TripsUiAvatarGroup = {
  __typename?: "TripsUIAvatarGroup";
  items: Array<TripsUiAvatarGroupItem>;
};

export type TripsUiAvatarGroupItem =
  | TripsUiIconAvatarGroupItem
  | TripsUiImageAvatarGroupItem
  | TripsUiTextAvatarGroupItem;

export type TripsUiBookableItineraryItemCard = {
  __typename?: "TripsUIBookableItineraryItemCard";
  accessibility?: Maybe<Scalars["String"]>;
  action: UiLinkAction;
  analytics?: Maybe<ClientSideAnalytics>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  itemPricePrimer: TripsUiItemPricePrimer;
  media?: Maybe<TripsItemCardMedia>;
  primary: Scalars["String"];
  saveToggle: TripsUiItineraryItemSaveToggle;
};

export type TripsUiBookedItemCard = {
  __typename?: "TripsUIBookedItemCard";
  accessibility?: Maybe<Scalars["String"]>;
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  badge?: Maybe<EgdsStandardBadge>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  logo?: Maybe<Image>;
  media?: Maybe<TripsItemCardMedia>;
  menu?: Maybe<TripsUiItemCardMenu>;
  primary: Scalars["String"];
};

export interface TripsUiButton {
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
}

export type TripsUiCanceledItemCard = {
  __typename?: "TripsUICanceledItemCard";
  accessibility?: Maybe<Scalars["String"]>;
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  badge?: Maybe<EgdsStandardBadge>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  logo?: Maybe<Image>;
  media?: Maybe<TripsItemCardMedia>;
  menu?: Maybe<TripsUiItemCardMenu>;
  primary: Scalars["String"];
};

export type TripsUiChatGptEntrypointResponse = {
  __typename?: "TripsUIChatGPTEntrypointResponse";
  card: TripsUiMessagingLinkCard;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiCloseDialogButton = {
  __typename?: "TripsUICloseDialogButton";
  button: TripsUiButton;
};

export type TripsUiCloseMapFloatingActionButton = {
  __typename?: "TripsUICloseMapFloatingActionButton";
  /** @deprecated Use icon.description instead */
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type TripsUiComment = {
  __typename?: "TripsUIComment";
  avatar: TripsUiAvatar;
  comment: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  timestamp: Scalars["String"];
};

export type TripsUiComments = {
  __typename?: "TripsUIComments";
  content: TripsUiCommentsTabContent;
  disclaimerBanner?: Maybe<TripsUiMessagingBannerCard>;
  postCommentSection: TripsUiPostCommentSection;
};

export type TripsUiCommentsAndVotesFailureResponse = {
  __typename?: "TripsUICommentsAndVotesFailureResponse";
  primary: Scalars["String"];
  retryButton: TripsUiCommentsAndVotesRetryButton;
  secondaries: Array<Scalars["String"]>;
};

export type TripsUiCommentsAndVotesResponse = TripsUiCommentsAndVotesFailureResponse | TripsUiCommentsAndVotesTabs;

export type TripsUiCommentsAndVotesRetryButton = {
  __typename?: "TripsUICommentsAndVotesRetryButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiCommentsAndVotesRetryPrimer;
};

export type TripsUiCommentsAndVotesRetryPrimer = {
  __typename?: "TripsUICommentsAndVotesRetryPrimer";
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiCommentsAndVotesSheet = {
  __typename?: "TripsUICommentsAndVotesSheet";
  fallback: TripsUiCommentsAndVotesFailureResponse;
  primer: TripsUiCommentsAndVotesSheetPrimer;
  toolbar: TripsUiCommentsAndVotesSheetToolbar;
};

export type TripsUiCommentsAndVotesSheetPrimer = {
  __typename?: "TripsUICommentsAndVotesSheetPrimer";
  defaultTab?: Maybe<Scalars["String"]>;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiCommentsAndVotesSheetToolbar = {
  __typename?: "TripsUICommentsAndVotesSheetToolbar";
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiCommentsAndVotesTabs = {
  __typename?: "TripsUICommentsAndVotesTabs";
  commentsTab: TripsUiCommentsTab;
  defaultTab: Scalars["String"];
  votesTab: TripsUiVotesTab;
};

export type TripsUiCommentsList = {
  __typename?: "TripsUICommentsList";
  comments: Array<TripsUiComment>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiCommentsTab = {
  __typename?: "TripsUICommentsTab";
  content: TripsUiComments;
  tab: TripsUiTab;
};

export type TripsUiCommentsTabContent = TripsUiCommentsList | TripsUiTabContentEmpty;

export type TripsUiCopyInviteLinkButton = {
  __typename?: "TripsUICopyInviteLinkButton";
  copiedButton: TripsUiPrimaryButton;
  copyButton: TripsUiPrimaryButton;
  link: HttpUri;
};

export type TripsUiCreateInviteErrorResponse = {
  __typename?: "TripsUICreateInviteErrorResponse";
  toast: TripsUiToast;
};

export type TripsUiCreateInviteResponse = TripsUiCreateInviteErrorResponse | TripsUiCreateInviteSuccessResponse;

export type TripsUiCreateInviteSuccessResponse = {
  __typename?: "TripsUICreateInviteSuccessResponse";
  shareSheetButton: TripsUiOpenInviteToTripShareSheetButton;
};

export type TripsUiCreateTripAndSaveItemSaveButton = {
  __typename?: "TripsUICreateTripAndSaveItemSaveButton";
  button: TripsUiPrimaryButton;
  createTripPrimer: TripsUiCreateTripPrimer;
};

export type TripsUiCreateTripFormSheet = {
  __typename?: "TripsUICreateTripFormSheet";
  /** @deprecated DO NOT USE! */
  itemId?: Maybe<Scalars["String"]>;
  primary: Scalars["String"];
  /** @deprecated DO NOT USE! Use submitButton. */
  saveButton: TripsUiPrimaryButton;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  submitButton: TripsUiCreateTripFormSheetSaveButton;
  toolbar: TripsUiSheetToolbar;
  tripNameInput: EgdsTextInputField;
};

export type TripsUiCreateTripFormSheetSaveButton = TripsUiCreateTripAndSaveItemSaveButton | TripsUiCreateTripSaveButton;

export type TripsUiCreateTripPrimer = {
  __typename?: "TripsUICreateTripPrimer";
  itemId: Scalars["String"];
  operationType: Scalars["String"];
  tripContext: TripsUiTripContext;
};

export type TripsUiCreateTripResponse = {
  __typename?: "TripsUICreateTripResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiCreateTripSaveButton = {
  __typename?: "TripsUICreateTripSaveButton";
  button: TripsUiPrimaryButton;
};

export type TripsUiCustomerNotificationOptionalContext = {
  __typename?: "TripsUICustomerNotificationOptionalContext";
  tripId: Scalars["String"];
  tripItemId: Scalars["String"];
};

export type TripsUiCustomerNotificationPrimer = {
  __typename?: "TripsUICustomerNotificationPrimer";
  funnelLocation: Scalars["String"];
  lineOfBusiness: Scalars["String"];
  notificationLocation: Scalars["String"];
  optionalContext?: Maybe<TripsUiCustomerNotificationOptionalContext>;
  xPageId: Scalars["String"];
};

export type TripsUiDatePickerAnalytics = {
  __typename?: "TripsUIDatePickerAnalytics";
  closeAnalytics: ClientSideAnalytics;
  nextPageAnalytics: ClientSideAnalytics;
  previousPageAnalytics: ClientSideAnalytics;
  submitAnalytics: ClientSideAnalytics;
};

export type TripsUiDatePickerAttributes = {
  __typename?: "TripsUIDatePickerAttributes";
  analytics: TripsUiDatePickerAnalytics;
  buttonText: Scalars["String"];
  daysBookableInAdvance: Scalars["Int"];
  endDate?: Maybe<Date>;
  footerText?: Maybe<Scalars["String"]>;
  itemId: Scalars["String"];
  itemType: Scalars["String"];
  maxDateRange: Scalars["Int"];
  midnightBookingOffset?: Maybe<Scalars["Int"]>;
  minDateRange: Scalars["Int"];
  productId?: Maybe<Scalars["String"]>;
  singleDateSelect: Scalars["Boolean"];
  startDate?: Maybe<Date>;
  tripId: Scalars["String"];
};

export type TripsUiDialog = {
  __typename?: "TripsUIDialog";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideImpressionEventAnalytics;
  closeAnalytics: ClientSideAnalytics;
};

export type TripsUiDialogFooterButtonLayout = "INLINE" | "STACKED";

export type TripsUiEnrichedSecondary = EgdsGraphicText | EgdsPlainText;

export type TripsUiExternalLinkPrimaryButton = {
  __typename?: "TripsUIExternalLinkPrimaryButton";
  button: TripsUiPrimaryButton;
  link: UiLinkAction;
};

export type TripsUiFavoriteToggle = {
  __typename?: "TripsUIFavoriteToggle";
  analytics: ClientSideAnalytics;
  isSelected: Scalars["Boolean"];
  selectedAccessibilityLabel: Scalars["String"];
  unselectedAccessibilityLabel: Scalars["String"];
};

export type TripsUiFullScreenMap = {
  __typename?: "TripsUIFullScreenMap";
  /** @deprecated This is a temporary field that will eventually be replaced. Do not use. */
  cards?: Maybe<Array<TripsUiMapItemCard>>;
  /** @deprecated Use TripsUIStaticMapContent.closeButton instead */
  closeButton: TripsUiCloseMapFloatingActionButton;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  map: EgdsBasicMap;
};

export type TripsUiGraphicTrigger = {
  __typename?: "TripsUIGraphicTrigger";
  analytics: ClientSideAnalytics;
  graphic: UiGraphic;
};

export type TripsUiIconAvatar = {
  __typename?: "TripsUIIconAvatar";
  icon: Icon;
};

export type TripsUiIconAvatarGroupItem = {
  __typename?: "TripsUIIconAvatarGroupItem";
  icon: Icon;
};

export type TripsUiImageAvatar = {
  __typename?: "TripsUIImageAvatar";
  image: Image;
};

export type TripsUiImageAvatarGroupItem = {
  __typename?: "TripsUIImageAvatarGroupItem";
  image: Image;
};

export type TripsUiImageGalleryCarouselSheet = {
  __typename?: "TripsUIImageGalleryCarouselSheet";
  carousel: TripCarousel;
  items: Array<TripsUiImageGalleryMedia>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiImageGalleryErrorResponse = {
  __typename?: "TripsUIImageGalleryErrorResponse";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"];
  retryButton: TripsUiImageGalleryRetryButton;
  secondaries: Array<Scalars["String"]>;
};

export type TripsUiImageGalleryMedia = {
  __typename?: "TripsUIImageGalleryMedia";
  action?: Maybe<TripsUiImageGalleryMediaAction>;
  identifier: Scalars["String"];
  media: Media;
  position: TripsUiImageGalleryMediaPosition;
  text?: Maybe<Scalars["String"]>;
};

export type TripsUiImageGalleryMediaAction = {
  __typename?: "TripsUIImageGalleryMediaAction";
  analytics: ClientSideAnalytics;
};

export type TripsUiImageGalleryMediaPosition = {
  __typename?: "TripsUIImageGalleryMediaPosition";
  accessibility: Scalars["String"];
  text: Scalars["String"];
};

export type TripsUiImageGalleryPrimer = {
  __typename?: "TripsUIImageGalleryPrimer";
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiImageGalleryResponse = TripsUiImageGalleryErrorResponse | TripsUiImageGallerySuccessResponse;

export type TripsUiImageGalleryRetryButton = {
  __typename?: "TripsUIImageGalleryRetryButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiImageGalleryPrimer;
};

export type TripsUiImageGallerySuccessResponse = {
  __typename?: "TripsUIImageGallerySuccessResponse";
  gallerySheet: TripsUiImageGalleryCarouselSheet;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  media: Array<TripsUiImageGalleryMedia>;
  primary: Scalars["String"];
};

export type TripsUiInviteToTripButton = TripsUiOpenInviteToTripDialogButton | TripsUiOpenInviteToTripShareSheetButton;

export type TripsUiInviteToTripDialog = {
  __typename?: "TripsUIInviteToTripDialog";
  dialog: TripsUiDialog;
  sections: Array<TripsUiInviteToTripDialogSection>;
};

export type TripsUiInviteToTripDialogCopyInviteSection = {
  __typename?: "TripsUIInviteToTripDialogCopyInviteSection";
  button: TripsUiCopyInviteLinkButton;
  heading: Scalars["String"];
  link: HttpUri;
};

export type TripsUiInviteToTripDialogEmailInputSection = {
  __typename?: "TripsUIInviteToTripDialogEmailInputSection";
  button: TripsUiSendInviteButton;
  emailInput: EgdsInputField;
  heading: Scalars["String"];
  messageInput: EgdsTextAreaInputField;
};

export type TripsUiInviteToTripDialogSection =
  | TripsUiInviteToTripDialogCopyInviteSection
  | TripsUiInviteToTripDialogEmailInputSection
  | TripsUiInviteToTripDialogTextSection;

export type TripsUiInviteToTripDialogTextSection = {
  __typename?: "TripsUIInviteToTripDialogTextSection";
  text: Scalars["String"];
};

export type TripsUiItemCardActionCount = TripsUiItemCardCommentActionCount | TripsUiItemCardVoteActionCount;

export type TripsUiItemCardCommentActionCount = {
  __typename?: "TripsUIItemCardCommentActionCount";
  sheet: TripsUiCommentsAndVotesSheet;
  trigger: TripsUiActionCount;
};

export type TripsUiItemCardMediaGalleryButton = {
  __typename?: "TripsUIItemCardMediaGalleryButton";
  analytics: ClientSideAnalytics;
  icon: Icon;
  sheet: TripsUiItemCardMediaGallerySheet;
};

export type TripsUiItemCardMediaGalleryImage = {
  __typename?: "TripsUIItemCardMediaGalleryImage";
  action: TripsUiItemCardMediaGalleryImageAction;
  galleryButton: TripsUiItemCardMediaGalleryButton;
  image: Image;
};

export type TripsUiItemCardMediaGalleryImageAction = {
  __typename?: "TripsUIItemCardMediaGalleryImageAction";
  analytics: ClientSideAnalytics;
  sheet: TripsUiItemCardMediaGallerySheet;
};

export type TripsUiItemCardMediaGallerySheet = {
  __typename?: "TripsUIItemCardMediaGallerySheet";
  primer: TripsUiImageGalleryPrimer;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiItemCardMenu = {
  __typename?: "TripsUIItemCardMenu";
  items: Array<TripsUiItemCardMenuItem>;
  menu: TripsUiMenu;
};

export type TripsUiItemCardMenuItem =
  | TripsUiItemCardMenuItemChangeDates
  | TripsUiItemCardMenuItemMoveConnectedItem
  | TripsUiItemCardMenuItemPriceAlerts
  | TripsUiItemCardMenuItemPriceAlertsInApp
  | TripsUiItemCardMenuItemRemoveItemFromTrip
  | TripsUiItemCardMenuItemSaveToTrip
  | TripsUiItemCardMenuItemUpdateTrip
  | TripsUiMenuItemLink;

export type TripsUiItemCardMenuItemChangeDates = {
  __typename?: "TripsUIItemCardMenuItemChangeDates";
  analytics: ClientSideAnalytics;
  datePickerAttributes: TripsUiDatePickerAttributes;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemMoveConnectedItem = {
  __typename?: "TripsUIItemCardMenuItemMoveConnectedItem";
  analytics: ClientSideAnalytics;
  dialog: TripsUiMoveConnectedItemDialog;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemPlanningSheet = {
  __typename?: "TripsUIItemCardMenuItemPlanningSheet";
  toolbar: TripsUiItemCardMenuItemPlanningSheetToolbar;
  tripPlanningPrimer: TripsUiTripPlanningPrimer;
};

export type TripsUiItemCardMenuItemPlanningSheetToolbar = {
  __typename?: "TripsUIItemCardMenuItemPlanningSheetToolbar";
  createTripButton: TripsUiSheetToolbarCreateTripButton;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiItemCardMenuItemPriceAlerts = {
  __typename?: "TripsUIItemCardMenuItemPriceAlerts";
  analytics: ClientSideAnalytics;
  /** @deprecated Use analytics instead */
  disabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use item instead */
  disabledMenuItem: TripsUiMenuItem;
  /** @deprecated Use analytics instead */
  enabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use item instead */
  enabledMenuItem: TripsUiMenuItem;
  item: TripsUiMenuItem;
  /** @deprecated Don't use */
  priceAlertsEnabled: Scalars["Boolean"];
  updateStatePrimer: TripsUiUpdateItemPriceAlertsStatePrimer;
};

export type TripsUiItemCardMenuItemPriceAlertsInApp = {
  __typename?: "TripsUIItemCardMenuItemPriceAlertsInApp";
  analytics: ClientSideAnalytics;
  customerNotificationPrimer: TripsUiCustomerNotificationPrimer;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemRefreshItineraryItem = {
  __typename?: "TripsUIItemCardMenuItemRefreshItineraryItem";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  primer: TripsUiRefreshItineraryItemPrimer;
};

export type TripsUiItemCardMenuItemRemoveItemFromItinerary = {
  __typename?: "TripsUIItemCardMenuItemRemoveItemFromItinerary";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  primer: TripsUiRemoveItineraryItemPrimer;
};

export type TripsUiItemCardMenuItemRemoveItemFromTrip = {
  __typename?: "TripsUIItemCardMenuItemRemoveItemFromTrip";
  analytics: ClientSideAnalytics;
  dialog: TripsUiRemoveItemFromTripDialog;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemSaveToTrip = {
  __typename?: "TripsUIItemCardMenuItemSaveToTrip";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  plannedTripsPrimer: TripsUiPlannedTripsPrimer;
};

export type TripsUiItemCardMenuItemUpdateTrip = {
  __typename?: "TripsUIItemCardMenuItemUpdateTrip";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  planningSheet: TripsUiTripPlanningSheet;
  /** @deprecated Use planningSheet instead */
  sheet: TripsUiItemCardMenuItemPlanningSheet;
};

export type TripsUiItemCardPriceAlertsToggle = {
  __typename?: "TripsUIItemCardPriceAlertsToggle";
  analytics: ClientSideImpressionEventAnalytics;
  primer: TripsUiUpdateItemPriceAlertsStatePrimer;
  toggle: TripsUiPriceAlertsIconToggle;
};

export type TripsUiItemCardVoteActionCount = {
  __typename?: "TripsUIItemCardVoteActionCount";
  action: TripsUiVoteAction;
  toggle: TripsUiActionCountToggle;
};

export type TripsUiItemPrice = TripsUiItemSimplePrice | TripsUiItemUnavailablePrice;

export type TripsUiItemPricePrimer = {
  __typename?: "TripsUIItemPricePrimer";
  itemId: Scalars["String"];
  /** @deprecated This is temporary until all traffic from the savedItemPrice query in bex-api-trips is migrated to the new tripItemPrice query */
  newQuery: Scalars["Boolean"];
};

export type TripsUiItemPriceResponse = {
  __typename?: "TripsUIItemPriceResponse";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  price: TripsUiItemPrice;
};

export type TripsUiItemSimplePrice = {
  __typename?: "TripsUIItemSimplePrice";
  leadingIcon?: Maybe<Icon>;
  primary: Scalars["String"];
  secondary: Scalars["String"];
};

export type TripsUiItemUnavailablePrice = {
  __typename?: "TripsUIItemUnavailablePrice";
  leadingIcon?: Maybe<Icon>;
  text: Scalars["String"];
};

export type TripsUiItineraryBuilderSmokeTestDialog = {
  __typename?: "TripsUIItineraryBuilderSmokeTestDialog";
  closeButton: TripsUiButton;
  content: Array<Scalars["String"]>;
  dialog: TripsUiDialog;
  heading: Scalars["String"];
};

export type TripsUiItineraryCardMenu = {
  __typename?: "TripsUIItineraryCardMenu";
  items: Array<TripsUiItineraryCardMenuItem>;
  menu: TripsUiMenu;
};

export type TripsUiItineraryCardMenuItem =
  | TripsUiItemCardMenuItemRefreshItineraryItem
  | TripsUiItemCardMenuItemRemoveItemFromItinerary
  | TripsUiItineraryCardMenuItemMoveToAnotherDay;

export type TripsUiItineraryCardMenuItemMoveToAnotherDay = {
  __typename?: "TripsUIItineraryCardMenuItemMoveToAnotherDay";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  smokeTestDialog?: Maybe<TripsUiItineraryBuilderSmokeTestDialog>;
};

export type TripsUiItineraryFailureResponse = {
  __typename?: "TripsUIItineraryFailureResponse";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"];
  refreshButton: TripsUiItineraryRefreshButton;
};

export type TripsUiItineraryItem = {
  __typename?: "TripsUIItineraryItem";
  card?: Maybe<TripsItemCard>;
  enrichedSecondaries: Array<TripsUiEnrichedSecondary>;
  icon: Icon;
  itineraryItemId: Scalars["String"];
  menu?: Maybe<TripsUiItineraryCardMenu>;
  /** @deprecated Use enrichedSecondaries instead */
  secondaries: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export interface TripsUiItineraryItemSaveToggle {
  toggle: TripsUiFavoriteToggle;
}

export type TripsUiItineraryPrimer = {
  __typename?: "TripsUIItineraryPrimer";
  tripId: Scalars["String"];
};

export type TripsUiItineraryRefreshButton = {
  __typename?: "TripsUIItineraryRefreshButton";
  button: TripsUiPrimaryButton;
  tripsItineraryPrimer: TripsUiItineraryPrimer;
};

export type TripsUiItineraryResponse = TripsUiItineraryFailureResponse | TripsUiItinerarySuccessResponse;

export type TripsUiItinerarySection = {
  __typename?: "TripsUIItinerarySection";
  heading: Scalars["String"];
  items?: Maybe<Array<TripsUiItineraryItem>>;
};

export type TripsUiItinerarySuccessResponse = {
  __typename?: "TripsUIItinerarySuccessResponse";
  sections: Array<TripsUiItinerarySection>;
};

export type TripsUiLinkButton = {
  __typename?: "TripsUILinkButton";
  action: UiLinkAction;
  button: TripsUiButton;
};

export type TripsUiManageParticipantsResponse = {
  __typename?: "TripsUIManageParticipantsResponse";
  button: TripsUiInviteToTripButton;
  participantAvatars: TripsUiParticipantAvatars;
};

export type TripsUiMapItemCard = {
  __typename?: "TripsUIMapItemCard";
  identifier: Scalars["String"];
  image?: Maybe<Image>;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsUiMenu = {
  __typename?: "TripsUIMenu";
  analytics: ClientSideImpressionEventAnalytics;
  trigger: TripsUiMenuTrigger;
};

export type TripsUiMenuItem = {
  __typename?: "TripsUIMenuItem";
  accessibility?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  title: Scalars["String"];
};

export type TripsUiMenuItemLink = {
  __typename?: "TripsUIMenuItemLink";
  action: UiLinkAction;
  item: TripsUiMenuItem;
};

export type TripsUiMenuTrigger = TripsUiGraphicTrigger | TripsUiTertiaryButton;

export type TripsUiMessagingActionCard = TripsUiMessagingCard & {
  __typename?: "TripsUIMessagingActionCard";
  icon?: Maybe<UiGraphic>;
  links: Array<EgdsStandardLink>;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type TripsUiMessagingBannerCard = TripsUiMessagingCard & {
  __typename?: "TripsUIMessagingBannerCard";
  icon?: Maybe<UiGraphic>;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
  theme: TripsUiMessagingBannerCardTheme;
};

export type TripsUiMessagingBannerCardTheme = "CRITICAL" | "DEFAULT" | "ERROR";

export interface TripsUiMessagingCard {
  icon?: Maybe<UiGraphic>;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
}

export type TripsUiMessagingLinkCard = TripsUiMessagingCard & {
  __typename?: "TripsUIMessagingLinkCard";
  icon?: Maybe<UiGraphic>;
  link: UiLinkAction;
  primary: Scalars["String"];
  secondaries?: Maybe<Array<Scalars["String"]>>;
  trailingIcon?: Maybe<Icon>;
};

export type TripsUiMoveConnectedItemButton = {
  __typename?: "TripsUIMoveConnectedItemButton";
  button: TripsUiButton;
  planningSheet: TripsUiTripPlanningSheet;
};

export type TripsUiMoveConnectedItemDialog = {
  __typename?: "TripsUIMoveConnectedItemDialog";
  content: Array<Scalars["String"]>;
  dialog: TripsUiDialog;
  footerButtons: TripsUiMoveConnectedItemDialogFooter;
};

export type TripsUiMoveConnectedItemDialogButton = TripsUiCloseDialogButton | TripsUiMoveConnectedItemButton;

export type TripsUiMoveConnectedItemDialogFooter = {
  __typename?: "TripsUIMoveConnectedItemDialogFooter";
  buttons: Array<TripsUiMoveConnectedItemDialogButton>;
  layout?: Maybe<TripsUiDialogFooterButtonLayout>;
};

export type TripsUiOpenInviteToTripDialogButton = {
  __typename?: "TripsUIOpenInviteToTripDialogButton";
  button: TripsUiTertiaryButton;
  dialog: TripsUiInviteToTripDialog;
};

export type TripsUiOpenInviteToTripShareSheetButton = {
  __typename?: "TripsUIOpenInviteToTripShareSheetButton";
  button: TripsUiTertiaryButton;
  image?: Maybe<Image>;
  link: HttpUri;
  messages: Array<Scalars["String"]>;
  tripName?: Maybe<Scalars["String"]>;
};

export type TripsUiOverviewTabs = {
  __typename?: "TripsUIOverviewTabs";
  selectedTabId: Scalars["String"];
  tabs: Array<TripsUiTab>;
};

export type TripsUiParticipantAvatar = {
  __typename?: "TripsUIParticipantAvatar";
  action: UiLinkAction;
  avatarGroup: TripsUiAvatar;
};

export type TripsUiParticipantAvatars = TripsUiParticipantAvatar | TripsUiParticipantsAvatarGroup;

export type TripsUiParticipantsAvatarGroup = {
  __typename?: "TripsUIParticipantsAvatarGroup";
  action: UiLinkAction;
  avatarGroup: TripsUiAvatarGroup;
};

export type TripsUiPlannedItemCardBadge = EgdsStandardBadge | TripsUiSavedBadge;

export type TripsUiPlannedTripsPrimer = {
  __typename?: "TripsUIPlannedTripsPrimer";
  attributes: TripsSaveItemAttributes;
  itemId?: Maybe<Scalars["String"]>;
  pageLocation: PageLocation;
};

export type TripsUiPlanningCard = TripsUiPlanningTripCard;

export type TripsUiPlanningFailureResponse = {
  __typename?: "TripsUIPlanningFailureResponse";
  primary: Scalars["String"];
  tryAgainButton: TripsUiPrimaryButton;
};

export type TripsUiPlanningResponse =
  | TripsUiPlanningFailureResponse
  | TripsUiPlanningSuccessResponse
  | TripsUiPlanningUnauthenticatedResponse;

export type TripsUiPlanningSuccessResponse = {
  __typename?: "TripsUIPlanningSuccessResponse";
  cards: Array<TripsUiPlanningCard>;
  primary: Scalars["String"];
};

export type TripsUiPlanningTripCard = {
  __typename?: "TripsUIPlanningTripCard";
  accessibility: Scalars["String"];
  action: TripsUiUpdateTripItemTripPrimer;
  analytics: ClientSideAnalytics;
  /** @deprecated Use saveIcon instead */
  icon: Icon;
  image?: Maybe<Image>;
  primary: Scalars["String"];
  saveIcon?: Maybe<Icon>;
  secondaries: Array<Scalars["String"]>;
};

export type TripsUiPlanningUnauthenticatedResponse = {
  __typename?: "TripsUIPlanningUnauthenticatedResponse";
  primary: Scalars["String"];
  signInButton: TripsUiLinkButton;
};

export type TripsUiPostCommentButton = {
  __typename?: "TripsUIPostCommentButton";
  button: TripsUiTertiaryButton;
  primer: TripsUiSaveCommentPrimer;
};

export type TripsUiPostCommentSection = {
  __typename?: "TripsUIPostCommentSection";
  input: EgdsTextAreaInputField;
  postButton: TripsUiPostCommentButton;
};

export type TripsUiPriceAlertsIconToggle = {
  __typename?: "TripsUIPriceAlertsIconToggle";
  accessibilityLabel: Scalars["String"];
  analytics: ClientSideAnalytics;
  /** @deprecated Use accessibilityLabel instead */
  disabledAccessibilityLabel: Scalars["String"];
  /** @deprecated Use analytics instead */
  disabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use icon instead */
  disabledIcon: Icon;
  /** @deprecated Don't use */
  enabled: Scalars["Boolean"];
  /** @deprecated Use accessibilityLabel instead */
  enabledAccessibilityLabel: Scalars["String"];
  /** @deprecated Use analytics instead */
  enabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use icon instead */
  enabledIcon: Icon;
  icon: Icon;
};

export type TripsUiPrimaryButton = TripsUiButton & {
  __typename?: "TripsUIPrimaryButton";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsUiRefreshItineraryItemPrimer = {
  __typename?: "TripsUIRefreshItineraryItemPrimer";
  itineraryId: Scalars["String"];
  itineraryItemId: Scalars["String"];
};

export type TripsUiRemoveItemFromTripButton = {
  __typename?: "TripsUIRemoveItemFromTripButton";
  button: TripsUiButton;
  primer: TripsUiRemoveItemFromTripPrimer;
};

export type TripsUiRemoveItemFromTripDialog = {
  __typename?: "TripsUIRemoveItemFromTripDialog";
  content: Array<Scalars["String"]>;
  dialog: TripsUiDialog;
  footer: TripsUiRemoveItemFromTripDialogFooter;
};

export type TripsUiRemoveItemFromTripDialogButton = TripsUiCloseDialogButton | TripsUiRemoveItemFromTripButton;

export type TripsUiRemoveItemFromTripDialogFooter = {
  __typename?: "TripsUIRemoveItemFromTripDialogFooter";
  buttons: Array<TripsUiRemoveItemFromTripDialogButton>;
  layout?: Maybe<TripsUiDialogFooterButtonLayout>;
};

export type TripsUiRemoveItemFromTripPrimer = {
  __typename?: "TripsUIRemoveItemFromTripPrimer";
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiRemoveItineraryItemPrimer = {
  __typename?: "TripsUIRemoveItineraryItemPrimer";
  itineraryId: Scalars["String"];
  itineraryItemId: Scalars["String"];
};

export type TripsUiRemoveTripItemResponse = {
  __typename?: "TripsUIRemoveTripItemResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiResponseStatus = "FAIL" | "SUCCESS";

export interface TripsUiSaveActivityCriteriaInput {
  products?: InputMaybe<Array<TripsUiSaveActivityProductCriteriaInput>>;
}

export interface TripsUiSaveActivityProductCriteriaInput {
  dateRange?: InputMaybe<DateRangeInput>;
  itemId: Scalars["String"];
  regionId?: InputMaybe<Scalars["String"]>;
}

export type TripsUiSaveCommentPrimer = {
  __typename?: "TripsUISaveCommentPrimer";
  commentId?: Maybe<Scalars["String"]>;
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiSaveItem = TripsSaveItem;

export type TripsUiSaveItemResponse = {
  __typename?: "TripsUISaveItemResponse";
  saveItem: TripsUiSaveItem;
  toast?: Maybe<TripsUiToast>;
};

export type TripsUiSaveItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUISaveItineraryItem";
  primer: TripsUiSaveTripItemsPrimer;
  toggle: TripsUiFavoriteToggle;
};

export interface TripsUiSaveLodgingCriteriaInput {
  products?: InputMaybe<Array<TripsUiSaveLodgingProductCriteriaInput>>;
}

export interface TripsUiSaveLodgingProductCriteriaInput {
  dateRange?: InputMaybe<DateRangeInput>;
  itemId: Scalars["String"];
  regionId?: InputMaybe<Scalars["String"]>;
}

export interface TripsUiSavePointOfInterestCriteriaInput {
  location?: InputMaybe<CoordinatesInput>;
  name?: InputMaybe<Scalars["String"]>;
  regionId: Scalars["String"];
}

export type TripsUiSaveTripItemCommentFailureResponse = {
  __typename?: "TripsUISaveTripItemCommentFailureResponse";
  toast: TripsUiToast;
};

export type TripsUiSaveTripItemCommentResponse =
  | TripsUiSaveTripItemCommentFailureResponse
  | TripsUiSaveTripItemCommentSuccessResponse;

export type TripsUiSaveTripItemCommentSuccessResponse = {
  __typename?: "TripsUISaveTripItemCommentSuccessResponse";
  actionCount: TripsUiActionCount;
  comments: Array<TripsUiComment>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export interface TripsUiSaveTripItemsCriteriaInput {
  activity?: InputMaybe<TripsUiSaveActivityCriteriaInput>;
  lodging?: InputMaybe<TripsUiSaveLodgingCriteriaInput>;
  pointsOfInterest?: InputMaybe<Array<TripsUiSavePointOfInterestCriteriaInput>>;
}

export type TripsUiSaveTripItemsPrimer = {
  __typename?: "TripsUISaveTripItemsPrimer";
  /** @deprecated after save mutation is updated */
  endDate?: Maybe<Date>;
  itemId: Scalars["String"];
  /** @deprecated after save mutation is updated */
  startDate?: Maybe<Date>;
  tripId?: Maybe<Scalars["String"]>;
};

export type TripsUiSaveTripItemsResponse = {
  __typename?: "TripsUISaveTripItemsResponse";
  status: TripsUiResponseStatus;
  toast?: Maybe<TripsUiToast>;
  url?: Maybe<Uri>;
};

export type TripsUiSavedBadge = {
  __typename?: "TripsUISavedBadge";
  accessibility?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  size?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type TripsUiSavedItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUISavedItineraryItem";
  primer: TripsUiSaveTripItemsPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiSecondaryButton = TripsUiButton & {
  __typename?: "TripsUISecondaryButton";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsUiSendInviteButton = {
  __typename?: "TripsUISendInviteButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiSendInvitePrimer;
};

export type TripsUiSendInvitePrimer = {
  __typename?: "TripsUISendInvitePrimer";
  tripId: Scalars["String"];
};

export type TripsUiSheetToolbar = {
  __typename?: "TripsUISheetToolbar";
  closeAccessibility: Scalars["String"];
  closeAnalytics: ClientSideAnalytics;
  closeText?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type TripsUiSheetToolbarCreateTripButton = {
  __typename?: "TripsUISheetToolbarCreateTripButton";
  action: TripsUiSheetToolbarCreateTripFormAction;
  button: TripsUiTertiaryButton;
};

export type TripsUiSheetToolbarCreateTripFormAction = {
  __typename?: "TripsUISheetToolbarCreateTripFormAction";
  sheet: TripsUiCreateTripFormSheet;
};

export type TripsUiStaticMap = {
  __typename?: "TripsUIStaticMap";
  map: EgdsBasicMap;
  mapImage: Image;
  openMapAction: TripsUiStaticMapAction;
};

export type TripsUiStaticMapAction = {
  __typename?: "TripsUIStaticMapAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  content: TripsUiStaticMapContent;
  /** @deprecated Use TripsUIStaticMapContent.map instead */
  fullScreenMap: TripsUiFullScreenMap;
};

export type TripsUiStaticMapContent = {
  __typename?: "TripsUIStaticMapContent";
  closeButton: TripsUiCloseMapFloatingActionButton;
  map: TripsUiFullScreenMap;
};

export type TripsUiTab = {
  __typename?: "TripsUITab";
  analytics: ClientSideAnalytics;
  name: Scalars["String"];
  tabId: Scalars["String"];
};

export type TripsUiTabContentEmpty = {
  __typename?: "TripsUITabContentEmpty";
  icon: Icon;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"];
  secondaries: Array<Scalars["String"]>;
};

export type TripsUiTertiaryButton = TripsUiButton & {
  __typename?: "TripsUITertiaryButton";
  accessibility?: Maybe<Scalars["String"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsUiTextAvatar = {
  __typename?: "TripsUITextAvatar";
  text: Scalars["String"];
};

export type TripsUiTextAvatarGroupItem = {
  __typename?: "TripsUITextAvatarGroupItem";
  text: Scalars["String"];
};

export type TripsUiTextLinkAction = {
  __typename?: "TripsUITextLinkAction";
  action: UiLinkAction;
  text: Scalars["String"];
};

export type TripsUiToast = {
  __typename?: "TripsUIToast";
  action?: Maybe<TripsUiToastAction>;
  actionText?: Maybe<Scalars["String"]>;
  analytics: ClientSideImpressionEventAnalytics;
  text: Scalars["String"];
};

export type TripsUiToastAction = UiLinkAction;

export type TripsUiTripContext = {
  __typename?: "TripsUITripContext";
  fromTripId: Scalars["String"];
  toTripId?: Maybe<Scalars["String"]>;
};

export interface TripsUiTripContextInput {
  fromTripId: Scalars["String"];
  toTripId?: InputMaybe<Scalars["String"]>;
}

export type TripsUiTripItemContext = {
  __typename?: "TripsUITripItemContext";
  itemId: Scalars["String"];
  operationType: Scalars["String"];
  tripId: Scalars["String"];
};

export interface TripsUiTripItemContextInput {
  itemId: Scalars["String"];
  operationType: Scalars["String"];
  tripId: Scalars["String"];
}

export type TripsUiTripItineraryItemResponse = {
  __typename?: "TripsUITripItineraryItemResponse";
  status: TripsUiResponseStatus;
  toast?: Maybe<TripsUiToast>;
};

export type TripsUiTripPlanningPrimer = {
  __typename?: "TripsUITripPlanningPrimer";
  /** @deprecated Use tripsUITripItemContext-itemId instead */
  itemId: Scalars["String"];
  /** @deprecated Use tripsUITripItemContext-tripId instead */
  tripId: Scalars["String"];
  tripsUITripItemContext?: Maybe<TripsUiTripItemContext>;
};

export type TripsUiTripPlanningSheet = {
  __typename?: "TripsUITripPlanningSheet";
  toolbar: TripsUiTripPlanningSheetToolbar;
  tripPlanningPrimer: TripsUiTripPlanningPrimer;
};

export type TripsUiTripPlanningSheetToolbar = {
  __typename?: "TripsUITripPlanningSheetToolbar";
  createTripButton: TripsUiSheetToolbarCreateTripButton;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiUnsaveItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUIUnsaveItineraryItem";
  primer: TripsUiUpdateTripItemTripPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiUnsavedItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUIUnsavedItineraryItem";
  primer: TripsUiUpdateTripItemTripPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiUpdateItemPriceAlertsStatePrimer = {
  __typename?: "TripsUIUpdateItemPriceAlertsStatePrimer";
  alertEnabled: Scalars["Boolean"];
  itemId: Scalars["String"];
  subscriptionId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiUpdateTripItemDatesResponse = {
  __typename?: "TripsUIUpdateTripItemDatesResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemPriceAlertStatusResponse = {
  __typename?: "TripsUIUpdateTripItemPriceAlertStatusResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemTripPrimer = {
  __typename?: "TripsUIUpdateTripItemTripPrimer";
  itemId: Scalars["String"];
  operationType: Scalars["String"];
  tripContext: TripsUiTripContext;
};

export type TripsUiUpdateTripItemTripResponse = {
  __typename?: "TripsUIUpdateTripItemTripResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemVoteFailureResponse = {
  __typename?: "TripsUIUpdateTripItemVoteFailureResponse";
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemVoteResponse = TripsUiUpdateTripItemVoteFailureResponse;

export type TripsUiVoteAction = {
  __typename?: "TripsUIVoteAction";
  itemId: Scalars["String"];
  tripId: Scalars["String"];
};

export type TripsUiVoter = {
  __typename?: "TripsUIVoter";
  avatar: TripsUiAvatar;
  name: Scalars["String"];
};

export type TripsUiVotes = {
  __typename?: "TripsUIVotes";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"];
  voters: Array<TripsUiVoter>;
};

export type TripsUiVotesTab = {
  __typename?: "TripsUIVotesTab";
  content: TripsUiVotesTabContent;
  tab: TripsUiTab;
};

export type TripsUiVotesTabContent = TripsUiTabContentEmpty | TripsUiVotes;

export type TripsUnsaveItemFromTripAction = TripsAction & {
  __typename?: "TripsUnsaveItemFromTripAction";
  analytics: ClientSideAnalytics;
  emitSignals?: Maybe<Array<TripsEmitSignal>>;
  itemId: Scalars["String"];
  /** @deprecated lineOfBusiness has been deprecated in favour of tripEntity */
  lineOfBusiness: LineOfBusinessDomain;
  tripEntity: TripEntity;
  tripItem: TripItem;
};

export type TripsUpdateTrip = {
  __typename?: "TripsUpdateTrip";
  emitSignals?: Maybe<Array<TripsEmitSignal>>;
  overview: TripOverview;
};

export type TripsUpdateTripAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsUpdateTripAction";
    analytics: ClientSideAnalytics;
    emitSignals?: Maybe<Array<TripsEmitSignal>>;
    inputIds: Array<Scalars["String"]>;
    overview: TripOverview;
  };

export type TripsVaConversationProperties = {
  __typename?: "TripsVAConversationProperties";
  launchPoint?: Maybe<Scalars["String"]>;
  skipWelcome?: Maybe<Scalars["String"]>;
};

export type TripsVaInitAction = {
  __typename?: "TripsVAInitAction";
  accessibility: Scalars["String"];
  analytics: ClientSideAnalytics;
  applicationName?: Maybe<Scalars["String"]>;
  clientOverrides?: Maybe<TripsVaOverrideData>;
  text: Scalars["String"];
};

export type TripsVaIntentValue = {
  __typename?: "TripsVAIntentValue";
  id: Scalars["String"];
  value: Scalars["String"];
};

export type TripsVaOverrideData = {
  __typename?: "TripsVAOverrideData";
  conversationProperties?: Maybe<TripsVaConversationProperties>;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]>;
  intentArguments?: Maybe<Array<TripsVaIntentValue>>;
  subscribedEvents?: Maybe<Array<Scalars["String"]>>;
};

export type TripsValidatedInput = EgdsElement &
  EgdsInputField & {
    __typename?: "TripsValidatedInput";
    egdsElementId: Scalars["String"];
    errorMessage?: Maybe<Scalars["String"]>;
    inputType?: Maybe<TripsInputType>;
    instructions?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]>;
    readOnly: Scalars["Boolean"];
    required?: Maybe<Scalars["Boolean"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]>;
  };

export interface TripsValidatedInputAnalytics {
  analytics: ClientSideAnalytics;
  egdsElementId: Scalars["String"];
}

export type TripsValidatedInputChangeAnalytics = TripsValidatedInputAnalytics & {
  __typename?: "TripsValidatedInputChangeAnalytics";
  analytics: ClientSideAnalytics;
  egdsElementId: Scalars["String"];
  referenceValue: Scalars["String"];
};

export type TripsView = {
  __typename?: "TripsView";
  contentType?: Maybe<TripsContentType>;
  customerNotificationBanner?: Maybe<TripsCustomerNotificationQueryParameters>;
  customerNotifications?: Maybe<TripsCustomerNotificationQueryParameters>;
  egTripsCards: Array<EgdsImageCard>;
  egTripsMap?: Maybe<EgdsBasicMap>;
  elements: Array<TripsComposableElement>;
  floatingActionButton?: Maybe<TripsFloatingActionButton>;
  header?: Maybe<TripsViewHeader>;
  pageTitle?: Maybe<Scalars["String"]>;
  prefetchOperations?: Maybe<Array<TripsPrefetchOperation>>;
  status?: Maybe<TripsResponseStatus>;
  /** @deprecated toast attribute is deprecated., replace with tripsSideEffects */
  toast?: Maybe<TripsToast>;
  /** @deprecated TripsMap is no longer used. egTripsMap and egTripsCards should be used together instead. */
  tripsMap?: Maybe<TripsMap>;
  tripsSideEffects?: Maybe<Array<TripsSideEffects>>;
};

export type TripsViewContentItem = {
  __typename?: "TripsViewContentItem";
  action?: Maybe<TripsAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export type TripsViewContentLineItem = {
  __typename?: "TripsViewContentLineItem";
  items: Array<TripsViewContentItem>;
};

export type TripsViewHeader = TripsSignalListener & {
  __typename?: "TripsViewHeader";
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
  signal?: Maybe<TripsSignal>;
  toolbar?: Maybe<TripsToolbar>;
};

export type TripsViewResponse =
  | TripsComposableDrawer
  | TripsFloatingActionDrawer
  | TripsInlinedActionsDrawer
  | TripsView;

export type TripsViewType =
  | "ACTIVITIES_SEARCH_RESULTS"
  | "ASC_CANCEL"
  | "BOOKING_FLIGHT_CANCEL"
  | "BOOKING_FLIGHT_CHANGE"
  | "CAMPAIGNS"
  | "CARS_SEARCH_RESULTS"
  | "FILTERED"
  | "FIND_ITINERARY_BY_OTHER_EMAIL"
  | "FIND_ITINERARY_BY_USER_EMAIL"
  | "FLIGHTS_SEARCH_RESULTS"
  | "ITEM_DETAILS"
  | "ITEM_ESSENTIAL_INFO"
  | "ITEM_PRICING_AND_REWARDS"
  | "ITEM_VOUCHER"
  | "ITINERARY"
  | "LOB_SELECTION_VIEW"
  | "LODGING_SEARCH_RESULTS"
  | "LOGIN_PROMPT"
  | "MANAGE_BOOKING"
  | "MANAGE_GUESTS"
  | "OVERVIEW"
  | "PACKAGES_FC_SEARCH_RESULTS"
  | "PACKAGES_FHC_SEARCH_RESULTS"
  | "PACKAGES_HC_SEARCH_RESULTS"
  | "PACKAGES_HF_SEARCH_RESULTS"
  | "PENDING_INVITE"
  | "SEARCH_BY_ITINERARY_NUMBER"
  | "SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL"
  | "TRIPS_LIST";

export type TripsVirtualAgentInitAction = TripsAction & {
  __typename?: "TripsVirtualAgentInitAction";
  analytics: ClientSideAnalytics;
  applicationName?: Maybe<Scalars["String"]>;
  clientOverrides?: Maybe<TripsVirtualAgentOverrideData>;
  pageName?: Maybe<Scalars["String"]>;
  /** @deprecated Request this value from the chatBotConfig query going forward. */
  resource?: Maybe<Uri>;
  title?: Maybe<Scalars["String"]>;
};

export type TripsVirtualAgentOverrideData = {
  __typename?: "TripsVirtualAgentOverrideData";
  conversationProperties?: Maybe<VirtualAgentConversationProperties>;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]>;
  intentArguments?: Maybe<Array<VirtualAgentIntentValue>>;
  /** @deprecated Please use intentArguments instead */
  intentMessage?: Maybe<VirtualAgentIntentMessage>;
  subscribedEvents?: Maybe<Array<Scalars["String"]>>;
};

export type TripsWebView = {
  __typename?: "TripsWebView";
  clientSideImpression: ClientSideImpressionEventAnalytics;
  messageCard?: Maybe<TripsUiMessagingActionCard>;
  url: Uri;
};

export interface TripsWebViewInput {
  filter?: InputMaybe<Scalars["String"]>;
  inviteId?: InputMaybe<Scalars["String"]>;
  screen?: InputMaybe<TripsScreen>;
  tripItemId?: InputMaybe<Scalars["String"]>;
  tripViewId?: InputMaybe<Scalars["String"]>;
}

export type TripsWidgetTextWithIconViewModel = {
  __typename?: "TripsWidgetTextWithIconViewModel";
  icon: Scalars["String"];
  text: Scalars["String"];
};

export type TripsWidgetTripDetailItemRelevance = {
  __typename?: "TripsWidgetTripDetailItemRelevance";
  duration: Scalars["Int"];
  score: Scalars["Int"];
};

export type TripsWidgetTripProductDetailViewModel = {
  __typename?: "TripsWidgetTripProductDetailViewModel";
  deeplinkUrl: Scalars["URL"];
  icon: Scalars["String"];
  subtitleText: Scalars["String"];
  titleText: Scalars["String"];
};

export type TriviaAnswer = {
  __typename?: "TriviaAnswer";
  answer: AppGrowthTextIconLinkListItem;
  id?: Maybe<Scalars["String"]>;
  result: Array<AppGrowthTextIconLinkListItem>;
};

export interface TriviaAnswerSubmitInput {
  answerId: Scalars["String"];
  questionId: Scalars["String"];
}

export type TriviaFinishedQuery = AppGrowthQueryResponse & {
  __typename?: "TriviaFinishedQuery";
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  logo: AppGrowthMediaItem;
  primaryButton?: Maybe<AppGrowthButton>;
  secondaryButton?: Maybe<AppGrowthButton>;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
  subheading?: Maybe<Scalars["String"]>;
};

export type TriviaHowToQuery = AppGrowthQueryResponse & {
  __typename?: "TriviaHowToQuery";
  actionStart: AppGrowthButton;
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  closeAction?: Maybe<AppGrowthTextIconLinkListItem>;
  heading?: Maybe<Scalars["String"]>;
  instructions: EgdsOrderedList;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
};

export type TriviaScreen = {
  __typename?: "TriviaScreen";
  /** @deprecated Field should not be used anymore. Will be replaced by navigation */
  closeAction?: Maybe<AppGrowthTextIconLinkListItem>;
  heading?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  navigation?: Maybe<AppGrowthNavigation>;
  possibleAnswers?: Maybe<Array<TriviaAnswer>>;
  primaryButton?: Maybe<AppGrowthButton>;
  text?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Int"]>;
  timesUpText?: Maybe<Scalars["String"]>;
};

export type TriviaStartQuery = AppGrowthQueryResponse & {
  __typename?: "TriviaStartQuery";
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  questions?: Maybe<Array<TriviaScreen>>;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
};

export interface TriviaStartRequestInput {
  campaignId: Scalars["String"];
  triviaId: Scalars["String"];
}

export interface TriviaSubmitRequestInput {
  campaignId: Scalars["String"];
  results: Array<TriviaAnswerSubmitInput>;
  triviaId: Scalars["String"];
}

export type TwoFactorAuthEgClickstreamData = {
  __typename?: "TwoFactorAuthEGClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
};

export type TypeaheadAdapterDetails = {
  __typename?: "TypeaheadAdapterDetails";
  adapterRequest?: Maybe<TypeaheadAdapterRequest>;
  categoryDetails?: Maybe<Array<TypeaheadCategoryDetails>>;
  domainId: Scalars["String"];
};

export type TypeaheadAdapterRequest = {
  __typename?: "TypeaheadAdapterRequest";
  includeMetadata?: Maybe<Array<Scalars["String"]>>;
};

export type TypeaheadCategoryDetails = {
  __typename?: "TypeaheadCategoryDetails";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type TypeaheadInfo = {
  __typename?: "TypeaheadInfo";
  allowExactMatch: Scalars["Boolean"];
  categorize: Scalars["Boolean"];
  client?: Maybe<Scalars["String"]>;
  dismissButtonText?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  drivable: Scalars["Boolean"];
  emptyResultsPlaceholder?: Maybe<Scalars["String"]>;
  /** @deprecated This will be removed when typeahead service will start using principal token to pull expUserId */
  expUserId?: Maybe<Scalars["String"]>;
  isDestination: Scalars["Boolean"];
  /** @deprecated Use typeaheadMultiSelect.isMultiLocation */
  isMultiLocation?: Maybe<Scalars["Boolean"]>;
  lineOfBusiness?: Maybe<Scalars["String"]>;
  listTitle: Scalars["Boolean"];
  maxNumberOfResults?: Maybe<Scalars["Int"]>;
  packageType?: Maybe<Scalars["String"]>;
  personalize: Scalars["Boolean"];
  regionId?: Maybe<Scalars["String"]>;
  regionType?: Maybe<Scalars["Int"]>;
  showCurrentLocation?: Maybe<Scalars["Boolean"]>;
  subLob?: Maybe<Scalars["String"]>;
  trending: Scalars["Boolean"];
  typeaheadFeatures?: Maybe<Scalars["String"]>;
  typeaheadMultiSelect?: Maybe<EgdsTypeaheadMultiSelect>;
};

export interface TypingIndicatorEventContentInput {
  start: Scalars["Boolean"];
}

export interface UiAction {
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
}

export type UiBanner = EgdsElement & {
  __typename?: "UIBanner";
  actions: Array<UiLinkAction>;
  egdsElementId?: Maybe<Scalars["String"]>;
  fullWidth?: Maybe<Scalars["Boolean"]>;
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  message: Scalars["String"];
  theme?: Maybe<Scalars["String"]>;
};

export type UiButton = UiPrimaryButton | UiSecondaryButton;

export type UiCreatePriceInsightsTrackingAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleAction &
  PriceInsightsTrackingToggleActionData & {
    __typename?: "UICreatePriceInsightsTrackingAction";
    accessibility?: Maybe<Scalars["String"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    existingTrackingDialog?: Maybe<PriceInsightsExistingTrackingDialogAction>;
    lowestPredictedPrice?: Maybe<Scalars["Float"]>;
    lowestPredictedPriceDate?: Maybe<Scalars["String"]>;
    priceShown: Scalars["String"];
    priceTrend?: Maybe<Scalars["String"]>;
    systemNotificationPermissionsDisabledAction: UiDisableSystemNotificationsAction;
    timeSeriesTierShown: Scalars["Int"];
  };

export type UiDisableSystemNotificationsAction = PriceInsightsTrackingToggleAction & {
  __typename?: "UIDisableSystemNotificationsAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  cancelButtonText: Scalars["String"];
  confirmationButtonText: Scalars["String"];
  dialogMessageHeading: Scalars["String"];
  dialogMessageSubHeading: Scalars["String"];
  displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  egcsDisplayAnalytics?: Maybe<EgClickstreamEvent>;
};

export type UiEmptyState = EgdsElement & {
  __typename?: "UIEmptyState";
  body: Scalars["String"];
  egdsElementId?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  icon?: Maybe<Icon>;
  primaryButton?: Maybe<UiPrimaryButton>;
  tertiaryButton?: Maybe<UiTertiaryButton>;
};

export type UiFlightAction = UiAction & {
  __typename?: "UIFlightAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
};

export type UiFlightFloatingActionButton = UiFloatingActionButton & {
  __typename?: "UIFlightFloatingActionButton";
  accessibility?: Maybe<Scalars["String"]>;
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  badge?: Maybe<Scalars["Int"]>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
};

export interface UiFloatingActionButton {
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  badge?: Maybe<Scalars["Int"]>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]>;
}

export type UiGraphic = Icon | Illustration | Mark;

export type UiLinkAction = UiAction & {
  __typename?: "UILinkAction";
  accessibility?: Maybe<Scalars["String"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"];
};

export type UiLinkTarget = "EXTERNAL" | "INTERNAL";

export type UiMessagingCard = EgdsElement & {
  __typename?: "UIMessagingCard";
  actions?: Maybe<UiMessagingCardActions>;
  egdsElementId?: Maybe<Scalars["String"]>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type UiMessagingCardActions = {
  __typename?: "UIMessagingCardActions";
  primary?: Maybe<UiTertiaryButton>;
  secondaries?: Maybe<Array<UiTertiaryButton>>;
};

export interface UiPill {
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of UIPill add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  selected: Scalars["Boolean"];
}

export type UiPrimaryButton = EgdsButton &
  EgdsElement & {
    __typename?: "UIPrimaryButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
  };

export type UiPrimaryFloatingActionButton = EgdsElement &
  UiFloatingActionButton & {
    __typename?: "UIPrimaryFloatingActionButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    badge?: Maybe<Scalars["Int"]>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
  };

export interface UiRemovablePill {
  accessibility?: Maybe<Scalars["String"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of UIRemovablePill add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"];
  remove?: Maybe<Icon>;
  selected: Scalars["Boolean"];
}

export type UiSecondaryButton = EgdsButton &
  EgdsElement & {
    __typename?: "UISecondaryButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
  };

export type UiSecondaryFloatingActionButton = EgdsElement &
  UiFloatingActionButton & {
    __typename?: "UISecondaryFloatingActionButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    badge?: Maybe<Scalars["Int"]>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
  };

export type UiSignal =
  | "BOOKING_SUCCESS"
  | "CONFIGURATION_ADDED"
  | "DUPLICATE_REQUEST"
  | "GENERIC_DOWNSTREAM_ERROR"
  | "INSURANCE_UNAVAILABLE"
  | "INVALID_EMAIL"
  | "OFFER_ADDED"
  | "OFFER_CHANGED"
  | "OFFER_REMOVED"
  | "OFFER_UNAVAILABLE"
  | "OPERATION_FAILED"
  | "PAYMENT_ERROR"
  | "PAYMENT_METHODS_APPLIED"
  | "PAYMENT_METHOD_UPDATED"
  | "PRICE_ADJUSTED"
  | "PRICE_CHANGED"
  | "REQUEST_CONFLICT"
  | "SESSION_EXPIRED"
  | "SESSION_TOKEN_UPDATED"
  | "UNKNOWN"
  | "UNRECOVERABLE_FAILURE"
  | "USER_STATE_UPDATED";

export type UiSimpleText = {
  __typename?: "UISimpleText";
  text: Scalars["String"];
};

export type UiTertiaryButton = EgdsButton &
  EgdsElement & {
    __typename?: "UITertiaryButton";
    accessibility?: Maybe<Scalars["String"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"];
    egdsElementId?: Maybe<Scalars["String"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]>;
  };

export type UiTextList = {
  __typename?: "UITextList";
  heading?: Maybe<Scalars["String"]>;
  texts?: Maybe<Array<Scalars["String"]>>;
};

export interface UiToggle {
  checked: Scalars["Boolean"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]>;
  checkedLabel: Scalars["String"];
  enabled: Scalars["Boolean"];
  /** @deprecated Use checked or unchecked label */
  label: Scalars["String"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]>;
  uncheckedLabel: Scalars["String"];
}

export type UiToolbar = {
  __typename?: "UIToolbar";
  actions: UiToolbarActions;
  primary?: Maybe<Scalars["String"]>;
  secondaries?: Maybe<Array<Scalars["String"]>>;
};

export type UiToolbarActions = {
  __typename?: "UIToolbarActions";
  primary: UiTertiaryButton;
  secondaries?: Maybe<Array<UiTertiaryButton>>;
};

export type UiValidateTravelerLoggedInAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleAction & {
    __typename?: "UIValidateTravelerLoggedInAction";
    accessibility?: Maybe<Scalars["String"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    egcsDisplayAnalytics?: Maybe<EgClickstreamEvent>;
    signInAction: UiLinkAction;
  };

export interface Uri {
  value: Scalars["String"];
}

export type UnionText = {
  __typename?: "UnionText";
  link?: Maybe<EgdsInlineLink>;
  text?: Maybe<Scalars["String"]>;
};

export interface UnitCard {
  removeLink?: Maybe<EgdsStandardLink>;
}

export type UnitOccupancy = {
  __typename?: "UnitOccupancy";
  adults: Scalars["Int"];
  children: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  people: Scalars["Int"];
};

export type UniversalProfileAboutYouField = {
  __typename?: "UniversalProfileAboutYouField";
  aboutYou: UniversalProfileTextAreaInputField;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileAccessibilityNeedsField = {
  __typename?: "UniversalProfileAccessibilityNeedsField";
  accessibilityNeeds: UniversalProfileBasicSelect;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileAccountManagementMessagingCard = {
  __typename?: "UniversalProfileAccountManagementMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileAccountManagementOptionType;
};

export type UniversalProfileAccountManagementOptionType = "TRAVELER_ARRANGER";

export type UniversalProfileAccountManagementSettings = {
  __typename?: "UniversalProfileAccountManagementSettings";
  /** @deprecated Use searchHistory instead. */
  clearSearchAndSavedData: UniversalProfileStandardLink;
  deleteAccount?: Maybe<UniversalProfileStandardLink>;
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileAccountManagementMessagingCard>;
  searchHistory?: Maybe<UniversalProfileSearchHistoryComponent>;
};

export type UniversalProfileAccountTakeOverCaptchaWidget = {
  __typename?: "UniversalProfileAccountTakeOverCaptchaWidget";
  links?: Maybe<Array<UniversalProfileAccountTakeOverLink>>;
  placementContext?: Maybe<Array<UniversalProfileAccountTakeOverPlacementContext>>;
};

export type UniversalProfileAccountTakeOverCsrfWidget = {
  __typename?: "UniversalProfileAccountTakeOverCsrfWidget";
  content: Scalars["String"];
};

export type UniversalProfileAccountTakeOverLink = {
  __typename?: "UniversalProfileAccountTakeOverLink";
  loadBeforeWidget?: Maybe<Scalars["Boolean"]>;
  mimeType: Scalars["String"];
  section?: Maybe<Scalars["String"]>;
  uri: Scalars["String"];
};

export type UniversalProfileAccountTakeOverPlacementContext = {
  __typename?: "UniversalProfileAccountTakeOverPlacementContext";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type UniversalProfileAccountTakeOverWidget =
  | UniversalProfileAccountTakeOverCaptchaWidget
  | UniversalProfileAccountTakeOverCsrfWidget;

export interface UniversalProfileAccountTakeOverWidgetRequestInput {
  content?: InputMaybe<Scalars["String"]>;
  type: UniversalProfileAccountTakeOverWidgetTypeRequest;
}

export type UniversalProfileAccountTakeOverWidgetTypeRequest = "CAPTCHA" | "CSRF";

export interface UniversalProfileAction {
  analytics: Array<UniversalProfileAnalyticEvent>;
}

export type UniversalProfileActionResponse =
  | UniversalProfileAdditionalTravelerSuccessResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse
  | UniversalProfileErrorResponse
  | UniversalProfileSuccessResponse;

export type UniversalProfileAdditionalTravelerContent = {
  __typename?: "UniversalProfileAdditionalTravelerContent";
  addTravelerButton: UniversalProfileButton;
  travelers: Array<UniversalProfileStandardLink>;
};

export interface UniversalProfileAdditionalTravelerContextInput {
  accountTakeOverWidgets?: InputMaybe<Array<UniversalProfileAccountTakeOverWidgetRequestInput>>;
  additionalTravelerIdentifier?: InputMaybe<Scalars["String"]>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
}

export type UniversalProfileAdditionalTravelerCreateAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerCreateAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"];
  redirectUrl: Scalars["String"];
};

export type UniversalProfileAdditionalTravelerFooter = {
  __typename?: "UniversalProfileAdditionalTravelerFooter";
  doneButton?: Maybe<UniversalProfileButton>;
  removeAdditionalTravelerSheet?: Maybe<UniversalProfileRemoveAdditionalTravelerSheet>;
  removeTravelerButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileAdditionalTravelerForm = {
  __typename?: "UniversalProfileAdditionalTravelerForm";
  additionalTraveler: UniversalProfileInformationBasicForm;
};

export type UniversalProfileAdditionalTravelerFormAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerFormAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"];
  redirectUrl: Scalars["String"];
};

export type UniversalProfileAdditionalTravelerInfoAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerInfoAction";
  additionalTravelerId?: Maybe<Scalars["String"]>;
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type UniversalProfileAdditionalTravelerInformation = {
  __typename?: "UniversalProfileAdditionalTravelerInformation";
  basicInformation: UniversalProfileInformation;
  footer: UniversalProfileAdditionalTravelerFooter;
  heading: UniversalProfileHeading;
  moreDetails: UniversalProfileMoreDetails;
};

export type UniversalProfileAdditionalTravelerSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileAdditionalTravelerSuccessResponse = {
  __typename?: "UniversalProfileAdditionalTravelerSuccessResponse";
  clickstreamAnalytics: Array<UniversalProfileAnalyticEvent>;
  message: UniversalProfileToast;
  travelerId: Scalars["String"];
};

export type UniversalProfileAdditionalTravelerViewAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerViewAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"];
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type UniversalProfileAdditionalTravelers = {
  __typename?: "UniversalProfileAdditionalTravelers";
  collapseAnalyticEvents?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  container: EgdsExpandoCard;
  content: UniversalProfileExpandoContent;
  expandAnalyticEvents?: Maybe<Array<UniversalProfileAnalyticEvent>>;
};

export type UniversalProfileAddressField = {
  __typename?: "UniversalProfileAddressField";
  addressLine1: UniversalProfileTextInputField;
  addressLine2: UniversalProfileTextInputField;
  city: UniversalProfileTextInputField;
  country: UniversalProfileBasicSelect;
  heading: UniversalProfileContentHeading;
  state: UniversalProfileCountryStateField;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  zipCode: UniversalProfileTextInputField;
};

export interface UniversalProfileAddressInput {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  stateCode?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
}

export type UniversalProfileAgeRangeValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileAgeRangeValidationRule";
  errorMessage: Scalars["String"];
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export interface UniversalProfileAnalyticEvent {
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
}

export type UniversalProfileAnalyticsEventSubType = "BLURRED" | "FOCUSED" | "INPUTTED" | "SUCCEEDED";

export type UniversalProfileAppearanceField = {
  __typename?: "UniversalProfileAppearanceField";
  appearance: UniversalProfileBasicRadioGroup;
  heading: UniversalProfileHeading;
};

export type UniversalProfileAttribute = UniversalProfileDefaultAttribute;

export type UniversalProfileBasicCheckBox = UniversalProfileInput & {
  __typename?: "UniversalProfileBasicCheckBox";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBasicCheckBox;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfileBasicInformationDateOfBirthInput {
  day?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<Scalars["Int"]>;
  year?: InputMaybe<Scalars["Int"]>;
}

export type UniversalProfileBasicInformationForm = {
  __typename?: "UniversalProfileBasicInformationForm";
  basicInformation: UniversalProfileInformationBasicForm;
};

export type UniversalProfileBasicInformationFormAction = UniversalProfileAction & {
  __typename?: "UniversalProfileBasicInformationFormAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileBasicInformationNameInput {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: InputMaybe<Scalars["String"]>;
}

export interface UniversalProfileBasicInformationRequestInput {
  accessibilityNeeds?: InputMaybe<Scalars["String"]>;
  bio?: InputMaybe<Scalars["String"]>;
  dateOfBirth: UniversalProfileBasicInformationDateOfBirthInput;
  gender?: InputMaybe<Scalars["String"]>;
  name: UniversalProfileBasicInformationNameInput;
}

export type UniversalProfileBasicInformationSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileBasicInformationSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileBasicRadioGroup = UniversalProfileInput & {
  __typename?: "UniversalProfileBasicRadioGroup";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBasicRadioGroup;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileBasicSelect = UniversalProfileInput & {
  __typename?: "UniversalProfileBasicSelect";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBasicSelect;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileBrandPreferences = {
  __typename?: "UniversalProfileBrandPreferences";
  appearance: UniversalProfileAppearanceField;
  copyInformation?: Maybe<UniversalProfileButton>;
  heading: UniversalProfileHeading;
  locationAndLanguage: UniversalProfileLocationAndLanguage;
  type: UniversalProfilePreferencesOptionType;
};

export type UniversalProfileBulletedList = UniversalProfileInput & {
  __typename?: "UniversalProfileBulletedList";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBulletedList;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileButton = {
  __typename?: "UniversalProfileButton";
  action: UniversalProfileAction;
  button: EgdsButton;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
};

export type UniversalProfileCallLinksSheet = {
  __typename?: "UniversalProfileCallLinksSheet";
  cancelButton: UniversalProfileButton;
  egdsSheet: EgdsSheet;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  rows: Array<UniversalProfileLinkRow>;
  title: Scalars["String"];
};

export type UniversalProfileCallPhoneNumberAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCallPhoneNumberAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  phone: TelUri;
};

export type UniversalProfileCancelAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCancelAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCategorySwitchOperator = "OFF" | "ON";

export interface UniversalProfileChangeEmailRequestInput {
  cmsToken: Scalars["String"];
  email: Scalars["String"];
}

export type UniversalProfileCheckBoxGroup = UniversalProfileInput & {
  __typename?: "UniversalProfileCheckBoxGroup";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsCheckBoxGroup;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileCheckboxAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileCheckboxAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
  subType: UniversalProfileCheckboxAnalyticsEventSubType;
};

export type UniversalProfileCheckboxAnalyticsEventSubType = "DESELECTED" | "SELECTED";

export type UniversalProfileClearHistoryAction = UniversalProfileAction & {
  __typename?: "UniversalProfileClearHistoryAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileClientInfoInput {
  appVersion?: InputMaybe<Scalars["String"]>;
  channelType: UniversalProfileClientType;
}

export type UniversalProfileClientType = "ANDROID" | "IOS" | "WEB";

export type UniversalProfileCloseAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCloseAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCloseButton = {
  __typename?: "UniversalProfileCloseButton";
  action?: Maybe<UniversalProfileAction>;
};

export type UniversalProfileCommPrefsSmsContactInfoDetails = {
  __typename?: "UniversalProfileCommPrefsSmsContactInfoDetails";
  contentHeading?: Maybe<UniversalProfileContentHeading>;
  countryCode: UniversalProfileBasicSelect;
  heading: UniversalProfileHeading;
  number: UniversalProfileNumberInputField;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
};

export interface UniversalProfileCommunicationPreferenceEmailRequestInput {
  categoryConsent: Array<UniversalProfileEmailCommunicationPreferenceUpdateInput>;
  unsubscribeFromAll: Scalars["Boolean"];
}

export interface UniversalProfileCommunicationPreferenceRequestInput {
  updates: Array<UniversalProfileCommunicationPreferenceUpdateInput>;
}

export interface UniversalProfileCommunicationPreferenceUnsubRequestInput {
  channel: CommunicationChannel;
  checksum: Scalars["String"];
  egAccountId: Scalars["String"];
  emlCid?: InputMaybe<Scalars["String"]>;
  emlDtl?: InputMaybe<Scalars["String"]>;
}

export interface UniversalProfileCommunicationPreferenceUnsubscribeCategoriesRequestInput {
  categoryTypes: Array<UniversalProfileCommunicationPreferencesCategoryType>;
  channel: CommunicationChannel;
  checksum: Scalars["String"];
  egAccountId: Scalars["String"];
  emailUnsubscribeAll?: InputMaybe<Scalars["Boolean"]>;
  emlCid?: InputMaybe<Scalars["String"]>;
  emlDtl?: InputMaybe<Scalars["String"]>;
  isStrict: Scalars["Boolean"];
}

export interface UniversalProfileCommunicationPreferenceUnsubscribePreferenceRequestInput {
  categoryType: UniversalProfileCommunicationPreferencesCategoryType;
  channel: CommunicationChannel;
  checksum: Scalars["String"];
  egAccountId: Scalars["String"];
  emlCid?: InputMaybe<Scalars["String"]>;
  emlDtl?: InputMaybe<Scalars["String"]>;
}

export interface UniversalProfileCommunicationPreferenceUpdateInput {
  category: UniversalProfileCommunicationPreferencesCategoryType;
  selectedChannels?: InputMaybe<Array<CommunicationChannel>>;
}

export type UniversalProfileCommunicationPreferences = {
  __typename?: "UniversalProfileCommunicationPreferences";
  communicationBrandPreferences: CommunicationPreferencesBrandComponent;
  communicationCommonPreferences: CommunicationPreferencesMainComponent;
};

export type UniversalProfileCommunicationPreferencesCategoryFormSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesCategoryFormSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCommunicationPreferencesCategoryType =
  | "ACCOUNT_SUPPORT"
  | "CONFIRMATION_AND_CRITICAL_UPDATES"
  | "OFFERS_AND_INSPIRATION"
  | "ONE_KEY"
  | "ONE_KEY_MONTHLY_STATEMENTS"
  | "ONE_KEY_REWARD_ACCOUNT_UPDATES"
  | "PARTNERS_OFFERS_WITH_US"
  | "PERSONALIZED_TRIP_RECOMMENDATIONS"
  | "REVIEWS"
  | "SURVEYS"
  | "THE_TRAVEL_EDIT"
  | "TRIP_BOARD_ACTIVITY"
  | "TRIP_PLANNING_ASSISTANCE";

export type UniversalProfileCommunicationPreferencesCheckBoxComponent = {
  __typename?: "UniversalProfileCommunicationPreferencesCheckBoxComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  categoryType?: Maybe<UniversalProfileCommunicationPreferencesCategoryType>;
  channelType?: Maybe<CommunicationChannel>;
  commPrefsSMSContactInfo?: Maybe<UniversalProfileCommunicationPreferencesSmsContactInfoForm>;
  universalProfileBasicCheckBox: UniversalProfileBasicCheckBox;
};

export type UniversalProfileCommunicationPreferencesEmailSelectionComponent = {
  __typename?: "UniversalProfileCommunicationPreferencesEmailSelectionComponent";
  categoryType?: Maybe<UniversalProfileCommunicationPreferencesCategoryType>;
  emailSelectionSwitch?: Maybe<EgdsStandardSwitch>;
  title: Scalars["String"];
  universalProfileSwitchChangeCriteria: UniversalProfileSwitchChangeCriteria;
};

export type UniversalProfileCommunicationPreferencesForm =
  | CommunicationPreferencesChannelSelectionForm
  | CommunicationPreferencesForm
  | UniversalProfileCommPrefsSmsContactInfoDetails
  | UniversalProfileCommunicationPreferencesCheckBoxComponent
  | UniversalProfileCommunicationPreferencesManageEmailForm
  | UniversalProfileCommunicationPreferencesSmsContactInfoForm
  | UniversalProfileCommunicationPreferencesUnsubscribeCategoriesForm
  | UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse;

export type UniversalProfileCommunicationPreferencesFormType =
  | "COMMUNICATION_PREFERENCE_CATEGORY_FORM"
  | "COMMUNICATION_PREFERENCE_MANAGE_EMAIL_FORM"
  | "COMMUNICATION_PREFERENCE_UNSUBSCRIBE_CATEGORY_FORM"
  | "COMMUNICATION_PREFERENCE_UNSUBSCRIBE_STRICT_FORM";

export type UniversalProfileCommunicationPreferencesManageEmailAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesManageEmailAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  commPrefsBrandExperienceType?: Maybe<UniversalProfileSubExperienceType>;
  type?: Maybe<CommunicationPreferencesOptionsType>;
  url?: Maybe<Scalars["String"]>;
};

export type UniversalProfileCommunicationPreferencesManageEmailForm = {
  __typename?: "UniversalProfileCommunicationPreferencesManageEmailForm";
  cancelButton: UniversalProfileButton;
  categorySwitches: Array<UniversalProfileCommunicationPreferencesEmailSelectionComponent>;
  description: Scalars["String"];
  summary: Scalars["String"];
  title: Scalars["String"];
  unsubscribeSwitch: UniversalProfileCommunicationPreferencesEmailSelectionComponent;
  updatePreferencesButton: UniversalProfileButton;
};

export type UniversalProfileCommunicationPreferencesManageEmailFormSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesManageEmailFormSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCommunicationPreferencesSmsContactInfoForm = {
  __typename?: "UniversalProfileCommunicationPreferencesSMSContactInfoForm";
  contactInfoDetails: UniversalProfileCommPrefsSmsContactInfoDetails;
  criteria: UniversalProfileSwitchChangeCriteria;
  description: Scalars["String"];
  save: UniversalProfileButton;
  title: Scalars["String"];
};

export type UniversalProfileCommunicationPreferencesUnsubscribeCategoriesForm = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeCategoriesForm";
  categories: Array<UniversalProfileCommunicationPreferencesCheckBoxComponent>;
  heading: UniversalProfileHeading;
  saveButton: UniversalProfileButton;
  unsubscribeAll: UniversalProfileCommunicationPreferencesCheckBoxComponent;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse";
  analytics: Array<UniversalProfileAnalyticEvent>;
  heading: UniversalProfileHeading;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeResponse = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeResponse";
  analytics: Array<UniversalProfileAnalyticEvent>;
  button: UniversalProfileButton;
  heading: UniversalProfileHeading;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeSaveAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse";
  heading: UniversalProfileHeading;
  signOutLauncher?: Maybe<UniversalProfileSignOutLauncher>;
};

export type UniversalProfileConfirmationLeavingView = {
  __typename?: "UniversalProfileConfirmationLeavingView";
  confirmationLeaving: UniversalProfileBulletedList;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileContactInformationForm = {
  __typename?: "UniversalProfileContactInformationForm";
  contactInformation: UniversalProfileInformationBasicForm;
};

export type UniversalProfileContactInformationFormAction = UniversalProfileAction & {
  __typename?: "UniversalProfileContactInformationFormAction";
  additionalTravelerIdentifier?: Maybe<Scalars["String"]>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileContactInformationRequestInput {
  address: UniversalProfileAddressInput;
  emergencyContact: UniversalProfileEmergencyContactInput;
  phone: UniversalProfilePhoneInput;
}

export type UniversalProfileContactInformationSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileContactInformationSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileContentHeading = {
  __typename?: "UniversalProfileContentHeading";
  description?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export interface UniversalProfileContextInput {
  accountTakeOverWidgets?: InputMaybe<Array<UniversalProfileAccountTakeOverWidgetRequestInput>>;
  additionalTravelerIdentifier?: InputMaybe<Scalars["String"]>;
  cmsToken?: InputMaybe<Scalars["String"]>;
  communicationPreferencesCategoryType?: InputMaybe<UniversalProfileCommunicationPreferencesCategoryType>;
  communicationPreferencesFormType?: InputMaybe<UniversalProfileCommunicationPreferencesFormType>;
  communicationPreferencesUnsubscribeContext?: InputMaybe<UniversalProfileCommunicationPreferenceUnsubRequestInput>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
  isPushDeviceSettingsEnabled?: InputMaybe<Scalars["Boolean"]>;
  settingsFormType?: InputMaybe<UniversalProfileSettingsFormType>;
  subExperienceType?: InputMaybe<UniversalProfileSubExperienceType>;
}

export type UniversalProfileContinueAction = UniversalProfileAction & {
  __typename?: "UniversalProfileContinueAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCopySupportInfoAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCopySupportInfoAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCountryField = {
  __typename?: "UniversalProfileCountryField";
  country: UniversalProfileBasicRadioGroup;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileCountryForm = {
  __typename?: "UniversalProfileCountryForm";
  confirmation: UniversalProfileSettingsBasicForm;
  country: UniversalProfileSettingsBasicForm;
};

export type UniversalProfileCountrySaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCountrySaveAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCountryStateField = {
  __typename?: "UniversalProfileCountryStateField";
  defaultFieldIdentifier: Scalars["String"];
  fieldIdentificationCriteria?: Maybe<Array<UniversalProfileFieldIdentificationCriteria>>;
  fields: Array<UniversalProfileInput>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type UniversalProfileDashboard = UniversalProfileDashboardComponent | UniversalProfileSignInComponent;

export type UniversalProfileDashboardComponent = {
  __typename?: "UniversalProfileDashboardComponent";
  accountUpgradeStatus?: Maybe<Scalars["String"]>;
  heading: UniversalProfileHeading;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  options: Array<UniversalProfileDashboardMessagingCard>;
  signInButton?: Maybe<UniversalProfileButton>;
  signOutLauncher?: Maybe<UniversalProfileSignOutLauncher>;
};

export type UniversalProfileDashboardMessagingCard = {
  __typename?: "UniversalProfileDashboardMessagingCard";
  action: UniversalProfileAction;
  analyticEvents?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  analytics?: Maybe<Array<UniversalProfileInteractionAnalyticEvent>>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileDashboardOptionType;
};

export type UniversalProfileDashboardOptionType =
  | "COMMUNICATIONS"
  | "COUPONS"
  | "CREDITS"
  | "HELP"
  | "LEGAL"
  | "PAYMENTS"
  | "PROFILE"
  | "REVIEWS"
  | "SETTINGS";

export type UniversalProfileDateField = {
  __typename?: "UniversalProfileDateField";
  day?: Maybe<UniversalProfileNumberInputField>;
  heading?: Maybe<UniversalProfileContentHeading>;
  month?: Maybe<UniversalProfileNumberInputField>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
  year?: Maybe<UniversalProfileNumberInputField>;
};

export type UniversalProfileDateOfBirthField = {
  __typename?: "UniversalProfileDateOfBirthField";
  day: UniversalProfileNumberInputField;
  heading: UniversalProfileContentHeading;
  month: UniversalProfileNumberInputField;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
  year: UniversalProfileNumberInputField;
};

export type UniversalProfileDefaultAttribute = {
  __typename?: "UniversalProfileDefaultAttribute";
  attribute: Scalars["String"];
  value: Scalars["String"];
};

export type UniversalProfileDeleteAccountAction = UniversalProfileAction & {
  __typename?: "UniversalProfileDeleteAccountAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileDeleteAccountForm = {
  __typename?: "UniversalProfileDeleteAccountForm";
  accountVerification: UniversalProfileSettingsBasicForm;
  confirmation?: Maybe<UniversalProfileSettingsBasicForm>;
  reason: UniversalProfileSettingsBasicForm;
  unableDelete?: Maybe<UniversalProfileSettingsBasicForm>;
};

export type UniversalProfileDeleteAccountViewAction = UniversalProfileAction & {
  __typename?: "UniversalProfileDeleteAccountViewAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileDependsOnCriteria = {
  __typename?: "UniversalProfileDependsOnCriteria";
  dependantFieldId: Scalars["String"];
  field: Scalars["String"];
  regex: Scalars["String"];
};

export type UniversalProfileDependsOnFieldIdentificationCriteria = {
  __typename?: "UniversalProfileDependsOnFieldIdentificationCriteria";
  dependsOnFieldIdentifier: Scalars["String"];
  dependsOnFieldValues: Array<Scalars["String"]>;
  fieldIdentifier: Scalars["String"];
  operator: UniversalProfileDependsOnFieldIdentificationCriteriaOperator;
};

export type UniversalProfileDependsOnFieldIdentificationCriteriaOperator = "EXCLUDES" | "INCLUDES";

export type UniversalProfileDependsOnValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileDependsOnValidationRule";
  criteria: UniversalProfileDependsOnCriteria;
  errorMessage: Scalars["String"];
  fieldIdentifier: Scalars["String"];
  required: Scalars["Boolean"];
};

export type UniversalProfileDialog = {
  __typename?: "UniversalProfileDialog";
  analytics: Array<UniversalProfileAnalyticEvent>;
  cancelButton: UniversalProfileButton;
  confirmButton: UniversalProfileButton;
  heading: UniversalProfileHeading;
  title: Scalars["String"];
};

export interface UniversalProfileEmailCommunicationPreferenceUpdateInput {
  category: UniversalProfileCommunicationPreferencesCategoryType;
  emailOptIn: Scalars["Boolean"];
}

export type UniversalProfileEmailEditAction = UniversalProfileAction & {
  __typename?: "UniversalProfileEmailEditAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileEmailForm = {
  __typename?: "UniversalProfileEmailForm";
  email: UniversalProfileSettingsBasicForm;
};

export type UniversalProfileEmailInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileEmailInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsEmailInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileEmailView = {
  __typename?: "UniversalProfileEmailView";
  email: UniversalProfileAttribute;
  hint?: Maybe<Scalars["String"]>;
};

export type UniversalProfileEmergencyContactField = {
  __typename?: "UniversalProfileEmergencyContactField";
  contactName: UniversalProfileTextInputField;
  heading: UniversalProfileContentHeading;
  phone: UniversalProfilePhoneField;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfileEmergencyContactInput {
  contactName?: InputMaybe<Scalars["String"]>;
  phone: UniversalProfilePhoneInput;
}

export type UniversalProfileErrorField = {
  __typename?: "UniversalProfileErrorField";
  errorMessage: Scalars["String"];
  /** @deprecated To be replaced by universalProfileIdentifier */
  id: Scalars["String"];
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
};

export type UniversalProfileErrorResponse = {
  __typename?: "UniversalProfileErrorResponse";
  accountTakeOverWidgets?: Maybe<Array<UniversalProfileAccountTakeOverWidget>>;
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  clickstreamAnalytics: Array<UniversalProfileAnalyticEvent>;
  errorFields?: Maybe<Array<UniversalProfileErrorField>>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
};

export type UniversalProfileErrorSummary = {
  __typename?: "UniversalProfileErrorSummary";
  summary: UiBanner;
};

export type UniversalProfileExpandoContent = UniversalProfileAdditionalTravelerContent | UniversalProfileLegalContent;

export type UniversalProfileExperience =
  | UniversalProfileAdditionalTravelerInformation
  | UniversalProfileCommunicationPreferences
  | UniversalProfileHelpAndFeedbackComponent
  | UniversalProfileHelpAndFeedbackHelpCenterComponent
  | UniversalProfileLegalInformation
  | UniversalProfileRedirectComponent
  | UniversalProfileSignInComponent
  | UniversalProfileTravelerDocumentsInformation
  | UniversalProfileTravelerInformation
  | UniversalProfileTravelerSettings;

export type UniversalProfileExperienceType =
  | "ADDITIONAL_TRAVELER"
  | "COMMUNICATION_PREFERENCES"
  | "HELP"
  | "LEGAL"
  | "PROFILE"
  | "SETTINGS"
  | "TRAVELER_DOCUMENTS";

export type UniversalProfileExternalRedirectAction = UniversalProfileAction & {
  __typename?: "UniversalProfileExternalRedirectAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl: Scalars["String"];
};

export type UniversalProfileFieldIdentificationCriteria = UniversalProfileDependsOnFieldIdentificationCriteria;

export type UniversalProfileForgotPasswordAction = UniversalProfileAction & {
  __typename?: "UniversalProfileForgotPasswordAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileFormSubmitConfirmationChannelBasedCriteria = {
  __typename?: "UniversalProfileFormSubmitConfirmationChannelBasedCriteria";
  channels: Array<CommunicationChannel>;
  fieldIdentifier?: Maybe<Scalars["String"]>;
};

export type UniversalProfileFormSubmitConfirmationCriteria = UniversalProfileFormSubmitConfirmationChannelBasedCriteria;

export type UniversalProfileFormSubmitConfirmationDialog = {
  __typename?: "UniversalProfileFormSubmitConfirmationDialog";
  cancelButton: UniversalProfileButton;
  confirmButton: UniversalProfileButton;
  contentHeading?: Maybe<UniversalProfileContentHeading>;
  formConfirmationCriteria?: Maybe<UniversalProfileFormSubmitConfirmationCriteria>;
  heading: UniversalProfileHeading;
  pushDeviceSettings?: Maybe<UiBanner>;
};

export type UniversalProfileGenderField = {
  __typename?: "UniversalProfileGenderField";
  gender: UniversalProfileBasicRadioGroup;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileHeading = {
  __typename?: "UniversalProfileHeading";
  description?: Maybe<Scalars["String"]>;
  iconList?: Maybe<Array<UiGraphic>>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type UniversalProfileHelpAndFeedbackComponent = {
  __typename?: "UniversalProfileHelpAndFeedbackComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileHelpAndFeedbackMessagingCard>;
  /** @deprecated Use virtualAgentComponent instead. */
  showVirtualAgent?: Maybe<Scalars["Boolean"]>;
  virtualAgentComponent?: Maybe<UniversalProfileVirtualAgentComponent>;
};

export type UniversalProfileHelpAndFeedbackHelpCenterComponent = {
  __typename?: "UniversalProfileHelpAndFeedbackHelpCenterComponent";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileHelpAndFeedbackHelpCenterMessagingCard>;
};

export type UniversalProfileHelpAndFeedbackHelpCenterMessagingCard = {
  __typename?: "UniversalProfileHelpAndFeedbackHelpCenterMessagingCard";
  action: UniversalProfileAction;
  card: EgdsStandardMessagingCard;
  type: HelpAndFeedbackHelpCenterOptionsType;
};

export type UniversalProfileHelpAndFeedbackMessagingCard = {
  __typename?: "UniversalProfileHelpAndFeedbackMessagingCard";
  action: UniversalProfileAction;
  card: EgdsStandardMessagingCard;
  sheet?: Maybe<UniversalProfileSheet>;
  type: HelpAndFeedbackOptionsType;
};

export type UniversalProfileImpressionAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileImpressionAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type UniversalProfileInformation = {
  __typename?: "UniversalProfileInformation";
  edit: UniversalProfileButton;
  heading: UniversalProfileHeading;
  information: Array<UniversalProfileAttribute>;
};

export type UniversalProfileInformationBasicForm = {
  __typename?: "UniversalProfileInformationBasicForm";
  cancelButton?: Maybe<UniversalProfileButton>;
  closeButton?: Maybe<UniversalProfileCloseButton>;
  content: Array<UniversalProfileInformationFormContent>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  heading: UniversalProfileHeading;
  impression: UniversalProfileImpressionAnalyticEvent;
  saveButton?: Maybe<UniversalProfileButton>;
  title: Scalars["String"];
};

export type UniversalProfileInformationForm =
  | UniversalProfileAdditionalTravelerForm
  | UniversalProfileBasicInformationForm
  | UniversalProfileContactInformationForm
  | UniversalProfileSignInComponent
  | UniversalProfileTravelerDocumentForm
  | UniversalProfileTsaInformationForm;

export type UniversalProfileInformationFormContent =
  | UniversalProfileAboutYouField
  | UniversalProfileAccessibilityNeedsField
  | UniversalProfileAddressField
  | UniversalProfileCloseButton
  | UniversalProfileDateField
  | UniversalProfileDateOfBirthField
  | UniversalProfileEmailView
  | UniversalProfileEmergencyContactField
  | UniversalProfileGenderField
  | UniversalProfileKnownTravelerNumberField
  | UniversalProfileNameField
  | UniversalProfilePassportField
  | UniversalProfilePhoneField
  | UniversalProfileRedressNumberField;

export type UniversalProfileInformationFormType =
  | "COMMUNICATION_PREFERENCE_UNSUB"
  | "UPDATE_ADDITIONAL_TRAVELER_INFO"
  | "UPDATE_BASIC_INFORMATION"
  | "UPDATE_COMMUNICATION_PREFERENCE"
  | "UPDATE_CONTACT_INFORMATION"
  | "UPDATE_CONTACT_INFORMATION_EMAIL"
  | "UPDATE_CONTACT_INFORMATION_PHONE_NUMBER"
  | "UPDATE_PASSPORT_INFO"
  | "UPDATE_TRAVELER_DOCUMENT"
  | "UPDATE_TSA_INFORMATION";

export interface UniversalProfileInput {
  /** @deprecated Please use inputAnalytics: List<UniversalProfileAnalyticEvent>? instead. */
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
}

export type UniversalProfileIntegerRangeValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileIntegerRangeValidationRule";
  errorMessage: Scalars["String"];
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export type UniversalProfileInteractionAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileInteractionAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
  subType?: Maybe<UniversalProfileAnalyticsEventSubType>;
};

export type UniversalProfileKnownTravelerNumberField = {
  __typename?: "UniversalProfileKnownTravelerNumberField";
  heading: UniversalProfileContentHeading;
  knownTravelerNumber: UniversalProfileTsaTextInputField;
};

export type UniversalProfileLanguageField = {
  __typename?: "UniversalProfileLanguageField";
  heading: UniversalProfileContentHeading;
  language: UniversalProfileBasicRadioGroup;
};

export type UniversalProfileLanguageForm = {
  __typename?: "UniversalProfileLanguageForm";
  language: UniversalProfileSettingsBasicForm;
};

export type UniversalProfileLegal = {
  __typename?: "UniversalProfileLegal";
  container: EgdsExpandoCard;
  content: UniversalProfileExpandoContent;
};

export type UniversalProfileLegalAction = UniversalProfileAction & {
  __typename?: "UniversalProfileLegalAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileLegalContent = {
  __typename?: "UniversalProfileLegalContent";
  links: Array<UniversalProfileStandardLink>;
};

export type UniversalProfileLegalInformation = {
  __typename?: "UniversalProfileLegalInformation";
  analytics: Array<UniversalProfileAnalyticEvent>;
  legal?: Maybe<UniversalProfileLegal>;
};

export type UniversalProfileLinkRow = UniversalProfilePhoneLinkRow;

export type UniversalProfileLocationAndLanguage = {
  __typename?: "UniversalProfileLocationAndLanguage";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileLocationAndLanguageMessagingCard>;
};

export type UniversalProfileLocationAndLanguageMessagingCard = {
  __typename?: "UniversalProfileLocationAndLanguageMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  enabled: Scalars["Boolean"];
  type: UniversalProfileLocationAndLanguageOptionType;
};

export type UniversalProfileLocationAndLanguageOptionType = "COUNTY" | "CURRENCY" | "LANGUAGE";

export type UniversalProfileLoginInformationSettings = {
  __typename?: "UniversalProfileLoginInformationSettings";
  heading?: Maybe<UniversalProfileHeading>;
  options: Array<UniversalProfileLoginInformationSettingsMessagingCard>;
  signInButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileLoginInformationSettingsMessagingCard = {
  __typename?: "UniversalProfileLoginInformationSettingsMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileLoginInformationSettingsOptionType;
};

export type UniversalProfileLoginInformationSettingsOptionType = "CHANGE_PASSWORD" | "CONNECTED_DEVICES" | "EMAIL";

export type UniversalProfileMinDateValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileMinDateValidationRule";
  errorMessage: Scalars["String"];
  minDay?: Maybe<Scalars["Int"]>;
  minMonth?: Maybe<Scalars["Int"]>;
  minYear?: Maybe<Scalars["Int"]>;
};

export type UniversalProfileMoreDetails = {
  __typename?: "UniversalProfileMoreDetails";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileMoreDetailsMessagingCard>;
};

export type UniversalProfileMoreDetailsMessagingCard = {
  __typename?: "UniversalProfileMoreDetailsMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileMoreDetailsOptionType;
};

export type UniversalProfileMoreDetailsOptionType =
  | "AIRPORT_SECURITY"
  | "FLIGHT_PREFERENCES"
  | "REWARD_PROGRAM"
  | "TRAVELER_DOCUMENTS";

export type UniversalProfileNameField = {
  __typename?: "UniversalProfileNameField";
  firstName: UniversalProfileTextInputField;
  heading: UniversalProfileContentHeading;
  lastName: UniversalProfileTextInputField;
  middleName?: Maybe<UniversalProfileTextInputField>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
};

export type UniversalProfileNumberInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileNumberInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsNumberInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfilePageViewAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfilePageViewAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type UniversalProfilePassport = {
  __typename?: "UniversalProfilePassport";
  addExpiryDateField?: Maybe<UniversalProfilePassportAddExpiryDateField>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  expiryDateField?: Maybe<UniversalProfileDateField>;
  heading?: Maybe<UniversalProfileHeading>;
  passportField?: Maybe<UniversalProfilePassportField>;
  removeButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfilePassportAddExpiryDateField = {
  __typename?: "UniversalProfilePassportAddExpiryDateField";
  addDate?: Maybe<UniversalProfileStandardLink>;
  heading?: Maybe<UniversalProfileContentHeading>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
};

export interface UniversalProfilePassportExpiryDateInput {
  day?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<Scalars["Int"]>;
  year?: InputMaybe<Scalars["Int"]>;
}

export type UniversalProfilePassportField = {
  __typename?: "UniversalProfilePassportField";
  country: UniversalProfileBasicSelect;
  heading?: Maybe<UniversalProfileContentHeading>;
  passportNumber: UniversalProfileTextInputField;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
};

export interface UniversalProfilePassportInformationRequestInput {
  country?: InputMaybe<Scalars["String"]>;
  expiryDate?: InputMaybe<UniversalProfilePassportExpiryDateInput>;
  passportNumber?: InputMaybe<Scalars["String"]>;
}

export type UniversalProfilePasswordCheckAction = UniversalProfileAction & {
  __typename?: "UniversalProfilePasswordCheckAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfilePasswordEditAction = UniversalProfileAction & {
  __typename?: "UniversalProfilePasswordEditAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfilePasswordField = {
  __typename?: "UniversalProfilePasswordField";
  heading: UniversalProfileContentHeading;
  password: UniversalProfilePasswordInputField;
};

export type UniversalProfilePasswordForm = {
  __typename?: "UniversalProfilePasswordForm";
  newPassword: UniversalProfileSettingsBasicForm;
  validateAccount: UniversalProfileSettingsBasicForm;
};

export type UniversalProfilePasswordInputField = UniversalProfileInput & {
  __typename?: "UniversalProfilePasswordInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsPasswordInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfilePhoneField = {
  __typename?: "UniversalProfilePhoneField";
  countryCode: UniversalProfileBasicSelect;
  heading: UniversalProfileContentHeading;
  number: UniversalProfileNumberInputField;
  smsInfo?: Maybe<UniversalProfileBasicCheckBox>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfilePhoneInput {
  countryCode?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  smsInfo?: InputMaybe<Scalars["Boolean"]>;
}

export type UniversalProfilePhoneLinkRow = {
  __typename?: "UniversalProfilePhoneLinkRow";
  action: UniversalProfileAction;
  heading?: Maybe<EgdsSpannableText>;
  leadingIcon: Icon;
};

export type UniversalProfilePreferences = {
  __typename?: "UniversalProfilePreferences";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfilePreferencesMessagingCard>;
  subOptions: Array<UniversalProfileBrandPreferences>;
};

export type UniversalProfilePreferencesMessagingCard = {
  __typename?: "UniversalProfilePreferencesMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  disable?: Maybe<Scalars["Boolean"]>;
  type: UniversalProfilePreferencesOptionType;
};

export type UniversalProfilePreferencesOptionType = "COUNTRY" | "CURRENCY" | "DISPLAY_APPEARANCE" | "LANGUAGE";

export type UniversalProfileReasonLeavingField = {
  __typename?: "UniversalProfileReasonLeavingField";
  checkboxGroup: UniversalProfileCheckBoxGroup;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileRedirectAction = UniversalProfileAction & {
  __typename?: "UniversalProfileRedirectAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"];
  redirectUrl: Scalars["String"];
};

export type UniversalProfileRedirectComponent = {
  __typename?: "UniversalProfileRedirectComponent";
  action: UniversalProfileRedirectAction;
};

export type UniversalProfileRedressNumberField = {
  __typename?: "UniversalProfileRedressNumberField";
  heading: UniversalProfileContentHeading;
  redressNumber: UniversalProfileTsaTextInputField;
};

export type UniversalProfileRegexValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileRegexValidationRule";
  errorMessage: Scalars["String"];
  regex: Scalars["String"];
};

export type UniversalProfileRemoveAdditionalTravelerSheet = {
  __typename?: "UniversalProfileRemoveAdditionalTravelerSheet";
  cancelButton?: Maybe<UniversalProfileButton>;
  description?: Maybe<Scalars["String"]>;
  removeButton?: Maybe<UniversalProfileButton>;
  sheet?: Maybe<EgdsSheet>;
  title?: Maybe<Scalars["String"]>;
};

export type UniversalProfileResponse = {
  __typename?: "UniversalProfileResponse";
  dashboard: UniversalProfileDashboard;
  passport: UniversalProfileExperience;
  travelerCommunicationPreferencesForm: UniversalProfileCommunicationPreferencesForm;
  travelerCompanionInformation: UniversalProfileExperience;
  travelerCompanionInformationForm: UniversalProfileInformationForm;
  travelerContext: UniversalProfileTravelerContext;
  travelerDocuments: UniversalProfileExperience;
  travelerInformation: UniversalProfileExperience;
  travelerInformationForm: UniversalProfileInformationForm;
  travelerPassportInformationEditForm: UniversalProfileInformationForm;
  travelerSettingsForm: UniversalProfileSettingsForm;
};

export type UniversalProfileResponseDashboardArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponsePassportArgs = {
  universalProfileContext: UniversalProfileTravelerDocumentsContextInput;
};

export type UniversalProfileResponseTravelerCommunicationPreferencesFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerCompanionInformationArgs = {
  universalProfileContext: UniversalProfileAdditionalTravelerContextInput;
};

export type UniversalProfileResponseTravelerCompanionInformationFormArgs = {
  universalProfileContext: UniversalProfileAdditionalTravelerContextInput;
};

export type UniversalProfileResponseTravelerContextArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerDocumentsArgs = {
  universalProfileContext: UniversalProfileTravelerDocumentsContextInput;
};

export type UniversalProfileResponseTravelerInformationArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerInformationFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerPassportInformationEditFormArgs = {
  universalProfileContext: UniversalProfileTravelerDocumentsContextInput;
};

export type UniversalProfileResponseTravelerSettingsFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileSearchHistoryComponent = {
  __typename?: "UniversalProfileSearchHistoryComponent";
  clearRecentSearchDialog?: Maybe<UniversalProfileDialog>;
  clearSearchAndSavedData?: Maybe<UniversalProfileStandardLink>;
};

export type UniversalProfileSettingsBasicForm = {
  __typename?: "UniversalProfileSettingsBasicForm";
  analytics: Array<UniversalProfileAnalyticEvent>;
  cancelButton?: Maybe<UniversalProfileButton>;
  content: Array<UniversalProfileSettingsFormContent>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  heading: UniversalProfileHeading;
  saveButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileSettingsForm =
  | UniversalProfileCountryForm
  | UniversalProfileDeleteAccountForm
  | UniversalProfileEmailForm
  | UniversalProfileLanguageForm
  | UniversalProfilePasswordForm
  | UniversalProfileRedirectComponent
  | UniversalProfileSignInComponent;

export type UniversalProfileSettingsFormContent =
  | UniversalProfileButton
  | UniversalProfileConfirmationLeavingView
  | UniversalProfileCountryField
  | UniversalProfileEmailInputField
  | UniversalProfileEmailView
  | UniversalProfileLanguageField
  | UniversalProfilePasswordField
  | UniversalProfileReasonLeavingField
  | UniversalProfileStrengthMeter;

export type UniversalProfileSettingsFormType =
  | "DELETE_ACCOUNT"
  | "UPDATE_COUNTRY"
  | "UPDATE_EMAIL"
  | "UPDATE_LANGUAGE"
  | "UPDATE_PASSWORD";

export type UniversalProfileSheet =
  | UniversalProfileCallLinksSheet
  | UniversalProfileRemoveAdditionalTravelerSheet
  | UniversalProfileTsaInformationSheet;

export type UniversalProfileShowSheetAction = UniversalProfileAction & {
  __typename?: "UniversalProfileShowSheetAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileSignInAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSignInAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl: Scalars["String"];
};

export type UniversalProfileSignInComponent = {
  __typename?: "UniversalProfileSignInComponent";
  createAccountButton: UniversalProfileButton;
  illustration: UniversalProfileThemeIllustration;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  signInButton: UniversalProfileButton;
};

export type UniversalProfileSignOutAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSignOutAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl: Scalars["String"];
};

export type UniversalProfileSignOutDialog = {
  __typename?: "UniversalProfileSignOutDialog";
  cancelButton: UniversalProfileButton;
  confirmButton: UniversalProfileButton;
  heading: UniversalProfileHeading;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  title: Scalars["String"];
};

export type UniversalProfileSignOutDialogLaunchAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSignOutDialogLaunchAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileSignOutLauncher = {
  __typename?: "UniversalProfileSignOutLauncher";
  dialog: UniversalProfileSignOutDialog;
  launchButton: UniversalProfileButton;
};

export interface UniversalProfileSmsContactInformationRequestInput {
  phone: UniversalProfilePhoneInput;
}

export type UniversalProfileStandardLink = {
  __typename?: "UniversalProfileStandardLink";
  action: UniversalProfileAction;
  disabled: Scalars["Boolean"];
  subtext?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type UniversalProfileStrengthMeter = {
  __typename?: "UniversalProfileStrengthMeter";
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileSubExperienceType =
  | "COMMUNICATION_PREFERENCES_EXPEDIA"
  | "COMMUNICATION_PREFERENCES_HOTELS"
  | "COMMUNICATION_PREFERENCES_VRBO"
  | "COMMUNICATION_PREFERENCES_WOTIF"
  | "VISIT_HELP_CENTER";

export type UniversalProfileSuccessResponse = {
  __typename?: "UniversalProfileSuccessResponse";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  clickstreamAnalytics: Array<UniversalProfileAnalyticEvent>;
  message: UniversalProfileToast;
};

export type UniversalProfileSwitchChangeCriteria = {
  __typename?: "UniversalProfileSwitchChangeCriteria";
  fieldIdentifier?: Maybe<Scalars["String"]>;
  operator: UniversalProfileCategorySwitchOperator;
};

export type UniversalProfileTextAreaInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileTextAreaInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsTextAreaInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileTextInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileTextInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsTextInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileThemeIllustration = {
  __typename?: "UniversalProfileThemeIllustration";
  dark: Illustration;
  standard: Illustration;
};

export type UniversalProfileToast = {
  __typename?: "UniversalProfileToast";
  toast: EgdsToast;
};

export interface UniversalProfileTravelerCompanionContactInfoInput {
  email?: InputMaybe<Scalars["String"]>;
  primaryPhone: UniversalProfilePhoneInput;
}

export interface UniversalProfileTravelerCompanionRequestInput {
  accessibilityNeeds?: InputMaybe<Scalars["String"]>;
  contactInfo: UniversalProfileTravelerCompanionContactInfoInput;
  dateOfBirth: UniversalProfileBasicInformationDateOfBirthInput;
  emergencyContact: UniversalProfileEmergencyContactInput;
  gender?: InputMaybe<Scalars["String"]>;
  name: UniversalProfileBasicInformationNameInput;
  travelerId?: InputMaybe<Scalars["String"]>;
  tsaInfo?: InputMaybe<UniversalProfileTsaInformationRequestInput>;
}

export type UniversalProfileTravelerContext = UniversalProfileTravelerContextComponent;

export type UniversalProfileTravelerContextComponent = {
  __typename?: "UniversalProfileTravelerContextComponent";
  accountUpgradeStatus?: Maybe<Scalars["String"]>;
  authenticationFactorAction?: Maybe<Scalars["String"]>;
  userBrandName?: Maybe<Scalars["String"]>;
};

export type UniversalProfileTravelerDocumentCreateAction = UniversalProfileAction & {
  __typename?: "UniversalProfileTravelerDocumentCreateAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"];
  redirectUrl: Scalars["String"];
};

export type UniversalProfileTravelerDocumentForm = {
  __typename?: "UniversalProfileTravelerDocumentForm";
  heading?: Maybe<UniversalProfileHeading>;
  passportInformation?: Maybe<UniversalProfileTravelerDocumentInformationForm>;
  title?: Maybe<Scalars["String"]>;
};

export type UniversalProfileTravelerDocumentInformationForm = {
  __typename?: "UniversalProfileTravelerDocumentInformationForm";
  content?: Maybe<Array<UniversalProfileInformationFormContent>>;
  saveButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileTravelerDocumentSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileTravelerDocumentSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileTravelerDocuments = {
  __typename?: "UniversalProfileTravelerDocuments";
  addAnotherButton?: Maybe<UniversalProfileButton>;
  heading?: Maybe<UniversalProfileHeading>;
  options?: Maybe<Array<UniversalProfileTravelerDocumentsMessagingCard>>;
};

export interface UniversalProfileTravelerDocumentsContextInput {
  accountTakeOverWidgets?: InputMaybe<Array<UniversalProfileAccountTakeOverWidgetRequestInput>>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
  travelerDocIdentifier?: InputMaybe<Scalars["String"]>;
}

export type UniversalProfileTravelerDocumentsInformation = {
  __typename?: "UniversalProfileTravelerDocumentsInformation";
  passport: UniversalProfilePassport;
  travelerDocuments: UniversalProfileTravelerDocuments;
};

export type UniversalProfileTravelerDocumentsMessagingCard = {
  __typename?: "UniversalProfileTravelerDocumentsMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card?: Maybe<EgdsStandardMessagingCard>;
  errorMessage?: Maybe<Scalars["String"]>;
  type?: Maybe<UniversalProfileTravelerDocumentsOptionType>;
};

export type UniversalProfileTravelerDocumentsOptionType = "PASSPORT";

export type UniversalProfileTravelerInformation = {
  __typename?: "UniversalProfileTravelerInformation";
  additionalTravelers?: Maybe<UniversalProfileAdditionalTravelers>;
  analytics: Array<UniversalProfileAnalyticEvent>;
  basicInformation: UniversalProfileInformation;
  contactInformation: UniversalProfileInformation;
  heading?: Maybe<UniversalProfileHeading>;
  moreDetails?: Maybe<UniversalProfileMoreDetails>;
};

export type UniversalProfileTravelerSettings = {
  __typename?: "UniversalProfileTravelerSettings";
  accountManagement: UniversalProfileAccountManagementSettings;
  analytics: Array<UniversalProfileAnalyticEvent>;
  heading: UniversalProfileHeading;
  legal?: Maybe<UniversalProfileLegal>;
  loginInformation: UniversalProfileLoginInformationSettings;
  preferences: UniversalProfilePreferences;
};

export type UniversalProfileTsaForm = {
  __typename?: "UniversalProfileTsaForm";
  content: Array<UniversalProfileTsaTextInputField>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  heading: UniversalProfileHeading;
  inputContent?: Maybe<Array<UniversalProfileInformationFormContent>>;
  saveButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileTsaInformationForm = {
  __typename?: "UniversalProfileTsaInformationForm";
  tsaInformation: UniversalProfileTsaForm;
};

export interface UniversalProfileTsaInformationRequestInput {
  knownTravelerNumber?: InputMaybe<Scalars["String"]>;
  redressNumber?: InputMaybe<Scalars["String"]>;
}

export type UniversalProfileTsaInformationSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileTsaInformationSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileTsaInformationSheet = {
  __typename?: "UniversalProfileTsaInformationSheet";
  confirmButton: UniversalProfileButton;
  egdsSheet: EgdsSheet;
  title: Scalars["String"];
};

export type UniversalProfileTsaTextInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileTsaTextInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  iconInfoDismiss: Icon;
  iconInfoOutline: Icon;
  input?: Maybe<EgdsTextInputField>;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  sheet?: Maybe<UniversalProfileTsaInformationSheet>;
  universalProfileIdentifier?: Maybe<Scalars["String"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfileValidationRule {
  errorMessage: Scalars["String"];
}

export type UniversalProfileVirtualAgentAction = UniversalProfileAction & {
  __typename?: "UniversalProfileVirtualAgentAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileVirtualAgentComponent = {
  __typename?: "UniversalProfileVirtualAgentComponent";
  action?: Maybe<UniversalProfileAction>;
};

export type UniversalSortAndFilterAnalytics = {
  __typename?: "UniversalSortAndFilterAnalytics";
  onDisplayErrorAnalytics: Array<ClientSideAnalytics>;
};

export type UnmappedFilter = {
  __typename?: "UnmappedFilter";
  filterId: Scalars["String"];
  filterValue: Scalars["String"];
};

export interface UnmappedFilterInput {
  filterId: Scalars["String"];
  filterValue: Scalars["String"];
}

export type UpdateAction = "ACCEPT" | "SHARE";

export type UpdateHistory = "REMOVE";

export interface UpdateInsurtechOfferRequestInput {
  coveredOfferTokens?: InputMaybe<Array<OfferIdentityInput>>;
  offersToAdd: Array<OfferIdentityInput>;
  offersToRemove: Array<OfferIdentityInput>;
  sessionIdentifier?: InputMaybe<Scalars["String"]>;
  targetOfferIdentity: OfferIdentityInput;
}

export type UpdateInsurtechOfferResponse = {
  __typename?: "UpdateInsurtechOfferResponse";
  message?: Maybe<Scalars["String"]>;
  selectedOffers: Array<OfferIdentity>;
  signals?: Maybe<Array<InsurtechSignal>>;
  status: InsurtechMutationStatus;
};

export interface UpdateJourneyContinuationIdRequestInput {
  additionalProducts?: InputMaybe<AdditionalProductsInput>;
  journeyContinuationId: Scalars["String"];
}

export type UpdateJourneyContinuationIdResponse = {
  __typename?: "UpdateJourneyContinuationIdResponse";
  journeyContinuationId: Scalars["String"];
};

export interface UpdatePaymentMethodRequestInput {
  paymentMethod: PaymentMethodInput;
  sessionId: Scalars["String"];
  sessionToken: Scalars["String"];
}

export type UpdatePresence = {
  __typename?: "UpdatePresence";
  currentState: AgentState;
  message: Scalars["String"];
};

export type UpdatePresenceResponse = {
  __typename?: "UpdatePresenceResponse";
  message: Scalars["String"];
  userMigrationReport: UserMigrationReport;
};

export type UpdateSupplySelectedWaiversResponse = {
  __typename?: "UpdateSupplySelectedWaiversResponse";
  selectedWaivers: Array<Maybe<Scalars["Int"]>>;
};

export type UpdateTripType = "ADD" | "REMOVE";

export type UpvotePropertyReviewWithAccessibility = AccessibilityField & {
  __typename?: "UpvotePropertyReviewWithAccessibility";
  accessibilityLabel?: Maybe<Scalars["String"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]>;
  primaryDisplayString?: Maybe<Scalars["String"]>;
};

export type Uri = Uri & {
  __typename?: "Uri";
  value: Scalars["String"];
};

export interface UriAttributesInput {
  gaiaType?: InputMaybe<Scalars["String"]>;
  lodgingUrlCode?: InputMaybe<Scalars["String"]>;
}

export type UriClassification = "CLASSIFICATION_DEALS" | "CLASSIFICATION_INVALID" | "CLASSIFICATION_LEGAL";

export interface UriContextAttributesInput {
  affinity?: InputMaybe<Scalars["String"]>;
  airCarrierCode?: InputMaybe<Scalars["String"]>;
  carClass?: InputMaybe<Scalars["String"]>;
  carSupplier?: InputMaybe<Scalars["String"]>;
  gaiaType?: InputMaybe<Scalars["String"]>;
  lodgingUrlCode?: InputMaybe<Scalars["String"]>;
  starRating?: InputMaybe<Scalars["String"]>;
}

export interface UriContextIdentifiersInput {
  destination: Scalars["String"];
  hotelId?: InputMaybe<Scalars["Int"]>;
  origin?: InputMaybe<Scalars["String"]>;
}

export interface UriContextInput {
  attributes?: InputMaybe<UriAttributesInput>;
  campaign?: InputMaybe<Scalars["String"]>;
  classification?: InputMaybe<UriClassification>;
  destination?: InputMaybe<Scalars["String"]>;
  funnelLocation?: InputMaybe<PageLocation>;
  identifiers?: InputMaybe<UriIdentifiersInput>;
  legalConcept?: InputMaybe<LegalConcept>;
}

export interface UriIdentifiersInput {
  accommodationType?: InputMaybe<Scalars["String"]>;
  airport?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  carClass?: InputMaybe<Scalars["String"]>;
  carSupplierLocation?: InputMaybe<Scalars["String"]>;
  carrierCode?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  cruiseLine?: InputMaybe<Scalars["String"]>;
  destination?: InputMaybe<Scalars["String"]>;
  discriminator?: InputMaybe<Scalars["String"]>;
  feature?: InputMaybe<Scalars["String"]>;
  flightRoute?: InputMaybe<Scalars["String"]>;
  funnelLocation?: InputMaybe<Scalars["String"]>;
  hotel?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lineOfBusiness?: InputMaybe<Scalars["String"]>;
  lodgingType?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Scalars["String"]>;
  ship?: InputMaybe<Scalars["String"]>;
  starRating?: InputMaybe<Scalars["String"]>;
  theme?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
}

export type UserAnswer = {
  __typename?: "UserAnswer";
  answer: Scalars["String"];
  answerTime: DateTime;
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  upVotes: FormattedNumber;
};

export type UserAuthentication = {
  __typename?: "UserAuthentication";
  signIn?: Maybe<SignIn>;
  signedInUser?: Maybe<SignedInUser>;
};

export type UserAuthenticationResponse = {
  __typename?: "UserAuthenticationResponse";
  userAuthentication: UserAuthentication;
};

export type UserFeedback = {
  __typename?: "UserFeedback";
  options: Array<UserFeedbackOption>;
  submit: CarActionableItem;
  submitConfirmation: Scalars["String"];
  userSurveyTitle: Scalars["String"];
};

export type UserFeedbackOption = {
  __typename?: "UserFeedbackOption";
  inputHeading: Scalars["String"];
  inputTextPlaceholder: Scalars["String"];
  option: CarActionableItem;
};

export interface UserLocationInput {
  userCountryCode?: InputMaybe<Scalars["String"]>;
  userLatitude?: InputMaybe<Scalars["BigDecimal"]>;
  userLongitude?: InputMaybe<Scalars["BigDecimal"]>;
  userSelectedOrigin?: InputMaybe<Scalars["String"]>;
}

export type UserMigrationReport = {
  __typename?: "UserMigrationReport";
  userIdentifierUsed?: Maybe<Scalars["String"]>;
  userUriReceived: Scalars["Boolean"];
  userVersionUsed?: Maybe<Scalars["String"]>;
  usernameReceived: Scalars["Boolean"];
};

export type UserNotificationState = {
  __typename?: "UserNotificationState";
  description?: Maybe<Scalars["String"]>;
  heading?: Maybe<Scalars["String"]>;
  optInButton?: Maybe<AppGrowthButton>;
  optOutButton?: Maybe<AppGrowthButton>;
  optedIn?: Maybe<Scalars["String"]>;
};

export type UserProperties = {
  __typename?: "UserProperties";
  countryCode?: Maybe<Scalars["String"]>;
  hotelId?: Maybe<Scalars["String"]>;
  hotelName?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
  isNluDisabled?: Maybe<Scalars["String"]>;
  pagePath?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type UserQuestion = {
  __typename?: "UserQuestion";
  answers?: Maybe<Array<UserAnswer>>;
  createDate?: Maybe<DateTime>;
  id: Scalars["String"];
  question: Scalars["String"];
  questioner?: Maybe<Scalars["String"]>;
  totalAnswers: FormattedNumber;
};

export type VaConversationProperties = {
  __typename?: "VAConversationProperties";
  launchPoint?: Maybe<Scalars["String"]>;
};

export type VacOrchestrator = {
  __typename?: "VacOrchestrator";
  auth: VacOrchestratorAuthentication;
};

export type VacOrchestratorAuthentication = {
  __typename?: "VacOrchestratorAuthentication";
  subscriptionId: Scalars["String"];
  token: Scalars["String"];
};

export type ValuePropositionBanner = {
  __typename?: "ValuePropositionBanner";
  carousel: EgdsCarousel;
  disclosure?: Maybe<ValuePropositionBannerDisclosure>;
  grid?: Maybe<ValuePropositionBannerGrid>;
  header: EgdsHeading;
};

export type ValuePropositionBannerDisclosure = {
  __typename?: "ValuePropositionBannerDisclosure";
  content: ValuePropositionBannerDisclosurePopover;
  icon?: Maybe<Icon>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
};

export type ValuePropositionBannerDisclosurePopover = EgdsDialog & {
  __typename?: "ValuePropositionBannerDisclosurePopover";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  details?: Maybe<EgdsTextList>;
  footer?: Maybe<EgdsDialogFooter>;
  header: EgdsSectionHeading;
};

export type ValuePropositionBannerGrid = {
  __typename?: "ValuePropositionBannerGrid";
  analytics?: Maybe<ClientSideAnalytics>;
  items: Array<EgdsCard>;
};

export type VehicleDetails = {
  __typename?: "VehicleDetails";
  attributes: Array<VehicleFeature>;
  category: Scalars["String"];
  categoryIcon?: Maybe<Icon>;
  description?: Maybe<Scalars["String"]>;
  ecoFriendlyFuel?: Maybe<CarsRichText>;
  features: Array<VehicleFeature>;
  fuelInfo: Array<VehicleFeature>;
  image?: Maybe<Image>;
};

export type VehicleFeature = {
  __typename?: "VehicleFeature";
  icon?: Maybe<Icon>;
  info?: Maybe<VehicleFeatureInfo>;
  text: Scalars["String"];
};

export type VehicleFeatureInfo = {
  __typename?: "VehicleFeatureInfo";
  carActionableItem: CarActionableItem;
  carActionableItemOnClose?: Maybe<CarActionableItem>;
  vehicleFeatureDialog: CarsDialog;
};

export type Video = {
  __typename?: "Video";
  description: Scalars["String"];
  playPauseAnalytics?: Maybe<ClientSideAnalytics>;
  thumbnail?: Maybe<Uri>;
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Uri;
};

export type VirtualAgentCancelIntentMessage = VirtualAgentIntentMessage & {
  __typename?: "VirtualAgentCancelIntentMessage";
  action: Scalars["String"];
  emailAddress?: Maybe<Scalars["String"]>;
  intent: Scalars["String"];
  orderLineId: Scalars["String"];
  orderNumber: Scalars["String"];
  product: Scalars["String"];
};

export type VirtualAgentCheckoutResponse = {
  __typename?: "VirtualAgentCheckoutResponse";
  status: VirtualAgentCheckoutStatus;
};

export type VirtualAgentCheckoutStatus = "FAILED" | "SUCCESS";

export interface VirtualAgentContextInput {
  itineraryNumber: Scalars["String"];
  opaqueToken: Scalars["String"];
}

export type VirtualAgentControl = {
  __typename?: "VirtualAgentControl";
  chat: VirtualAgentControlChat;
  chatConfig: VirtualAgentControlChatConfig;
  feedback?: Maybe<VirtualAgentControlFeedback>;
  trigger?: Maybe<VirtualAgentControlTrigger>;
};

export type VirtualAgentControlAblyConfig = {
  __typename?: "VirtualAgentControlAblyConfig";
  clientId: Scalars["String"];
  token: Scalars["String"];
};

export type VirtualAgentControlActionable = {
  __typename?: "VirtualAgentControlActionable";
  action: VirtualAgentControlUiAction;
  element: VirtualAgentControlActionableElement;
};

export type VirtualAgentControlActionableElement = {
  __typename?: "VirtualAgentControlActionableElement";
  button?: Maybe<EgdsButton>;
  link?: Maybe<EgdsStandardLink>;
};

export interface VirtualAgentControlAnalyticEvent {
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
}

export type VirtualAgentControlAnalyticEventSubType = "BLURRED" | "FOCUSED" | "INPUTTED" | "SUCCEEDED";

export type VirtualAgentControlAndroidEvent = {
  __typename?: "VirtualAgentControlAndroidEvent";
  inboundMessage?: Maybe<VirtualAgentControlInboundMessage>;
};

export type VirtualAgentControlAttachmentData = {
  __typename?: "VirtualAgentControlAttachmentData";
  mediaType: Scalars["String"];
  mediaUrl: Scalars["String"];
  name: Scalars["String"];
  size: Scalars["String"];
  uniqueAttachmentId: Scalars["String"];
};

export interface VirtualAgentControlAttachmentDataInput {
  mediaType: Scalars["String"];
  mediaUrl: Scalars["String"];
  name: Scalars["String"];
  size: Scalars["String"];
  uniqueAttachmentId: Scalars["String"];
}

export interface VirtualAgentControlAttributeInput {
  key: Scalars["String"];
  value: Scalars["String"];
}

export type VirtualAgentControlAvatar = AvatarGroup;

export type VirtualAgentControlChannelOrigin = {
  __typename?: "VirtualAgentControlChannelOrigin";
  businessSegmentGuid: Scalars["String"];
  channelOriginGuid: Scalars["String"];
  channelOriginId: Scalars["String"];
  partnerGuid: Scalars["String"];
};

export type VirtualAgentControlChat = {
  __typename?: "VirtualAgentControlChat";
  analytics?: Maybe<Array<AnalyticsElement>>;
  conversationIntro?: Maybe<VirtualAgentControlConversationIntro>;
  footer: VirtualAgentControlChatFooter;
  header?: Maybe<VirtualAgentControlHeader>;
};

export type VirtualAgentControlChatConfig = {
  __typename?: "VirtualAgentControlChatConfig";
  channelOrigin: VirtualAgentControlChannelOrigin;
  chatCapabilities?: Maybe<ChatCapabilities>;
};

export type VirtualAgentControlChatContent = {
  __typename?: "VirtualAgentControlChatContent";
  /** @deprecated Prefer virtualAgentControl.chat.conversationIntro */
  conversationIntro?: Maybe<VirtualAgentControlConversationIntro>;
  elements: Array<VirtualAgentControlChatElement>;
};

export type VirtualAgentControlChatElement =
  | VirtualAgentControlInboundMessageGroup
  | VirtualAgentControlMessageSeparator
  | VirtualAgentControlOutboundMessageElementGroup
  | VirtualAgentControlOutboundMessageGroup;

export type VirtualAgentControlChatFooter = {
  __typename?: "VirtualAgentControlChatFooter";
  attach?: Maybe<EgdsButton>;
  input: VirtualAgentControlInput;
  send: EgdsButton;
};

export type VirtualAgentControlChatHeader = {
  __typename?: "VirtualAgentControlChatHeader";
  avatar: VirtualAgentControlAvatar;
  minimize?: Maybe<EgdsButton>;
  text: Scalars["String"];
};

export type VirtualAgentControlConfig = {
  __typename?: "VirtualAgentControlConfig";
  webSocket?: Maybe<VirtualAgentControlWebSocketConfig>;
};

export interface VirtualAgentControlConversationEventInput {
  content?: InputMaybe<EventContentInput>;
  eventType: VirtualAgentControlEventType;
}

export type VirtualAgentControlConversationEventResponse = {
  __typename?: "VirtualAgentControlConversationEventResponse";
  retryText?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlConversationIntro = {
  __typename?: "VirtualAgentControlConversationIntro";
  bottomBorder?: Maybe<VirtualAgentControlMessageSeparator>;
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlDialogAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlDialogAction";
  analytics?: Maybe<ClientSideAnalytics>;
  srcDoc: Scalars["String"];
  toolbar: UiToolbar;
};

export type VirtualAgentControlDynamicCard = {
  __typename?: "VirtualAgentControlDynamicCard";
  cover?: Maybe<Image>;
  elements: Array<DynamicCardElement>;
};

export type VirtualAgentControlDynamicCardItem = EgdsCarouselItem & {
  __typename?: "VirtualAgentControlDynamicCardItem";
  card: VirtualAgentControlDynamicCard;
  id?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlErrorBanner = {
  __typename?: "VirtualAgentControlErrorBanner";
  header: Scalars["String"];
  retryText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type VirtualAgentControlErrorFullPageView = {
  __typename?: "VirtualAgentControlErrorFullPageView";
  header: Scalars["String"];
  icon: Icon;
  retryText?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type VirtualAgentControlEventType = "CONVERSATION_READ" | "TYPING_INDICATOR";

export type VirtualAgentControlFeedback = {
  __typename?: "VirtualAgentControlFeedback";
  form: VirtualAgentControlFeedbackForm;
  openForm: EgdsButton;
};

export type VirtualAgentControlFeedbackForm = ReportProblemForm;

export type VirtualAgentControlFormInput = EgdsBasicCheckBox;

export type VirtualAgentControlHeader = VirtualAgentControlChatHeader | VirtualAgentControlToolbar;

export type VirtualAgentControlHorizontalBreak = {
  __typename?: "VirtualAgentControlHorizontalBreak";
  label?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlImpressionAnalyticEvent = VirtualAgentControlAnalyticEvent & {
  __typename?: "VirtualAgentControlImpressionAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type VirtualAgentControlInboundCardMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundCardMessage";
  eyebrow?: Maybe<Scalars["String"]>;
  header?: Maybe<Scalars["String"]>;
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"];
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInboundDynamicCardMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundDynamicCardMessage";
  carousel?: Maybe<EgdsCarousel>;
  dynamicCard?: Maybe<VirtualAgentControlDynamicCard>;
  eyebrow?: Maybe<Scalars["String"]>;
  metadata: VirtualAgentControlMessageMetadata;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInboundFileMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundFileMessage";
  /** @deprecated Prefer attachmentMetadata */
  attachment?: Maybe<VirtualAgentControlAttachmentData>;
  attachmentLink: EgdsStandardLink;
  attachmentMetadata?: Maybe<VirtualAgentControlAttachmentData>;
  eyebrow?: Maybe<Scalars["String"]>;
  metadata: VirtualAgentControlMessageMetadata;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export interface VirtualAgentControlInboundMessage {
  eyebrow?: Maybe<Scalars["String"]>;
  metadata: VirtualAgentControlMessageMetadata;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
}

export type VirtualAgentControlInboundMessageGroup = {
  __typename?: "VirtualAgentControlInboundMessageGroup";
  eyebrow?: Maybe<Scalars["String"]>;
  messages: Array<VirtualAgentControlInboundMessage>;
  timeStamp?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlInboundTextMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundTextMessage";
  eyebrow?: Maybe<Scalars["String"]>;
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"];
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInput = EgdsTextInputField | EgdsTypeaheadInputField;

export type VirtualAgentControlInteractionAnalyticEvent = VirtualAgentControlAnalyticEvent & {
  __typename?: "VirtualAgentControlInteractionAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
  subType?: Maybe<VirtualAgentControlAnalyticEventSubType>;
};

export interface VirtualAgentControlMessageInput {
  attachments?: InputMaybe<Array<VirtualAgentControlAttachmentDataInput>>;
  intent?: InputMaybe<Scalars["String"]>;
  messageTriggerLocation?: InputMaybe<VirtualAgentControlMessageTriggerLocation>;
  messageType?: InputMaybe<VirtualAgentControlMessageType>;
  payload?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  umid: Scalars["String"];
}

export type VirtualAgentControlMessageMetadata = {
  __typename?: "VirtualAgentControlMessageMetadata";
  isActivityIndicatorMessage?: Maybe<Scalars["Boolean"]>;
  messageAuthor?: Maybe<Scalars["String"]>;
  messageId: Scalars["String"];
  timeStamp?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlMessageSeparator = {
  __typename?: "VirtualAgentControlMessageSeparator";
  primary: Scalars["String"];
};

export type VirtualAgentControlMessageTriggerLocation = "EXTERNAL" | "INTERNAL";

export type VirtualAgentControlMessageType = "FILE" | "INTENT" | "POSTBACK" | "TEXT";

export type VirtualAgentControlMessages = {
  __typename?: "VirtualAgentControlMessages";
  messages: Array<VirtualAgentControlChatElement>;
  meta?: Maybe<VirtualAgentControlMessagesMetadata>;
};

export type VirtualAgentControlMessagesMetadata = {
  __typename?: "VirtualAgentControlMessagesMetadata";
  participants: Array<ConversationParticipant>;
};

export type VirtualAgentControlOutboundFileMessage = VirtualAgentControlOutboundMessageElement & {
  __typename?: "VirtualAgentControlOutboundFileMessage";
  /** @deprecated Prefer attachmentMetadata */
  attachment?: Maybe<VirtualAgentControlAttachmentData>;
  attachmentLink: EgdsStandardLink;
  attachmentMetadata?: Maybe<VirtualAgentControlAttachmentData>;
  metadata: VirtualAgentControlMessageMetadata;
  status?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlOutboundMessage = {
  __typename?: "VirtualAgentControlOutboundMessage";
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
};

export interface VirtualAgentControlOutboundMessageElement {
  metadata: VirtualAgentControlMessageMetadata;
  status?: Maybe<Scalars["String"]>;
}

export type VirtualAgentControlOutboundMessageElementGroup = {
  __typename?: "VirtualAgentControlOutboundMessageElementGroup";
  messages: Array<VirtualAgentControlOutboundMessageElement>;
  status?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlOutboundMessageGroup = {
  __typename?: "VirtualAgentControlOutboundMessageGroup";
  messages: Array<VirtualAgentControlOutboundMessage>;
  status?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlOutboundTextMessage = VirtualAgentControlOutboundMessageElement & {
  __typename?: "VirtualAgentControlOutboundTextMessage";
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlPageViewAnalyticEvent = VirtualAgentControlAnalyticEvent & {
  __typename?: "VirtualAgentControlPageViewAnalyticEvent";
  eventName: Scalars["String"];
  eventVersion: Scalars["String"];
  payload: Scalars["AnalyticsPayload"];
};

export type VirtualAgentControlRedirectAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlRedirectAction";
  analytics?: Maybe<ClientSideAnalytics>;
  target: UiLinkTarget;
  url: Scalars["String"];
};

export type VirtualAgentControlSendMessageAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlSendMessageAction";
  analytics?: Maybe<ClientSideAnalytics>;
  message: Scalars["String"];
  payload?: Maybe<Scalars["String"]>;
};

export type VirtualAgentControlSendMessageResult = {
  __typename?: "VirtualAgentControlSendMessageResult";
  deliveryStatus: Scalars["String"];
  messageId?: Maybe<Scalars["String"]>;
  umid: Scalars["String"];
};

export type VirtualAgentControlStateUpdates = {
  __typename?: "VirtualAgentControlStateUpdates";
  chatInput?: Maybe<ChatInput>;
};

export type VirtualAgentControlToolbar = {
  __typename?: "VirtualAgentControlToolbar";
  closeButton?: Maybe<EgdsButton>;
};

export type VirtualAgentControlTrigger = {
  __typename?: "VirtualAgentControlTrigger";
  autoOpen: Scalars["Boolean"];
  button?: Maybe<VirtualAgentControlTriggerButton>;
};

export type VirtualAgentControlTriggerButton =
  | UiPrimaryButton
  | UiPrimaryFloatingActionButton
  | UiSecondaryButton
  | UiSecondaryFloatingActionButton
  | UiTertiaryButton;

export interface VirtualAgentControlUiAction {
  analytics?: Maybe<ClientSideAnalytics>;
}

export type VirtualAgentControlWebSocketConfig = {
  __typename?: "VirtualAgentControlWebSocketConfig";
  ablyConfig: VirtualAgentControlAblyConfig;
  subscriptionId: Scalars["String"];
};

export type VirtualAgentControlWebviewAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlWebviewAction";
  analytics?: Maybe<ClientSideAnalytics>;
  toolbar: UiToolbar;
  uri: Scalars["String"];
};

export type VirtualAgentConversationProperties = {
  __typename?: "VirtualAgentConversationProperties";
  launchPoint?: Maybe<Scalars["String"]>;
  pageName?: Maybe<Scalars["String"]>;
  skipWelcome?: Maybe<Scalars["String"]>;
};

export interface VirtualAgentIntentMessage {
  intent: Scalars["String"];
}

export type VirtualAgentIntentValue = {
  __typename?: "VirtualAgentIntentValue";
  id: Scalars["String"];
  value: Scalars["String"];
};

export type VirtualTourPrompt = {
  __typename?: "VirtualTourPrompt";
  button?: Maybe<EgdsOverlayButton>;
  caption?: Maybe<EgdsPlainText>;
  heading?: Maybe<EgdsHeading>;
  heroImage?: Maybe<EgdsPhotosphereImage>;
  url?: Maybe<Scalars["URL"]>;
};

export type VoiceOfTheCustomer = {
  __typename?: "VoiceOfTheCustomer";
  accessibilityMessage: Scalars["String"];
  analytics: ClientSideAnalytics;
  displayAction: Scalars["String"];
  label: Scalars["String"];
  relativeURI: HttpUri;
};

export type WaivingSource = "EXPEDIA" | "INSURANCE" | "NO_WAIVER" | "SUPPLIER";

export type WidgetResponse = LegacyWidgetComponent;

export type WishListConfigReivew = {
  __typename?: "WishListConfigReivew";
  description: Scalars["String"];
  rating: Scalars["Float"];
};

export type WishlistConfigEntries = {
  __typename?: "WishlistConfigEntries";
  image: WishlistConfigImage;
  pricePerNight: Scalars["Int"];
  review: WishListConfigReivew;
  title: Scalars["String"];
};

export type WishlistConfigImage = {
  __typename?: "WishlistConfigImage";
  url: Scalars["String"];
};

export interface WishlistContextInput {
  destinationId?: InputMaybe<Scalars["String"]>;
  userLocation?: InputMaybe<CoordinatesInput>;
  wishlistSeason?: InputMaybe<WishlistSeason>;
}

export type WishlistFligtsPriceCard = {
  __typename?: "WishlistFligtsPriceCard";
  subTitle: Scalars["String"];
  title: Scalars["String"];
};

export type WishlistHeading = {
  __typename?: "WishlistHeading";
  description?: Maybe<Scalars["String"]>;
  destination: Scalars["String"];
  destinationGallery?: Maybe<DestinationGallery>;
  title: Scalars["String"];
};

export type WishlistHotelDeals = {
  __typename?: "WishlistHotelDeals";
  cards: Array<WishlistConfigEntries>;
};

export type WishlistHotelsPriceCard = {
  __typename?: "WishlistHotelsPriceCard";
  subTitle: Scalars["String"];
  title: Scalars["String"];
};

export type WishlistResult = {
  __typename?: "WishlistResult";
  flightsPrice?: Maybe<WishlistFligtsPriceCard>;
  heading?: Maybe<WishlistHeading>;
  hotelDeals?: Maybe<WishlistHotelDeals>;
  hotelsPrice?: Maybe<WishlistHotelsPriceCard>;
  thingsToDo?: Maybe<WishlistThingsToDo>;
};

export type WishlistSeason = "FALL" | "SPRING" | "SUMMER" | "WINTER";

export type WishlistThingsToDo = {
  __typename?: "WishlistThingsToDo";
  groups?: Maybe<Array<DestinationRecommendationGroup>>;
  title: Scalars["String"];
};

export type WizardAdditionalUserInputRequiredAction = UiAction & {
  __typename?: "WizardAdditionalUserInputRequiredAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
  inputSource: WizardAdditionalUserInputSource;
};

export type WizardAdditionalUserInputSource = {
  __typename?: "WizardAdditionalUserInputSource";
  requestedInputs: Array<Scalars["String"]>;
  resource: Uri;
};

export type WizardSubmitAction = UiAction & {
  __typename?: "WizardSubmitAction";
  accessibility?: Maybe<Scalars["String"]>;
  actionContext: Scalars["String"];
  analytics: ClientSideAnalytics;
};

export type WorkFlowItem = {
  __typename?: "WorkFlowItem";
  automationStatus: Scalars["String"];
  channel: Scalars["String"];
  intent: Scalars["String"];
  orderLineId: Scalars["String"];
  product: Scalars["String"];
};

export type WorkerInfo = {
  __typename?: "WorkerInfo";
  instanceId: Scalars["String"];
  workerId: Scalars["String"];
};

export type ZonedDateTime = {
  __typename?: "ZonedDateTime";
  day: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  epochSeconds: Scalars["String"];
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"];
  /** @deprecated Use platform formatters and return localized strings instead */
  fullDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  fullTimeFormat?: Maybe<Scalars["String"]>;
  hour: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  longDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  longTimeFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumTimeFormat?: Maybe<Scalars["String"]>;
  minute: Scalars["Int"];
  month: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["String"];
  second: Scalars["Int"];
  /** @deprecated Use platform formatters and return localized strings instead */
  shortDateFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  shortTimeFormat?: Maybe<Scalars["String"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  timeZoneOffsetSeconds: Scalars["Int"];
  year: Scalars["Int"];
};

export type ZonedDateTimeFormattedArgs = {
  format: Scalars["String"];
};

export type ClickActionType = EgdsStandardLink | UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type Description =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsLevelList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsUnorderedList
  | LevelOneList
  | PortableTextHtmlTableSection
  | RichText;

export type Element = AgencyQuoteListTooltip | EgdsBasicCheckBox | EgdsPlainText | EgdsStandardLink;

export type InstallmentPlanDetails = AffirmContentDetails | AffirmDetails | KlarnaAppDetails | KlarnaDetails;

export type List = EgdsOrderedList | EgdsTextIconList | EgdsUnorderedList;

export type Messages = EgdsPlainText | MultiItemMessage;

export type PortableTextContent =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsLevelList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsUnorderedList
  | LevelOneList
  | PortableTextHtmlTableSection;

export type BlossomNotificationQueryVariables = Exact<{
  context: ContextInput;
  notificationLocation: NotificationLocationOnPage;
  lineOfBusiness: LineOfBusinessDomain;
  funnelLocation: PageLocation;
}>;

export type BlossomNotificationQuery = {
  __typename?: "Query";
  notification: {
    __typename?: "NotificationQuery";
    inlineNotification: {
      __typename?: "InlineNotification";
      type: NotificationType;
      notificationLocation?: NotificationLocationOnPage | null;
      title?: {
        __typename?: "NotificationPhrase";
        items: Array<
          | {
              __typename: "NotificationPhraseLinkNode";
              target?: NotificationLinkTarget | null;
              text: string;
              styles: Array<NotificationPhrasePartStyle>;
              uri?:
                | { __typename: "GeoURI"; value: string }
                | { __typename: "HttpURI"; value: string }
                | { __typename: "MailToURI"; value: string }
                | { __typename: "MobileAppURI"; value: string }
                | { __typename: "RelativeURI"; value: string }
                | { __typename: "SelfReferencedURI"; value: string }
                | { __typename: "TelURI"; value: string }
                | { __typename: "Uri"; value: string }
                | null;
              actions?: Array<
                | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
                | { __typename: "NotificationAnalyticsEgClickstream" }
                | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
                | { __typename: "NotificationMarketing" }
                | { __typename: "NotificationSchema" }
                | { __typename: "NotificationSpecifications" }
              > | null;
            }
          | { __typename: "NotificationPhraseTextNode"; text: string; styles: Array<NotificationPhrasePartStyle> }
        >;
      } | null;
      body?: Array<{
        __typename?: "NotificationPhrase";
        items: Array<
          | {
              __typename: "NotificationPhraseLinkNode";
              target?: NotificationLinkTarget | null;
              id?: string | null;
              text: string;
              styles: Array<NotificationPhrasePartStyle>;
              uri?:
                | { __typename: "GeoURI"; value: string }
                | { __typename: "HttpURI"; value: string }
                | { __typename: "MailToURI"; value: string }
                | { __typename: "MobileAppURI"; value: string }
                | { __typename: "RelativeURI"; value: string }
                | { __typename: "SelfReferencedURI"; value: string }
                | { __typename: "TelURI"; value: string }
                | { __typename: "Uri"; value: string }
                | null;
              actions?: Array<
                | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
                | { __typename: "NotificationAnalyticsEgClickstream" }
                | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
                | { __typename: "NotificationMarketing" }
                | { __typename: "NotificationSchema" }
                | { __typename: "NotificationSpecifications" }
              > | null;
              icon?: { __typename?: "Icon"; id: string; description: string } | null;
            }
          | { __typename: "NotificationPhraseTextNode"; text: string; styles: Array<NotificationPhrasePartStyle> }
        >;
      }> | null;
      containerLink?: {
        __typename?: "NotificationContainerLink";
        target?: NotificationLinkTarget | null;
        uri?:
          | { __typename: "GeoURI"; value: string }
          | { __typename: "HttpURI"; value: string }
          | { __typename: "MailToURI"; value: string }
          | { __typename: "MobileAppURI"; value: string }
          | { __typename: "RelativeURI"; value: string }
          | { __typename: "SelfReferencedURI"; value: string }
          | { __typename: "TelURI"; value: string }
          | { __typename: "Uri"; value: string }
          | null;
        actions?: Array<
          | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
          | { __typename: "NotificationAnalyticsEgClickstream" }
          | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
          | { __typename: "NotificationMarketing" }
          | { __typename: "NotificationSchema" }
          | { __typename: "NotificationSpecifications" }
        > | null;
      } | null;
      logo?: { __typename?: "Image"; description: string; url: any } | null;
      links?: Array<{
        __typename?: "NotificationPhraseLinkNode";
        id?: string | null;
        text: string;
        uri?:
          | { __typename: "GeoURI"; value: string }
          | { __typename: "HttpURI"; value: string }
          | { __typename: "MailToURI"; value: string }
          | { __typename: "MobileAppURI"; value: string }
          | { __typename: "RelativeURI"; value: string }
          | { __typename: "SelfReferencedURI"; value: string }
          | { __typename: "TelURI"; value: string }
          | { __typename: "Uri"; value: string }
          | null;
        icon?: { __typename?: "Icon"; id: string; description: string } | null;
        actions?: Array<
          | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
          | { __typename: "NotificationAnalyticsEgClickstream" }
          | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
          | { __typename: "NotificationMarketing" }
          | { __typename: "NotificationSchema" }
          | { __typename: "NotificationSpecifications" }
        > | null;
      }> | null;
      dismiss?: {
        __typename?: "NotificationDismiss";
        icon: { __typename?: "Icon"; id: string; description: string };
        actions?: Array<
          | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
          | { __typename: "NotificationAnalyticsEgClickstream" }
          | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
          | { __typename: "NotificationMarketing" }
          | { __typename: "NotificationSchema" }
          | { __typename: "NotificationSpecifications" }
        > | null;
      } | null;
      revealActions?: Array<
        | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
        | { __typename: "NotificationAnalyticsEgClickstream" }
        | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
        | { __typename: "NotificationMarketing" }
        | { __typename: "NotificationSchema" }
        | { __typename: "NotificationSpecifications" }
      > | null;
      backgroundImage?: { __typename?: "Image"; url: any } | null;
    };
  };
};

export type CampaignByIdQueryQueryVariables = Exact<{
  productType?: InputMaybe<Array<CampaignProductType> | CampaignProductType>;
  context: ContextInput;
  userLocation?: InputMaybe<UserLocationInput>;
  referrer?: InputMaybe<Scalars["String"]>;
  enableTripAttach?: InputMaybe<Scalars["Boolean"]>;
  displayPropertyCount?: InputMaybe<Scalars["Boolean"]>;
  campaignId: Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  pageNumber: Scalars["Int"];
  enableLivePricing?: InputMaybe<Scalars["Boolean"]>;
  returnPath?: InputMaybe<Scalars["String"]>;
  destinationId?: InputMaybe<Scalars["String"]>;
}>;

export type CampaignByIdQueryQuery = {
  __typename?: "Query";
  campaignRecommendations?: {
    __typename?: "RecommendedCampaigns";
    responseId: any;
    campaigns: Array<{
      __typename?: "CampaignDetail";
      headline: string;
      fromOriginHeaderText?: string | null;
      termsAndConditions: string;
      productTypes: Array<string>;
      recommendedType: RecommendedType;
      campaignId: string;
      campaignContentPaginationInfo?: {
        __typename?: "CampaignContentPaginationInfo";
        hasMoreContentPages: boolean;
      } | null;
      description: { __typename?: "CampaignDescriptions"; short?: string | null; long?: string | null };
      offers?: Array<
        | {
            __typename: "ActivityOfferItem";
            title?: string | null;
            description?: string | null;
            duration?: string | null;
            durationLabel?: string | null;
            city: string;
            province?: string | null;
            dates?: string | null;
            freeCancellationMessage?: string | null;
            name: string;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            image: { __typename?: "Image"; description: string; url: any };
            activityOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string; qualifierText: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
          }
        | {
            __typename: "DestinationOfferItem";
            affinities?: Array<string> | null;
            propertyCount?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: { __typename?: "OfferPrice"; value: string; qualifierText: string; disclaimerText: string };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "FlightOfferItem";
            airlineName?: string | null;
            originCity?: string | null;
            originAirportCode?: string | null;
            destinationCity?: string | null;
            destinationAirportCode?: string | null;
            tripType?: string | null;
            flightClass?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: { __typename?: "OfferPrice"; value: string; qualifierText: string; prefixText?: string | null };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "HotelOfferItem";
            location?: string | null;
            starRating?: number | null;
            guestReviewRating?: string | null;
            guestReviewRatingQualifierText?: string | null;
            guestReviewCount?: string | null;
            vipMessage?: string | null;
            freeCancellationMessage?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            discountBadge?: {
              __typename?: "CampaignOfferBadge";
              text?: string | null;
              type: CampaignOfferBadgeType;
            } | null;
            mod?: {
              __typename?: "MODItem";
              modTagText?: string | null;
              modAvailabilityText?: string | null;
              modSignInText?: string | null;
              signInUrl?: { __typename?: "HttpURI"; value: string; relativePath: string } | null;
            } | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PackageOfferItem";
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            flightOfferItem?: {
              __typename?: "FlightOfferItem";
              airlineName?: string | null;
              originCity?: string | null;
              originAirportCode?: string | null;
              destinationCity?: string | null;
              destinationAirportCode?: string | null;
              airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            } | null;
            hotelOfferItem?: {
              __typename?: "HotelOfferItem";
              name: string;
              location?: string | null;
              vipMessage?: string | null;
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PropertyOfferItem";
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
      > | null;
    }>;
    paginationInfo: { __typename?: "CampaignPagination"; referrer: string };
  } | null;
};

export type CampaignRecommendationsWithOffersQueryQueryVariables = Exact<{
  numCampaigns: Scalars["Int"];
  pageNumberOfCampaigns: Scalars["Int"];
  maxNumberOfCampaigns?: InputMaybe<Scalars["Int"]>;
  productType?: InputMaybe<Array<CampaignProductType> | CampaignProductType>;
  recommendationType?: InputMaybe<Array<InputMaybe<RecommendedType>> | InputMaybe<RecommendedType>>;
  context: ContextInput;
  userLocation?: InputMaybe<UserLocationInput>;
  referrer?: InputMaybe<Scalars["String"]>;
  userTripState?: InputMaybe<Scalars["String"]>;
  enableLivePricing?: InputMaybe<Scalars["Boolean"]>;
  returnPath?: InputMaybe<Scalars["String"]>;
}>;

export type CampaignRecommendationsWithOffersQueryQuery = {
  __typename?: "Query";
  campaignRecommendations?: {
    __typename?: "RecommendedCampaigns";
    responseId: any;
    campaigns: Array<{
      __typename?: "CampaignDetail";
      headline: string;
      fromOriginHeaderText?: string | null;
      termsAndConditions: string;
      productTypes: Array<string>;
      recommendedType: RecommendedType;
      campaignId: string;
      description: { __typename?: "CampaignDescriptions"; short?: string | null; long?: string | null };
      offers?: Array<
        | {
            __typename: "ActivityOfferItem";
            title?: string | null;
            description?: string | null;
            duration?: string | null;
            durationLabel?: string | null;
            city: string;
            province?: string | null;
            dates?: string | null;
            freeCancellationMessage?: string | null;
            name: string;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            image: { __typename?: "Image"; description: string; url: any };
            activityOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string; qualifierText: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
          }
        | {
            __typename: "DestinationOfferItem";
            affinities?: Array<string> | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "FlightOfferItem";
            airlineName?: string | null;
            originCity?: string | null;
            originAirportCode?: string | null;
            destinationCity?: string | null;
            destinationAirportCode?: string | null;
            tripType?: string | null;
            flightClass?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: { __typename?: "OfferPrice"; value: string; qualifierText: string; prefixText?: string | null };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "HotelOfferItem";
            location?: string | null;
            starRating?: number | null;
            guestReviewRating?: string | null;
            guestReviewRatingQualifierText?: string | null;
            guestReviewCount?: string | null;
            vipMessage?: string | null;
            freeCancellationMessage?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            discountBadge?: {
              __typename?: "CampaignOfferBadge";
              text?: string | null;
              type: CampaignOfferBadgeType;
            } | null;
            mod?: {
              __typename?: "MODItem";
              modTagText?: string | null;
              modAvailabilityText?: string | null;
              modSignInText?: string | null;
              signInUrl?: { __typename?: "HttpURI"; value: string; relativePath: string } | null;
            } | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PackageOfferItem";
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            flightOfferItem?: {
              __typename?: "FlightOfferItem";
              airlineName?: string | null;
              originCity?: string | null;
              originAirportCode?: string | null;
              destinationCity?: string | null;
              destinationAirportCode?: string | null;
              airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            } | null;
            hotelOfferItem?: {
              __typename?: "HotelOfferItem";
              name: string;
              location?: string | null;
              vipMessage?: string | null;
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PropertyOfferItem";
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
      > | null;
    }>;
    paginationInfo: { __typename?: "CampaignPagination"; referrer: string; hasNextPage: boolean };
  } | null;
};

export type CustomerPropertySearchByIdQueryQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type CustomerPropertySearchByIdQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      name: string;
      id: string;
      infositeURL: string;
      price?: {
        __typename?: "PropertyPrice";
        options?: Array<{
          __typename?: "PropertyPriceOption";
          displayPrice?: {
            __typename?: "Money";
            formatted: string;
            amount: number;
            currencyInfo?: { __typename?: "Currency"; symbol: string; code: string; name: string } | null;
          } | null;
        }> | null;
      } | null;
      image?: { __typename?: "Image"; url: any } | null;
    }>;
    searchCriteria: {
      __typename?: "SearchCriteria";
      dateRange: {
        __typename?: "PropertyDateRange";
        checkInDate: { __typename?: "Date"; isoFormat: string; day: number; month: number; year: number };
        checkOutDate: { __typename?: "Date"; isoFormat: string; day: number; month: number; year: number };
      };
    };
  };
};

export type CustomerSearchHistoryAllQueryQueryVariables = Exact<{
  context: ContextInput;
  lobs?: InputMaybe<Array<HistoryType> | HistoryType>;
  numberOfResults?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomerSearchHistoryAllQueryQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "Customer";
    searchHistory?: Array<{
      __typename?: "History";
      type?: HistoryType | null;
      items?: Array<{
        __typename?: "HistoryItem";
        id?: string | null;
        localizedDateRange?: string | null;
        localizedAsOfDateTime?: string | null;
        lastViewedDate: {
          __typename?: "ZonedDateTime";
          day: number;
          hour: number;
          minute: number;
          second: number;
          month: number;
          year: number;
        };
        startDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        endDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        originalSearchPrice?: {
          __typename?: "Money";
          amount: number;
          formatted: string;
          currencyInfo?: { __typename?: "Currency"; code: string; name: string; symbol: string } | null;
        } | null;
        productInfo?:
          | {
              __typename?: "HistoryActivityDetail";
              name?: string | null;
              duration?: string | null;
              leadTicketType?: string | null;
              searchUrl?: string | null;
              imageUrl?: { __typename?: "Image"; description: string; url: any } | null;
            }
          | {
              __typename?: "HistoryActivitySearch";
              searchUrl?: string | null;
              regionDetail?: { __typename?: "GeographicFeature"; name: string } | null;
            }
          | { __typename?: "HistoryCarDetail" }
          | { __typename?: "HistoryCarSearch" }
          | { __typename?: "HistoryCruiseSearch" }
          | {
              __typename?: "HistoryFlightDetail";
              localizedFlightName?: string | null;
              searchUrl?: string | null;
              routeType?: string | null;
              flightPassengers?: {
                __typename?: "FlightPassengers";
                adults?: number | null;
                agesOfChildren?: Array<number> | null;
              } | null;
            }
          | {
              __typename?: "HistoryFlightSearch";
              localizedFlightName?: string | null;
              searchUrl?: string | null;
              routeType?: string | null;
              flightPassengers?: {
                __typename?: "FlightPassengers";
                adults?: number | null;
                agesOfChildren?: Array<number> | null;
              } | null;
            }
          | {
              __typename?: "HistoryPackageSearch";
              localizedPackageName?: string | null;
              localizedRegionName?: string | null;
              localizedPackagesTemplate?: string | null;
              searchUrl?: string | null;
            }
          | {
              __typename?: "HistoryPropertyDetail";
              regionId?: string | null;
              formattedRating: string;
              formattedReviewCount: string;
              propertyId?: string | null;
              searchUrl?: string | null;
              summary?: { __typename?: "PropertySummary"; id: string; name: string } | null;
              regionDetail?: { __typename?: "GeographicFeature"; name: string } | null;
              reviewInfo?: {
                __typename?: "PropertyReviews";
                summary: {
                  __typename?: "PropertyReviewSummary";
                  averageOverallRating: { __typename?: "FormattedNumber"; formatted: string };
                  totalCount: { __typename?: "FormattedNumber"; formatted: string };
                };
              } | null;
              gallery?: {
                __typename?: "PropertyGallery";
                featured?: { __typename?: "Image"; description: string; url: any } | null;
              } | null;
            }
          | {
              __typename?: "HistoryPropertySearch";
              localizedRoomsTemplate?: string | null;
              searchUrl?: string | null;
              regionDetail?: { __typename?: "GeographicFeature"; name: string } | null;
            }
          | null;
      } | null> | null;
    }> | null;
  };
};

export type CustomerSearchHistoryQueryQueryVariables = Exact<{
  context: ContextInput;
  lobs?: InputMaybe<Array<HistoryType> | HistoryType>;
  numberOfResults?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomerSearchHistoryQueryQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "Customer";
    searchHistory?: Array<{
      __typename?: "History";
      type?: HistoryType | null;
      items?: Array<{
        __typename?: "HistoryItem";
        startDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        endDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        originalSearchPrice?: {
          __typename?: "Money";
          amount: number;
          formatted: string;
          currencyInfo?: { __typename?: "Currency"; code: string; name: string; symbol: string } | null;
        } | null;
        productInfo?:
          | { __typename?: "HistoryActivityDetail" }
          | { __typename?: "HistoryActivitySearch" }
          | { __typename?: "HistoryCarDetail" }
          | { __typename?: "HistoryCarSearch" }
          | { __typename?: "HistoryCruiseSearch" }
          | { __typename?: "HistoryFlightDetail" }
          | { __typename?: "HistoryFlightSearch" }
          | { __typename?: "HistoryPackageSearch" }
          | {
              __typename?: "HistoryPropertyDetail";
              regionId?: string | null;
              propertyId?: string | null;
              propertySummary?: { __typename?: "PropertySummary"; id: string; name: string } | null;
              propertyImageGallery?: {
                __typename?: "PropertyImageGallery";
                featured?: { __typename?: "PropertyImage"; image: { __typename?: "Image"; url: any } } | null;
              } | null;
            }
          | { __typename?: "HistoryPropertySearch" }
          | null;
      } | null> | null;
    }> | null;
  };
};

export type DxPropertySearchQueryQueryVariables = Exact<{
  context: ContextInput;
  hotelIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type DxPropertySearchQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      id: string;
      name: string;
      infositeURL: string;
      price?: {
        __typename?: "PropertyPrice";
        lead?: { __typename?: "Money"; formatted: string; amount: number } | null;
      } | null;
      reviews: { __typename?: "PropertyReviewsSummary"; score?: number | null; total?: number | null };
      gallery?: Array<{ __typename?: "Image"; url: any; description: string }> | null;
    }>;
  };
};

export type MapPropertySearchByLatLongQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type MapPropertySearchByLatLongQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      name: string;
      id: string;
      infositeURL: string;
      star?: number | null;
      price?: {
        __typename?: "PropertyPrice";
        lead?: { __typename?: "Money"; formatted: string; amount: number } | null;
      } | null;
      reviews: {
        __typename?: "PropertyReviewsSummary";
        score?: number | null;
        total?: number | null;
        localizedSubtitle?: string | null;
      };
      image?: { __typename?: "Image"; url: any } | null;
      mapMarker?: {
        __typename?: "MapMarker";
        latLong: { __typename?: "Coordinates"; latitude: number; longitude: number };
      } | null;
    }>;
    summary: {
      __typename?: "PropertyResultsSummary";
      messages?: Array<{
        __typename?: "MessageResult";
        type?: MessageType | null;
        title?: { __typename?: "MessagingResultTitle"; text?: string | null } | null;
        subtitle?: { __typename?: "MessagingResultTitle"; text?: string | null } | null;
      }> | null;
    };
  };
};

export type MerchCouponsQueryQueryVariables = Exact<{
  context: ContextInput;
  couponIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type MerchCouponsQueryQuery = {
  __typename?: "Query";
  merchCoupons?: {
    __typename?: "MerchCoupons";
    coupons: Array<{
      __typename?: "CouponDetail";
      id: string;
      name: string;
      statusMessage: string;
      termsAndConditions: {
        __typename?: "CouponTermsAndConditions";
        text: string;
        informationUrl: { __typename?: "HttpURI"; value: string };
      };
    }>;
  } | null;
};

export type CouponDetailFragmentFragment = {
  __typename?: "CouponDetail";
  id: string;
  name: string;
  statusMessage: string;
  termsAndConditions: {
    __typename?: "CouponTermsAndConditions";
    text: string;
    informationUrl: { __typename?: "HttpURI"; value: string };
  };
};

export type MojoQueryQueryVariables = Exact<{
  context: ContextInput;
  mojoContext: MojoContextInput;
}>;

export type MojoQueryQuery = {
  __typename?: "Query";
  mojoSection?: { __typename?: "MojoSection"; data: string } | null;
};

export type PrefetchSearchResultsMutationVariables = Exact<{
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
}>;

export type PrefetchSearchResultsMutation = {
  __typename?: "Mutation";
  preloadPropertySearch: {
    __typename?: "PropertySearchPreloadResults";
    criteria: {
      __typename?: "PropertySearchCriteria";
      primary: {
        __typename?: "PrimaryPropertyCriteria";
        destination: { __typename?: "Destination"; regionId?: string | null; regionName?: string | null };
      };
    };
  };
};

export type PropertyFilterSearchQueryQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type PropertyFilterSearchQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    sortAndFilter: {
      __typename?: "SortAndFilter";
      selected?: {
        __typename?: "SortAndFilterSelected";
        priceFilterPills?: {
          __typename?: "PriceFilterPills";
          aggregate?: { __typename?: "PriceFilterPillsAggregate"; label?: string | null } | null;
        } | null;
      } | null;
    };
    propertySearchListings: Array<
      | { __typename?: "EGDSStandardMessagingCard" }
      | { __typename?: "FlexibleDateSearchCard" }
      | { __typename?: "LodgingCard" }
      | { __typename?: "LodgingHeading" }
      | { __typename?: "LodgingIntentCard" }
      | { __typename?: "LodgingListingDivider" }
      | { __typename?: "LodgingSurveyTrigger" }
      | {
          __typename: "MessageResult";
          index?: number | null;
          type?: MessageType | null;
          title?: {
            __typename?: "MessagingResultTitle";
            text?: string | null;
            icon?: { __typename?: "Icon"; description: string; id: string } | null;
          } | null;
          subtitle?: { __typename?: "MessagingResultTitle"; text?: string | null } | null;
        }
      | { __typename?: "NestedListingContainer" }
      | {
          __typename: "Property";
          id: string;
          name: string;
          addressMessaging?: string | null;
          star?: number | null;
          structureType: StructureType;
          offerSummary: {
            __typename?: "OfferSummary";
            messages?: Array<{ __typename?: "OfferSummaryMessage"; type?: string | null }> | null;
          };
          propertyImage?: { __typename?: "PropertyImage"; image: { __typename?: "Image"; url: any } } | null;
          destinationInfo: {
            __typename?: "PropertyDestinationInfo";
            distanceFromMessaging?: string | null;
            distanceFromDestination?: { __typename?: "Distance"; unit: DistanceUnit; value: number } | null;
          };
          mapMarker?: {
            __typename?: "MapMarker";
            latLong: { __typename?: "Coordinates"; latitude: number; longitude: number };
          } | null;
          reviews: { __typename?: "PropertyReviewsSummary"; score?: number | null; total?: number | null };
          price?: {
            __typename?: "PropertyPrice";
            priceMessages?: Array<{ __typename?: "LodgingPlainMessage"; value: string }> | null;
            options?: Array<{
              __typename?: "PropertyPriceOption";
              displayPrice?: {
                __typename?: "Money";
                amount: number;
                formatted: string;
                currencyInfo?: { __typename?: "Currency"; code: string } | null;
              } | null;
            }> | null;
          } | null;
          propertyDetailsLink?: {
            __typename?: "LodgingLink";
            uri: { __typename?: "HttpURI"; relativePath: string; value: string };
          } | null;
        }
      | { __typename?: "PropertyListingsIconTextExpando" }
      | { __typename?: "PropertySearchListingPlaceholder" }
      | { __typename?: "PropertySearchListingsOverfilteredCard" }
      | { __typename?: "PropertySearchListingsSoldOutCard" }
      | { __typename?: "RecentlyViewedPropertiesCarouselWrapper" }
      | { __typename?: "ShoppingListingContainer" }
      | { __typename?: "SponsoredContentPlacement" }
      | { __typename?: "StaticLodgingMultiItemCrossSell" }
    >;
  };
};

export type PropertyFiltersQueryQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type PropertyFiltersQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    sortAndFilter: {
      __typename?: "SortAndFilter";
      options?: {
        __typename?: "SortAndFilterViewModel";
        sortAndFilter: Array<{
          __typename?: "FilterViewModel";
          label?: string | null;
          subLabel?: string | null;
          name: string;
          max?: number | null;
          min?: number | null;
          step?: number | null;
          options: Array<{
            __typename?: "OptionViewModel";
            label?: string | null;
            optionValue?: string | null;
            filterCategory?: string | null;
            description?: string | null;
            selectedLabel?: string | null;
            isSelected: boolean;
            range?: { __typename?: "FilterOptionRange"; max?: number | null; min?: number | null } | null;
          }>;
        }>;
      } | null;
    };
  };
};

export type QueryPropertyHeroImageQueryVariables = Exact<{
  context: ContextInput;
  propertyId: Scalars["String"];
}>;

export type QueryPropertyHeroImageQuery = {
  __typename?: "Query";
  propertyInfo: {
    __typename?: "PropertyInfo";
    id: string;
    gallery?: {
      __typename?: "PropertyGallery";
      featured?: { __typename?: "Image"; description: string; url: any } | null;
    } | null;
  };
};

export type QueryPropertyReviewsHeadlineQueryVariables = Exact<{
  context: ContextInput;
  propertyId: Scalars["String"];
}>;

export type QueryPropertyReviewsHeadlineQuery = {
  __typename?: "Query";
  propertyInfo: {
    __typename?: "PropertyInfo";
    id: string;
    infositeUrl?: any | null;
    summary?: {
      __typename?: "PropertySummary";
      id: string;
      name: string;
      overview?: { __typename?: "PropertyOverview"; starRating?: number | null } | null;
      location?: {
        __typename?: "PropertyLocation";
        address?: {
          __typename?: "PropertyAddress";
          city?: string | null;
          countryCode?: string | null;
          firstAddressLine?: string | null;
          secondAddressLine?: string | null;
          postalCode?: string | null;
          province?: string | null;
        } | null;
      } | null;
    } | null;
  };
};

export type PropertySearchQueryQueryVariables = Exact<{
  context: ContextInput;
  hotelIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type PropertySearchQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      id: string;
      name: string;
      gallery?: Array<{ __typename?: "Image"; url: any; description: string }> | null;
    }>;
  };
};

export type TravelAgentSearchWizardConfigQueryQueryVariables = Exact<{
  context: ContextInput;
}>;

export type TravelAgentSearchWizardConfigQueryQuery = {
  __typename?: "Query";
  travelAgentSearchWizardConfig?: {
    __typename?: "TravelAgentSearchWizardConfig";
    packageRatesEnabled: boolean;
    enabledLobs: Array<string>;
    packageRatesDetail?: {
      __typename?: "TravelAgentPackageRatesDetail";
      heading?: string | null;
      termsAndConditions: string;
    } | null;
  } | null;
};

export const CouponDetailFragmentFragmentDoc = ({
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CouponDetailFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CouponDetail" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "statusMessage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "termsAndConditions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "informationUrl" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CouponDetailFragmentFragment, unknown>;
export const BlossomNotificationDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BlossomNotification" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "notificationLocation" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "NotificationLocationOnPage" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lineOfBusiness" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LineOfBusinessDomain" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "funnelLocation" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "PageLocation" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "notification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inlineNotification" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "lineOfBusiness" },
                      value: { kind: "Variable", name: { kind: "Name", value: "lineOfBusiness" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "notificationLocation" },
                      value: { kind: "Variable", name: { kind: "Name", value: "notificationLocation" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageLocation" },
                      value: { kind: "Variable", name: { kind: "Name", value: "funnelLocation" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "notificationLocation" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "title" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "styles" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationPhraseLinkNode" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uri" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actions" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationAnalytics" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "description" } },
                                                    { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationCookie" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "name" } },
                                                    { kind: "Field", name: { kind: "Name", value: "value" } },
                                                    { kind: "Field", name: { kind: "Name", value: "expires" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "body" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "styles" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationPhraseLinkNode" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uri" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actions" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationAnalytics" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "description" } },
                                                    { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationCookie" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "name" } },
                                                    { kind: "Field", name: { kind: "Name", value: "value" } },
                                                    { kind: "Field", name: { kind: "Name", value: "expires" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "icon" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "containerLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "target" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationAnalytics" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationCookie" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                        { kind: "Field", name: { kind: "Name", value: "expires" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "links" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationAnalytics" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationCookie" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                        { kind: "Field", name: { kind: "Name", value: "expires" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dismiss" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationAnalytics" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationCookie" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                        { kind: "Field", name: { kind: "Name", value: "expires" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "revealActions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "NotificationAnalytics" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotificationCookie" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "expires" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "backgroundImage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<BlossomNotificationQuery, BlossomNotificationQueryVariables>;
export const CampaignByIdQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CampaignByIdQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productType" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "CampaignProductType" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UserLocationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "enableTripAttach" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "displayPropertyCount" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "campaignId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageNumber" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destinationId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "campaignRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productType" },
                value: { kind: "Variable", name: { kind: "Name", value: "productType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userLocation" },
                value: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "referrer" },
                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "enableTripAttach" },
                value: { kind: "Variable", name: { kind: "Name", value: "enableTripAttach" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "campaignParameters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "campaignId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "campaignId" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contentPagination" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "pageSize" },
                            value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "pageNumber" },
                            value: { kind: "Variable", name: { kind: "Name", value: "pageNumber" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "displayPropertyCount" },
                value: { kind: "Variable", name: { kind: "Name", value: "displayPropertyCount" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "includeDeals" },
                value: { kind: "BooleanValue", value: true },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "enableLivePricing" },
                value: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destinationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "destinationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaigns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "campaignId" },
                        name: { kind: "Name", value: "id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "headline" } },
                      { kind: "Field", name: { kind: "Name", value: "fromOriginHeaderText" } },
                      { kind: "Field", name: { kind: "Name", value: "termsAndConditions" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "campaignContentPaginationInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "hasMoreContentPages" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "short" } },
                            { kind: "Field", name: { kind: "Name", value: "long" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "productTypes" } },
                      { kind: "Field", name: { kind: "Name", value: "recommendedType" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "secondaryContent" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DestinationOfferItem" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "affinities" } },
                                  { kind: "Field", name: { kind: "Name", value: "propertyCount" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "HotelOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "location" } },
                                  { kind: "Field", name: { kind: "Name", value: "starRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRatingQualifierText" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewCount" } },
                                  { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "discountBadge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mod" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "modTagText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modAvailabilityText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modSignInText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "signInUrl" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "returnPath" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "relativePath" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FlightOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "airlineImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "tripType" } },
                                  { kind: "Field", name: { kind: "Name", value: "flightClass" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PackageOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "airlineImage" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hotelOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "location" } },
                                        { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ActivityOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "duration" } },
                                  { kind: "Field", name: { kind: "Name", value: "durationLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "province" } },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "responseId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "referrer" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CampaignByIdQueryQuery, CampaignByIdQueryQueryVariables>;
export const CampaignRecommendationsWithOffersQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CampaignRecommendationsWithOffersQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numCampaigns" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageNumberOfCampaigns" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "maxNumberOfCampaigns" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productType" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "CampaignProductType" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "recommendationType" } },
          type: { kind: "ListType", type: { kind: "NamedType", name: { kind: "Name", value: "RecommendedType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UserLocationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userTripState" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "campaignRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "numberOfCampaigns" },
                value: { kind: "Variable", name: { kind: "Name", value: "numCampaigns" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageNumber" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageNumberOfCampaigns" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxNumberOfCampaigns" },
                value: { kind: "Variable", name: { kind: "Name", value: "maxNumberOfCampaigns" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productType" },
                value: { kind: "Variable", name: { kind: "Name", value: "productType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "recommendationType" },
                value: { kind: "Variable", name: { kind: "Name", value: "recommendationType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userLocation" },
                value: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "referrer" },
                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "includeDeals" },
                value: { kind: "BooleanValue", value: true },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userTripState" },
                value: { kind: "Variable", name: { kind: "Name", value: "userTripState" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "enableLivePricing" },
                value: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaigns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "campaignId" },
                        name: { kind: "Name", value: "id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "headline" } },
                      { kind: "Field", name: { kind: "Name", value: "fromOriginHeaderText" } },
                      { kind: "Field", name: { kind: "Name", value: "termsAndConditions" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "short" } },
                            { kind: "Field", name: { kind: "Name", value: "long" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "productTypes" } },
                      { kind: "Field", name: { kind: "Name", value: "recommendedType" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "secondaryContent" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DestinationOfferItem" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "affinities" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "HotelOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "location" } },
                                  { kind: "Field", name: { kind: "Name", value: "starRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRatingQualifierText" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewCount" } },
                                  { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "discountBadge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mod" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "modTagText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modAvailabilityText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modSignInText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "signInUrl" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "returnPath" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "relativePath" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FlightOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "airlineImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "tripType" } },
                                  { kind: "Field", name: { kind: "Name", value: "flightClass" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PackageOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "airlineImage" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hotelOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "location" } },
                                        { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ActivityOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "duration" } },
                                  { kind: "Field", name: { kind: "Name", value: "durationLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "province" } },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "responseId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "referrer" } },
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CampaignRecommendationsWithOffersQueryQuery,
  CampaignRecommendationsWithOffersQueryQueryVariables
>;
export const CustomerPropertySearchByIdQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomerPropertySearchByIdQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "infositeURL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "displayPrice" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                        { kind: "Field", name: { kind: "Name", value: "amount" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "currencyInfo" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                              { kind: "Field", name: { kind: "Name", value: "code" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchCriteria" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateRange" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "checkInDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "isoFormat" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "checkOutDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "isoFormat" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerPropertySearchByIdQueryQuery, CustomerPropertySearchByIdQueryQueryVariables>;
export const CustomerSearchHistoryAllQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomerSearchHistoryAllQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "HistoryType" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "customer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchHistory" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "types" },
                      value: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "numberOfResults" },
                      value: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "localizedDateRange" } },
                            { kind: "Field", name: { kind: "Name", value: "localizedAsOfDateTime" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastViewedDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                  { kind: "Field", name: { kind: "Name", value: "hour" } },
                                  { kind: "Field", name: { kind: "Name", value: "minute" } },
                                  { kind: "Field", name: { kind: "Name", value: "second" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalSearchPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currencyInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "code" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryActivitySearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "regionDetail" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryActivityDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "duration" } },
                                        { kind: "Field", name: { kind: "Name", value: "leadTicketType" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "imageUrl" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryFlightSearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "localizedFlightName" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                        { kind: "Field", name: { kind: "Name", value: "routeType" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "flightPassengers" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "adults" } },
                                              { kind: "Field", name: { kind: "Name", value: "agesOfChildren" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryFlightDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "localizedFlightName" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                        { kind: "Field", name: { kind: "Name", value: "routeType" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "flightPassengers" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "adults" } },
                                              { kind: "Field", name: { kind: "Name", value: "agesOfChildren" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPackageSearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "localizedPackageName" } },
                                        { kind: "Field", name: { kind: "Name", value: "localizedRegionName" } },
                                        { kind: "Field", name: { kind: "Name", value: "localizedPackagesTemplate" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPropertySearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "regionDetail" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "localizedRoomsTemplate" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPropertyDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "regionId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "summary" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "regionDetail" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "reviewInfo" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "summary" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "averageOverallRating" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "totalCount" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "formattedRating" } },
                                        { kind: "Field", name: { kind: "Name", value: "formattedReviewCount" } },
                                        { kind: "Field", name: { kind: "Name", value: "propertyId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "gallery" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "featured" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "description" } },
                                                    { kind: "Field", name: { kind: "Name", value: "url" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerSearchHistoryAllQueryQuery, CustomerSearchHistoryAllQueryQueryVariables>;
export const CustomerSearchHistoryQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomerSearchHistoryQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "HistoryType" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "customer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchHistory" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "types" },
                      value: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "numberOfResults" },
                      value: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalSearchPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currencyInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "code" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPropertyDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "regionId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "propertySummary" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "context" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "context" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "propertyId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "propertyImageGallery" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "context" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "context" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "featured" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "image" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "url" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerSearchHistoryQueryQuery, CustomerSearchHistoryQueryQueryVariables>;
export const DxPropertySearchQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DxPropertySearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyIds" },
                      value: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "EnumValue", value: "REVIEW" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "infositeURL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lead" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                            { kind: "Field", name: { kind: "Name", value: "total" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gallery" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DxPropertySearchQueryQuery, DxPropertySearchQueryQueryVariables>;
export const MapPropertySearchByLatLongDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MapPropertySearchByLatLong" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "EnumValue", value: "DISTANCE" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "infositeURL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lead" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                            { kind: "Field", name: { kind: "Name", value: "total" } },
                            { kind: "Field", name: { kind: "Name", value: "localizedSubtitle" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "star" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mapMarker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latLong" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "latitude" } },
                                  { kind: "Field", name: { kind: "Name", value: "longitude" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "messages" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subtitle" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MapPropertySearchByLatLongQuery, MapPropertySearchByLatLongQueryVariables>;
export const MerchCouponsQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MerchCouponsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "couponIds" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "merchCoupons" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "couponIds" },
                value: { kind: "Variable", name: { kind: "Name", value: "couponIds" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coupons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CouponDetailFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CouponDetailFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<MerchCouponsQueryQuery, MerchCouponsQueryQueryVariables>;
export const MojoQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MojoQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "mojoContext" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MojoContextInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mojoSection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "mojoContext" },
                value: { kind: "Variable", name: { kind: "Name", value: "mojoContext" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "data" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MojoQueryQuery, MojoQueryQueryVariables>;
export const PrefetchSearchResultsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PrefetchSearchResults" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shoppingContext" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ShoppingContextInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "preloadPropertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shoppingContext" },
                value: { kind: "Variable", name: { kind: "Name", value: "shoppingContext" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "criteria" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "destination" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "regionId" } },
                                  { kind: "Field", name: { kind: "Name", value: "regionName" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PrefetchSearchResultsMutation, PrefetchSearchResultsMutationVariables>;
export const PropertyFilterSearchQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PropertyFilterSearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sortAndFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "selected" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "priceFilterPills" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "aggregate" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "label" } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "propertySearchListings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MessageResult" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subtitle" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "index" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Property" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "addressMessaging" } },
                            { kind: "Field", name: { kind: "Name", value: "star" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offerSummary" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "type" } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "propertyImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "destinationInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "distanceFromMessaging" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "distanceFromDestination" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "unit" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mapMarker" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latLong" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "latitude" } },
                                        { kind: "Field", name: { kind: "Name", value: "longitude" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviews" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "score" } },
                                  { kind: "Field", name: { kind: "Name", value: "total" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "price" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "priceMessages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "displayPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "amount" } },
                                              { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "currencyInfo" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "code" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "propertyDetailsLink" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "relativePath" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "structureType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PropertyFilterSearchQueryQuery, PropertyFilterSearchQueryQueryVariables>;
export const PropertyFiltersQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PropertyFiltersQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sortAndFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sortAndFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "label" } },
                                  { kind: "Field", name: { kind: "Name", value: "subLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "max" } },
                                  { kind: "Field", name: { kind: "Name", value: "min" } },
                                  { kind: "Field", name: { kind: "Name", value: "step" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "label" } },
                                        { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                                        { kind: "Field", name: { kind: "Name", value: "filterCategory" } },
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "selectedLabel" } },
                                        { kind: "Field", name: { kind: "Name", value: "isSelected" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "range" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "max" } },
                                              { kind: "Field", name: { kind: "Name", value: "min" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PropertyFiltersQueryQuery, PropertyFiltersQueryQueryVariables>;
export const QueryPropertyHeroImageDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QueryPropertyHeroImage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertyInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "propertyId" },
                value: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gallery" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featured" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<QueryPropertyHeroImageQuery, QueryPropertyHeroImageQueryVariables>;
export const QueryPropertyReviewsHeadlineDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QueryPropertyReviewsHeadline" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertyInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "propertyId" },
                value: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "infositeUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "overview" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "starRating" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstAddressLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "secondAddressLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "province" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<QueryPropertyReviewsHeadlineQuery, QueryPropertyReviewsHeadlineQueryVariables>;
export const PropertySearchQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PropertySearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyIds" },
                      value: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gallery" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PropertySearchQueryQuery, PropertySearchQueryQueryVariables>;
export const TravelAgentSearchWizardConfigQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TravelAgentSearchWizardConfigQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "travelAgentSearchWizardConfig" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "packageRatesEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "enabledLobs" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageRatesDetail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "heading" } },
                      { kind: "Field", name: { kind: "Name", value: "termsAndConditions" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  TravelAgentSearchWizardConfigQueryQuery,
  TravelAgentSearchWizardConfigQueryQueryVariables
>;
