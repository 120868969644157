import { createContext } from "react";

export interface MultiLOBPackageWizardContext {
  isInsideMultiLOBPackageWizard: boolean;
}

const defaultContext = {
  isInsideMultiLOBPackageWizard: false,
};

export const MultiLOBPackageWizardContext = createContext<MultiLOBPackageWizardContext>(defaultContext);
