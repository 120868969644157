import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";
import { FlightHiddenSearchDataProps } from "components/flexComponents/WizardFlightPWA/typings";
import { FlightType } from "stores/wizard/state/typings";
import { TravelersMetadata } from "components/shared/TravelersField/typings";

type QueryParams = {
  mode: string;
  leg1: string;
  leg2?: string;
  "flight-type": string;
  trip: string;
  options: string;
  passengers: string;
};

export type FlightsPredictiveSearchURLParams = {
  flightHiddenSearchDataProps: FlightHiddenSearchDataProps;
  travelersMetadata: TravelersMetadata;
  multiCityFlightLegURL?: string[];
};

export const getFlightsPredictiveSearchURL = (
  flightHiddenSearchDataProps: FlightHiddenSearchDataProps,
  travelersMetadata: TravelersMetadata,
  multiCityFlightLegURL?: string[]
): string => {
  const { flightType, flightAirline, mode, trip, leg1, leg2, flightClass, maxhops } = flightHiddenSearchDataProps;
  const { flightSearch } = travelersMetadata;
  const tripType = trip === FlightType.MULTI_CITY ? "multi" : trip;
  const flightOptions = flightAirline ? `${flightClass},carrier:${flightAirline.value}` : `${flightClass}`;
  const queryParams: QueryParams = {
    mode,
    leg1,
    leg2,
    "flight-type": flightType,
    trip: tripType,
    options: `${flightOptions}${maxhops || ""}`,
    passengers: flightSearch,
  };

  const queryParamsKeys = Object.keys(queryParams);

  let flightsPredictiveSearchURL = queryParamsKeys.reduce((currentURL, currentQueryParamKey, currentIndex) => {
    if (
      typeof queryParams[currentQueryParamKey as keyof QueryParams] === "string" &&
      queryParams[currentQueryParamKey as keyof QueryParams]
    ) {
      currentURL += `${currentQueryParamKey}=${queryParams[currentQueryParamKey as keyof QueryParams]}`;
      if (currentIndex < queryParamsKeys.length - 1) {
        currentURL += "&";
      }
    }

    return currentURL;
  }, `${window.location.origin}/flights-predictive-search?`);

  if (multiCityFlightLegURL?.length && trip === FlightType.MULTI_CITY) {
    flightsPredictiveSearchURL = multiCityFlightLegURL.reduce(
      (currentURL, currentMultiCityFlightLegURL, currentIndex) => {
        if (typeof currentMultiCityFlightLegURL === "string" && currentMultiCityFlightLegURL) {
          currentURL += `leg${currentIndex + 2}=${currentMultiCityFlightLegURL}`;
          if (currentIndex < multiCityFlightLegURL.length - 1) {
            currentURL += "&";
          }
        }

        return currentURL;
      },
      `${flightsPredictiveSearchURL}&`
    );
  }

  return flightsPredictiveSearchURL;
};

export const checkIfCurrentLastLegDateUpdated = (
  subLOBState: FlightType,
  defaultDate: Date,
  currentDate: null | Date
): boolean => {
  if (!currentDate) {
    return false;
  }
  const defaultLastAdditionalLegDate = new Date(defaultDate);
  const currentLastAdditionalLegDate = new Date(currentDate);

  defaultLastAdditionalLegDate.setHours(0, 0, 0, 0);
  currentLastAdditionalLegDate.setHours(0, 0, 0, 0);

  return (
    subLOBState === FlightType.MULTI_CITY &&
    defaultLastAdditionalLegDate.getTime() !== currentLastAdditionalLegDate.getTime()
  );
};

export const triggerFlightsPredictiveSearchURL = async (
  flightsPredictiveSearchURLParams: FlightsPredictiveSearchURLParams
) => {
  const { flightHiddenSearchDataProps, travelersMetadata, multiCityFlightLegURL } = flightsPredictiveSearchURLParams;
  const logger = ClientLogger.getLoggerWithIdentifier("processFlightsPredictiveSearch");
  try {
    const flightsPredictiveSearchURL = getFlightsPredictiveSearchURL(
      flightHiddenSearchDataProps,
      travelersMetadata,
      multiCityFlightLegURL
    );

    if (flightsPredictiveSearchURL) {
      await fetch(flightsPredictiveSearchURL);
    }
  } catch (error) {
    logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, "ENVERROR"), error);
  }
};

export const triggerFlightsPredictiveSearchURLVariant = async (
  isFlightsPredictiveSearch: boolean,
  isDateChanged: boolean,
  queryParams: string
) => {
  if (isFlightsPredictiveSearch && isDateChanged) {
    const logger = ClientLogger.getLoggerWithIdentifier("processFlightsPredictiveSearchSharedUI");
    try {
      await fetch(`${window.location.origin}/flights-predictive-search${queryParams}`);
    } catch (error) {
      logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, "FLIGHT_PREDICTIVE_SEARCH_FETCH_ERROR"), error);
    }
  }
};
