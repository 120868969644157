import { createContext, useContext } from "react";

export interface MultiLOBWizardRegionContext {
  isMultiLOB: boolean;
}

const defaultContext = {
  isMultiLOB: false,
};

export const MultiLOBWizardRegionContext = createContext<MultiLOBWizardRegionContext>(defaultContext);
export const useMultiLOBWizardRegion = () => useContext(MultiLOBWizardRegionContext);
