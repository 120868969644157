import * as React from "react";
import {
  ExperienceTemplateRenderer,
  ExperienceTemplateRendererContextProvider,
} from "experience-template-renderer-react";
import { GridContainer } from "src/components/shared/GridContainer/GridContainer";
import { GridItemWrapper } from "src/components/shared/GridContainer/GridItemWrapper";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { blossomCodeSplitter } from "src/components/flexFramework/CodeSplitter/BlossomCodeSplitter";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { getExperienceTemplateComponentWrapper } from "src/components/flexFramework/ExperienceTemplateComponentWrapper";

export const RegionChildrenWithGridContainer: React.FC<{
  blossomComponent: BlossomComponent;
  templateComponent: ExtendedTemplateComponent;
}> = ({ blossomComponent, templateComponent }) => {
  const blossomComponentChildren = blossomComponent.getBlossomComponents();

  if (
    !blossomComponentChildren.length ||
    !templateComponent.children?.length ||
    blossomComponentChildren.length !== templateComponent.children?.length
  ) {
    return null;
  }

  return (
    <GridContainer templateComponent={templateComponent}>
      <ExperienceTemplateRendererContextProvider
        libraryKeyResolver={blossomCodeSplitter.experienceTemplateLibraryKeyResolver}
        library={blossomCodeSplitter.componentLibrary}
        wrapper={GridItemWrapper}
      >
        <ExperienceTemplateRenderer
          templateComponents={templateComponent.children}
          wrapper={getExperienceTemplateComponentWrapper(blossomComponent)}
        />
      </ExperienceTemplateRendererContextProvider>
    </GridContainer>
  );
};

export const RegionChildren: React.FC<{
  blossomComponent: BlossomComponent; // Tmp accept a blossomComponent while there are still regions that require it.
  templateComponent: ExtendedTemplateComponent;
}> = ({ blossomComponent, templateComponent }) => {
  const blossomComponentChildren = blossomComponent.getBlossomComponents();

  if (!blossomComponentChildren.length || !templateComponent.children?.length) {
    return null;
  }

  return (
    <ExperienceTemplateRendererContextProvider
      libraryKeyResolver={blossomCodeSplitter.experienceTemplateLibraryKeyResolver}
      library={blossomCodeSplitter.componentLibrary}
      wrapper={getExperienceTemplateComponentWrapper(blossomComponent)}
    >
      <ExperienceTemplateRenderer templateComponents={templateComponent.children} />
    </ExperienceTemplateRendererContextProvider>
  );
};

export const RegionChildrenList: React.FC<{
  blossomComponent: BlossomComponent; // Tmp accept a blossomComponent while there are still regions that require it.
  templateComponents: ExtendedTemplateComponent[];
}> = ({ blossomComponent, templateComponents }) => {
  return (
    <ExperienceTemplateRendererContextProvider
      libraryKeyResolver={blossomCodeSplitter.experienceTemplateLibraryKeyResolver}
      library={blossomCodeSplitter.componentLibrary}
      wrapper={getExperienceTemplateComponentWrapper(blossomComponent)}
    >
      <ExperienceTemplateRenderer templateComponents={templateComponents} />
    </ExperienceTemplateRendererContextProvider>
  );
};
