import * as React from "react";

import AmenitiesSection from "./AmenitiesSection";
import RecommendationSection from "./RecommendationSection";
import { CONTENT_TYPE } from "components/flexComponents/Hotels/views/HotelsViews/HotelUtils";

import { HotelsFlexModuleResult, Hotel } from "typings/microserviceModels/hotels-flex-module";

interface AmenitiesOrRecommendationProps {
  model: HotelsFlexModuleResult;
  hotel: Hotel;
  className?: string;
}

const AmenitiesOrRecommendation = ({ model, hotel, className = "" }: AmenitiesOrRecommendationProps) => {
  const { contentRecipe, showAmenitiesOrConfidenceMessage } = model;

  if (!showAmenitiesOrConfidenceMessage) {
    return null;
  }

  return contentRecipe === CONTENT_TYPE.REVIEW ? (
    <RecommendationSection recommendedPercent={hotel.recommendedPercent} className={className} />
  ) : (
    <AmenitiesSection hotel={hotel} className={className} />
  );
};

export default AmenitiesOrRecommendation;
