/* istanbul ignore file */
import { logger } from "bernie-core";
import * as React from "react";
import { LocalizedComponentProps, withLocalization } from "bernie-l10n";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Logger } from "bernie-logger";
import { BlossomComponent } from "components/flexFramework/domain/BlossomComponent";
import { BlossomComponentsFactory } from "components/flexFramework/domain/BlossomComponentsFactory";
import { FlexComponent } from "typings/flexFramework/FlexComponent";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { ErrorBoundary } from "components/errorBoundaries/errorBoundaries";

export interface BlossomComponentsProps extends LocalizedComponentProps {
  context: ExtendedContextStore;
  logger?: Logger;
  blossomComponent?: BlossomComponent; // if provided blossomComponet (singular), only the provide BlossomComponent's children will be rendere
  blossomComponents?: BlossomComponent[]; // if provided blossomComponents (plural), it returns the same result as if it were passed flexComponents
  flexComponents?: FlexComponent[];
}

export interface WrapperBlossomComponentsProps {
  blossomComponent?: BlossomComponent;
}

/**
 * React component that uses the Blossom Components Factory to render the desired components.
 * It knows how to handle things like a module definition, flex components, composition and a template.
 */
@logger("BlossomComponents")
@withLocalization
export class BlossomComponents extends React.Component<BlossomComponentsProps> {
  public render() {
    if (this.props.blossomComponent) {
      const childrenRender = this.props.blossomComponent.renderChildren(
        this.props.logger!,
        this.props.l10n,
        this.props.context
      );

      return childrenRender;
    }

    const keyHelper = new ItemKeyHelper("blossomComponent");

    return (this.props.blossomComponents || BlossomComponentsFactory.get(this.props)).map((component) => {
      const id = component.id || keyHelper.next();
      return (
        <ErrorBoundary key={id} id={id}>
          {component.render(id, this.props.context, this.props.l10n, this.props.logger!)}
        </ErrorBoundary>
      );
    });
  }
}
