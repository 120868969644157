import * as React from "react";

import { UitkText } from "uitk-react-text";

import FeaturedReview from "./FeaturedReview";
import { CONTENT_TYPE } from "components/flexComponents/Hotels/views/HotelsViews/HotelUtils";

import { HotelsFlexModuleResult, Hotel } from "typings/microserviceModels/hotels-flex-module";

interface DescriptionOrReviewProps {
  model: HotelsFlexModuleResult;
  hotel: Hotel;
  className?: string;
}

const DescriptionOrReview = ({ model, hotel, className = "" }: DescriptionOrReviewProps) => {
  const { contentRecipe } = model;

  return contentRecipe === CONTENT_TYPE.REVIEW ? (
    <FeaturedReview hotel={hotel} className={className} />
  ) : (
    <UitkText size={300} className={className}>
      {hotel.shortDescription}
    </UitkText>
  );
};

export default DescriptionOrReview;
