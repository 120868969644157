import { ILogger, SystemEvent, SystemEventLevel } from "@shared-ui/logger-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

const MOJO_TOKENS_NO_TOKENS = new SystemEvent(SystemEventLevel.ERROR, "MOJO_TOKENS_NO_TOKENS");
const MOJO_TOKENS_ERROR = new SystemEvent(SystemEventLevel.ERROR, "MOJO_TOKENS_ERROR");
const MOJO_TOKENS_USING_DEFAULT = new SystemEvent(SystemEventLevel.WARN, "MOJO_TOKENS_USING_DEFAULT");

const RE = new RegExp(`([^"]*__[^"]*)`, "g");

export const replaceTokens = async (data: string, context: ExtendedContextStore, logger: ILogger): Promise<string> => {
  const { EGDSTokens } = await import(/* webpackChunkName: "egds-tokens-runtime" */ "@egds/tokens-web");

  try {
    const brand: string = EGDSTokens.brands.find((b) => b === context.site.uiBrand)
      ? context.site.uiBrand!
      : EGDSTokens.brands.find((b) => b === context.site.brand)
      ? context.site.brand!
      : "";
    const tokens = new EGDSTokens({ brand });

    if (tokens) {
      return data.replace(RE, (match: string) =>
        match.includes("icon__") ? match : (tokens.getToken(match).value as string)
      );
    } else {
      logger.event?.(MOJO_TOKENS_NO_TOKENS, { uiBrand: context.site.uiBrand, brand: context.site.brand });
      return replaceTokensWithDefaultBrand(data, EGDSTokens, logger);
    }
  } catch (err) {
    logger.event?.(MOJO_TOKENS_ERROR, err);
    return replaceTokensWithDefaultBrand(data, EGDSTokens, logger);
  }
};

const replaceTokensWithDefaultBrand = (data: string, EGDSTokens: any, logger: ILogger) => {
  logger.event?.(MOJO_TOKENS_USING_DEFAULT);

  try {
    const tokens = new EGDSTokens({ brand: "default" });
    return data.replace(RE, (match: string) => tokens.getToken(match).value as string);
  } catch (err) {
    logger.event?.(MOJO_TOKENS_ERROR, err);
    return data;
  }
};
