import {
  Action,
  FlexTrackingInfo,
  sendDelayedTrackEvent,
  sendImmediateTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { AnalyticsStore } from "bernie-plugin-mobx";

export const ACTION_OPEN = "open";
export const ACTION_CLOSE = "close";
export const ACTION_DONE = "done";
export const ACTION_TOGGLE = "toggle";

export interface TrackingUtilsProps {
  trackingEnabled: boolean;
  moduleName?: string;
  analytics?: AnalyticsStore;
}

export class TrackingUtils {
  private props: TrackingUtilsProps;

  public constructor(props: TrackingUtilsProps) {
    this.props = props;
  }

  /**
   *
   * @param rfrr
   * @param module
   * @param action
   * @param isImmediate
   */
  public trackEvent(rfrr: string, module?: string, action = Action.CLICK, isImmediate = false): void {
    const moduleName = module || this.props.moduleName;

    if (this.props.trackingEnabled && this.props.analytics && moduleName) {
      const trackingInfo: FlexTrackingInfo = {
        moduleName,
        rfrr,
        action,
      };

      if (!isImmediate) {
        sendDelayedTrackEvent(trackingInfo, this.props.analytics);
      } else {
        sendImmediateTrackEvent(trackingInfo, this.props.analytics);
      }
    }
  }
}

export function getTracker(trackingEnabled = false, analytics?: AnalyticsStore, moduleName?: string) {
  return new TrackingUtils({
    trackingEnabled,
    moduleName,
    analytics,
  });
}

export default TrackingUtils;
