import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { EssClientLogger } from "typeahead-client-logger";
import { ClientLogger } from "bernie-client";

import { UitkSpacing, UitkSpacingProps } from "uitk-react-spacing";

import { SearchForm } from "@shared-ui/search-tools-lodging";
import { Viewport, ViewSmall, ViewExtraLarge } from "@shared-ui/viewport-context";

import { Destination, LodgingSearchFormProps } from "./typings";
import { useSignalPublisher, useSignalSubscriber } from "@shared-ui/signal-events-context";
import { PropertyOffersSignalNames } from "@shared-ui/lodging-property-offers";
import { PropertyBookBarSignalNames } from "../PropertyBookBar/utils/constant";
import { useRefContext } from "@shared-ui/ref-context";
import { useDestinationFieldExperiments } from "@shared-ui/retail-search-tools-product";
import { useLodgingSearchExperiment } from "../../utility/useLodgingSearchExperiment";
import { useOptimizeLodgingSearchLoadTimeExperiment } from "../../utility/useOptimizeLodgingSearchLoadTimeExperiment";
import { useLodgingSrpPrefetchAssetsExperiment } from "../../utility/useLodgingSrpPrefetchAssetsExperiment";
import { Field } from "uitk-react-form";
import { getFunnelLocation, getPageIdentity } from "src/utils/PageIdentityUtil";
import { useExperiment } from "@shared-ui/experiment-context";

/**
 * Wrapper for `@shared-ui/search-tools-lodging` SearchForm
 * Powered by https://flexmanager.prod.expedia.biz/app/legacy/moduleStore/show/378
 */
export const LodgingSearchForm = withStores(
  "context",
  "analytics",
  "page"
)(
  observer((props: LodgingSearchFormProps) => {
    const { templateComponent, analytics, context, page } = props;
    const { registerTarget } = useRefContext();
    /* istanbul ignore next */
    const { adapterFields, log: logDestinationFieldExperiments } = useDestinationFieldExperiments({
      brand: context?.site?.brand,
      deviceType: context?.deviceInformation?.type,
    });
    const { prefetchLodgingSrpAssets } = useLodgingSrpPrefetchAssetsExperiment();
    const { exposure: imageMosaicLargeScreensExposure } = useExperiment(
      "Adaptex_campaign_PDP_mosaic_for_large_screens"
    );
    const { exposure: imageMosaicMWebExposure } = useExperiment("Adaptex_campaign_PDP_mosaic_for_small_screens");
    const adaptexCampaignVariant = imageMosaicLargeScreensExposure.bucket !== 0 || imageMosaicMWebExposure.bucket !== 0;
    const { publishSignal } = useSignalPublisher();

    const { metadata } = templateComponent;
    const { id } = metadata;
    const searchFormRef = registerTarget<HTMLDivElement>("lodgingSearchFormSection");
    /* istanbul ignore if */

    const {
      sticky,
      autoOpenCalendar,
      prefillDestination,
      prefillPropertyName,
      hideFlexibleDates,
      fieldToAutoSubmitOnChange,
    } = templateComponent.config;

    /* istanbul ignore next */
    const essLogger = new EssClientLogger(analytics, ClientLogger, "", context?.experimentContext?.experiments);

    /* istanbul ignore next */
    const getLocationId = () => {
      if (context?.searchContext?.location) {
        return context.searchContext.location.id.toString();
      }
      return null;
    };

    const pageIdentity = getPageIdentity(page);

    const shouldSetPropertyId = getFunnelLocation(pageIdentity.funnelLocation) === "DETAILS";

    const destination: Destination = {};

    if (shouldSetPropertyId) {
      destination.pinnedPropertyId = getLocationId();
    } else {
      destination.regionId = getLocationId();
    }

    const prefillInputs = {
      propertySearchCriteriaInput: {
        primary: {
          destination: destination,
          rooms: [],
        },
      },
    };

    const isMobile = context?.deviceInformation?.mobile;
    const [toggleCalendar, setToggleCalendar] = React.useState(isMobile ? false : autoOpenCalendar ?? false);

    useSignalSubscriber({
      query: { type: PropertyOffersSignalNames.AVAILABILITY_CALL_TO_ACTION_CTA_BUTTON_CLICK },
      onSignal: () => setToggleCalendar(true),
    });

    useSignalSubscriber({
      query: { type: PropertyBookBarSignalNames.CHECK_AVAILABILITY_CALL_TO_ACTION_BUTTON_CLICK },
      onSignal: () => setToggleCalendar(true),
    });

    const { triggerPreloadingSearch } = useOptimizeLodgingSearchLoadTimeExperiment();
    const lodgingSearchExperimentProps = useLodgingSearchExperiment(hideFlexibleDates);

    const handleFieldChange = React.useCallback(
      (field: Field, event: React.SyntheticEvent, queryParams: string) => {
        triggerPreloadingSearch(field, event, queryParams);
        prefetchLodgingSrpAssets(field);
      },
      [prefetchLodgingSrpAssets, triggerPreloadingSearch]
    );

    const searchFormProps = {
      inputs: prefillDestination ? prefillInputs : {},
      onFieldChange: handleFieldChange,
      essLogger,
      enableReflection: true,
      redirectToSearchResultOnSubmit: true,
      sticky,
      autoOpenCalendar,
      additionalAdapterFields: {
        ...adapterFields,
        historyDetail: true,
        xPageId: page?.pageId ?? "",
      },
      onOpenLocationField: logDestinationFieldExperiments,
      features: prefillPropertyName ? ["PREFILL_PROPERTY_NAME"] : null,
      showCalendar: toggleCalendar,
      toggleCalendar: setToggleCalendar,
      updateLatLongOnSelection: undefined,
      ...lodgingSearchExperimentProps,
      onSubmit: () => {
        if (adaptexCampaignVariant) {
          publishSignal({
            payload: {},
            type: "ADAPTEX_MOSAIC_VIEW_BOOKING_FORM_EVENT",
          });
        }
      },
    };

    const searchFormMobileProps = {
      autoSubmitOnFieldChangeConfig: {
        onDateFieldChange: fieldToAutoSubmitOnChange === "date-field",
      },
    };

    const mobileSpacing: UitkSpacingProps | null = {
      margin: {
        small: {
          inline: "three",
        },
      },
    };

    return (
      <>
        <section id={id} data-testid="lodging-search-form">
          <Viewport>
            <ViewSmall>
              <UitkSpacing {...mobileSpacing}>
                <div id="lodgingSearchFormSection" data-testid="lodgingSearchFormSection" ref={searchFormRef}>
                  <SearchForm {...searchFormProps} {...searchFormMobileProps} />
                </div>
              </UitkSpacing>
            </ViewSmall>
            <ViewExtraLarge>
              <SearchForm {...searchFormProps} />
            </ViewExtraLarge>
          </Viewport>
        </section>
      </>
    );
  })
);

export default LodgingSearchForm;
