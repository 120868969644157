import { UitkFigureAspectRatioType } from "uitk-react-images";

export const convertAspectRatioToType = (
  ratio: string,
  defaultValue: UitkFigureAspectRatioType | null
): UitkFigureAspectRatioType => {
  switch (ratio) {
    case "1-1":
      return UitkFigureAspectRatioType.R1_1;
    case "4-3":
      return UitkFigureAspectRatioType.R4_3;
    case "3-2":
      return UitkFigureAspectRatioType.R3_2;
    case "16-9":
      return UitkFigureAspectRatioType.R16_9;
    case "21-9":
      return UitkFigureAspectRatioType.R21_9;
    case "3-4":
      return UitkFigureAspectRatioType.R3_4;
    case "4-1":
      return UitkFigureAspectRatioType.R4_1;
    default:
      return defaultValue || UitkFigureAspectRatioType.R1_1;
  }
};
