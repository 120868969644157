import { ExtendedTemplateComponent } from "./typings";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";

/**
 * Returns TRUE if TemplateComponent is likely to have data required for rendering, or has any children.
 * Assumes "clientSideOnly" components are always renderable - Shared-UI components are considered "clientSideOnly"
 * @param templateComponent ExtendedTemplateComponent
 * @param flexModuleModelStore FlexModuleModelStore
 * @returns boolean
 */
export const shouldRender = (
  templateComponent: ExtendedTemplateComponent,
  flexModuleModelStore: FlexModuleModelStore
): boolean => {
  if (templateComponent.children && templateComponent.children.length > 0) {
    return true;
  }

  if (templateComponent.config.clientSideOnly || templateComponent.config.ajaxOnly) {
    return true;
  }

  // "packaging" check is hack to allow for Packaging module's empty state render
  if (templateComponent.type === "packaging" || flexModuleModelStore.isPopulated(templateComponent.metadata.id)) {
    return true;
  }

  return false;
};

/**
 * Inspects all child nodes of provided TemplateComponent. Returns TRUE if any pass the shouldRender test. Only inspects immediate descendants.
 * @param templateComponent ExtendedTemplateComponent
 * @param flexModuleModelStore FlexModuleModelStore
 * @returns boolean
 */
export const hasRenderableChildren = (
  templateComponent: ExtendedTemplateComponent,
  flexModuleModelStore: FlexModuleModelStore
): boolean => {
  const children = templateComponent.children;

  if (!children || children.length === 0) {
    return false;
  }

  const renderableChildren = children.filter((child) => shouldRender(child, flexModuleModelStore));

  return renderableChildren.length > 0;
};
