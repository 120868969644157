import * as React from "react";

import { isDefaultGeolocationSupported } from "components/utility/GeolocationUtil";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "components/shared/TravelersField";
import { HotelWizardState } from "stores/wizard/state";
import { useConfigOverrides } from "stores/wizard/config/overrideConfigByView/useConfigOverrides";

/**
 * Switches between the regular destination label and "Current location" based on the state.
 */
export const useHotwireHotelGeolocationOverrides = (
  hotelWizardState: HotelWizardState,
  defaultToUserLocation = false
) =>
  React.useEffect(
    () =>
      hotelWizardState.overrideConfig(() => {
        const { location } = hotelWizardState.config;
        const destination = hotelWizardState.location.destination.value;

        const useCurrentLocationPlaceholder =
          isDefaultGeolocationSupported() &&
          defaultToUserLocation &&
          hotelWizardState.isGeolocationAllowed &&
          destination === "";

        location.destination.defaultToUserLocation = useCurrentLocationPlaceholder;
        location.destination.labelToken = useCurrentLocationPlaceholder
          ? "typeahead.currentLocation.available.item"
          : "hotwire.wizard.hotel.destination.label";
        location.destination.invalidLocationMessageKey = useCurrentLocationPlaceholder
          ? "hotwire.wizard.error.disabledGeolocation"
          : "wizard.destination.warning.invalidDestination.text";
      }),
    [defaultToUserLocation, hotelWizardState.location.destination.value, hotelWizardState.isGeolocationAllowed]
  );

export const useHotwireHotelOverrides = (hotelWizardState: HotelWizardState) =>
  useConfigOverrides(hotelWizardState, () => {
    const { date, location, travelers, form, navigation } = hotelWizardState.config;

    date.maxDaysRange = 30;
    date.daysBookableInAdvance = 330;
    date.defaultOffsetDates = {
      start: 0,
      end: 1,
    };
    /**
     * by default, Blossom supports international date format out-of-the-box. since our app only supports U.S. date format,
     * setting the following no-harm date configs
     *
     * submissionFormat will be read in HotwireHotelAppLinkSearchData, but not by Dates component in WizardHotelPWAv2HotwireView,
     * since useHiddenInputs is still set to false.
     */
    date.submissionFormat = "MM/dd/yyyy";
    date.ISOSubmissionSupport = false;

    navigation.tabTitleToken = "hotwire.wizard.hotel.tab.title.label";

    const baseDestinationToken = "hotwire.wizard.hotel.destination";

    location.destination.labelToken = `${baseDestinationToken}.label`;
    location.destination.queryParams = {
      regionId: "",
      latLong: "",
      iataCode: "",
      countryCode: "",
    };
    location.destination.currentLocationInputName = "destination";
    location.destination.typeaheadCurrentLocationEnabled = true;
    location.destination.typeaheadEmptyResultPlaceholder = `${baseDestinationToken}.typeahead.emptyResultPlaceholder`;

    const baseRoomPickerToken = "hotwire.wizard.roomPicker";

    travelers.labelToken = `${baseRoomPickerToken}.travelers.title`;
    travelers.dialogTitleToken = `${baseRoomPickerToken}.travelers.title`;
    travelers.travelersToken = `${baseRoomPickerToken}.travelers`;
    travelers.roomsGuestsToken = `${baseRoomPickerToken}.roomsGuests`;
    travelers.maxTravelersPerRoom = 4;
    travelers.maxBookableTravelers = 24;
    travelers.maxAdultsPerRoom = 4;
    travelers.maxChildrenPerRoom = 3;
    travelers.maxRooms = 6;
    travelers.hiddenInputType = TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_HOTEL_SEARCH;
    travelers.withAges = false;
    travelers.withInfants = false;

    form.action = "https://www.hotwire.com/hotels/search";
    form.submitButton.locToken = "hotwire.wizard.hotel.submit";
  });
