import { useQuery } from "@apollo/client";
import { getBexApiContext } from "bernie-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import {
  PropertyFiltersQueryDocument,
  PropertyFiltersQueryQueryVariables,
  SelectedValueInput,
} from "__generated__/typedefs";

function getLocation(context: ExtendedContextStore) {
  const location = context.searchContext.location;
  const { latitude, longitude } = location.geoCoordinate || {};
  if (location.type === "point_of_interest") {
    return {
      poi: `${latitude},${longitude}:${location.id}`,
    };
  }

  return {
    regionId: `${latitude},${longitude}:${location.id}`,
  };
}

export const generateSelectionsInput = (context: ExtendedContextStore): SelectedValueInput[] => {
  const { sort, paymentType, propertyStyle, travelerType, amenities } =
    context.searchContext.affinity?.bexApiFilters || {};

  const starRating = context.searchContext.starRating?.hsrFilterCode
    ? Number(context.searchContext.starRating.hsrFilterCode)
    : undefined;

  const structureTypes = context?.searchContext?.alternativeAccommodation?.lodgingUrlCode;
  const { regionId, poi } = getLocation(context);

  const selections: any = {
    sort,
    paymentType,
    propertyStyle,
    travelerType,
    amenities,
    starRating,
    structureTypes,
    regionId,
    poi,
  };

  return Object.keys(selections)
    .filter((s) => selections[s])
    .map((s) => ({ id: s, value: selections[s] }));
};

export function usePropertySearchQuery(context: ExtendedContextStore) {
  const destination = { regionId: String(context?.searchContext?.location?.id) };
  const selections = generateSelectionsInput(context);

  const variables: PropertyFiltersQueryQueryVariables = {
    context: getBexApiContext(context),
    destination: destination,
    criteria: {
      primary: {
        destination: destination,
        dateRange: null,
        rooms: [],
      },
      secondary: {
        selections,
      },
    },
  };

  return useQuery(PropertyFiltersQueryDocument, {
    variables,
    errorPolicy: "all",
    fetchPolicy: "cache-first",
  });
}

export default usePropertySearchQuery;
