const timeValuesAM: string[] = [
  "1200AM",
  "1215AM",
  "1230AM",
  "1245AM",
  "0100AM",
  "0115AM",
  "0130AM",
  "0145AM",
  "0200AM",
  "0215AM",
  "0230AM",
  "0245AM",
  "0300AM",
  "0315AM",
  "0330AM",
  "0345AM",
  "0400AM",
  "0415AM",
  "0430AM",
  "0445AM",
  "0500AM",
  "0515AM",
  "0530AM",
  "0545AM",
  "0600AM",
  "0615AM",
  "0630AM",
  "0645AM",
  "0700AM",
  "0715AM",
  "0730AM",
  "0745AM",
  "0800AM",
  "0815AM",
  "0830AM",
  "0845AM",
  "0900AM",
  "0915AM",
  "0930AM",
  "0945AM",
  "1000AM",
  "1015AM",
  "1030AM",
  "1045AM",
  "1100AM",
  "1115AM",
  "1130AM",
  "1145AM",
];

const timeValuesPM: string[] = [
  "1200PM",
  "1215PM",
  "1230PM",
  "1245PM",
  "0100PM",
  "0115PM",
  "0130PM",
  "0145PM",
  "0200PM",
  "0215PM",
  "0230PM",
  "0245PM",
  "0300PM",
  "0315PM",
  "0330PM",
  "0345PM",
  "0400PM",
  "0415PM",
  "0430PM",
  "0445PM",
  "0500PM",
  "0515PM",
  "0530PM",
  "0545PM",
  "0600PM",
  "0615PM",
  "0630PM",
  "0645PM",
  "0700PM",
  "0715PM",
  "0730PM",
  "0745PM",
  "0800PM",
  "0815PM",
  "0830PM",
  "0845PM",
  "0900PM",
  "0915PM",
  "0930PM",
  "0945PM",
  "1000PM",
  "1015PM",
  "1030PM",
  "1045PM",
  "1100PM",
  "1115PM",
  "1130PM",
  "1145PM",
];

/*
  This array is intended to work for Cartrawler urls since
  the service is reading the times as 24hrs.
*/

export const timeValues24HoursFormat = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];

export const timeValues = [...timeValuesAM, ...timeValuesPM];
