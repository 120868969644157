export const toBooleanOrUndefined = (str: string | undefined) => {
  switch (str) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
};
