import { FlexComponent } from "src/typings/flexFramework/FlexComponent";
import { BlossomComponent } from "components/flexFramework/domain/BlossomComponent";
import { BlossomComponentFromComposition } from "components/flexFramework/domain/BlossomComponentFromComposition";

interface BlossomComponentFactoryProps {
  flexComponents?: FlexComponent[];
}

/**
 * Factory that helps get Blossom Components based on the given props
 */
export class BlossomComponentsFactory {
  public static get = (props: BlossomComponentFactoryProps): BlossomComponent[] => {
    if (!props) {
      return [];
    }

    if (props.flexComponents && props.flexComponents.length > 0) {
      return [...props.flexComponents.map((component) => new BlossomComponentFromComposition(component))];
    }

    return [];
  };
}
