import * as React from "react";
import { LOBState } from "src/stores/wizard/state";
import {
  UitkLayoutConditionalGridSpan,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridStartEnd,
} from "uitk-react-layout-grid";
import { UitkButtonSize, UitkPrimaryButton } from "uitk-react-button";
import { useLocalization } from "@shared-ui/localization-context";
import { FlexClickTracker } from "src/components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { CobrandImage } from "components/shared/CobrandImage/CobrandImage";
import { GlobalConfig } from "stores/wizard/config";

const ButtonTracked = FlexClickTracker(UitkPrimaryButton);

export interface SubmitButtonProps {
  lobState: LOBState;
  colSpan?: UitkLayoutConditionalGridSpan;
  colStart?: UitkLayoutConditionalGridStartEnd;
  colEnd?: UitkLayoutConditionalGridStartEnd;
  className?: string;
  rfrr?: string;
  uisPrimeMicroMessages?: object[];
  globalConfig?: GlobalConfig;
  disabled?: boolean;
  moduleName?: string;
  testId?: string;
}

export const SubmitButton: React.FunctionComponent<SubmitButtonProps> = ({
  colSpan,
  colStart,
  colEnd,
  lobState,
  className,
  rfrr,
  uisPrimeMicroMessages,
  globalConfig,
  disabled,
  moduleName,
  testId = "submit-button",
}) => {
  const { formatText } = useLocalization();
  const moduleNameType = moduleName ? moduleName : "StorefrontWizard";

  return (
    <UitkLayoutGridItem colSpan={colSpan} colStart={colStart} colEnd={colEnd} className={className ?? ""}>
      <div>
        <ButtonTracked
          data-testid={testId}
          tag="button"
          type="submit"
          size={UitkButtonSize.LARGE}
          isFullWidth
          moduleName={moduleNameType}
          rfrr={rfrr || `SearchClick_NA`}
          uisPrimeMicroMessages={uisPrimeMicroMessages}
          disabled={disabled}
        >
          {formatText(lobState.config.form.submitButton.locToken)}
        </ButtonTracked>
        <CobrandImage globalConfig={globalConfig} />
      </div>
    </UitkLayoutGridItem>
  );
};
