/**
 * @desc In order to create the TimeDropdown we need to store
 * time cannonical values to be used later inside data-time
 * @return number[] [0, 15, 30, 45, ...etc]
 */
function createTimeCannonical(timeIncrement: number) {
  let time = 0;
  const timeCannonical: number[] = [];
  const timeToReach = 1425;
  while (time <= timeToReach) {
    timeCannonical.push(time);
    time += timeIncrement;
  }

  return timeCannonical;
}

const timesCreated = createTimeCannonical(15);
const timesCreatedHotwire = createTimeCannonical(30);

export const timesCannonical = timesCreated;
export const timesCannonicalHotwire = timesCreatedHotwire;
