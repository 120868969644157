import * as React from "react";
import { AttributionTextProps } from "./typings";
import { useLocalization } from "@shared-ui/localization-context";
import { License } from "typings/utility/media";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkText } from "uitk-react-text";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { RenderAttributionTextHTML } from "src/components/utility/MediaAttributionUtil";

const renderAttributionTextHTML: RenderAttributionTextHTML = ({ mainText, altText, alternateView, attribUrl }) => {
  const { formatText } = useLocalization();
  const attributionClassNames = alternateView ? "AttributionTextAlternate" : "AttributionText";
  const url = attribUrl || "";
  const attributionLinkText = formatText("hero.attribution.openLink", altText);

  return (
    <UitkSpacing padding="one">
      <div className={attributionClassNames} title={altText}>
        <UitkText inline="small" overflow="truncate">
          <TrackedLink
            title={attributionLinkText}
            className="uitk-attribution-link"
            moduleName="attributionsText.ownerLink"
            href={url}
            rfrr="Attributions.click"
          >
            <span dangerouslySetInnerHTML={{ __html: mainText }} />
          </TrackedLink>
        </UitkText>
      </div>
    </UitkSpacing>
  );
};

const stripTags = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, "");
};

/**
 * A component for displaying image attributions for Trover and Wikimedia. Renders a semi-transparent black rectangle
 * behind the text, positioning should be handled in the SCSS for the parent image component.
 *
 * @param props
 *  source:
 * @constructor
 */

export const AttributionText: React.FC<AttributionTextProps> = (props: AttributionTextProps) => {
  const { formatText } = useLocalization();
  const {
    source,
    origin,
    attribUrl,
    authorName,
    authorUrl,
    licenseSuite,
    licenseName,
    licenseUrl,
    alternateView,
    overrideRender,
  } = props;

  if (!source || !authorName) {
    return null;
  }

  let mainText;
  let altText;

  if (!/trover/i.test(source) && authorUrl && licenseUrl && (licenseSuite || licenseName) && attribUrl) {
    const licenseText = licenseSuite || licenseName;
    mainText = formatText("hero.attribution.wikimediaLink", attribUrl, authorUrl, authorName, licenseUrl, licenseText);
    altText = `${stripTags(mainText)} / ` + `${formatText("attributionText.wikimediaModified")}`;
  } else if (/trover/i.test(source) && (attribUrl || authorUrl)) {
    mainText = formatText("hero.attribution.troverLink", authorName, attribUrl || authorUrl);
    altText = stripTags(mainText);
  } else if (
    origin &&
    origin.match(/TourismMedia/i) &&
    !authorName.match(/TourismMedia/i) &&
    !authorName.match(/Tourism Media/i)
  ) {
    mainText = formatText("attributiontext.generic", authorName);
    altText = formatText("attributiontext.generic", authorName);
  } else {
    return null;
  }

  const render = overrideRender || renderAttributionTextHTML;

  return render({
    mainText: mainText.replace("<a", '<a target="_blank"'),
    altText,
    alternateView,
    attribUrl,
  });
};

export const getAttributionAltText = (
  source: string,
  origin: string,
  name: string,
  imageAlt: string,
  authorName: string,
  license?: License,
  attribUrl?: string
) => {
  const { formatText } = useLocalization();
  if (!source || !authorName) {
    return imageAlt || name;
  }

  let licenseText = "";
  if (license) {
    if (license.suite) {
      licenseText = license.suite;
    } else if (license.name) {
      licenseText = license.name;
    }
  }

  if (licenseText && source.match(/wikimedia/i)) {
    return formatText("hero.attribution.wikimediaAlt", name, authorName, licenseText);
  }
  if (attribUrl && source.match(/trover/i)) {
    return stripTags(formatText("hero.attribution.troverLink", authorName, attribUrl));
  }
  if (
    origin &&
    origin.match(/TourismMedia/i) &&
    !authorName.match(/TourismMedia/i) &&
    !authorName.match(/Tourism Media/i)
  ) {
    return formatText("attributiontext.generic", authorName);
  }
  return imageAlt || name;
};
