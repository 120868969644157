import * as React from "react";
import ReactLazyLoad from "react-lazyload";
import { ExtendedContextStore, PageType } from "typings/flexFramework/FlexDefinitions";
import { isVariantEnabled } from "components/utility/experiment";
import { checkForBots } from "src/components/utility/BotsUtils";

interface LazyLoadProps {
  context?: ExtendedContextStore;
  children: React.ReactElement;
  forceLazyLoad?: boolean;
  hasOffset?: boolean;
  className?: String;
}

// Renders its children only when it is visible to the user
export const LazyLoad: React.FC<LazyLoadProps> & { enabled?: boolean } = ({
  context,
  children,
  forceLazyLoad,
  className,
  hasOffset = true,
}) => {
  if (!children) {
    return null;
  }

  if (!context || !LazyLoad.enabled || !shouldLazyLoad(context, forceLazyLoad)) {
    return children;
  }

  return (
    <ReactLazyLoad className={className} once offset={hasOffset ? 200 : 0}>
      {children}
    </ReactLazyLoad>
  );
};

const pageTypesToLazyLoad: PageType[] = [
  "Alternative-Accommodations",
  "Hotel-Destinations",
  "Hotel-Filter-Destinations",
  "Hotel-Information",
  "Hotels-Near",
  "Travel-Guide-Accommodation",
  "Travel-Guide-Filter-Hotels",
  "Travel-Guide-Hotels",
  "Travel-Guide-VacationRentals",
  "Vacation-Rental-Destinations",
];

const shouldLazyLoad = (context: ExtendedContextStore, forceLazyLoad?: boolean) => {
  if (context.queryParams?.["dynamicRender"] === "1") {
    return true;
  }

  const hasBots = checkForBots(context);

  // We should not lazy load for bots for SEO purposes
  if (hasBots) {
    return false;
  }

  // force lazyload prop sets lazyload for everything except bots.
  if (forceLazyLoad) return true;

  return (
    isVariantEnabled(context, "Blossom_Dynamic_Render") || pageTypesToLazyLoad.includes(context.searchContext?.pageType)
  );
};

// Global flag to turn on/off for testing purposes
LazyLoad.enabled = true;
