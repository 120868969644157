import * as React from "react";
import { TemplateComponentProps } from "experience-template-renderer-react";
import { useLocalization } from "@shared-ui/localization-context";
import { ErrorBoundary } from "components/errorBoundaries/errorBoundaries";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { withStores } from "src/stores";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";

export const getExperienceTemplateComponentWrapper = (
  blossomComponent?: BlossomComponent
): React.FC<TemplateComponentProps> => {
  const blossomComponentChildren = blossomComponent?.getBlossomComponents();

  const Wrapper: React.FC<
    TemplateComponentProps & {
      context: ExtendedContextStore;
      flexModuleModelStore: FlexModuleModelStore;
    }
  > = ({ templateComponent, children, context, flexModuleModelStore }) => {
    const l10n = useLocalization();
    const blossomComponent = blossomComponentChildren?.find(
      (blossomComponent) => blossomComponent.templateComponent.metadata.id === templateComponent.metadata?.id
    );

    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement<any>(child, {
          l10n,
          context,
          flexModuleModelStore,
          blossomComponent,
        });
      }
      return child;
    });

    if (childrenWithProps?.length && childrenWithProps?.length > 0) {
      const id = templateComponent.metadata?.id;
      return (
        <ErrorBoundary key={id} id={id}>
          {childrenWithProps}
        </ErrorBoundary>
      );
    }

    return null;
  };

  return withStores("context", "flexModuleModelStore")(Wrapper);
};

export const DefaultExperienceTemplateComponentWrapper = getExperienceTemplateComponentWrapper();
