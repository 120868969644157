import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export const checkForBots = (context: ExtendedContextStore) => {
  const userAgent = context.userAgent?.toLowerCase() || "";

  return (
    userAgent.includes("bot") ||
    userAgent.includes("naver") ||
    userAgent.includes("catchpoint") ||
    userAgent.includes("screaming frog") ||
    userAgent.includes("google-inspectiontool")
  );
};
