/* istanbul ignore file */

import { Logger } from "bernie-logger";
import * as React from "react";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { ServerSideLogger } from "components/utility/ServerSideLogger";
import { UNSUPPORTED_MODULE } from "config/systemEvents";
import { Component, ComponentProps } from "src/typings/flexFramework/FlexComponent";
import { Localization } from "bernie-l10n";
import { blossomCodeSplitter } from "src/components/flexFramework/CodeSplitter/BlossomCodeSplitter";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

// Do not log if we find those modules
const SKIP_LOGGING_FOR_MODULES = new Set([
  "footer", // It's on too many templates
  "map-overlay", // Needed for its tokens, no UI
  "hotel-description", // Needed for its tokens, no UI
  "native-ad", // Too many templates
  "sticky-wizard", // Too many templates
  "flights-editorial-content",
  "biased-wizard",
  "things-todo",
  "storefront-recently-viewed",
  "recent-user-hotel-searches",
  "tokens", // Needed for tokens
  "trip-cost-estimator", // UI implementation deleted
  "trip-attach-qualification", // UI implementation deleted
]);

/**
 * Base class to describe a component that we are rendering in Blossom.
 * This is an adapter pattern over the components given to use from whatever data source (e.g. LPT Composition).
 *
 * This class knows what it is, and knows how to render itself appropriately regardless of where its data comes from.
 */
export abstract class BlossomComponent {
  public templateComponent: ExtendedTemplateComponent;

  public id: string;

  public fmId: number;

  public fmTitleId?: number;

  public name: string;

  public type: string;

  public modules: Component[];

  public scene: string | undefined;

  public abstract getProps: () => any;

  public abstract shouldRender: () => boolean;

  public abstract getView: () => string | undefined;

  public abstract getName: () => string;

  public abstract getBlossomComponents: () => BlossomComponent[];

  protected constructor(module: Component) {
    this.id = module.id;
    this.name = module.name;
    this.fmId = module.fmId;
    this.fmTitleId = module.fmTitleId;
    this.scene = module.scene;
    this.modules = module.modules;
  }

  public render = (key: string, context: ExtendedContextStore, l10n: Localization, logger: Logger) => {
    /* istanbul ignore next */
    if (!this.shouldRender()) {
      return null; //ignoring this for coverage since we are intentionally migrating away from it
    }

    const component = this.getReactComponent();
    if (component === undefined) {
      this.logUnsupportedModule(logger);

      return null;
    }

    const props: ComponentProps = {
      ...this.getProps(),
      key,
      context,
      l10n,
      blossomComponent: this,
    };

    return React.createElement(component as any, props);
  };

  public renderChildren = (
    logger: Logger,
    l10n: Localization,
    context: ExtendedContextStore,
    filterFunction?: (component: BlossomComponent) => boolean
  ) => {
    let children = this.getBlossomComponents();
    if (filterFunction) {
      children = children.filter(filterFunction);
    }

    return children
      .map((component) => component.render(component.id, context, l10n, logger))
      .filter((component) => component) as React.ReactElement[];
  };

  /**
   * Gets the react component from the Flex Component Map using its name and view.
   * The convention is: <module name>_<view>
   *
   * e.g. "hotels_travellers-loved"
   */
  public getReactComponent = () => {
    const flexComponentName = this.getName();
    const view = this.getView();

    return blossomCodeSplitter.getComponentFromLibrary(flexComponentName, view);
  };

  /**
   * Takes the name of a Flex component found in a composition that had no corresponding Flex UI Component, and reports it to the server side logger
   */
  private logUnsupportedModule = (logger: Logger) => {
    /* istanbul ignore next */
    if (!SKIP_LOGGING_FOR_MODULES.has(this.name)) {
      ServerSideLogger(logger).logEvent(UNSUPPORTED_MODULE, "Found an unsupported module on the template", {
        module_name: this.name,
      });
    }
  };
}
