import * as React from "react";
import { inject, observer } from "mobx-react";
import { AdvertisingStore, initializeClear, Targeting, updateTargeting } from "bernie-plugin-ads-loader-script";

interface AdScriptsProps {
  advertising?: AdvertisingStore;
}

export const AdScripts: React.FunctionComponent<AdScriptsProps> = inject("advertising")(
  observer((props: AdScriptsProps) => {
    const { advertising } = props;

    const updateChanges = () => {
      if (window && advertising?.includeAdLoader) {
        const targetingData: Targeting = {};
        if (advertising?.CMPTST) {
          targetingData.CMPTST = advertising.CMPTST;
        }
        updateTargeting(targetingData);
      }
    };

    // componentDidMount and cleanup
    React.useEffect(() => {
      updateChanges();
      return initializeClear();
    }, []);

    // componentDidUpdate - update when includeAdLoader = true
    React.useEffect(() => {
      updateChanges();
    }, [advertising?.includeAdLoader]);

    return null;
  })
);
