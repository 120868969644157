import { FlexComponent } from "typings/flexFramework/FlexComponent";
import { BlossomComponentsFactory } from "components/flexFramework/domain/BlossomComponentsFactory";
import { BlossomComponent } from "components/flexFramework/domain/BlossomComponent";
import {
  flexToExtendedTemplateComponent,
  DescendantDepth as XTDescendantDepth,
} from "src/stores/ExperienceTemplateStore";

/**
 * Describes a Blossom Component deriving from Flex Composition data source.
 */
export class BlossomComponentFromComposition extends BlossomComponent {
  private readonly flexComponent: FlexComponent;

  public constructor(flexComponent: FlexComponent) {
    super(flexComponent);
    this.flexComponent = flexComponent;
    this.type = flexComponent.type;
    this.templateComponent = flexToExtendedTemplateComponent(this.flexComponent, XTDescendantDepth.ALL);
  }

  public shouldRender = () =>
    this.flexComponent.model && !this.flexComponent.model.error && !this.flexComponent.model.empty;

  // Since regions don't have consistent names, we need to handle them properly.
  // Otherwise, just give the component name
  public getName = () => (this.flexComponent.type === "Region" ? "region" : this.flexComponent.name);

  public getView = () => this.flexComponent.view || (this.flexComponent.model?.view as string | undefined);

  public getBlossomComponents = () => [
    ...BlossomComponentsFactory.get({ flexComponents: this.flexComponent.modules }),
    ...BlossomComponentsFactory.get({ flexComponents: this.flexComponent.groups }),
    ...BlossomComponentsFactory.get({ flexComponents: this.flexComponent.children }),
  ];

  public getProps = () => {
    return {
      flexComponent: this.flexComponent,
      templateComponent: this.templateComponent,
    };
  };
}
