import * as React from "react";
import { Logger } from "bernie-logger";
import { logger } from "bernie-core";
import { FAILED_REGION_RENDER } from "src/config/systemEvents";

interface ErrorBoundaryProps {
  logger?: Logger;
  id?: string;
}

@logger("ErrorBoundary")
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, {}> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log("Error in a module: ", this.props.id, errorInfo, error);
    if (this.props.logger) {
      try {
        const errorOutput = JSON.stringify(errorInfo).slice(0, 512);
        this.props.logger.logEvent(FAILED_REGION_RENDER, this.props.id, errorOutput, error);
      } catch (exception) {
        this.props.logger.logEvent(FAILED_REGION_RENDER, this.props.id, errorInfo, error);
      }
    }
  }

  public render() {
    return this.props.children;
  }
}
