import * as React from "react";

import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

import { Amenities } from "@shared-ui/retail-product-amenities";
import { PropertySummaryAmenities } from "@shared-ui/lodging-property-details";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";

const WrappedSummaryAmenities = PropertyBexApiWrapper(PropertySummaryAmenities);
const WrappedProductAmenities = ProductBexApiWrapper(Amenities);

export const AmenitiesModule = ({ context }: { context: ExtendedContextStore }) => (
  <Experiment name="PEXO_PDP_AMENITY_PRODUCT_ENTITY_MIGRATION_WEB">
    <ExperimentControl>
      <WrappedSummaryAmenities context={context} />
    </ExperimentControl>
    <ExperimentVariant bucket={1}>
      <WrappedProductAmenities context={context} />
    </ExperimentVariant>
  </Experiment>
);
